<template>
<div :class="componentClasses" @click="clicked()">
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.66332 0.0801711C9.79937 -0.0267237 9.98989 -0.0267237 10.126 0.0801711L18.6965 6.81374C18.7883 6.88585 18.842 6.99672 18.842 7.11416V17.6955C18.842 18.3067 18.6016 18.8928 18.1738 19.325C17.746 19.7572 17.1657 20 16.5607 20H12.7569L12.7514 20L12.7459 20H7.04317L7.03769 20L7.03221 20H3.22862C2.62357 20 2.04329 19.7572 1.61546 19.325C1.18762 18.8928 0.947266 18.3067 0.947266 17.6955V7.11416C0.947266 6.99672 1.00095 6.88585 1.09272 6.81374L9.66332 0.0801711ZM7.41446 19.2388H12.3746V10.3806H7.41446V19.2388Z" fill="black"/>
  </svg>
</div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconHomeFilled',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-home-filled': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
