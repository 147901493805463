<template>
    <popup
    v-if="visible"
    ref="evergreenProgressImage"
    :visible="visible"
    @hide="closePopup"
    class="gc-popup-evergreen-progress-dismiss"
    :no-close-on-backdrop="true"
  >
    <popup-title>
      <popup-button-close @hide="closePopup()"/>
    </popup-title>
    <popup-body class="gc-popup-evergreen-progress-dismiss__body text-center">
        <popup-content v-if="status==='init'">
            <popup-text-heading class="gc-popup-evergreen-progress-dismiss__heading">
              {{ $t('message["popup.evergreen-progress-image.dismiss.heading"]') }}
            </popup-text-heading>
            <popup-text-body>
                {{ $t('message["popup.evergreen-progress-image.dismiss.description"]') }}
            </popup-text-body>
        </popup-content>
        <popup-footer v-if="status==='init'">
            <button-primary :text="$t('message[\'popup.evergreen-progress-image.dismiss.button\']')" @click="dismissPic"></button-primary>
        </popup-footer>
    </popup-body>
  </popup>
</template>
<script>
import Popup from './Popup'
import PopupBody from './popup-sub-components/PopupBody'
import PopupTitle from './popup-sub-components/PopupTitle'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupContent from './popup-sub-components/PopupContent'
import PopupFooter from './popup-sub-components/PopupFooter'
import ButtonPrimary from '../buttons/ButtonPrimary'
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import TextContent from '../../root/TextContent'
import PopupTextBody from './popup-sub-components/PopupTextBody'
import PopupTextHeading from './popup-sub-components/PopupTextHeading'
export default {
  name: 'PopupEvergreenProgressDismiss',
  components: {
    Popup,
    PopupBody,
    PopupTitle,
    PopupButtonClose,
    PopupContent,
    PopupFooter,
    ButtonPrimary,
    TextContent,
    PopupTextBody,
    PopupTextHeading
  },
  mixins: [popupAnimationMixin],
  props: {
    visible: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      status: 'init',
      loading: true
    }
  },
  methods: {
    dismissPic (e, callback) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('dismissPic')
        if (callback) {
          callback()
        }
      })
    },
    closePopup (e, callback) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('hideEvergreenProgressDismissPopup')
        if (callback) {
          callback()
        }
      })
    }
  }
}
</script>
