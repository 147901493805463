<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.6417 8.70432C15.4446 8.9015 15.2111 9 14.9413 9H0.996169C0.726221 9 0.492851 8.9015 0.295678 8.70432C0.0985048 8.50693 0 8.27356 0 8.00378C0 7.73405 0.0985048 7.50068 0.295678 7.30345L7.26826 0.33087C7.46565 0.133697 7.69902 0.0349731 7.96875 0.0349731C8.23848 0.0349731 8.47207 0.133697 8.66908 0.33087L15.6417 7.3035C15.8386 7.50068 15.9375 7.73405 15.9375 8.00383C15.9375 8.27356 15.8386 8.50693 15.6417 8.70432Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconUpArrowFilled',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-up-arrow-filled': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
