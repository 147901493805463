import { mapMutations } from 'vuex'

export default {
  methods: {
    ...mapMutations({
      setNotificationBarSettings: 'pageStore/setNotificationBar',
      resetNotificationBarSettings: 'pageStore/resetNotificationBar'
    })
  },
  destroyed () {
    this.resetNotificationBarSettings()
  }
}
