<template>
  <button-base :borderRadius="'none'" :class="componentClasses" :type="'solid'" @click="clicked()">
    <div class="gc-button-primary-forward__left-section">
      <slot class="gc-button-primary-forward__left-section-icon">
        <icon-list />
      </slot>
      <div class="gc-button-primary-forward__text-section">
        <text-content :class="textClass" :line-height="'lh-xl'" :size="'sm1'" :weight="'extra-bold'"> {{ text }}</text-content>
      </div>
    </div>
    <div class="gc-button-primary-forward__right-section">
      <icon-forward />
    </div>
  </button-base>
</template>

<script>

import ButtonBase from '../../../../root/ButtonBase'
import TextBodyRegular from '../../../../global/typography/TextBodyRegular'
import IconList from '../../../../root/icons/IconList'
import IconForward from '../../../../root/icons/IconForward'
import TextContent from '../../../../root/TextContent'

export default {
  name: 'ButtonInvitationNotification',
  components: {
    TextContent,
    ButtonBase,
    IconList,
    TextBodyRegular,
    IconForward
  },
  props: {
    /**
     * The main text of the button
     */
    text: {
      default: ''
    },
    /**
     * The sub text of the button
     */
    subText: {
      default: ''
    },
    /**
     * The disabled state of the button
     */
    disabled: {
      default: false
    }
  },
  computed: {
    hasSubText: function () {
      return this.subText.replace(/\s/g, '') !== ''
    },
    hasIcon () {
      return !!this.$slots.default
    },
    textClass: function () {
      return {
        'gc-button-primary-forward__text': true,
        'gc-button-primary-forward__text--with-sub-text': this.hasSubText
      }
    },

    componentClasses: function () {
      return {
        'pc-invitaion-notification gc-button-primary-forward': true,
        'gc-button-primary-forward--disabled': this.disabled
      }
    }
  },
  methods: {
    /**
     * function to emit the click event
     */
    clicked () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped></style>
