<template>
  <div :class="componentClasses" v-on="$listeners">
   <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_9803_169598)">
      <path d="M5.56238 8.62532V8.3749C5.56238 8.23696 5.67434 8.12518 5.81193 8.12518H12.8129C12.9508 8.12518 13.0624 8.23574 13.0624 8.3749V8.62532C13.0624 8.76326 12.9506 8.87505 12.8129 8.87505H5.81193C5.67416 8.87505 5.56238 8.76448 5.56238 8.62532Z" fill="white"/>
      <path d="M5.56238 5.62535V5.37493C5.56238 5.23699 5.67434 5.12503 5.81193 5.12503H12.8129C12.9508 5.12503 13.0624 5.23577 13.0624 5.37493V5.62535C13.0624 5.76329 12.9506 5.87507 12.8129 5.87507H5.81193C5.67416 5.87507 5.56238 5.76451 5.56238 5.62535Z" fill="white"/>
      <path d="M5.56238 11.6254V11.375C5.56238 11.237 5.67434 11.1252 5.81193 11.1252H12.8129C12.9508 11.1252 13.0624 11.2358 13.0624 11.375V11.6254C13.0624 11.7633 12.9506 11.8753 12.8129 11.8753H5.81193C5.67416 11.8753 5.56238 11.7645 5.56238 11.6254Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.18809 12.3752C3.04684 12.3752 2.9375 12.2629 2.9375 12.1245V10.8759C2.9375 10.7346 3.04963 10.6253 3.18809 10.6253H4.43672C4.57797 10.6253 4.68749 10.7374 4.68749 10.8759V12.1245C4.68749 12.2657 4.57518 12.3752 4.43672 12.3752H3.18809ZM4.18751 11.1252H3.43746V11.8753H4.18751V11.1252Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.18809 9.37527C3.04684 9.37527 2.9375 9.26297 2.9375 9.1245V7.87588C2.9375 7.73463 3.04963 7.62511 3.18809 7.62511H4.43672C4.57797 7.62511 4.68749 7.73742 4.68749 7.87588V9.1245C4.68749 9.26576 4.57518 9.37527 4.43672 9.37527H3.18809ZM4.18751 8.12526H3.43746V8.87513H4.18751V8.12526Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.18809 6.37513C3.04684 6.37513 2.9375 6.263 2.9375 6.12453L2.9375 4.87591C2.9375 4.73465 3.04963 4.62514 3.18809 4.62514H4.43672C4.57797 4.62514 4.68749 4.73744 4.68749 4.87591V6.12453C4.68749 6.26579 4.57518 6.37513 4.43672 6.37513H3.18809ZM4.18751 5.12511H3.43746L3.43746 5.87515H4.18751V5.12511Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.500851 16.5C0.22618 16.5 0 16.2757 0 15.9991L0 1.00085C0 0.726182 0.224273 0.500002 0.500851 0.500002L15.4991 0.500002C15.7738 0.500002 16 0.724276 16 1.00085V15.9991C16 16.2738 15.7757 16.5 15.4991 16.5H0.500851ZM0.750062 15.4983C0.750062 15.6375 0.862715 15.75 1.0017 15.75H14.9982C15.1374 15.75 15.2498 15.6373 15.2498 15.4983L15.2498 1.50181C15.2498 1.36264 15.1372 1.25017 14.9982 1.25017L1.0017 1.25017C0.862541 1.25017 0.750062 1.36282 0.750062 1.50181L0.750062 15.4983Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_9803_169598">
      <rect width="16" height="16" fill="white" transform="matrix(1 0 0 -1 0 16.5)"/>
      </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconList',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-list': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
