<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
    <path d="M13.7376 5.5037L24.4869 16.064C25.171 16.7358 25.171 17.8249 24.4869 18.4964C23.8034 19.1679 22.6948 19.1679 22.0113 18.4964L12.4998 9.15212L2.98869 18.4961C2.30489 19.1676 1.19638 19.1676 0.512853 18.4961C-0.170951 17.8246 -0.170951 16.7355 0.512853 16.0638L11.2623 5.50343C11.6042 5.16768 12.0519 5 12.4998 5C12.9479 5 13.3959 5.16801 13.7376 5.5037Z" fill="#9E9E9E"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="25" height="25" fill="white" transform="translate(0 25) rotate(-90)"/>
    </clipPath>
    </defs>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconUpArrow',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-up-arrow': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
