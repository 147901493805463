import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import ProfileService from '../../../../services/ProfileService'

export default {
  data () {
    return {
      imagesUrl: this.$appConfig.imagesUrl,
      currentPage: 1,
      perPage: 10,
      pageCount: 1,
      loading: true,
      history: [],
      toBeDeleted: [],
      hasImages: false,
      displayDateFormat: 'MMM D'
    }
  },
  methods: {
    ...mapMutations({
      visible: 'confirmationPopupStore/visible',
      showConfirmationPopup: 'confirmationPopupStore/show',
      hideConfirmationPopup: 'confirmationPopupStore/hide',
      setConfirmationPopupTitle: 'confirmationPopupStore/setTitle',
      setConfirmationPopupDescription: 'confirmationPopupStore/setDescription',
      setConfirmationPopupConfirmationText: 'confirmationPopupStore/setConfirmationText',
      setConfirmationPopupConfirmationLoadingText: 'confirmationPopupStore/setConfirmationLoadingText'
    }),
    ...mapGetters({
      service: 'fitnessDiaryStore/getService',
      visibleConfirmationPopup: 'confirmationPopupStore/visible'
    }),
    resetHistoryData () {
      this.currentPage = 1
      this.perPage = 10
      this.pageCount = 1
      this.history = []
      this.toBeDeleted = []
    },
    async deleteHistory () {
      this.loading = true
      // this.showLoading()

      const lastId = this.toBeDeleted[(this.toBeDeleted.length - 1)]
      try {
        for (const id of this.toBeDeleted) {
          await this.service()
            .removeImageHistory(id)
            .finally(() => {
              if (lastId.toString() === id.toString()) {
                this.service().getDataFromAPI(true)
                  .finally(() => {
                    this.resetHistoryData()
                    this.load()
                    this.$emit('reInit')
                    this.hideLoading()
                  })
              }
            })
        }
      } catch (e) {
        this.resetHistoryData()
        this.load()
        this.$emit('reInit')
        this.hideLoading()
      }
    },
    /**
     * Initiate deletion
     * DeleteConfirmation popup window will be displayed once this is executed.
     * @param {Object} data
     */
    initiateDelete (data) {
      Object.keys(data.images).forEach((imageKey) => {
        this.toBeDeleted.push(data.images[imageKey].id)
      })

      let hasPublicImage = Object.values(data.images).some(image => {
        return !image.private
      })
      this.setDeletePopupContent(data.date, hasPublicImage)
      this.showConfirmationPopup()
    },
    setDeletePopupContent (date, hasPublicPhotos) {
      let trainerEmail = ''
      let pService = new ProfileService()
      pService.setProfileData().then(() => {
        trainerEmail = pService.trainer.company_name || ''
        if (hasPublicPhotos) {
          this.setConfirmationPopupDescription(this.$t('message[\'fitness-diary.delete-shared-photo-prompt\']', [date, trainerEmail]))
        } else {
          this.setConfirmationPopupDescription(this.$t('message[\'fitness-diary.delete-private-photo-prompt\']', [date, trainerEmail]))
        }
      })

      if (this.service().images['front-image'].getTodayImage() || this.service().images['back-image'].getTodayImage() || this.service().images['side-image'].getTodayImage()) {
        this.contentClass = this.contentClass + ' fd-upload-image-popup--with-new-replace'
        this.hasAlreadyUploadedImages = true
      }

      this.setConfirmationPopupTitle(this.$t('message[\'fitness-diary.history-delete-title\']'))
      if (hasPublicPhotos) {
        this.setConfirmationPopupDescription(this.$t('message[\'fitness-diary.delete-shared-photo-prompt\']', [date, trainerEmail]))
      } else {
        this.setConfirmationPopupDescription(this.$t('message[\'fitness-diary.delete-private-photo-prompt\']', [date, trainerEmail]))
      }

      this.setConfirmationPopupConfirmationText(this.$t('message[\'general.delete\']'))
      this.setConfirmationPopupConfirmationLoadingText(this.$t('message[\'general.delete.loading\']'))
    },
    scrollListener () {
      // gap that should event trigger before scrolling to bottom
      const bottomGap = 100
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - bottomGap) {
        if (!this.loading && (this.currentPage <= this.pageCount)) {
          this.load()
        }
      }
    },
    /**
     * returns the photo uploaded date in the format of 'MMM DD'
     * @param {String}date
     * @returns {String}
     */
    changeDateFormat (date) {
      const dateObj = moment(date)
      const today = moment(new Date())
      if (dateObj.format('MMM D') === today.format('MMM D')) {
        return this.$i18n.t('message["general.today"]')
      } else if (!today.isSame(dateObj, 'year')) {
        return dateObj.format('ll')
      } else {
        return dateObj.format('MMM D')
      }
    },
    /**
     * call the API and load fetched user-journey history data
     */
    load () {
      this.loading = true
      this.service().getPhotosHistory(this.currentPage, this.perPage)
        .then(response => {
          this.pageCount = response.data.last_page
          let currentPageImagesCount = 0
          response.data.data.forEach(stat => {
            let hasImage = false
            const statArr = []
            // call the changeDateFormat() method to modify Date string
            statArr.date = this.changeDateFormat(stat.date)

            const imagesArr = []
            let hasPublic = false
            stat.images.forEach(statData => {
              if (statData.image) {
                const imageObj = {
                  image: statData.image,
                  id: statData.id,
                  type: statData.type,
                  private: statData.private
                }
                if (statData.id && !statData.private) {
                  hasPublic = true
                }
                switch (statData.type) {
                  case 'front':
                    imagesArr.frontImage = imageObj
                    hasImage = true
                    break
                  case 'side':
                    imagesArr.sideImage = imageObj
                    hasImage = true
                    break
                  case 'back':
                    imagesArr.backImage = imageObj
                    hasImage = true
                    break
                }
              }
            })

            if (hasImage) {
              this.hasImages = true
              currentPageImagesCount++
              statArr.images = Object.assign({}, imagesArr)
              statArr.hasPublic = hasPublic
              this.history.push(Object.assign({}, statArr))
            }
          })
          this.currentPage++

          if (this.currentPage >= this.pageCount && !this.hasImages) {
            this.$emit('has-no-images')
          }
          this.loading = false
          if ((this.history.length < 5 || currentPageImagesCount === 0) && (this.currentPage <= this.pageCount)) {
            this.load()
          }
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
