<template>
    <page :header="header" class="gc-page-meal-plan-paired" @ready="setFooterClasses">
        <page-container>
            <div class="gc-page-meal-plan-paired__header">
                <text-heading-4
                        class="gc-page-meal-plan-paired__title text-center"
                        weight="extra-bold"
                        lineHeight="multi">
                    {{$tc('message[\'meal-plan-pair.paired-meals\']', 2)}}
                </text-heading-4>
            </div>
            <div class="gc-page-meal-plan-paired__content">
                <text-body-small :line-height="'multi'" class="gc-page-meal-plan-paired__connected-text">
                    {{$t('message[\'member-connect.connected-with\']')}}
                </text-body-small>
                <card-member-small
                :avatar="member.getAvatar()"
                :name="member.getFullName()"
                :email="member.getEmail()"></card-member-small>
                <div class="gc-page-meal-plan-paired__meal-selection">
                  <text-body-small class="gc-page-meal-plan-paired__meal-selection-title" :line-height="'multi'" :weight="'extra-bold'">
                    {{ $t('message[\'meal-plan-pair.choose-meal\']') }}
                </text-body-small>
                  <div class="gc-page-meal-plan-paired__check-box-input-wrapper">
                      <div class="gc-page-meal-plan-paired__check-box-input" v-for="(parentMealType, key) in parentMealTypes" :key="key">
                          <input-check-box-squared :id="parentMealType.id" v-model="pairingMealTypes"></input-check-box-squared>
                          <label class="gc-page-meal-plan-paired__check-box-input-label">{{parentMealType.name}}</label>
                      </div>
                  </div>
                </div>
            </div>
        </page-container>
        <page-footer ref="footer" @click="pairMealTypes" :validated="pairingMealTypes.length === 0"></page-footer>
    </page>
</template>

<script>
import FormBaseComponent from '../../layout/template-1/global/widgets/forms/FormBaseComponent'
import TextBodySmall from '../typography/TextBodySmall'
import TextHeading4 from '../typography/TextHeading4'
import PageContainer from './page-sub-components/PageContainer'
import Page from './PageForm'
import Member from '../../../services/meal-plan-pair/Member'
import TextBodyRegular from '../typography/TextBodyRegular'
import ConnectionService from '../../../services/meal-plan-pair/ConnectionService'
import PairService from '../../../services/meal-plan-pair/PairService'
import InputCheckBoxSquared from '../inputs/InputCheckBoxSquared'
import PageFooter from './page-sub-components/PageFormFooter'
import lodash from 'lodash'
import CardMemberSmall from '../cards/CardMemberSmall'
import MealPlanService from '../../../services/MealPlanService'

export default {
  name: 'PagePairedMeals',
  props: {
    mealsPerDay: {
      default: 3
    }
  },
  extends: FormBaseComponent,
  components: {
    CardMemberSmall,
    TextBodyRegular,
    Page,
    PageContainer,
    TextHeading4,
    TextBodySmall,
    InputCheckBoxSquared,
    PageFooter
  },
  data: function () {
    return {
      header: {
        isTransparent: false,
        isFixed: false,
        show: false,
        left: '',
        right: '',
        rightBlink: false,
        modifiers: [],
        mainTitle: '',
        subTitle: ''
      },
      member: new Member(),
      connectionService: new ConnectionService(),
      pairService: new PairService(),
      mealPlanService: new MealPlanService(),
      parentMealTypes: [], // meal types of the owner
      pairingMealTypes: [], // current selected meal types
      alreadyPairedCount: 0, // already paired meal types count
      isPaired: false // already paired status
    }
  },
  mounted () {
    this.setPageDetails()
  },
  methods: {
    async setPageDetails () {
      this.showLoading()
      // initiating connection service and pair service (which will load current state)
      await Promise.all([this.connectionService.init(), this.pairService.init()])
        .catch(() => {
          this.hideLoading()
        })

      // getting other partner details(should be the owner)
      this.member = this.connectionService.getPartner()

      // getting already meal types paired status
      this.isPaired = this.pairService.isPaired()

      // selecting only first 3 meal types by default
      let takingCount = 3
      if (this.mealsPerDay && this.mealsPerDay < 3) {
        // if meals per day is less than set taking count to meals per day count
        takingCount = this.mealsPerDay
      }

      // ordering meal types by its priority
      let parentPlans = lodash.orderBy(
        this.pairService.getParentMealTypes(),
        'priority',
        'asc'
      )

      // selecting first 3 meal types max. probably breakfast, lunch, dinner
      this.parentMealTypes = lodash.take(parentPlans, takingCount)

      // setting already paired meal types to selected list
      let alreadyPaired = []
      lodash.forEach(this.pairService.getPairedMealTypes(), (value) => {
        alreadyPaired.push(value.id)
      })
      this.pairingMealTypes = alreadyPaired
      this.alreadyPairedCount = alreadyPaired.length
      this.hideLoading()
      setTimeout(() => {
        this.setFooterClasses()
      }, 300)
    },
    async pairMealTypes () {
      if (!this.isPaired) {
        // if no any pairing is done previously, pair new meal types
        this.submitPairMealTypes()
      } else {
        if (this.pairingMealTypes.length !== this.alreadyPairedCount) {
          // if meal types already paired and new selection is different with previous
          // unpair first
          await this.pairService.unpair()

          // then pair newly selected meal types
          this.submitPairMealTypes()
        } else {
          // if meal types already paired and new selection is same with previous, go to next page
          this.nextComponent()
        }
      }
    },
    submitPairMealTypes () {
      this.showLoading()

      // pair selected meal types
      this.pairService.pair(this.pairingMealTypes)
        .then(async () => {
          // re initiating pair details
          await this.pairService.init({forceLoad: true})

          // reset meal plan details
          this.mealPlanService.reset()

          this.nextComponent()
        })
        .catch(() => {
          this.hideLoading()
        })
    },
    nextComponent () {
      this.$emit('submit', {})
    },
    setFooterClasses () {
      if (this.$refs.footer) {
        this.$refs.footer.setFooterClass()
      }
    }
  }
}
</script>
