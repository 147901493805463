import TrackerAPI from './api/v2/TrackerAPI'
import lodash from 'lodash'

export default class TrackerService {
  /**
   * Tracker API instance
   * @type {TrackerAPI}
   * @private
   */
  _api = null

  /**
   * Metric type
   * @type {string}
   * @private
   */
  _metric = ''

  /**
   * No of tries for querying
   * @type {number}
   * @private
   */
  _queryTries = 0

  /**
   * No of tries for tracking
   * @type {number}
   * @private
   */
  _trackTries = 0

  constructor () {
    this._api = new TrackerAPI()
  }

  /**
   * Initiating metric instance
   * @param metric
   */
  initiate (metric) {
    this._metric = metric
  }

  /**
   * Retrieving records for the given criteria
   * @param {moment.Moment} fromObj
   * @param {moment.Moment} toObj
   * @returns {Promise<unknown>}
   */
  query (fromObj = null, toObj = null) {
    let from = null
    let to = null

    if (fromObj) {
      from = fromObj.format('YYYY-MM-DDTHH:mm:ssZ')
    }
    if (toObj) {
      to = toObj.format('YYYY-MM-DDTHH:mm:ssZ')
    }
    return new Promise(async (resolve, reject) => {
      // querying records
      let queryResponse = null

      await this._api.getTrackers(this._metric)
        .then(async (response) => {
          const recordResponse = await this._api.getTrackerRecords({metric: this._metric, to, from})

          let results = recordResponse.data.data
          if (results) {
            this._queryTries = 0
            resolve(results)
          } else {
            queryResponse = response
          }
        }).catch((error) => {
          this._queryTries = 0
          reject(error)
        })

      let error = lodash.get(queryResponse, 'data.errors.0')

      // create tracker if not created
      if (error && error.extensions.code === 'NOT_FOUND' && this._queryTries < 1) {
        this._api.createTracker(this._metric)
          .then((response) => {
            // checking created tracker
            let trackerName = lodash.get(response, 'data.data.accountCreateTracker.metric')
            if (trackerName) {
              this._queryTries++
              resolve(this.query(from, to))
            } else {
              // tracker not created
              this._queryTries = 0
              reject(response)
            }
          })
          .catch((error) => {
            this._queryTries = 0
            reject(error)
          })
      } else {
        this._queryTries = 0
        reject(queryResponse)
      }
    })
  }

  /**
   * Creating a new record
   * @param value
   * @param comment
   * @returns {Promise<unknown>}
   */
  track (value, comment) {
    const payload = {metric: this._metric, value}
    if (comment) {
      payload.comment = comment
    }

    return new Promise(async (resolve, reject) => {
      await this._api.saveRecords(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          this._trackTries = 0
          reject(error)
        })
    })
  }

  /**
   * Updating an existing record
   * @param id
   * @param value
   * @param comment
   * @returns {Promise<unknown>}
   */
  update (id, value, comment) {
    const payload = {metric: this._metric, id, value}
    if (comment) {
      payload.comment = comment
    }
    return new Promise((resolve, reject) => {
      this._api.updateTrackerRecords(id, payload)
        .then((response) => {
          // checking updated entry ID
          let updatedId = response.data.data.id
          if (updatedId) {
            resolve(response)
          } else {
            reject(response)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * Delete an existing record
   * @param id
   * @returns {Promise<unknown>}
   */
  delete (id) {
    return new Promise((resolve, reject) => {
      this._api.deleteTrackerRecords(id)
        .then((response) => {
          let errorResponse = lodash.get(response, 'data.errors')
          if (!errorResponse) {
            resolve(response)
          } else {
            reject(response)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
