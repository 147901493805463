<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
    <path d="M11.2624 19.4963L0.513102 8.93597C-0.171036 8.26421 -0.171036 7.17506 0.513102 6.50362C1.19663 5.83213 2.30524 5.83213 2.98872 6.50362L12.5002 15.8479L22.0113 6.50389C22.6951 5.8324 23.8036 5.8324 24.4871 6.50389C25.171 7.17538 25.171 8.26448 24.4871 8.93624L13.7377 19.4966C13.3958 19.8323 12.9481 20 12.5002 20C12.0521 20 11.6041 19.832 11.2624 19.4963Z" fill="#9E9E9E"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="25" height="25" fill="white" transform="translate(25) rotate(90)"/>
    </clipPath>
    </defs>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconDownArrow',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon--down-arrow': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
