<template>
  <div>
    <component v-bind:is="dynamicComponent" @purchaseClick="purchaseClick"></component>
  </div>
</template>

<script>
import Purchase from './Purchase'
import AccountDetails from './AccountDetails'
export default {
  name: 'Main',
  data () {
    return {
      response: '',
      dynamicComponent: '',
      components: {
        purchase: Purchase,
        account: AccountDetails
      }
    }
  },
  mounted () {
    this.dynamicComponent = this.components.purchase
    localStorage.removeItem('lastSessionRoute')
  },
  methods: {
    purchaseClick () {
      this.dynamicComponent = this.components.account
    }
  }
}
</script>

<style scoped>
</style>
