import WorkoutApi from './api/workout/WorkoutApi'
import { getVimeoIdFromUrl } from '../helpers/url'
import { getVimeoImage } from '../helpers/vimeo'

export default class WorkoutProgramExerciseService {
  id = 0
  name = ''
  programID = 0
  weekID = 0
  dayID = 0
  isDetailsLoaded = false
  suggestedExerciseSwapDetails = null
  setType = null
  /**
   * sets Reps
   * eg - `1 1 1`, `12 10 8`
   * @type {string}
   */
  setReps = ''
  tempo = ''

  exerciseTime = null
  restTime = null
  exerciseTimeInterval = null
  restTimeInterval = null

  instructions = '[]'

  video = []
  vimeoImage = null
  images = []

  preferredMuscle = ''
  muscles = []
  injuries = []

  workoutLog = null
  completed = false
  isSwappable = true

  constructor (exercise) {
    this.id = exercise.id
    this.name = exercise.name
    this.programID = exercise.programID
    this.weekID = exercise.weekID
    this.dayID = exercise.dayID

    this.completed = exercise.completed

    // TODO: remove the if condition after API is deployed fully
    if (typeof exercise.can_swap !== 'undefined') {
      this.isSwappable = exercise.can_swap
    }

    this.images = exercise.images
    this.setReps = exercise.set_reps
    if (typeof this.setReps === 'string') {
      this.setReps = this.setReps.trim()
    }
    this.setType = exercise.set_type
    this.videos = exercise.videos

    this.preferredMuscle = exercise.preferred_muscle || ''
    this.muscles = exercise.muscles || []
  }

  loadExerciseData () {
    let api = new WorkoutApi()
    return api.getExerciseDetails(this.programID, this.id).then((data) => {
      return data.data
    })
  }

  setExerciseData () {
    return new Promise((resolve, reject) => {
      this.loadExerciseData().then(data => {
        this.name = data.name
        this.images = data.images
        this.workoutLog = data.workout_log
        this.tempo = data.tempo
        this.exerciseTime = data.exercise_time_value
        this.preferredMuscle = data.preferred_muscle || ''
        this.restTime = data.exercise_rest_time
        this.exerciseTimeInterval = data.exercise_time_interval
        this.restTimeInterval = data.exercise_rest_interval
        this.instructions = data.instructions
        this.injuries = data.injuries
        if (data.videos) {
          this.videos = data.videos
        }
        // TODO: remove the if condition after API is deployed fully
        if (typeof data.can_swap !== 'undefined') {
          this.isSwappable = data.can_swap
        }
        this.setType = data.set_id
        this.isDetailsLoaded = true
        resolve(this)
      }).catch(() => {
        reject(new Error('exercise not loaded ID:' + this.id))
      })
    })
  }

  hasVideoUrl () {
    return this.videos && this.videos[0] && this.videos[0].url
  }

  getVideoUrl () {
    if (this.hasVideoUrl()) {
      return this.videos[0].url
    }
    return null
  }

  loadVideoImageFromVideoUrl () {
    return new Promise((resolve, reject) => {
      if (!this.hasVideoUrl()) {
        resolve(null)
      } else {
        let vimeoID = getVimeoIdFromUrl(this.getVideoUrl())

        if (vimeoID) {
          const vimeoUrl = new URL(this.getVideoUrl())
          const urlHash = vimeoUrl.searchParams.get('h') || null
          getVimeoImage(vimeoID, urlHash).then(url => {
            this.vimeoImage = url
            resolve(url)
          }).catch(() => {
            resolve(null)
          })
        } else {
          resolve(null)
        }
      }
    })
  }

  getImage () {
    if (this.images && this.images[0]) {
      return this.images[0].url.trim()
    } else if (this.vimeoImage) {
      return this.vimeoImage
    }
    return null
  }

  hasImage () {
    if (this.images && this.images[0]) {
      return !!this.images[0].url
    }
    return false
  }

  /**
   * can exercise swappable directly without request
   * @returns {boolean}
   */
  canSwap () {
    return this.isSwappable
  }

  getSetsRepsString () {
    if (this.setReps) {
      return this.setReps.replace(/ /g, '/')
    }
    return ''
  }

  /**
   * @returns {string[]|*[]}
   */
  getSetsReps () {
    if (this.setReps) {
      return this.setReps.split(' ')
    }
    return []
  }

  getSetsCount () {
    if (this.setReps) {
      return this.setReps.split(' ').length
    }
    return 1
  }

  getPreferredMuscle () {
    return this.preferredMuscle
  }

  getMuscles () {
    return this.muscles
  }

  getID () {
    return this.id
  }

  getName () {
    return this.name
  }

  getIsCompleted () {
    return this.completed
  }

  getMusclesCount () {
    return this.muscles.length
  }

  getInjuries () {
    return this.injuries || []
  }

  getTempo () {
    return this.tempo
  }

  getProgramID () {
    return this.programID
  }

  getExerciseTime () {
    return parseInt(this.exerciseTime) || 0
  }

  getRestTime () {
    return parseInt(this.restTime) || 0
  }

  getExerciseTimeInterval () {
    return this.exerciseTimeInterval
  }

  getRestTimeInterval () {
    return this.restTimeInterval
  }

  getInstructions () {
    if (Array.isArray(this.instructions)) {
      return this.instructions
    }
    return JSON.parse(this.instructions) || []
  }

  getWorkoutLogMetrics () {
    if (this.workoutLog && this.workoutLog.metrics) {
      return this.workoutLog.metrics
    }
    return []
  }

  getWorkoutLogData () {
    return this.workoutLog
  }

  getSetType () {
    return this.setType
  }

  getWeekID () {
    return this.weekID
  }

  getDayID () {
    return this.dayID
  }

  setExerciseCompleted () {
    this.completed = true
  }

  setExerciseInComplete () {
    this.completed = false
  }

  getSuggestedExerciseSwapDetails () {
    return new Promise((resolve, reject) => {
      if (this.suggestedExerciseSwapDetails) {
        resolve(this.suggestedExerciseSwapDetails)
        return
      }

      const workoutApi = new WorkoutApi()
      workoutApi.getSuggestedExerciseSwapDetails(this.programID, this.id).then(res => res.data).then(data => {
        this.suggestedExerciseSwapDetails = data
        resolve(this.suggestedExerciseSwapDetails)
      }).catch(() => {
        reject(new Error('unable to load swap data'))
      })
    })
  }

  swapForAlternativeExercise (genericExerciseID) {
    return new Promise((resolve, reject) => {
      const workoutApi = new WorkoutApi()
      workoutApi.swapForAlternativeExercise(this.getProgramID(), this.getID(), genericExerciseID).then(() => {
        this.videos = []
        this.images = []
        this.vimeoImage = null
        this.setExerciseData().then().then(() => {
          // success
        }).finally(() => {
          resolve()
        })
      }).catch(err => {
        reject(err)
      })
    })
  }

  submitSwapExercise (type) {
    return new Promise((resolve, reject) => {
      const workoutApi = new WorkoutApi()
      workoutApi.swapExercise(this.getProgramID(), this.getID(), type).then(function (data) {
        return resolve()
      }).catch(err => {
        reject(err)
      })
    })
  }

  submitSwapInjuryRequestExercise (injuries, message) {
    return new Promise((resolve, reject) => {
      const workoutApi = new WorkoutApi()

      workoutApi.swapInjuryRequestExercise(this.getProgramID(), this.getID(), injuries, message, 'injury').then(function (data) {
        return resolve()
      }).catch(err => {
        reject(err)
      })
    })
  }

  isSetHavingFailureSetOrRepRanges () {
    if (this.setReps === null) {
      return false
    }

    if (this.setReps.toLowerCase().includes('f') || this.setReps.includes('-')) {
      return true
    }

    return false
  }
}
