<template>
  <card-content>
    <div :class="componentClasses">
      <div class="gc-card-habits-list__wrapper">
        <div class="gc-card-habits-list__header-wrapper"  @click="clickForward()">
          <div class="gc-card-habits-list__header-wrapper-left-section">
            <div class="gc-card-habits-list__header-text">
              <text-content
                :size="'sm4'"
                :weight="'extra-bold'"
                :line-height="'multi'"

              >
                {{ $t('message["habit-tracker.header"]') }}
              </text-content>
              <div @click.stop="clickedInfo()">
                <icon-info
                  class="gc-card-habits-list__info-icon"
                  :size="'sm4'"
                />
              </div>

            </div>
            <div class="gc-card-habits-list__header-description">
              <text-content
                :size="'sm1'"
                :line-height="'multi'"

              >
                {{ $t('message["habit-tracker.header-description"]',{date: date}) }}
              </text-content>
            </div>
          </div>
          <div
            class="gc-card-habits-list__header-wrapper-right-section"

          >
            <icon-forward
              class="gc-card-habits-list__forword-icon"
              :size="'sm4'"

            />
          </div>
        </div>
        <div class="gc-card-habits-list__wrapper-content">
          <div
            v-for="(entry, entryIndex) in setHabitsOrder.getEntries()"
            :key="entryIndex"
            class="gc-card-habits-list__wrapper-list-item"
          >
            <div class="gc-card-habits-list__wrapper-list-title">
              <text-content
                :size="'sm2'"
                :weight="'medium'"
                :line-height="'multi'"
              >
                {{ entry.habit.getName() }}
              </text-content>
            </div>
            <div class="gc-card-habits-list__wrapper-list-icons">
              <icon-completed-filled
                v-if="entry.getLabel() === 'yes'"
                :state="setIconStateByIndex(entryIndex)"
                :size="'md1'"
                @click="clicked(entry, 'yes', entryIndex)"
              />
              <icon-completed-linear
                v-else-if="entry.getLabel() !== 'yes'"
                :state="setIconStateByIndex(entryIndex)"
                :size="'md1'"
                @click="clicked(entry, 'yes', entryIndex)"
              />
              <icon-incompleted-filled
                v-if="entry.getLabel() === 'no'"
                :state="setIconStateByIndex(entryIndex)"
                :size="'md1'"
                @click="clicked(entry, 'no', entryIndex)"
              />
              <icon-incompleted-linear
                v-else-if="entry.getLabel() !== 'no'"
                :state="setIconStateByIndex(entryIndex)"
                :size="'md1'"
                @click="clicked(entry, 'no', entryIndex)"
              />
              <icon-arrow-circular-filled
                v-if="entry.getLabel() === 'skip'"
                :state="setIconStateByIndex(entryIndex)"
                :size="'md1'"
                @click="clicked(entry, 'skip', entryIndex)"
              />
              <icon-arrow-circular-linear
                v-else-if="entry.getLabel() !== 'skip'"
                :state="setIconStateByIndex(entryIndex)"
                :size="'md1'"
                @click="clicked(entry, 'skip', entryIndex)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </card-content>
</template>
<script>
import TextContent from '../../root/TextContent'
import HeaderSimple from '../headers/HeaderSimple'
import IconCompletedLinear from '../../root/icons/IconCompletedLinear'
import IconForward from '../../root/icons/IconForward'
import IconInfo from '../../root/icons/IconInfo'
import IconIncompletedLinear from '../../root/icons/IconIncompletedLinear'
import IconArrowCircularLinear from '../../root/icons/IconArrowCircularLinear'
import IconCompletedFilled from '../../root/icons/IconCompletedFilled'
import IconIncompletedFilled from '../../root/icons/IconIncompletedFilled'
import IconArrowCircularFilled from '../../root/icons/IconArrowCircularFilled'
import CardContent from './CardContent'
import DashBoardService from '../../../services/DashboardService'
import { mapGetters, mapMutations } from 'vuex'
import GridCol from '../grid/GridCol'
import GridRow from '../grid/GridRow'
import moment from 'moment'
export default {
  name: 'CardHabitsList',

  components: {
    GridCol,
    GridRow,
    TextContent,
    HeaderSimple,
    IconCompletedLinear,
    IconForward,
    IconInfo,
    IconIncompletedLinear,
    IconCompletedFilled,
    IconArrowCircularLinear,
    IconIncompletedFilled,
    IconArrowCircularFilled,
    CardContent
  },
  props: {
    /**
       * habit tracker habits list
       */
    habitsEntries: {
      type:Array,
      required: true,
      default: function(){
        return []
      },
    }
  },
  data () {
    return {
      habitsData: [],
      submittingEntryIndexes: []
    }
  },
  computed: {
    ...mapGetters({
      getPlanExpiredPopupShow: 'getPlanExpiredPopupShow'
    }),
    date(){
      return moment().format('ll')
    },
    componentClasses () {
      return {
        'gc-card-habits-list': true
      }
    },
    setHabitsOrder () {
      return this.habitsEntries
    }
  },
  methods: {
    ...mapMutations({
      showSubscriptionExpiredPopup: 'showSubscriptionExpiredPopup'
    }),
    clickForward () {
      this.clickHeader()
      this.logEvent('HabitTracker.OpenedTrackerCalendar', {}, false)
    },
    setIconStateByIndex (entryIndex) {
      return this.submittingEntryIndexes.includes(entryIndex) ? 'disabled' : ''
    },
    clicked (entry, status, entryIndex) {
      let dashboard = new DashBoardService()
      if (!(dashboard.isEntriesEditable())) {
        if (!(this.getPlanExpiredPopupShow)) {
          this.showSubscriptionExpiredPopup('habitTracker_select_status')
        }
      } else {
        if (this.submittingEntryIndexes.includes(entryIndex)) {
          return
        }
        this.submittingEntryIndexes.push(entryIndex)
        if (entry.getLabel() === status) {
          this.unmarkEntry(entry, entryIndex)
        } else {
          this.markEntry(entry, status, entryIndex)
        }
      }
    },
    unmarkEntry (entry, entryIndex) {
      let existedStatus = entry.getLabel()
      this.habitsEntries.deleteHabitEntry(entry.entry.id).finally(() => {
        this.submittingEntryIndexes = this.submittingEntryIndexes.filter(
          (value) => {
            return value !== entryIndex
          }
        )

        this.logEvent('HabitTracker.HabitStatusCleared', {
          habit: entry.habit.getName(),
          cleared_status: this.getLogStatusName(existedStatus),
          tracking_date: entry.getDate()
        }, false)
      })
    },
    markEntry (entry, status, entryIndex) {
      let habits = this.setHabitsOrder.getEntries()
      habits[entryIndex].entry.label = status
      entry
        .saveEntry(status)
        .catch(() => {
          habits[entryIndex].entry.label = null
        })
        .finally(() => {
          this.submittingEntryIndexes = this.submittingEntryIndexes.filter(
            (value) => {
              return value !== entryIndex
            }
          )

          this.logEvent('HabitTracker.HabitStatusChecked', {
            habit: entry.habit.getName(),
            status: this.getLogStatusName(entry.getLabel()),
            tracking_date: entry.getDate()
          }, false)
        })
    },
    getLogStatusName (raw) {
      switch (raw) {
        case 'yes':
          return 'completed'

        case 'no':
          return 'incomplete'

        default:
          return 'skipped'
      }
    },
    clickedInfo () {
      this.$emit('showInstructions')
    },
    clickHeader () {
      this.$emit('header-click')
    }
  }
}
</script>
