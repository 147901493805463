<template>
  <div class="gc-popup__section text-right">
    <button class="gc-popup__button-close" v-on="$listeners"   @click="hide()">
    <IconClose></IconClose>
    </button>
  </div>
</template>

<script>
import IconClose from '../../../root/icons/IconClose'

export default {
  name: 'PopupButtonClose',
  components: {
    IconClose
  },
  methods: {
    hide () {
      this.$emit('hide')
      this.$emit('click')
    }
  }
}
</script>

<style scoped>

</style>
