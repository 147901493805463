<template>
  <div :class="componentClasses" @click="clicked()">
    <text-content :weight="'extra-bold'" :line-height="'multi'" :size="'sm4'">
      {{ headerText }}
    </text-content>
    <div class="gc-header-simple__icon-wrapper">
      <icon-paper-plane style="padding-left: 20px; padding-right: 20px" v-if="showSendIcon" @click="sendIconClicked()"></icon-paper-plane>
      <icon-info-bulb v-if="icon === 'bulb' && showIcon" @click="iconClicked()" />
      <icon-info-bulb :size="'sm3'" v-else-if="icon === 'bulb-small' && showIcon" @click="iconClicked()" />
      <icon-forward
              :size="'md1'"
              v-else-if="icon === 'forward' && showIcon"
              @click="iconClicked()"
      />
      <icon-forward :size="'sm4'"
                    v-else-if="icon === 'forward-small' && showIcon"
                    @click="iconClicked()"
      />
    </div>

  </div>
</template>

<script>
import TextContent from '../../root/TextContent'
import IconInfoBulb from '../../root/icons/IconInfoBulb'
import IconForward from '../../root/icons/IconForward'
import IconPaperPlane from '../../root/icons/IconPaperPlane'

export default {
  name: 'HeaderSimple',
  components: {
    IconPaperPlane,
    IconInfoBulb,
    IconForward,
    TextContent
  },
  props: {
    headerText: {
      default: ''
    },
    showSendIcon: {
      default: false
    },
    showIcon: {
      default: true
    },
    icon: {
      default: '' // 'bulb','forward'
    }
  },
  computed: {

    componentClasses: function () {
      return {
        'gc-header-simple': true
      }
    }
  },
  methods: {
    sendIconClicked () {
      this.$emit('print-icon-click')
    },
    iconClicked () {
      this.$emit('icon-click')
    },
    clicked () {
      this.$emit('click')
    }
  }
}
</script>

<style scoped></style>
