<template>
  <div :class="componentClasses" v-on="$listeners">
     <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.5 3.18C11.3674 3.18 11.2402 3.12732 11.1464 3.03355C11.0527 2.93979 11 2.81261 11 2.68L11 2C11 1.73478 10.8946 1.48043 10.7071 1.29289C10.5196 1.10536 10.2652 1 10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289C9.10536 1.48043 9 1.73478 9 2L9 2.68C9 2.81261 8.94732 2.93979 8.85355 3.03355C8.75979 3.12732 8.63261 3.18 8.5 3.18C8.36739 3.18 8.24021 3.12732 8.14645 3.03355C8.05268 2.93979 8 2.81261 8 2.68L8 2C8 1.46957 8.21071 0.960859 8.58579 0.585786C8.96086 0.210714 9.46957 -1.28474e-07 10 -1.74846e-07C10.5304 -2.21217e-07 11.0391 0.210713 11.4142 0.585786C11.7893 0.960859 12 1.46957 12 2L12 2.68C12 2.81261 11.9473 2.93979 11.8536 3.03355C11.7598 3.12732 11.6326 3.18 11.5 3.18Z" fill="white"/>
      <path d="M18.1667 19.0004L1.8332 19.0004C1.44841 19.0004 1.07338 18.8793 0.761229 18.6543C0.449083 18.4293 0.215654 18.1117 0.0940119 17.7467C-0.0276298 17.3816 -0.0313156 16.9876 0.0834766 16.6203C0.198269 16.253 0.425718 15.9312 0.733601 15.7004L1.5571 15.0827C1.86702 14.8511 2.07495 14.5083 2.1371 14.1264L3.1703 7.85442C3.42256 6.22356 4.25008 4.7368 5.50322 3.66303C6.75635 2.58925 8.35236 1.99935 10.0026 2C11.6529 2.00065 13.2484 2.59182 14.5007 3.66658C15.753 4.74135 16.5793 6.22876 16.8303 7.85982L17.8628 14.1264C17.925 14.5083 18.1329 14.8511 18.4428 15.0827L19.2665 15.7004C19.5745 15.9312 19.802 16.253 19.9168 16.6204C20.0316 16.9877 20.0279 17.3818 19.9062 17.7469C19.7845 18.112 19.551 18.4295 19.2387 18.6545C18.9265 18.8795 18.5515 19.0005 18.1667 19.0004Z" fill="white"/>
      <path d="M9.99995 22.0004C9.07201 21.9993 8.18237 21.6303 7.52622 20.9741C6.87006 20.3179 6.50098 19.4283 6.49995 18.5004C6.49995 18.3678 6.55263 18.2406 6.6464 18.1468C6.74017 18.053 6.86734 18.0004 6.99995 18.0004C7.13256 18.0004 7.25974 18.053 7.35351 18.1468C7.44727 18.2406 7.49995 18.3678 7.49995 18.5004C7.49995 19.1634 7.76334 19.7993 8.23219 20.2681C8.70103 20.737 9.33691 21.0004 9.99995 21.0004C10.663 21.0004 11.2989 20.737 11.7677 20.2681C12.2366 19.7993 12.5 19.1634 12.5 18.5004C12.5 18.3678 12.5526 18.2406 12.6464 18.1468C12.7402 18.053 12.8673 18.0004 13 18.0004C13.1326 18.0004 13.2597 18.053 13.3535 18.1468C13.4473 18.2406 13.5 18.3678 13.5 18.5004C13.4989 19.4283 13.1298 20.3179 12.4737 20.9741C11.8175 21.6303 10.9279 21.9993 9.99995 22.0004Z" fill="white"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconBellFilled',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-bell-filled': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
