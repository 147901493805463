import BaseAPI from '../BaseAPI.js'
import axios from 'axios'
export default class extends BaseAPI {
  _resource ='nutrition'
  _resourceMe ='me'

  getMeal (id) {
    const url = this._baseURL
    const pathArr = [this._resource, 'catalog', 'meals', id]
    return axios.get(url + this._getPath(pathArr))
  }
}
