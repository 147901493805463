import BannersAPI from './api/common/BannersAPI'
import bannerConfig from '../config/banner-config'
export default class BannerService {
  banners =null

  getBanners () {
    return new Promise((resolve, reject) => {
      if (this.banners === null) {
        const service = new BannersAPI()
        service.getList().then(data => {
          this.banners = data.data.banners
          resolve(this.banners)
        }).catch(err => {
          reject(err.response)
        })
      } else {
        resolve(this.banners)
      }
    })
  }

  checkBannerToShow (bannerIndex, pageName) {
    bannerIndex = parseInt(bannerIndex)
    if (pageName && Array.isArray(this.banners) && this.banners.length > bannerIndex && bannerIndex > (-1)) {
      const filtered = bannerConfig.banners.filter(area => {
        return this.banners[bannerIndex].active_pages.some(page => {
          return page.code === area.code
        })
      })
      let isFound = filtered.some(area => {
        return area.pages.includes(pageName)
      })
      if (!isFound) {
        isFound = this.banners[bannerIndex].active_pages.some(page => { return page.code === pageName })
      }
      return isFound
    }
    return false
  }
}
