<template>
  <div :class="componentClasses">
    <div @click="reduceValue" :class="leftButtonClasses">
      <icon-decrease :size="'md2'"/>
    </div>
    <div class="gc-input-number-spinner__input-wrap">
      <input
        class="form-control ma-form__input gc-input-number-spinner__input" inputmode="numeric"
        :max="maxValue"
        :min="minValue"
        maxlength="2"
        :step="step"
        type="tel"
        pattern="[0-9]*"
        v-bind:class="inputClasses"
        v-model="comValue"
        @focus="handleInputState()"
        @blur="handleInputState()"
        @change="changed()"
      />
      <span v-if="isPercentage" :class="percentageClasses">
          %
      </span>
    </div>
    <div @click="addValue" :class="rightButtonClasses">
      <icon-add-fill-rounded :size="'md2'" />
    </div>
    <br/>
  </div>
</template>

<script>
import IconDecrease from '../../root/icons/IconDecrease'
import IconIncrease from '../../root/icons/IconIncrease'
import TextContent from '../../root/TextContent'
import IconAddFillRounded from '../../root/icons/IconAddRounded'
export default {
  name: 'InputNumberSpinner',
  components: {
    IconDecrease,
    IconIncrease,
    TextContent,
    IconAddFillRounded
  },
  props: {
    minValue: {
      type: Number,
      default: 0
    },
    maxValue: {
      type: Number,
      default: 100
    },
    isValid: {type: Boolean},
    value: {type: Number},
    step: {type: Number},
    isPercentage: {default: false},
    isDisabled: {default: false}
  },
  computed: {
    componentClasses () {
      return {
        'gc-input-number-spinner': true
      }
    },
    inputClasses () {
      return {
        'gc-input-number-spinner__input--typed': this.isTyped,
        'gc-input-number-spinner__input--error': (!this.isValid && this.isValueChanged),
        'gc-input-number-spinner__input--disabled': this.isDisabled
      }
    },

    leftButtonClasses () {
      return {
        'gc-input-number-spinner__left-btn btn': true,
        'gc-input-number-spinner__btn--disabled': this.isDisabled
      }
    },

    rightButtonClasses () {
      return {
        'gc-input-number-spinner__right-btn btn': true,
        'gc-input-number-spinner__btn--disabled': this.isDisabled
      }
    },

    percentageClasses () {
      return {
        'gc-input-number-spinner__input-percentage': true,
        'gc-input-number-spinner__input-percentage--active': this.isActive || this.isTyped || !this.isValid
      }
    }
  },
  data: function () {
    return {
      imageUrl: this.$appConfig.imagesUrl,
      comValue: 0,
      isValueChanged: false,
      isTyped: false,
      isActive: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function () {
        if (this.value >= this.minValue && this.value <= this.maxValue) {
          this.comValue = this.value
        }
      }
    },
    comValue: function (val) {
      this.isValueChanged = true
      val = parseInt(val) || 0
      this.comValue = val
      this.$emit('input', val)
    }
  },
  methods: {
    changed () {
      this.$nextTick(() => {
        this.$emit('change', this.comValue)
      })
    },
    addValue () {
      this.isTyped = true
      this.comValue = parseInt(this.comValue) || 0
      if (this.comValue <= this.maxValue) {
        this.comValue += this.step
      }
      if (this.comValue > this.maxValue) {
        this.comValue = this.maxValue
      }
      this.changed()
    },
    reduceValue () {
      this.isTyped = true
      this.comValue = parseInt(this.comValue) || 0
      if (this.comValue >= this.minValue) {
        this.comValue -= this.step
      }
      if (this.comValue < this.minValue) {
        this.comValue = this.minValue
      }
      this.changed()
    },

    handleInputState () {
      this.isActive = !this.isActive
    }
  }
}
</script>
