<template>
 <!-- for desktop view -->
  <div class="gc-banner-image"  v-on="$listeners">
    <div class="pc-dashboard__main-featured-area__content">
      <div class="gc-banner-image__image">
        <div class="gc-banner-image__image--blur-warp">
            <image-base :src="src " class="gc-banner-image__image--blur"  />
        </div>
            <image-base  :src="src" class="gc-banner-image__image-on-top"  />
       <div class="gc-banner-image__content">
        <slot>
        </slot>
       </div>

      </div>

    </div>
  </div>
</template>
<script>

import ImageBase from '../../root/Image'

export default {
  name: 'BannerImage',
  components: {
    ImageBase
  },
  props: {
    src: {}
  }
}
</script>
