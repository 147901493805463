<template>
  <div>
    <img
      class="temp-image" style="width: 100%;    height: 100vh;    object-fit: cover;    object-position: top center;"
      :src="this.$appConfig.imagesUrl + 'fitness-diary/coming-soon.jpg'"
    />
  </div>
</template>

<script>
import BaseComponent from '../global/base/BaseComponent'
import '../../../../helpers/validation'

export default {
  name: 'ComingSoon',
  extends: BaseComponent,
  data: function () {
    return {
      page: 'contact',
      pageClass: ['macroapp--page-notification', 'macroapp--page-my-journey--coming-soon'],
      error: '',
      headerOptions: {
        show: true,
        left: 'menu',
        right: '',
        rightDisabled: true,
        helpContent: '',
        modifiers: ['with-bg-color'],
        formData: {
          image: ''
        },
        header: {
          mainTitle: 'My Journey',
          subTitle: ' '
        }
      }
    }
  },
  mounted () {
    this.setHeader(this.headerOptions)
    this.setPageClass()
    this.hideLoading()
  }
}
</script>

<style scoped>
</style>
