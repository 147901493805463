<template>
  <div class="footer footer--with-back" :class="footerWrapperClass">
    <button :class="footerBtnClass" class="footer__btn-main" type="submit">{{buttonText}}</button>
    <span class="footer__link" @click="delayedGoBack">{{$t('message["general.form-go-back"]')}}</span>
  </div>
</template>
<script>
export default {
  name: 'FormFooter',
  props: {
    validated: {
      default: false
    },
    alwaysFixed: {
      default: false
    },
    fixed: {
      default: true
    },
    text: {
      default: ''
    }
  },
  data: function () {
    return {
      footerWrapperClass: 'fixed-bottom'
    }
  },
  computed: {
    buttonText () {
      return this.text || this.$i18n.t('message["general.next"]')
    },
    footerBtnClass () {
      let className = ''
      if (this.validated) {
        className = 'btn btn-primary btn-main'
      } else {
        className = 'btn btn-main btn-main--disabled'
      }
      return className
    }
  },
  mounted () {
    if (!this.alwaysFixed) {
      window.addEventListener('resize', this.setFooterClass)
      this.setFooterClass()
    }
  },
  methods: {
    setFooterClass () {
      if (window.innerHeight - 154 > $('.macroapp').height()) {
        if (this.fixed) {
          this.footerWrapperClass = 'fixed-bottom'
        } else {
          this.footerWrapperClass = 'custom-footer-padding'
        }
      } else {
        this.footerWrapperClass = ' custom-footer-padding'
      }
    }
  }
}
</script>
