<template>
    <iframe :src="page"   @load="iframeLoaded()" frameborder="0" style=" width:100%; height:100vh;"></iframe>
</template>

<script>
import BaseComponent from '../global/base/BaseComponent'

export default {
  name: 'Page',
  extends: BaseComponent,
  data: function () {
    return {
      page: this.$route.query.page,
      pageClass: ['macroapp--page-notification', 'macroapp--page-contact-form'],
      headerOptions: {
        show: true,
        left: 'previous',
        right: '',
        rightDisabled: true,
        helpContent: '',
        modifiers: ['with-bg-color'],
        formData: {
          image: ''
        },
        header: {
          mainTitle: '',
          subTitle: ' '
        }
      }
    }
  },
  mounted () {
    this.setHeader(this.headerOptions)
    setTimeout(() => {
      this.hideLoading()
    }, 10000)
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
  },
  methods: {
    iframeLoaded () {
      this.hideLoading()
    }
  }

}
</script>

<style scoped>

</style>
