<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.7661 9.73654L21.766 9.73652L4.3764 1.10282C4.37637 1.10281 4.37635 1.1028 4.37633 1.10279C3.15347 0.494969 1.68479 1.02105 1.09493 2.27026L1.09493 2.27026C0.794564 2.90644 0.769355 3.64271 1.02541 4.29881C1.02542 4.29882 1.02542 4.29884 1.02543 4.29885L4.02803 11.9992L1.02541 19.7003L1.02528 19.7006C0.525678 20.9911 1.13886 22.4565 2.40275 22.9715C2.68855 23.088 2.99324 23.1486 3.30099 23.15H3.30169C3.67394 23.15 4.04125 23.0638 4.37619 22.8979L4.37635 22.8979L21.766 14.2618L21.766 14.2618C22.9868 13.6552 23.4934 12.1516 22.9046 10.9043C22.6644 10.3954 22.2635 9.98372 21.7661 9.73654ZM3.57377 21.208L3.57375 21.208C3.26916 21.3593 2.89989 21.2302 2.74987 20.9123L2.74986 20.9123C2.67328 20.75 2.66685 20.5618 2.73228 20.3942L2.73231 20.3941L5.63905 12.9374H20.2324L3.57377 21.208ZM3.57378 2.79271L20.2322 11.0609H5.63909L2.73236 3.60414L2.73237 3.60413L2.73153 3.60207C2.63223 3.35808 2.69449 3.07706 2.88417 2.9017L2.88425 2.90178L2.8884 2.89763C3.07111 2.71496 3.34529 2.67299 3.57084 2.79121L3.57082 2.79124L3.57378 2.79271Z" fill="black" stroke="black" stroke-width="0.3"/>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconSend',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-send': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
