<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_26425_16894)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.85905 0.517484C2.69703 0.555079 2.5259 0.686462 2.44691 0.833878L2.40039 0.920675V8.32594C2.40039 15.6326 2.40085 15.7326 2.43464 15.8341C2.4783 15.9654 2.61437 16.1662 2.72028 16.2558C2.8284 16.3471 3.00234 16.436 3.14343 16.4719C3.27824 16.5063 3.50224 16.5096 3.63983 16.4792C3.844 16.4342 3.7697 16.4832 6.75373 14.4291C7.13836 14.1643 7.663 13.8043 7.91958 13.629L8.38611 13.3103L9.76407 14.2525C10.522 14.7707 11.5236 15.4557 11.99 15.7748C12.4564 16.0938 12.8778 16.3748 12.9266 16.3991C13.1211 16.4962 13.423 16.5266 13.6428 16.4713C14.0194 16.3766 14.3144 16.0752 14.3842 15.7137C14.399 15.6374 14.4028 13.7073 14.399 8.27427L14.3939 0.937897L14.3566 0.858299C14.3089 0.756726 14.208 0.646732 14.1115 0.59102C13.9421 0.493185 14.3123 0.499023 8.38842 0.500676C5.27671 0.501554 2.89655 0.508788 2.85905 0.517484Z" fill="#FFBA00"/>
      </g>
      <defs>
        <clipPath id="clip0_26425_16894">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconSaveFilled',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-save-filled': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
