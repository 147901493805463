<template>
    <page :header="header" class="gc-page-meal-category">
      <validation-observer ref="form" :rules="'date|birthday|required'" v-slot="{ handleSubmit, invalid}">
          <page-container>
              <div class="gc-page-meal-category__header">
                  <text-heading-4
                      class="gc-page-meal-category__title text-center"
                      weight="extra-bold"
                      lineHeight="multi">{{title.main}}</text-heading-4>
              </div>
              <div class="gc-page-meal-category__content">
                  <form id="form-input-meal-category"
                      class="gc-page-meal-category__form"
                      @submit.prevent="handleSubmit(nextComponent)">
                          <validation-provider v-slot="{ errors, validated, dirty }" rules="required" vid="mealCategory" name="meal category">
                            <input-meal-category
                                class="gc-page-meal-category__input"
                                :options="options"
                                v-model="mealCategory"></input-meal-category>
                              <text-body-extra-small v-if="validated&&dirty" class="gc-page-progress-form__input-error text-center"  lineHeight="multi">{{errors[0]}}</text-body-extra-small>
                          </validation-provider>
                  </form>
              </div>
          </page-container>
          <page-footer :form="'form-input-meal-category'" :validated="invalid"></page-footer>
        </validation-observer>
    </page>
</template>

<script>
import FormBaseComponent from '../../layout/template-1/global/widgets/forms/FormBaseComponent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ButtonPrimary from '../buttons/ButtonPrimary'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import TextHeading4 from '../typography/TextHeading4'
import PageContainer from './page-sub-components/PageContainer'
import Page from './PageForm'
import ButtonChoice from '../buttons/ButtonChoice'
import InputMealCategory from '../inputs/InputMealCategory'
import PageFooter from './page-sub-components/PageFormFooter'

export default {
  name: 'PageBirthday',
  extends: FormBaseComponent,
  components: {
    Page,
    PageContainer,
    TextHeading4,
    ButtonPrimary,
    TextBodyExtraSmall,
    ValidationObserver,
    ValidationProvider,
    ButtonChoice,
    InputMealCategory,
    PageFooter
  },
  data: function () {
    return {
      header: {
        isTransparent: false,
        isFixed: false,
        show: false,
        left: '',
        right: '',
        rightBlink: false,
        modifiers: [],
        mainTitle: '',
        subTitle: ''
      },
      mealCategory: ''
    }
  },
  mounted () {
    this.mealCategory = this.inputValue
    // this.mealCategory = this.mealCategory || this.options[0].id
    if (this.mealCategory.length === 0) {
      this.$nextTick(() => {
        this.mealCategory = this.options[0].id
      })
    }
    if (typeof this.errors[this.inputKey] !== 'undefined') {
      this.$refs.form.setErrors({ 'mealCategory': this.errors[this.inputKey] })
    }
    this.hideLoading()
  },
  methods: {
    nextComponent () {
      this.showLoading()
      const self = this
      // holding router for 0.5 seconds
      setTimeout(() => {
        const inputObj = { key: this.inputKey, value: this.mealCategory }
        self.$emit('submit', inputObj)
      }, 500)
    }
  }
}
</script>
