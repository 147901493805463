import ProfileApi from '@/services/api/profile/ProfileApi'
import DeviceAPI from '@/services/api/creator/DeviceAPI'
import { checkIsCreator, checkIsMember } from '@/helpers/user'

export default class PushNotificationsService {


  registerDevice(subscriptionID){
    console.log(`registering: ${subscriptionID}`)
    const payload ={
      subscription_id : subscriptionID
    }
    const savedSubId =  localStorage.getItem('pushNotificationSubscriptionId')
    if(savedSubId === subscriptionID){
      return
    }
    if(checkIsMember()){
      return this.registerMemberDevice(payload)
    }else if(checkIsCreator()) {
      return this.registerCreatorDevice(payload)
    }

    return null
  }

  registerMemberDevice (payload) {
    const profile = new ProfileApi()
    return profile.addPushNotificationDevices(payload).then(res => {
      localStorage.setItem('pushNotificationSubscriptionId', payload.subscription_id)
      return res.data
    })
  }
  registerCreatorDevice (payload) {
    const profile = new DeviceAPI()
    return profile.post(payload).then(res => {
      localStorage.setItem('pushNotificationSubscriptionId', payload.subscription_id)
      return res.data
    })
  }

}
