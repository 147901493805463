<template>
  <div :class="componentClasses" v-on="$listeners">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.1668 19.9999L3.83326 19.9999C3.44847 19.9999 3.07343 19.8788 2.76129 19.6538C2.44914 19.4288 2.21571 19.1113 2.09407 18.7462C1.97243 18.3811 1.96874 17.9871 2.08353 17.6198C2.19833 17.2525 2.42578 16.9307 2.73366 16.6999L3.55716 16.0822C3.86707 15.8506 4.07501 15.5078 4.13716 15.1259L5.17036 8.85393C5.42262 7.22307 6.25014 5.73631 7.50328 4.66254C8.75641 3.58876 10.3524 2.99886 12.0027 2.99951C13.6529 3.00017 15.2485 3.59133 16.5008 4.6661C17.753 5.74086 18.5794 7.22827 18.8304 8.85933L19.8629 15.1259C19.925 15.5078 20.1329 15.8506 20.4429 16.0822L21.2666 16.6999C21.5745 16.9307 21.802 17.2526 21.9168 17.6199C22.0316 17.9872 22.0279 18.3813 21.9062 18.7464C21.7845 19.1115 21.551 19.4291 21.2388 19.654C20.9266 19.879 20.5516 20 20.1668 19.9999ZM12.0008 3.99988C10.587 3.99302 9.21821 4.49585 8.14511 5.41623C7.07201 6.3366 6.36653 7.61287 6.15796 9.01113L5.12401 15.2884C5.02027 15.9248 4.67371 16.496 4.15726 16.882L3.33351 17.4999C3.19354 17.6048 3.09013 17.7511 3.03795 17.9181C2.98576 18.085 2.98745 18.2642 3.04277 18.4301C3.09808 18.5961 3.20423 18.7404 3.34615 18.8427C3.48807 18.9449 3.65858 18.9999 3.83351 18.9999L20.167 18.9999C20.3419 18.9999 20.5123 18.9449 20.6542 18.8426C20.7961 18.7404 20.9022 18.5961 20.9575 18.4302C21.0128 18.2643 21.0145 18.0852 20.9624 17.9183C20.9102 17.7514 20.8069 17.6051 20.667 17.5001L19.843 16.882C19.3266 16.496 18.98 15.9248 18.8763 15.2884L17.8428 9.01648C17.6352 7.61745 16.9303 6.34012 15.8573 5.41871C14.7843 4.4973 13.4151 3.99357 12.0008 3.99988Z" fill="white"/>
    <path d="M13.5 4.18C13.3674 4.18 13.2402 4.12732 13.1464 4.03355C13.0527 3.93979 13 3.81261 13 3.68L13 3C13 2.73478 12.8946 2.48043 12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2C11.7348 2 11.4804 2.10536 11.2929 2.29289C11.1054 2.48043 11 2.73478 11 3L11 3.68C11 3.81261 10.9473 3.93979 10.8536 4.03355C10.7598 4.12732 10.6326 4.18 10.5 4.18C10.3674 4.18 10.2402 4.12732 10.1464 4.03355C10.0527 3.93979 10 3.81261 10 3.68L10 3C10 2.46957 10.2107 1.96086 10.5858 1.58579C10.9609 1.21071 11.4696 1 12 1C12.5304 1 13.0391 1.21071 13.4142 1.58579C13.7893 1.96086 14 2.46957 14 3L14 3.68C14 3.81261 13.9473 3.93979 13.8536 4.03355C13.7598 4.12732 13.6326 4.18 13.5 4.18Z" fill="white"/>
    <path d="M12 23C11.0721 22.999 10.1824 22.6299 9.52627 21.9737C8.87011 21.3176 8.50103 20.4279 8.5 19.5C8.5 19.3674 8.55268 19.2402 8.64645 19.1464C8.74021 19.0527 8.86739 19 9 19C9.13261 19 9.25979 19.0527 9.35355 19.1464C9.44732 19.2402 9.5 19.3674 9.5 19.5C9.5 20.163 9.76339 20.7989 10.2322 21.2678C10.7011 21.7366 11.337 22 12 22C12.663 22 13.2989 21.7366 13.7678 21.2678C14.2366 20.7989 14.5 20.163 14.5 19.5C14.5 19.3674 14.5527 19.2402 14.6464 19.1464C14.7402 19.0527 14.8674 19 15 19C15.1326 19 15.2598 19.0527 15.3536 19.1464C15.4473 19.2402 15.5 19.3674 15.5 19.5C15.499 20.4279 15.1299 21.3176 14.4737 21.9737C13.8176 22.6299 12.9279 22.999 12 23Z" fill="white"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconBell',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-bell': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
