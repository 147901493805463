<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.5 0H2.49999C1.11996 0.00164062 0.00164062 1.11996 0 2.49999V11.5C0.00164062 12.88 1.11996 13.9983 2.49999 14H11.5C12.88 13.9983 13.9983 12.88 14 11.5V2.49999C13.9983 1.11996 12.88 0.00164062 11.5 0ZM13 11.5C13 12.3284 12.3284 13 11.5 13H2.49999C1.67156 13 0.999989 12.3284 0.999989 11.5V2.49999C0.999989 1.67156 1.67156 0.999989 2.49999 0.999989H11.5C12.3284 0.999989 13 1.67156 13 2.49999V11.5Z" fill="black"/>
      <path d="M9.50001 6.50002H7.50001V4.50001C7.50001 4.22386 7.27615 4 7 4C6.72385 4 6.49999 4.22386 6.49999 4.50001V6.50002H4.50001C4.22386 6.50002 4 6.72387 4 7.00003C4 7.27618 4.22386 7.50001 4.50001 7.50001H6.50002V9.50001C6.50002 9.77617 6.72387 10 7.00003 10C7.27618 10 7.50001 9.77614 7.50001 9.50001V7.50001H9.50001C9.77617 7.50001 10 7.27615 10 7C10 6.72385 9.77614 6.50002 9.50001 6.50002Z" fill="black"/>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconIosAddToHome',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-ios-add-to-home': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped>

</style>
