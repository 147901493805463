<template>
  <page-secondary
    class="pc-dashboard"
    :header="headerOptions"
  >
    <page-container-fluid
      breakpoint="lg"
      :reverse="true"
    >
      <grid-row no-gutters>
        <grid-col lg="12">
          <image-featured
            :has-notifications="false"
            :page-header="''"
          />
        </grid-col>
        <page-container class="pc-creator-dashboard__container">
          <div>
            <button-primary-forward
              v-for="community in communitiesLinks"
              :key="'link-community-' + community.id"
              :text="community.name"
              @click="goToCommunity(community.id)"
            />
            <button-primary-forward
              v-if="showPageCreatorAnalyticsLink"
              :text="'Analytics'"
              @click="gotoAnalytics()"
            />
          </div>
          <div>
            <button-primary-forward
              v-if="evergreenProgressEnabled && isCreator"
              class="pc-dashboard__transformation-btn"
              :text="'Transfomations'"
              @click="goToTransfomations()"
            />
          </div>
        </page-container>
      </grid-row>
      <container-chat class="pc-dashboard__container-chat"/>
    </page-container-fluid>
  </page-secondary>
</template>

<script>
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import PageContainerFluid from '../../../global/pages/page-sub-components/PageContainerFluid'
import GridRow from '../../../global/grid/GridRow'
import GridCol from '../../../global/grid/GridCol'
import ImageFeatured from './page-components/ImageFeatured'
import PageSecondary from '../../../global/pages/PageSecondary'
import CommunityService from '../../../../services/community/CommunityService'
import ButtonPrimaryForward from '../../../global/buttons/ButtonPrimaryForward'
import ContainerChat from '../../../global/containers/ContainerChat'
import { mapActions } from 'vuex'
import { isMobileNativeApp } from '@/includes/NativeAppCommon'
import NativeAppService from '@/services/NativeAppService'
import {flagValue, getCreatorAnalyticsConfig} from '@/includes/TemplateSettings'
import DashBoardService from '../../../../services/DashboardService'
import {checkIsCreator} from '@/helpers/user'

export default {
  name: 'PageCreatorDashboard',
  components: {
    ButtonPrimaryForward,
    PageSecondary,
    ImageFeatured,
    GridCol,
    GridRow,
    PageContainerFluid,
    PageContainer,
    ContainerChat
  },
  data: function () {
    return {
      isCreator: false,
      communitiesLinks: [],
      showPageCreatorAnalyticsLink: false,
      headerOptions: {
        show: false,
        left: 'back',
        right: '',
        helpContent: '',
        rightDisabled: false,
        modifiers: [],
        header: {
          mainTitle: '',
          subTitle: ''
        }
      },
      notificationsCheckTimer: null
    }
  },
  computed: {
    evergreenProgressEnabled () {
      return flagValue('enable_ever_green_member_progress', true)
    },
  },
  mounted () {
    this.isCreator = checkIsCreator()
    this.loadCommunities()
    this.setVisibilityOfCreatorAnalyticsLink()

    this.initCommunityNotificationDetails()
    // notification status will be updated every 30 seconds
    this.notificationsCheckTimer = setInterval(() => this.initCommunityNotificationDetails(), 2*60*1000)
    if(isMobileNativeApp()){
      const nativeApp = new NativeAppService()
      nativeApp.getPushDeviceStateRequest()
    }
  },
  methods: {
    goToTransfomations () {
      this.delayedRouterPush({
        path: '/dashboard?member-progress'
      })
    },
    setVisibilityOfCreatorAnalyticsLink () {
      this.showPageCreatorAnalyticsLink = !!getCreatorAnalyticsConfig()
      if (this.showPageCreatorAnalyticsLink) {
        const hasEnabledUsers = getCreatorAnalyticsConfig().user_emails || []
        if (Array.isArray(hasEnabledUsers) && hasEnabledUsers.length > 0) {
          const dashBoardService = new DashBoardService()
          dashBoardService.getUserConfig().then((config => {
            this.showPageCreatorAnalyticsLink = hasEnabledUsers.includes(config.email)
          })).catch(() => {
            this.showPageCreatorAnalyticsLink = false
          })
        }
      }
    },
    async loadCommunities () {
      let community = new CommunityService()
      await community
        .getCommunities()
        .then(async () => {
          this.communitiesLinks = await community.getActiveCommunities()
        })
        .catch((err) => {
          console.log(err)
          this.hideLoading()
        })
      this.hideLoading()
    },
    goToCommunity (communityId) {
      this.delayedRouterPush({
        path: '/dashboard?community=' + communityId
      })
    },
    gotoAnalytics () {
      this.delayedRouterPush({
        path: '/dashboard?page=creator-analytics'
      })
    }
  },
  beforeDestroy () {
  }
}
</script>
