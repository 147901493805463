<template>
    <iframe :src="videoUrl"
             :class="[iframeID]"
            allow="autoplay; fullscreen" allowfullscreen height="100%" id="iframe" mozallowfullscreen
            frameBorder="0" ref="videoPlayer" webkitallowfullscreen width="100%" title="exercise video"></iframe>
  </template>

<script>
import Player from '@vimeo/player'
import { isMobileNativeApp } from '@/includes/NativeAppCommon'


/**
   * video loader for the vimeo video
   * emit the change $event
   */
export default {
  name: 'VideoPlayerVimeoVideo',
  props: {
    videoId: {
      type: String
    },
    options: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      iframeID: '',
      isBuffering: false,
      seeked: false,
      player: '',
      duration: 0 // duration of the video in seconds,
    }
  },
  computed: {
    videoQueryParams: function () {
      const searchParams = new URLSearchParams()
      searchParams.set('controls', 0)
      if (this.options.mute) {
        searchParams.set('mute', 1)
      }
      return searchParams.toString()
    },
    videoUrl: function () {
      let url = this.options.url
      if (url.startsWith('https://player.vimeo.com/video/')) {
        if (url.search('autoplay')) {
          url = this.options.url.replace('autoplay=1', 'autoplay=0')
        }
        return url + ((url.includes('?')) ? '&' : '?') + this.videoQueryParams
      } else {
        return 'https://player.vimeo.com/video/' + this.videoId + '?' + this.videoQueryParams
      }
    }
  },
  beforeMount () {
    this.iframeID = this.generateRandomID(8)
  },
  mounted () {
    const iframe = document.querySelector('.' + this.iframeID)
    if (!iframe) {
      return
    }
    const options = {
      muted: !!this.options.mute,
      controls: false,
      responsive: true,
      autoplay: false

    }
    if (isMobileNativeApp()) {
      options.quality = '1080p'
    }
    this.player = new Player(iframe, options)
    if (this.options.mute) {
      this.setMuted()
    }

    this.player.on('qualitychange', (e) => {
      console.log(e)
    })
    this.player.on('play', (e) => {
      console.log(e)
      this.isBuffering = false
      this.$emit('playing')
    })

    this.player.on('pause', (event) => {
      console.log(e)
      if (!this.isBuffering && !this.seeked) {
        this.$emit('paused')
      }
    })

    this.player.on('seeked', (event) => {
      this.$emit('seeked')
    })

    this.player.on('error', (error) => {
      console.log(error)
      this.$emit('error', error)
    })
    this.player.on('bufferstart', (error) => {
      this.isBuffering = true
      this.$emit('bufferstart', error)
    })
    this.player.on('bufferend', (error) => {
      this.isBuffering = false
      this.$emit('bufferend', error)
    })

    this.player.on('ended', () => {
      this.$emit('ended')
    })

    this.player.on('loaded', () => {
      this.$emit('loaded')
    })

    this.player.on('seeked', () => {
      this.$emit('seeked')
    })

    this.player.on('timeupdate', (data) => {
      this.$emit('timeupdate', data)
      this.seeked = false
    })

    this.player.ready().then(() => {
      // set mute status
      this.player.pause()
      if (this.options.mute) {
        this.setMuted()
      } else {
        this.player.setVolume(1)
      }
      if (false) {
        let quality = null
        let hasSetQuality = true
        this.player.getQualities().then((qualities) => {
          qualities.forEach(qu => {
            if (quality === null && qu.id !== 'auto') {
              quality = qu.id
            }
            if (qu.id === 'auto' && qu.active) {
              hasSetQuality = false
            }
          })
          if (!hasSetQuality) {
            this.player.setQuality(quality).then((quality1) => {
              this.player.pause()
              this.player.setCurrentTime(0)
              // quality was successfully set
            }).catch(function (error) {
              console.log(error)
            })
          }

          // qualities = an array of quality objects
        }).catch(function (error) {
          console.log(error)
          // an error occurred
        })
      }
    }).catch(() => {
      // do nothing
    }).finally(() => {
      this.$emit('ready')
    })
    this.player.getDuration().then(duration => {
      this.$emit('durationupdate', duration)
      this.duration = duration
    })
  },
  watch: {
    videoId (val) {
      this.player.play(val)
    }
  },
  methods: {
    generateRandomID (length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
      let randomID = ''

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length)
        randomID += characters.charAt(randomIndex)
      }

      return randomID
    },
    setMuted () {
      this.player.setMuted(true)
    },
    play () {
      this.player.play()
      this.setVideoReadyFullScreen()
    },
    pause () {
      this.player.pause()
    },
    replay () {
      this.player.setCurrentTime(0)
      this.play()
    },
    seekToPosition (position, isVideoPlaying) {
      this.seeked = true
      if (isVideoPlaying) { // pause the video only if the video is playing at that moment
        this.pause()
      }
      // 'position' is a number from 0 - 100. It represents the position of the slider thumb
      // We are calculating the percentage of the time that's been seeked in the video
      let seekTo = this.duration * (position / 100)
      if (seekTo > this.duration) {
        seekTo = this.duration - 0.1 // player throws an exception if seeking 'position === duration'
      }
      this.$nextTick(() => {
        this.player.setCurrentTime(seekTo)
        if (isVideoPlaying) {
          // play only if the player got paused during the seeking.
          // if the player was paused before seeking, it won't be played.
          this.player.play()
        }
      })
    },
    async setVideoReadyFullScreen () {
      if (!this.startPlayingFullScreenIfPortrait) {
        return
      }
      let vidHeight = 0
      let vidWidth = 0
      await this.player.getVideoHeight().then(height => {
        vidHeight = height
      })
      await this.player.getVideoWidth().then(width => {
        vidWidth = width
      })

      if (vidHeight > vidWidth) {
        this.$emit('fullScreen', true)
      }
    },
    beforeDestroy () {
      if (this.player) {
        this.player.destroy()
      }
    }
  }
}
</script>
