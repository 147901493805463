<template>
  <div :class="componentClasses" @click="clicked()">
     <div class="gc-side-bar__button-primary-icon">
       <slot name="icon"></slot>
     </div>
     <text-body-small line-height="lh-lg" :weight="textFontWeight">
        <slot></slot>
     </text-body-small>
  </div>
</template>
<script>
import TextBodySmall from '../../../../../global/typography/TextBodySmall'

export default {
  name: 'side-bar-button-primary',
  components: {
    TextBodySmall

  },
  props: {
    active: {
      default: false
    }
  },
  methods: {
    clicked () {
      this.$emit('click')
    }
  },
  computed: {
    textFontWeight: function () {
      return this.active ? 'extra-bold' : 'medium'
    },
    componentClasses: function () {
      return {
        'gc-side-bar__button-primary': true,
        'gc-side-bar__button-primary__selected': this.active
      }
    }
  }
}
</script>
