export default class Habit {
  id = null
  name = null

  updatedAt = null
  createdAt = null
  lastSetOn = null

  constructor (habit) {
    this.id = habit.id
    this.name = habit.name
    this.updatedAt = habit.updated_at
    this.createdAt = habit.created_at
    this.lastSetOn = habit.last_set_on
  }

  getName () {
    return this.name
  }

  getID () {
    return this.id
  }
}
