import { render, staticRenderFns } from "./NavigationTabsSecondary.vue?vue&type=template&id=34797175&scoped=true"
import script from "./NavigationTabsSecondary.vue?vue&type=script&lang=js"
export * from "./NavigationTabsSecondary.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34797175",
  null
  
)

export default component.exports