<template>
  <div :class="componentClasses">
    <slot></slot>
    <text-content
      class="pc-dashboard__card-empty-text"
      :weight="'normal'"
      :line-height="'single'"
      :size="'sm1'"
    >
      {{ text }}
    </text-content>
  </div>
</template>

<script>
import TextContent from '../../../../root/TextContent'

export default {
  name: 'CardEmpty',
  components: {
    TextContent
  },
  props: {
    text: {
      default: ''
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'pc-dashboard__card-empty': true
      }
    }
  }
}
</script>

<style scoped></style>
