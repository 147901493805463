export default class PeriodLog {
  _id = null
  _cycleLength = null
  _lastPeriodDate = null
  _predictedDate = null
  _actualDate = null
  _periodLength = null
  _periodId = null
  _createdAt = null
  _updatedAt = null

  constructor (initialData = null) {
    if (initialData) {
      this.initiate(initialData)
    }
  }

  initiate (initialData) {
    this._id = initialData.id
    this._cycleLength = initialData.cycle_length
    this._lastPeriodDate = initialData.last_period_date
    this._predictedDate = initialData.predicted_date
    this._actualDate = initialData.actual_date
    this._periodLength = initialData.period_length
    this._periodId = initialData.period_id
    this._createdAt = initialData.created_at
    this._updatedAt = initialData.updated_at
  }

  getId () {
    return this._id
  }

  getCycleLength () {
    return this._cycleLength
  }

  getLastPeriodDate () {
    return this._lastPeriodDate
  }

  getPredictedDate () {
    return this._predictedDate
  }

  getActualDate () {
    return this._actualDate
  }

  getPeriodLength () {
    return this._periodLength
  }

  getPeriodId () {
    return this._periodId
  }

  getCreatedAt () {
    return this._createdAt
  }

  getUpdatedAt () {
    return this._updatedAt
  }

  setId (id) {
    this._id = id
  }

  setCycleLength (cycleLength) {
    this._cycleLength = cycleLength
  }

  setLastPeriodDate (lastPeriodDate) {
    this._lastPeriodDate = lastPeriodDate
  }

  setPredictedDate (predictedDate) {
    this._predictedDate = predictedDate
  }

  setActualDate (actualDate) {
    this._actualDate = actualDate
  }

  setPeriodLength (periodLength) {
    this._periodLength = periodLength
  }

  setPeriodId (periodId) {
    this._periodId = periodId
  }

  setCreatedAt (createdAt) {
    this._createdAt = createdAt
  }

  setUpdatedAt (updatedAt) {
    this._updatedAt = updatedAt
  }
}
