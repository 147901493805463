<template>
  <div :class="componentClasses" @click="clicked()">
    <svg xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg"
         style="margin: auto; background: rgba(255, 255, 255, 0); display: block; shape-rendering: auto;" width="576px"
         height="576px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <g transform="rotate(0 50 50)">
        <rect x="48.5" y="33" rx="1.5" ry="1.6" width="3" height="10" fill="#727272">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.9345794392523364s"
                   begin="-0.8566978193146417s" repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(30 50 50)">
        <rect x="48.5" y="33" rx="1.5" ry="1.6" width="3" height="10" fill="#727272">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.9345794392523364s"
                   begin="-0.778816199376947s" repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(60 50 50)">
        <rect x="48.5" y="33" rx="1.5" ry="1.6" width="3" height="10" fill="#727272">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.9345794392523364s"
                   begin="-0.7009345794392523s" repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(90 50 50)">
        <rect x="48.5" y="33" rx="1.5" ry="1.6" width="3" height="10" fill="#727272">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.9345794392523364s"
                   begin="-0.6230529595015576s" repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(120 50 50)">
        <rect x="48.5" y="33" rx="1.5" ry="1.6" width="3" height="10" fill="#727272">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.9345794392523364s"
                   begin="-0.545171339563863s" repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(150 50 50)">
        <rect x="48.5" y="33" rx="1.5" ry="1.6" width="3" height="10" fill="#727272">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.9345794392523364s"
                   begin="-0.4672897196261682s" repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(180 50 50)">
        <rect x="48.5" y="33" rx="1.5" ry="1.6" width="3" height="10" fill="#727272">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.9345794392523364s"
                   begin="-0.3894080996884735s" repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(210 50 50)">
        <rect x="48.5" y="33" rx="1.5" ry="1.6" width="3" height="10" fill="#727272">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.9345794392523364s"
                   begin="-0.3115264797507788s" repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(240 50 50)">
        <rect x="48.5" y="33" rx="1.5" ry="1.6" width="3" height="10" fill="#727272">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.9345794392523364s"
                   begin="-0.2336448598130841s" repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(270 50 50)">
        <rect x="48.5" y="33" rx="1.5" ry="1.6" width="3" height="10" fill="#727272">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.9345794392523364s"
                   begin="-0.1557632398753894s" repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(300 50 50)">
        <rect x="48.5" y="33" rx="1.5" ry="1.6" width="3" height="10" fill="#727272">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.9345794392523364s"
                   begin="-0.0778816199376947s" repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(330 50 50)">
        <rect x="48.5" y="33" rx="1.5" ry="1.6" width="3" height="10" fill="#727272">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.9345794392523364s" begin="0s"
                   repeatCount="indefinite"></animate>
        </rect>
      </g>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconPreloader',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-preloader': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped>

</style>
