import CommunityService from '../../services/community/CommunityService'
import Vue from 'vue'
import Vuex from 'vuex'
import DashBoardService from '../../services/DashboardService'
import { waitSeconds } from '@/helpers'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    unreadNotificationsExist: false,
    initiated : false,
    activeCommunities:[],
    unseenCount: 0,
  },
  mutations: {
    setUnreadNotificationsExist (state, value) {
      state.unreadNotificationsExist = value
    }
  },
  actions: {
    async initiate({state,dispatch, }){

      if(state.initiated){
        return
      }
      state.initiated= true
      const communityService = new CommunityService()
      if(!communityService.list){
        await communityService.getCommunities()
        state.activeCommunities = communityService.getActiveCommunities()
        if(state.activeCommunities.length>0){
          dispatch('initNotificationDetails')
        }

      }

    },
    async initNotificationDetails ({ commit,state}) {
      const dashboard = new DashBoardService()
      if(!dashboard.isCommunityEnabled()){
        return
      }
      if(state.unseenCount>0){
        return
      }
      const communityService = new CommunityService()
      if(!communityService.list){
        await waitSeconds(2)
        await communityService.getCommunities()

      }
      state.activeCommunities = await communityService.getActiveCommunities()
      if(state.activeCommunities.length===0){
        return
      }
      await communityService.getNotifications(1, 0).then((response) => {
        state.unseenCount =  response.unseen
        commit('setUnreadNotificationsExist', response.unseen > 0)
        localStorage.setItem('communityNotificationUnseenCount',(state.unseenCount).toString())
      })
    }
  },
  getters: {
    isUnreadNotificationsExist (state) {
      return state.unreadNotificationsExist
    },
    getActiveCommunities(state){
      return state.activeCommunities
    }
  }
}
