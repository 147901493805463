<template>
    <card-mood-tracker
            class="pc-dashboard__card-mood-tracker"
            v-if="isShowCard"
            :dashboardIntegration="true"
            :showAddIcon="true"
            :showMoodCount="true"
            :moodCountAwful="moodCount.awful"
            :moodCountCrap="moodCount.crap"
            :moodCountMeh="moodCount.meh"
            :moodCountGood="moodCount.good"
            :moodCountGreat="moodCount.great"
            @header-click="navigateToMoodTrackerHome()"
            @update-count="setMoodTracks($event)"
    />
</template>
<script>
import CardMoodTracker from '../../../../global/cards/CardMoodTracker'
import MoodTrackerService from '../../../../../services/MoodTrackerService'
import MOODS from '../../../../../services/enum/MoodTracker'

export default {
  name: 'card-dashboard-mood-tracker',
  components: { CardMoodTracker },
  data: function () {
    return {
      moodCount: {
        awful: 0,
        crap: 0,
        meh: 0,
        good: 0,
        great: 0
      },
      isShowCard: true
    }
  },
  beforeMount () {
    this.setMoodTracks({isActiveLoadingBuffer: true})
  },
  methods: {
    setMoodTracks (event = false) {
      if (!event.isActiveLoadingBuffer) {
        this.showLoading()
      }

      let moodTrackerService = new MoodTrackerService()
      let dateRange = moodTrackerService.getTodayDateRange()

      moodTrackerService.getDataByDateRange(dateRange)
        .then((response) => {
          this.setMoodsCounts(response)
          this.hideLoading()
        }).catch(() => {
          this.isShowCard = false
          this.hideLoading()
        })
    },
    setMoodsCounts (moods) {
      this.moodCount.awful = this.getMoodCountByType(moods, MOODS.AWFUL.TYPE)
      this.moodCount.crap = this.getMoodCountByType(moods, MOODS.CRAP.TYPE)
      this.moodCount.meh = this.getMoodCountByType(moods, MOODS.MEH.TYPE)
      this.moodCount.good = this.getMoodCountByType(moods, MOODS.GOOD.TYPE)
      this.moodCount.great = this.getMoodCountByType(moods, MOODS.GREAT.TYPE)
    },
    getMoodCountByType (moods, type) {
      return moods.filter(mood => {
        return mood.value === type
      }).length
    },

    navigateToMoodTrackerHome () {
      this.showLoading()
      // holding router so slick dom breaking is not visible before fade effect
      this.delayedRouterPush({
        path: '/dashboard',
        query: { page: 'mood-tracker' }
      })
    }
  }
}
</script>
