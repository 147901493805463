<template>
<card-content>
<div :class="componentClasses">
  <header-simple v-if="dashboardIntegration"  :headerText="$t('message[\'mood-tracker.card-heading\']')" :icon="'forward-small'" @click="clickedHeader()"></header-simple>
  <pop-up-add-mood :visible="uploadPopup.show" :type="moodType"
      v-if="uploadPopup.show" v-on:close="closePopup" @success="updateCount($event)"></pop-up-add-mood>
  <div class="gc-card-mood-tracker__mood-set">
    <card-mood-tracker-mood :count="moodCountAwful" :show-add-icon="showAddIcon" :state="awfulState" :show-mood-count="showMoodCount" :moodClickable="enableMoodClickable" :text="$t('message[\'mood-tracker.mood-awful\']')" :moodType="1" v-on:add-clicked="showMoodAddPopup($event)" v-on:select-mood="selectMood($event)">
      <icon-awful class="gc-card-tracker__icon-mood"/>
    </card-mood-tracker-mood>
    <card-mood-tracker-mood :count="moodCountCrap" :show-add-icon="showAddIcon" :state="crapState" :show-mood-count="showMoodCount" :text="$t('message[\'mood-tracker.mood-crap\']')" :moodClickable="enableMoodClickable" :moodType="2" v-on:add-clicked="showMoodAddPopup($event)" v-on:select-mood="selectMood($event)">
      <icon-crap class="gc-card-tracker__icon-mood"/>
    </card-mood-tracker-mood>
    <card-mood-tracker-mood :count="moodCountMeh" :show-add-icon="showAddIcon" :state="mehState" :show-mood-count="showMoodCount" :text="$t('message[\'mood-tracker.mood-meh\']')" :moodClickable="enableMoodClickable" :moodType="3" v-on:add-clicked="showMoodAddPopup($event)" v-on:select-mood="selectMood($event)">
      <icon-meh class="gc-card-tracker__icon-mood"/>
    </card-mood-tracker-mood>
    <card-mood-tracker-mood :count="moodCountGood" :show-add-icon="showAddIcon" :state="goodState" :show-mood-count="showMoodCount" :text="$t('message[\'mood-tracker.mood-good\']')" :moodClickable="enableMoodClickable" :moodType="4" v-on:add-clicked="showMoodAddPopup($event)" v-on:select-mood="selectMood($event)">
      <icon-good class="gc-card-tracker__icon-mood"/>
    </card-mood-tracker-mood>
    <card-mood-tracker-mood :count="moodCountGreat" :show-add-icon="showAddIcon" :state="greatState" :show-mood-count="showMoodCount" :text="$t('message[\'mood-tracker.mood-great\']')" :moodClickable="enableMoodClickable" :moodType="5" v-on:add-clicked="showMoodAddPopup($event)" v-on:select-mood="selectMood($event)">
      <icon-great class="gc-card-tracker__icon-mood"/>
    </card-mood-tracker-mood>
  </div>
  </div>
</card-content>
</template>

<script>

import TextContent from '../../root/TextContent'
import IconAwful from '../../root/icons/IconAwful'
import IconCrap from '../../root/icons/IconCrap'
import IconMeh from '../../root/icons/IconMeh'
import IconGood from '../../root/icons/IconGood'
import IconGreat from '../../root/icons/IconGreat'
import IconAddFillRounded from '../../root/icons/IconAddFillRounded'
import HeaderSimple from '../headers/HeaderSimple'
import CardMoodTrackerMood from './card-mood-tracker-sub-components/CardMoodTrackerMood'
import PopUpAddMood from '../../layout/template-1/mood-tracker/page-components/PopupAddMood'
import MOODS from '../../../services/enum/MoodTracker'
import CardContent from './CardContent'
import DashBoardService from '../../../services/DashboardService'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'card-mood-tracker',
  components: {
    CardMoodTrackerMood,
    TextContent,
    IconAwful,
    IconCrap,
    IconMeh,
    IconGood,
    IconGreat,
    IconAddFillRounded,
    HeaderSimple,
    PopUpAddMood,
    CardContent
  },
  props: {
    /**
     * The main text of the mood
     */
    text: {
      default: ''
    },
    /**
     * The disabled state of the mood
     */
    disabled: {
      default: false
    },
    /**
     * The show mood add icon
     */
    showAddIcon: {
      default: true
    },
    /**
     * The show mood count
     */
    showMoodCount: {
      default: true
    },
    /**
     * The show mood count with background color
     */
    moodCountWithFill: {
      default: false
    },

    enableMoodClickable: {
      default: false
    },
    /**
     * The show the mood tracker with dashborad header
     */
    dashboardIntegration: {
      default: false
    },
    /**
     * The show the mood tracker on pages
     */
    pageIntergration: {
      default: false
    },

    moodCountAwful: {
      default: 0
    },
    moodCountCrap: {
      default: 0
    },
    moodCountMeh: {
      default: 0
    },
    moodCountGood: {
      default: 0
    },
    moodCountGreat: {
      default: 0
    },

    selectMoodType: {
      default: 0
    }
  },
  data () {
    return {
      moodType: '',
      uploadPopup: {
        show: false
      },
      awfulState: '',
      crapState: '',
      mehState: '',
      goodState: '',
      greatState: ''
    }
  },
  computed: {
    ...mapGetters({
      getPlanExpiredPopupShow: 'getPlanExpiredPopupShow'
    }),
    componentClasses: function () {
      return {
        'gc-card-mood-tracker': true,
        'gc-card-mood-tracker--show-add-icon': this.showAddIcon,
        'gc-card-mood-tracker--show-mood-count': this.showMoodCount,
        'gc-card-mood-tracker__mood-count--with-background-color': this.moodCountWithFill,
        'gc-card-mood-tracker--dashboard-integration': this.dashboardIntegration
      }
    }
  },
  beforeMount () {
    if (this.selectMoodType === MOODS.AWFUL.TYPE) {
      this.resetMoodTypes()
      this.awfulState = 'active'
    } else if (this.selectMoodType === MOODS.CRAP.TYPE) {
      this.resetMoodTypes()
      this.crapState = 'active'
    } else if (this.selectMoodType === MOODS.MEH.TYPE) {
      this.resetMoodTypes()
      this.mehState = 'active'
    } else if (this.selectMoodType === MOODS.GOOD.TYPE) {
      this.resetMoodTypes()
      this.goodState = 'active'
    } else if (this.selectMoodType === MOODS.GREAT.TYPE) {
      this.resetMoodTypes()
      this.greatState = 'active'
    } else if (this.pageIntergration) {
      this.activeAll()
    }
  },
  methods: {
    ...mapMutations({
      showSubscriptionExpiredPopup: 'showSubscriptionExpiredPopup'
    }),
    activeAll () {
      this.awfulState = 'active'
      this.crapState = 'active'
      this.mehState = 'active'
      this.goodState = 'active'
      this.greatState = 'active'
    },
    updateCount (event) {
      this.$emit('update-count', event)
    },
    selectMood (event) {
      this.setActiveState(event.type)
      this.moodType = event.type
      this.$emit('mood-select', {type: this.moodType})
    },

    setActiveState (moodType) {
      this.resetMoodTypes()
      if (moodType === MOODS.AWFUL.TYPE) {
        this.awfulState = 'active'
      } else if (moodType === MOODS.CRAP.TYPE) {
        this.crapState = 'active'
      } else if (moodType === MOODS.MEH.TYPE) {
        this.mehState = 'active'
      } else if (moodType === MOODS.GOOD.TYPE) {
        this.goodState = 'active'
      } else if (moodType === MOODS.GREAT.TYPE) {
        this.greatState = 'active'
      }
    },

    resetMoodTypes () {
      this.awfulState = 'inactive'
      this.crapState = 'inactive'
      this.mehState = 'inactive'
      this.goodState = 'inactive'
      this.greatState = 'inactive'
    },

    showMoodAddPopup (event) {
      let dashboard = new DashBoardService()
      if (!(dashboard.isEntriesEditable())) {
        if (!(this.getPlanExpiredPopupShow)) {
          this.showSubscriptionExpiredPopup('moodTracker_add_entry')
        }
      } else {
        this.moodType = event.type
        this.uploadPopup.show = true
      }
    },
    clickedHeader () {
      this.$emit('header-click')
    },
    closePopup () {
      this.uploadPopup.show = false
    }
  }
}
</script>
