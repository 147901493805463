<template>
  <div :class="componentClasses" @click="clicked()">
  <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.4729 0.489349C12.1869 0.203412 11.7231 0.203412 11.4371 0.489398L4.96941 6.95712L1.56477 3.55248C1.27878 3.2665 0.815014 3.2665 0.528979 3.55248C0.242944 3.83847 0.242944 4.30224 0.528979 4.58827L4.45149 8.51079C4.5945 8.6538 4.78196 8.72529 4.96936 8.72529C5.15676 8.72529 5.34426 8.65375 5.48723 8.51079L12.4729 1.52514C12.7589 1.23915 12.7589 0.775384 12.4729 0.489349Z" fill="black"/>
  </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconComplete',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-complete': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
