<template>
  <popup
    :visible="visible"
    modal-class="pc-popup-day-recipe-email"
    v-if="visible"
    size="md"
    @hide="closePopup()"
  >
    <popup-title>
      <popup-button-close @hide="closePopup($event)"></popup-button-close>
    </popup-title>
    <popup-body class="text-center">
      <popup-head>
        <popup-text-heading>
          {{ title }}
        </popup-text-heading>
      </popup-head>
          <popup-content>
            <text-body-small line-height="lh-lg"><p v-html="description"></p></text-body-small>
          </popup-content>
          <popup-footer>
            <button-primary
              :text="buttonText"
              @click="confirm"
            ></button-primary>
          </popup-footer>

    </popup-body>
  </popup>
</template>

<script>

import popupAnimationMixin from '../../../../../../../mixins/popupAnimationMixin'
import Popup from '../../../../../../global/popups/Popup'
import PopupHead from '../../../../../../global/popups/popup-sub-components/PopupHead'
import PopupButtonClose from '../../../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupTextHeading from '../../../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupTitle from '../../../../../../global/popups/popup-sub-components/PopupTitle'
import PopupFooter from '../../../../../../global/popups/popup-sub-components/PopupFooter'
import PopupContent from '../../../../../../global/popups/popup-sub-components/PopupContent'
import PopupBody from '../../../../../../global/popups/popup-sub-components/PopupBody'
import ButtonPrimary from '../../../../../../global/buttons/ButtonPrimary'
import TextBodySmall from '../../../../../../global/typography/TextBodySmall'

export default {
  name: 'PopupInputGroupIngredientsConfirmation',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    categoryName: {
      type: String,
      required: true
    }
  },
  mixins: [popupAnimationMixin],
  components: {
    TextBodySmall,
    ButtonPrimary,
    Popup,
    PopupHead,
    PopupTextHeading,
    PopupButtonClose,
    PopupTitle,
    PopupBody,
    PopupFooter,
    PopupContent
  },
  mounted () {

  },
  data: function () {
    return {
      title: this.$i18n.t("message['shopping-list-group.popup-confirmation-header']"),
      buttonText: this.$i18n.t("message['shopping-list-group.popup-confirmation-button-text']")
    }
  },
  computed: {
    description: function () {
      let params = {
        category: '<p class="category-name">' + this.categoryName + '</p>'
      }
      return this.$i18n.t("message['shopping-list-group.popup-confirmation-description']", params)
    }
  },
  methods: {
    confirm () {
      this.$emit('confirm')
      this.closePopup()
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }

      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    }

  }
}
</script>
