<template>
  <page-secondary :header="headerOptions" :class="componentClasses" style="transform: translate3d(0)">
  <page-container class="pc-my-journey_container" v-if="fitnessData.length>0">
    <card-photo-journey-main v-if="isPaidUserEnabled && showPhotoJourney" :is-editable="isEditable"/>
    <grid-row class="pc-my-journey_cards-wrapper">
    <grid-col
      v-for="singleData in fitnessData"
      :key="'progress-card-'+singleData.getType()"
      :cols="12"
      :md="(singleData.type === 'weight')?12:6"
      v-if="!cardsToHide.includes(singleData.type)"
      class="pc-home__main-card-wrapper">
      <card-measurement-detailed
        v-if="singleData.type === 'weight'"
        :name="singleData.name"
        :type ="singleData.type"
        :firstTimeValue="singleData.firstTime.value"
        :latestValue="singleData.currentValue"
        :firstTimeDate="singleData.firstTime.date"
        :latestDate="singleData.latestDate"
        :measurementUnit="singleData.unit"
        :is-editable="isEditable"
        @showProgressPopup="requestUpdate($event)"
      />
      <card-measurement
        v-if="singleData.type !== 'weight'"
        :id="singleData.type"
        :name="singleData.name"
        :type="singleData.type"
        :firstTimeValue="singleData.firstTime.value"
        :latestValue="singleData.currentValue"
        :measurementUnit="singleData.unit"
        :firstTimeDate="singleData.firstTime.date"
        :latestDate="singleData.latestDate"
        :is-editable="isEditable"
        @showProgressPopup="requestUpdate($event)"
      ></card-measurement>
    </grid-col>
    </grid-row>
      <pop-up-update-fitness-data
      :fitnessData="updatePopup.data"
      :type="updatePopup.type" :visible="updatePopup.show" v-if="updatePopup.show"
      v-on:updated="dataUpdated()"
      v-on:close="updatePopup.show=false"></pop-up-update-fitness-data>
  </page-container>
  </page-secondary>
</template>

<script>
import ProgressCard from './widgets/ProgressCard'
import PhotoJourneyCard from './widgets/PhotoJourneyCard'
import PopUpUpdateFitnessData from '../../../global/popups/PopUpUpdateFitnessData'
import fitnessDairyPageMixin from '../../../../mixins/fitnessDairyPageMixin'
import fitnessDiaryPhotoAndMeasurementMixin
  from '../../../../mixins/component-mixins/page/fitness-diary/fitnessDiaryPhotoAndMeasurementMixin'
import CardMeasurement from '../../../global/cards/CardMeasurement'
import CardMeasurementDetailed from '../../../global/cards/CardMeasurementDetailed'
import CardPhotoJourneyMain from './page-components/CardPhotoJourneyMain'
import PageSecondary from '../../../global/pages/PageSecondary'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import { mapGetters } from 'vuex'
import DashBoardService from '../../../../services/DashboardService'
import GridRow from '../../../global/grid/GridRow'
import GridCol from '../../../global/grid/GridCol'
import { isPhotoUploadEnabled } from './../../../../includes/TemplateSettings'
import ProfileService from '../../../../services/ProfileService'
import globalEventBus from '../../../../event-buses/globalEventBus'
export default {
  name: 'FitnessDiaryHome',
  mixins: [fitnessDairyPageMixin, fitnessDiaryPhotoAndMeasurementMixin],
  components: {
    GridCol,
    GridRow,
    PageContainer,
    PageSecondary,
    ProgressCard,
    PopUpUpdateFitnessData,
    PhotoJourneyCard,
    CardMeasurement,
    CardMeasurementDetailed,
    CardPhotoJourneyMain },
  data () {
    return {
      isPaidUserEnabled: false,
      isEditable: true,
      headerOptions: {
        pageClass: ['macroapp--page-common'],
        isTransparent: false,
        isFixed: true,
        show: true,
        left: 'menu',
        right: '',
        rightBlink: false,
        modifiers: ['small-title'],
        mainTitle: this.$i18n.t('message["fitness-diary.home-title"]'),
        subTitle: ''
      },
      showPhotoJourney: true
    }
  },
  created () {
    this.setPageTitle(this.$t('message["fitness-diary.home-title"]'))
  },
  beforeMount () {
    this.showPhotoJourney = isPhotoUploadEnabled()
    let dashBoardService = new DashBoardService()
    this.isEditable = dashBoardService.isFitnessDiaryEditable()
  },
  mounted () {
    // this.headerOptions.header.mainTitle = this.$i18n.t('message["fitness-diary.home-title"]')
    // this.setHeader(this.headerOptions)
    this.setPageClass()
    this.getData()
    this.isPaidUser()
    // this.goto()
    globalEventBus.$on('metrics-clicked', this.scrollToMetric)
  },
  computed: {
    componentClasses: function () {
      return {
        'pc-my-journey': true,
        'pc-my-journey__tablet-view': this.isTablet,
        'pc-my-journey__desktop-view': this.isDesktop
      }
    }
  },
  methods: {
    ...mapGetters({
      service: 'fitnessDiaryStore/getService'
    }),
    scrollToMetric () {
      setTimeout(() => {
        this.$nextTick(() => {
          let ele = document.querySelector(
            '.gc-card-measurement'
          )
          const top = ele.getBoundingClientRect().top
          const headerHeight = document.querySelector('.gc-header-page').offsetHeight
          window.scrollTo({
            top: (top - headerHeight - 12),
            left: 0,
            behavior: 'smooth'
          })
        })
      }, 200)
    },
    isPaidUser () {
      const profileService = new ProfileService()
      if (!profileService.getDob() || profileService.getDob().length === 0 || profileService.getDob() === null) {
        this.isPaidUserEnabled = false
      } else {
        this.isPaidUserEnabled = true
      }
    }
    // goto () {
    //   setTimeout(() => {
    //     document.getElementById('arms').scrollIntoView({
    //       behavior: 'smooth'
    //     })
    //   }, 2000)
    // }
  },
  beforeDestroy () {
    globalEventBus.$off('metrics-clicked', this.scrollToMetric)
  }
}
</script>
