import LanguageService from '../services/LanguageService'
import headerModifiers from '../config/header-modifiers'
import {replaceWordsWithNumbers} from '../helpers/string/index'
import jsonp from 'jsonp'
import UrlPattern from 'url-pattern'
import * as Sentry from '@sentry/browser'
import { mapGetters, mapMutations } from 'vuex'
export default {
  data: function () {
    return {
      langNumbers: {}
    }
  },
  computed: {
    ...mapGetters({
      appMode: 'getAppMode',
      trainerBrand: 'pageStore/getTrainerBrand'
    }),
    isDesktop: function () {
      return this.appMode === 'desktop'
    },
    isTablet: function () {
      return this.appMode === 'tablet'
    }
  },
  methods: {
    ...mapMutations({
      setPageTitle: 'pageStore/setPageTitle',
      setTrainer: 'pageStore/setTrainer'
    }),
    setNavClasses (classes) {
      const navClasses = []
      if (Array.isArray(classes)) {
        classes.forEach((modifier) => {
          navClasses.push(headerModifiers[modifier] || modifier)
        })
      } else {
        navClasses.push(headerModifiers[classes] || classes)
      }
      return navClasses
    },
    appHeaderRightBlink () {
      this.headerOptions.rightBlink = true
      const self = this
      setTimeout(function () {
        self.headerOptions.rightBlink = false
      }, 2000)
    },
    getLanguageReference (key) {
      const lang = new LanguageService()
      return lang.get(key)
    },
    getLanguageReferencesList () {
      const lang = new LanguageService()
      return lang.getAll()
    },
    /**
     * show the loading buffer of current the page
     */
    showLoading () {
      this.$store.commit('showLoading')
    },
    /**
     * hide the loading buffer of current the page
     */
    hideLoading (time = 200) {
      this.$store.commit('hideLoading', {time: time})
      let event = new CustomEvent('app-ready', {'detail': {}})
      document.dispatchEvent(event)
    },
    /**
     * hide the loading buffer of current the page
     */
    showServerErrorPopup () {
      this.$store.commit('showServerErrorPopup')
    },
    /**
     * delayed router push method
     */
    delayedRouterPush (routerObj) {
      this.showLoading()
      const self = this
      // holding router for 0.5 seconds
      setTimeout(function () {
        self.$router.push(routerObj)
      }, 500)
    },
    /**
     * delayed router back method
     */
    delayedGoBack () {
      this.showLoading()
      let self = this
      // holding router for 0.5 seconds
      setTimeout(function () {
        self.$router.back()
      }, 500)
    },
    /**
     * cache array of images
     */
    cacheImages (images) {
      images.forEach(function (image) {
        const imageObj = new Image()
        imageObj.src = image
      })
    },
    /**
     * get store image by key
     */
    getStoreImage (key) {
      const images = this.$store.getters.getImages
      return images[key]
    },
    filterNumberKeysAndValues (obj) {
      const filter = /^general.\d+$/
      let key
      for (key in obj) {
        if (obj.hasOwnProperty(key) && filter.test(key)) {
          this.langNumbers[obj[key]] = key.replace('general.', '')
        }
      }
    },
    isEmpty (obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false
        }
      }
      return true
    },
    firstLetterCaps (text) {
      return text.charAt(0).toUpperCase() + text.slice(1)
    },
    /**
     *
     * @param numberStr - `Week One`
     * @param withFrontZero - if number is less than 10 adding a 0 in front of the number if true = `Week 01` if false = `Week 1`
     * @returns {*}
     */
    numberWordToNumeric (numberStr, withFrontZero = false) {
      if (!numberStr || typeof numberStr !== 'string') {
        return numberStr
      }
      if (this.isEmpty(this.langNumbers)) {
        const langService = new LanguageService()
        this.filterNumberKeysAndValues(langService.data)
        numberStr.replace(/\b(work)\b/)
      }

      return replaceWordsWithNumbers(numberStr, this.langNumbers)
    },
    getThumbImageFromOrig (image, defaultImage = null) {
      if (!image) {
        return defaultImage
      }
      const arr = image.split('/')
      const lastIndex = arr.length - 1
      const fileName = arr[lastIndex]
      const thumbName = fileName.replace('-orig', '-thumb')
      arr[lastIndex] = thumbName

      // return thumb url
      return arr.join('/') || defaultImage
    },
    /**
     *
     */
    getPageConfigByType (type) {
      const mappedPage = this.$layoutConfig.pages.find(page => {
        return page.type === type
      })
      return mappedPage || null
    },
    getPageConfigByName (name) {
      const mappedPage = this.$layoutConfig.pages.find(page => {
        return page.name === name
      })
      return mappedPage || null
    },

    getPageWidgetByPageAndType (pageType, type) {
      const pageConfig = this.getPageConfigByType(pageType)
      const widget = pageConfig.widgets.find(page => {
        return page.key === type
      })
      return widget || null
    },
    /**
     * light -true , dark- false
     * check the color is a light or dark variation
     * @param color
     * @returns {boolean}
     */
    lightOrDark (color) {
      // Variables for red, green, blue values
      let r, g, b, hsp

      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)

        r = color[1]
        g = color[2]
        b = color[3]
      } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +('0x' + color.slice(1).replace(
          color.length < 5 && /./g, '$&$&'))

        r = color >> 16
        g = color >> 8 & 255
        b = color & 255
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
      )

      // Using the HSP value, determine whether the color is light or dark
      return (hsp > 127.5) // light -true , dark- false
    },
    /**
     * check array of text or text is empty without the html tag
     * @param text
     * @returns {boolean}
     */
    checkIsNotEmptyTextArray (text) {
      text = text || ''
      if (Array.isArray(text)) {
        const index = text.findIndex(ele => {
          ele = ele || ''
          return Boolean(ele.replace(/<\/?[^>]+(>|$)/g, '').trim())
        })
        return index !== -1
      } else if (typeof text === 'string' || text instanceof String) {
        return Boolean(text.replace(/<\/?[^>]+(>|$)/g, '').trim())
      } else {
        return false
      }
    },
    registerStoreModule (moduleName, storeModule) {
      const store = this.$store

      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    },
    getVimeoImage (videoId) {
      return new Promise((resolve, reject) => {
        try {
          jsonp('https://vimeo.com/api/oembed.json?url=https://vimeo.com/' + videoId + '?format=jsonp',
            null,
            (error, data) => {
              if (!error) {
                const height = data.height
                const width = data.width

                // checking for valid dimensions
                if (height === 0 || width === 0) {
                  reject(new Error('invalid image dimensions'))
                  return
                }

                // setting image width to defined value and height according to ratio
                const expectedWidth = 1200
                const ratio = expectedWidth / width
                const expectedHeight = Math.floor(height * ratio)

                const parts = data.thumbnail_url.split('_')
                const fileNameParts = parts[1].split('.')
                const extension = fileNameParts[fileNameParts.length - 1]
                parts[1] = expectedWidth + 'x' + expectedHeight + '.' + extension
                const url = parts.join('_')

                // return vimeo image (if not empty)
                if (url && url.trim() !== '') {
                  resolve(url)
                } else {
                  reject(new Error('empty image'))
                }
              } else {
                reject(new Error('fetch failed'))
              }
            })
        } catch (e) {
          reject(new Error('fetch failed'))
        }
      })
    },
    isRTL () {
      let htmlElement = document.getElementsByTagName('html')[0]
      return !!(htmlElement && htmlElement.getAttribute('dir') && htmlElement.getAttribute('dir') === 'rtl')
    },
    getVimeoIdFromUrl (url) {
      const pattern = new UrlPattern('(http(s)\\://)player.vimeo.com(\\::port)/video/:videoId(/)(?*)')
      let matchUrl = pattern.match(url)
      if (!matchUrl) {
        const patternNotEmbed = new UrlPattern('(http(s)\\://)vimeo.com(\\::port)/:videoId(/)(?*)')
        matchUrl = patternNotEmbed.match(url)
        return matchUrl ? matchUrl.videoId : null
      } else {
        return matchUrl ? matchUrl.videoId : null
      }
    },
    getCustomPageNameFromUrl (url) {
      const pattern = new UrlPattern('(http(s)\\://)(:subdomain.):domain.:tld(\\::port)/page/:pageName(?*)')
      const matchUrl = pattern.match(url)
      return matchUrl ? matchUrl.pageName : null
    },
    logSentry (payload = {}) {
      Sentry.captureException(payload)
    }
  }
}
