<template>
  <button-base :borderRadius="'lg1'" :class="componentClasses" :type="'solid'" @click="clicked()">
    <icon-cart/>
    <text-body-regular :line-height="'multi'" :weight="'extra-bold'">{{ text }}</text-body-regular>
  </button-base>
</template>

<script>

import ButtonBase from '../../root/ButtonBase'
import IconCart from '../../root/icons/IconCart'
import TextBodyRegular from '../typography/TextBodyRegular'

export default {
  name: 'ButtonPrimaryIconCart',
  components: {
    ButtonBase,
    IconCart,
    TextBodyRegular
  },
  props: {
    /**
     * The main text of the button
     */
    text: {
      default: ''
    },
    /**
     * The disabled state of the button
     */
    disabled: {
      default: false
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-button-primary-icon-cart': true
      }
    }
  },
  methods: {
    /**
     * function to emit the click event
     */
    clicked () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped></style>
