<template>
<div :class="componentClasses" @click="clicked()">
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3494 4.40015H1.64941V3.40015H20.3494V4.40015ZM20.3494 12.1001H1.64941V11.1001H20.3494V12.1001ZM1.64941 19.8004H20.3494V18.8004H1.64941V19.8004Z" fill="black"/>
  </svg>
</div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconHamburgerLinear',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-hamburger-linear': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
