<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.358341 0.295677C0.555351 0.0985034 0.788884 -1.3298e-06 1.05872 -1.30621e-06L15.0038 -8.70878e-08C15.2738 -6.34882e-08 15.5071 0.0985047 15.7043 0.295678C15.9015 0.493069 16 0.726439 16 0.996223C16 1.26595 15.9015 1.49932 15.7043 1.69655L8.73174 8.66913C8.53435 8.8663 8.30098 8.96503 8.03125 8.96503C7.76152 8.96503 7.52793 8.8663 7.33092 8.66913L0.358341 1.69649C0.161386 1.49932 0.0624999 1.26595 0.0624999 0.996167C0.0624999 0.726437 0.161386 0.493068 0.358341 0.295677Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconDownArrowFilled',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon--down-arrow-filled': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
