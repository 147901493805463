<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="52" height="32" viewBox="0 0 52 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 6C0 2.68629 2.68629 0 6 0H46C49.3137 0 52 2.68629 52 6V26C52 29.3137 49.3137 32 46 32H6C2.68629 32 0 29.3137 0 26V6ZM16 6H27.3364C35.0398 6 38 10.1887 38 15.4832C38 21.8166 33.8624 25 27.3364 25H16V6ZM17.0764 7.13885V23.9272H27.3364C33.526 23.9272 36.8899 21.0454 36.8899 15.5163C36.8899 9.81962 33.2568 7.13885 27.3364 7.13885H17.0764ZM20.1037 15.8181C21.237 15.8181 22.1557 14.9029 22.1557 13.774C22.1557 12.6451 21.237 11.7299 20.1037 11.7299C18.9705 11.7299 18.0518 12.6451 18.0518 13.774C18.0518 14.9029 18.9705 15.8181 20.1037 15.8181ZM18.4558 16.6895H21.7524V22.5202H18.4558V16.6895ZM34.0979 11.8977H33.156V15.6508H35.5107C35.4098 19.739 33.3578 22.5203 27.3364 22.5203H23.4006V15.6508H24.0061C25.049 15.6508 25.6881 14.9471 25.6881 13.7743C25.6881 12.6014 25.049 11.8977 23.9725 11.8977H23.4006V8.44622H27.3364C31.0367 8.44622 35.1071 9.58555 35.4771 14.7125H34.0979V11.8977ZM28.6147 15.6505V14.7123H27.2355V14.2096H28.4802V13.2713H27.2019V12.8357H28.5475V11.8974H26.26V15.6505H28.6147ZM30.1618 11.8974L29.0181 15.6505H30.0272L30.2291 14.9468H31.4737L31.6756 15.6505H32.6511L31.5074 11.8974H30.1618ZM30.4983 13.9749H30.5088L30.4983 14.0085V13.9749ZM30.5088 13.9749L30.8347 12.9361H30.8683L31.1711 13.9749H30.5088ZM23.4006 12.8358V14.7123H23.9725C24.3762 14.7123 24.7462 14.6118 24.7462 13.7741C24.7462 12.9363 24.3425 12.8358 23.9725 12.8358H23.4006Z" fill="white"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconPaymentIDeal',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-payment': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
