<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 12C0 5.37258 5.37258 0 12 0H28C34.6274 0 40 5.37258 40 12C40 18.6274 34.6274 24 28 24H12C5.37258 24 0 18.6274 0 12Z" fill="#252524"/>
    <path d="M23.4285 11.4286H20.5711V8.57142C20.5711 8.25583 20.3152 8 19.9996 8C19.6839 8 19.4281 8.25583 19.4281 8.57142V11.4286H16.5715C16.2559 11.4286 16 11.6844 16 12C16 12.3156 16.2559 12.5714 16.5715 12.5714H19.428V15.4286C19.428 15.7442 19.6839 16 19.9995 16C20.3152 16 20.571 15.7442 20.571 15.4286V12.5714H23.4285C23.7441 12.5714 24 12.3156 24 12C24 11.6844 23.7442 11.4286 23.4285 11.4286Z" fill="white"/>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconAddNew',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-add-new': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
