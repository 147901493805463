<template>
  <page-form :header="header" class="pc-progress-body-measurements">
    <page-container>
      <div class="pc-progress-body-measurements__header text-center">
        <text-heading4
          class="pc-progress-body-measurements__title"
          weight="extra-bold"
          line-height="multi"
          >{{ $t("message['general.form.body-measurements']") }}</text-heading4
        >
        <text-body-regular
          class="pc-progress-body-measurements__sub-title"
          weight="medium"
          line-height="multi"
          >{{
            "(" + $t("message['general.form.optional']") + ")"
          }}</text-body-regular
        >
      </div>
      <validation-observer ref="form" v-slot="{ handleSubmit,invalid }">
        <form
          id="form-body-measurements"
          class="pc-progress-body-measurements__form"
          @submit.prevent="handleSubmit(submitForm)"
        >
          <div v-if="show.shoulders">
            <text-body-regular
              class="pc-progress-body-measurements__label"
              weight="extra-bold"
              line-height="multi"
              >{{
                formFieldTitle.shoulders + measurementUnit
              }}</text-body-regular
            >
            <input-body-measurement
              class="pc-progress-body-measurements__input"
              :measurement-type="formFieldTitle.shoulders"
              :name="formFieldTitle.shoulders"
              v-model="formData.shoulders"
              :measurement-system="measurementSystem"
            ></input-body-measurement>
          </div>

          <div v-if="show.arms">
            <text-body-regular
              class="pc-progress-body-measurements__label"
              weight="extra-bold"
              line-height="multi"
              >{{ formFieldTitle.arms + measurementUnit }}</text-body-regular
            >
            <input-body-measurement
              class="pc-progress-body-measurements__input"
              :measurement-type="formFieldTitle.arms"
              :name="formFieldTitle.arms"
              v-model="formData.arms"
              :measurement-system="measurementSystem"
            ></input-body-measurement>
          </div>

          <div v-if="show.chest">
            <text-body-regular
              class="pc-progress-body-measurements__label"
              weight="extra-bold"
              line-height="multi"
              >{{ formFieldTitle.chest + measurementUnit }}</text-body-regular
            >
            <input-body-measurement
              class="pc-progress-body-measurements__input"
              :measurement-type="formFieldTitle.chest"
              :name="formFieldTitle.chest"
              v-model="formData.chest"
              :measurement-system="measurementSystem"
            ></input-body-measurement>
          </div>

          <div v-if="show.waist">
            <text-body-regular
              class="pc-progress-body-measurements__label"
              weight="extra-bold"
              line-height="multi"
              >{{ formFieldTitle.waist + measurementUnit }}</text-body-regular
            >
            <input-body-measurement
              class="pc-progress-body-measurements__input"
              :measurement-type="formFieldTitle.waist"
              :name="formFieldTitle.waist"
              v-model="formData.waist"
              :measurement-system="measurementSystem"
            ></input-body-measurement>
          </div>

          <div v-if="show.hips">
            <text-body-regular
              class="pc-progress-body-measurements__label"
              weight="extra-bold"
              line-height="multi"
              >{{ formFieldTitle.hips + measurementUnit }}</text-body-regular
            >
            <input-body-measurement
              class="pc-progress-body-measurements__input"
              :measurement-type="formFieldTitle.hips"
              :name="formFieldTitle.hips"
              v-model="formData.hips"
              :measurement-system="measurementSystem"
            ></input-body-measurement>
          </div>

          <div v-if="show.legs">
            <text-body-regular
              class="pc-progress-body-measurements__label"
              weight="extra-bold"
              line-height="multi"
              >{{ formFieldTitle.legs + measurementUnit }}</text-body-regular
            >
            <input-body-measurement
              class="pc-progress-body-measurements__input"
              :measurement-type="formFieldTitle.legs"
              :name="formFieldTitle.legs"
              v-model="formData.legs"
              :measurement-system="measurementSystem"
            ></input-body-measurement>
          </div>

          <div v-if="show.calves">
            <text-body-regular
              class="pc-progress-body-measurements__label"
              weight="extra-bold"
              line-height="multi"
              >{{ formFieldTitle.calves + measurementUnit }}</text-body-regular
            >
            <input-body-measurement
              class="pc-progress-body-measurements__input"
              :measurement-type="formFieldTitle.calves"
              :name="formFieldTitle.calves"
              v-model="formData.calves"
              :measurement-system="measurementSystem"
            ></input-body-measurement>
          </div>
          <div v-if="show.height">
            <text-body-regular
              class="pc-progress-body-measurements__label"
              weight="extra-bold"
              line-height="multi"
              >{{
                formFieldTitle.height + heightMeasurementUnit
              }}</text-body-regular
            >
            <input-body-measurement
              class="pc-progress-body-measurements__input"
              :measurement-type="'height'"
              :name="formFieldTitle.height"
              v-model="formData.height"
              :measurement-system="measurementSystem"
            ></input-body-measurement>
          </div>
        </form>

        <page-footer :form="'form-body-measurements'" :validated="invalid"></page-footer>
      </validation-observer>
    </page-container>
  </page-form>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '../../../../helpers/validation'
import { mapGetters, mapMutations } from 'vuex'
import InputBodyMeasurement from './../../../global/inputs/InputBodyMeasurement'
import TextHeading4 from '../../../global/typography/TextHeading4'
import TextBodyRegular from '../../../global/typography/TextBodyRegular'
import ButtonPrimary from '../../../global/buttons/ButtonPrimary'
import TextBodyExtraSmall from '../../../global/typography/TextBodyExtraSmall'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import PageForm from '../../../global/pages/PageForm'
import PageFooter from '../../../global/pages/page-sub-components/PageFormFooter'
import {
  isMeasurementArmsEnabled,
  isMeasurementCalvesEnabled,
  isMeasurementChestEnabled,
  isMeasurementHeightEnabled,
  isMeasurementHipEnabled,
  isMeasurementLegsEnabled,
  isMeasurementShouldersEnabled,
  isMeasurementWaistEnabled,
  isPhotoUploadEnabled
} from '../../../../includes/TemplateSettings'

export default {
  name: 'PageBodyMeasurements',
  components: {
    PageForm,
    ValidationObserver,
    ValidationProvider,
    InputBodyMeasurement,
    TextHeading4,
    TextBodyRegular,
    ButtonPrimary,
    TextBodyExtraSmall,
    PageContainer,
    PageFooter
  },
  data: function () {
    return {
      header: {
        isTransparent: false,
        isFixed: false,
        show: false,
        left: '',
        right: '',
        rightBlink: false,
        modifiers: [],
        mainTitle: '',
        subTitle: ''
      },
      measurementSystem: '',
      formData: {
        shoulders: 0,
        arms: 0,
        chest: 0,
        waist: 0,
        hips: 0,
        legs: 0,
        calves: 0,
        height: 0
      },
      previousFormData: {
        shoulders: 0,
        arms: 0,
        chest: 0,
        waist: 0,
        hips: 0,
        legs: 0,
        calves: 0,
        height: 0
      },
      formFieldTitle: {
        shoulders: '',
        arms: '',
        chest: '',
        waist: '',
        hips: '',
        legs: '',
        calves: '',
        height: ''
      },
      show: {
        shoulders: '',
        arms: '',
        chest: '',
        waist: '',
        hips: '',
        legs: '',
        calves: '',
        height: ''
      },
      submitText: ''
    }
  },
  beforeMount () {
    this.checkFlagValues()
    isPhotoUploadEnabled()
      ? (this.submitText = this.$i18n.t('message["general.next"]'))
      : (this.submitText = this.$i18n.t('message["general.submit"]'))
  },
  mounted () {
    this.getService()
      .getMyInfoFromAPI()
      .then((response) => {
        this.measurementSystem = response.data.measurement_system
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        this.hideLoading()
        let service = this.getService()
        const data = service.progressData
        data.measurements.fields.forEach((measurement) => {
          this.previousFormData[measurement.key] = measurement.value
          this.formData[measurement.key] = measurement.value
          this.formFieldTitle[measurement.key] =
            service.progressData.contents[measurement.key] || measurement.name
        })
        this.previousFormData['height'] = this.getService().getHeight()
        this.formData['height'] = this.getService().getHeight()
        this.formFieldTitle['height'] = service.progressData.contents['height']
        // after animation ends
        setTimeout(() => {
          this.$refs.form.setErrors(service.errors)
        }, 500)
      })
  },
  created () {},
  computed: {
    measurementUnit () {
      if (this.measurementSystem === 'metric') {
        return ' (' + this.$t('message["general.cm"]') + ')'
      } else if (this.measurementSystem === 'imperial') {
        return ' (' + this.$t('message["general.inch"]') + ')'
      } else {
        return ' '
      }
    },
    heightMeasurementUnit () {
      if (this.measurementSystem === 'metric') {
        return ' (' + this.$t('message["general.cm"]') + ')'
      } else if (this.measurementSystem === 'imperial') {
        return (
          ' (' +
          this.$t('message["general.ft"]') +
          ' ' +
          this.$t('message["general.inch"]') +
          ')'
        )
      } else {
        return ' '
      }
    }
  },
  methods: {
    ...mapGetters({
      getService: 'formWizardStore/getProgressFormService'
    }),
    ...mapMutations({
      setService: 'formWizardStore/setProgressFormService',
      setFormStatus: 'formWizardStore/setFormStatus'
    }),
    submitForm () {
      const service = this.getService()
      let isValueChanged = this.checkValueChange(
        this.previousFormData,
        this.formData
      )
      this.logEvent('ProgressUpdate.StepComplete', {
        type: this.$route.path.split('/')[1],
        step: 'measurements',
        value_changed: isValueChanged
      }, false)

      service.measurements = this.formData
      this.$emit('submit', {
        key: 'measurements',
        value: service.measurements
      })
    },

    checkValueChange (oldValue, newValue) {
      return !(JSON.stringify(newValue) === JSON.stringify(oldValue))
    },

    checkFlagValues () {
      this.show.shoulders = isMeasurementShouldersEnabled()
      this.show.arms = isMeasurementArmsEnabled()
      this.show.chest = isMeasurementChestEnabled()
      this.show.waist = isMeasurementWaistEnabled()
      this.show.hips = isMeasurementHipEnabled()
      this.show.legs = isMeasurementLegsEnabled()
      this.show.calves = isMeasurementCalvesEnabled()
      this.show.height = isMeasurementHeightEnabled()
    }
  }
}
</script>
