<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_16913_10564)">
        <path d="M13.1563 4.6875H11.75V5.62501H12.6875V14.0625H3.31251V5.62501H4.25002V4.6875H2.84376C2.58465 4.6875 2.375 4.89715 2.375 5.15626V14.5312C2.375 14.7904 2.58465 15 2.84376 15H13.1563C13.4154 15 13.625 14.7904 13.625 14.5312V5.15626C13.625 4.89715 13.4154 4.6875 13.1563 4.6875Z" fill="black"/>
        <path d="M7.53064 1.79443V8.43751H8.46815V1.79443L10.4805 3.80676L11.1433 3.14392L7.99939 0L4.85547 3.14392L5.51831 3.80676L7.53064 1.79443Z" fill="black"/>
      </g>
      <defs>
        <clipPath id="clip0_16913_10564">
          <rect width="15" height="15" fill="white" transform="translate(0.5)"/>
        </clipPath>
      </defs>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconIosMenu',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-ios-menu': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped>

</style>
