<template>
  <div>
    <div class="gc-form-head gc-form-head--with-description">
      <text-heading3 class="gc-form-head__main-title">{{title.main}}</text-heading3>
      <text-body-extra-small line-height="multi" class="gc-form-head__sub-title">{{title.sub}}</text-body-extra-small>
    </div>

    <div class="container gc-form-content gc-compare-photo mb-5">
      <div class="row gc-compare-photo__card-wrapper">
        <div class="col-6 gc-compare-photo__card">
          <card-photo-journey
            :image="first.image.url"
            :value="first.weight.value"
            :unit="first.weight.unit"
            :date="first.image.date"
          ></card-photo-journey>
        </div>
        <div class="col-6 gc-compare-photo__card">
          <card-photo-journey
            :image="latest.image.url"
            :value="latest.weight.value"
            :unit="latest.weight.unit"
            :date="latest.image.date"></card-photo-journey>
        </div>
      </div>

      <text-body-small class="gc-compare-photo__question" weight="extra-bold">
        {{$t('message[\'fitness-diary.so-far-comparison.question\']')}}
      </text-body-small>

      <card-feedback-mood
        class="gc-compare-photo__mood-selection"
        @moodChange="moodChange"
      ></card-feedback-mood>

      <text-area
        class="gc-compare-photo__mood-note"
        :placeholder="$t('message[\'general.placeholders.add-note-to-trainer\']')"
        v-model="feedback.note"
        :errorMessage="errorMessage"
      />
    </div>

    <div class="gc-form-foot fixed-bottom">
      <button-primary
        class="gc-form-foot__execute"
        :disabled="!feedback.mood"
        @click="submit">{{$t('message[\'general.submit\']')}}
      </button-primary>
      <button-link-secondary
        class="gc-form-foot__go-back"
        :text="$t('message[\'general.go-back\']')"
        @click="goBack">
      </button-link-secondary>
    </div>
  </div>
</template>

<script>
import TextHeading3 from '../typography/TextHeading3'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import ButtonPrimary from '../buttons/ButtonPrimary'
import ButtonLinkSecondary from '../buttons/ButtonLinkSecondary'
import BaseComponent from '../../layout/template-1/global/widgets/forms/FormBaseComponent'
import CardPhotoJourney from '../cards/CardPhotoJourneyImage'
import TextBodySmall from '../typography/TextBodySmall'
import {mapGetters} from 'vuex'
import CardFeedbackMood from '../cards/CardFeedbackMoodTracker'
import TextArea from '../inputs/TextArea'
import lodash from 'lodash'
import moment from 'moment'
import ProgressFeedbackService from '../../../services/ProgressFeedbackService'
import APIErrorHandler from '../../../services/api-handler/APIErrorHandler'

export default {
  name: 'ComparePhotos',
  props: {
    latestWeight: {
      default: ''
    }
  },
  extends: BaseComponent,
  components: {
    TextBodySmall,
    ButtonLinkSecondary,
    ButtonPrimary,
    TextBodyExtraSmall,
    TextHeading3,
    CardPhotoJourney,
    CardFeedbackMood,
    TextArea
  },
  data () {
    return {
      feedback: {
        mood: null,
        note: ''
      },
      first: {
        image: {
          date: '',
          url: ''
        },
        weight: {
          date: '',
          value: '',
          unit: ''
        }
      },
      latest: {
        image: {
          date: '',
          url: ''
        },
        weight: {
          date: '',
          value: '',
          unit: ''
        }
      },
      errorMessage: ''
    }
  },
  mounted () {
    this.service()
      .getDataFromAPI(true)
      .finally(() => {
        this.setImageData()
        this.setWeightData()
        this.hideLoading()
      })
    this.setTitles()
  },
  methods: {
    ...mapGetters({
      service: 'fitnessDiaryStore/getService'
    }),
    moodChange (mood) {
      this.feedback.mood = mood
    },
    setTitles () {
      this.title.main = this.$t('message["fitness-diary.so-far-comparison.title"]')
    },
    setImageData () {
      let images = this.service().images

      let dates = Object.values(images).map(image => {
        return new Date(image.firstTime.date)
      })
      let newDates = Object.values(images).map(image => {
        return new Date(image.latestDate)
      })

      let firstDate = lodash.min(dates)
      let latestDate = lodash.max(newDates)
      let month = (firstDate.getMonth() + 1)
      let day = firstDate.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      firstDate = firstDate.getFullYear() + '-' + month + '-' + day

      if (firstDate) {
        let firstDateObj = moment(firstDate)
        this.first.image.date = firstDateObj.format('YYYY-MM-DD')

        if (
          moment(firstDate).format('YYYY-MM-DD') ===
          moment(images['front-image'].firstTime.date).format('YYYY-MM-DD')
        ) {
          this.first.image.url = images['front-image'].firstTime.image
        }
      }

      if (latestDate) {
        let latestDateObj = moment(latestDate)
        this.latest.image.date = latestDateObj.format('YYYY-MM-DD')
        if (
          moment(latestDate).format('YYYY-MM-DD') ===
          moment(images['front-image'].latestDate).format('YYYY-MM-DD')
        ) {
          this.latest.image.url = images['front-image'].currentImage
        }
      }
    },
    setWeightData () {
      let data = this.service().data.weight
      this.first.weight.unit = data.unit
      this.latest.weight.unit = data.unit
      this.first.weight.date = data.firstTime.date
      this.latest.weight.date = data.latestDate
      if (data.unit === 'kg') {
        this.first.weight.value = Number.parseFloat(data.firstTime.value).toFixed(1) || '0'
        this.latest.weight.value = Number.parseFloat(data.currentValue).toFixed(1) || '0'
      } else if (data.unit === 'lb') {
        this.first.weight.value = Number.parseFloat(data.firstTime.value).toFixed(0) || '0'
        this.latest.weight.value = Number.parseFloat(data.currentValue).toFixed(0) || '0'
      }

      if (this.latestWeight && this.latestWeight !== '') {
        this.latest.weight.value = this.latestWeight
      }
    },
    submit () {
      this.showLoading()
      let progressFeedbackService = new ProgressFeedbackService()
      progressFeedbackService.sendFeedbackEmail(
        this.feedback.mood,
        this.feedback.note
      ).then(() => {
        this.nextComponent()
      }).catch((error) => {
        APIErrorHandler.logSentry(error, 'progress_feedback_email')
        this.nextComponent()
      }).finally(() => {
        this.hideLoading()
      })
    },
    goBack () {
      this.$emit('back')
    }
  }
}
</script>

<style scoped>

</style>
