<template>
    <div class="pc-enter-pair-code">
        <text-body-small line-height="multi" class="pc-enter-pair-code__description">
            {{$i18n.t(
            'message[\'member-connect.enter-code-description\']'
            )}}
        </text-body-small>
        <text-input :error-message="errorMessage" :placeholder="$i18n.t(
                    'message[\'member-connect.connect-code-input-placeholder\']'
                    )" v-model="enteredPairCode" class="pc-enter-pair-code__input"></text-input>
        <button-secondary :disabled="enteredPairCode.length === 0" @click="submitPairCode" text="Connect" class="pc-profile--my-connections__connect-button"></button-secondary>
        <popup-process-tracking
                ref="processStatusPopup"
                :processing-title="connectPopup.processingTitle"
                :processed-title="connectPopup.processedTitle"
        @completed="emitConnected"></popup-process-tracking>
    </div>
</template>

<script>
import TextBodySmall from '../../../../../global/typography/TextBodySmall'
import TextInput from '../../../../../global/inputs/TextInput'
import ButtonSecondary from '../../../../../global/buttons/ButtonSecondary'
import ConnectionService from '../../../../../../services/meal-plan-pair/ConnectionService'
import PopupProcessTracking from '../../../../../global/popups/PopupProcessTracking'
import PairService from '../../../../../../services/meal-plan-pair/PairService'
import MealPlanService from '../../../../../../services/MealPlanService'
import DashboardService from '../../../../../../services/DashboardService'

export default {
  name: 'EnterPairCode',
  components: {
    PopupProcessTracking,
    TextBodySmall,
    TextInput,
    ButtonSecondary
  },
  data () {
    return {
      connectionService: new ConnectionService(),
      pairService: new PairService(),
      mealPlanService: new MealPlanService(),
      dashboardService: new DashboardService(),
      enteredPairCode: '',
      errorMessage: '',
      connectPopup: {
        visible: false,
        status: 'processing',
        processingTitle: this.$i18n.t(
          'message["member-connect.connecting"]'
        ),
        processedTitle: this.$i18n.t(
          'message["member-connect.connected"]'
        )
      }
    }
  },
  methods: {
    async submitPairCode () {
      this.clearError()

      this.$refs.processStatusPopup.showProcessing()

      try {
        await this.connectionService.disconnect()
      } catch (e) {
        // ignore if error occurs
      }

      // connecting using a code
      await this.connectionService.connect(this.enteredPairCode)
        .then(async (res) => {
          if (!res.success) {
            // if connection code is invalid
            this.showError()

            this.$refs.processStatusPopup.hide()
            this.emitConnectFailed()
          } else {
            // if success re initiate connection details
            await this.connectionService.init({forceLoad: true})

            // re initiate pair details
            await this.pairService.init({forceLoad: true})

            // reset meal plan details
            this.mealPlanService.reset()

            // reset dashboard service details
            this.dashboardService.resetData()

            this.$refs.processStatusPopup.showProcessed()
          }
        })
        .catch(() => {
          this.$refs.processStatusPopup.hide()

          this.showError()
        })
    },
    clearError () {
      this.errorMessage = ''
    },
    showError () {
      this.errorMessage = this.$i18n.t(
        'message["member-connect.invalid-connect-code"]'
      )
    },
    emitConnected () {
      this.$emit('connected')
    },
    emitConnectFailed () {
      this.$emit('failed')
    }
  }
}
</script>

<style scoped>

</style>
