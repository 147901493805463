<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.90099 14.1852C9.38106 14.1852 9.77024 13.7917 9.77024 13.3063C9.77024 12.8209 9.38106 12.4274 8.90099 12.4274C8.42091 12.4274 8.03174 12.8209 8.03174 13.3063C8.03174 13.7917 8.42091 14.1852 8.90099 14.1852Z" fill="black"/>
      <path d="M8.9011 0C3.98171 0 0 4.02528 0 9C0 13.974 3.98105 18 8.9011 18C13.8205 18 17.8022 13.9747 17.8022 9C17.8022 4.02595 13.8211 0 8.9011 0ZM8.9011 16.5938C4.75037 16.5938 1.3908 13.1974 1.3908 9C1.3908 4.80315 4.74981 1.40625 8.9011 1.40625C13.0518 1.40625 16.4114 4.80259 16.4114 9C16.4114 13.1968 13.0524 16.5938 8.9011 16.5938Z" fill="black"/>
      <path d="M8.90092 4.51758C7.36715 4.51758 6.11932 5.77927 6.11932 7.33008C6.11932 7.71841 6.43065 8.0332 6.81472 8.0332C7.19879 8.0332 7.51012 7.71841 7.51012 7.33008C7.51012 6.55467 8.13403 5.92383 8.90092 5.92383C9.6678 5.92383 10.2917 6.55467 10.2917 7.33008C10.2917 8.10548 9.6678 8.73633 8.90092 8.73633C8.51685 8.73633 8.20552 9.05112 8.20552 9.43945V11.1973C8.20552 11.5856 8.51685 11.9004 8.90092 11.9004C9.28498 11.9004 9.59631 11.5856 9.59631 11.1973V10.0536C10.7947 9.7406 11.6825 8.63821 11.6825 7.33008C11.6825 5.77927 10.4347 4.51758 8.90092 4.51758Z" fill="black"/>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconQuestionRounded',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-question-rounded': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
