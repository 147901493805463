<template>
  <div>
    <div :class="componentClasses" :style="componentStyles" @click="clicked()">
      <header-simple class="gc-card-featured-image__header" :headerText="header" :icon="'forward'"/>
    </div>
  </div>
</template>

<script>
import HeaderSimple from '../headers/HeaderSimple'

export default {
  name: 'CardFeaturedImage',
  components: {
    HeaderSimple
  },
  props: {
    header: {
      default: ''
    },
    image: {
      default: ''
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-card-featured-image': true
      }
    },
    componentStyles: function () {
      return {
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),' + `url("${this.image}")`
      }
    }
  },
  methods: {
    clicked () {
      this.$emit('click')
    }
  }
}
</script>

<style scoped></style>
