import BaseAPI from '../BaseAPI'
import axios from 'axios'

export default class ProfileApi extends BaseAPI {
  constructor () {
    super()
    this._resource = 'credit-card'
    this._parentResources = ['account-details']
    // this.url = this._baseURL + this.endpoint
  }

  createCreditCardUpdateSession (payload = {}) {
    const url = this._baseURL
    const pathArr = [ 'account-details', 'credit-card', 'session' ]
    return axios.post(url + this._getPath(pathArr), payload)
  }
}
