<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.8789 4.01449C2.86945 7.04555 0.189573 8.08135 0.107712 8.16727C0.0301594 8.25319 0 8.33434 0 8.47277C0 8.61596 0.0301594 8.69234 0.107712 8.77826C0.168031 8.84031 1.40887 9.51812 2.86514 10.2866L5.51917 11.6804L6.93666 15.7139C7.7208 17.9335 8.38431 19.7903 8.41447 19.838C8.48771 19.9574 8.7333 20.0385 8.85824 19.9812C8.91425 19.9574 9.95691 19.0695 11.1719 18.0003C12.3912 16.9358 13.4037 16.0623 13.4295 16.0623C13.4511 16.0623 14.735 16.7831 16.2775 17.6662C17.8501 18.5635 19.1555 19.2795 19.2417 19.2939C19.3709 19.313 19.4226 19.2939 19.5347 19.1841L19.6682 19.0456L20.8531 9.72337C21.6458 3.45602 22.0207 0.367683 21.9991 0.296083C21.9474 0.148111 21.8139 0.0287781 21.6674 0.000137329C21.5769 -0.0141811 18.6385 1.07891 10.8789 4.01449ZM19.9741 10.0145C19.4097 14.4442 18.9401 18.0814 18.9272 18.0958C18.897 18.1292 11.3098 13.8093 11.3098 13.7568C11.3098 13.7377 13.2055 11.4084 15.5192 8.57778C20.685 2.26746 20.9694 1.92378 20.9823 1.94288C20.9909 1.95242 20.5342 5.58014 19.9741 10.0145ZM18.156 2.28655C18.1473 2.2961 15.3684 4.21975 11.9776 6.56344L5.82076 10.8165L3.72684 9.71383C2.57647 9.10762 1.61999 8.58733 1.59414 8.56346C1.56829 8.53482 1.58121 8.50618 1.63722 8.49186C1.68462 8.47754 5.39853 7.07419 9.88797 5.37011C14.3774 3.67082 18.0784 2.27701 18.1129 2.27224C18.1473 2.27224 18.1646 2.27701 18.156 2.28655ZM19.0607 2.87367C18.9142 3.04551 10.6894 13.1029 10.3662 13.5086C10.2327 13.6709 10.1034 14.0337 9.47867 15.9335C9.07798 17.1602 8.73761 18.1626 8.72037 18.1626C8.69883 18.1626 6.42826 11.752 6.38948 11.585C6.37656 11.5325 19.0694 2.70661 19.1728 2.70183C19.1943 2.69706 19.1426 2.77821 19.0607 2.87367ZM11.7191 15.0647C12.1844 15.332 12.5592 15.5659 12.5506 15.585C12.5334 15.6423 9.70271 18.1149 9.68116 18.091C9.65531 18.0671 10.81 14.5826 10.8445 14.5826C10.8617 14.5826 11.2538 14.7974 11.7191 15.0647Z" fill="#151515"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconPaperPlane',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-paper-plane': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
