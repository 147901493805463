import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    visible: false,
    image: null,
    slug: null,
    title: '',
    content: '',
    popupShown: false
  },
  mutations: {
    showPopup (state) {
      state.visible = true
    },
    hidePopup (state) {
      state.visible = false
      state.popupShown = true
      state.slug = null
    },
    setPopupShown (state) {
      state.popupShown = true
    },
    setContent (state, data) {
      state.popupShown = false
      state.title = data.title || ''
      state.slug = data.slug || ''
      state.content = data.content || ''
      state.image = data.image || null
    }
  },
  getters: {
    visible (state) {
      return state.visible
    },
    isPopupShown (state) {
      return state.popupShown
    },
    getInfo (state) {
      return {
        title: state.title,
        slug: state.slug,
        content: state.content,
        image: state.image
      }
    },
    getSlug (state) {
      return state.slug
    }
  }
}
