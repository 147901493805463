<template>
  <popup
    :visible="visible"
    v-if="visible"
    size="md"
    @hide="closePopup"
  >
    <popup-title>
      <popup-button-close  @hide="closePopup($event)"></popup-button-close>
    </popup-title>

    <PoupBody class="text-center">
      <popup-content>
        <popup-head>
          <div class="info-modal__head mood-tracker-modal__head" v-if="status==='init'">
            <popup-text-heading><p>{{ $t('message["mood-tracker.pop-up-general-heading"]') }}</p></popup-text-heading>
          </div>
          <div class="info-modal__sub-heading mood-tracker-modal__sub-head" v-if="status==='init'">
            <popup-text-body>
              <p>{{ $t('message["mood-tracker.pop-up-general-sub-heading"]') }}</p>
            </popup-text-body>
          </div>
        </popup-head>
      </popup-content>
      <popup-footer>
        <div v-if="status==='init'">
          <div class="mood-tracker-modal__popup-single-btn-wrap">
            <button-secondary :text="$t('message[\'mood-tracker.pop-up-edit-button-text\']')" @click="showEditPopup()"/>
          </div>
          <div class="mood-tracker-modal__popup-single-btn-wrap">
            <button-secondary :text="$t('message[\'mood-tracker.pop-up-delete-button-text\']')" @click="showDeletePopup()"/>
          </div>
        </div>
      </popup-footer>
    </PoupBody>
  </popup>
</template>

<script>
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import TextContent from '../../../../root/TextContent'
import TextArea from '../../../../global/inputs/TextArea'
import ButtonSecondary from '../../../../global/buttons/ButtonSecondary'
import IconCompleteFilled from '../../../../root/icons/IconCompleteFilled'
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import Popup from '../../../../global/popups/Popup'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupTextSubHeading from '../../../../global/popups/popup-sub-components/PopupTextSubHeading'
import PopupTextBody from '../../../../global/popups/popup-sub-components/PopupTextBody'
import PoupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupFooter from '../../../../global/popups/popup-sub-components/PopupFooter'
import DashBoardService from '../../../../../services/DashboardService'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'pop-up-add-mood',
  mixins: [popupAnimationMixin],
  components: {
    PopupFooter,
    PopupContent,
    PopupTitle,
    PopupHead,
    PopupTextHeading,
    PopupButtonClose,
    Popup,
    TextContent,
    TextArea,
    ButtonSecondary,
    IconCompleteFilled,
    LoadingBuffer,
    PopupTextSubHeading,
    PopupTextBody,
    PoupBody
  },
  props: {
    visible: { default: false }
  },
  data: function () {
    return {
      contentClass: 'info-modal__content info-modal__content--hidden custom-modal-padding fd-upload-image-popup',
      status: 'loading'
    }
  },
  computed: {
    ...mapGetters({
      getPlanExpiredPopupShow: 'getPlanExpiredPopupShow'
    })
  },
  mounted () {
    this.status = 'init'
  },
  methods: {
    ...mapMutations({
      showSubscriptionExpiredPopup: 'showSubscriptionExpiredPopup'
    }),
    closePopup (e) {
      this.closeAnimatedPopup(() => {
        this.$emit('close')
        // backdrop close event fire
        if (e && e.trigger === 'backdrop') {
          this.submitCloseEvent('MoodTracker', 'background')
        }
      })
    },
    showEditPopup () {
      let dashboard = new DashBoardService()
      if (!(dashboard.isEntriesEditable())) {
        if (!(this.getPlanExpiredPopupShow)) {
          this.closePopup()
          this.showSubscriptionExpiredPopup('moodTracker_edit_entry')
        }
      } else {
        this.$emit('showEditPopup')
      }
    },

    showDeletePopup () {
      this.$emit('showDeletePopup')
    }
  }
}
</script>
