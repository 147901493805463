<template>
    <popup :visible="visible" size="md" @hide="closePopup()" class="pc-profile-payment-popup__error">
      <popup-title>
        <popup-button-close  @click="closePopup"> </popup-button-close>
      </popup-title>
      <popup-body class="pc-profile-payment-popup__error_container">
        <popup-content>
          <popup-head>
            <popup-text-heading>
              {{ title }}
            </popup-text-heading>
            <popup-text-body>
              <img :src="successImage"  alt=""/>
            </popup-text-body>
          </popup-head>
        </popup-content>
        <popup-footer>
          <br>
        </popup-footer>
      </popup-body>
    </popup>
</template>

<style></style>

<script>
import popupAnimationMixin from '../../../../../../mixins/popupAnimationMixin'
import PopupTitle from '../../../../../global/popups/popup-sub-components/PopupTitle'
import Popup from '../../../../../global/popups/Popup'
import PopupButtonClose from '../../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupBody from '../../../../../global/popups/popup-sub-components/PopupBody'
import PopupContent from '../../../../../global/popups/popup-sub-components/PopupContent'
import PopupHead from '../../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupTextBody from '../../../../../global/popups/popup-sub-components/PopupTextBody'
import PopupFooter from '../../../../../global/popups/popup-sub-components/PopupFooter'
import ButtonPrimary from '../../../../../global/buttons/ButtonPrimary'
import Image from '../../../../../root/Image'

export default {
  components: {
    Popup,
    PopupTitle,
    PopupButtonClose,
    PopupBody,
    PopupContent,
    PopupHead,
    PopupTextHeading,
    PopupTextBody,
    PopupFooter,
    ButtonPrimary,
    Image
  },
  name: 'PopupPaymentSuccess',
  props: ['title', 'visible', 'error'],
  mixins: [popupAnimationMixin],
  data: function () {
    return {
      successImage: this.$appConfig.imagesUrl + 'swap-success.svg',
      imagesUrl: this.$appConfig.imagesUrl,
      logoIcon: this.$appConfig.imagesUrl + 'theme-switch.png'
    }
  },
  mounted () {
    setTimeout(() => {
      this.closePopup()
    }, 3000)
  },
  methods: {
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    }
  }
}
</script>
