<template>
  <div :class="componentClasses" @click="clicked()">
    <svg
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.05799 0.345374C3.90319 0.1751 3.63967 0.162551 3.4694 0.317346C3.29912 0.47214 3.28657 0.73566 3.44137 0.905934L5.11484 2.74675C3.69996 3.56657 2.67494 4.63746 1.98987 5.53153C1.60925 6.02826 1.33123 6.47345 1.14753 6.7962C1.05561 6.95768 0.987124 7.08885 0.941049 7.18091C0.918007 7.22695 0.900555 7.26324 0.888567 7.28869L0.874662 7.3186L0.87075 7.32721L0.869549 7.32989L0.868977 7.33117C0.821099 7.4389 0.820976 7.56217 0.868862 7.6699L1.24961 7.50066C0.868862 7.6699 0.869285 7.67085 0.869285 7.67085L0.869941 7.67232L0.871951 7.67677L0.878783 7.69168C0.884592 7.70424 0.892919 7.722 0.903803 7.7446C0.925568 7.7898 0.957579 7.85442 1.00015 7.93569C1.08527 8.09816 1.21279 8.32753 1.38531 8.6015C1.72992 9.14877 2.25644 9.87778 2.98625 10.6075C4.44801 12.0691 6.73525 13.5412 9.99789 13.5417C11.4218 13.5528 12.8292 13.2483 14.1192 12.6515L15.9414 14.6559C16.0962 14.8262 16.3597 14.8388 16.53 14.684C16.7002 14.5292 16.7128 14.2656 16.558 14.0954L4.05799 0.345374ZM1.24961 7.50066L0.868977 7.33117C0.868977 7.33117 0.868849 7.33145 1.24961 7.50066ZM1.73833 7.54898L1.71325 7.50064C1.75211 7.42493 1.8048 7.32608 1.87177 7.20841C2.03992 6.91298 2.2974 6.5003 2.65134 6.03837C3.31645 5.17036 4.31398 4.13906 5.68955 3.37899L7.32547 5.17851C6.80667 5.77602 6.50011 6.53376 6.46213 7.33217C6.4175 8.27037 6.74737 9.18788 7.37918 9.88287C8.01099 10.5779 8.893 10.9934 9.8312 11.0381C10.6296 11.0762 11.413 10.843 12.0571 10.3834L13.5287 12.0021C12.4164 12.4768 11.2162 12.7181 10.0029 12.7084H9.99961C7.01317 12.7084 4.92596 11.3686 3.57547 10.0182C2.89903 9.34184 2.40992 8.66475 2.09048 8.15745C1.93096 7.90413 1.81434 7.69409 1.73833 7.54898ZM7.89118 5.8008L11.4914 9.76109C11.015 10.0754 10.4481 10.2333 9.87087 10.2058C9.15343 10.1716 8.47895 9.85378 7.9958 9.32231C7.51264 8.79084 7.26039 8.08922 7.29452 7.37177C7.32198 6.79457 7.533 6.24516 7.89118 5.8008Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99995 1.45839C9.43572 1.45748 8.87238 1.50336 8.31573 1.59556C8.16519 1.62049 8.04031 1.72573 7.99023 1.86987C7.94014 2.01401 7.97286 2.17399 8.0755 2.2869L12.7975 7.48121C12.8007 7.4849 12.804 7.48853 12.8074 7.4921L15.9889 10.9918C16.1429 11.1612 16.4047 11.1747 16.5752 11.0219C17.4602 10.2293 18.096 9.39648 18.5109 8.76205C18.7186 8.4444 18.8717 8.17518 18.9737 7.9837C19.0247 7.88792 19.063 7.81146 19.0889 7.75798C19.1019 7.73123 19.1118 7.71022 19.1187 7.69539L19.1268 7.67784L19.1291 7.67265L19.1303 7.67011C19.1782 7.56238 19.1783 7.43919 19.1304 7.33145L18.7496 7.50066C19.1304 7.33145 19.1299 7.33051 19.1299 7.33051L19.1293 7.32904L19.1273 7.32459L19.1205 7.30968C19.1146 7.29711 19.1063 7.27935 19.0954 7.25674C19.0737 7.21154 19.0417 7.1469 18.9991 7.06562C18.914 6.90311 18.7864 6.67369 18.6139 6.39967C18.2693 5.85228 17.7428 5.12313 17.013 4.39325C15.5511 2.93113 13.2634 1.45849 9.99995 1.45839ZM18.7496 7.50066L19.1303 7.67011C19.1303 7.67011 19.1304 7.6699 18.7496 7.50066ZM12.6201 5.11813C13.1036 5.65 13.4131 6.31544 13.5094 7.02549L16.3216 10.1189C16.9849 9.47394 17.4771 8.82032 17.8134 8.30599C18.0059 8.01162 18.1465 7.76416 18.2382 7.59197C18.2559 7.55862 18.2719 7.5281 18.286 7.50064L18.2609 7.45226C18.1849 7.30712 18.0682 7.09703 17.9087 6.84365C17.5893 6.33624 17.1002 5.65899 16.4237 4.98247C15.0732 3.63183 12.9853 2.29172 9.99893 2.29172C9.74334 2.2913 9.48796 2.30134 9.23341 2.32177L10.8059 4.0515C11.5036 4.21485 12.1366 4.58626 12.6201 5.11813Z"
        fill="black"
      />
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconEyeClose',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-eye-close': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
