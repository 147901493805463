<template>
    <b-row v-bind="attr">
      <slot></slot>
    </b-row>
</template>

<script>
/**
    * ref - https://bootstrap-vue.org/docs/components/layout#comp-ref-b-row
   * Props
   * align-content  String            null        Align columns items together on the cross axis: 'start', 'center', 'end', 'around', 'between' or 'stretch'. Has no effect on single rows of items
   * align-h        String            null        Horizontal alignment/spacing of all columns: 'start', 'center', 'end', 'around', or 'between'
   * align-v        String            null        Vertical alignment of all columns in a row: 'start', 'center', 'end', 'baseline', or 'stretch'
   * cols           Number or String              The number row columns to create at the 'xs' breakpoint. Requires Bootstrap v4.4 CSS
   * cols-lg        Number or String              The number row columns to create at the 'lg' breakpoint. Requires Bootstrap v4.4 CSS
   * cols-md        Number or String              The number row columns to create at the 'md' breakpoint. Requires Bootstrap v4.4 CSS
   * cols-sm        Number or String              The number row columns to create at the 'sm' breakpoint. Requires Bootstrap v4.4 CSS
   * cols-xl        Number or String              The number row columns to create at the 'xl' breakpoint. Requires Bootstrap v4.4 CSS
   * no-gutters     Boolean         false         When set, removes the margin from the row and removes the padding from the child columns
   * tag            String          'div'         Specify the HTML tag to render instead of the default tag
   */
export default {
  name: 'GridRow',
  computed: {
    attr: function () {
      return this.$attrs
    }
  }
}
</script>

<style scoped>

</style>
