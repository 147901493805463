<template>
  <div>
    <div class="gc-card-text__list" >
     <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CardTextList',
  methods: {
    clicked () {
      this.$emit('clicked')
    }
  }
}
</script>
