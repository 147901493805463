<template>
    <div class="pc-content-text">
        <text-body-extra-small class="pc-content-text__text app-content-wrap" line-height="multi">
          <component :is="processedHtml"/>
        </text-body-extra-small>
    </div>
</template>
<script>
import contentContainerMixin from '../../../../../mixins/component-mixins/page/content/contentContainerMixin'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
export default {
  name: 'PageContentText',
  components: { TextBodyExtraSmall },
  mixins: [contentContainerMixin],
  props: {
    description: {
      default: ''
    }
  },
  mounted () {
    this.listeners()
    setTimeout(() => {
      document.querySelector('body')
        .addEventListener('click', this.linkNavigateClickHandler, true)
    }, 1000)
  },
  beforeDestroy () {
    this.destroyListeners()
  },
  methods: {
    // checkHasImage () {
    //   return !this.hideImage && Boolean(this.mainImage)
    // }
  }
}
</script>
