
export default class GenericRecipeService {
  id = null
  name = ''
  description = ''
  instructions = []
  macroNutrients = []
  images = []
  videos = []
  preparationTime = 0
  ingredients= []

  constructor (id) {
    this.id = id
  }

  getId () {
    return this.id
  }
  setName (name) {
    this.name = name
  }
  setDescription (description) {
    this.description = description
  }
  setVideos (videos) {
    this.videos = videos
  }
  setImages (images) {
    this.images = images
  }
  setPreparationTime (time) {
    this.preparationTime = time
  }
  setIngredients (ingredient) {
    this.ingredients = ingredient
  }
  setInstructions (instructions) {
    this.instructions = instructions
  }
  setMacroNutrients (macros) {
    this.macroNutrients = macros
  }
  setCalories (cal) {
    this.calories = cal
  }

  getName () {
    return this.name
  }
  getDescription () {
    return this.description
  }
  getVideos () {
    return this.videos.map(video => {
      if (typeof video === 'string') {
        return {url: video}
      }
      return video
    })
  }
  getImages () {
    return this.images
  }
  getPreparationTime () {
    return this.preparationTime
  }
  getIngredients () {
    return this.ingredients
  }
  getInstructions () {
    return this.instructions
  }
  getMacroNutrients () {
    return this.macroNutrients
  }
  getCalories () {
    return this.calories
  }
}
