<template>
  <div>
    <div v-if="notifications.length === 0" class="pc-notifications__empty-state-message text-center">
          <text-body-extra-small lineHeight="multi">{{ $t('message["notification.empty"]') }}</text-body-extra-small>
        </div>
        <card-notification v-else class="pc-notifications__notification"
          v-for="(  notification, index  ) in   notifications  " :key="'notification-' + index" :type="notification.type"
          :action="notification.action" :text="notification.message" @hide="hideNotification(index)"></card-notification>
  </div>
</template>

<script>
import CardNotification from './CardNotification'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import NotificationService from '../../../../../services/NotificationService'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'NotificationsContainer',
  components: {
    CardNotification,
    TextBodyExtraSmall
  },
  data () {
    return {
      notifications: []
    }
  },
  beforeMount () {
    this.showLoading()
    const service = new NotificationService()
    service.getFromAPI().then(res => {
      this.notifications = this.setNotifications(res)
      this.hideLoading()
    }).catch(() => {
      this.hideLoading()
    }).finally(()=>{
      pageReadyEvent()
    })
  },
  methods: {
    setNotifications (notifications) {
      return notifications.filter(notification => {
        return (
          typeof notification.action.url !== 'undefined'
        )
      })
    },
    hideNotification (key) {
      if (key > -1) {
        this.notifications.splice(key, 1)
      }
    }
  }
}
</script>
