import { extend } from 'vee-validate'
import {i18n} from '../../localization/i18n'

const validator = {
  params: ['limit'],
  validate (value, { limit }) {
    if (!(Array.isArray(value) && value.length <= limit)) {
      return i18n.t('message["validation.limit_selected_options"]').replace('{limit}', limit)
    } else {
      return true
    }
  }
}
extend('limit_selected_options', validator)
