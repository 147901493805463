<template>
  <div
    class="gc-sidebar"
    v-bind:class="{'gc-sidebar--hide':!showingMenu&&!closingMenu,'gc-sidebar--close':!showingMenu&&closingMenu}"
  >
    <slideout
      ref="menu"
      menu="#menu"
      panel="#panel"
      :toggleSelectors="['.toggle-button']"
      @on-open="onMenuOpens()"
      :duration="500"
      @on-beforeclose="closedMenu()"
    >
      <div class="gc-sidebar__bg" v-if="showingMenu" v-on:click="closeMenu"></div>
      <nav id="menu" v-show="clicked" v-on:scroll.passive="setScroll">
        <div>
          <a v-on:click="showParent = !showParent" v-show="!showParent"></a>
          <div class="gc-sidebar__user-details-wrap" @click="navigate('/account-details?page=account-settings')">
            <div class="gc-sidebar__image-wrap">
              <img :src="profileImage" @onerror="imageLoadOnError" alt="profile-image"/>
            </div>
            <text-content
              class="gc-sidebar__profile-name"
              :weight="'extra-bold'"
              :size="'sm4'"
              :lineHeight="'multi'">
              {{ $t('message["general.sidebar-title"]', {name: charLimitedUserName}) }}
            </text-content>

          </div>
          <div v-for="link in navLinks"
               :class="['link_'+link.code]"
               :key="'link-'+link.code">
            <side-bar-button-primary
              @click="navigateLink(link)"
              v-bind:class="{'gc-side-bar__button-primary__selected':(isCurrentRoute(link))}">
              {{ showLinkText(link.label) }}
              <icon-ellipse size="sm1" class="gc-sidebar__link-icon-new" v-if="link.isNew"/>
            </side-bar-button-primary>
          </div>

          <side-bar-button-primary
            v-if="showWorkoutLogLink&&workoutPlans.length>0&&isMember"
            :class="{'gc-side-bar__button-primary__selected':($router.currentRoute.fullPath.includes('workout-log'))}"
            @click="navigate('/workout-programs/?workout-log=1')"
          >
            {{ $t('message["workout-log.title"]') }}
          </side-bar-button-primary>

          <side-bar-button-primary
            v-if="isMember"
            @click="navigate('/account-details')"
            v-bind:class="{'gc-side-bar__button-primary__selected':(curruntRoute === '/account-details')}">
            {{ $t('message["profile.title"]') }}
          </side-bar-button-primary>

          <side-bar-button-primary
            :href="void(0)"
            @click="showLogoutPopup">
            {{ $t('message[\'menu.logout\']') }}
          </side-bar-button-primary>

          <ul class="side-menu-bottom-wrap">
            <li>
              <button-link @click="openTermsAndConditionsPopup()"
                           :text="$t('message[\'settings.terms.and.conditions\']')"></button-link>
            </li>
            <li>
              <button-link @click="openPrivacyPolicyPopup()"
                           :text="$t('message[\'settings.privacy-policy\']')"></button-link>
            </li>
            <li>
              <button-link :text="getTrainerIDInfo() + ' | ' + appVersion"></button-link>
            </li>
          </ul>
        </div>
      </nav>
      <main id="panel"></main>
      <header>
        <div>
          <a class="btn btn-back toggle-button slideout-panel--button"
             v-if="!showingMenu" v-on:click="clicked=true">
            <icon-ellipse class="gc-sidebar__icon-new" size="sm1" v-if="features.hasNew"/>
            <icon-menu/>
          </a>
          <a style="display: none;"
             class="btn btn-back toggle-button slideout-panel--button" v-else v-on:click="clicked=true">
          </a>
        </div>
      </header>
    </Slideout>
    <content-page-popup :page="'privacy-policy'" :visible="showPrivacyPolicyPopup" v-bind:key="'privacy-policy-popup'"
                        v-if="showPrivacyPolicyPopup"
                        v-on:close="closePrivacyPolicyPopup()"></content-page-popup>
    <content-page-popup :page="'legal-disclaimer'" :visible="showTermsCondPopup" v-bind:key="'legal-disclaimer-popup'"
                        v-if="showTermsCondPopup"
                        v-on:close="closeTermsAndConditionsPopup()"></content-page-popup>
    <popup-logout-confirmation :visible="showLogoutConfirmationPopup"
                               v-on:close="closeLogoutConfirmationPopup()"
                               v-on:confirm="logout"/>
  </div>
</template>
<script>

import sideBarMixin from '../../../mixins/component-mixins/global/sideBarMixin'
import IconMenu from '../../root/icons/IconMenu'
import SideBarButtonPrimary from '../../global/navigators/side-bar-sub-components/SideBarButtonPrimary'
import SideBarButtonCollapse from '../../global/navigators/side-bar-sub-components/SideBarButtonCollapse'
import ButtonLink from '../../global/buttons/ButtonLink'
import ButtonLinkSecondary from '../../global/buttons/ButtonLinkSecondary'
import TextContent from '../../root/TextContent'
import DashboardService from '../../../services/DashboardService'
import IconEllipse from '../../root/icons/IconEllipse'
import PopupConfirmation from '../popups/PopupConfirmation'
import PopupLogoutConfirmation from '../popups/PopupLogoutConfirmation'

export default {
  name: 'sideBar',
  components: {
    PopupLogoutConfirmation,
    PopupConfirmation,
    IconMenu,
    SideBarButtonPrimary,
    ButtonLink,
    ButtonLinkSecondary,
    TextContent,
    SideBarButtonCollapse,
    IconEllipse
  },
  data: function () {
    return {
      isContentPage: false,
      charLimitedUserName: this.userName,
      showLogoutConfirmationPopup: false
    }
  },
  mixins: [sideBarMixin],
  beforeMount () {
    this.loadCommunities()
    this.settingCurruntRoute()
    this.isContentPage = this.curruntRoute.startsWith('/page')
    let date = new Date()
    this.year = date.getFullYear()

    let service = new DashboardService()
    service.getNavigation().then(data => {
      this.navLinksList = data.links
    })
    this.showWorkoutLog()
    this.checkNewFeatures()
  },
  mounted () {
    this.setUserNameCharLimit()
  },
  methods: {
    isCurrentRoute (link) {
      if (link.url.endsWith('/dashboard') && this.$route.path === link.url && Object.values(this.$route.query).length > 0) {
        return false
      }
      if (Object.keys(this.$route.query).length > 0) {
        if (Object.keys(this.$route.query)[0] === 'workout-log-history') {
          return false
        }
      }
      if (link.url.includes('community')) {
        return this.$route.fullPath === link.url
      }
      return this.curruntRoute === link.url
    },
    open () {
      this.clicked = true
      if (this.$children[0].slideout) {
        this.$children[0].slideout.toggle()
      }
    },
    setUserNameCharLimit () {
      let username = this.userName
      if (username.length > 8) {
        username = username.substring(0, 8)
        this.charLimitedUserName = username + '...'
      } else {
        this.charLimitedUserName = username
      }
    },
    showLogoutPopup () {
      this.showLogoutConfirmationPopup = true
    },
    closeLogoutConfirmationPopup () {
      this.showLogoutConfirmationPopup = false
    }
  }
}

</script>
