<template>
    <component v-if="dynamicComponent && notificationData" v-bind:is="dynamicComponent"
               v-bind="notificationData">
               </component>
</template>

<script>
import MemberDisconnected from './popups/MemberDisconnected'
import NotificationPopupService from '../../../../services/NotificationPopupService'
import lodash from 'lodash'

export default {
  // this will handle unread notifications (popup notifications)
  name: 'Handler',
  components: {
    MemberDisconnected
  },
  data () {
    return {
      dynamicComponent: null,
      notificationType: '',
      notificationData: null
    }
  },
  mounted () {
    this.setNotification()
  },
  methods: {
    setNotification () {
      const service = new NotificationPopupService()

      service.getMemberNotifications()
        .then((messages) => {
          let firstNotification = lodash.first(messages)
          if (firstNotification) {
            this.notificationType = firstNotification.type
            this.notificationData = firstNotification.message
          }

          this.setPopup()
        }).catch(() => {
          // ignore
        })
    },
    setPopup () {
      switch (this.notificationType) {
        case 'member_disconnected':
          this.dynamicComponent = MemberDisconnected
          break
      }
    }
  }
}
</script>

<style scoped>

</style>
