<template>
  <div class="form-check btn-choice" v-if="title_key!==''">
    <button-choice :selected="selected" type="button" :text="title"  @click="choiceClicked"></button-choice>
  </div>

</template>

<script>
import ButtonChoice from '../../../../global/buttons/ButtonChoice'
/**
 * basic radio button component
 * used in RadioButtonGroup also can be used directly
 * emits the inout and change $events
 *
 */
export default {
  name: 'RadioButton',
  components: {ButtonChoice},
  data: function () {
    return {
      selected: false
    }
  },
  props: ['description', 'title_key', 'name', 'value', 'title'],
  watch: {
    value: {
      immediate: true,
      handler: function () {
        if (this.value === this.title_key) {
          this.selected = true
        } else {
          this.selected = false
        }
      }
    }
  },
  computed: {
    /**
     * check component have an description
     * @returns {boolean}
     */
    hasDescription () {
      return !(this.description === '' || typeof this.description === 'undefined')
    },
    /**
     * set and get the radio value
     */
    radioValue: {
      get: function () {
        return this.value
      },
      set: function () {
        this.$emit('input', this.title_key)
        this.$emit('change', this.title_key)
      }
    }
  },
  methods: {
    choiceClicked () {
      this.$emit('input', this.title_key)
      this.$emit('change', this.title_key)
    }
  }
}
</script>
