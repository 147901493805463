import PushNotificationsService from '@/services/PushNotificationsService'

export default class NativeAppService {
  inAppBrowsing= false
  inAppPurchases= false
  microphonePermissions= false
  cameraPermissions= false
  pushDeviceStateResponse = null
  pushPersonalization = false
  constructor () {
    if (!NativeAppService.instance) {
      NativeAppService.instance = this
      this._listen()
      this.sendWhatCanDoEvent()
    }
    return NativeAppService.instance
  }

  sendWhatCanDoEvent () {
    try {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          command: {
            name: 'whatYouCan'
          }
        })
      )
    } catch (e) {
        console.log(e)
    }
  }

  showAppBrowser (url) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        command: {
          name: 'openInAppBrowserUrl',
          args: {
            url: url
          }
        }
      })
    )
  }

  sendIdentifyCustomerRequest (user) {
    const ids = [user.reseller_id, user.trainer_id, user.id]
    const command = {
      name: 'identifyCustomerRequest',
      args: {
        customer: {
          id: ids.join('-')
        }
      }
    }

    this._sendReactNativeWebViewPostMessage(command)
  }
  canSendPushNotifications () {
    return this.pushPersonalization
  }

  getPushDeviceStateRequest () {
    if (this.pushPersonalization) {
      const command = {
        name: 'getPushDeviceStateRequest',
        args: { }
      }
      this._sendReactNativeWebViewPostMessage(command)
    }
  }


  _sendReactNativeWebViewPostMessage (command) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        command: command
      })
    )
  }



  _listen () {
    window.addEventListener('message', (ev) => {
      try {
        const data = JSON.parse(ev.data)
        if (data.name && data.name === 'whatYouCanResponse') {
          if (data.args) {
            this.inAppBrowsing = data.args.inAppBrowsing || false
            this.inAppPurchases = data.args.inAppPurchases || false
            this.microphonePermissions = data.args.microphonePermissions || false
            this.cameraPermissions = data.args.cameraPermissions || false
            this.pushPersonalization = data.args.pushPersonalization || false
          }
        } else if (data.name && data.name === 'getPushDeviceStateResponse') {
          if (data.args) {
            this.pushDeviceStateResponse = data.args
            const pushNotifications = new PushNotificationsService()
            pushNotifications.registerDevice(this.pushDeviceStateResponse.userId)
          }
        } else if (data.name && data.name === 'ping') {
          this.sendPongEvent()
        }
      } catch (e) {
        // no json returned
      }
    })
  }

  sendPongEvent () {
    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(
      JSON.stringify({
        command: {
          name: 'pong'
        }
      })
    )
  }

  _destroyListener () {
    window.removeEventListener('message')
  }

  removeListeners () {
    this._destroyListener()
  }
}
