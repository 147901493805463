<template>
  <div class="video-container">
    <div class="card-search" v-if="noDelayTags && noDelayTags.length>0">
      <p>{{$t("message['settings.filter-tags']")}}</p>
      <div class="form-group ma-form">
        <select2 v-if="noDelayTags && noDelayTags.length !== 0" :multiple="true" :options="noDelayTags" v-model="selectedTag"></select2>
      </div>
    </div>
    <div v-if="videos && videos.length > 0">
      <div v-for="video in videos" v-bind:key="video.id">
        <videoArea :video="video" ref="video" v-if="selectedTag && selectedTag.length ===0 || showVideo(video.tags) " />
      </div>
    </div>
  </div>
</template>

<script>
import Select2 from '../../global/widgets/InputSelect2'
import VideoArea from './VideoArea'
import DashboardService from '../../../../../services/DashboardService'
import NativeAppMixin from '../../../../../mixins/NativeAppMixin'
import moment from 'moment'
export default {
  name: 'video-container',
  components: { Select2, VideoArea },
  mixins: [NativeAppMixin],
  props: {
    tags: {},
    videoList: {}
  },
  data: function () {
    return {
      selectedTag: [],
      videos: [],
      noDelayTags: [],
      convert: {
        m: 60000,
        h: 3600000,
        d: 86400000,
        w: 604800000,
        y: 31536000000
      }
    }
  },
  watch: {
    videoList (newList, oldList) {
      if (oldList.length === 0 && newList.length > 0) {
        const service = new DashboardService()
        service.getUserData()
          .then(response => {
            const compare = (response.subscriptions && response.subscriptions[0]) ? response.subscriptions[0].created_at : moment().format()
            this.startDate = new Date(compare).getTime()
            const videos = newList
            const filter = this.filterVideos(videos)
            this.noDelayTags = filter.tags
            this.videos = filter.videos
          })
      }
    }
  },
  mounted () {
    this.resizeVideoListener()
    document.querySelector('body')
      .addEventListener('click', this.linkNavigateClickHandler, true)
  },
  methods: {
    resizeVideoListener () {
      window.addEventListener('resize', this.resizeVideos)
    },
    resizeVideos () {
      this.videos.forEach((video, index) => {
        this.$refs.video[index].resizeVideo()
      })
    },
    showVideo (VideoTag) {
      const res = VideoTag.findIndex(ele => {
        return this.selectedTag.includes(ele)
      })

      return (res >= 0)
    },
    filterVideos (videos) {
      let noDelayTags = []
      for (let i = 0; i < videos.length; i++) {
        const tags = videos[i].tags
        const tmpNoDelay = []
        let spliced = false
        if (tags) {
          for (let j = 0; j < tags.length; j++) {
            const tdelay = tags[j].match(/^delay(\d+)(m|h|d|w|y)$/)
            if (tdelay) {
              spliced = this.spliceVideo(i, videos, tdelay)
            } else {
              const tagIndex = noDelayTags.findIndex((item, i) => {
                return item.id === tags[j]
              })
              if (tagIndex === -1) {
                tmpNoDelay.push({ id: tags[j], text: tags[j] })
              }
            }
          }
          if (!spliced) {
            noDelayTags = [...noDelayTags, ...tmpNoDelay]
          } else {
            i--
          }
        }
      }
      return { videos: videos, tags: noDelayTags }
    },
    spliceVideo (i, videos, tdelay) {
      let spliced = false
      const amt = tdelay[1]
      const period = tdelay[2]
      let now = new Date()
      if (period === 'd' || period === 'w' || period === 'y') {
        now.setHours(0, 0, 0, 0)
        now = now.getTime()
      } else {
        now = now.getTime()
      }
      const compare = now - amt * this.convert[period]
      if (compare < this.startDate) {
        videos.splice(i, 1)
        spliced = true
      }
      return spliced
    },
    linkNavigateClickHandler (event) {
      let targetElement = event.target
      const selector = '.video-container .content-text a'
      while (targetElement != null) {
        if (targetElement.matches(selector)) {
          event.preventDefault()
          this.handleUrlNavigate(targetElement.getAttribute('href'))
          return
        }
        targetElement = targetElement.parentElement
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeVideos)
    document.querySelector('body')
      .removeEventListener('click', this.linkNavigateClickHandler, true)
  }
}
</script>
