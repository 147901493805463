<template>
  <page-secondary class="pc-workout-log-history" :header="headerOptions">
    <page-container class="pc-workout-log-history__container">
      <workout-log-history-statistics
        v-if="statistics.best"
        :title="$t('message[\'workout-log.personal-best\']')"
        :best-score="
          getValue(statistics.best) + ' ' + getUnitByValue(statistics.best)
        "
        :mean-score="
          $t('message[\'workout-log.mean-value\']', {
            type: statistics.label,
            value: getMeanValue(),
            unit: getUnitByValue(statistics.best),
          })
        "
      />

      <loading-buffer v-if="status === 'loading'" />
      <workout-log-history-empty
        v-if="history.length === 0 && status !== 'loading'"
      />
      <card-workout-history
        class="pc-workout-log-history__workout-history-card"
        :edit-inside="true"
        :entries="item.entries"
        :exercise-i-d="item.program_exercise_id"
        :exercise-name="exerciseName"
        :day-name="dayName"
        :week-name="weekName"
        :logged-at="item.logged_at"
        :metrics="metrics"
        :note="item.note"
        :sets="setSets(item.sets)"
        :unit-system-value="unit"
        :workout-i-d="workoutID"
        v-bind:key="'history-workout-log-stat' + index"
        v-for="(item, index) in history"
        v-show="showItem(item.program_exercise_id)"
        v-on:reload="loadData(true)"
        v-on:request-delete="goToDelete(item.program_exercise_id)"
        v-on:request-edit="
          goToEdit(item.week.id, item.day.id, item.program_exercise_id)
        "
      />
      <popup-workout-log-remove-logged-data
        :exercise-i-d="toDeleteExercise"
        :visible="requestDelete"
        v-if="requestDelete"
        v-on:close="requestDelete = false"
        v-on:success="deleteSuccess(toDeleteExercise)"
      />
      <popup-general-exercise
        :image="exercisePopup.image"
        :instructions="exercisePopup.instructions"
        :muscles="exercisePopup.muscles"
        :name="exercisePopup.name"
        :video="exercisePopup.video"
        :visible="exercisePopup.visible"
        v-if="exercisePopup.visible"
        v-on:close="closeInfoPopup()"
      ></popup-general-exercise>
    </page-container>
  </page-secondary>
</template>

<script>
import workoutLogLogListMixin from '../../../../mixins/component-mixins/page/workout/workoutLogLogListMixin'
import BaseComponent from '../global/base/BaseComponent'
import WorkoutLogService from '../../../../services/WorkoutLogService'
import LoadingBuffer from '../global/widgets/LoadingBuffer'
import WorkoutLogStat from './widgets/WorkoutLogStat'
import PopupWorkoutLogRemoveLoggedData from './page-components/popups/PopupWorkoutLogMarkIncomplete'
import { mapGetters, mapMutations } from 'vuex'
import DashBoardService from '../../../../services/DashboardService'
import unitSystem from '../../../../config/measurement-units'
import WorkoutLogHistoryEmpty from './widgets/WorkoutLogHistoryEmpty'
import WorkoutProgramsService from '../../../../services/WorkoutProgramsService'
import PopupGeneralExercise from './widgets/PopupGeneralExercise'
import PageSecondary from '../../../global/pages/PageSecondary'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import IconTrophy from '../../../root/icons/IconTrophy'
import TextBodyRegular from '../../../global/typography/TextBodyRegular'
import TextHeading3 from '../../../global/typography/TextHeading3'
import CardWorkoutHistory from '../../../global/cards/CardWorkoutHistory'
import WorkoutLogHistoryStatistics from './page-components/workout-log-sub-components/WorkoutLogHistoryStatistics'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'ContactUs',
  mixins: [workoutLogLogListMixin],
  extends: BaseComponent,
  components: {
    LoadingBuffer,
    WorkoutLogStat,
    PopupWorkoutLogRemoveLoggedData,
    WorkoutLogHistoryEmpty,
    PopupGeneralExercise,
    PageSecondary,
    PageContainer,
    IconTrophy,
    TextBodyRegular,
    TextHeading3,
    CardWorkoutHistory,
    WorkoutLogHistoryStatistics
  },
  data: function () {
    return {
      status: 'loading',
      history: [],
      metrics: [],
      toDeleteExercise: 0,
      requestDelete: false,
      exerciseName: '',
      page: 'workout-log',
      pageClass: [
        'macroapp--page-workout-log',
        'macroapp--page-workout-log--history'
      ],
      error: '',
      statistics: {},
      exercisePopup: {
        visible: false,
        name: '',
        image: '',
        video: '',
        muscles: [],
        instructions: ''
      },
      headerOptions: {
        unit: 'metric',
        show: true,
        left: 'previous',
        right: 'info',
        rightDisabled: false,
        helpContent: '',
        modifiers: ['with-bg-color', 'small-title'],
        header: {
          mainTitle: '',
          subTitle: ''
        }
      }
    }
  },
  mounted () {
    this.setStoreData({ workoutId: 1, exerciseID: 1 })
    this.loadPageData()
  },
  computed: {
    ...mapGetters({
      getInfoButtonClick: 'pageStore/getInfoButtonClick'
    })
  },
  watch: {
    getInfoButtonClick: function (newVal, oldVal) {
      if (!this.exercisePopup.visible) {
        this.showInfoPopup()
      }
    }
  },
  methods: {
    ...mapMutations({
      setStoreData: 'singleExercisePageStore/init'
    }),
    loadPageData () {
      this.status = 'loading'
      this.history = []
      const service = new WorkoutLogService()
      const dashBoardService = new DashBoardService()
      const userPromise = dashBoardService.getUserData()
      const workoutLogPromise = service.getGenericExerciseHistory(
        this.$route.query['workout-log-history'],
        true
      )
      Promise.all([userPromise, workoutLogPromise])
        .then((values) => {
          const logData = values[1]
          const userData = values[0]
          this.unit = userData.measurement_system
          this.timeZone = userData.timezone
          this.history = []
          this.history = logData.data.history
          this.metrics = logData.data.metrics
          this.statistics = logData.data.statistics

          this.exercisePopup.name = logData.data.name
          this.exercisePopup.muscles = logData.data.muscles
          this.exercisePopup.instructions = JSON.parse(
            logData.data.instructions
          )
          this.exercisePopup.image = logData.data.images[0]
          this.exercisePopup.video = logData.data.videos[0]
        })
        .finally(() => {
          this.hideLoading()
          pageReadyEvent()
          this.status = 'loaded'
        })

      this.headerOptions.header.mainTitle = this.$route.query.name
      this.exerciseName = this.$route.query.name
      this.setPageClass()
      this.setHeader(this.headerOptions)
    },
    showInfoPopup () {
      this.exercisePopup.visible = true
    },
    closeInfoPopup () {
      this.exercisePopup.visible = false
    },
    getMetrics (entries) {
      if (!entries[0]) {
        return []
      }
      return entries[0].attributes.map((data) => {
        return {
          type: data.type,
          label: this.$i18n.t('message["workout-log.' + data.type + '"]')
        }
      })
    },
    getSets (log) {
      const sets = log.entries.map((data) => {
        return '1'
      })
      if (log.sets) {
        return log.sets.replace(/ /g, '/')
      }
      return sets.join('/')
    },
    loadData () {
      this.loadPageData()
    },
    goToDelete (exerciseId) {
      this.toDeleteExercise = exerciseId
      this.requestDelete = true
    },
    deleteSuccess () {
      this.loadPageData()
    },
    getValue (value) {
      if (this.statistics.type === 'duration') {
        const val = parseInt(value)
        const min = parseInt(val / 60)
        const sec = parseInt(val % 60)
        if (min > 0 && sec > 0) {
          return this.setTimerNumbers(min) + ':' + this.setTimerNumbers(sec)
        } else if (min > 0) {
          return this.setTimerNumbers(min)
        } else {
          return this.setTimerNumbers(sec)
        }
      } else if (this.statistics.type === 'reps') {
        return Math.round(value)
      }
      if (parseFloat(value).toString() === parseInt(value).toString()) {
        return value
      } else {
        return parseFloat(value).toFixed(2)
      }
    },
    getUnitByValue (value) {
      if (this.statistics.type === 'duration') {
        const val = parseInt(value)
        const min = parseInt(val / 60)
        const sec = parseInt(val % 60)
        if (min > 0 && sec > 0) {
          return 'min'
        } else if (min > 0) {
          return 'min'
        } else {
          return 'sec'
        }
      }
      return unitSystem[this.unit][this.statistics.type]
    },
    setTimerNumbers (num) {
      num = parseInt(num)
      return num < 10 ? '0' + num : num
    },
    getMeanValue () {
      return this.getValue(this.statistics.mean)
    }
  },
  beforeDestroy () {
    const workoutService = new WorkoutProgramsService()
    workoutService.reset()
    this.resetHeader()
    this.resetBackground()
  }
}
</script>

<style scoped></style>
