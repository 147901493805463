<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0101 4.08418C14.1988 6.27285 14.1988 9.82139 12.0101 12.0101C9.82146 14.1987 6.27293 14.1987 4.08426 12.0101C1.89559 9.82139 1.89559 6.27285 4.08426 4.08418C6.27293 1.89551 9.82146 1.89551 12.0101 4.08418ZM13.0946 13.0468C15.8452 10.2705 15.8373 5.79003 13.0708 3.02352C10.2963 0.249067 5.79805 0.249066 3.0236 3.02352C0.249141 5.79798 0.249141 10.2963 3.0236 13.0707C5.79011 15.8372 10.2706 15.8451 13.0469 13.0945L12.7752 13.3662L16.3212 16.9123L17.3819 15.8516L13.8358 12.3056L13.0946 13.0468Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconSearch',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-search': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
