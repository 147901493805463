<template>
    <side-bar-button-primary
            @click="goToMetrics()"
    >
        <template #default>
            {{$t('message["navigator.bottom-bar.metrics"]').toLowerCase()}}
        </template>
        <template #icon>
            <icon-metrics size="sm2"/>
        </template>
    </side-bar-button-primary>
</template>
<script>
import IconMetrics from '../../root/icons/IconMetrics'
import SideBarButtonPrimary from './side-bar-sub-components/SideBarButtonPrimary'
import globalEventBus from '../../../../../event-buses/globalEventBus'

export default {
  name: 'ButtonSideBarMetrics',
  components: {
    IconMetrics,
    SideBarButtonPrimary
  },
  methods: {
    goToMetrics () {
      if (this.$route.fullPath === '/dashboard') {
        globalEventBus.$emit('metrics-clicked')
      } else if (this.$route.fullPath.includes('mood-tracker') || this.$route.fullPath.includes('period-tracker') || this.$route.fullPath.includes('habit-tracker')) {
        this.$router.push({ path: '/dashboard' })
        setTimeout(() => {
          globalEventBus.$emit('metrics-clicked')
        }, 1000)
      } else {
        this.navigateToDashboard().then(() => {
          globalEventBus.$emit('metrics-clicked')
          setTimeout(() => {
            globalEventBus.$emit('metrics-clicked')
          }, 1000)
        })
      }
    },
    navigateToDashboard () {
      return new Promise((resolve) => {
        this.$router.push({ path: '/dashboard' })
        this.$router.afterEach(() => {
          resolve()
        })
      })
    }
  }

}
</script>
