<template>
  <page-image-cover :class="componentClasses" :header="headerOptions" :background-image="backgroundImage">
    <div class="pc-request-new-password__start-header">
      <div class="pc-request-new-password__logo">
        <img :style="logoShow?{}:logoStyle" :src="logo" alt="" @onerror="logoShow= false">
      </div>
    </div>
    <!--   page wrapper -->
    <page-container>
      <validation-observer ref="form" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submitForm)">
          <div class="pc-request-new-password__card-sign-in">
            <div class="pc-request-new-password__header">
                <text-content :weight="'extra-bold'"
                              :size="'lg1'"
                              :lineHeight="'multi'"
                              class="text-center" >
                {{ $t("message[\'auth.forgotPassword\']") }}
                </text-content>
            </div>
            <div class="pc-request-new-password__inputs">
              <div class="form-group ma-form">
                <validation-provider :name="$t('message[\'auth.email\']')"
                                    :vid="'email'"
                                    rules="required|email"
                                    v-slot="{ errors }">

                  <text-input :type="'email'"
                                :placeholder="$t('message[\'auth.email\']')"
                                :errorMessage="errors[0]"
                                v-model="formData.email" />
                </validation-provider>
              </div>
            </div>
            <div class="pc-request-new-password__buttons">
              <button-primary type="submit"
                              :text="$t('message[\'auth.submit\']')" />
              <button-primary type="button"
                              @click="goBack()"
                              class="pc-request-new-password__buttons-back"
                              :text="$t('message[\'forgot-password.back-to-login\']')" />
            </div>
          </div>
        </form>
      </validation-observer>
    </page-container>
    <div class="app-bg-overlay"></div>
    <forget-password-popup :visible="successPopupShow" :title="successPopupTitle" v-on:close="successPopupShow=false"/>
  </page-image-cover>
</template>

<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '../../../../helpers/validation'
import ForgetPasswordPopup from './widgets/ForgetPasswordPopup'
import requestNewPasswordMixin from '../../../../mixins/component-mixins/page/auth/requestNewPasswordMixin'
import TextInput from '../../../global/inputs/TextInput'
import ButtonPrimary from '../../../global/buttons/ButtonPrimary'
import ButtonSecondary from '../../../global/buttons/ButtonSecondary'
import TextContent from '../../../root/TextContent'
import PageImageCover from '../../../global/pages/PageImageCover'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'AddNewPassword',
  mixins: [requestNewPasswordMixin],
  created () {
    this.setTrainer('')
    this.setPageTitle(this.$t("message['auth.forgot-password']"))
  },
  mounted () {
    this.setImages()
    setTimeout(() => {
      this.hideLoading()
      pageReadyEvent()
    }, 1500)
    if (templateConfig && templateConfig.style_variables && templateConfig.style_variables['var(--bg-homePages)']) {
      document.body.style.background = templateConfig.style_variables['var(--bg-homePages)']
    }
  },
  data: function () {
    return {
      headerOptions: {
        show: false

      }
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'pc-request-new-password': true,
        'pc-request-new-password__desktop-view': this.isDesktop,
        'pc-request-new-password__tablet-view': this.isTablet
      }
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    ForgetPasswordPopup,
    TextInput,
    ButtonPrimary,
    ButtonSecondary,
    TextContent,
    PageImageCover,
    PageContainer
  }
}
</script>

<style scoped>

</style>
