import BaseAPI from '../BaseAPI.js'
import axios from 'axios'

export default class extends BaseAPI {
  _resource = 'progress'

  getUpdateData () {
    const url = this._getParentURL()
    return axios.get(url + this._resource + '/update')
  }

  updateData (data) {
    const url = this._getParentURL()
    return axios.post(url + this._resource + '/update', data)
  }

  getHistory (page = null, perPage = null) {
    const url = this._baseURL
    const pathArr = [this._resource, 'history']
    return axios.get(url + this._getPath(pathArr),
      {
        params: { page: page, per_page: perPage }
      }
    )
  }

  getHistoryByRange (type, startDate, endDate) {
    const url = this._baseURL
    const pathArr = [this._resource, 'history', type]
    return axios.get(url + this._getPath(pathArr),
      {
        params: { start_date: startDate, end_date: endDate }
      }
    )
  }

  getFirstTimeData () {
    const url = this._baseURL
    const pathArr = [this._resource, 'first']
    return axios.get(url + this._getPath(pathArr))
  }

  /**
   *
   * @param payload -type of the formData
   * @returns {Promise<AxiosResponse<T>>}
   */
  uploadImage (formData) {
    const url = this._baseURL
    const pathArr = [this._resource, 'images']
    return axios.post(url + this._getPath(pathArr), formData)
  }

  deleteImage (id) {
    const url = this._baseURL
    const pathArr = [this._resource, 'images', id]
    return axios.delete(url + this._getPath(pathArr))
  }
}
