<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.9795 1H4C4.43562 9.8 8.17466 12 9.98973 12C15.2171 11.1619 16.161 4.31746 15.9795 1Z" fill="#FFCB0E"/>
<path d="M19 2H15.5V3H18C17.2 7 15 8.33333 14 8.5L13 9.5C17 9.5 18.6667 4.5 19 2Z" fill="#FFCB0E"/>
<path d="M1 2H4.5V3H2C2.8 7 5 8.33333 6 8.5L7 9.5C3 9.5 1.33333 4.5 1 2Z" fill="#FFCB0E"/>
<path d="M14.5 16.5C12.1 16.1 10.8333 13 10.5 11.5H9.5C8.7 15.1 6.5 16.3333 5.5 16.5V20.5H5V21V21.5H15V21V20.5H14.5V16.5Z" fill="#FFCB0E"/>
<path d="M14.5 20.5V16.5H5.5V20.5H5V21V21.5H15V21V20.5H14.5Z" fill="#474D5D"/>
<path d="M12 20.5V16.5H14.5V20.5H15V21.5H12.5V20.5H12Z" fill="#2E3444"/>
<path d="M8 20.5V16.5H5.5V20.5H5V21.5H7.5V20.5H8Z" fill="#606876"/>
<path d="M7.5 20.5V16.5H5.5V20.5H5V21.5H7V20.5H7.5Z" fill="#474D5D"/>
<path d="M9.5 12C9.5 14 8.16667 15.8333 7.5 16.5H8C9.5 15.5 10 13 10 12H9.5Z" fill="#FCE699"/>
<path d="M6.50001 1C6.50001 5.5 8.5 10.5 10 12C9 11.5 6 8.5 5.5 1H6.50001Z" fill="#FCE699"/>
<path d="M14 1H16C16 11.5 10.5 12 10.5 12C10.5 12 14 8 14 1Z" fill="#F9A01B"/>
<path d="M12.5 16.5H14.5C12 16 10.5 12 10.5 12C11 15 11.8333 16.3333 12.5 16.5Z" fill="#F9A01B"/>
</svg>

  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconTrophy',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-trophy': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
