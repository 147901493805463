import BaseAPI from '../BaseAPI.js'
import axios from 'axios'

export default class extends BaseAPI {
  _resource ='group'
  _parentResources = ['me']

  createGroup () {
    return axios.post(this._baseURL + 'me/group/create', {})
  }

  leaveGroup () {
    return axios.post(this._baseURL + 'me/group/leave', {})
  }

  joinGroup (passCode) {
    return axios.post(this._baseURL + 'me/group/join', {
      passcode: passCode
    })
  }
}
