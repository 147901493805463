<template>
    <div :class="componentClasses" @click="clicked()" >
        <icon-tick :size="'sm1'"/>
    </div>
</template>

<script>
import IconTick from '../../root/icons/IconTick'
import lodash from 'lodash'

export default {
  name: 'InputCheckBoxCircular',
  components: {
    IconTick
  },
  props: {
    disabled: {
      default: false
    },
    value: {
      default: []
    },
    id: {
      default: null
    }
  },
  data: function () {
    return {
      checkedProxy: null
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function () {
        this.checkedProxy = this.value
      }
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-input-checkbox-circular': true,
        'gc-input-checkbox-circular--disabled': this.disabled,
        'gc-input-checkbox-circular--selected': this.value.includes(this.id)
      }
    }
  },
  methods: {
    clicked () {
      if (!this.disabled) {
        const checked = this.checkedProxy.includes(this.id)
        if (!checked) {
          this.checkedProxy.push(this.id)
        } else {
          this.checkedProxy = this.checkedProxy.filter(selectedValue => {
            return this.id !== selectedValue
          })
        }
        this.checkedProxy = lodash.uniq(this.checkedProxy)
        this.$emit('input', this.checkedProxy)
        this.$emit('click')
      }
    }
  }
}
</script>
