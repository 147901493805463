<template>
    <div>
        <div class="pc-popup-period-tracker">
            <popup-title class="pc-popup-period-tracker__title-with-tools">
                <popup-button-back @click="goBack"/>
                <popup-button-close @click="showExitConfirm"/>
            </popup-title>
            <popup-body class="text-center">
                <popup-content>
                    <popup-head class="text-center">
                        <popup-text-heading class="pc-popup-period-tracker__head">
                            {{$t('message[\'period-tracker.period-duration.header\']')}}
                        </popup-text-heading>
                    </popup-head>
                    <popup-text-body class="pc-popup-period-tracker__description">
                        {{$t('message[\'period-tracker.period-duration.description\']')}}
                    </popup-text-body>
                    <div class="pc-popup-period-tracker__content">
                        <div class="modal-measure-row">
                            <div>
                                <div class="modal-measure-title">
                                    <text-content :size="'xl1'" :lineHeight="'multi'" :weight="'extra-bold'" class="modal-measure-title-input">
                                        {{inputValueLabel}}
                                    </text-content>
                                  <span class="modal-measure-title-unit">
                                      <text-content :size="'sm4'" :lineHeight="'multi'" :weight="'extra-bold'">
                                          {{$tc('message["general.days"]', inputValue)}}
                                      </text-content>
                                    </span>
                                </div>
                            </div>
                            <div class="modal-measure-btn-wrap">
                                <div class="modal-measure-btn btn-top">
                                    <div  @click="addValue">
                                        <icon-up-arrow-filled :size="'sm4'"/>
                                    </div>
                                </div>
                                <div class="modal-measure-btn btn-down">
                                    <div  @click="reduceValue">
                                        <icon-down-arrow-filled :size="'sm4'"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </popup-content>
                <popup-floating-footer>
                    <button-primary :text="$t('message[\'period-tracker.popup-button.continue\']')" @click="doAction"/>
                </popup-floating-footer>
            </popup-body>
        </div>
    </div>
</template>

<script>
import popupAnimationMixin from '../../../../../../mixins/popupAnimationMixin'
import PopupTextHeading from '../../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupTextBody from '../../../../../global/popups/popup-sub-components/PopupTextBody'
import PopupHead from '../../../../../global/popups/popup-sub-components/PopupHead'
import IconUpArrowFilled from '../../../../../root/icons/IconUpArrowFilled'
import IconDownArrowFilled from '../../../../../root/icons/IconDownArrowFilled'
import {mapGetters} from 'vuex'
import PopupTitle from '../../../../../global/popups/popup-sub-components/PopupTitle'
import PopupButtonClose from '../../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupButtonBack from '../../../../../global/popups/popup-sub-components/PopupButtonBack'
import PopupFloatingFooter from '../../../../../global/popups/popup-sub-components/PopupFloatingFooter'
import PopupBody from '../../../../../global/popups/popup-sub-components/PopupBody'
import PopupContent from '../../../../../global/popups/popup-sub-components/PopupContent'
import ButtonPrimary from '../../../../../global/buttons/ButtonPrimary'
import TextContent from '../../../../../root/TextContent'

export default {
  name: 'PeriodDuration',
  mixins: [popupAnimationMixin],
  components: {
    ButtonPrimary,
    PopupTextHeading,
    PopupTextBody,
    PopupHead,
    IconUpArrowFilled,
    IconDownArrowFilled,
    PopupTitle,
    PopupButtonClose,
    PopupButtonBack,
    PopupFloatingFooter,
    PopupBody,
    PopupContent,
    TextContent
  },
  data () {
    return {
      cycleLength: 28,
      inputValue: 4
    }
  },
  computed: {
    inputValueLabel () {
      return (this.inputValue < 10) ? ('0' + this.inputValue) : this.inputValue
    }
  },
  watch: {
    inputValue () {
      this.setInputValue()
    }
  },
  mounted () {
    this.cycleLength = this.service()
      .getFirstRecord()
      .getCycleLength()

    let existingValue = this.service()
      .getFirstRecord()
      .getPeriodLength()

    if (existingValue) {
      this.inputValue = existingValue
    }
    this.$emit('mounted')
  },
  methods: {
    ...mapGetters({
      service: 'periodTrackerStore/getService'
    }),
    setInputValue () {
      this.service()
        .getFirstRecord()
        .setPeriodLength(this.inputValue)
    },
    goBack () {
      this.$emit('goBack')
    },
    showExitConfirm () {
      this.$emit('exit-confirm')
    },
    doAction () {
      this.setInputValue()
      this.$emit('next')
    },
    addValue () {
      this.inputValue = parseInt(this.inputValue) || 0
      if (this.inputValue < this.cycleLength) {
        this.inputValue++
      }
    },
    reduceValue () {
      this.inputValue = parseInt(this.inputValue) || 0
      if (this.inputValue > 1) {
        this.inputValue--
      }
    }
  }
}
</script>

<style scoped>

</style>
