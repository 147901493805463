<template>
  <transition name="fade" mode="out-in">
    <component v-bind:is="dynamicComponent"></component>
  </transition>
</template>

<script>
import BaseComponent from '../global/base/BaseComponent'
import FormHandler from './FormHandler'
import Profile from './Profile'
import PageAccountSettings from './account-settings/PageAccountSettings'
import PaymentSettings from './payment/PaymentSettings'
import PageFitness from './fitness/PageFitness'
import PageDiet from './diet/PageDiet'
import MainPageMixin from '../../../../mixins/mainPageMixin'
import store from '../../../../store/index'
import ChangePassword from './change-password/ChangePassword'
import DislikedMeals from './diet/DislikedMeals'
import DashboardService from '../../../../services/DashboardService'
import MyConnections from './my-connections/PageMyConnections'
import DislikedIngredients from './diet/DislikedIngredients'
import PreferredMeals from './diet/PreferredMeals'
import PageFavouriteMeals from '@/components/layout/template-1/profile/diet/PageFavouriteMeals.vue'
export default {
  name: 'Main',
  extends: BaseComponent,
  mixins: [MainPageMixin],
  components: { },
  data: function () {
    return {
      components: {
        'profile-home': { design1: Profile },
        profileFormHandler: { design1: FormHandler },
        'profile-account-settings': { design1: PageAccountSettings },
        'profile-payment-settings': { design1: PaymentSettings },
        'profile-fitness-preferences': { design1: PageFitness },
        'profile-dislike-meals': { design1: DislikedMeals },
        'profile-favourite-meals': { design1: PageFavouriteMeals },
        'profile-dislike-ingredients': { design1: DislikedIngredients },
        'profile-dietary-preference': { design1: PageDiet },
        'profile-change-password': { design1: ChangePassword },
        'profile-my-connections': { design1: MyConnections },
        'profile-preferred-meals': {design1: PreferredMeals}
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    let initData = async () => {
      let service = new DashboardService()
      await service.getUserConfig()
    }
    initData().finally(() => {
      if (!navigator.onLine) {
        store.commit('showNoConnectionErrorPopup')
        next()
      }
      if (to.name !== from.name) {
        $.getScript('https://js.stripe.com/v3/', () => {
          next()
        })
      } else {
        next()
      }
    })
  },
  beforeRouteLeave (to, from, next) {
    if (to.name !== from.name) {
      const stripeElements = document.querySelectorAll(
        'iframe[src*="js.stripe"]'
      )
      stripeElements.forEach((ele) => {
        ele.parentNode.removeChild(ele)
      })
    }
    next()
  },
  computed: {
    page: function () {
      switch (this.$route.query.page) {
        case 'account-settings-form':
          return 'profileFormHandler'
        case 'account-settings':
          return 'profile-account-settings'
        case 'fitness-preferences':
          return 'profile-fitness-preferences'
        case 'dietary-preferences':
          return 'profile-dietary-preference'
        case 'disliked-meals':
          return 'profile-dislike-meals'
        case 'disliked-ingredients':
          return 'profile-dislike-ingredients'
        case 'preferred-meals':
          return 'profile-preferred-meals'
        case 'favourite-meals':
          return 'profile-favourite-meals'
        case 'payment-settings':
          return 'profile-payment-settings'
        case 'my-connections':
          return 'profile-my-connections'
        case 'change-password':
          return 'profile-change-password'
        default:
          return 'profile-home'
      }
    }
  }
}
</script>
