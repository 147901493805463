<template>
  <div>
    <workout-log-success-area v-if="showSuccess"/>
    <timer
      :rest-header="restText"
      :rest-interval="exerciseRestInterval"
      :rest-time="exerciseRestTime"
      :time="timerTime"
      :workout-header="workoutTimerText"
      v-if="showTimer&&(timerTime>0||exerciseRestTime>0)"
      :rest-message="restMessage()"
      v-on:workout-timer-end="workoutTimerEnded($event)"
      v-on:workoutTimerStarted="workoutTimerStarted($event)"
      v-on:workout-timer-remaining = "workoutTimerRemaining($event)"
    />

    <div class="row">
      <div class="col ma-form__input-group ma-form__input-group--with-text">
        <ValidationProvider name="note" rules="max:1000" v-slot="{ errors , invalid , validated}">
          <textarea
            v-on:change="noteChanged()"
            :placeholder="[[$t('message[\'workout-log.add-note\']')]]"
            class="form-control ma-form__input"
            cols="3"
            maxlength="999"
            v-bind:class="{'ma-form__input--error': invalid&&validated }"
            v-model.trim="formData.note"
          ></textarea>
          <p class="app-error-msg ">{{ errors[0] }}</p>
        </ValidationProvider>
      </div>
    </div>

    <div class="pt-4" v-if="saveType==='save'">
      <button
        class="btn btn-primary btn-main footer__btn-main"
        type="button"
        v-on:click="completeExercise()"
      >
        {{$t('message["workout-log.complete-exercise"]')}}
      </button>
    </div>
    <div  class="pt-4" v-if="saveType==='update'">
      <button
        class="btn btn-primary btn-main footer__btn-main"
        type="button"
        v-on:click="completeExercise()"
      >
        {{$t('message["workout-log.save-text"]')}}
      </button>
      <button class="btn btn-main btn-main--with-only-outline" type="button" v-on:click="cancelEdit()"
      >{{$t('message["general.cancel"]')}}
      </button>
    </div>
  </div>

</template>

<script>
import { ValidationProvider } from 'vee-validate'
import WorkoutLogSuccessArea from './WorkoutLogSuccessArea'
import Timer from './Timer'
import workoutLogNoteFormMixin from '../../../../../mixins/component-mixins/page/workout/workoutLogNoteFormMixin'
export default {
  name: 'WorkoutLogNoteForm',
  components: {
    WorkoutLogSuccessArea,
    ValidationProvider,
    Timer
  },
  mixins: [workoutLogNoteFormMixin]
}
</script>
