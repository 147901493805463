<template>
  <page :header="headerOptions" class="gc-page-disliking">
    <page-container id="page-container">
    <ValidationObserver
      name="allergies"
      ref="form"
      v-slot="{ handleSubmit }"
    >
      <form id="form-input-disliking" @submit.prevent="handleSubmit(nextComponent)">
        <div id="page-header" class="gc-page-disliking__header-wrapper">
          <text-heading4
            class="gc-page-disliking__title"
            :weight="'extra-bold'"
            :lineHeight="'multi'">
            {{title.main}}
          </text-heading4>
          <text-body-extra-small
            class="gc-page-disliking__sub-title"
            :weight="'extra-bold'"
            :lineHeight="'multi'">
            {{title.sub}}
          </text-body-extra-small>
        </div>
        <input-multi-select
          v-if="!isGrouped"
          v-on:change="setFormData"
          v-model="inputValue"
          :options="options"
          @change="inputChange()"
          :placeHolder="$t('message[\'general.enter-your-ingredients\']')"
        />
        <ingredients
          v-if="isGrouped"
          class="gc-page-disliking__input"
          :groupedIngredients="ingredientGroup"
          :ListIngredients="ingredientList"
          v-model="inputValue"

          v-on:input="setFormData()"/>
      </form>
    </ValidationObserver>
    </page-container>
    <page-footer ref="footer" id="page-footer" :form="'form-input-disliking'" :validated="invalid"></page-footer>
  </page>
</template>

<script>
import BaseComponent from '../../layout/template-1/global/widgets/forms/FormBaseComponent'
import SubscriptionFormData from '../../../services/ui/forms/SubscriptionFormData'
import Page from './PageForm'
import PageContainer from './page-sub-components/PageContainer'
import TextHeading4 from '../typography/TextHeading4'
import Ingredients from '../inputs/InputGroupIngredients'
import InputMultiSelect from '../inputs/InputMultiSelect'
import PageFooter from './page-sub-components/PageFormFooter'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import { enableGroupedIngredients } from '../../../includes/TemplateSettings'
import IngredientService from '../../../services/IngredientService'
import InputGroupIngredients from '../inputs/InputGroupIngredients.vue'

export default {
  name: 'Allergies',
  extends: BaseComponent,
  components: {
    InputGroupIngredients,
    TextBodyExtraSmall,
    PageFooter,
    Ingredients,
    TextHeading4,
    PageContainer,
    Page,
    InputMultiSelect
  },
  data: function () {
    return {
      selected: '',
      childValue: [],
      headerOptions: {
        show: false
      },
      isGrouped: false,
      ingredientGroup: [],
      ingredientList: []
    }
  },
  created () {
    this.pageClass = ['page-allergies']
    const formData = new SubscriptionFormData()
    this.selectedNutritionalPref = parseInt(
      formData.getValue('nutritionalPref')
    )
  },
  mounted () {
    this.checkIfGrouped()
    this.getIngredients()
  },
  watch: {
    childValue: function (val) {
      this.setFormData()
    }
  },
  computed: {},
  methods: {
    inputChange () {
      this.$refs.footer.setFooterClass()
    },
    checkIfGrouped () {
      if (enableGroupedIngredients()) {
        this.isGrouped = true
      }
    },
    getIngredients () {
      const ingredientService = new IngredientService()
      let mAllergiesList = []
      let mAllergiesGroup = []
      ingredientService.setIngredients()
        .then(() => {
          mAllergiesGroup = ingredientService.getGroupedIngredients()
          const ingredients = ingredientService.getIngredients()
          Object.keys(ingredients).forEach(function (value, index, array) {
            const groups = ingredients[value].groups || []
            mAllergiesList.push({id: (ingredients[value].id), text: ingredients[value].value, groups: groups})
          })
          this.ingredientList = mAllergiesList
          this.ingredientGroup = mAllergiesGroup
        })
        .finally(() => {
          this.hideLoading()
        })
    }
  }
}
</script>
