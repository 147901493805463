import BaseComponent from '../components/layout/template-1/global/base/BaseComponent'
import UpsellService from '../services/UpsellService'

export default {
  extends: BaseComponent,
  props: {
    product: {
      type: String,
      required: true
    },
    amount: {
      type: [String, Number],
      required: true,
      validator: amt => !isNaN(parseFloat(amt)),
      default: 1.00
    },
    currency: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    desc: {
      type: String,
      required: true
    },
    redirect: {
      type: String,
      required: true
    },
    webhook: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      redirectUrl: null,
      productImage: null,
      productDescription: null,
      productWebhook: null,
      displayAmount: null
    }
  },
  created () {
    // eslint-disable-next-line
    if (this.redirect != 'null') {
      this.redirectUrl = this.redirect
      // this.setRedirectParams()
    }
    // eslint-disable-next-line
    if (this.image != 'null') {
      this.productImage = this.image
    }
    // eslint-disable-next-line
    if (this.desc != 'null') {
      this.productDescription = this.desc
    }
    // eslint-disable-next-line
    if (this.webhook != 'null') {
      this.productWebhook = this.webhook
    }
    this.displayAmount = parseFloat(this.amount).toLocaleString('en', { style: 'currency', currency: this.currency })
  },
  methods: {
    setRedirectParams () {
      const upsellService = new UpsellService()
      let redirect = this.redirectUrl
      upsellService.getRedirectParams()
        .then(redirectParams => {
          let hasParams = redirect.split('?')[1]
          if (hasParams) {
            hasParams = hasParams.split('&')
          }
          redirect += (hasParams && hasParams.length > 0 ? '&' : '?') + redirectParams
          this.redirectUrl = redirect
        })
    },
    purchase () {
      const upsellService = new UpsellService()
      let message = {
        lang: 'upsell.loading',
        vars: []
      }
      const prod = { name: this.product, price: parseFloat(this.amount), currency: this.currency }
      if (this.productWebhook) {
        prod.webhook = this.productWebhook
      }
      this.$emit('showUpsellPopup', { message: message, status: 'loading' }, () => {})
      upsellService.init()
        .then((response) => {
          message = {
            lang: 'upsell.purchase-confirmation',
            vars: [this.product, this.amount, this.currency.toUpperCase(), response.data.last4]
          }
          this.$emit('showUpsellPopup', { message: message, confirmText: 'upsell.confirm-button-text', declineText: 'upsell.decline-button-text', status: 'processing' }, (confirmation) => {
            if (confirmation) {
              message = {
                lang: 'upsell.purchase-processing',
                vars: [this.product]
              }
              this.$emit('showUpsellPopup', { message: message, confirmText: null, declineText: null, status: 'loading' }, () => {})
              upsellService.purchase(prod)
                .then(() => {
                  message = {
                    lang: this.redirectUrl ? 'upsell.payment-successful-redirect' : 'upsell.payment-successful-email',
                    vars: []
                  }
                  this.$emit('showUpsellPopup', { message: message, confirmText: 'general.continue', declineText: null, status: 'success' }, (confirmation) => {
                    if (this.redirectUrl) {
                      window.location.href = this.redirectUrl
                    }
                  })
                }).catch(() => {
                  message = {
                    lang: 'upsell.error-occurred',
                    vars: [this.product]
                  }
                  this.$emit('showUpsellPopup', { message: message, confirmText: 'general.continue', declineText: null, status: 'error' }, (confirmation) => {})
                })
            }
          })
        }).catch(() => {
          message = {
            lang: 'upsell.error-occurred',
            vars: [this.product]
          }
          this.$emit('showUpsellPopup', { message: message, confirmText: 'general.continue', declineText: null, status: 'error' }, (confirmation) => {})
        })
    }
  }
}
