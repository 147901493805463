<template>
  <button-base :class="componentClasses" @click="clicked()">
    <text-content :size="'sm1'" :decoration="'underline'" :line-height="'multi'" :weight="weight"> {{ text }}</text-content>
  </button-base>
</template>

<script>

import TextContent from '../../root/TextContent'
import ButtonBase from '../../root/ButtonBase'

export default {
  name: 'ButtonLink',
  components: {
    ButtonBase,
    TextContent
  },
  props: {
    /**
     * The main text of the button
     */
    text: {
      default: ''
    },
    /**
     * Enabled / Disabled status
     * Expected values: Boolean
     */
    disabled: {
      default: false
    },
    /**
     * Font weight of the text
     * Expected values: 'light' = 400 | 'medium' = 600 | 'bold' = 600 | 'extra-bold' = 700
     */
    weight: {
      default: 'extra-bold'
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-button-link': true,
        'gc-button-link--disabled': this.disabled
      }
    }
  },
  /**
   * function to emit the click event
   */
  methods: {
    clicked () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped></style>
