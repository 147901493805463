<template>
  <popup
    @hide="closePopup"
    modal-class="pc-popup-workout-log-personal-best"
    :visible="visible"
    v-if="visible"
  >
    <popup-title>
      <popup-button-close @click="closePopup"/>
    </popup-title>
    <popup-body class="text-center">
      <popup-head>
        <icon-trophy size="lg4" class="pc-popup-workout-log-personal-best__icon-trophy"/>
        <popup-text-sub-heading  >{{ $t('message[\'popup.workout-log-personal-best.title\']')}}
        </popup-text-sub-heading>
      </popup-head>
      <popup-content>
        <popup-text-heading v-if="value"
                            class="pc-popup-workout-log-personal-best__value" >{{value}}</popup-text-heading>
        <popup-text-body>
          <p v-html="$t('message[\'popup.workout-log-personal-best.description\']',[this.exerciseName])"
          ></p>
        </popup-text-body>
      </popup-content>
      <popup-footer>
        <button-primary
          :text="actionButtonText"
          v-on:click="doAction()"
        />
      </popup-footer>
    </popup-body>
  </popup>

</template>

<script>
import popupAnimationMixin from '../../../../../../mixins/popupAnimationMixin'
import PopupTitle from '../../../../../global/popups/popup-sub-components/PopupTitle'
import PopupBody from '../../../../../global/popups/popup-sub-components/PopupBody'
import PopupHead from '../../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupContent from '../../../../../global/popups/popup-sub-components/PopupContent'
import PopupFooter from '../../../../../global/popups/popup-sub-components/PopupFooter'
import ButtonSecondary from '../../../../../global/buttons/ButtonSecondary'
import ButtonPrimary from '../../../../../global/buttons/ButtonPrimary'
import PopupTextSubHeading from '../../../../../global/popups/popup-sub-components/PopupTextSubHeading'
import PopupTextBody from '../../../../../global/popups/popup-sub-components/PopupTextBody'
import Popup from '../../../../../global/popups/Popup'
import PopupButtonClose from '../../../../../global/popups/popup-sub-components/PopupButtonClose'
import IconTrophy from '../../../../../root/icons/IconTrophy'

export default {
  name: 'PopupWorkoutLogPersonalBest',
  components: {
    IconTrophy,
    PopupTextBody,
    PopupTextSubHeading,
    ButtonPrimary,
    ButtonSecondary,
    PopupFooter,
    PopupContent,
    PopupTextHeading,
    PopupButtonClose,
    PopupHead,
    PopupBody,
    PopupTitle,
    Popup
  },
  mixins: [popupAnimationMixin],
  props: {
    visible: { default: false },
    exerciseName: { default: '' },
    value: { default: '' },
    buttonText: { default: '' }
  },
  computed: {
    actionButtonText: function () {
      return this.buttonText || this.$i18n.t('message["exercise.next-exercise"]')
    }
  },
  data: function () {
    return {
      title: '',
      imagesUrl: this.$appConfig.imagesUrl
    }
  },
  methods: {
    doAction () {
      this.$emit('next')
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    }
  }
}
</script>
