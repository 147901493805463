<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.9602 3.93428H0.040169C0.0630261 3.84285 0.103026 3.75142 0.14874 3.65999L1.31445 1.54571C1.52588 1.15714 1.9316 0.922852 2.3716 0.922852H13.6287C14.0687 0.922852 14.4745 1.15714 14.6859 1.54571L15.8516 3.65999C15.8973 3.75142 15.9373 3.84285 15.9602 3.93428Z" fill="#AFAFAF"/>
      <path d="M14.5621 8.50173C14.3764 8.5383 14.1856 8.55715 13.9964 8.55715C13.2187 8.55715 12.5216 8.23658 12.0096 7.72744C11.493 8.23944 10.7833 8.55715 9.99985 8.55715C9.221 8.55715 8.52271 8.23601 8.01014 7.72687C7.49357 8.23944 6.78328 8.55715 5.99985 8.55715C5.221 8.55715 4.52328 8.23658 4.01071 7.72744C3.49471 8.23944 2.78557 8.55715 2.00328 8.55715C1.81414 8.55715 1.62328 8.5383 1.43471 8.50173C1.36557 8.48858 1.30157 8.46173 1.23414 8.44344V14.8714C1.23414 15.54 1.77128 16.0772 2.43985 16.0772H3.60785V10.5337C3.60785 10.2652 3.82557 10.0474 4.09414 10.0474H7.03357C7.30214 10.0474 7.51985 10.2652 7.51985 10.5337V16.0772H13.5599C14.2284 16.0772 14.7656 15.54 14.7656 14.8714V8.44287C14.6976 8.46172 14.6324 8.48801 14.5621 8.50173ZM12.3919 12.2434C12.3919 12.512 12.1741 12.7297 11.9056 12.7297H10.1953C9.92671 12.7297 9.709 12.512 9.709 12.2434V10.5332C9.709 10.2646 9.92671 10.0469 10.1953 10.0469H11.9056C12.1741 10.0469 12.3919 10.2646 12.3919 10.5332V12.2434Z" fill="#AFAFAF"/>
      <path d="M15.96 4.79138H0.04C0.0262857 4.79138 0.0137143 4.78737 0 4.7868V5.62566C0 6.59138 0.651429 7.47709 1.6 7.65995C2.88 7.91138 4 6.93423 4 5.69995C4 6.8028 4.89714 7.69995 6 7.69995C7.10286 7.69995 8 6.8028 8 5.69995C8 6.8028 8.89714 7.69995 10 7.69995C11.1029 7.69995 12 6.8028 12 5.69995C12 6.93423 13.12 7.91138 14.4 7.65995C15.3486 7.47709 16 6.59138 16 5.62566V4.7868C15.9863 4.78737 15.9737 4.79138 15.96 4.79138Z" fill="#AFAFAF"/>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconRestaurant',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-restaurant': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
