<template>
  <popup
    v-if="visible"
    ref="planInactiveModal"
    :visible="visible"
    @hide="closePopup"
    modal-class="gc-popup-plan-inactive"

  >
    <popup-title>
      <popup-button-close @hide="closePopup"/>
    </popup-title>
    <popup-body class="gc-popup-plan-inactive__gc-popup-plan-inactive__body text-center">
      <popup-head v-if="!PreloaderVisibility" class="text-center">
        <icon-info :size="'lg1'" class="gc-popup-plan-inactive__header-icon"/>
        <popup-text-heading>{{ title }}</popup-text-heading>
      </popup-head>
      <popup-content>
        <loading-buffer v-if="PreloaderVisibility"></loading-buffer>
        <div v-if="!PreloaderVisibility">
        <popup-text-body
          v-if="status==='init'"
          class="text-center gc-popup-plan-inactive__subtitle-description"
        >{{ $t('message["popup-plan-inactive.description"]') }}</popup-text-body>
        <popup-text-body
          v-if="status==='init'"
          class="text-center gc-popup-plan-inactive__subtitle-support "
        >{{ $t('message["popup-plan-inactive.support-text"]') }}</popup-text-body>
        <a :href="'mailto:'+trainerEmail"><button-link class="gc-popup-plan-inactive__trainer-email text-center" :text="trainerEmail"></button-link></a>
        </div>
      </popup-content>
      <popup-footer v-if="status==='init'">
        <a :href="'mailto:'+trainerEmail"><button-primary v-if="!PreloaderVisibility" :text="buttonText"></button-primary></a>
      </popup-footer>
    </popup-body>

  </popup>
</template>

<script>
import LoadingBuffer from '../../layout/template-1/global/widgets/LoadingBuffer'
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'

import Popup from './Popup.vue'
import PopupBody from './popup-sub-components/PopupBody'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupContent from './popup-sub-components/PopupContent'
import PopupFloatingFooter from './popup-sub-components/PopupFloatingFooter'
import PopupFooter from './popup-sub-components/PopupFooter'
import PopupTextBody from './popup-sub-components/PopupTextBody'
import PopupHead from './popup-sub-components/PopupHead'
import PopupTextHeading from './popup-sub-components/PopupTextHeading'
import PopupTextSubHeading from './popup-sub-components/PopupTextSubHeading'
import PopupTitle from './popup-sub-components/PopupTitle'
import ButtonPrimary from '../buttons/ButtonPrimary'
import ButtonSecondary from '../buttons/ButtonSecondary'
import BaseComponent from '../../layout/template-1/global/base/BaseComponent'
import IconInfo from '../../root/icons/IconInfo'
import ButtonLink from '../buttons/ButtonLink'
import ProfileService from '../../../services/ProfileService'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'PopupPlanInactive',
  components: {
    ButtonLink,
    IconInfo,
    LoadingBuffer,
    Popup,
    PopupBody,
    PopupButtonClose,
    PopupContent,
    PopupFloatingFooter,
    PopupFooter,
    PopupTextBody,
    PopupHead,
    PopupTextHeading,
    PopupTextSubHeading,
    PopupTitle,
    ButtonSecondary,
    ButtonPrimary
  },
  mixins: [popupAnimationMixin],
  extends: BaseComponent,
  props: {
    data: {
      default: function () {
        return {}
      },
      type: Object
    },
    visible: {
      default: false,
      type: Boolean
    }
  },
  data: function () {
    return {
      title: this.$i18n.t('message["popup-plan-inactive.header"]'),
      buttonText: this.$i18n.t('message["popup-plan-inactive.button-text"]'),
      supportEmail: 'support@macroactive.com',
      status: 'loading',
      loading: true,
      trainerEmail: '',
      PreloaderVisibility: true
    }
  },
  computed: {
    ...mapGetters({
      getPlanExpiredPopupShow: 'getPlanExpiredPopupShow',
      getPlanExpiredPopupContext: 'getPlanExpiredPopupContext'
    })
  },
  mounted () {
    this.getSupportEmail()
    this.logEvent('GeneralActionRestricted', {reason: 'one-off-plan-restricted', context: this.getPlanExpiredPopupContext}, false)
    setTimeout(() => {
      this.$nextTick(() => {
        this.setPopupContentHeight()
      })
    }, 1000)
  },
  created () {
    this.status = 'init'
  },
  methods: {
    ...mapMutations({
      hideSubscriptionExpiredPopup: 'hideSubscriptionExpiredPopup'
    }),
    hidePreloader () {
      if (this.PreloaderVisibility) {
        this.PreloaderVisibility = false
      }
    },
    setPopupContentHeight () {
      this.$nextTick(() => {
        this.$refs.planInactiveModal.setContentHeight()
      })
    },
    closePopup (e, callback) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
        if (callback) {
          callback()
        }
        if (this.getPlanExpiredPopupShow) {
          this.hideSubscriptionExpiredPopup()
        }
      })
    },
    getSupportEmail () {
      const profileService = new ProfileService()
      profileService.setProfileData()
        .then(data => {
          data = data.data
          if (typeof data.settings_manager.trainer.support_email !== 'undefined') {
            this.trainerEmail = data.settings_manager.trainer.support_email
          }
          this.hidePreloader()
          this.setPopupContentHeight()
        })
    }
  }
}
</script>
