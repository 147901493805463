import BaseAPI from '../BaseAPI.js'
import axios from 'axios'
import DefineInclude from '../../../DefineInclude'
export default class extends BaseAPI {
  _resource ='shopping-list'
  _parentResources =['meal-plans']
  _emailApiBaseUrl = DefineInclude.shoppingListEmailAPIurl

  /***
   * custom API for the email send only
   *
   */
  sendEmail (payload, grouped = false) {
    const url = this._emailApiBaseUrl
    const headers = this._headers
    headers.Authorization = 'Bearer ' + this._token
    headers['X-Api-Host'] = DefineInclude.apiUrl.replace(/\/$/, '')
    const pathArr = ['shopping-list', 'email']
    if (grouped) {
      pathArr.push('group')
    }
    return axios.post(url + this._getPath(pathArr), payload, { headers: headers })
  }
}
