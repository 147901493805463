<template>
  <div :class="componentClasses" @click="clicked">
      <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Badge',
  computed: {
    componentClasses: function () {
      return {
        'rc-badge': true
      }
    }
  },
  methods: {
    /**
     * function to emit the click event
     */
    clicked () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped></style>
