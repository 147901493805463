<template>
    <!--   page wrapper -->
  <page-image-cover class="page-workout-week" :header="headerOptions" :background-image="background.image">

  <div >
    <page-container>
      <div class="page-workout-week__header-wrapper">
        <text-heading2 :weight="'extra-bold'" :lineHeight="'multi'" class="page-workout-week__header">{{ numberWordToNumeric(title.main) }}</text-heading2>
      </div>
    </page-container>
    <div class="page-workout-week__app-slider-wrapper">
      <slick
        key="days-slick"
        ref="slick"
        class="page-workout-week__app-slider"
        :options="slick.slickOptions"
        @afterChange="handleAfterChange"
        @beforeChange="handleBeforeChange"
        @breakpoint="handleBreakpoint"
        @destroy="handleDestroy"
        @edge="handleEdge"
        @init="handleInit"
        @reInit="handleReInit"
        @setPosition="handleSetPosition"
        @swipe="handleSwipe"
        @lazyLoaded="handleLazyLoaded"
        @lazyLoadError="handleLazeLoadError"
      >
        <div v-for="(day) in days"
             :key="'workout-day-slider-'+day.getID()"
             @click="navigateNext(day)"
             class="page-workout-week__app-slider-slide">
          <div class="page-workout-week__slide-check-icon" v-if="isAllExercisesCompletedOfDay(day)">
            <icon-complete-filled :size="'sm4'"/>
          </div>
          <text-heading3
            :lineHeight="'multi'"
            :weight="'extra-bold'"
            class="page-workout-week__slide-title">
            {{numberWordToNumeric (day.getName())}}
          </text-heading3>
          <text-body-extra-small
            :lineHeight="'multi'"
            class="page-workout-week__slide-sub-title"
            v-if="workoutLogEnabled">
            {{getCompletedExercises(day)+"/"+getTotalExercisesInDay(day)+" "+$t("message['workout-log.completed']")}}
          </text-body-extra-small>
          <text-body-regular
            :lineHeight="'multi'"
            :weight="'extra-bold'"
            class="page-workout-week__slide__button">
            {{$t('message[\'general.start\']')}} >
          </text-body-regular>
        </div>
      </slick>
    </div>

    <div class="pc-page-workout-week__background-gradient"></div>
  </div>
  </page-image-cover>
</template>

<script>
import BaseComponent from '../global/base/BaseComponent'
import Slick from 'vue-slick'
import SliderService from '../../../../services/SliderService'
import { mapGetters, mapMutations } from 'vuex'
import { slickMixin } from '../../../../mixins/slickMixin'
import WorkoutProgramsService from '../../../../services/WorkoutProgramsService'
import TextHeading2 from '../../../global/typography/TextHeading2'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import IconCompleteFilled from '../../../root/icons/IconCompleteFilled'
import TextHeading3 from '../../../global/typography/TextHeading3'
import TextBodyExtraSmall from '../../../global/typography/TextBodyExtraSmall'
import TextBodyRegular from '../../../global/typography/TextBodyRegular'
import PageImageCover from '../../../global/pages/PageImageCover'
import headerEventBus from '../../../../event-buses/headerEventBus'
export default {
  name: 'PageDays',
  extends: BaseComponent,
  mixins: [slickMixin],
  components: {
    PageImageCover,
    TextBodyRegular,
    TextBodyExtraSmall,
    TextHeading3,
    IconCompleteFilled,
    PageContainer,
    TextHeading2,
    Slick
  },
  data: function () {
    return {
      headerOptions: {
        show: true,
        left: 'previous-emit',
        leftDisabled: false,
        right: 'help',
        rightBlink: false,
        rightDisabled: false,
        helpContent: '',
        modifiers: ['with-img'],
        mainTitle: '',
        subTitle: '',
        isTransparent: true
      },
      backgroundImage: '',
      background: {
        show: true,
        type: 'image',
        image: '',
        classes: [' macroapp-background--page-workout']
      },
      description: '',
      pageClass: [
        'macroapp--page-common',
        'macroapp--page-workout-days-view',
        'macroapp--page-workout-single-week'
      ],
      title: {
        main: '',
        sub: ''
      },
      days: [],
      workoutLogEnabled: false,
      slick: {
        sliderID: '',
        sliderType: '',
        slickOptions: {
          centerMode: true,
          // centerPadding: '60px',
          slidesToShow: 1,
          variableWidth: true,
          autoplay: false,
          dots: false,
          prevArrow: false,
          nextArrow: false,
          infinite: false,
          rtl: false
        }
      },
      instructionsPopup: {
        firstTimeKey: 'exerciseView',
        instructions: [],
        visible: false
      }
    }
  },
  beforeDestroy () {
    this.$refs.slick.destroy()
  },
  beforeMount () {
    this.loadPageData()
    this.slick.slickOptions.rtl = this.isRTL()
  },
  mounted () {
    this.setPageClass()
    this.handleBuldClick()
  },
  watch: {
    headerBackButtonClick: function (newVal, oldVal) {
      this.navigatePrevious()
    }
  },
  computed: {
    ...mapGetters({
      headerBackButtonClick: 'pageStore/getBackButtonClick',
      instructionsPopupVisibility: 'instructionPopupStore/visible'
    })
  },
  methods: {
    ...mapMutations({
      viewInstructionsPopup: 'instructionPopupStore/show',
      setInstructionsPopupContent: 'instructionPopupStore/setContent',
      setWorkoutFrom: 'setWorkoutFrom'
    }),
    handleBuldClick () {
      headerEventBus.$emit('clicked', this.clickCount)
    },
    navigatePrevious () {
      this.$router.push({
        path: this.$appConfig.appUrlList.workout,
        query: { workout: this.params.workout }
      })
    },
    setProgramData (program) {
      this.workoutLogEnabled = program.getWorkoutLogEnabled()

      this.description = program.getDescription()
      this.setPageTitle(program.getName())
      this.instructionsPopup.instructions = [program.getDescription()]
      this.instructionsPopup.notes = program.notes || ''
      this.instructionsPopup.instructionType = 'workoutWeeks'
      // calling to setContent function in the pageStore
      this.setInstructionsPopupContent(this.instructionsPopup)

      let workoutProgramWeekID = this.params.week
      workoutProgramWeekID = parseInt(workoutProgramWeekID) || 0
      let week = program.getWeekByID(workoutProgramWeekID)

      this.setWeekData(week)

      this.afterPageDataLoaded()
      this.hideLoading()
    },
    setWeekData (week) {
      this.background.image = week.getBackgroundImage()
      this.setBackground(this.background)

      this.title.main = week.getName()

      this.days = week.getDays()
    },
    loadPageData () {
      let workoutProgramID = this.params.workout

      workoutProgramID = parseInt(workoutProgramID)
      let workoutProgramsService = new WorkoutProgramsService()
      workoutProgramsService.setWorkoutProgramList().then(() => {
        let program = workoutProgramsService.getWorkoutProgram(workoutProgramID)

        if (program.checkDetailsLoaded()) {
          this.setProgramData(program)
        } else {
          program.setProgramDetails().then(() => {
            this.setProgramData(program)
          })
        }
      }).catch(() => {
        this.hideLoading()
      })
    },
    afterPageDataLoaded () {
      this.setSliderTypeAndID('workoutWeek', this.params.week)
      this.reInit()
    },
    getCompletedExercises (day) {
      const completedExercise = day.exercises.filter(exercise => {
        return exercise.completed || false
      })
      return completedExercise.length
    },

    getTotalExercisesInDay (day) {
      return Object.keys(day.exercises).length
    },
    isAllExercisesCompletedOfDay (day) {
      return this.getCompletedExercises(day) === this.getTotalExercisesInDay(day)
    },
    navigateNext (day) {
      this.showLoading()
      this.setWorkoutFrom('workout-week')
      // holding router so slick dom breaking is not visible before fade effect
      this.delayedRouterPush({
        path: this.$appConfig.appUrlList.workout,
        query: {
          workout: this.params.workout,
          week: this.params.week,
          day: day.getID()
        }
      })
      this.logEvent('WorkoutProgram.ViewedSingleDay', {
        day: this.numberWordToNumeric(day.getName()),
        context: 'workout-program-week-page',
        type: day.getType()
      })
    },
    // slick
    next () {
      this.$refs.slick.next()
    },

    slickGoTo (slideIndex) {
      this.$refs.slick.goTo(slideIndex)
    },

    prev () {
      this.$refs.slick.prev()
    },

    reInit () {
      const service = new SliderService()
      const currIndex = service.getCurrentSlideIndex(
        this.slick.sliderType,
        this.slick.sliderID
      )
      if (typeof this.$refs.slick !== 'undefined') {
        this.$refs.slick.destroy()
      }
      this.$nextTick(() => {
        this.$refs.slick.create()
        this.$refs.slick.goTo(currIndex, true)
      })
    },

    // Events listeners
    handleAfterChange (event, slick, currentSlide) {
      const service = new SliderService()
      service.setCurrentSlide(
        this.slick.sliderType,
        this.slick.sliderID,
        currentSlide
      )
    },
    setSliderTypeAndID (sliderType, sliderID) {
      this.slick.sliderID = sliderID
      this.slick.sliderType = sliderType
    },
    slickGoToByTypeAndID () {
      const service = new SliderService()
      this.slickGoTo(
        service.getCurrentSlideIndex(this.slick.sliderType, this.sliderID)
      )
    }
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
  }
}
</script>
