<template>
  <popup
    v-if="visible"
    :visible="visible"
    @hide="closePopup"
    class="gc-popup-forget-password"
  >
    <popup-title>
      <popup-button-close @hide="closePopup"/>
    </popup-title>
    <popup-body class="gc-popup-meal-swap__body">
      <popup-head class="text-center">
        <popup-text-heading>{{title ? title : ''}}</popup-text-heading>
      </popup-head>
      <popup-content>
        <popup-text-body
          class="text-center info-modal__subtitle mb-2"
        >{{$t("message['general.back-to-login-text']")}}
        </popup-text-body>
      </popup-content>
      <popup-footer>
        <button-primary @click="submit" :text="$t('message[\'forgot-password.back-to-login\']')"></button-primary>
      </popup-footer>
    </popup-body>
  </popup>
</template>

<script>

import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import Popup from '../../../../global/popups/Popup'
import PopupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupFooter from '../../../../global/popups/popup-sub-components/PopupFooter'
import PopupTextBody from '../../../../global/popups/popup-sub-components/PopupTextBody'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupTextSubHeading from '../../../../global/popups/popup-sub-components/PopupTextSubHeading'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'

export default {
  name: 'ForgetPasswordPopup',
  props: ['title', 'visible'],
  components: {
    Popup,
    PopupBody,
    PopupButtonClose,
    PopupContent,
    PopupFooter,
    PopupTextBody,
    PopupHead,
    PopupTextHeading,
    PopupTextSubHeading,
    PopupTitle,
    ButtonPrimary
  },
  mixins: [popupAnimationMixin],
  data: function () {
    return {
      imagesUrl: this.$appConfig.imagesUrl
    }
  },
  methods: {
    submit () {
      this.$router.push({
        path: this.$appConfig.appUrlList.signIn
      })
    },
    closePopup (e, callback) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
        if (callback) {
          callback()
        }
      })
    }
  }
}
</script>
