import UpsellButton from '../../../../components/layout/template-1/content/widgets/UpsellButton'
import UpsellPopup from '../../../../components/layout/template-1/content/widgets/PopupUpsellConformation'
import NativeAppMixin from '../../../NativeAppMixin'

// TODO -fix disabled eslint issues
export default {
  mixins: [NativeAppMixin],
  props: {
    description: {
      default: ''
    }
  },
  computed: {
    processedHtml () {
      let html = this.description || ''
      html = html.replace(/<iframe/g, '<iframe loading=lazy ')
      this.logPageEvent()
      if (html.indexOf('<style>') > -1) {
        html = this.stripAppendStyles(html, 'head')
      }
      if (html.indexOf('<script') > -1) {
        html = this.stripAppendScripts(html, 'body')
      }
      if (html.indexOf('[UpsellName') > -1) {
        html = html.replace(/\[UpsellName=([\s\S]*?)Amount=([\s\S]*?)Currency=([\s\S]*?)Image=([\s\S]*?)Description=([\s\S]*?)Redirect=([\s\S]*?)Webhook=([\s\S]*?)\]/g, function (match, name, amount, currency, image, description, redirect, webhook) {
          // eslint-disable-next-line no-useless-escape
          const desc = description.replace(/\"/g, '&quot;')
          // eslint-disable-next-line no-useless-escape
          const product = name.replace(/\"/g, '&quot;')
          const el = '<upsell-button product="' + product + '" amount="' + amount + '" currency="' + currency + '" image="' + image + '" desc="' + desc + '" redirect="' + redirect + '" webhook="' + webhook + '" v-on:showUpsellPopup="displayPopup"></upsell-button>'
          return el
        })
        html += '<upsell-popup :data="popupData" :visible="upsellPopup.visible" v-if="upsellPopup.visible" v-on:hide="upsellPopup.visible=false" v-on:confirmation="confirmation.callback"></upsell-popup>'
      }
      html = '<div>' + html + '</div>'
      return {
        components: {
          'upsell-button': UpsellButton,
          'upsell-popup': UpsellPopup
        },
        data: function () {
          return {
            upsellPopup: {
              visible: false
            },
            popupData: {
              message: null,
              confirmText: null,
              declineText: null
            },
            confirmation: {
              callback: null
            }
          }
        },
        methods: {
          displayPopup (data, callback = null) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.popupData = data
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.upsellPopup.visible = true
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.confirmation.callback = callback
          }
        },
        template: html
      }
    }
  },
  methods: {
    linkNavigateClickHandler (event) {
      let targetElement = event.target
      const selector = '.content-container .content-text a'
      while (targetElement != null) {
        if (targetElement.matches(selector)) {
          event.preventDefault()
          this.handleUrlNavigate(targetElement.getAttribute('href'))
          return
        }
        targetElement = targetElement.parentElement
      }
    },
    logPageEvent () {
      const description = this.description || ''
      if (description.includes('MA:LiveStream')) {
        let path = this.$route.path.replace('/page/', '')
        this.logEvent('Temp.VisitedLiveStreamPage', {
          'slug': path
        })
      }
    },
    checkHasImage () {
      return !this.hideImage && Boolean(this.mainImage)
    },
    stripAppendStyles (html, appendTo) {
      const styles = html.match(/<style[\s\S]*?>[\s\S]*?<\/style>/g)
      html = html.replace(/<style[\s\S]*?>[\s\S]*?<\/style>/g, '')
      if (styles) {
        let css = ''
        const element = document[appendTo] || document.getElementsByTagName(appendTo)[0]
        const style = document.createElement('style')
        for (let i = 0; i < styles.length; i++) {
          css += styles[i].replace(/<style>|<\/style>/g, '')
        }
        element.appendChild(style)
        style.type = 'text/css'
        style.id = 'pofferpg_css'
        if (style.styleSheet) {
          style.styleSheet.cssText = css
        } else {
          style.appendChild(document.createTextNode(css))
        }
      }
      return html
    },
    stripAppendScripts (html, appendTo) {
      const scripts = html.match(/<script[\s\S]*?>[\s\S]*?<\/script>/g)
      html = html.replace(/<script[\s\S]*?>[\s\S]*?<\/script>/g, '')
      if (scripts) {
        for (let i = 0; i < scripts.length; i++) {
          const innerHTML = scripts[i].replace(/<script[\s\S]*?>|<\/script>/g, '')
          let srcType = scripts[i].match(/<script([\s\S]*?)>/)
          const srcTypeJson = {}
          if (srcType) {
            srcType = srcType[1].split(' ')
            for (let j = 0; j < srcType.length; j++) {
              const tmp = srcType[j].split('=')
              if (tmp.length > 1) {
                srcTypeJson[tmp[0]] = tmp[1].replace(/'|"/g, '')
              }
            }
          }
          const element = document[appendTo] || document.getElementsByTagName(appendTo)[0]
          const scriptTag = document.createElement('script')
          for (const key in srcTypeJson) {
            scriptTag[key] = srcTypeJson[key]
          }
          scriptTag.id = 'pofferpg_js' + i
          if (innerHTML.trim().length > 0) {
            scriptTag.innerHTML = innerHTML
          }
          element.appendChild(scriptTag)
        }
      }
      return html
    },
    listeners () {
      document.addEventListener('router-changed', this.removeCssJs)
    },
    destroyListeners () {
      document.removeEventListener('router-changed', this.removeCssJs)
      document.removeEventListener('router-changed', this.removeCssJs)
      document.querySelector('body')
        .removeEventListener('click', this.linkNavigateClickHandler, true)
    },
    removeCssJs () {
      $('[id^=pofferpg_]').remove()
    }
  }
}
