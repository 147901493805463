<template>
  <page :header="headerOptions" class="gc-page-meals-per-day">
    <validation-observer slim name="mealsPerDay" ref="form" v-slot="{ handleSubmit,invalid }">
    <page-container>
      <form id="form-input-meals-per-day" @submit.prevent="handleSubmit(nextComponent)">
        <div class="gc-page-meals-per-day__header-wrapper">
          <text-heading4 class="gc-page-meals-per-day__title" :weight="'extra-bold'" :lineHeight="'multi'">{{title.main}}</text-heading4>
          <text-body-extra-small class="gc-page-meals-per-day__sub-title" :weight="'extra-bold'" :lineHeight="'multi'">{{title.sub}}</text-body-extra-small>
        </div>
        <validation-provider :rules="'required'"
                            :vid="'Meals per Day'"
                            :name="title.main"
                            v-slot="{ errors }"
                            class="gc-page-meals-per-day__input-wrapper">
          <button-choice
            type="button"
            :text="option.value"
            :key="option.value"
            v-for="option in newOptions"
            v-model="inputValue"
            :selected="(parseInt(inputValue) === parseInt(option.key))"
            @click="setFormData(inputValue = option.key)"
            class="gc-page-meals-per-day__input"/>
          <text-body-extra-small class="gc-page-meals-per-day__error-message">{{errors[0]}}</text-body-extra-small>
        </validation-provider>
      </form>

    </page-container>
    <page-footer :form="'form-input-meals-per-day'" :validated="invalid"></page-footer>
    </validation-observer>
  </page>
</template>

<script>
import BaseComponent from '../../layout/template-1/global/widgets/forms/FormBaseComponent'
import Page from './PageForm'
import PageContainer from './page-sub-components/PageContainer'
import TextHeading4 from '../typography/TextHeading4'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import ButtonChoice from '../buttons/ButtonChoice'
import ButtonPrimary from '../buttons/ButtonPrimary'
import PageFooter from './page-sub-components/PageFormFooter'

export default {
  name: 'MealsPerDay',
  components: {
    PageFooter,
    ButtonPrimary,
    ButtonChoice,
    TextBodyExtraSmall,
    TextHeading4,
    PageContainer,
    Page
  },
  extends: BaseComponent,
  computed: {
    newOptions: function () {
      return this.options.map(option => {
        option.value = this.$i18n.tc('message["subscriptions.meals-per-day"]',
          (Math.max(0, (parseInt(option.value) - 3))),
          {
            meals: (Math.min(parseInt(option.value), 3)),
            snacks: (parseInt(option.value) - 3),
            totalMeals: (parseInt(option.value)) // for custom trainers
          })
        return option
      })
    }
  },
  mounted () {
    this.hideLoading()
    setTimeout(() => {
      if (this.errors.mpd) {
        this.$refs.form.setErrors({ 'Meals per Day': this.errors.mpd })
      } else if (this.errors[this.inputKey]) {
        this.$refs.form.setErrors({ 'Meals per Day': this.errors[this.inputKey] })
      }
    }, 200)
  },
  data: function () {
    return {
      perRow: 2,
      pageClass: ['macroapp--page-meals-per-day'],
      headerOptions: {
        show: false,
        left: 'previous',
        right: '',
        modifiers: ['with-img'],
        mainTitle: '',
        subTitle: '',
        isTransparent: true
      }
    }
  }
}
</script>
