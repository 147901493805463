<template>
  <div class="pc-container-meal-plan-summary-email">
    <button-primary
      class="pc-container-meal-plan-summary-email__button"
      :text="text" @click="showPopup">
      <icon-paper-plane size="md1"/>
    </button-primary>
    <popup-meal-plan-summary-email
      v-if="popupVisibility"
      :meal-plan-i-d="mealPlanId"
      :visible="popupVisibility"
      @close="hidePopup"/>
  </div>
</template>
<script>
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import IconPaperPlane from '../../../../root/icons/IconPaperPlane'
import PopupMealPlanSummaryEmail from './PopupMealPlanSummaryEmail'

export default {
  name: 'ContainerMealPlanSummaryEmail',
  props: {
    mealPlanId: {
      required: true
    }
  },
  components: {
    PopupMealPlanSummaryEmail,
    IconPaperPlane,
    ButtonPrimary
  },
  computed: {
    text: function () {
      return this.$i18n.t('message[\'meal-plan.email-summary\']')
    }
  },
  data: function () {
    return {
      popupVisibility: false
    }
  },
  methods: {
    showPopup: function () {
      this.popupVisibility = true
    },
    hidePopup: function () {
      this.popupVisibility = false
    }
  }
}
</script>
