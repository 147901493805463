<template>
  <div>
  </div>
</template>

<script>

export default {
  name: 'logout',
  mounted () {
    localStorage.removeItem('lastSessionRoute')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('authToken')
    window.location.replace('/login')
  }
}
</script>
