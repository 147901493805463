<template>
  <div
    :class="{'gc-navigator-bottom-bar__link--active': active }"
    class="gc-navigator-bottom-bar__link gc-navigator-bottom-bar__link--profile"
    @click="clicked()">
    <div class="gc-navigator-bottom-bar__icon">
      <icon-user-profile
        v-if="active"
        :size="'md1'"
        class="gc-navigator-bottom-bar__link-icon gc-navigator-bottom-bar__link-icon--active"/>
      <icon-user-profile
        v-else
        :size="'md1'"
        class="gc-navigator-bottom-bar__link-icon gc-navigator-bottom-bar__link-icon--inactive"/>
      <icon-underline/>
    </div>
  </div>
</template>
<script>
import IconUserProfile from '../../../root/icons/IconUserProfile'
import IconDot from '../../../root/icons/IconDot'
import IconUnderline from '@/components/root/icons/IconUnderline.vue'

export default {
  name: 'NavigatorBottomBarLinkProfile',
  components: {
    IconUnderline,
    IconDot,
    IconUserProfile
  },
  props: {
    active: {
      default: false
    }
  },
  methods: {
    clicked () {
      this.$emit('click')
    }
  }
}
</script>
