<template>
    <div
    :class="componentClasses"
    v-on="$listeners"
    >
      <slot></slot>
    </div>
</template>

<script>

export default {
  name: 'MealActionBar',
  computed: {
    componentClasses: function () {
      return {
        'gc-meal__action-area': true
      }
    }
  }
}
</script>
<style scoped>
</style>
