<template>
  <div :class="componentClasses" @click="clicked()">
  <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M17.5 34C26.6127 34 34 26.6127 34 17.5C34 8.3873 26.6127 1 17.5 1C8.3873 1 1 8.3873 1 17.5C1 26.6127 8.3873 34 17.5 34Z" fill="#AFAFAF" fill-opacity="0.2"/>
  <path d="M34 17.5C34 26.6127 26.6127 34 17.5 34C8.3873 34 1 26.6127 1 17.5C1 8.3873 8.3873 1 17.5 1C26.6127 1 34 8.3873 34 17.5ZM2.485 17.5C2.485 25.7926 9.20744 32.515 17.5 32.515C25.7926 32.515 32.515 25.7926 32.515 17.5C32.515 9.20744 25.7926 2.485 17.5 2.485C9.20744 2.485 2.485 9.20744 2.485 17.5Z" fill="#AFAFAF"/>
  <path d="M17.2854 19.999C14.6286 19.999 12.0918 21.1267 10.3264 23.0929C10.0628 23.3864 10.0871 23.8378 10.3806 24.1013C10.6749 24.3656 11.1255 24.3406 11.3891 24.0471C12.8839 22.3823 15.0336 21.4274 17.2854 21.4274C19.5437 21.4274 21.692 22.3816 23.1803 24.0463C23.3218 24.2042 23.5174 24.2842 23.7131 24.2842C23.8824 24.2842 24.0531 24.2242 24.1888 24.1028C24.483 23.8392 24.508 23.3886 24.2452 23.0943C22.4862 21.1274 19.9494 19.999 17.2854 19.999Z" fill="#AFAFAF"/>
  <path d="M15.1425 13.5714C15.1425 13.3007 14.9897 13.0536 14.7476 12.9322L9.03404 10.0755C8.6798 9.89906 8.252 10.0426 8.07559 10.3947C7.89919 10.7475 8.04203 11.1767 8.39484 11.3531L12.8314 13.5714L8.39555 15.789C8.04274 15.9654 7.8999 16.3946 8.07631 16.7474C8.20129 16.9981 8.4534 17.1424 8.71551 17.1424C8.82264 17.1424 8.93191 17.1181 9.03404 17.0674L14.7476 14.2106C14.9897 14.0892 15.1425 13.8421 15.1425 13.5714Z" fill="#AFAFAF"/>
  <path d="M26.4947 10.3953C26.3182 10.0425 25.8897 9.89968 25.5362 10.0761L19.8227 12.9328C19.5806 13.0535 19.4277 13.3007 19.4277 13.5713C19.4277 13.842 19.5806 14.0891 19.8227 14.2105L25.5362 17.0673C25.6383 17.118 25.7476 17.1423 25.8547 17.1423C26.1168 17.1423 26.369 16.998 26.4947 16.7473C26.6711 16.3945 26.5282 15.9653 26.1754 15.7889L21.7389 13.5713L26.1747 11.3538C26.5275 11.1766 26.6711 10.7481 26.4947 10.3953Z" fill="#AFAFAF"/>
  </svg>
    </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconAwful',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-awful': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
