<template>
    <badge-important class="pc-badge-meal-plan-pair">
        <text-body-extra-small
                class="pc-badge-meal-plan-pair__text" :line-height="'lh-xl'" :weight="'extra-bold'">
            {{$t('message["meal-plan-pair.paired-meal-plan"]')}}
        </text-body-extra-small>
        <icon-question-rounded v-if="partnerConnected"
                class="pc-badge-meal-plan-pair__icon"
                               size="sm2"
                               @click="showPopup"/>
        <popup-pair-meal-plan-info :visible="popupVisibility" @close="hidePopup"/>
    </badge-important>
</template>
<script>
import BadgeImportant from '../../../../global/badges/BadgeImportant'
import IconQuestionRounded from '../../../../root/icons/IconQuestionRounded'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import PopupPairMealPlanInfo from './PopupPairMealPlanInfo'
import ConnectionService from '../../../../../services/meal-plan-pair/ConnectionService'
import DashBoardService from '../../../../../services/DashboardService'

export default {
  name: 'BadgeMealPlanPair',
  components: {
    PopupPairMealPlanInfo,
    BadgeImportant,
    IconQuestionRounded,
    TextBodyExtraSmall
  },
  data: function () {
    return {
      popupVisibility: false,
      connectionService: new ConnectionService(),
      dashboardService: new DashBoardService(),
      partnerConnected: false
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      if (!this.dashboardService.isMealPlanPairEnabled()) {
        return
      }

      this.connectionService.init()
        .then(() => {
          this.partnerConnected = this.connectionService.isPartnerConnected()
        })
        .finally(() => {
          this.hideLoading()
        })
    },
    showPopup () {
      this.popupVisibility = true
    },
    hidePopup () {
      this.popupVisibility = false
    }
  }
}
</script>
