<template>
  <div
    :class="{'gc-navigator-bottom-bar__link--active': chatVisibility }"
    class="gc-navigator-bottom-bar__link gc-navigator-bottom-bar__link--chat"
    @click="clicked()">
    <div class="gc-navigator-bottom-bar__icon">

      <icon-chat
        v-if="!chatVisibility"
        :size="'md1'"
        class="gc-navigator-bottom-bar__link-icon gc-navigator-bottom-bar__link-icon "
        @click="openChat"/>

      <icon-chat
        v-else
        :size="'md1'"
        class="gc-navigator-bottom-bar__link-icon gc-navigator-bottom-bar__link-icon gc-navigator-bottom-bar__link-icon--active"
        @click="closeChat"/>
      <icon-ellipse v-if="!chatVisibility &&newMessages"/>
      <icon-underline/>
    </div>
  </div>
</template>
<script>
import IconChat from '../../../root/icons/IconChat'
import ContainerChat from '../../containers/ContainerChat'
import IconEllipse from '../../../root/icons/IconEllipse'
import IconDot from '../../../root/icons/IconDot'
import IconUnderline from '@/components/root/icons/IconUnderline.vue'

export default {
  name: 'NavigatorBottomBarLinkChat',
  components: {
    IconUnderline,
    IconDot,
    IconEllipse,
    IconChat,
    ContainerChat
  },
  props: {
    active: {
      default: false
    }
  },
  data: function () {
    return {
      mounted: false,
      newMessages: false,
      chatVisibility: false,
      clickedBeforeMount: false
    }
  },
  mounted () {
    window.addEventListener('web-socket-connection-error', this.handleChatCollapsed)
    window.addEventListener('ma-chat-mounted', this.handleChatMounted)
    window.addEventListener('ma-chat-expanded', this.handleChatExpanded)
    window.addEventListener('ma-chat-collapsed', this.handleChatCollapsed)
    window.addEventListener('ma-chat-new-messages', this.handleNewMessages)
  },
  beforeDestroy () {
    window.removeEventListener('web-socket-connection-error', this.handleChatCollapsed)
    window.removeEventListener('ma-chat-mounted', this.handleChatMounted)
    window.removeEventListener('ma-chat-expanded', this.handleChatExpanded)
    window.removeEventListener('ma-chat-collapsed', this.handleChatCollapsed)
    window.removeEventListener('ma-chat-new-messages', this.handleNewMessages)
  },
  methods: {
    handleChatMounted () {
      this.mounted = true
      if (this.clickedBeforeMount) {
        this.clickedBeforeMount = false
        this.openChat()
      }
      this.hideLoading()
    },
    handleChatExpanded () {
      this.chatVisibility = true
      this.active = true
    },
    handleChatCollapsed () {
      this.chatVisibility = false
      this.active = false
      this.newMessages = false
      this.$emit('active', false)
    },
    handleNewMessages (e) {
      this.newMessages = true
    },
    openChat () {
      if (!this.mounted) {
        this.clickedBeforeMount = true
        this.showLoading()
        setTimeout(() => {
          this.hideLoading()
        }, 3000)
      }
      if (!document.querySelector('.chat-button')) {
        return
      }
      this.chatVisibility = true

      document.querySelector('.chat-button').click()
      this.$emit('active', true)
    },
    closeChat () {
      this.chatVisibility = false
      document.querySelector('.custom-channel-header__icon-close div').click() //to-do add classname to div on chat-app
    },
    clicked () {
      this.$emit('click')
    }
  }
}

</script>
