export default {
  data: function () {
    return {
      headerHeight: 0
    }
  },
  computed: {
    contentAreaStyles: function () {
      return {
        paddingTop: ((this.header.isFixed) ? this.headerHeight : 0) + 'px'
      }
    }
  },
  mounted () {
    $('#app:has(.gc-page)').addClass('gc-app')
  },
  methods: {
    headerUpdated (height) {
      this.headerHeight = height
    }
  },
  destroyed () {
    $('#app:has(.gc-page)').removeClass('gc-app')
  }
}
