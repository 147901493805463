<template>
  <div
    class="card-timer card-timer--with-rest"
    v-bind:class="{'card-timer--white' : color==='white','card-timer--inside-video':insideVideo,'card-timer--green' : color==='green'}"
  >
    <div>
      <div class="app-timer d-flex"
           v-bind:class="{'app-timer--with-header':Boolean(name),'rest-timer--playing' : play}">
        <transition>
          <countdown
            ref="countdownRest"
            :time="restCountdownTime*1000"
            :auto-start="false"
            v-if="timerShow"
            v-on:end="startWorkout()"
            class="time-set app-timer__left"
            @progress="countdownProgress()"
          >
            <template slot-scope="props">
              <div v-bind:class="{'time-active' : play}">
                <a v-if="!play">
                  <p class="app-timer__header">{{$t('message["general.rest"]')}}</p>
                  <div class="time-block rest-timer-block">
                    <span class="with-sub-title rest-timer-sub-title">{{$t('message["general.for"]')}}</span>
                    <span
                      class="app-timer__time rest-timer__time"
                    >{{headerTime (props.totalMinutes, props.seconds) }}</span>
                    <span class="with-sub-title rest-timer__time-type">{{headerTimeType (props.totalMinutes, props.seconds) }}</span>
                  </div>
                </a>

                <a v-if="play">
                  <div class="time-block">
                    <span
                      class="app-timer__time"
                    >{{setTimerNumbers(props.totalMinutes) }}:{{setTimerNumbers(props.seconds) }}</span>
                    <span class="with-sub-title">{{$t('message["general.time-minutes-short"]')}}</span>
                  </div>
                </a>
              </div>
            </template>
          </countdown>
        </transition>
        <a class="col text-right" v-if="!play" @click="playCountdown">
          <svg class="svg-rest-play-icon timer__btn-lg" width="51" height="50" viewBox="0 0 51 50" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M25.2564 0C11.33 0 0 11.215 0 25C0 38.785 11.33 50 25.2564 50C39.1828 50 50.5128 38.785 50.5128 25C50.5128 11.215 39.1828 0 25.2564 0ZM38.3586 25.6883L19.8372 38.1883C19.695 38.2842 19.5291 38.3333 19.3632 38.3333C19.2285 38.3333 19.093 38.3017 18.9701 38.2367C18.694 38.0925 18.5214 37.8092 18.5214 37.5V12.5C18.5214 12.1908 18.694 11.9075 18.9701 11.7633C19.2462 11.6183 19.5796 11.6383 19.8381 11.8117L38.3594 24.3117C38.5884 24.4667 38.7265 24.7242 38.7265 25C38.7265 25.2758 38.5884 25.5333 38.3586 25.6883Z"
              fill="#3D3D3D"/>
          </svg>

        </a>
      </div>
      <transition>
        <div>
          <div class="row">
            <div class="col text-center" v-if="!noWorkoutMessage">
              <a
                class="timer__play-btn timer--bottom-text"
                v-on:click="startWorkout()"
              >{{$t('message["general.start-workout"]')}}</a>
            </div>
          </div>
          <div class="row">
            <div class="col text-center" v-if="restMessage">
              <a
                class="timer--bottom-text timer--bottom-text-grey"
              >{{restMessage}}</a>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import VueCountdown from '@chenfengyuan/vue-countdown'
import timerRestMixin from '../../../../../mixins/component-mixins/global/timers/timerRestMixin'

Vue.component(VueCountdown.name, VueCountdown)
export default {
  name: 'RestTimer',
  mixins: [timerRestMixin]
}
</script>
