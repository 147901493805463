<template>
  <div
    v-bind="$attrs"
    :class="componentClasses"
    v-on="$listeners" @click="clicked">
    <img
      :src="image"
      class="pc-input-radio-exercise__image"
      @error="setNoImage()"
      alt="">
    <div class="pc-input-radio-exercise__overlay"/>
    <div class="pc-input-radio-exercise__area-top-right">
      <input-radio-button-circular
        class="pc-input-radio-exercise__radio"
        :id="id"
        :value="inputVal"
      />
    </div>
    <div class="pc-input-radio-exercise__area-bottom-left">
      <text-body-regular
        weight="extra-bold"
        class="pc-input-radio-exercise__name"
        line-height="multi" >{{ name }}</text-body-regular>
      <text-body-extra-small line-height="multi" class="pc-input-radio-exercise__muscle">{{ muscle }}</text-body-extra-small>
      <badge-important class="pc-input-radio-exercise__set-type">
        <text-body-extra-small
          weight="extra-bold"
          line-height="multi">{{ setType }}</text-body-extra-small>
      </badge-important>
      <label-reps :reps="setText" class="pc-input-radio-exercise__reps"/>
    </div>
  </div>
</template>

<script>
import InputRadioButtonCircular from '../../../../global/inputs/InputRadioButtonCircular'
import TextBodyRegular from '../../../../global/typography/TextBodyRegular'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import LabelReps from '../../../../global/labels/LabelReps'
import BadgeImportant from '../../../../global/badges/BadgeImportant'
export default {
  name: 'InputRadioExercise',
  components: { BadgeImportant, LabelReps, TextBodyExtraSmall, TextBodyRegular, InputRadioButtonCircular },
  props: {
    value: {
      default: ''
    },
    id: {
      required: true
    },
    name: {
      default: ''
    },
    muscle: {
      default: ''
    },
    setType: {
      default: ''
    },
    setText: {
      default: ''
    },
    image: {
      default: null
    }
  },
  data: function () {
    return {
      inputVal: '',
      hasImage: true
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'pc-input-radio-exercise': true,
        'pc-input-radio-exercise--selected': this.value === this.id,
        'pc-input-radio-exercise--no-image': !this.hasImage
      }
    }
  },
  watch: {
    value: function () {
      if (this.value !== this.inputValue) {
        this.inputVal = this.value
      }
    }
  },
  methods: {
    setNoImage () {
      this.hasImage = false
    },
    changeInput () {
      if (this.value !== this.id) {
        this.$emit('input', this.id)
      } else {
        this.$emit('input', null)
      }
    },

    clicked () {
      if (!this.disabled) {
        this.$emit('click', this.id)
        this.changeInput()
      }
    }
  }
}
</script>

<style scoped>

</style>
