<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 0C15.8389 0 18.9779 1.30024 21.3388 3.66113C23.6998 6.02212 25 9.16113 25 12.5C25 15.8389 23.6998 18.9779 21.3388 21.3388C18.9779 23.6998 15.8389 25 12.5 25C9.16108 25 6.02207 23.6998 3.66113 21.3388C1.3002 18.9779 0 15.8389 0 12.5C0 9.16113 1.3002 6.02207 3.66113 3.66113C6.02202 1.30024 9.16108 0 12.5 0ZM1.46484 12.5C1.46484 18.5848 6.41519 23.5352 12.5 23.5352C18.5848 23.5352 23.5352 18.5848 23.5352 12.5C23.5352 6.41519 18.5848 1.46484 12.5 1.46484C6.41519 1.46484 1.46484 6.41519 1.46484 12.5ZM17.4371 8.4894C17.7231 8.20341 18.1869 8.20341 18.4729 8.48935C18.7589 8.77538 18.7589 9.23915 18.4729 9.52514L11.4872 16.5108C11.3443 16.6537 11.1568 16.7253 10.9694 16.7253C10.782 16.7253 10.5945 16.6538 10.4515 16.5108L6.52898 12.5883C6.24294 12.3022 6.24294 11.8385 6.52898 11.5525C6.81501 11.2665 7.27878 11.2665 7.56477 11.5525L10.9694 14.9571L17.4371 8.4894Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconCompleteRounded',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-complete-rounded': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
