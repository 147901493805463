import axios from 'axios'
import DefineInclude from '../../DefineInclude'
import APIErrorHandler from './APIErrorHandler'
import AuthService from '../AuthService'

export default class APIHandler {
  static initAxiosConfig () {
    APIHandler.initAPIErrorConfig()
    APIHandler.initAuthHeaderConfig()
  }

  static initAPIErrorConfig () {
    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
      return response
    }, async function (error) {
      const originalRequest = error.config
      if (error && error.response && error.response.status && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true
        let auth = new AuthService()

        let token = await auth.getTokenFormRefreshToken()
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
        originalRequest.headers['Authorization'] = 'Bearer ' + token
        const axiosApiInstance = axios.create()
        return axiosApiInstance(originalRequest)
      }

      if (error.config.url.includes(DefineInclude.apiUrl) || error.config.url.includes(DefineInclude.apiV2Url)) {
        APIErrorHandler.handleError(error.response)
      }
      return Promise.reject(error)
    })
  }

  static initAuthHeaderConfig () {
    // Add a request interceptor
    axios.interceptors.request.use(
      config => {
        const token = localStorage.getItem('authToken')
        if (token) {
          config.headers.Authorization = 'Bearer ' + token
        }
        if(!config.headers['Content-Type']){
          config.headers['Content-Type'] = 'application/json'
        }

        if (!window.navigator.onLine) {
          if (config.method === 'get') {
            config.cancelToken = true
            APIErrorHandler.handleNoConnection()
          }
        }
        return config
      },
      error => {
        return Promise.reject(error)
      })
  }
}
