<template>
  <div>
    <text-content
      class="gc-popup gc-popup-exercise-swap__alternative-description"
      :size="'sm4'"
      :weight="'extra-bold'"
      :line-height="'multi'"
        >{{$i18n.t('message[\'swap.alternative-description\']')}}
    </text-content>
      <radio-button title_key="gym" :value="selectedOption" :title="$i18n.t('message[\'swap.suggest-gym\']')" key="gym"
                    :class="['btn-choice--swap_gym']" v-model="selectedOption"></radio-button>

      <radio-button title_key="home" :value="selectedOption" :title="$i18n.t('message[\'swap.suggest-home\']')" key="home"
                    :class="['btn-choice--swap_home']" v-model="selectedOption"></radio-button>
    <div class="gc-popup gc-popup-exercise-swap__line" ></div>
    <text-content
      class="exercise-swap-modal__contact-support gc-popup gc-popup-exercise-swap__support-description"
      :size="'sm4'"
      :weight="'extra-bold'"
      :line-height="'multi'"
    >{{$i18n.t('message[\'swap.support-description\']')}}
    </text-content>
      <radio-button title_key="injury" :value="selectedOption" :title="$i18n.t('message[\'swap.suggest-injury\']')" key="injury"
                    :class="['btn-choice--swap_injury']" v-model="selectedOption"></radio-button>
  </div>

</template>

<script>
import RadioButton from '../../global/widgets/InputRadioButton'
import workoutSwapMixin from '../../../../../mixins/workoutSwapMixin'
import WorkoutService from '../../../../../services/WorkoutService'
import PopupTextBody from '../../../../global/popups/popup-sub-components/PopupTextBody'
import TextContent from '../../../../root/TextContent'

export default {
  name: 'SwapOptions',
  mixins: [workoutSwapMixin],
  components: {
    PopupTextBody,
    RadioButton,
    TextContent
  },
  data: function () {
    return {
      showInjuryOption: true,
      selectedOption: ''
    }
  },
  watch: {
    selectedOption: function (option) {
      if (option && option !== '') {
        this.optionSelect()
      }
    }
  },
  props: ['data'],
  mounted () {
    this.setPageLanguageData()
    this.loadPageData()
    this.$emit('content-change')
  },
  methods: {
    loadPageData () {
      const workoutService = new WorkoutService()
      const self = this
      workoutService.setInjuries().then(function () {
        const injuriesList = workoutService.getInjuries()

        // hide injury option if no injuries exits
        if (injuriesList.length <= 0) {
          self.showInjuryOption = false
        }
      })
    },
    setPageLanguageData () {
      // title
      this.setSwapTitle(this.$i18n.t('message[\'swap.swap-exercises\']'))
    },
    optionSelect () {
      switch (this.selectedOption) {
        case 'gym':
          this.$emit('swapSubmit', 'gym')
          break

        case 'home':
          this.$emit('swapSubmit', 'home')
          break

        case 'injury':
          this.$emit('showInjuries')
          break
      }
    }
  }
}
</script>
