<template>
<div class="gc-popup-sub-text-sub-heading">
  <text-content
  :size= "'sm4'"
  :weight= "'extra-bold'"
  :lineHeight= "lineHeight"
  :decoration= "decoration">
    <slot></slot>
  </text-content>
</div>
</template>

<script>
import TextContent from '../../../root/TextContent'

export default {
  name: 'PopupTextSubHeading',
  components: {
    TextContent
  },
  props: {
    /**
    * to decorate sub heading text
    * expected values - underline, italic
    */
    decoration: {
      default: ''
    },
    /**
    * to set lineheight of Popup sub heading text
    * expected values(old version): 'single' = 1 | 'multi' = 1.5
    * expected values(new version): 'lh-sm' = 1 | 'lh-md' = 1.3 | 'lh-lg' = 1.5 | 'lh-xl' = 1.6
    */
    lineHeight: {
      default: 'lh-lg'
    }
  }
}
</script>

<style scoped></style>
