<template>

  <page-secondary class="pc-workout-programs" v-if="loaded" :header="headerOptions"  >
    <!-- Workout Programs -->
    <page-container>
      <workout-program-card
        :workouts="allWorkoutPrograms"
        page="workout-programs"
        class="pc-workout-programs" />
    </page-container>

  </page-secondary>

</template>

<script>
import WorkoutProgramCard from '../dashboard/page-components/CardListWorkouts'
import pageWorkoutListMixin from '../../../../mixins/component-mixins/page/workout/pageWorkoutListMixin'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import PageSecondary from '../../../global/pages/PageSecondary'

export default {
  name: 'PageWorkoutList',
  mixins: [pageWorkoutListMixin],
  components: {
    PageSecondary,
    PageContainer,

    WorkoutProgramCard
  },
  created () {

  }

}
</script>
