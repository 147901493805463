import BaseAPI from '../BaseAPI.js'
import axios from 'axios'
import AuthService from '../../AuthService'

export default class extends BaseAPI {
  constructor () {
    super()
    this._baseURL = 'https://addon-upsell.macroactivemvp.com/api/'
  }

  setToken () {
    const auth = new AuthService()
    this._token = auth.setToken()
  }

  _purchaseRequest (endpoint, method, data) {
    switch (method) {
      case 'post':
        return axios.post(this._baseURL + endpoint, {
          product: data
        }, {
          headers: {
            Authorization: 'Bearer ' + this._token,
            'X-Api-Host': window.location.host
          },
          dataType: 'json'
        })
      case 'get':
        return axios.get(this._baseURL + endpoint, {
          headers: {
            Authorization: 'Bearer ' + this._token,
            'X-Api-Host': window.location.host
          }
        })
      default:
        return axios.get(this._baseURL + endpoint, {
          headers: {
            Authorization: 'Bearer ' + this._token,
            'X-Api-Host': window.location.host
          }
        })
    }
  }

  purchase (product) {
    return this._purchaseRequest('purchase', 'post', product)
  }

  purchaseConfig () {
    return this._purchaseRequest('config', 'get')
  }

  paymentProfile () {
    return this._purchaseRequest('payment_profile', 'get')
  }

  purchaseStatus (paymentIntent) {
    return this._purchaseRequest('purchase_status', 'post', {
      payment_intent: paymentIntent
    })
  }

  makePurchase (product) {
    return new Promise((resolve, reject) => {
      this.purchaseConfig()
        .then((config) => {
          const stripe = Stripe(config.data.platform_stripe_key, {
            stripeAccount: config.data.trainer_stripe_id
          })
          this.purchase(product)
            .then((data) => {
              if (data.status === 'requires_action') {
                stripe.confirmCardPayment(data.client_secret).then((result) => {
                  if (result.error) {
                    resolve(result.error.message)
                  } else {
                    const paymentCompleteConfirmation = setInterval(() => {
                      this.purchaseStatus(result.paymentIntent.id)
                        .then((data) => {
                          if (data.status === 'succeeded') {
                            clearInterval(paymentCompleteConfirmation)
                            resolve(data.status)
                          }
                        })
                    }, 3000)
                  }
                })
              } else {
                resolve(data.status)
              }
            }).catch((err) => {
              reject(err)
            })
        }).catch((err) => {
          reject(err)
        })
    })
  }
}
