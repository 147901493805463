<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.249 5.39235C11.5214 5.39235 12.5528 4.36089 12.5528 3.08851C12.5528 1.81613 11.5214 0.784668 10.249 0.784668C8.97663 0.784668 7.94516 1.81613 7.94516 3.08851C7.94516 4.36089 8.97663 5.39235 10.249 5.39235Z" fill="white"/>
      <path d="M13.7013 11.6543L13.1783 7.58106C13.1196 6.99013 12.6266 6.54433 12.0333 6.54433H11.4021C11.2489 6.54433 11.1026 6.60538 10.9943 6.71251L10.2501 7.45665L9.506 6.71251C9.39772 6.60538 9.25143 6.54433 9.09822 6.54433H8.46812C7.87373 6.54433 7.38071 6.99013 7.32311 7.56609L6.79899 11.6554C6.77825 11.819 6.82894 11.9849 6.93837 12.1081C7.0478 12.2325 7.20446 12.3039 7.37034 12.3039H7.99238L8.47388 18.1787C8.53148 18.7685 9.02565 19.2155 9.62004 19.2155H10.8814C11.4758 19.2155 11.9688 18.7685 12.0287 18.1684L12.5091 12.3039H13.1299C13.2958 12.3039 13.4525 12.2325 13.5619 12.1081C13.6725 11.9849 13.7232 11.819 13.7013 11.6543Z" fill="white"/>
      <path d="M16.0083 5.39235C17.2807 5.39235 18.3122 4.36089 18.3122 3.08851C18.3122 1.81613 17.2807 0.784668 16.0083 0.784668C14.7359 0.784668 13.7045 1.81613 13.7045 3.08851C13.7045 4.36089 14.7359 5.39235 16.0083 5.39235Z" fill="white"/>
      <path d="M4.48969 0.784668C3.21913 0.784668 2.18585 1.81794 2.18585 3.08851C2.18585 4.35908 3.21913 5.39235 4.48969 5.39235C5.75911 5.39235 6.79354 4.35908 6.79354 3.08851C6.79354 1.81794 5.75911 0.784668 4.48969 0.784668Z" fill="white"/>
      <path d="M19.4609 11.6543L18.9379 7.58221C18.8792 6.99013 18.3873 6.54433 17.7929 6.54433H17.1617C17.0085 6.54433 16.8622 6.60538 16.7539 6.71251L16.0098 7.45665L15.2656 6.71251C15.1573 6.60538 15.011 6.54433 14.859 6.54433H14.2277C14.1563 6.54433 14.0918 6.56968 14.0238 6.58235C14.1724 6.84498 14.2888 7.12951 14.3233 7.44859L14.8452 11.508C14.9097 12.0021 14.7553 12.4998 14.4236 12.8741C14.2669 13.0538 14.0665 13.1805 13.8522 13.2796L14.2346 17.0268C14.2934 17.6177 14.7864 18.0635 15.3808 18.0635H16.6422C17.2366 18.0635 17.7296 17.6166 17.7895 17.0164L18.2687 12.3039H18.8896C19.0554 12.3039 19.2121 12.2325 19.3215 12.1081C19.4321 11.9849 19.4828 11.819 19.4609 11.6543Z" fill="white"/>
      <path d="M6.07266 12.8681C5.74552 12.4984 5.59346 12.0019 5.65567 11.51L6.17979 7.41956C6.20974 7.11315 6.32263 6.83669 6.46662 6.58096C6.40211 6.56944 6.34106 6.5441 6.2731 6.5441H5.64185C5.48864 6.5441 5.34235 6.60515 5.23407 6.71228L4.48992 7.45642L3.74578 6.71228C3.6375 6.60515 3.49121 6.5441 3.33916 6.5441H2.70787C2.11348 6.5441 1.62045 6.98989 1.56286 7.56585L1.03873 11.6552C1.018 11.8187 1.06868 11.9846 1.17812 12.1079C1.28755 12.2323 1.44536 12.3037 1.61009 12.3037H2.23212L2.71363 17.0266C2.77122 17.6164 3.2654 18.0633 3.85979 18.0633H5.12114C5.71553 18.0633 6.20855 17.6164 6.26845 17.0162L6.65089 13.2782C6.43552 13.178 6.23393 13.0501 6.07266 12.8681Z" fill="white"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconCommunity',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-community': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
