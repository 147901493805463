<template>
  <div class="gc-card-image-cover" style="">
    <div class="gc-card-image-cover__feature-image--blur">
        <image-base class="gc-card-image-cover__image" :src="imageSrc" style="" :default-src="defaultImageSrc"/>
    </div>
    <div class="gc-card-image-cover__feature-image">
       <image-base class="gc-card-image-cover__image" :src="imageSrc" style="" :default-src="defaultImageSrc"/>
    </div>

    <div class="gc-card-image-cover__image-overlay"></div>
    <div class="gc-card-image-cover__content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import ImageBase from '../../root/Image'

export default {
  name: 'CardImageCover',
  props: {
    imageSrc: {
      required: true
    },
    defaultImageSrc: {
      required: false
    }
  },
  components: {
    ImageBase
  }
}
</script>
