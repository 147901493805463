<template>
  <page :header="headerOptions" class="gc-page-workouts">
    <workout v-if="show"
             v-bind="options[currentIndex]"
             :currentWorkoutIndex="(currentIndex + 1)"
             :totalPages="options.length"
             :submit-text="options[currentIndex].submitText"
             @go-to-gender="goToGender()"
             @submit="formSubmit($event)"/>
  </page>
</template>

<script>
import BaseComponent from '../../layout/template-1/global/widgets/forms/FormBaseComponent'
import Workout from './page-workout-sub-components/PageWorkoutInputDropdown'
import Page from './PageForm'
import {isMeasurementsEnabled, isPhotoUploadEnabled} from '../../../includes/TemplateSettings'

export default {
  name: 'Workouts',
  extends: BaseComponent,
  components: {
    Page,
    Workout
  },
  data: function () {
    return {
      show: true,
      workoutInputData: [],
      currentIndex: 0,
      pageClass: ['macroapp--page-workout-form'],
      componentData: {},
      headerOptions: {
        show: false,
        left: 'previous',
        right: '',
        modifiers: ['with-img'],
        mainTitle: '',
        subTitle: '',
        isTransparent: true
      }
    }
  },
  beforeMount () {
    if (!isMeasurementsEnabled() && !isPhotoUploadEnabled()) {
      this.options.forEach((item, index, array) => {
        if (index === array.length - 1) {
          item.submitText = this.$i18n.t('message["general.submit"]')
          return
        }
        item.submitText = this.$i18n.t('message["general.next"]')
      })
    }
  },
  methods: {
    goToGender () {
      this.$emit('go-to-gender')
    },
    formSubmit (data) {
      this.workoutInputData.push(data.value)

      this.show = false
      if (this.currentIndex < (this.options.length - 1)) {
        setTimeout(() => {
          this.currentIndex++
          this.show = true
        }, 500)
      } else {
        const inputObj = {
          key: data.key,
          value: this.workoutInputData
        }
        this.$emit('submit', inputObj)
      }
    }
  }

}
</script>

<style scoped>

</style>
