<template>
  <div class="gc-header-subscription">
    <header-steps :steps="steps" :route="route" />
    <!-- <icon-support class="gc-header-subscription__icon-support"
                  v-if="this.$route.fullPath.includes('pay')"
                  :size="'md1'"
                  @click="supportClicked"
    /> -->
  </div>
</template>

<script>
import IconSupport from '../../root/icons/IconSupport'
import HeaderSteps from './HeaderSteps'
import headerEventBus from '../../../event-buses/headerEventBus'

export default {
  name: 'HeaderSubscription',
  components: {
    HeaderSteps,
    IconSupport
  },
  props: {
    steps: {
      type: Array
    },
    route: {
      type: String,
      default: ''
    }
  },
  methods: {
    supportClicked () {
      headerEventBus.$emit('support-icon-clicked')
    }
  }
}
</script>
