<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.87952 4.53113C10.6299 3.7807 10.6299 2.56402 9.87952 1.8136C9.12909 1.06317 7.91241 1.06317 7.16199 1.8136C6.41156 2.56402 6.41156 3.7807 7.16199 4.53113C7.91241 5.28155 9.12909 5.28155 9.87952 4.53113Z" fill="white"/>
      <path d="M17.6494 0H11.5425C11.3462 0 11.1919 0.160718 11.1919 0.357143C11.1919 0.553568 11.3462 0.714286 11.5425 0.714286H13.6915V2.36786H14.8834C15.0762 2.36786 15.234 2.525 15.234 2.725C15.234 2.92142 15.0762 3.08214 14.8834 3.08214H13.6915V4.44643H15.8475C16.0403 4.44643 16.1981 4.60713 16.1981 4.80357C16.1981 5 16.0403 5.16072 15.8475 5.16072H13.6915V6.52498H14.8834C15.0762 6.52498 15.234 6.6857 15.234 6.88213C15.234 7.07857 15.0762 7.23927 14.8834 7.23927H13.6915V8.60356H15.8475C16.0403 8.60356 16.1981 8.76428 16.1981 8.9607C16.1981 9.15715 16.0403 9.31784 15.8475 9.31784H13.6915V10.6821H14.8834C15.0762 10.6821 15.234 10.8429 15.234 11.0393C15.234 11.2357 15.0762 11.3964 14.8834 11.3964H13.6915V12.7607H15.8475C16.0403 12.7607 16.1981 12.9214 16.1981 13.1179C16.1981 13.3143 16.0403 13.475 15.8475 13.475H13.6915V14.8393H14.8834C15.0762 14.8393 15.234 15 15.234 15.1964C15.234 15.3928 15.0762 15.5536 14.8834 15.5536H13.6915V16.9179H15.8475C16.0403 16.9179 16.1981 17.0786 16.1981 17.275C16.1981 17.475 16.0403 17.6321 15.8475 17.6321H13.6915V19.2857H10.5434C10.6521 19.0857 10.7152 18.8571 10.7152 18.6143V12.7964C10.8764 12.875 11.0587 12.9214 11.248 12.9214C11.9281 12.9214 12.4785 12.3571 12.4785 11.6643V7.46785C12.4785 6.21785 11.4829 5.20358 10.2559 5.20358H6.43121C5.20421 5.20358 4.20508 6.21785 4.20508 7.46785V11.6643C4.20508 12.3571 4.75898 12.9214 5.43909 12.9214C5.62841 12.9214 5.81069 12.8786 5.96845 12.7964V18.6143C5.96845 18.8571 6.03155 19.0857 6.14023 19.2857H2.35057C2.15425 19.2857 2 19.4464 2 19.6429C2 19.8393 2.15425 20 2.35057 20H17.6494C17.8457 20 18 19.8393 18 19.6429V0.357143C18 0.160718 17.8457 0 17.6494 0Z" fill="white"/>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconMetrics',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-metrics': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
