import BaseComponent from '../../../../components/layout/template-1/global/base/BaseComponent'
import WorkoutProgramsService from '../../../../services/WorkoutProgramsService'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  extends: BaseComponent,
  data: function () {
    return {
      pageClass:
        'macroapp--page-common app-background-color macroapp--page-week-set macroapp--page-workout-list',
      workoutPrograms: [],
      allWorkoutPrograms: [],
      challengePrograms: [],
      description: '',
      loaded: false,
      headerOptions: {
        show: true,
        left: 'menu',
        right: '',
        modifiers: ['small-title', 'with-bg-color'],
        header: {
          mainTitle: '',
          subTitle: ''
        }
      }
    }
  },
  created () {
    this.setPageTitle(this.$i18n.t("message['general.workout-programs']"))
  },
  beforeMount () {
    this.loadPageData()
  },
  mounted () {
  },
  methods: {
    loadPageData () {
      const workoutService = new WorkoutProgramsService()
      workoutService
        .setWorkoutProgramList()
        .then(() => {
          this.workoutPrograms = workoutService.getWorkoutPrograms()
          this.allWorkoutPrograms = workoutService.getAllWorkoutPrograms()
          this.challengePrograms = workoutService.getChallengeWorkoutPrograms()
        }).finally(() => {
          this.loaded = true
          this.headerOptions.mainTitle = this.$i18n.t("message['general.workout-programs']")
          this.headerOptions.header.mainTitle = this.$i18n.t("message['general.workout-programs']")
          this.ready()
          pageReadyEvent()
        })
    },
    navigateNext (id) {
      this.$router.push({
        path: this.$appConfig.appUrlList.workoutBase,
        query: {
          workout: id

        }
      })
    },
    ready () {
      this.hideLoading()
    }
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
  }
}
