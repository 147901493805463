<template>
      <text-body-extra-small class="pc-form-workout-log__text-set-complete" line-height="multi">{{text}}</text-body-extra-small>
</template>
<script>
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
export default {
  name: 'TextSetComplete',
  components: { TextBodyExtraSmall },
  props: {
    totalSets: {
      default: 0,
      type: Number
    },
    marked: {
      default: 0,
      type: Number
    }
  },
  computed: {
    text: function () {
      if (this.totalSets === 0) {
        return ''
      } else if (this.marked === 0) {
        return this.$i18n.t('message["workout-log.mark-no-sets"]')
      } else if (this.marked === this.totalSets) {
        return this.$i18n.t('message["workout-log.mark-all-sets"]')
      } else {
        return this.$i18n.t('message["workout-log.mark-sets"]', [
          this.marked,
          this.totalSets
        ])
      }
    }
  }
}
</script>
