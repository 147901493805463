<template>
  <div @click="viewMore('home_card')"  :class="cardClass">
    <p class="card-measure__title">{{ name }}</p>
    <div class="card-measure__add-button">
      <a :href="void 0" v-on:click.stop="addData()">
        <plus-svg/>
      </a>
    </div>
    <div class="card-measure-first-section">
    <p class="card-measure__difference">
              {{progress.diff}}<span> {{ measurementUnit }}</span>
            </p>
    </div>
    <div class="container card-measure-second-section">
      <div class="row align-items-center">
        <div class="col-4 p-0 text-left">
          <p class="card-measure__measure">
            {{ getFirstTimeValue() }} <span> {{ measurementUnit }}</span>
          </p>
          <p class="card-measure__day">{{ getFirstTimeDate() }}</p>
        </div>
        <div class="col-4 text-center">
          <div class="card-measure__arrow-top-measure" v-if="progress.diff > 0">

            <p class="card-measure__status">{{ progress.diffText }}</p>
          </div>
          <right-arrow-svg/>
        </div>
        <div class="col-4 p-0 text-right">
          <p class="card-measure__measure">{{getLatestValue()}} <span>{{ measurementUnit }}</span></p>
          <p class="card-measure__day">{{ getLatestDate() }}</p>
        </div>
      </div>
    </div>
    <div class="card-measure__more-button" v-if="isEditable" v-on:click.stop.prevent="viewMore('home_more')">
       <p class="card-measure__more-link">{{ $t('message["general.more"]') }} ></p>
    </div>
  </div>
</template>
<script>
import PlusSvg from './svgs/PlusSvg'
import RightArrowSvg from './svgs/RightArrowSvg'
import fitnessDiaryFitnessMeasurementComponentMixin from '../../../../../mixins/component-mixins/page/fitness-diary/fitnessDiaryFitnessMeasurementComponentMixin'

export default {
  name: 'CardLargeFitnessDiaryMeasurement',
  mixins: [fitnessDiaryFitnessMeasurementComponentMixin],
  computed: {
    cardClass: function () {
      return [
        'card-measure', 'card-measure--big', 'card-measure__' + this.type
      ]
    }
  },
  components: { RightArrowSvg, PlusSvg }

}
</script>
