<template>
    <div :class="componentClasses" @click="viewMore()">
        <div class="gc-card-photo-journey-compare__header">
            <text-content :size="'sm2'" :weight="'extra-bold'" :lineHeight="'multi'">{{$t('message["fitness-diary.photo-journey"]')}}</text-content>
            <icon-add v-if="isEditable" :size="'sm3'" @click.stop="showPopup()" />
            <icon-forward v-else :size="'sm3'"  />
        </div>
      <div class="gc-card-photo-journey-compare__wrapper">
        <div class="gc-card-photo-journey-compare__before" v-if="showFirstImages">
            <div class="gc-card-photo-journey-compare__second-title">
                <text-content :size="'sm1'" :lineHeight="'multi'">
                  <div>{{$t('message["fitness-diary.before"]')}}</div>
                  <div> &nbsp; - &nbsp; </div>
                  <div>{{firstDate}}</div>
                </text-content>
            </div>
            <div class="row gc-card-photo-journey-compare__image-wrapper">
                <div class="col-4 gc-card-photo-journey-compare__images-wrap" v-for="(image, index) in firstImages" :key="index">
                    <loading-buffer height="160" v-show="image.image &&image.state==='loading'&&showBuffer"/>
                    <img
                        alt =''
                        v-if="image.image"
                        v-show="!(image.state==='loading'&&showBuffer)"
                        class="gc-card-photo-journey-compare__images"
                        :src="image.image"
                        v-on:load="hideLoadingFirstImage(index)"
                        v-on:error="hideLoadingFirstImage(index)"
                    />
                    <div v-else class="gc-card-photo-journey-compare__images--empty">
                        <card-photo-compare-empty />
                    </div>
                </div>
            </div>
        </div>
        <div class="gc-card-photo-journey-compare__now" v-if="showLatestImages">
            <div class="gc-card-photo-journey-compare__second-title">
                <text-content :size="'sm1'" :lineHeight="'multi'">
                  <div>{{$t('message["fitness-diary.now"]')}}</div>
                  <div> &nbsp; - &nbsp; </div>
                  <div>{{latestDate}}</div></text-content>
            </div>
            <div class="row gc-card-photo-journey-compare__image-wrapper">
                <div  class="col-4 gc-card-photo-journey-compare__images-wrap" v-for="(image, index) in latestImages" :key="index">
                  <loading-buffer height="160" v-show="image.image && image.state==='loading'&&showBuffer"/>
                    <img
                        alt =''
                        v-if="image.image"
                        v-show="!(image.state==='loading'&&showBuffer)"
                        class="gc-card-photo-journey-compare__images"
                        :src="image.image"
                        v-on:load="hideLoadingLatestImage(index)"
                        v-on:error="hideLoadingLatestImage(index)"
                    />
                    <div v-else class="gc-card-photo-journey-compare__images--empty">
                        <card-photo-compare-empty />
                    </div>
                </div>
            </div>
        </div>
      </div>
        <div class="gc-card-photo-journey-compare__initial" v-if="!showLatestImages && showFirstImages">
          <text-content :size="'sm1'" :lineHeight="'multi'">{{ $t('message["fitness-diary.photo-journey-without-latest-image"]') }}</text-content>
        </div>
        <div class="gc-card-photo-journey-compare__empty" v-if="!showLatestImages && !showFirstImages">
            <text-content :size="'sm1'" :lineHeight="'multi'">{{ $t('message["fitness-diary.photo-journey-description"]') }}</text-content>
        </div>
        <div class="gc-card-photo-journey-compare__more-button" v-if="isEditable" @click.stop.prevent="viewMore()">
        <text-content :size="'sm1'" :lineHeight="'multi'">{{ $t('message["general.more"]') }} ></text-content>
        </div>
    </div>
</template>
<script>
import IconAdd from '../../root/icons/IconAdd'
import TextContent from '../../root/TextContent'
import CardPhotoCompareEmpty from './card-my-journey-sub-components/CardPhotoCompareEmpty'
import Image from '../../root/Image'
import LoadingBuffer from '../../layout/template-1/global/widgets/LoadingBuffer'
import IconForward from '../../root/icons/IconForward'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'CardPhotoJourneyCompare',
  props: {
    firstDate: {
      type: String,
      default: ''
    },
    latestDate: {
      type: String,
      default: ''
    },
    showFirstImages: {
      type: Boolean,
      default: true
    },
    showLatestImages: {
      type: Boolean,
      default: true
    },
    isEditable: {
      default: true
    },
    firstImages: {
      type: Object,
      default: function () {
        return {
          front: { image: '', show: false, state: 'loading' },
          side: { image: '', show: false, state: 'loading' },
          back: { image: '', show: false, state: 'loading' }
        }
      }
    },
    latestImages: {
      type: Object,
      default: function () {
        return {
          front: { image: '', show: false, state: 'loading' },
          side: { image: '', show: false, state: 'loading' },
          back: { image: '', show: false, state: 'loading' }
        }
      }
    }
  },
  data () {
    return {
      isClicked: false,
      showBuffer: true
    }
  },
  computed: {
    ...mapGetters({
      getPlanExpiredPopupShow: 'getPlanExpiredPopupShow'
    }),
    componentClasses: function () {
      return {
        'gc-card-photo-journey-compare': true,
        'gc-card-photo-journey-compare-on-tap': this.isClicked,
        'gc-card-photo-journey-compare--empty': !this.showLatestImages && !this.showFirstImages
      }
    }
  },
  components: {
    IconForward,
    IconAdd,
    TextContent,
    CardPhotoCompareEmpty,
    Image,
    LoadingBuffer
  },
  methods: {
    ...mapMutations({
      showSubscriptionExpiredPopup: 'showSubscriptionExpiredPopup'
    }),
    viewMore () {
      this.onClicked()
      this.$router.push({
        path: this.$route.path,
        query: { page: 'photo-journey' }
      })
    },
    showPopup () {
      this.$emit('showUploadPopup')
    },
    hideLoadingLatestImage (index) {
      this.latestImages[index].state = 'loaded'
      this.hideLoading()
    },
    hideLoadingFirstImage (index) {
      this.firstImages[index].state = 'loaded'
      this.hideLoading()
    },
    hideLoading () {
      this.showBuffer = false
    },
    onClicked () {
      this.activeOnClick()
      setTimeout(() => {
        this.deactiveOnClick()
      }, 300)
    },

    activeOnClick () {
      this.isClicked = true
    },

    deactiveOnClick () {
      this.isClicked = false
    }
  }
}
</script>
