import { render, staticRenderFns } from "./IconRestaurant.vue?vue&type=template&id=5bc72288&scoped=true"
import script from "./IconRestaurant.vue?vue&type=script&lang=js"
export * from "./IconRestaurant.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bc72288",
  null
  
)

export default component.exports