<template>
  <div :class="componentClasses" >
      <div class="gc-progress-bar__bar gc-progress-bar__bar--filled" :class="barClasses" :style="barStyles"></div>
  </div>
</template>

<script>

export default {
  name: 'ProgressBar',
  props: {
    progress: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-progress-bar': true
      }
    },
    barClasses: function () {
      return {
        'gc-progress-bar__bar--warning': (this.progress > 100),
        'gc-progress-bar__bar--disabled': this.disabled
      }
    },
    barStyles: function () {
      let progress = (this.progress > 100) ? 100 : this.progress
      return {
        'width': progress + '%'
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped></style>
