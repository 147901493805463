<template>
  <button-base :class="componentClasses" @click="clicked()">
    <text-content :size="'sm1'" :line-height="'multi'" :weight="'extra-bold'"> {{ text }}</text-content>
  </button-base>
</template>

<script>

import TextContent from '../../root/TextContent'
import ButtonBase from '../../root/ButtonBase'

export default {
  name: 'ButtonLinkSecondary',
  components: {
    ButtonBase,
    TextContent
  },
  props: {
    /**
     * The main text of the button
     */
    text: {
      default: ''
    },
    /**
     * Enabled / Disabled status
     * Expected values: Boolean
     */
    disabled: {
      default: false
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-button-link-secondary': true,
        'gc-button-link-secondary--disabled': this.disabled
      }
    }
  },
  /**
   * function to emit the click event
   */
  methods: {
    clicked () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped></style>
