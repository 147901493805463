import BaseComponent from '../../../components/layout/template-1/global/base/BaseComponent'
import { mapMutations } from 'vuex'
import NotificationService from '../../../services/NotificationService'

export default {
  extends: BaseComponent,
  computed: {
    notificationText () {
      let notificationCount = this.notificationList.length
      return this.$i18n.tc("message['notification.default']",
        notificationCount)
    },
    notificationURL () {
      return this.notificationList[0].action.url || '#'
    },
    notificationList () {
      return this.$store.getters['getNotificationList'].filter(notification => {
        return !(
          typeof notification.action.url !== 'undefined' &&
          (notification.action.url.includes('workout-programs/update') ||
            notification.action.url.includes('meal-plans/update') || (notification.action.url.includes('api/notifications/subscription/') && notification.action.url.includes('/cancel')))
        )
      })
    },
    show () {
      return !(typeof this.params.page !== 'undefined' && this.params.page === 'notifications')
    }
  },
  methods: {
    ...mapMutations({
      setNotificationBarSettings: 'pageStore/setNotificationBar',
      resetNotificationBarSettings: 'pageStore/resetNotificationBar'
    }),
    mapNotificationText (text, url) {
      text = text.trim()
      let newText = text

      switch (text) {
        case this.$i18n.t("message['messages.payment-action-required-text']").trim():
          newText = this.$i18n.t("message['notification.bank-authorization-requires.short']")
          break
        case this.$i18n.t("message['messages.sca-bank-notice']").trim():
          newText = this.$i18n.t("message['notification.confirm-your-payment.short']")
          break
        case this.$i18n.t("message['messages.account-disabled']").trim():
          newText = this.$i18n.t("message['notification.account-disabled.short']")
          break
        case this.$i18n.t("message['messages.successfully-subscribed']").trim():
          newText = this.$i18n.t("message['notification.subscription-successful.short']")
          break
        case this.$i18n.t("message['messages.subscription-incomplete']").trim():
          newText = this.$i18n.t("message['notification.complete-your-subscription.short']")
          break
        case this.$i18n.t("message['messages.subscription-canceled']").trim():
          newText = this.$i18n.t("message['notification.subscription-canceled.short']")
          break
        default:
          newText = text
          break
      }
      if (url.includes('/api/notifications/subscription')) {
        newText = this.$i18n.t("message['notification.subscription-canceled.short']")
      }
      return newText
    },
    navigate () {
      if (this.notificationList.length === 1) {
        let url = this.notificationURL
        this.doAction(url)
      } else {
        this.navigateToList()
      }
    },
    navigateToList () {
      let path = '/dashboard?page=notifications'
      if (path !== this.$router.currentRoute.fullPath) {
        // holding router so slick dom breaking is not visible before fade effect
        this.delayedRouterPush({
          path: path
        })
      }
    },
    doAction (url) {
      if (url.includes('meal-plans/update')) {
        this.$router.push({
          path: this.$appConfig.appUrlList.mealPlanUpdate
        })
      } else if (url.includes('workout-programs/update')) {
        this.$router.push({
          path: this.$appConfig.appUrlList.workoutUpdate
        })
      } else if (url.includes('api/notifications/subscription/') && url.includes('/cancel')) {
        this.doCancelNotificationsSubscription(url)
      } else {
        window.location.href = url
      }
    },
    doCancelNotificationsSubscription (url) {
      let service = new NotificationService()
      service.cancelSubscription(url).then(() => {
        this.setNotificationBarSettings({show: false})
      }).catch(() => {
        this.$store.commit('showServerErrorPopup')
      })
    }
  }
}
