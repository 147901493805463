import axios from 'axios'
import BaseAPI from '../BaseAPI'
import DefineInclude from '../../../DefineInclude'
export default class WorkoutApi extends BaseAPI {
  _resource ='workout-programs'
  _emailApiBaseUrl = DefineInclude.shoppingListEmailAPIurl
  constructor () {
    super()
    this.endpoint = 'workout-programs'
    this.url = this._baseURL + this.endpoint
  }

  getWorkoutList (data) {
    return axios.get(this.url, {
      params: { }
    })
  }

  getWorkoutDetails (pid) {
    return axios.get(this.url + '/' + pid, {
      params: { }
    })
  }

  getExerciseDetails (pid, eid) {
    return axios.get(this.url + '/' + pid + '/exercise/' + eid, {
      params: { }
    })
  }

  /**
   * @deprecated - API is removed
   * @returns {Promise<AxiosResponse<T>>}
   */
  getExerciseSwapDetails () {
    return axios.get(this.url + '/swap-list', {
      params: { }
    })
  }

  /**
   * get improved exercise swap suggestions
   *
   * @param workoutID
   * @param exerciseID
   * @returns {Promise<AxiosResponse<T>>}
   */
  getSuggestedExerciseSwapDetails (workoutID, exerciseID) {
    const url = this._baseURL
    const pathArr = [this._resource, workoutID, 'exercise', exerciseID, 'swap']
    return axios.get(url + this._getPath(pathArr))
  }

  swapForAlternativeExercise (workoutID, exerciseID, alternativeExerciseID) {
    const url = this._baseURL
    const pathArr = [this._resource, workoutID, 'exercise', exerciseID, 'swap']
    return axios.post(url + this._getPath(pathArr), {exercise_id: alternativeExerciseID})
  }

  sendWorkoutSummaryPdf (payload) {
    const url = this._emailApiBaseUrl
    const headers = this._headers
    headers.Authorization = 'Bearer ' + this._token
    headers['X-Api-Host'] = DefineInclude.apiUrl.replace(/\/$/, '')
    const pathArr = [this._resource, 'email']
    return axios.post(url + this._getPath(pathArr), payload, { headers: headers })
  }

  swapExercise (pid, genWorkoutId, suitableFor) {
    let data = {
      pid: pid,
      gen_workout_id: genWorkoutId
    }
    if (suitableFor) {
      data['suitable_for'] = suitableFor
    }
    return axios.post(this.url + '/swap-exercise', data, {
      headers: this._getPostHeaders()
    })
  }

  swapInjuryRequestExercise (pid, genWorkoutId, injuries, message, suitableFor) {
    let note = ''
    if (injuries.length > 0) {
      note = ((message) ? message + ' ' : '') + '(' + injuries.join(', ') + ')'
    } else {
      note = message
    }
    return axios.post(this.url + '/swap-request', JSON.stringify({
      pid: parseInt(pid),
      gen_workout_id: parseInt(genWorkoutId),
      injury: [],
      message: note,
      suitable_for: suitableFor
    }), {
      headers: this._getPostHeaders()
    })
  }

  getUpdateData () {
    const url = this._baseURL
    const pathArr = [this.endpoint, 'update']
    return axios.get(url + this._getPath(pathArr))
  }

  updateData (payload) {
    const url = this._baseURL
    const pathArr = [this.endpoint, 'update']
    return axios.post(url + this._getPath(pathArr), payload)
  }
}
