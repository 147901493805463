<template>
  <div :class="componentClasses" @click="clicked()">
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.3175 4.92852C0.3175 4.31483 0.814993 3.81746 1.42855 3.81746L18.5714 3.81746C18.8679 3.81746 19.1458 3.96626 19.3532 4.18873C19.5621 4.41274 19.6825 4.69315 19.6825 4.92852V6.03955L0.3175 6.03955L0.3175 4.92852ZM9.31289e-06 6.03955L0 6.19843L0 8.73817L0 8.8969L3.98596e-05 15.4048C3.98596e-05 15.7379 0.163917 16.0923 0.414561 16.3611C0.666649 16.6314 1.02369 16.8333 1.42855 16.8333L18.5714 16.8333C19.3603 16.8333 19.9999 16.1939 19.9999 15.4048L19.9999 8.8969L20 8.73817V6.19843V6.0397L19.9999 4.92852C19.9999 4.59548 19.836 4.24099 19.5854 3.97221C19.3333 3.70189 18.9763 3.5 18.5714 3.5L1.42855 3.5C0.63969 3.5 3.98596e-05 4.13947 3.98596e-05 4.92852L9.31289e-06 6.03955ZM0.3175 15.4048L0.3175 8.8969L19.6825 8.8969L19.6825 15.4048C19.6825 16.0185 19.185 16.5159 18.5714 16.5159L1.42855 16.5159C1.13204 16.5159 0.854192 16.3671 0.646733 16.1446C0.43783 15.9206 0.3175 15.6402 0.3175 15.4048ZM13.2804 10.9604C13.2804 10.8727 13.3514 10.8017 13.4391 10.8017L15.6614 10.8017C15.6949 10.8017 15.7347 10.819 15.7698 10.8566C15.8063 10.8959 15.8201 10.9382 15.8201 10.9604V11.9127C15.8201 12.0004 15.7491 12.0714 15.6614 12.0714H13.4391C13.4056 12.0714 13.3659 12.0541 13.3307 12.0165C13.2942 11.9772 13.2804 11.9349 13.2804 11.9127V10.9604ZM13.4391 10.4842C13.1761 10.4842 12.963 10.6973 12.963 10.9604V11.9127C12.963 12.0326 13.0202 12.149 13.0985 12.233C13.1783 12.3185 13.2973 12.3889 13.4391 12.3889H15.6614C15.9244 12.3889 16.1376 12.1758 16.1376 11.9127V10.9604C16.1376 10.8405 16.0803 10.7241 16.002 10.6401C15.9222 10.5546 15.8033 10.4842 15.6614 10.4842L13.4391 10.4842Z"
        fill="black"
      />
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconCvv',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-cvv': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
