<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 15C3.35478 15 0 11.6456 0 7.5C0 3.35478 3.3544 0 7.5 0C11.6452 0 15 3.3544 15 7.5C15 11.6452 11.6456 15 7.5 15ZM10.8792 6.99999H8.00001V4.12075C8.00001 3.84463 7.77615 3.62074 7.5 3.62074C7.22385 3.62074 6.99999 3.8446 6.99999 4.12075V6.99999H4.12075C3.84463 6.99999 3.62074 7.22385 3.62074 7.5C3.62074 7.77615 3.8446 8.00001 4.12075 8.00001H6.99999V10.8792C6.99999 11.1554 7.22385 11.3793 7.5 11.3793C7.77615 11.3793 8.00001 11.1554 8.00001 10.8792V8.00001H10.8792C11.1554 8.00001 11.3793 7.77615 11.3793 7.5C11.3793 7.22385 11.1554 6.99999 10.8792 6.99999Z" fill="#AFAFAF"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconAddFillRounded',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-addFillRounded': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
