<template>
  <transition name="fade" mode="out-in">
    <component v-bind:is="dynamicComponent" v-bind="componentData"></component>
  </transition>
</template>

<script>
import BaseComponent from '../../global/base/BaseComponent'
import ContentPageFactoryService from '../../../../../services/ContentPageFactoryService'
import ListContainer from './ListContainer'
import VideoContainer from './VideoContainer'
import ContentContainer from './ContentContainer'
import NativeAppMixin from '../../../../../mixins/NativeAppMixin'

export default {
  name: 'ContentPageArea',
  extends: BaseComponent,
  mixins: [NativeAppMixin],
  data: function () {
    return {
      componentData: {},
      components: {
        list: ListContainer,
        video: VideoContainer,
        content: ContentContainer
      },
      dynamicComponent: ''
    }
  },
  props: {
    pageName: {
      type: String,
      required: true
    },
    hideHeaderImage: {
      type: Boolean,
      required: false
    }
  },
  mounted () {
    const contentPageFactory = new ContentPageFactoryService()
    const page = contentPageFactory.getPage(this.pageName)
    page.setPageData().then(() => {
      this.setPageData(page)
      this.setPageComponentByType(page.pageData.type)
    })
  },

  methods: {
    setPageData (page) {
      switch (page.pageData.type) {
        case 'custom-list': {
          const listData = page.getListData()
          this.componentData = {
            description: page.pageData.page.description,
            itemList: listData.items,
            mainImage: listData.headerImage,
            hideImage: this.hideHeaderImage
          }
          break
        }
        case 'custom-video': {
          const tags = []
          for (const [key, value] of Object.entries(page.pageData.tags)) {
            tags.push({ id: key, text: value })
          }
          this.componentData = {
            tags: tags,
            videoList: page.pageData.items,
            hideImage: this.hideHeaderImage
          }
          break
        }
        default: {
          this.componentData = {
            description: page.pageData.page.description,
            mainImage: page.pageData.page.header_image,
            hideImage: this.hideHeaderImage
          }
          break
        }
      }
    },
    setPageComponentByType (type) {
      switch (type) {
        case 'custom-list':
          this.dynamicComponent = this.components.list
          break
        case 'custom-video':
          this.dynamicComponent = this.components.video
          break
        default:
          this.dynamicComponent = this.components.content
          break
      }
    }
  }
}
</script>
