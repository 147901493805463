<template>
  <page-secondary
    :header="headerOptions"
    class="pc-food-dairy-log-meals"
  >
    <container-meal-plan-meal-log
      v-show="currentTab === 'meal-plan'"
      v-if="mealLog"
      :is-active-tab="currentTab === 'meal-plan'"
      :meal-log="mealLog"
      @log="emitLog"
      @success="setSuccess"
      @change-tab="changeTab"
    />
    <container-custom-meal-log
      v-show="currentTab === 'new-meal'"
      v-if="mealLog"
      :is-active-tab="currentTab === 'new-meal'"
      :support-net-carbs="showNetCarbs"
      :meal-log="mealLog"
      @success="setSuccess($event)"
      @log="emitLog($event)"
      @change-tab="changeTab($event)"
    />
    <container-dine-out-meal-log
      v-show="currentTab === 'dine-out'"
      v-if="mealLog"
      :is-active-tab="currentTab === 'dine-out'"
      :support-net-carbs="showNetCarbs"
      :meal-log="mealLog"
      @success="setSuccess($event)"
      @log="emitLog($event)"
      @change-tab="changeTab($event)"
      @meal-erased="dineOutMealSaved"
      @meal-saved="dineOutMealSaved"
    />
    <container-saved-meals
      v-show="currentTab === 'saved-meals'"
      v-if="enableSavedMeals && mealLog"
      :is-active-tab="currentTab === 'saved-meals'"
      :meal-log="mealLog"
      @log="emitLog($event)"
      @success="setSuccess($event)"
      @change-tab="changeTab($event)"
    />
    <popup-food-dairy-success-save
      v-if="popupLogSuccess.visible"
      :visible="popupLogSuccess.visible"
      :saved="popupLogSuccess.saved"
      @close="popupLogSuccess.visible=false"
    />
  </page-secondary>
</template>

<script>
import PageSecondary from '../../../global/pages/PageSecondary'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import ContainerCustomMealLog from './page-components/ContainerCustomMealLog'
import ContainerMealPlanMealLog from './page-components/ContainerMealPlanMealLog'
import ContainerSavedMeals from './page-components/ContainerSavedMeals'
import PopupFoodDairySuccessSave from './page-components/PopupFoodDairySuccessSave'
import pageLogMealsMixin from '../../../../mixins/component-mixins/page/food-diary/pageLogMealsMixin'
import ContainerDineOutMealLog from './page-components/ContainerDinningOutMealLog'

export default {
  name: 'PageLogMeals',
  components: {
    ContainerDineOutMealLog,
    PopupFoodDairySuccessSave,
    ContainerSavedMeals,
    PageContainer,
    PageSecondary,
    ContainerMealPlanMealLog,
    ContainerCustomMealLog
  },
  mixins: [pageLogMealsMixin]

}
</script>
