<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.9993 11.3697C23.0682 17.7102 18.1352 22.9269 12.0033 22.9994C11.9616 23 11.9187 23 11.877 23C7.27415 23 3.10299 20.0286 1.47138 15.5716C1.40822 15.3984 1.34797 15.2223 1.29234 15.0444C1.19384 14.7299 1.36071 14.392 1.66548 14.2902C1.97199 14.1901 2.29646 14.3614 2.39496 14.676C2.44479 14.8347 2.49809 14.9917 2.55487 15.1468C4.01672 19.1402 7.7539 21.8019 11.877 21.8019C11.9146 21.8019 11.9523 21.8019 11.9894 21.8013C17.4827 21.7366 21.9019 17.0632 21.8399 11.3829C21.7773 5.70312 17.2603 1.13523 11.7634 1.19873C7.90687 1.24426 4.45417 3.5944 2.85037 7.14807L5.99656 5.86427C6.29611 5.74386 6.63159 5.89422 6.74863 6.20155C6.86567 6.50947 6.71966 6.85752 6.42242 6.97854L2.03688 8.76735C1.96851 8.79551 1.89609 8.80929 1.82424 8.80929C1.73617 8.80929 1.6481 8.78832 1.56814 8.74698C1.42155 8.6721 1.31204 8.53671 1.26685 8.37436L0.0222801 3.86039C-0.0652105 3.54228 0.112669 3.211 0.420914 3.11994C0.729159 3.03068 1.04899 3.2134 1.13706 3.5321L1.92216 6.38007C3.76178 2.55981 7.54126 0.0503213 11.7507 0.000598738C11.7935 -3.29106e-07 11.8364 0 11.8793 0C17.9538 0 22.9303 5.0729 22.9993 11.3697ZM11.7704 5.35447C11.4499 5.35447 11.191 5.62285 11.191 5.95353V11.3895C11.191 11.3973 11.195 11.4038 11.195 11.4116C11.1967 11.456 11.2089 11.4967 11.2199 11.5392C11.2286 11.5728 11.2327 11.6075 11.2466 11.6387C11.2622 11.674 11.2866 11.7022 11.3086 11.7339C11.33 11.7651 11.3486 11.798 11.3752 11.8238C11.3995 11.8478 11.4302 11.8627 11.4586 11.8819C11.494 11.9059 11.527 11.931 11.567 11.9466C11.5739 11.9496 11.5786 11.9556 11.5861 11.958L17.5221 14.0152C17.583 14.0361 17.645 14.0463 17.7058 14.0463C17.948 14.0463 18.174 13.8876 18.2551 13.6372C18.3571 13.3233 18.1937 12.9842 17.8895 12.8788L12.3492 10.9587V5.95413C12.3498 5.62285 12.0908 5.35447 11.7704 5.35447Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconHistory',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-history': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
