<template>
    <div :class="componentClasses" v-show="showComponent">
      <div class="pc-input-group-ingredients-sub-group__header"  >
        <text-body-regular
          weight="extra-bold"
          line-height="multi"
        >
        {{subCategory.name}}
        </text-body-regular>
        <input-check-box-squared
          @click="toggleDislikedGroup"
          :id="0"
          v-model="allSelected"
          />
      </div>
        <div class="pc-input-group-ingredients-sub-group__list-ingredients">
        <ingredients-list
            v-for="(ingredient, index) in subCategory.ingrediants"
            v-if="showIngredient(ingredient.id)"
            ref="ingredients"
            :key="index"
            :count="count"
            :name="ingredient.name"
            :id="ingredient.id"
            :image="ingredient.image"
            :quantity="ingredient.quantity"
            :unit="ingredient.units"
            v-model="subGroupDislikes"
        />
      </div>
    </div>
</template>
<script>
import ButtonSecondaryAccordion from '../../../../../../global/buttons/ButtonSecondaryAccordion'
import IngredientsList from './InputGroupIngredientsItem'
import IconDislike from '../../../../../../root/icons/IconDislike'
import InputCheckBoxSquared from '../../../../../../global/inputs/InputCheckBoxSquared'
import TextBodySmall from '../../../../../../global/typography/TextBodySmall'
import TextBodyRegular from '../../../../../../global/typography/TextBodyRegular'
export default {
  name: 'InputGroupIngredientsSubGroup',
  props: {
    count: {
      type: Number,
      default: 1
    },
    type: {
      default: ''
    },
    subCategory: {
      default: function () {
        return {}
      }
    },
    value: {
      default: function () {
        return []
      },
      type: Array
    }
  },
  data () {
    return {

      showIngredientsList: false,
      subGroupDislikes: [],
      allSelected: []

    }
  },
  components: {
    TextBodyRegular,
    TextBodySmall,
    ButtonSecondaryAccordion,
    IngredientsList,
    IconDislike,
    InputCheckBoxSquared
  },
  watch: {
    subGroupDislikes: function () {
      if (this.isSubGroupDislike) {
        this.allSelected = [0]
      } else {
        this.allSelected = []
      }

      this.emitInput()
    },
    value: {
      deep: true,
      immediate: true,
      handler: function () {
        this.subGroupDislikes = this.value
      }
    },
    isSubGroupDislike: function (val) {
    },
    subGroupDislikesCount: function () {
      if (this.subGroupDislikesCount === this.subCategory.ingrediants.length) {
        this.$emit('all-selected', this.subGroupDislikesCount)
      } else if (this.subGroupDislikesCount === 0) {
        this.$emit('all-deselected')
      } else {
        this.$emit('some-selected')
      }
    }

  },
  mounted () {
  },
  computed: {
    showComponent: function () {
      return !((this.type === 'cart' && this.isSubGroupDislike) || (this.subGroupDislikesCount === 0 && this.type === 'purchased'))
    },
    componentClasses: function () {
      return {
        'pc-input-group-ingredients-sub-group': true
      }
    },
    setCounts: function () {
      if (this.subGroupDislikesCount === 0 || this.subGroupDislikesCount === 1) {
        return this.subGroupDislikesCount + ' Ingredient disliked'
      } else {
        return this.subGroupDislikesCount + ' Ingredients disliked'
      }
    },
    isSubGroupDislike: function () {
      return this.subCategory.ingrediants.every(ing => {
        return this.subGroupDislikes.includes(ing.id)
      })
    },
    subGroupDislikesCount () {
      return this.subCategory.ingrediants.filter(ing => {
        return this.subGroupDislikes.includes(ing.id)
      }).length
    }

  },
  methods: {
    showIngredient (id) {
      const isIngredientSelected = this.value.includes(id)
      return !((this.type === 'cart' && isIngredientSelected) || (!isIngredientSelected && this.type === 'purchased'))
    },
    emitInput () {
      this.$emit('input', this.subGroupDislikes)
    },

    selectedAll () {
      this.subCategory.ingrediants.forEach((ingredient) => {
        if (!this.subGroupDislikes.includes(ingredient.id)) {
          this.subGroupDislikes.push(ingredient.id)
        }
      })
    },
    deselectAll () {
      let idList = []
      this.subCategory.ingrediants.forEach((ingredient) => {
        idList.push(ingredient.id)
      })
      this.subGroupDislikes = this.subGroupDislikes.filter((obj) => {
        return !idList.includes(obj)
      })
    },
    toggleDislikedGroup () {
      if (this.isSubGroupDislike) {
        this.deselectAll()
      } else {
        this.selectedAll()
      }
    }
  }
}
</script>
