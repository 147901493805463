<template>
  <div class="pc-popup-swap-injuries-area">
    <text-content class="pc-popup-swap-injuries-area__info" :size="'sm2'" :weight="'medium'" :line-height="'multi'">{{customInputLabel}}</text-content>
      <div class="form-group ma-form ">
        <text-area class="pc-popup-swap-injuries-area__text-area" v-model="message" :placeholder="customInputPlaceholder"></text-area>
      </div>
    <popup-floating-footer>
      <button-primary class="pc-popup-swap-injuries-area__submit-button" @click="footerSubmit" :text="footerData.btnLabel"></button-primary>
    </popup-floating-footer>
  </div>
</template>

<script>
import WorkoutService from '../../../../../services/WorkoutService'
import workoutSwapMixin from '../../../../../mixins/workoutSwapMixin'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupFloatingFooter from '../../../../global/popups/popup-sub-components/PopupFloatingFooter'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import TextArea from '../../../../global/inputs/TextArea'
import TextContent from '../../../../root/TextContent'

export default {
  name: 'SwapInjuries',
  mixins: [workoutSwapMixin],
  components: {
    PopupContent,
    PopupFloatingFooter,
    ButtonPrimary,
    TextArea,
    TextContent
  },
  data: function () {
    return {
      injuriesList: {},
      selectedInjuries: [],
      message: '',
      topDescription: '',
      customInputPlaceholder: '',
      customInputLabel: '',
      footerData: {
        btnLabel: this.$i18n.t('message[""swap.request-button""]') || 'Request Swap',
        active: true
      }
    }
  },
  watch: {
    selectedInjuries: function (options) {
      if (options.length > 0) {
        this.footerData.active = true
      } else {
        this.footerData.active = false
      }
    }
  },
  created () {
    this.loadPageLanguageData()
    this.loadPageData()
  },
  methods: {
    loadPageLanguageData () {
      // title
      this.setSwapTitle(this.$i18n.t('message["swap.suggest-injury"]'))
      // description
      this.topDescription = this.$i18n.t("message['swap.request-description']")
      // custom inputs placeholder
      this.customInputPlaceholder = this.$i18n.t("message['swap.request-input-placeholder']")
      // custom inputs label
      this.customInputLabel = this.$i18n.t("message['swap.request-input-label']")
      // footer button text
      this.footerData.btnLabel = this.$i18n.t("message['swap.request-button-text']")
    },
    setMultiSelectData (data) {
      this.selectedInjuries = data
    },
    loadPageData () {
      const workoutService = new WorkoutService()
      const self = this
      workoutService.setInjuries().then(function () {
        self.injuriesList = workoutService.getInjuries()
      })
    },
    footerSubmit () {
      let selectedInjuryNames = []
      this.injuriesList.forEach(injury => {
        let isSelected = this.selectedInjuries.some(id => {
          return id === injury.id
        })
        if (isSelected) {
          selectedInjuryNames.push(injury.name)
        }
      })
      this.$emit('injurySwapSubmit', selectedInjuryNames, this.message)
    }
  }
}
</script>
