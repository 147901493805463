import UrlPattern from 'url-pattern'

/**
 * returns the vimeo video ID by vimeo video URL
 * if not returns null
 * @param url
 * @returns {*}
 */
export function getVimeoIdFromUrl (url) {
  const pattern = new UrlPattern('(http(s)\\://)player.vimeo.com(\\::port)/video/:videoId(/)(?*)')
  let matchUrl = pattern.match(url)
  if (!matchUrl) {
    const patternNotEmbed = new UrlPattern('(http(s)\\://)vimeo.com(\\::port)/:videoId(/)(?*)')
    matchUrl = patternNotEmbed.match(url)
    return matchUrl ? matchUrl.videoId : null
  } else {
    return matchUrl ? matchUrl.videoId : null
  }
}

/**
 * return custom page name if url is a custom page
 * if not a custom page url returns null
 * @param url
 * @returns {*}
 */
export function getCustomPageNameFromUrl (url) {
  const pattern = new UrlPattern('(http(s)\\://)(:subdomain.):domain.:tld(\\::port)/page/:pageName(?*)')
  const matchUrl = pattern.match(url)
  return matchUrl ? matchUrl.pageName : null
}
