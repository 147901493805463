import WorkoutApi from './api/workout/WorkoutApi'
import store from '../store'
import lodash from 'lodash'
import DashBoardService from './DashboardService'
import { i18n } from '../helpers/localization/i18n'
/**
 * @deprecated
 * use WorkoutProgramsService.js
 */
export default class WorkoutService {
  workouts = new Map()
  data = {
    workouts: [],
    programDetails: [],
    weeks: [],
    weekImages: [],
    exercise: {},
    injuries: [],
    weekBackgroundImages: []
  }

  isWorkoutLogEnabled = false
  isVideoMuted = false

  constructor () {
    if (!WorkoutService.instance) {
      this.setWorkoutPrograms()
      WorkoutService.instance = this
    }
    return WorkoutService.instance
  }

  setImages () {
    const images = store.getters.getImages
    this.data.weekImages = [
      images['workout.week1.tile'],
      images['workout.week2.tile'],
      images['workout.week3.tile'],
      images['workout.week4.tile']
    ]
  }

  setWeekBackgroundImages () {
    const images = store.getters.getImages
    this.data.weekBackgroundImages = [
      images['workout.week1.background'],
      images['workout.week2.background'],
      images['workout.week3.background'],
      images['workout.week4.background']
    ]
  }

  setWorkoutPrograms () {
    return new Promise((resolve, reject) => {
      const workoutApi = new WorkoutApi()
      if (this.data.workouts && this.data.workouts.length) {
        resolve(this.data.workouts)
      } else {
        workoutApi.getWorkoutList().then((data) => {
          this.data.workouts = data.data.workouts
          resolve(this.data.workouts)
        }).catch(err => {
          reject(err)
        })
      }
    })
  }

  sendWorkoutPdf (payload) {
    return new Promise((resolve, reject) => {
      const workoutApi = new WorkoutApi()
      workoutApi.sendWorkoutSummaryPdf(payload).then((data) => {
        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  setWorkoutProgramsList (workouts) {
    this.data.workouts = workouts
  }

  resetWorkout () {
    this.workouts = new Map()
    this.data = {
      workouts: [],
      programDetails: [],
      weeks: [],
      weekImages: [],
      exercise: {},
      injuries: [],
      weekBackgroundImages: []
    }
  }

  setWorkoutProgramDetails (workout, force = false) {
    return new Promise((resolve, reject) => {
      this.setImages()
      let workoutApi = new WorkoutApi()
      if (!force && this.hasWorkoutProgramDetails(workout) && this.hasWorkoutWeek(workout)) {
        return resolve()
      }
      workoutApi.getWorkoutDetails(workout).then((data) => {
        const workoutAPIData = data.data
        this.workouts.set(workout, workoutAPIData)

        this.mapWorkoutProgramData(workout, workoutAPIData)
        return resolve()
      }).catch(err => {
        reject(err)
      })
    })
  }

  mapWorkoutProgramData (workout, workoutAPIData) {
    let dashBoardService = new DashBoardService()
    workout = parseInt(workout)
    const self = this
    let weeks = []
    let days = []
    let workouts = []

    let programDescription = workoutAPIData.description
    let setTypes = workoutAPIData.set_types
    this.isWorkoutLogEnabled = workoutAPIData.workout_log_enabled || dashBoardService.isWorkoutLogEnabled()
    this.isVideoMuted = workoutAPIData.mute_videos || dashBoardService.isExerciseVideoMuted()
    // setting weeks
    workoutAPIData.weeks.forEach((weekVal, index, array) => {
      const weekImageList = self.data.weekImages
      self.setWeekBackgroundImages()
      let backgroundImages = self.data.weekBackgroundImages
      let week = {
        'id': weekVal.id,
        'name': weekVal.name,
        'workoutLogEnabled': this.isWorkoutLogEnabled,
        'completed': weekVal.completed,
        'image': weekImageList[(index % 4)] || '',
        'description': programDescription,
        'days': [],
        'imageBackground': backgroundImages[(index % 4)] || ''
      }
      weeks.push(week)
      // setting days
      weekVal.days.forEach(function (dayVal, index, array) {
        const day = {
          id: dayVal.id,
          name: dayVal.day,
          completed: dayVal.completed,
          set_types: setTypes,
          description: '',
          exercises: [],
          injuries: []
        }
        days.push(day)
        const dayObjIndex = days.findIndex(d => {
          return d.id === dayVal.id
        })
        const dayObj = days[dayObjIndex]
        // setting workouts
        dayVal.workouts.forEach((workoutsObj, wid) => {
          if (workoutsObj.description) {
            dayObj.description = dayObj.description + workoutsObj.description
          } else {
            dayObj.description = ''
          }

          dayObj.injuries = dayObj.injuries.concat(workoutsObj.injuries)
          lodash.uniq(dayObj.injuries)
          workoutsObj.exercises.forEach(function (workoutVal, index, array) {
            let workoutType = 'standard'
            if (dayVal.workouts.length > 1) {
              workoutType = 'standard'
            } else if (self.isCircuitModeEnabled()) {
              workoutType = workoutsObj.type || 'standard'
            }

            const circuitRounds = workoutsObj.circuit_rounds || 1

            dayObj.type = workoutType
            dayObj.circuitRounds = circuitRounds

            const exercise = {
              id: workoutVal.id,
              name: workoutVal.name,
              completed: workoutVal.completed,
              type: workoutType,
              circuitRounds: circuitRounds,
              preferredMuscle: workoutVal.preferred_muscle || '',
              image: ((workoutVal.images.length > 0) ? workoutVal.images[0].url : '') || workoutVal.image,
              images: workoutVal.images || [],
              videos: workoutVal.videos || [],
              setType: workoutVal.set_type,
              canSwap: workoutVal.can_swap,
              setReps: workoutVal.set_reps
                ? workoutVal.set_reps.replace(/ /g, '/')
                : workoutVal.set_reps,
              muscles: workoutVal.muscles
            }
            workouts.push(exercise)
          })
        })
        dayObj.exercises = workouts
        days[dayObjIndex] = dayObj
        workouts = []
      })
      const currentWeekIndex = weeks.findIndex(w => {
        return w.id === weekVal.id
      })

      weeks[currentWeekIndex].days = days
      days = []
    })
    const currentWeekIndex = this.data.weeks.findIndex(week => {
      return week.workout === workout
    })

    if (currentWeekIndex >= 0) {
      this.data.weeks[currentWeekIndex] = { weeks: weeks, workout: workout }
    } else {
      this.data.weeks.push({ weeks: weeks, workout: workout })
    }

    weeks = []

    let programDetails = {
      'id': workout,
      'title': workoutAPIData.title,
      'description': workoutAPIData.description,
      'notes': workoutAPIData.notes || '',
      'workoutLogEnabled': this.isWorkoutLogEnabled,
      'muted': dashBoardService.isExerciseVideoMuted()
    }
    const programDetailsIndex = this.data.programDetails.findIndex(programDetail => {
      return programDetail.id === workout
    })
    if (programDetailsIndex >= 0) {
      this.data.programDetails[programDetailsIndex] = programDetails
    } else {
      this.data.programDetails.push(programDetails)
    }
  }

  getCircuitWorkoutDetails (weekID) {
    return this.data.weeks.find(week => {
      return week.id === weekID
    })
  }

  setWorkoutExerciseDetails (workout, exercise) {
    let dashBoardService = new DashBoardService()
    return new Promise((resolve, reject) => {
      const workoutApi = new WorkoutApi()
      const self = this
      workoutApi.getExerciseDetails(workout, exercise).then(function (data) {
        self.data.exercise = data.data
        const sets = self.data.exercise.sets
        if (sets) {
          // replaces string spaces with /
          self.data.exercise.sets = sets.replace(/ /g, '/')
        }
        self.data.exercise.exercise_swap_enabled = dashBoardService.isExerciseSwapEnabled()
        return resolve()
      }).catch(err => {
        reject(err)
      })
    })
  }

  setInjuries () {
    return new Promise((resolve) => {
      this.data.injuries = []
      resolve()
    })
  }

  submitSwapExercise (pid, genWorkoutId, type) {
    return new Promise((resolve, reject) => {
      const workoutApi = new WorkoutApi()

      workoutApi.swapExercise(pid, genWorkoutId, type).then(function (data) {
        return resolve()
      }).catch(err => {
        reject(err)
      })
    })
  }

  submitSwapInjuryRequestExercise (pid, genWorkoutId, injuries, message) {
    return new Promise((resolve, reject) => {
      const workoutApi = new WorkoutApi()

      workoutApi.swapInjuryRequestExercise(pid, genWorkoutId, injuries, message, 'injury').then(function (data) {
        return resolve()
      }).catch(err => {
        reject(err)
      })
    })
  }

  getWorkouts () {
    return new Promise((resolve, reject) => {
      if (this.data.workouts.length === 0) {
        this.setWorkoutPrograms().then(res => {
          resolve(res)
        })
      } else {
        resolve(this.data.workouts)
      }
    })
  }

  getWorkoutProgramDetails (workoutID) {
    workoutID = parseInt(workoutID)
    return this.data.programDetails.find((program) => {
      return workoutID === program.id
    })
  }

  hasWorkoutProgramDetails (workoutID) {
    workoutID = parseInt(workoutID)
    return this.data.programDetails.some((program) => {
      return workoutID === program.id
    })
  }

  getWorkoutWeeks (workoutID) {
    workoutID = parseInt(workoutID)
    const weeks = this.data.weeks.find(week => {
      return parseInt(week.workout) === parseInt(workoutID)
    })
    return weeks.weeks || []
  }

  hasWorkoutWeek (workoutID) {
    workoutID = parseInt(workoutID)
    return this.data.weeks.some(week => {
      return parseInt(week.workout) === workoutID
    })
  }

  getWorkoutWeek (workoutID, weekID) {
    workoutID = parseInt(workoutID)
    const weeks = this.getWorkoutWeeks(workoutID)
    return weeks.find(week => {
      return week.id === parseInt(weekID)
    })
  }

  getWorkoutWeekDays (workoutID, weekID) {
    workoutID = parseInt(workoutID)
    const currentWeek = this.getWorkoutWeek(workoutID, weekID)
    return currentWeek.days
  }

  getWorkoutWeekDayByID (workoutID, weekID, dayID) {
    workoutID = parseInt(workoutID)
    dayID = parseInt(dayID)
    const days = this.getWorkoutWeekDays(workoutID, weekID)
    return days.find(day => {
      return dayID === day.id
    })
  }

  getExerciseOfDay (dayObj, exerciseID) {
    exerciseID = parseInt(exerciseID)
    return dayObj.exercises.find(exercise => {
      return exerciseID === exercise.id
    })
  }

  getWorkoutWeekDay (workout, week, day) {
    return this.getWorkoutWeekDayByID(workout, week, day)
  }

  getWorkoutWeekDayExercises (week, day) {
    return this.data.weeks[week].days[day].exercises
  }

  getExerciseDetails () {
    return this.data.exercise
  }

  getInjuries () {
    return this.data.injuries
  }

  isCircuitModeEnabled () {
    if (templateConfig.instance_config &&
      'is_circuit_mode_enabled' in templateConfig.instance_config) {
      return templateConfig.instance_config.is_circuit_mode_enabled
    }
    return false
  }

  groupExercises (exercises, mode) {
    if (mode === 'circuit') {
      return this.groupCircuitExercises(exercises)
    }
    const groupedExercises = []
    let preID = 0
    let currentGroup = []
    exercises = Object.values(exercises)
    exercises.forEach((exercise) => {
      if (preID === 0) {
        preID = exercise.setType.id
        currentGroup.push(exercise)
      } else if (
        exercise.setType.id !== preID ||
        !this.isSetAMultipleSet(exercise.setType.name)
      ) {
        groupedExercises.push(currentGroup)
        currentGroup = []
        currentGroup.push(exercise)
        preID = exercise.setType.id
      } else if (exercise.setType.id === preID) {
        currentGroup.push(exercise)
      } else {
        currentGroup.push(exercise)
        preID = exercise.setType.id
      }
    })
    groupedExercises.push(currentGroup)
    return groupedExercises
  }

  groupCircuitExercises (exercises) {
    const groupedExercises = []
    groupedExercises.push(Object.values(exercises))
    return groupedExercises
  }

  isSetAMultipleSet (type) {
    const sets = [i18n.t('message["workouts.set-type-super"]'), i18n.t('message["workouts.set-type-giant"]')]
    return sets.some(eType => {
      return type.includes(eType)
    })
  }
}
