<template>
    <div>
        <div class="pc-popup-period-tracker">
            <popup-title class="pc-popup-period-tracker__header-without-icon"></popup-title>
            <popup-body class="text-center">
                <popup-content>
                    <popup-head class="text-center">
                        <popup-text-heading class="pc-popup-period-tracker__head">
                            {{$t('message[\'period-tracker.exit-confirm.header\']')}}
                        </popup-text-heading>
                    </popup-head>
                    <popup-text-body class="pc-popup-period-tracker__description">
                        {{$t('message[\'period-tracker.exit-confirm.description\']')}}
                    </popup-text-body>
                </popup-content>
                <popup-floating-footer>
                    <button-primary :text="$t('message[\'period-tracker.exit-confirm.exit-setup\']')"
                                    @click="closePopup"/>
                    <div class="mt-3"></div>
                    <button-secondary :text="$t('message[\'period-tracker.exit-confirm.go-back\']')"
                                      @click="hideExitConfirm"/>
                </popup-floating-footer>
            </popup-body>
        </div>
    </div>
</template>

<script>
import PopupFloatingFooter from '../../../../../global/popups/popup-sub-components/PopupFloatingFooter'
import DownTriangleSvg from '../../../fitness-diary/widgets/svgs/DownTriangleSvg'
import UpTriangleSvg from '../../../fitness-diary/widgets/svgs/UpTriangleSvg'
import IconClose from '../../../../../root/icons/IconClose'
import Popup from '../../../../../global/popups/Popup'
import PopupButtonClose from '../../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupTextHeading from '../../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupTextSubHeading from '../../../../../global/popups/popup-sub-components/PopupTextSubHeading'
import PopupTextBody from '../../../../../global/popups/popup-sub-components/PopupTextBody'
import PopupBody from '../../../../../global/popups/popup-sub-components/PopupBody'

import PopupContent from '../../../../../global/popups/popup-sub-components/PopupContent'
import PopupTitle from '../../../../../global/popups/popup-sub-components/PopupTitle'
import PopupHead from '../../../../../global/popups/popup-sub-components/PopupHead'
import IconUpArrowFilled from '../../../../../root/icons/IconUpArrowFilled'
import IconDownArrowFilled from '../../../../../root/icons/IconDownArrowFilled'
import IconCompletedFilled from '../../../../../root/icons/IconCompletedFilled'
import ButtonPrimary from '../../../../../global/buttons/ButtonPrimary'
import ButtonSecondary from '../../../../../global/buttons/ButtonSecondary'

export default {
  name: 'ExitConfirm',
  components: {
    ButtonPrimary,
    PopupFloatingFooter,
    DownTriangleSvg,
    UpTriangleSvg,
    IconClose,
    Popup,
    PopupButtonClose,
    PopupTextHeading,
    PopupTextSubHeading,
    PopupTextBody,
    PopupBody,
    PopupContent,
    PopupTitle,
    PopupHead,
    IconUpArrowFilled,
    IconDownArrowFilled,
    IconCompletedFilled,
    ButtonSecondary
  },
  methods: {
    closePopup () {
      this.$emit('close')
    },
    hideExitConfirm () {
      this.$emit('exit-confirm', false)
    }
  },
  mounted () {
    this.$emit('mounted')
  }
}
</script>

<style scoped>

</style>
