<template>
  <popup :visible="visible" @hide="closePopup()" class="pc-popup-contact-us--success">
    <popup-title class="pc-popup-contact-us--success_title">
      <popup-button-close @click="closePopup"> </popup-button-close>
    </popup-title>
    <popup-body class="pc-popup-contact-us--success_body">
      <popup-content>
        <popup-head>
          <popup-text-heading class="pc-popup-contact-us--success_heading">
            {{ $t('message["popup.contact-us-success.title"]')}}
          </popup-text-heading>
          <popup-text-body>
            <icon-complete-filled size="lg3" class="pc-popup-contact-us--icon_success"/>
          </popup-text-body>
        </popup-head>
      </popup-content>
    </popup-body>
  </popup>
</template>

<script>
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import Popup from '../../../../global/popups/Popup'
import PopupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextBody from '../../../../global/popups/popup-sub-components/PopupTextBody'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import IconCompleteFilled from '../../../../root/icons/IconCompleteFilled'

export default {
  name: 'PopupContactUsSuccess',
  mixins: [popupAnimationMixin],
  props: [
    'visible'
  ],
  components: {
    Popup,
    PopupTitle,
    PopupButtonClose,
    PopupBody,
    PopupContent,
    PopupHead,
    PopupTextHeading,
    PopupTextBody,
    IconCompleteFilled
  },
  methods: {
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    },
    submit () {
      this.$emit('close')
    }
  }
}
</script>

<style></style>
