import axios from 'axios'
import DefineInclude from '../../DefineInclude'

export default class Api {
  constructor () {
    this.url = DefineInclude.apiUrl
    this.token = ''
  }

  getToken () {
    return axios.get(this.url + 'integrations/customer_token')
  }

  getTokenFromRefresh (token) {
    return axios.post(this.url + 'auth/refresh', {
      'refresh_token': token
    }, {})
  }

  signIn (data) {
    return axios.post(this.url + 'auth/sign-in', data)
  }

  mobileSignOut () {
    return axios.get(this.url.replace('api/', '') + 'mobile/logout')
  }

  forgotPasswordRequest (data) {
    return axios.post(this.url + 'auth/forgot-password', data)
  }

  forgotPasswordReset (data) {
    return axios.post(this.url + 'auth/reset-password', data)
  }
}
