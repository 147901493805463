<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
        <path d="M9.71181 5.11567L0.128397 14.2824C-0.0367998 14.4419 -0.0441436 14.7056 0.115389 14.8716C0.274492 15.0376 0.538167 15.0433 0.704575 14.8846L9.9999 5.99376L19.2953 14.8846C19.4617 15.0433 19.7253 15.0376 19.8845 14.8716C19.9618 14.791 20 14.6869 20 14.5835C20 14.4736 19.9569 14.3646 19.8714 14.2824L10.288 5.11567C10.1269 4.96184 9.87295 4.96184 9.71181 5.11567Z" fill="#3D3D3D"/>
        </g>
        <defs>
        <clipPath id="clip0">
        <rect width="20" height="20" fill="white" transform="translate(0 20) rotate(-90)"/>
        </clipPath>
        </defs>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconUpArrow',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-up-arrow': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
