import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    todayLog: null,
    addPopupShown: false,
    paginationCurrentPage: 0,
    currentDays: [],
    currentLogs: [],
    hasMoreLogs: true,
    lastSelectedDay: null,
    lastSelectedMeals: null,
    lastContentPage: 'list-page',
    editableMealLog: null,
    dineOutEnabled: false,
    lastSelectedRestaurantName:'',
  },
  mutations: {
    setDineOutEnabled (state, payload) {
      state.dineOutEnabled = payload
    },
    setLastSelectedRestaurantName (state, payload) {
      state.lastSelectedRestaurantName = payload
    },
    setTodayLog (state, payload) {
      state.todayLog = payload
    },
    setLastPage (state, payload) {
      state.lastContentPage = payload
    },
    setEditableMealLog (state, payload) {
      state.editableMealLog = payload
    },
    setPaginationData (state, payload) {
      state.paginationCurrentPage = payload.paginationCurrentPage
      state.currentDays = payload.currentDays
      state.currentLogs = payload.currentLogs
      state.hasMoreLogs = payload.hasMoreLogs
    },
    updateMealLogsWithUpdatedMealLog (state, payload) {
      state.currentDays = state.currentDays.map((date) => {
        if (date.date === payload.logged_date) {
          date.logged = !!(payload.logged_meals && payload.logged_meals.length > 0)

          date.id = payload._id
        }
        return date
      })

      let hasSameLog = false
      state.currentLogs = state.currentLogs.map((log) => {
        if (log.logged_date === payload.logged_date) {
          log = payload
          hasSameLog = true
        }
        return log
      })
      if (state.lastSelectedDay && state.lastSelectedDay.date === payload.logged_date) {
        state.lastSelectedDay.id = payload._id
      }
      if (!hasSameLog) {
        state.currentLogs.push(payload)
      }
    },
    setAddPopupShown (state) {
      state.addPopupShown = true
    },
    setLastSelectedMeals (state, payload) {
      state.lastSelectedMeals = payload
    },
    setLastSelectedDay (state, payload) {
      state.lastSelectedDay = payload
    },
    setAddPopupClosed (state) {
      state.addPopupShown = false
    },
    reset (state) {
      state.addPopupShown = false
      state.paginationCurrentPage = 0
      state.currentDays = []
      state.currentLogs = []
      state.hasMoreLogs = true
      state.lastSelectedDay = null
    }
  },
  getters: {
    getEditableMealLog (state) {
      return state.editableMealLog
    },
    getTodayLog (state) {
      return state.todayLog
    },
    getAddPopupShown (state) {
      return state.addPopupShown
    },
    getPaginationCurrentPage (state) {
      return state.paginationCurrentPage
    },
    getCurrentDays (state) {
      return state.currentDays
    },
    getCurrentLogs (state) {
      return state.currentLogs
    },
    getLastSelectedMeals (state) {
      return state.lastSelectedMeals
    },
    getHasMoreLogs (state) {
      return state.hasMoreLogs
    },
    getLastPage (state) {
      return state.lastContentPage
    },
    getLastSelectedDate (state) {
      return state.lastSelectedDay
    },
    getDineOutEnabled (state) {
      return state.dineOutEnabled
    },
    getLastSelectedRestaurantName (state) {
      return state.lastSelectedRestaurantName
    }
  }
}
