<template>
  <button-base :borderRadius="'sm1'" :type="'solid'" :class="componentClasses" @click="clicked()">
    <div class="gc-button-dining-out-restaurant__left-section">
      <slot class="gc-button-dining-out-restaurant__left-section-icon"></slot>
      <div class="gc-button-dining-out-restaurant__text-section">
        <text-content :class="textClass" :line-height="'multi'" :size="'sm3'" :weight="'extra-bold'"> {{ text }}</text-content>
        <text-content :class="subTextClass" :line-height="'multi'" :size="'sm1'" v-if="hasSubText"> {{ subText }}</text-content>
      </div>
    </div>
    <div class="gc-button-dining-out-restaurant__right-section">
      <icon-forward></icon-forward>
    </div>
  </button-base>
</template>

<script>

  import TextContent from '../../root/TextContent'
  import ButtonBase from '../../root/ButtonBase'
  import IconForward from '../../root/icons/IconForward'

  export default {
    name: 'ButtonDiningOutRestaurant',
    components: {
      ButtonBase,
      IconForward,
      TextContent
    },
    props: {
      /**
       * The main text of the button
       */
      text: {
        default: ''
      },
      /**
       * The sub text of the button
       */
      subText: {
        default: ''
      },
      /**
       * The disabled state of the button
       */
      disabled: {
        default: false
      }
    },
    computed: {
      hasSubText: function () {
        return this.subText.replace(/\s/g, '') !== ''
      },
      hasIcon () {
        return !!this.$slots.default
      },
      textClass: function () {
        return {
          'gc-button-dining-out-restaurant__text': true,
          'gc-button-dining-out-restaurant__text--with-sub-text': this.hasSubText
        }
      },
      subTextClass: function () {
        return {
          'gc-button-dining-out-restaurant__sub-text': this.hasSubText
        }
      },
      componentClasses: function () {
        return {
          'gc-button-dining-out-restaurant': true,
          'gc-button-dining-out-restaurant--with-sub-text': this.hasSubText,
          'gc-button-dining-out-restaurant--disabled': this.disabled
        }
      }
    },
    methods: {
      /**
       * function to emit the click event
       */
      clicked () {
        if (!this.disabled) {
          this.$emit('click')
        }
      }
    }
  }
</script>

<style scoped></style>
