<template>
  <div class="gc-card-paired-profile">
    <div class="gc-card-paired-profile__avatar">
      <image-base class="gc-card-paired-profile__image" border-radius="circle" :src="image"/>
    </div>
    <div class="gc-card-paired-profile__info">
      <text-body-regular
        class="gc-card-paired-profile__text-name"
        line-height="lh-lg"
        weight="extra-bold"
      >{{name}}</text-body-regular>
      <text-body-small class="gc-card-paired-profile__email" line-height="lh-lg"  >({{email}})</text-body-small>
    </div>
  </div>
</template>

<script>
import ImageBase from '../../root/Image'
import TextBodyRegular from '../typography/TextBodyRegular'
import TextBodySmall from '../typography/TextBodySmall'

export default {
  name: 'CardPairedProfile',
  components: {
    TextBodySmall,
    TextBodyRegular,
    ImageBase
  },
  props: {
    image: {
      default: ''
    },
    name: {
      default: ''
    },
    email: {
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
