<template>
    <popup class="gc-popup-delete-confirmation" :visible="visibility" @hide="hidePopup">
        <popup-title class="gc-popup-delete-confirmation__title">
            <popup-button-close @click="hidePopup"></popup-button-close>
        </popup-title>

        <popup-body>
            <popup-head class="gc-popup-delete-confirmation__heading">
                <popup-text-heading :size="'md2'"  weight="extra-bold" class="text-center">{{title}}</popup-text-heading>
            </popup-head>
            <popup-content class="gc-popup-delete-confirmation__content">
                <popup-text-body class="gc-popup-delete-confirmation__description text-center">{{description}}</popup-text-body>
            </popup-content>

            <popup-footer class="gc-popup-delete-confirmation__footer">
                <button-primary :text="confirmationText" @click="confirmed"></button-primary>
            </popup-footer>
        </popup-body>
    </popup>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import PopupTitle from './popup-sub-components/PopupTitle'
import PopupBody from './popup-sub-components/PopupBody'
import PopupHead from './popup-sub-components/PopupHead'
import PopupContent from './popup-sub-components/PopupContent'
import PopupFooter from './popup-sub-components/PopupFooter'
import ButtonPrimary from '../buttons/ButtonPrimary'
import ButtonSecondary from '../buttons/ButtonSecondary'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupTextBody from './popup-sub-components/PopupTextBody'
import PopupTextHeading from './popup-sub-components/PopupTextHeading'
import Popup from './Popup'
import TextContent from '../../root/TextContent'

export default {
  name: 'PopupConfirmation',
  mixins: [popupAnimationMixin],
  components: {
    Popup,
    PopupTitle,
    PopupHead,
    PopupBody,
    PopupContent,
    PopupFooter,
    PopupButtonClose,
    ButtonPrimary,
    TextContent,
    PopupTextBody,
    PopupTextHeading,
    ButtonSecondary
  },
  computed: {
    ...mapGetters({
      title: 'confirmationPopupStore/title',
      description: 'confirmationPopupStore/description',
      confirmationText: 'confirmationPopupStore/confirmationText',
      visibility: 'confirmationPopupStore/visible'
    })
  },
  methods: {
    ...mapMutations({
      hidePopup: 'confirmationPopupStore/hide'
    }),
    confirmed () {
      this.$emit('confirm')
      this.hidePopup()
    }
  }
}
</script>
