<template>
  <popup
    v-if="visible"
    ref="mealSwapModal"
    :visible="visible"
    @hide="closePopup"
    class="gc-popup-meal-swap"
    :no-close-on-backdrop="noCloseOnBackdrop"
  >
    <popup-title>
      <popup-button-close :style="{'visibility' : noCloseOnBackdrop? 'hidden' :'visible' }" @hide="closePopup()"/>
    </popup-title>
    <popup-body class="gc-popup-meal-swap__body text-center">
      <popup-head class="text-center">
        <popup-text-heading>{{ title }}</popup-text-heading>
        <div v-if="status==='loading'" class="text-center">
          <popup-text-heading>{{ $t('message["general.swapping"]') + '...'}}</popup-text-heading>
          <loading-buffer :height="'75'"></loading-buffer>
        </div>
      </popup-head>
      <popup-content>
        <popup-text-body
          v-if="status==='init'"
          class="text-center info-modal__subtitle mb-2"
        >{{ $t('message["messages.swap-meal-popup"]') }}
        </popup-text-body>
        <icon-complete-filled :size="'lg3'" v-if="status==='success'"/>

        <div v-if="status==='error'">
          <div class="text-center"/>

          <p class="text-center info-modal__subtitle mb-2">{{ errorMessage }}</p>
        </div>
      </popup-content>
      <popup-footer v-if="status==='init'">
        <button-primary @click="swapMeal()" :text="swapConfirmButtonText"></button-primary>
      </popup-footer>
    </popup-body>

  </popup>
</template>

<script>
import LoadingBuffer from '../../layout/template-1/global/widgets/LoadingBuffer'
import MealPlanService from '../../../services/MealPlanService'
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import mealSwapMixin from '../../../mixins/component-mixins/global/mealSwapMixin'
import DefineInclude from '../../../DefineInclude'
import Popup from './Popup.vue'
import PopupBody from './popup-sub-components/PopupBody'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupContent from './popup-sub-components/PopupContent'
import PopupFloatingFooter from './popup-sub-components/PopupFloatingFooter'
import PopupFooter from './popup-sub-components/PopupFooter'
import PopupTextBody from './popup-sub-components/PopupTextBody'
import PopupHead from './popup-sub-components/PopupHead'
import PopupTextHeading from './popup-sub-components/PopupTextHeading'
import PopupTextSubHeading from './popup-sub-components/PopupTextSubHeading'
import PopupTitle from './popup-sub-components/PopupTitle'
import ButtonPrimary from '../buttons/ButtonPrimary'
import ButtonSecondary from '../buttons/ButtonSecondary'
import BaseComponent from '../../layout/template-1/global/base/BaseComponent'
import IconCompleteFilled from '../../root/icons/IconCompleteFilled'
import ProfileService from '../../../services/ProfileService'

export default {
  name: 'SwapMeal',
  components: {
    LoadingBuffer,
    Popup,
    PopupBody,
    PopupButtonClose,
    PopupContent,
    PopupFloatingFooter,
    PopupFooter,
    PopupTextBody,
    PopupHead,
    PopupTextHeading,
    PopupTextSubHeading,
    PopupTitle,
    ButtonSecondary,
    ButtonPrimary,
    IconCompleteFilled
  },
  mixins: [popupAnimationMixin, mealSwapMixin],
  extends: BaseComponent,
  props: {
    meal: {
      required: true
    },
    data: {
      default: function () {
        return {}
      },
      type: Object
    },
    visible: {
      default: false,
      type: Boolean
    }
  },
  data: function () {
    return {
      title: this.$i18n.t('message["swap.meal-popup-title"]'),
      status: 'loading',
      loading: true,
      confirmButtonText: this.$i18n.t('message["swap.meal-popup-button-text"]'),
      swapConfirmButtonText: this.$i18n.t('message["swap.confirm-button-text"]'),
      successMsg: 'success',
      errorMessage: 'Error',
      ingredientsToSwap: []
    }
  },
  created () {
    this.status = 'init'
    const service = new MealPlanService()
    service.getMealPlan(this.$route.params.mid).then(response => {
      this.confirmButtonText = response.content['request-meal-substitute']
      this.successMsg = response.content['meal-substitute-requested']
    })
  },
  computed: {
    noCloseOnBackdrop: function () {
      return this.status === 'success' || this.status === 'loading'
    }
  },
  methods: {
    async swapMeal () {
      this.status = 'loading'
      this.title = ''
      await this.setIngredients()
    },
    async setIngredients () {
      const promises = []
      this.meal.getRecipes().forEach(recipe => {
        promises.push(this.getRepeatIngredientSwap(recipe, this.$route.params.mid))
      })
      let results = await Promise.all(promises)

      results.forEach((repeatIngredientSwap) => {
        this.ingredientsToSwap = this.ingredientsToSwap.concat(repeatIngredientSwap)
      })

      let profileService = new ProfileService()
      await profileService.setProfileData()
      let ingredients = this.ingredientsToSwap
      let excludedIngredients = profileService.getExcludedIngrediants()
      let ingredientsArray = ingredients.map(x => x.id)
      let commonIngredients = ingredientsArray.filter(e => excludedIngredients.indexOf(e) !== -1)
      if (commonIngredients.length > 0) {
        for (let i = 0; i < commonIngredients.length;) {
          const idxObj = ingredients.findIndex(object => {
            return object.id === commonIngredients[i]
          })
          ingredients.splice(idxObj, 1)
          i++
        }
      }
      this.sendSwapRequest(ingredients)
    },
    sendSwapRequest (repeatIngredientSwap) {
      this.meal.swap()
        .then(() => {
          this.status = 'success'
          this.title = this.$i18n.t('message["meal-plan.swapped"]')
          this.updateMealSwapStatus([{
            mid: this.data.mealID,
            rmid: this.data.refMealID
          }])
          const recipeseArray = []
          this.meal.getRecipes().forEach(recipe => {
            recipeseArray.push(recipe.getName())
          })
          this.$emit('meal-swap-success')
          setTimeout(() => {
            this.logEvent('MealPlan.SwappedMeal', {
              day: this.data.mealTitle,
              recipes: recipeseArray.join(',')
            })
            this.closePopup(null, () => {
              if (repeatIngredientSwap.length > 0) {
                this.showUpdateDislikesPopup(repeatIngredientSwap, this.data.mealID)
              } else {
                if (this.$route.query.meal) {
                  this.$router.push({
                    path:
                      [DefineInclude.appUrlList.mealPlanBase, this.$route.params.mid].join('/'),
                    query: {
                      day: this.$route.query.day
                    }
                  })
                }
              }
            })
          }, 2000)
        }).catch(() => {
          this.status = 'error'
          if (!this.$i18n) {
            return
          }
          this.title = this.$i18n.t('message[\'swap.meal-requested-title-error\']')
          this.errorMessage = this.$i18n.t('message[\'swap.meal-requested-error\']')
        })
    },
    closePopup (e, callback) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('hide-swap-popup')
        if (callback) {
          callback()
        }
      })
    }

  }
}
</script>
