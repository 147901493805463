<template>
  <div :class="componentClasses">
    <icon-trophy :size="'sm4'" v-if="isPersonalBest" class="gc-card-workout-history__personal-best"></icon-trophy>
    <icon-more class="gc-card-workout-history__dots" :size="'sm2'" @click="clickedMore()"></icon-more>
    <div class="gc-card-workout-history__header">
      <text-body-regular :weight="'extra-bold'" :lineHeight="'multi'">{{formatDateTime(loggedAt)}}
      </text-body-regular>
    </div>
    <div class="gc-card-workout-history__content-wrapper" >
      <div class="gc-card-workout-history__sets" v-show="!isEditing">
        <div v-for="(entry,eID) in entries.slice(0,exerciseSetCount)" :key="eID" >
          <text-body-small2 v-if="!isEditing" :weight="'extra-bold'" :lineHeight="'multi'">
            <div class="gc-card-workout-history__content-text" :class="{
              'gc-card-workout-history__content-text--with-four': metricsCount===4,
              'gc-card-workout-history__content-text': true,
            }" v-html="setLogText(entry)"></div>
          </text-body-small2>

        </div>
      </div>
      <div class="gc-card-workout-history__extra-sets" v-if="entries.slice(exerciseSetCount).length" v-show="!isEditing">
        <div v-for="(entry,eID) in entries.slice(exerciseSetCount)" :key="eID" >
          <text-body-small2 v-if="!isEditing" :weight="'extra-bold'" :lineHeight="'multi'">
            <div class="gc-card-workout-history__content-text" :class="{
            'gc-card-workout-history__content-text--with-four': metricsCount===4,
            'gc-card-workout-history__content-text': true,
          }" v-html="setLogText(entry)"></div>
          </text-body-small2>

        </div>
      </div>

      <form-note-workout-log
        :exercise-i-d="exerciseID"
        :day-name="dayName"
        :week-name="weekName"
        :note="note" :save-type="'update'"
        :workout-i-d="workoutID"
        :exercise-name="exerciseName"
        v-if="showNoteForm"
        v-on:cancel="cancelEdit()"
        v-on:completed="completeEdit($event)"
      />
      <form-edit-workout-log
        :current-unit-system="unitSystemValue"
        :entries="entries"
        :day-name="dayName"
        :week-name="weekName"
        :exercise-name="exerciseName"
        :exercise-i-d="exerciseID"
        :exercise-rest-interval="0"
        :exercise-rest-time="0"
        :exercise-time="0"
        :is-completed="true"
        :force-edit="true"
        :metrics="metrics"
        :pre-note="note"
        :sets="sets"
        :workout-i-d="workoutID"
        v-if="showEntryForm"
        v-on:cancel="cancelEdit()"
        v-on:canceled="cancelEdit()"
        v-on:submitted="completeEdit($event)"
      />

    </div>
    <div class="gc-card-workout-history__footer" v-if="!isEditing">
      <text-body-extra-small :weight="'medium'" :lineHeight="'multi'">
        {{(editNote||note||$t('message[\'workout-log.exercise-completed-text\']'))}}
      </text-body-extra-small>
    </div>
    <popup-workout-log-modify :visible="editPopup.visible"
                              @edit="editClicked()"
                              @delete="deleteClicked()"
                              @close="hideModifyPopup()"/>
  </div>
</template>
<script>
import TextContent from '../../root/TextContent'
import IconTrophy from '../../root/icons/IconTrophy'
import IconMore from '../../root/icons/IconMore'
import workoutLogSingleLogMixin from '../../../mixins/component-mixins/page/workout/workoutLogSingleLogMixin'
import TextBodyRegular from '../typography/TextBodyRegular'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import PopupWorkoutLogModify from '../../layout/template-1/workout/page-components/popups/PopupWorkoutLogModify'
import FormEditWorkoutLog from '../../layout/template-1/workout/page-components/FormEditWorkoutLog'
import FormNoteWorkoutLog from '../../layout/template-1/workout/page-components/FormNoteWorkoutLog'
import TextBodySmall2 from '../typography/TextBodySmall2'

export default {
  name: 'CardWorkoutHistory',
  mixins: [workoutLogSingleLogMixin],
  components: {
    TextBodySmall2,
    FormNoteWorkoutLog,
    FormEditWorkoutLog,
    PopupWorkoutLogModify,
    TextBodyExtraSmall,
    TextBodyRegular,
    TextContent,
    IconTrophy,
    IconMore
  },
  computed: {
    componentClasses () {
      return {
        'gc-card-workout-history': true
      }
    }
  },
  methods: {
    clickedMore () {
      this.editPopup.visible = true
    },
    hideModifyPopup () {
      this.editPopup.visible = false
    },
    setLogText (entry) {
      const values = entry.attributes.map((data, index) => {
        let val = data.value
        if (typeof data.is_personal_best !== 'undefined' &&
            data.is_personal_best === true) {
          this.isPersonalBest = true
        }
        let unitSystemType = this.getUnit(data.type)
        if (data.type === 'duration') {
          val = parseInt(val)
          const min = parseInt(val / 60)
          const sec = parseInt(val % 60)
          if (min > 0 && sec > 0) {
            val = this.setTimerNumbers(min) + ':' + this.setTimerNumbers(sec)
            unitSystemType = this.$i18n.t('message["general.time-minutes-short"]')
          } else if (min > 0) {
            val = this.setTimerNumbers(min)
            unitSystemType = this.$i18n.t('message["general.time-minutes-short"]')
          } else {
            val = this.setTimerNumbers(sec)
            unitSystemType = this.$i18n.t('message["general.time-seconds-short"]')
          }
        }
        const mainTag = document.querySelector('html')
        let isRtl = mainTag.getAttribute('dir')

        let pclass = 'gc-card-workout-history__content-text-value'
        if (entry.attributes.length === 1) {
          pclass += ' text-center'
        } else if (index === 0 && isRtl === '') {
          pclass += ' text-right'
        } else if (entry.attributes.length - 1 === index && isRtl === '') {
          pclass += ' text-left'
        }

        if (entry.attributes.length === 1) {
          pclass += ' text-center'
        } else if (index === 0 && isRtl === 'rtl') {
          pclass += ' text-left'
        } else if (entry.attributes.length - 1 === index && isRtl === 'rtl') {
          pclass += ' text-right'
        }

        return `<span    class="${pclass}">${
          val + ' ' + unitSystemType
        }</span>`
      })
      return values.join('<span class="gc-card-workout-history__content-text-x"  >x</span>')
    }
  }
}
</script>
