<template>
  <!--   page background -->
  <page-secondary :header="headerOptions" class="pc-profile--fitness">
    <!--   page wrapper -->
    <page-container class="pc-profile--fitness__container">

      <text-body-small :line-height="'multi'" class="pc-profile--fitness__info-text">
        {{ $t('message["profile.account-settings.fitness-help"]') }}<a class="pc-profile--fitness__info-mail"
                                                                       :href="'mailto:' + supportEmail">{{ supportEmail }}</a>
      </text-body-small>
      <grid-row align-h="center" align-content="center">
        <grid-col md="6">
          <card-text v-if="injuryNames.length > 0">

            <card-text-header>
              {{
                $t(
                  'message["profile.account-settings.fitness.description-header"]',
                  [supportEmail]
                )
              }}
            </card-text-header>
            <card-text-list>
              <card-text-list-item
                :text="name"
                v-for="name of injuryNames"
                :key="'injury-' + name"
              />
            </card-text-list>
          </card-text>
        </grid-col>
      </grid-row>

    </page-container>

  </page-secondary>

</template>

<script>
import BaseComponent from '../../global/base/BaseComponent'
import lodash from 'lodash'
import InjuryService from '../../../../../services/InjuryService'
import ProfileService from '../../../../../services/ProfileService'
import PageContainer from '../../../../global/pages/page-sub-components/PageContainer'
import PageSecondary from '../../../../global/pages/PageSecondary'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import CardText from '../../../../global/cards/CardText'
import CardTextList from '../../../../global/cards/card-text-sub-components/CardTextList'
import CardTextHeader from '../../../../global/cards/card-text-sub-components/CardTextHeader'
import CardTextListItem from '../../../../global/cards/card-text-sub-components/CardTextListItem'
import GridRow from '../../../../global/grid/GridRow'
import GridCol from '../../../../global/grid/GridCol'
import TextBodySmall from '../../../template-2/global/typography/TextBodySmall'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'PageFitness',
  extends: BaseComponent,
  components: {
    TextBodySmall,
    GridCol,
    GridRow,
    PageContainer,
    PageSecondary,
    TextBodyExtraSmall,
    CardText,
    CardTextList,
    CardTextListItem,
    CardTextHeader
  },
  data: function () {
    return {
      imagesUrl: this.$appConfig.imagesUrl,
      headerOptions: {
        isTransparent: false,
        isFixed: false,
        show: true,
        left: 'previous',
        right: '',
        rightBlink: false,
        modifiers: ['small-title', 'with-bg-color'],
        mainTitle: this.$t('message["profile.fitness-preferences"]'),
        subTitle: ''
      },
      injuryNames: [],
      allInjuries: {},
      injuries: [],
      supportEmail: ''
    }
  },
  created () {
    this.setPageTitle(this.$t('message["profile.fitness-preferences"]'))
  },
  mounted () {
    this.setHeader(this.headerOptions)
    this.setPageClass()
    this.setPageData()
  },
  methods: {
    async setPageData () {
      const profileService = new ProfileService()
      await profileService.setProfileData().then((res) => {
        this.injuries = profileService.getInjuries()
        if (
          typeof res.data.settings_manager.trainer.support_email !==
          'undefined'
        ) {
          this.supportEmail = res.data.settings_manager.trainer.support_email
        }
      })
      this.setList()
      pageReadyEvent()
    },
    async setList () {
      const injuryNames = []

      const injuryService = new InjuryService()
      await injuryService
        .setInjuries()
        .then((res) => {
          this.injuries.forEach(function (value, index, array) {
            // only add two
            const injury = injuryService.getInjury(value)
            injuryNames.push(injury)
          })

          this.injuryNames = injuryNames
          this.injuryNames = lodash.uniq(this.injuryNames)
        })
        .finally(() => {
          this.hideLoading()
        })
    },
    navigate (section) {
      const query = {
        page: 'account-settings-form',
        section: section
      }
      this.$router.push({
        path: this.$route.path,
        query: query
      })
    },
    navigatePrevious () {
      this.$router.push({
        path: this.$route.path,
        query: {loading: '0'}
      })

    }
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
  }
}
</script>
