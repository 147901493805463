<template>
  <div
    v-if="show"
    class="gc-container-chat"
  >
    <link
      type="text/css"
      rel="stylesheet"
      :href="assertUrl+'index3.css'"
    >
    <link
      type="text/css"
      rel="stylesheet"
      :href="assertUrl+'index2.css'"
    >
    <link
      type="text/css"
      rel="stylesheet"
      :href="assertUrl+'index.css'"
    >
    <script
      type="module"
      crossorigin
      :src="assertUrl+'index.js'"
    />

    <chat-app />
    <popup-chat-not-supported
      v-if="errorPopup"
      @close="hideErrorPopup"
    />
  </div>
</template>

<script>
import DashBoardService from '../../../services/DashboardService'
import PopupChatNotSupported from '../popups/PopupChatNotSupported'
export default {
  name: 'ContainerChat',
  components: {
    PopupChatNotSupported
  },
  data: function () {
    return {
      assertUrl: this.$appConfig.chatAssetURL,
      errorPopup: false,
      baseURL: this.$appConfig.resourceBaseURL,
      show: false
    }
  },
  beforeMount () {
    let dashBoardService = new DashBoardService()
    dashBoardService.getUserData().then(() => {
      this.show = dashBoardService.hasChatEnabled()
      if (this.show) {
        this.$emit('enabled')
      }
    })
    window.addEventListener('web-socket-connection-error', this.showErrorPopup)
    window.addEventListener('ma-chat-mounted', this.mounted)
    window.addEventListener('ma-chat-new-messages', this.messagedUpdated)
  },
  beforeDestroy () {
    window.removeEventListener('web-socket-connection-error', this.showErrorPopup)
    window.removeEventListener('ma-chat-mounted', this.mounted)
    window.removeEventListener('ma-chat-new-messages', this.messagedUpdated)
    let bodyElement = document.body
    if (bodyElement.classList.contains('with-chat')) {
      bodyElement.classList.remove('with-chat')
    }
  },
  methods: {
    showErrorPopup () {
      this.errorPopup = true
    },
    hideErrorPopup () {
      this.errorPopup = false
    },
    mounted () {
      this.$emit('mounted')
      document.body.classList.add('with-chat')
    },
    messagedUpdated () {
      this.$emit('changeNewMessages')
    }
  }
}
</script>
