<template>
  <svg
    width="65"
    height="6"
    viewBox="0 0 65 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M65 3L60 0.113249V5.88675L65 3ZM0 3.5H60.5V2.5H0V3.5Z"
      fill="black"
      id="long-arrow"
    />
  </svg>
</template>
<script>
export default {
  name: 'right-arrow-svg'
}
</script>
