<template>
  <popup
    :visible="visible"
    @hide="closePopup"
    :modal-class="computedPopupClasses"
    v-if="visible">
    <popup-title>
      <popup-button-close @hide="closePopup"></popup-button-close>
    </popup-title>
    <popup-body class="text-center">
      <popup-head>
        <popup-text-heading>{{headerText}}</popup-text-heading>
      </popup-head>
      <popup-content>
        <popup-text-body class="gc-popup-text-share__description">
          {{descriptionText}}
        </popup-text-body>

        <text-area class="gc-popup-text-share__input-text text-center" :readonly="true" v-model="textLang"/>
        <text-body-extra-small
          v-if="error"
          class="gc-popup-text-share__text-error"
          line-height="lh-lg"
          weight="extra-bold">{{$t('message["popup.copy-and-share.error"]')}}
        </text-body-extra-small>
        <text-body-extra-small
          v-else-if="successful"
          class="gc-popup-text-share__text-success"
          line-height="lh-lg"
          weight="extra-bold">{{$t('message["popup.copy-and-share.success"]')}}
        </text-body-extra-small>
      </popup-content>
      <popup-footer>
        <button-primary :text="buttonText"
                        @click="copyTextToClipboard ()"></button-primary>

      </popup-footer>

    </popup-body>
  </popup>
</template>

<script>
import Popup from '../../popups/Popup'
import PopupTitle from '../../popups/popup-sub-components/PopupTitle'
import PopupButtonClose from '../../popups/popup-sub-components/PopupButtonClose'
import PopupBody from '../../popups/popup-sub-components/PopupBody'
import PopupContent from '../../popups/popup-sub-components/PopupContent'
import PopupFooter from '../../popups/popup-sub-components/PopupFooter'
import ButtonPrimary from '../../buttons/ButtonPrimary'
import PopupTextHeading from '../../popups/popup-sub-components/PopupTextHeading'
import PopupTextBody from '../../popups/popup-sub-components/PopupTextBody'
import PopupHead from '../../popups/popup-sub-components/PopupHead'
import TextBodyExtraSmall from '../../typography/TextBodyExtraSmall'
import popupAnimationMixin from '../../../../mixins/popupAnimationMixin'
import TextArea from '../../inputs/TextArea'

export default {
  name: 'PopupTextShare',
  mixins: [popupAnimationMixin],
  props: {
    header: {
      default: ''
    },
    description: {
      default: ''
    },
    button: {
      default: ''
    },
    text: {
      required: false
    },
    url: {
      required: false
    },
    visible: {
      required: true
    },
    popupClasses: {
      default: []
    }
  },
  components: {
    TextBodyExtraSmall,
    PopupHead,
    PopupTextBody,
    PopupTextHeading,
    ButtonPrimary,
    PopupFooter,
    PopupContent,
    PopupBody,
    PopupButtonClose,
    PopupTitle,
    Popup,
    TextArea
  },
  data: function () {
    return {
      textLang: '',
      error: '',
      successful: false
    }
  },
  mounted () {
    this.textLang = this.url || this.text
  },
  computed: {
    headerText: function () {
      return this.header || this.$i18n.t('message["popup.copy-and-share.header"]')
    },
    descriptionText: function () {
      return this.description || this.$i18n.t('message["popup.copy-and-share.description"]')
    },
    buttonText: function () {
      return this.button || this.$i18n.t('message["popup.copy-and-share.copy"]')
    },
    computedPopupClasses: function () {
      return 'gc-popup gc-popup-text-share ' + this.popupClasses
    }
  },
  methods: {
    fallbackCopyTextToClipboard (text) {
      let ele = document.querySelector('.gc-popup-text-share__input-text textarea')
      ele.focus()
      ele.select()
      try {
        this.successful = document.execCommand('copy')
      } catch (err) {
        this.error = this.$i18n.t('message["popup.copy-and-share.error"]')
      }
    },
    copyTextToClipboard () {
      let text = this.url || this.text
      this.error = null
      this.successful = false

      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(() => {
        this.successful = true
      }, () => {
        this.fallbackCopyTextToClipboard(text)
      })
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
        this.closeAnimatedPopup(() => {
          this.$emit('close')
        })
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>

<style scoped>

</style>
