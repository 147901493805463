<template>
  <div :class="componentClasses" v-if="banners.length>0">
    <app-banner v-for="(banner ,index) in banners"
                :activePages="banner.active_pages"
                :bannerIndex="index"
                :image="banner.image" :link="banner.url"
                :key="'banner-'+index"
    />
  </div>
</template>
<script>
import AppBanner from './AppBanner'
import { mapActions, mapGetters } from 'vuex'
import BannerImage from '../../../../global/banners/BannerImage'
import { checkIsMember } from '../../../../../helpers/user'

export default {
  name: 'app-banner-list',
  components: { AppBanner, BannerImage },
  mounted () {
  },
  watch: {
    $route (to, from) {
      if (!checkIsMember()) {
        return
      }
      if (typeof to.meta.requiresAuth !== 'undefined' && to.meta.requiresAuth) {
        this.setBanner()
      }
    }
  },
  computed: {
    componentClasses: function () {
      let classes = ['app-banner-wrapper']
      if (this.isDesktop) {
        classes.push('app-banner-wrapper--desktop')
      }
      return classes
    },
    ...mapGetters({
      banners: 'bannerStore/getBannersToShow'
    })
  },
  methods: {
    ...mapActions({
      setBanner: 'bannerStore/loadBanners'
    })
  }
}
</script>
