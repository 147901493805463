import UpsellAPI from './api/upsell/UpsellAPI'
import ProfileService from './ProfileService'
import RegionService from './RegionService'

export default class UpsellService {
  constructor () {
    if (!UpsellService.instance) {
      UpsellService.instance = this
    }
    return UpsellService.instance
  }

  init () {
    return new Promise((resolve, reject) => {
      const upsellAPI = new UpsellAPI()
      upsellAPI.setToken()
      upsellAPI.paymentProfile().then((profile) => {
        resolve(profile)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  purchase (product) {
    return new Promise((resolve, reject) => {
      const upsellAPI = new UpsellAPI()
      upsellAPI.makePurchase(product)
        .then((response) => {
          resolve(response)
        }).catch((err) => {
          reject(err)
        })
    })
  }

  getRedirectParams () {
    if (this.redirectParams) {
      return new Promise((resolve, reject) => {
        resolve(this.redirectParams)
      })
    } else {
      this.redirectParams = new Promise((resolve, reject) => {
        const profileService = new ProfileService()
        const regionService = new RegionService()
        const redirectParams = {
          email: null,
          name: null,
          street: null,
          suburb: null,
          city: null,
          postcode: null,
          region: null,
          country: null
        }
        profileService.setProfileData()
          .then(res => {
            redirectParams.name = res.data.customer.first_name + (res.data.customer.last_name.length > 0 ? ' ' + res.data.customer.last_name : '')
            redirectParams.email = res.data.customer.email
            redirectParams.street = res.data.address.line_1
            redirectParams.suburb = res.data.address.suburb
            redirectParams.city = res.data.address.city
            redirectParams.postcode = res.data.address.postcode

            regionService.setRegions()
              .then(regions => {
                const tmpRegion = regions.data.regions[res.data.address.country.id - 1]
                const countryRegions = regionService.getRegionsByCountryId(res.data.address.country.id)
                redirectParams.country = tmpRegion.region[tmpRegion.region.length - 1].name
                redirectParams.region = countryRegions[res.data.address.region.id]
                let tmp = ''
                for (const key in redirectParams) {
                  if (redirectParams[key] && redirectParams[key] !== '') {
                    tmp += key + '=' + redirectParams[key] + '&'
                  }
                }
                this.redirectParams = encodeURI(tmp.slice(0, -1))
                resolve(this.redirectParams)
              }).catch((err) => {
                reject(err)
              })
          }).catch((err) => {
            reject(err)
          })
      })
      return this.redirectParams
    }
  }
}
