<template>
  <div class="gc-input-password">
    <text-input :type="inputType" :placeholder="$t('message[\'sign-up.form.password\']')" v-model="password" @input="passwordStrengthCheck" @change="changed"/>
    <div class="gc-input-password__icons" @click="toggleShowPassword">
      <icon-eye-open v-if="showPassword" :size="'sm4'"/>
      <icon-eye-close v-else :size="'sm4'"/>
    </div>
  </div>
</template>

<script>
import IconEyeClose from '../../root/icons/IconEyeClose'
import IconEyeOpen from '../../root/icons/IconEyeOpen'
import TextInput from './TextInput'

export default {
  name: 'InputPassword',
  props: {
    value: {
      default: ''
    }
  },
  components: {
    TextInput,
    IconEyeOpen,
    IconEyeClose
  },
  watch: {
    value: {
      immediate: true,
      handler: function () {
        this.password = this.value
      }
    }
  },
  data: function () {
    return {
      showPassword: false,
      inputType: 'password',
      password: ''
    }
  },
  mounted () {
    if (this.password) {
      this.passwordStrengthCheck()
    }
  },
  methods: {
    changed(){
      this.$emit('change', this.password)
    },
    toggleShowPassword () {
      this.showPassword = !this.showPassword
      if (this.inputType === 'text') {
        this.inputType = 'password'
      } else {
        this.inputType = 'text'
      }
    },
    passwordStrengthCheck () {
      let passWordStrengthCheckers = {
        hasSixCharacters: /.{6,}/.test(this.password),

      }

      if (passWordStrengthCheckers.hasSixCharacters) {
        this.$emit('strong')
      } else if (!this.hasSixCharacters) {
        this.$emit('weak')
      }
      this.$emit('input', this.password)
    }
  }
}
</script>
