<template>
  <div class="gc-header-steps">
    <div
      v-for="(step, index) in steps"
      :key="index"
      :class="[
      stepClasses,
        {
          'gc-header-step--completed': step.status === 'completed',
          'gc-header-step--active': step.active,
        }
      ]"
    >
      <div class="gc-header-step__circle">
        <icon-tick
          v-if="step.status === 'completed'"
          class="gc-header-step__icon"
          :size="'sm1'"
        />
        <div v-if="step.status !== 'completed'" class="gc-header-step__count">
          {{ step.number }}
        </div>
      </div>
      <text-body-extra-small
        class="gc-header-step__description"
        :line-height="'multi'"
      >
        {{ step.text }}
      </text-body-extra-small>
    </div>
  </div>
</template>

<script>
import IconTick from '../../root/icons/IconTick'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'

export default {
  name: 'HeaderSteps',
  components: {
    IconTick,
    TextBodyExtraSmall
  },
  props: {
    steps: {
      type: Array
    },
    route: {
      type: String,
      default: ''
    }
  },
  computed: {
    // step states to show tick icon: 'default' | 'completed'
    // steps route to see which route is active: route prop
    stepClasses: function () {
      return {
        'gc-header-step': true
        // 'gc-header-step--active': this.route === '/sign-up'
      }
    }
  }
}
</script>

<style></style>
