<template>
  <div class="gc-card-subscription-cover-image" :style="componentStyles">
    <div class="gc-card-subscription-cover-image__logo-wrapper">
      <img :src="logo" alt="" class="gc-card-subscription-cover-image__logo" />
    </div>
    <text-heading-3
      class="gc-card-subscription-cover-image__title"
      :line-height="'multi'"
      :weight="'extra-bold'"
    >
      {{ title }}
    </text-heading-3>
  </div>
</template>

<script>
import TextHeading3 from '../../typography/TextHeading3'

export default {
  components: { TextHeading3 },
  name: 'CardSubscriptionCoverImage',
  props: {
    title: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    logo: {
      type: String,
      default: ''
    }
  },
  computed: {
    componentStyles: function () {
      return {
        'background-image':
          'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%),' +
          `url("${this.image}")`
      }
    }
  }
}
</script>
