<template>
    <page :header="header" class="gc-page-gender">
       <validation-observer ref="form" v-slot="{handleSubmit, invalid}">
          <page-container>
              <div class="gc-page-gender__header">
                  <text-heading-4
                      class="gc-page-gender__title text-center"
                      weight="extra-bold"
                      lineHeight="multi">{{title.main}}</text-heading-4>
              </div>
              <div class="gc-page-gender__content">
                  <form id="form-input-gender"
                      class="gc-page-gender__form"
                      @submit.prevent="handleSubmit(nextComponent)">
                          <validation-provider v-slot="{ errors }" rules="required" vid="genderInput" :name="'gender'">
                             <button-choice
                                type="button"
                                :class="['gc-page-gender__input','gc-page-gender__input-'+option.value]"
                                v-for="option in options"
                                :key="'gender-'+option.value"
                                :text="option.name"
                                v-model="gender"
                                :selected="(gender === option.value)"
                                @click="setGender(option.value)"></button-choice>
                                <text-body-extra-small class="gc-page-progress-form__input-error text-center"  lineHeight="multi">{{errors[0]}}</text-body-extra-small>
                          </validation-provider>
                  </form>
              </div>
          </page-container>
          <page-footer :form="'form-input-gender'" :validated="invalid"></page-footer>
        </validation-observer>
    </page>
</template>

<script>
import FormBaseComponent from '../../layout/template-1/global/widgets/forms/FormBaseComponent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ButtonPrimary from '../buttons/ButtonPrimary'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import TextHeading4 from '../typography/TextHeading4'
import PageContainer from './page-sub-components/PageContainer'
import Page from './PageForm'
import ButtonChoice from '../buttons/ButtonChoice'
import TextContent from '../../root/TextContent'
import PageFooter from './page-sub-components/PageFormFooter'

export default {
  name: 'PageGender',
  extends: FormBaseComponent,
  components: {
    Page,
    PageContainer,
    TextHeading4,
    ButtonPrimary,
    TextBodyExtraSmall,
    ValidationObserver,
    ValidationProvider,
    ButtonChoice,
    TextContent,
    PageFooter
  },
  data: function () {
    return {
      header: {
        isTransparent: false,
        isFixed: false,
        show: false,
        left: '',
        right: '',
        rightBlink: false,
        modifiers: [],
        mainTitle: '',
        subTitle: ''
      },
      gender: ''
    }
  },
  mounted () {
    this.gender = this.inputValue
    this.hideLoading()
  },
  methods: {
    setGender (selectedGender) {
      this.gender = selectedGender
    },
    nextComponent () {
      const inputObj = { key: this.inputKey, value: this.gender }
      this.$emit('submit', inputObj)
    }
    // checkGenderSupport (gender) {
    //   return this.options.some(option => {
    //     return option.value === gender && option.support
    //   })
    // }
  }
}
</script>
