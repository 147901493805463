import { render, staticRenderFns } from "./CardEmpty.vue?vue&type=template&id=221716ed&scoped=true"
import script from "./CardEmpty.vue?vue&type=script&lang=js"
export * from "./CardEmpty.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "221716ed",
  null
  
)

export default component.exports