import Vue from 'vue'
import Vuex from 'vuex'
import FitnessDiaryService from '../../services/FitnessDiaryService'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    service: new FitnessDiaryService(),
    banners: [],
    toBeDeleted: [],
    lastVisitedCard: ''
  },
  mutations: {
    updateToBeDeleted (state, arr) {
      state.toBeDeleted = arr
    },
    setLastVisitedCard (state, payload) {
      state.lastVisitedCard = payload.lastVistedCard
    }
  },
  actions: {
  },
  getters: {
    getService (state) {
      return state.service
    },
    getToBeDeleted (state) {
      return state.toBeDeleted
    },
    getlastVisitedCard (state) {
      return state.LastVisitedCard
    }
  }
}
