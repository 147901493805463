<template>
    <page-secondary :header="headerOptions" class="pc-profile--my-connections">
        <page-container class="pc-profile--my-connections__container">
            <div v-if="!paired">
                <card-share-pair-code
                        :pairCode="pairCode"
                        class="pc-profile--my-connections__share-card"
                ></card-share-pair-code>
                <hr class="pc-profile--my-connections__hr"/>
                <enter-pair-code @failed="loadPairedStatus" @connected="loadPairedStatus"></enter-pair-code>
            </div>
            <div v-else>
                <card-paired-member @refresh="loadPairedStatus"></card-paired-member>
            </div>
        </page-container>
    </page-secondary>
</template>

<script>
import PageSecondary from '../../../../global/pages/PageSecondary'
import BaseComponent from '../../global/base/BaseComponent'
import PageContainer from '../../../../global/pages/page-sub-components/PageContainer.vue'
import ConnectionService from '../../../../../services/meal-plan-pair/ConnectionService'
import CardPairedMember from './page-components/CardPairedMember'
import CardSharePairCode from './page-components/CardSharePairCode'
import EnterPairCode from './page-components/EnterPairCode'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'PageMyConnections',
  extends: BaseComponent,
  components: {
    PageSecondary,
    PageContainer,
    CardPairedMember,
    CardSharePairCode,
    EnterPairCode
  },
  data () {
    return {
      cs: new ConnectionService(),
      headerOptions: {
        isTransparent: false,
        isFixed: false,
        show: true,
        left: 'previous',
        right: '',
        rightBlink: false,
        modifiers: ['small-title', 'with-bg-color'],
        mainTitle: this.$i18n.t(
          'message["profile.my-connections"]'
        ),
        subTitle: ''
      },
      paired: false,
      pairCode: ''
    }
  },
  created () {
    this.setPageTitle(this.$t('message["profile.my-connections"]'))
  },
  mounted () {
    this.loadPairedStatus()
  },
  methods: {
    async loadPairedStatus () {
      this.showLoading()

      // load member connect details with force create (will create connection instance if not exists)
      await this.cs.init({
        forceLoad: true,
        forceCreate: true
      })
        .catch(() => {
          this.hideLoading()
        })

      // getting connection code
      this.pairCode = this.cs.getConnectionCode()

      // getting whether meal plan is paired and partner is connected
      this.paired = this.cs.isPartnerConnected()

      this.hideLoading()
      pageReadyEvent()
    }
  }
}
</script>

<style scoped>

</style>
