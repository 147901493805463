<template>
    <div class="gc-card-photo-journey-history">
        <div class="gc-card-photo-journey-history__head">
            <!--image-uploaded date & image visibility status-->
            <div class="gc-card-photo-journey-history__head-left">
              <text-body-small class="gc-card-photo-journey-history__head-date" weight="extra-bold" :lineHeight="'multi'">{{date}}</text-body-small>
              <icon-users v-if="isPublic" class="gc-card-photo-journey-history__head-icon-users" size="sm2"></icon-users>
            </div>
            <!--delete option-->
            <icon-garbage-bin size="sm2" @click="initiateDelete(galleryData)" class="gc-card-photo-journey-history__head-icon-delete"></icon-garbage-bin>
        </div>
        <div class="gc-card-photo-journey-history__gallery">
              <image-photo-journey-history
                                  class="gc-card-photo-journey-history__front-image"
                                  :image="galleryData.images.frontImage.image"
                                  :type="galleryData.images.frontImage.type"
                                  v-if="galleryData.images.frontImage"/>
              <image-photo-journey-history
                                  class="gc-card-photo-journey-history__side-image"
                                  :image="galleryData.images.sideImage.image"
                                  :type="galleryData.images.sideImage.type"
                                  v-if="galleryData.images.sideImage"/>

              <image-photo-journey-history
                                  class="gc-card-photo-journey-history__back-image"
                                  :image="galleryData.images.backImage.image"
                                  :type="galleryData.images.backImage.type"
                                  v-if="galleryData.images.backImage"/>
        </div>
    </div>
</template>

<script>

import ImagePhotoJourneyHistory from './ImagePhotoJourneyHistory'
import fitnessDiaryPhotoJourneyHistoryMixin
  from '../../../../../mixins/component-mixins/page/fitness-diary/fitnessDiaryPhotoJourneyHistoryMixin'
import IconGarbageBin from '../../../../root/icons/IconGarbageBin'
import TextBodySmall from '../../../../global/typography/TextBodySmall'
import IconUsers from '../../../../root/icons/IconUsers'
import { mapMutations } from 'vuex'
import IconCamera from '../../../../root/icons/IconCamera'

export default {
  name: 'CardPhotoJourneyHistory',
  mixins: [fitnessDiaryPhotoJourneyHistoryMixin],
  components: {
    IconCamera,
    ImagePhotoJourneyHistory,
    IconGarbageBin,
    TextBodySmall,
    IconUsers
  },
  props: {
    /**
     * photos uploaded date
     */
    date: {
      type: String,
      default: ''
    },
    /**
     * whether photos are shared in Public or not
     * @values false, true
     */
    isPublic: {
      type: Boolean,
      default: false
    },
    /**
     * an object with details of images(side/front/back) that are uploaded within a day
     */
    galleryData: {
      type: Object
    }
  },
  methods: {
    ...mapMutations({
      updateToBeDeleted: 'fitnessDiaryStore/updateToBeDeleted'
    }),
    /**
     * override the initiateDelete() method
     */
    initiateDelete (data) {
      Object.keys(data.images).forEach((imageKey) => {
        this.toBeDeleted.push(data.images[imageKey].id)
        // update the toBeDeleted photos array which is in vuex store
        this.updateToBeDeleted(this.toBeDeleted)
      })

      let hasPublicImage = Object.values(data.images).some(image => {
        return !image.private
      })
      this.setDeletePopupContent(data.date, hasPublicImage)
      this.showConfirmationPopup()
    }
  }
}
</script>
