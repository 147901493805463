<template>
  <popup-body >
    <popup-head>
      <popup-text-heading class="text-center">{{ title }}</popup-text-heading>
    </popup-head>
    <popup-content class="pc-popup-exercise-swap-container-contact-support text-center">
      <popup-text-body
        v-if="state === 'init'"
        class="text-center">
        {{ message }}
      </popup-text-body>
      <loading-buffer
        v-if="state === 'submitting'"
        height="50"/>
      <text-body-small
        v-if="state === 'success'"
        :size="'sm2'"
        :weight="'medium'"
        :line-height="'multi'"
        class="pc-popup-exercise-swap-container-contact-support__text-success">{{ $t('message["swap.exercise-pending-message"]') }}</text-body-small>
    </popup-content>
    <popup-footer>
      <button-primary
        v-if="state=== 'init'"
        :text="footerData.btnLabel"
        :disabled="!footerData.active"
        @click="footerSubmit"/>
    </popup-footer>

  </popup-body>
</template>

<script>
import PopupContent from '../../../../../../global/popups/popup-sub-components/PopupContent'
import ButtonPrimary from '../../../../../../global/buttons/ButtonPrimary'
import TextContent from '../../../../../../root/TextContent'
import PopupHead from '../../../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupBody from '../../../../../../global/popups/popup-sub-components/PopupBody'
import PopupTextBody from '../../../../../../global/popups/popup-sub-components/PopupTextBody'
import WorkoutProgramExerciseService from '../../../../../../../services/WorkoutProgramExerciseService'
import IconCompletedFilled from '../../../../../../root/icons/IconCompletedFilled'
import LoadingBuffer from '../../../../global/widgets/LoadingBuffer'
import PopupFooter from '../../../../../../global/popups/popup-sub-components/PopupFooter'
import TextBodySmall from '../../../../../../global/typography/TextBodySmall'

export default {
  name: 'PopupSuggestedExerciseSwapContainerContactSupport',
  components: {
    TextBodySmall,
    PopupFooter,
    LoadingBuffer,
    IconCompletedFilled,
    PopupTextBody,
    PopupBody,
    PopupTextHeading,
    PopupHead,
    ButtonPrimary,
    PopupContent,
    TextContent
  },
  props: {
    exercise: {
      required: true,
      validator: function (value) {
        return value instanceof WorkoutProgramExerciseService
      }
    }
  },
  data: function () {
    return {
      title: '',
      message: '',
      customInputPlaceholder: '',
      customInputLabel: '',
      state: 'init',
      footerData: {
        btnLabel: this.$i18n.t('message[""swap.request-button""]') || 'Request Swap',
        active: true
      }
    }
  },
  watch: {
    state: function () {
      this.$emit('state', this.state)
    }
  },
  created () {
    this.loadPageLanguageData()
  },
  mounted () {
    this.$emit('mounted')
  },
  methods: {
    loadPageLanguageData () {
      // title
      this.title = this.$i18n.t('message["swap.swap-exercises"]')
      // description
      this.message = this.$i18n.t('message[\'swap.static-exercise-swap-message\']')
      // footer button text
      this.footerData.btnLabel = this.$i18n.t('message[\'swap.static-exercise-swap-submit\']')
    },
    footerSubmit () {
      this.state = 'submitting'
      this.title = this.$i18n.t('message["exercise-swap.requesting"]')

      this.hasError = false
      this.logEvent('SwappedExercise', {

        reason: 'static-exercise',
        exerciseID: this.exercise.getID(),
        exerciseName: this.exercise.getName()
      }, false)
      this.exercise.submitSwapExercise(null).then(() => {
        this.title = this.$i18n.t('message["swap.request-success-title"]')
        this.message = this.$i18n.t('message["swap.exercise-pending-message"]')
        this.state = 'success'
        this.$emit('swap-success')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.title = this.$i18n.t('message["swap.request-success-title"]')
          this.message = this.$i18n.t('message["swap.exercise-pending-message"]')
          this.$emit('swap-success')
          this.state = 'success'
        } else {
          this.message = this.$i18n.t('message["swap.exercise-pending-message"]')
          this.hasError = true
          this.$emit('swap-error')
          this.state = 'error'
        }
      }).finally(() => {
        this.footerData.active = true
      })
    }
  }
}
</script>
