<template>
    <div :class="componentClasses" v-on="$listeners" v-bind="$attrs">
      <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'NavigationBarTab',
  props: {
    active: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-navigation-bar__tab': true,
        'gc-navigation-bar__tab--active': this.active,
        'gc-navigation-bar__tab--disabled': this.disabled

      }
    }
  }
}
</script>

<style scoped>

</style>
