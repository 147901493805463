import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    mealSwapIngredients: {},
    mealSwapStatus: {}
  },
  mutations: {
    init (state) {
      const mealSwapIngredients = window.localStorage.getItem('meal_swap_ingredients')
      if (mealSwapIngredients && mealSwapIngredients.trim().length !== 0) {
        state.mealSwapIngredients = JSON.parse(mealSwapIngredients)
      }
    },
    updateMealSwapIngredients (state, payload) {
      state.mealSwapIngredients = payload
      window.localStorage.setItem('meal_swap_ingredients', JSON.stringify(state.mealSwapIngredients))
    },
    updateMealSwapStatus (state, ids) {
      for (let i = 0; i < ids.length; i++) {
        state.mealSwapStatus[ids[i].mid + '' + ids[i].rmid] = true
      }
    }
  },
  getters: {
    getMealSwapIngredients (state) {
      return state.mealSwapIngredients
    },
    getMealSwapStatus (state) {
      return state.mealSwapStatus
    }
  }
}
