<template>

  <div class="gc-input-body-fat">
    <carousel-body-fat
      ref="carousel"
      class="gc-input-body-fat__carousel text-center"
      :slickData="slick.data"
      @handleAfterChange="handleAfterChange"
      @handleBeforeChange="handleBeforeChange"
      @handleBreakpoint="handleBreakpoint"
      @handleDestroy="handleDestroy"
      @handleEdge="handleEdge"
      @handleInit="handleInit"
      @handleReInit="handleReInit"
      @handleSetPosition="handleSetPosition"
      @handleSwipe="handleSwipe"
      @handleLazyLoaded="handleLazyLoaded"
      @handleLazeLoadError="handleLazeLoadError"
    />
      <input-number-spinner
        :is-valid="!haveError"
        :value="newValue"
        :step="1"
        :min-value="this.validation.min_value"
        :max-value="this.validation.max_value"
        :is-percentage="true"
        :placeholder="25"
        ref="bodyFatInput"
        v-model="newValue"
        @change="setActiveSlideByVal()"
        :name="inputKey" />
      <text-body-small
        v-if="errorMessage.length > 0"
        :lineHeight="'multi'"
        class="gc-input-body-fat__input-error text-center">
        {{errorMessage}}
      </text-body-small>
  </div>
</template>
<script>
import TextInput from './TextInput'
import InputNumberSpinner from './InputNumberSpinner'
import CarouselBodyFat from '../carousels/CarouselBodyFat'
import { flagValue } from '../../../../../includes/TemplateSettings'
import TextBodySmall from '../typography/TextBodySmall'

export default {
  name: 'InputBodyFat',
  components: {
    TextBodySmall,
    CarouselBodyFat,
    TextInput,
    InputNumberSpinner
  },
  props: {
    gender: {
      default: 'm'
    },
    options: {
      required: true
    },
    errorMessage: {
      type: String,
      default: ''
    },
    inputKey: {
      type: String,
      default: 'body_fat'
    },
    value: {
      default: 20
    }
  },
  mounted () {
    this.newValue = Math.round(this.value).toString()
    this.changeIconColor()
    this.setActiveSlideByVal()
    this.hideLoading()
    this.checkError()
  },
  beforeMount () {
    this.slick.data = this.options
    this.validation.min_value = parseInt(this.options[0].min)
    this.validation.max_value = 50
  },
  data: function () {
    return {
      bodyFatEstimator: {
        show: false,
        suggestedBodyFat: ''
      },
      validation: {
        required: true,
        min_value: 0,
        max_value: 50
      },
      imageUrl: this.$appConfig.imagesUrl,
      oldChildValue: '',
      newValue: 10,
      slick: {
        slickOptions: {
          centerMode: true,
          centerPadding: '60px',
          slidesToShow: 1,
          autoplay: false,
          dots: false,
          prevArrow: false,
          nextArrow: false,
          variableWidth: true
        },
        activeSlide: {
          index: 0,
          min: 0,
          max: 6
        },
        data: []
      },
      pageClass: ['gc-input-body-fat'],
      haveError: false
    }
  },
  watch: {
    newValue: function (newVal, oldVal) {
      newVal = Math.round(newVal)
      if (newVal > 50) {
        newVal = 50
        this.newValue = newVal
      } else if (newVal < 0) {
        newVal = 0
        this.newValue = newVal
      }

      this.$emit('input', this.newValue)
    },
    value: {
      immediate: true,
      handler: function () {
        this.newValue = this.value
      }
    },
    gender: {
      immediate: true,
      handler: function () {
        if (this.gender === 'f') {
          this.validation.min_value = 10
        } else {
          this.validation.min_value = 3
        }
      }
    },
    errorMessage: function () {
      this.checkError()
    }
  },
  methods: {
    checkError () {
      this.haveError = this.errorMessage.length > 0
    },
    setActiveSlideByVal (currentSlide = -1) {
      this.newValue = Math.round(this.newValue) || 0
      let selectedIndex = 0
      // get slider index matching range
      let index
      for (index = 0; index < this.slick.data.length; index++) {
        const min = this.slick.data[index].min
        const max = this.slick.data[index].max
        if (
          this.newValue >= parseInt(min) &&
          this.newValue <= parseInt(max)
        ) {
          selectedIndex = index
        }
      }
      if (currentSlide !== selectedIndex) {
        this.slickGoTo(selectedIndex)
      }
    },
    addBodyFat () {
      if (Math.round(this.newValue) >= 50) {
        return
      }
      this.newValue = this.newValue || 0
      this.newValue = Math.round(this.newValue) + 1
      this.newValue = Math.round(this.newValue)
      this.setActiveSlideByVal()
    },
    reduceBodyFat () {
      if (Math.round(this.newValue) <= this.validation.min_value) {
        return
      }
      this.newValue = Math.round(this.newValue) - 1
      this.newValue = Math.round(this.newValue)
      this.setActiveSlideByVal()
    },
    setValBySlide (e) {
      const min = typeof (e) === 'object' ? e.childNodes[0].childNodes[0].dataset.min : this.slick.data[e].min
      const max = typeof (e) === 'object' ? e.childNodes[0].childNodes[0].dataset.max : this.slick.data[e].max
      let value = Math.round((parseInt(min) + parseInt(max)) / 2)
      if (value > 50) {
        value = 50
      }
      if (
        !(this.newValue >= parseInt(min) && this.newValue <= parseInt(max))
      ) {
        this.newValue = Math.round(value)
      }
    },
    changeIconColor () {
    },
    next () {
      this.$refs.carousel.next()
    },
    slickGoTo (slideIndex) {
      this.$nextTick(() => {
        this.$refs.carousel.goTo(slideIndex)
      })
    },
    prev () {
      this.$refs.carousel.prev()
    },
    reSlick () {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.carousel.reSlick()
      })
    },
    // Events listeners
    handleAfterChange (event, slick, currentSlide) {
      this.setActiveSlideByVal(currentSlide)
    },
    handleBeforeChange (event, slick, currentSlide, nextSlide) {
      this.setValBySlide(nextSlide)
    },
    handleBreakpoint (event, slick, breakpoint) {
      // console.log('handleBreakpoint', event, slick, breakpoint)
    },
    handleDestroy (event, slick) {
      // console.log('handleDestroy', event, slick)
    },
    handleEdge (event, slick, direction) {
      // console.log('handleEdge', event, slick, direction)
    },
    handleInit (event, slick) {
      // console.log('handleInit', event, slick)
      this.$emit('init', true)
    },
    handleReInit (event, slick) {
      // console.log('handleReInit', event, slick)
    },
    handleSetPosition (event, slick) {
      // console.log('handleSetPosition', event, slick)
    },
    handleSwipe (event, slick, direction) {
      const currentSlide = slick.currentSlide
      this.slick.activeSlide = currentSlide
      const e = slick.$slides.eq(currentSlide).get(0)
      // console.log()
      // console.log('handleAfterChange', event, slick, currentSlide)
      // console.log(event)
      this.setValBySlide(e)
    },
    handleLazyLoaded (event, slick, image, imageSource) {
      // console.log('handleLazyLoaded', event, slick, image, imageSource)
    },
    handleLazeLoadError (event, slick, image, imageSource) {
      // console.log('handleLazeLoadError', event, slick, image, imageSource)
    },
    closeBodyFatEstimator () {
      this.bodyFatEstimator.show = false
    },
    showBodyFatEstimator () {
      this.bodyFatEstimator.show = true
    },
    enableBodyFatEstimator () {
      return flagValue('show_body_fat_estimator', true)
    },
    setBodyFat (value) {
      this.newValue = value
      this.setActiveSlideByVal()
    }
  },
  beforeDestroy () {
    if (!this.$refs.carousel && !this.$refs.carousel.$el) {
      return
    }
    this.$refs.carousel.$el.style.visibility = 'hidden'
  }
}
</script>
