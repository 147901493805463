<template>
  <div>
    <div v-bind:style="styles"></div>
  </div>
</template>

<script>

export default {
  name: 'LoadingBufferCustom',
  props: {
    size: {
      default: 24
    }
  },
  data: function () {
    return {
      styles: {
        height: '400px',
        'background-repeat': 'no-repeat',
        'background-image': 'url(' + this.$appConfig.imagesUrl + 'loader.svg)',
        'background-size': '70px 70px',
        'background-position': 'center'
      },
      imagesUrl: this.$appConfig.imagesUrl
    }
  },
  mounted () {
    this.styles.height = this.size + 'px'
    this.styles.width = this.size + 'px'
  }

}
</script>
