<template>
  <page :header="header" class="gc-page-birthday">
    <validation-observer ref="form" v-slot="{ handleSubmit, invalid}">
      <page-container>
        <div class="gc-page-birthday__header">
          <text-heading-4
            class="gc-page-birthday__title text-center"
            weight="extra-bold"
            lineHeight="multi">{{title.main}}</text-heading-4>
        </div>
        <div class="gc-page-birthday__content">
          <form id="form-input-birthday"
                class="gc-page-birthday__form"
                @submit.prevent="handleSubmit(nextComponent)">
            <validation-provider v-slot="{ errors, invalid, validated }" rules="date|birthday|required" :vid="'Birthday'" :name="title.main">
              <div  class="gc-page-birthday__form-input-date form-group ma-form" v-if="isiOS">
                <text-input
                  type="date"
                  :vid="'birth-day'"
                  :name="title.main"
                  placeholder="MM/DD/YYY"
                  v-model="birthdate"
                  :errorMessage='errors[0]'
                  :hide-error-message="true"
                />
              </div>
              <div class="gc-page-birthday__form-input-date form-group ma-form " v-if="!isiOS">
                <input-custom-date v-model="birthdate"  :error="(validated&&invalid)"/>
              </div>
              <text-body-extra-small class="gc-page-progress-form__input-error text-center" lineHeight="multi">{{errors[0]}}</text-body-extra-small>
            </validation-provider>
          </form>
        </div>
      </page-container>
      <page-footer :form="'form-input-birthday'" :validated="invalid"></page-footer>
    </validation-observer>
  </page>
</template>

<script>
import FormBaseComponent from '../../layout/template-1/global/widgets/forms/FormBaseComponent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ButtonPrimary from '../buttons/ButtonPrimary'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import TextHeading4 from '../typography/TextHeading4'
import PageContainer from './page-sub-components/PageContainer'
import Page from './PageForm'
import { isIOS } from 'mobile-device-detect'
import PageFooter from './page-sub-components/PageFormFooter'
import InputCustomDate from '../inputs/InputCustomDate'
import TextInput from '../inputs/TextInput'

export default {
  name: 'PageBirthday',
  extends: FormBaseComponent,
  components: {
    TextInput,
    Page,
    PageContainer,
    TextHeading4,
    ButtonPrimary,
    TextBodyExtraSmall,
    ValidationObserver,
    ValidationProvider,
    PageFooter,
    InputCustomDate
  },
  data: function () {
    return {
      header: {
        isTransparent: false,
        isFixed: false,
        show: false,
        left: '',
        right: '',
        rightBlink: false,
        modifiers: [],
        mainTitle: '',
        subTitle: ''
      },
      isiOS: true,
      birthdate: ''
    }
  },
  mounted () {
    this.birthdate = this.inputValue
    this.isiOS = isIOS
    if (typeof this.errors[this.inputKey] !== 'undefined') {
      this.$refs.form.setErrors({ Birthday: this.errors.dob_year })
    }
    this.hideLoading()
  },
  methods: {
    nextComponent () {
      this.$emit('submit', { key: this.inputKey, value: this.birthdate })
    }
  }
}
</script>
