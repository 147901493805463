<template>
  <div>
    <page-secondary :header="headerOptions">
      <page-container>
        <form-component
          :config="formConfig"
          :options="formOptions"
          :errors="formErrors"
          @submit="handleSubmit"
          @change="handleValueChange"
          @navigatePrevious="navigatePrevious"
        ></form-component>
        <data-required-popup
          :visible="dataRequiredPopupShow"
          :page-name="getPageName()"
          v-on:close="dataRequiredPopupShow = false"
          v-on:submit="showErrorForm()"
        ></data-required-popup>
      </page-container>
    </page-secondary>
  </div>
</template>

<script>
import FormComponent from '../../../global/forms/FormDynemic'
import DataRequiredPopup from '../../../global/popups/PopupDataRequired'
import formConfig, {ConfigService} from './formConfig'
import ProfileService from '../../../../services/ProfileService'
import APIErrorHandler from '../../../../services/api-handler/APIErrorHandler'
import router from '../../../../router/templateOne'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import PageSecondary from '../../../global/pages/PageSecondary'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'
import headerEventBus from '@/event-buses/headerEventBus'

export default {
  name: 'ProfileUpdateHandler',
  components: {
    FormComponent,
    DataRequiredPopup,
    PageContainer,
    PageSecondary
  },
  data: function () {
    return {
      formList: {
        'full-name': 'fullName',
        email: 'email',
        birthday: 'birthday',
        address: 'address',
        phone: 'phone',
        'time-zone': 'timeZone'
      },
      formConfig: {},
      formErrors: {},
      nextErrorForm: '',
      formOptions: {
        timeZoneList: [],
        countryList: [],
        regionList: []
      },
      dataRequiredPopupShow: false,
      lastChangedCountry: '',
      headerOptions: {
        isTransparent: false,
        isFixed: false,
        show: true,
        left: 'previous-emit',
        right: '',
        rightBlink: false,
        modifiers: ['small-title'],
        mainTitle: '',
        subTitle: ''
      }
    }
  },
  mounted () {
    this.setComponent(this.$route.query.section)
    this.headerOptions.mainTitle = this.getPageName()
    headerEventBus.$on('back-button-click', this.navigatePrevious)
  },
  beforeDestroy () {
    headerEventBus.$off('back-button-click', this.navigatePrevious)
  },
  methods: {
    getPageName () {
      return this.$i18n.t('message["' + this.formConfig.title + '"]')
    },
    // set forms elements
    async setComponent (type) {
      // redirect if data not initialized
      const profileService = new ProfileService()
      if (!profileService.checkInitialized()) {
        return this.navigatePrevious(type)
      }

      // set existing forms errors from service
      this.formErrors = profileService.getFormErrors()

      const activeFormConfig = formConfig[type]
      this.formConfig = {
        formName: type,
        topHTML:
          typeof activeFormConfig.topHTML !== 'undefined'
            ? activeFormConfig.topHTML({email: profileService.supportEmail})
            : '',
        title: activeFormConfig.title,
        pageContainerClass: activeFormConfig.pageContainerClass,
        description: activeFormConfig.description,
        inputs: activeFormConfig.inputs,
        submitType: activeFormConfig.submitType,
        submitText: activeFormConfig.submitText,
        defaultData: {}
      }

      await activeFormConfig.getDefaultData().then((res) => {
        this.formConfig.defaultData = res.defaultData
        this.formOptions = res.formOptions
      })
      pageReadyEvent()
      this.hideLoading()
    },
    // handle forms submit
    handleSubmit (data) {
      const formName = data.formName
      const payload = data.formData
      const submitType = this.formConfig.submitType

      if (submitType !== 'onChange') {
        this.showLoading()
      }

      this.submitForm(formName, payload)
        .then((res) => {
          if (submitType !== 'onChange') {
            this.navigatePrevious(formName)
          } else {
            this.hideLoading()
          }
        })
        .catch((error) => {
          this.hideLoading()
          if (error.response && error.response.status === 422) {
            this.handleUnprocessableEntity(error)
          }
        })
    },
    handleUnprocessableEntity (error) {
      // assigning validation errors
      const formErrors = error.response.data.errors
      this.formErrors = formErrors

      const profileService = new ProfileService()

      // set form errors to instance
      profileService.setFormErrors(formErrors)

      const errorKeyList = Object.keys(formErrors)
      let formToBeDisplayed = null
      const formList = Object.keys(formConfig)

      // check for 422 error belonging form in form config
      formList.forEach(function (FormValue) {
        const inputs = formConfig[FormValue].inputs
        inputs.forEach(function (inputValue) {
          if (!formToBeDisplayed && errorKeyList.includes(inputValue.model)) {
            formToBeDisplayed = FormValue
          }
        })
      })

      if (formToBeDisplayed) {
        this.nextErrorForm = formToBeDisplayed
        this.dataRequiredPopupShow = true
      } else {
        // display error
        APIErrorHandler.handleInternalServerError()
      }
    },
    showErrorForm () {
      this.$router.push({
        path: router.path,
        query: {
          page: 'account-settings-form',
          section: this.nextErrorForm
        }
      })
    },
    submitForm (formName, payload) {
      const activeFormConfig = formConfig[formName]
      return activeFormConfig.submitForm(payload)
    },
    // handle any event related to inputs changes
    async handleValueChange (data) {
      const formName = data.formName
      const payload = data.formData

      switch (formName) {
        case this.formList.address:

          // change regions according to related country
          if (payload.country && this.lastChangedCountry !== payload.country) {
            this.showLoading()
            this.lastChangedCountry = payload.country

            const configService = new ConfigService()
            configService.getRegionList(payload.country).then((res) => {
              this.formConfig.defaultData.region.value = ''
              this.formOptions.regionList = res
              this.hideLoading()
            })
          } else {
            this.hideLoading()
          }
          break
      }
    },
    navigatePrevious () {
      this.showLoading()
      let self = this
      setTimeout(function () {
        self.$router.push({
          path: self.$appConfig.appUrlList.profile,
          query: {
            page: 'account-settings'
          }
        })
      }, 100)

    }
  }
}
</script>
