<template>
  <div class="gc-card-meal__wrapper">
    <div :class="componentClasses" v-on="$listeners">
      <div v-if="imagesSrc.length > 0" class="gc-card-meal__image-wrapper row">
        <img
          v-for="(item, index) in imagesSrc"
          :src="item"
          class="gc-card-meal__image col"
          :key="'gc-card-meal__image'+index"
          alt=""
        />
      </div>
      <div :class="componentOverlayClasses"></div>
      <div class="gc-card-meal__head">
          <div class="gc-card-meal__badge-wrapper">
          <badge-choice
            class="gc-card-meal__type"
            v-if="cardType && isNewMeal === false">
            <text-body-regular :weight="'extra-bold'" :lineHeight="'multi'">{{cardType}}</text-body-regular>
          </badge-choice>
          <badge-choice
            v-if="cardType && isNewMeal"
            class="gc-card-meal__type gc-card-meal__type__new-meal"
            >
            <text-body-regular :weight="'extra-bold'" :lineHeight="'multi'">{{$t('message["food-diary.new-meal"]')}}</text-body-regular>
          </badge-choice>
          <badge-choice
            v-if="isPaired"
            class="gc-card-meal__type gc-card-meal__type__paired-meal"
            >
            <text-body-regular :weight="'extra-bold'" :lineHeight="'multi'">{{$tc('message["meal-plan-pair.paired-meals"]',1)}}</text-body-regular>
          </badge-choice>
        </div>
        <text-body-regular class="gc-card-meal__title" :weight="'extra-bold'" :lineHeight="'multi'">{{cardTitle}}</text-body-regular>
      </div>

      <div class="gc-card-meal__bottom">
         <nutrition-details-bar
          :calories="calories"
          :macroNutrients="macroNutrientsToShow"
          >
          </nutrition-details-bar>
      </div>
    </div>

    <div class="gc-meal-card__action-area"  v-if="!!$slots.default">
      <slot></slot>
    </div>

  </div>

</template>
<script>
import BadgeChoice from '../badges/BadgeChoice'
import MealActionBar from '../bars/BarMealAction.vue'
import NutritionDetailsBar from '../bars/BarNutritionDetails.vue'
import TextBodyRegular from '../typography/TextBodyRegular'

export default {
  name: 'CardMeal',
  components: {
    TextBodyRegular,
    BadgeChoice,
    MealActionBar,
    NutritionDetailsBar
  },
  props:
      {
        type: {
          default: ''
        },
        title: {
          default: ''
        },
        isNewMeal: {
          default: false
        },
        isPaired: {
          default: false
        },
        imagesSrc: {
          default: []
        },
        calories: {
          default: function () {
            return []
          }
        },
        macroNutrients: {
          default: function () {
            return []
          }
        }
      },
  computed: {
    macroNutrientsToShow: function () {
      if (Array.isArray(this.macroNutrients)) {
        return this.macroNutrients.filter(macro => {
          return !!macro.qut
        })
      }
      return this.macroNutrients
    },
    componentClasses: function () {
      return {
        'gc-card-meal': true,
        'gc-card-meal__without-calories': !this.calories
      }
    },
    componentOverlayClasses: function () {
      return {
        'gc-card-meal__overlay': true,
        'gc-card-meal__overlay-no-image': this.imagesSrc.length === 0
      }
    },
    cardTitle () {
      return this.title.length >= 50 ? this.title.substring(0, 47) + '...' : this.title
    },
    cardType () {
      return this.type.length >= 15 ? this.type.substring(0, 12) + '...' : this.type
    }
  }
}
</script>
<style>
</style>
