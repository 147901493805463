<template>
  <page-secondary :header="headerOptions">

    <page-container class="page-exercise-list-with-cards">
      <!--   page wrapper -->
        <div class="page-exercise-list-with-cards__alert-warning-wrapper">
          <alert-warning
            v-for="injury in injuries" v-if="injuries.length>0"
            :key="'injury-'+injury.name"
            :description="injury.description"
            :name="injury.name"
            class="page-exercise-list-with-cards__alert-warning"
          />
        </div>

      <grid-row class="page-exercise-list-with-cards__card-list-wrapper">
        <grid-col
          v-for="(exerciseGroup,groupID)  in exercises"
          :md="(exerciseGroup.getExercisesCount()===1) ? 6 : 12"
          :key="'card-list-group-'+groupID"
          :class="exerciseGroup.getExercisesCount()>1 ?
                  'page-exercise-list-with-card__exercise-type-wrapper-with-separator' :
                  'page-exercise-list-with-card__exercise-type-wrapper'">
          <text-body-regular
            class="page-exercise-list-with-cards__card-list-title"
             v-if="exerciseGroup.getExercisesCount()>1&&circuit.isCircuit"
            :weight="'extra-bold'"
            :lineHeight="'multi'"
          >{{$t('message["workout.circuit-workout"]')+" - "+circuit.rounds+" "+$tc('message["workout.rounds"]',circuit.rounds)}}</text-body-regular>
          <text-body-regular
            class="page-exercise-list-with-cards__card-list-title"
            :weight="'extra-bold'"
            :lineHeight="'multi'"
            v-else-if="exerciseGroup.getExercisesCount()>1">
            {{exerciseGroup.getName()}}
          </text-body-regular>
          <grid-row >
            <grid-col cols="12" :md="(exerciseGroup.getExercisesCount()===1) ? 12 : 6" v-for="(exercise,groupExerciseID)  in exerciseGroup.exercises"
                         :key="'card-list-group-exercises-'+groupExerciseID">
            <card-exercise-overview
              @click="navigateNext(exercise.getID(),(exerciseGroup.getExercisesCount()>1),exercise.setType.id)"
              :exercise="exercise"
              :is-circuit="circuit.isCircuit"
              :isGrouped="exerciseGroup.getExercisesCount()>1&&!circuit.isCircuit"
              :index="groupExerciseID+1"/>
              </grid-col>
          </grid-row>
        </grid-col>
      </grid-row>
    </page-container>
  </page-secondary>
</template>

<script>
import BaseComponent from '../global/base/BaseComponent'
import Instructions from '../../../global/popups/PopupInstructions'
import ExerciseListCard from './widgets/ExerciseListCard'
import { mapGetters } from 'vuex'
import AlertWarning from '../../../global/alerts/AlertWarning'
import WorkoutProgramsService from '../../../../services/WorkoutProgramsService'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import CardExerciseOverview from '../../../global/cards/CardExerciseOverview'
import TextBodyExtraSmall from '../../../global/typography/TextBodyExtraSmall'
import TextBodyRegular from '../../../global/typography/TextBodyRegular'
import PageSecondary from '../../../global/pages/PageSecondary'
import GridRow from '../../../global/grid/GridRow'
import GridCol from '../../../global/grid/GridCol'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'PageDay',
  extends: BaseComponent,
  components: {
    GridRow,
    GridCol,
    PageSecondary,
    TextBodyRegular,
    TextBodyExtraSmall,
    CardExerciseOverview,
    PageContainer,
    ExerciseListCard,
    AlertWarning

  },
  data: function () {
    return {
      injuries: [],
      warningImageURL: this.$appConfig.imagesUrl + 'icons/warning-red.svg',
      headerOptions: {
        show: true,
        left: 'previous-emit',
        right: 'help',
        modifiers: ['with-bg-color', 'small-title'],
        mainTitle: '',
        subTitle: ''
      },
      mode: 'standard',
      circuit: {
        isCircuit: false,
        rounds: 1
      },
      description: '',
      pageClass: ['macroapp--page-common', 'macroapp--page-single-day-cards'],
      exercises: {},
      instructionsPopup: {
        instructions: '',
        visible: false,
        title: ''
      }
    }
  },
  beforeMount () {
    this.loadWorkoutProgramData()
    // this.loadPageData()
  },
  mounted () {
    this.setPageClass()
    this.setHeader(this.headerOptions)
  },
  computed: {
    ...mapGetters({
      headerBackButtonClick: 'pageStore/getBackButtonClick',
      getWorkoutFrom: 'getWorkoutFrom'
    })
  },
  watch: {
    headerBackButtonClick: function (newVal, oldVal) {
      this.navigatePrevious()
    }
  },
  methods: {
    goToNotFoundPage () {
      this.$router.replace({path: '/404'})
    },
    loadWorkoutProgramData () {
      let workoutProgramID = this.params.workout
      workoutProgramID = parseInt(workoutProgramID)
      let workoutProgramsService = new WorkoutProgramsService()
      workoutProgramsService.setWorkoutProgramList().then(() => {
        let program = workoutProgramsService.getWorkoutProgram(workoutProgramID)
        if (program.checkDetailsLoaded()) {
          this.setProgramData(program)
        } else {
          program.setProgramDetails().then(() => {
            this.setProgramData(program)

          }).catch(err => {
            if (err.response && err.response.status === 404) {
              this.goToNotFoundPage()
            }
            this.hideLoading()
          })
        }
      }).catch(() => {
        this.hideLoading()
      })
    },
    setProgramData (program) {
      let week = this.getWeekInProgram(program)
      let day = this.getDayInWeek(week)
      if (!day) {
        this.goToNotFoundPage()
      } else {
        this.setPageTitle(program.getName())
        this.setPageData(program, week, day)
      }
      pageReadyEvent()
      this.hideLoading()
    },
    setPageData (program, week, day) {
      this.mode = day.getType()
      this.setCircuitData(day)

      this.setHeaderTitle(week, day)
      this.setInstructions(day)

      this.injuries = day.getInjuries()
      this.exercises = day.getGroupedExercisesBySetType()
    },
    setCircuitData (day) {
      this.circuit.isCircuit = day.getIsCircuit()
      if (day.getIsCircuit()) {
        this.circuit.rounds = day.getCircuitRounds()
      }
    },
    setInstructions (day) {
      this.description = day.getDescription()
      this.instructionsPopup.instructions = [this.description]
      this.instructionsPopup.instructionType = 'workoutDay'
      // calling to setContent function in the pageStore
      this.setInstructionsPopupContent(this.instructionsPopup)
    },
    setHeaderTitle (week, day) {
      this.headerOptions.mainTitle = this.numberWordToNumeric(day.getName())
      this.headerOptions.subTitle = this.numberWordToNumeric(week.getName())
      this.setHeader(this.headerOptions)
    },
    getWeekInProgram (program) {
      let workoutProgramWeekID = this.params.week
      workoutProgramWeekID = parseInt(workoutProgramWeekID) || 0
      return program.getWeekByID(workoutProgramWeekID)
    },
    getDayInWeek (week) {
      let workoutProgramDayID = this.params.day
      workoutProgramDayID = parseInt(workoutProgramDayID) || 0
      return week.getDayByID(workoutProgramDayID)
    },
    showHelp () {
      this.showPopup(
        {
          title: 'Instructions',
          instructions: [this.description]
        },
        Instructions
      )
    },
    navigateNext (exercise, grouped, setID) {
      this.$router.push({
        path: this.$appConfig.appUrlList.workout,
        query: {
          workout: this.params.workout,
          week: this.params.week,
          day: this.params.day,
          exercise: exercise,
          grouped: grouped,
          'set-id': setID,
          mode: this.mode
        }
      })
    },

    navigatePrevious () {
      if (this.getWorkoutFrom === 'dashboard') {
        this.$router.push({
          path: this.$appConfig.appUrlList.dashboard
        })
      } else if (this.getWorkoutFrom === 'workout-programs') {
        this.$router.push({
          path: this.$appConfig.appUrlList.workoutBase
        })
      } else {
        this.$router.push({
          path: this.$appConfig.appUrlList.workout,
          query: { workout: this.params.workout, week: this.params.week }
        })
      }
    }
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
  }
}
</script>
