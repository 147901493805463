<template>
  <popup
    @hide="closePopup"
    modal-class="gc-popup-pwa-instructions"
    size="md"
    v-if="visible"
    :visible="visible"

  >
    <popup-title>
      <popup-button-close @click="closePopup"></popup-button-close>
    </popup-title>
    <popup-body class="text-center">
      <popup-head>
        <img class="gc-popup-pwa-instructions__pwa-image" :src="logoIcon" alt="app-icon"/>
        <popup-text-body class="gc-popup-pwa-instructions__title">
          <p v-html="$t('message[\'popup.pwa.title\']')"></p>
        </popup-text-body>
        <hr class="gc-popup-pwa-instructions__line"/>
      </popup-head>
      <popup-content>
      <popup-text-body
        v-if="isIOSDevice"
        class="gc-popup-pwa-instructions__instructions gc-popup-pwa-instructions__instructions-ios">
        <p class="gc-popup-pwa-instructions__instructions-text">{{ $t('message["popup.pwa.tap"]') }}</p>
        <icon-ios-menu size="sm1" class="gc-popup-pwa-instructions__instructions-icon"/>
        <p class="gc-popup-pwa-instructions__instructions-text">{{ $t('message["popup.pwa.text-1"]') }}</p>
        <p class="gc-popup-pwa-instructions__instructions-text">{{ $t('message["popup.pwa.text-2"]') }}</p>
        <icon-ios-add-to-home size="sm1" class="gc-popup-pwa-instructions__instructions-icon"/>
      </popup-text-body>
      <popup-text-body
        v-else-if="isAndroidDevice"
        class="gc-popup-pwa-instructions__instructions gc-popup-pwa-instructions__instructions-android">
        <p class="gc-popup-pwa-instructions__instructions-text">{{ $t('message["popup.pwa.text-tap-android"]') }}</p>
        <icon-more size="sm1" class="gc-popup-pwa-instructions__instructions-icon gc-popup-pwa-instructions__instructions-icon-more"/>
        <p class="gc-popup-pwa-instructions__instructions-text">{{ $t('message["popup.pwa.text-1-android"]') }}</p>
        <p class="gc-popup-pwa-instructions__instructions-text">{{ $t('message["popup.pwa.text-2-android"]') }}</p>
        <icon-android-add-to-home size="sm4" class="gc-popup-pwa-instructions__instructions-icon gc-popup-pwa-instructions__instructions-icon-android-add-to-home"/>
      </popup-text-body>
      </popup-content>
    </popup-body>

  </popup>
</template>

<script>

import Popup from '../popups/Popup'
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import PopupTitle from './popup-sub-components/PopupTitle'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupBody from './popup-sub-components/PopupBody'
import PopupTextBody from './popup-sub-components/PopupTextBody'
import IconIosMenu from './popup-pwa-instructions-sub-components/IconIosMenu'
import IconIosAddToHome from './popup-pwa-instructions-sub-components/IconIosAddToHome'
import {isIOS, isAndroid} from 'mobile-device-detect'
import IconMore from '../../root/icons/IconMore'
import IconAndroidAddToHome from './popup-pwa-instructions-sub-components/IconAndroidAddToHome'
import PopupContent from './popup-sub-components/PopupContent'
import PopupHead from './popup-sub-components/PopupHead'
export default {
  name: 'PopupPWAInstructions',
  components: {
    PopupHead,
    PopupContent,
    IconAndroidAddToHome,
    IconMore,
    IconIosAddToHome,
    IconIosMenu,
    PopupTextBody,
    PopupBody,
    PopupButtonClose,
    PopupTitle,
    Popup
  },
  props: ['visible', 'title'],
  mixins: [popupAnimationMixin],
  data: function () {
    return {
      imagesUrl: this.$appConfig.imagesUrl,
      logoIcon: this.$appConfig.imagesUrl + 'logo-icon/default-icon.png'
    }
  },
  computed: {
    isAndroidDevice: function () {
      return isAndroid
    },
    isIOSDevice: function () {
      return isIOS
    }
  },
  mounted () {
    const manifest = $('link[rel=\'manifest\']')
    if (manifest.length > 0 && typeof manifest.eq(0).attr('href') !== 'undefined') {
      this.readTextFile(manifest.eq(0).attr('href'), (text) => {
        try {
          const data = JSON.parse(text)
          this.logoIcon = data.icons[0].src || this.$appConfig.imagesUrl + 'logo-icon/default-icon.png'
        } catch (e) {
          this.logoIcon = this.$appConfig.imagesUrl + 'logo-icon/default-icon.png'
        }
      })
    }
  },
  methods: {
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    },
    readTextFile (file, callback) {
      const rawFile = new XMLHttpRequest()
      rawFile.overrideMimeType('application/json')
      rawFile.open('GET', file, true)
      rawFile.onreadystatechange = function () {
        if (rawFile.readyState === 4) {
          callback(rawFile.responseText)
        }
      }
      rawFile.send(null)
    }
  }
}
</script>
