<template>
  <div
    class="pc-dashboard__notification-bar"
    v-if="notificationBarShow && notificationList.length > 0 && show"
    @click="navigateToList()"
  >
    <div class="pc-dashboard__notification-bar__link">
      <text-content
        :size="'sm1'"
        :line-height="'multi'"
        :weight="'extra-bold'"
        :decoration="'underline'"
      >
        {{ notificationText }} >
      </text-content>
    </div>
  </div>
</template>
<script>
import TextContent from '../../../../root/TextContent'
import { mapGetters } from 'vuex'
import notificationBarMixin from '../../../../../mixins/component-mixins/global/notificationBarMixin'

export default {
  name: 'DashboardNotificationBar',
  components: { TextContent },
  mixins: [notificationBarMixin],
  computed: {
    ...mapGetters({
      notificationBarSettings: 'pageStore/notificationBarSettings',
      notificationCount: 'getNotificationCount'
    }),
    notificationBarShow () {
      return (
        this.notificationBarSettings.show &&
          this.notificationCount > 0 &&
          !(this.params.page && this.params.page === 'notifications')
      )
    }
  }
}
</script>
