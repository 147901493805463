import { mapGetters, mapMutations } from 'vuex'
import MealPlanService from '../../../../services/MealPlanService'
import ShoppingListService from '../../../../services/ShoppingListService'
import lodash from 'lodash'
import BaseComponent from '../../../../components/layout/template-1/global/base/BaseComponent'
import {
  enableGroupedIngredients,
  enableShoppingListIngredientsFlag
} from '../../../../includes/TemplateSettings'

export default {
  extends: BaseComponent,
  data: function () {
    return {
      selectedItems: [],
      shoppingItems: [],
      groupedShoppingItems: [],
      loadingItems: true,
      mealPlanID: 0,
      instructionsPopup: {
        instructions: '',
        title: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      selectedDays: 'shoppingStore/days',
      shoppedItemsCount: 'shoppingStore/shoppedItemsCount',
      shoppedItems: 'shoppingStore/shoppedItems',
      peopleCount: 'shoppingStore/peopleCount'
    }),
    showGroupedShoppingList: function () {
      return enableGroupedIngredients() && enableShoppingListIngredientsFlag()
    }
  },
  watch: {
    selectedItems: function (val, old) {
      this.setSelectedItems({ items: val })
    }
  },
  beforeMount () {
    this.mealPlanID = this.$route.params.mid
  },
  methods: {
    ...mapMutations({
      'setSelectedItems': 'shoppingStore/selectedItems'
    }),
    getMealPlanData () {
      const mealService = new MealPlanService()
      mealService.getMealPlan(this.mealPlanID).then((response) => {
        this.instructionsPopup.instructions = [response.plan.description]
        // calling to setContent function in the pageStore
        this.setInstructionsPopupContent(this.instructionsPopup)
        this.afterGetMealPlanData(response)
      }).catch(err => {
        this.afterGetMealPlanDataError(err)
      })
    },
    afterGetMealPlanData (response) {

    },
    afterGetMealPlanDataError () {
    // ignore the error
    },
    getShoppingListData () {
      const service = new ShoppingListService()
      const selectedItems = this.shoppedItems
      service
        .get(
          { days: this.selectedDays },
          this.mealPlanID
        )
        .then((res) => {
          this.loadingItems = false
          let items = res.shopping_items.filter((item) => {
            return lodash.includes(selectedItems, item.id)
          })
          items = lodash.sortBy(items, 'name')
          this.shoppingItems = items
          if (this.showGroupedShoppingList) {
            this.groupedShoppingItems = service.ingredientsByGroups
          }
          this.afterGetShoppingListData(res)
        }).catch((err) => {
          this.afterGetShoppingListDataError(err)
        }).finally(() => {
          this.hideLoading()
          this.selectedItems = this.shoppedItems
        })
    },
    afterGetShoppingListData (response) {

    },
    afterGetShoppingListDataError () {
      this.hideLoading()
    }

  }
}
