import MealPlanAPI from '../api/meal-plan/MealPlanAPI'
import MealPlanDayService from './MealPlanDayService'
import MealDislikeService from '../MealDislikeService'
import MealFavouritesService from '@/services/nutrition/MealFavouritesService'

export default class MealPlanService {
  _id = null
  _name = ''
  _description = ''
  _subscriptionId = ''
  _image = null
  _notes = ''
  _isPaired = false
  _isParent = false
  _pricingPlanName = ''
  _pricingPlanType = ''
  _activateAt = null
  _available = true
  _archived = false
  _deslikes = new MealDislikeService()
  _favorites = new MealFavouritesService()
  _isDetailsLoaded = false

  _days = []

  /**
   *
   * @param data
   */
  constructor (data) {
    this._id = data.id
    this._name = data.name || ''
    this._description = data.description || ''
    this._subscriptionId = data.subscription_id || ''
    this._image = data.image || ''
    this._notes = data.notes || ''
    this._isPaired = data.is_paired || false
    this._isParent = data.is_parent || false
    this._pricingPlanName = data.pricing_plan_name || ''
    this._pricingPlanType = data.pricing_plan_type || ''
    this._activateAt = data.activate_at || ''
    this._available = data.available || false
    this._archived = data.archived || false
  }

  getId () {
    return this._id
  }

  getDays () {
    return this._days
  }

  isAvailable () {
    return this._available
  }

  getActivateAt () {
    return this._activateAt
  }

  isLoaded () {
    return this._isDetailsLoaded
  }

  getName () {
    return this._name
  }

  getDescription () {
    return this._description
  }

  /**
   * returns plan is paired with another user plans
   * @returns {boolean}
   */
  isPairedMealPlan () {
    return this._isPaired
  }

  getNotes () {
    return this._notes
  }

  getCoverImage () {
    return this._image
  }

  getSubscriptionID () {
    return this._subscriptionId
  }

  /**
   * returns plan is paired with another users plans and it is parent of it
   * @returns {boolean}
   */
  isParentPlan () {
    return this._isParent
  }

  async setMealPreferences(){
    if(this._deslikes.getDislikes()===null || this._favorites.getFavourites()===null){
      const mealAPI = new MealPlanAPI()
      return mealAPI.getPreferences().then(data=>data.data).then(data=>{
        this._deslikes.setDislikeIDs(data.dislikes.map(meal=>meal.id))
        this._favorites.setFavouriteIDs(data.favorites.map(meal=>meal.id))

      })
    }


  }

  getData () {
    return new Promise((resolve, reject) => {
      if (this.isLoaded()) {
        resolve()
      } else {
        this.getDataFromAPI().then(() => {
          resolve()
        })
      }
    })
  }

  getAverageCaloriesOfDays () {
    let total = 0
    this._days.forEach(day => {
      total = total + day.getCalories()
    })
    return Math.round(total / this._days.length)
  }

  getAverageMacroNutrientsOfDays () {
    let total = {
      carbohydrates: 0,
      fats: 0,
      net_carbohydrates: 0,
      proteins: 0,
      show_net_carbs: false
    }
    this._days.forEach(day => {
      Object.entries(day.getMacroNutrients()).forEach(([key, val]) => {
        total[key] = total[key] + val
      })
    })

    return {
      carbohydrates: Math.round(total.carbohydrates / this._days.length),
      fats: Math.round(total.fats / this._days.length),
      net_carbohydrates: Math.round(total.net_carbohydrates / this._days.length),
      proteins: Math.round(total.proteins / this._days.length),
      show_net_carbs: !!(total.show_net_carbs)
    }
  }

  getDataFromAPI () {
    if (!this._available) {
      throw new Error('meal plan not available')
    }

    return Promise.all([this.getMealPLanDataFromAPI(),this.setMealPreferences()]).then(() => {
      this._days.forEach(day => {
        day.setDislikeService(this._deslikes)
        day.setDislikeService(this._deslikes)
      })
    })
  }

  getMealPLanDataFromAPI () {
    let api = new MealPlanAPI()
    return api.get(this._id).then(data => data.data).then(data => {
      this._mapPlanData(data.plan)
    })
  }

  /**
   *
   * @param plan - row plan data from API response
   * @private
   */
  _mapPlanData (plan) {
    if (plan.weeks && plan.weeks[0] && plan.weeks[0].days) {
      this._days = []
      plan.weeks[0].days.forEach(day => {
        let dayObj = new MealPlanDayService(day, this._id)
        this._days.push(dayObj)
      })
    }

    this._isDetailsLoaded = true
  }

  getDay (id) {
    return this._days.find(day => {
      return day.getId() === id
    })
  }

  getDayIndex (id) {
    return this._days.findIndex(day => {
      return day.getId() === id
    })
  }

  isSwapEnabled () {
    return !this._archived
  }

  getPricingPlanType () {
    return this._pricingPlanType
  }

  getPricingPlanName () {
    return this._pricingPlanName
  }

  swapMeal (mealId) {
    this.getDays().forEach(day => {
      let selectedMeal = day.getMeals().find(meal => {
        return meal.getId() === mealId
      })

      if (selectedMeal) {
        return selectedMeal.swap()
      }
    })

    return Promise.resolve()
  }

  getMealsDislikedIngredients (excludedIngredients, currentMealId, mealSwapStatus) {
    const excludedNames = []
    const inclIng = []
    const mealIds = []
    const refMealIds = []
    let reg = ''

    for (let i = 0; i < excludedIngredients.length; i++) {
      reg += excludedIngredients[i].name + (i < excludedIngredients.length - 1 ? '|' : '')
      excludedNames.push(excludedIngredients[i].name)
    }
    reg = reg.replace(/[.*+?^${}()[\]\\]/g, '\\$&')
    const regex = new RegExp(reg)

    this.getDays().forEach(day => {
      day.getMeals().forEach(meal => {
        meal.getRecipes().forEach(recipe => {
          const ingredients = recipe.getIngredients()

          ingredients.forEach(ingredient => {
            const matched = ingredient.name.match(regex)
            if (matched) {
              const index = excludedNames.indexOf(matched[0])
              if (mealIds.indexOf(meal.getId()) === -1 &&
                !mealSwapStatus[meal.getId() + '' + meal.getReferenceMealId()] &&
                meal.getId() !== currentMealId && meal.getSwapStatus() !== 'swap-requested') {
                mealIds.push(meal.getId())
                refMealIds.push(meal.getReferenceMealId())
                if (inclIng.indexOf(excludedIngredients[index]) === -1) {
                  inclIng.push(excludedIngredients[index])
                }
              }
            }
          })
        })
      })
    })
    return {
      ingredients: inclIng,
      mealIds: mealIds,
      refMealIds: refMealIds
    }
  }
}
