import { render, staticRenderFns } from "./IconForward.vue?vue&type=template&id=7aba54af&scoped=true"
import script from "./IconForward.vue?vue&type=script&lang=js"
export * from "./IconForward.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7aba54af",
  null
  
)

export default component.exports