<template>
  <div
    ref="header"
    v-bind="$attrs"
    :class="headerClasses"
    class="gc-header-page">
    <div class="gc-header__section-left">
      <icon-backward
        v-if="options.left === 'previous'"
        :size="'sm4'"
        class="gc-header__left-icon gc-header__left-icon-backward"
        @click="previous()"/>
      <icon-backward
        v-else-if="options.left === 'previous-emit'"
        :size="'sm4'"
        class="gc-header__left-icon gc-header__left-icon-backward"
        @click="previous('emit')"/>
      <app-header-logo
        v-else-if="options.left === 'logo' || options.left === 'menu'"
        @click="goToDashboard()"/>
      <div
        v-else
        class="gc-header__left-empty-area"/>
    </div>
    <div class="gc-header__section-center">
      <div :class="titleClasses">
        <text-heading3
          v-if="mainTitle && !modifiers.includes('small-title')"
          :line-height="'multi'"
          weight="extra-bold">{{ mainTitle }}
        </text-heading3>
        <text-body-medium
          v-if="mainTitle && modifiers.includes('small-title')"
          :line-height="'multi'"
          weight="extra-bold">{{ mainTitle }}
        </text-body-medium>
      </div>
      <div class="gc-header__sub-title">
        <text-body-small
          v-if="subTitle"
          :line-height="'multi'"
          :decoration="modifiers.includes('underlined-subtitle') ? 'underline' : ''"
          @click="subTitleClicked"
        >
          {{ subTitle }}
        </text-body-small>
      </div>
    </div>
    <div class="gc-header__section-right">
      <icon-info
        v-if="rightIcons.includes('info')"
        :size="'sm4'"
        class="gc-header__right-icon gc-header__right-icon-info"
        @click="infoButtonClicked"/>
      <icon-info-bulb
        v-else-if="rightIcons.includes('bulb') && !rightIconActive"
        :size="'sm4'"
        class="gc-header__right-icon gc-header__right-icon-info-bulb"
        @click="showHelpPopup()"/>
      <icon-info-bulb-filled
        v-else-if="rightIcons.includes('bulb') && rightIconActive"
        :size="'sm4'"
        class="gc-header__right-icon gc-header__right-icon-info-bulb gc-header__right-icon-info-bulb-filled"
        @click="showHelpBulb()"/>
      <icon-add
        v-else-if="rightIcons.includes('add')"
        :size="'sm4'"
        class="gc-header__right-icon gc-header__right-icon-add"
        @click="addButtonClicked"/>
      <icon-send
        v-else-if="rightIcons.includes('message')"
        :size="'sm4'"
        class="gc-header__right-icon gc-header__right-icon-send"
        @click="sendButtonClick"/>
      <icon-edit
        v-else-if="rightIcons.includes('edit')"
        :size="'sm4'"
        class="gc-header__right-icon gc-header__right-icon-send"
        @click="editButtonClick"/>
      <icon-mark-read
        v-else-if="rightIcons.includes('mark-read')"
        :state="(options.iconStates && options.iconStates['mark-read']) ? options.iconStates['mark-read'] : 'active'"
        :size="'md1'"
        class="gc-header__right-icon gc-header__right-icon-mark-read"
        @click="markReadButtonClick"/>
      <icon-close
        v-else-if="rightIcons.includes('close')"
        :size="'sm2'"
        class="gc-header__right-icon gc-header__right-icon-send"
        @click="closeButtonClick"/>

      <div
        v-else-if="rightIcons.includes('profile') && rightIcons.includes('notifications')"
        class="gc-header__mutiple-icons">
        <icon-notifications
          :size="'sm2'"
          :class="{
            'gc-header__right-icon gc-header__right-icon-notification':true,
            'gc-header__right-icon gc-header__right-icon-notification--active':notificationCount>0 || unreadCommunityNotificationsExist
          }"
          @click="goToNotificationPage"/>
        <span
          v-if="notificationCount>0 || unreadCommunityNotificationsExist"
          class="gc-header__right-icon-notification_circle"/>
        <div
          class="gc-header__right--profile"
          @click="goToProfilePage()">
          <img
            :src="profileImage"
            alt="">
        </div>
      </div>

      <div class="gc-header__notification-only" v-if="rightIcons.includes('notifications') && !rightIcons.includes('profile')">
        <icon-notifications
          :size="'sm2'"
          :class="{
            'gc-header__right-icon gc-header__right-icon-notification':true,
            'gc-header__right-icon gc-header__right-icon-notification--active':notificationCount>0 || unreadCommunityNotificationsExist
          }"
          @click="goToNotificationPage"/>
        <span
          v-if="notificationCount>0 || unreadCommunityNotificationsExist"
          class="gc-header__right-icon-notification_circle"/>
      </div>

      <button
        v-else-if="rightIcons.includes('pwa') && showPwaPopup()"
        class="gc-header__right-button gc-header__right-gc-header__right-button-pwa">
        <img
          :src="imagesUrl + 'icons/iosdownloadnew.gif'"
          alt="PWA"
        >
        <span/>
      </button>
      <div
        v-else
        class="gc-header__right-empty-area"/>
    </div>
  </div>
</template>

<script>
import IconBackward from '../../root/icons/IconBackward'
import IconInfo from '../../root/icons/IconInfo'
import SideBarV2 from '../navigators/SideBar'
import IconAdd from '../../root/icons/IconAdd'
import IconSend from '../../root/icons/IconSend'
import IconEdit from '../../root/icons/IconEdit'
import IconClose from '../../root/icons/IconClose'
import IconInfoBulb from '../../root/icons/IconInfoBulb'
import appHeaderMixin from '../../../../../mixins/appHeaderMixin'
import TextHeading3 from '../typography/TextHeading3'
import IconInfoBulbFilled from '../../root/icons/IconInfoBulbFilled'
import AppHeaderLogo from '../widgets/app-headers/AppHeaderLogo'
import IconNotifications from '../../root/icons/IconNotifications'
import TextBodyMedium from '../typography/TextBodyMedium'
import TextBodySmall from '../typography/TextBodySmall'
import { flagValue } from '../../../../../includes/TemplateSettings'
import SideMenu from '../widgets/SideMenuV2'
import IconMarkRead from '../../root/icons/IconMarkRead'

export default {
  name: 'HeaderPage',
  components: {
    IconMarkRead,
    IconInfoBulbFilled,
    IconInfoBulb,
    IconSend,
    IconAdd,
    SideBarV2,
    SideMenu,
    IconInfo,
    IconBackward,
    TextHeading3,
    AppHeaderLogo,
    IconNotifications,
    TextBodyMedium,
    TextBodySmall,
    IconEdit,
    IconClose
  },
  mixins: [appHeaderMixin],
  computed: {
    headerClasses: function () {
      return {
        'gc-header-page--with-title': !!this.mainTitle,
        'gc-header-page--transparent': !!this.isTransparent,
        'gc-header-page--top-fixed': !!this.isFixed,
        'gc-header-page--with-sub-title': !!this.subTitle,
        'gc-header-page--without-sub-title': !this.subTitle,
        'gc-header-page__title-sm': this.modifiers.includes('small-title')
      }
    },
    titleClasses: function () {
      return {
        'gc-header__title': true,
        'gc-header__title--small': this.modifiers.includes('small-title')

      }
    },
    showNewSideMenu () {
      return !!flagValue('show_sidebar_v2', true)
    }
  },
  mounted () {
    this.setRightIcons()
    this.emitHeaderHeight()
    this.onScreenResize()
  },
  updated () {
    this.emitHeaderHeight()
    this.$emit('updated')
  },
  beforeDestroy () {
    this.offScreenResize()
  },
  methods: {
    onScreenResize () {
      window.addEventListener('resize', this.emitHeaderHeight)
    },
    offScreenResize () {
      window.removeEventListener('resize', this.emitHeaderHeight)
    },
    emitHeaderHeight () {
      let header = this.$refs.header
      if (header) {
        this.$nextTick(() => {
          let height = header.offsetHeight + parseFloat(window.getComputedStyle(header, null).getPropertyValue('margin-top'))
          this.$emit('height-updated', height)
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
