<template>
  <div  class="gc-popup__body" id="gc-popup-body" >
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'popup-body'
}
</script>

<style scoped>

</style>
