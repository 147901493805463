<template>
    <div :class="componentClasses">
      <div
        v-if="selectedMeasurementSystem === 'metric'"
        @click="focusInputOnClick('input-kg')"
        class="gc-input-weight__input-wrapper row">
        <input
          class="gc-input-weight__input col-6"
          :maxlength="maxValue"
          :placeholder="placeholder"
          :disabled="disabled"
          v-model.number="kgWeight"
          type="number"
          step="0.01"
          ref="inputKg"
        />
        <text-content
          class="gc-input-weight__unit col-6"
          :weight="'extra-bold'"
          :size="'sm4'"
          :lineHeight="'multi'">
          {{selectedUnit}}
        </text-content>
      </div>
      <div
        v-if="selectedMeasurementSystem === 'imperial'"
        @click="focusInputOnClick('input-lbs')"
        class="gc-input-weight__input-wrapper row">
        <input
          class="gc-input-weight__input col-6"
          :maxlength="maxValue"
          :placeholder="placeholder"
          :disabled="disabled"
          v-model.number="lbsWeight"
          type="number"
          step="0.01"
          ref="inputLbs"
        />
        <text-content
          class="gc-input-weight__unit col-6"
          :weight="'extra-bold'"
          :size="'sm4'"
          :lineHeight="'multi'">
          {{selectedUnit}}
        </text-content>
      </div>
      <text-content
        v-if="errorMessage.length > 0"
        class="gc-input-weight__error-message"
        :weight="'medium'"
        :size="'sm1'"
        :lineHeight="'multi'">
        {{ errorMessage }}
      </text-content>
      <div class="gc-input-weight__unit-selector-wrapper" v-if="measurementSystemChangeable">
        <div
          class="gc-input-weight__unit-selector__unit gc-input-weight__unit-selector__unit-lbs"
          v-bind:class="{ 'gc-input-weight__unit-selector__unit--selected': this.selectedMeasurementSystem === 'imperial' }"
          @click="changeUnit('imperial')">
          <icon-down-arrow-filled
            v-if="selectedMeasurementSystem === 'imperial'"
            :size="'sm1'"
            class="gc-input-weight__unit-selector__unit-lbs__arrow" />
          <text-content
            class="gc-input-weight__unit-selector__unit-lbs__text"
            :weight="'extra-bold'"
            :size="'sm4'"
            :lineHeight="'multi'">
            {{$t('message["general.lbs"]')}}
          </text-content>
        </div>
        <div
          class="gc-input-weight__unit-selector__unit gc-input-weight__unit-selector__unit-kg"
          v-bind:class="{ 'gc-input-weight__unit-selector__unit--selected': this.selectedMeasurementSystem === 'metric' }"
          @click="changeUnit('metric')">
          <icon-down-arrow-filled
          v-if="selectedMeasurementSystem === 'metric'"
          :size="'sm1'"
          class="gc-input-weight__unit-selector__unit-kg__arrow" />
          <text-content
            class="gc-input-weight__unit-selector__unit-kg__text"
            :weight="'extra-bold'"
            :size="'sm4'"
            :lineHeight="'multi'">
            {{$t('message["general.kg"]')}}
          </text-content>
        </div>
      </div>
  </div>
</template>

<script>
import TextContent from '../../root/TextContent'
import IconDownArrowFilled from '../../root/icons/IconDownArrowFilled'

export default {
  name: 'InputWeight',
  components: {
    TextContent,
    IconDownArrowFilled
  },
  data: function () {
    return {
      lbsWeight: null,
      kgWeight: null,
      lastLbsValue: null,
      lastKgValue: null,
      isValueChanged: false,
      selectedMeasurementSystem: 'metric'
    }
  },
  props: {
    measurementSystemChangeable: {
      default: true
    },
    value: {
      default: null
    },
    minValue: {
      type: Number,
      default: 0
    },
    maxValue: {
      type: Number,
      default: 100
    },
    errorMessage: {
      default: ''
    },
    placeholder: {
      default: '0'
    },
    disabled: {
      default: false
    },
    measurementSystem: {
      default: 'metric'
    }
  },
  computed: {
    selectedUnit: function () {
      if (this.selectedMeasurementSystem === 'imperial') {
        return this.$i18n.t('message["general.lbs"]')
      } else {
        return this.$i18n.t('message["general.kg"]')
      }
    },
    componentClasses: function () {
      return {
        'gc-input-weight': true,
        'gc-input-weight__error': this.errorMessage.length > 0,
        'gc-input-weight__disabled': this.disabled
      }
    }
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function () {
        if (this.value.preference === 'imperial') {
          this.selectedMeasurementSystem = 'imperial'
          this.lbsWeight = parseFloat(this.value.lbs)
        } else {
          this.selectedMeasurementSystem = 'metric'
          this.kgWeight = parseFloat(this.value.kg)
        }
      }
    },
    measurementSystem: {
      immediate: true,
      handler: function () {
        if (this.measurementSystem === 'imperial') {
          this.selectedMeasurementSystem = 'imperial'
        } else {
          this.selectedMeasurementSystem = 'metric'
        }
      }
    },
    lbsWeight (val) {
      this.$nextTick(() => {
        this.lastLbsValue = this.lbsWeight
        this.emitLbsValue(val)
      })
    },
    kgWeight (val) {
      this.$nextTick(() => {
        this.lastKgValue = this.kgWeight
        this.emitKgValue(val)
      })
    }
  },
  methods: {
    emitLbsValue (val) {
      this.$nextTick(() => {
        let value = {
          preference: 'imperial',
          lbs: parseFloat(val),
          kg: this.lastKgValue
        }
        this.$emit('input', value)
        this.$emit('change', value)
      })
    },
    emitKgValue (val) {
      let value = 0
      value = {
        preference: 'metric',
        lbs: this.lastLbsValue,
        kg: parseFloat(val) || null
      }
      this.$emit('input', value)
      this.$emit('change', value)
    },
    changeUnit (unit) {
      if (!this.disabled) {
        this.selectedMeasurementSystem = unit
        // to change v-model values along with the unit change
        if (this.selectedMeasurementSystem === 'imperial') {
          this.emitLbsValue(this.lbsWeight)
        } else {
          this.emitKgValue(this.kgWeight)
        }
        this.$emit('unit', this.selectedMeasurementSystem)
      }
    },
    focusInputOnClick (inputName) {
      if (inputName === 'input-kg') {
        this.$refs.inputKg.focus()
      } else if (inputName === 'input-lbs') {
        this.$refs.inputLbs.focus()
      }
    }
  }
}
</script>
