<template>
    <component v-bind:is="component" />
</template>
<script>
import PageInviteAFriend from './PageInviteAFriend'
import PageListInvitations from './PageListInvitations'

export default {
  name: 'page-invitations-main',
  components: {
    PageInviteAFriend,
    PageListInvitations
  },
  data () {
    return {
      component: 'PageInviteAFriend'
    }
  },
  mounted () {
    this.setDynamicComponent()
  },
  methods: {
    setDynamicComponent () {
      let currentRoute = window.location.href
      if (currentRoute.includes('list')) {
        this.component = 'PageListInvitations'
      } else {
        this.component = 'PageInviteAFriend'
      }
    }
  }
}
</script>
