<template>
  <div :class="componentClasses" v-on="$listeners" @click="clicked()">
    <div class="gc-action__wrap">
      <icon-swap :size="'sm4'" class="gc-meal__action-icon" />
    </div>

    <text-body-small
      v-if="showText"
      class="gc-meal__action-text"
      :line-height="'multi'"
    >
      {{ text }}
    </text-body-small>
  </div>
</template>

<script>
import IconSwap from "../../root/icons/IconSwap";
import TextBodySmall from "../typography/TextBodySmall";

export default {
  name: "ActionMealSwap",
  components: {
    IconSwap,
    TextBodySmall,
  },
  props: {
    state: {
      type: String,
      default: "",
    },
    showText: {
      type: Boolean,
      default: true,
    },
    limitText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    componentClasses: function () {
      return {
        "gc-meal__action": true,
        "gc-meal__action-swap": true,
        "gc-meal__action-disabled": this.state === "disabled",
        "gc-meal__action-onTap": this.state === "onTap",
        "gc-meal__action-active": this.state === "active",
      };
    },
    text: function () {
      let text = this.$i18n.t('message["meal-plan.swap"]');
      if (this.state === "active") {
        return this.$i18n.t('message["meal-plan.swapping"]');
      }
      if (this.limitText) {
        text = text.substring(0, 2) + "...";
      }
      return text;
    },
  },
  methods: {
    clicked() {
      this.$emit("clicked");
    },
  },
};
</script>
<style scoped></style>
