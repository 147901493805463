var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.componentClasses},[_c('div',{ref:"graph-scroll-sec",staticClass:"gc-chart__wrapper",attrs:{"id":"chart-wrapper"}},[(_vm.graphData.datasets[0].data.length)?_c('line-chart',{style:('width:' + _vm.graphWidth + 'px'),attrs:{"chart-data":_vm.graphData,"uom":_vm.unit,"tooltip":_vm.tooltips,"isRtl":_vm.isRTL()},on:{"axisDataRendered":_vm.setAxisData}}):_vm._e(),(_vm.staticAxis&&_vm.graphData)?_c('div',{staticClass:"fixed-y-axis",attrs:{"id":"y-axis"}},_vm._l((_vm.staticAxis.ticks),function(tick,index,key){return _c('div',{key:key},[(_vm.staticAxis.ticks.length - 1 === index)?_c('div',{style:('margin-top:'+  70 + 'px; ' +
            'margin-bottom: 5px;' +
            'font-size:' + _vm.staticAxis.options.ticks.fontSize + 'px;' +
            'color: ' + _vm.staticAxis.options.ticks.fontColor + ';' +
            'font-weight:700;')},[_vm._v(" "+_vm._s(tick)+" ")]):_c('div',{style:('margin-top:'+  70 + 'px; ' +
            'margin-bottom:'+ _vm.yTickGap() + 'px;' +
            'font-size:' + _vm.staticAxis.options.ticks.fontSize + 'px;' +
            'color: ' + _vm.staticAxis.options.ticks.fontColor + ';' +
            'font-weight:700;')},[_vm._v(_vm._s(tick)+" ")])])}),0):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }