<template>
  <div
    class="gc-navigator-bottom-bar__floating-area-button"
    @click="requestUpdate('weight')">
    <div class="gc-navigator-bottom-bar__icon">
      <transition>
        <icon-scale
          size="sm4"
          class="gc-navigator-bottom-bar__floating-area-button-icon"/>
      </transition>

    </div>
    <div class="gc-navigator-bottom-bar__text">
      <text-body-extra-small
        line-height="multi"
        class=" gc-navigator-bottom-bar__floating-area-button-text">
        {{ $t('message["navigator.bottom-bar.weight"]') }}
      </text-body-extra-small>
    </div>
    <pop-up-update-fitness-data
      v-if="updatePopup.show"
      :fitness-data="updatePopup.data"
      :type="updatePopup.type"
      :visible="updatePopup.show"
      @close="closeUpdatePopup()"
    />
  </div>
</template>
<script>
import IconScale from '../../../root/icons/IconScale'
import TextBodyExtraSmall from '../../typography/TextBodyExtraSmall'
import PopUpUpdateFitnessData from '../../../layout/template-1/fitness-diary/widgets/PopUpUpdateFitnessData'
import { mapGetters } from 'vuex'
import FitnessDiaryStore from '../../../../store/modules/FitnessDiaryStore'
import { flagValue } from '../../../../includes/TemplateSettings'

export default {
  name: 'NavigatorBottomBarButtonScale',
  components: {
    PopUpUpdateFitnessData,
    IconScale,
    TextBodyExtraSmall
  },
  data: function () {
    return {
      fitnessData: [],
      show: false,
      updatePopup: {
        show: false,
        type: '',
        data: 0
      }
    }
  },
  beforeCreate () {
    const store = this.$store
    if (!(store && store.state && store.state['fitnessDiaryStore'])) {
      store.registerModule('fitnessDiaryStore', FitnessDiaryStore)
    }
  },
  mounted () {
    if (
      flagValue('show_fitness_diary') === null ||
      flagValue('show_fitness_diary')
    ) {
      this.show = true
    }

    this.getData()
  },
  methods: {
    ...mapGetters({
      service: 'fitnessDiaryStore/getService'
    }),
    closeUpdatePopup () {
      this.updatePopup.show = false
    },
    getData () {
      let service = this.service()
      service
        .getDataFromAPI()
        .then(() => {
          this.fitnessData = Object.values(service.getData())
          this.hasTodayUploadAllImages = Object.values(service.images).every(
            image => {
              return image.getTodayImage()
            }
          )
          this.updatedFitnessData()
          this.isLoaded = true
        })
        .catch(() => {
          this.isLoaded = false
        })
    },
    requestUpdate (type) {
      if (type) {
        let currentData = this.fitnessData.find(data => {
          return data.getType() === type
        })
        if (currentData) {
          this.updatePopup.show = true
          this.updatePopup.type = currentData.getType()
          this.updatePopup.data = currentData
        }
      }
    }
  }
}
</script>
