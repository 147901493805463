<template>
    <div :class="componentClasses" v-show="(!search) || (search&&hasSearchResults.value)">
      <button-secondary-accordion :class="{'gc-input-group-ingredients-parent-accordian__main--dislike-all': isAllSelected}"
                                  :text="indredient.name"
                                  :show-arrow="!search"
                                  :subText="setCounts" @click="parentSelect()">
        <div class="gc-input-group-ingredients-parent-accordian__wrapper">
          <input-radio-button-squared :disabled="disabled"
                                      class="gc-input-group-ingredients-parent-accordian__square-radio"
                                      v-if="!isAllSelected"
                                      :semiSelected="oneSelect" @click="toggleDislikedGroup()" />

          <!-- <input-radio-button-squared class="gc-input-group-ingredients-parent-accordian__square-radio" :semiSelected="true" v-else-if="isAllSelected && totalSelectedCount > 0" @click="toggleDislikedGroup" /> -->
          <div class="gc-input-group-ingredients-parent-accordian__dislike-radio" @click="toggleDislikedGroup" v-else>
            <input-radio-button-squared :disabled="disabled" :dislike="!disabled" class="gc-input-group-ingredients-parent-accordian__square-radio"/>
          </div>
        </div>
      </button-secondary-accordion>
      <div class="gc-input-group-ingredients-parent-accordian__sub-category-wrapper" v-show="(!search&&showSubCatogories) || (search&&hasSearchResults.value)">
        <ingredients-sub-accordian
          ref="subCategory"
          :disabled="disabled"
          v-for="(subCategory, index) in indredient.subCategory"
          :key="subCategory.id"
          v-model="selectedIngredients"
          :subCategory="subCategory"
          :search="searchString"
          @all-selected="setSubCategoryAllSelected(subCategory.id)"
          @all-deselected="setSubCategoryAllDeselected(subCategory.id)"
          @some-selected="setSubCategorySomeSelected(subCategory.id)"
          @select="selectedSubCategoryCount($event, index)"
          @has-search-results="getHasSearchResults"
        />
      </div>
    </div>
</template>
<script>
import ButtonSecondaryAccordion from '../../buttons/ButtonSecondaryAccordion'
import IngredientsSubAccordian from './InputGroupIngredientsSubAccordian'
import lodash from 'lodash'
import IconDislike from '../../../root/icons/IconDislike'
import InputRadioButtonSquared from '../InputRadioButtonSquared'
export default {
  name: 'InputGroupIngredientsParentAccordian',
  props: {
    search: {
      default: ''
    },
    indredient: {
      default: function () {
        return {}
      }
    },
    value: {
      default: function () {
        return []
      },
      type: Array
    },
    disabled: {
      type: Boolean,
      default: false
    },
    searchValue: {
      default: ''
    }
  },
  data () {
    return {
      hasSearchResults: {search: '', value: false},
      selectedIngredients: [],
      showSubCatogories: false,
      allSelectedSubCatogories: [],
      isAllSelected: false,
      selectedCounts: [],
      totalSelectedCount: 0
    }
  },
  watch: {
    value: function () {
      this.selectedIngredients = this.value
    },
    selectedIngredients: function () {
      this.$emit('input', this.selectedIngredients)
    },
    searchValue: function () {
    }
  },
  components: {
    ButtonSecondaryAccordion,
    IngredientsSubAccordian,
    IconDislike,
    InputRadioButtonSquared
  },
  computed: {
    searchString: function () {
      return this.search || ''
    },
    componentClasses: function () {
      return {
        'gc-input-group-ingredients-parent-accordian': true,
        'gc-input-group-ingredients-parent-accordian--with-search': !!this.search
      }
    },

    oneSelect () {
      return (this.totalSelectedCount > 0)
    },
    setCounts: function () {
      if (this.totalSelectedCount === 0 || this.totalSelectedCount === 1) {
        return this.totalSelectedCount + ' Ingredient disliked'
      } else {
        return this.totalSelectedCount + ' Ingredients disliked'
      }
    }
  },
  mounted () {
    this.selectedIngredients = this.value
  },
  methods: {
    selectedSubCategoryCount (selectedIngredients, subCategoryIndex) {
      this.selectedCounts[subCategoryIndex] = selectedIngredients
      let allSelectedIngredients = []
      allSelectedIngredients = allSelectedIngredients.concat(...this.selectedCounts)
      // lodash.uniq(allSelectedIngredients)
      this.totalSelectedCount = lodash.uniqBy(allSelectedIngredients, 'id').length
    },
    setSubCategoryAllSelected (id) {
      this.allSelectedSubCatogories.push(id)
      this.allSelectedSubCatogories = lodash.uniq(this.allSelectedSubCatogories)
      this.isAllSelected = this.allSelectedSubCatogories.length === this.indredient.subCategory.length
    },
    setSubCategoryAllDeselected (catID) {
      this.allSelectedSubCatogories = this.allSelectedSubCatogories.filter(id => {
        return id !== catID
      })
      this.isAllSelected = this.allSelectedSubCatogories.length === this.indredient.subCategory.length
    },
    setSubCategorySomeSelected (catID) {
      this.allSelectedSubCatogories = this.allSelectedSubCatogories.filter(id => {
        return id !== catID
      })
      this.isAllSelected = this.allSelectedSubCatogories.length === this.indredient.subCategory.length
    },
    selectedAll () {
      this.$refs.subCategory.forEach((ingredient, index) => {
        setTimeout(() => {
          ingredient.selectedAll()
          ingredient.emitInput()
          this.$emit('input', this.selectedIngredients)
        }, index * 50)
      })
    },
    deselectAll () {
      this.$refs.subCategory.forEach((ingredient, index) => {
        setTimeout(() => {
          ingredient.deselectAll()
          ingredient.emitInput()
          this.$emit('input', this.selectedIngredients)
        }, index * 50)
      })
    },
    toggleDislikedGroup () {
      if (this.disabled) {
        return
      }

      if (this.isAllSelected) {
        this.deselectAll()
      } else {
        this.selectedAll()
      }
    },
    getHasSearchResults (res) {
      if (this.search !== this.hasSearchResults.search) {
        this.hasSearchResults.value = res
        this.hasSearchResults.search = this.search
        this.$emit('has-search-results', res)
      } else if (this.search === this.hasSearchResults.search && res) {
        this.hasSearchResults.value = res
        this.$emit('has-search-results', res)
      }
    },
    parentSelect () {
      if (this.showSubCatogories) {
        this.showSubCatogories = false
        this.$emit('changed')
      } else {
        this.showSubCatogories = true
        this.$emit('changed')
      }
    }
  }
}
</script>
