import { mapGetters, mapMutations } from 'vuex'
import { isFoodDiaryEnabled } from '../includes/TemplateSettings'
import DashBoardService from '../services/DashboardService'
import NutritionService from '../services/nutrition/NutritionService'

export default {
  computed: {
    canShowCustomMealLogPopup: async function () {
      if (isFoodDiaryEnabled() && this.$route.meta && this.$route.meta.requiresAuth) {
        const dashBoardService = new DashBoardService()
        if (dashBoardService.hasMealPlans()) {
          const nut = new NutritionService()
          await nut.init()
          return !!(new NutritionService()).getLatestMealPlan()
        }
      }
      return false
    },
    ...mapGetters({
      customMealLogPopupVisibility: 'customMealLogPopupStore/visible'
    })
  },
  methods: {
    ...mapMutations({
      setCustomMeal: 'customMealLogPopupStore/setCustomMeal'

    }),
    addEventListenerCustomMealLogEvent () {
      document.addEventListener('food-diary-add-custom-meal', this.setFDCustomMealEvent)
    },
    removeEventListenerCustomMealLogEvent () {
      document.removeEventListener('food-diary-add-custom-meal', this.setFDCustomMealEvent)
    },
    setFDCustomMealEvent (event) {
      this.setCustomMeal(event.detail)
    }
  }

}
