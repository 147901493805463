<template>
  <!--   page background -->
  <page-secondary
    :header="headerOptions"
    class="pc-profile--dislike-meals"
  >
    <page-container class="pc-profile--dislike-meals__container">
      <text-content
        :size="'sm2'"
        :weight="'medium'"
        :line-height="'multi'"
        class="pc-text-helper"
      >
        <p v-html="$t('message[\'meal-dislikes.message\']')"/>
      </text-content>
      <text-body-small
        v-if="dislikes.length === 0"
        class="pc-text-no-results"
      >
        <p v-html="$t('message[\'meal-dislikes.no-results\']')"/>
      </text-body-small>

      <grid-row>
        <grid-col
          v-for="(dislike, dID) in dislikes"
          :key="dID"
          md="6"
        >
          <card-meal-dislike
            class="pc-card-meal-dislike"
            :dislike="dislike"
            @delete="showDislikesPopup(dislike.id, dislike.name)"
          />
        </grid-col>
      </grid-row>

      <popup-remove-dislikes
        v-if="dislikesPopup.visible"
        :meal="dislikesPopup.mealName"
        :meal-i-d="dislikesPopup.mealID"
        :visible="dislikesPopup.visible"
        @close="dislikesPopup.visible = false"
        @success="successRemove($event)"
      />
    </page-container>
  </page-secondary>
</template>

<script>
import BaseComponent from '../../global/base/BaseComponent'
import MealDislikeService from '../../../../../services/MealDislikeService'
import PopupRemoveDislikes from '../../../../global/popups/PopupRemoveDislikes'
import CardMealDislike from './page-components/CardMealDislike'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import TextContent from '../../../../root/TextContent'
import PageSecondary from '../../../../global/pages/PageSecondary'
import PageContainer from '../../../../global/pages/page-sub-components/PageContainer'
import GridCol from '../../../../global/grid/GridCol'
import GridRow from '../../../../global/grid/GridRow'
import TextBodySmall from '../../../template-2/global/typography/TextBodySmall'
import NutritionService from '@/services/nutrition/NutritionService'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'
import headerEventBus from '@/event-buses/headerEventBus'

export default {
  name: 'DislikedMeals',
  components: {
    TextBodySmall,
    GridRow,
    GridCol,
    TextBodyExtraSmall,
    CardMealDislike,
    PopupRemoveDislikes,
    TextContent,
    PageSecondary,
    PageContainer
  },
  extends: BaseComponent,
  data: function () {
    return {
      imagesUrl: this.$appConfig.imagesUrl,
      headerOptions: {
        isTransparent: false,
        isFixed: false,
        show: true,
        left: 'previous-emit',
        right: '',
        rightBlink: false,
        modifiers: ['small-title', 'with-bg-color'],
        mainTitle: this.$t('message["meal-plan.disliked-meals"]'),
        subTitle: ''
      },
      dislikes: [],
      dislikesPopup: {
        mealName: '',
        mealID: 0,
        visible: false
      }
    }
  },
  created () {
    this.setPageTitle(this.$t('message["meal-plan.disliked-meals"]'))
  },
  mounted () {
    this.setHeader(this.headerOptions)
    this.setPageClass()
    this.setPageData()
    headerEventBus.$on('back-button-click', this.navigatePrevious)
  },
  beforeDestroy () {
    headerEventBus.$off('back-button-click', this.navigatePrevious)
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
  },
  methods: {
    showDislikesPopup (mealID, mealName) {
      this.dislikesPopup = {
        mealName: mealName,
        mealID: mealID,
        visible: true
      }
    },
    setPageData () {
      let nutrition = new NutritionService()
      nutrition.setDetailedMealPreferences().then(() => {

        this.dislikes = nutrition.getDislikes().getDislikes()
      }).finally(() => {
        this.hideLoading()
        pageReadyEvent()
      })
    },
    navigatePrevious () {
      this.$router.push({
        path: this.$appConfig.appUrlList.profile,
        query: {
          page: 'dietary-preferences',
          loading: '0'
        }
      })
    },
    getPreparationTime (meal) {
      let totalTime = meal.recipes.reduce((accumulator, recipe) => {
        return accumulator + recipe.preparation_time
      }, 0)

      return (
        totalTime + ' ' + this.$i18n.tc('message[\'general.minutes\']', totalTime)
      )
    },
    getIngredientsCount (meal) {
      let total = meal.recipes.reduce((accumulator, recipe) => {
        return accumulator + recipe.ingredients_count
      }, 0)
      return (
        total + ' ' + this.$i18n.tc('message[\'general.ingredients\']', total)
      )
    },
    successRemove (data) {
      let dislikeService = new MealDislikeService()
      this.dislikes = dislikeService.getDislikes()
      this.logEvent('MealPlan.DeletedDislikedMeal', {
        meal: this.dislikesPopup.mealName
      }, false)
    }
  }
}
</script>
