export function macroSplit (a, b, strict, snack, main, val) {
  let acal = {
    p: a.macronutrients.proteins * 4,
    c: a.macronutrients.carbohydrates * 4,
    f: a.macronutrients.fats * 9,
    k: a.calories
  }
  let bcal = {
    p: b.macronutrients.proteins * 4,
    c: b.macronutrients.carbohydrates * 4,
    f: b.macronutrients.fats * 9,
    k: b.calories
  }
  if(b.calories===0){
    return true
  }
  if (snack) {
    return b.calories / a.calories <= 1
  } else if (val === 'Chipotle' || val === 'Pita Pit' || val === 'Starbucks' || val === 'Five Guys') {
    return b.calories / a.calories <= 1
  } else if (strict) {

    return Math.abs(acal.p / acal.k - bcal.p / bcal.k) < .1 &&
      Math.abs(acal.c / acal.k - bcal.c / bcal.k) < .1 &&
      Math.abs(acal.f / acal.k - bcal.f / bcal.k) < .1 &&
      ((b.calories / a.calories) >= (main ? .6 : 0)) &&
      ((b.calories / a.calories) <= 1)
  } else {
    return b.calories / a.calories <= 1
  }
}
