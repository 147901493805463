<template>
    <div class="pc-challenge-list">
        <div v-for="(challengePlan, key) in challengePlans" :key="key">
            <header-simple
                    v-if="challengePlan.planName && showPlanName"
                    class="pc-challenge-list__header"
                    :header-text="challengePlan.planName"
            />
            <hr class="pc-challenge-list__header-line" v-if="challengePlan.planName && showPlanName">
            <!--meal plans-->
            <div v-if="challengePlan.mealPlans.length > 0"
                 class="pc-challenge-list__wrapper"
                 :class="workoutComponentType === 'week-slider'
            && challengePlan.workouts.filter((workout) => {
              return workout.getAvailability()
            }).length > 0
            ? 'pc-challenge-list__wrapper--bottom-gap-lg'
            : ''">
                <div v-for="mealPlan in challengePlan.mealPlans" :key="mealPlan.getId()">
                    <card-challenge
                            class="pc-challenge-list__card"
                            v-if="!mealPlan.isAvailable()"
                            :type="'meal-plan'"
                            :image="mealPlan.getCoverImage()"
                            :key="'meal-plan-card-challenge-' + mealPlan.getId()"
                            :title="mealPlan.getName()"
                            :activation-time="mealPlan.getActivateAt()"
                    />
                    <card-navigation
                            class="pc-challenge-list__card"
                            v-else-if="showCardNavigation"
                            :key="'meal-plan-card-navigation-' + mealPlan.getId()"
                            :header-text="mealPlan.getName()"
                            :image="mealPlan.getCoverImage()"
                            @click="navigateToMealPlan(mealPlan.getId())"
                    />
                    <card-navigation
                            class="pc-challenge-list__card"
                            v-else-if="showCardFeaturedImage"
                            :key="'meal-plan-card-' + mealPlan.getId()"
                            :image="mealPlan.getCoverImage()"
                            :header-text="mealPlan.getName()"
                            @click="navigateToMealPlan(mealPlan.getId())"
                    />
                </div>
            </div>
            <!--activated workouts-->
            <div class="pc-challenge-list__wrapper">
                <div v-for="workout in challengePlan.workouts"
                     :key="workout.id">
                    <card-navigation
                            class="pc-challenge-list__card"
                            v-if="workout.getAvailability() && workoutComponentType === 'card'"
                            :key="'workout-program-card-' + workout.getID()"
                            :header-text="workout.getName()"
                            :image="workout.getBackgroundImage()"
                            @click="navigateToProgram(workout.getID())"
                    />
                    <slider-workout-program
                            class="pc-challenge-list__workout-slider"
                            v-else-if="workout.getAvailability() && workoutComponentType === 'week-slider'"
                            :key="'workouts-list-item-' + workout.getID()"
                            :workout="workout"
                            :page="page"
                            :activeWorkout="activeWorkout"
                            @activeWorkout="setActiveWorkout"
                    />
                </div>
            </div>
            <!--not yet activated workout plans-->
            <div class="pc-challenge-list__wrapper">
                <div v-for="workout in challengePlan.workouts"
                     :key="workout.id">
                    <card-challenge
                            class="pc-challenge-list__card"
                            v-if="!workout.getAvailability()"
                            type="workout"
                            :image="workout.getBackgroundImage()"
                            :key="'workout-program-challenge-' + workout.getID()"
                            :title="workout.getName()"
                            :activation-time="workout.getActiveAt()"
                    />
                </div>
            </div>
            <!--plan pages-->
            <div class="pc-challenge-list__wrapper">
                <div v-for="page in challengePlan.planPages" :key="page.id">
                    <card-challenge
                            class="pc-challenge-list__card"
                            v-if="getProductActivated(page.activate_at)"
                            type="page"
                            :image="page.image"
                            :key="'page-card-challenge-' + page.id"
                            :title="page.name"
                            :activation-time="page.activate_at"
                    />
                    <card-navigation
                            v-else
                            class="pc-challenge-list__card"
                            :key="'product-page-card-' + page.name"
                            :image="page.image"
                            :header-text="page.name"
                            @click="navigateToPage(page.url)"
                    />
                </div>
            </div>
            <hr class="pc-challenge-list__bottom-line" v-if="challengePlan.planName && showPlanName">
        </div>
    </div>
</template>

<script>
import CardChallenge from '../../../../global/cards/CardChallenge'
import CardNavigation from '../../../../global/cards/CardNavigation'
import {showFoodDairy} from '../../../../../includes/TemplateSettings'
import HeaderSimple from '../../../../global/headers/HeaderSimple'
import SliderWorkoutProgram from '../../../../global/sliders/SliderWorkoutProgram'
import lodash from 'lodash'
import moment from 'moment'
import NativeAppMixin from '../../../../../mixins/NativeAppMixin'

export default {
  name: 'CardListChallengePlans',
  props: {
    workoutWidgetType: {
      default: '' // card('card') or any('')
    },
    showPlanName: {
      default: true
    },
    mealPlans: {
      default: []
    },
    workouts: {
      default: []
    },
    planPages: {
      default: []
    }
  },
  mixins: [NativeAppMixin],
  components: {HeaderSimple, CardChallenge, CardNavigation, SliderWorkoutProgram},
  computed: {
    challengeMealPlans: function () {
      return this.mealPlans.filter((mealPlan) => {
        return mealPlan.getPricingPlanType() === 'challenge' || mealPlan.getActivateAt()
      })
    },
    challengeWorkouts: function () {
      return this.workouts.filter((workout) => {
        return workout.getPlanType() === 'challenge' || workout.getActiveAt()
      })
    },
    challengePlanPages: function () {
      return this.planPages.filter((planPage) => {
        return planPage.pricing_plan_type === 'challenge' || planPage.activate_at
      })
    },
    challengePlans: function () {
      return this.getChallengePlans()
    }
  },
  data: function () {
    return {
      showCardFeaturedImage: true,
      showCardNavigation: false,
      activeWorkout: null,
      workoutComponentType: 'card'
    }
  },
  created () {
    this.showCardByType()
  },
  mounted () {
    if (this.workoutWidgetType === 'card') {
      this.workoutComponentType = 'card'
    } else if (
      templateConfig.instance_config &&
        'show_dashboard_week_slider' in templateConfig.instance_config &&
        templateConfig.instance_config.show_dashboard_week_slider === false
    ) {
      this.workoutComponentType = 'card'
    } else {
      this.workoutComponentType = 'week-slider'
    }
    this.$emit('ready')
  },
  methods: {
    getProductActivated (time) {
      return moment.unix(time).isAfter(moment())
    },
    getMealPlansSubscriptions () {
      return this.challengeMealPlans.map(plan => {
        return {
          id: plan.getSubscriptionID(),
          name: plan.getPricingPlanName()
        }
      })
    },
    getWorkoutsSubscriptions () {
      return this.challengeWorkouts.map(plan => {
        return {
          id: plan.getSubscriptionId(),
          name: plan.getPlanName()
        }
      })
    },
    getProductPlanSubscriptions () {
      return this.challengePlanPages.map(plan => {
        return {
          id: plan.subscription_id,
          name: plan.pricing_plan_name
        }
      })
    },
    getChallengePlans () {
      let challengePlans = []

      challengePlans = challengePlans.concat(this.getMealPlansSubscriptions(), this.getWorkoutsSubscriptions(), this.getProductPlanSubscriptions())
      challengePlans = lodash.uniqBy(challengePlans, function (challengePlan) {
        return challengePlan.id
      })

      challengePlans = lodash.mapValues(challengePlans, (challengePlan) => {
        let subscriptionId = challengePlan.id
        let planName = challengePlan.name

        return {
          subscriptionId: subscriptionId,
          planName: planName,
          mealPlans: this.challengeMealPlans.filter((mealPlan) => {
            return mealPlan.getSubscriptionID() === subscriptionId
          }),
          workouts: this.challengeWorkouts.filter((workout) => {
            return workout.getSubscriptionId() === subscriptionId
          }),
          planPages: this.challengePlanPages.filter((planPage) => {
            return planPage.subscription_id === subscriptionId
          })
        }
      })

      return challengePlans
    },
    showCardByType () {
      if (showFoodDairy()) {
        this.showCardFeaturedImage = false
        this.showCardNavigation = true
      } else {
        this.showCardFeaturedImage = true
        this.showCardNavigation = false
      }
    },
    navigateToMealPlan (id) {
      const navigationPath = [this.$appConfig.appUrlList.mealPlanBase, id].join('/')
      if (navigationPath === this.$route.path && Object.entries(this.$route.query).length === 0) {
        return
      }
      this.showLoading()
      // delaying route push to prevent UI breakage visibility
      this.delayedRouterPush({
        path: [this.$appConfig.appUrlList.mealPlanBase, id].join('/')
      })
    },
    navigateToProgram (id) {
      const navigationPath = '/workout-programs'
      if (navigationPath === this.$route.path && this.$route.query.workout && this.$route.query.workout.toString() === id.toString() && Object.entries(this.$route.query).length === 1) {
        return
      }
      this.showLoading()
      // holding router so slick dom breaking is not visible before fade effect
      this.delayedRouterPush({
        path: navigationPath,
        query: { workout: id }
      })
    },
    navigateToPage (url) {
      if (url.startsWith('/page')) {
        this.showLoading()
        this.$router.push({path: url})
      } else {
        this.handleUrlNavigate(url)
      }
    },
    setActiveWorkout (workout) {
      this.activeWorkout = workout
    }
  }
}
</script>

<style scoped>

</style>
