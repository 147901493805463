import defaultLang from './lang'
import carolineLang from './caroline-lang'

let lang = {
  default: defaultLang,
  caroline: carolineLang
}
export const globalLang = defaultLang

export default Object.assign({}, lang.default, lang.caroline)
