<template>
  <!--   page background -->
  <page-secondary
    :header="headerOptions"
    class="pc-profile--diet"
  >
    <page-container class="pc-profile--diet__container">
      <grid-row>
        <grid-col
          v-if="showFavorite"
          md="6"
        >
          <button-secondary-forward
            :text="$t('message[\'general.favorite-meals\']')"
            class="pc-profile--diet__nav-button"
            @click="navigate('favourite-meals')"
          />
        </grid-col>
        <grid-col
          v-if="showIngredientSection"
          md="6"
        >
          <button-secondary-forward
            :text="$t('message[\'general.foods.i.dislike\']')"
            class="pc-profile--diet__nav-button"
            @click="navigate('disliked-ingredients')"
          />
        </grid-col>
        <grid-col
          v-if="showPreferredMeals"
          md="6"
        >
          <button-secondary-forward
            :text="$t('message[\'meal-plan.preferred-meals\']')"
            class="pc-profile--diet__nav-button"
            @click="navigate('preferred-meals')"
          />
        </grid-col>
        <grid-col
          v-if="showMealDislikes"
          md="6"
        >
          <button-secondary-forward
            :text="$t('message[\'meal-plan.disliked-meals\']')"
            class="pc-profile--diet__nav-button"
            @click="navigate('disliked-meals')"
          />
        </grid-col>
      </grid-row>
    </page-container>
  </page-secondary>
</template>

<script>
import BaseComponent from '../../global/base/BaseComponent'
import ProfileService from '../../../../../services/ProfileService'
import IngredientService from '../../../../../services/IngredientService'
import {enableMealDislikesFlag, enableMealFavourites} from '@/includes/TemplateSettings'
import PageSecondary from '../../../../global/pages/PageSecondary'
import PageContainer from '../../../../global/pages/page-sub-components/PageContainer'
import ButtonSecondaryForward from '../../../../global/buttons/ButtonSecondaryForward'
import DashBoardService from '../../../../../services/DashboardService'
import GridRow from '../../../../global/grid/GridRow'
import GridCol from '../../../../global/grid/GridCol'
import headerEventBus from '../../../../../event-buses/headerEventBus'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'PageDiet',
  components: {
    GridCol,
    GridRow,
    PageSecondary,
    PageContainer,
    ButtonSecondaryForward
  },
  extends: BaseComponent,
  data: function () {
    return {
      showPreferredMeals: false,
      showMealDislikes: false,
      showFavorite: false,
      showIngredientSection: false,
      imagesUrl: this.$appConfig.imagesUrl,
      headerOptions: {
        isTransparent: false,
        isFixed: false,
        show: true,
        left: 'previous-emit',
        right: '',
        rightBlink: false,
        modifiers: ['small-title', 'with-bg-color'],
        mainTitle: this.$t('message["profile.dietary-preferences"]'),
        subTitle: ''
      },
      supportEmail: '',
      foodsDontLikeNames: [],
      foodsDontLike: [],
      allIngredients: {}
    }
  },
  mounted () {
    this.setPageClass()
    this.setPageData()
    this.setDietarySectionVisibility()
    if (enableMealDislikesFlag()) {
      this.showMealDislikes = true
    }
    this.showFavorite = enableMealFavourites()
    pageReadyEvent()
    headerEventBus.$on('back-button-click', this.navigatePrevious)
  },
  created () {
    this.setPageTitle(this.$t('message["profile.dietary-preferences"]'))
  },
  beforeDestroy () {
    headerEventBus.$off('back-button-click', this.navigatePrevious)
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
  },
  methods: {
    setDietarySectionVisibility () {
      let dashBoardService = new DashBoardService()
      this.showIngredientSection = dashBoardService.isIngredientDislikesEnabled()
      this.showPreferredMeals = dashBoardService.isPreferredRecipesEnabled()
    },
    async setPageData () {
      const profileService = new ProfileService()
      await profileService.setProfileData().then((res) => {
        this.foodsDontLike = profileService.getExcludedIngrediants()
        if (
          typeof res.data.settings_manager.trainer.support_email !==
          'undefined'
        ) {
          this.supportEmail = res.data.settings_manager.trainer.support_email
        }
      })

      const ingredientService = new IngredientService()
      await ingredientService
        .setIngredients()
        .then((res) => {
          this.allIngredients = ingredientService.getIngredients()
        })
        .finally(() => {
          this.setList()
          this.hideLoading()
        })
    },
    setList () {
      const mAllergies = []

      const ingredientService = new IngredientService()
      this.foodsDontLike.forEach(function (value, index, array) {
        const ingredient = ingredientService.getIngredient(value)
        mAllergies.push(ingredient)
      })

      this.foodsDontLikeNames = mAllergies
    },
    navigate (page, section = null) {
      let query = {}
      if (section) {
        query = {page: page, section: section, loading: '1'}
      } else {
        query = {page: page, loading: '1'}
      }
      this.showLoading()
      let self = this
      setTimeout(function () {
        self.$router.push({
          path: self.$route.path,
          query: query
        })
      }, 500)
    },
    navigatePrevious () {
      this.$router.push({
        path: this.$route.path,
        query: {loading: '0'}
      })
    }
  }
}
</script>
