<template>
  <popup
    @hide="closePopup"
    modal-class="pc-popup-workout-log-confirm-log"
    :visible="visible"
    v-if="visible"
  >
    <popup-title>
      <popup-button-close @click="closePopup"></popup-button-close>
    </popup-title>
    <popup-body class="text-center">
      <popup-content>
        <popup-head>
          <popup-text-heading>{{ title }}</popup-text-heading>
        </popup-head>
        <popup-text-body>
          <icon-completed-filled v-if="state=== 'success'"
                                 size="lg3"
                                 class="pc-popup-workout-log-mark-incomplete__icon-success" />
          <p v-html="description"></p>
        </popup-text-body>
      </popup-content>
      <popup-footer v-if="buttonTextConfirm">
        <button-primary
          class="pc-popup-workout-log-confirm-log__button-submit"
          :text="buttonTextConfirm"
          @click="doAction()"
        />
      </popup-footer>
    </popup-body>
  </popup>
</template>

<script>

import popupAnimationMixin from '../../../../../../mixins/popupAnimationMixin'
import Popup from '../../../../../global/popups/Popup'
import PopupTitle from '../../../../../global/popups/popup-sub-components/PopupTitle'
import PopupButtonClose from '../../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupContent from '../../../../../global/popups/popup-sub-components/PopupContent'
import PopupHead from '../../../../../global/popups/popup-sub-components/PopupHead'
import PopupBody from '../../../../../global/popups/popup-sub-components/PopupBody'
import PopupTextHeading from '../../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupTextBody from '../../../../../global/popups/popup-sub-components/PopupTextBody'
import PopupFooter from '../../../../../global/popups/popup-sub-components/PopupFooter'
import ButtonPrimary from '../../../../../global/buttons/ButtonPrimary'
import ButtonSecondary from '../../../../../global/buttons/ButtonSecondary'
import IconCompletedFilled from '@/components/root/icons/IconCompletedFilled.vue'

export default {
  name: 'PopupWorkoutLogConfirmRemoveExtraSets',
  components: {
    IconCompletedFilled,
    PopupBody,
    ButtonSecondary,
    ButtonPrimary,
    PopupFooter,
    PopupTextBody,
    PopupTextHeading,
    PopupHead,
    PopupContent,
    PopupButtonClose,
    PopupTitle,
    Popup
  },
  mixins: [popupAnimationMixin],
  props: {
    visible: { default: false },
    setIndex: { default: 0 },

  },
  data: function () {
    return {
      imagesUrl: this.$appConfig.imagesUrl,
      state: 'init'
    }
  },
  computed: {
    title: function () {
      if (this.state === 'processing') {
        return this.$t('message[\'workout-log.popup-remove-extra-set-title-processing\']')
      } else if (this.state === 'success') {
        return this.$t('message[\'workout-log.popup-remove-extra-set-title-success\']')
      }
      return this.$t('message[\'workout-log.popup-remove-extra-set-title\']')
    },
    buttonTextConfirm: function () {
      if(this.state === 'processing' || this.state === 'success'){
        return
      }
      return this.$i18n.t('message["workout-log.popup-remove-extra-set-confirm"]')
    },
    description: function () {
      if(this.state === 'processing' || this.state === 'success'){
        return
      }
      return this.$i18n.t('message[\'workout-log.popup-remove-extra-set-description\']')
    }
  },
  methods: {
    doAction () {
      this.$emit('remove-extra-set', this.setIndex)
      this.state = 'processing'
      setTimeout(() => {
        this.state = 'success'
        this.closePopup()
      }, 1000)
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    }

  }
}
</script>
