<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_23952_55)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 10C0.25 4.47715 4.72715 0 10.25 0C15.7728 0 20.25 4.47715 20.25 10C20.25 12.5719 19.2791 14.917 17.6838 16.6888C15.8534 18.7218 13.201 20 10.25 20C7.29904 20 4.64663 18.7218 2.81621 16.6888C1.22091 14.917 0.25 12.5719 0.25 10ZM4.10015 15.1169C5.56761 16.8787 7.77786 18 10.25 18C12.7221 18 14.9324 16.8787 16.3999 15.1169C15.5132 14.3976 14.4021 14 13.25 14H7.25C6.09789 14 4.98678 14.3976 4.10015 15.1169ZM6.25 8C6.25 5.79086 8.04086 4 10.25 4C12.4591 4 14.25 5.79086 14.25 8C14.25 10.2091 12.4591 12 10.25 12C8.04086 12 6.25 10.2091 6.25 8Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_23952_55">
          <rect width="20" height="20" fill="white" transform="translate(0.25)"/>
        </clipPath>
      </defs>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconUserProfile',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-user-profile': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
