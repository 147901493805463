export default class WorkoutProgramSetTypeService {
  name = ''
  id = ''
  exercises = []

  constructor (setType, exercises) {
    this.exercises = exercises
    this.id = setType.id
    this.name = setType.name
  }

  getID () {
    return this.id
  }

  getExercisesCount () {
    return this.exercises.length
  }

  getName () {
    return this.name
  }

  getExercises () {
    return this.exercises
  }
}
