<template>
  <page-secondary :header="headerOptions" class="gc-mood-tracker">
    <!--   page wrapper -->
    <mood-tracker-tab
      :isWeekActive="tabWeekActive"
      :isMonthActive="tabMonthActive"
      @tab-click="changeTabs($event)"
    />
    <page-container class="container">
      <div class="gc-mood-tracker__mood-card">
      <card-mood-tracker
        :showMoodCount="true"
        :moodCountWithFill="true"
        :showAddIcon="false"
        :pageIntergration="isMoodTrackerOnPage"
        :moodCountAwful="moodCount.awful"
        :moodCountCrap="moodCount.crap"
        :moodCountMeh="moodCount.meh"
        :moodCountGood="moodCount.good"
        :moodCountGreat="moodCount.great">
        </card-mood-tracker>
      </div>
    <div class="gc-mood-tracker__moodlist" v-if="moodsListShow">
      <div v-for="(moodsGroup, index) in moodsGroupList" :key="index">
        <div class="gc-mood-tracker__moodlist-title mt-4">
          <text-content :size="'sm2'">{{getDisplayDate(moodsGroup.date)}}</text-content>
        </div>
        <div class="gc-mood-tracker__moodlist mt-3">
          <list-mood-tracker
            v-for="mood in moodsGroup.moods"
            :key="mood.id"
            :moodType="mood.value"
            :description="mood.comment"
            :timeStamp="mood.createdAt"
            @showGeneralPopup="showGeneralPopup(mood)"></list-mood-tracker>
        </div>
      </div>
    </div>
    <div class="gc-mood-tracker__moodlist-empty text-center" v-if="!moodsListShow">
    <text-content :weight="'light'" :size="'sm1'" :lineHeight="'multi'">{{ $t('message["mood-tracker.empty-mood-message"]') }}</text-content>
    </div>
    <pop-up-add-mood
      v-if="uploadPopup.show"
      :visible="uploadPopup.show"
      :enableMoodClickableFeature="enableMoodClickable"
      :type="selectedMoodType"
      :moodId="selectedMoodId"
      :moodComment="selectedMoodComment"
      :isEditablePopup="isEditable"
      @success="getMoods($event)"
      @close="closePopup()"></pop-up-add-mood>
      <popup-basic-mood-tracker
      v-if="actionsPopup.show"
      :visible="actionsPopup.show"
      @showEditPopup="showEditPopup()"
      @showDeletePopup="showDeletePopup()"
      @close="closePopup()"></popup-basic-mood-tracker>
      <popup-delete-mood
      v-if="deletePopup.show"
      :visible="deletePopup.show"
      :moodId="selectedMoodId"
      @deleteSuccess="getMoods($event)"
      @close="closePopup()"></popup-delete-mood>
    </page-container>
  </page-secondary>
</template>

<script>
import BaseComponent from '../global/base/BaseComponent'
import MoodTrackerService from '../../../../services/MoodTrackerService'
import DashBoardService from '../../../../services/DashboardService'
import LoadingBuffer from '../global/widgets/LoadingBuffer'
import pageMixin from '../../../../mixins/pageMixin'
import CardMoodTracker from '../../../global/cards/CardMoodTracker'
import ListMoodTracker from '../../../global/lists/ListMoodTracker'
import TextContent from '../../../root/TextContent'
import PopUpAddMood from '../mood-tracker/page-components/PopupAddMood'
import PopupBasicMoodTracker from '../mood-tracker/page-components/PopupBasicMoodTracker'
import PopupDeleteMood from '../mood-tracker/page-components/PopupDeleteMood'
import MoodTrackerTab from '../mood-tracker/page-components/MoodTrackerTab'
import { mapGetters, mapMutations } from 'vuex'
import MOODS from '../../../../services/enum/MoodTracker'
import moment from 'moment'
import PageSecondary from '../../../global/pages/PageSecondary'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'mood-tracker',
  extends: BaseComponent,
  mixins: [pageMixin],
  components: {
    PageContainer,
    PageSecondary,
    LoadingBuffer,
    CardMoodTracker,
    ListMoodTracker,
    TextContent,
    PopUpAddMood,
    MoodTrackerTab,
    PopupBasicMoodTracker,
    PopupDeleteMood
  },
  data: function () {
    return {
      headerOptions: {
        show: true,
        left: 'previous',
        right: 'add',
        rightDisabled: false,
        modifiers: ['with-bg-color', 'small-title'],
        header: {
          mainTitle: this.$i18n.t('message["mood-tracker.header"]'),
          subTitle: ''
        },
        mainTitle: this.$i18n.t('message["mood-tracker.header"]')
      },
      loading: true,
      pageClass: ['macroapp--page-common'],
      moodsGroupList: [],
      moodCount: {},
      dateRangeType: 'week',
      moodsListShow: true,
      uploadPopup: {
        show: false
      },
      actionsPopup: {
        show: false
      },
      deletePopup: {
        show: false
      },
      enableMoodClickable: false,
      isEditable: false,
      tabWeekActive: true,
      tabMonthActive: false,
      isMoodTrackerOnPage: false,
      selectedMoodType: 0,
      selectedMoodId: null,
      selectedMoodComment: ''
    }
  },
  computed: {
    ...mapGetters({
      getAddButtonClick: 'pageStore/getAddButtonClick',
      getPlanExpiredPopupShow: 'getPlanExpiredPopupShow'
    })
  },
  watch: {
    getAddButtonClick: function () {
      let dashboard = new DashBoardService()
      if (!(dashboard.isEntriesEditable())) {
        if (!(this.getPlanExpiredPopupShow)) {
          this.showSubscriptionExpiredPopup('moodTracker_add_entry')
        }
      } else {
        if (!this.uploadPopup.show) {
          this.enableMoodClickable = true
          this.uploadPopup.show = true
        }
      }
    }
  },
  created () {
    this.setPageTitle(this.$t('message["mood-tracker.header"]'))
  },
  beforeMount () {
    this.getMoods()
  },
  mounted () {
    this.headerOptions.header.mainTitle = this.$i18n.t('message["mood-tracker.header"]')
    this.setPageClass()
    // this.setHeader(this.headerOptions)
    pageReadyEvent()
  },
  methods: {
    ...mapMutations({
      showSubscriptionExpiredPopup: 'showSubscriptionExpiredPopup'
    }),
    showMoodAddPopup () {
      this.enableMoodClickable = true
      this.uploadPopup.show = true
    },
    closePopup () {
      if (this.uploadPopup.show) {
        this.uploadPopup.show = false
        this.isEditable = false
        this.selectedMoodType = 0
        this.selectedMoodComment = null
      } else if (this.actionsPopup.show) {
        this.actionsPopup.show = false
        this.selectedMoodType = 0
        this.selectedMoodComment = null
      } else if (this.deletePopup.show) {
        this.deletePopup.show = false
        this.selectedMoodType = 0
        this.selectedMoodComment = null
      }
    },
    changeTabs (event) {
      if (event.tab === 'week') {
        this.tabWeekActive = true
        this.tabMonthActive = false
      } else if (event.tab === 'month') {
        this.tabMonthActive = true
        this.tabWeekActive = false
      }
      this.toggleDateRange(event.tab)
    },
    toggleDateRange (dateRangeType) {
      this.dateRangeType = dateRangeType
      this.getMoods()
    },
    getMoods (event = false) {
      if (!event.isActiveLoadingBuffer) {
        this.showLoading()
      }
      this.moodsGroupList = []
      let dateRange

      let moodTrackerService = new MoodTrackerService()
      if (this.dateRangeType === 'week') {
        dateRange = moodTrackerService.getWeekDateRange()
      } else if (this.dateRangeType === 'month') {
        dateRange = moodTrackerService.getMonthDateRange()
      }

      moodTrackerService.getDataByDateRange(dateRange)
        .then((response) => {
          if (response.length === 0) {
            this.moodsListShow = false
            this.setMoodsCounts(response)
            this.hideLoading()
          } else {
            this.moodsGroupList = this.setMoodList(response)
            this.setMoodsCounts(response)
            this.moodsListShow = true
            this.hideLoading()
          }
        }).catch((error) => {
          console.log('error', error)
        })
    },
    setMoodList (moods) {
      let headingDates = []
      let moodsObject = []

      moods.forEach(mood => {
        let createdDate = moment(mood.createdAt).format('YYYY-MM-DD')
        headingDates.push(createdDate)
      })
      let uniqueArray = headingDates.filter((item, pos) => {
        return headingDates.indexOf(item) === pos
      })

      uniqueArray.forEach(day => {
        let moodsArray = moods.filter(mood => {
          let createdDate = moment(mood.createdAt).format('YYYY-MM-DD')
          return day === createdDate
        })
        moodsObject.push({
          date: day,
          moods: moodsArray
        })
      })

      return moodsObject
    },
    getDisplayDate (date) {
      let today = moment()
      let currentDate = moment(date)
      let dateDiff = today.diff(currentDate, 'days')
      if (dateDiff === 0) {
        return this.$i18n.t('message["general.today"]')
      } else if (dateDiff === 1) {
        return this.$i18n.t('message["general.yesterday"]')
      }
      return currentDate.format('ll')
    },
    setMoodsCounts (moods) {
      this.moodCount.awful = this.getMoodCountByType(moods, MOODS.AWFUL.TYPE)
      this.moodCount.crap = this.getMoodCountByType(moods, MOODS.CRAP.TYPE)
      this.moodCount.meh = this.getMoodCountByType(moods, MOODS.MEH.TYPE)
      this.moodCount.good = this.getMoodCountByType(moods, MOODS.GOOD.TYPE)
      this.moodCount.great = this.getMoodCountByType(moods, MOODS.GREAT.TYPE)
    },
    getMoodCountByType (moods, type) {
      return moods.filter(mood => {
        return mood.value === type
      }).length
    },
    showGeneralPopup (mood) {
      this.selectedMoodType = mood.value
      this.selectedMoodId = mood.id
      this.selectedMoodComment = (mood.comment === 'null') ? '' : mood.comment
      this.actionsPopup.show = true
    },
    showEditPopup () {
      this.actionsPopup.show = false
      this.enableMoodClickable = true
      this.isEditable = true
      this.uploadPopup.show = true
    },
    showDeletePopup () {
      this.actionsPopup.show = false
      this.deletePopup.show = true
    }
  }

}
</script>
