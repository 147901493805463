<template>
  <date-picker class="gc-input-date-picker"
               :locale="locale"
               :masks="masks"
               :popover="{ placement: 'bottom',positionFixed : false }"
               v-model="date"
               style="width: 100%" >
    <template v-slot="{ togglePopover }">
      <input-text
        @click="togglePopover()"
        :value="value"
        :error-message="error"
        readonly
      />
    </template>
  </date-picker>
</template>

<script>
import InputSelect from './InputSelect'
import InputSingleSelect from './InputSingleSelect'
import InputText from './TextInput'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import moment from 'moment'
import LanguageService from '../../../services/LanguageService'

export default {
  components: {
    InputText,
    InputSelect,
    InputSingleSelect,
    DatePicker
  },
  name: 'InputDatePicker',
  props: {
    value: {
      default: ''
    },
    error: {
      default: ''
    }
  },
  data: function () {
    return {
      date: moment().format('YYYY-MM-DD'),
      locale: 'en',
      format: 'YYYY-MM-DD',
      masks: {
        input: ['YYYY-MM-DD']
      }
    }
  },
  watch: {

    value: {
      handler: function () {
        if (this.value) {
          this.date = moment(this.value).locale('en').format('YYYY-MM-DD')
        }
      },
      immediate: true
    },
    date: function (val) {
      const dateStr = moment(val).locale('en').format('YYYY-MM-DD')
      if (moment(this.value).locale('en').format('YYYY-MM-DD') !== dateStr) {
        this.$emit('input', dateStr) // emit date of YYYY-MM-DD
        this.$emit('change', dateStr) // emit date of YYYY-MM-DD
      }
    }
  },
  beforeMount () {
    this.months = []
    let month = 1
    for (month = 1; month <= 12; month++) {
      this.months.push({
        id: month,
        text: this.$i18n.t('message["calendar.month-' + month + '-short"]')
      })
    }
    let lang = new LanguageService()
    this.locale = lang.getAppLang()
  }
}
</script>
