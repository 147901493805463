export default class UserAlreadyRegisteredError extends Error {
  constructor (email) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super()

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UserAlreadyRegisteredError)
    }

    this.name = 'UserAlreadyRegisteredError'
    // Custom debugging information
    this.email = email
  }
}
