<template>
    <div :class="componentClasses" @click="clicked(id)">
        <div class="gc-button-check__text-container">
            <text-content :class="textClass" :line-height="'multi'" :size="'sm4'" :weight="'extra-bold'"> {{ text }}</text-content>
            <text-content :size="'sm1'" :line-height="'multi'" v-if="hasSubText"> {{ subText }}</text-content>
        </div>
        <div class="gc-button-check__circle-container">
            <div class="gc-button-check__circle">
                <icon-tick/>
            </div>
        </div>
    </div>
</template>

<script>
import TextContent from '../../root/TextContent'
import IconTick from '../../root/icons/IconTick'

export default {
  name: 'ButtonCheck',
  components: {
    TextContent,
    IconTick
  },
  props: {
    text: {
      default: ''
    },
    id: {
      default: ''
    },
    subText: {
      default: ''
    },
    disabled: {
      default: false
    },
    selected: {
      default: false
    }
  },
  computed: {
    hasSubText: function () {
      return this.subText.replace(/\s/g, '') !== ''
    },
    textClass: function () {
      return {
        'gc-button-check__text': true,
        'gc-button-check__text--with-sub-text': this.hasSubText
      }
    },
    componentClasses: function () {
      return {
        'gc-button-check': true,
        'gc-button-check--disabled': this.disabled,
        'gc-button-check--with-sub-text': this.hasSubText,
        'gc-button-check--selected': this.selected
      }
    }
  },
  methods: {
    clicked (id) {
      if (!this.disabled) {
        this.$emit('click', id)
        if (!this.selected) {
          this.selected = true
        } else {
          this.selected = false
        }
      }
    }
  }
}
</script>
