<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path
          d="M12.5008 25C19.3932 25 25.0005 19.3925 25.0005 12.4999C25.0005 5.60755 19.3932 0 12.5008 0C5.60841 0 0.000976562 5.60755 0.000976562 12.4999C0.000976562 19.3925 5.60841 25 12.5008 25ZM12.5008 1.71115C18.4497 1.71115 23.2894 6.55096 23.2895 12.4999C23.2895 18.4489 18.4498 23.2887 12.5008 23.2889C6.55194 23.2887 1.71224 18.4489 1.71224 12.4998C1.71224 6.55108 6.55194 1.71115 12.5008 1.71115Z"
          fill="black"/>
        <path
          d="M10.8212 17.8533C11.1553 18.1873 11.6971 18.1872 12.0311 17.8533C12.3653 17.519 12.3653 16.9774 12.031 16.6431L8.74328 13.3556L18.2638 13.3546C18.7363 13.3545 19.1192 12.9716 19.1192 12.4988C19.1191 12.0263 18.7362 11.6435 18.2637 11.6435L8.74282 11.6444L12.0313 8.35614C12.3654 8.02201 12.3654 7.48015 12.0313 7.14613C11.8642 6.97913 11.6453 6.89551 11.4262 6.89551C11.2073 6.89551 10.9884 6.97913 10.8213 7.14602L6.0724 11.8948C5.9119 12.0552 5.82178 12.2727 5.82178 12.4998C5.82189 12.7268 5.91201 12.9442 6.07252 13.1049L10.8212 17.8533Z"
          fill="black"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="25" height="25" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconBackwardArrowRounded',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-backward-arrow-rounded': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>
