<template>
  <action-meal-swap
  :state="state"
  :showText="showText"
  :limitText="limitText"
  v-on="$listeners"
  @clicked="clicked()"></action-meal-swap>
</template>

<script>
import ActionMealSwap from '../../../global/actions/ActionMealSwap.vue'

export default {
  name: 'CardMealActionSwap',
  components: {
    ActionMealSwap
  },
  props:
    {
      state: {
        type: String,
        default: ''
      },
      showText: {
        type: Boolean,
        default: true
      },
      limitText: {
        type: Boolean,
        default: false

      }
    },
  computed: {
    componentClasses: function () {
      return {
        'gc-card-meal__action': true,
        'gc-card-meal__action-swap': true,
        'gc-card-meal__action-disabled': this.state === 'disabled',
        'gc-card-meal__action-onTap': this.state === 'onTap',
        'gc-card-meal__action-active': this.state === 'active'
      }
    },
    text: function () {
      let text = this.$i18n.t('message["meal-plan.swap"]')
      if (this.state === 'active') {
        return this.$i18n.t('message["meal-plan.swapping"]')
      }
      if (this.limitText) {
        text = text.substring(0, 2) + '...'
      }
      return text
    }
  },
  methods: {
    clicked () {
      this.$emit('clicked')
    }
  }
}
</script>
<style scoped>
</style>
