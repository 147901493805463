<template>
  <popup
    @hide="closePopup"
    ref="mealSwapDislikesModal"
    :visible="visible"
    modal-class="gc-popup gc-popup-swap-dislikes"
    v-if="visible"
    :no-close-on-backdrop="noCloseOnBackdrop"
  >
    <popup-title>
      <popup-button-close :style="{'visibility' : noCloseOnBackdrop? 'hidden' :'visible' }" @hide="closePopup"/>
    </popup-title>
    <popup-body>
      <popup-head class="text-center">
        <popup-text-heading v-if="status==='init'">{{ title }}</popup-text-heading>
        <loading-buffer v-else-if="status==='loading'" :height="'75'"></loading-buffer>
      </popup-head>
      <popup-content>
        <text-body-small v-if="status==='init'">
          {{otherMealsText}}
        </text-body-small>
        <div v-else-if="status==='success'" class="text-center">
          <div class="text-center"></div>
          <p
            class="text-center info-modal__subtitle mb-2"
          >{{successMsg}}</p>

          <icon-complete-filled :size="'lg3'"  />
        </div>
        <div v-else-if="status==='error'">
          <div class="text-center"></div>

          <p class="text-center info-modal__subtitle mb-2">{{errorMessage}}</p>
        </div>

      </popup-content>
      <popup-footer v-if="status==='init'">
        <button-primary class="gc-popup-swap-dislikes__button-confirmation"
                        v-on:click="swapDislikes()" :text="confirmButtonText"></button-primary>
        <button-secondary v-on:click="closePopup()" :text="noButtonText"/>
      </popup-footer>
    </popup-body>
  </popup>
</template>

<script>
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import BaseComponent from '../../global/base/BaseComponent'
import DefineInclude from '../../../../../DefineInclude'
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import {mapMutations} from 'vuex'
import Popup from '../../../../global/popups/Popup'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import TextBodySmall from '../../../../global/typography/TextBodySmall'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupFooter from '../../../../global/popups/popup-sub-components/PopupFooter'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import ButtonSecondary from '../../../../global/buttons/ButtonSecondary'
import IconCompleteFilled from '../../../../root/icons/IconCompleteFilled'

export default {
  name: 'SwapDislikes',
  props: {
    data: {
      required: true
    },
    visible: {
      required: true
    },
    mealPlan: {
      required: true
    }
  },
  mixins: [popupAnimationMixin],
  components: {
    IconCompleteFilled,
    ButtonSecondary,
    ButtonPrimary,
    PopupFooter,
    PopupContent,
    TextBodySmall,
    PopupTextHeading,
    PopupHead,
    PopupBody,
    PopupButtonClose,
    PopupTitle,
    Popup,
    LoadingBuffer
  },
  extends: BaseComponent,
  data: function () {
    return {
      successImage: DefineInclude.imagesUrl + 'swap-success.svg',
      title: '',
      status: 'loading',
      loading: true,
      otherMealsText: '',
      confirmButtonText: '',
      noButtonText: '',
      successMsg: 'success',
      errorMessage: 'Error'
    }
  },
  created () {
    this.status = 'init'
    this.title = this.$i18n.t('message["swap.other-dislikes-title"]')
    this.otherMealsText = this.swapOtherMealsText
    this.confirmButtonText = this.$i18n.tc('message["swap.other-dislikes-confirm-button"]', this.data.otherMeals.length)
    this.noButtonText = this.$i18n.tc('message["swap.other-dislikes-decline-button"]', this.data.otherMeals.length)
  },
  computed: {
    noCloseOnBackdrop: function () {
      return this.status === 'success' || this.status === 'loading'
    },
    swapOtherMealsText: function () {
      let mealText = this.shorten(this.data.ingredients[0].name)
      if (this.data.ingredients.length > 1) {
        const shortenIng = this.data.ingredients.map(ing => this.shorten(ing))
        mealText = shortenIng.reduce(
          (accumulator, currentValue, index) => (index === 0) ? currentValue : accumulator + ', ' + currentValue,
          ''
        )
      }

      return this.$i18n.tc('message["swap.other-dislikes-message"]', this.data.otherMeals.length, {
        count: this.data.otherMeals.length,
        meals: mealText
      })
    }
  },
  methods: {
    ...mapMutations({
      updateMealSwapStatus: 'mealSwapStore/updateMealSwapStatus'
    }),
    swapDislikes () {
      this.status = 'loading'
      this.title = 'Swapping'
      let promises = []
      let mealAndRefIds = []
      for (let i = 0; i < this.data.otherMeals.length; i++) {
        promises.push(this.mealPlan.swapMeal(this.data.otherMeals[i]))
        mealAndRefIds.push({mid: this.data.otherMeals[i], rmid: this.data.refMealIds[i]})
      }
      this.logEvent('SmartDislikes.SwappedAllMeals', {
        no_of_other_meals: promises.length
      })
      Promise.all(promises)
        .then((results) => {
          let plural = (this.data.otherMeals.length > 1 ? 's' : '')

          setTimeout(() => {
            this.status = 'success'
            this.title = this.$i18n.t('message["swap.other-dislikes-success-title"]', [this.data.otherMeals.length, plural])
            this.successMsg = this.$i18n.t('message["swap.other-dislikes-success-message"]', [plural])
            this.updateMealSwapStatus(mealAndRefIds)
          }, 1500)

          setTimeout(() => {
            this.$emit('meal-swap-success')
            this.closePopup(null, () => {
              if (this.params.meal) {
                this.$router.push({
                  path: [DefineInclude.appUrlList.mealPlanBase, this.$route.params.mid].join('/'),
                  query: {
                    day: this.params.day
                  }
                })
              }
            })
          }, 3000)
        }).catch(() => {
          this.title = this.$i18n.t('message["swap.other-dislikes-error-title"]')
          this.status = 'error'
          this.errorMessage = this.$i18n.t('message["swap.other-dislikes-error-message"]')
        })
    },
    closePopup (e, callback) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('hide-swap-dislikes-popup')
        if (callback) {
          callback()
        }
      })
    },
    shorten (name) {
      return name.indexOf('(') > -1 ? name.substring(0, name.indexOf('(') - 1).trim() : name.trim()
    }
  }
}
</script>
