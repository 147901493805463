<template>
  <div>
    <div
      v-if="!log"
      class="container"
    >
      <loading-buffer height="300" />
    </div>
    <transition name="fade">
      <div v-if="log">
        <!-- Log Food Diary Card -->
        <grid-row>
          <grid-col>
            <transition name="fade">
              <card-food-diary-main
                :calories="calories"
                :macro-nutrients="macroNutrients"
                class="pc-food-dairy-home__log-card"
              />
            </transition>
          </grid-col>
        </grid-row>

        <transition-group name="fade">
          <grid-row
            key="row-cards"
            class="pc-food-dairy-home__log-card-with-macros-row"
          >
            <grid-col
              v-for="meal in sortMealsByDateANdTime(log.logged_meals)"
              :key="'col-cards' + meal.id"
              class="pc-food-dairy-home__log-card-with-macros-col"
              md="6"
            >
              <card-meal-remove-with-macros
                v-if="log && meal.recipes"
                :id="meal.id|| 0"
                :key="meal.id + log._id"
                :show-net-carbs="log.required.macronutrients.show_net_carbs"
                :meal-plan-id="0"
                :meal-type="meal.meal_type"
                :restaurant-name="getRestaurantName(meal)"
                :recipes="meal.recipes"
                :name="meal.name"
                :type="getMealType(meal)"
                :day-id="meal.id || 0"
                :log-i-d="log._id|| 0"
                :image="meal.image ||[]"
                :macro-nutrients="meal.macronutrients"
                :calories="meal.calories"
                :date="log.logged_date"
                :meal="meal"
                @meal-removed="loadLogData(log._id, true)"
              />
            </grid-col>
          </grid-row>
        </transition-group>
      </div>
    </transition>
  </div>
</template>

<script>
import FoodDiaryService from '../../../../../services/FoodDiaryService'
import DashBoardService from '../../../../../services/DashboardService'
import { mapGetters, mapMutations } from 'vuex'
import CardFoodDiaryMain from '../../../../global/cards/CardFoodDiaryMain'
import CardMealRemoveWithMacros from './CardMealRemoveWithMacros'
import lodash from 'lodash'
import headerEventBus from '../../../../../event-buses/headerEventBus'
import GridRow from '../../../../global/grid/GridRow'
import GridCol from '../../../../global/grid/GridCol'
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import NutritionService from '../../../../../services/nutrition/NutritionService'

export default {
  name: 'FoodDiaryDay',
  components: {
    GridRow,
    GridCol,
    CardMealRemoveWithMacros,
    CardFoodDiaryMain,
    LoadingBuffer
  },
  props: {
    foodDiaryDayID: {
      required: true,
      type: String
    },
    date: {
      required: true,
      type: String
    },
    days: {
      required: true,
      type: Array
    }
  },
  data: function () {
    return {
      editButtonClicked: false,
      service: null,

      logPopup: {
        visible: false
      },
      log: null,
      state: 'loaded',
      macroNutrients: [],
      calories: {
        name: 'calories',
        quantity: 0,
        maxQuantity: 0,
        unit: this.$i18n.t("message['food-diary.unit-cal']")
      }
    }
  },
  computed: {
    ...mapGetters({
      getAddButtonClick: 'pageStore/getAddButtonClick',
      getAddPopupShown: 'foodDiaryStore/getAddPopupShown',

      getPlanExpiredPopupShow: 'getPlanExpiredPopupShow'
    })
  },
  watch: {
    log: {
      deep: true,
      handler (value) {
        let loggedMeals = []
        if (value) {
          loggedMeals = value.logged_meals || []
        } else {
          return {}
        }

        let totalMacros = this.service.getCurrentNutrientTotalsOfMeals(
          loggedMeals
        )

        this.macroNutrients = [
          {
            name: this.$i18n.t("message['general.proteins']"),
            quantity: totalMacros.macronutrients.proteins,
            maxQuantity: value.required.macronutrients.proteins,
            unit: '%'
          },
          {
            name: this.$i18n.t("message['general.fats']"),
            quantity: totalMacros.macronutrients.fats,
            maxQuantity: value.required.macronutrients.fats,
            unit: '%'
          },
          {
            name: this.$i18n.t("message['general.carbs']"),
            quantity: totalMacros.macronutrients.carbohydrates,
            maxQuantity: value.required.macronutrients.carbohydrates,
            unit: '%'
          }
        ]
        if (this.log.required.macronutrients.show_net_carbs) {
          this.macroNutrients.push({
            name: this.$i18n.t("message['general.carbs-net']"),
            quantity: totalMacros.macronutrients.net_carbohydrates,
            maxQuantity: value.required.macronutrients.net_carbohydrates,
            unit: '%'
          })
        }
        this.calories = {
          name: this.$i18n.tc("message['general.calories']", 2),
          quantity: totalMacros.calories,
          maxQuantity: value.required.calories,
          unit: this.$i18n.t("message['food-diary.unit-cal']")
        }
      }
    },
    date: {
      immediate: true,
      handler: function () {
        this.log = null
        this.service = new FoodDiaryService()
        let id = this.foodDiaryDayID
        this.loadLogData(id)
      }
    }
  },
  mounted () {
    headerEventBus.$on('add-button-click', this.editLog)
    this.service = new FoodDiaryService()
    if (this.getAddPopupShown) {
      this.logPopup.visible = true
    }
  },
  beforeDestroy () {
    headerEventBus.$off('add-button-click', this.editLog)
  },
  methods: {
    getRestaurantName(meal){
      if(meal.meta && meal.meta.restaurantName){
        return meal.meta.restaurantName
      }
      return ''
    },
    getMealType(meal){
      if(this.getRestaurantName(meal)){
        return {
          name: this.$i18n.t('message[\'food-diary.dine-out.meal-type\']'),
          id :0
        }
      }
      return  meal.type|| {}
    },
    editLog () {
      if (this.editButtonClicked) {
        return
      }
      this.editButtonClicked = true
      let dashboard = new DashBoardService()
      if (!dashboard.isEntriesEditable()) {
        if (!this.getPlanExpiredPopupShow) {
          this.showSubscriptionExpiredPopup('foodDiary_add_entry')
        }
      } else {
        this.goToLogPage()
      }
    },
    goToLogPage () {
      if (!this.log) {
        this.editButtonClicked = false
      }
      this.setEditableMealLog(this.log)
      this.setLastPage('food-diary-list')
      this.setRouteTransition({ name: 'up-slide-fade', mode: 'out-in' })
      this.$router.push({
        path: '/meal-plans',
        query: { page: 'food-diary-log-meal', loading: '0' }
      })
    },
    ...mapMutations({
      setLastPage: 'foodDiaryStore/setLastPage',
      showSubscriptionExpiredPopup: 'showSubscriptionExpiredPopup',
      setRouteTransition: 'setRouteTransition',
      setEditableMealLog: 'foodDiaryStore/setEditableMealLog'
    }),
    getMinimumCalorieDay () {
      let sortedDays = lodash.sortBy(this.days, ['_calories'])
      return sortedDays[0] || null
    },
    setRequiredNutrients () {
      const mealPlans = new NutritionService()
      const mealPlan = mealPlans.getLatestMealPlan()

      return {
        calories: mealPlan.getAverageCaloriesOfDays(),
        macronutrients: mealPlan.getAverageMacroNutrientsOfDays()
      }
    },
    loadLogData (id, emit = false) {
      this.state = 'loading'

      if (!id) {
        this.makeEmptyLog()
        this.hideLoading()
        return
      }

      this.log = null
      this.service
        .getLogByID(id)
        .then((res) => {
          let log = res.data.log
          log.logged_meals = log.logged_meals.map((meal) => {
            if (meal.meal_type === 'custom' || meal.mealType === 'dine-out') {
              let service = new FoodDiaryService()
              return Object.assign(
                service.addNutrientsOfRecipes(meal.recipes),
                meal
              )
            }
            return meal
          })
          if (emit) {
            this.$emit('updated', log)
          }
          this.$nextTick(() => {
            this.log = log
          })
        })
        .finally(() => {
          this.state = 'loaded'
          this.hideLoading()
        })
    },
    hideLogPopup () {
      this.logPopup.visible = false
    },
    makeEmptyLog () {
      this.log = this.service.emptyLog()
      this.log.logged_date = this.date
      this.log.required = this.setRequiredNutrients()
    },
    sortMealsByDateANdTime (meals) {
      return lodash.orderBy(meals, ['date', 'time'], ['asc', 'asc'])
    },
    setLog (log) {
      this.loadLogData(log._id)
      this.log = log
      setTimeout(() => {
        this.$emit('updated', log)
      }, 3200)
    }
  }
}
</script>
