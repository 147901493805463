<template>
  <component v-if="currentComponent!==''" v-bind:is="currentComponent" v-bind="currentComponentData"></component>
</template>

<script>
import CartButton from './cart-buttons/CartButton'
import CartButtonV2 from './cart-buttons/CartButtonV2'
import widgetWrapperConfigMixin from '../../../../../mixins/widgetWrapperConfigMixin'

export default {
  name: 'CartButton',
  props: ['type', 'dayid', 'pageConfig'],
  mixins: [widgetWrapperConfigMixin],
  data: function () {
    return {
      components: {
        design1: CartButton,
        design2: CartButtonV2
      },
      pageType: 'widget',
      name: 'cart-button'
    }
  }
}
</script>

<style scoped>

</style>
