class TimeFormatHelper {
  secToMinSec = (seconds) => {
    const mm = Math.floor(seconds / 60)
    const ss = Math.floor(seconds % 60)

    return {
      time: `${mm.toString().padStart(2, '0')}:${ss
        .toString()
        .padStart(2, '0')}`,
      seconds: ss,
      minutes: mm
    }
  }
}

export default new TimeFormatHelper()
