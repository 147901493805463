<template>
  <popup
    v-if="visible"
    ref="mealFavoriteDislikedMealModal"
    :visible="visible"
    @hide="closePopup"
    class="gc-popup-dislike-favorite-meal"
    :no-close-on-backdrop="noCloseOnBackdrop"
  >
    <popup-title>
      <popup-button-close :style="{'visibility' : noCloseOnBackdrop? 'hidden' :'visible' }" @hide="closePopup()"/>
    </popup-title>
    <popup-body class="gc-popup-dislike-favorite-meal__body text-center">
      <popup-head class="text-center">
        <popup-text-heading>{{ title }}</popup-text-heading>
      </popup-head>
      <popup-content>
        <popup-text-body
          class="text-center info-modal__subtitle mb-2"
        >{{ $t('message["dislike-favorite-meal.description"]') }}
        </popup-text-body>
      </popup-content>
      <popup-footer>
        <button-primary @click="dislikeMeal()" :text="confirmButtonText"></button-primary>
      </popup-footer>
    </popup-body>

  </popup>
</template>

<script>
import LoadingBuffer from '../../layout/template-1/global/widgets/LoadingBuffer'
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import Popup from './Popup.vue'
import PopupBody from './popup-sub-components/PopupBody'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupContent from './popup-sub-components/PopupContent'
import PopupFloatingFooter from './popup-sub-components/PopupFloatingFooter'
import PopupFooter from './popup-sub-components/PopupFooter'
import PopupTextBody from './popup-sub-components/PopupTextBody'
import PopupHead from './popup-sub-components/PopupHead'
import PopupTextHeading from './popup-sub-components/PopupTextHeading'
import PopupTextSubHeading from './popup-sub-components/PopupTextSubHeading'
import PopupTitle from './popup-sub-components/PopupTitle'
import ButtonPrimary from '../buttons/ButtonPrimary'
import ButtonSecondary from '../buttons/ButtonSecondary'
import BaseComponent from '../../layout/template-1/global/base/BaseComponent'
import IconCompleteFilled from '../../root/icons/IconCompleteFilled'

export default {
  name: 'PopupDislikeFavoriteMeal',
  components: {
    LoadingBuffer,
    Popup,
    PopupBody,
    PopupButtonClose,
    PopupContent,
    PopupFloatingFooter,
    PopupFooter,
    PopupTextBody,
    PopupHead,
    PopupTextHeading,
    PopupTextSubHeading,
    PopupTitle,
    ButtonSecondary,
    ButtonPrimary,
    IconCompleteFilled
  },
  mixins: [popupAnimationMixin],
  extends: BaseComponent,
  props: {
    visible: {
      default: false,
      type: Boolean
    }
  },
  data: function () {
    return {
      title: this.$i18n.t('message["dislike-favorite-meal.header"]'),
      loading: true,
      confirmButtonText: this.$i18n.t('message["workout-log.mark-incomplete-confirm"]'),
      successMsg: 'success',
      errorMessage: 'Error'
    }
  },
  methods: {
    async dislikeMeal () {
      this.$emit('submit')
      this.closePopup()
    },
    closePopup (e, callback) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('hide-popup')
        if (callback) {
          callback()
        }
      })
    }

  }
}
</script>
