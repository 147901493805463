<template>
  <div class="gc-card-member-large">
    <badge-choice class="gc-card-member-large__badge">
      <text-body-extra-small :weight="'extra-bold'" :line-height="'multi'">{{labelText}}</text-body-extra-small>
    </badge-choice>
    <div class="gc-card-member-large__user-info">
      <base-image class="gc-card-member-large__avatar" border-radius="circle" :src="avatar"></base-image>
      <div class="gc-card-member-large__info">
        <text-body-regular class="gc-card-member-large__name" :line-height="'multi'" :weight="'extra-bold'">{{name}}
        </text-body-regular>
        <text-body-small class="gc-card-member-large__email" :line-height="'multi'">({{email}})</text-body-small>
      </div>
    </div>
    <div class="gc-card-member-large__extra-info">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import BadgeChoice from '../badges/BadgeChoice'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import BaseImage from '../../root/Image'
import TextBodyRegular from '../typography/TextBodyRegular'
import TextBodySmall from '../typography/TextBodySmall'

export default {
  name: 'CardMemberLarge',
  props: {
    avatar: {
      default: ''
    },
    name: {
      default: ''
    },
    email: {
      default: ''
    },
    labelText: {
      default: ''
    }
  },
  components: {
    BadgeChoice,
    TextBodyExtraSmall,
    BaseImage,
    TextBodyRegular,
    TextBodySmall
  }
}
</script>

<style scoped>

</style>
