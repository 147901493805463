<template>
  <popup
      :visible="visible"
      @hide="closePopup"
      modal-class="gc-popup gc-popup-dynamic-message"
      v-if="visible"
    >
    <popup-title>
      <popup-button-close @click="closePopup"></popup-button-close>
    </popup-title>
    <popup-body class="text-center">
      <popup-content>
        <popup-head>
          <div v-if="content.image" class="gc-popup-dynamic-message__featured-image">
            <img  :src="content.image" alt="">
          </div>
        </popup-head>
         <popup-text-heading  class="gc-popup-dynamic-message__title" v-if="content.title">
          <div v-html="content.title"></div>
        </popup-text-heading>
        <popup-text-body class="gc-popup-dynamic-message__description">
          <text-content :size="'sm2'" :weight="''" :lineHeight="'multi'"
                        v-if="content.content"
                        >
            <div v-html="content.content"></div>
          </text-content>
        </popup-text-body>
        <div>
        </div>
      </popup-content>
       <popup-footer>
        <button-primary class="gc-popup-error__btn-wrapper" :text="$i18n.t('message[\'general.back\']' )"  @click="closePopup()" />
      </popup-footer>
    </popup-body>
    </popup>
</template>

<script>

import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import { mapGetters, mapMutations } from 'vuex'
import ButtonPrimary from '../buttons/ButtonPrimary'
import PopupTextBody from './popup-sub-components/PopupTextBody'
import PopupFooter from './popup-sub-components/PopupFooter'
import PopupTextHeading from './popup-sub-components/PopupTextHeading'
import PopupHead from './popup-sub-components/PopupHead'
import PopupContent from './popup-sub-components/PopupContent'
import PopupBody from './popup-sub-components/PopupBody'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupTitle from './popup-sub-components/PopupTitle'
import Popup from './Popup'
import TextContent from '../../root/TextContent'

export default {
  name: 'PopupNotFoundError',
  mixins: [popupAnimationMixin],
  components: {
    ButtonPrimary,
    PopupTextBody,
    PopupTextHeading,
    PopupHead,
    PopupContent,
    PopupFooter,
    PopupBody,
    PopupButtonClose,
    PopupTitle,
    Popup,
    TextContent
  },
  data: function () {
    return {
      visible: false
    }
  },
  computed: {
    ...mapGetters({
      content: 'dynamicContentPopupStore/getInfo',
      slug: 'dynamicContentPopupStore/getSlug'
    })
  },
  mounted () {
    this.hideLoading()
    this.waitUntilOtherPopupsAreClosed()
    this.logEvent('DynamicMessageShown', {'slug': this.slug})
  },
  methods: {
    waitUntilOtherPopupsAreClosed () {
      let interval = null
      const ele = document.querySelector('.gc-popup__dialog')
      if (!ele) {
        this.visible = true
      }
      interval = setInterval(() => {
        const ele = document.querySelector('.gc-popup__dialog')
        if (!ele) {
          clearInterval(interval)
          this.visible = true
        }
      }, 500)
    },
    ...mapMutations({
      hidePopup: 'dynamicContentPopupStore/hidePopup'
    }),
    closePopup () {
      this.closeAnimatedPopup(() => {
        this.$emit('close')
        this.hidePopup()
        const event = new CustomEvent('dynamic-content-popup-closed', { })

        document.dispatchEvent(event)
      })
    }
  }
}
</script>

<style scoped>
</style>
