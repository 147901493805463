import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    /** popup visibility */
    visible: false,
    /** popup title */
    title: '',
    /** popup instruction-popups array */
    placeHolderText: '',
    flag: '',
    eventName: ''
  },
  mutations: {
    /** to show the popup  */
    show (state) {
      state.visible = true
    },
    /** to hide the popup */
    hide (state) {
      state.visible = false
    },
    /** set the pop up content */
    setContent (state, data) {
      state.title = data.title || ''
      state.flag = data.flag || ''
      state.eventName = data.eventName || ''
      state.placeHolderText = data.placeHolderText || ''
    },
    /** reset the popup data */
    reset (state) {
      state.title = ''
      state.flag = ''
      state.placeHolderText = ''
      state.eventName = ''
      state.visible = false
    }

  },
  actions: {},
  getters: {
    /** get the popup visibility */
    visible (state) {
      return state.visible
    },
    /** get the popup flag */
    flag (state) {
      return state.flag
    },
    /** get the popup title */
    title (state) {
      return state.title
    },
    /** get the popup placeHolderText */
    placeHolderText (state) {
      return state.placeHolderText
    },
    /** get the popup eventName */
    eventName (state) {
      return state.eventName
    }
  }
}
