<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.9745 0.0792157L0.388583 11.419C0.160677 11.5335 0.012599 11.7623 0.0016302 12.017C-0.00933855 12.2718 0.118396 12.5125 0.335615 12.6461L5.62574 15.9016V23.2969C5.62574 23.5995 5.81929 23.868 6.1063 23.9638C6.39337 24.0596 6.70935 23.961 6.89104 23.7191L10.5065 18.9052L18.6161 23.8957C18.8129 24.0168 19.0567 24.0335 19.2682 23.9402C19.4797 23.847 19.6319 23.6557 19.6752 23.4286L23.9869 0.839106C23.9884 0.831606 23.9897 0.824106 23.9909 0.816559C24.0831 0.247169 23.4858 -0.184222 22.9745 0.0792157ZM6.29197 14.6605L2.14686 12.1097L18.6896 3.804L6.29197 14.6605ZM7.03185 21.1899V15.8817L18.6778 5.68354C18.422 6.02409 7.33644 20.7844 7.03185 21.1899ZM18.4849 22.1639L11.3545 17.7759L22.0375 3.55167L18.4849 22.1639Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconSend',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-send': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
