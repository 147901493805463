import FitnessDiaryAPI from './api/progress-check-in/FitnessDiaryAPI'
import TrainerFeedService from './TrainerFeedService'
import moment from 'moment'
export default class FitnessImageMetric {
  /**
   * types - back ,side , back
   * @type {string}
   */
  type =''
  /**
   * any String that user understand
   * @type {string}
   */
  name =''
  firstTime={
    id: null,
    image: null,
    date: null
  }

  inputImage = null
  latestDate = null
  currentImage = null
  currentImageID = null
  _diaryService = null
  constructor (type, diaryService) {
    this.type = type
    this._diaryService = diaryService
  }

  setName (name) {
    this.name = name
  }

  setFirstTimeImage (value, date = null, id = null) {
    this.firstTime.image = value
    this.firstTime.id = id
    this.firstTime.date = this._parseDate(date)
  }

  setCurrentImage (value, id) {
    this.inputImage = value
    this.currentImage = value
    this.currentImageID = id
    // if (!this.firstTime.image) {
    //   this.firstTime.image = this.currentImage
    //   let today = new Date()
    //   this.firstTime.date = (today.getFullYear() + '-' + today.getMonth() + '-' + today.getDate())
    // }
  }

  hasCurrentImage () {
    return Boolean(this.currentImage)
  }

  setLatestDate (date) {
    this.latestDate = this._parseDate(date)
  }

  getInputImage () {
    return parseFloat(this.inputImage)
  }

  getType () {
    return this.type
  }

  getFirstTimeData () {
    return this.firstTime
  }

  resetInputData () {
    this.inputImage = this.currentImage
  }

  setInputValue (newInputImage) {
    this.inputImage = newInputImage
  }

  getLatestData () {
    return {
      value: this.getCurrentImage(),
      date: this.latestDate
    }
  }

  getTodayImage () {
    const latestDate = new Date(this.latestDate)
    const todayDate = new Date()
    // call setHours to take the time out of the comparison
    if (latestDate.setHours(0, 0, 0, 0) === todayDate.setHours(0, 0, 0, 0)) {
      return this.getCurrentImage()
    } else {
      return null
    }
  }

  getName () {
    return this.name
  }

  getLatestDate () {
    return this.latestDate
  }

  getCurrentImage () {
    return this.currentImage
  }

  uploadImage (formData) {
    const service = new FitnessDiaryAPI()
    return new Promise((resolve, reject) => {
      service.setPhoto(formData).then(data => {
        const today = new Date()
        const todayDate = moment(today).format('YYYY-MM-DD')
        if (!this.firstTime.date || todayDate === this.firstTime.date) {
          this.firstTime.date = todayDate
          this.firstTime.id = data.data.id
          this.firstTime.image = data.data.url
        }

        this.latestDate = todayDate
        this.currentImage = data.data.url
        this.currentImageID = data.data.id

        if (!data.data.private) {
          let feedService = new TrainerFeedService()
          feedService.logFitnessDairyPhotoUpload(this)
        }

        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  deleteImage (id) {
    const service = new FitnessDiaryAPI()
    return new Promise((resolve, reject) => {
      service.removePhoto(id).then(data => {
        if (id === this.firstTime.id) {
          this.firstTime.id = null
          this.firstTime.image = null
          this.firstTime.date = null
        }
        if (id === this.currentImageID) {
          this.currentImageID = null
          this.latestDate = null
          this.currentImage = null
        }
        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  _parseDate (input, format = null) {
    if (!input) {
      return ''
    }
    format = format || 'yyyy-mm-dd' // default format
    const parts = input.match(/(\d+)/g)
    let i = 0; const fmt = {}
    // extract date-part indexes from the format
    format.replace(/(yyyy|dd|mm)/g, function (part) { fmt[part] = i++ })

    const date = new Date(parts[fmt.yyyy], parts[fmt.mm] - 1, parts[fmt.dd])
    let month = '' + (date.getMonth() + 1)
    let day = '' + date.getDate()
    const year = date.getFullYear()

    if (month.length < 2) { month = '0' + month }
    if (day.length < 2) { day = '0' + day }

    return [year, month, day].join('-')
  }

  getDiaryService () {
    return this._diaryService
  }
}
