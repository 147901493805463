import moment from 'moment'

export default class PlanService {
  id = null
  name = null
  type = 'null'
  paymentGateway = ''
  price = null
  currency = null
  expireTimeStamp = null
  status = 'active'
  activateTimeStamp = null
  createdTimeStamp = null
  paymentDetails= {
    "type": null,
  }
  constructor (plan) {
    this.id = plan.id
    this.name = plan.plan_name
    if(plan.plan_type){
      this.type = plan.plan_type
    }else if(plan.is_recurring){
      this.type = 'subscription'
    } else {
      this.type = 'one-off'
    }

    this.paymentGateway = plan.payment_gateway
    this.price = plan.renewal_price
    this.currency = plan.plan_currency
    this.createdTimeStamp = plan.created_at
    this.activateTimeStamp = plan.activate_at || null
    this.expireTimeStamp = plan.expires_at
    this.status = plan.plan_status
    if(plan.payment_details){
      this.paymentDetails = plan.payment_details
    }
  }

  /**
   * get the plan id
   * @returns {null}
   */
  getID () {
    return this.id
  }

  /**
   * get plan type
   * @returns {string}
   */
  getType () {
    return this.type
  }

  /**
   * get payment gateway
   * @returns {string}
   */
  getPaymentGateway () {
    return this.paymentGateway
  }

  /**
   * check is payment gateway is apple
   * @returns {boolean}
   */
  isApplePaymentGateway () {
    return this.paymentGateway === 'apple'
  }

  /**
   * check is payment gateway is stripe
   * @returns {boolean}
   */
  isStripePaymentGateway () {
    return this.paymentGateway === 'stripe'
  }

  getCardLastDigits(){
    return this.paymentDetails.last4 || null
  }

  /**
   * get plan name
   * @returns {null}
   */
  getName () {
    return this.name
  }

  /**
   * get the plan price
   * @returns {string}
   */
  getPrice () {
    return this.price || '0'
  }

  /**
   * get plan currency
   * @returns {string}
   */
  getCurrency () {
    return this.currency || ''
  }

  /**
   * check the plan is expired
   * @returns {boolean}
   */
  isExpired () {
    const today = moment()
    const date = moment(this.expireTimeStamp * 1000)
    return today.diff(date) > 0
  }

  /**
   * returns the plans expire date
   * @returns {moment.Moment}
   */
  getExpireDate () {
    return moment(this.expireTimeStamp * 1000)
  }

  /**
   * returns the plans expire date string
   * @returns {string}
   */
  getExpireDateString () {
    return this.getExpireDate().format('ll')
  }

  /**
   * returns the plans created/registered  date
   * @returns {moment.Moment}
   */
  getCreatedDate () {
    return moment(this.createdTimeStamp * 1000)
  }

  /**
   * returns the plans created/registered date string
   * @returns {string}
   */
  getCreatedDateString () {
    return this.getCreatedDate().format('ll')
  }

  getStatus () {
    return this.status
  }

  getActivationDate () {
    if (!this.activateTimeStamp) {
      return null
    }
    return moment(this.activateTimeStamp * 1000)
  }

  getActivationDateString () {
    if (!this.activateTimeStamp) {
      return ''
    }
    return this.getActivationDate().format('ll')
  }

  /**
   * Checks if the activation of the plan is postponed.
   * @returns {boolean} Returns true if the activation timestamp exists (i.e., the activation is postponed), false otherwise.
   */
  isAPostponedActivation () {
    return !!this.activateTimeStamp
  }

  /**
   * get the payment details
   * @returns {{type: null}}
   */
  getPaymentDetails(){
    return this.paymentDetails
  }

  isACardPayment () {
    if(!this.paymentDetails.type){
      return this.isStripePaymentGateway() ? 'card' : ''
    }
    return this.paymentDetails.type === 'card'

  }
  isASEPAPayment(){
    if(!this.paymentDetails.type){
      return this.isStripePaymentGateway() ? 'card' : ''
    }
    return this.paymentDetails.type === 'sepa_debit'
  }

  isAIDealPayment(){
    if(!this.paymentDetails.type){
      return this.isStripePaymentGateway() ? 'card' : ''
    }
    return this.paymentDetails.type === 'ideal'
  }

  isAGiroPayment(){
    if(!this.paymentDetails.type){
      return this.isStripePaymentGateway() ? 'card' : ''
    }
    return this.paymentDetails.type === 'giropay'
  }

  getPlanType() {
    return ''
  }
}
