export const setValuesMixin = {
  methods: {
    setMaxValue (val, max) {
      let res = 0
      const intVal = parseFloat(val)
      if (intVal > max) {
        res = max
      } else if (isNaN(intVal)) {
        res = ''
      } else {
        res = intVal
      }
      return res
    }
  }
}
