import BaseComponent from '../../../../components/layout/template-1/global/base/BaseComponent'
import ForgotPasswordService from '../../../../services/ForgotPasswordService'
import { checkNativeAppVersion } from '../../../../includes/NativeAppCommon'

export default {
  extends: BaseComponent,
  data: function () {
    return {
      systemError: [],
      state: 'init',
      formData: {
        email: ' ',
        token: this.$route.params.token,
        password: '',
        confirm_password: ''
      },
      logo: '',
      logoShow: true,
      logoStyle: {
        visibility: 'hidden'
      },
      backgroundImage: ''
    }
  },
  methods: {
    /**
     * get the login background image from toning
     */
    setImages () {
      // this.logo = this.imagesUrl + 'generic/logo.png'
      this.logo = this.getStoreImage('login.logo')
      if (this.logo.match(/\.(jpeg|jpg|gif|png)$/) === null) {
        this.logoShow = false
      }
      this.backgroundImage = this.getStoreImage('login.background')
    },
    goBack () {
      try {
        if (checkNativeAppVersion()) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              command: {
                name: 'goback'
              }
            })
          )
        }
      } catch (e) {
      }
      window.history.back()
    },
    /**
     * submit the password reset
     */
    submitForm () {
      if (this.state === 'submitting') {
        return
      }
      this.showLoading()
      this.systemError = []
      const auth = new ForgotPasswordService()
      // sending the API call
      auth.reset(this.formData).then(() => {
        this.$router.push({
          path: this.$appConfig.appUrlList.signIn
        })
      }).catch(data => {
        if (data.status === 422) {
          this.$refs.form.setErrors({ email: data.data.errors })
        } else if (typeof data.data.errors !== 'undefined') {
          this.systemError = data.data.errors
        } else if (typeof data.data.message !== 'undefined') {
          this.systemError = data.data.message
        }
      }).finally(() => {
        this.hideLoading()
        this.state = 'init'
      })
    }
  }
}
