<template>
    <label-base class="gc-label-reps" v-if="setsText">
      <text-body-extra-small
        :lineHeight="'multi'"
      >
        {{ setsText }}
      </text-body-extra-small>
    </label-base>

</template>

<script>
import LabelBase from '../../root/LabelBase'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'

export default {
  name: 'labelReps',
  components: {
    TextBodyExtraSmall,
    LabelBase
  },
  props: {
    reps: {
      required: true
    }
  },

  computed: {
    setsText: function () {
      let sets = this.reps.split('/')
      let isAllSetsHaveOneRep = sets.every((set) => {
        return set.trim() === '1'
      })
      if (isAllSetsHaveOneRep) {
        return (
          sets.length +
          ' ' +
          this.$i18n.tc("message['general.set']", sets.length)
        )
      } else {
        return (
          this.$i18n.tc("message['general.reps']", sets.length) +
          ' ' +
          this.reps
        )
      }
    }
  }
}
</script>

<style scoped>

</style>
