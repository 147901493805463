<template>
  <div class="gc-body-measurements-inputs">
    <div
      v-if="measurementSystem === 'metric'"
      class="gc-body-measurements-inputs__metric"
    >
      <validation-provider
        class="gc-body-measurements-inputs__input"
        rules="max_value:244"
        :name="stringToLower(name)"
        v-slot="{ errors }"
      >
        <text-input
          :max-decimal-points="1"
          :placeholder="30"
          :value="measurementInMetric"
          :type="'decimal'"
          step="0.01"
          min="0"
          :onkeypress="allowOnlyNumbers"
          v-model.number="measurementInMetric"
          :error-message="errors[0]"
        ></text-input>
      </validation-provider>
    </div>
    <div
      v-else-if="
        measurementSystem === 'imperial' && measurementType !== 'height'
      "
      class="gc-body-measurements-inputs__imperial"
    >
      <validation-provider
        class="gc-body-measurements-inputs__input gc-body-measurements-inputs__input--inches"
        rules="max_value:60"
        :name="stringToLower(name)"
        v-slot="{ errors }"
      >
        <text-input
          :max-decimal-points="1"
          :placeholder="2"
          :type="'decimal'"
          step="0.01"
          min="0"
          :onkeypress="allowOnlyNumbers"
          v-model.number="measurementInInches"
          :error-message="errors[0]"
        ></text-input>
      </validation-provider>
    </div>

    <validation-observer
      v-else-if="measurementSystem === 'imperial'"
      v-slot="{ errors }"
    >
      <div class="gc-body-measurements-inputs__imperial">
        <validation-provider
          class="gc-body-measurements-inputs__input gc-body-measurements-inputs__input--feet"
          :rules="maxFt"
          :name="'feet'"
          v-slot="{ errors }"
        >
          <text-input
            :max-decimal-points="0"
            :placeholder="0"
            :type="'decimal'"
            step="1"
            min="0"
            :onkeypress="allowOnlyIntegers"
            v-model.number="measurementInFeet"
            :error-message="errors[0]"
            :hide-error-message="true"
          ></text-input>
        </validation-provider>
        <validation-provider
          class="gc-body-measurements-inputs__input gc-body-measurements-inputs__input--inches"
          :rules="maxIn"
          :name="'inches'"
          v-slot="{ errors }"
        >
          <text-input
            :max-decimal-points="1"
            :placeholder="2"
            :type="'decimal'"
            step="0.1"
            min="0"
            :onkeypress="allowOnlyIntegers"
            v-model.number="measurementInInches"
            :error-message="errors[0]"
            :hide-error-message="true"
          ></text-input>
        </validation-provider>
      </div>
      <text-body-extra-small
        :line-height="'multi'"
        class="gc-body-measurements-inputs__input--error">
        {{ heightErrorMessage(errors) }}
      </text-body-extra-small>
    </validation-observer>
  </div>
</template>

<script>
import TextInput from './TextInput'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall.vue'
import {i18n} from '../../../helpers/localization/i18n'

export default {
  name: 'InputBodyMeasurement',
  components: {
    TextBodyExtraSmall,
    TextInput,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    name: {
      type: String
    },
    measurementType: {
      type: String
    },
    measurementSystem: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String
    },
    value: {
      type: Number
    }
  },
  data: function () {
    return {
      measurementInMetric: 0,
      measurementInInches: 0,
      measurementInFeet: 0
    }
  },
  computed: {
    maxFt () {
      return 'max_value:8'
    },
    maxIn () {
      if (this.measurementInFeet >= 8) {
        return 'max_value:0'
      }
      return 'max_value:12'
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function () {
        this.mapInputs()
      }
    },
    measurementSystem: {
      immediate: true,
      handler: function () {
        this.mapInputs()
      }
    },
    measurementType: {
      immediate: true,
      handler: function () {
        this.mapInputs()
      }
    },
    measurementInFeet: function () {
      this.$nextTick(() => {
        this.$emit(
          'input',
          this.measurementInFeet * 12 + this.measurementInInches
        )
      })
    },
    measurementInInches: function () {
      this.$nextTick(() => {
        this.$emit(
          'input',
          this.measurementInFeet * 12 + this.measurementInInches
        )
      })
    },
    measurementInMetric: function () {
      this.$nextTick(() => {
        this.$emit('input', this.measurementInMetric)
      })
    }
  },

  methods: {
    heightErrorMessage (errors) {
      if (errors && (errors['feet'] || errors['inches']) && (errors['feet'][0] || errors['inches'][0])) {
        return i18n.t(`message.validation.messages.max_value`, { max: '8 ft (96 inches)', _field_: 'height' })
      }
    },
    mapInputs () {
      const value = parseFloat(this.value) || 0
      if (this.measurementSystem === 'metric') {
        this.measurementInMetric = value
      } else if (
        this.measurementSystem === 'imperial' &&
        this.measurementType === 'height'
      ) {
        this.measurementInFeet = parseInt(value / 12)
        this.measurementInInches = value - this.measurementInFeet * 12
      } else if (this.measurementSystem === 'imperial') {
        this.measurementInInches = value
      }
      this.measurementInInches = Math.round(this.measurementInInches * 10) / 10
    },
    allowOnlyNumbers (event) {
      return (
        (event.charCode >= 48 && event.charCode <= 57) || event.charCode === 46
      )
    },
    stringToLower (val) {
      if (typeof val === 'string') {
        return val.toLowerCase()
      }
      return val
    },
    allowOnlyIntegers (event) {
      return event.charCode >= 48 && event.charCode <= 57
    }
  }
}
</script>
