<template>
  <!--   page background -->

  <page-secondary :header="headerOptions" class="pc-profile--account-settings">
    <!--   page wrapper -->
    <page-container class="pc-profile--account-settings__container">
      <div class="pc-profile--account-settings__profile-image-wrap">
        <image-uploader
          :autoRotate="false"
          :debug="0"
          :id="'profile-image'"
          :maxWidth="1000"
          :preview="false"
          :quality="0.7"
          @input="uploadImage($event)"
          accept="image/*"
          class="pc-profile--account-settings__profile-image"
          outputFormat="blob"
          ref="image-uploader"
        >
          <label :for="'profile-image'" slot="upload-label">
            <image-base
              v-if="profileImage"
              :src="profileImage"
              alt="Profile-image"
              class="pc-profile--account-settings__profile-image"
            />
            <div v-else class="pc-profile__image" alt="Profile-image">
              <icon-profile/>
            </div>
            <icon-camera :size="'sm2'" class="pc-profile--account-settings__profile-image-camera"/>
          </label>
        </image-uploader>
      </div>
      <text-body-extra-small class="pc-profile--account-settings__input-error-message">
        {{ imageError }}
      </text-body-extra-small>
      <grid-row>
        <grid-col md="6">
          <button-link-forward
            v-on:click="navigate('full-name')"
            :text="$t('message[\'profile.account-settings.full-name\']')"
            :subText="limitText(fullName)"
            class="pc-profile--account-settings__button pc-profile--account-settings__nav-button_full-name"
          />
        </grid-col>
        <grid-col md="6">
          <button-link-forward
            v-on:click="navigate('email')"
            :text="$t('message[\'profile.account-settings.email\']')"
            :subText="limitText(email)"
            class="pc-profile--account-settings__button pc-profile--account-settings__nav-button_email"
          />
        </grid-col>
        <grid-col md="6">
          <button-link-forward
            v-on:click="navigate('phone')"
            :text="$t('message[\'profile.account-settings.phone\']')"
            :subText="limitText(phone)"
            class="pc-profile--account-settings__button pc-profile--account-settings__nav-button_phone"
          />
        </grid-col>
        <grid-col md="6">
          <button-link-forward
            v-on:click="navigate('birthday')"
            :text="$t('message[\'profile.account-settings.birthday\']')"
            :subText="limitText(birthday)"
            class="pc-profile--account-settings__button pc-profile--account-settings__nav-button_birthday"
          />
        </grid-col>
        <grid-col md="6">
          <button-link-forward
            v-on:click="navigate('address')"
            :text="$t('message[\'profile.account-settings.address\']')"
            :subText="addressText"
            class="pc-profile--account-settings__button pc-profile--account-settings__nav-button_address"
          />
        </grid-col>
        <grid-col md="6">
          <button-link-forward
            v-on:click="navigate('time-zone')"
            :text="$t('message[\'profile.account-settings.time-zone\']')"
            :subText="limitText(timezone)"
            class="pc-profile--account-settings__button pc-profile--account-settings__nav-button_timezone"
          />
        </grid-col>
        <grid-col md="6" v-if="extraFields.length > 0">
          <button-link-forward
            v-for="extraField in extraFields"
            :key="extraField.key"
            v-on:click="navigate('custom-page')"
            :text="limitText(extraField.key)"
            :subText="limitText(extraField.value) || emptyValue"
            class="pc-profile--account-settings__button pc-profile--account-settings__nav-button_timezone"
          />

        </grid-col>
        <grid-col md="12">
          <button-link
            v-if="showAccountDeleteLink"
            @click="navigateToPath('/account-delete')"
            class="pc-profile--account-settings__nav-button_delete-account"
            :text="$t('message[\'settings.delete-account\']')"
          />
        </grid-col>
      </grid-row>
    </page-container>
    <popup-account-deletion :email="email" v-if="showAccountDeletePopup" @hide-popup="showAccountDeletePopup = false"
                            :visible="showAccountDeletePopup"
    />
  </page-secondary>
</template>

<script>
import BaseComponent from '../../global/base/BaseComponent'
import ProfileService from '../../../../../services/ProfileService'
import TimezoneService from '../../../../../services/TimezoneService'
import DashboardService from '../../../../../services/DashboardService'
import ImageUploader from 'vue-image-upload-resize'
import PageSecondary from '../../../../global/pages/PageSecondary'
import PopupAccountDeletion from '../../../../global/popups/PopupAccountDeletion.vue'
import PageContainer from '../../../../global/pages/page-sub-components/PageContainer.vue'
import ImageBase from '../../../../root/Image'
import IconCamera from '../../../../root/icons/IconCamera'
import ButtonLinkForward from '../../../../global/buttons/ButtonLinkForward'
import ButtonSecondaryForward from '../../../../global/buttons/ButtonSecondaryForward.vue'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import IconProfile from '../../../../root/icons/IconProfile'
import GridRow from '../../../../global/grid/GridRow'
import GridCol from '../../../../global/grid/GridCol'
import ButtonLink from '@/components/global/buttons/ButtonLink'
import {isIOS} from 'mobile-device-detect'
import BillingService from '@/services/plans/BillingService'
import { isMobileNativeApp } from '@/includes/NativeAppCommon'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'
import headerEventBus from '@/event-buses/headerEventBus'

export default {
  name: 'PageAccountSettings',
  extends: BaseComponent,
  components: {
    ButtonLink,
    GridCol,
    GridRow,
    IconProfile,
    ImageUploader,
    PageSecondary,
    PageContainer,
    ImageBase,
    IconCamera,
    ButtonLinkForward,
    TextBodyExtraSmall,
    ButtonSecondaryForward,
    PopupAccountDeletion
  },
  created () {
    this.setPageTitle(this.$t('message["profile.account-settings"]'))
  },
  mounted () {
    this.setPageClass()
    this.headerOptions.mainTitle = this.$i18n.t(
      'message["profile.account-settings"]'
    )
    this.setHeader(this.headerOptions)
    this.setPageData()
    this.setProfileImage()
    this.setAccountDeleteLinkVisibility()
    pageReadyEvent()
    headerEventBus.$on('back-button-click', this.navigatePrevious)
  },
  beforeDestroy () {
    headerEventBus.$off('back-button-click', this.navigatePrevious)
  },
  data: function () {
    return {
      showAccountDeleteLink: false,
      emptyValue: this.$i18n.t('message["profile.account-settings.custom-field-empty"]'),
      imageError: '',
      profileImage: null,
      imagesUrl: this.$appConfig.imagesUrl,
      headerOptions: {
        isTransparent: false,
        isFixed: false,
        show: true,
        left: 'previous-emit',
        right: '',
        rightBlink: false,
        modifiers: ['small-title', 'with-bg-color'],
        mainTitle: 'Account Settings',
        subTitle: ''
      },
      fullName: '',
      email: '',
      phone: '',
      birthday: '',
      address: '',
      timezone: '',
      customFieldText: '',
      extraFields: [],
      showAccountDeletePopup: false

    }
  },
  computed: {
    addressText: function () {
      let address = ((this.address.line1) ? this.address.line1 : '') + ' '
      address = address + ((this.address.suburb) ? this.address.suburb : '') + ' '
      address = address + ((this.address.city) ? this.address.city : '')
      return this.limitText(address)
    }
  },
  methods: {
    async setAccountDeleteLinkVisibility () {
      if (isMobileNativeApp() && isIOS) {
        const billing = new BillingService()
        await billing.getInfoFormAPI()
        this.showAccountDeleteLink = billing.hasAnyStateApplePaymentGatewayPlan()
      }
    },
    async setPageData () {
      const profileService = new ProfileService()
      profileService.setProfileData().then((res) => {
        this.fullName = profileService.getFullName()
        this.email = profileService.getEmail()
        this.phone = profileService.getPhone()
        this.birthday = profileService.getDob()
        this.address = profileService.getAddress()
        this.extraFields = profileService.getExtraFields()

        // this.timezone = profileService.getTimezone()

        const timezoneService = new TimezoneService()
        timezoneService.setTimezones().then((res) => {
          this.timezone = timezoneService.getTimezone(
            profileService.getTimezone()
          )
          this.hideLoading()
        })
      })
    },
    limitText (text) {
      if (typeof text === 'string') {
        return text.length > 27 ? text.substring(0, 27) + '...' : text
      } else if (!text) {
        return ''
      }
      return text
    },
    navigate (section) {
      const query = {
        page: 'account-settings-form',
        section: section
      }
      this.showLoading()
      let self = this
      setTimeout(function () {
        self.$router.push({
          path: self.$route.path,
          query: query
        })
      }, 500)

    },
    navigateToPath (path) {
      this.$router.push({
        path: path
      })
    },
    navigatePrevious () {
      this.$router.push({
        path: this.$route.path,
        query: {loading: '0'}
      })
    },
    setProfileImage () {
      const profileService = new ProfileService()
      profileService.setProfileData().then((data) => {
        this.profileImage =
          data.data.avatar.reference || null
        this.hideLoading()
      })
    },
    triggerImageUploadClick () {
      this.$refs['image-uploader'].click()
    },
    uploadImage (fileList) {
      const formData = new FormData()

      formData.append('image', fileList, 'image')
      const profileService = new ProfileService()
      this.showLoading()
      profileService
        .updateImage(formData)
        .then((data) => {
          const dashboard = new DashboardService()
          dashboard.getUserData(true).then((data) => {
          })
          this.setProfileImage()
        })
        .catch((err) => {
          if (
            err.response.status === 422 &&
            err.response.data.type === 'form'
          ) {
            this.imageError = err.response.data.errors[0] || ''
          } else {
            this.imageError = err.response.data.error || ''
          }
          this.hideLoading()
        })
    }
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
  }
}
</script>
