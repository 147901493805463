<template>
    <div :class="classes" v-on="$listeners">
      <slot class="pc-navigation-tabs-secondary__tab-slot"></slot>
     <text-body-extra-small v-if="!!text" lineHeight="multi" class="pc-navigation-tabs-secondary__tab-text">
       {{text}}
     </text-body-extra-small>
    </div>
</template>

<script>
import TextBodyExtraSmall from '../../../../../global/typography/TextBodyExtraSmall'
export default {
  name: 'NavigationTabsSecondaryTab',
  components: { TextBodyExtraSmall },
  props: {
    text: {
      default: '',
      type: String
    },
    selected: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    classes: function () {
      return {
        'pc-navigation-tabs-secondary__tab': true,
        'pc-navigation-tabs-secondary__tab--selected': this.selected

      }
    }
  }
}
</script>

<style scoped>

</style>
