import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    service: null,
    lastPlanID: [],
    registered: false
  },
  mutations: {
    setLastPlanID (state, lastPlanID) {
      state.lastPlanID = lastPlanID
    },
    setRegistered (state, registered) {
      state.registered = registered
    },
    setService (state, service) {
      state.service = service
    }
  },

  getters: {
    getLastPlanID (state) {
      return state.lastPlanID
    },
    getIsRegisteredIsRegistered (state) {
      return state.registered
    },
    getService (state) {
      return state.service
    }

  }
}
