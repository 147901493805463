<template>
  <popup-body class="pc-popup pc-popup-exercise-swap__body-options">
    <popup-head>
      <popup-text-heading class="text-center">{{ title }}</popup-text-heading>
    </popup-head>
    <popup-content class="pc-popup-suggested-exercise-swap-container-contact-support">
      <text-body-regular
        v-if="hasGymSuggestedExercises|| hasHomeSuggestedExercises"
        :size="'sm4'"
        :weight="'extra-bold'"
        :line-height="'multi'"
        class="pc-popup pc-popup-exercise-swap__alternative-description"
      >{{ $i18n.t('message[\'swap.alternative-description\']') }}
      </text-body-regular>
      <div class="pc-popup-exercise-swap__buttons pc-popup-exercise-swap-alternative__buttons">

        <button-choice
          class="pc-popup-exercise-swap__button"
          v-if="hasGymSuggestedExercises"
          :text="$i18n.t('message[\'swap.suggest-gym\']')"
          @click="clickedOption('gym')"/>
        <button-choice
          class="pc-popup-exercise-swap__button"
          v-if="hasHomeSuggestedExercises"
          :text="$i18n.t('message[\'swap.suggest-home\']')"
          @click="clickedOption('home')"/>

      </div>

      <div
        v-if="hasGymSuggestedExercises|| hasHomeSuggestedExercises"
        class="pc-popup pc-popup-exercise-swap__line"/>
      <div class="pc-popup pc-popup-exercise-swap__contact-support exercise-swap-modal__contact-support">
        <text-body-regular
          :size="'sm4'"
          :weight="'extra-bold'"
          :line-height="'multi'"
          class="pc-popup pc-popup-exercise-swap__support-description"
        >{{ $i18n.t('message[\'swap.support-description\']') }}
        </text-body-regular>
        <div>
          <button-choice
            :text="$i18n.t('message[\'swap.suggest-injury\']')"
            class="pc-popup-exercise-swap__button pc-popup-exercise-swap__button-swap pc-popup-exercise-swap__button-swap--support"
            @click="clickedOption('injuries')"/>
        </div>

      </div>

    </popup-content>

  </popup-body>

</template>

<script>

import PopupTextBody from '../../../../../../global/popups/popup-sub-components/PopupTextBody'
import ButtonChoice from '../../../../../../global/buttons/ButtonChoice'
import PopupHead from '../../../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupBody from '../../../../../../global/popups/popup-sub-components/PopupBody'
import PopupContent from '../../../../../../global/popups/popup-sub-components/PopupContent'
import WorkoutProgramExerciseService from '../../../../../../../services/WorkoutProgramExerciseService'
import TextBodyRegular from '../../../../../../global/typography/TextBodyRegular'

export default {
  name: 'PopupSuggestedExerciseSwapContainerOptions',
  components: {
    TextBodyRegular,
    PopupContent,
    PopupBody,
    PopupTextHeading,
    PopupHead,
    ButtonChoice,
    PopupTextBody
  },
  props: {
    suggestedExercises: {
      required: true
    },
    exercise: {
      required: true,
      validator: function (value) {
        return value instanceof WorkoutProgramExerciseService
      }
    }
  },
  data: function () {
    return {
      title: ''
    }
  },
  computed: {
    hasHomeSuggestedExercises () {
      return this.suggestedExercises && this.suggestedExercises.home && this.suggestedExercises.home.length > 0
    },
    hasGymSuggestedExercises () {
      return this.suggestedExercises && this.suggestedExercises.gym && this.suggestedExercises.gym.length > 0
    }
  },
  mounted () {
    this.setPageLanguageData()
    this.$emit('mounted')
  },
  methods: {
    setPageLanguageData () {
      this.title = this.$i18n.t('message[\'swap.swap-exercises\']')
    },
    clickedOption (type) {
      if (type === 'injuries') {
        this.$emit('injuries-selected', type)
      } else {
        this.$emit('type-selected', type)
      }
    }
  }
}
</script>
