<template>
  <popup
    v-if="true"
    ref="popup"
    :visible="true"
    class="pc-popup-payment-processing"
    :no-close-on-backdrop="true"
  >
    <popup-title style="visibility: hidden">
      <popup-button-close @hide="closePopup" />
    </popup-title>
    <popup-body class="pc-popup-payment-processing__body text-center">
      <popup-head class="text-center">
        <popup-text-heading>{{ title }}</popup-text-heading>
      </popup-head>
      <popup-content>
        <popup-text-body
          v-html="textDescription"
        />
        <loading-buffer
          v-if="!textDescription"
          height="100"
        />
      </popup-content>
      <popup-footer>
        <button-primary
          v-if="showUnderstood"
          :text="$t('message[\'general.understood\']')"
          @click="goToDashboard"
        />
      </popup-footer>

    </popup-body>
  </popup>
</template>

<script>

import Popup from '../../../../global/popups/Popup'
import PopupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupFooter from '../../../../global/popups/popup-sub-components/PopupFooter'
import PopupTextBody from '../../../../global/popups/popup-sub-components/PopupTextBody'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import LoadingBuffer from '../../global/widgets/LoadingBuffer'

export default {
  name: 'PopupPleaseAwait',
  components: {
    LoadingBuffer,
    Popup,
    PopupBody,
    PopupButtonClose,
    PopupContent,
    PopupFooter,
    PopupTextBody,
    PopupHead,
    PopupTextHeading,
    PopupTitle,
    ButtonPrimary
  },
  mixins: [popupAnimationMixin],

  props: {
    error: {
      default: ''
    }
  },
  data: function () {
    return {
      textDescription: '',
      showUnderstood: false,
      title: this.$i18n.t('message["general.please-wait"]')
    }
  },
  mounted () {
    setTimeout(() => {
      if (this.$i18n) {
        this.textDescription = this.$i18n.t('message["sign-up.popup-payment-processing.description-pending"]')
        this.showUnderstood = true
        this.title = this.$i18n.t('message["sign-up.popup-payment-processing.title-pending"]')
      }
      // Check if the browser supports the 'navigator.connection' API
      if (navigator.connection) {
        const connection = navigator.connection
        if ((!navigator.onLine || [ 'slow-2g', '2g' ].includes(connection.effectiveType)) && this.$i18n) {
          this.title = this.$i18n.t('message["sign-up.popup-payment-processing.title-connection-issue"]')
          this.textDescription = this.$i18n.t('message["sign-up.popup-payment-processing.description-connection-issue"]')
        }
        this.showUnderstood = true
      } else {
        console.log("The 'navigator.connection' API is not supported in this browser.")
      }
    }, 20000)
  },
  methods: {
    goToDashboard () {
      this.$emit('redirected')
      setTimeout(() => {
        this.$router.replace({path: this.$appConfig.appUrlList.dashboard})
      }, 300)
    },
    closePopup (e, callback) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
        if (callback) {
          callback()
        }
      })
    }

  }
}
</script>
