<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.5" cy="12.5" r="12.5" fill="#2FC417"/>
      <path d="M18.4729 8.48935C18.1869 8.20341 17.7231 8.20341 17.4371 8.4894L10.9694 14.9571L7.56477 11.5525C7.27878 11.2665 6.81501 11.2665 6.52898 11.5525C6.24294 11.8385 6.24294 12.3022 6.52898 12.5883L10.4515 16.5108C10.5945 16.6538 10.782 16.7253 10.9694 16.7253C11.1568 16.7253 11.3443 16.6538 11.4872 16.5108L18.4729 9.52514C18.7589 9.23915 18.7589 8.77538 18.4729 8.48935Z" fill="white"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconCompleteFilled',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-complete-filled': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
