<template>
  <popup
    :visible="visible"
    modal-class="pc-popup-pair-meal-plan-info"
    v-if="visible"
    size="md"
    @hide="closePopup()"
  >
    <popup-title>
      <popup-button-close @hide="closePopup($event)"></popup-button-close>
    </popup-title>
    <popup-body class="text-center">
      <popup-head class="pc-popup-pair-meal-plan-info__head">
        <popup-text-heading>
          {{$t('message["meal-plan-pair.paired-meal-plan"]')}}
        </popup-text-heading>
      </popup-head>
      <popup-content v-if="state==='init'">
        <text-body-small line-height="lh-lg" class="pc-popup-pair-meal-plan-info__top-text">
          {{$t('message["meal-plan-pair.popup-paired-meal-plan-connected-with"]')}}
        </text-body-small>
        <card-paired-profile
          class="pc-popup-pair-meal-plan-info__card-profile"
          :image="partner.getAvatar()"
          :name="partner.getFullName()"
          :email="partner.getEmail()"/>
        <text-body-small line-height="lh-lg" class="pc-popup-pair-meal-plan-info__text-description">
          {{$t('message["meal-plan-pair.popup-paired-meal-plan-description"]')}}
        </text-body-small>

        <alert-dotted class="pc-popup-pair-meal-plan-info__alert">
          <text-body-small v-html="warningText"/>
        </alert-dotted>
      </popup-content>
      <popup-content v-else>
        <loading-buffer  height="200"/>
      </popup-content>
    </popup-body>
    <popup-body class="text-center">

    </popup-body>
  </popup>
</template>

<script>
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import Popup from '../../../../global/popups/Popup'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import PairService from '../../../../../services/meal-plan-pair/PairService'
import ConnectionService from '../../../../../services/meal-plan-pair/ConnectionService'
import AlertDotted from '../../../../global/alerts/AlertDotted'
import TextBodySmall from '../../../../global/typography/TextBodySmall'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import CardPairedProfile from '../../../../global/cards/CardPairedProfile'
import LoadingBuffer from '../../global/widgets/LoadingBuffer'

export default {
  name: 'PopupPairMealPlanInfo',
  props: ['visible'],
  mixins: [popupAnimationMixin],
  components: {
    LoadingBuffer,
    CardPairedProfile,
    AlertDotted,
    TextBodySmall,
    PopupContent,
    PopupTextHeading,
    PopupHead,
    Popup,
    PopupTitle,
    PopupButtonClose,
    PopupBody
  },

  data: function () {
    return {
      pairService: null,
      connectionService: null,
      connected: false,
      isMember: false,
      isOwner: false,
      partner: null,
      loaded: false
    }
  },
  computed: {
    state: function () {
      if (!this.loaded) {
        return 'loading'
      }

      return 'init'
    },
    warningText: function () {
      let warnings = ''
      if (this.isOwner) {
        warnings = this.$i18n.t('message["meal-plan-pair.popup-paired-meal-plan-restrictions-owner"]')
      } else {
        warnings = this.$i18n.t('message["meal-plan-pair.popup-paired-meal-plan-restrictions-member"]')
      }
      const warningHTML = `<p class="alert">${warnings}<p>`
      return this.$i18n.t('message["meal-plan-pair.popup-paired-meal-plan-restrictions"]', {
        restrictions: warningHTML
      })
    }
  },
  mounted () {
    this.initServices()
  },
  methods: {
    initServices () {
      this.pairService = new PairService()
      this.connectionService = new ConnectionService()
      Promise.all([this.pairService.init(), this.connectionService.init()]).then(() => {
        if (this.connectionService.isConnectionCreated()) {
          this.connected = true
          this.isOwner = this.connectionService.isOwner()
          this.partner = this.connectionService.getPartner()
        }
        this.loaded = true
      })
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    }

  }
}
</script>
