<template>
  <transition name="fade">
    <div  class="gc-popup__floating-footer" id="gc-popup-floating-footer">
      <slot></slot>
    </div>
  </transition>

</template>

<script>
export default {
  name: 'PopupFloatingFooter'
}
</script>

<style scoped>

</style>
