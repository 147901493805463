<template>
  <div class="pc-card-photo-journey-compare"
       style="-webkit-transform: translateZ(0); -moz-transform: translateZ(0);  -ms-transform: translateZ(0);-o-transform: translateZ(0);transform: translateZ(0);">
    <card-photo-journey-compare
      :showFirstImages="showFirstImages"
      :firstImages="first.images"
      :showLatestImages="showLatestImages"
      :latestImages="latest.images"
      :firstDate="first.date"
      :latestDate="latest.date"
      :is-editable="isEditable"
      @showUploadPopup="showPhotoUpload()"
    />
    <pop-up-upload-photos
      v-if="uploadPopup.show"
      :visible="uploadPopup.show"
      @close="hidePhotoUpload()"
      @uploaded="photosUploaded"
    />
  </div>
</template>
<script>
import CardPhotoJourneyCompare from '../../../../global/cards/CardPhotoJourneyCompare'
import fitnessDiaryPhotoJourneySummaryMixin
  from '../../../../../mixins/component-mixins/page/fitness-diary/fitnessDiaryPhotoJourneySummaryMixin'
import PopUpUploadPhotos from '../../../../global/popups/PopUpUploadPhotos'

export default {
  name: 'CardPhotoJourneyMain',
  mixins: [fitnessDiaryPhotoJourneySummaryMixin],
  components: {
    CardPhotoJourneyCompare,
    PopUpUploadPhotos
  },
  mounted () {
    this.setPageData()
  }
}
</script>
