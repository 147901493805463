import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    /**
     * Feature enabled status
     */
    enabled: false,
    /**
     * Agreement accepted
     */
    isAgreed: false,
    /**
     * Current route auth status
     */
    authRoute: false,
    /**
     * Chatbox current visibility
     */
    visible: false,
    /**
     * Talk js session
     */
    session: null,
    /**
     * Active conversation
     */
    conversation: null,
    /**
     * Unread count
     */
    alertCount: 0,
    /**
     * Participants list
     */
    participants: {
      me: {
        id: '',
        name: '',
        email: '',
        photoUrl: '',
        role: 'customer'
      },
      other: {
        id: '',
        name: '',
        email: '',
        photoUrl: '',
        role: 'coach'
      }
    }
  },
  mutations: {
    setEnabled (state, enabled) {
      state.enabled = enabled
    },
    setIsAgreed (state, agree) {
      state.isAgreed = agree
    },
    setSession (state, session) {
      state.session = session
    },
    setConversation (state, conversation) {
      state.conversation = conversation
    },
    setAlertCount (state, popupButton) {
      state.alertCount = popupButton
    },
    setMainParticipantAttributes (state, attributes = []) {
      attributes.forEach((item) => {
        state.participants.me[item[0]] = item[1]
      })
    },
    setOtherParticipantAttributes (state, attributes = []) {
      attributes.forEach((item) => {
        state.participants.other[item[0]] = item[1]
      })
    },
    setVisibility (state, visibility) {
      state.visible = visibility
    },
    setAuthRoute (state, auth) {
      state.authRoute = auth
    }
  },
  getters: {
    getEnabled (state) {
      return state.enabled
    },
    getIsAgreed (state) {
      return state.isAgreed
    },
    getSession (state) {
      return state.session
    },
    getConversation (state) {
      return state.conversation
    },
    getAlertCount (state) {
      return state.alertCount
    },
    getParticipants (state) {
      return state.participants
    },
    getVisibility (state) {
      return state.visible
    },
    getAuthRoute (state) {
      return state.authRoute
    }
  }
}
