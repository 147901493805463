<template>
  <button class="gc-popup__button-close" v-on="$listeners" >
    <icon-backward size="md1"/>
  </button>
</template>

<script>
import IconBackward from '../../../root/icons/IconBackward'
export default {
  name: 'PopupButtonBack',
  components: { IconBackward }
}
</script>

<style scoped>

</style>
