<template>
  <page-secondary
    class="pc-notifications"
    :header="header"
  >
    <page-container-fluid class="pc-notifications__container">
      <navigation-bar-tab v-if="isMember && isCommunityEnabled">
        <div class="row pc-notification-tab-row">
          <div
            class="col p-0"
            @click="setTab('community')"
          >
            <div
              class="pc-notification-tab-row__tab"
              :class="{ 'pc-notification-tab-row__tab--active': activeNotificationTab === 'community' }"
            >
              <div class="pc-notification-tab-row__tab-single-tab">
                <text-body-extra-small>
                  {{ $t('message[\'notifications.tabs.community\']') }}
                </text-body-extra-small>
              </div>
            </div>
          </div>
          <div
            class="col p-0"
            @click="setTab('other')"
          >
            <div
              class="pc-notification-tab-row__tab"
              :class="{ 'pc-notification-tab-row__tab--active': activeNotificationTab === 'other' }"
            >
              <div class="pc-notification-tab-row__tab-single-tab">
                <text-body-extra-small>
                  {{ $t('message[\'notifications.tabs.other\']') }}
                </text-body-extra-small>
              </div>
            </div>
          </div>
        </div>
      </navigation-bar-tab>
      <div v-if="readyToLoad">
        <div
          v-if="activeNotificationTab === 'community' && isCommunityEnabled"
          class="community-notification-container-wrap"
        >
          <community-notifications-container @notifications-changed="handleCommunityNotificationChange" />
        </div>
        <div v-if="activeNotificationTab === 'other'">
          <notifications-container />
        </div>
      </div>
    </page-container-fluid>
  </page-secondary>
</template>

<script>
// import BaseComponent from '../global/base/BaseComponent.vue'
import pageMixin from '../../../../mixins/pageMixin'
import LoadingBuffer from '../global/widgets/LoadingBuffer'
import Page from '../../../global/pages/Page'
import PageContainerFluid from '../../../global/pages/page-sub-components/PageContainerFluid'
import CardNotification from './page-components/CardNotification'
import PageSecondary from '../../../global/pages/PageSecondary'
import TextBodyExtraSmall from '../../../global/typography/TextBodyExtraSmall'
import NavigationBarTab from '../../../global/navigators/navigation-bar-sub-components/NavigationBarTab'
import CommunityNotificationsContainer from './page-components/CommunityNotificationsContainer'
import NotificationsContainer from './page-components/NotificationsContainer'
import {checkNotAMember} from '../../../../helpers/user'
import DashboardService from '../../../../services/DashboardService'
import CommunityService from '@/services/community/CommunityService'
import { mapGetters } from 'vuex'

export default {
  name: 'PageNotifications',
  components: {
    LoadingBuffer,
    Page,
    PageContainerFluid,
    CardNotification,
    PageSecondary,
    TextBodyExtraSmall,
    NavigationBarTab,
    CommunityNotificationsContainer,
    NotificationsContainer
  },
  mixins: [pageMixin],
  data: function () {
    return {
      header: {
        isTransparent: false,
        isFixed: false,
        show: true,
        left: 'previous',
        right: 'mark-read',
        iconStates: {
          'mark-read': 'disabled'
        },
        rightBlink: false,
        modifiers: ['small-title'],
        mainTitle: this.$i18n.t('message["notification.header"]'),
        subTitle: ''
      },
      loading: true,
      notifications: [],
      workouts: [],
      mealPlanOptions: [],
      description: '',
      activeNotificationTab: 'other', // community, other
      readyToLoad: false,
      isCommunityEnabled: false
    }
  },
  computed: {
    isMember () {
      return !checkNotAMember()
    }
  },
  created () {
    this.setPageTitle(this.$t('message["notification.header"]'))
  },
  beforeMount () {
    this.showLoading()
    setTimeout(() => {
      this.readyToLoad = true // prevent showing broken content before loading
    }, 300)

    if (!this.isMember) {
      this.activeNotificationTab = 'community'
    } else {
      let queryParams = this.$route.query
      if(this.getPreviousRoute().fullPath.includes('communities')){
        this.activeNotificationTab = 'community'
      } else if ('tab' in queryParams) {
        this.activeNotificationTab = queryParams.tab === 'community' ? 'community' : 'other'
      }
    }
  },
  mounted () {
    this.setIsCommunityEnabled()
  },
  methods: {
    ...mapGetters({
      getPreviousRoute : 'getPreviousRoute'
    }),
    setTab (tab) {
      this.showLoading()
      setTimeout(() => {
        this.activeNotificationTab = tab
      }, 300)
    },
    setIsCommunityEnabled () {
      let communityService = new CommunityService()
      communityService.getCommunities().then(async () => {
        await communityService.getActiveCommunities().then(data => {
          this.isCommunityEnabled = data.length > 0
          if (!this.isCommunityEnabled) {
            this.activeNotificationTab = 'other'
          }
        })
      }).catch(() => {
        this.isCommunityEnabled = false
      })
    },
    handleCommunityNotificationChange (notifications) {
      this.header.iconStates['mark-read'] = notifications.filter(notification => {
        return !notification.isRead
      }).length > 0 ? 'active' : 'disabled'
    }
  }
}
</script>
