<template>
    <div>
        <div v-if="type==='select'&&loaded&&optionsList.length>0">
            <text-body-small :line-height="'multi'" class="food-accordion__sub-title text-color text-center" v-if="showHeader">
                {{$t('message[\'profile.dietary-preferences.food-dont-like.description\']')}}
            </text-body-small>
            <select2
                    :disabled="disabled"
                    :multiple="true"
                    :options="optionsList"
                    :placeHolder="$t('message[\'general.enter-names-of-food\']')"
                    v-model="inputArray"
                    v-on:change="setFormData"
            ></select2>
        </div>
        <!-- <AccordForm v-if="type==='accordion'&&loaded&&options.length>0"
                    :options="options"
                    :show-header="showHeader"
                    v-model="inputArray"
                    v-on:change="setFormData($event)"/> -->
        <input-group-ingredients v-if="type==='accordion'&&loaded&&optionsList.length>0"
                                 :disabled="disabled"
                                 :groupedIngredients="optionsGroup"
                                 :ListIngredients="optionsList"
                                 :show-header="showHeader"
                                 v-model="inputArray"
                                 v-on:change="setFormData($event)"
        />
    </div>
</template>
<script>
import Select2 from '../../../../../../global/inputs/InputMultiSelect'
import InputGroupIngredients from '../../../../../../global/inputs/InputGroupIngredients'
import TextBodySmall from '../../../../../template-2/global/typography/TextBodySmall'
import {enableIngredientsGroupedFlag} from '../../../../../../../includes/TemplateSettings'
export default {
  props: {
    showHeader: {
      default: false
    },
    optionsList: {
      default: []
    },
    optionsGroup: {
      default: []
    },
    value: {
      default: []
    },
    disabled: {
      default: false
    }
  },
  name: 'dislikes-input',
  components: {
    TextBodySmall,
    Select2,
    InputGroupIngredients
  },
  data: function () {
    return {
      type: 'select', // select , accordion
      loaded: false,
      inputArray: []
    }
  },
  mounted () {
    if (enableIngredientsGroupedFlag()) {
      this.type = 'accordion'
    }
    this.inputArray = this.value
    this.loaded = true
    this.emitMounted()
  },
  watch: {
    inputArray: function (newVal) {
      this.$emit('input', newVal)
    }
  },
  methods: {
    setFormData () {
      this.$emit('change', this.inputArray)
    },
    emitMounted () {
      setTimeout(() => {
        this.$emit('mounted')
      }, 200)
    }
  }
}
</script>
