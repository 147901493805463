<template>
  <div :class="componentClasses" >

    <div class="row pc-mood-tracker-tab__row">

      <div class="col-6 p-0">
        <div class="pc-mood-tracker-tab__single pc-mood-tracker-tab__single--month" :class="{ active: isWeekActive }" @click="tabClick('week')">
          <div class="pc-mood-tracker-tab__text">
            <text-content
              :weight="'medium'"
              :line-height="'multi'"
              :size="'sm1'"
            > <p>{{ $t('message["mood-tracker.tab-week"]') }}</p> </text-content>
          </div>
        </div>
      </div>

      <div class="col-6 p-0">
        <div class="pc-mood-tracker-tab__single pc-mood-tracker-tab__single--week" :class="{ active: isMonthActive }" @click="tabClick('month')">
          <div class="pc-mood-tracker-tab__text">
            <text-content
              :weight="'medium'"
              :line-height="'multi'"
              :size="'sm1'"
            > <p>{{ $t('message["mood-tracker.tab-month"]') }}</p> </text-content>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>
<script>
import TextContent from '../../../../root/TextContent'
export default {
  name: 'pc-mood-tracker-tab',
  components: {
    TextContent
  },
  props: {
    mode: {
      default: 'mode'
    },

    isWeekActive: {
      default: true
    },

    isMonthActive: {
      default: false
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'pc-mood-tracker-tab': true
      }
    }
  },

  methods: {
    tabClick (tabType) {
      this.$emit('tab-click', {tab: tabType})
    }
  }
}
</script>
