<template>
    <div class="gc-alert-dotted">
        <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'AlertDotted'
}
</script>

<style scoped>

</style>
