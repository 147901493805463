<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="1" clip-path="url(#clip0_25638_21962)">
        <path
          d="M16.9623 14.6072C17.8584 13.9994 18.4489 12.969 18.4489 11.8026C18.4489 9.93847 16.941 8.42193 15.0875 8.42193C13.234 8.42193 11.726 9.93847 11.726 11.8026C11.726 12.969 12.3165 13.9994 13.2126 14.6072C11.7349 15.2147 10.6104 16.5175 10.244 18.1092C9.87769 16.5174 8.75317 15.2147 7.27544 14.6072C8.17155 13.9993 8.76202 12.969 8.76202 11.8026C8.76202 9.93847 7.25409 8.42193 5.40058 8.42193C3.54707 8.42193 2.03913 9.93847 2.03913 11.8026C2.03913 12.969 2.62961 13.9994 3.52575 14.6072C1.71138 15.3531 0.429576 17.2483 0.429576 19.3382C0.429576 19.3382 0.420694 19.6252 0.548551 19.7259C0.649011 19.8051 0.816205 19.8154 0.816205 19.8154L19.5528 19.8154C19.5528 19.8154 19.791 19.8267 19.9395 19.6961C20.1088 19.5472 20.0584 19.2371 20.0584 19.2371C20.0584 17.1472 18.7766 15.3531 16.9623 14.6072Z"
          fill="white"/>
        <path
          d="M4.85209 0.328876C4.79281 0.418339 4.79297 0.604928 4.79297 0.604928V6.91799C4.79297 6.91799 4.79203 7.11097 4.91153 7.21613C5.01607 7.30813 5.14866 7.32695 5.14866 7.32695H8.53821L10.0986 9.3628C10.0986 9.3628 10.1649 9.45224 10.2468 9.45224C10.3288 9.45224 10.395 9.3628 10.395 9.3628L11.9555 7.32695H15.345C15.345 7.32695 15.5107 7.3418 15.6118 7.24591C15.7189 7.14429 15.7007 6.94776 15.7007 6.94776V0.53758C15.7007 0.53758 15.7022 0.405184 15.6118 0.2991C15.5063 0.175335 15.2857 0.179803 15.2857 0.179803H5.20778C5.20778 0.179803 4.9573 0.170096 4.85209 0.328876ZM10.1305 5.26969L8.48394 4.04715L9.16197 3.11685L10.0434 3.77133L11.687 2.22155L12.468 3.06538L10.1305 5.26969Z"
          fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_25638_21962">
          <rect width="20" height="20" fill="white" transform="translate(0.25)"/>
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconCommunityChat',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-community-chat': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
