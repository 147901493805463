<template>
  <page-secondary :header="headerOptions" class="pc-exercise pc-exercise-grouped" desktop-side-bar="false">
    <nav-circuit-exercise-bar
      v-if="exerciseData && exercises.length > 0 && mode === 'circuit'"
      :circuit="circuit"
      :current-exercise="exerciseData"
      :day="params.day"
      :exercises="exercises"
      @goto-exercise="navigateNav($event)"
      @goto-exercise-listing="exitCircuit()"
    />
    <nav-exercise-medium-set
      v-else-if="exerciseData && exercises.length>0&&exercises.length<=3&&mode!=='circuit'"
      :current-exercise="exerciseData"
      :exercises="exercises"
      @navigate="navigateNav($event)"
    />
    <nav-exercise-large-set
      v-else-if="exerciseData && exercises.length>0&&exercises.length>3&&mode!=='circuit'"
      :current-exercise="exerciseData"
      :exercises="exercises"
      @navigate="navigateNav($event)"
    />
    <div class="pc-exercise-grouped__exercise-title-text" v-if="loaded && exerciseData">
      <text-body-regular :lineHeight="'multi'" :weight="'extra-bold'" >{{exerciseData.name}}</text-body-regular>
    </div>
    <popup-workout-log-exit :type="'circuit'" :visible="circuit.requestExit"
                            @close="circuit.requestExit=false"
                            @exit="exitCircuit()"/>
    <container-single-exercise :show-complete-button="this.mode !== 'circuit'" :show-sets-area="false" v-if="loaded"/>
  </page-secondary>
</template>

<script>
import pageMixin from '../../../../mixins/pageMixin'
import exerciseMixin from '../../../../mixins/page-mixins/workout/exerciseMixin'
import PopupWorkoutLogExit from './page-components/popups/PopupWorkoutLogExit'
import ContainerSingleExercise from './page-components/ContainerSingleExercise'
import { mapGetters, mapMutations } from 'vuex'
import PageSecondary from '../../../global/pages/PageSecondary'
import NavExerciseMediumSet from './page-components/NavExerciseMediumSet'
import NavExerciseLargeSet from './page-components/NavExerciseLargeSet'
import NavCircuitExerciseBar from './page-components/NavCircuitExerciseBar'
import TextBodyRegular from '../../../global/typography/TextBodyRegular'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'ExerciseView',
  mixins: [pageMixin, exerciseMixin],
  components: {
    TextBodyRegular,
    NavCircuitExerciseBar,
    NavExerciseLargeSet,
    NavExerciseMediumSet,
    PageSecondary,
    ContainerSingleExercise,
    PopupWorkoutLogExit
  },
  data: function () {
    return {
      loaded: false,
      exercises: [],
      currentExerciseId: 0,
      mode: 'standard',
      circuit: {
        totalRounds: 1,
        isCircuit: false,
        requestExit: false,
        confirmExit: false
      },
      headerOptions: {
        show: true,
        left: 'previous-emit',
        right: 'help',
        modifiers: [
          'small-title',
          'app-nav--with-bg-color',
          'macroapp--page-workout-video--with-navbar'
        ],
        header: {
          mainTitle: '',
          subTitle: ''
        }
      }
    }
  },
  mounted () {
    this.currentExerciseId = parseInt(this.params.exercise)
    this.mode = this.$route.query.mode || 'standard'
    this.setHeader(this.headerOptions)
    this.loaded = true
  },
  computed: {
    ...mapGetters({
      headerBackButtonClick: 'pageStore/getBackButtonClick',
      circuitModeCurrentRound: 'workoutCircuitStore/getCurrentRound'
    })
  },
  watch: {
    headerBackButtonClick: function (newVal, oldVal) {
      this.goToBackPage()
    },
    getWorkoutDataStatus: function (newVal, oldVal) {
      if (newVal === 'loaded') {
        this.setGroupExerciseData()
      }
      if (this.getWorkoutDataStatus === 'loaded' && this.getExerciseDataStatus === 'loaded') {
        this.logExerciseViewedEvent()
        pageReadyEvent()
      }
    },
    getExerciseDataStatus: function (newVal, oldVal) {
      if (newVal === 'loaded') {
        this.afterLoadPageData()
      }
      if (this.getWorkoutDataStatus === 'loaded' && this.getExerciseDataStatus === 'loaded') {
        this.logExerciseViewedEvent()
      }
    }
  },
  methods: {
    ...mapMutations({
      circuitModeInitDay: 'workoutCircuitStore/initDay',
      circuitDestroyDay: 'workoutCircuitStore/destroyDay'
    }),
    afterLoadPageData () {
      if (this.mode === 'circuit') {
        this.headerOptions.mainTitle = this.$i18n.t('message["workout.circuit-workout"]')
        this.headerOptions.subTitle = this.$i18n.tc('message["workout.rounds"]', 1) + ' ' + this.circuitModeCurrentRound
      } else {
        this.headerOptions.mainTitle = this.exerciseData.getSetType().name
        this.instructionsPopup.instructions = [
          this.exerciseData.getSetType().description
        ]
        this.instructionsPopup.title = this.exerciseData.getSetType().name
        // calling to setContent function in the pageStore

        this.setInstructionsPopupContent(this.instructionsPopup)
      }
    },
    goToBackPage () {
      if (this.circuit.isCircuit) {
        if (this.getCurrentExerciseIndex() === 0 && this.circuitModeCurrentRound === 1) {
          this.navigateExerciseList()
        } else {
          this.circuit.requestExit = true
        }
      } else {
        this.navigateExerciseList()
      }
    },
    exitCircuit () {
      this.circuit.requestExit = false
      this.resetWorkoutData()
      // this.loadWorkoutData()
      this.circuitDestroyDay()
      this.navigateExerciseList()
    },
    isExerciseCompleted (exercise) {
      return (
        (exercise.completed && this.currentExerciseId !== exercise.id) ||
          (this.isCompleted && this.currentExerciseId === exercise.id)
      )
    },
    getCurrentExerciseIndex () {
      return this.exercises.findIndex(ex => {
        return ex.id === this.currentExerciseId
      })
    },
    navigateNav (id) {
      if (id !== this.currentExerciseId) {
        this.$router.replace({
          path: this.$appConfig.appUrlList.workout,
          query: {
            exercise: id,
            day: this.params.day,
            week: this.params.week,
            workout: this.params.workout,
            'set-id': this.params['set-id'],
            grouped: this.params.grouped,
            mode: this.params.mode
          }
        })
      }
    },
    navigateNextWorkout () {
      this.setGroupExerciseData()
    },
    setGroupExerciseData () {
      this.exercises = []

      const dayData = this.workoutProgram.getDayByID(parseInt(this.params.day))

      this.circuit.isCircuit = dayData.getIsCircuit()
      if (this.circuit.isCircuit) {
        this.circuit.totalRounds = dayData.getCircuitRounds() || 1
      }

      this.exercises = dayData.groupExercises(dayData.exercises).find((group) => {
        return group.some((ex) => {
          return ex.getID() === parseInt(this.params.exercise)
        })
      })
    }
  }
}
</script>
