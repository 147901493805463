<template>
  <popup
    :visible="visible"
    v-if="visible"
    size="md"
    @hide="closePopup"
  >
    <popup-title>
      <popup-button-close  @hide="closePopup($event)"></popup-button-close>
    </popup-title>
    <PoupBody class="text-center">
      <popup-content>
        <popup-head>
          <div class="info-modal__head mood-tracker-modal__head" v-if="status==='init'">
            <popup-text-heading><p>{{ $t('message["mood-tracker.pop-up-delete-heading"]') }}</p></popup-text-heading>
          </div>
          <div class="info-modal__sub-heading mood-tracker-modal__sub-head" v-if="status==='init'">
            <popup-text-body>
            <p>{{ $t('message["mood-tracker.pop-up-delete-sub-heading"]') }}</p>
            </popup-text-body>
          </div>
        </popup-head>
        <div v-if="status==='loading'">
          <div class="text-center info-modal__subtitle">
            <popup-text-heading>
              <p>{{ $t('message["mood-tracker.pop-up-deleting"]') }}</p>
            </popup-text-heading>
          </div>
          <loading-buffer :height="'100'"></loading-buffer>
        </div>
        <div v-if="status==='success'">
          <div class="text-center info-modal__subtitle mood-tracker-modal__success">
            <popup-text-heading>
              {{ $t('message["mood-tracker.pop-up-header-delete-success"]') }}
            </popup-text-heading>
          </div>
          <icon-complete-filled :size="'lg2'" />
        </div>
      </popup-content>
    </PoupBody>
    <popup-footer>
      <div v-if="status==='init'">
        <button-primary :text="$t('message[\'mood-tracker.pop-up-delete-action-button-text\']')" @click="doAction()" />
      </div>
    </popup-footer>
  </popup>
</template>

<script>
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import TextContent from '../../../../root/TextContent'
import TextArea from '../../../../global/inputs/TextArea'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import IconCompleteFilled from '../../../../root/icons/IconCompleteFilled'
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import Popup from '../../../../global/popups/Popup'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupTextSubHeading from '../../../../global/popups/popup-sub-components/PopupTextSubHeading'
import PopupTextBody from '../../../../global/popups/popup-sub-components/PopupTextBody'
import PoupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import MoodTrackerService from '../../../../../services/MoodTrackerService'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupFooter from '../../../../global/popups/popup-sub-components/PopupFooter'

export default {
  name: 'pop-up-add-mood',
  mixins: [popupAnimationMixin],
  components: {
    PopupFooter,
    PopupContent,
    PopupTitle,
    PopupHead,
    PopupTextHeading,
    PopupButtonClose,
    Popup,
    TextContent,
    TextArea,
    ButtonPrimary,
    IconCompleteFilled,
    LoadingBuffer,
    PopupTextSubHeading,
    PopupTextBody,
    PoupBody
  },
  props: {
    visible: { default: false },
    moodId: {default: null}
  },
  data: function () {
    return {
      contentClass: 'info-modal__content info-modal__content--hidden custom-modal-padding fd-upload-image-popup',
      status: 'loading',
      submitInProgress: false
    }
  },
  mounted () {
    this.status = 'init'
  },
  methods: {
    closePopup (e) {
      this.closeAnimatedPopup(() => {
        this.$emit('close')
        // backdrop close event fire
        if (e && e.trigger === 'backdrop') {
          this.submitCloseEvent('MoodTracker', 'background')
        }
      })
    },

    showEditPopup () {
      this.$emit('showEditPopup')
    },
    doAction () {
      this.status = 'loading'
      this.submitInProgress = true
      let moodTrackerService = new MoodTrackerService()

      moodTrackerService.delete(this.moodId)
        .then(() => {
          this.$emit('deleteSuccess', {isActiveLoadingBuffer: true})
          this.status = 'success'
          setTimeout(() => {
            this.closePopup()
          }, 3000)
        }).catch((error) => {
          if (error.message) {
            this.errorMessage = error.extensions.validation['input.comment'][0].message
            this.status = 'init'
            this.submitInProgress = false
          }
        })
    }
  }
}
</script>
