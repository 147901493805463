<template>
  <div class="" v-bind:class="pageClasses">
    <div class="macroapp-background" v-bind:class="background.classes" v-if="background.type!==''">
      <div :style="'background-image: url('+background.image+')'" class="macroapp-background__image"
           v-if="background.type=== 'image'"></div>
    </div>
    <component :pageConfig="dynamicComponentConfig" v-bind:is="dynamicComponent"></component>
  </div>

</template>

<script>
import Page from './Page'
import MainPageMixin from '../../../../mixins/mainPageMixin'
export default {
  name: 'Main',
  mixins: [MainPageMixin],
  components: { Page },
  data: function () {
    return {
      components: {
        page: { design1: Page }
      }
    }
  },
  computed: {
    page () {
      return 'page'
    }
  }
}
</script>

<style scoped>

</style>
