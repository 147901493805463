import moment from 'moment'
import unitSystem from '../../../../config/measurement-units'

export default {
  props: {
    editInside: {
      default: false
    },
    loggedAt: {
      default: ''
    },
    note: {
      default: ''
    },
    exerciseName: {
      default: ''
    },
    dayName: { default: '' },
    weekName: { default: '' },
    metrics: {
      default: function () {
        return []
      },
      type: Array
    },
    entries: {
      default: function () {
        return []
      },
      type: Array
    },
    sets: {
      default: ''
    },
    workoutID: {
      default: 0
    },
    exerciseID: {
      default: 0
    },
    unitSystemValue: {
      default: 'metric'
    }
  },
  data: function () {
    return {
      isPersonalBest: false,
      isEditing: false,
      editNote: '',
      mainWrapperClass: ['workout-stats'],
      editPopup: {
        visible: false
      }
    }
  },
  mounted () {
    if (this.entries[0] && this.entries[0].attributes.length === 1) {
      this.mainWrapperClass.push('workout-single-stats')
    }
    this.setPersonalBest()
  },
  computed: {
    exerciseSetCount: function () {
      return  this.sets.split('/').length
    },
    metricsCount () {
      return this.metrics.length
    },
    showNoteForm: function () {
      return this.isEditing && (this.metrics.length === 0 || !this.sets)
    },
    showEntryForm: function () {
      return this.isEditing && this.metrics.length > 0 && Boolean(this.sets)
    }
  },
  methods: {
    setPersonalBest () {
      this.isPersonalBest = false
      this.entries.forEach(entry => {
        entry.attributes.forEach((data, index) => {
          if (typeof data.is_personal_best !== 'undefined' &&
            data.is_personal_best === true) {
            this.isPersonalBest = true
          }
        })
      })
    },
    getUnit (type) {
      if (type === 'reps') {
        return this.$i18n.t('message["workout-log.reps-unit"]')
      }
      return unitSystem[this.unitSystemValue][type]
    },
    setLogText (entry) {
      const values = entry.attributes.map((data, index) => {
        let val = data.value
        if (typeof data.is_personal_best !== 'undefined' &&
          data.is_personal_best === true) {
          this.isPersonalBest = true
        }
        let unitSystemType = this.getUnit(data.type)
        if (data.type === 'duration') {
          val = parseInt(val)
          const min = parseInt(val / 60)
          const sec = parseInt(val % 60)
          if (min > 0 && sec > 0) {
            val = this.setTimerNumbers(min) + ':' + this.setTimerNumbers(sec)
            unitSystemType = this.$i18n.t('message["general.time-minutes-short"]')
          } else if (min > 0) {
            val = this.setTimerNumbers(min)
            unitSystemType = this.$i18n.t('message["general.time-minutes-short"]')
          } else {
            val = this.setTimerNumbers(sec)
            unitSystemType = this.$i18n.t('message["general.time-seconds-short"]')
          }
        }
        let pclass = 'text-center'
        if (entry.attributes.length === 1) {
          // ignore entry
        } else if (index === 0) {
          pclass = 'text-right'
        } else if (entry.attributes.length - 1 === index) {
          pclass = 'text-left'
        }

        return `<p class="${pclass}">${
          val + ' ' + unitSystemType
        }</p>`
      })
      return values.join(' <span> x </span>')
    },
    setTimerNumbers (num) {
      num = parseInt(num)
      return num < 10 ? '0' + num : num
    },
    formatDateTime (dateTime) {
      const today = new Date()
      if (moment(dateTime).startOf('day').diff(moment(today), 'days') > -2) {
        return moment(dateTime).calendar()
      } else {
        return moment(dateTime).format('ddd, DD MMMM YYYY')
      }
    },
    cancelEdit () {
      this.isEditing = false
    },
    completeEdit (payload) {
      this.isEditing = false
      this.$emit('reload')
    },
    editClicked () {
      if (this.editInside) {
        this.isEditing = true
      } else {
        this.$emit('request-edit', this.exerciseID)
      }
      this.closeClicked()
    },
    deleteClicked () {
      this.$emit('request-delete', this.exerciseID)
      this.closeClicked()
    },
    closeClicked () {
      this.editPopup.visible = false
    },
    getSets (entries) {
      const sets = []
      entries.forEach(data => {
        sets.push('1')
      })
      if (entries.length > this.sets.split('/').length) {
        return sets.join('/') || this.sets
      } else {
        return this.sets || sets.join('/')
      }
    }
  }
}
