<template>
    <popup class="pc-popup-disconnect" v-if="visible" :visible="visible" size="md" @hide="closePopup()">
        <popup-title>
            <popup-button-close  @click="closePopup"></popup-button-close>
        </popup-title>
        <popup-body v-if="status === 'ready'">
            <popup-content class="pc-popup-disconnect__content">
                <popup-head>
                    <popup-text-heading class="pc-popup-disconnect__heading">
                        {{ $t('message[\'member-connect.disconnect-question\']') }}
                    </popup-text-heading>
                    <popup-text-body>
                        <div v-html="$t('message[\'member-connect.disconnect-confirm-description\']', [name])"></div>
                    </popup-text-body>
                </popup-head>
            </popup-content>
            <popup-footer>
                <button-primary
                        :text="$t('message[\'member-connect.yes-disconnect\']')"
                        v-on:click="disconnect"/>
            </popup-footer>
        </popup-body>

        <popup-body class="text-center" v-if="status === 'processing'">
            <popup-head class="text-center">
                <popup-text-heading>{{$t('message[\'member-connect.disconnecting\']')}}</popup-text-heading>
                <div class="text-center">
                    <loading-buffer :height="'75'"></loading-buffer>
                </div>
            </popup-head>
        </popup-body>

        <popup-body class="text-center" v-if="status === 'processed'">
            <popup-head class="text-center">
                <popup-text-heading>{{$t('message[\'member-connect.disconnected\']')}}</popup-text-heading>
            </popup-head>
            <popup-content>
                <icon-complete-filled :size="'lg3'"/>
            </popup-content>
        </popup-body>
    </popup>
</template>

<style></style>

<script>
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import PopupTitle from '../../global/popups/popup-sub-components/PopupTitle'
import Popup from '../../global/popups/Popup'
import PopupButtonClose from '../../global/popups/popup-sub-components/PopupButtonClose'
import PopupBody from '../../global/popups/popup-sub-components/PopupBody'
import PopupContent from '../../global/popups/popup-sub-components/PopupContent'
import PopupHead from '../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../global/popups/popup-sub-components/PopupTextHeading'
import PopupTextBody from '../../global/popups/popup-sub-components/PopupTextBody'
import PopupFooter from '../../global/popups/popup-sub-components/PopupFooter'
import ButtonPrimary from '../../global/buttons/ButtonPrimary'
import LoadingBuffer from '../../layout/template-1/global/widgets/LoadingBuffer'
import IconCompleteFilled from '../../root/icons/IconCompleteFilled'
import ConnectionService from '../../../services/meal-plan-pair/ConnectionService'
import NotificationPopupService from '../../../services/NotificationPopupService'
import PairService from '../../../services/meal-plan-pair/PairService'
import MealPlanService from '../../../services/MealPlanService'

export default {
  name: 'PopupMemberDisconnect',
  props: {
    name: {
      default: ''
    }
  },
  components: {
    Popup,
    PopupTitle,
    PopupButtonClose,
    PopupBody,
    PopupContent,
    PopupHead,
    PopupTextHeading,
    PopupTextBody,
    PopupFooter,
    ButtonPrimary,
    LoadingBuffer,
    IconCompleteFilled
  },
  mixins: [popupAnimationMixin],
  data: function () {
    return {
      visible: false,
      status: 'ready', // ready, processing, processed, error
      connectionService: new ConnectionService(),
      pairService: new PairService(),
      mealPlanService: new MealPlanService()
    }
  },
  methods: {
    show () {
      this.visible = true
      this.status = 'ready'
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.visible = false
        this.$emit('close')
      })
    },
    async disconnect () {
      this.status = 'processing'

      // getting the partner details
      let partner = this.connectionService.getPartner()
      let self = this.connectionService.getSelf()

      // disconnecting connection
      await this.connectionService.disconnect()
        .then(async () => {
          // re initiating connection details
          await this.connectionService.init({forceLoad: true})

          // re initiate pair details
          await this.pairService.init({forceLoad: true})

          // set message for partner
          await (new NotificationPopupService()).setMemberNotification(
            'member_disconnected',
            partner.getId(),
            {
              name: self.getFullName(),
              email: self.getEmail(),
              avatar: self.getAvatar()
            }
          )

          // reset meal plan details
          this.mealPlanService.reset()

          this.status = 'processed'
          setTimeout(() => {
            this.closePopup()
            this.$emit('disconnected')
          }, 1000)
        })
        .catch(() => {
          this.closePopup()
        })
    }
  }
}
</script>
