export default class InputDataValidationError extends Error {
  constructor (errors = {}) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super()

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InputDataValidationError)
    }

    this.name = 'InputDataValidationError'
    // Custom debugging information
    this.errors = errors
  }
}
