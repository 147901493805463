<template>
  <banner-image v-if="show" @click="goTo(link)" :src="image"/>
</template>
<script>
import { mapGetters } from 'vuex'
import { isMobileNativeApp } from '../../../../../includes/NativeAppCommon'
import NativeAppMixin from '../../../../../mixins/NativeAppMixin'
import BannerImage from '../../../../global/banners/BannerImage'

export default {
  name: 'app-banner',
  mixins: [NativeAppMixin],
  components: {
    BannerImage
  },
  props: {
    image: {},
    link: {
      default: '#'
    },
    bannerIndex: { default: 0 },
    activePages: {
      default: []
    }
  },
  data: function () {
    return {
      show: true
    }
  },
  computed: {
    ...mapGetters({
      showBanner: 'bannerStore/showBanner'
    })
  },
  mounted () {

  },
  methods: {
    goTo (page) {
      if (page === window.location.href) {
        return
      }
      if (page.startsWith(window.location.origin)) {
        this.$router.push({ path: page.replace(window.location.origin, '') })
      } else {
        this.handleUrlNavigate(page)
        // this.$router.push({ path: '/external-page', query: { page: page } })
      }
    },
    openExternalURL (url) {
      if (isMobileNativeApp() && window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage({
          name: 'openInAppBrowserUrl',
          args: {
            url: url
          }
        })
      } else {
        this.handleUrlNavigate(url)
      }
    }
  }

}
</script>
