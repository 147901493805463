<template>
    <div :class="cardClass"  @click="viewMore('home_card')">
        <p class="card-measure__title">{{name}}</p>
        <div class="card-measure__add-button">
            <a :href="void(0)" v-on:click.stop="addData()">
                <plus-svg></plus-svg>
            </a>
        </div>
        <div class="container card-measure-second-section">
            <p class="card-measure__info" v-if="!getFirstTimeValue()&&!getLatestValue()">{{message["empty-measurement"]}}</p>
            <p class="card-measure__info" v-if="getFirstTimeValue()">{{message["request-log-measurement"]}}</p>
            <p class="card-measure__measure" v-if="getFirstTimeValue()">{{getFirstTimeValue()}} {{measurementUnit}}</p>
            <p class="card-measure__measure" v-else-if="getLatestValue()">{{getLatestValue()}} {{measurementUnit}}</p>
            <p class="card-measure__day" v-if="getLatestValue()&&(getFirstTimeValue()===getLatestValue())">{{getLatestDate()}}</p>
            <p class="card-measure__day" v-else-if="getFirstTimeValue()">{{getFirstTimeDate()}}</p>
            <p class="card-measure__day" v-else-if="!getFirstTimeValue()&&getLatestValue()">{{getLatestDate()}}</p>
        </div>
      <div class="card-measure__more-button" v-if="isEditable" v-on:click.stop.prevent="viewMore('home_more')">
        <p class="card-measure__more-link">{{ $t('message["general.more"]') }} ></p>
      </div>
    </div>
</template>
<script>
import PlusSvg from './svgs/PlusSvg'
import fitnessDiaryFitnessMeasurementComponentMixin from '../../../../../mixins/component-mixins/page/fitness-diary/fitnessDiaryFitnessMeasurementComponentMixin'
export default {
  name: 'CardSmallFitnessDiaryMeasurement',
  mixins: [fitnessDiaryFitnessMeasurementComponentMixin],
  computed: {
    cardClass: function () {
      let classes = [ 'card-measure', 'card-measure--first-time', 'card-measure__' + this.type ]
      if (!this.getFirstTimeValue()) {
        classes.push('card-measure--empty')
      }
      return classes
    }
  },
  components: { PlusSvg }
}
</script>
