<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M4.63009 1.76413H1.08588C0.872871 1.76413 0.700195 1.93681 0.700195 2.14982C0.700195 2.36282 0.872871 2.5355 1.08588 2.5355H1.48074L1.94697 14.8906C1.97624 15.6663 2.62423 16.2916 3.39973 16.2916H11.6276C12.4021 16.2916 13.0511 15.666 13.0804 14.8906L13.5466 2.5355H13.9419C14.1549 2.5355 14.3276 2.36282 14.3276 2.14982C14.3276 1.93681 14.1549 1.76413 13.9419 1.76413H10.3976L10.168 1.19006C9.97705 0.712781 9.44117 0.349976 8.92801 0.349976H6.09968C5.58795 0.349976 5.05013 0.71403 4.85972 1.19006L4.63009 1.76413ZM5.46088 1.76413H9.56681L9.45177 1.47653C9.37801 1.29211 9.12576 1.12134 8.92801 1.12134H6.09968C5.90334 1.12134 5.64918 1.29339 5.57592 1.47653L5.46088 1.76413ZM2.71778 14.8615L2.25265 2.5355H12.7747L12.3095 14.8615C12.2959 15.2227 11.9872 15.5202 11.6276 15.5202H3.39973C3.03928 15.5202 2.73143 15.2232 2.71778 14.8615ZM7.25682 13.8489V5.10682C7.25682 4.89381 7.4295 4.72114 7.6425 4.72114C7.85551 4.72114 8.02819 4.89381 8.02819 5.10682V13.8489C8.02819 14.0619 7.85551 14.2346 7.6425 14.2346C7.4295 14.2346 7.25682 14.0619 7.25682 13.8489ZM4.30018 5.11816L4.55731 13.8603C4.56357 14.0732 4.74125 14.2407 4.95416 14.2345C5.16707 14.2282 5.3346 14.0505 5.32834 13.8376L5.07121 5.09548C5.06495 4.88257 4.88728 4.71505 4.67436 4.72131C4.46145 4.72757 4.29392 4.90525 4.30018 5.11816ZM9.6996 13.8376L9.95672 5.09548C9.96298 4.88257 10.1407 4.71505 10.3536 4.72131C10.5665 4.72757 10.734 4.90525 10.7277 5.11816L10.4706 13.8603C10.4644 14.0732 10.2867 14.2407 10.0738 14.2345C9.86086 14.2282 9.69333 14.0505 9.6996 13.8376Z"
            fill="black"/>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconGarbageBin',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-garbage-bin': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
