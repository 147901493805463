<template>
    <div>
        <div class="macroapp macroapp--page-account">
            <div>
                <div class="head">
                    <h1 class="head__title">{{ $t('message[\'iap.account.head_title\']') }}</h1>
                    <p class="sub__title">{{ $t('message[\'iap.account.sub__title\']') }}</p>
                </div>
                <div class="container">
          <span>
            <ValidationObserver ref="form" v-slot="{ handleSubmit,invalid }">
            <form @submit.prevent="handleSubmit(submitAccountDetails)" ref="formElement" style="margin-bottom: 30px;">
               <ValidationProvider name="first name" rules="required" v-slot="{ errors , invalid, validated }">
                <div class="form-group ma-form">
                    <label class="ma-form__label">{{ $t('message[\'iap.account.first_name\']') }}</label>
                    <input type="text" class="form-control ma-form__input" v-model="payload.first_name"/>
                    <p class="app-error-msg">
                      <span v-if="validationErrors.first_name">{{validationErrors.first_name[0]}}</span>
                      <span v-else-if="errors[0]">{{errors[0]}}</span>
                    </p>
                </div>
               </ValidationProvider>
               <ValidationProvider name="last name" rules="required" v-slot="{ errors , invalid, validated }">
                <div class="form-group ma-form">
                    <label class="ma-form__label">{{ $t('message[\'iap.account.last_name\']') }}</label>
                    <input type="text" class="form-control ma-form__input" v-model="payload.last_name"/>
                    <p class="app-error-msg">
                      <span v-if="validationErrors.last_name">{{validationErrors.last_name[0]}}</span>
                      <span v-else-if="errors[0]">{{errors[0]}}</span>
                    </p>
                </div>
               </ValidationProvider>
               <ValidationProvider name="email" rules="required" v-slot="{ errors , invalid, validated }">
                <div class="form-group ma-form">
                    <label class="ma-form__label">{{ $t('message[\'iap.account.email\']') }}</label>
                    <input type="email" class="form-control ma-form__input" v-model="payload.email"/>
                    <p class="app-error-msg">
                      <span v-if="validationErrors.email">{{validationErrors.email[0]}}</span>
                      <span v-else-if="errors[0]"  @click="emailErrorClick" v-html="errors[0]"></span>
                    </p>
                </div>
               </ValidationProvider>
              <ValidationProvider name="confirm email" rules="required|confirmed:email"
                                  v-slot="{ errors , invalid, validated }">
               <div class="form-group ma-form">
                <span>
                  <label class="ma-form__label">{{ $t('message[\'iap.account.confirm_email\']') }}</label>
                  <input type="email" class="form-control ma-form__input" v-model="payload.confirm_email"/>
                  <p class="app-error-msg">
                     <span v-if="validationErrors.confirm_email">{{validationErrors.confirm_email[0]}}</span>
                    <span v-else-if="errors[0]">{{errors[0]}}</span>
                  </p>
                </span>
              </div>
                  </ValidationProvider>
                <ValidationProvider name="password"
                                    :rules="{required: true,min:8,regex:/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/}"
                                    v-slot="{ errors , invalid, validated }">
              <div class="form-group ma-form">
                <span>
                  <label class="ma-form__label">{{ $t('message[\'iap.account.password\']') }}<span
                    v-if="passwordStrengthMessage"
                    class="pc-sign-up-register__registration-form-password-strength"
                  > {{ passwordStrengthMessage }}</span
                  >

                  </label>
                  <text-body-extra-small
                                          class="pc-sign-up-register__registration-form-label-description"
                                        >
                        {{ $t('message["sign-up.form.password-guild"]') }}
                      </text-body-extra-small>
                  <input-password type="password" class="" v-model="payload.password"
                                  @strong="passwordIsStrong"
                                  @weak="passwordIsWeak"/>
                  <p class="app-error-msg">
                    <span v-if="validationErrors.password">{{validationErrors.password[0]}}</span>
                    <span v-else-if="errors[0]">{{errors[0]}}</span>
                  </p>
                </span>
              </div>
                    </ValidationProvider>
                  <ValidationProvider name="confirm password" rules="required|confirmed:password"
                                      v-slot="{ errors , invalid, validated }">
               <div class="form-group ma-form">
                <span>
                  <label class="ma-form__label">{{ $t('message[\'iap.account.confirm_password\']') }}</label>
                  <input type="password" class="form-control ma-form__input" v-model="payload.confirm_password"/>
                  <p class="app-error-msg">
                    <span v-if="validationErrors.confirm_password">{{validationErrors.confirm_password[0]}}</span>
                    <span v-else-if="errors[0]">{{errors[0]}}</span>
                  </p>
                </span>
              </div>
                      </ValidationProvider>
              <ValidationProvider v-if="showVerificationCode"
                                  name="verification-code"
                                  v-id="'verification_code'"
                                      v-slot="{ errors , invalid, validated }">
               <div class="form-group ma-form">
                <span>
                  <label class="ma-form__label">{{ $t('message[\'iap.verification-code\']') }}</label>
                  <input type="password" class="form-control ma-form__input" v-model="payload.verification_code"/>
                  <p class="app-error-msg">
                    <span v-if="validationErrors.verification_code">{{validationErrors.verification_code[0]}}</span>
                    <span v-else-if="errors[0]">{{errors[0]}}</span>
                  </p>
                </span>
              </div>
                      </ValidationProvider>
              <button type="submit"
                      :disabled="invalid"
                      style="margin-bottom: 60px; margin-top: 30px;"
                      class="btn btn-primary btn-main">{{ $t('message[\'iap.account.next_btn\']') }}</button>
            </form>
            </ValidationObserver>
          </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IAPService from '../../../../services/IAPService'
import CountryService from '../../../../services/CountryService'
import RegionService from '../../../../services/RegionService'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import AppService from '../../../../services/AppService'
import CountryCodes from '../../../../config/country-codes'
import lodash from 'lodash'
import InputPassword from '../../../global/inputs/InputPassword'
import TextBodyExtraSmall from '../../../global/typography/TextBodyExtraSmall'

export default {
  name: 'AccountDetails',
  watch: {
    payload: {
      handler (val) {
        if (val.first_name === 'iaptest') {
          this.testing = true
        }
      },
      deep: true
    },
    testing: async function (val) {
      if (val) {
        // setup test env
        this.payload.last_name = 'user'
        this.payload.email = 'iaptest@macroactive.com'
        this.payload.confirm_email = 'iaptest@macroactive.com'
        this.payload.password = '123456'
        this.payload.confirm_password = '123456'

        this.clientDetails.country = 'Invalid country'
        this.clientDetails.city = 'undefined city'

        this.payload.country = ''
        this.payload.region = ''
        this.payload.region_name = this.clientDetails.city

        this.setClientCountry().catch(() => {
          this.handleMountError('Client country set failed')
        })
      }
    }
  },
  async mounted () {
    this.setPageData()

    // set client location by IP
    await this.setClientDetails().catch(() => {
      this.handleMountError('Client location set failed')
    })

    // fetching country list
    await this.setCountryList().catch(() => {
      this.handleMountError('Countries fetch failed')
    })

    // setting up county by mapping with list
    await this.setClientCountry().catch(() => {
      this.handleMountError('Client country set failed')
    })

    // fetching region list for the country
    await this.setRegionList().catch((sds) => {
      this.handleMountError('Regions fetch failed')
    })

    // setting up region by mapping with list
    await this.setClientRegion().catch(() => {
      this.handleMountError('Client region set failed')
    }).finally(() => {
      this.hideLoading()
    })

    // execute listeners
    this.listeners()
  },
  components: {
    TextBodyExtraSmall,
    InputPassword,
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      passwordStrength: '',
      passwordStrengthMessage: '',
      countries: [],
      regions: [],
      countryNameMap: {
        'Brunei Darussalam': 'Brunei',
        Congo: 'Republic of the Congo',
        'Congo, the Democratic Republic of the': 'Democratic Republic of the Congo',
        'Holy See (Vatican City State)': 'Vatican',
        'Iran, Islamic Republic of': 'Iran',
        'Korea, Democratic People\'s Republic of': 'South Korea',
        'Korea, Republic of': 'North Korea',
        'Libyan Arab Jamahiriya': 'Libya',
        'Macedonia, the Former Yugoslav Republic of': 'Macedonia',
        'Micronesia, Federated States of': 'Micronesia',
        'Moldova, Republic of': 'Moldova',
        'Palestinian Territory, Occupied': 'Palestinian Territory',
        'Russian Federation': 'Russia',
        'Syrian Arab Republic': 'Syria',
        'Taiwan, Province of China': 'Taiwan',
        'Tanzania, United Republic of': 'Tanzania',
        'Timor-Leste': 'Timor Leste',
        'Virgin Islands, British': 'British Virgin Islands',
        'Virgin Islands, U.S.': 'U.S. Virgin Islands',
        'Cote D\'Ivoire': 'Ivory Coast',
        'Czech Republic': 'Czechia',
        'Lao People\'s Democratic Republic': 'Laos',
        'Saint Martin (French part)': 'Saint Barthelemy',
        'Viet Nam': 'Vietnam',
        'Falkland Islands (Malvinas)': 'Falkland Islands'
      },
      countryCodes: CountryCodes,
      showVerificationCode: false,
      payload: {
        first_name: '',
        last_name: '',
        email: '',
        confirm_email: '',
        password: '',
        confirm_password: '',
        country: '',
        region: '',
        region_name: '',
        verification_code: ''
      },
      validationErrors: {
        first_name: '',
        last_name: '',
        email: '',
        confirm_email: '',
        password: '',
        confirm_password: '',
        country: '',
        region: '',
        region_name: '',
        verification_code: ''
      },
      productDetails: {
        name: '',
        localizedPrice: ''
      },
      clientDetails: {
        country: '',
        city: ''
      },
      testing: false
    }
  },
  beforeDestroy () {
    this.destroyListeners()
  },
  methods: {
    passwordIsStrong () {
      this.passwordStrength = 'strong'
      this.passwordStrengthMessage = this.$i18n.t('message["sign-up.form.password-strength-strong"]')
    },
    passwordIsWeak () {
      this.passwordStrength = 'weak'
      this.passwordStrengthMessage = this.$i18n.t('message["sign-up.form.password-strength-weak"]')
    },
    handleMountError (message) {
      this.logError('SignUp.IAPError', 'Form Error: ' + message, {}, false)
      this.goBack()
    },
    logError (type, message, payload = {}, show = true) {
      if (!show) return
      this.logEvent(type, {
        error: message,
        payload: payload
      })
      IAPService.logError(message, payload, show)
    },
    goBack () {
      this.$router.push('/login')
    },
    countryChange () {
      this.payload.region_name = ''
      this.payload.region = ''
      this.setRegionList()
    },
    regionChange (e) {
      this.payload.region_name = e.target.options[e.target.options.selectedIndex].text
    },
    setPageData () {
      let iapService = new IAPService()
      iapService.setAccountInitStack()
      this.productDetails = iapService.getProductData()
    },
    /**
       * Set client region details by IP
       * Note: To limit information capturing apple requested us not to ask country and region from customers during app submission so we decided to do an ip look up to fulfil the API requirement
       * @returns {Promise<unknown>}
       */
    setClientDetails () {
      return new Promise((resolve, reject) => {
        let appService = new AppService()
        appService.getIPDetails()
          .then((details) => {
            this.clientDetails.country = lodash(this.countryCodes).get(details.country, '')
            this.clientDetails.city = details.city

            this.logEvent('Dev.SignUp.ClientIPRegionDetails', {
              country: this.clientDetails.country,
              city: this.clientDetails.city
            }, false)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    setClientCountry () {
      return new Promise((resolve, reject) => {
        // set country by client ip details
        this.countries.forEach((value) => {
          if (
            value.text.trim() === this.clientDetails.country.trim() ||
              // check names map list if not original strings does not match
              (value.text in this.countryNameMap &&
                this.countryNameMap[value.text] === this.clientDetails.country)
          ) {
            this.payload.country = parseInt(value.id) || value.id
          }
        })

        // ** temp fix till stable solution for region select
        if (!this.payload.country || (this.payload.country && this.payload.country === '')) {
          this.payload.country = lodash(this.countries.filter(country => country.text === 'New Zealand')).get('0.id')
          this.clientDetails.city = 'New Zealand'
        }
        // ********

        if (!this.payload.country || (this.payload.country && this.payload.country === '')) {
          reject(Error('Country name (' + this.clientDetails.country + ' not found in the list'))
        }
        resolve()
      })
    },
    setClientRegion () {
      return new Promise((resolve, reject) => {
        if (!this.clientDetails.city || (this.clientDetails.city && this.clientDetails.city === '')) {
          reject(Error('Region name (' + this.clientDetails.city + ' not found'))
        }

        // set region by client ip details
        this.payload.region_name = this.clientDetails.city

        // set country by client ip details
        this.regions.forEach((value) => {
          if (value.text.trim() === this.clientDetails.city.trim()) {
            this.payload.region = value.id
          }
        })
        resolve()
      })
    },
    setCountryList () {
      return new Promise((resolve, reject) => {
        let countryService = new CountryService()
        countryService.setCountries()
          .then(res => {
            let countries = []
            let allCountries = countryService.getCountries()
            Object.keys(allCountries).forEach(function (value, index, array) {
              countries.push({id: value, text: allCountries[value]})
            })
            this.countries = countries
            this.setClientDetails()
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    setRegionList () {
      return new Promise((resolve, reject) => {
        let countryService = new CountryService()
        let countryName = countryService.getCountryById(this.payload.country)
        let regionService = new RegionService()
        regionService.setRegions()
          .then(res => {
            let regions = []
            let allRegions = regionService.getRegionsByCountryId(this.payload.country)
            Object.keys(allRegions).forEach(function (value, index, array) {
              if (countryName.toString() !== allRegions[value].toString()) {
                regions.push({id: value, text: allRegions[value]})
              }
            })
            this.regions = regions
            resolve()
          }).catch((error) => {
            reject(error)
          })
      })
    },
    submitAccountDetails () {
      if (this.passwordStrength === 'weak') {
        return
      }
      if ((
        this.regions.length === 0 ||
        !this.payload.region ||
        this.payload.region.trim() === ''
      )) {
        delete this.payload.region
      }
      this.$store.commit('showLoading')
      let iapService = new IAPService()
      iapService.submitMemberDetails(this.payload).catch(err => {
        if (err.message === 'user-already-registered') {
          let loginHTML = '<span class="login rc-text-content--style-underline rc-text-content--weight-extra-bold">' + this.$i18n.t("message['sign-up.register.login']") + '</span>'
          this.$refs.form.setErrors({email: [
            this.$i18n.t("message['sign-up.form.email-already-registered-error']", {login: loginHTML})
          ]})
        }
        this.hideLoading()
      })
    },
    listeners () {
      document.addEventListener('iap-message', this.iapStatusCheck)
    },
    iapStatusCheck (ev) {
      this.showVerificationCode = false
      switch (ev.detail.status) {
        case 'account_create_validation':
          this.validationErrors = ev.detail.data.errors
          IAPService.logError('Validation error', this.validationErrors, false)
          this.$store.commit('hideLoading')
          break
        case 'account_conflict':
          this.showVerificationCode = true
          this.validationErrors.verification_code = [ev.detail.data.reason]
          if (ev.detail.data.reason === 'verification-code-required') {
            this.validationErrors.verification_code = [this.$i18n.t('message["iap.verification-code.required"]')]
          } else if (ev.detail.data.reason === 'verification-code-incorrect') {
            this.validationErrors.verification_code = [this.$i18n.t('message["iap.verification-code.invalid"]')]
          } else if (ev.detail.data.reason === 'verification-code-outdated') {
            this.validationErrors.verification_code = [this.$i18n.t('message["iap.verification-code.expired"]')]
          }
          IAPService.logError('Account conflict', {}, false)
          this.$store.commit('hideLoading')
          break
        case 'subscription_created':
          this.logEvent('SignUp.IAPComplete', {
            plan_name: this.productDetails.name,
            price: this.productDetails.localizedPrice
          })
          this.loginAndRedirect(ev.detail.data.subscriptionId)
          break
        case 'timeout':
          this.logError('SignUp.IAPError', 'Form Timeout', ev.detail.data, false)
          this.$router.push('/login')
          break
        case 'error':
          this.logError('SignUp.IAPError', 'Form Error', ev.detail.data, false)
          this.$router.push('/login')
          break
      }
    },
    destroyListeners () {
      document.removeEventListener('iap-message', this.iapStatusCheck)
    },
    emailErrorClick (e) {
      if (e.srcElement.classList.contains('login')) {
        this.$router.push('/login')
      }
    },
    loginAndRedirect (subscriptionId) {
      $.ajax({
        url: '/login',
        method: 'POST',
        headers: {
          'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: {email: this.payload.email, password: this.payload.password, remember: 1},
        success: (result, code, ex) => {
          this.$router.push('/subscriptions/' + subscriptionId + '/settings')
        },
        error: (xhr, ajaxOptions, thrownError) => {
          this.logEvent('SignUp.IAPError', {
            error: 'Form Error: IAP login failed'
          }, false)
          IAPService.logError('IAP login failed', {
            email: this.payload.email,
            password: this.payload.password,
            subscription: subscriptionId
          })
          this.$store.commit('hideLoading')
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
