<template>
  <popup
    v-if="visible"
    :visible="visible"
    @hide="closePopup"
    modal-class="gc-popup-exercise-swap"
    ref="exerciseSwapModal"
  >
    <popup-title>
      <popup-button-close @hide="closePopup"/>
    </popup-title>

      <popup-head class="text-center">
        <popup-text-heading >{{pageData.title}}</popup-text-heading>
      </popup-head>
    <popup-body class="gc-popup-meal-swap__body">
      <popup-content>
          <component  @changeParentTitle="changeTitle"
                               @injurySwapSubmit="injurySwapSubmit"
                               @contactSupportSwapSubmit="contactSupportSwapSubmit"
                               @showInjuries="showInjuries"
                               @successRedirect="afterSuccessRedirect"
                               @successReload="afterSuccessReload()"
                               @swapSubmit="swapSubmit" v-if="!loading"
                               :message="successMessage"
                                @content-change="contentChanged()"
                                :trainerEmail="trainerEmail"
                               v-bind:is="dynamicComponent"></component>
          <loading-buffer v-if="loading"></loading-buffer>
          <div class="app-error-msg" style="width: auto" v-if="hasError">
            {{$t('message["swap.exercise-response-error"]',[trainerEmail])}}
          </div>
      </popup-content>
    </popup-body>
  </popup>
</template>

<script>
import Options from './PopupSwapOptionsArea'
import Success from './PopupSwapSuccessArea'
import Injuries from './PopupSwapInjuriesArea'
import ContactSupport from './PopupSwapContactSupportArea'
import RequestSuccess from './PopupSwapRequestSuccessArea'
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import Popup from '../../../../global/popups/Popup'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupTextBody from '../../../../global/popups/popup-sub-components/PopupTextBody'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import WorkoutService from '../../../../../services/WorkoutService'
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import ProfileService from '../../../../../services/ProfileService'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'ExerciseSwapMain',
  props: {
    visible: {
      default: false
    },
    exerciseName: {
      default: ''
    },
    canSwap: {
      default: true
    }
  },
  mixins: [popupAnimationMixin],
  components: {
    LoadingBuffer,
    Popup,
    PopupBody,
    PopupButtonClose,
    PopupTextBody,
    PopupHead,
    PopupTextHeading,
    PopupTitle,
    PopupContent
  },
  data: function () {
    return {
      params: this.$route.query,
      imagesUrl: this.$appConfig.imagesUrl,
      trainerEmail: '',
      components: {
        options: Options,
        success: Success,
        injuries: Injuries,
        contactSupport: ContactSupport,
        requestSuccess: RequestSuccess
      },
      pageData: {
        title: ''
      },
      activeComponent: '',
      successMessage: '',
      loading: false,
      hasError: false,
      swapSuccess: false
    }
  },
  mounted () {
    this.setInitComponent()
    const profileService = new ProfileService()
    profileService.setProfileData()
      .then(data => {
        data = data.data
        if (typeof data.settings_manager.trainer.support_email !== 'undefined') {
          this.trainerEmail = data.settings_manager.trainer.support_email
        }
      })
  },
  computed: {
    dynamicComponent: function () {
      return this.components[this.activeComponent]
    }
  },
  watch: {
    dynamicComponent: function () {
      this.contentChanged()
    }
  },
  methods: {
    ...mapMutations({
      unsetLogDataInStore: 'workoutLogStore/unsetLogFormData',
      setCurrentExercise: 'workoutLogStore/setCurrentExercise',
      removeWorkoutLog: 'singleExercisePageStore/removeWorkoutLog'
    }),
    ...mapActions({
      removeWorkoutLog: 'singleExercisePageStore/removeWorkoutLog'
    }),
    setInitComponent () {
      if (this.canSwap) {
        this.setActiveComponent('options')
      } else {
        this.setActiveComponent('contactSupport')
      }
    },
    contentChanged () {
      this.$nextTick(() => {
        this.$refs.exerciseSwapModal.setContentHeight()
        setTimeout(() => {
          this.$refs.exerciseSwapModal.setContentHeight()
        }, 50)
      })
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      if (this.swapSuccess) {
        this.afterSuccessRedirect()
      } else {
        this.closeAnimatedPopup(() => {
          // TODO: refactor this to an emit
          this.$parent.hideExerciseSwapPopup()
        })
      }
    },
    unsetWorkoutLogCache () {
      const exID = parseInt(this.params.exercise)
      this.setCurrentExercise(exID)
      this.unsetLogDataInStore(exID)
      this.removeWorkoutLog()
    },
    contactSupportSwapSubmit () {
      this.bufferShow(this.$i18n.t('message["general.swapping"]'))
      this.hasError = false
      const workoutService = new WorkoutService()

      this.logEvent('SwappedExercise', {

        reason: 'static-exercise',
        exerciseID: this.params.exercise,
        exerciseName: this.exerciseName
      }, false)
      workoutService.submitSwapExercise(this.params.workout, this.params.exercise, null).then(() => {
        this.successMessage = this.$i18n.t('message["swap.exercise-pending-message"]')
        this.setActiveComponent('requestSuccess')
        this.swapSuccess = true
      }).catch((err) => {
        if (err.response.status === 422) {
          this.successMessage = this.$i18n.t('message["swap.exercise-pending-message"]')
          this.setActiveComponent('requestSuccess')
          this.swapSuccess = true
          this.trainerEmail = ''
        } else {
          this.setInitComponent()
          this.hasError = true
        }
      })
    },

    swapSubmit (type) {
      this.bufferShow(this.$i18n.t('message["general.swapping"]'))
      this.hasError = false
      const workoutService = new WorkoutService()
      this.logEvent('SwappedExercise', {
        reason: type,
        exerciseID: this.params.exercise,
        exerciseName: this.exerciseName
      }, false)
      workoutService.submitSwapExercise(this.params.workout, this.params.exercise, type).then(() => {
        this.successMessage = this.$i18n.t("message['swap.exercise-has-been-swapped']")
        this.setActiveComponent('success')
        this.swapSuccess = true
      }).catch((err) => {
        if (err.response.status === 422) {
          this.successMessage = this.$i18n.t('message["swap.exercise-pending-message"]')
          this.setActiveComponent('success')
          this.swapSuccess = true
          this.trainerEmail = ''
        } else {
          this.setInitComponent()
          this.hasError = true
        }
      })
    },
    showInjuries () {
      this.hasError = false
      // this.changeTitle(this.$i18n.t('message["general.areas-affected"]'))
      this.activeComponent = 'injuries'
    },
    injurySwapSubmit (injuries, message) {
      this.hasError = false
      this.bufferShow()
      this.logEvent('SwappedExercise', {
        reason: 'injury',
        exerciseID: this.params.exercise,
        exerciseName: this.exerciseName
      }, false)
      const workoutService = new WorkoutService()
      const self = this
      workoutService.submitSwapInjuryRequestExercise(this.params.workout, this.params.exercise, injuries, message).then(function () {
        self.setActiveComponent('requestSuccess')
        self.swapSuccess = true
      }).catch((err) => {
        if (err.response.status === 422) {
          this.successMessage = this.$i18n.t('message["swap.exercise-pending-message"]')
          this.setActiveComponent('success')
          this.swapSuccess = true
          this.trainerEmail = ''
        } else {
          this.setInitComponent()
          this.hasError = true
        }
      })
    },
    bufferShow (title = '') {
      this.changeTitle(title)
      this.loading = true
    },
    bufferHide (title = '') {
      this.changeTitle(title)
      this.loading = false
    },
    setActiveComponent (component, title = '') {
      this.activeComponent = component
      this.bufferHide(title)
    },
    afterSuccessRedirect () {
      this.closeAnimatedPopup(() => {
        this.unsetWorkoutLogCache()
        this.$emit('successHandle')
        this.swapSuccess = true
      })
    },
    afterSuccessReload () {
      this.closeAnimatedPopup(() => {
        this.unsetWorkoutLogCache()
        this.$emit('successReloadHandle')
        this.swapSuccess = true
      })
    },
    changeTitle (title) {
      this.pageData.title = title
    }
  }
}
</script>
