<template>
  <page-secondary  :header="headerOptions" class="pc-contact-us">
    <page-container v-if="showPage" class="pc-contact-us__container">
      <text-body-small class="pc-contact-us__description" v-if="description" v-html="description"></text-body-small>
      <validation-observer ref="form" v-slot="{ handleSubmit, invalid }">
        <form
          @submit.prevent="handleSubmit(submitForm)"
          class="pc-contact-us__form"
        >
          <div class="pc-contact-us__input-container">
            <text-body-small
              class="pc-contact-us__input-label pc-contact-us__input-label-name"
            >
              {{ $t("message['form.full-name']") }}
            </text-body-small>
            <validation-provider
              :name="$t('message[\'form.full-name\']')"
              rules="required"
              v-slot="{ errors }"
            >
              <text-input
                :name="$t('message[\'form.full-name\']')"
                v-model="formData.fullName"
                :error-message="errors[0]"
                class="pc-contact-us__input pc-contact-us__input-name"
                :placeholder="$t('message[\'form.full-name\']')"
              />
            </validation-provider>
          </div>

          <div class="pc-contact-us__input-container">
            <text-body-small
              class="pc-contact-us__input-label pc-contact-us__input-label-email"
            >
              {{ $t("message['auth.email']") }}
            </text-body-small>
            <validation-provider
              :name="$t('message[\'auth.email\']')"
              rules="required|email"
              v-slot="{ errors }"
            >
              <text-input
                :error-message="errors[0]"
                v-model="formData.email"
                :name="$t('message[\'auth.email\']')"
                class="pc-contact-us__input pc-contact-us__input-email"
                :placeholder="$t('message[\'auth.email\']')"
              />
            </validation-provider>
          </div>

          <div class="pc-contact-us__input-container">
            <text-body-small
              class="pc-contact-us__input-label pc-contact-us__input-label-subject"
            >
              {{ $t("message['form.subject']") }}
            </text-body-small>
            <validation-provider
              rules="required"
              :name="$t('message[\'form.subject\']')"
              v-slot="{ errors }"
            >
              <text-input
                :error-message="errors[0]"
                v-model="formData.subject"
                :name="$t('message[\'form.subject\']')"
                class="pc-contact-us__input pc-contact-us__input-subject"
                :placeholder="$t('message[\'form.subject\']')"
              />
            </validation-provider>
          </div>

          <div class="pc-contact-us__input-container">
            <text-body-small
              class="pc-contact-us__input-label pc-contact-us__input-label-message"
            >
              {{ $t("message['form.message']") }}
            </text-body-small>
            <validation-provider
              :name="$t('message[\'form.message\']')"
              rules="required"
              v-slot="{ errors }"
            >
              <text-area
                :error-message="errors[0]"
                v-model="formData.message"
                :name="$t('message[\'form.message\']')"
                class="pc-contact-us__input pc-contact-us__input-message"
                :placeholder="$t('message[\'form.message\']')"
              />
            </validation-provider>
          </div>

          <page-fixed-footer :unfixed-on-tablet="true">
            <button-primary
              class="pc-contact-us__button-submit"
              :disabled="invalid"
              type="submit"
              :text="$t('message[\'form.send-message\']')"
            />
          </page-fixed-footer>
        </form>
      </validation-observer>
    </page-container>
    <popup-contact-us-success v-if="showPopup" :visible="showPopup" @close="redirect, showPopup = false"/>
  </page-secondary>
</template>

<script>
import BaseComponent from '../global/base/BaseComponent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ContactService from '../../../../services/ContactService'
import ContentPageFactoryService from '../../../../services/ContentPageFactoryService'
import '../../../../helpers/validation'
import PageSecondary from '../../../global/pages/PageSecondary'
import PageFixedFooter from '../../../global/pages/PageFixedFooter'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import TextInput from '../../../global/inputs/TextInput'
import TextArea from '../../../global/inputs/TextArea'
import ButtonPrimary from '../../../global/buttons/ButtonPrimary'
import PopupContactUsSuccess from './page-components/PopupContactUsSuccess'
import TextBodySmall from '../../template-2/global/typography/TextBodySmall'
import TextBodyExtraSmall from '../../template-2/global/typography/TextBodyExtraSmall'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'PageContactUs',
  extends: BaseComponent,
  components: {
    TextBodyExtraSmall,
    TextBodySmall,
    ValidationProvider,
    ValidationObserver,
    PageFixedFooter,
    PageSecondary,
    PageContainer,
    TextInput,
    TextArea,
    ButtonPrimary,
    PopupContactUsSuccess
  },
  data: function () {
    return {
      state: 'init',
      page: 'contact',
      showPopup: false,
      showPage: true,
      description: '',
      headerOptions: {
        show: true,
        left: 'menu',
        right: '',
        rightDisabled: true,
        helpContent: '',
        modifiers: ['small-title', 'with-bg-color'],
        formData: {
          image: ''
        },
        mainTitle: ''
      },
      formData: {
        fullName: '',
        email: '',
        subject: '',
        message: ''
      }
    }
  },
  mounted () {
    this.loadPageData()
  },
  methods: {
    loadPageData () {
      const contentPageFactory = new ContentPageFactoryService()
      const page = contentPageFactory.getPage(this.page)
      page.setPageData().then(() => {
        this.setPageTitle(page.pageData.page.title)
        this.headerOptions.mainTitle = page.pageData.page.title
        this.description = page.pageData.page.description
      }).finally(() => {
        this.hideLoading()
        pageReadyEvent()
        pageReadyEvent()
      })
    },
    redirect () {
      this.$router.push({
        path: this.$appConfig.appUrlList.dashboard
      })
    },
    submitForm () {
      this.showLoading()
      if (this.state === 'submitted') {
        return
      }
      this.state = 'submitted'
      this.error = ''
      const contactService = new ContactService()
      contactService
        .submitContact(this.formData)
        .then(() => {
          this.state = 'success'
          this.showPopup = true
          this.showPage = false
          setTimeout(() => {
            this.showPopup = false
            this.redirect()
          }, 3000)
        })
        .catch((error) => {
          this.state = 'error'
          if (error.response.status === 422) {
            if (
              typeof error.response.data.type !== 'undefined' &&
              error.response.data.type === 'logic'
            ) {
              this.error =
                error.response.data.errors[0] ||
                this.$i18n.t('message["general.try-again"]')
            } else {
              this.$refs.form.setErrors(error.response.data.errors)
            }
          } else {
            this.error = this.$i18n.t('message["general.try-again"]')
          }
        }).finally(() => {
          this.hideLoading()
        })
    }
  }
}
</script>

<style scoped></style>
