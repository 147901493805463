<template>
  <button-base :borderRadius="'sm1'" :class="componentClasses" :type="'solid'" @click="clicked()">
    <slot></slot>
    <text-body-regular :class="textClass" :weight="'extra-bold'" :lineHeight="'multi'">{{text}}</text-body-regular>
    <text-body-extra-small class="gc-button-primary__sub-text" :lineHeight="'multi'" v-if="hasSubText">{{subText}}</text-body-extra-small>
  </button-base>
</template>

<script>

import ButtonBase from '../../root/ButtonBase'
import TextBodyRegular from '../typography/TextBodyRegular'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'

export default {
  name: 'ButtonPrimary',
  components: {
    TextBodyExtraSmall,
    TextBodyRegular,
    ButtonBase
  },
  props: {
    /**
     * The main text of the button
     */
    text: {
      default: ''
    },
    /**
       * The sub text of the button
       */
    subText: {
      default: ''
    },
    /**
       * The disabled state of the button
       */
    disabled: {
      default: false
    }
  },
  computed: {
    hasSubText: function () {
      return this.subText.replace(/\s/g, '') !== ''
    },
    textClass: function () {
      return {
        'gc-button-primary__text': true,
        'gc-button-primary__text--with-sub-text': this.hasSubText
      }
    },
    componentClasses: function () {
      return {
        'gc-button-primary': true,
        'gc-button-primary--disabled': this.disabled,
        'gc-button-primary--with-sub-text': this.hasSubText
      }
    }
  },
  methods: {
    /**
       * function to emit the click event
       */
    clicked () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped></style>
