<template>
    <page :header="header" class="gc-page-height">
      <validation-observer ref="form" v-slot="{handleSubmit, invalid}">
          <page-container>
              <div class="gc-page-height__header">
                  <text-heading-4
                      class="gc-page-height__title text-center"
                      weight="extra-bold"
                      lineHeight="multi">{{title.main}}</text-heading-4>
              </div>
              <div class="gc-page-height__content">
                  <form id="form-input-height"
                      class="gc-page-height__form"
                      @submit.prevent="handleSubmit(nextComponent)">
                          <validation-provider v-slot="{ errors }" :rules="validations" :vid="'height'" name="height">
                              <input-height
                                  class="gc-page-height__input-height"
                                  :placeholder="placeholder"
                                  :measurementSystem="height.preference"
                                  :measurement-system-changeable="switchPreference"
                                  v-model="height"
                                  :error-messages="errors[0]">
                              </input-height>
                          </validation-provider>
                  </form>
              </div>
          </page-container>
          <page-footer :form="'form-input-height'" :validated="invalid"></page-footer>
        </validation-observer>
    </page>
</template>

<script>
import FormBaseComponent from '../../layout/template-1/global/widgets/forms/FormBaseComponent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ButtonPrimary from '../buttons/ButtonPrimary'
import InputHeight from '../inputs/InputHeight'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import TextHeading4 from '../typography/TextHeading4'
import PageContainer from './page-sub-components/PageContainer'
import Page from './PageForm'
import { setValuesMixin } from '../../layout/template-1/global/widgets/forms/helpers/SetValuesMixin'
import PageFooter from './page-sub-components/PageFormFooter'

export default {
  name: 'PageHeight',
  extends: FormBaseComponent,
  mixins: [ setValuesMixin ],
  props: {
    switchPreference: {
      default: true
    }
  },
  components: {
    Page,
    PageContainer,
    TextHeading4,
    ButtonPrimary,
    TextBodyExtraSmall,
    InputHeight,
    ValidationObserver,
    ValidationProvider,
    PageFooter
  },
  data: function () {
    return {
      header: {
        isTransparent: false,
        isFixed: false,
        show: false,
        left: '',
        right: '',
        rightBlink: false,
        modifiers: [],
        mainTitle: '',
        subTitle: ''
      },
      height: {
        preference: '',
        feet: null,
        inch: null,
        cm: null
      },
      placeholder: '',
      validations: ''
    }
  },
  beforeMount () {
    this.height = this.inputValue

    // to show placeholder values
    if ((this.height.inch === 0 && this.height.feet === 0) || (isNaN(this.height.inch) && isNaN(this.height.feet))) {
      this.height.inch = null
      this.height.feet = null
    }
    if (isNaN(this.height.cm) || this.height.cm === '') {
      this.height.cm = null
    }

    this.hideLoading()

    if (this.errors.height) {
      this.$refs.form.setErrors({
        height: this.errors.height,
        feet: this.errors.height,
        inch: this.errors.height
      })
    }
    this.$nextTick(() => {
      this.validations = 'height'
    })
  },
  watch: {
    height: {
      immediate: true,
      deep: true,
      handler: function () {
        if (this.height.preference === 'imperial') {
          this.placeholder = '0'
        } else if (this.height.preference === 'metric') {
          this.placeholder = '180.0'
        }
      }
    }
  },
  methods: {
    nextComponent () {
      this.$emit('submit', { key: this.inputKey, value: this.height })
    }
  }
}
</script>
