import FoodDiarySavedMealsService from '../../../../services/FoodDiarySavedMealsService'
import FoodDiaryService from '../../../../services/FoodDiaryService'
import { mapGetters } from 'vuex'
import moment from 'moment'
import DashBoardService from '../../../../services/DashboardService'
import NutritionService from '../../../../services/nutrition/NutritionService'
import logMealMixin from '@/mixins/component-mixins/page/food-diary/logMealMixin'

export default {
  mixins:[logMealMixin],
  props: {
    isActiveTab: {
      default: false
    },
    mealLog: {
      required: true
    }
  },
  data: function () {
    return {
      state: 'init',
      dayText: '',
      mealPlanID: 0,
      days: [],
      selectedDayID: 0,
      selectedOptionText: '',
      meals: [],
      searchString: '',
      currentDay: '',
      foodDiarySavedMealsService: new FoodDiarySavedMealsService(),
      foodDiaryService: new FoodDiaryService(),
      pagination: {
        currentPage: 0,
        hasMore: true
      },
      showNetCarbs: false,
      mealsToAdd: [],
      mealsToRemove: []
    }
  },
  computed: {
    ...mapGetters({
      previousRoute: 'getPreviousRoute'
    }),
    newMealCount: function () {
      return this.mealsToAdd.length || 0
    },
    isButtonDisabled: function () {
      return this.state === 'submitting' || (this.mealsToAdd.length === 0 && this.mealsToRemove.length === 0)
    },
    macroNutrients: function () {
      let macroNutrients = []
      // to insert data to nutrition array
      const protein = this.mealLog.required.macronutrients.proteins
      macroNutrients.push({
        unit: this.$i18n.t('message[\'general.g\']'),
        name: this.$i18n.t('message[\'general.proteins\']'),
        qut: protein
      })
      const fat = this.mealLog.required.macronutrients.fats
      macroNutrients.push({
        unit: this.$i18n.t('message[\'general.g\']'),
        name: this.$i18n.t('message[\'general.fats\']'),
        qut: fat
      })
      const carbs = this.mealLog.required.macronutrients.carbohydrates
      macroNutrients.push({
        unit: this.$i18n.t('message[\'general.g\']'),
        name: this.$i18n.t('message[\'general.carbs\']'),
        qut: carbs
      })
      if (this.mealLog.required.macronutrients.show_net_carbs === true) {
        const netCarbs = this.mealLog.required.macronutrients.net_carbohydrates
        macroNutrients.push({
          unit: this.$i18n.t('message[\'general.g\']'),
          name: this.$i18n.t('message[\'general.carbs-net\']'),
          qut: netCarbs
        })
      }

      return macroNutrients
    },
    calories () {
      return [{
        unit: '',
        name: this.$i18n.tc('message[\'general.calories\']', this.mealLog.required.calories),
        qut: this.mealLog.required.calories
      }]
    }
  },
  watch: {
    mealLog: {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.setMealPlanIDAndDays()
        this.dayText = moment(value.logged_date).format('ll')
        if (this.dayText === moment().format('ll')) {
          this.dayText = this.$i18n.t('message["general.today"]').toLowerCase()
        }
      }
    },
    searchString: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.pagination = {
          currentPage: 0,
          hasMore: true
        }
      }
      this.search()
    }
  },
  async  beforeMount () {
    await this.search()
  },
  mounted () {
    let dashBoardService = new DashBoardService()
    this.showNetCarbs = dashBoardService.isNetCarbsAsSeparateNutrient()
    this.setMealPlanIDAndDays()
    window.addEventListener('scroll', this.scrollListener)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.scrollListener)
  },
  methods: {
    getRestaurantName(meal){
      if(meal.meta && meal.meta.restaurantName){
        return meal.meta.restaurantName
      }
      return ''
    },
    getCustomMealMealMacros (meal) {
      return meal.recipes.reduce((accumulator, currentValue) => {
        Object.entries(accumulator).forEach(([key, val]) => {
          accumulator[key] = currentValue.macronutrients[key] + val
        })

        return accumulator
      }, {
        carbohydrates: 0,
        fats: 0,
        net_carbohydrates: 0,
        proteins: 0,
        show_net_carbs: false
      })
    },
    getCustomMealMealCalories (meal) {
      return meal.recipes.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.calories
      }, 0)
    },
    isMealLogged (meal) {
      return this.mealLog.logged_meals.some(loggedMeal => {
        return loggedMeal.id === meal._id
      })
    },
    mealRemoved (meal) {
      let hasLoggedBefore = this.mealsToAdd.some(addedMeal => {
        return meal._id === addedMeal._id
      })
      if (hasLoggedBefore) {
        this.mealsToAdd = this.mealsToAdd.filter(addedMeal => {
          return meal._id !== addedMeal._id
        })
      } else {
        this.mealsToRemove.push(meal)
      }
    },
    mealUnsaved (meal) {
      this.meals = this.meals.filter(m => {
        return m._id !== meal._id
      })
    },
    mealAdded (meal) {
      let hasRemovedBefore = this.mealsToRemove.some(addedMeal => {
        return meal._id === addedMeal._id
      })
      if (hasRemovedBefore) {
        this.mealsToRemove = this.mealsToRemove.filter(addedMeal => {
          return meal._id !== addedMeal._id
        })
      } else {
        this.mealsToAdd.push(meal)
      }
    },
    changeTab (name) {
      this.$emit('change-tab', name)
    },
    setMealPlanIDAndDays () {
      let mealPlanService = new NutritionService()
      const latestMealPlan = mealPlanService.getLatestMealPlan()
      latestMealPlan.getData().then(() => {
        this.mealPlanID = latestMealPlan.getId()
        this.days = latestMealPlan.getDays()
        let selectedDay = this.getSelectedDayByRequiredMacronutrientsAndCalories()
        if (selectedDay && selectedDay.getId()) {
          this.selectedDayID = selectedDay.getId()
        }

        this.setSelectedOptionDayText()
      })
    },
    setDay (day) {
      this.currentDay = day
      this.setSelectedOptionDayText()
    },
    getSelectedDayByRequiredMacronutrientsAndCalories () {
      let currentRequired = this.mealLog.required
      return this.days.find(day => {
        return day.getCalories() === currentRequired.calories &&
          day.getMacroNutrients().carbohydrates === currentRequired.macronutrients.carbohydrates &&
          day.getMacroNutrients().fats === currentRequired.macronutrients.fats &&
          day.getMacroNutrients().proteins === currentRequired.macronutrients.proteins
      })
    },
    setSelectedOptionDayText () {
      let selectedDay = this.getSelectedDayByRequiredMacronutrientsAndCalories()
      if (selectedDay) {
        this.selectedOptionText = this.numberWordToNumeric(selectedDay.getName())
      } else {
        this.selectedOptionText = this.$i18n.t('message["food-diary.another-option"]')
      }
    },
    async search () {
      if (this.state === 'searching' || !this.pagination.hasMore) {
        return
      }
      this.state = 'searching'
      let foodDiarySavedMealsService = new FoodDiarySavedMealsService()
      const currentSearchString = this.searchString
      await foodDiarySavedMealsService.search(currentSearchString, (this.pagination.currentPage + 1))
      this.state = 'init'
      this.meals = foodDiarySavedMealsService.getMeals()
      this.pagination.hasMore = foodDiarySavedMealsService.hasMoreMeals()
      this.pagination.currentPage = foodDiarySavedMealsService.getCurrentPage()
      if (currentSearchString !== this.searchString) {
        this.pagination = {
          currentPage: 0,
          hasMore: true
        }
        this.search()
      }
    },
    scrollListener () {
      if (!this.isActiveTab) {
        return
      }
      // gap that should event trigger before scrolling to bottom
      const bottomGap = 100
      if ((window.innerHeight + window.scrollY) > document.body.offsetHeight - bottomGap) {
        this.search()
      }
    },
    setUserEventSavedMealAdded (name, hasImage) {
      let context = (this.previousRoute.path.includes('/dashboard')) ? 'dashboard' : 'food-diary'
      this.logEvent('FoodDiary.MealLogged', {
        'context': context,
        'selected_option': this.selectedOptionText,
        'is_saved': true,
        'is_custom': true,
        'is_today': (this.mealLog.logged_date === moment().locale('en').format('YYYY-MM-DD'))
      })
    },
    submit () {
      if (this.state === 'submitting') {
        return
      }
      this.state = 'submitting'
      let mealLog = JSON.parse(JSON.stringify(this.mealLog))
      this.mealsToAdd.forEach(meal => {
        this.setUserEventSavedMealAdded(meal.name, !!meal.recipes[0].image)
        mealLog.logged_meals.push({
          id: meal._id,
          time: moment().locale('en').format('HH:mm'),
          date: moment().locale('en').format('YYYY-MM-DD'),
          mealType: 'custom'
        })
      })
      mealLog.logged_meals = mealLog.logged_meals.filter(meal => {
        return !this.mealsToRemove.some(mealToRemove => {
          return mealToRemove._id === meal.id
        })
      })
      this.foodDiaryService.saveMealLog(mealLog).then(log => {
        this.mealLog = log.log
        this.$emit('log', log.log)
        this.$emit('success', {type: 'custom-meal', saved: false})
        this.state = 'init'
      })
    }
  }

}
