<template>
  <div :class="componentClasses" @click="clicked">
    <img
      v-if="image"
      :src="image"
      alt=""
      class="gc-card-exercise-overview__image"
      @error="setVideoThumb()"
    />
    <div class="gc-card-exercise-overview__overlay"></div>
    <text-body-extra-small
      v-if="isGrouped"
      class="gc-card-exercise-overview__index"
      :lineHeight="'multi'">
      {{$t('message["workouts.exercise"]')}} {{index}}
    </text-body-extra-small>
    <icon-complete-filled class="svg-green-check-btn gc-card-exercise-overview__check-green"
                          v-if="exercise.getIsCompleted()"
                          :size="'md1'"/>
    <div class="gc-card-exercise-overview__title">
    </div>
    <div class="gc-card-exercise-overview__card-container">
      <text-body-regular
        class="gc-card-exercise-overview__name"
        :weight="'extra-bold'"
        :lineHeight="'multi'">
        {{ getExerciseName() }}
      </text-body-regular>
      <text-body-extra-small
        class="gc-card-exercise-overview__muscles"
        :lineHeight="'multi'">
        {{ getExerciseMuscles() }}
      </text-body-extra-small>
      <badge-important
        class="gc-card-exercise-overview__set-type"
        v-if="setBadge">
        <text-body-extra-small :weight="'extra-bold'"
                               :lineHeight="'multi'">
          {{ exercise.getSetType().name }}
        </text-body-extra-small>
      </badge-important>
      <badge-important
        class="gc-card-exercise-overview__set-type gc-card-exercise-overview__set-type--circuit"
        v-if="isCircuit"
      >
        <text-body-extra-small
          :weight="'extra-bold'"
          :lineHeight="'multi'"
        >{{$t('message["workout.mode-circuit"]')}}</text-body-extra-small>
      </badge-important>
      <div class="gc-card-exercise-overview__reps-wrapper">
        <label-reps
          class="gc-card-exercise-overview__reps"
          v-if="exercise.getSetsRepsString()"
          :reps="exercise.getSetsRepsString()"></label-reps>
          <icon-info class="gc-card-exercise-overview__reps-info-icon" @click="iconInfoClicked" :size="'sm2'" v-if="exercise.isSetHavingFailureSetOrRepRanges()"/>
      </div>
    </div>
    <popup-exercise-reps v-if="showExerciseRepsPopup" :popup-visibility="showExerciseRepsPopup" @close="closePopup()"/>
  </div>
</template>

<script>
import IconCompleteFilled from '../../root/icons/IconCompleteFilled'
import LabelReps from '../labels/LabelReps'
import exerciseCardMixin from '../../../mixins/component-mixins/page/workout/exerciseCardMixin'
import TextBodyRegular from '../typography/TextBodyRegular'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import BadgeImportant from '../badges/BadgeImportant'
import IconInfo from '../../root/icons/IconInfo'
import PopupExerciseReps from '../popups/PopupExerciseReps'

export default {
  name: 'CardExerciseOverview',
  mixins: [exerciseCardMixin],
  components: {
    BadgeImportant,
    TextBodyExtraSmall,
    TextBodyRegular,
    IconCompleteFilled,
    LabelReps,
    IconInfo,
    PopupExerciseReps
  },
  props: {
    exercise: {
      required: true
    },
    index: {
      default: 1
    }
  },
  data () {
    return {
      showExerciseRepsPopup: false
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-card-exercise-overview': true,
        'gc-card-exercise-overview__card-image--disabled': (!this.exercise.getImage() || this.thumbLoading)
      }
    },
    setBadge: function () {
      return !this.isCircuit && ((this.getExerciseMusclesCount() === 1 && this.getExerciseMuscles() !== this.exercise.getSetType().name) || this.getExerciseMusclesCount() > 1)
    }
  },
  methods: {
    clicked (event) {
      /**
       * Emit click event only if it's clicked outside of the info icon
       */
      if (event.target.classList.length === 0) {
        return
      }
      this.$emit('click', this.exercise.getID())
    },
    iconInfoClicked () {
      this.showExerciseRepsPopup = true
    },
    closePopup () {
      this.showExerciseRepsPopup = false
    }
  }
}
</script>

<style scoped></style>
