<template>
  <div :class="componentClasses">
    <input
      v-bind="$attrs"
      class="gc-text-input__input"
      :maxlength="maxlength"
      :readonly="readonly"
      :placeholder="placeholder"
      :type="inputType"
      :disabled="disabled"
      v-model="newValue"
      :inputmode="inputMode"
      :step="step"
      :min="min"
      :max="max"
      @keyup="keyUp"
      @keypress="keyPressed"
      @change="emitValue"
      ref="input"
    />
    <text-body-extra-small
      v-if="!hideErrorMessage && errorMessage.length > 0"
      :line-height="'multi'"
      class="gc-text-input__error" v-html="errorMessage || ''">
    </text-body-extra-small>
  </div>
</template>

<script>
import TextContent from '../../root/TextContent'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'

export default {
  name: 'TextInput',
  components: {
    TextBodyExtraSmall,
    TextContent
  },
  data () {
    return {
      newValue: ''
    }
  },
  props: {
    /**
    * to change type of input
    * expected values - text, numeric, search, number, email, date, time, password, datetime-local, url
    */
    type: {
      default: 'text',
      validator: function (value) {
        let supportedTypes = ['text', 'numeric', 'search', 'number', 'email', 'date', 'time', 'password', 'datetime-local', 'url', 'decimal']
        return supportedTypes.includes(value)
      }
    },
    /**
    * to change placeholder of input
    * expected value - string
    */
    placeholder: {
      default: ''
    },
    mask: {
      default: null
    },
    /**
    * to set an error message
    * expected value - string
    */
    errorMessage: {
      default: ''
    },

    hideErrorMessage: {
      default: false
    },
    /**
    * to change the value
    * expected value - string
    */
    value: {
      default: ''
    },
    /**
    * to specify the max no of decimal places the number can have
    * expected value - string
    */
    step: {
      default: 'any'
    },
    /**
    * to specify which set of characters are allowed to input
    * expected value - string
    */
    onkeyup: {
      default: ''
    },
    /**
    * to specify the minimum number that can be input
    * expected value - string
    */
    min: {
      default: ''
    },
    /**
    * to specify the maximum number that can be input
    * expected value - string
    */
    max: {
      default: ''
    },
    /**
    * to specify which set of characters are allowed to input
    * expected value - string
    */
    onkeypress: {
      default: null
    },
    /**
     * to specify the maximum no of decimal places the input number can have
     */
    maxDecimalPoints: {
      default: 2
    },
    /**
    * to change the state
    * expected value - true, false
    */
    disabled: {
      default: false
    },
    /**
    * change to read only
    * expected value - true, false
    */
    readonly: {
      default: false
    },
    /**
    * to set a max length
    * expected value - integer
    */
    maxlength: {
      default: 1000
    },
    inputFontSize: {
      type: Number,
      default: 16
    },
    inputLineHeight: {
      type: Number,
      default: 1.5
    },
    inputFontWeight: {
      type: Number,
      default: 700
    },
    mobileInputMode: {
      default: ''
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-text-input': true,
        'gc-text-input--error': this.errorMessage.length > 0
      }
    },
    inputType: function () {
      if (this.type === 'numeric') {
        return 'number'
      }
      if (this.type === 'decimal') {
        return 'text'
      }
      return this.type
    },
    inputMode: function () {
      if (this.mobileInputMode) {
        return this.mobileInputMode
      }
      let mode = 'text'
      switch (this.type) {
        case 'text':
          mode = 'text'
          break
        case 'numeric':
          mode = 'numeric'
          break
        case 'number':
          mode = 'tel'
          break
        case 'email':
          mode = 'email'
          break
        case 'url':
          mode = 'url'
          break
        case 'search':
          mode = 'search'
          break
        case 'tel':
          mode = 'tel'
          break
        case 'decimal':
          mode = 'decimal'
          break
        case 'password':
          mode = 'text'
          break
      }
      return mode
    }
  },
  watch: {
    /**
    * to set and return value of input
    */
    value: {
      handler: function () {
        this.newValue = this.value
      },
      immediate: true
    },
    newValue () {
      if (this.type === 'decimal' && (typeof this.newValue === 'string' && !this.newValue.endsWith('.') && !this.newValue.endsWith('0'))) {
        this.newValue = this.limitDecimal(this.newValue)
      }

      if (typeof this.newValue === 'string' && this.newValue.endsWith(',')) {
        this.newValue = this.newValue.replace(/,/g, '.')
      }
      this.$emit('input', this.newValue)
    }
  },
  mounted () {
    this.setInputCssValues()
  },
  methods: {
    keyUp (event) {
      if (this.onkeyup) {
        return this.onkeyup(event)
      }
    },
    keyPressed (event) {
      if (this.onkeypress) {
        return this.onkeypress(event)
      }
    },
    /**
    * to pass the value of input
    */
    emitValue () {
      this.$emit('change', this.newValue)
    },
    setInputCssValues () {
      const input = this.$refs.input
      input.style.fontSize = this.inputFontSize + 'px'
      input.style.fontWeight = this.inputFontWeight
      input.style.lineHeight = this.inputLineHeight
    },
    limitDecimal (val) {
      let stringVal = val.toString()
      const stringArray = stringVal.split('.')
      if (stringArray[1]) {
        return stringArray[0] + '.' + stringArray[1].substring(0, this.maxDecimalPoints)
      }
      return stringVal
    }
  }
}
</script>
