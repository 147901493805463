<template>
    <div :class="componentClasses">
                <text-body-regular v-if="showHeader" class="gc-input-group-ingredients__title" :weight="'extra-bold'" :lineHeight="'multi'">{{$t("message['general.foods.i.dislike']")}}</text-body-regular>
                <text-body-extra-small :lineHeight="'multi'" class="gc-input-group-ingredients__sub-title">
                  {{$t("message['profile.dietary-preferences.food-dont-like.description']")}}
                </text-body-extra-small>
      <input-action-search :placeholder="$t('message[\'general.search-ingredients\']')" v-model="search"/>
                <div class="gc-input-group-ingredients__parent-wrapper" v-for="groupedIngredient in groupedIngredients" :key="groupedIngredient.id">
                  <ingredients-parent-accordian :search="searchString.toLowerCase()" :disabled="disabled" @changed="changed()" v-model="selectedIngredients" :indredient="groupedIngredient"/>
                </div>
            </div>
        </template>
<script>
import IngredientsParentAccordian from './input-group-ingredients-sub-components/InputGroupIngredientsParentAccordian'
import TextBodyRegular from '../typography/TextBodyRegular'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import Select2 from './InputMultiSelect.vue'
import InputMultiSelectDropdown from './InputMultiSelectDropdown.vue'
import InputActionSearch from './InputActionSearch'

export default {
  name: 'InputGroupIngredients',
  components: {
    Select2,
    InputMultiSelectDropdown,
    TextBodyExtraSmall,
    TextBodyRegular,
    IngredientsParentAccordian,
    InputActionSearch

  },
  props: {
    showHeader: {
      default: false
    },
    groupedIngredients: {
      default: function () {
        return []
      }
    },
    ListIngredients: {
      default: function () {
        return []
      }
    },
    value: {
      type: Array,
      default: function () {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    searchEnabled: {
      default: true
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function () {
        this.selectedIngredients = this.value
      }
    },
    selectedIngredients: function () {
      this.$emit('input', this.selectedIngredients)
    }

  },
  data: function () {
    return {
      search: '',
      selectedIngredients: []
    }
  },
  computed: {
    searchString: function () {
      return this.search || ''
    },
    componentClasses: function () {
      return {
        'gc-input-group-ingredients': true,
        'gc-input-group-ingredients--with-search': !!this.search
      }
    }
  },
  methods: {
    changed () {
      this.$emit('changed')
    }
  }
}
</script>
