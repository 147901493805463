<template>
    <popup class="gc-popup-feedback" v-if="visible" :visible="visible" :no-close-on-backdrop="true" @hide="closePopup">
        <popup-title class="gc-popup-feedback__title">
            <popup-button-close @click="closePopup"></popup-button-close>
        </popup-title>
        <popup-body class="text-center">
            <popup-head>
                <popup-text-sub-heading class="gc-popup-feedback__heading">{{popupHeading}}</popup-text-sub-heading>
            </popup-head>

            <popup-content >
                <validation-observer ref="form" v-slot="{ handleSubmit }">
                    <form v-if="state=== 'init'" id="feedback-form" class="gc-popup-feedback__form" @submit.prevent="handleSubmit(doAction)">
                        <popup-text-body class="gc-popup-feedback__sub-heading">{{$t("message[\'popup.feed-back.sub-heading\']")}}</popup-text-body>
                        <validation-provider
                            rules="required|max:1000"
                            :name="$t('message[\'general.feedback\']')"
                            v-slot="{ errors }">
                            <text-area
                                class="gc-popup-feedback__form-text-area "
                                v-model="feedback"
                                :placeholder="textPlaceHolder"
                                :error-message="errors[0]"
                                :rows="3"></text-area>
                        </validation-provider>
                    </form>
                </validation-observer>

                <loading-buffer
                    v-if="state==='processing'"
                    class="gc-popup-feedback__loading-buffer"
                    height="40"></loading-buffer>
                <icon-complete-filled
                    v-else-if="state==='success'"
                    class="gc-popup-feedback__icon-success"
                    size="lg3"></icon-complete-filled>
                <popup-text-body
                    v-else-if="state==='failure'"
                    class="gc-popup-feedback__common-error-msg">{{$t('message[\'general.common-error-message\']')}}</popup-text-body>
            </popup-content>

            <popup-footer v-if="state==='init'">
              <button-primary
                  class="gc-popup-feedback__form-button-submit"
                  type="submit"
                  form="feedback-form"
                  :text="$t('message[\'popup.feed-back.button-text\']')"></button-primary>
            </popup-footer>
        </popup-body>
    </popup>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import { mapGetters, mapMutations } from 'vuex'
import WorkoutLogService from '../../../services/WorkoutLogService'
import Popup from '../popups/Popup'
import PopupBody from '../popups/popup-sub-components/PopupBody'
import PopupContent from '../popups/popup-sub-components/PopupContent'
import PopupHead from '../popups/popup-sub-components/PopupHead'
import PopupTitle from '../popups/popup-sub-components/PopupTitle'
import PopupButtonClose from '../popups/popup-sub-components/PopupButtonClose'
import PopupTextHeading from '../popups/popup-sub-components/PopupTextHeading'
import TextArea from '../../global/inputs/TextArea'
import ButtonPrimary from '../../global/buttons/ButtonPrimary'
import PopupFooter from '../popups/popup-sub-components/PopupFooter'
import LoadingBuffer from '../../layout/template-1/global/widgets/LoadingBuffer'
import IconCompleteFilled from '../../root/icons/IconCompleteFilled'
import PopupTextBody from './popup-sub-components/PopupTextBody'
import PopupTextSubHeading from './popup-sub-components/PopupTextSubHeading'

export default {
  name: 'PopupFeedBackNew',
  props: {
    visible: {
      default: false
    },
    title: {
      default: ''
    },
    textPlaceHolder: {
      default: ''
    },
    flag: {
      required: true
    }
  },
  mixins: [popupAnimationMixin],
  components: {
    PopupTextSubHeading,
    ValidationObserver,
    ValidationProvider,
    Popup,
    PopupBody,
    PopupHead,
    PopupContent,
    PopupTitle,
    PopupButtonClose,
    PopupTextHeading,
    TextArea,
    ButtonPrimary,
    PopupFooter,
    LoadingBuffer,
    IconCompleteFilled,
    PopupTextBody
  },
  data: function () {
    return {
      feedback: '',
      state: 'init' // init,processing,success,failure
    }
  },
  computed: {
    ...mapGetters({
      service: 'singleExercisePageStore/getCurrentWorkoutLogService'
    }),
    popupHeading () {
      if (this.state === 'init') {
        return this.title
      } else if (this.state === 'processing') {
        return this.$i18n.t('message[\'popup.feed-back.processing\']')
      } else if (this.state === 'success') {
        return this.$i18n.t('message[\'popup.feed-back.success\']')
      } else {
        return ''
      }
    }
  },
  methods: {
    doAction () {
      this.state = 'processing'
      let maAppMeta = $('meta[name=ma-application]').attr('content')
      const maAppMetaCustomer = $('meta[name=ma-application]').attr('data-customer-id') || 0
      maAppMeta = maAppMeta.split('.')
      const trainerID = maAppMeta[1] || 0
      const resellerID = maAppMeta[0] || 0
      const payload = {
        trainerID: trainerID,
        resellerID: resellerID,
        customer: maAppMetaCustomer,
        flag: this.flag,
        feedback: this.feedback,
        url: window.location.href
      }
      const service = new WorkoutLogService()
      service.sendFeedBack(payload).finally(() => {
        this.state = 'success'
        this.$emit('success')
        this.resetPopup()
      })
    },
    closePopup (e) {
      if (e && e.preventDefault) {
        e.preventDefault()
      }

      this.closeAnimatedPopup(() => {
        this.$emit('close')
        this.resetPopup()
      })
    },
    ...mapMutations({
      resetPopup: 'feedBackPopupStore/reset'
    })
  }
}
</script>
