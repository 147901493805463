<template>
  <div :class="componentClasses" @click="clicked()">
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.0234 3.71094H0.976562C0.437207 3.71094 0 4.14814 0 4.6875C0 5.22686 0.437207 5.66406 0.976562 5.66406H24.0234C24.5628 5.66406 25 5.22686 25 4.6875C25 4.14814 24.5628 3.71094 24.0234 3.71094ZM24.0234 11.5234H0.976562C0.437207 11.5234 0 11.9606 0 12.5C0 13.0394 0.437207 13.4766 0.976562 13.4766H24.0234C24.5628 13.4766 25 13.0394 25 12.5C25 11.9606 24.5628 11.5234 24.0234 11.5234ZM0.976562 19.3359H24.0234C24.5628 19.3359 25 19.7731 25 20.3125C25 20.8519 24.5628 21.2891 24.0234 21.2891H0.976562C0.437207 21.2891 0 20.8519 0 20.3125C0 19.7731 0.437207 19.3359 0.976562 19.3359Z"
        fill="black"
      />
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconMenu',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-menu': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
