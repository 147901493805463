<template>
<div :class="componentClasses" @click="clicked()">
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.65906 0.0801711C9.79508 -0.0267237 9.98555 -0.0267237 10.1216 0.0801711L18.6899 6.81374C18.7816 6.88585 18.8353 6.99672 18.8353 7.11416V17.6955C18.8353 18.3067 18.595 18.8928 18.1673 19.325C17.7396 19.7572 17.1595 20 16.5546 20H12.7518C12.75 20 12.7482 20 12.7463 20C12.7445 20 12.7427 20 12.7409 20H7.0396C7.03778 20 7.03595 20 7.03412 20C7.03229 20 7.03047 20 7.02865 20H3.22606C2.62117 20 2.04105 19.7572 1.61333 19.325C1.1856 18.8928 0.945312 18.3067 0.945312 17.6955V7.11416C0.945312 6.99672 0.998978 6.88585 1.09073 6.81374L9.65906 0.0801711ZM7.4108 19.2388H12.3697V10.3806H7.4108V19.2388ZM13.123 19.2388V10C13.123 9.78983 12.9544 9.61943 12.7463 9.61943H7.03412C6.82609 9.61943 6.65745 9.78983 6.65745 10V19.2388H3.22606C2.82097 19.2388 2.43247 19.0762 2.14603 18.7868C1.85958 18.4973 1.69866 18.1048 1.69866 17.6955V7.30031L9.89031 0.862752L18.082 7.30031V17.6955C18.082 18.1048 17.921 18.4973 17.6346 18.7868C17.3482 19.0762 16.9597 19.2388 16.5546 19.2388H13.123Z" fill="black"/>
  </svg>
</div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconHomeLinear',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-home-linear': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
