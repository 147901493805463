<template>
  <text-body-regular class="gc-card-text__header" :weight="'extra-bold'" :lineHeight="'multi'" >
    <slot></slot>
 </text-body-regular>
</template>

<script>
import TextBodyRegular from '../../typography/TextBodyRegular'

export default {
  name: 'CardTextHeader',
  components: {
    TextBodyRegular
  },
  methods: {
    clicked () {
      this.$emit('clicked')
    }
  }
}
</script>
<style scoped>
</style>
