<template>
    <div>
      <card-evergreen-progress-image
        v-if="configs"
        :configs="configs"
        @checkNow="showCheckNowPopup"
        @showDismiss="showDismissPopup"
      />
      <popup-evergreen-progress-image
        :visible="showPopup"
        :data="configs"
        @hideEvergreenProgressImagePopup="showPopup=false"
      />
      <popup-evergreen-progress-dismiss
        :visible="showDismiss"
        :imageID="configs.id"
        @dismissPic="callDismiss"
        @hideEvergreenProgressDismissPopup="showDismiss=false"
      />
    </div>
</template>
<script>
import CardEvergreenProgressImage from '../../../../global/cards/CardEvergreenProgressImage'
import PopupEvergreenProgressImage from '../../../../global/popups/PopupEvergreenProgressImage'
import PopupEvergreenProgressDismiss from '../../../../global/popups/PopupEvergreenProgressDismiss'
import UserEventsService from '../../../../../services/UserEventsService'
export default {
  name: 'CardDashboardEvergreenProgressImage',
  components: {
    CardEvergreenProgressImage,
    PopupEvergreenProgressImage,
    PopupEvergreenProgressDismiss
  },
  props: {
    configs: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showPopup: false,
      showDismiss: false

    }
  },
  methods: {
    async callDismiss () {
      this.showDismiss = false
      this.$emit('dismissCard')
      const response = await UserEventsService.trackItemVisit('everGreen', this.configs.id)
    },
    showCheckNowPopup () {
      this.showPopup = true
    },

    showDismissPopup () {
      this.showDismiss = true
    }
  }
}
</script>
