import SignUpAPI from '../api/SignUp/SignUpAPI'
import PlanNotFoundError from './errors/PlanNotFoundError'
import PaymentGatewayNotSupportedError from './errors/PaymentGatewayNotSupportedError'
import UserAlreadyRegisteredError from './errors/UserAlreadyRegisteredError'
import InputDataValidationError from './errors/InputDataValidationError'
import DashboardService from '../DashboardService'
import AuthService from '../AuthService'
import RegionAPI from '../api/common/RegionAPI'
import ToManyCouponRequestsError from './errors/ToManyCouponRequestsError'
import InvalidCouponError from './errors/InvalidCouponError'
import StripePaymentFailed from './errors/StripePaymentFailed'
import jquery from 'jquery'

export default class SignUpService {
  _productDetails = null
  _paymentGatewayDetails = null
  _planUUID = null
  _regions = null
  _signUpAPI = new SignUpAPI()
  _regionAPI = new RegionAPI()

  constructor (planUUID) {
    this._planUUID = planUUID
  }

  getPlanUUID () {
    return this._planUUID
  }

  /**
   * get product details
   * @returns {Promise}
   */
  getPlanDetails () {
    if (this._productDetails) {
      return Promise.resolve(this._productDetails)
    }
    // TODO :revert
    let product = this._getPlanDetailsFromAPI() // load product details
    // let config = this._getTrainerConfig() // extra_field info
    let regions = this._getRegionsFromAPI() // load regions

    return new Promise((resolve) => {
      Promise.all([product, regions]).then(data => {
        const productResponse = data[0]
        const configResponse = { 'extra_field': null }
        this._regions = data[1].regions
        let productDetails = productResponse
        productDetails = Object.assign(productDetails, { extra_field: configResponse.extra_field })
        this._productDetails = productDetails

        resolve(productDetails)
      })
    })
  }

  /**
   * register user data
   * @param userData
   * @returns {Promise}
   */
  registerUser (userData) {
    return new Promise((resolve, reject) => {
      if (userData.plan_uuid === '') {
        userData.plan_uuid = this._planUUID
      }
      this._signUpAPI.postUserDetails(userData).then(data => data.data).then(data => {
        const auth = new AuthService()
        auth.setTokens(data.token, null)
        this._signIn(userData).finally(() => {
          resolve(data.user_id)
        })
      }).catch(err => {
        if (err.response) {
          let response = err.response
          if (response.status === 409) {
            reject(new UserAlreadyRegisteredError(userData.email))
          } else if (response.status === 422) {
            reject(new InputDataValidationError(response.data.errors))
          } else {
            reject(err)
          }
        }
      })
    })
  }

  /**
   * get stripe checkout url
   * @returns {Promise}
   */
  getStripeCheckout () {
    return new Promise((resolve, reject) => {
      this._signUpAPI.getStripeCheckout(this._planUUID).then(data => data.data).then(data => {
        resolve(data.checkout_url)
      }).catch(err => {
        reject(err)
      })
    })
  }

  /**
   * get stripe checkout url
   * @returns {Promise}
   */
  getStripePaymentToken (coupon = null) {
    let payload = {}
    if (coupon) {
      payload = { coupon: coupon }
    }

    return new Promise((resolve, reject) => {
      this._signUpAPI.getStripePaymentToken(this._planUUID, payload).then(data => data.data).then(data => {
        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  /**
   * get countries list
   * @returns {*|*[]}
   */
  getCountries () {
    return this._productDetails.countries || []
  }

  /**
   * get region list by countries ID
   * @returns {*|*[]}
   */
  getRegionsByCountry (id) {
    let country = this._regions.find(ele => {
      return ele.country_id === id
    })
    if (country.region.length > 2) {
      return country.region
    }

    return []
  }

  /**
   * check user payment
   * @param paymentToken
   * @returns {Promise}
   */
  payViaStripe (paymentToken, coupon = null) {
    const payload = {
      'plan_id': this._planUUID,
      'payment_token': paymentToken,
      'type': 'stripe'
    }
    if (coupon) {
      payload.coupon = coupon
    }
    return new Promise((resolve, reject) => {
      this._signUpAPI.postPaymentDetails(payload).then(data => data.data).then(data => {
        resolve(data)
      }).catch(err => {
        if (err) {
          let response = err.response
          if (response.status === 422) {
            reject(new StripePaymentFailed(response.data.stripe_error_code, response.data.errors.toString()))
          } else if (response.status === 403) {
            reject(new PlanNotFoundError())
          }
        }
        reject(err)
      })
    })
  }

  /**
   *  confirm stripe 3DS
   *
   * @param subscriptionId
   * @param paymentIntentId
   * @returns {Promise}
   */
  confirmPaymentViaStripe (subscriptionId, paymentIntentId) {
    const payload = {
      'payment_intent_id': paymentIntentId,
      'subscription_id': subscriptionId
    }

    return new Promise((resolve, reject) => {
      this._signUpAPI.confirmPaymentDetails(payload).then(data => data.data).then(data => {
        resolve(data)
      })
    })
  }

  /**
   * sign-in user
   * @param userData
   * @returns {*}
   * @private
   */
  _signIn (userData) {
    let auth = new AuthService()
    const loginData = {
      email: userData.email,
      password: userData.password
    }
    const formData = new FormData()
    formData.set('email', userData.email)
    formData.set('password', userData.password)
    fetch('/login', {
      method: 'POST',
      redirect: 'manual',
      headers: {
        'X-CSRF-TOKEN': jquery('meta[name="csrf-token"]').attr('content'),
        'Access-Control-Allow-Origin': '*'
      },
      credentials: 'same-origin',
      body: formData

    })

    return auth.signIn(loginData)
  }

  getPaymentGatewayDetails () {
    if (this._paymentGatewayDetails) {
      return Promise.resolve(this._paymentGatewayDetails)
    }
    return new Promise((resolve) => {
      this._getPaymentGatewayDetailsFromAPI().then(data => {
        this._paymentGatewayDetails = data
        resolve(this._paymentGatewayDetails)
      })
    })
  }

  createPaymentIndent (payload = {}) {
    return this._signUpAPI.createPaymentIndent(this._planUUID, payload).then(data => data.data)
  }

  checkSubscriptionStatus (subscription) {
    return this._signUpAPI.checkSubscriptionStatus(subscription).then(data => data.data)
  }

  confirmCheckoutPaymentBySessionID (sessionID) {
    return new Promise((resolve, reject) => {
      const payload = {
        'sessionId': sessionID
      }

      this._signUpAPI.confirmPaymentSession(this._planUUID, payload).then(data => data.data).then(paymentGatewayDetails => {
        resolve(paymentGatewayDetails)
      }).catch(err => {
        if (err.status) {
          reject(new PlanNotFoundError())
        }

        reject(err)
      })
    })
  }

  confirmCheckoutPaymentForIdeal (setupIntent, coupon = null) {
    const payload = {
      //  payment_intent: '',
      setup_intent: setupIntent,
      payment_method_code: 'stripe-ideal-one-off'
    }
    if (coupon) {
      payload.coupon_code = coupon
    }
    return new Promise((resolve, reject) => {
      this._signUpAPI.processPaymentAction(this._planUUID, payload).then(data => data.data).then(data => {
        console.log(data)
        resolve(data)
      }).catch(err => {
        if (err.status) {
          reject(new PlanNotFoundError())
        }

        reject(err)
      })
    })
  }

  confirmCheckoutPaymentForGiro (paymentIntent, coupon = null) {
    const payload = {
      payment_intent: paymentIntent,
      payment_method_code: 'stripe-giropay'
    }
    if (coupon) {
      payload.coupon_code = coupon
    }
    return new Promise((resolve, reject) => {
      this._signUpAPI.processPaymentAction(this._planUUID, payload).then(data => data.data).then(data => {
        console.log(data)
        resolve(data)
      }).catch(err => {
        if (err.status) {
          reject(new PlanNotFoundError())
        }

        reject(err)
      })
    })
  }

  confirmCheckoutPaymentForCard (paymentIntent, coupon = null) {
    const payload = {
      payment_intent: paymentIntent,
      payment_method_code: 'stripe-sepa-pay'
    }
    if (coupon) {
      payload.coupon_code = coupon
    }
    return new Promise((resolve, reject) => {
      this._signUpAPI.processPaymentAction(this._planUUID, payload).then(data => data.data).then(data => {
        console.log(data)
        resolve(data)
      }).catch(err => {
        if (err.status) {
          reject(new PlanNotFoundError())
        }

        reject(err)
      })
    })
  }

  confirmSetupIndent (setupIntent, coupon = null) {
    const payload = {
      'setup_intent': setupIntent

    }
    if (coupon) {
      payload.coupon = coupon
    }
    return new Promise((resolve, reject) => {
      this._signUpAPI.confirmSetupIndent(this._planUUID, payload).then(data => data.data).then(data => {
        console.log('confirmSetupIndent')
        console.log(data)
        resolve(data)
      }).catch(err => {
        if (err.status) {
          reject(new PlanNotFoundError())
        }

        reject(err)
      })
    })
  }

  confirmCheckoutPaymentForGoogleAndApplePay (paymentIntent, coupon = null) {
    const payload = {
      payment_intent: paymentIntent,
      payment_method_code: 'stripe-apple-google-pay'
    }
    if (coupon) {
      payload.coupon_code = coupon
    }
    return new Promise((resolve, reject) => {
      this._signUpAPI.processPaymentAction(this._planUUID, payload).then(data => data.data).then(data => {
        console.log(data)
        resolve(data)
      }).catch(err => {
        if (err.status) {
          reject(new PlanNotFoundError())
        }

        reject(err)
      })
    })
  }

  confirmCheckoutPaymentForSEPA (setupIntent, coupon = null) {
    const payload = {
      //  payment_intent: '',
      setup_intent: setupIntent,
      payment_method_code: 'stripe-sepa-pay'
    }
    if (coupon) {
      payload.coupon_code = coupon
    }
    return new Promise((resolve, reject) => {
      this._signUpAPI.processPaymentAction(this._planUUID, payload).then(data => data.data).then(data => {
        console.log(data)
        resolve(data)
      }).catch(err => {
        if (err.status) {
          reject(new PlanNotFoundError())
        }

        reject(err)
      })
    })
  }

  _getPaymentGatewayDetailsFromAPI () {
    return new Promise((resolve, reject) => {
      this._signUpAPI.getPaymentGateways(this._planUUID).then(data => data.data).then(paymentGatewayDetails => {
        if (paymentGatewayDetails.type !== 'stripe') {
          throw new PaymentGatewayNotSupportedError(paymentGatewayDetails.type)
        }
        resolve(paymentGatewayDetails)
      }).catch(err => {
        if (err.status) {
          reject(new PlanNotFoundError())
        }

        reject(err)
      })
    })
  }

  getPaymentGatewayGatewaysFromAPI () {
    return new Promise((resolve, reject) => {
      this._signUpAPI.getPaymentGateways(this._planUUID).then(data => data.data).then(paymentGatewayDetails => {
        resolve(paymentGatewayDetails)
      }).catch(err => {
        reject(err)
      })
    })
  }

  validateCoupon (coupon) {
    const payload = {
      'coupon': coupon
    }
    return new Promise((resolve, reject) => {
      return this._signUpAPI.validateCoupon(this._planUUID, payload).then(data => data.data).then(couponData => {
        if (couponData.success) {
          resolve(couponData)
        } else {
          throw new InvalidCouponError()
        }
      }).catch(err => {
        if (err.response && err.response.status === 429) {
          reject(new ToManyCouponRequestsError())
        }

        reject(err)
      })
    })
  }

  _getPlanDetailsFromAPI () {
    return new Promise((resolve, reject) => {
      return this._signUpAPI.getProductDetails(this._planUUID).then(data => data.data).then(productDetails => {
        resolve(productDetails)
      }).catch(err => {
        if (err.response && err.response.status === 404) {
          reject(new PlanNotFoundError())
        }

        reject(err)
      })
    })
  }

  _getRegionsFromAPI () {
    return new Promise((resolve, reject) => {
      return this._regionAPI.getList().then(data => data.data).then(response => {
        resolve(response)
      }).catch(err => {
        if (err.response && err.response.status === 404) {
          reject(new PlanNotFoundError())
        }

        reject(err)
      })
    })
  }

  createSetupIndent () {
    return new Promise((resolve, reject) => {
      return this._signUpAPI.createSetupIntent(this._planUUID).then(data => data.data).then(indentData => {
        resolve(indentData)
      }).catch(err => {
        if (err.response && err.response.status === 404) {
          reject(new PlanNotFoundError())
        }

        reject(err)
      })
    })
  }

  _getTrainerConfig () {
    let dashBoardService = new DashboardService()
    return dashBoardService.getUserConfig()
  }

  /** to check the activation code
   * @param code - ActivationCode
   * @returns {*}
   */
  checkActivationCode (code) {
    // Create a payload object with the activation code
    const payload = {
      'activation_code': code
    }

    // Return a new Promise to handle asynchronous operations
    return new Promise((resolve) => {
      // Call the _signUpAPI.checkActivationCode method with the plan UUID and payload
      return this._signUpAPI.checkActivationCode(this._planUUID, payload)
        // Extract the 'data' property from the response
        .then(data => data.data)
        .then(data => {
          // Resolve the Promise with the value of 'data.success'
          resolve(data.success)
        })
        .catch(() => {
          // If there is an error, resolve the Promise with 'false'
          resolve(false)
        })
    })
  }
}
