<template>
  <div
    :class="componentClasses"
    v-bind="$attrs"
    v-on="$listeners">

        <navigator-side-menu v-if="showSideBar"/>

        <header-page
          v-if="header.show"
          :options="header"
          :is-transparent="header.isTransparent"
          :is-fixed="header.isFixed"
          :modifiers="header.modifiers"
          :main-title="header.mainTitle"
          :sub-title="header.subTitle"
          @height-updated="headerUpdated"/>
          <grid-row class="gc-page-image-cover__row m-0">
            <grid-col :lg="desktopSideBar? 5: 6" v-if="isDesktop" class="px-0">
              <image-base class="gc-page-image-cover-featured-image" :src="backgroundImage"/>
            </grid-col>
            <grid-col :lg="desktopSideBar? 7: 6" class="px-0">
              <div
                v-if="!isDesktop"
                class="gc-page-image-cover__background-image"
                :style="backgroundImageStyles"/>
                <div class="gc-page-image-cover-header-empty-gap"></div>
              <div
                class="gc-page__content "
              :style="contentAreaStyles">
              <slot/>
            </div>

            </grid-col>

          </grid-row>

  </div>
</template>

<script>
import HeaderPage from '../headers/HeaderPage'
import pageMixin from '../../../mixins/component-mixins/global/page/pageMixin'
import NavigatorSideMenu from '../navigators/NavigatorSideMenu'
import GridCol from '../grid/GridCol'
import GridRow from '../grid/GridRow'
import ImageBase from '../../layout/template-2/root/Image'

export default {
  name: 'PageImageCover',
  mixins: [pageMixin],
  props: {
    /**
       * header component props
       * refer header component prop
       * sample structure
       *  @example
       *  {
       *    isTransparent: false,
       *    isFixed: false,
       *    show: true,
       *    left: 'previous',
       *    right: 'message',
       *    rightBlink: false,
       *    modifiers: [],
       *    mainTitle: 'Day 1',
       *    subTitle: ''
       *   }
       */
    header: {
      type: Object,
      required: true
    },
    backgroundImage: {
      type: String,
      required: true
    },
    desktopSideBar: {
      type: Boolean,
      default: true
    }
  },
  components: { ImageBase, GridRow, GridCol, NavigatorSideMenu, HeaderPage },
  computed: {
    componentClasses: function () {
      return {
        'gc-page': true,
        'gc-page-image-cover': true,
        'gc-page-image-cover--with-side-bar': this.showSideBar,
        'gc-page--with-side-bar': this.showSideBar
      }
    },
    showSideBar: function () {
      let auth = this.$route.meta.requiresAuth
      return this.desktopSideBar && this.isDesktop && auth
    },
    backgroundImageStyles: function () {
      let backgroundImage = this.backgroundImage
      return {
        'background-image': `url("${backgroundImage}")`
      }
    }
  }
}
</script>

<style scoped>

</style>
