import DefineInclude from '../../DefineInclude'
import AuthService from '../AuthService'
import Lodash from 'lodash'
import axios from 'axios'
/**
 * Base API Class that can be use to connect the apis
 */
export default class API {
  /**
   * base url
   * @type {string}
   * @private
   */
  _baseURL = ''
  /**
   * resource parameter
   * @type {string}
   * @private
   */
  _resource =''
  /**
   * Auth token
   * @type {string}
   * @private
   */
  _token = ''

  /**
   * parent resource array
   * @type {*[]}
   * @private
   */
  _parentResources =[]

  _headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }

  constructor () {
    this._baseURL = DefineInclude.apiUrl
    this._token = ''
    const auth = new AuthService()
    this._setToken(auth.getToken())
  }

  /**
   * use to set the Auth token
   * @param token -auth token
   * @private
   */
  _setToken (token) {
    this._token = token
  }

  /**
   * use to join the urls parameters
   * @param arr
   * @returns {*|string}
   * @private
   */
  _makeURL (arr) {
    return Lodash.join(arr, '/')
  }

  _getPostHeaders () {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this._token
    }
  }

  _getPath (pathArr) {
    return pathArr.join('/')
  }

  /**
   * generate the parent URL
   * @param pids -parent URL param IDS array
   * @returns {string} -url
   * @private
   */
  _getParentURL (pids) {
    let url = this._baseURL
    if (this._parentResources.length !== 0) {
      url = url.slice(0, -1)
      for (const pid in this._parentResources) {
        url += '/' + this._parentResources[pid]
        if ((typeof pids !== 'undefined') && (typeof pids[pid] !== 'undefined')) {
          url += '/' + pids[pid]
        }
      }
      url = url + '/'
    }
    return url
  }

  /**
   * get resource by ID
   * @param id -ID
   * @param pids -parent ID array
   * @returns {Promise<AxiosResponse<T>>}
   */
  get (id, pids) {
    const url = this._getParentURL(pids)
    const pathArr = [this._resource, id]
    return axios.get(url + this._getPath(pathArr))
  }

  /**
   * get resources
   * @param pids -parent  IDs
   * @returns {Promise<AxiosResponse<T>>}
   */
  getList (pids = []) {
    const url = this._getParentURL(pids)
    return axios.get(url + this._resource)
  }

  /**
   * POST request of the resource
   * @param payload - payload, parentIDs array
   * @param pids
   * @returns {Promise<AxiosResponse<T>>}
   */
  post (payload, pids = []) {
    const url = this._getParentURL(pids)
    return axios.post(url + this._resource, payload)
  }

  sendPostRequest (url, payload) {
    return axios.post(url, payload)
  }
}
