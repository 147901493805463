<template>
  <div @click="resetOptionWrapperWidth" :class="componentClass">
    <div class="gc-select2" id="multi-select">
      <select ref="input" style="width: 100%;" :data-minimum-results-for-search="minimumResultsForSearch">
        <slot></slot>
      </select>
    </div>
    <text-content
      :weight="'medium'"
      :size="'sm1'"
      :lineHeight="'multi'"
      ref="errorMessage"
      class="gc-select2__error-message" v-if="haveError">{{errorMessage}}</text-content>
  </div>
</template>
<script>
import TextContent from '../../root/TextContent'
export default {
  components: { TextContent },
  name: 'Select2',
  props: {
    sort: {
      type: Boolean,
      default: false
    },
    showSearch: {
      type: Boolean,
      default: false
    },
    options: {
      default: []
    },
    value: {
      default: ''
    },
    multiple: {
      default: false
    },
    placeHolder: {
      default: ''
    },
    disabled: {
      default: false
    },
    errorMessage: {
      default: ''
    },
    defaultValue: {
      default: '0'
    }
  },
  data: function () {
    return {
      currentValue: 0,
      elementID: '',
      dropdownOptions: {},
      errorClass: '',
      haveError: false,
      noResultMessage: this.$i18n.t('message["general.no-results"]'),
      select2Obj: null
    }
  },
  computed: {
    minimumResultsForSearch () {
      return this.showSearch ? null : 'Infinity'
    },
    componentClass: function () {
      let classes = { 'gc-input-single-select': true
      }
      classes['gc-input-multi-select' + this.elementID] = true
      return classes
    }
  },
  beforeMount () {
    this.elementID = Math.floor(Math.random() * 10000) + 1
    window.addEventListener(
      'orientationchange',
      this.handleOrientationChange
    )
  },
  mounted: function () {
    let currentElementByClass = '.gc-input-multi-select' + this.elementID
    // if prop not initialised make it empty
    if (!this.options) {
      this.options = {}
    }
    const vm = this
    let value = this.value || this.currentValue || 0
    let defaultValue = this.defaultValue
    let selectOptions = []
    this.dropdownOptions = this.options
    if (this.options.length === 1) {
      value = this.options[0].id
      this.$emit('input', value)
      this.$emit('change', value)
    } else if (this.dropdownOptions.length === 1) {
      value = this.dropdownOptions[0].id
      selectOptions = this.dropdownOptions
      this.$emit('input', value)
      this.$emit('change', value)
    }
    if (Array.isArray(this.options) && this.options.length > 0) {
      selectOptions = this.options
    } else if (Array.isArray(this.dropdownOptions) && this.dropdownOptions.length > 0) {
      selectOptions = this.dropdownOptions
    }
    let noResult = this.noResultMessage
    this.select2Obj = $(this.$refs.input)
      .select2(
        {
          'language': {
            'noResults': function () {
              return noResult
            }
          },
          disabled: this.disabled,
          data: selectOptions,
          multiple: this.multiple,
          placeholder: this.placeHolder,
          containerCssClass: this.errorClass,
          dropdownCssClass: 'dropdownCssClass',
          sorter: this.sortFeature(this.options)
        })
      .val(value)
      .trigger('change')

    this.select2Obj.val(value)
      .on('change', function (e) {
        //  $(currentElementByClass + ' .select2-selection__arrow').hide()
        if ($(this).val()) {
          vm.$emit('input', $(this).val())
          vm.$emit('change', $(this).val())
        } else {
          vm.$emit('input', defaultValue)
          vm.$emit('change', defaultValue)
        }
      }).on('select2:select', function () {
        let selectedWidth = $(currentElementByClass + ' .select2-selection__rendered')
        let inputWrapperWidth = $(currentElementByClass + ' .select2-selection--multiple').outerWidth()
        $(selectedWidth).width(inputWrapperWidth)
        // vm.$emit('inputs', $(this).val())
        // vm.$emit('change', $(this).val())
      }).on('select2:unselecting', function () {
        $(this).data('unselecting', true)
      }).on('select2:opening', function (e) {
        if ($(this).data('unselecting')) {
          $(this).removeData('unselecting')
          e.preventDefault()
        }
      }).on('select2:open', function (e) {
        if (!this.multiple) {
          $(currentElementByClass + ' .select2-container--open').addClass('select2-container--single1')
          $(currentElementByClass + ' .select2-selection__arrow b').css('transform', 'rotate(180deg)')
          $(currentElementByClass + ' .select2-selection__arrow b').css('right', '5%')
          // $(currentElementByClass + ' .select2-selection__arrow').hide()
          $(currentElementByClass + ' .select2-container--below.select2-container--single.select2-container--open').hide()
        }
      }).on('select2:closing', (e) => {
        if (!this.multiple) {
          $(currentElementByClass + ' .select2-selection__arrow b').css('transform', 'rotate(360deg)')
          $(currentElementByClass + ' .select2-selection__arrow b').css('right', '0%')
          $(currentElementByClass + ' .select2-container--below.select2-container--single.select2-container--open').show()
        } else {
          $(this.$refs.inputBottomDiv).height($(currentElementByClass + ' .select2-selection__rendered').height())
        }
      })
    this.resetOptionWrapperHeight()
    $(this.$refs.inputBottomDiv).height($(currentElementByClass + ' .select2-selection__rendered').height())
    window.addEventListener('resize', this.windowResize)
  },
  methods: {
    sortFeature (data) {
      if (this.sort) {
        return data.sort(function (a, b) {
          return a.text < b.text ? -1 : a.text > b.text ? 1 : 0
        })
      }
    },
    handleOrientationChange () {
      setTimeout(() => {
        this.resetOptionWrapperWidth()
      }, 500)
      setTimeout(() => {
        this.$emit('change')
      }, 1500)
    },
    windowResize (event) {
      setTimeout(() => {
        this.resetOptionWrapperHeight()
      }, 1000)
      this.resetOptionWrapperHeight()
    },
    resetOptionWrapperHeight () {
      this.$nextTick(() => {
        let currentElementByClass = '.gc-input-multi-select' + this.elementID

        let errorMessageHeight = $(currentElementByClass + ' .gc-select2__error-message').outerHeight() || 0
        let selectedHeight = $(currentElementByClass + ' .select2-selection__rendered').outerHeight()
        let selectedContainerHeight = $(currentElementByClass + ' .select2-container').outerHeight()

        $(currentElementByClass).height(errorMessageHeight + selectedHeight + selectedContainerHeight)
        this.$emit('change')
      })
    },
    resetOptionWrapperWidth () {
      this.$nextTick(() => {
        let currentElementByClass = '.gc-input-multi-select' + this.elementID
        let selectedWidth = $(currentElementByClass + ' .select2-selection__rendered')
        let inputWrapperWidth = $(currentElementByClass + ' .select2-selection--multiple').outerWidth()
        let searchFeild = document.querySelector('.select2-search__field')
        if (searchFeild) {
          searchFeild.style.maxWidth = inputWrapperWidth - 40 + 'px'
          $(selectedWidth).width(inputWrapperWidth)
        }

        setTimeout(() => {
          this.$emit('change')
        }, 500)
        $(this.$refs.inputBottomDiv).height($(currentElementByClass + ' .select2-selection__rendered').height())
      })
    }
  },
  watch: {
    errorMessage: {
      handler: function () {
        if (this.errorMessage.length > 0) {
          this.errorClass = 'gc-select2__error'
          this.haveError = true
          this.currentValue = ''
        }
        this.resetOptionWrapperHeight()
      },
      immediate: true
    },

    value: {
      handler: function () {
        if (this.value && this.value.toString() !== this.currentValue) {
          this.currentValue = this.value.toString()

          this.$nextTick(() => {
            if (this.select2Obj) {
              this.select2Obj.val([this.currentValue]).trigger('change')
            }
          })
        }
      },
      immediate: true
    },
    options: function (options) {
      this.dropdownOptions = options
      // update options
      this.select2Obj
        .empty()
        .select2({
          data: this.dropdownOptions,
          multiple: this.multiple,
          placeholder: this.placeHolder,
          containerCssClass: 'error',
          dropdownCssClass: 'dropdownCssClass',
          sorter: function (data) {
            return data.sort(function (a, b) {
              return a.text < b.text ? -1 : a.text > b.text ? 1 : 0
            })
          }
        })
      // set default values
      this.$nextTick(() => {
        if (this.select2Obj) {
          this.select2Obj.val([this.currentValue]).trigger('change')
        }
      })
      // set main wrapper height first load

      this.resetOptionWrapperHeight()
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.windowResize)
    this.select2Obj.hide()
    this.select2Obj
      .off()
      .select2('destroy')
  }
}
</script>
