<template>
      <div class="pc-page-account-deletion-confirm">
        <text-body-medium :weight="'extra-bold'">{{pageData.text}}</text-body-medium>
        <button-primary @click="navigateBack()" :text="$t('message[\'general.go-back\']')"/>
      </div>
</template>

<script>
import AccountDeleteService from '@/services/AccountDeleteService'
import TextBodyMedium from './../../../global/typography/TextBodyMedium.vue'
import ButtonPrimary from './../../../global/buttons/ButtonPrimary.vue'
import store from '@/store'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'PageAccountDeletionConfirmation',
  components: {
    TextBodyMedium,
    ButtonPrimary,
  },
  props: [],
  data: function () {
    return {
      headerOptions: {
        show: false
      },
      pageData: {
        state: '',
        reason: '',
        success: false,
        text: ''
      },
      token: ''
    }
  },
  mounted () {
    this.token = this.$route.path.split('/')[3]
    if (this.token) {
      this.setPageData()
    }
  },
  methods: {
    async setPageData () {
      let accountService = new AccountDeleteService()
      const textData = await accountService.getAccountDeletePageData()
      await accountService.getAccountDeleteStatus(this.token).then(data => {
        if (data.data.state === "Email verified") {
          this.pageData.text = data.data.state
        } else {
          accountService.verifyAccountDeleteEmail(this.token).then(data => {
            if (data.data.success === true) {
              this.pageData.text = textData.data.content['request-verified']
            } else {
              this.pageData.text = textData.data.content['request-verification-failed']
            }
          }).catch(data => {
            if (data.response.status === 404) {
              this.$router.replace({path: '/404'})
            } else if (data.response.status ===  500) {
              store.commit('showNoConnectionErrorPopup')
            } else {
              this.pageData.text = textData.data.content['request-verification-failed']
            }
          }).finally(() => {
            this.hideLoading()
          })
        }
        }).catch(data => {
          if (data.response.status === 404) {
            this.$router.replace({path: '/404'})
          } else if (data.response.status ===  500) {
            store.commit('showNoConnectionErrorPopup')
          }
        }).finally(() => {
        pageReadyEvent()
        this.hideLoading()
      })
    },
    navigateBack () {
      this.$router.replace({path: '/dashboard'})
    }
  }
}
</script>
