<template>
    <svg fill="none" height="9" viewBox="0 0 15 9" width="15" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.7216 8.15921C14.5361 8.34479 14.3163 8.4375 14.0624 8.4375H0.937571C0.683502 8.4375 0.46386 8.34479 0.278285 8.15921C0.0927104 7.97343 0 7.75379 0 7.49988C0 7.24601 0.0927104 7.02637 0.278285 6.84075L6.84071 0.278318C7.02649 0.0927429 7.24614 -0.000172615 7.5 -0.000172615C7.75386 -0.000172615 7.97371 0.0927429 8.15913 0.278318L14.7216 6.8408C14.9069 7.02637 15 7.24601 15 7.49993C15 7.75379 14.9069 7.97343 14.7216 8.15921Z"
              fill="#868686"/>
    </svg>
</template>
<script>
export default {
  name: 'upTriangleSvg'
}
</script>
