<template>
  <div :class="componentClasses" @click="clicked()">
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.5037 13.7376L17.064 24.4869C17.7358 25.171 18.8249 25.171 19.4964 24.4869C20.1679 23.8034 20.1679 22.6948 19.4964 22.0113L10.1521 12.4998L19.4961 2.98869C20.1676 2.30489 20.1676 1.19638 19.4961 0.512853C18.8246 -0.170951 17.7355 -0.170951 17.0638 0.512853L6.50343 11.2623C6.16768 11.6042 6 12.0519 6 12.4998C6 12.9479 6.16801 13.3959 6.5037 13.7376Z" fill="#9E9E9E"/>
</svg>

  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconBackward',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-backward': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
