<template>
    <div :class="componentClasses" v-on="$listeners" @click="moodClicked()">
      <div class="gc-card-mood-tracker__mood-icons">
        <icon-add-fill-rounded v-if="showAddIcon"
                            class="gc-card-tracker__icon-add-fill-rounded"
                            />
        <slot></slot>
      </div>
        <text-content
                      v-if="text"
                      class="gc-card-mood-tracker_mood-text gc-card-mood-tracker_mood-text-content text-center"
                     :size="'sm1'"
                     :weight="'medium'">
            <p>{{text}}</p>
        </text-content>

        <div v-if="showMoodCount" class="gc-card-mood-tracker__mood-count">
            <text-content class="gc-card-mood-tracker_mood-text-content text-center"
                         :size="'sm1'"
                         :weight="'medium'">
                <div class="gc-card-mood-tracker__mood-count">
                  <p>x</p>
                  <p>{{count}}</p><!-- here x and {{count}} was put in separate p tags in order to make their positions dynamic when RTL is enabled.  -->
                </div>
            </text-content>
        </div>
    </div>
</template>
<script>
import IconAddFillRounded from '../../../root/icons/IconAddFillRounded'
import TextContent from '../../../root/TextContent'

export default {
  name: 'card-mood-tracker-mood',
  components: {
    IconAddFillRounded,
    TextContent
  },
  props: {
    state: {
      default: ''
    },
    text: {
      default: ''
    },
    count: {
      default: 0
    },
    showAddIcon: {
      default: false
    },
    showMoodCount: {
      default: true
    },

    moodClickable: {
      default: false
    },

    moodType: {
      default: 1
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-card-mood-tracker__mood': true,
        'gc-card-mood-tracker__mood--active': this.state === 'active',
        'gc-card-mood-tracker__mood--inactive': this.state === 'inactive'
      }
    }
  },
  methods: {
    moodClicked () {
      if (this.moodClickable) {
        this.$emit('select-mood', {type: this.moodType})
      } else if (this.showAddIcon) {
        this.$emit('add-clicked', {type: this.moodType})
      }
    }
  }
}
</script>
