<template>
  <div v-bind:class="mainWrapperClass">
    <div>
      <div class="workout-stats__trophy" v-if="!isEditing">
        <img :src="$appConfig.imagesUrl+'icons/trophy.svg'" v-if="isPersonalBest"/>
      </div>
      <div class="workout-stats__head text-center">{{ formatDateTime(loggedAt) }}</div>

      <div class="workout-state__dots" v-if="!isEditing" v-on:click="editPopup.visible=true">
      <svg class="svg-dots" width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM14 0C12.9 0 12 0.9 12 2C12 3.1 12.9 4 14 4C15.1 4 16 3.1 16 2C16 0.9 15.1 0 14 0ZM8 0C6.9 0 6 0.9 6 2C6 3.1 6.9 4 8 4C9.1 4 10 3.1 10 2C10 0.9 9.1 0 8 0Z" fill="black"/>
</svg>

        <!-- <img :src="$appConfig.imagesUrl+'icons/three-dots-dark.svg'"/> -->
      </div>
      <div v-if="!isEditing">
        <div
          class="workout-stats__data workout-single-stats"
          v-for="(entry,eID) in entries"
          v-html="setLogText(entry)"
          :key="'workout-stats__data workout-single-stats-'+ exerciseID+'-'+eID"
        ></div>
        <p class="workout-stats__note text-center">
          {{(editNote||note||$t('message[\'workout-log.exercise-completed-text\']'))}}</p>
      </div>
    </div>
    <div class="workout-log-form">
    <workout-log-note-form
      :exercise-i-d="exerciseID"
      :day-name="dayName"
      :week-name="weekName"
      :note="note" :save-type="'update'"
      :workout-i-d="workoutID"
      :exercise-name="exerciseName"
      v-if="showNoteForm"
      v-on:cancel="cancelEdit()"
      v-on:completed="completeEdit($event)"
    ></workout-log-note-form>
      <workout-log-entry-form-edit
        :current-unit-system="unitSystemValue"
        :entries="entries"
        :day-name="dayName"
        :week-name="weekName"
        :exercise-name="exerciseName"
        :exercise-i-d="exerciseID"
        :exercise-rest-interval="0"
        :exercise-rest-time="0"
        :exercise-time="0"
        :is-completed="true"
        :force-edit="true"
        :metrics="metrics"
        :pre-note="note"
        :sets="getSets(entries)"
      :workout-i-d="workoutID"
      v-if="showEntryForm"
      v-on:cancel="cancelEdit()"
      v-on:canceled="cancelEdit()"
      v-on:submitted="completeEdit($event)"
    />
    </div>
    <popup-workout-log-modify
      v-if="editPopup.visible"
      :visible="editPopup.visible"
      v-on:edit="editClicked()"
      v-on:delete="deleteClicked()"
      v-on:close="closeClicked()"
    />
  </div>
</template>

<script>
import PopupWorkoutLogModify from './PopupWorkoutLogModify'

import WorkoutLogNoteForm from './WorkoutLogNoteForm'
import WorkoutLogEntryFormEdit from '../page-components/FormEditWorkoutLog'
import workoutLogSingleLogMixin from '../../../../../mixins/component-mixins/page/workout/workoutLogSingleLogMixin'

export default {
  name: 'WorkoutLogStat',
  components: { WorkoutLogEntryFormEdit, PopupWorkoutLogModify, WorkoutLogNoteForm },
  mixins: [workoutLogSingleLogMixin]

}
</script>

<style scoped>
</style>
