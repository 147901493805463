<template>
  <div class="pc-form-note-workout-log">
    <workout-log-success-area v-if="showSuccess" />
    <div v-if="showTimer && (timerTime > 0 || exerciseRestTime > 0)">
      <timer-completed @restart="restart" v-if="timerCompleted"></timer-completed>
      <timer
              class="pc-form-note-workout-log__timer"
              :rest-header="restText"
              :rest-interval="exerciseRestInterval"
              :rest-time="exerciseRestTime"
              :time="timerTime"
              :workout-header="workoutTimerText"
              v-else
              :rest-message="restMessage()"
              v-on:rest-timer-end="restTimerEnded($event)"
              v-on:workout-timer-end="workoutTimerEnded($event)"
              v-on:workoutTimerStarted="workoutTimerStarted($event)"
              v-on:workout-timer-remaining="workoutTimerRemaining($event)"
      />
    </div>
    <div class="row">
      <div class="col pc-form-note-workout-log__wrapper-input">
        <validation-provider
          name="note"
          rules="max:1000"
          v-slot="{ errors, invalid, validated }"
        >
          <text-area
            v-on:change="noteChanged()"
            :placeholder="[[$t('message[\'workout-log.add-note\']')]]"
            class="pc-form-note-workout-log__text-area"
            :cols="3"
            :rows="2"
            maxlength="999"
            v-bind:class="{
              'pc-form-note-workout-log__text-area--error':
                invalid && validated,
            }"
            :error-message="errors[0]"
            v-model.trim="formData.note"
          ></text-area>
        </validation-provider>
      </div>
    </div>

    <div class="pt-4" v-if="saveType === 'save'">
      <button-primary
        class="pc-form-note-workout-log__submit"
        type="button"
        v-on:click="completeExercise()"
        :text="$t('message[\'workout-log.complete-exercise\']')"
      />
    </div>
    <div class="pt-4" v-if="saveType === 'update'">
      <button-primary
        class="pc-form-note-workout-log__submit"
        type="button"
        :text="$t('message[\'workout-log.save-text\']')"
        v-on:click="completeExercise()"
      />
      <button-secondary
        class="pc-form-note-workout-log__cancel"
        :text="$t('message[\'general.cancel\']')"
        type="button"
        v-on:click="cancelEdit()"
      />
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import WorkoutLogSuccessArea from './ContainerFullscreenSuccess'
import Timer from './Timer'
import workoutLogNoteFormMixin from '../../../../../mixins/component-mixins/page/workout/workoutLogNoteFormMixin'
import TextArea from '../../../../global/inputs/TextArea'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import ButtonSecondary from '../../../../global/buttons/ButtonSecondary'
import TimerCompleted from './TimerCompleted'
export default {
  name: 'FormNoteWorkoutLog',
  components: {
    TimerCompleted,
    ButtonSecondary,
    ButtonPrimary,
    TextBodyExtraSmall,
    TextArea,
    WorkoutLogSuccessArea,
    ValidationProvider,
    Timer
  },
  mixins: [workoutLogNoteFormMixin]
}
</script>
