import { mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isDynamicContentPopupShown: 'dynamicContentPopupStore/isPopupShown',
      dynamicContentPopupVisibility: 'dynamicContentPopupStore/visible',
      dynamicContentPopupSlug: 'dynamicContentPopupStore/getSlug',
      dynamicContentPopupContent: 'dynamicContentPopupStore/getInfo'
    }),
    showDynamicContentPopup () {
      return this.checkDynamicContentPopupToShow()
    }

  },
  methods: {
    ...mapMutations({
      setDynamicContent: 'dynamicContentPopupStore/setContent'

    }),
    checkDynamicContentPopupToShow () {
      return (!this.isDynamicContentPopupShown) && this.checkDynamicContentPopupSlugEqualCurrentRoute() && !this.$store.state.loading
    },
    checkDynamicContentPopupSlugEqualCurrentRoute: function () {
      if (!this.dynamicContentPopupSlug) {
        return false
      }
      const url = new URL(window.location.origin + this.dynamicContentPopupSlug)
      if (url.pathname === this.$route.path || this.checkURLPathsMatch(url.pathname, this.$route.path)) {
        let hasSearchParams = true
        for (const [key, value] of Object.entries(this.$route.query)) {
          if (['loading', 'grouped', 'mode', 'set-id'].includes(key)) {
            continue
          }
          if (![value.toString(), '{any}'].includes(url.searchParams.get(key))) {
            return false
          }
        }
        if (hasSearchParams) {
          for (const [key, value] of url.searchParams.entries()) {
            if (!((key in this.$route.query) && (this.$route.query[key] === value.toString() || value.toLowerCase() === '{any}'))) {
              return false
            }
          }
        }
      } else {
        return false
      }

      return true
    },
    checkURLPathsMatch (urlPath = '', comUrlPath = '') {
      const urlParts = urlPath.split('/')
      const comUrlParts = comUrlPath.split('/')
      if (urlParts.length !== comUrlParts.length) {
        return false
      }
      return urlParts.every((part, index) => {
        let comPart = comUrlParts[index] || ''

        if (part.startsWith(':') || comPart.startsWith(':')) {
          return true
        }
        return part === comPart
      })
    },
    addEventListenerDynamicContentEvent () {
      document.addEventListener('dynamic-content-popup', this.setDynamicContentEvent)
    },
    removeEventListenerDynamicContentEvent () {
      document.removeEventListener('dynamic-content-popup', this.setDynamicContentEvent)
    },
    setDynamicContentEvent (event) {
      this.setDynamicContent(event.detail || {})
    }
  }

}
