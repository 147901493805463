import { render, staticRenderFns } from "./CardMealActionFavorite.vue?vue&type=template&id=4fe84f93&scoped=true"
import script from "./CardMealActionFavorite.vue?vue&type=script&lang=js"
export * from "./CardMealActionFavorite.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fe84f93",
  null
  
)

export default component.exports