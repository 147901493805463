<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.451 5.10282L15.8345 4.75136L15.4682 4.38206L11.7639 0.647949L11.0539 1.35222L13.9129 4.23419H4.00024H3.50024V4.73419V14.5787L4.50024 14.5787L4.50024 5.23419H13.8275L11.0711 7.76021L11.7467 8.49746L15.451 5.10282ZM4.77297 15.5675L4.38945 15.919L4.75581 16.2883L8.46014 20.0224L9.17007 19.3181L6.31108 16.4361H16.2238H16.7238V15.9361V6.09168L15.7238 6.09168V15.4361H6.39646L9.15291 12.9101L8.4773 12.1729L4.77297 15.5675Z" fill="black"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconSwap',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-swap': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
