export default class SliderService {
  _getFromStorage () {
    if (JSON.parse(localStorage.getItem('sliders')) !== null) {
      return JSON.parse(localStorage.getItem('sliders'))
    }
    return []
  }

  _getSlider (type, ID) {
    const sliders = this._getFromStorage()
    if (sliders !== null) {
      const current = sliders.find((slider) => {
        return (slider.type === type && slider.id === ID)
      })
      if (typeof current !== 'undefined' && typeof current.currentIndex !== 'undefined') {
        return current
      }
    }
    return null
  }

  resetSliderType (type) {
    let sliders = this._getFromStorage()
    if (sliders !== null) {
      let filteredSliders = sliders.filter((slider) => {
        return slider.type !== type
      })
      this._setToStorage(filteredSliders)
    }
  }

  getCurrentSlideIndex (type, ID) {
    const slider = this._getSlider(type, ID)
    if (slider !== null) {
      if (typeof slider.currentIndex !== 'undefined') {
        return slider.currentIndex
      }
    }
    return 0
  }

  setCurrentSlide (type, ID, slide) {
    let sliders = this._getFromStorage()

    const currentIndex = sliders.findIndex(current => (typeof current.id !== 'undefined') && current.id === ID && current.type === type)
    const sliderObj = {
      type: type,
      id: ID,
      currentIndex: slide
    }

    if (typeof currentIndex !== 'undefined' && currentIndex !== -1) {
      sliderObj.currentIndex = slide
      sliders[currentIndex] = sliderObj
    } else {
      sliders = sliders.concat([sliderObj])
    }

    this._setToStorage(sliders)
  }

  _setToStorage (sliders) {
    localStorage.setItem('sliders', JSON.stringify(sliders))
  }
}
