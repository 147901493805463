import { render, staticRenderFns } from "./InputShoppingListItem.vue?vue&type=template&id=3851d44d"
import script from "./InputShoppingListItem.vue?vue&type=script&lang=js"
export * from "./InputShoppingListItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports