<template>
  <div>
    <slick
      ref="slick"
      :options="daysSlickOptions"
      :class="sliderClasses"
      @afterChange="handleAfterChange"
      @beforeChange="handleBeforeChange"
      @breakpoint="handleBreakpoint"
      @destroy="handleDestroy"
      @edge="handleEdge"
      @init="handleInit"
      @reInit="handleReInit"
      @setPosition="handleSetPosition"
      @swipe="handleSwipe"
      @lazyLoaded="handleLazyLoaded"
      @lazyLoadError="handleLazeLoadError"
    >
      <div
        v-for="day in days"
        :key="'day-slider-box'+day.getId()"
        :class="{'day-slider-box--active':(day.getId()===activeDayID),'day-slider-box--disabled': disabled}"
        class="meal-plan_day-slider-box "
        @click="setActive(day)"
      >
        <text-body-extra-small
          :weight="'extra-bold'"
          :line-height="'multi'"
        >
          {{ numberWordToNumeric(day.getName()) }}
        </text-body-extra-small>
      </div>
    </slick>
  </div>
</template>
<script>
import Slick from 'vue-slick'
import { slickMixin } from '../../../../../mixins/slickMixin'
import lodash from 'lodash'
import moment from 'moment'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'

export default {
  name: 'FoodDiaryDaySlider',
  components: {TextBodyExtraSmall, Slick},
  mixins: [slickMixin],
  props: {
    mealPlanID: {
      type: Number,
      required: true
    },
    selectedDayID: {
      type: Number,
      default: 0
    },
    days: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      daysSlickOptions: {
        swipe: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        variableWidth: true,
        autoplay: false,
        dots: false,
        prevArrow: false,
        nextArrow: false,
        infinite: false,
        // focusOnSelect: true,
        rtl: false,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 7
            }

          }
        ]

      },
      activeDayID: 1
    }
  },
  computed: {
    sliderClasses: function () {
      return {
        'meal-plan_day-slider': true,
        'meal-plan_day-slider--disabled': this.disabled
      }
    }
  },
  watch: {
    disabled: {
      immediate: true,
      handler: function (value) {
        this.daysSlickOptions.swipe = !value
      }
    }
  },
  beforeMount () {
    if (this.isTablet || this.isDesktop) {
      this.daysSlickOptions.responsive[0].settings.slidesToShow = this.days.length
    }

    this.daysSlickOptions.rtl = this.isRTL()
    window.addEventListener('resize', this.reInit)
  },
  mounted () {
    this.setSliderTypeAndID('food-diary-selected-day', moment().format('YYYY-MM-DD'))
    let activeSlide = localStorage.getItem('food-diary-selected-day') || '{}'
    activeSlide = JSON.parse(activeSlide)
    if (this.selectedDayID) {
      this.setActive(this.findDayByID(this.selectedDayID), true, false)
    } else if (activeSlide.date && activeSlide.date === moment().format('YYYY-MM-DD') && activeSlide.planID === this.mealPlanID) {
      this.setActive(this.findDayByID(activeSlide.id), true)
    } else {
      this.resetSliderType()
      this.setActive(lodash.first(this.days))
    }
    this.reInit()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.reInit)
    this.$refs.slick.$el.style.display = 'none'
  },
  methods: {
    findDayByID (id) {
      return this.days.find(day => {
        return day.getId() === id
      })
    },
    setActive (day, force = false, preSaving = true) {
      if (!force && this.disabled) {
        return
      }
      this.activeDayID = day.getId()
      day.name = this.numberWordToNumeric(day.getName())
      this.$emit('day-select', day)
      if (preSaving) {
        localStorage.setItem('food-diary-selected-day', JSON.stringify({id: day.getId(), date: moment().format('YYYY-MM-DD'), planID: this.mealPlanID}))
      }
      this.$emit('changed')
    },
    handleInit (event, slick) {
      const el = this.$refs.slick.$el

      let childrenWidth = 0
      el.querySelectorAll('.slick-slide').forEach(e => {
        childrenWidth = e.offsetWidth + 12 + childrenWidth
      })

      if (childrenWidth + 20 < el.clientWidth) {
        setTimeout(() => {
          if (el.querySelector('.slick-track')) {
            el.querySelector('.slick-track').style.width = 'auto'
          }
        }, 200)
      }
    }
  }
}
</script>
