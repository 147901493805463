import BaseAPI from '../BaseAPI.js'
import axios from 'axios'
export default class extends BaseAPI {
  _resource ='workout-log'

  getLogByExercise (exerciseID) {
    const url = this._baseURL
    const pathArr = [this._resource, 'exercises', exerciseID, 'log']
    return axios.get(url + this._getPath(pathArr))
  }

  saveNoteByExercise (exerciseID, payload) {
    const url = this._baseURL
    const pathArr = [this._resource, 'exercises', exerciseID, 'note']
    return axios.post(url + this._getPath(pathArr), payload)
  }

  markExerciseComplete (exerciseID, payload) {
    const url = this._baseURL
    const pathArr = [this._resource, 'exercises', exerciseID, 'completed']
    return axios.post(url + this._getPath(pathArr), payload)
  }

  setInCompleteExercise (exerciseID, payload) {
    const url = this._baseURL
    const pathArr = [this._resource, 'exercises', exerciseID, 'completed']
    return axios.delete(url + this._getPath(pathArr), payload)
  }

  getGenericExerciseHistory (genericExerciseID) {
    const url = this._baseURL
    const pathArr = [this._resource, 'history', 'exercises', genericExerciseID]
    return axios.get(url + this._getPath(pathArr))
  }

  searchWorkoutLogExercise (searchString) {
    const url = this._baseURL
    const pathArr = [this._resource, 'history', 'exercises']
    return axios.get(url + this._getPath(pathArr), { params: { q: searchString } })
  }

  setDayComplete (dayID, payload = {}) {
    const url = this._baseURL
    const pathArr = [this._resource, 'days', dayID, 'completed']
    return axios.post(url + this._getPath(pathArr), payload)
  }

  setWeekComplete (weekID, payload = {}) {
    const url = this._baseURL
    const pathArr = [this._resource, 'weeks', weekID, 'completed']
    return axios.post(url + this._getPath(pathArr), payload)
  }

  setDayIncomplete (dayID, payload = {}) {
    const url = this._baseURL
    const pathArr = [this._resource, 'days', dayID, 'completed']
    return axios.delete(url + this._getPath(pathArr), payload)
  }

  setWeekIncomplete (weekID, payload = {}) {
    const url = this._baseURL
    const pathArr = [this._resource, 'weeks', weekID, 'completed']
    return axios.delete(url + this._getPath(pathArr), payload)
  }
}
