<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.7896 7.61841L14.1975 1.83932C13.5932 1.29882 12.8108 1 12.0001 1C11.1893 1 10.407 1.29882 9.80272 1.83932L3.21061 7.61841C2.86163 7.93051 2.58315 8.31339 2.3937 8.74153C2.20426 9.16968 2.1082 9.63327 2.11192 10.1014V19.7039C2.11192 20.5781 2.45918 21.4165 3.07731 22.0346C3.69544 22.6527 4.53381 23 5.40798 23H18.5922C19.4664 23 20.3047 22.6527 20.9229 22.0346C21.541 21.4165 21.8883 20.5781 21.8883 19.7039V10.0904C21.8904 9.62413 21.7936 9.16265 21.6042 8.73652C21.4148 8.31038 21.1372 7.92929 20.7896 7.61841ZM14.1975 20.8026H9.80272V15.3092C9.80272 15.0178 9.91847 14.7384 10.1245 14.5323C10.3306 14.3263 10.61 14.2105 10.9014 14.2105H13.0988C13.3902 14.2105 13.6696 14.3263 13.8757 14.5323C14.0817 14.7384 14.1975 15.0178 14.1975 15.3092V20.8026Z"
        fill="#FF5757"/>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconHomeFilled',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-home-filled': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
