import MealPlanAPI from '../api/meal-plan/MealPlanAPI'
import lodash from 'lodash'
import {enableMealFavourites} from '@/includes/TemplateSettings'

export default class MealFavouritesService {
  favourites = null
  favouriteIDs = []

  //
  constructor () {
    if (!MealFavouritesService.instance) {
      MealFavouritesService.instance = this
    }
    return MealFavouritesService.instance
  }

  setFavourites (force = false) {
    let api = new MealPlanAPI()
    return new Promise(async (resolve, reject) => {
      if (!enableMealFavourites()) {
        this.favourites = []
        resolve(this.favourites)
        return
      }
      if (this.favourites !== null && !force) {
        resolve(this.favourites)
      } else {
        await api.getPreferences().then((data) => {
          this.favourites = lodash.sortBy(data.data.favourites, [function (o) {
            return o.name
          }])
          let favIDs = []
          for (const id of data.data.favorites) {
            favIDs.push(id.id)
          }
          this.setFavouriteIDs(favIDs)
          resolve(this.favourites)
        }).catch(() => {
          resolve([])
        })
      }
    })
  }

  setFavouritesMeals (favourites) {
    this.favourites = lodash.sortBy(favourites, [function (o) {
      return o.name
    }])
    // uniq by id
    this.favourites = lodash.uniqBy(this.favourites, 'id')
    this.setFavouriteIDs()
  }

  setFavourite (payload) {
    let api = new MealPlanAPI()
    return new Promise((resolve, reject) => {
      api.saveFavorites(payload).then((data) => {
        this.favouriteIDs.push(payload.meal_id)
        resolve(data)
        this.setFavourites(true)
      }).catch(err => {
        reject(err)
      })
    })
  }

  removeFavourite (id) {
    let api = new MealPlanAPI()
    return new Promise((resolve, reject) => {
      api.deleteFavorites(id).then((data) => {
        this.setFavourites(true)
        if (this.favourites) {
          this.favourites = this.favourites.filter(favourite => {
            return favourite.id !== id
          })
          this.setFavouriteIDs()
        }
        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  setFavouriteIDs (ids = null) {
    if (Array.isArray(ids)) {
      this.favouriteIDs = ids
      return
    }
    this.favouriteIDs = this.favourites.map(obj => {
      return obj.id
    })
  }

  getFavouriteIDs () {
    return this.favouriteIDs
  }

  isFavouriteMeal (refID) {
    return this.favouriteIDs.includes(refID)
  }

  getFavourites () {
    return this.favourites
  }
}
