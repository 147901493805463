<template>
    <div class="gc-card-member-small">
        <div class="gc-card-member-small__avatar">
          <image-base class="gc-card-member-small__image" border-radius="circle" :src="avatar"></image-base>
        </div>
        <div class="gc-card-member-small__info">
          <text-body-regular class="gc-card-member-small__name" :line-height="'multi'" :weight="'extra-bold'">{{name}}</text-body-regular>
          <text-body-small class="gc-card-member-small__email" :line-height="'multi'">({{email}})</text-body-small>
        </div>
    </div>
</template>

<script>
import ImageBase from '../../root/Image'
import TextBodyRegular from '../typography/TextBodyRegular'
import TextBodySmall from '../typography/TextBodySmall'

export default {
  name: 'CardMemberSmall',
  props: {
    avatar: {
      default: ''
    },
    name: {
      default: ''
    },
    email: {
      default: ''
    }
  },
  components: {
    ImageBase,
    TextBodyRegular,
    TextBodySmall
  }
}
</script>

<style scoped>

</style>
