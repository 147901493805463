import MealPlanAPI from './api/meal-plan/MealPlanAPI'
import lodash from 'lodash'
import { enableMealDislikesFlag } from '../includes/TemplateSettings'
export default class MealDislikeService {
  dislikes =null
  dislikeIDs =[]
  //
  constructor () {
    if (!MealDislikeService.instance) {
      MealDislikeService.instance = this
    }
    return MealDislikeService.instance
  }

  setDislikes (force = false) {
    let api = new MealPlanAPI()
    return new Promise((resolve, reject) => {
      if (!enableMealDislikesFlag()) {
        this.dislikes = []
        resolve(this.dislikes)
        return
      }
      if (this.dislikes !== null && !force) {
        resolve(this.dislikes)
      } else {
        api.getDislikes().then((data) => {
          this.dislikes = lodash.sortBy(data.data.dislikes, [function (o) { return o.name }])
          this.setDislikeIDs()
          resolve(this.dislikes)
        }).catch(() => {
          resolve([])
        })
      }
    })
  }

  setDislikeMeals(dislikes){
    this.dislikes = lodash.sortBy(dislikes, [function (o) { return o.name }])
    this.dislikes = lodash.uniqBy(this.dislikes, 'id')
    this.setDislikeIDs()
  }

  setDislike (payload) {
    let api = new MealPlanAPI()
    return new Promise((resolve, reject) => {
      api.saveDislikes(payload).then((data) => {
        this.dislikeIDs.push(payload.meal_id)
        resolve(data)
        this.setDislikes(true)
      }).catch(err => {
        reject(err)
      })
    })
  }
  removeDislike (id) {
    let api = new MealPlanAPI()
    return new Promise((resolve, reject) => {
      api.deleteDislikes(id).then((data) => {
        if (this.dislikes) {
          this.dislikes = this.dislikes.filter(dislike => {
            return dislike.id !== id
          })
          this.setDislikeIDs()
        }
        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  }
  setDislikeIDs (ids=null) {
    if(Array.isArray(ids)){
      this.dislikeIDs = ids
      return
    }
    this.dislikeIDs = this.dislikes.map(obj => {
      return obj.id
    })
  }
  getDislikeIDs () {
    return this.dislikeIDs
  }
  isDislikeMeal (refID) {
    return this.dislikeIDs.includes(refID)
  }
  getDislikes () {
    return this.dislikes
  }
}
