<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.9134 15.0857H22.3457C23.1269 15.0857 23.76 15.7189 23.76 16.5C23.76 17.2811 23.1267 17.9143 22.3455 17.9143H17.9133V22.3457C17.9133 23.1268 17.2801 23.76 16.4989 23.76C15.7177 23.76 15.0844 23.1268 15.0844 22.3457V17.9143H10.6545C9.87327 17.9143 9.24 17.2811 9.24 16.5C9.24 15.7189 9.87327 15.0857 10.6545 15.0857H15.0845V10.6543C15.0845 9.87318 15.7177 9.24 16.4989 9.24C17.2801 9.24 17.9134 9.87318 17.9134 10.6543V15.0857Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 16.5C0 7.38749 7.38749 0 16.5 0C25.6125 0 33 7.38749 33 16.5C33 25.6125 25.6125 33 16.5 33C7.38749 33 0 25.6125 0 16.5ZM2.75002 16.5C2.75002 24.0937 8.90626 30.25 16.5 30.25C24.0937 30.25 30.25 24.0937 30.25 16.5C30.25 8.90626 24.0937 2.75002 16.5 2.75002C8.90626 2.75002 2.75002 8.90626 2.75002 16.5Z" fill="black"/>
</svg>

  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconAddRounded',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-addRounded': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
