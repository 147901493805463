/**
 * Base API Class that can be use to connect the apis
 */
import DefineInclude from '../../DefineInclude'
import axios from 'axios'

export default class FeatureUpdatesAPI {
  _config={headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }}

  getUpdatesData () {
    let url = DefineInclude.featureUpdatesUrl
    return axios.get(url, this._config)
  }
}
