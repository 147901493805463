const banners = {
  banners: [
    {
      pages: [
        'workout-list',
        'workout-page',
        'workout-day',
        'workout-exercises-list'/*,
        'workout-exercise' */
      ],
      type: 'system-page',
      code: 'workout-programs'
    },
    {
      pages: [
        'subscription-start',
        'subscription-finish'
      ],
      type: 'system-page',
      code: 'subscribe-settings'
    },
    {
      pages: [
        'profile-home'
      ],
      type: 'system-page',
      code: 'account-management'
    },
    {
      pages: [
        'meal-plan-day',
        'meal-plan-list',
        'meal-plan-shopping',
        'meal-plan-home'
      ],
      type: 'system-page',
      code: 'meal-plans'
    },
    {
      pages: [
        'shopping-days'
      ],
      type: 'system-page',
      code: 'shopping-list'
    },
    {
      pages: [
        'dashboard-home'
      ],
      type: 'system-page',
      code: 'dashboard'
    },
    {
      pages: [
        'contact-us'
      ],
      type: 'system-page',
      code: 'contact'
    },
    {
      pages: [],
      type: 'system-page',
      code: 'progress'
    },
    {
      pages: [],
      type: 'system-page',
      code: 'custom-page-list'
    },
    {
      pages: [],
      type: 'system-page',
      code: 'custom-page-text'
    },
    {
      pages: [],
      type: 'system-page',
      code: 'custom-page-video'
    },
    {
      pages: [],
      type: 'system-page',
      code: 'privacy-policy'
    },
    {
      pages: [],
      type: 'system-page',
      code: 'legal-disclaimer'
    },
    {
      pages: [],
      type: 'system-page',
      code: 'login'
    },
    {
      pages: [
        'diet-progress-start',
        'diet-progress-thank-you'
      ],
      type: 'system-page',
      code: 'update-ntr-details'
    },
    {
      pages: [
        'fitness-progress-start',
        'fitness-progress-thank-you'
      ],
      type: 'system-page',
      code: 'update-wko-details'
    },
    {
      pages: [],
      type: 'system-page',
      code: 'subscribe-pay'
    }
  ]
}

export default banners
