import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    days: new Map(),
    currentDayID: 0,
    currentDay: {
      currentExID: 0,
      completedExercises: [],
      roundId: 1
    }
  },
  mutations: {
    initDay (state, payload) {
      state.currentDayID = payload
      if (state.days.size === 0) {
        let data = localStorage.getItem('circuitDay') || '[]'
        data = JSON.parse(data)

        state.days = new Map(data.slice(Math.max(data.length - 20, 0)))
      }
      state.currentDay = state.days.get(payload) || { currentExID: 0, completedExercises: [], roundId: 1 }
    },
    destroyDay (state) {
      if (state.days.size !== 0) {
        state.days.delete(state.currentDayID)
      }
      const str = JSON.stringify(state.days)
      localStorage.setItem('circuitDay', str)
    },
    setExerciseComplete (state, payload) {
      const index = state.currentDay.completedExercises.findIndex(ex => {
        return ex.id === payload
      })

      if (index === -1) {
        state.currentDay.completedExercises.push({ id: payload, round: 1 })
      } else {
        state.currentDay.completedExercises[index] = {
          id: payload,
          round: state.currentDay.roundId
        }
      }
      state.days.set(state.currentDayID, state.currentDay)
      const str = JSON.stringify(state.days)
      localStorage.setItem('circuitDay', str)
    },
    incrementRound (state) {
      state.currentDay.roundId++
      state.days.set(state.currentDayID, state.currentDay)
      const str = JSON.stringify(state.days)
      localStorage.setItem('circuitDay', str)
    },
    decrementRound (state) {
      state.currentDay.roundId--
      state.days.set(state.currentDayID, state.currentDay)
      const str = JSON.stringify(state.days)
      localStorage.setItem('circuitDay', str)
    }
  },
  actions: {

  },
  getters: {
    getCurrentRound (state) {
      return state.currentDay.roundId
    },
    getCurrentCompletedExercises (state) {
      return state.currentDay.completedExercises
    }
  }
}
