<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="rc-icon-complete-filled__background" d="M12.5 0C5.60742 0 0 5.60742 0 12.5C0 19.3926 5.60742 25 12.5 25C19.3926 25 25 19.3926 25 12.5C25 5.60742 19.3926 0 12.5 0Z" fill="#A46E7B"/>
    <path class="rc-icon-complete-filled__tick" d="M18.7414 8.39058C18.3565 7.98482 17.715 7.96695 17.3086 8.35274L10.8383 14.4889L7.75219 11.3203C7.36133 10.9193 6.72046 10.9105 6.31909 11.3014C5.91806 11.6919 5.90962 12.3335 6.30014 12.7345L10.0839 16.6193C10.2822 16.823 10.5457 16.9257 10.81 16.9257C11.0606 16.9257 11.3113 16.8331 11.5076 16.6477L18.7036 9.82339C19.1096 9.43824 19.1268 8.79669 18.7414 8.39058Z" fill="white"/>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconCompleteFilled',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-complete-filled': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
