<template>
  <popup
    :visible="visible"
    @hide="closePopup"
    modal-class="pc-popup pc-popup-billing-info"
    v-if="visible"
  >
    <popup-title>
      <popup-button-close @click="closePopup"></popup-button-close>
    </popup-title>
    <popup-body class="text-center">
      <popup-content>
        <popup-head>
          <popup-text-heading>{{plan.getName()}}</popup-text-heading>
        </popup-head>

        <div class="pc-popup-billing-info__content-subscription" v-if="planType === 'subscription'&& !plan.isAPostponedActivation()">
           <div class="pc-popup-billing-info__content-row">
              <div class="pc-popup-billing-info__content-left">
                <text-body-small line-height="lh-lg">{{$t("message['billing-info.plan-type']")}}</text-body-small>
                <text-body-regular line-height="lh-lg" weight="extra-bold">{{$t("message['billing-info.subscription']")}}</text-body-regular>
              </div>
             <div class="pc-popup-billing-info__content-right">
               <text-body-small line-height="lh-lg">{{$t("message['billing-info.renewal-price']")}}</text-body-small>
               <text-body-regular line-height="lh-lg" weight="extra-bold">{{Number(plan.getPrice()).toFixed(2)+' '+ plan.getCurrency()}}</text-body-regular>
             </div>
           </div>
          <div class="pc-popup-billing-info__content-row">
              <div class="pc-popup-billing-info__content-left">
                <text-body-small line-height="lh-lg">{{$t("message['billing-info.plan-start-date']")}}</text-body-small>
                <text-body-regular line-height="lh-lg" weight="extra-bold">{{plan.getCreatedDateString()}}</text-body-regular>
              </div>
             <div class="pc-popup-billing-info__content-right">
               <text-body-small line-height="lh-lg">{{$t("message['billing-info.renewal-date']")}}</text-body-small>
               <text-body-regular line-height="lh-lg" weight="extra-bold">{{plan.nextBillingDateString()}}</text-body-regular>
             </div>
           </div>
          <div class="pc-popup-billing-info__content-row">
              <div class="pc-popup-billing-info__content-left">
                <text-body-small line-height="lh-lg">{{$tc("message['billing-info.payment-method']",1)}}</text-body-small>
                <text-body-regular line-height="lh-lg" weight="extra-bold">{{paymentGateway}}</text-body-regular>
              </div>
           </div>
        </div>

        <div class="pc-popup-billing-info__content-subscription" v-if="planType === 'subscription'&& plan.isAPostponedActivation()">
           <div class="pc-popup-billing-info__content-row">
              <div class="pc-popup-billing-info__content-left">
                <text-body-small line-height="lh-lg">{{$t("message['billing-info.plan-type']")}}</text-body-small>
                <text-body-regular line-height="lh-lg" weight="extra-bold">{{$t("message['billing-info.subscription']")}}</text-body-regular>
              </div>
             <div class="pc-popup-billing-info__content-right">
               <text-body-small line-height="lh-lg">{{$t("message['billing-info.renewal-price']")}}</text-body-small>
               <text-body-regular line-height="lh-lg" weight="extra-bold">{{Number(plan.getPrice()).toFixed(2)+' '+ plan.getCurrency()}}</text-body-regular>
             </div>
           </div>
          <div class="pc-popup-billing-info__content-row">
              <div class="pc-popup-billing-info__content-left">
                <text-body-small line-height="lh-lg">{{$t("message['billing-info.plan-start-date']")}}</text-body-small>
                <text-body-regular line-height="lh-lg" weight="extra-bold">{{plan.getActivationDateString()}}</text-body-regular>
              </div>
             <div class="pc-popup-billing-info__content-right">
               <text-body-small line-height="lh-lg">{{$t("message['billing-info.renewal-date']")}}</text-body-small>
               <text-body-regular line-height="lh-lg" weight="extra-bold">{{plan.nextBillingDateString()}}</text-body-regular>
             </div>
           </div>
          <div class="pc-popup-billing-info__content-row">
              <div class="pc-popup-billing-info__content-left">
                <text-body-small line-height="lh-lg">{{$tc("message['billing-info.payment-method']",1)}}</text-body-small>
                <text-body-regular line-height="lh-lg" weight="extra-bold">{{paymentGateway}}</text-body-regular>
              </div>
           </div>
        </div>

        <div class="pc-popup-billing-info__content-one-off" v-else-if="planType === 'one-off' && !plan.isAPostponedActivation()">
           <div class="pc-popup-billing-info__content-row">
              <div class="pc-popup-billing-info__content-left">
                <text-body-small line-height="lh-lg">{{$t("message['billing-info.plan-type']")}}</text-body-small>
                <text-body-regular line-height="lh-lg" weight="extra-bold">{{$t("message['billing-info.one-off']")}}</text-body-regular>
              </div>
             <div class="pc-popup-billing-info__content-right">
               <text-body-small line-height="lh-lg">{{$t("message['billing-info.plan-price']")}}</text-body-small>
               <text-body-regular line-height="lh-lg" weight="extra-bold">{{Number(plan.getPrice()).toFixed(2)+' '+ plan.getCurrency()}}</text-body-regular>
             </div>
           </div>
          <div class="pc-popup-billing-info__content-row">
              <div class="pc-popup-billing-info__content-left">
                <text-body-small line-height="lh-lg">{{$t("message['billing-info.plan-start-date']")}}</text-body-small>
                <text-body-regular line-height="lh-lg" weight="extra-bold">{{plan.getCreatedDateString()}}</text-body-regular>
              </div>
             <div class="pc-popup-billing-info__content-right">
               <text-body-small line-height="lh-lg">{{$t("message['billing-info.expiration-date']")}}</text-body-small>
               <text-body-regular line-height="lh-lg" weight="extra-bold">{{plan.getExpireDateString()}}</text-body-regular>
             </div>
           </div>
          <div class="pc-popup-billing-info__content-row" v-if="paymentGateway">
            <div class="pc-popup-billing-info__content-left">
              <text-body-small line-height="lh-lg">{{$tc("message['billing-info.payment-method']",1)}}</text-body-small>
              <text-body-regular line-height="lh-lg" weight="extra-bold">{{paymentGateway}}</text-body-regular>
            </div>
          </div>
        </div>

        <div class="pc-popup-billing-info__content-challenge" v-else-if="planType === 'challenge' ||( planType === 'one-off'&& plan.isAPostponedActivation())">
          <div class="pc-popup-billing-info__content-row">
            <div class="pc-popup-billing-info__content-left">
              <text-body-small line-height="lh-lg">{{$t("message['billing-info.plan-type']")}}</text-body-small>
              <text-body-regular line-height="lh-lg" weight="extra-bold">{{$t("message['billing-info.challenge']")}}</text-body-regular>
            </div>
            <div class="pc-popup-billing-info__content-right">
              <text-body-small line-height="lh-lg">{{$t("message['billing-info.plan-price']")}}</text-body-small>
              <text-body-regular line-height="lh-lg" weight="extra-bold">{{Number(plan.getPrice()).toFixed(2)+' '+ plan.getCurrency()}}</text-body-regular>
            </div>
          </div>
          <div class="pc-popup-billing-info__content-row">
            <div class="pc-popup-billing-info__content-left">
              <text-body-small line-height="lh-lg">{{$t("message['billing-info.plan-start-date']")}}</text-body-small>
              <text-body-regular line-height="lh-lg" weight="extra-bold">{{plan.getActivationDateString()}}</text-body-regular>
            </div>
            <div class="pc-popup-billing-info__content-right">
              <text-body-small line-height="lh-lg">{{$t("message['billing-info.end-date']")}}</text-body-small>
              <text-body-regular line-height="lh-lg" weight="extra-bold">{{plan.getExpireDateString()}}</text-body-regular>
            </div>
          </div>
          <div class="pc-popup-billing-info__content-row" v-if="paymentGateway">
            <div class="pc-popup-billing-info__content-left">
              <text-body-small line-height="lh-lg">{{$tc("message['billing-info.payment-method']",1)}}</text-body-small>
              <text-body-regular line-height="lh-lg" weight="extra-bold">{{paymentGateway}}</text-body-regular>
            </div>
          </div>
        </div>

      </popup-content>
    </popup-body>
  </popup>
</template>

<script>

import Popup from '../../../../../global/popups/Popup'
import PopupTitle from '../../../../../global/popups/popup-sub-components/PopupTitle'
import PopupButtonClose from '../../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupBody from '../../../../../global/popups/popup-sub-components/PopupBody'
import PopupContent from '../../../../../global/popups/popup-sub-components/PopupContent'
import PopupHead from '../../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../../global/popups/popup-sub-components/PopupTextHeading'
import PlanService from '../../../../../../services/plans/PlanService'
import popupAnimationMixin from '../../../../../../mixins/popupAnimationMixin'
import TextBodySmall from '../../../../../global/typography/TextBodySmall'
import TextBodyRegular from '../../../../../global/typography/TextBodyRegular'

export default {
  name: 'PopupBillingInfo',
  components: {
    TextBodyRegular,
    TextBodySmall,
    PopupTextHeading,
    PopupHead,
    PopupContent,
    PopupBody,
    PopupButtonClose,
    PopupTitle,
    Popup
  },
  mixins: [popupAnimationMixin],
  props: {
    visible: {
      default: true
    },
    plan: {
      validator: function (value) {
        return value instanceof PlanService
      }
    }
  },
  computed: {
    planType: function () {
      return this.plan.getType()
    },
    paymentGateway () {
      if (this.plan.isApplePaymentGateway()) {
        return this.$i18n.t('message["billing-info.apple-pay-header"]')
      } else if (this.plan.isACardPayment()) {
        return this.$i18n.t('message["billing-info.card-header"]')
      } else if (this.plan.isASEPAPayment()) {
        return this.$i18n.t('message["billing-info.sepa-header"]')
      } else if (this.plan.isAIDealPayment()) {
        return this.$i18n.t('message["billing-info.i-deal-header"]')
      } else if (this.plan.isAGiroPayment()) {
        return this.$i18n.t('message["billing-info.giro-header"]')
      } else {
        return ''
      }
    }
  },
  mounted () {
  },
  methods: {
    closePopup (e, callback) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
        if (callback) {
          callback()
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
