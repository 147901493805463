<template>
  <div :class="componentClasses" @click="clicked()">
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.90735e-06 12.4999C1.90735e-06 19.3925 5.60732 25 12.4997 25C19.3921 25 24.9995 19.3925 24.9995 12.4999C24.9995 5.60755 19.3921 0 12.4997 0C5.60732 0 1.90735e-06 5.60755 1.90735e-06 12.4999ZM1.71104 12.4999C1.71115 6.55096 6.55085 1.71115 12.4997 1.71115C18.4486 1.71115 23.2883 6.55108 23.2883 12.4998C23.2883 18.4489 18.4486 23.2887 12.4997 23.2889C6.55074 23.2887 1.71104 18.4489 1.71104 12.4999ZM12.969 17.8534C13.303 18.1873 13.8447 18.1875 14.1788 17.8534L18.9275 13.1051C19.088 12.9444 19.1781 12.7269 19.1783 12.4999C19.1783 12.2729 19.0881 12.0554 18.9276 11.895L14.1787 7.1462C14.0116 6.97931 13.7927 6.89569 13.5738 6.89569C13.3548 6.89569 13.1358 6.97931 12.9687 7.14632C12.6346 7.48033 12.6346 8.0222 12.9687 8.35633L16.2572 11.6446L6.73637 11.6437C6.26387 11.6437 5.88091 12.0265 5.8808 12.499C5.8808 12.9718 6.26376 13.3547 6.73626 13.3548L16.2568 13.3557L12.9691 16.6433C12.6347 16.9776 12.6347 17.5192 12.969 17.8534Z"
        fill="black"
      />
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconForwardArrowRounded',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-forward-arrow-rounded': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>
