<template>
  <popup
    :visible="visible"
    @hide="closePopup"
    modal-class="gc-popup-content-page gc-content-page-popup"
    ref="modal"
    v-if="visible"
  >
    <popup-title>
      <popup-button-close @hide="closePopup"/>
    </popup-title>
    <popup-body>
      <popup-head class="text-center" v-if="!loading">
        <popup-text-heading>{{title ? title : ''}}</popup-text-heading>
      </popup-head>
      <popup-content class="gc-popup-content-page__content">
        <popup-text-body class="app-content-wrap" v-if="!loading">
          <span v-html="contentText"></span>
        </popup-text-body>
        <loading-buffer v-if="loading"></loading-buffer>
      </popup-content>
    </popup-body>
  </popup>
</template>

<script>
import ContentPageFactoryService from '../../../services/ContentPageFactoryService'
import NativeAppMixin from '../../../mixins/NativeAppMixin'
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import LoadingBuffer from '../../layout/template-1/global/widgets/LoadingBuffer'
import Popup from './Popup'
import PopupBody from './popup-sub-components/PopupBody'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupContent from './popup-sub-components/PopupContent'
import PopupTextBody from './popup-sub-components/PopupTextBody'
import PopupHead from './popup-sub-components/PopupHead'
import PopupTextHeading from './popup-sub-components/PopupTextHeading'
import PopupTextSubHeading from './popup-sub-components/PopupTextSubHeading'
import PopupTitle from './popup-sub-components/PopupTitle'

export default {
  name: 'ContentPagePopup',
  mixins: [NativeAppMixin, popupAnimationMixin],
  components: {
    LoadingBuffer,
    Popup,
    PopupBody,
    PopupButtonClose,
    PopupContent,
    PopupTextBody,
    PopupHead,
    PopupTextHeading,
    PopupTextSubHeading,
    PopupTitle
  },
  props: ['visible', 'page'],
  mounted () {
    if (navigator.onLine) {
      this.show = true
    }
    this.loadPageData()
  },
  data: function () {
    return {
      show: false,
      title: '',
      contentText: '',
      imagesUrl: this.$appConfig.imagesUrl,
      loading: true
    }
  },
  created () {
    document.querySelector('body')
      .addEventListener('click', this.linkNavigateClickHandler, true)
  },
  beforeDestroy () {
    document.querySelector('body')
      .removeEventListener('click', this.linkNavigateClickHandler, true)
  },
  methods: {
    linkNavigateClickHandler (event) {
      let targetElement = event.target
      const selector = '.info-modal__text a'
      while (targetElement != null) {
        if (targetElement.matches(selector)) {
          event.preventDefault()
          this.handleUrlNavigate(targetElement.getAttribute('href'))
          return
        }
        targetElement = targetElement.parentElement
      }
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    },
    loadPageData () {
      this.loading = true
      const contentPageFactory = new ContentPageFactoryService()
      const page = contentPageFactory.getPage(this.page)
      page.setPageData().then(() => {
        this.show = true
        this.title = page.pageData.page.title
        this.contentText = page.pageData.page.description
        this.loading = false
      }).catch(err => {
        if (!navigator.onLine) {
          this.$store.commit('showNoConnectionErrorPopup')
          this.closePopup()
        }
        err = {}
      })
    }
  }
}
</script>

<style>
  .hidden {
    display: none;
  }
</style>
