import DefineInclude from '../../../DefineInclude'
import axios from 'axios'

export default class TrackerAPI {
  /**
   * Graphql endpoint
   * @type {string}
   */
  _graphqlUrl = DefineInclude.apiV2Url + 'graphql/'

  /**
   * Retrieve entries for the given criteria
   * @param metric
   * @param from (Format ATOM)
   * @param to (Format ATOM)
   * @returns {Promise<unknown>}
   */
  queryEntries (metric, from = null, to = null) {
    let filter = ''

    if (from) {
      from = `"${from}"`
    }

    if (to) {
      to = `"${to}"`
    }

    if (from || to) {
      filter = `(from: ${from},
                  to: ${to})`
    }

    return this._handleGraphQLRequest(`{
            me {
              tracker(metric: "${metric}") {
                type
                metric
                periodInDays
                records ${filter} {
                  id
                  value
                  comment
                  createdAt
                }
              }
            }
          }`)
  }

  /**
   * Create a new entry
   * @param metric
   * @param value
   * @param comment
   * @returns {Promise<unknown>}
   */
  createEntry (metric, value, comment) {
    return this._handleGraphQLRequest(`mutation {
            accountCreateTrackerRecord(input:{
              metric: "${metric}",
              value: ${value},
              comment: "${comment}"
            }) {
              id
              value
              comment
              }
            }`)
  }

  /**
   * Update an entry
   * @param id
   * @param value
   * @param comment
   * @returns {Promise<unknown>}
   */
  updateEntry (id, value, comment) {
    return this._handleGraphQLRequest(`mutation {
            accountUpdateTrackerRecord(input:{
              id: "${id}",
              value: ${value},
              comment: "${comment}"
            }) {
              id
              value
              comment
            }
          }`)
  }

  /**
   * Delete an entry
   * @param id
   * @returns {Promise<unknown>}
   */
  deleteEntry (id) {
    return this._handleGraphQLRequest(`mutation {
            accountDeleteTrackerRecord(id: "${id}")
          }`)
  }

  /**
   * Create a new tracker
   * @param metric
   * @returns {Promise<unknown>}
   */
  /*  createTracker (metric) {
    return this._handleGraphQLRequest(`mutation {
            accountCreateTracker(input:{
              metric: "${metric}",
              type:NORMAL
            }) {
              metric
              type
              periodInDays
              }
            }`)
  } */

  /**
   * Handles graphQL requests with token handling
   * @param query
   * @returns {Promise<unknown>}
   * @private
   */
  _handleGraphQLRequest (query) {
    return new Promise((resolve, reject) => {
      // try request
      this._graphQLRequest(query)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * Sends graphQL request
   * @param query
   * @returns {*}
   * @private
   */
  _graphQLRequest (query) {
    return axios({
      url: this._graphqlUrl,
      method: 'post',
      data: {
        query: query
      }
    })
  }

  getTrackers (matricId) {
    const baseURL = DefineInclude.apiUrl

    return axios.get(baseURL + 'accounts/trackers/' + matricId)
  }

  /**
   *
   * @param payload - trackerId from to
   * @returns {Promise<AxiosResponse<T>>}
   */
  createTracker (payload) {
    const baseURL = DefineInclude.apiUrl

    return axios.post(baseURL + 'accounts/trackers', payload)
  }

  getTrackerRecords (payload) {
    const baseURL = DefineInclude.apiUrl
    return axios.get(baseURL + 'accounts/trackers/records', {params: payload})
  }

  saveRecords (payload) {
    const baseURL = DefineInclude.apiUrl
    return axios.post(baseURL + 'accounts/trackers/records', payload)
  }
  updateTrackerRecords (uuid, payload) {
    const baseURL = DefineInclude.apiUrl
    return axios.patch(baseURL + 'accounts/trackers/records/' + uuid, payload)
  }
  deleteTrackerRecords (uuid) {
    const baseURL = DefineInclude.apiUrl
    return axios.delete(baseURL + 'accounts/trackers/records/' + uuid)
  }
}
