<template>
<div class="gc-photo-journey-single">
  <img class="gc-photo-journey-single__image"
       :style="{visibility: showImage ? 'visible' : 'hidden'}"
       :src="image" v-on:load="setImageLoaded" />

  <div class="gc-photo-journey-single__bottom">
    <text-body-regular
      class="gc-photo-journey-single__stat"
      weight="extra-bold"
    >{{dateString}}</text-body-regular>
    <text-body-small class="gc-photo-journey-single__date">{{value}}{{unit}}</text-body-small>
  </div>
</div>
</template>

<script>
import TextBodyRegular from '../typography/TextBodyRegular'
import TextBodySmall from '../typography/TextBodySmall'
import moment from 'moment'

export default {
  name: 'CardPhotoJourney',
  props: {
    image: {
      default: ''
    },
    value: {
      default: ''
    },
    unit: {
      default: ''
    },
    label: {
      default: ''
    },
    date: {
      default: ''
    }
  },
  data () {
    return {
      showImage: false
    }
  },
  computed: {
    dateString () {
      if (!this.date) {
        return ''
      } else if (moment().isSame(this.date, 'day')) {
        return this.$i18n.t('message["general.today"]')
      } else {
        return moment(this.date).format('ll')
      }
    }
  },
  components: {
    TextBodyRegular,
    TextBodySmall
  },
  methods: {
    setImageLoaded () {
      this.showImage = true
    }
  }
}
</script>

<style scoped>

</style>
