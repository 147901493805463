<template>
  <action-meal-favorite
    :state="state"
    :showText="showText"
    :limitText="limitText"
    v-on="$listeners"
    :class="componentClasses"
    @clicked="clicked()"
  ></action-meal-favorite>
</template>

<script>
import ActionMealFavorite from '../../../global/actions/ActionMealFavorite.vue'

export default {
  name: 'CardMealActionFavorite',
  components: {
    ActionMealFavorite
  },
  props:
    {
      state: {
        type: String,
        default: ''
      },
      showText: {
        type: Boolean,
        default: true
      },
      limitText: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
  computed: {
    componentClasses: function () {
      return {
        'gc-card-meal__action': true,
        'gc-card-meal__action-favorite': true,
        'gc-card-meal__action-disabled': this.state === 'disabled' || this.disabled,
        'gc-card-meal__action-onTap': this.state === 'onTap',
        'gc-card-meal__action-active': this.state === 'active'
      }
    },
    text: function () {
      let text = this.$i18n.t('message["meal-plan.favorite"]')
      if (this.limitText) {
        text = text.substring(0, 2) + '...'
      }
      return text
    }
  },
  methods: {
    clicked () {
      this.$emit('clicked')
    }
  }
}
</script>
<style scoped>
</style>
