<template>
  <div :class="componentClasses" @click="clicked()">
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0769 17.5C9.84695 17.5 9.62523 17.4188 9.45245 17.2714C8.79987 16.7156 8.17071 16.1932 7.61562 15.7324L7.61278 15.73C5.98534 14.379 4.57999 13.2123 3.60217 12.063C2.50912 10.7782 2 9.55996 2 8.22914C2 6.93613 2.45514 5.74325 3.28149 4.87008C4.1177 3.98659 5.26511 3.5 6.5127 3.5C7.44517 3.5 8.29913 3.78717 9.05079 4.35348C9.43014 4.63933 9.77399 4.98918 10.0769 5.39725C10.38 4.98918 10.7237 4.63933 11.1032 4.35348C11.8548 3.78717 12.7088 3.5 13.6413 3.5C14.8887 3.5 16.0363 3.98659 16.8725 4.87008C17.6988 5.74325 18.1538 6.93613 18.1538 8.22914C18.1538 9.55996 17.6448 10.7782 16.5518 12.0629C15.574 13.2123 14.1687 14.3789 12.5416 15.7298C11.9855 16.1913 11.3553 16.7145 10.7013 17.2717C10.5286 17.4188 10.3068 17.5 10.0769 17.5ZM6.5127 4.42179C5.53254 4.42179 4.63212 4.80285 3.97708 5.49485C3.3123 6.1973 2.94614 7.16832 2.94614 8.22914C2.94614 9.34842 3.37319 10.3494 4.33067 11.4749C5.25611 12.5627 6.63262 13.7054 8.22641 15.0285L8.22937 15.0309C8.78656 15.4935 9.41818 16.0179 10.0756 16.5778C10.7369 16.0168 11.3695 15.4916 11.9278 15.0283C13.5215 13.7051 14.8979 12.5627 15.8233 11.4749C16.7807 10.3494 17.2077 9.34842 17.2077 8.22914C17.2077 7.16832 16.8415 6.1973 16.1768 5.49485C15.5218 4.80285 14.6213 4.42179 13.6413 4.42179C12.9232 4.42179 12.264 4.64414 11.6819 5.08258C11.1632 5.47348 10.8018 5.96764 10.59 6.3134C10.481 6.4912 10.2893 6.59733 10.0769 6.59733C9.86457 6.59733 9.67281 6.4912 9.56386 6.3134C9.35213 5.96764 8.99077 5.47348 8.47192 5.08258C7.88983 4.64414 7.2306 4.42179 6.5127 4.42179Z"
        fill="#FFBA00"
      />
      <path
        d="M14.9999 4.49953C12.9999 2.89953 10.8333 5.1662 9.99995 6.49953C7.9999 4.49955 6.99995 4.49953 4.49995 4.99951C2.49995 5.3995 2.99995 8.83284 3.49995 10.4995L9.99995 16.4995L13.4999 13.4995C13.9999 12.9995 15.2999 11.5995 16.4999 9.99951C17.9999 7.99951 17.4999 6.49953 14.9999 4.49953Z"
        fill="#FFBA00"
        stroke="#FFBA00"
      />
    </svg>
  </div>
</template>

<script>
import iconMixin from "../../../mixins/component-mixins/root/iconMixin";

export default {
  name: "IconFavorite",
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        "rc-icon": true,
        "rc-icon-favorite": true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses,
      };
    },
  },
};
</script>

<style scoped></style>
