<template>
  <grid-col
    lg="6"
    class="pc-card-meal-log"
  >
    <card-meal
      :type="type.name"
      :title="title"
      :macro-nutrients="macroNutrientsArray"
      :calories="caloriesArray"
      :images-src="images"
    >
      <card-meal-action-log-meal
        :state="(currentLog)?'active':''"
        @click="toggleLog()"
      />

      <card-meal-action-view-meal @click="clickedView()" />
    </card-meal>
  </grid-col>
</template>
<script>
import CardMeal from '../../../../global/cards/CardMeal'
import CardMealActionViewMeal from '../../../../global/cards/card-meal-sub-components/CardMealActionViewMeal'
import CardMealActionLogMeal from '../../../../global/cards/card-meal-sub-components/CardMealActionLogMeal'
import GridCol from '../../../../global/grid/GridCol'

export default {
  name: 'CardMealLog',
  components: { GridCol, CardMealActionLogMeal, CardMealActionViewMeal, CardMeal },
  props: {
    name: {
      required: true,
      type: String
    },
    macroNutrients: {
      required: false,
      type: Object
    },
    calories: {
      required: false,
      type: Number
    },
    id: {
      type: Number,
      required: true
    },
    mealPlanId: {
      type: Number,
      required: true
    },
    dayId: {
      type: Number,
      required: true
    },
    type: {
      type: Object,
      required: true
    },
    recipes: {
      required: true,
      type: Array
    },
    logged: {
      default: false,
      type: Boolean
    }
  },
  data: function () {
    return {
      currentLog: false,
      title: '',
      images: []
    }
  },
  computed: {
    macroNutrientsArray: function () {
      if (!this.macroNutrients) {
        return []
      }
      let macros = [
        {
          qut: this.roundupMacroNutrients(this.macroNutrients.proteins),
          unit: this.$i18n.t('message["food-diary.unit-grams"]'),
          name: this.firstLetterCaps(this.$i18n.t("message['general.proteins']"))
        },
        {
          qut: this.roundupMacroNutrients(this.macroNutrients.fats),
          unit: this.$i18n.t('message["food-diary.unit-grams"]'),
          name: this.firstLetterCaps(this.$i18n.t("message['general.fats']"))
        },
        {
          qut: this.roundupMacroNutrients(this.macroNutrients.carbohydrates),
          unit: this.$i18n.t('message["food-diary.unit-grams"]'),
          name: this.firstLetterCaps(this.$i18n.t("message['general.carbs']"))
        }
      ]
      if (this.macroNutrients.show_net_carbs) {
        macros.push({
          qut: this.roundupMacroNutrients(this.macroNutrients.net_carbohydrates),
          unit: this.$i18n.t('message["food-diary.unit-grams"]'),
          name: this.firstLetterCaps(this.$i18n.t("message['general.carbs-net']"))
        })
      }

      return macros
    },

    caloriesArray: function () {
      return [
        {
          qut: this.calories,
          name: this.$i18n.tc("message['general.calories']", this.roundupMacroNutrients(this.calories))
        }
      ]
    }

  },
  watch: {
    currentLog: function () {
      this.$emit('logged', { selected: this.currentLog })
    }
  },
  mounted () {
    this.currentLog = this.logged
    this.setMealTitle()
    this.setImages()
  },
  methods: {

    showImage (index) {
      this.images[index].state = 'loaded'
    },
    preLoadImages () {
      this.images.forEach(image => {
        let img = new Image()
        img.src = image.url
      })
    },
    setMealTitle () {
      let recipes = this.recipes.map(recipe => {
        return recipe.getName()
      })
      this.title = recipes.join(' | ')
    },
    toggleLog () {
      this.currentLog = !this.currentLog
    },
    clickedView () {
      this.setUserEventViewMeal()
      this.delayedRouterPush({ path: [this.$appConfig.appUrlList.mealPlanBase, this.mealPlanId].join('/'), query: { day: this.dayId, meal: this.id, loading: '0' } })
    },
    setUserEventViewMeal (log) {
      let context = (this.$route.name === 'DashboardMain') ? 'dashboard' : 'food-diary'
      this.logEvent('FoodDiary.ViewMeal', {
        'context': context
      })
    },
    setImages () {
      let images = this.recipes.map(recipe => {
        return recipe.getImageMainImage() || ''
      })
      images = images.filter(image => {
        return image
      })
      this.preLoadImages()
      this.images = images.slice(0, 3)
    },
    roundupMacroNutrients (val) {
      const intVal = parseInt(val)
      if (isNaN(intVal)) {
        return val
      } else {
        return intVal
      }
    }
  }
}
</script>
