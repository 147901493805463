<template>
  <div class="gc-card-text__list-text">
    <text-body-extra-small
      class="gc-card-text__list-text__item-number"
      :line-height="'multi'"
      >{{ itemNumber }} </text-body-extra-small
    >
    <text-body-extra-small :line-height="'multi'"
      ><p v-html="text"></p
    ></text-body-extra-small>
  </div>
</template>
<script>
import TextBodyExtraSmall from '../../typography/TextBodyExtraSmall'

export default {
  name: 'CardTextListItem',
  components: { TextBodyExtraSmall },
  props: {
    itemNumber: {
      default: '-'
    },
    text: {
      default: '',
      type: String
    }
  }
}
</script>
