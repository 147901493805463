<template>
  <page :header="headerOptions" class="gc-page-vegetarian-days">
    <validation-observer slim name="VegetarianDays" ref="form" v-slot="{ handleSubmit,invalid }">
    <page-container>

      <form id="form-input-vegetarian-days" @submit.prevent="handleSubmit(nextComponent)">
        <div class="gc-page-vegetarian-days__header-wrapper">
          <text-heading4 class="gc-page-vegetarian-days__title" :weight="'extra-bold'" :lineHeight="'multi'">{{title.main}}</text-heading4>
          <text-body-extra-small class="gc-page-vegetarian-days__sub-title" :weight="'extra-bold'" :lineHeight="'multi'">{{title.sub}}</text-body-extra-small>
        </div>
        <validation-provider :rules="'required'"
                            :name="title.main"
                            :vid="'Vegetarian Days'"
                            v-slot="{ errors }"
                            class="gc-page-vegetarian-days__input-wrapper">
          <button-choice
            type="button"
            :text="option.value"
            :key="option.value"
            v-for="option in options"
            v-model="inputValue"
            :selected="(inputValue === option.key)"
            @click="setFormData(inputValue = option.key)"
            class="gc-page-vegetarian-days__input"/>
          <text-body-extra-small class="gc-page-vegetarian-days__error-message">{{errors[0]}}</text-body-extra-small>
        </validation-provider>
      </form>

    </page-container>
    <page-footer :form="'form-input-vegetarian-days'" :validated="invalid"></page-footer>
    </validation-observer>
  </page>
</template>

<script>
import BaseComponent from '../../layout/template-1/global/widgets/forms/FormBaseComponent'
import ButtonChoice from '../buttons/ButtonChoice'
import TextHeading4 from '../typography/TextHeading4'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import ButtonPrimary from '../buttons/ButtonPrimary'
import Page from './PageForm'
import PageContainer from './page-sub-components/PageContainer'
import PageFooter from './page-sub-components/PageFormFooter'

export default {
  name: 'VegetarianDays',
  extends: BaseComponent,
  components: {
    PageFooter,
    PageContainer,
    Page,
    ButtonPrimary,
    TextBodyExtraSmall,
    TextHeading4,
    ButtonChoice
  },
  mounted () {
    this.hideLoading()
  },
  data: function () {
    return {
      selected: '',
      selectedNutritionalPref: '',
      pageClass: ['macroapp--page-vegetarian-days'],
      headerOptions: {
        show: false,
        left: 'previous',
        right: '',
        modifiers: ['with-img'],
        mainTitle: '',
        subTitle: '',
        isTransparent: true
      }
    }
  }
}
</script>
<style scoped>

</style>
