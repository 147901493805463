<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_27546_25639)">
        <path d="M19.3448 11.0664C17.9485 9.08024 16.2751 9.73579 15.0892 10.0176C15.1761 8.85415 15.7631 7.95669 16.8772 7.28146L16.2012 6.16599C15.4595 6.61548 14.8866 7.15497 14.4838 7.78017C14.0909 6.34872 12.9699 5.64548 11.1198 5.67134C11.0902 7.79146 12.0182 8.95391 13.9023 9.16032C13.8382 9.43614 13.7977 9.72348 13.7816 10.0223C12.5945 9.74337 10.91 9.06887 9.50575 11.0665C7.82197 13.4617 9.74286 17.7304 11.2369 18.7027C12.7309 19.675 13.8429 18.7027 14.4253 18.7027C15.0076 18.7027 16.1196 19.675 17.6136 18.7027C19.1076 17.7304 21.0285 13.4617 19.3448 11.0664Z" fill="black"/>
        <path d="M7.83874 15.4733H2.56593V14.169H7.59049C7.49686 13.3192 7.54897 12.5267 7.74358 11.8118H2.45644V10.5074H8.31198C8.35272 10.4428 8.39475 10.3789 8.43874 10.3164C9.02772 9.47854 9.73033 8.9101 10.5377 8.61604C10.0416 7.83675 9.79897 6.8423 9.81561 5.65312L9.82916 4.68289L9.81561 1H2.14082H1.07043C1.07043 1 0.540582 1.01773 0.270312 1.288C4.19319e-05 1.55827 4.19552e-05 2.09881 4.19552e-05 2.09881V17.8736C4.19552e-05 17.8736 -0.103753 18.544 0.270207 18.8646C0.590753 19.1393 1.08112 19.1348 1.08112 19.1348H9.75768C9.33983 18.6847 9.00541 18.1706 8.76924 17.7524C8.36569 17.0379 8.04928 16.2604 7.83874 15.4733ZM2.45644 6.84589H7.49947V8.15022H2.45644V6.84589Z" fill="black"/>
        <path d="M9.81561 1L9.81544 4.37113L11.1015 4.36714C11.1405 4.3666 11.1791 4.36632 11.2176 4.36632C11.8548 4.36632 12.4357 4.44312 12.9559 4.59484V1.64836C12.9559 1.64836 12.9767 1.37195 12.7928 1.19791C12.6187 1.03318 12.3423 1 12.3423 1H9.81561Z" fill="black"/>
      </g>
      <defs>
        <clipPath id="clip0_27546_25639">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconCoaching',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-coaching': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
