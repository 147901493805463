<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.8092 2.20368C12.807 2.20142 12.8052 2.19899 12.8029 2.19676C9.87392 -0.732264 5.12509 -0.732264 2.19668 2.19682C-0.730091 5.12359 -0.732201 9.86736 2.1902 12.7963C2.1924 12.7985 2.19425 12.801 2.19647 12.8032C5.1255 15.7323 9.87433 15.7323 12.8027 12.8032C15.7295 9.87638 15.7317 5.13262 12.8092 2.20368ZM3.08061 3.08061C5.3718 0.788894 9.00025 0.648857 11.4553 2.6605L2.66044 11.4554C0.648832 9.0008 0.788899 5.37235 3.08061 3.08061ZM11.9188 11.9193C9.62766 14.211 5.99921 14.3511 3.54411 12.3394L12.339 3.54454C14.3506 5.99914 14.2106 9.62762 11.9188 11.9193Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconDislike',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-dislike': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
