<template>
  <div :class="componentClasses" @click="clicked()">
  <svg width="13" height="25" viewBox="0 0 13 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.394581 12.8602L11.853 24.8395C12.0524 25.046 12.382 25.0552 12.5895 24.8558C12.797 24.6569 12.8041 24.3273 12.6057 24.1193L1.4922 12.5001L12.6058 0.880917C12.8042 0.672907 12.7971 0.343315 12.5895 0.144436C12.4888 0.0478058 12.3586 1.87313e-06 12.2294 1.86184e-06C12.0921 1.84983e-06 11.9558 0.0539073 11.853 0.160746L0.394581 12.14C0.202295 12.3414 0.202294 12.6588 0.394581 12.8602Z" fill="black"/>
  </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconBackward',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-backward': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
