<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0139 1.29084C9.70285 1.05755 9.26159 1.12059 9.0283 1.43164C8.79502 1.74268 8.85805 2.18395 9.1691 2.41724L9.48071 2.65094L9.48466 2.65388C11.0669 3.82338 11.9766 5.67543 12.003 7.61017C11.7829 7.54203 11.5735 7.4412 11.3827 7.30768C10.37 6.68445 9.12354 6.37284 7.955 6.60655C5.0726 7.07397 3.04713 10.1122 3.43664 13.3841C3.82615 16.656 7.02017 22.4987 9.90257 22.0313C10.2921 21.9534 10.6816 21.7976 10.9932 21.5639C12.0059 20.8628 13.4082 20.8628 14.4209 21.5639C14.7325 21.7976 15.1221 21.9534 15.5116 22.0313C18.394 22.4987 21.588 16.656 21.9775 13.3841C22.4449 10.1122 20.4194 7.07397 17.4591 6.60655C16.2906 6.37284 15.0441 6.68445 14.1093 7.30768C13.889 7.44789 13.6532 7.55205 13.4112 7.62016C13.3878 5.25168 12.2789 2.96937 10.3236 1.5231L10.0139 1.29084Z"
        fill="#FF5757"/>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconMealPlanFilled',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-meal-plan-filled': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
