<template>
  <div :class="componentClasses" @click="clicked()">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'CardText',
  computed: {
    componentClasses: function () {
      return {
        'gc-card-text': true
      }
    }
  },
  methods: {
    clicked () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
</script>
