<template>
  <page-secondary
    :desktop-side-bar="false"
    :header="headerOptions"
    class="pc-sign-up-payment"
  >
    <header-subscription
      :steps="stepsData"
      :route="'/sign-up'"
    />
    <grid-row :no-gutters="true">
      <grid-col
        md="12"
        lg="4"
        v-if="isDesktop || (plan.checkoutType &&plan.checkoutType!=='stripe')"
      >
        <card-subscription-cover-image
          :logo="logoImage"
          :image="titleImage"
        />
      </grid-col>

      <grid-col
        md="12"
        lg="8"
        class="pc-sign-up-payment__content-col"
      >
        <page-container class="pc-sign-up-register__container">
          <div :class="{'pc-sign-up-payment__wrapper': hasOtherCurrencyPlan}">
            <div
              v-if="plan.description&&!hasOtherCurrencyPlan && plan.checkoutType!=='stripe'&& plan.checkoutType"
              class="pc-sign-up-payment__intro"
            >
              <text-body-small
                :line-height="'multi'"
                v-html="plan.description"
              />
            </div>
            <div class="pc-sign-up-payment__plan" v-show="plan && plan.checkoutType && plan.checkoutType!=='stripe'">
              <text-body-regular
                :line-height="'multi'"
                :weight="'extra-bold'"
                class="pc-sign-up-payment__plan-title"
              >
                {{ $t('message["sign-up.selected-plan"]') }}
              </text-body-regular>
              <card-subscription-plan
                :type="plan.type"
                :title="plan.title"
                :period="plan.period"
                :price="plan.price"
                :currency="plan.currency"
                :status="plan.status"
                :trial-period="plan.trialPeriod"
                :discounted-price="coupon.updatedPrice"
              />
            </div>
            <div class="pc-sign-up-payment__scroll"></div>
            <hr
              v-if="!hasOtherCurrencyPlan && plan.checkoutType && plan.checkoutType!=='stripe'"
              class="pc-sign-up-payment__seperator"
            >
            <div v-if="!hasOtherCurrencyPlan && plan.checkoutType==='stripe'">
              <div class="pc-sign-up-payment__redirect">
                <div
                  class="loader"
                  :style="{'background-image': 'url(' + baseImageURL + 'loader.svg)',}"
                />
                <text-body-extra-small
                  weight="extra-bold"
                >
                  {{ $t('message[\'sign-up.payment.stripe-redirect\']') }}
                </text-body-extra-small>
              </div>
            </div>
            <div
              v-else-if="!hasOtherCurrencyPlan&& plan.checkoutType==='internal'"
              class="pc-sign-up-payment__forms"
            >
              <text-body-regular
                :line-height="'multi'"
                :weight="'extra-bold'"
                class="pc-sign-up-payment__registration-form-title"
              >
                {{ $t('message["sign-up.payment.form-title"]') }}
              </text-body-regular>

              <card-content
                class="pc-sign-up-payment__registration-form-card pc-sign-up-payment__registration-form-payment-card"
              >
                <text-body-extra-small
                  :line-height="'multi'"
                  class="pc-sign-up-payment__registration-form-card-text"
                >
                  {{ $t('message["sign-up.request-contact-support"]') }}
                </text-body-extra-small>
                <button-link
                  :text="$t('message[\'settings.contact-support\']')"
                  class="pc-sign-up-payment__registration-form-card-button"
                  @click="showPopupContactSupport"
                />
              </card-content>
              <validation-observer
                v-if="plan.couponEnabled"
                ref="couponForm"
                class="pc-sign-up-payment__container-coupon"
                tag="div"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('message[\'sign-up.form.coupon-code\']')"
                  tag="div"
                  class="pc-sign-up-payment__registration-form-input"
                  vid="coupon"
                  rules=""
                >
                  <text-body-small
                    :line-height="'multi'"
                    class="pc-sign-up-payment__registration-form-label"
                  >
                    {{
                      $t('message["sign-up.form.coupon-code"]') + ' (' + $t('message["general.form.optional"]') + ')'
                    }}
                  </text-body-small>
                  <input-coupon-code
                    v-model="formData.coupon"
                    :is-coupon-code-valid="couponCodeValidity"
                    class="pc-sign-up-payment__registration-form-input-coupon-code"
                    @request-validation="validateCoupon"
                    @coupon-code="checkCouponCodeValidity"
                    @close="clearCouponCode"
                  />
                  <text-error v-if="errors[0]">
                    {{ errors[0] }}
                  </text-error>
                  <text-body-extra-small
                    v-if="coupon.applied"
                    class="pc-sign-up-payment__text-coupon-success"
                  >
                    {{ couponSuccessText }}
                  </text-body-extra-small>
                </validation-provider>
              </validation-observer>
              <text-body-small
                v-if="plan.checkoutType !== 'stripe'"
                class="pc-sign-up-payment__text-select"
              >
                {{ $t('message["sign-up.form.select-payment-method"]') }}
              </text-body-small>
              <container-stripe-payment-element
                v-if="stripeKey && showPaymentElements&&paymentGateways&&plan.type&&plan.checkoutType === 'internal'"
                :full-name="user.name"
                :pay-button-text="payButtonText"
                :email="user.email"
                :payment-methods="paymentMethods"
                :coupon="(coupon.applied)?formData.coupon:''"
                :price="plan.price"
                :amount="(coupon.updatedPrice*100) || (plan.price*100)"
                :plan-type="plan.type"
                :currency="plan.currency"
                :stripe-account="paymentGateways.account_id"
                :stripe-account-country-code="paymentGateways.account_country"
                :supported-payment-methods="[]"
                :stripe-key="stripeKey"
              />
              <loading-buffer
                v-else-if="!stripeKey"
                :height="100"
              />
            </div>
            <div v-else-if="plan.checkoutType">
              <alert-dotted class="pc-sign-up-payment__warning">
                <icon-warning
                  size="sm2"
                  class="pc-sign-up-payment__warning-icon"
                />
                <div class="pc-sign-up-payment__content">
                  <text-body-small
                    :weight="'extra-bold'"
                    :line-height="'multi'"
                    class="pc-sign-up-payment__warning-name"
                  >
                    {{ $t('message["sign-up.payment-method.error-other-currency-title"]') }}
                  </text-body-small>
                  <text-body-extra-small
                    :line-height="'multi'"
                  >
                    {{ $t('message["sign-up.payment-method.error-other-currency"]') }}
                  </text-body-extra-small>
                </div>
              </alert-dotted>
              <div>
                <button-primary
                  class="pc-sign-up-payment-confirm__submit"
                  :text="$t('message[\'sign-up.payment.try-again\']')"
                  @click="restartPayment"
                />
                <button-secondary
                  class="pc-sign-up-payment-confirm__contact"
                  :text="$t('message[\'settings.contact-support\']')"
                  @click="contactSupport.popupShow= true"
                />
              </div>
            </div>
          </div>
        </page-container>
      </grid-col>
    </grid-row>
    <popup-contact-support
      v-if="contactSupport.popupShow"
      :popup-visibility="contactSupport.popupShow"
      @close="contactSupport.popupShow=false"
    />
    <popup-plan-exists
      v-if="hasSubscribed&& !shownPopupPlanExists"
      :plan-name="plan.title"
      @showContactSupport="contactSupport.popupShow= true"
      @close="shownPopupPlanExists = true"
    />
  </page-secondary>
</template>

<script>
import AlertDotted from '../../../global/alerts/AlertDotted'
import ButtonLink from '../../../global/buttons/ButtonLink'
import ButtonPrimary from '../../../global/buttons/ButtonPrimary'
import CardSubscriptionCoverImage
  from '../../../global/cards/card-subscription-sub-components/CardSubscriptionCoverImage'
import CardSubscriptionPlan from '../../../global/cards/card-subscription-sub-components/CardSubscriptionPlan'
import CardContent from '../../../global/cards/CardContent'
import HeaderSubscription from '../../../global/headers/HeaderSubscription'
import InputCouponCode from '../../../global/inputs/input-sign-up-sub-components/InputCouponCode'
import TextInput from '../../../global/inputs/TextInput'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import PageSecondary from '../../../global/pages/PageSecondary'
import TextBodyExtraSmall from '../../../global/typography/TextBodyExtraSmall'
import TextBodyRegular from '../../../global/typography/TextBodyRegular'
import TextBodySmall from '../../../global/typography/TextBodySmall'
import IconPaymentLogos from '../../../root/icons/IconPaymentLogos'
import IconPoweredByStripe from '../../../root/icons/IconPoweredByStripe'
import IconSecureLock from '../../../root/icons/IconSecureLock'
import IconWarning from '../../../root/icons/IconWarning'
import globalSignUpMixin from '../../../../mixins/component-mixins/page/sign-up/globalSignUpMixin'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import TextError from './page-components/TextError'
import IconCvc from '../../../root/icons/IconCvc'
import IconLoadingBuffer from '../../../root/icons/IconLoadingBuffer'
import DashBoardService from '../../../../services/DashboardService'
import PopupPlanExists from './page-components/PopupPlanExists'
import PopupContactSupport from '../../../global/popups/PopupContactSupport'
import headerEventBus from '../../../../event-buses/headerEventBus'
import pageSignUpPayment from '../../../../mixins/component-mixins/page/sign-up/pageSignUpPaymentMixin'
import ContainerStripePaymentElement from './page-components/ContainerStripePaymentElementUpdated.vue'
import GridRow from '../../../global/grid/GridRow'
import GridCol from '../../../global/grid/GridCol'
import BillingService from '../../../../services/plans/BillingService'
import LoadingBuffer from '../global/widgets/LoadingBuffer'
import ButtonSecondary from '../../../global/buttons/ButtonSecondary'
import { paymentPageLoadedEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'PageSignUpPayment',
  components: {
    LoadingBuffer,
    GridRow,
    GridCol,
    ContainerStripePaymentElement,
    PopupContactSupport,
    PopupPlanExists,
    IconLoadingBuffer,
    TextError,
    PageSecondary,
    HeaderSubscription,
    CardSubscriptionCoverImage,
    PageContainer,
    TextBodyRegular,
    TextBodyExtraSmall,
    TextBodySmall,
    TextInput,
    ButtonPrimary,
    CardContent,
    CardSubscriptionPlan,
    IconPaymentLogos,
    IconSecureLock,
    IconPoweredByStripe,
    ButtonLink,
    InputCouponCode,
    AlertDotted,
    IconWarning,
    ValidationObserver,
    ValidationProvider,
    IconCvc,
    ButtonSecondary
  },
  mixins: [globalSignUpMixin, pageSignUpPayment],
  watch: {
    'formData.coupon': function () {
      if (!this.coupon.applied) {
        return
      }
      this.showPaymentElements = false
      this.$nextTick(() => {
        this.showPaymentElements = true
      })
    },
    'coupon.applied': function () {
      if (!this.coupon.applied) {
        return
      }
      this.showPaymentElements = false
      this.$nextTick(() => {
        this.showPaymentElements = true
      })
    }
  },
  created () {

    this.setCardElementStyles()
  },
  async beforeMount () {

    this.initService()
    const planData = await this.service.getPlanDetails()
    this.plan.checkoutType = planData.checkout_type
    this.setImages()
    this.setLang()
    let dashBoardService = new DashBoardService()
    const currentPlanID = this.$route.params.plan
    const dashBoardData = await dashBoardService.getUserData(true)
    this.user.email = dashBoardData.email
    this.user.name = dashBoardData.first_name + ' ' + dashBoardData.last_name
    this.hasSubscribed = dashBoardData.subscriptions.some(plan => {
      return (currentPlanID === plan.plan_uuid || currentPlanID === plan.pricing_plan_uuid) && !['cancelled', 'expired', 'pending-payment'].includes(plan.status)
    })
    if (this.hasSubscribed) {
      this.hideLoading()
      this.$router.push({ path: `/subscribe/${currentPlanID}/already-subscribed` })
      return
    }
    await this.setPlanData()
    this.hideLoading()
    let billing = new BillingService()
    await billing.getInfoFormAPI()

    this.hasOtherCurrencyPlan = billing.getPlans().some(plan => {
      return plan.getCurrency() !== this.plan.currency
    })

    if (this.hasOtherCurrencyPlan || this.hasSubscribed) {
      this.hideLoading()
    } else if (this.plan.checkoutType === 'stripe') {
      this.goToStripeCheckout()
    }
    paymentPageLoadedEvent()
  },
  mounted () {
    headerEventBus.$on('support-icon-clicked', this.showPopupContactSupport)
    this.stepsData[0].status = 'completed'
  },
  beforeDestroy () {
    headerEventBus.$on('support-icon-clicked', this.showPopupContactSupport)
  }
}
</script>
