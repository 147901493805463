<template>
  <div :class="componentClasses">
    <div class="gc-card-evergreen-progress-image__content-wrapper">
      <!-- Text content -->
      <div class="gc-card-evergreen-progress-image__text-content-wrapper">
        <text-content :weight="'extra-bold'" :size="'sm3'" :lineHeight="'multi'">
          {{ configs.tab_title}}
        </text-content>
      </div>
      <!-- Buttons -->
      <div class="gc-card-evergreen-progress-image__button-wrapper">
        <button-custom class="gc-card-evergreen-progress-image__check-now-button" :text="$t('message[\'evergreen-progress-image.check-now-button-title\']')" :border-radius="'sm1'" @click="checkNow" />
        <button-link-secondary class="gc-card-evergreen-progress-image__dismiss-button" :text="$t('message[\'evergreen-progress-image.dismiss-button-title\']')" @click="dismiss" />
      </div>
    </div>
  </div>
</template>
<script>
import ButtonCustom from '../buttons/ButtonCustom'
import ButtonLinkSecondary from '../buttons/ButtonLinkSecondary'
import TextContent from '../../root/TextContent'
export default {
  name: 'CardEvergreenProgressImage',
  components: {
    ButtonCustom,
    ButtonLinkSecondary,
    TextContent
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    configs: {
      type: Object,
      required: true
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-card-evergreen-progress-image': true,
        'gc-card-evergreen-progress-image__disabled': this.disabled
      }
    }
  },
  methods: {
    checkNow () {
      this.$emit('checkNow')
    },

    dismiss () {
      this.$emit('showDismiss')
    }
  }
}
</script>
