<template>
    <popup class="pc-popup-meal-plans-pair" v-if="visible" :visible="visible" size="md" @hide="closePopup()">
        <popup-title class="pc-popup-meal-plans-pair__title">
            <popup-button-close  @click="closePopup"> </popup-button-close>
        </popup-title>
        <popup-body v-if="status === 'ready'">
            <popup-content class="pc-popup-meal-plans-pair__content">
                <popup-head class="pc-popup-meal-plans-pair__head">
                    <popup-text-heading class="pc-popup-meal-plans-pair__heading">
                        {{ $t('message[\'meal-plan-pair.pair-meal-plan\']') }}
                    </popup-text-heading>
                    <popup-text-body class="pc-popup-meal-plans-pair__description">
                        <div v-html="$t('message[\'meal-plan-pair.description\']')"></div>
                    </popup-text-body>
                </popup-head>
                <text-body-small :line-height="'multi'" :weight="'extra-bold'">
                    {{ $t('message[\'meal-plan-pair.choose-meal\']') }}
                </text-body-small>
                <div class="pc-popup-meal-plans-pair__check-box-input-wrapper">
                  <div class="pc-popup-meal-plans-pair__check-box-input" v-for="(parentMealType, key) in parentMealTypes" :key="key">
                    <input-check-box-squared  :id="parentMealType.id" v-model="paringMealTypes"></input-check-box-squared>
                      <label class="pc-popup-meal-plans-pair__check-box-input-label">{{parentMealType.name}}</label>
                  </div>
                </div>
            </popup-content>
            <popup-footer>
                <button-primary
                        :disabled="paringMealTypes.length === 0"
                        :text="$t('message[\'meal-plan-pair.request-pairing\']')"
                        v-on:click="pairMealTypes"/>
            </popup-footer>
        </popup-body>

        <popup-body class="text-center" v-if="status === 'processing'">
            <popup-head class="text-center">
                <popup-text-heading>{{$t('message[\'meal-plan-pair.requesting\']')}}</popup-text-heading>
                <div class="text-center">
                    <loading-buffer :height="'75'"></loading-buffer>
                </div>
            </popup-head>
        </popup-body>

        <popup-body class="text-center" v-if="status === 'processed'">
            <popup-head class="text-center">
                <popup-text-heading>{{$t('message[\'meal-plan-pair.requested\']')}}</popup-text-heading>
            </popup-head>
            <popup-content>
                <icon-complete-filled :size="'lg3'"/>
            </popup-content>
        </popup-body>
    </popup>
</template>

<style></style>

<script>
import popupAnimationMixin from '../../../../../../mixins/popupAnimationMixin'
import PopupTitle from '../../../../../global/popups/popup-sub-components/PopupTitle'
import Popup from '../../../../../global/popups/Popup'
import PopupButtonClose from '../../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupBody from '../../../../../global/popups/popup-sub-components/PopupBody'
import PopupContent from '../../../../../global/popups/popup-sub-components/PopupContent'
import PopupHead from '../../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupTextBody from '../../../../../global/popups/popup-sub-components/PopupTextBody'
import PopupFooter from '../../../../../global/popups/popup-sub-components/PopupFooter'
import ButtonPrimary from '../../../../../global/buttons/ButtonPrimary'
import TextBodySmall from '../../../../../global/typography/TextBodySmall'
import InputCheckBoxSquared from '../../../../../global/inputs/InputCheckBoxSquared'
import PairService from '../../../../../../services/meal-plan-pair/PairService'
import lodash from 'lodash'
import LoadingBuffer from '../../../global/widgets/LoadingBuffer'
import IconCompleteFilled from '../../../../../root/icons/IconCompleteFilled'

export default {
  components: {
    InputCheckBoxSquared,
    TextBodySmall,
    Popup,
    PopupTitle,
    PopupButtonClose,
    PopupBody,
    PopupContent,
    PopupHead,
    PopupTextHeading,
    PopupTextBody,
    PopupFooter,
    ButtonPrimary,
    LoadingBuffer,
    IconCompleteFilled
  },
  name: 'PopupMealPlanPair',
  mixins: [popupAnimationMixin],
  data: function () {
    return {
      visible: false,
      status: 'ready', // ready, processing, processed, error
      pairService: new PairService(),
      parentMealTypes: {},
      paringMealTypes: []
    }
  },
  mounted () {
    this.loadPairDetails()
  },
  methods: {
    show () {
      this.visible = true
      this.status = 'ready'
    },
    async loadPairDetails () {
      // initiating pair details
      await this.pairService.init()

      // ordering parent meal types by priority
      let parentPlans = lodash.orderBy(
        this.pairService.getParentMealTypes(),
        'priority',
        'asc'
      )

      // getting highest 3 prioritised meal types
      this.parentMealTypes = lodash.take(parentPlans, 3)
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.visible = false
        this.$emit('close')
      })
    },
    pairMealTypes () {
      this.status = 'processing'

      // pairing selected meal types
      this.pairService.pair(this.paringMealTypes)
        .then(() => {
          // re initiate pair details
          this.pairService.init({forceLoad: true})
          this.status = 'processed'
          setTimeout(() => {
            this.closePopup()
            this.$emit('refresh')
          }, 1000)
        })
        .catch(() => {
          this.closePopup()
        })
    }
  }
}
</script>
