<template>
  <grid-col
    v-if="!isRemoved"
    lg="6"
  >
    <card-meal
      :type="type.name"
      :title="title"
      :macro-nutrients="macroNutrientsArray"
      :calories="caloriesObj"
      :images-src="images"
      :is-new-meal="false"
      class="pc-card-save-meal-with-macros"
    >
      <card-meal-action-log-meal
        :state="logMealState"
        @click="logMeal()"
      />
      <card-meal-action-remove
        :state="actionRemoveState"
        @click="setRemove()"
      />
      <popup-erase-meal
        v-if="mealRemovePopup.visible"
        :visible="mealRemovePopup.visible"
        :meal-name="title"
        :custom-meal-i-d="id"
        @close="closePopup"
        @error="setError"
        @deleting="setRemoving()"
        @success="setRemoved()"
      />
    </card-meal>
  </grid-col>
</template>
<script>
import CardMeal from '../../../../global/cards/CardMeal'
import CardMealActionViewMeal from '../../../../global/cards/card-meal-sub-components/CardMealActionViewMeal'
import CardMealActionLogMeal from '../../../../global/cards/card-meal-sub-components/CardMealActionLogMeal'
import CardMealActionRemove from '../../../../global/cards/card-meal-sub-components/CardMealActionRemove'
import { mapGetters, mapMutations } from 'vuex'
import PopupEraseMeal from './PopupEraseMealMeal'
import GridCol from '../../../../global/grid/GridCol'

export default {
  name: 'CardSavedMealWithMacros',
  components: {
    GridCol,
    PopupEraseMeal,
    CardMealActionRemove,
    CardMealActionLogMeal,
    CardMealActionViewMeal,
    CardMeal },
  props: {
    name: {
      required: true,
      type: String
    },
    restaurantName:{
      required: false,
      type: String
    },
    id: {
      type: String,
      required: true
    },
    logID: {
      type: String,
      required: true
    },
    isLogged: {
      required: true
    },
    mealType: {
      type: String,
      required: true
    },
    type: {
      type: Object,
      required: true
    },
    recipes: {
      type: Array,
      required: true
    },
    macroNutrients: {
      type: Object,
      required: true
    },
    calories: {
      type: Number,
      required: true
    },
    showNetCarbs: {
      type: Boolean,
      default: false
    }

  },
  data: function () {
    return {
      currentLog: false,
      title: '',
      images: [],
      states: {
        remove: 'pending',
        add: 'pending'
      },
      isRemoved: false,
      mealRemovePopup: {
        visible: false
      },
      mealPlanId: null,
      dayId: null

    }
  },
  computed: {
    ...mapGetters({
      getPlanExpiredPopupShow: 'getPlanExpiredPopupShow'
    }),
    actionRemoveState: function () {
      switch (this.states.remove) {
        case 'pending':
          return ''
        case 'remove' :
          return 'onTap'
        case 'removing':
          return 'active'
        default:
          return ''
      }
    },
    logMealState: function () {
      return (this.states.add === 'adding') ? 'active' : ''
    },
    showViewMeal: function () {
      if (this.mealType === 'custom') {
        return false
      }
      return (this.mealPlanId && this.dayId)
    },
    macroNutrientsArray: function () {
      if (!this.macroNutrients) {
        return
      }
      let macros = [
        {
          qut: this.roundupMacroNutrients(this.macroNutrients.proteins),
          unit: this.$i18n.t('message["food-diary.unit-grams"]'),
          name: this.firstLetterCaps(this.$i18n.t("message['general.proteins']"))
        },
        {
          qut: this.roundupMacroNutrients(this.macroNutrients.fats),
          unit: this.$i18n.t('message["food-diary.unit-grams"]'),
          name: this.firstLetterCaps(this.$i18n.t("message['general.fats']"))
        },
        {
          qut: this.roundupMacroNutrients(this.macroNutrients.carbohydrates),
          unit: this.$i18n.t('message["food-diary.unit-grams"]'),
          name: this.firstLetterCaps(this.$i18n.t("message['general.carbs']"))
        }
      ]
      if (this.showNetCarbs) {
        macros.push({
          qut: this.roundupMacroNutrients(this.macroNutrients.net_carbohydrates),
          unit: this.$i18n.t('message["food-diary.unit-grams"]'),
          name: this.firstLetterCaps(this.$i18n.t("message['general.carbs-net']"))
        })
      }

      return macros
    },
    caloriesObj: function () {
      return [
        {
          qut: this.calories,
          name: this.$i18n.tc("message['general.calories']", this.roundupMacroNutrients(this.calories))
        }
      ]
    }
  },
  mounted () {
    if (this.isLogged) {
      this.states.add = 'adding'
    }
    this.setMealTitle()
    this.setImages()
  },
  methods: {
    ...mapMutations({
      showSubscriptionExpiredPopup: 'showSubscriptionExpiredPopup'
    }),
    setRemoving () {
      this.states.remove = 'removing'
    },
    setError () {
      this.states.remove = ''
    },
    logMeal () {
      if (this.states.add === 'adding') {
        this.states.add = 'pending'
        this.$emit('meal-log-removed')
      } else {
        this.states.add = 'adding'
        this.$emit('meal-log-added')
      }
    },
    setRemoved () {
      setTimeout(() => {
        this.isRemoved = true
        this.$emit('meal-unsaved')
      }, 1000)
    },
    requestRemoveMeal () {
      this.mealRemovePopup.visible = true
    },
    closePopup () {
      this.mealRemovePopup.visible = false
      this.states.remove = 'pending'
    },
    setRemove () {
      if (this.states.remove === 'pending') {
        this.states.remove = 'remove'
        this.requestRemoveMeal()
      }
    },
    showImage (index) {
      this.images[index].state = 'loaded'
    },
    preLoadImages () {
      this.images.forEach(image => {
        let img = new Image()
        img.src = image.url
      })
    },
    setMealTitle () {
      let recipes = this.recipes.map(recipe => {
        return recipe.name
      })
      this.title = (this.restaurantName ? this.restaurantName +' • ' : ''  ) + recipes.join(' | ')
    },
    clickedView () {
      this.delayedRouterPush({ path: [this.$appConfig.appUrlList.mealPlanBase, this.mealPlanId].join('/'), query: { day: this.dayId, meal: this.id } })
    },
    setImages () {
      let images = []
      images = this.recipes.map(recipe => {
        if (this.mealType === 'custom') {
          return recipe.image
        } else {
          return recipe.images[0] || ''
        }
      })

      images = images.filter(image => {
        return image
      })
      this.preLoadImages()
      this.images = images.slice(0, 3)
    },
    roundupMacroNutrients (val) {
      const intVal = parseInt(val)
      if (isNaN(intVal)) {
        return val
      } else {
        return intVal
      }
    }
  }
}
</script>
