import DashboardService from '../../../../services/DashboardService'
import moment from 'moment'
import {mapGetters} from 'vuex'

export default {
  props: {
    fitnessData: {
      default: function () {
        return {}
      }
    },
    type: {default: ''}
  },
  data: function () {
    return {
      status: 'init',
      success: {
        status: false,
        isLoading: false
      },
      validation: {
        required: true,
        min_value: 0.1,
        max_value: 500
      },
      hasError: false,
      generalErrors: [],
      submited: false,
      minValue: 0,
      maxValue: 500,
      step: 1,
      unit: '',
      inputValue: 0,
      imagesUrl: this.$appConfig.imagesUrl,
      measurementSystem: '',
      height: '',
      gender: ''
    }
  },
  watch: {
    inputValue: function (newVal, oldVal) {
      if (this.$refs.metricForm) {
        if (newVal === 0) {
          this.hasError = true
        } else {
          this.$refs.metricForm.validate()
            .then((stat) => {
              this.hasError = !stat
            })
        }
      }
    }
  },
  beforeMount () {
    this.inputValue = this.fitnessData.getInputValue() || 0
    if (this.fitnessData.getType() === 'body_fat') {
      this.inputValue = parseFloat(this.inputValue) * 100
    }
  },
  mounted () {
    const dashboardService = new DashboardService()
    dashboardService.getUserData().then(data => {
      this.height = data.height
      this.gender = data.gender
      this.setMaxAndMinValues()
    })

    this.unit = this.fitnessData.getMeasurementUnit()
    this.inputChanged()
    this.setMaxAndMinValues()
    this.measurementSystem = this.service().getMeasurementSystem()
  },
  computed: {
    isInputHasThreeChars () {
      return parseInt(this.inputValue) >= 100
    },
    inputMode () {
      if (this.fitnessData.getType() === 'weight') {
        return 'decimal'
      }
      return (this.measurementSystem === 'metric') ? 'decimal' : 'numeric'
    }
  },
  methods: {
    ...mapGetters({
      service: 'fitnessDiaryStore/getService'
    }),
    changed () {
      this.inputChanged()
    },
    inputChanged () {
      this.inputValue = this.inputValue || 0
      const zeroFixedTypes = ['weight', 'height']
      if (zeroFixedTypes.includes(this.fitnessData.getType()) && this.service().measurementSystem === 'metric') {
        this.inputValue = Number.parseFloat(this.inputValue).toFixed(1)
      } else if (zeroFixedTypes.includes(this.fitnessData.getType()) && this.service().measurementSystem === 'imperial') {
        this.inputValue = Number.parseFloat(this.inputValue).toFixed(1)
      } else if (this.fitnessData.getType() === 'body_fat') {
        this.inputValue = Math.round(this.inputValue) || 0
      } else if (this.service().measurementSystem === 'imperial') {
        this.inputValue = Number.parseFloat(this.inputValue).toFixed(1) || 0
        this.step = 0.1
      } else {
        this.inputValue = Number.parseFloat(this.inputValue).toFixed(1) || 0
        this.step = 0.1
      }
      this.$nextTick(() => {
        if (this.$refs.metricForm) {
          this.$refs.metricForm.validate()
            .then((stat) => {
              this.hasError = !stat
            })
        }
        if (!parseFloat(this.inputValue)) {
          this.hasError = true
        }
      })
    },
    setMaxAndMinValues () {
      const mType = this.service().measurementSystem
      if (this.fitnessData.getType() === 'height') {
        if (mType === 'metric') {
          this.step = 0.1
          this.minValue = 120
          this.maxValue = 210

          this.validation.min_value = 120
          this.validation.max_value = 210
        } else {
          this.step = 0.1
          this.minValue = 48
          this.maxValue = 83

          this.validation.min_value = 48
          this.validation.max_value = 83
        }
      } else if (this.fitnessData.getType() === 'weight') {
        if (mType === 'metric') {
          this.minValue = 30
          this.maxValue = 250
          this.step = 0.1

          this.validation.min_value = 30
          this.validation.max_value = 250
        } else {
          this.step = 0.1
          this.minValue = 60
          this.maxValue = 550

          this.validation.min_value = 60
          this.validation.max_value = 500
        }
      } else if (this.fitnessData.getType() === 'body_fat') {
        this.validation = {
          required: true,
          min_value: 3,
          max_value: 50
        }
        if (this.gender === 'f') {
          this.validation.min_value = 10
        }
      }
    },
    setHasError (error) {
      this.hasError = error
    },
    getBodyFatValue () {
      return this.inputValue * 100
    },
    setBodyFatValue (data) {
      this.inputValue = data
      this.inputChanged()
    },
    doAction () {
      if (this.submited || this.hasError) {
        // do nothing
      } else if (this.fitnessData.getType() !== 'body_fat') {
        this.$refs['metricForm'].validate()
          .then(() => {
            this.saveData()
          })
      } else {
        this.saveData()
      }
    },
    saveData () {
      if (!parseFloat(this.inputValue)) {
        return
      }
      this.status = 'loading'
      this.submited = true

      this.fitnessData.setInputValue(this.inputValue)
      this.fitnessData.saveData()
        .then(() => {
          this.status = 'success'
          this.success.status = true
          this.fitnessData.setLatestDate(
            moment().format('YYYY-MM-DD')
          )
          this.afterUpdateData()
          this.$emit('updated')
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 422) {
            if (err.response.data.type === 'form') {
              if (this.$refs['metricForm']) {
                const err1 = Object.values(
                  err.response.data.errors
                )
                const errorKey = this.fitnessData.getType()
                let errObj = {}
                errObj[errorKey] = err1[0]
                this.$refs['metricForm'].setErrors(errObj)
              } else {
                this.generalErrors = Object.values(
                  err.response.data.errors
                )
              }
            } else if (err.response.data.type === 'logic') {
              this.generalErrors = err.response.data.errors
            } else {
              let errors = []
              const serverErrors = Object.values(err.response.data.errors)
              errors = errors.concat(serverErrors)
              this.generalErrors = errors
            }
          } else {
            this.generalErrors = [
              this.$i18n.t('message["general.server-error-title"]')
            ]
          }
        })
        .finally(() => {
          this.submited = false
          this.hideLoading()
        })
    },
    afterUpdateData () {

    },
    addValue () {
      this.inputValue = parseFloat(this.inputValue) || 0
      if (this.inputValue < this.maxValue) {
        this.inputValue += this.step
      }
      if (this.inputValue > this.maxValue) {
        this.inputValue = this.maxValue
      }
      this.inputChanged()
    },

    reduceValue () {
      this.inputValue = parseFloat(this.inputValue) || 0
      if (this.inputValue >= this.minValue) {
        this.inputValue -= this.step
      }
      if (this.inputValue < this.minValue) {
        this.inputValue = this.minValue
      }
      this.inputChanged()
    }
  }
}
