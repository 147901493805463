export default class InvalidCouponError extends Error {
  constructor (error = 'invalid') {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super()

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InvalidCouponError)
    }

    this.name = 'InvalidCouponError'
    // Custom debugging information
    this.error = error
  }
}
