<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.0525 13.8018H11.9795C11.5482 13.8018 11.1982 13.4518 11.1982 13.0206V4.94751C11.1982 4.51626 11.5482 4.16626 11.9795 4.16626C12.4107 4.16626 12.7607 4.51626 12.7607 4.94751V12.2393H20.0525C20.4838 12.2393 20.8338 12.5893 20.8338 13.0206C20.8338 13.4518 20.4838 13.8018 20.0525 13.8018Z" fill="black"/>
    <path d="M12.5 25C5.60722 25 0 19.3928 0 12.5C0 5.60722 5.60722 0 12.5 0C19.3928 0 25 5.60722 25 12.5C25 19.3928 19.3928 25 12.5 25ZM12.5 1.5625C6.46877 1.5625 1.5625 6.46877 1.5625 12.5C1.5625 18.5312 6.46877 23.4375 12.5 23.4375C18.5312 23.4375 23.4375 18.5312 23.4375 12.5C23.4375 6.46877 18.5312 1.5625 12.5 1.5625Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconClock',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-clock': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
