<template>
  <button-base :borderRadius="'sm1'" :class="componentClasses" :type="'outline'" @click="clicked()">
    <text-content :class="textClass" :line-height="'multi'" :size="'sm4'" :weight="'extra-bold'"> {{ text }}</text-content>
    <text-content :class="subTextClass" :line-height="'multi'" :size="'sm1'"> {{ subText }}</text-content>
  </button-base>
</template>

<script>

import TextContent from '../../root/TextContent'
import ButtonBase from '../../root/ButtonBase'

export default {
  name: 'ButtonChoice',
  components: {
    ButtonBase,
    TextContent
  },
  props: {
    /**
     *  The main text of the button
     */
    text: {
      default: ''
    },
    /**
     * The sub text of the button
     */
    subText: {
      default: ''
    },
    /**
     * The disabled state of the button
     */
    disabled: {
      default: false
    },
    /**
     * The selected state of the button
     */
    selected: {
      default: false
    }
  },
  computed: {
    hasSubText: function () {
      return this.subText.replace(/\s/g, '') !== ''
    },
    textClass: function () {
      return {
        'gc-button-choice__text': true,
        'gc-button-choice__text--with-sub-text': this.hasSubText
      }
    },
    subTextClass: function () {
      return {
        'gc-button-choice__sub-text': true
      }
    },
    componentClasses: function () {
      return {
        'gc-button-choice': true,
        'gc-button-choice--disabled': this.disabled,
        'gc-button-choice--selected': this.selected,
        'gc-button-choice--with-sub-text': this.hasSubText
      }
    }
  },
  methods: {
    /**
     * function to emit the click event
     */
    clicked () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped></style>
