<template>
    <div :class="componentClasses" :style="elementStyles">
      <div class="gc-page-fixed-footer__fixed-area container" ref="fixedArea">
        <slot></slot>
      </div>

    </div>
</template>

<script>
export default {
  name: 'PageFixedFooter',
  props: {
    fixedOnDesktop: {
      default: false,
      type: Boolean
    },
    unfixedOnTablet: {
      default: false,
      type: Boolean
    }
  },
  data: function () {
    return {
      mutationObserverFooter: null,
      elementStyles: {
        height: 0
      }
    }
  },
  computed: {
    componentClasses: function () {
      return ((this.isTablet && this.unfixedOnTablet) || (this.isDesktop && !this.fixedOnDesktop)) ? ['gc-page-fixed-footer', 'gc-page-fixed-footer--unfixed'] : ['gc-page-fixed-footer', 'gc-page-fixed-footer--fixed']
    }
  },
  mounted () {
    this.observeElements()
    this.setContentHeight()
  },
  beforeDestroy () {
    if (this.mutationObserverFooter) {
      this.mutationObserverFooter.disconnect()
    }
  },
  methods: {
    observeElements () {
      const footer = this.$refs.fixedArea
      if (footer) {
        const config = { attributes: true, childList: true, subtree: true }
        const callback = () => {
          this.setContentHeight()
        }
        this.mutationObserverFooter = new MutationObserver(callback)
        this.mutationObserverFooter.observe(footer, config)
      }
    },
    setContentHeight () {
      let footer = this.$refs.fixedArea
      if (footer) {
        let dimensions = footer.getBoundingClientRect()
        this.elementStyles.height = dimensions.height + 'px'
      }
    }
  }
}
</script>
