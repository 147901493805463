<template>
  <!--   page background -->
  <page class="pc-subscription-finish" :desktop-side-bar="false" :header="headerOptions" :background-image="backgroundImage">

    <!--   page wrapper -->
    <page-container>
      <div class="pc-subscription-finish__card-feature-box">
        <text-heading4
          :lineHeight="'multi'"
          :weight="'extra-bold'"
          class="pc-subscription-finish__card-feature-box__title"
          v-if="hasGreetingText()"
          v-html="$t('message[\'form-title.subscription-success\']')"/>
        <text-body-regular
          :lineHeight="'multi'"
          class="pc-subscription-finish__card-feature-box__sub-text"
          v-html="$t('message[\'form-helper-text.success\']')"/>
        <button-primary
          @click="completeSubmit()"
          :text="$t('message[\'general.done\']')"/>
      </div>

    </page-container>
    <div class="app-bg-overlay"></div>
  </page>
</template>
<script>
import BaseComponent from '../global/widgets/forms/FormBaseComponent'
import { mapGetters, mapMutations } from 'vuex'
import PopupDataInvalid from '../../../global/popups/PopupDataInvalid'
import Page from '../../../global/pages/PageImageCover'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import ButtonPrimary from '../../../global/buttons/ButtonPrimary'
import TextHeading2 from '../../../global/typography/TextHeading2'
import TextBodyRegular from '../../../global/typography/TextBodyRegular'
import TextHeading4 from '../../../global/typography/TextHeading4'

export default {
  extends: BaseComponent,
  name: 'Finished',
  components: {
    TextHeading4,
    PopupDataInvalid,
    TextBodyRegular,
    TextHeading2,
    ButtonPrimary,
    PageContainer,
    Page
  },
  data: function () {
    return {
      description: this.$i18n.t("message['form-helper-text.success']"),
      hasError: false,
      backgroundImage: '',
      pageClass: ['macroapp--page-start'],
      errors: [],
      headerOptions: {
        show: false,
        left: '',
        right: '',
        modifiers: ['with-img'],
        mainTitle: '',
        subTitle: '',
        isTransparent: true
      }
    }
  },
  watch: {
    isDesktop: function () {
      this.headerOptions.show = !this.isDesktop
    }
  },
  beforeMount () {
    this.showLoading()
    this.headerOptions.show = !this.isDesktop
  },
  mounted () {
    this.backgroundImage = this.getStoreImage('onboard.finishBackground')
    this.hideLoading()
  },
  methods: {
    ...mapGetters({
      getService: 'formWizardStore/getSubscriptionFormService'
    }),
    ...mapMutations({
      setService: 'formWizardStore/setSubscriptionFormService',
      setFormStatus: 'formWizardStore/setFormStatus'
    }),
    hasGreetingText () {
      let html = this.$i18n.t("message['form-title.subscription-success']")
      let div = document.createElement('div')
      div.innerHTML = html
      let text = div.textContent || div.innerText || ''
      return Boolean(text)
    },
    navigate () {
      const subscription = this.$route.params.subscription
      this.$router.push({
        path:
            this.$appConfig.appUrlList.onboard.replace(
              ':subscription',
              subscription
            ) + '/1'
      })
    },
    completeSubmit () {
      this.$emit('complete')
      this.$router.push({
        path: this.$appConfig.appUrlList.dashboard
      })
    }
  }
}
</script>
