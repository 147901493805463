<template>
  <div :class="componentClasses" v-on="$listeners">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.2593 8.88889H10.7407V0.740741C10.7407 0.331111 10.4089 0 10 0C9.59111 0 9.25926 0.331111 9.25926 0.740741V8.88889H0.740741C0.331852 8.88889 0 9.22 0 9.62963C0 10.0393 0.331852 10.3704 0.740741 10.3704H9.25926V19.2593C9.25926 19.6689 9.59111 20 10 20C10.4089 20 10.7407 19.6689 10.7407 19.2593V10.3704H19.2593C19.6681 10.3704 20 10.0393 20 9.62963C20 9.22 19.6681 8.88889 19.2593 8.88889Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconAdd',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-add': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
