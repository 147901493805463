<template>
  <div></div>
</template>

<script>
import LoadingBuffer from '../widgets/LoadingBuffer'
import { mapMutations } from 'vuex'

export default {
  name: 'BaseComponent',
  props: {
    pageConfig: {
      type: Object,
      required: false,
      default: function () {
        return {
          type: '',
          name: '',
          variation: 'design1',
          properties: [],
          widgets: []
        }
      }
    }
  },
  components: {
    LoadingBuffer
  },
  data: function () {
    return {
      imagesUrl: this.$appConfig.imagesUrl,
      pageClass: [''],
      workoutDetails: {},
      popupData: {},
      popupComponents: {},
      activePopupComponent: ''
    }
  },
  computed: {
    params: function () {
      return this.$route.query
    }
  },
  methods: {
    ...mapMutations({
      setHeader: 'pageStore/setHeader',
      setBackground: 'pageStore/setBackground',
      setBackgroundImage: 'pageStore/setBackgroundImage',
      resetHeader: 'pageStore/resetHeader',
      resetBackground: 'pageStore/resetBackground',
      addPageClass: 'pageStore/addPageClass',
      removeClasses: 'pageStore/removeClasses',
      viewInstructionsPopup: 'instructionPopupStore/show',
      setInstructionsPopupContent: 'instructionPopupStore/setContent'
    }),
    setPageClass () {
      // set page class for custom styles
      // top class set
      let classes = []
      if (Array.isArray(this.pageClass)) {
        classes = this.pageClass
      } else {
        classes.push(this.pageClass)
      }
      if (document.getElementsByClassName('macroapp').length > 0) {
        const topEl = document.getElementsByClassName('macroapp')[0]
        const newClass = topEl.classList[0] + ' ' + classes.join(' ')
        topEl.setAttribute('class', newClass)
      }

      this.addPageClass([this.pageClass])
    },
    navigatePrevious () {
      window.history.back()
    }
  },
  destroyed () {
    this.removeClasses(this.pageClass)
  }
}
</script>
