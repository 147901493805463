<template>
  <div :class="componentClasses"  >
        <div class="pc-workout__nav-circuit-exercises__back-button"  v-on:click="navigatePreviousExerciseCircuit">
          <icon-backward-arrow-rounded v-if="showCircuitBackButton" :size="'md2'"/>
        </div>
        <div class="pc-workout__nav-circuit-exercises__middle-text">
          <text-body-extra-small>
             <icon-complete-filled v-if="currentExercise.getIsCompleted()" :size="'sm1'" />
            {{ getCircuitModeNavText() }}
          </text-body-extra-small>
        </div>
        <div class="pc-workout__nav-circuit-exercises__forward-button">
         <icon-forward-arrow-rounded
              v-if="showCircuitNextButton"
              :size="'md1'"
              @click="navigateNextExerciseCircuit"
            />
             <icon-complete-rounded v-if="showCircuitFinishButton" :size="'md2'" @click="finishExerciseCircuit()"/>
        </div>
  </div>

</template>
<script>
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import IconForwardArrowRounded from '../../../../root/icons/IconForwardArrowRounded'
import IconBackwardArrowRounded from '../../../../root/icons/IconBackwardArrowRounded'
import IconCompleteFilled from '../../../../root/icons/IconCompleteFilled'
import IconCompleteRounded from '../../../../root/icons/IconCompleteRounded'

export default {
  name: 'NavCircuitExercises',
  components: {
    TextBodyExtraSmall,
    IconForwardArrowRounded,
    IconBackwardArrowRounded,
    IconCompleteFilled,
    IconCompleteRounded
  },
  props: {
    exercises: {
      default: ''
    },
    currentExercise: '',
    showCircuitBackButton: false,
    showCircuitNextButton: true,
    showCircuitFinishButton: false
  },
  computed: {
    componentClasses: function () {
      return {
        'pc-workout__nav-circuit-exercises': true,
        'pc-workout__nav-circuit-exercises--with-slider': true
      }
    }
  },
  methods: {
    getCurrentExerciseIndex () {
      return this.exercises.findIndex((ex) => {
        return ex.id === this.currentExercise.getID()
      })
    },
    getCircuitModeNavText () {
      let currentIndex = this.getCurrentExerciseIndex()
      currentIndex++
      return this.$i18n.t(
        'message["workout-log.group-exercise-current-text-circuit"]',
        [currentIndex, this.exercises.length]
      )
    },

    navigatePreviousExerciseCircuit () {
      this.$emit('goto-previous-exercise')
    },
    navigateNextExerciseCircuit () {
      this.$emit('goto-next-exercise')
    },
    finishExerciseCircuit () {
      this.$emit('finish-circuit')
    }
  }
}
</script>
