<template>

    <transition name="fade" mode="out-in">
      <component v-bind:is="dynamicComponent" v-bind="{...componentData,'pageConfig': getPageConfigByName(this.page) }" v-on:submit="formSubmit($event)"></component>
    </transition>

</template>

<script>
import BaseComponent from '../global/base/BaseComponent'
import InjuriesPreventExercising from '../../../global/pages/PageInjuriesPreventExercising'
import ProgressCheckInService from '../../../../services/ui/forms/ProgressCheckInService'
import Measurements from './PageBodyMeasurements'
import { mapGetters, mapMutations, mapState } from 'vuex'
import Workouts from '../../../global/pages/PageWorkouts'

export default {
  name: 'WorkoutMain',
  extends: BaseComponent,
  data: function () {
    return {
      /** sub component config **/
      components: {
        'progress-injuries': {
          component: { design1: InjuriesPreventExercising },
          querySection: 'injuries',
          options: {},
          init: function (resolve, reject, service, data) {
            if (!service.showInjuries()) {
              reject(new Error('skip progress-injuries'))
            } else {
              this.options = service.getInjuriesFormData()
              resolve()
            }
          },
          onSubmit: function (service, data) {
            service.fitness.injuries = data.value
          },
          getNextComponent: function (resolve, reject, service) {
            resolve({ component: Workouts, query: { page: 'fitness', section: 'workout' } })
          }
        },
        'progress-workout': {
          component: { design1: Workouts },
          querySection: 'workout',
          options: {},
          init: function (resolve, reject, service, data) {
            this.options = service.getWorkoutsFormData()
            resolve()
          },
          onSubmit: function (service, data) {
            service.saveWorkouts(data.value)
          },
          getNextComponent: function (resolve, reject, service, comData) {
            resolve({ component: Measurements, query: { page: 'measurements' } })
          }
        }
      },
      /** end sub component config **/
      currentComponent: InjuriesPreventExercising,
      dynamicComponent: '',
      componentData: {}
    }
  },
  mounted () {
    if (this.getService() === null) {
      const progressService = new ProgressCheckInService()
      const payload = { type: 'fitness' }
      progressService.getDataFromAPI(payload).then(data => {
        this.setService(progressService)
        this.loadComponent(this.getService())
      }).catch(() => {
        this.showServerErrorPopup()
        this.hideLoading()
      })
    } else {
      this.loadComponent(this.getService())
    }
  },
  computed: {
    ...mapState({
      formStatus: 'formWizardStore/formStatus'

    }),
    page () {
      for (const [key, value] of Object.entries(this.components)) {
        if (value.querySection === this.$route.query.section) {
          return key
        }
      }
      return 'progress-injuries'
    }
  },
  methods: {
    ...mapGetters({
      getService: 'formWizardStore/getProgressFormService'
    }),
    ...mapMutations({
      setService: 'formWizardStore/setProgressFormService',
      showServerErrorPopup: 'showServerErrorPopup',
      setFormStatus: 'formWizardStore/setFormStatus'

    }),
    loadComponent (service) {
      const curComponent = this.getComponentBySection()
      const data = {}
      const componentData = new Promise((resolve, reject) => {
        curComponent.init(resolve, reject, service, data)
      })

      this.currentComponent = curComponent
      componentData.then(() => {
        this.componentData = curComponent.options
        this.dynamicComponent = this.getComponentByConfig()
      }).catch(() => {
        this.nextComponent(true)
        this.hideLoading()
      })
    },
    getComponentBySection () {
      return this.components[this.page]
    },
    getComponentByComponentObject (component) {
      for (const [key, value] of Object.entries(this.components)) {
        // TODO -fix
        // eslint-disable-next-line no-unused-vars
        for (const [ckey, cvalue] of Object.entries(value.component)) {
          if (cvalue === component) {
            return this.components[key]
          }
        }
      }
      return this.components['progress-injuries']
    },
    formSubmit (data) {
      data.index = this.getIndex()
      var isValueChanged
      if (data.key === 'injuriesPrvtExc') {
        data.key = 'injuries'
        isValueChanged = this.checkValueChange(this.getService().fitness[data.key], data.value, data.key)
      } else if (data.key === 'workout') {
        data.key = 'programs'
        isValueChanged = this.checkValueChange(this.getService().programs, data.value, data.key)
      } else {
        isValueChanged = this.checkValueChange(this.getService().fitness[data.key], data.value, data.key)
      }

      this.currentComponent.onSubmit(this.getService(), data)
      this.logEvent('ProgressUpdate.StepComplete', {type: this.$route.path.split('/')[1], step: data.key, value_changed: isValueChanged}, false)
      this.nextComponent()
    },

    checkValueChange (oldValue, newValue, key) {
      if (key === 'programs') {
        let newArray = []
        let newOldArray = []
        newValue.forEach(element => {
          let emptyObject = {
            'id': element.id,
            'goal': element.workout_goal,
            'weeks': element.weeks,
            'days_per_week': element.days_per_week,
            'session_duration': element.duration
          }
          newArray.push(emptyObject)
        })

        oldValue.forEach(element => {
          let oldObject = {
            'id': element.id,
            'goal': element.goal,
            'weeks': element.weeks,
            'days_per_week': element.days_per_week,
            'session_duration': element.session_duration
          }
          newOldArray.push(oldObject)
        })
        return JSON.stringify(newOldArray) !== JSON.stringify(newArray)
      } else {
        return newValue !== oldValue
      }
    },

    arrayEquals (a, b) {
      return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    },

    nextComponent (replace = false) {
      const data = {}
      const next = new Promise((resolve, reject) => {
        this.getCurrentComponent().getNextComponent(resolve, reject, this.getService(), data)
      })
      next.then(nextComponent => {
        this.navigateToProgress(nextComponent.query, replace)
      })
    },
    getCurrentComponent () {
      return this.currentComponent
    },
    getIndex () {
      return parseInt((this.$route.query.index || '0'))
    },
    navigateToProgress (query, replace = false) {
      const routeObj = {
        path: this.$route.path,
        query: query
      }
      if (replace) {
        this.$router.replace(routeObj)
      } else {
        this.$router.push(routeObj)
      }
    },
    getComponentByConfig () {
      const pageConfig = this.getPageConfigByName(this.page)
      const pageComponents = this.getComponentBySection().component
      if (pageConfig && pageConfig.variation) {
        return pageComponents[pageConfig.variation] || pageComponents.design1
      } else {
        return pageComponents.design1
      }
    }
  }

}
</script>
