export default class DBHelper {
  constructor (dbName, dbVersion, storeName) {
    this.dbName = dbName
    this.dbVersion = dbVersion
    this.storeName = storeName
    this.db = null
  }

  openDatabase () {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, this.dbVersion)

      request.onupgradeneeded = event => {
        const db = event.target.result
        if (!db.objectStoreNames.contains(this.storeName)) {
          const store = db.createObjectStore(this.storeName, {
            keyPath: 'id',
            autoIncrement: true
          })
          // Creating an index for the 'name' property
          store.createIndex('nameIndex', 'name', { unique: false });

        }
      }

      request.onsuccess = event => {
        this.db = event.target.result
        resolve(this.db)
      }

      request.onerror = event => {
        reject(event.target.error)
      }
    })
  }

  closeDatabase () {
    if (this.db) {
      this.db.close()
      this.db = null
    }
  }

  addData (data) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readwrite')
      const store = transaction.objectStore(this.storeName)
      const request = store.add(data)

      request.onsuccess = event => {
        resolve(event.target.result)
      }

      request.onerror = event => {
        reject(event.target.error)
      }
    })
  }
  addBulkData(dataArray) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readwrite');
      const store = transaction.objectStore(this.storeName);

      let addedCount = 0;
      const totalItems = dataArray.length;

      dataArray.forEach(data => {
        const request = store.add(data);

        request.onsuccess = () => {
          addedCount++;
          if (addedCount === totalItems) {
            resolve(true);
          }
        };

        request.onerror = event => {
          reject(event.target.error);
        };
      });
    });
  }


  getData (id) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readonly')
      const store = transaction.objectStore(this.storeName)
      const request = store.get(id)

      request.onsuccess = event => {
        resolve(event.target.result)
      }

      request.onerror = event => {
        reject(event.target.error)
      }
    })
  }

  getAllData () {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readonly')
      const store = transaction.objectStore(this.storeName)
      const request = store.getAll()

      request.onsuccess = event => {
        resolve(event.target.result)
      }

      request.onerror = event => {
        reject(event.target.error)
      }
    })
  }

  updateData (id, newData) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readwrite')
      const store = transaction.objectStore(this.storeName)
      const request = store.put(newData, id)

      request.onsuccess = event => {
        resolve(event.target.result)
      }

      request.onerror = event => {
        reject(event.target.error)
      }
    })
  }

  deleteData (id) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readwrite')
      const store = transaction.objectStore(this.storeName)
      const request = store.delete(id)

      request.onsuccess = (event) => {
        resolve()
      }

      request.onerror = event => {
        reject(event.target.error)
      }
    })
  }
  getDataCount() {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readonly');
      const store = transaction.objectStore(this.storeName);
      const request = store.count();

      request.onsuccess = event => {
        resolve(event.target.result);
      };

      request.onerror = event => {
        reject(event.target.error);
      };
    });
  }

  openCursorOnStore( callback) {
    const storeName = this.storeName
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([storeName], 'readonly');
      const store = transaction.objectStore(storeName);
      const request = store.openCursor();

      const results = [];

      request.onsuccess = event => {
        const cursor = event.target.result;
        if (cursor) {
          callback(results, cursor);
        } else {
          resolve(results);
        }
      };

      request.onerror = event => {
        reject(event.target.error);
      };
    });
  }

  searchByName(nameSubstring) {
    nameSubstring = nameSubstring.toLowerCase();

    return this.openCursorOnStore( (results, cursor) => {
      const name = cursor.value.name.toLowerCase();
      if (name.includes(nameSubstring)) {
        results.push(cursor.value);
      }
      cursor.continue();
    });
  }

  getNameList(){
    return this.openCursorOnStore( (results, cursor) => {
      results.push({name: cursor.value.name});
      cursor.continue();
    });
  }


}
