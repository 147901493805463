<template>
  <div
    class="container gc-page__container"
    v-on="$listeners"
    v-bind="$attrs">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'PageContainer'
}
</script>

<style scoped>

</style>
