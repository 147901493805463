import BaseComponent from '../../../../components/layout/template-1/global/base/BaseComponent'
import unitSystem from '../../../../config/measurement-units'
import lodash from 'lodash'
import { mapGetters, mapMutations } from 'vuex'

/**
 * there are variations of the cart button with same logic
 * to different variation can use this mixin
 */
export default {
  extends: BaseComponent,
  props: {
    groupInfo: {
      default: function () {
        return {
          setName: '',
          currentID: 1,
          totalExercises: 1
        }
      },
      type: Object
    },
    exerciseTimeValue: { default: 0 },
    forceEdit: { default: false },
    isCompleted: { default: false },
    exerciseID: {
      default: 0,
      type: Number
    },
    exerciseName: { default: '' },
    dayName: { default: '' },
    weekName: { default: '' },
    workoutID: { default: 0 },
    currentUnitSystem: { default: 'metric' },
    timezone: { default: '' },
    historyData: {
      default: function () {
        return []
      },
      type: [Object, Array]
    },
    metrics: {
      default: function () {
        return []
      },
      type: Array
    },
    entries: {
      default: function () {
        return []
      },
      type: Array
    },
    preNote: { default: '' },
    enabled: {
      default: false,
      type: Boolean
    },
    sets: {
      default: '',
      type: String
    },
    setsCount: {
      default: 0,
      type: Number
    }
  },
  data: function () {
    return {
      popupExtraSetDelete:{
        show: false,
        index: -1,
      },
      isSubmitting: false,
      setID: 0,
      showRestTimer: false,
      confirmPopupShow: false,
      exitPopupShow: false,
      requestEdit: false,
      requestDelete: false,
      isLoaded: false,
      isDeleted: false,
      lastTimerValue: null,
      lastRestTimerValue: null,
      exerciseTime: 0,
      metricsArranged: [],
      personalBestPopup: {
        show: false,
        value: ''
      },
      formData: [],
      note: '',
      data: {
        type: '',
        value: '',
        placeholder: '',
        label: ''
      },
      ticksClasses: {
        '01': 'tick-green',
        10: 'tick-black',
        11: 'tick-green',
        '00': 'tick-grey'
      },
      restTimerSet: 0 // rest timer shown count
    }
  },
  mounted () {
    this.calculateWorkoutTime()
    this.arrangeMetrics()
    const sets = this.sets.split('/')
    this.setCurrentExerciseInStore(parseInt(this.exerciseID))
    const storeData = this.getLogDataFromStore(parseInt(this.exerciseID))
    this.note = this.preNote
    if (storeData && storeData.note) {
      this.note = storeData.note
    }
    if (storeData && storeData.setID) {
      this.setID = storeData.setID
    }
    if (storeData && storeData.formData.length > 0 &&
      storeData.formData[0].attributes.length === this.metrics.length && sets.length === storeData.formData.length) {
      const isMatchMetrics = storeData.formData[0].attributes.every(att => {
        return this.metrics.some(metric => metric.type === att.type)
      })
      if (isMatchMetrics) {
        if (storeData && storeData.lastTimerValue) {
          this.lastTimerValue = storeData.lastTimerValue
        }
        this.formData = storeData.formData
        this.validateCompletedForms()
        this.refreshRestTimerCount()

        this.$nextTick(()=>{
          if (storeData && storeData.lastTimerValue===0 && storeData.lastRestTimerValue && this.$refs.workoutTimer){
            this.$refs.workoutTimer.setRestTimerTime(storeData.lastRestTimerValue)
            this.$refs.workoutTimer.showRestTimer()
          }
        })
        return
      }
    }
    if (sets.length < this.entries.length) {
      let missingSets = this.entries.length - sets.length
      for (missingSets; missingSets > 0; missingSets--) {
        sets.push('1')
      }
    }
    sets.forEach((set, index) => {
      let defaults = { attributes: [] }
      // historyData comes as desc of date so get the latest value
      if (lodash.head(this.historyData)) {
        defaults = lodash.head(this.historyData).entries[index]
      }
      const entryData = this.entries[index] || defaults
      this.formData.push(
        this.createEntry(set, index, entryData, this.currentUnitSystem, this.timezone)
      )
    })

    if (this.isCompleted) {
      let currentID = 0
      this.formData.forEach((data, id) => {
        const isValid = this.validateForm(id, false)
        if (isValid && id < this.formData.length - 1) {
          currentID = id + 1
        }
        this.formData[id].current = false
        this.formData[id].completed = isValid && this.entries.length > id
      })
      this.formData[currentID].current = true
      this.setID = currentID
    }
    this.refreshRestTimerCount()

  },
  computed: {
    ...mapGetters({
      service: 'singleExercisePageStore/getCurrentWorkoutLogService'
    }),
    isLastSetCompleted:function(){
      const lastSets = this.formData.slice(-1)
      if(lastSets.length===0){
        return false
      }
      const lastSet =lastSets[0]
      return lastSet.completed
    },
    isAllDefinedSetsCompleted:function (){
      return this.formData.slice(0,this.exerciseSetCount).every(set => set.completed)
    },
    exerciseSetCount: function () {
      return  this.sets.split('/').length
    },
    /**
     * check get the timers header
     */
    timerHeader: function () {
      const currentIndex = this.currentFormIndex()
      if (currentIndex >= 0) {
        return this.$i18n.tc('message["general.set"]', 1) + ' ' + (currentIndex + 1)
      }
    },
    /**
     * get the timer current step
     */
    timerCurrentSet: function () {
      const currentIndex = this.currentFormIndex()
      if (currentIndex >= 0) {
        return currentIndex + 1
      } else {
        return 0
      }
    },
    /**
     * get the total completed sets count
     * @returns {*}
     */
    totalCompleted: function () {
      return this.formData.filter((form) => form.completed).length
    }
  },
  watch: {
    totalCompleted: function () {
      this.setLogDataInStore()
    }
  },
  methods: {
    ...mapGetters({
      getLogDataFromStore: 'workoutLogStore/getFormLogData',
      exerciseData: 'singleExercisePageStore/getExerciseData'
    }),
    ...mapMutations({
      setCurrentExerciseInStore: 'workoutLogStore/setCurrentExercise',
      unsetLogDataInStore: 'workoutLogStore/unsetLogFormData',
      setLogDataToStore: 'workoutLogStore/setLogFormData'
    }),
    requestDeleteSet(index){
      this.popupExtraSetDelete.show = true
      this.popupExtraSetDelete.index = index
    },
    /**
     * Deletes a set from the formData array based on the provided index.
     * After deletion, it reassigns the index values for the remaining sets.
     * If there is no current exercise index, it sets the current exercise to the first uncompleted one.
     *
     * @param {number} index - The index of the set to be deleted from the formData array.
     */
    deleteSet(index){
      // Filter out the set with the provided index
      this.formData =  this.formData.filter(item => item.index !== index)

      // Reassign the index values for the remaining sets
      this.formData =  this.formData.map((item,i) => {
        item.index = i
        return item
      })

      // Check if there is a current exercise index
      const hasCurrentExerciseIndex = this.formData.findIndex(item=>item.current)
      // If there is no current exercise index
      if(hasCurrentExerciseIndex<0){
        let previousCompletedIndex = 0

        // Set the current exercise to the first uncompleted one
        this.formData =  this.formData.map((item,i) => {

          if(item.completed){
            previousCompletedIndex = i
            return item
          }else if(i === previousCompletedIndex + 1){
            item.current = true
          }

          return item
        })
      }

    },
    /**
     * Creates a new entry in the formData array.
     *
     * This function creates a new entry with default attributes and appends it to the formData array.
     * The new entry is created using the createEntry function with a set of 1, an index of the last form index plus 1,
     * default attributes, the current unit system, and the timezone.
     * If the last form in the formData array is completed, it sets the current attribute of the last form to true
     * and sets the next current form.
     */
    createNewEntry(){
      // Initialize default attributes
      let defaults = { attributes: [] }

      // Get the index of the last form in the formData array
      const lastFormIndex = this.formData.length - 1

      // Create a new entry and append it to the formData array
      this.formData.push(
        this.createEntry(1, (lastFormIndex+1), defaults, this.currentUnitSystem, this.timezone)
      )


      // If the last form in the formData array is completed
      if(this.formData[lastFormIndex].completed){
        // Set the current attribute of the last form to true
        this.formData[lastFormIndex].current = true

        // Set the next current form
        this.setNextCurrent(lastFormIndex)
      }
    },
    restMessage () {
      if (this.groupInfo.totalExercises > 1) {
        return this.$i18n.t('message["workout.no-workout-message-timer-with-value-group-set"]')
      } else {
        return this.$i18n.t('message["workout.no-workout-message-timer-with-value"]')
      }
    },
    currentFormIndex () {
      return this.formData.findIndex((form) => {
        return form.current
      })
    },
    /**
     * arrange Metrics for specific order {reps,weight,distance,duration}
     */
    arrangeMetrics () {
      const sortedBy = {
        reps: 0,
        weight: 1,
        distance: 2,
        duration: 3
      }
      this.metricsArranged = this.metrics.sort(
        (a, b) => sortedBy[a.type] - sortedBy[b.type]
      )
    },
    /**
     * calculate the workout time to seconds
     */
    calculateWorkoutTime () {
      this.exerciseTime = 0
      if (
        typeof this.exerciseTimeValue !== 'undefined' &&
        this.exerciseTimeValue !== null
      ) {
        this.exerciseTime = parseInt(this.exerciseTimeValue)
        if (this.exerciseTimeInterval === 'minute') {
          this.exerciseTime = this.exerciseTime * 60
        } else if (this.exerciseTimeInterval === 'hour') {
          this.exerciseTime = this.exerciseTime * 60 * 60
        }
      }
    },
    formErrorMessage (errors) {
      for (const [key, value] of Object.entries(errors)) {
        if (value.length > 0 && key) {
          const error = value[0]
          return error.charAt(0).toUpperCase() + error.slice(1).toLowerCase()
        }
      }
      return ''
    },
    /**
     * function that call when the form input is changed
     *
     * check ,set the completed status of the form
     * @param fIndex
     * @param eIndex
     * @param value
     * @param silent
     */
    entryChanged (fIndex, eIndex, value, silent = false) {
      this.$nextTick(() => {
        this.setLogDataInStore()
        this.formData[fIndex].attributes[eIndex].value = value.toString().replace(/,/g, '.')
        if (this.formData[fIndex].attributes[eIndex].type === 'reps') {
          this.formData[fIndex].attributes[eIndex].value = parseInt(this.formData[fIndex].attributes[eIndex].value) || ''

          this.formData[fIndex].attributes[eIndex].value = this.formData[fIndex].attributes[eIndex].value.toString()
        } else if (this.formData[fIndex].attributes[eIndex].type === 'weight' && typeof this.formData[fIndex].attributes[eIndex].value === 'string' && this.formData[fIndex].attributes[eIndex].value.slice(-1) !== '.' && !silent) {
          let updatedValue = parseFloat(this.formData[fIndex].attributes[eIndex].value)
          if (!updatedValue && updatedValue !== 0) {
            updatedValue = ''
          }
          this.formData[fIndex].attributes[eIndex].value = updatedValue.toString()
        }
        if (this.formData[fIndex].attributes[eIndex].type === 'duration' && !silent) {
          const timeVal = this.service.timeToSeconds(this.formData[fIndex].attributes[eIndex].value)
          this.formData[fIndex].attributes[eIndex].value = this.numberToString(timeVal)
          this.setCurrentTimerToMainTimer()
          return
        }
        const haveAllEntryIsFilled = this.formData[fIndex].attributes.every(
          (attribute) => {
            const valueToValidate = attribute.value
            return valueToValidate !== '' && valueToValidate !== '0' && valueToValidate !== '00:00'
          }
        )
        if (!silent && haveAllEntryIsFilled && this.validateForm(fIndex)) {
          this.lastTimerValue = 0
          this.formData[fIndex].completed = true
          if (this.formData.length !== fIndex + 1) {
            this.setNextCurrent(fIndex)
            setTimeout(() => {
              this.resetTimerAndCompleteSet(fIndex)
            }, 200)
          }
        } else if (!this.formData[fIndex].completed) {
          this.formData[fIndex].current = true
          this.formData[fIndex].completed = false
        } else if (!this.validateForm(fIndex)) {
          this.formData[fIndex].completed = false
          this.formData[fIndex].current = true
        }
        this.setLogDataInStore()
        if (!this.hasRestTimer()) {
          this.refreshRestTimerCount()
        }
      })
    },
    setLogDataInStore () {
      this.setLogDataToStore({
        id: parseInt(this.exerciseID),
        log: {
          formData: this.formData,
          note: this.note,
          setID: this.setID,
          lastTimerValue: this.lastTimerValue,
          lastRestTimerValue: this.lastRestTimerValue
        }
      })
    },
    /**
     * check the all form data is filled
     * @param form
     * @returns {*}
     */
    isFormAllFilled (form) {
      return form.attributes.every((input) => {
        const value = input.value.toString().trim()
        return value !== '' && value !== '0' && value !== '00:00'
      })
    },
    /**
     * disable form play button by the form ID
     * @param fIndex
     * @returns {boolean|*}
     */
    disableFormPlayButton (fIndex) {
      return this.setID !== fIndex || this.formData[fIndex].completed
    },
    /**
     * set the form bottom text
     * @param formData
     * @returns {string|*}
     */
    setPreviousText (formData) {
      let hasEmpty = false
      let allEmpty = true
      const values = formData.attributes.map((data) => {
        if (data.preValue) {
          allEmpty = false
          if (['reps', 'duration'].includes(data.type)) {
            return data.preValue + ' ' + data.unit
          }
          return data.preValue + data.unit
        }
        hasEmpty = true
        return ''
      })
      if (hasEmpty) {
        if (this.hasMetricType('duration')) {
          return this.$i18n.t('message[\'workout-log.previous-no-duration\']')
        }
        return ''
      }
      if (
        this.hasMetricType('duration') &&
        this.metricsArranged.length === 1 &&
        allEmpty
      ) {
        return this.$i18n.t('message[\'workout-log.previous-no-duration\']')
      } else if (
        this.hasMetricType('duration') &&
        this.metricsArranged.length === 1
      ) {
        return this.$i18n.t('message[\'workout-log.previous-no-duration\']', [
          values.join(' x ')
        ])
      }

      return this.$i18n.t('message[\'workout-log.previous\']', [
        values.join(' x ')
      ])
    },
    getUnit (type) {
      if (type === 'reps') {
        return this.$i18n.t('message["workout-log.reps-unit"]')
      }
      return unitSystem[this.currentUnitSystem][type]
    },
    getMetricLabel (metric) {
      if (metric.type === 'reps') {
        return this.$i18n.t('message[\'workout-log.reps\']')
      } else if (metric.type === 'distance') {
        return this.$i18n.t('message[\'workout-log.distance\']') + '(' + this.getUnit(metric.type) + ')'
      } else if (metric.type === 'weight') {
        return this.$i18n.t('message[\'workout-log.weight\']') + '(' + this.getUnit(metric.type) + ')'
      } else if (metric.type === 'duration') {
        return this.$i18n.t('message["general.time"]') + '(' + this.$i18n.t('message["general.time-minutes-short"]') + ':' + this.$i18n.t('message["general.time-seconds-short"]') + ')'
      }
      return metric.label + '(' + this.getUnit(metric.type) + ')'
    },
    getMetricPlaceholder (type, defaultValue = '') {
      if (type === 'reps') {
        return this.$i18n.t('message[\'workout-log.reps\']')
      } else if (type === 'distance') {
        return this.$i18n.t('message[\'workout-log.distance\']')
      } else if (type === 'weight') {
        return this.$i18n.t('message[\'workout-log.weight\']')
      } else if (type === 'duration') {
        return this.$i18n.t('message["general.time"]')
      }
      return defaultValue
    },
    /**
     * check the form data object
     * @param set
     * @param index
     * @param entryData
     * @param unitType
     * @param timeZone
     * @returns {{current: boolean, isPlayed: boolean, attributes: [], completed: boolean, wrapClass: [], changed: boolean}}
     */
    createEntry (set, index, entryData, unitType, timeZone) {
      const entryFormData = []

      this.metricsArranged.forEach((metric) => {
        let preValue = null
        let entry = null
        if (entryData) {
          entry = entryData.attributes.find((data) => {
            return data.type === metric.type
          })
        }

        const unit = this.getUnit(metric.type)
        if (metric.type === 'reps') {
          let defaults = { value: set }
          if (this.isCompleted) {
            defaults = { value: '' }
          } else if (this.historyData.length === 0) {
            preValue = ''
          } else {
            preValue = set
          }
          if (!this.isCompleted && entry && entry.value) {
            preValue = entry.value
            entry.value = set
          }
          entry = entry || defaults
        } else if (metric.type === 'duration') {
          // if completed show saved value, otherwise show timer value or default zero
          let durationVal = this.exerciseTimeValue || 0
          if (this.isCompleted && entry) {
            durationVal = entry.value
          }
          let oldDuration = ''
          if(entry &&  entry.value){
            oldDuration = this.setTimerNumbers(parseInt(entry.value / 60)) +
            ':' +
            this.setTimerNumbers(parseInt(entry.value % 60))
          }
          entry = {
            value:
              this.setTimerNumbers(parseInt(durationVal / 60)) +
              ':' +
              this.setTimerNumbers(parseInt(durationVal % 60))
          }
          preValue = oldDuration || entry.value || ''
        } else {
          entry = entry || { value: '' }
          preValue = entry.value || ''
        }
        const modifiedEntry = (entry) || { value: '' }
        let currentValue = modifiedEntry.value
        if (modifiedEntry.value === 0) {
          currentValue = 0
        } else if (!modifiedEntry.value) {
          currentValue = ''
        }
        entryFormData.push({
          ...metric,
          preValue: preValue,
          unit: unit,
          value: currentValue,
          placeholder: this.getMetricPlaceholder(metric.type, metric.label) || ''
        })
      })

      return {
        attributes: entryFormData,
        current: index === 0,
        index:index,
        completed: false,
        changed: false,
        wrapClass: [],
        isPlayed: !this.hasMetricType('duration')
      }
    },
    /**
     * get the arranged metric by array index
     * @param type
     * @returns {*}
     */
    getMetricTypeIndex (type) {
      return this.metricsArranged.findIndex((metric) => {
        return metric.type === type
      })
    },
    /**
     * emit the nextExercise event
     */
    emitNextExercise () {
      this.$emit('nextExercise', true)
    },
    isFormsHasErrors () {
      return this.formData.some((data, id) => {
        return Object.keys(this.$refs['form' + id][0].errors).some(key => {
          return data.completed && this.$refs['form' + id][0].errors[key].length > 0
        })
      })
    },
    /**
     * validate the completed forms
     * using timeout because of the UI create delay
     */
    validateCompletedForms () {
      setTimeout(() => {
        this.formData.forEach((data, id) => {
          if (data.completed && this.$refs['form' + id] && this.$refs['form' + id][0]) {
            this.$refs['form' + id][0].validate().then(isValid => {
              this.formData[id].completed = isValid
              if (!isValid) {
                this.formData[id].current = true
              }
            })
          }
        })
      }, 200)
    },
    /**
     * check and save the data
     */
    gotoNextExercise () {
      if (this.isFormsHasErrors()) {
        return
      }
      if (
        this.isLoaded &&
        !this.requestEdit &&
        this.isCompleted &&
        !this.isDeleted &&
        !this.forceEdit &&
        this.metricsArranged.length > 0
      ) {
        this.emitNextExercise()
        return
      }
      const isAllIncomplete = this.formData.every((form) => {
        return !form.completed
      })
      if (isAllIncomplete) {
        if (this.isSubmitting) {
          return
        }
        this.isSubmitting = true
        this.showLoading()
        /**
         * for re-complete the exercise without any set completion.
         * 1 .incomplete exercise
         * 2 .mark exercise as complete
         */
        this.service.setIncompleteExercise(parseInt(this.exerciseID)).finally(() => {
          this.service.markExerciseComplete({ note: this.note }, parseInt(this.exerciseID)).then(() => {
            this.logSaveData(false)
            this.unsetLogDataInStore(parseInt(this.exerciseID))

            this.navigateNextExercise()
          }).finally(() => {
            this.isSubmitting = false
            this.hideLoading()
          })
        })
        return
      }

      const isAllCompleted = this.formData.every((form) => {
        return form.completed
      })
      if (!isAllCompleted) {
        this.confirmPopupShow = true
      } else {
        this.saveData()
      }
    },
    /**
     * play the timer
     * @param fIndex
     * @param eIndex
     */
    formTimerStart (fIndex, eIndex) {
      if (fIndex === this.setID && !this.formData[fIndex].completed) {
        this.showRestTimer = false
        this.showMainTimer = true
        if (!this.playMainTimer && this.exerciseTime) {
          // do nothing
        } else if (!this.playMainTimer) {
          const values = this.formData[fIndex].attributes[eIndex].value
            .replace('_', '0')
            .split(':')
          this.exerciseTime = parseInt(values[0] * 60) + parseInt(values[1])
        } else {
          this.isMainTimerStopped = true
          this.setID = this.formData.findIndex(data => data.current)
        }
        this.playMainTimer = !this.playMainTimer
      }
    },
    /**
     * set the workout timer is started
     */
    workoutTimerStarted () {
      this.playMainTimer = true
      this.lastRestTimerValue = 0
      this.logTimerStartedActivityData()
    },
    workoutTimerRemaining (event) {
      this.lastTimerValue = event.remaining.totalSeconds
      this.setLogDataInStore()
    },
    setRestTimerRemaining (event) {
      this.lastRestTimerValue = event.remaining.totalSeconds
      this.setLogDataInStore()
    },
    setWorkoutTimerStopped (event, setId) {
      // Get the total seconds from the "event.remaining" object
      let totalSeconds = event.remaining.totalSeconds

      // Get the current item from the "formData" array using "setId"
      const current = this.formData[setId]

      // Check if the current item is not completed
      if (!current.completed) {
        // Find the input with a type of 'duration' in the "current.attributes" array
        const input = current.attributes.find((inputData) => {
          return inputData.type === 'duration'
        })

        // Calculate the remaining time by subtracting "totalSeconds" from the duration
        totalSeconds = this.service.timeToSeconds(input.value) - totalSeconds

        // If "totalSeconds" becomes zero or negative, reset it to the duration
        if (!totalSeconds) {
          totalSeconds = this.service.timeToSeconds(input.value)
        }

        // Initialize the "lastTimerValue" to 0
        this.lastTimerValue = 0

        // Update the "input.value" with the formatted time (MM:SS)
        input.value =
          this.setTimerNumbers(totalSeconds / 60) + // Minutes
          ':' +
          this.setTimerNumbers(totalSeconds % 60) // Seconds

        this.$nextTick(()=>{
          this.lastTimerValue = 0
          this.setLogDataInStore()
        })
      }
    },
    /**
     * set the workout timer is paused
     */
    workoutTimerPaused (event) {
      const setId = event['set-id']
      if (this.isMainTimerStopped) {
        let totalSeconds = event.remaining.totalSeconds
        const current = this.formData[setId]
        const input = current.attributes.find((inputData) => {
          return inputData.type === 'duration'
        })
        totalSeconds = this.service.timeToSeconds(input.value) - totalSeconds
        if (!totalSeconds) {
          totalSeconds = this.service.timeToSeconds(input.value)
        }

        this.lastTimerValue = 0
        input.value =
          this.setTimerNumbers(totalSeconds / 60) +
          ':' +
          this.setTimerNumbers(totalSeconds % 60)
        this.isMainTimerStopped = false
        this.showMainTimer = false
        setTimeout(() => {
          this.showMainTimer = true
          this.workoutTimerEnded(event)
        }, 50)
      }
    },
    workoutTimerStopped (event) {
      const setId = event['set-id']
      if (!this.isMainTimerStopped) {
        this.setWorkoutTimerStopped(event, setId)
      }
      if (!this.exerciseRestTime) {
        this.showMainTimer = false
        this.$nextTick(() => {
          this.showMainTimer = true
        })
      }
      this.$nextTick(()=>{
        this.setLogDataInStore()
      })
    },
    noteChanged () {
      this.setLogDataInStore()
    },
    /**
     * set the workout timer is ended
     */
    workoutTimerEnded (event) {
      this.lastTimerValue = 0
      this.lastRestTimerValue= this.exerciseRestTime
      this.setLogDataInStore()
      const setId = (this.timerCurrentSet - 1)
      this.playMainTimer = false
      const fIndex = setId
      this.formData[fIndex].isPlayed = true
      if (!this.validateForm(fIndex)) {
        this.setCurrentTimerToMainTimer()
        return
      }

      this.formData[fIndex].completed = true
      if (this.formData.length !== fIndex + 1) {
        this.setNextCurrent(fIndex)

        this.setCurrentTimerToMainTimer()
      }
      this.setCurrentTimerToMainTimer()
      if (!this.hasRestTimer()) {
        this.refreshRestTimerCount()
      }
    },
    hasRestTimer () {
      return !!this.exerciseRestTime
    },
    resetTimers () {
      this.showMainTimer = false
      setTimeout(() => {
        this.showMainTimer = true
      }, 500)
    },
    stopWatchResumed (event) {
      this.playMainTimer = true
    },
    restTimerEnded () {
      this.showMainTimer = true
      this.showRestTimer = false
      this.refreshRestTimerCount()
      this.lastRestTimerValue = 0
      this.setLogDataInStore()
    },
    refreshRestTimerCount () {
      // setting rest timer show count to total completed count
      this.restTimerSet = this.totalCompleted
    },
    resetRestTimerCount () {
      this.restTimerSet = 0
    },
    stopWatchStarted (event) {
      this.playMainTimer = true
      this.logTimerStartedActivityData()
    },
    logTimerStartedActivityData () {
      const logData = {
        set: (this.setID + 1),
        workout_program_id: this.$route.query.workout,
        exercise_title: this.exerciseName,
        duration: this.exerciseTimeValue,
        timer_type: 'workout'
      }
      this.logEvent('WorkoutLog.TimerStarted', logData)
    },
    stopWatchPaused (event) {
      this.playMainTimer = false
    },
    stopWatchStopped (event) {
      const setId = event['set-id']
      this.lastTimerValue = 0
      this.setID = this.formData.findIndex(data => data.current)

      if (!this.playMainTimer) {
        this.showMainTimer = false
        setTimeout(() => {
          if (parseInt(this.exerciseRestTime)) {
            this.showRestTimer = true
          } else {
            this.showMainTimer = true
          }
        }, 200)
        return
      }
      this.setWatchTime(event)
      const fIndex = setId
      this.formData[fIndex].isPlayed = true
      this.playMainTimer = false
      if (!this.validateForm(fIndex)) {
        return
      }
      this.formData[fIndex].completed = true
      if (this.formData.length !== fIndex + 1) {
        this.setNextCurrent(fIndex)
        this.showMainTimer = false
        setTimeout(() => {
          this.lastTimerValue = 0
          if (parseInt(this.exerciseRestTime)) {
            this.showRestTimer = true
          } else {
            this.showMainTimer = true
          }
        }, 200)
      } else if (this.formData.length === fIndex + 1) {
        if (parseInt(this.exerciseRestTime)) {
          this.showMainTimer = false
          this.showRestTimer = true
        } else {
          this.showMainTimer = true
        }
      }
    },
    setNextCurrent (fIndex) {
      if (this.formData[fIndex].current) {
        let nextId = 0
        for (nextId = 0; nextId < this.formData.length; nextId++) {
          if (!this.formData[nextId].completed) {
            this.formData[fIndex].current = false
            this.formData[nextId].current = true
            this.setID = nextId
            break
          }
        }
      }
    },
    setAllNotCurrent () {
      this.formData.forEach((data, index) => {
        this.formData[index].current = false
      })
    },
    setWatchTime (event) {
      const setId = event['set-id']
      if (this.formData[setId].completed) {
        return
      }
      const totalSeconds = event.time.totalSeconds
      if (totalSeconds === 0) {
        return
      }
      this.lastTimerValue = totalSeconds
      this.setLogDataInStore()

      const current = this.formData[setId]
      const input = current.attributes.find((inputData) => {
        return inputData.type === 'duration'
      })
      input.value =
        this.setTimerNumbers(totalSeconds / 60) +
        ':' +
        this.setTimerNumbers(totalSeconds % 60)
    },
    setCurrentTimerToMainTimer () {
      const fIndex = this.formData.findIndex((form) => {
        return form.current
      })
      if (this.formData[fIndex] && this.formData[fIndex].attributes) {
        const input = this.formData[fIndex].attributes.find((inputData) => {
          return inputData.type === 'duration'
        })
        if (input) {
          const values = input.value.replace('_', '0').split(':')
          this.exerciseTime = parseInt(values[0] * 60) + parseInt(values[1])
        }
      }
    },
    navigateNextExercise () {
      this.$emit('submitted')
      this.personalBestPopup.show = false
      this.showSuccess = true
      setTimeout(() => {
        this.$emit('completed')
        setTimeout(() => {
          this.showSuccess = false
        }, 500)
      }, 2000)
    },
    confirmSave () {
      this.confirmPopupShow = false
      let incompleteFormsCount = 0
      this.formData.forEach((form, index) => {
        if (!form.completed) {
          setTimeout(() => {
            this.formData[index].wrapClass.push(' workout-log-form__section--not-filled')
            setTimeout(() => {
              this.formData[index].wrapClass.pop()
            }, 3000)
          }, (incompleteFormsCount * 500))
          incompleteFormsCount++
        }
      })

      this.saveData()
    },
    setMask (type) {
      if (type === 'duration') {
        return '99:99'
      }
    },
    setTimerNumbers (num) {
      num = parseInt(num)
      return num < 10 ? '0' + num : num
    },
    setIncomplete (fIndex) {
      this.formData = this.formData.map((data, id) => {
        if (id === fIndex) {
          data.current = true
          data.completed = false
        } else {
          data.current = false
        }
        return data
      })
      this.refreshRestTimerCount()
      if (!this.playMainTimer) {
        this.setID = fIndex
        this.resetTimers()
        if (this.exerciseTimeValue) {
          this.setCurrentTimerToMainTimer()
        }
      }
    },
    /**
     * if all complete set only last incomplete
     * if one or more completed last complete one can be incomplete
     * @param fIndex
     * @returns {*|boolean}
     */
    canFormSetIncomplete (fIndex) {
      const isAllCompleted = this.formData.every((form) => {
        return form.completed
      })
      return this.formData[fIndex].completed && (((fIndex + 1) === this.formData.length) || (!isAllCompleted && this.formData[fIndex + 1] && this.formData[fIndex + 1].current))
    },
    setCurrentCompleted (fIndex) {
      if (this.canFormSetIncomplete(fIndex)) {
        this.lastTimerValue = 0
        this.setIncomplete(fIndex)
        this.setLogDataInStore()
        return
      }
      if (
        this.formData[fIndex].current &&
        this.validateForm(fIndex, false, true)
      ) {
        this.lastTimerValue = 0
        if (this.getMetricTypeIndex('duration') >= 0 && this.playMainTimer) {
          this.formTimerStart(fIndex, this.getMetricTypeIndex('duration'))
          setTimeout(() => {
            this.resetTimerAndCompleteSet(fIndex)
          }, 200)
        } else if (this.getMetricTypeIndex('duration') >= 0) {
          if (this.formData.length === fIndex + 1 && this.hasRestTimer()) {
            this.setCompleted(fIndex)
            this.showRestTimer = true
          } else {
            this.resetTimerAndCompleteSet(fIndex)
          }
        } else {
          this.setCompleted(fIndex)
        }
      }

      this.validateCompletedForms()
      if (!this.hasRestTimer()) {
        this.refreshRestTimerCount()
      }
      this.setLogDataInStore()
    },
    resetTimerAndCompleteSet (fIndex) {
      this.showMainTimer = false
      this.showRestTimer = false
      this.setCompleted(fIndex)
      setTimeout(() => {
        this.showRestTimer = false
        this.showMainTimer = true
      }, 200)
    },
    setCompleted (fIndex) {
      this.formData[fIndex].completed = true
      if (this.formData.length !== fIndex + 1) {
        this.setNextCurrent(fIndex)
        if (this.hasMetricType('duration')) {
          this.setCurrentTimerToMainTimer()
          this.playMainTimer = false
          this.lastTimerValue = null
        }
      } else {
        this.setNextCurrent(fIndex)
      }
      this.setLogDataInStore()
    },
    getMetricType (type) {
      return this.metricsArranged.find((metric) => {
        return metric.type === type
      })
    },
    getSetsCount (type) {
      const sets = this.sets.split('/')
      return sets.length
    },
    getTotalLogSets () {
      return this.formData.length
    },
    hasMetricType (type) {
      return this.metricsArranged.some((metric) => {
        return metric.type === type
      })
    },
    setInputMode (type) {
      if (type === 'weight' || type === 'distance') {
        return 'decimal'
      }
      return 'numeric'
    },
    onMetricKeyPress (event) {
      if (event.srcElement.value.length <= 5) {
        return true
      }

      event.preventDefault()
    },
    setInputType (type) {
      if (type === 'weight' || type === 'distance') {
        return 'decimal'
      } else if (type === 'duration') {
        return 'text'
      }
      return 'number'
    },
    validateForm (fIndex, checkTime = true, setErrors = false) {
      if (checkTime && !this.formData[fIndex].isPlayed) {
        return false
      }
      const requiredText = this.$i18n.t('message["validation.required"]')
      const repsOnlyValidText = this.$i18n.t('message["validation.reps-only"]')

      return this.formData[fIndex].attributes.every((input) => {
        const value = input.value.toString().trim()
        const valid = value !== '' && value !== '00:00'
        if (!valid && setErrors) {
          const error = {}
          error[input.type] = requiredText.replace(':attribute', input.label).replace('{_field_}', input.label)
          this.$refs['form' + fIndex][0].setErrors(error)
        } else if (input.type === 'reps' && (input.value.toString().includes('-') || input.value.toString().toLowerCase().includes('f'))) {
          const error = {}
          error[input.type] = repsOnlyValidText
          this.$refs['form' + fIndex][0].setErrors(error)
          return false
        }
        return valid
      })
    },
    /**
     * returns the validation by matric type
     * @param type
     * @returns {string}
     */
    getValidationByType (type) {
      switch (type) {
        case 'distance':
          return 'required|min_value:0'
        case 'duration':
          return 'required|time'
        case 'reps':
          return 'required|min_value:1|integer|workout_reps'
        case 'weight':
          return 'required|min_value:0'
        default:
          return 'required'
      }
    },
    /**
     * emit cancel edit event
     */
    cancelEdit () {
      this.unsetLogDataInStore(parseInt(this.exerciseID))
      this.$emit('canceled')
    },
    exit () {
      this.$emit('submitted')
      this.$emit('completed')
    },
    isAllFormsFilled () {
      const inputForm = this.formData
      return inputForm.every((form) => {
        return form.completed || ((this.requestEdit || this.forceEdit) && this.isFormAllFilled(form))
      })
    },
    /**
     * save the data using API
     */
    saveData: function () {
      if (this.isSubmitting) {
        return
      }
      const inputForm = this.formData
      const filledData = inputForm.filter((form) => {
        return form.completed
      })
      const service = this.service
      const submittedData = []
      filledData.forEach(function (formInner) {
        const mappedData = { attributes: [] }
        formInner.attributes.forEach((att) => {
          let inputVal
          const inputType = att.type
          if (att.type === 'duration') {
            inputVal = service.timeToSeconds(att.value)
          } else {
            inputVal = att.value
          }
          mappedData.attributes.push({
            type: inputType,
            value: inputVal
          })
        })
        submittedData.push(mappedData)
      })
      if (submittedData.length === 0) {
        this.navigateNextExercise()
      } else {
        const payload = {
          entries: submittedData,
          note: this.note
        }
        this.showLoading()
        this.isSubmitting = true
        this.service
          .setCompleteExercise(payload, parseInt(this.exerciseID))
          .then((data) => {
            this.logSaveData()
            const activityLogData = {
              event: 'WorkoutLog.PersonalBest',
              exercise: {
                exerciseID: this.exerciseID,
                name: this.exerciseName,
                program_id: this.workoutID
              }, // full exercise object
              personal_best_value: '',
              personal_best_metric: '',
              personal_best_unit: ''
            }
            this.unsetLogDataInStore(parseInt(this.exerciseID))
            this.personalBestPopup.show = data.data.is_new_personal_best || false
            if (!this.personalBestPopup.show) {
              this.navigateNextExercise()
            } else {
              data.data.entries.some((entry) => {
                return entry.attributes.some((attribute) => {
                  const isPersonalBest = attribute.is_personal_best || false
                  if (isPersonalBest) {
                    this.personalBestPopup.value = this.getValueWithUnit(attribute.value, attribute.type)
                    activityLogData.personal_best_value = attribute.value
                    activityLogData.personal_best_metric = attribute.type
                    activityLogData.personal_best_unit = unitSystem[this.currentUnitSystem][attribute.type]
                  }
                  return isPersonalBest
                })
              })
              if (activityLogData.exercise.name === this.exerciseData().name) {
                activityLogData.exercise = { ...activityLogData.exercise, ...this.exerciseData() }
              }
              this.logEvent('WorkoutLog.PersonalBest', activityLogData)
            }
          }).finally(() => {
            this.hideLoading()
            this.isSubmitting = false
          })
      }
    },
    logSaveData (logged_metrics = true) {
      const logData = {
        logged_note: !!this.note,
        logged_metrics: logged_metrics,
        exercise: this.exerciseName,
        day: this.dayName,
        week: this.weekName,
        all_sets_completed: this.isAllFormsFilled(),
        available_metrics: this.metrics.map((metric) => metric.type),
        include_failure_range_reps: (this.sets.toString().includes('-') || this.sets.toString().toLowerCase().includes('f')),
        own_sets: this.formData.slice(this.exerciseSetCount).length
      }
      this.logEvent('WorkoutLog.RecordedEntry', logData, true)
    },
    getValueWithUnit (value, type) {
      value = parseFloat(value)
      let typeValue = this.getUnit(type)
      let valueModified = value
      if (type === 'duration') {
        value = parseInt(value)
        const min = parseInt(value / 60) || 0
        const sec = parseInt(value % 60) || 0
        if (min === 0 && sec > 0) {
          valueModified = sec
          typeValue = this.$i18n.t('message["general.time-seconds-short"]')
        } else if (sec === 0) {
          valueModified = min
          typeValue = this.$i18n.t('message["general.time-minutes-short"]')
        } else {
          valueModified = this.numberToString(value)
          typeValue = this.$i18n.t('message["general.time-minutes-short"]')
        }
      }
      return valueModified + ' ' + typeValue
    },
    numberToString (value) {
      let min = parseInt(value / 60) || 0
      let sec = parseInt(value % 60) || 0
      min = min < 10 ? '0' + min : min
      sec = sec < 10 ? '0' + sec : sec
      return min + ':' + sec
    },
    workoutTimerEndedWithoutMetric () {
      if (!this.hasRestTimer()) {
        this.refreshRestTimerCount()
      }
    },
    restTimerStarted (data) {
      const logData = {
        set: (this.setID + 1),
        workout_program_id: this.$route.query.workout,
        exercise_title: this.exerciseName,
        duration: (data.time || 0),
        timer_type: 'rest'
      }
      this.logEvent('WorkoutLog.TimerStarted', logData)
    },
    timerRestTimerEnded () {
      this.showMainTimer = false
      this.$nextTick(() => {
        this.showMainTimer = true
      })
      this.refreshRestTimerCount()
    },
    restartTimers () {
      this.formData = this.formData.map((data, id) => {
        if (id === 0) {
          this.setID = id
          data.current = true
        } else {
          data.current = false
        }

        data.completed = false
        return data
      })
      this.resetRestTimerCount()
      if (!this.playMainTimer) {
        if (this.exerciseTimeValue) {
          this.setCurrentTimerToMainTimer()
        } else {
          this.exerciseTime = 0
          this.lastTimerValue = 0
        }
        this.resetTimers()
      }
    }
  }
}
