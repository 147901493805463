<template>
    <div class="gc-page-member-connected-section">
        <text-body-small class="gc-page-member-connected-section__description">
            {{$t('message[\'member-connect.connected-with\']')}}
        </text-body-small>
        <card-member-large
                :avatar="member.getAvatar()"
                :name="member.getFullName()"
                :email="member.getEmail()"
                :labelText="memberTypeLabel">
            <button-custom
                    :text="$t('message[\'member-connect.disconnect\']')"
            @click="showDisconnectConfirm"></button-custom>
        </card-member-large>
        <alert-dotted class="gc-page-member-connected-section__alert">
            <text-body-small
            :line-height="'multi'"
                    v-html="$t('message[\'member-connect.connected-info-note-generic\']', [member.getFullName()])"
            ></text-body-small>
        </alert-dotted>
        <popup-member-disconnect :name="member.getFullName()" @disconnected="emitDisconnect" ref="disconnectPopup"></popup-member-disconnect>
    </div>
</template>

<script>
import TextBodySmall from '../../typography/TextBodySmall'
import CardMemberLarge from '../../cards/CardMemberLarge'
import AlertDotted from '../../alerts/AlertDotted'
import ButtonCustom from '../../buttons/ButtonCustom'
import Member from '../../../../services/meal-plan-pair/Member'
import PopupMemberDisconnect from '../../popups/PopupMemberDisconnect'

export default {
  name: 'PageMemberConnectedCard',
  props: {
    member: {
      default: new Member()
    }
  },
  components: {
    PopupMemberDisconnect,
    TextBodySmall,
    CardMemberLarge,
    AlertDotted,
    ButtonCustom
  },
  data () {
    return {
      memberTypeLabel: ''
    }
  },
  mounted () {
    this.setLabel()
  },
  methods: {
    showDisconnectConfirm () {
      this.$refs.disconnectPopup.show()
    },
    emitDisconnect () {
      this.$emit('refresh')
    },
    setLabel () {
      let langKey = 'member-connect.my-follower'

      if (this.member.getIsOwner()) {
        langKey = 'member-connect.my-manager'
      }

      this.memberTypeLabel = this.$i18n.t(
        `message['${langKey}']`
      )
    }
  }
}
</script>

<style scoped>

</style>
