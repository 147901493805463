<template>
  <div :class="componentClasses" @click="clicked()">
    <text-content :weight="'extra-bold'" :line-height="'multi'" :size="'sm3'">
      {{ headerText }}
    </text-content>
    <icon-info-bulb v-if="icon === 'bulb' && showIcon" @click="iconClicked()" />
    <icon-info-bulb :size="'sm2'" v-else-if="icon === 'bulb-small' && showIcon" @click="iconClicked()" />
    <icon-forward
      :size="'sm2'"
      v-else-if="icon === 'forward' && showIcon"
      @click="iconClicked()"
    />
    <icon-forward :size="'sm2'"
      v-else-if="icon === 'forward-small' && showIcon"
      @click="iconClicked()"
    />
  </div>
</template>

<script>
import TextContent from '../../root/TextContent'
import IconInfoBulb from '../../root/icons/IconInfoBulb'
import IconForward from '../../root/icons/IconForward'

export default {
  name: 'HeaderSimple',
  components: {
    IconInfoBulb,
    IconForward,
    TextContent
  },
  props: {
    headerText: {
      default: ''
    },
    showIcon: {
      default: true
    },
    icon: {
      default: '' // 'bulb','forward'
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-header-simple': true
      }
    }
  },
  methods: {
    iconClicked () {
      this.$emit('icon-click')
    },
    clicked () {
      this.$emit('click')
    }
  }
}
</script>

<style scoped></style>
