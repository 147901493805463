<template>
  <div
    class="app-side-menu app-side-menu-v1"
    v-bind:class="{'app-side-menu--hide':!showingMenu&&!closingMenu,'app-side-menu--close':!showingMenu&&closingMenu}"
  >
      <Slideout
        ref="menu"
        menu="#menu"
        panel="#panel"
        :toggleSelectors="['.toggle-button']"
        @on-open="onMenuOpens()"
        :duration="500"
        @on-beforeclose="closedMenu()"
      >
        <div class="app-side-menu__bg" v-if="showingMenu" v-on:click="closeMenu"></div>
        <!--        <div>-->
        <icon-ellipse v-if="features.hasNew"/>
        <nav id="menu" v-show="clicked" v-on:scroll.passive="setScroll">
          <div>
            <a v-on:click="showParent = !showParent" v-show="!showParent"></a>
            <div class="app-side-menu__profile-details">
              <div class="app-side-menu__image-wrap">
                <img class="app-side-menu__profile-image app-side-menu-v1__profile-image " @click="navigate('/account-details?page=account-settings')" :src="profileImage" @onerror="imageLoadOnError" alt="profile-image"/>
              </div>
              <p @click="navigate('/account-details?page=account-settings')" class="app-side-menu__profile-name  app-side-menu-v1__profile-name ">{{userName}}</p>
            </div>
            <ul v-show="showParent">

              <li class="app-side-menu__list-item"
                  v-for="link in navLinks"
                  :class="['link_'+link.code]"
                  :key="'link-'+link.code"
              >
                <span
                  v-if="link.type=== 'custom-page-external'"
                  @click="handleUrlNavigate(link.url)"
                >{{link.label}}</span>

                <span @click="navigate(link.url)" v-else-if="link.type=== 'system-page'">{{showLinkText(link.label)}}  <icon-ellipse class="app-side-menu__icon-new" v-if="link.isNew"/></span>

                <span @click="navigateToPage(link.url)"
                      v-else-if="contentPageTypes.includes(link.type)">{{link.label}}</span>
              </li>
              <li class="app-side-menu__list-item link_workout-log" v-if="isMember&&showWorkoutLogLink">
                <span @click="navigate('/workout-programs/?workout-log=1')">{{$t('message["workout-log.title"]')}}</span>
              </li>
              <li class="app-side-menu__list-item link_profile" v-if="isMember">
                <span @click="navigate('/account-details')">{{$t('message["profile.title"]')}}</span>
              </li>
              <li class="app-side-menu__list-item link_logout">
                <span @click="logout">{{$t('message[\'menu.logout\']')}}</span>
              </li>
            </ul>
            <ul v-show="subMenuType==='meal-plan'&&!showParent">
            </ul>
            <ul v-show="subMenuType==='workout'&&!showParent">
            </ul>
            <ul class="side-menu-bottom-wrap">
              <li>
                <a v-on:click="openPrivacyPolicyPopup()">{{$t("message['settings.privacy-policy']")}}</a>
              </li>
              <li>
                <a v-on:click="openTermsAndConditionsPopup ()">{{ $t("message['settings.terms-cond']")}}</a>
              </li>
              <li>
                <p class="side-menu-bottom-wrap__t-id">
                  {{getTrainerIDInfo()}}
                  <span>{{appVersion}}</span>
                </p>
              </li>
            </ul>
          </div>
        </nav>

        <main id="panel"></main>
        <header>

          <div>
            <a class="btn btn-back toggle-button slideout-panel--button" v-if="!showingMenu" v-on:click="clicked=true">
              <icon-menu :size="'md1'"/>
            </a>
            <a :style="(scrollPosition > 30) ? 'display:none;' : ''"
               class="btn btn-back toggle-button slideout-panel--button button-back" v-else v-on:click="clicked=true">
              <icon-backward  size="md1"/>

            </a>
          </div>
        </header>
      </Slideout>
    <content-page-popup :page="'privacy-policy'" :visible="showPrivacyPolicyPopup" v-bind:key="'privacy-policy-popup'" v-if="showPrivacyPolicyPopup"
                        v-on:close="closePrivacyPolicyPopup()"></content-page-popup>
    <content-page-popup :page="'legal-disclaimer'" :visible="showTermsCondPopup" v-bind:key="'legal-disclaimer-popup'" v-if="showTermsCondPopup"
                        v-on:close="closeTermsAndConditionsPopup()"></content-page-popup>

  </div>
</template>
<script>

import sideBarMixin from '../../../../../mixins/component-mixins/global/sideBarMixin'
import IconMenu from '../../../../root/icons/IconMenu'
import IconBackward from '../../../../root/icons/IconBackward'
import IconEllipse from '../../../../root/icons/IconEllipse'

export default {
  name: 'side-bar',
  components: {
    IconBackward,
    IconMenu,
    IconEllipse
  },
  mixins: [sideBarMixin]
}

</script>
