<template>
  <page-secondary :header="headerOptions">
    <page-container class="pc-period-tracker">
    <div>
        <div class="pc-period-tracker__calender-alert" v-if="calendarEdit && history.length > 0 && editInfoAlert">
            <text-content size="sm1" class="pc-period-tracker__calender-alert-text">
                {{$t('message[\'period-tracker.calendar.edit-alert\']')}}
            </text-content>
            <div class="pc-period-tracker__setting">
                <button-link :text="$t('message[\'profile.update\']')"
                             @click="showDefaultSettingsPopup"/>
                <icon-settings :size="'sm4'" @click="showDefaultSettingsPopup"></icon-settings>
            </div>
        </div>
        <div class="pc-period-tracker__content pc-period-tracker__content--default-top-gap"
             v-if="history.length === 0">
            <div class="pc-period-tracker__card">
                <div class="container pc-period-tracker__card-content pc-period-tracker__card-content--empty">
                    <text-content :size="'sm1'" :lineHeight="'multi'">{{$t('message[\'period-tracker.empty-message\']')}}</text-content>
                </div>
            </div>
        </div>
        <div class="pc-period-tracker__content"
             :class="{'pc-period-tracker__content--default-top-gap': calendarEdit && !editInfoAlert}"
             v-else>
            <div class="pc-period-tracker__calendar-wrapper"
                 :class="{'pc-period-tracker__calendar-wrapper--edit': calendarEdit}" v-if="calendar.visible">
                <div>
                    <date-picker v-if="calendarEdit"
                                 :locale="calendar.locale"
                                 is-inline
                                 is-expanded
                                 :attributes="calendar.attributes"
                                 :columns="calendar.cols"
                                 :rows="calendar.slides"
                                 :to-page="calendar.toPage"
                                 :disabled-dates="calendar.disabledDates"
                                 :masks="calendar.masks"
                                 :first-day-of-week="1"
                                 :disable-page-swipe="true"
                                 title-position="left"
                                 mode="multiple"
                                 :value="calendar.selectedDates"
                                 v-model="calendar.selectedDates"/>
                    <calendar v-else
                              :locale="calendar.locale"
                              is-inline
                              is-expanded
                              :attributes="calendar.attributes"
                              :columns="calendar.cols"
                              :rows="calendar.slides"
                              :to-page="calendar.toPage"
                              :first-day-of-week="1"
                              :disable-page-swipe="true"
                              :masks="calendar.masks"
                              title-position="left"
                              mode="multiple"
                              :value="[]"/>
                </div>
                <page-fixed-footer class="pc-period-tracker__footer" v-if="calendarEdit">
                    <button-primary @click="updateHistory"
                                    :text="$t('message[\'period-tracker.logs-update.submit\']')"/>
                </page-fixed-footer>
            </div>
        </div>
        <popup-default-settings v-if="defaultSettingsPopup.visible"
                                :visible="defaultSettingsPopup.visible"
                                @completed="defaultSettingsProcessed"
                                @close="closeDefaultSettingsPopup"/>
        <div class="pc-period-tracker__information" v-if="history.length > 0">
            <div class="pc-period-tracker__information-data"><p>
                {{$t('message[\'period-tracker.calendar-legend.logged-periods\']')}}</p></div>
            <div class="pc-period-tracker__information-data"><p>
                {{$t('message[\'period-tracker.calendar-legend.predicted-periods\']')}}</p></div>
            <div class="pc-period-tracker__information-data"><p>
                {{$t('message[\'period-tracker.calendar-legend.today\']')}}</p></div>
        </div>
    </div>
    </page-container>
  </page-secondary>
</template>

<script>
import PopupDefaultSettings from '../period-tracker/page-components/default-settings-popup/PopupMain'
import BaseComponent from '../global/base/BaseComponent'
import TextContent from '../../../root/TextContent'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import Calendar from 'v-calendar/lib/components/calendar.umd'
import ButtonPrimary from '../../../global/buttons/ButtonPrimary'
import ButtonLink from '../../../global/buttons/ButtonLink'
import IconClose from '../../../root/icons/IconClose'
import IconSettings from '../../../root/icons/IconSettings'
import IconEdit from '../../../root/icons/IconEdit'
import PageSecondary from '../../../global/pages/PageSecondary'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import PageFooter from '../../../global/pages/page-sub-components/PageFormFooter'
import PagePeriodTrackerMixin from '../../../../mixins/component-mixins/page/period-tracker/PagePeriodTrackerMixin'
import PageFixedFooter from '../../../global/pages/PageFixedFooter'

export default {
  name: 'PagePeriodTracker',
  extends: BaseComponent,
  mixins: [PagePeriodTrackerMixin],
  components: {
    PageFixedFooter,
    PageSecondary,
    PageContainer,
    PopupDefaultSettings,
    DatePicker,
    Calendar,
    TextContent,
    ButtonPrimary,
    IconClose,
    IconSettings,
    IconEdit,
    ButtonLink,
    PageFooter
  },
  created () {
    this.setPageTitle(this.$t('message["period-tracker.title"]'))
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
  }
}
</script>

<style scoped>

</style>
