import feedbackMixin from './feedbackMixin'

export default {
  mixins: [feedbackMixin],

  methods: {
    setFeedbackPopup () {
      this.setFeedbackEvent('fitness-diary', 10)
      this.setFeedbackFeatureFlag('fd_feedback_popup_show')
      const featureName = this.$i18n.t('message[\'progress.my-journey\']')
      this.setFeedbackPopupData(this.$i18n.t('message[\'popup.feed-back.title\']', { feature: featureName }), this.$i18n.t('message[\'popup.feed-back.placeholder\']'))
    }
  }

}
