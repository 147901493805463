<template>
    <side-bar-button-primary
            @click="requestUpload"
    >
        <template #default>
            {{$t('message["navigator.bottom-bar.photo"]').toLowerCase()}}
        </template>
        <template #icon>
            <icon-camera size="sm2"/>
            <pop-up-upload-photos
              :visible="uploadPopup.show"
              v-if="uploadPopup.show"
              v-on:close="dataUpdated()"
              v-on:updated="dataUpdated()"
            />
        </template>
    </side-bar-button-primary>
</template>
<script>
import IconCamera from '../../root/icons/IconCamera'
import SideBarButtonPrimary from './side-bar-sub-components/SideBarButtonPrimary'
import PopUpUploadPhotos from '../popups/PopUpUploadPhotos'
import FitnessDiaryStore from '../../../../../store/modules/FitnessDiaryStore'
import { flagValue } from '../../../../../includes/TemplateSettings'
import { mapGetters, mapMutations } from 'vuex'
import DashBoardService from '../../../../../services/DashboardService'
import globalEventBus from '../../../../../event-buses/globalEventBus'

export default {
  name: 'ButtonSideBarPhotoPpload',
  components: {
    PopUpUploadPhotos,
    IconCamera,
    SideBarButtonPrimary
  },
  data: function () {
    return {
      show: false,
      uploadPopup: {
        show: false
      }
    }
  },
  beforeCreate () {
    const store = this.$store
    if (!(store && store.state && store.state['fitnessDiaryStore'])) {
      store.registerModule('fitnessDiaryStore', FitnessDiaryStore)
    }
  },
  mounted () {
    if (
      flagValue('show_fitness_diary') === null ||
      flagValue('show_fitness_diary')
    ) {
      this.show = true
    }

    this.getData()
  },
  methods: {
    ...mapGetters({
      service: 'fitnessDiaryStore/getService'
    }),
    ...mapMutations({
      showSubscriptionExpiredPopup: 'showSubscriptionExpiredPopup'
    }),
    requestUpload () {
      let service = new DashBoardService()
      if (service.isEntriesEditable()) {
        this.uploadPopup.show = true
      } else {
        this.showSubscriptionExpiredPopup('journey_add_photo')
      }
    },
    dataUpdated () {
      this.uploadPopup.show = false
      globalEventBus.$emit('photo-journey-photos-uploaded', {})
    },
    getData () {
      let service = this.service()
      service
        .getDataFromAPI()
        .then(() => {
          this.fitnessData = Object.values(service.getData())
          this.hasTodayUploadAllImages = Object.values(service.images).every(
            image => {
              return image.getTodayImage()
            }
          )
          this.updatedFitnessData()
          this.isLoaded = true
        })
        .catch(() => {
          this.isLoaded = false
        })
    }
  }

}
</script>
