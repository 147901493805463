<template>
  <div>
    <page-secondary :header="headerOptions">
      <page-container class="gc-disliked-igredients__container">
        <div class="gc-form-dynemic">
          <!--   page wrapper -->
          <div>
            <alert-dotted class="gc-form-dynemic__alert" v-if="editDisable && member.getFullName()">
              <div class="gc-form-dynemic__alert-header">
                <icon-meal :size="'sm2'"/>
                <text-body-small class="gc-form-dynemic__alert-title" :line-height="'multi'" :weight="'extra-bold'">
                  {{ $t('message["meal-plan-pair.paired-meal-plan-with-exclamation"]') }}
                </text-body-small>
              </div>
              <text-body-extra-small class="gc-form-dynemic__alert-description" :line-height="'lh-xl'"
                                     v-html="$t('message[\'meal-plan-pair.feature-disabled-due-to\']', [member.getFullName()])"></text-body-extra-small>
            </alert-dotted>
            <div>
              <div class="text-left with-under-line">
                <text-body-small :line-height="'multi'" class="btn-no-border__text-top dislike-foods__info">
                  {{ $t('message["profile.account-settings.dietary-help"]') }}<strong>
                  <a :href="'mailto:' + supportEmail">{{ supportEmail }}</a>
                </strong>
                </text-body-small>
              </div>
            </div>
            <div class="gc-form-dynemic__profile-form profile-form profile-form-page-foods-do-not-like">
              <dislikes-input
                :disabled="editDisable"
                v-if="ingredientList.length > 0"
                :multiple="true"
                :optionsList="ingredientList"
                :optionsGroup="ingredientGroup"
                :place-holder="$t('message[\'profile.dietary-preferences.food-dont-like\']')"
                @mounted="setPageFooterClass()"
                :show-header="true"
                v-model="excludedIngredients"
              ></dislikes-input>
              <page-footer
                v-if="!editDisable"
                ref="pageFooter"
              >
                <button-primary
                  @click="submitForm"
                  :text="$t('message[\'profile.save\']')"/>
              </page-footer>
            </div>
          </div>
        </div>
      </page-container>
    </page-secondary>
  </div>
</template>

<script>
import ProfileService from '../../../../../services/ProfileService'
import IngredientService from '../../../../../services/IngredientService'
import {enableGroupedIngredients} from '../../../../../includes/TemplateSettings'
import DislikesInput from '../../global/widgets/forms/dietary/DislikesInput'
import PageFooter from '../../../../global/pages/PageFixedFooter'
import PageContainer from '../../../../global/pages/page-sub-components/PageContainer'
import PageSecondary from '../../../../global/pages/PageSecondary'
import BaseComponent from '../../global/base/BaseComponent'
import AlertDotted from '../../../../global/alerts/AlertDotted'
import TextBodySmall from '../../../../global/typography/TextBodySmall'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import PairService from '../../../../../services/meal-plan-pair/PairService'
import Member from '../../../../../services/meal-plan-pair/Member'
import lodash from 'lodash'
import ConnectionService from '../../../../../services/meal-plan-pair/ConnectionService'
import IconMeal from '../../../../root/icons/IconMeal'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'
import headerEventBus from '@/event-buses/headerEventBus'

export default {
  name: 'DislikedIngredients',
  extends: BaseComponent,
  components: {
    ButtonPrimary,
    TextBodyExtraSmall,
    TextBodySmall,
    AlertDotted,
    DislikesInput,
    PageFooter,
    PageContainer,
    PageSecondary,
    IconMeal
  },
  data () {
    return {
      headerOptions: {
        isTransparent: false,
        isFixed: false,
        show: true,
        left: 'previous-emit',
        right: '',
        rightBlink: false,
        modifiers: ['small-title', 'with-bg-color'],
        mainTitle: this.$t('message["general.foods.i.dislike"]'),
        subTitle: ''
      },
      alreadyExcludedIngredients: [],
      excludedIngredients: [],
      ingredientList: [],
      ingredientGroup: [],
      supportEmail: '',
      editDisable: false,
      member: new Member(),
      connectionService: new ConnectionService(),
      pairService: new PairService(),
      profileService: new ProfileService()
    }
  },
  created () {
    this.setPageTitle(this.$t('message["general.foods.i.dislike"]'))
  },
  mounted () {
    this.setHeader(this.headerOptions)
    this.setPageClass()
    this.setPageData()
    pageReadyEvent()
    headerEventBus.$on('back-button-click', this.navigatePrevious)
  },
  beforeDestroy () {
    headerEventBus.$off('back-button-click', this.navigatePrevious)
  },
  methods: {
    navigatePrevious () {
      this.$router.push({
        path: this.$appConfig.appUrlList.profile,
        query: {
          page: 'dietary-preferences',
          loading: '0'
        }
      })
    },
    async setPageData () {
      // initiating connection, pair, profile details
      await Promise.all([
        this.connectionService.init(),
        this.pairService.init(),
        this.profileService.setProfileData()
      ]).catch(() => {
        this.hideLoading()
      })

      // setting partner details
      this.member = this.connectionService.getPartner()

      // getting support email of the trainer
      this.supportEmail = this.profileService.supportEmail

      if (this.pairService.isPaired() && !this.connectionService.isOwner()) {
        // if meal plan is paired and current user is not the owner

        // disable inputs
        this.editDisable = true

        // setting partner's (owner) dislikes
        this.excludedIngredients = []
        lodash.forEach(this.pairService.getDislikedIngredients(), (value) => {
          this.excludedIngredients.push(value.id)
        })
      } else {
        this.excludedIngredients = this.profileService.getExcludedIngrediants()
      }

      const ingredientService = new IngredientService()
      let mAllergiesList = []
      let mAllergiesGroup = []
      ingredientService.setIngredients()
        .then(() => {
          mAllergiesGroup = ingredientService.getGroupedIngredients()
          const ingredients = ingredientService.getIngredients()
          Object.keys(ingredients).forEach(function (value, index, array) {
            const groups = ingredients[value].groups || []
            mAllergiesList.push({id: (ingredients[value].id), text: ingredients[value].value, groups: groups})
          })
          this.ingredientList = mAllergiesList
          this.ingredientGroup = mAllergiesGroup
        })
        .finally(() => {
          this.hideLoading()
        })
    },
    async submitForm () {
      this.showLoading()
      const profileService = new ProfileService()
      await profileService.setProfileData()
      await profileService.updateExcludedIngredients({excluded_ingredients: this.excludedIngredients})
        .then(() => {
          this.goBack()
        })
        .catch(() => {
          this.hideLoading()
        })
    },
    goBack () {
      this.showLoading()
      let self = this
      setTimeout(function () {
        self.$router.push({
          path: self.$appConfig.appUrlList.profile,
          query: {
            page: 'dietary-preferences'
          }
        })
      }, 500)
    },
    setPageFooterClass () {
      this.$refs.pageFooter.setContentHeight()
    }
  }
}
</script>

<style scoped>

</style>
