<template>
    <page :header="header" class="gc-page-custom-field">
      <validation-observer ref="form" v-slot="{handleSubmit, invalid}">
          <page-container>
              <div class="gc-page-custom-field__header">
                  <text-heading-4
                      class="gc-page-custom-field__title text-center"
                      weight="extra-bold"
                      lineHeight="multi">{{title.main.substring(0, 40)}}</text-heading-4>
              </div>
              <div class="">
                  <form id="form-input-custom-field"
                      class=""
                      @submit.prevent="handleSubmit(nextComponent)">
                          <validation-provider v-slot="{ errors }" rules="required|max:200" :vid="'cutomField'" :name="title.main.substring(0, 40)">
                              <text-input
                                  class="gc-page-custom-field__input-cutom-field"
                                  :placeholder="placeholder"
                                  v-model="cutomField"
                                  :error-messages="errors[0]">
                              </text-input>
                              <text-body-extra-small class="gc-page-progress-form__input-error text-center" lineHeight="multi">{{errors[0]}}</text-body-extra-small>
                          </validation-provider>
                  </form>
              </div>
          </page-container>
          <page-footer :form="'form-input-custom-field'" :validated="invalid"></page-footer>
        </validation-observer>
    </page>
</template>

<script>
import FormBaseComponent from '../../layout/template-1/global/widgets/forms/FormBaseComponent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ButtonPrimary from '../buttons/ButtonPrimary'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import TextHeading4 from '../typography/TextHeading4'
import PageContainer from './page-sub-components/PageContainer'
import Page from './PageForm'
import { setValuesMixin } from '../../layout/template-1/global/widgets/forms/helpers/SetValuesMixin'
import PageFooter from './page-sub-components/PageFormFooter'
import TextInput from '../inputs/TextInput'

export default {
  name: 'PageHeight',
  extends: FormBaseComponent,
  mixins: [ setValuesMixin ],
  props: {
    switchPreference: {
      default: true
    }
  },
  components: {
    Page,
    PageContainer,
    TextHeading4,
    ButtonPrimary,
    TextBodyExtraSmall,
    TextInput,
    ValidationObserver,
    ValidationProvider,
    PageFooter
  },
  data: function () {
    return {
      header: {
        isTransparent: false,
        isFixed: false,
        show: false,
        left: '',
        right: '',
        rightBlink: false,
        modifiers: [],
        mainTitle: '',
        subTitle: ''
      },
      cutomField: '',
      placeholder: '',
      validations: ''
    }
  },
  beforeMount () {
    this.cutomField = this.extraData.value
    this.placeholder = this.$i18n.t('message["form-title.custom-page-placeholder"]')
  },
  methods: {
    nextComponent () {
      this.$emit('submit', { key: this.inputKey, value: this.cutomField })
    }
  }
}
</script>
