<template>
  <div :class="componentClasses">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'labelBase',
  computed: {
    componentClasses: function () {
      return {
        'rc-label': true
      }
    }
  }
}
</script>

<style scoped>

</style>
