import jsonp from 'jsonp'

/**
 * retrun a vimeo video thumbnail image
 * @param videoId
 * @param hash security hash
 * @returns {Promise<unknown>}
 */
export function getVimeoImage (videoId, hash = null) {
  const hashpart = (hash) ? '/' + hash : ''
  return new Promise((resolve, reject) => {
    try {
      jsonp('https://vimeo.com/api/oembed.json?url=https://vimeo.com/' + videoId + hashpart + '?format=jsonp',
        null,
        (error, data) => {
          if (!error) {
            const height = data.height
            const width = data.width

            // checking for valid dimensions
            if (height === 0 || width === 0) {
              reject(new Error('invalid image dimensions'))
              return
            }

            // setting image width to defined value and height according to ratio
            const expectedWidth = 1200
            const ratio = expectedWidth / width
            const expectedHeight = Math.floor(height * ratio)
            if (data.thumbnail_url) {
              const parts = data.thumbnail_url.split('_')
              const fileNameParts = parts[1].split('.')
              const extension = fileNameParts[fileNameParts.length - 1]
              parts[1] = expectedWidth + 'x' + expectedHeight + '.' + extension
              const url = parts.join('_')

              // return vimeo image (if not empty)
              if (url && url.trim() !== '') {
                resolve(url)
              } else {
                reject(new Error('empty image'))
              }
            } else {
              reject(new Error('empty image'))
            }
          } else {
            reject(new Error('fetch failed'))
          }
        })
    } catch (e) {
      reject(new Error('fetch failed'))
    }
  })
}
