<template>
    <popup
            v-if="visible"
            ref="processSuccessPopup"
            :visible="visible"
            @hide="closePopup"
    >
        <popup-title>
            <popup-button-close @hide="closePopup"/>
        </popup-title>
        <popup-body class="gc-popup-meal-swap__body text-center">
            <popup-head class="text-center">
                <popup-text-heading>{{ title }}</popup-text-heading>
                <div class="text-center" v-if="status === 'processing'">
                    <loading-buffer :height="'75'"></loading-buffer>
                </div>
            </popup-head>
            <popup-content>
                <icon-complete-filled :size="'lg3'" v-if="status === 'processed'"/>
                <div v-if="status === 'error'">
                    <div class="text-center"/>
                    <p class="text-center info-modal__subtitle mb-2">{{ errorMessage }}</p>
                </div>
            </popup-content>
        </popup-body>
    </popup>
</template>

<script>
import LoadingBuffer from '../../layout/template-1/global/widgets/LoadingBuffer'
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import DefineInclude from '../../../DefineInclude'
import Popup from './Popup.vue'
import PopupBody from './popup-sub-components/PopupBody'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupContent from './popup-sub-components/PopupContent'
import PopupHead from './popup-sub-components/PopupHead'
import PopupTextHeading from './popup-sub-components/PopupTextHeading'
import PopupTitle from './popup-sub-components/PopupTitle'
import IconCompleteFilled from '../../root/icons/IconCompleteFilled'

export default {
  name: 'PopupProcessTracking',
  components: {
    LoadingBuffer,
    Popup,
    PopupBody,
    PopupButtonClose,
    PopupContent,
    PopupHead,
    PopupTextHeading,
    PopupTitle,
    IconCompleteFilled
  },
  mixins: [popupAnimationMixin],
  props: {
    processingTitle: {
      default: ''
    },
    processedTitle: {
      default: ''
    }
  },
  watch: {
    status () {
      this.setTitle()
    }
  },
  data: function () {
    return {
      visible: false,
      successImage: DefineInclude.imagesUrl + 'swap-success.svg',
      title: '',
      status: 'processing'
    }
  },
  mounted () {
    this.setTitle()
  },
  methods: {
    setTitle () {
      if (this.status === 'processing') {
        this.title = this.processingTitle
      } else {
        this.title = this.processedTitle
      }
    },
    showProcessing () {
      this.visible = true
      this.status = 'processing'
    },
    showProcessed () {
      this.visible = true
      this.status = 'processed'

      setTimeout(() => {
        this.closePopup(null, () => {
          this.$emit('completed')
        })
      }, 500)
    },
    hide () {
      this.closePopup()
      this.status = 'processing'
    },
    closePopup (e, callback) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('hidden')
        this.visible = false
        if (callback) {
          callback()
        }
      })
    }
  }
}
</script>
