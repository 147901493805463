<template>
  <div class="select2-main-wrapper">
    <select ref="input" style="width: 100%;">
      <slot></slot>
    </select>
    <transition>
      <div ref="inputBottomDiv"></div>
    </transition>
  </div>

</template>

<script>

export default {
  name: 'select2',
  props: {
    disabled: false,
    options: {
      default: []
    },
    value: {
      default: []
    },
    multiple: {
      default: true
    },
    placeHolder: {
      default: ''
    }
  },
  data: function () {
    return {
      dropdownOptions: {}
    }
  },
  mounted: function () {
    // if prop not initialised make it empty
    if (!this.options) {
      this.options = {}
    }
    const vm = this
    let value = this.value
    let selectOptions = []
    this.dropdownOptions = this.options
    if (this.options.length === 1) {
      value = this.options[0].id
    } else if (this.dropdownOptions.length === 1) {
      value = this.dropdownOptions[0].id
      selectOptions = this.dropdownOptions
    }

    if (Array.isArray(this.options) && this.options.length > 0) {
      selectOptions = this.options
    } else if (Array.isArray(this.dropdownOptions) && this.dropdownOptions.length > 0) {
      selectOptions = this.dropdownOptions
    }

    $(this.$refs.input)
    // init select2
      .select2(
        {
          data: selectOptions,
          disabled: this.disabled,
          multiple: this.multiple,
          placeholder: this.placeHolder,
          containerCssClass: 'error',
          dropdownCssClass: 'dropdownCssClass',
          sorter: function (data) {
            return data.sort(function (a, b) {
              return a.text < b.text ? -1 : a.text > b.text ? 1 : 0
            })
          }
        })
      .val(value)
      .trigger('change')
    // emit event on change.
      .on('change', function () {
        // removing manually set first load height
        $('.select2-main-wrapper').height($('.select2-selection__rendered').outerHeight())
        $('.select2-selection__arrow').hide()
        if ($(this).val()) {
          vm.$emit('input', $(this).val())
          vm.$emit('change', $(this).val())
        } else {
          vm.$emit('input', [])
          vm.$emit('change', [])
        }
      }).on('select2:select', function () {
        // vm.$emit('inputs', $(this).val())
        // vm.$emit('change', $(this).val())
      }).on('select2:unselecting', function () {
        $(this).data('unselecting', true)
      }).on('select2:opening', function (e) {
        if ($(this).data('unselecting')) {
          $(this).removeData('unselecting')
          e.preventDefault()
        }
      }).on('select2:open', function (e) {
        if (!this.multiple) {
          $('.select2-container--open').addClass('select2-container--single1')
          $('.select2-selection__arrow').hide()
          $('.select2-container--below.select2-container--single.select2-container--open').hide()
        }
      }).on('select2:closing', (e) => {
        if (!this.multiple) {
          $('.select2-container--below.select2-container--single.select2-container--open').show()
        } else {
          $(this.$refs.inputBottomDiv).height($('.select2-selection__rendered').height())
        }
      })
    $('.select2-main-wrapper').height($('.select2-selection__rendered').outerHeight())
    window.addEventListener('resize', this.windowResize)
  },
  methods: {
    windowResize (event) {
      this.resetOptionWrapperHeight()
    },
    resetOptionWrapperHeight () {
      $('.select2-main-wrapper').height($('.select2-selection__rendered').outerHeight())
    }
  },
  watch: {
    options: function (options) {
      this.dropdownOptions = options
      // update options
      $(this.$refs.input)
        .empty()
        .select2({
          data: this.dropdownOptions,
          multiple: this.multiple,
          placeholder: this.placeHolder,
          containerCssClass: 'error',
          dropdownCssClass: 'dropdownCssClass',
          sorter: function (data) {
            return data.sort(function (a, b) {
              return a.text < b.text ? -1 : a.text > b.text ? 1 : 0
            })
          }
        })

      // set default values
      $(this.$refs.input).val(this.value).trigger('change')

      // set main wrapper height first load
      $('.select2-main-wrapper').height($('.select2-selection__rendered').outerHeight())
      // set main wrapper height first load
      this.resetOptionWrapperHeight()
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.windowResize)

    $(this.$refs.input).hide()
    $(this.$refs.input)
      .off()
      .select2('destroy')
  }
}
</script>
