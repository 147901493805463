<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 0C4.47305 0 0 4.47254 0 10C0 15.527 4.47254 20 10 20C15.527 20 20 15.5275 20 10C20 4.47305 15.5275 0 10 0ZM10 18.6667C5.22121 18.6667 1.33332 14.7788 1.33332 10C1.33332 5.22117 5.22121 1.33332 10 1.33332C14.7788 1.33332 18.6667 5.22121 18.6667 10C18.6667 14.7788 14.7788 18.6667 10 18.6667Z" fill="black"/>
        <path d="M14.5057 9.3333H10.6668V5.49432C10.6668 5.12615 10.3683 4.82764 10.0001 4.82764C9.63187 4.82764 9.33339 5.12611 9.33339 5.49432V9.3333H5.49441C5.12624 9.3333 4.82773 9.63178 4.82773 9.99998C4.82773 10.3682 5.1262 10.6667 5.49441 10.6667H9.33339V14.5056C9.33339 14.8738 9.63187 15.1723 10.0001 15.1723C10.3683 15.1723 10.6668 14.8738 10.6668 14.5056V10.6667H14.5057C14.8739 10.6667 15.1724 10.3682 15.1724 9.99998C15.1724 9.63178 14.8739 9.3333 14.5057 9.3333Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'
export default {
  name: 'IconAddRounded',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-add-rounded': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>
