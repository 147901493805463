<template>
  <div>

    <div class="input-group mb-3 ">
      <input aria-describedby="number" class="form-control  ma-form__input " v-bind:class="{'ma-form__input--error':error}" inputmode="numeric" v-on:change="emitInput()"
             max='31' maxlength="2" min="0" pattern="[0-9]*" placeholder="DD" type="tel" v-model="date.day"/>
      <select aria-describedby="number" class="form-control required ma-form__input"  v-bind:class="{'ma-form__input--error':error}"  inputmode="numeric" type="tel"  v-model="date.month"  v-on:change="emitInput()">
        <option  disabled selected>MM</option>
        <option  v-for="month in months" :value="month.id" v-bind:key="'custom-date-month'+month.id">{{month.text}}</option>
      </select>
      <input aria-describedby="number" class="form-control   ma-form__input"  v-bind:class="{'ma-form__input--error':error}"  inputmode="numeric" v-model="date.year" v-on:change="emitInput()"
             max='2100' maxlength="4" min="0" pattern="[0-9]*" placeholder="YYYY" type="tel"/>

    </div>

  </div>

</template>

<script>

export default {
  name: 'CustomDate',
  props: ['value', 'error'],
  data: function () {
    return {
      date: {
        day: '',
        month: '',
        year: ''
      },
      months: []
    }
  },
  watch: {
    value: function (val) {
      this.setDoB(val)
    }
  },
  beforeMount () {
    this.months = []
    let month = 1
    for (month = 1; month <= 12; month++) {
      this.months.push({ id: month, text: this.$i18n.t('message["calendar.month-' + month + '-short"]') })
    }
    if (this.value) {
      this.setDoB(this.value)
    }
  },
  methods: {
    setDoB (value) {
      const date = value.split('-')
      this.date.day = date[2] || this.date.day
      this.date.month = parseInt(date[1]) || parseInt(this.date.month)
      this.date.year = date[0] || this.date.year
    },
    emitInput () {
      if (this.date.month === '' || this.date.day === '' || this.date.year === '') {
        this.$emit('input', '')
      } else {
        let month = parseInt(this.date.month)
        if (month < 10) {
          month = '0' + month
        }
        let day = parseInt(this.date.day) || 1
        if (day < 10) {
          day = '0' + day
        }
        this.$emit('input', this.date.year + '-' + month + '-' + day)
      }
    }
  }
}
</script>
