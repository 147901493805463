var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.componentClasses},[_c('div',{staticClass:"row pc-workout__nav-exercise-large-set__row"},_vm._l((_vm.exercises),function(exercise,id){return _c('div',{key:'exercise-tab' + id,staticClass:"col p-0"},[_c('div',{staticClass:"pc-workout__nav-exercise-large-set__tab",class:{
          'pc-workout__nav-exercise-large-set--active':
            exercise.getID() === _vm.currentExercise.getID(),
          'pc-workout__nav-exercise-large-set--complete': _vm.isExerciseCompleted(
            exercise
          ),
        },on:{"click":function($event){return _vm.navigateNav(exercise.id)}}},[_c('div',{staticClass:"pc-workout__nav-exercise-large-set__single-tab"},[_c('text-body-extra-small',[(_vm.isExerciseCompleted(exercise))?_c('icon-complete-filled',{attrs:{"size":'sm1'}}):_vm._e(),_vm._v(" "+_vm._s(id + 1)+" ")],1)],1)])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }