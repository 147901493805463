<template>
  <div
    :class="componentClasses"
    v-on="$listeners"
    @click="clicked()">
    <icon-garbage-bin
      :size="'md1'"
      class="gc-meal__action-icon"
    />
    <text-body-small v-if="showText" class="gc-meal__action-text" :line-height="'multi'">
      {{ text }}
    </text-body-small>

  </div>
</template>

<script>
import IconGarbageBin from '../../root/icons/IconGarbageBin'
import TextBodySmall from '../typography/TextBodySmall'

export default {
  name: 'ActionMealRemove',
  components: {
    IconGarbageBin,
    TextBodySmall
  },
  props:
    {
      state: {
        type: String,
        default: ''
      },
      showText: {
        type: Boolean,
        default: true
      },
      limitText: {
        type: Boolean,
        default: false

      }
    },
  computed: {
    componentClasses: function () {
      return {
        'gc-meal__action': true,
        'gc-meal__action-remove': true,
        'gc-meal__action-disabled': this.state === 'disabled',
        'gc-meal__action-onTap': this.state === 'onTap',
        'gc-meal__action-active': this.state === 'active'
      }
    },
    text: function () {
      let text = this.$i18n.t('message["meal-plan.remove"]')
      if (this.state === 'active') {
        return this.$i18n.t('message["meal-plan.removing"]')
      }
      if (this.limitText) {
        text = text.substring(0, 2) + '...'
      }
      return text
    }
  },
  methods: {
    clicked () {
      this.$emit('clicked')
    }
  }
}
</script>
<style scoped>
</style>
