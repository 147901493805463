<template>
  <div>
    <loading-buffer height="300" v-if="status==='loading'"/>
    <div class="pc-container-workout-log-history" v-if="status === 'loaded'" >
      <card-workout-history
        class="pc-container-workout-log-history__card"
        :edit-inside="true"
        :entries="item.entries"
        :exercise-i-d="item.program_exercise_id"
        :exercise-name="exerciseName"
        :day-name="dayName"
        :week-name="weekName"
        :logged-at="item.logged_at"
        :metrics="metrics"
        :note="item.note"
        :sets="setSets(item.sets)"
        :unit-system-value="unit"
        :workout-i-d="workoutID"
        v-bind:key="'history-workout-log-stat' + index"
        v-for="(item, index) in history"
        v-if="showItem(item.program_exercise_id)"
        v-on:reload="reloadData()"
        v-on:request-delete="goToDelete(item.program_exercise_id)"
        v-on:request-edit="
        goToEdit(item.week.id, item.day.id, item.program_exercise_id)
      "
      />

        <div
          v-if="history.length === 0"
          class="pc-container-workout-log-history__empty text-center"
        >
         <icon-history size="md2" class="pc-container-workout-log-history__icon-clock"/>
          <text-body-extra-small
            class="text-center"
            v-html="$t('message[\'workout-log.history-empty\']')"
          ></text-body-extra-small>
        </div>

      <popup-workout-log-remove-logged-data
        :exercise-i-d="toDeleteExercise"
        :visible="requestDelete"
        v-if="requestDelete"
        v-on:close="requestDelete = false"
        v-on:success="deleteSuccess(toDeleteExercise)"
      />
    </div>
  </div>

</template>

<script>
import workoutLogLogListMixin from '../../../../../mixins/component-mixins/page/workout/workoutLogLogListMixin'

import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import CardWorkoutHistory from '../../../../global/cards/CardWorkoutHistory'
import PopupWorkoutLogRemoveLoggedData from './popups/PopupWorkoutLogMarkIncomplete'
import IconClock from '../../../../root/icons/IconClock'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import IconHistory from '../../../../root/icons/IconHistory'

export default {
  name: 'WorkoutLogExerciseHistory',
  mixins: [workoutLogLogListMixin],
  components: { IconHistory, TextBodyExtraSmall, IconClock, LoadingBuffer, CardWorkoutHistory, PopupWorkoutLogRemoveLoggedData }
}
</script>
