<template>
  <page-form class="pc-progress-process">
      <preloader-with-text-slides
        :textSlides="textSlides"
        @loaded="setSlidesComplete">
      </preloader-with-text-slides>
      <popup-data-invalid :errors="errors" :visible="hasError" v-on:close="navigate()"/>
  </page-form>

</template>

<script>
import NotificationService from '../../../../services/NotificationService'
import { mapGetters } from 'vuex'
import PopupDataInvalid from '../../../global/popups/PopupDataInvalid'
import BaseComponent from '../global/base/BaseComponent'
import PreloaderWithTextSlides from '../../../global/preloaders/PreloaderWithTextSlides'
import PageForm from '../../../global/pages/PageForm'
import * as Sentry from '@sentry/browser'
import DashboardService from '../../../../services/DashboardService'

export default {
  name: 'ProcessData',
  extends: BaseComponent,
  components: {
    PageForm,
    PopupDataInvalid,
    PreloaderWithTextSlides
  },
  data: function () {
    return {
      errors: [],
      hasError: false,
      textSlides: [
        this.$t('message[\'general.preloader.submitting-info\']'),
        this.$t('message[\'general.preloader.updating-preferences\']')
      ],
      slidesComplete: false,
      processComplete: false,
      checkStatusInterval: null
    }
  },
  beforeDestroy () {
    this.destroyStatusChecker()
  },
  mounted () {
    this.hideLoading()
    if (this.$route.name === 'MealPlanUpdateMain') {
      this.makeMealPlanUpdateData()
    } else {
      this.makeWorkoutUpdateData()
    }
    this.setStatusChecker()
  },
  methods: {
    ...mapGetters({
      getService: 'formWizardStore/getProgressFormService'
    }),
    setStatusChecker () {
      this.checkStatusInterval = setInterval(() => {
        if (this.slidesComplete && this.processComplete) {
          this.nextComponent()
        }
      }, 1000)
    },
    destroyStatusChecker () {
      clearInterval(this.checkStatusInterval)
    },
    makeWorkoutUpdateData () {
      const service = this.getService()
      service.updateWorkout().then(() => {
        this.setProcessComplete()
      }).catch((err) => {
        this.errors = Object.values(service.getErrors())
        this.hasError = true
        if (err.status === 422) {
          this.errors = Object.values(err.data.errors)
        }
        Sentry.captureException(err)
      }).finally(() => {
        this.hideLoading()
        let notifications = new NotificationService()
        notifications.resetNotifications()
      })
    },
    makeMealPlanUpdateData () {
      const service = this.getService()
      service.updateMealPlan().then(() => {
        this.setProcessComplete()
      }).catch((err) => {
        this.errors = Object.values(service.getErrors())
        if (err.status === 422) {
          this.errors = Object.values(err.data.errors)
        }
        this.hasError = true
      }).finally(() => {
        this.hideLoading()
        let notifications = new NotificationService()
        notifications.resetNotifications()
      })
    },
    navigate () {
      if (this.$route.name === 'MealPlanUpdateMain') {
        this.$router.push({ path: this.$appConfig.appUrlList.mealPlanUpdate })
      } else {
        this.$router.push({ path: this.$appConfig.appUrlList.workoutUpdate })
      }
    },
    setProcessComplete () {
      const dashboard = new DashboardService()
      dashboard.resetData()
      dashboard.getUserData(true)
      dashboard.getPageData(true)
      this.processComplete = true
    },
    setSlidesComplete () {
      this.slidesComplete = true
    },
    nextComponent () {
      this.$emit('submit', null)
    }
  }
}
</script>

<style scoped>

</style>
