export default class PaymentGatewayNotSupportedError extends Error {
  constructor (type = 'PaymentGateway') {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super()

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PaymentGatewayNotSupportedError)
    }

    this.name = 'PaymentGatewayNotSupportedError'
    // Custom debugging information
    this.type = type
  }
}
