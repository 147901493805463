import { Howl, Howler } from 'howler'

export default {

  props: {
    autoStart: {
      default: false
    },
    color: {
      default: 'green'
    },
    name: {
      default: ''
    },
    restMessage: {
      default: ''
    },
    noWorkoutMessage: {
      default: false
    },
    insideVideo: {
      default: false
    },
    restTime: 0,
    restInterval: 0
  },
  data: function () {
    return {
      sounds: {
        started: null,
        ended: null
      },
      state: 'pending', // pending,started,paused,stopped
      countdownProgressData: {},
      restCountdownTime: 0,
      play: false,
      timerShow: false,
      imagePath: this.$appConfig.imagesUrl + 'timer/',
      imagePlay: '',
      imagePause: '',
      imageRestart: ''
    }
  },
  watch: {
    play (newVal, oldVal) {
      if (newVal === true && this.state === 'pending') {
        this.state = 'started'
      } else if (newVal === false) {
        this.state = 'paused'
      } else if (newVal === true && this.state === 'paused') {
        this.state = 'started'
      }
    },
    state (newVal, oldVal) {
      if (newVal === 'started' && oldVal === 'pending') {
        this.playStartTimer()
        this.$emit('started', { time: this.restCountdownTime })
      }
    }
  },
  mounted () {
    if (typeof this.color !== 'undefined' && this.color === 'white') {
      this.imagePlay = this.imagePath + 'btn-play.svg'
    } else if (typeof this.color !== 'undefined' && this.color === 'green') {
      this.imagePlay = this.imagePath + 'btn-play-green.svg'
    } else {
      this.imagePlay = this.imagePath + 'btn-play-dark.svg'
    }
    this.setRestTime()
    this.timerShow = true
  },
  methods: {
    playStartTimer () {
      this.sounds.started = new Howl({
        src: ['https://app-redesign-plugin-files.s3.us-west-1.amazonaws.com/sounds/timer/timer-start.mp3'],
        volume: 0.5
      })
      this.setVolume()
      this.sounds.started.play()
    },
    playEndTimer () {
      this.sounds.ended = new Howl({
        src: ['https://app-redesign-plugin-files.s3.us-west-1.amazonaws.com/sounds/timer/timer-end.mp3'],
        volume: 0.5
      })
      this.setVolume()
      this.sounds.ended.play()
    },
    setVolume () {
      if (templateConfig.instance_config &&
        'rest_timer_vol' in templateConfig.instance_config) {
        let vol = parseInt(templateConfig.instance_config.rest_timer_vol) || 0
        vol = vol / 10
        Howler.volume(vol)
      }
    },
    countdownProgress (data) {
      this.countdownProgressData = data
      this.$emit('remaining', { remaining: this.countdownProgressData })
    },
    setRestTime () {
      this.restCountdownTime = this.restTime || 0
      if (this.restTime !== 0) {
        this.restTime = parseInt(this.restTime)
        if (this.restInterval === 'hour') {
          this.restCountdownTime = this.restTime * 3600
        } else if (this.restInterval === 'minute') {
          this.restCountdownTime = this.restTime * 60
        }
      }
    },
    setTimerNumbers (num) {
      num = parseInt(num)
      return num < 10 ? '0' + num : num
    },
    playCountdown () {
      if (this.play) {
        this.$refs.countdownRest.abort()
        this.$emit('pause', { remaining: this.countdownProgressData })
      } else {
        this.$refs.countdownRest.start()
        this.$emit('start', { time: this.restCountdownTime })
      }
      this.play = !this.play
    },
    startWorkout () {
      this.playEndTimer()
      this.timerShow = false
      setTimeout(() => {
        this.timerShow = true
        this.play = false
      }, 5)
      this.$emit('ended', true)
    },
    headerTime (min, sec) {
      if (min === 0) {
        return this.setTimerNumbers(sec)
      } else if (sec === 0) {
        return this.setTimerNumbers(min)
      }
      return this.setTimerNumbers(min) + ':' + this.setTimerNumbers(sec)
    },
    headerTimeType (min, sec) {
      if (min === 0) {
        return this.$i18n.t('message["general.time-seconds-short"]')
      } else {
        return this.$i18n.t('message["general.time-minutes-short"]')
      }
    }
  }
}
