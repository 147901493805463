<template>
  <popup
    @hide="closePopup"
    modal-class="pc-popup-workout-log-confirm-log"
    :visible="visible"
    v-if="visible"
  >
    <popup-title>
      <popup-button-close @click="closePopup"></popup-button-close>
    </popup-title>
    <popup-body class="text-center">
      <popup-content>
        <popup-head>
          <popup-text-heading>{{ $t('message[\'popup.workout-log-confirm-without-complete.title\']')}}
          </popup-text-heading>
        </popup-head>
        <popup-text-body>
          <p v-html="$t('message[\'popup.workout-log-confirm-without-complete.description\']')"></p>
        </popup-text-body>
      </popup-content>
      <popup-footer>
        <button-primary
          class="pc-popup-workout-log-confirm-log__button-submit"
          :text="buttonTextConfirm"
                        @click="doAction()"/>
        <button-secondary :text="$t('message[\'popup.workout-log-confirm-without-complete.btn-cancel\']')"
                          @click="closePopup()"/>
      </popup-footer>
    </popup-body>
  </popup>
</template>

<script>

import popupAnimationMixin from '../../../../../../mixins/popupAnimationMixin'
import Popup from '../../../../../global/popups/Popup'
import PopupTitle from '../../../../../global/popups/popup-sub-components/PopupTitle'
import PopupButtonClose from '../../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupContent from '../../../../../global/popups/popup-sub-components/PopupContent'
import PopupHead from '../../../../../global/popups/popup-sub-components/PopupHead'
import PopupBody from '../../../../../global/popups/popup-sub-components/PopupBody'
import PopupTextHeading from '../../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupTextBody from '../../../../../global/popups/popup-sub-components/PopupTextBody'
import PopupFooter from '../../../../../global/popups/popup-sub-components/PopupFooter'
import ButtonPrimary from '../../../../../global/buttons/ButtonPrimary'
import ButtonSecondary from '../../../../../global/buttons/ButtonSecondary'

export default {
  name: 'PopupWorkoutLogConfirmWithoutLog',
  components: {
    PopupBody,
    ButtonSecondary,
    ButtonPrimary,
    PopupFooter,
    PopupTextBody,
    PopupTextHeading,
    PopupHead,
    PopupContent,
    PopupButtonClose,
    PopupTitle,
    Popup
  },
  mixins: [popupAnimationMixin],
  props: {
    visible: { default: false },
    buttonText: { default: '' }
  },
  data: function () {
    return {
      title: '',
      imagesUrl: this.$appConfig.imagesUrl
    }
  },
  computed: {
    buttonTextConfirm: function () {
      return (!this.buttonText) ? this.$i18n.t('message["popup.workout-log-confirm-without-complete.btn-confirm"]') : this.buttonText
    }
  },
  methods: {
    doAction () {
      this.$emit('next')
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    }

  }
}
</script>
