import lang from '../../lang/lang'
// TODO - use API call to get this after platform intergation

export default function changeToNewTheme () {
  const title = getLang('popup.to-new-design.title')
  const descriptionHeader = getLang('popup.to-new-design.description-header')
  const descriptionContent = getLang('popup.to-new-design.description-content')
  const bodyText = `<h3 style="text-align: center"> ${descriptionHeader}<h3/>
                                    <br>
                    <p style="text-align: center">${descriptionContent}<p/>`
  const cancelText = getLang('general.cancel')
  const confirmText = getLang('general.change-theme.button-text')

  let background = $('.article-primary-gama.article-meal').css('background-color')// eslint-disable-line
  let fontColor = $('.article-primary-gama.article-meal h4.article-title').css('color')// eslint-disable-line
  const newLookPopup = `<div class="modal fade"   id="newThemeModal">
            <div class="modal-dialog modal-dialog-centered" style="margin-top:100px">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" ><span aria-hidden="true">&times;</span></button>
                        <h4 class="modal-title">${title}</h4>
                    </div>
                    <div class="modal-body">
                        ${bodyText}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal"> ${cancelText}</button>
                        <button type="button" class="btn btn-action btn-change-theme"> ${confirmText}</button>
                    </div>
                </div>
            </div>
        </div>`
  $('main').before(newLookPopup)// eslint-disable-line

  let themeChangeDiv = ''
  if (isUrl('/dashboard')) {// eslint-disable-line
    const switchText = getLang('theme-switch.bar-text')
    themeChangeDiv = `<div style="width: 100%;background-color: rgb(255, 255, 255);font-size: 14px;text-align: center;padding: 13px;text-decoration: underline;" class="new-theme-change-text"> ${switchText}</div>`
  }
  let changeThemeButton = ''
  if (isUrl('/account-details')) {// eslint-disable-line
    const confirmText = getLang('theme-switch.button-text')
    changeThemeButton = `<div class="form-row-primary">
                                    <div class="form-col">
                                        <div class="form-row">

                                            <div class="form-controls form-actions"  >
                                                <a href="javascript:" data-toggle="modal" class="btn m_b_10 new-theme-change-text">${confirmText}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>`
  }

  $('.form-row-primary:nth-child(2)').not(':eq(0)').after(changeThemeButton)// eslint-disable-line

  $('main').before(themeChangeDiv)// eslint-disable-line

  $('.new-theme-change-text').css('background-color', background)// eslint-disable-line
  $('.new-theme-change-text').css('color', fontColor)// eslint-disable-line
  $('.new-theme-change-text').click(function () {// eslint-disable-line
    $('#newThemeModal').modal('show')// eslint-disable-line
  })
  $('.btn-change-theme').click(function () {// eslint-disable-line
    localStorage.setItem('theme', 'new')
    window.location.reload()
  })

  $(window).resize(function () {// eslint-disable-line
    if (screen.width > 768) {
      $('.new-theme-change-text').css('margin-top', '40px')// eslint-disable-line
    } else {
      $('.new-theme-change-text').css('margin-top', '0px')// eslint-disable-line
    }
  })
}

function getLang (key) {
  if (templateConfig && templateConfig.localization && templateConfig.localization[key]) {
    return templateConfig.localization[key]
  }
  return lang[key] || key
}
