<template>
  <popup
    v-if="true"
    ref="popup"
    :visible="true"
    class="pc-popup-plan-exists"
    :no-close-on-backdrop="true"
    @hide="closePopup"
  >
    <popup-title>
      <popup-button-close @hide="closePopup" />
    </popup-title>
    <popup-body class="gc-popup-meal-swap__body text-center">
      <popup-head class="text-center">
        <popup-text-heading>{{ $t('message["sign-up.popup-plan-exists.title"]') }}</popup-text-heading>
      </popup-head>
      <popup-content>
        <p>
          <popup-text-body class="pc-popup-plan-exists__message">
            {{ $t('message["sign-up.popup-plan-exists.message-one"]') }}
          </popup-text-body>
        </p>
        <p>
          <popup-text-body>{{ $t('message["sign-up.popup-plan-exists.message-two"]') }}</popup-text-body>
        </p>
      </popup-content>
      <popup-footer>
        <button-primary
          class="pc-popup-plan-exists__understood-btn"
          :text="$t('message[\'general.understood\']')"
          @click="goToDashboard"
        />
        <button-secondary
          :text="$t('message[\'settings.contact-support\']')"
          @click="showContactSupport"
        />
      </popup-footer>
    </popup-body>
  </popup>
</template>

<script>

import Popup from '../../../../global/popups/Popup'
import PopupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupFooter from '../../../../global/popups/popup-sub-components/PopupFooter'
import PopupTextBody from '../../../../global/popups/popup-sub-components/PopupTextBody'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import ButtonSecondary from '../../../../global/buttons/ButtonSecondary'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'

export default {
  name: 'PopupPlanExists',
  components: {
    Popup,
    PopupBody,
    PopupButtonClose,
    PopupContent,
    PopupFooter,
    PopupTextBody,
    PopupHead,
    PopupTextHeading,
    PopupTitle,
    ButtonSecondary,
    ButtonPrimary
  },
  mixins: [popupAnimationMixin],

  props: {
    planName: {
      default: function () {
        return {}
      },
      type: Object
    }
  },
  computed: {
    description: function () {
      const planName = '<span class="rc-text-content--weight-extra-bold">' + this.planName + '</span>'
      return this.$i18n.t('message["sign-up.popup-plan-exists.description"]', {plan: planName})
    }
  },
  methods: {
    showContactSupport () {
      this.$emit('showContactSupport')
    },
    goToDashboard () {
      this.$router.replace({path: this.$appConfig.appUrlList.dashboard})
    },
    closePopup (e, callback) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
        this.goToDashboard()
        if (callback) {
          callback()
        }
      })
    }

  }
}
</script>
