<template>
  <transition name="fade">
    <div class="gc-popup__body-content" id="gc-popup-body-content">
      <slot></slot>
      <div class="gc-popup_body-static-bottom"></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'PopupContent'
}
</script>

<style scoped>

</style>
