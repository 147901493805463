import ContentPageService from './ContentPageService'
import ContentPagesApi from './api/content-page/ContentPagesApi'

export default class ContentPageFactoryService {
  _pages =new Map()
  _pagesList = []
  constructor () {
    if (!ContentPageFactoryService.instance) {
      ContentPageFactoryService.instance = this
    }
    return ContentPageFactoryService.instance
  }

  getPageList () {
    const api = new ContentPagesApi()
    return new Promise((resolve, reject) => {
      if (this._pagesList.length === 0) {
        api.getList().then(data => {
          this._pagesList = data.data.pages.map(page => {
            page.code = page.code || page.route
            return page
          })
          resolve(data.data.pages)
        }).catch((err) => {
          reject(err)
        })
      } else {
        resolve(this._pagesList)
      }
    })
  }

  findPageInList (page, list) {
    page = encodeURIComponent(page)
    return list.find(link => {
      const params = link.url.split('/')
      return page === params[params.length - 1]
    })
  }

  setPage (pageName, PageObj) {
    console.trace(pageName, PageObj)
    this._pages.set(pageName, PageObj)
  }

  getPage (pageName) {
    if (!this.hasPage(pageName)) {
      const obj = new ContentPageService(pageName)
      this.setPage(pageName, obj)
    }
    return this._pages.get(pageName)
  }

  hasPage (pageName) {
    return this._pages.has(pageName)
  }
}
