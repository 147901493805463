import PeriodTrackerApi from '../api/period-tracker/PeriodTrackerApi'
import PeriodTrackerService from './PeriodTrackerService'

export default class PeriodFirstRecord {
  _nextPeriod = null
  _cycleLength = null
  _cycleId = null
  _actualDate = null
  _actualPeriodLength = null

  constructor (initialData = null) {
    if (initialData) {
      this.initiate(initialData)
    }
  }

  initiate (initialData) {
    this._nextPeriod = initialData.next_period
    this._cycleLength = initialData.cycle_length
    this._cycleId = initialData.cycle_id
  }

  getNextPeriod () {
    return this._nextPeriod
  }

  getCycleLength () {
    return this._cycleLength
  }

  getCycleId () {
    return this._cycleId
  }

  getActualDate () {
    return this._actualDate
  }

  getActualPeriodLength () {
    return this._actualPeriodLength
  }

  setNextPeriod (nextPeriod) {
    this._nextPeriod = nextPeriod
  }

  setCycleLength (cycleLength) {
    this._cycleLength = cycleLength
  }

  setCycleId (cycleId) {
    this._cycleId = cycleId
  }

  setActualDate (actualDate) {
    this._actualDate = actualDate
  }

  setActualPeriodLength (actualPeriodLength) {
    this._actualPeriodLength = actualPeriodLength
  }

  save () {
    return new Promise((resolve, reject) => {
      let api = new PeriodTrackerApi()
      api.updateCycle(
        this.getCycleId(),
        this.getActualDate(),
        this.getActualPeriodLength()
      )
        .then(() => {
          resolve()
        })
        .catch((error) => {
          PeriodTrackerService.handleServerError(error.response)
          reject(error)
        })
    })
  }
}
