<template>
      <transition name="fade" mode="out-in">
        <component v-bind:is="dynamicComponent"></component>
      </transition>
</template>

<script>
import PageBillingInfo from './PageBillingInfo'
import PageCreditCard from './PageCreditCard'
import BaseComponent from '../../global/base/BaseComponent'
import CardUpdateSuccess from './page-components/CardUpdateSuccess'
import PageSecondary from '../../../../global/pages/PageSecondary'
import PageContainer from '../../../../global/pages/page-sub-components/PageContainer'

export default {
  components: { PageSecondary, PageContainer },
  name: 'PaymentSettings',
  extends: BaseComponent,
  data: function () {
    return {
      components: {
        settings: PageCreditCard,
        billing: PageBillingInfo,
        updateSuccess: CardUpdateSuccess,
        PageSecondary,
        PageContainer
      },
      dynamicComponent: ''
    }
  },
  mounted () {
    if (this.$route.query.update) {
      this.setComponentByType('update-' + this.$route.query.update)
    } else {
      this.setComponentByType(this.$route.query.section)
    }
  },
  methods: {
    setComponentByType (type = '') {
      switch (type) {
        case 'update':
          this.dynamicComponent = this.components.billing
          break
        case 'update-success':
          this.dynamicComponent = this.components.updateSuccess
          break
        default:
          this.dynamicComponent = this.components.billing

          break
      }
    }
  }
}
</script>
