<template>
    <div :class="componentClasses" v-show="(!search)||(search&&hasSearchResult)">

      <button-secondary-accordion :class="{'gc-input-group-ingredients-sub-accordian__main--dislike-all': isSubGroupDislike}"
                                  :text="subCategory.name"
                                  :subText="setCounts"
                                  :show-arrow="!search"
                                  @click="subCategorySelect()">
          <div class="gc-input-group-ingredients-sub-accordian__wrapper">
            <input-radio-button-squared :disabled="disabled" class="gc-input-group-ingredients-sub-accordian__square-radio" v-if="!isSubGroupDislike" :semiSelected="subGroupDislikesCount > 0" @click="toggleDislikedGroup()" />
            <div class="gc-input-group-ingredients-sub-accordian__dislike-radio" @click="toggleDislikedGroup()" v-else>
            <icon-dislike :size="'sm4'" />
            </div>
          </div>
      </button-secondary-accordion>
      <div class="gc-input-group-ingredients-sub-accordian__ingredients-wrapper" v-show="(!search&&showIngredientsList)||(search&&hasSearchResult)">
        <ingredients-list
            v-for="(ingredient, index) in subCategory.ingrediants"
            v-show="(!search||ingredient.name.toLowerCase().includes(search))"
            ref="ingredients"
            :disabled="disabled"
            :key="index"
            :name="ingredient.name"
            :id="ingredient.id"
            :dislikedAll="isSubGroupDislike"
            @dislike="dislike"
        />
      </div>
    </div>
</template>
<script>
import ButtonSecondaryAccordion from '../../buttons/ButtonSecondaryAccordion'
import IngredientsList from './InputGroupIngredientsItem'
import IconDislike from '../../../root/icons/IconDislike'
import lodash from 'lodash'
import InputRadioButtonSquared from '../InputRadioButtonSquared'
export default {
  name: 'InputGroupIngredientsSubAccordian',
  props: {
    subCategory: {
      default: function () {
        return {}
      }
    },
    search: {
      default: ''
    },
    value: {
      default: function () {
        return []
      },
      type: Array
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showIngredientsList: false,
      subGroupDislikes: []

    }
  },
  components: {
    ButtonSecondaryAccordion,
    IngredientsList,
    IconDislike,
    InputRadioButtonSquared
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: function () {
        this.subGroupDislikes = lodash.uniq(this.value)
        if (this.$refs.ingredients) {
          this.$refs.ingredients.forEach((ingredient) => {
            if (this.value.includes(ingredient.getID())) {
              ingredient.setDisliked()
            } else {
              ingredient.unsetDisliked()
            }
          })
          this.emitSelect()
        }
      }
    },
    subGroupDislikesCount: function () {
      if (this.subGroupDislikesCount === this.subCategory.ingrediants.length) {
        this.$emit('all-selected', this.subGroupDislikesCount)
      } else if (this.subGroupDislikesCount === 0) {
        this.$emit('all-deselected')
      } else {
        this.$emit('some-selected')
      }
    }

  },
  mounted () {
    this.$refs.ingredients.forEach((ingredient) => {
      if (this.value.includes(ingredient.getID())) {
        ingredient.setDisliked()
      }
    })
    this.emitSelect()
  },
  computed: {
    hasSearchResult () {
      if (this.search) {
        const hasSearchRes = this.subCategory.ingrediants.some((ingredient) => {
          return ingredient.name.toLowerCase().includes(this.search)
        })
        this.$emit('has-search-results', hasSearchRes)

        return hasSearchRes
      }
      return false
    },
    componentClasses: function () {
      return {
        'gc-input-group-ingredients-sub-accordian': true,
        'gc-input-group-ingredients-sub-accordian--with-search': !!this.search
      }
    },
    setCounts: function () {
      if (this.subGroupDislikesCount === 0 || this.subGroupDislikesCount === 1) {
        return this.subGroupDislikesCount + ' Ingredient disliked'
      } else {
        return this.subGroupDislikesCount + ' Ingredients disliked'
      }
    },
    isSubGroupDislike: function () {
      return this.subCategory.ingrediants.every(ing => {
        return this.subGroupDislikes.includes(ing.id)
      })
    },
    subGroupDislikesCount () {
      return this.subCategory.ingrediants.filter(ing => {
        return this.subGroupDislikes.includes(ing.id)
      }).length
    }

  },
  methods: {
    emitSelect () {
      let selected = this.subCategory.ingrediants.filter(ing => {
        return this.subGroupDislikes.includes(ing.id)
      })

      this.$emit('select', selected)
    },
    subCategorySelect () {
      this.showIngredientsList = !this.showIngredientsList
    },

    dislike (obj) {
      if (obj.status) {
        this.subGroupDislikes.push(obj.id)
      } else {
        this.subGroupDislikes = this.subGroupDislikes.filter((item) => {
          return item !== obj.id
        })
      }
      this.emitInput()
    },
    emitInput () {
      this.subGroupDislikes = lodash.uniq(this.subGroupDislikes)
      this.$emit('input', this.subGroupDislikes)
      this.emitSelect()
    },

    selectedAll () {
      this.$refs.ingredients.forEach((ingredient) => {
        ingredient.setDisliked()
        this.subGroupDislikes.push(ingredient.getID())
      })
    },
    deselectAll () {
      let idList = []
      this.$refs.ingredients.forEach((ingredient) => {
        ingredient.unsetDisliked()
        idList.push(ingredient.getID())
      })
      this.subGroupDislikes = this.subGroupDislikes.filter((obj) => {
        return !idList.includes(obj)
      })
      // this.subGroupDislikes = []
    },
    toggleDislikedGroup () {
      if (this.disabled) {
        return
      }

      if (this.isSubGroupDislike) {
        this.deselectAll()
      } else {
        this.selectedAll()
      }
      this.emitInput()
    }
  }
}
</script>
