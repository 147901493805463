var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.componentClasses},[(_vm.isPersonalBest)?_c('icon-trophy',{staticClass:"gc-card-workout-history__personal-best",attrs:{"size":'sm4'}}):_vm._e(),_c('icon-more',{staticClass:"gc-card-workout-history__dots",attrs:{"size":'sm2'},on:{"click":function($event){return _vm.clickedMore()}}}),_c('div',{staticClass:"gc-card-workout-history__header"},[_c('text-body-regular',{attrs:{"weight":'extra-bold',"lineHeight":'multi'}},[_vm._v(_vm._s(_vm.formatDateTime(_vm.loggedAt))+" ")])],1),_c('div',{staticClass:"gc-card-workout-history__content-wrapper"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isEditing),expression:"!isEditing"}],staticClass:"gc-card-workout-history__sets"},_vm._l((_vm.entries.slice(0,_vm.exerciseSetCount)),function(entry,eID){return _c('div',{key:eID},[(!_vm.isEditing)?_c('text-body-small2',{attrs:{"weight":'extra-bold',"lineHeight":'multi'}},[_c('div',{staticClass:"gc-card-workout-history__content-text",class:{
            'gc-card-workout-history__content-text--with-four': _vm.metricsCount===4,
            'gc-card-workout-history__content-text': true,
          },domProps:{"innerHTML":_vm._s(_vm.setLogText(entry))}})]):_vm._e()],1)}),0),(_vm.entries.slice(_vm.exerciseSetCount).length)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isEditing),expression:"!isEditing"}],staticClass:"gc-card-workout-history__extra-sets"},_vm._l((_vm.entries.slice(_vm.exerciseSetCount)),function(entry,eID){return _c('div',{key:eID},[(!_vm.isEditing)?_c('text-body-small2',{attrs:{"weight":'extra-bold',"lineHeight":'multi'}},[_c('div',{staticClass:"gc-card-workout-history__content-text",class:{
          'gc-card-workout-history__content-text--with-four': _vm.metricsCount===4,
          'gc-card-workout-history__content-text': true,
        },domProps:{"innerHTML":_vm._s(_vm.setLogText(entry))}})]):_vm._e()],1)}),0):_vm._e(),(_vm.showNoteForm)?_c('form-note-workout-log',{attrs:{"exercise-i-d":_vm.exerciseID,"day-name":_vm.dayName,"week-name":_vm.weekName,"note":_vm.note,"save-type":'update',"workout-i-d":_vm.workoutID,"exercise-name":_vm.exerciseName},on:{"cancel":function($event){return _vm.cancelEdit()},"completed":function($event){return _vm.completeEdit($event)}}}):_vm._e(),(_vm.showEntryForm)?_c('form-edit-workout-log',{attrs:{"current-unit-system":_vm.unitSystemValue,"entries":_vm.entries,"day-name":_vm.dayName,"week-name":_vm.weekName,"exercise-name":_vm.exerciseName,"exercise-i-d":_vm.exerciseID,"exercise-rest-interval":0,"exercise-rest-time":0,"exercise-time":0,"is-completed":true,"force-edit":true,"metrics":_vm.metrics,"pre-note":_vm.note,"sets":_vm.sets,"workout-i-d":_vm.workoutID},on:{"cancel":function($event){return _vm.cancelEdit()},"canceled":function($event){return _vm.cancelEdit()},"submitted":function($event){return _vm.completeEdit($event)}}}):_vm._e()],1),(!_vm.isEditing)?_c('div',{staticClass:"gc-card-workout-history__footer"},[_c('text-body-extra-small',{attrs:{"weight":'medium',"lineHeight":'multi'}},[_vm._v(" "+_vm._s((_vm.editNote||_vm.note||_vm.$t('message[\'workout-log.exercise-completed-text\']')))+" ")])],1):_vm._e(),_c('popup-workout-log-modify',{attrs:{"visible":_vm.editPopup.visible},on:{"edit":function($event){return _vm.editClicked()},"delete":function($event){return _vm.deleteClicked()},"close":function($event){return _vm.hideModifyPopup()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }