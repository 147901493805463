import { checkNativeAppVersion } from '../../../../includes/NativeAppCommon'
import ForgotPasswordService from '../../../../services/ForgotPasswordService'
import BaseComponent from '../../../../components/layout/template-1/global/base/BaseComponent'

export default {
  extends: BaseComponent,
  data: function () {
    return {
      state: 'init',
      successPopupShow: false,
      successPopupTitle: this.$i18n.t('message[\'auth.password-reset\']'),
      formData: {
        email: ''
      },
      logo: '',
      logoShow: true,
      logoStyle: {
        visibility: 'hidden'
      },
      backgroundImage: '',
      submited: false
    }
  },
  methods: {
    /**
     * get the login background image from toning
     */
    setImages () {
      // this.logo = this.imagesUrl + 'generic/logo.png'
      this.logo = this.getStoreImage('login.logo')
      if (this.logo.match(/\.(jpeg|jpg|gif|png)$/) === null) {
        this.logoShow = false
      }
      this.backgroundImage = this.getStoreImage('login.background')
    },
    /**
     * trigger the native app goback event if native app
     * then go to the previous page
     */
    goBack () {
      try {
        if (checkNativeAppVersion()) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              command: {
                name: 'goback'
              }
            })
          )
        }
      } catch (e) {
        console.log(e)
      }
      window.history.back()
    },
    /**
     * submit the password reset
     */
    submitForm () {
      this.showLoading()
      if (this.state === 'submitting') {
        return
      }
      this.state = 'submitting'
      const auth = new ForgotPasswordService()
      auth.request(this.formData).then(data => {
        this.successPopupShow = true
      }).catch(data => {
        if (data.status === 422) {
          this.$refs.form.setErrors({ email: data.data.errors })
        } else {
          this.showServerErrorPopup()
        }
      }).finally(() => {
        this.state = 'init'
        this.hideLoading()
      })
    }
  }
}
