<template>
  <div>
    <div v-if="notifications.filter((notification) => {
      return notification.user
    }).length === 0" class="pc-notifications__empty-state-message text-center">
      <text-body-extra-small lineHeight="multi">{{ $t('message["notification.empty"]') }}</text-body-extra-small>
    </div>
    <div v-else>
      <div :key="key" v-for="(  notification, key  ) in notifications" @click="navigateToPost(notification)">
        <card-community-notification :notification="notification"/>
      </div>
    </div>
  </div>
</template>

<script>
import CommunityService from '../../../../../services/community/CommunityService'
import CardCommunityNotification from './CardCommunityNotification'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import headerEventBus from '../../../../../event-buses/headerEventBus'
import DashBoardService from '@/services/DashboardService'
import { checkIsCreator } from '@/helpers/user'

export default {
  name: 'CommunityNotificationsContainer',
  components: {
    CardCommunityNotification,
    TextBodyExtraSmall
  },
  data () {
    return {
      notifications: [],
      unread: 0,
      unseen: 0,
      loadComplete: false,
      scrollLoadingBusy: false,
      pagination: {
        limit: 0,
        offset: 0
      },
      communityService: new CommunityService()
    }
  },
  watch: {
    notifications: {
      handler: function (notifications) {
        this.$emit('notifications-changed', notifications)
      },
      deep: true
    }
  },
  methods: {
    async navigateToPost (notification) {
      const communityId = (await this.communityService.getActiveCommunities())[0].id
      const redirectPath = `/dashboard?community=${communityId}#/communities/feeds/${communityId}/view-post/published/${notification.activityId}`
      let dashboard = new DashBoardService()
      if (!notification.isRead && (checkIsCreator() || dashboard.hasActivePlan())) {
        this.showLoading()
        this.communityService.notificationRead(notification.id)
          .finally(() => {
            this.delayedRouterPush({
              path: redirectPath
            })
          })
      } else {
        if (checkIsCreator() && !notification.isRead) {
          this.showLoading()
          this.communityService.notificationRead(notification.id)
        }
        this.delayedRouterPush({
          path: redirectPath
        })
      }
    },
    markReadAll () {
      // prevent action if no unread notifications exist
      if (this.notifications.filter(notification => {
        return !notification.isRead
      }).length === 0) {
        return
      }
      this.showLoading()
      this.communityService.setNotificationsAsRead()
        .finally(() => {
          this.reset()
          this.loadNext()
        })
    },
    scrollDifference () {
      return document.documentElement.scrollHeight - window.innerHeight
    },
    handleScroll () {
      if (this.scrollLoadingBusy || this.loadComplete) {
        return
      }
      this.scrollLoadingBusy = true
      const difference = this.scrollDifference()
      const scrollposition = document.documentElement.scrollTop
      if (difference - scrollposition <= 50) {
        this.loadNext()
      } else {
        this.scrollLoadingBusy = false
      }
    },
    async loadNext () {
      this.showLoading()
      return await this.communityService.getNotifications(this.pagination.limit, this.pagination.offset)
        .then((response) => {
          this.notifications = this.notifications.concat(response.notifications)
          // on first load, set unread and unseen
          if (this.pagination.offset === 0) {
            this.unread = response.unread
            this.unseen = response.unseen
          }
          this.pagination.offset += this.pagination.limit
          if (response.notifications.length < this.pagination.limit) {
            this.loadComplete = true
          } else if (this.scrollDifference() <= 0) {
            // if offset limit is reached and scroll is not enabled, load next again till scroll is enabled or all records are loaded
            this.loadNext()
          }
          this.scrollLoadingBusy = false
        })
        .catch(() => {
          this.scrollLoadingBusy = false
        })
    },
    reset () {
      this.notifications = []
      this.pagination.limit = 20
      this.pagination.offset = 0
      this.loadComplete = false
      this.scrollLoadingBusy = false
    }
  },
  mounted () {
    this.showLoading()
    this.reset()
    this.communityService.setNotificationsAsSeen()
    this.loadNext()
      .finally(() => {
        document.addEventListener('scroll', this.handleScroll)
        headerEventBus.$on('read-all-button-click', this.markReadAll)
        this.hideLoading()
      })
    localStorage.setItem('communityNotificationUnseenCount', '0')
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.handleScroll)
    headerEventBus.$off('read-all-button-click', this.markReadAll)
  }
}
</script>
