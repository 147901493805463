<template>
  <div :style="modalStyle" class="modal-image" id="myModal" scroll="no">
    <span class="modal-image__close" v-on:click="close()"><img :src="imagesUrl + 'close-btn.svg'" alt="popup" /></span>
    <img :src="image" class="modal-image__content">
  </div>

</template>

<script>
export default {
  name: 'CustomPopupImage',
  props: {
    image: {
      required: true
    }
  },
  data: function () {
    return {
      imagesUrl: this.$appConfig.imagesUrl,
      modalStyle: { opacity: 0 }
    }
  },
  mounted () {
    this.modalStyle.opacity = 1
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
