<template>
  <popup
    v-if="visible"
    ref="mealRemoveModal"
    :visible="visible"
    modal-class="pc-popup-remove-meal"
    @hide="closePopup"
  >
    <popup-title>
      <popup-button-close @hide="closePopup" />
    </popup-title>
    <popup-body>
      <popup-head class="text-center">
        <popup-text-heading v-if="state==='init'">
          {{ $t('message["food-diary.remove-meal-title"]') }}
        </popup-text-heading>
        <popup-text-heading v-if="state==='submitting'">
          {{ $t('message["food-diary.remove-meal-removing"]') }}
        </popup-text-heading>
        <popup-text-heading v-if="state==='success'">
          {{ $t('message["food-diary.remove-meal-removed"]') }}
        </popup-text-heading>
      </popup-head>
      <popup-content>
        <popup-text-body
          v-if="state==='init'"
          class="text-center"
        >
          <p v-html="description" />
        </popup-text-body>
        <div
          v-if="state==='success'"
          class="text-center"
        >
          <icon-complete-filled :size="'lg3'" />
        </div>
        <div v-if="state==='submitting'">
          <loading-buffer height="100" />
        </div>
      </popup-content>
      <popup-floating-footer v-if="state==='init'">
        <button-primary
          :text="removeConfirmButtonText"
          @click="remove()"
        />
      </popup-floating-footer>
    </popup-body>
  </popup>
</template>

<script>
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import BaseComponent from '../../global/base/BaseComponent'
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import FoodDiaryService from '../../../../../services/FoodDiaryService'
import Popup from '../../../../global/popups/Popup'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupTextBody from '../../../../global/popups/popup-sub-components/PopupTextBody'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import PopupTextSubHeading from '../../../../global/popups/popup-sub-components/PopupTextSubHeading'
import IconCompleteFilled from '../../../../root/icons/IconCompleteFilled'
import PopupFloatingFooter from '../../../../global/popups/popup-sub-components/PopupFloatingFooter'

// TODO - lang
export default {
  name: 'PopupRemoveMeal',
  components: {
    PopupFloatingFooter,
    IconCompleteFilled,
    PopupTextSubHeading,
    ButtonPrimary,
    PopupTextBody,
    PopupContent,
    PopupTextHeading,
    PopupHead,
    PopupBody,
    PopupButtonClose,
    PopupTitle,
    Popup,
    LoadingBuffer
  },
  extends: BaseComponent,
  mixins: [popupAnimationMixin],
  props: {
    logID: {
      required: true,
      type: String

    },
    mealID: {
      required: true,
      type: String

    },
    visible: {
      required: true,
      type: Boolean

    },
    mealName: {
      required: true,
      type: String

    },
    date: {
      required: true,
      type: String

    }},
  data: function () {
    return {
      state: 'init',
      removeConfirmButtonText: this.$i18n.t('message["food-diary.remove-meal-confirm-button-text"]')
    }
  },
  computed: {
    description: function () {
      let date = this.date
      let mealName = this.mealName
      mealName = `<span class="pc-popup-remove-meal__meal">${mealName}</span>`
      return this.$i18n.t('message["food-diary.remove-meal-sub-title"]', {mealName, date})
    }
  },
  created () {

  },
  methods: {

    remove () {
      this.state = 'submitting'
      let service = new FoodDiaryService()
      this.$emit('deleting')
      service.removeMealFromLog(this.logID, this.mealID).then(() => {
        this.state = 'success'
        setTimeout(() => {
          this.$emit('success')
          this.closePopup()
        }, 3000)
      }).catch(() => {
        this.$emit('error')
        this.state = 'init'
      })
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    }

  }
}
</script>
