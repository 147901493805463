<template>
  <button-primary-forward-icon @click="navigate" :text="title" :subText="buttonCountText">
    <icon-cart/>
  </button-primary-forward-icon>
</template>
<script>
import cartButtonMixin from '../../../../../../mixins/cartButtonMixin'
import ButtonPrimaryForwardIcon from '../../../../../global/buttons/ButtonPrimaryForwardIcon.vue'
import IconCart from '../../../../../root/icons/IconCart.vue'

export default {
  components: { ButtonPrimaryForwardIcon, IconCart },
  name: 'CartButton',
  mixins: [cartButtonMixin],
  computed: {
    buttonCountText: function () {
      if (this.count > 1) {
        return this.count.toString() + ' ' + this.$i18n.tc('message["general.items"]', parseInt(this.count))
      }
      return this.count.toString() + ' ' + this.$i18n.tc('message["general.items"]', parseInt(this.count))
    }
  }
}
</script>
