<template>
  <div
    class="gc-sidebar"
    v-bind:class="{'gc-sidebar--hide':!showingMenu&&!closingMenu,'gc-sidebar--close':!showingMenu&&closingMenu}"
  >
      <slideout
        ref="menu"
        menu="#menu"
        panel="#panel"
        :toggleSelectors="['.toggle-button']"
        @on-open="onMenuOpens()"
        :duration="500"
        @on-beforeclose="closedMenu()"
      >
        <div class="gc-sidebar__bg" v-if="showingMenu" v-on:click="closeMenu"></div>
        <nav id="menu" v-show="clicked" v-on:scroll.passive="setScroll">
          <div>
            <a v-on:click="showParent = !showParent" v-show="!showParent"></a>
            <div class="gc-sidebar__user-details-wrap" @click="navigate('/account-details?page=account-settings')">
              <div class="gc-sidebar__image-wrap">
                <img :src="profileImage" @onerror="imageLoadOnError" alt="profile-image"/>
              </div>
              <text-content
              class="gc-sidebar__profile-name"
              :weight="'extra-bold'"
              :size="'sm4'"
              :lineHeight="'multi'">
               {{$t('message["general.sidebar-title"]', { name: charLimitedUserName }) }}
              </text-content>

            </div>
            <div v-for="link in systemLinksList"
                  :class="['link_'+link.code]"
                  :key="'link-'+link.code">
              <side-bar-button-primary
               @click="navigate(link.url)"
               v-bind:class="{'gc-side-bar__button-primary__selected':(curruntRoute === link.url)}">
                {{showLinkText(link.label)}}
              </side-bar-button-primary>
            </div>

              <side-bar-button-primary
                v-if="showWorkoutLogLink&&workoutPlans.length>0"
                :class="{'gc-side-bar__button-primary__selected':(curruntRoute === '/workout-programs/?workout-log=1')}"
                @click="navigate('/workout-programs/?workout-log=1')"
              >
                {{$t('message["workout-log.title"]')}}
            </side-bar-button-primary>

            <div>
              <side-bar-button-collapse v-if="showCollapseButton" class="gc-sidebar__trainer-features-trigger" @click="showTrainerFeatures">
                {{$t('message["general.sidebar-trainer-features"]')}}
              </side-bar-button-collapse>
                <div class="gc-sidebar__trainer-features" :class="trainerFeaturesClass">
                  <div v-for="link in customLinksList"
                      :class="['link_'+link.code]"
                      :key="'link-'+link.code">
                      <side-bar-button-primary
                      v-if="link.type=== 'custom-page-external'"
                      v-bind:class="{'gc-side-bar__button-primary__selected':(curruntRoute === link.url)}"
                      @click="handleUrlNavigate(link.url)">
                        {{link.label}}
                      </side-bar-button-primary>

                      <side-bar-button-primary
                      @click="navigate(link.url)"
                      v-bind:class="{'gc-side-bar__button-primary__selected':(curruntRoute === link.url)}"
                      v-else-if="link.type=== 'system-page'">
                        {{this.curruntRoute}}
                      </side-bar-button-primary>

                      <side-bar-button-primary
                      @click="navigateToPage(link.url)"
                      v-else-if="contentPageTypes.includes(link.type)"
                      v-bind:class="{'gc-side-bar__button-primary__selected':(curruntRoute === link.url)}">
                        {{link.label}}
                      </side-bar-button-primary>
                  </div>
              </div>
            </div>

              <side-bar-button-primary
               @click="navigate('/account-details')"
               v-bind:class="{'gc-side-bar__button-primary__selected':(curruntRoute === '/account-details')}">
                {{$t('message["profile.title"]')}}
              </side-bar-button-primary>

              <side-bar-button-primary
               :href="void(0)"
               @click="logout">
                {{$t('message[\'menu.logout\']')}}
              </side-bar-button-primary>

            <ul class="side-menu-bottom-wrap">
              <li>
                <button-link @click="openTermsAndConditionsPopup()" :text="$t('message[\'settings.terms-cond\']')"></button-link>
              </li>
              <li>
                <button-link @click="openPrivacyPolicyPopup()" :text="$t('message[\'settings.privacy-policy\']')"></button-link>
              </li>
              <li>
                <button-link :text="getTrainerIDInfo() + ' | ' + appVersion"></button-link>
              </li>
            </ul>
          </div>
        </nav>
        <main id="panel"></main>
        <header>
          <div>
            <a class="btn btn-back toggle-button slideout-panel--button"
              v-if="!showingMenu" v-on:click="clicked=true">
              <icon-menu />
            </a>
            <a style="display:none;"
               class="btn btn-back toggle-button slideout-panel--button" v-else v-on:click="clicked=true">
            </a>
          </div>
        </header>
      </Slideout>
    <content-page-popup :page="'privacy-policy'" :visible="showPrivacyPolicyPopup" v-bind:key="'privacy-policy-popup'" v-if="showPrivacyPolicyPopup"
                        v-on:close="closePrivacyPolicyPopup()"></content-page-popup>
    <content-page-popup :page="'legal-disclaimer'" :visible="showTermsCondPopup" v-bind:key="'legal-disclaimer-popup'" v-if="showTermsCondPopup"
                        v-on:close="closeTermsAndConditionsPopup()"></content-page-popup>
  </div>
</template>
<script>

import sideBarMixin from '../../../../../mixins/component-mixins/global/sideBarMixin'
import IconMenu from '../../root/icons/IconMenu'
import SideBarButtonPrimary from './side-bar-sub-components/SideBarButtonPrimary'
import SideBarButtonCollapse from './side-bar-sub-components/SideBarButtonCollapse'
import ButtonLink from '../buttons/ButtonLink'
import ButtonLinkSecondary from '../buttons/ButtonLinkSecondary'
import TextContent from '../../root/TextContent'
import DashboardService from '../../../../../services/DashboardService'

export default {
  name: 'sideBar',
  components: {
    IconMenu,
    SideBarButtonPrimary,
    ButtonLink,
    ButtonLinkSecondary,
    TextContent,
    SideBarButtonCollapse
  },
  data: function () {
    return {
      hideTrainerFeatures: true,
      isContentPage: false,
      charLimitedUserName: this.userName
    }
  },
  mixins: [sideBarMixin],
  beforeMount () {
    this.settingCurruntRoute()
    this.isContentPage = this.curruntRoute.startsWith('/page')
    if (this.isContentPage) {
      this.showTrainerFeatures()
    }
    let date = new Date()
    this.year = date.getFullYear()

    let service = new DashboardService()
    service.getNavigation().then(data => {
      this.navLinksList = data.links
      this.setNavLinks()
    })
    this.showWorkoutLog()
  },
  mounted () {
    this.setUserNameCharLimit()
  },
  computed: {
    trainerFeaturesClass: function () {
      return {
        'gc-sidebar__trainer-features-shown': this.hideTrainerFeatures === false,
        'gc-sidebar__trainer-features-hidden': this.hideTrainerFeatures === true
      }
    },
    showCollapseButton: function () {
      return (this.customLinksList.length > 0)
    }
  },
  methods: {
    showTrainerFeatures () {
      this.hideTrainerFeatures = !this.hideTrainerFeatures
    },
    setUserNameCharLimit () {
      let username = this.userName
      if (username.length > 8) {
        username = username.substring(0, 8)
        this.charLimitedUserName = username + '...!'
      } else {
        this.charLimitedUserName = username + '!'
      }
    }
  }
}

</script>
