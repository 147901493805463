<template>
  <div class="gc-preloader">
    <icon-preloader :size="'lg3'"></icon-preloader>
    <text-body-small>
      {{text}}
    </text-body-small>
  </div>
</template>

<script>
import IconPreloader from '../../root/icons/IconPreloader'
import TextBodySmall from '../typography/TextBodySmall'
export default {
  name: 'PreloaderWithTextSlides',
  props: {
    /**
     * Array containing slide list (texts)
     */
    textSlides: {
      default: []
    },
    /**
     * Interval that should slides changed (in milliseconds)
     */
    interval: {
      default: 1000
    }
  },
  components: {
    IconPreloader,
    TextBodySmall
  },
  data () {
    return {
      text: '',
      textIndex: 0,
      timer: ''
    }
  },
  mounted () {
    this.text = this.textSlides[0]
    this.timer = setInterval(() => {
      if (this.textIndex >= this.textSlides.length) {
        clearInterval(this.timer)
        this.$emit('loaded')
      } else {
        this.text = this.textSlides[this.textIndex]
        this.textIndex++
      }
    }, this.interval)
  }
}
</script>

<style scoped>

</style>
