<template>
  <popup
    v-if="visible"
    :visible="visible"
    modal-class="pc-popup-fd-view-mp-meal"
    @hide="closePopup"
  >
    <popup-title>
      <popup-button-close @hide="closePopup" />
    </popup-title>
    <popup-body class="pc-popup-fd-view-mp-meal__body">
      <popup-head class="text-center">
        <text-body-regular
          class="pc-popup-fd-view-mp-meal__header"
          line-height="lh-lg"
          :weight="'extra-bold'"
        >
          {{ header }}
        </text-body-regular>
        <text-body-small
          class="pc-popup-fd-view-mp-meal__sub-header"
          line-height="lh-lg"
        >
          {{ subHeader }}
        </text-body-small>
      </popup-head>
      <popup-content>
        <card-meal-featured
          class="pc-popup-fd-view-mp-meal__card"
          :macro-nutrients="macroNutrients"
          :calories="calories"
          :images-src="getFirstImages"
        />
        <div class="pc-popup-fd-view-mp-meal__info">
          <div class="pc-popup-fd-view-mp-meal__recipes">
            <loading-buffer
              v-if="recipes.length===0"
              height="50"
            />
            <accordion-recipe
              v-for="recipe in recipes"
              :key="'recipe'+recipe.getId()"
              class="pc-popup-fd-view-mp-meal__recipe"
              :recipe="recipe"
            />
          </div>
        </div>
      </popup-content>
    </popup-body>
  </popup>
</template>

<script>
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import Popup from '../../../../global/popups/Popup'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupTextBody from '../../../../global/popups/popup-sub-components/PopupTextBody'
import PopupTextSubHeading from '../../../../global/popups/popup-sub-components/PopupTextSubHeading'
import NutritionService from '../../../../../services/nutrition/NutritionService'
import CardMealFeatured from '../../../../global/cards/CardMealFeatured'
import MealPlanMealRecipeService from '../../../../../services/nutrition/MealPlanMealRecipeService'
import AccordionRecipe from '../../meal-plan/AccordionRecipe'
import TextBodyRegular from '../../../../global/typography/TextBodyRegular'
import TextBodySmall from '../../../../global/typography/TextBodySmall'

export default {
  name: 'PopupFoodDairyViewMealPlanMeal',
  components: {
    TextBodySmall,
    TextBodyRegular,
    AccordionRecipe,
    CardMealFeatured,
    PopupTextSubHeading,
    PopupTextBody,
    PopupContent,
    PopupTextHeading,
    PopupHead,
    PopupBody,
    PopupButtonClose,
    PopupTitle,
    Popup,
    LoadingBuffer
  },
  mixins: [popupAnimationMixin],
  props: {
    header: {
      default: String
    },
    subHeader: {
      default: String
    },
    visible: {default: false},
    meal: {
      required: true
    }
  },

  data: function () {
    return {
      state: 'init',
      detailedMeal: null,
      recipes: []
    }
  },
  computed: {
    getFirstImages () {
      return this.meal.recipes.map((recipe) => recipe.images[0]).slice(0, 3)
    },
    macroNutrients () {
      const macroNutrients = []
      // to insert data to nutrition array
      const Protein = this.meal.macronutrients.proteins
      macroNutrients.push({
        unit: this.$i18n.t('message[\'general.g\']'),
        name: this.$i18n.t('message[\'general.proteins\']'),
        qut: parseInt(Protein)
      })
      const fat = this.meal.macronutrients.fats
      macroNutrients.push({
        unit: this.$i18n.t('message[\'general.g\']'),
        name: this.$i18n.t('message[\'general.fats\']'),
        qut: parseInt(fat)
      })
      const carbs = this.meal.macronutrients.carbohydrates
      macroNutrients.push({
        unit: this.$i18n.t('message[\'general.g\']'),
        name: this.$i18n.t('message[\'general.carbs\']'),
        qut: parseInt(carbs)
      })
      if (this.meal.macronutrients.show_net_carbs === true) {
        const netCarb = this.meal.macronutrients.net_carbohydrates
        macroNutrients.push({
          unit: this.$i18n.t('message[\'general.g\']'),
          name: this.$i18n.t('message[\'general.carbs-net\']'),
          qut: parseInt(netCarb)
        })
      }
      return macroNutrients
    },
    calories () {
      // to insert data to nutrition array
      const calories = this.meal.calories
      return [{
        unit: '',
        name: this.$i18n.tc('message[\'general.calories\']', calories),
        qut: parseInt(calories)
      } ]
    }
  },
  watch: {
    detailedMeal: {
      immediate: true,
      deep: true,
      handler: function () {
        if (!this.detailedMeal) {
          return
        }
        this.recipes = this.meal.recipes.map(recipeObj => {
          const mealPlanRecipe = new MealPlanMealRecipeService(recipeObj, 0)
          mealPlanRecipe._isLoaded = true

          const recipe = this.detailedMeal.getRecipe(mealPlanRecipe.getReferenceId())
          recipe.getIngredients()
          let ingredients = recipe.getIngredients().map(data => {
            const sIng = recipeObj.ingredients.find(ing => ing.reference_ingredient_id === data.id) || null
            if (!sIng) {
              return sIng
            }
            if (data.substitutions && data.substitutions.length > 0) {
              sIng.substitutions = data.substitutions.map(sub => {
                return recipeObj.ingredients.find(ing => ing.reference_ingredient_id === sub.id)
              }) || []
            } else {
              sIng.substitutions = []
            }
            return sIng
          })
          ingredients = ingredients.filter(ing => !!ing)
          mealPlanRecipe.setIngredients(ingredients)
          mealPlanRecipe.setDescription(recipe.getDescription())
          mealPlanRecipe.setVideos(recipe.getVideos())
          mealPlanRecipe.setInstructions({steps: recipe.getInstructions()})

          return mealPlanRecipe
        })
      }
    }
  },
  beforeMount () {
    this.load()
  },
  methods: {
    async load () {
      const mealRefId = this.meal.reference_meal_id
      const nutritionService = new NutritionService()
      let meal = nutritionService.getGenericMeal(mealRefId)
      if (!meal) {
        meal = nutritionService.createGenericMeal(mealRefId)
      }
      if (!meal.isLoaded()) {
        await meal.load()
        this.detailedMeal = meal
      } else {
        this.detailedMeal = meal
      }
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    }

  }
}
</script>
