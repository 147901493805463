<template>
    <div :class="componentClasses"  @click="navigateNext()">
      <div class="gc-card-meal__slider-top">
        <div class="gc-card-meal__header" v-if="showTexts"><text-content :weight="'extra-bold'" :size="'lg1'" :lineHeight="'multi'">{{ header }}</text-content></div>
        <div class="gc-card-meal__sub-header" v-if="showTexts"><text-content :weight="'medium'" :size="'sm1'" :lineHeight="'multi'">{{ description }}</text-content></div>

      </div>
      <div class="gc-card-meal__slider-bottom">
        <div class="gc-card-meal__button" v-if="showTexts"><text-content :weight="'extra-bold'" :size="'sm4'" :lineHeight="'multi'">{{$t('message["meal-plan.slider-card-start"]')}} > </text-content></div>
      </div>

    </div>
</template>
<script>
import TextContent from '../../../root/TextContent'
export default {
  name: 'CardMealSlider',
  components: {
    TextContent
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    header: {
      type: String,
      default: ''
    },

    description: {
      type: String,
      default: ''
    }
  },
  computed: {
    componentClasses () {
      return {
        'gc-card-meal__slider': true
      }
    }
  },
  data: function () {
    return {
      showTexts: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.showTexts = true
    })
  },
  methods: {
    navigateNext () {
      this.$emit('navigate', this.index)
    }
  }
}
</script>
