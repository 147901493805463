<template>
<div :class="componentClasses" @click="clicked()">
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.57129 3.30005V5.36255H20.2713V3.30005H1.57129Z" fill="black"/>
    <path d="M1.57129 10.5188V12.5813H20.2713V10.5188H1.57129Z" fill="black"/>
    <path d="M1.57129 19.8H20.2713V17.7375H1.57129V19.8Z" fill="black"/>
  </svg>
</div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconHamburgerFilled',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-hamburger-filled': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
