<template>
  <button-base :borderRadius="'sm1'" :class="componentClasses" :type="'outline'" :submitType="setButtonSubmitType" @click="clicked()">
    <div class="gc-button-secondary-accordion__left-section">
      <slot class="gc-button-secondary-accordion__left-section-icon"></slot>
      <div class="gc-button-secondary-accordion__text-section">
        <text-body-regular :class="textClass" :line-height="'multi'" :size="'sm4'" :weight="'extra-bold'"> {{ text }}</text-body-regular>
        <text-body-extra-small :class="subTextClass" :line-height="'multi'" :size="'sm1'" v-if="hasSubText"> {{ subText }}</text-body-extra-small>
      </div>
    </div>
    <div class="gc-button-secondary-accordion__right-section">
      <icon-forward v-if="showArrow"></icon-forward>
    </div>
  </button-base>
</template>

<script>

import ButtonBase from '../../root/ButtonBase'
import IconForward from '../../root/icons/IconForward'
import TextBodyRegular from '../typography/TextBodyRegular'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'

export default {
  name: 'ButtonSecondaryAccordion',
  components: {
    TextBodyExtraSmall,
    TextBodyRegular,
    ButtonBase,
    IconForward
  },
  props: {
    /**
     * The main text of the button
     */
    text: {
      default: ''
    },
    /**
     * The sub text of the button
     */
    subText: {
      default: ''
    },
    /**
     * The disabled state of the button
     */
    disabled: {
      default: false
    },
    selected: {
      default: false
    },
    isSubmit: {
      default: false
    },
    showArrow: {
      default: true
    }
  },
  data () {
    return {
      stateSelected: this.selected
    }
  },
  computed: {
    setButtonSubmitType: function () {
      if (this.isSubmit) {
        return ''
      } else {
        return 'button'
      }
    },
    hasSubText: function () {
      return this.subText.replace(/\s/g, '') !== ''
    },
    hasIcon () {
      return !!this.$slots.default
    },
    textClass: function () {
      return {
        'gc-button-secondary-accordion__text': true,
        'gc-button-secondary-accordion__text--with-sub-text': this.hasSubText
      }
    },
    subTextClass: function () {
      return {
        'gc-button-secondary-accordion__sub-text': this.hasSubText
      }
    },
    componentClasses: function () {
      return {
        'gc-button-secondary-accordion': true,
        'gc-button-secondary-accordion--with-sub-text': this.hasSubText,
        'gc-button-secondary-accordion--disabled': this.disabled,
        'gc-button-secondary-accordion--selected': this.stateSelected
      }
    }
  },
  methods: {
    /**
     * function to emit the click event
     */
    clicked () {
      if (!this.disabled) {
        this.$emit('click')
        if (!this.stateSelected) {
          this.stateSelected = true
        } else {
          this.stateSelected = false
        }
      }
    }
  }
}
</script>

<style scoped></style>
