<template>
    <div>
      <input-select v-if="options && options.length > 0" :items="options" :name="name" :value="formData.value"
                    :classes="'form-control ma-form__input text-left'" :placeholder="placeHolder"
                    @input="formData.value = $event, onChangeOption($event)"/>

      <text-input v-else type="text" :name="name" v-model="formData.value"
             v-bind:class="{'ma-form__input--error': invalid&&validated }"
             :placeholder="placeHolder" @change="onChangeInput" />

    </div>
</template>

<script>
import InputSelect from './InputSelect'
import TextInput from './TextInput'
export default {
  name: 'InputSelectOrInputText',
  props: ['input', 'placeHolder', 'name', 'value', 'options', 'invalid', 'validated'],
  data: function () {
    return {
      formData: {
        type: '',
        value: ''
      }
    }
  },
  components: {
    InputSelect,
    TextInput
  },
  watch: {
    value: {
      handler () {
        this.setData()
      },
      deep: true
    },
    options: {
      handler () {
        this.setType()
      },
      deep: true
    }
  },
  mounted () {
    this.setType()
    this.setData()
  },
  methods: {
    setData () {
      if (this.value) {
        this.formData.value = this.value.value || ''
      }
    },
    setType () {
      this.formData.type = (Array.isArray(this.options) && this.options.length > 0) ? 'id' : 'name'
    },
    onChangeOption () {
      this.$emit('input', this.formData)
    },
    onChangeInput () {
      this.$emit('input', this.formData)
    }
  }
}
</script>

<style scoped>

</style>
