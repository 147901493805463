/**
 * Base API Class that can be use to connect the apis
 */
import DefineInclude from '../../DefineInclude'
import AuthService from '../AuthService'
import axios from 'axios'

export default class CommunityAPI {
  _baseURL = ''
  auth = null
  constructor () {
    this._baseURL = DefineInclude.communityAPIUrl
    this._token = ''
    this.auth = new AuthService()
  }
  _getHeaders () {
    return {
      'X-App-Type': 'members-area'
    }
  }
  getFeeds () {
    let url = this._baseURL + 'feeds'
    return axios.get(url, {headers: this._getHeaders()})
  }
  initUser (params) {
    let url = this._baseURL + 'users/init'
    return axios.post(url, params, {headers: this._getHeaders()})
  }
  getMeUser () {
    let url = this._baseURL + 'users/me'
    return axios.get(url, {headers: this._getHeaders()})
  }
  getNotifications (limit, offset) {
    let url = this._baseURL + 'notification-feed'
    return axios.get(url, {headers: this._getHeaders(), params: {limit: limit, offset: offset}})
  }

  setNotificationRead (id) {
    let url = this._baseURL + 'notification-feed/read'
    return axios.post(url, {'notificationId': id}, {headers: this._getHeaders()})
  }

  setAllNotificationsSeen () {
    let url = this._baseURL + 'notification-feed/seen/all'
    return axios.post(url, {}, {headers: this._getHeaders()})
  }

  setAllNotificationsRead () {
    let url = this._baseURL + 'notification-feed/read/all'
    return axios.post(url, {}, {headers: this._getHeaders()})
  }
}
