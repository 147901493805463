<template>
  <div :class="componentClasses" @click="clicked()">
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.4963 13.7376L8.93597 24.4869C8.26421 25.171 7.17506 25.171 6.50362 24.4869C5.83213 23.8034 5.83213 22.6948 6.50362 22.0113L15.8479 12.4998L6.50389 2.98869C5.8324 2.30489 5.8324 1.19638 6.50389 0.512853C7.17538 -0.170951 8.26448 -0.170951 8.93624 0.512853L19.4966 11.2623C19.8323 11.6042 20 12.0519 20 12.4998C20 12.9479 19.832 13.3959 19.4963 13.7376Z" fill="#9E9E9E"/>
  </svg>

  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconForward',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-forward': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
