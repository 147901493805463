import BaseAPI from '../BaseAPI.js'
import axios from 'axios'
import DefineInclude from '../../../DefineInclude'

export default class extends BaseAPI {
  _resource = 'diary'

  getSummary () {
    const url = this._baseURL
    const pathArr = [this._resource, 'summary']
    return axios.get(url + this._getPath(pathArr))
  }

  getPhotosHistory (params) {
    const url = this._baseURL
    const pathArr = [this._resource, 'photos', 'history']
    return axios.get(url + this._getPath(pathArr), { params: params })
  }

  setPhoto (formData) {
    const url = this._baseURL
    const pathArr = [this._resource, 'photos']

    const headers = this._headers
    headers['Content-Type'] = 'multipart/form-data'
    return axios.post(url + this._getPath(pathArr), formData,{headers:headers})
  }

  removePhoto (id) {
    const url = this._baseURL
    const pathArr = [this._resource, 'photos', id]
    return axios.delete(url + this._getPath(pathArr))
  }

  getEntriesHistory (params) {
    const url = this._baseURL
    const pathArr = [this._resource, 'entries', 'history']
    return axios.get(url + this._getPath(pathArr), { params: params })
  }

  setEntry (json) {
    const url = this._baseURL
    const pathArr = [this._resource, 'entries']
    return axios.post(url + this._getPath(pathArr), json)
  }

  sendPhotoUploadedEmail (payload) {
    const url = DefineInclude.shoppingListEmailAPIurl
    const headers = this._headers
    headers.Authorization = 'Bearer ' + this._token
    headers['X-Api-Host'] = DefineInclude.apiUrl.replace(/\/$/, '')
    const pathArr = ['fitness-dairy', 'photo', 'upload', 'email']
    return axios.post(url + this._getPath(pathArr), payload, { headers: headers })
  }
}
