<template>
  <div class="gc-image-upload-card" ref="element">
    <image-uploader
      v-if="state === 'init' || state === 'error'|| state === 'error'"
      :autoRotate=false
      :id="imageName"
      :maxWidth="2000"
      :preview=false
      :debug="1"
      :quality="0.9"
      @input="setUploadFile($event)"
      @onComplete="onComplete"
      accept="image/*"
      doNotResize="heic"
      class="gc-image-upload-card__init"
      :outputFormat="(isIOS)?'file':'blob'"
      ref="image-uploader"
    >
      <label :for="imageName" slot="upload-label">
        <img :src="imageObject.getTodayImage()" alt="" class="gc-image-upload-card__now-uploading-image"
             v-if="showTodayImage&&imageObject.getTodayImage()" v-on:error="hideTodayImage()"
        />
        <div class="gc-image-upload-card__no-image" v-if="!(showTodayImage&&imageObject.getTodayImage())"
             :class="{'has_image':(showTodayImage&&imageObject.getTodayImage()),'no_image':!(showTodayImage&&imageObject.getTodayImage())}"
        >
          <icon-camera :size="'sm4'"/>
        </div>
      </label>
    </image-uploader>
    <div class="gc-image-upload-card__uploading" v-if="state === 'uploading'">
      <div class="gc-image-upload-card__has_image">
        <loading-buffer :height="50"/>
      </div>
      <img :src="uploadedImage" alt="" class="gc-image-upload-card__uploading-image"/>
    </div>
    <div
      class="gc-image-upload-card__uploaded gc-image-upload-card--with-close"
      v-if="state === 'uploaded'||state === 'to-upload'"
    >
      <div v-if="state!=='uploaded'" v-on:click="removeImage()">
        <icon-close :size="'sm1'"/>
      </div>
      <div class="gc-image-upload-card__has_image">
        <img class="gc-image-upload-card__image" :src="uploadedImage" alt=""/>
      </div>

    </div>
  </div>
</template>
<script>
import ImageUploader from 'vue-image-upload-resize'
import IconCamera from '../../root/icons/IconCamera'
import IconClose from '../../root/icons/IconClose'
import { isIOS } from 'mobile-device-detect'
import { isMobileNativeApp } from '@/includes/NativeAppCommon'
import Compressor from 'compressorjs'
import LoadingBuffer from '@/components/layout/template-1/global/widgets/LoadingBuffer'
import * as Sentry from '@sentry/browser'

export default {
  name: 'fitnessImageImageUpload',
  components: {
    LoadingBuffer,
    IconClose,
    IconCamera,
    ImageUploader
  },
  props: {
    imageName: {
      required: true
    },
    readyToUpload: {
      default: false
    },
    isPrivate: {
      default: 1
    },
    imageObject: {
      required: true
    }
  },
  data: function () {
    return {
      isIOS: false,
      showTodayImage: true,
      state: 'init',
      imageError: '',
      uploadedImage: '',
      initUploadedImage: '',
      formData: new FormData()
    }
  },
  watch: {
    readyToUpload: function (newVal) {
      if (this.state === 'to-upload' && newVal) {
        this.uploadImage()
      }
    }
  },
  created () {
    this.isIOS = isIOS

  },
  mounted () {
    this.hideTodayImage()
  },
  methods: {
    setFile (e) {
      this.setUploadFile(e.target.files[0])
    },
    clickedCard () {
      this.$refs.inputFile.click()
    },
    hideTodayImage () {
      this.showTodayImage = false
    },
    triggerImageUploadClick () {
      this.$refs['image-uploader'].$el.click()
    },
    onComplete (e) {
      if (e && e.type === 'error' && this.state !== 'error') {
        this.state = 'error'
        const langField = this.$i18n.t('message["fitness-diary.' + this.imageObject.type + '"]')
        const message = this.$i18n.t('message.validation.messages.mimes').replace('{_field_}', langField)
        this.$emit('invalid-upload', message)
      }
    },
    setUploadFile (file) {
      if (!(file instanceof File)) {
        return this.setUploadData(file)
      }
      return new Compressor(file, {
        quality: 0.9,
        maxWidth: 2000,
        maxHeight: 2000,
        success: (result) => {

          if (result instanceof Blob) {
            const newFile = new File([result], file.name, {
              type: file.type,
            })
            console.log('save blob')
            this.setUploadData(newFile)
          } else {
            this.setUploadData(result)
          }

        },
        error: (err) => {
          try {
            Sentry.captureMessage('Image Compressor Error', err.message)
            Sentry.captureException(err)
          } catch (e) {
            //ignore
          }
          this.setUploadData(file)
        },
      })
    },
    setUploadData (fileList) {
      try {
        console.log(fileList)
        console.log(fileList.size)
        console.log(fileList.type)
      } catch (e) {
        // no need to log
      }

      this.showPreloaderForWhile()
      const formData = new FormData()

      const reader = new FileReader()

      reader.onload = (e) => {
        this.uploadedImage = e.target.result
      }
      const _URL = window.URL || window.webkitURL
      let img = new Image()
      const objectUrl = _URL.createObjectURL(fileList)
      img.onload = () => {
        let minWidth = 350
        if (img.width >= minWidth) {
          this.state = 'to-upload'
          this.$emit('to-upload')
        } else {
          this.state = 'error'
          let langField = this.$i18n.t('message["fitness-diary.' + this.imageObject.type + '"]')
          let message = this.$i18n.t('message["validation.dimensions_width"]').replace('{_field_}', langField).replace('{width}', minWidth)
          this.$emit('invalid-upload', message)
        }
        _URL.revokeObjectURL(objectUrl)
      }
      img.onerror = (err) => {
        alert('Sorry, we are unable to preview your image. But you can continue uploading.')
        this.state = 'to-upload'
        this.$emit('to-upload')
        try {
          Sentry.captureMessage('Image Preview Error')
          Sentry.captureException(err)
        } catch (e) {

        }
      }
      img.src = objectUrl

      formData.append(this.imageName, fileList, this.imageName)
      this.formData.append('image', fileList, 'image')
      this.formData.append('type', this.imageName.replace('_image', ''))
      reader.readAsDataURL(fileList)
    },
    showPreloaderForWhile () {
      if (!isMobileNativeApp() || isIOS) {
        return
      }
      document.querySelectorAll('.gc-image-upload-card').forEach(ele => {
        ele.style.pointerEvents = 'none'
        ele.style.filter = 'blur(8px)'
        this.$emit('disable-submit', {})
        setTimeout(() => {
          ele.style.pointerEvents = 'auto'
          ele.style.filter = 'blur(0)'
          this.$emit('enable-submit', {})
        }, 2000)
      })
    },
    uploadImage () {
      if (!navigator.onLine) {
        this.state = 'to-upload'
        this.$emit('error-upload', this.$i18n.t('message["general.no-internet-connection"]'))
        return
      }
      this.formData.append('private', this.isPrivate)
      this.state = 'uploading'
      this.imageObject
        .uploadImage(this.formData)
        .then((data) => {
          console.log(data)
          this.state = 'uploaded'
          this.imageObject.currentImageID = data.data[this.imageName + '_id']
          this.$emit('uploaded')
        })
        .catch((err) => {
          console.log(err)
          try {
            Sentry.captureMessage('Image uploaded Error')
          } catch (e) {
            //ignore
          }
          this.state = 'to-upload'
          if (err.response.status === 413) {
            this.imageError = this.$i18n.t('message["fitness-diary.error-upload"]')
          } else if (err.response.status === 422 && err.response.data.type === 'form') {
            this.imageError = err.response.data.errors.image[0] || err.response.data.errors[0] || this.$i18n.t('message["fitness-diary.error-upload"]')
          } else if (err.response.data.message) {
            this.imageError = err.response.data.message
          } else {
            this.imageError = err.response.data.error || this.$i18n.t('message["fitness-diary.error-upload"]')
          }
          this.error = 'error'
          if (!navigator.onLine) {
            this.$emit('error-upload', this.$i18n.t('message["general.no-internet-connection"]'))
          }
          this.$emit('error-upload', this.imageError)
        })
        .finally(() => {
          this.hideLoading()
        })
    },
    removeImage () {
      this.$emit('remove-upload')
      this.state = 'init'
    }
  }
}
</script>
