<template>
  <div ref="title" class="gc-popup__title" id="gc-popup-title">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PopupTitle'
}
</script>

<style scoped>

</style>
