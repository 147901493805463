<template>
  <!--   page background -->
  <page class="pc-progress-thank-you" :desktop-side-bar="false" :header="headerOptions" :background-image="backgroundImage">
    <!--   page wrapper -->
    <page-container class="pc-progress-thank-you__container">
      <div class="pc-progress-thank-you__card-feature-box">
        <text-heading4
          :lineHeight="'multi'"
          :weight="'extra-bold'"
          class="pc-progress-thank-you__card-feature-box__title"
          v-if="hasGreetingText()"
          v-html="greetingText"/>
        <text-body-regular
          :lineHeight="'multi'"
          class="pc-progress-thank-you__card-feature-box__sub-text"
          v-html="descriptionText"/>
        <button-primary
          @click="completeSubmit"
          :text="$t('message[\'progress.end-button\']')"/>
      </div>

    </page-container>
    <div class="app-bg-overlay"></div>
  </page>
</template>
<script>
import BaseComponent from '../global/widgets/forms/FormBaseComponent'
import { mapGetters, mapMutations } from 'vuex'
import PopupDataInvalid from '../../../global/popups/PopupDataInvalid'
import Page from '../../../global/pages/PageImageCover'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import ButtonPrimary from '../../../global/buttons/ButtonPrimary'
import TextHeading2 from '../../../global/typography/TextHeading2'
import TextBodyRegular from '../../../global/typography/TextBodyRegular'
import TextHeading4 from '../../../global/typography/TextHeading4'
import DashboardService from '../../../../services/DashboardService'

export default {
  extends: BaseComponent,
  name: 'Finished',
  components: {
    TextHeading4,
    PopupDataInvalid,
    TextBodyRegular,
    TextHeading2,
    ButtonPrimary,
    PageContainer,
    Page
  },
  data: function () {
    return {
      description: this.$i18n.t("message['form-helper-text.success']"),
      hasError: false,
      backgroundImage: '',
      pageClass: ['macroapp--page-start'],
      errors: [],
      headerOptions: {
        show: true,
        left: '',
        right: '',
        modifiers: ['with-img'],
        mainTitle: '',
        subTitle: '',
        isTransparent: true
      }
    }
  },
  computed: {
    descriptionText: function () {
      if(!this.getService() || !this.getService().plan){
        return this.$i18n.t('message[\'progress.end-description\']')
      }
      if (this.getService().plan.follow === 'yes') {
        return this.$i18n.t('message[\'progress.end.followed-plan-no-result-description-one\']') + '<br>' + this.$i18n.t('message[\'progress.end.followed-plan-no-result-description-two\']')
      } else if (this.getService().plan.follow === 'no') {
        return this.$i18n.t('message[\'progress.end.not-followed-plan-description-one\']') + '<br>' + this.$i18n.t('message[\'progress.end.not-followed-plan-description-two\']')
      }
      return this.$i18n.t('message[\'progress.end-description\']')
    },
    greetingText: function () {
      if (this.getService().plan.follow === 'yes') {
        return this.$i18n.t('message[\'progress.end.followed-plan-no-result-title\']', {name: this.userName})
      } else if (this.getService().plan.follow === 'no') {
        return this.$i18n.t('message[\'progress.end.not-followed-plan-title\']', {name: this.userName})
      }
      return this.$i18n.t('message[\'progress.end-title\']', {name: this.userName})
    }
  },
  watch: {
    isDesktop: function () {
      this.headerOptions.show = !this.isDesktop
    }
  },
  beforeMount () {
    this.headerOptions.show = !this.isDesktop
  },
  mounted () {
    this.backgroundImage = this.getStoreImage('progressCheckIn.finish')
    const service = new DashboardService()
    service.getUserData().then(data => {
      this.userName = data.first_name
    }).finally(() => {
      this.hideLoading()
    })
  },
  methods: {
    ...mapGetters({
      getService: 'formWizardStore/getProgressFormService',
      fitnessDiaryService: 'fitnessDiaryStore/getService'
    }),
    ...mapMutations({
      setService: 'formWizardStore/setProgressFormService',
      resetService: 'formWizardStore/reset',
      setFormStatus: 'formWizardStore/setFormStatus'
    }),
    hasGreetingText () {
      let html = this.greetingText
      let div = document.createElement('div')
      div.innerHTML = html
      let text = div.textContent || div.innerText || ''
      return Boolean(text)
    },
    completeSubmit () {
      this.fitnessDiaryService().getDataFromAPI(true)
      this.resetService()
      this.$emit('complete')
      this.$router.push({
        path: this.$appConfig.appUrlList.dashboard
      })
    }
  }
}
</script>
