import { Howl, Howler } from 'howler'

export default {

  props: {
    autoStart: {
      default: false
    },
    color: {
      default: 'black'
    },
    name: {
      default: ''
    },
    time: {
      default: 0
    },
    showRest: {
      default: false
    }
  },
  created () {
    this.show = true
    this.play = this.autoStart
    if (typeof this.color !== 'undefined' && this.color === 'white') {
      this.imagePlay = this.imagePath + 'btn-play.svg'
      this.imagePause = this.imagePath + 'btn-pause.svg'
      this.imageRestart = this.imagePath + 'restart-white.svg'
    } else {
      this.imagePlay = this.imagePath + 'btn-play-dark.svg'
      this.imagePause = this.imagePath + 'btn-pause-dark.svg'
      this.imageRestart = this.imagePath + 'restart-dark.svg'
    }
  },
  mounted () {
    if ($('.app-background-color').length > 0) {
      const background = window.getComputedStyle($('.app-background-color')[0])
      if (!this.lightOrDark(background.getPropertyValue('background-color'))) {
        $('.timer__btn-lg').css('filter', 'invert(1)')
      } else {
        $('.timer__btn-lg').css('filter', 'invert(0)')
      }
    }
  },
  watch: {
    autoStart: function (value) {
      this.playWorkoutCountdown()
    },
    play (newVal, oldVal) {
      if (newVal === true && this.state === 'pending') {
        this.state = 'started'
      } else if (newVal === false) {
        this.state = 'paused'
      } else if (newVal === true && this.state === 'paused') {
        this.state = 'started'
      }
    },
    state (newVal, oldVal) {
      if (newVal === 'started' && oldVal === 'pending') {
        this.playStartTimer()
      }
    }
  },
  data: function () {
    return {
      sounds: {
        started: null,
        ended: null
      },
      countdownProgressData: {},
      play: false,
      state: 'pending', // pending,started,paused,stopped
      show: false,
      imagePath: this.$appConfig.imagesUrl + 'timer/',
      imagePlay: '',
      imagePause: '',
      imageRestart: ''
    }
  },
  methods: {
    setVolume () {
      if (templateConfig.instance_config &&
        'workout_timer_vol' in templateConfig.instance_config) {
        let vol = parseInt(templateConfig.instance_config.workout_timer_vol) || 0
        vol = vol / 10
        Howler.volume(vol)
      }
    },
    playStartTimer () {
      this.sounds.started = new Howl({
        src: ['https://app-redesign-plugin-files.s3.us-west-1.amazonaws.com/sounds/timer/timer-start.mp3'],
        volume: 0.5
      })
      this.setVolume()
      this.sounds.started.play()
    },
    playEndTimer () {
      this.sounds.ended = new Howl({
        src: ['https://app-redesign-plugin-files.s3.us-west-1.amazonaws.com/sounds/timer/timer-end.mp3'],
        volume: 0.5
      })
      this.setVolume()
      this.sounds.ended.play()
    },
    countdownProgress (data) {
      this.countdownProgressData = data
      this.$emit('remaining', { remaining: this.countdownProgressData })
    },
    setTimerNumbers (num) {
      num = parseInt(num)
      return num < 10 ? '0' + num : num
    },
    playWorkoutCountdown () {
      if (this.play && this.state === 'pending') {
        this.state = 'started'
        if (this.$refs.countdownWorkout) {
          this.$refs.countdownWorkout.start()
          this.$emit('start')
        }

        this.play = true
        return
      } else if (this.play) {
        this.$refs.countdownWorkout.abort()
        this.$emit('pause', { remaining: this.countdownProgressData })
      } else {
        if (this.$refs.countdownWorkout) {
          this.$refs.countdownWorkout.start()
          this.$emit('start')
        }
      }
      this.play = !this.play
    },
    restartCountdown () {
      this.show = false
      this.$nextTick(() => {
        this.show = true
        this.$nextTick(() => {
          this.play = true
          this.state = 'pending'
          this.playWorkoutCountdown()
        })
      })
    },
    showRestCountdown () {
      this.$emit('show-rest', true)
      this.stop()
    },
    stop () {
      this.$emit('stopped', { remaining: this.countdownProgressData })
      this.workoutEnded()
    },
    workoutEnded () {
      this.playEndTimer()
      this.show = false
      setTimeout(() => {
        this.show = true
        this.play = false
        this.state = 'pending'
      }, 5)
      this.$emit('workout-end', true)
    },
    headerTime (min, sec) {
      if (min === 0) {
        return this.setTimerNumbers(sec)
      } else if (sec === 0) {
        return this.setTimerNumbers(min)
      }
      return this.setTimerNumbers(min) + ':' + this.setTimerNumbers(sec)
    },
    headerTimeType (min, sec) {
      if (min === 0) {
        return this.$i18n.t('message["general.time-seconds-short"]')
      } else {
        return this.$i18n.t('message["general.time-minutes-short"]')
      }
    }
  }
}
