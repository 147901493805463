import BaseComponent from '../components/layout/template-1/global/base/BaseComponent'
import { mapGetters, mapMutations } from 'vuex'

/**
 * must have page as a computed or data of the component
 */
export default {

  extends: BaseComponent,
  computed: {
    /**
     * map the store function with the component
     */
    ...mapGetters({
      /**
       * to set the page Main  Class (with the 'macroapp' class )
       */
      pageClasses: 'pageStore/pageClasses',
      /**
       * to set the navigation Classes
       */
      navClasses: 'pageStore/navClasses',
      /**
       * to set the header props
       */
      headerOptions: 'pageStore/header',
      /**
       * to set the page background
       */
      background: 'pageStore/background'
    }),
    /**
     * to set the dynamic component
     * @returns {*}
     */
    dynamicComponent: function () {
      return this.getComponentByConfig(this.page)
    },
    /**
     * to set the dynamic component configuration as `pageConfig` prop
     * @returns {*}
     */
    dynamicComponentConfig: function () {
      return this.getPageConfigByName(this.page)
    }
  },
  updated () {
    this.setPageName(this.page)
  },
  methods: {
    ...mapMutations({
      setPageName: 'pageStore/setPageName',
      unsetPageName: 'pageStore/unsetPageName'
    }),
    /**
     * common method to get the variation of page by the page name
     * @param pageName
     * @returns {*}
     */
    getComponentByConfig (pageName) {
      const pageConfig = this.dynamicComponentConfig
      const pageComponents = this.components[pageName]
      if (pageConfig && pageConfig.variation) {
        return pageComponents[pageConfig.variation] || pageComponents.design1
      } else {
        return pageComponents.design1
      }
    }
  },

  destroyed () {
    this.unsetPageName()
  }
}
