<template>
    <page :header="header" class="gc-page-activity-level">
        <validation-observer ref="form" v-slot="{handleSubmit, invalid}">
          <page-container>
            <div class="gc-page-activity-level__header">
                <text-heading-4
                    class="gc-page-activity-level__title text-center"
                    weight="extra-bold"
                    lineHeight="multi">{{title.main}}
                </text-heading-4>
            </div>
              <div class="gc-page-activity-level__content">
                  <form id="form-input-activity-level"
                      class="gc-page-activity-level__form"
                      @submit.prevent="handleSubmit(nextComponent)">
                          <validation-provider v-slot="{ errors }" rules="required|excluded:0" vid="activityLevel" :name="title.main">
                              <button-choice
                                  type="button"
                                  class="gc-page-activity-level__input"
                                  v-for="option in options"
                                  :key="option.key"
                                  :text="option.value"
                                  v-model="userInputValue"
                                  @click="setUserInputValue(option.key)"
                                  :selected="(userInputValue===option.key)"></button-choice>
                                  <text-body-extra-small class="gc-page-progress-form__input-error activity-level-error-msg text-center"  lineHeight="multi">{{errors[0]}}</text-body-extra-small>
                          </validation-provider>
                  </form>
              </div>
          </page-container>
          <page-footer :form="'form-input-activity-level'" :hideReturnButton="true" :validated="invalid"></page-footer>
        </validation-observer>
    </page>
</template>

<script>
import FormBaseComponent from '../../layout/template-1/global/widgets/forms/FormBaseComponent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ButtonPrimary from '../buttons/ButtonPrimary'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import TextHeading4 from '../typography/TextHeading4'
import PageContainer from './page-sub-components/PageContainer'
import Page from './PageForm'
import ButtonChoice from '../buttons/ButtonChoice'
import PageFooter from './page-sub-components/PageFormFooter'
import IconInfo from '../../root/icons/IconInfo'

export default {
  name: 'PageConfirmation',
  extends: FormBaseComponent,
  components: {
    Page,
    PageContainer,
    TextHeading4,
    ButtonPrimary,
    TextBodyExtraSmall,
    ValidationObserver,
    ValidationProvider,
    ButtonChoice,
    PageFooter,
    IconInfo
  },
  data: function () {
    return {
      header: {
        isTransparent: false,
        isFixed: false,
        show: false,
        left: '',
        right: '',
        rightBlink: false,
        modifiers: [],
        mainTitle: '',
        subTitle: ''
      },
      userInputValue: ''

    }
  },
  mounted () {
    let selectedOption = this.options.find(option => option.value === this.inputValue)
    if (selectedOption) {
      this.userInputValue = selectedOption.key
    }

    this.hideLoading()
  },
  methods: {

    setUserInputValue (selectedActivityLevel) {
      this.userInputValue = selectedActivityLevel
    },
    nextComponent () {
      const inputObj = { key: this.inputKey, value: this.userInputValue }
      this.$emit('submit', inputObj)
    }
  }
}
</script>
