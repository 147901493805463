<template>
  <popup
    :visible="popupVisibility"
    @hide="closePopup"
    modal-class="gc-popup-exercise-reps"
    ref="modal"
    v-if="visible"
  >
    <popup-title>
      <popup-button-close @hide="closePopup($event)"/>
    </popup-title>
    <popup-body class="gc-popup-exercise-reps__body">
      <popup-head>
        <popup-text-heading class="gc-popup-exercise-reps__heading">
          {{$t('message["exercise.reps-popup-title"]')}}
        </popup-text-heading>
      </popup-head>
      <popup-content>
        <popup-text-body class="gc-popup-exercise-reps__description">
          {{$t('message["exercise.reps-popup-description"]')}}
        </popup-text-body>
        <popup-text-body class="gc-popup-exercise-reps__default-reps">
          <span class="gc-popup-exercise-reps__default-reps-heading">{{$t('message["exercise.reps-popup-default-reps-title"]')}} - </span>{{$t('message["exercise.reps-popup-default-reps-description"]')}}
        </popup-text-body>

        <popup-text-body class="gc-popup-exercise-reps__range-reps">
          <span class="gc-popup-exercise-reps__range-reps-heading">{{$t('message["exercise.reps-popup-range-reps-title"]')}} - </span>{{$t('message["exercise.reps-popup-range-reps-description"]')}}
        </popup-text-body>

        <popup-text-body class="gc-popup-exercise-reps__failure-reps">
          <span class="gc-popup-exercise-reps__failure-reps-heading">{{$t('message["exercise.reps-popup-failure-reps-title"]')}} - </span>{{$t('message["exercise.reps-popup-failure-reps-description"]')}}
        </popup-text-body>

      </popup-content>
    </popup-body>
  </popup>
</template>

<script>
import Popup from './Popup'
import PopupBody from './popup-sub-components/PopupBody'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupHead from './popup-sub-components/PopupHead'
import PopupTextHeading from './popup-sub-components/PopupTextHeading'
import PopupTitle from './popup-sub-components/PopupTitle'
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import PopupContent from './popup-sub-components/PopupContent'
import PopupTextBody from './popup-sub-components/PopupTextBody'

export default {
  name: 'PopupExerciseReps',
  components: {
    Popup,
    PopupTitle,
    PopupButtonClose,
    PopupBody,
    PopupHead,
    PopupTextHeading,
    PopupContent,
    PopupTextBody
  },
  data: function () {
    return {
      visible: this.popupVisibility
    }
  },
  mixins: [popupAnimationMixin],
  props: {
    popupVisibility: {
      type: Boolean
    }
  },
  methods: {
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.visible = false
        this.$emit('close')
      })
    }
  }

}
</script>

<style></style>
