<template>
  <popup
    @hide="closePopup"
    modal-class="pc-popup-workout-log-exit"
    :visible="visible"
    v-if="visible"
  >
    <popup-title>
      <popup-button-close @click="closePopup"></popup-button-close>
    </popup-title>
    <popup-body class="text-center">
      <popup-content>
        <popup-head>
          <popup-text-heading>{{$t('message[\'popup.workout-log-exit.title\']')}}
          </popup-text-heading>
        </popup-head>
        <popup-text-body>
          <p v-html="$t('message[\'popup.workout-log-exit.description\']')" v-if="type==='default'"></p>
          <p v-html="$t('message[\'popup.workout-log-exit.description-circuit\']')" v-if="type==='circuit'"></p>
        </popup-text-body>
      </popup-content>
      <popup-footer>
        <button-primary
          class="pc-popup-workout-log-exit__button-submit"
          :text="$t('message[\'workout-log.exit-anyway\']')"
          @click="doAction()"/>
        <button-secondary :text="$t('message[\'popup.workout-log-exit.back-button\']')"
                          @click="closePopup()"/>
      </popup-footer>
    </popup-body>
  </popup>
</template>

<script>

import popupAnimationMixin from '../../../../../../mixins/popupAnimationMixin'
import PopupBody from '../../../../../global/popups/popup-sub-components/PopupBody'
import ButtonSecondary from '../../../../../global/buttons/ButtonSecondary'
import ButtonPrimary from '../../../../../global/buttons/ButtonPrimary'
import PopupFooter from '../../../../../global/popups/popup-sub-components/PopupFooter'
import PopupTextBody from '../../../../../global/popups/popup-sub-components/PopupTextBody'
import PopupTextHeading from '../../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupHead from '../../../../../global/popups/popup-sub-components/PopupHead'
import PopupContent from '../../../../../global/popups/popup-sub-components/PopupContent'
import PopupButtonClose from '../../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupTitle from '../../../../../global/popups/popup-sub-components/PopupTitle'
import Popup from '../../../../../global/popups/Popup'

export default {
  name: 'PopupWorkoutLogExit',
  components: {
    PopupBody,
    ButtonSecondary,
    ButtonPrimary,
    PopupFooter,
    PopupTextBody,
    PopupTextHeading,
    PopupHead,
    PopupContent,
    PopupButtonClose,
    PopupTitle,
    Popup
  },
  mixins: [popupAnimationMixin],
  props: {
    visible: {
      default: false
    },
    type: {
      default: 'default'
    }
  },
  data: function () {
    return {
      title: '',
      imagesUrl: this.$appConfig.imagesUrl

    }
  },
  mounted () {

  },
  methods: {
    doAction () {
      this.$emit('exit')
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    }

  }
}
</script>
