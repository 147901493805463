<template>
  <popup
    :visible="visible"
    @hide="closePopup"
    modal-class="gc-popup gc-popup-account-deletion"
    v-if="visible"
  >
    <popup-title>
      <popup-button-close @click="closePopup"></popup-button-close>
    </popup-title>
    <popup-body class="text-center">
      <validation-observer ref="form" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <popup-content>
            <popup-head>
              <popup-text-heading v-if="status==='success'" class="gc-popup-account-deletion__title">
                {{ $t('message[\'account-deletion.popup-title\']') }}
              </popup-text-heading>
              <popup-text-heading v-if="status==='init'" class="gc-popup-account-deletion__title">
                {{ pageData['page-title'] }}
              </popup-text-heading>
            </popup-head>
            <popup-text-body class="gc-popup-account-deletion__description">
              <text-content v-if="status==='init'" :size="'sm2'" :lineHeight="'multi'"
                            v-html="pageData['page-content']"></text-content>
              <text-content v-if="status==='success'" :size="'sm2'" :lineHeight="'multi'"
                            v-html="pageData['request-submitted']"></text-content>
            </popup-text-body>
            <div>
              <loading-buffer v-if="status==='loading'" :height="'75'"></loading-buffer>
              <validation-provider :name="$t('message[\'auth.email\']').toLowerCase()"
                                   :vid="'email'"
                                   rules="required|email"
                                   v-slot="{ errors }">
                <text-input
                  v-if="status==='init'"
                  class="gc-popup-account-deletion__email-input"
                  v-model="formData.email"
                  :type="'email'"
                  :errorMessage="errors[0]"
                  :placeholder="$t('message[\'general.enter-email\']')"
                />
              </validation-provider>
            </div>
          </popup-content>
          <popup-footer>
            <button-primary
              v-if="status==='init'"
              class="gc-popup-account-deletion__submit-button"
              type="submit"
              :text="pageData['button-start']"
            />
            <button-primary
              v-if="status==='success'"
              class="gc-popup-account-deletion__return-button"
              @click="closePopup()"
              :text="$t('message[\'general.understood\']')"
            />
          </popup-footer>
        </form>
      </validation-observer>
    </popup-body>
  </popup>
</template>

<script>
import ButtonPrimary from '../buttons/ButtonPrimary'
import TextInput from '../inputs/TextInput.vue'
import PopupFooter from './popup-sub-components/PopupFooter'
import PopupTextBody from './popup-sub-components/PopupTextBody'
import PopupTextHeading from './popup-sub-components/PopupTextHeading'
import PopupHead from './popup-sub-components/PopupHead'
import PopupContent from './popup-sub-components/PopupContent'
import PopupBody from './popup-sub-components/PopupBody'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupTitle from './popup-sub-components/PopupTitle'
import Popup from './Popup'
import TextContent from '../../root/TextContent'
import AccountDeleteService from '@/services/AccountDeleteService'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import LoadingBuffer from '@/components/layout/template-1/global/widgets/LoadingBuffer.vue'
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'

export default {
  name: 'PopupAccountDeletion',
  mixins: [popupAnimationMixin],
  components: {
    LoadingBuffer,
    ButtonPrimary,
    PopupFooter,
    PopupTextBody,
    PopupTextHeading,
    PopupHead,
    PopupContent,
    PopupBody,
    PopupButtonClose,
    PopupTitle,
    Popup,
    TextContent,
    ValidationProvider,
    ValidationObserver,
    TextInput
  },
  data: function () {
    return {
      pageData: {},
      formData: {
        email: '',
        password: '',
        remember: true
      },
      errors: {
        email: []
      },
      status: 'loading'
    }
  },
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    email: {
      default: ''
    }
  },
  computed: {},
  mounted () {
    this.setPageData()
  },
  methods: {
    async setPageData () {
      if (this.email) {
        this.formData.email = this.email
      }
      let service = new AccountDeleteService()
      await service.getAccountDeletePageData().then(data => {
        this.pageData = data.data.content
      }).catch(data => {
      }).finally(() => {
        this.status = 'init'
      })
    },
    async submit () {
      this.status = 'loading'
      this.validateFormData()
      if (this.errors.email.length === 0) {
        this.logEvent('DataDelete.RequestConfirmed', {'email': this.formData.email})
        let service = new AccountDeleteService()
        await service.sendAccountDeleteLinkEmail(this.formData.email).then(data => {
          this.status = 'success'
        }).catch(data => {
          console.log(data)
        }).finally(() => {
        })
      }
    },
    validateFormData () {
      const requiredText = this.$i18n.t('message["validation.required"]')
      if (!this.formData.email) {
        this.errors.email = [requiredText.replace(':attribute', this.$i18n.t('message[\'auth.email\']'))]
      }
    },
    closePopup (e, callback) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('hide-popup')
        if (callback) {
          callback()
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
