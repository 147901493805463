import { render, staticRenderFns } from "./RecentPeriod.vue?vue&type=template&id=378650e7&scoped=true"
import script from "./RecentPeriod.vue?vue&type=script&lang=js"
export * from "./RecentPeriod.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "378650e7",
  null
  
)

export default component.exports