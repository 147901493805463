<template>
    <div>
        <div class="pc-popup-period-tracker">
            <popup-title class="pc-popup-period-tracker__title-with-tools">
                <popup-button-back @click="goBack"/>
                <popup-button-close @click="showExitConfirm"/>
            </popup-title>
            <popup-body class="text-center">
                <popup-content>
                    <popup-head class="text-center pc-popup-period-tracker__date-piker-head">
                        <popup-text-heading class="pc-popup-period-tracker__head">
                            {{$t('message[\'period-tracker.recent-period.header\']')}}
                        </popup-text-heading>
                    </popup-head>
                    <popup-text-body class="pc-popup-period-tracker__description pc-popup-period-tracker__date-piker-description">
                        {{$t('message[\'period-tracker.recent-period.description\']')}}
                    </popup-text-body>
                    <div class="gc-custom-date-tracker">
                        <date-picker is-inline
                                     :locale="calendar.locale"
                                     :masks="calendar.masks"
                                     mode="multiple"
                                     :value="[]"
                                     :max-date="calendar.maxDate"
                                     :min-date="calendar.minDate"
                                     :attributes="calendar.attributes"
                                     v-model="inputValue"
                                     :first-day-of-week="1"
                                     @dayclick="daySelect"></date-picker>
                    </div>
                </popup-content>
                <popup-floating-footer>
                    <button-primary :text="$t('message[\'period-tracker.popup-button.finish\']')"
                                    :disabled="!validated"
                                    @click="doAction" />
                </popup-floating-footer>
            </popup-body>
        </div>
    </div>
</template>

<script>
import popupAnimationMixin from '../../../../../../mixins/popupAnimationMixin'
import PopupTextHeading from '../../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupTextBody from '../../../../../global/popups/popup-sub-components/PopupTextBody'
import PopupHead from '../../../../../global/popups/popup-sub-components/PopupHead'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import moment from 'moment'
import {mapGetters} from 'vuex'
import PopupFloatingFooter from '../../../../../global/popups/popup-sub-components/PopupFloatingFooter'
import Popup from '../../../../../global/popups/Popup'
import PopupTitle from '../../../../../global/popups/popup-sub-components/PopupTitle'
import PopupButtonClose from '../../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupButtonBack from '../../../../../global/popups/popup-sub-components/PopupButtonBack'
import PopupBody from '../../../../../global/popups/popup-sub-components/PopupBody'
import PopupContent from '../../../../../global/popups/popup-sub-components/PopupContent'
import ButtonPrimary from '../../../../../global/buttons/ButtonPrimary'
import LanguageService from '../../../../../../services/LanguageService'

export default {
  name: 'RecentPeriod',
  mixins: [popupAnimationMixin],
  components: {
    ButtonPrimary,
    PopupTextHeading,
    PopupTextBody,
    PopupHead,
    DatePicker,
    PopupFloatingFooter,
    Popup,
    PopupTitle,
    PopupButtonClose,
    PopupButtonBack,
    PopupBody,
    PopupContent
  },
  computed: {
    validated () {
      return this.inputValue.length > 0
    }
  },
  data () {
    return {
      periodDuration: 0,
      inputValue: [],
      calendar: {
        masks: {
          weekdays: 'WW'
        },
        maxDate: moment().toDate(),
        minDate: moment().subtract(5, 'months').startOf('month').toDate(),
        locale: 'en',
        attributes: [
          {
            key: 'today',
            highlight: false,
            dot: true,
            dates: moment().toDate(),
            order: 0
          },
          {
            key: 'predicted',
            highlight: {
              class: 'calendar-prediction',
              fillMode: 'none',
              contentClass: 'calendar-prediction-wraper'
            },
            dates: [],
            order: 0
          }
        ]
      }
    }
  },
  watch: {
    inputValue (value) {
      if (value.length > 0) {
        this.service()
          .getFirstRecord()
          .setLastPeriodDate(moment(value[0]).format('YYYY-MM-DD'))

        this.service()
          .getFirstRecord()
          .setLastPeriodLength(this.inputValue.length)
      }
    }
  },
  mounted () {
    this.periodDuration = this.service()
      .getFirstRecord()
      .getPeriodLength()

    // set locale
    let langCode = (new LanguageService()).getAppLang()
    if (langCode) {
      this.calendar.locale = langCode
    }

    this.$emit('mounted')
  },
  methods: {
    ...mapGetters({
      service: 'periodTrackerStore/getService'
    }),
    goBack () {
      this.$emit('goBack')
    },
    showExitConfirm () {
      this.$emit('exit-confirm')
    },
    doAction () {
      this.$emit('next')
    },
    daySelect (day) {
      let selectedDate = moment(day.id).startOf('day')
      let firstDate = null
      let lastDate = null

      if (this.inputValue.length > 0) {
        firstDate = moment(this.inputValue[0]).startOf('day')
        lastDate = moment(this.inputValue[this.inputValue.length - 1]).startOf('day')
      }

      // valid range check
      if (
        (this.calendar.minDate && selectedDate.diff(moment(this.calendar.minDate), 'days') < 0) ||
        selectedDate.diff(moment(this.calendar.maxDate).startOf('day'), 'days') > 0
      ) {
        return
      }

      if (firstDate && lastDate) {
        // check if selected next day to the range
        if (
          selectedDate.format('YYYY-MM-DD') ===
          moment(lastDate.toDate()).add(1, 'days').format('YYYY-MM-DD')
        ) {
          return
        }

        // last selected date handle
        if (
          selectedDate.format('YYYY-MM-DD') ===
          moment(lastDate.toDate()).format('YYYY-MM-DD')
        ) {
          // removing predictions on empty selection
          if (this.inputValue.length === 1) {
            this.calendar.attributes[this.getAttributeIndexByKey('predicted')].dates = []
          }

          return
        }
      }

      this.inputValue = []
      let periodDays = this.periodDuration
      let newValues = []
      newValues.push(selectedDate.toDate())
      for (let i = 0; (i + 1) < periodDays; i++) {
        newValues.push(selectedDate.add(1, 'days').toDate())
      }
      this.inputValue = newValues

      // set predictions
      this.calendar.attributes[this.getAttributeIndexByKey('predicted')].dates = []
      if (this.inputValue.length > 0) {
        let startDate = moment(this.inputValue[0])
        let predictedValues = []
        predictedValues.push(startDate.toDate())
        for (let i = 0; (i + 1) < periodDays; i++) {
          predictedValues.push(startDate.add(1, 'days').toDate())
        }
        this.calendar.attributes[this.getAttributeIndexByKey('predicted')].dates = predictedValues
      }
    },
    getAttributeIndexByKey (key) {
      let attributeIndex = ''
      this.calendar.attributes.forEach((attribute, index) => {
        if (attribute.key === key) {
          attributeIndex = index
        }
      })
      return attributeIndex
    }
  }
}
</script>

<style scoped>

</style>
