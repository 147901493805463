<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.93359 3.88159C1.93359 2.17422 3.24369 0.785156 4.85403 0.785156C6.46441 0.785156 7.7745 2.17422 7.7745 3.88159C7.7745 5.58897 6.46437 6.97803 4.85403 6.97803C3.24369 6.97803 1.93359 5.58897 1.93359 3.88159ZM11.4734 1.22954L11.4933 1.22948C12.8667 1.22948 13.9903 2.40607 14.0023 3.86213C14.0081 4.56479 13.7568 5.22907 13.2947 5.7326C12.8243 6.24516 12.1931 6.53054 11.5173 6.53613L11.4971 6.5362C10.8288 6.5362 10.2008 6.26176 9.72675 5.76204C9.25641 5.26616 8.99419 4.60613 8.98838 3.90351C8.9826 3.20088 9.23388 2.5366 9.696 2.03307C10.1664 1.52048 10.7976 1.23513 11.4734 1.22954ZM11.6171 7.04303H11.3746C10.2453 7.04303 9.21465 7.4724 8.43652 8.17616C8.63121 8.32553 8.81652 8.48834 8.99215 8.66397C9.51027 9.18209 9.91715 9.78584 10.2015 10.4583C10.4578 11.064 10.604 11.7021 10.6381 12.3593H16.0003V11.4258C16.0003 9.00928 14.034 7.04303 11.6171 7.04303ZM7.81 8.87743C8.88781 9.68055 9.61 10.9337 9.69844 12.3584C9.70469 12.4565 9.70781 12.5556 9.70781 12.6552V13.214H0V12.6552C0 10.0549 2.10781 7.94714 4.70813 7.94711H5C6.05344 7.94711 7.02594 8.29305 7.81 8.87743Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconUsers',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-users': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
