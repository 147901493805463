<template>
  <badge :class="componentClasses" @click="clicked">
    <slot></slot>
  </badge>
</template>

<script>
import Badge from '../../root/Badge'

export default {
  name: 'BadgeImportant',
  components: {
    Badge
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-badge-important': true
      }
    }
  },
  methods: {
    /**
     * function to emit the click event
     */
    clicked () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped></style>
