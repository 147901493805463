<template>
  <div :class="componentClasses" v-on="$listeners">
   <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5351_14329)">
    <path d="M0.941417 6.11716V6.82419C0.941417 6.95363 1.04635 7.05857 1.17579 7.05857H6.82421C6.95364 7.05857 7.05858 6.95363 7.05858 6.82419V6.11716H0.941417Z" fill="white"/>
    <path d="M7.89219 1.91992C7.80756 1.86562 7.69773 1.87136 7.61919 1.93417L5.91284 3.29924C5.83007 3.36546 5.80204 3.47897 5.84448 3.5761C5.87054 3.63576 5.88377 3.69919 5.88377 3.76463C5.88377 4.02472 5.67218 4.23632 5.4121 4.23632C5.15203 4.23632 4.94042 4.02472 4.94042 3.76463C4.94042 3.61161 5.01535 3.46743 5.14087 3.37891C5.23556 3.31215 5.26724 3.18619 5.21543 3.08255L4.20962 1.07097C4.16992 0.991562 4.08876 0.941406 3.99998 0.941406C3.9112 0.941406 3.83005 0.991562 3.79034 1.07097L2.78453 3.08257C2.73272 3.18619 2.76441 3.31215 2.8591 3.37893C2.98461 3.46744 3.05955 3.61163 3.05955 3.76464C3.05955 4.02474 2.84796 4.23633 2.58786 4.23633C2.32777 4.23633 2.11618 4.02474 2.11618 3.76464C2.11618 3.69921 2.1294 3.63577 2.15547 3.57611C2.19791 3.47899 2.16988 3.36547 2.08712 3.29926L0.38081 1.93417C0.302294 1.87134 0.192451 1.86564 0.107811 1.91992C0.0231859 1.97423 -0.0173764 2.07646 0.00701413 2.17403L0.875605 5.64842H7.12439L7.99298 2.17403C8.01737 2.07646 7.97681 1.97423 7.89219 1.91992Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_5351_14329">
    <rect width="8" height="8" fill="white"/>
  </clipPath>
  </defs>
</svg>


  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconCrown',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-crown': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
