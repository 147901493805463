import { flagValue } from '../includes/TemplateSettings'
export default {
  components: { },
  data: function () {
    return {
      feedback: {
        eventName: null,
        title: '',
        textPlaceHolder: '',
        featureFlag: null,
        popupShow: false,
        eventCountToShow: null
      }
    }
  },
  methods: {
    triggerFeedbackEvent () {
      if (this.feedback.featureFlag && !flagValue(this.feedback.featureFlag, true)) {
        return
      }
      let count = localStorage.getItem('feedback-' + this.feedback.eventName) || '0'
      count = parseInt(count)
      ++count
      localStorage.setItem('feedback-' + this.feedback.eventName, (count).toString())
      if (count === this.feedback.eventCountToShow) {
        this.showFeedbackPopup()
        return true
      }
      return false
    },
    setFeedbackEvent (name, count) {
      this.feedback.eventName = name
      this.feedback.eventCountToShow = count
    },
    setFeedbackFeatureFlag (flag) {
      this.feedback.featureFlag = flag
    },
    setFeedbackPopupData (title, textPlaceholder = '') {
      this.feedback.title = title
      this.feedback.textPlaceHolder = textPlaceholder
    },
    showFeedbackPopup () {
      this.feedback.popupShow = true
    },
    hideFeedbackPopup () {
      this.feedback.popupShow = false
    }
  }
}
