import ScriptsAPI from './api/common/ScriptsAPI'
import BannersAPI from './api/common/BannersAPI'

export default class AppService {
   scripts =null
   banners =null
   constructor () {
     if (!AppService.instance) {
       AppService.instance = this
     }
     return AppService.instance
   }

   getScripts () {
     return new Promise((resolve, reject) => {
       if (this.scripts === null) {
         const service = new ScriptsAPI()
         service.getList().then(data => {
           this.scripts = data.data
           resolve(this.scripts)
         }).catch(err => {
           reject(err.response)
         })
       } else {
         resolve(this.scripts)
       }
     })
   }

   getHeaderScript(){
    return  this.scripts.header || null
   }

   getFooterScript(){
    return  this.scripts.footer || null
   }
   getThankYouPageScript(){
    return  this.scripts.thank_you || null
   }

   getBanners () {
     return new Promise((resolve, reject) => {
       if (this.banners === null) {
         const service = new BannersAPI()
         service.getList().then(data => {
           this.banners = data.data
           resolve(this.banners)
         }).catch(err => {
           reject(err.response)
         })
       } else {
         resolve(this.banners)
       }
     })
   }

   getIPDetails () {
     return fetch('https://ipinfo.io/?token=2ad4b65378e01f')
       .then((response) => response.json())
   }
}
