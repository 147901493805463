<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M25 12.5C25 19.4036 19.4036 25 12.5 25C5.59644 25 0 19.4036 0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5ZM9.11828 8.38161C8.91488 8.17822 8.58511 8.17822 8.38171 8.38161C8.17831 8.58501 8.17831 8.91478 8.38171 9.11818L11.7634 12.4999L8.38171 15.8816C8.17831 16.085 8.17831 16.4148 8.38171 16.6182C8.58511 16.8216 8.91488 16.8216 9.11828 16.6182L12.5 13.2365L15.8817 16.6182C16.0851 16.8216 16.4149 16.8216 16.6183 16.6182C16.8217 16.4148 16.8217 16.085 16.6183 15.8816L13.2366 12.4999L16.6183 9.11818C16.8217 8.91478 16.8217 8.58501 16.6183 8.38161C16.4149 8.17822 16.0851 8.17822 15.8817 8.38161L12.5 11.7633L9.11828 8.38161Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconIncompletedFilled',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-dot': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
