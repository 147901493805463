import { render, staticRenderFns } from "./PopupExerciseReps.vue?vue&type=template&id=1c44610c"
import script from "./PopupExerciseReps.vue?vue&type=script&lang=js"
export * from "./PopupExerciseReps.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports