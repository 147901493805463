<template>
  <youtube :video-id="videoId" :player-vars="playerVars" ref="youtube"
           @ready="videoReady"
           @ended="videoEnded"
           @playing="videoPlaying"
           @paused="videoPaused"
  ></youtube>
</template>

<script>
import Vue from 'vue'
import VueYoutube from 'vue-youtube'

Vue.use(VueYoutube)

export default {
  name: 'VideoPlayerYoutubeVideo',
  props: {
    videoId: {
      type: String
    },
    options: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  components: {
    VueYoutube
  },
  data () {
    return {
      playerVars: {
        controls: 0,
        showinfo: 0,
        modestbranding: 1,
        autohide: 1,
        playsinline: 1
      }
    }
  },
  mounted () {

  },
  computed: {
    player () {
      return this.$refs.youtube.player
    }
  },
  watch: {
    videoId: function (val) {
      this.player.loadVideoById(val)
    }
  },
  methods: {
    play () {
      this.player.playVideo()
    },
    pause () {
      this.player.pauseVideo()
    },
    replay () {
      this.player.seekTo(0)
    },
    videoReady () {
      // set mute status
      if (this.options.mute) {
        this.player.mute()
      } else {
        this.player.unMute()
      }

      this.$emit('ready')
    },
    videoEnded () {
      this.$emit('ended')
    },
    videoPlaying () {
      this.$emit('playing')
    },
    videoPaused () {
      this.$emit('paused')
    },
    beforeDestroy () {
      if (this.player) {
        this.player.destroy()
      }
    }
  }
}
</script>
