<template>
  <div class="pc-navigation">
    <navigator-side-menu/>
  </div>
</template>
<script>

import NavigatorSideMenu from '../../../global/navigators/NavigatorSideMenu'

export default {
  name: 'PageNavigation',
  components: {
    NavigatorSideMenu

  },
  mounted () {
    this.hideLoading()
  }
}

</script>
