import { flagValue } from '../includes/TemplateSettings'
import EventHandlerAPI from './custom-api/EventHandlerAPI'
import FeatureUpdatesAPI from './custom-api/FeatureUpdatesAPI'
import DashBoardService from './DashboardService'
import moment from 'moment'
export default class FeaturesService {
  _isGotContentInThisSession = false
  _userRegisterDate = moment()

  constructor () {
    if (!FeaturesService.instance) {
      FeaturesService.instance = this
    }
    try {
      let dashBoardService = new DashBoardService()
      this._userRegisterDate = dashBoardService.getRegisteredDate()
    } catch (e) {
      this._userRegisterDate = moment()
    }

    return FeaturesService.instance
  }
  /**
   * get the new updates date via API
   * @returns {Promise<T>}
   */
  getFeatureUpdates () {
    let api = new FeatureUpdatesAPI()

    return api.getUpdatesData().then(data => {
      return data.data
    }).then(data => {
      return data.feature_highlights || []
    })
  }

  /**
   * returns the Array of unseen features
   * @returns {Promise<unknown>}
   */
  getNewFeatureUpdateContents () {
    return new Promise((resolve) => {
      if (this._isGotContentInThisSession) {
        resolve([])
        return
      }
      this.getFeatureUpdates().then(featureUpdates => {
        let checkArray = []

        featureUpdates.forEach(update => {
          if (!!flagValue(update.feature_flag, false) &&
            update.feature_flag in templateConfig.instance_config_timestamps &&
            this._userRegisterDate < moment(templateConfig.instance_config_timestamps[update.feature_flag].updated_at).subtract(1, 'days') &&
            this.isFeatureEnabledAfterEnablingFeatureUpdatePopup(update.feature_flag)
          ) {
            checkArray.push(this.checkUserAFirstTimeUser(update.feature_flag))
          }
        })
        Promise.all(checkArray).then((values) => {
          let filteredValues = values.filter(value => {
            return !!value
          })

          resolve(this.getUnseenContent(featureUpdates, filteredValues))
        })
        this._isGotContentInThisSession = true
      }).catch(() => {
        resolve([])
      })
    })
  }

  isFeatureEnabledAfterEnablingFeatureUpdatePopup (featureFlag) {
    return moment(templateConfig.instance_config_timestamps[featureFlag].updated_at) > moment(templateConfig.instance_config_timestamps['enable_feature_updates_popup'].updated_at)
  }

  /**
   * filter and map user unseen feature content
   * @param featureUpdates
   * @param unseenTags
   * @returns {*}
   */
  getUnseenContent (featureUpdates, unseenTags) {
    let filteredValues = featureUpdates.filter(update => {
      return unseenTags.includes(update.feature_flag)
    })
    return filteredValues.map(value => {
      return `<div style="width:100%;font-weight: 700; font-size: 20px;">${value.title}</div><div>${value.description}</div>`
    })
  }

  /**
   * check user eligible to see the content
   * @param featureFlag
   * @returns {Promise<unknown>}
   */
  checkUserAFirstTimeUser (featureFlag) {
    return new Promise(resolve => {
      if (!this._isFeatureEnabledByTag(featureFlag)) {
        resolve(false)
      } else if (this.checkUserAFirstTimeUserViaLocalStorage(featureFlag)) {
        resolve(false)
      } else {
        this.checkUserAFirstTimeUserViaAPI(featureFlag).then(res => {
          if (res) {
            resolve(featureFlag)
          } else {
            resolve(false)
          }
        }).catch(() => {
          resolve(false)
        })
      }
    })
  }

  /**
   * check user eligible to see the content by local Storage
   * @param tag
   * @returns {boolean}
   */
  checkUserAFirstTimeUserViaLocalStorage (tag) {
    let localData = localStorage.getItem('feature_popup_show_' + tag) || false

    return !!localData
  }

  /**
   * set user seen the feature content
   * @param tag
   */
  setUserAFirstTimeUserViaLocalStorage (tag) {
    localStorage.setItem('feature_popup_show_' + tag, true)
  }

  /**
   * check user eligible to see the content by API
   * @param tag
   * @returns {boolean}
   */
  checkUserAFirstTimeUserViaAPI (tag) {
    let eventName = 'FeatureFirstTimePopupShow_test124_' + tag
    let payload = {
      'event': eventName,
      'criteria': {
        'type': 'event_count',
        'attributes': { 'count': 1 }
      },
      'only_once': true,
      'acknowledge': true
    }

    let api = new EventHandlerAPI()

    return new Promise((resolve) => {
      api.trackEventWithEligibilityCheck(payload).then(viewData => {
        if (viewData.data && viewData.data.eligible) {
          this.setUserAFirstTimeUserViaLocalStorage(tag)
          resolve(tag)
        } else {
          this.setUserAFirstTimeUserViaLocalStorage(tag)
          resolve(false)
        }
      }).catch(() => {
        resolve(false)
      })
    })
  }
  /**
   * check feature enabled
   * @param featureFlag
   * @returns {boolean}
   */
  _isFeatureEnabledByTag (featureFlag) {
    return !!flagValue(featureFlag, false)
  }
}
