<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 0C4.48608 0 0 4.48608 0 10C0 15.5139 4.48608 20 10 20C15.5139 20 20 15.5139 20 10C20 4.48608 15.5139 0 10 0ZM8.75 13.75H6.25V6.25H8.75V13.75ZM13.75 13.75H11.25V6.25H13.75V13.75Z" fill="black"/>
</svg>

  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconPause',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-pause': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
