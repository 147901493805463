import AuthAPI from './api/AuthAPI'

export default class ForgotPasswordService {
  countries = []
  regions =[]
  productDetails={}

  request (payload) {
    return new Promise((resolve, reject) => {
      const api = new AuthAPI()
      api.forgotPasswordRequest(payload).then((data) => {
        resolve(data.data)
      }).catch(err => { reject(err.response) })
    })
  }

  reset (payload) {
    return new Promise((resolve, reject) => {
      const api = new AuthAPI()
      api.forgotPasswordReset(payload).then((data) => {
        resolve(data)
      }).catch(err => { reject(err.response) })
    })
  }
}
