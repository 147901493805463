import SubscriptionFormData from './SubscriptionFormData'
import DefineInclude from '../../../DefineInclude'
import SubscriptionAPI from '../../api/subscription/SubscriptionAPI'
import NutritionCategoryAPI from '../../api/subscription/NutritionCategoryAPI'
import IngredientsAPI from '../../api/common/IngredientsAPI'
import activityLevel from '../../../config/activity-levels'

export default class SubscriptionOption {
  height = {};
  weight = {};
  bodyFat = {};
  activityLevel = {};
  mealCategory = {};
  nutritionalPref = [];
  mPD = {};
  vegetarian = {};
  dGoals = {};
  allergies = {}
  suitableFor = {};
  injuries = {};
  expLevel = [];
  workoutGoals = {};
  sessionsPerWeek = {};
  daysPerWeek = {};
  config = {};
  workouts = {}
  subscriptionData = null

  constructor () {
    if (!SubscriptionOption.instance) {
      const np = (new SubscriptionFormData()).getValue('nutritionalPref')
      if (np !== '' && !isNaN(np)) {
        this.setNutritionalPrefData(np)
      }
      SubscriptionOption.instance = this
    }
    return SubscriptionOption.instance
  }

  getSubscriptionData (id) {
    return new Promise((resolve, reject) => {
      if (this.subscriptionData === null) {
        const service = new SubscriptionAPI()
        service.get(id).then(data => {
          this.subscriptionData = data.data
          this.setOptionsFields()
          resolve(data.data)
        })
      } else {
        this.setOptionsFields()
        resolve(this.subscriptionData)
      }
    })
  }

  setOptionsFields () {
    this.nutritionalPref = this.subscriptionData.nutritional_category.map(ele => {
      return { key: ele.id, value: ele.name }
    })
    this.mealCategory = this.subscriptionData.nutritional_category

    this.expLevel = []
    Object.keys(this.subscriptionData.levels).forEach((item) => {
      this.expLevel.push({ key: item, value: this.subscriptionData.levels[item] })
    })

    this.suitableFor = []
    Object.keys(this.subscriptionData.locations).forEach((item) => {
      this.suitableFor.push({ key: item, value: this.subscriptionData.locations[item] })
    })

    this.injuries = this.subscriptionData.injuries.map(option => {
      return { id: option.id, text: option.value }
    })

    this.activityLevel = this.subscriptionData.activity_level.map(option => {
      return { key: option.id, value: option.name }
    })
    this.workouts = this.subscriptionData.workouts
  }

  setNutritionalPrefData (nutritionalPrefID) {
    return new Promise((resolve, reject) => {
      const service = new NutritionCategoryAPI()
      service.get(nutritionalPrefID).then(data => {
        this.mPD[nutritionalPrefID] = data.data.mpd
        this.vegetarian[nutritionalPrefID] = data.data.vegetarian
        this.dGoals[nutritionalPrefID] = data.data.goals
        resolve(data.data)
      }).catch(err => {
        reject(err.response)
      })
    })
  }

  getContent (key) {
    return this.subscriptionData.contents[key]
  }

  getHeight (measureType = null) {
    return this.height[measureType !== null ? measureType : (new SubscriptionFormData()).getValue('unit')]
  }

  getWeight (measureType = null) {
    return this.weight[measureType !== null ? measureType : (new SubscriptionFormData()).getValue('unit')]
  }

  getBodyFat (gender = null) {
    if (gender === null) {
      gender = (new SubscriptionFormData()).getValue('gender')
    }

    const baseImageURL = DefineInclude.resourceBaseURL + '/static/assets/images/bodyfat/'
    const maleBaseImageUrl = baseImageURL + 'men/'
    const femaleBaseImageUrl = baseImageURL + 'women/'
    const bodyFatRanges = {
      m:
        [
          {
            image: maleBaseImageUrl + 'm1.jpg', min: '3', max: '5'
          }, {
            image: maleBaseImageUrl + 'm2.jpg', min: '6', max: '8'
          }, {
            image: maleBaseImageUrl + 'm3.jpg', min: '9', max: '13'
          }, {
            image: maleBaseImageUrl + 'm4.jpg', min: '14', max: '17'
          }, {
            image: maleBaseImageUrl + 'm5.jpg', min: '18', max: '22'
          }, {
            image: maleBaseImageUrl + 'm6.jpg', min: '23', max: '27'
          }, {
            image: maleBaseImageUrl + 'm7.jpg', min: '28', max: '32'
          }, {
            image: maleBaseImageUrl + 'm8.jpg', min: '33', max: '37'
          }, {
            image: maleBaseImageUrl + 'm9.jpg', min: '38', max: '100'
          }
        ],
      f: [
        {
          image: femaleBaseImageUrl + 'f1.jpg', min: '10', max: '13'
        }, {
          image: femaleBaseImageUrl + 'f2.jpg', min: '14', max: '18'
        }, {
          image: femaleBaseImageUrl + 'f3.jpg', min: '19', max: '23'
        }, {
          image: femaleBaseImageUrl + 'f4.jpg', min: '24', max: '27'
        }, {
          image: femaleBaseImageUrl + 'f5.jpg', min: '28', max: '32'
        }, {
          image: femaleBaseImageUrl + 'f6.jpg', min: '33', max: '37'
        }, {
          image: femaleBaseImageUrl + 'f7.jpg', min: '38', max: '42'
        }, {
          image: femaleBaseImageUrl + 'f8.jpg', min: '43', max: '47'
        }, {
          image: femaleBaseImageUrl + 'f9.jpg', min: '48', max: '100'
        }
      ]
    }
    if (gender === 'female' || gender === 'f') {
      return bodyFatRanges.f
    } else {
      return bodyFatRanges.m
    }
  }

  getActivityLevel () {
    return this.activityLevel.map(level => {
      const index = activityLevel.levels.findIndex(lvl => {
        return lvl.id === level.key
      })
      return {
        key: level.key,
        value: level.value,
        description: (index === -1) ? '' : activityLevel.levels[index].description
      }
    })
  }

  getMealCategory () {
    return this.mealCategory.map(option => {
      return { key: option.id, value: option.value }
    })
  }

  getNutritionalPref () {
    return new Promise((resolve, reject) => {
      const api = new NutritionCategoryAPI()
      api.getList().then(data => {
        this.nutritionalPref = data.data.categories
        resolve(this.nutritionalPref)
      })
    })
  }

  getMealsPerDay () {
    return this.mPD[(new SubscriptionFormData()).getValue('nutritionalPref')]
  }

  getVegetarianDays () {
    return this.vegetarian[(new SubscriptionFormData()).getValue('nutritionalPref')]
  }

  getDGoal () {
    return this.dGoals[(new SubscriptionFormData()).getValue('nutritionalPref')]
  }

  getAllergies () {
    const api = new IngredientsAPI()
    return new Promise((resolve, reject) => {
      api.getList().then(data => {
        resolve(data.data.ingredients)
      })
    })
  }

  getSuitableFor () {
    return this.suitableFor
  }

  getInjuries () {
    return this.injuries
  }

  getExpLevel () {
    return this.expLevel
  }

  getWorkoutGoals (workoutIndex = 0) {
    const gender = (new SubscriptionFormData()).getValue('gender')
    return this.workouts[workoutIndex].workout_goals[gender].map(option => {
      return { id: option.id, text: option.value }
    })
  }

  getSessionDuration (workoutIndex = 0) {
    const gender = (new SubscriptionFormData()).getValue('gender')
    return this.workouts[workoutIndex].durations[gender].map(option => {
      return { id: option.id, text: option.value }
    })
  }

  getDaysPerWeek (workoutIndex = 0) {
    const gender = (new SubscriptionFormData()).getValue('gender')

    return this.workouts[workoutIndex].days[gender].map(option => {
      const newop = {}
      newop.text = option.value + (parseInt(option.value) === 1 ? ' day' : ' days') + ' a week'
      newop.id = option.value
      return newop
    })
  }

  getPlaceOfWork () {
    return [
      { key: '1.00', value: 'Desk or Office Job', description: 'Student, Software Engineer' },
      { key: '1.20', value: 'Desk or Retail Job', description: 'Sales Rep, Teacher' },
      { key: '1.375', value: 'Light Lifting Job', description: 'Warehouse, Packaging job' },
      { key: '1.55', value: 'Heavy Lifting Job', description: 'Labour, Construction worker' }
    ]
  }

  getWorkoutCount () {
    return this.workouts.length
  }

  getWorkout (index) {
    return this.workouts[index]
  }

  getWeeks (workoutIndex = 0) {
    const gender = (new SubscriptionFormData()).getValue('gender')
    return this.workouts[workoutIndex].weeks[gender]
  }

  getWorkoutID (workoutIndex = 0) {
    return this.workouts[workoutIndex].id
  }

  getWeeklyExercise () {
    return [
      { key: '1.00', value: '1-3 times or less', description: '30 - 60 mins or less' },
      { key: '1.20', value: '3-4 times', description: '60 mins sessions' },
      { key: '1.375', value: '4-5 times', description: '60 - 90 mins sessions' },
      { key: '1.55', value: '5-6 times', description: '90 - 120 mins or more' }
    ]
  }

  setConfig (config) {
    this.config = config
  }
}
