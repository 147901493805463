<template>
    <div v-if="member.getId()">
        <div class="pc-profile--my-connections__header">
            <text-body-small :lineHeight="'lh-lg'">{{$i18n.t(
                'message[\'member-connect.connected-with\']'
                )}}
            </text-body-small>
        </div>
        <card-member-large
                :avatar="member.getAvatar()"
                :name="member.getFullName()"
                :email="member.getEmail()"
                :labelText="memberTypeLabel">
            <div class="pc-profile--my-connections__button-wrapper">
                <button-custom class="pc-profile--my-connections__button-pair-meal-plan"
                               :type="'outline'"
                               :disabled="!ownerPlanIsValid"
                               v-if="mealPairingEnabled"
                               @click="showMealPlanPairPopup" :text="$i18n.t(
                        'message[\'meal-plan-pair.pair-meal-plan\']'
                        )"></button-custom>
                <button-custom class="pc-profile--my-connections__button-disconnect" :type="'outline'" @click="showDisconnectConfirm" :text="$i18n.t(
                        'message[\'member-connect.disconnect-button\']'
                        )"></button-custom>
            </div>
        </card-member-large>

        <alert-dotted class="pc-profile--my-connections__alert-dotted" v-if="ownerPlanNotValidAlertEnabled">
            <div class="pc-profile--my-connections__alert-dotted-header">
                <icon-meal :size="'sm2'"/>
                <text-body-small :line-height="'multi'" :weight="'extra-bold'">
                    {{$i18n.t(
                    'message[\'meal-plan-pair.pairing-unavailable\']'
                    )}}
                </text-body-small>
            </div>
            <text-body-extra-small class="pc-profile--my-connections__alert-description" :line-height="'multi'" v-html="$i18n.t(
                'message[\'meal-plan-pair.pairing-unavailable-description\']'
                )"></text-body-extra-small>
        </alert-dotted>

        <alert-dotted class="pc-profile--my-connections__alert-dotted" v-if="mealPairingPendingAlertEnabled">
            <div class="pc-profile--my-connections__alert-dotted-header">
              <icon-meal :size="'sm2'"/>
              <text-body-small :line-height="'multi'" :weight="'extra-bold'">
                  {{$t('message[\'meal-plan-pair.pending\']')}}
              </text-body-small>
            </div>
            <text-body-extra-small class="pc-profile--my-connections__alert-description" :line-height="'multi'" v-html="$t('message[\'meal-plan-pair.pending-description\']')"></text-body-extra-small>
        </alert-dotted>
        <popup-meal-plans-pair ref="mealTypesPairPopup" @refresh="setPageData"></popup-meal-plans-pair>
        <popup-member-disconnect :name="member.getFullName()" @disconnected="emitDisconnect" ref="disconnectPopup"></popup-member-disconnect>
    </div>
</template>

<script>
import TextBodySmall from '../../../../../global/typography/TextBodySmall'
import TextBodyExtraSmall from '../../../../../global/typography/TextBodyExtraSmall'
import ButtonCustom from '../../../../../global/buttons/ButtonCustom'
import PopupMealPlansPair from '../popups/PopupMealPlansPair'
import Member from '../../../../../../services/meal-plan-pair/Member'
import ConnectionService from '../../../../../../services/meal-plan-pair/ConnectionService'
import PairService from '../../../../../../services/meal-plan-pair/PairService'
import CardMemberLarge from '../../../../../global/cards/CardMemberLarge'
import AlertDotted from '../../../../../global/alerts/AlertDotted'
import IconMeal from '../../../../../root/icons/IconMeal'
import DashBoardService from '../../../../../../services/DashboardService'
import PopupMemberDisconnect from '../../../../../global/popups/PopupMemberDisconnect'

export default {
  name: 'CardPairedMember',
  components: {
    PopupMemberDisconnect,
    ButtonCustom,
    AlertDotted,
    CardMemberLarge,
    TextBodySmall,
    TextBodyExtraSmall,
    PopupMealPlansPair,
    IconMeal
  },
  computed: {
    mealPairingEnabled () {
      return !this.isOwner && !this.mealPaired
    },
    mealPairingPendingAlertEnabled () {
      return !this.isOwner && this.mealPaired && this.ownerPlanIsValid && !this.hasPairedMealPlan
    },
    ownerPlanNotValidAlertEnabled () {
      return !this.isOwner && !this.ownerPlanIsValid
    }
  },
  data () {
    return {
      connectionService: new ConnectionService(),
      pairService: new PairService(),
      dashboardService: new DashBoardService(),
      memberTypeLabel: '',
      member: new Member(),
      mealPaired: false,
      ownerPlanIsValid: false,
      hasPairedMealPlan: false,
      isOwner: false
    }
  },
  mounted () {
    this.setPageData()
  },
  methods: {
    async setPageData () {
      this.showLoading()

      await Promise.all([
        this.connectionService.init(),
        this.pairService.init(),
        this.dashboardService.getPageData()
      ])
        .catch(() => {
          this.hideLoading()
        })

      this.member = this.connectionService.getPartner()
      this.isOwner = this.connectionService.isOwner()

      this.mealPaired = this.pairService.isPaired()
      this.ownerPlanIsValid = this.pairService.ownerPlanIsValid()

      this.hasPairedMealPlan = this.dashboardService.hasPairedMealPlans()

      this.setLabel()

      this.hideLoading()
    },
    setLabel () {
      let langKey = 'member-connect.my-follower'

      if (this.member.getIsOwner()) {
        langKey = 'member-connect.my-manager'
      }

      this.memberTypeLabel = this.$i18n.t(
        `message['${langKey}']`
      )
    },
    showDisconnectConfirm () {
      this.$refs.disconnectPopup.show()
    },
    showMealPlanPairPopup () {
      this.$refs.mealTypesPairPopup.show()
    },
    emitDisconnect () {
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>

</style>
