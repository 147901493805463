<template>
  <button-base :borderRadius="'sm1'" :class="componentClasses" :type="'solid'" @click="clicked()">
    <text-content :class="textClass" :line-height="'multi'" :size="'sm3'" :weight="'medium'"> {{ text }}</text-content>
    <text-content :size="'sm1'" :line-height="'multi'" v-if="hasSubText"> {{ subText }}</text-content>
  </button-base>
</template>

<script>

import TextContent from '../../root/TextContent'
import ButtonBase from '../../root/ButtonBase'

export default {
  name: 'ButtonSecondary',
  components: {
    ButtonBase,
    TextContent
  },
  props: {
    /**
     * The main text of the button
     */
    text: {
      default: ''
    },
    /**
     * The sub text of the button
     */
    subText: {
      default: ''
    },
    /**
     * The disabled state of the button
     */
    disabled: {
      default: false
    }
  },
  computed: {
    hasSubText: function () {
      return this.subText.replace(/\s/g, '') !== ''
    },
    textClass: function () {
      return {
        'gc-button-secondary__text': true,
        'gc-button-secondary__text--with-sub-text': this.hasSubText
      }
    },
    componentClasses: function () {
      return {
        'gc-button-secondary': true,
        'gc-button-secondary--disabled': this.disabled,
        'gc-button-secondary--with-sub-text': this.hasSubText
      }
    }
  },
  /**
   * function to emit the click event
   */
  methods: {
    clicked () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped></style>
