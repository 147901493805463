import { AppConfig } from '../../AppConfig'
import SubscriptionOption from './SubscriptionOption'
import SubscriptionAPI from '../../api/subscription/SubscriptionAPI'

export default class SubscriptionFormData {
  data = {
    unit: '',
    gender: 'male',
    birthDay: '',
    weeklyExercise: '',
    placeOfWork: '',
    activityLvl: '',
    bodyFat: '',
    weData: {
      times: '',
      duration: ''
    },
    height: {
      preference: 'imperial',
      feet: '',
      inch: '',
      cm: ''
    },
    weight: {
      preference: 'imperial',
      lbs: '',
      kg: ''
    },
    allergies: [],
    dGoal: '',
    mealsPerDay: '',
    nutritionalPref: '',
    vegetarianDay: '',
    excLocPref: '',
    excLvl: '',
    injuriesPrvtExc: '',
    daysPerWeek: '',
    sessionDuration: '',
    wGoal: '',
    workouts: []
  }

  errors = {}

  constructor () {
    if (!SubscriptionFormData.instance) {
      SubscriptionFormData.instance = this
      this.getValuesFormLocalStorage()
    }
    return SubscriptionFormData.instance
  }

  setErrors (errors) {
    this.errors = errors
  }

  getHeightValueByUnit (unit) {
    if (unit === 'imperial') {
      return parseInt(this.data.height.feet * 12) + (parseInt((this.data.height.inch || '0')))
    } else {
      return parseInt(this.data.height.cm)
    }
  }

  getWeightValueByUnit (unit) {
    if (unit === 'imperial') {
      return parseInt(this.data.weight.lbs)
    } else {
      return parseInt(this.data.weight.kg)
    }
  }

  sendData (subscription) {
    return new Promise((resolve, reject) => {
      this.setValuesToLocalStorage()
      this.convertValuesToUnitSystem()
      //  $('.personal-details.forms').submit()
      const optionService = new SubscriptionOption()
      optionService.getSubscriptionData().then(data => {
        // this.calculateActivityLevel()
        // this.data.activityLvl = this.mapData(this.data.activityLvl, 'activityLvl')
        const dob = new Date(this.data.birthDay)

        const payload = {
          gender: this.data.gender,
          dob_day: dob.getDate(),
          dob_month: (dob.getMonth() + 1),
          dob_year: dob.getFullYear(),
          system: this.data.unit,
          weight: this.getWeightValueByUnit(this.data.unit),
          height: this.getHeightValueByUnit(this.data.unit),
          body_fats: this.mapData(this.data.bodyFat, 'bodyFat'),
          activity_level: this.data.activityLvl,
          nutritional_category: this.data.nutritionalPref,
          nutrition_goals: this.data.dGoal,
          meals_per_day: this.data.mealsPerDay,
          cuisines: [],
          allergies: this.data.allergies,
          level: this.data.excLvl,
          location: this.data.excLocPref,
          injuries: this.data.injuriesPrvtExc,
          workouts: this.data.workouts
        }
        payload.vegetarian_days = parseInt(this.data.vegetarianDay) || ''

        const api = new SubscriptionAPI()
        api.saveData(subscription, payload).then(data => {
          resolve(data.data)
        }).catch(err => {
          reject(err.response)
        })
      })
    })
  }

  setValuesToLocalStorage () {
    // sessionStorage.setItem('OnBoardingData', JSON.stringify(this.data))
  }

  getValuesFormLocalStorage () {
    if (sessionStorage.getItem('OnBoardingData') !== null) {
      this.data = JSON.parse(sessionStorage.getItem('OnBoardingData'))
    }
  }

  destroyLocalStorageData () {
    sessionStorage.removeItem('OnBoardingData')
  }

  getValue (type) {
    return this.data[type]
  }

  setValue (type, value) {
    this.data[type] = value
    this.setMainFormData(type, value)
    const conTypes = ['unit']
    if (conTypes.includes(type)) {
      this.convertValuesToUnitSystem()
    }
  }

  calculateActivityLevel (config) {
    let val = ''
    val = (parseFloat(this.data.placeOfWork) + this.getWeeklyExercise()) / 2
    this.data.activityLvl = val
    this.setMainFormData('activityLvl', this.data.activityLvl)
  }

  mapData (val, type) {
    let res = val
    let mapType
    let config = AppConfig
    if (typeof macroAppConfig !== 'undefined') {
      config = macroAppConfig
    }
    if (typeof config.onBoarding.mapping[type] === 'undefined' || config.onBoarding.mapping[type].length === 0) {
      return res
    } else {
      res = ''
    }
    for (mapType of config.onBoarding.mapping[type]) {
      if ((mapType.type === 'range' && val >= mapType.range.min &&
        val <= mapType.range.max) || (mapType.type === 'single' && val === (mapType.value))) {
        res = mapType.systemValue
        break
      }
    }
    if (res === '') {
      throw new Error('data mapping out of bound exception : type - ' + type + ', value ' + val)
    }

    return res
  }

  getWeeklyExercise () {
    const calcData = parseInt(this.data.weData.times) * parseInt(this.data.weData.duration)
    let res
    if (isNaN(calcData) || calcData === 0) {
      res = 0
    } else if (calcData <= 90) {
      res = 1.0
    } else if (calcData <= 240) {
      res = 1.2
    } else if (calcData <= 450) {
      res = 1.375
    } else {
      res = 1.55
    }
    return res
  }

  convertValuesToUnitSystem () {
    if (this.data.unit === 'metric') {
      if (this.data.height.preference === 'imperial') {
        this.data.height.cm = this.inToCm(((parseInt(this.data.height.feet) * 12) + parseInt((this.data.height.inch || '0'))))
        this.data.height.preference = 'metric'
      }
      this.setMainFormData('height', this.data.height.cm)
      if (this.data.weight.preference === 'imperial') {
        this.data.weight.kg = this.lbToKg(parseFloat(this.data.weight.lbs))
        this.data.weight.preference = 'metric'
      }
      this.setMainFormData('weight', this.data.weight.kg)
    } else if (this.data.unit === 'imperial') {
      if (this.data.height.preference === 'metric') {
        const totalInc = this.cmToIn((parseInt(this.data.height.cm)))
        this.data.height.inch = parseInt(totalInc) % 12
        this.data.height.feet = parseInt(totalInc / 12)
        this.data.height.preference = 'imperial'
      }
      this.setMainFormData('height', ((parseInt(this.data.height.feet) * 12) + parseInt((this.data.height.inch || '0'))))
      if (this.data.weight.preference === 'metric') {
        this.data.weight.lbs = this.kgToLb(this.data.weight.kg)
        this.data.weight.preference = 'imperial'
      }
      this.setMainFormData('weight', this.data.weight.lbs)
    }
  }

  setMainFormData (type, value) {
  }

  inToCm (inches) {
    return Math.round(Math.round(inches * 2.54 * 100) / 100)
  }

  cmToIn (cm) {
    return Math.round(Math.round(cm * 0.39370 * 100) / 100)
  }

  lbToKg (lb) {
    return Math.round(Math.round(lb * 0.453592 * 100) / 100)
  }

  kgToLb (kg) {
    return Math.round(Math.round(kg * 2.20462 * 100) / 100)
  }
}
