import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    visible: false,
    feature: '',
    title: '',
    featureInfo: [],
    popupShown: false
  },
  mutations: {
    showPopup (state) {
      state.visible = true
    },
    hidePopup (state) {
      state.visible = false
      state.setPopupShown = true
    },
    setPopupShown (state) {
      state.popupShown = true
    },
    setContent (state, data) {
      state.title = [...state.title, data.title]

      state.featureInfo = [...state.featureInfo, data.featureInfo]
      state.feature = [...state.feature, data.feature]
    }
  },
  getters: {
    visible (state) {
      return state.visible
    },
    title (state) {
      return state.title
    },
    isPopupShown (state) {
      return state.popupShown
    },
    featureInfo (state) {
      return state.featureInfo
    },
    getFeature (state) {
      return state.feature
    }
  }
}
