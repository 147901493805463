<template>
      <page :header="header" class="gc-page-dietary-goal">
        <validation-observer slim ref="form" v-slot="{handleSubmit, invalid}">
            <page-container>
                <div class="gc-page-dietary-goal__header">
                    <text-heading-4
                        class="gc-page-dietary-goal__title text-center"
                        weight="extra-bold"
                        lineHeight="multi">{{title.main}}</text-heading-4>
                </div>
                <div class="gc-page-dietary-goal__content">
                    <form id="form-input-dietary-goal"
                        class="gc-page-dietary-goal__form"
                        @submit.prevent="handleSubmit(nextComponent)">
                            <validation-provider v-slot="{ errors }" rules="required" vid="dietaryGoal" name="dietary goal">
                                <button-choice
                                    type="button"
                                    class="gc-page-dietary-goal__input"
                                    v-for="option in options"
                                    :key="'dGoal-'+option.id"
                                    :text="option.name"
                                    v-model="dietaryGoal"
                                    @click="setDietaryGoal(option.id)"
                                    :selected="(dietaryGoal===option.id)"></button-choice>
                                    <text-body-extra-small class="gc-page-progress-form__input-error dietary-goal-error-msg text-center"  lineHeight="multi">{{errors[0]}}</text-body-extra-small>
                            </validation-provider>
                    </form>
                </div>
            </page-container>

            <page-footer :form="'form-input-dietary-goal'" :validated="invalid"></page-footer>
        </validation-observer>
    </page>
</template>

<script>
import FormBaseComponent from '../../layout/template-1/global/widgets/forms/FormBaseComponent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ButtonPrimary from '../buttons/ButtonPrimary'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import TextHeading4 from '../typography/TextHeading4'
import PageContainer from './page-sub-components/PageContainer'
import Page from './PageForm'
import ButtonChoice from '../buttons/ButtonChoice'
import PageFooter from './page-sub-components/PageFormFooter'

export default {
  name: 'PageActivityLevel',
  extends: FormBaseComponent,
  components: {
    Page,
    PageContainer,
    TextHeading4,
    ButtonPrimary,
    TextBodyExtraSmall,
    ValidationObserver,
    ValidationProvider,
    ButtonChoice,
    PageFooter
  },
  data: function () {
    return {
      header: {
        isTransparent: false,
        isFixed: false,
        show: false,
        left: '',
        right: '',
        rightBlink: false,
        modifiers: [],
        mainTitle: '',
        subTitle: ''
      },
      dietaryGoal: ''
    }
  },
  mounted () {
    this.dietaryGoal = this.inputValue
    this.hideLoading()
  },
  methods: {
    setDietaryGoal (selectedGoal) {
      this.dietaryGoal = selectedGoal
    },
    nextComponent () {
      const inputObj = { key: this.inputKey, value: this.dietaryGoal }
      this.$emit('submit', inputObj)
    }
  }
}
</script>

<style scope>
</style>
