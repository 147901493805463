<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.13422 7.13869V7.13871L4.13432 7.69963L4.13434 7.79961H4.23432H13.7654H13.8654V7.69961V7.1509V7.15054L13.8655 7.13962V7.13872C13.8655 4.45567 11.6829 2.27305 8.99986 2.27305C6.31684 2.27305 4.13422 4.45567 4.13422 7.13869ZM9.65086 4.92297V4.82297H9.55086H8.44882H8.34882V4.92297V6.49756H5.49399C5.79682 4.83753 7.25388 3.57512 8.99982 3.57512C10.7458 3.57512 12.2028 4.83756 12.5056 6.49756H9.65086V4.92297ZM15.3315 -0.1H2.66854C1.14184 -0.1 -0.1 1.14184 -0.1 2.66854V15.3315C-0.1 16.8582 1.14184 18.1 2.66854 18.1H15.3315C16.8582 18.1 18.1 16.8582 18.1 15.3315V2.66854C18.1 1.14184 16.8582 -0.1 15.3315 -0.1ZM16.798 15.3315C16.798 16.14 16.14 16.798 15.3315 16.798H9.651V13.2612C9.651 12.9017 9.35953 12.6102 8.99998 12.6102C8.64043 12.6102 8.34896 12.9017 8.34896 13.2612V16.798H2.66854C1.85997 16.798 1.20204 16.14 1.20204 15.3315V2.66854C1.20204 1.85997 1.85997 1.20204 2.66854 1.20204H15.3315C16.1401 1.20204 16.798 1.85997 16.798 2.66853L16.798 15.3315Z"
        fill="#A46E7B" stroke="#A46E7B" stroke-width="0.2"/>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconScale',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-scale': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
