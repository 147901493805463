<template>
  <button-progress-action
    :header="headerText"
    :description="descriptionText"
    @click="doAction()"
  />
</template>
<script>
import ButtonProgressAction from './ButtonProgressAction'

export default {
  name: 'ButtonProgressUpdate',
  props: ['text', 'action', 'colorClass'],
  components: {
    ButtonProgressAction
  },
  data: function () {
    return {
      headerText: '',
      descriptionText: ''
    }
  },
  mounted () {
    const parser = new DOMParser()
    const htmlString = this.text
    const doc = parser.parseFromString(htmlString, 'text/html')
    let headerTextEle = doc.querySelector('.btn-notification__title')
    let subHeaderTextEle = doc.querySelector('.btn-notification__sub-title')
    this.headerText = headerTextEle ? headerTextEle.innerHTML : ''
    this.descriptionText = subHeaderTextEle ? subHeaderTextEle.innerHTML : ''
  },
  methods: {
    doAction () {
      this.showLoading()
      if (this.action.url.includes('meal-plans/update')) {
        // delaying route push to prevent UI breakage visibility
        this.delayedRouterPush({
          path: this.$appConfig.appUrlList.mealPlanUpdate
        })
      } else if (this.action.url.includes('workout-programs/update')) {
        // delaying route push to prevent UI breakage visibility
        this.delayedRouterPush({
          path: this.$appConfig.appUrlList.workoutUpdate
        })
      } else if (this.action.url.includes(window.location.hostname)) {
        // delaying route push to prevent UI breakage visibility
        this.delayedRouterPush({
          path: this.action.url.replace(
            window.location.protocol + '//' + window.location.hostname,
            ''
          )
        })
      } else {
        this.hideLoading()
      }
    }
  }
}
</script>
