<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16.5C0 7.40152 7.40215 0 16.5 0C25.5978 0 33 7.40152 33 16.5C33 25.5985 25.5978 33 16.5 33C7.40215 33 0 25.5985 0 16.5ZM1.26923 16.5C1.26923 24.8979 8.10213 31.7308 16.5 31.7308C24.8979 31.7308 31.7308 24.8979 31.7308 16.5C31.7308 8.10213 24.8979 1.26923 16.5 1.26923C8.10213 1.26923 1.26923 8.10213 1.26923 16.5ZM8.24985 15.8654H24.7498C25.1002 15.8654 25.3845 16.149 25.3845 16.5C25.3845 16.8509 25.1002 17.1346 24.7498 17.1346H8.24985C7.89954 17.1346 7.61523 16.8509 7.61523 16.5C7.61523 16.149 7.89954 15.8654 8.24985 15.8654Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconDecrease',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-decrease': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
