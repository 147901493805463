<template>
  <div :style="modalStyle" class="modal-image recipe-iframe-wrap" id="myModal" scroll="no">
    <span class="modal-image__close" v-on:click="close()"><img :src="imagesUrl + 'close-btn.svg'" alt="popup" /></span>
    <iframe allow="autoplay; fullscreen" allowfullscreen class="modal-image__content"
            :src="url" frameborder="0"    height="250"
            style=""
            title="recipe"></iframe>
  </div>
</template>

<script>
export default {
  name: 'CustomIframePopup',
  props: {
    url: {
      required: true
    }
  },
  data: function () {
    return {
      imagesUrl: this.$appConfig.imagesUrl,
      modalStyle: { opacity: 0 }
    }
  },
  mounted () {
    this.modalStyle.opacity = 1
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
</style>
