import BaseAPI from '../BaseAPI.js'
import axios from 'axios'
export default class AccountDeleteApi extends BaseAPI {
  _resource ='account-delete'
  _chatApiBaseUrl = ''

  fetchAccountDeleteConfirmationContent () {
    const url = this._baseURL
    const pathArr = [this._resource]
    return axios.get(url + this._getPath(pathArr))
  }
  sendAccountDeleteLinkEmail (email) {
    const url = this._baseURL
    const pathArr = [this._resource]
    return axios.post(url + this._getPath(pathArr), email)
  }

  verifyEmail (token) {
    const url = this._baseURL
    const pathArr = [this._resource]
    return axios.get(url + this._getPath(pathArr) + '/verify-email/' + token)
  }

  fetchAccountDeletionStatus (token) {
    const url = this._baseURL
    const pathArr = [this._resource]
    return axios.get(url + this._getPath(pathArr) + '/status/' + token)
  }
}
