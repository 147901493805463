<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0ZM15.1877 10.2753L7.85433 15.2753C7.798 15.3137 7.73233 15.3333 7.66667 15.3333C7.61333 15.3333 7.55967 15.3207 7.511 15.2947C7.40167 15.237 7.33333 15.1237 7.33333 15V5C7.33333 4.87633 7.40167 4.763 7.511 4.70533C7.62033 4.64733 7.75233 4.65533 7.85467 4.72467L15.188 9.72467C15.2787 9.78667 15.3333 9.88967 15.3333 10C15.3333 10.1103 15.2787 10.2133 15.1877 10.2753Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconPlay',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-play': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
