<template>
    <div>
        <div class="pc-popup-period-tracker">
            <popup-title>
              <popup-button-close @click="closePopup"/>
            </popup-title>
            <popup-body class="text-center">
                <popup-content>
                    <popup-text-body class="pc-popup-period-tracker__description"
                                     v-html="$t('message[\'period-tracker.disclaimer-description\']')">
                    </popup-text-body>
                </popup-content>
                <popup-footer>
                    <button-primary :text="$t('message[\'period-tracker.disclaimer-confirm\']')"
                                    @click="doAction"/>
                </popup-footer>
            </popup-body>
        </div>
    </div>
</template>

<script>
import Popup from '../../../../../global/popups/Popup'
import PopupButtonClose from '../../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupTextHeading from '../../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupTextSubHeading from '../../../../../global/popups/popup-sub-components/PopupTextSubHeading'
import PopupTextBody from '../../../../../global/popups/popup-sub-components/PopupTextBody'
import PopupBody from '../../../../../global/popups/popup-sub-components/PopupBody'
import PopupFooter from '../../../../../global/popups/popup-sub-components/PopupFloatingFooter'
import PopupContent from '../../../../../global/popups/popup-sub-components/PopupContent'
import PopupTitle from '../../../../../global/popups/popup-sub-components/PopupTitle'
import PopupHead from '../../../../../global/popups/popup-sub-components/PopupHead'
import ButtonPrimary from '../../../../../global/buttons/ButtonPrimary'

export default {
  name: 'DisclaimerConfirm',
  components: {
    ButtonPrimary,
    Popup,
    PopupButtonClose,
    PopupTextHeading,
    PopupTextSubHeading,
    PopupTextBody,
    PopupBody,
    PopupFooter,
    PopupContent,
    PopupTitle,
    PopupHead

  },
  methods: {
    closePopup () {
      this.$emit('close')
    },
    doAction () {
      this.$emit('next')
    }
  },
  mounted () {
    this.$emit('mounted')
  }
}
</script>

<style scoped>

</style>
