export default {
  methods: {
    closeAnimatedPopup (callback = null) {
      this.setCloseAnimateClass()
      if (callback) {
        setTimeout(() => {
          callback()
          this.resetPopupClass()
        }, 750)
      }
    },
    setCloseAnimateClass () {
      document.body.classList.add('modal-open--closing')
    },
    resetPopupClass () {
      document.body.classList.remove('modal-open--closing')
    },
    submitCloseEvent (popup, through) {
      this.logEvent('General.PopupClosed', { popup: popup, through: through }, false)
    }
  }
}
