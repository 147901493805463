import { render, staticRenderFns } from "./PageSignUpPaymentConfirm.vue?vue&type=template&id=881642a4&scoped=true"
import script from "./PageSignUpPaymentConfirm.vue?vue&type=script&lang=js"
export * from "./PageSignUpPaymentConfirm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "881642a4",
  null
  
)

export default component.exports