import { mapGetters } from 'vuex'
import DashBoardService from '../../../../services/DashboardService'

export default {
  props: {
    exerciseID: {
      default: 0
    },
    exerciseName: {
      default: ''
    },
    dayName: { default: '' },
    weekName: { default: '' },
    sets: {
      default: ''
    },
    workoutID: {
      default: 0
    },
    metrics: {
      default: function () {
        return []
      },
      type: Array
    },
    entries: {
      default: function () {
        return []
      },
      type: Array
    }
  },
  data: function () {
    return {
      status: 'loading',
      toDeleteExercise: 0,
      requestDelete: false,
      isLoaded: false,
      history: [],
      deletedLogs: [],
      unit: '',
      timeZone: '',
      genericExerciseID: null
    }
  },
  mounted () {
    this.loadData()
    const activityLogData = {
      exercise_title: this.exerciseName
    }
    this.logEvent('WorkoutLog.ViewedExerciseHistory', activityLogData, false)
  },
  computed: {
    ...mapGetters({
      service: 'singleExercisePageStore/getCurrentWorkoutLogService'
    })
  },
  methods: {
    reloadData () {
      this.loadData(true)
      this.$emit('reload')
    },

    loadData (force = false) {
      this.status = 'loading'
      const dashBoardService = new DashBoardService()
      const userPromise = dashBoardService.getUserData()
      const workoutLogPromise = this.service.getLogByExercise()
      Promise.all([userPromise, workoutLogPromise])
        .then((values) => {
          const logData = values[1]
          const userData = values[0]
          this.unit = userData.measurement_system
          this.timeZone = userData.timezone
          this.genericExerciseID = logData.data.exercise.id
          this.service
            .getGenericExerciseHistory(logData.data.exercise.id, true)
            .then((history) => {
              this.isLoaded = true
              this.history = []
              this.$nextTick(() => {
                this.history = history.data.history
              })
            })
            .finally(() => {
              this.hideLoading()
              this.status = 'loaded'
            })
        })
        .catch(() => {
          this.hideLoading()
          this.status = 'loaded'
        })
    },
    setSets (sets) {
      if (sets) {
        return sets.replace(/ /g, '/')
      }
      return this.sets
    },
    goToEdit (week, day, exercise) {
      this.$router.push({
        path: this.$appConfig.appUrlList.workout,
        query: {
          workout: this.workoutID,
          week: week,
          day: day,
          exercise: exercise,
          'workout-log': 'edit',
          hash: Math.random().toString(36).substring(7)
        }
      })
    },
    loadHistory () {
      if (!this.genericExerciseID) {
        return
      }
      this.service
        .getGenericExerciseHistory(this.genericExerciseID, true)
        .then((history) => {
          this.history = []
          this.$nextTick(() => {
            this.history = history.data.history
          })
        })
        .finally(() => {
          this.hideLoading()
        })
    },
    deleteSuccess (exerciseID) {
      this.deletedLogs.push(parseInt(exerciseID))
      this.loadHistory()
      this.requestDelete = false
      this.$emit('deleted', exerciseID)
    },
    goToDelete (exercise) {
      this.requestDelete = true
      this.toDeleteExercise = exercise
    },
    showItem (ID) {
      return !this.deletedLogs.includes(parseInt(ID))
    },
    gotoNextExercise () {
      this.$emit('nextExercise', true)
    }
  }
}
