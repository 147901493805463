<template>
  <popup
    @hide="closePopup"
    ref="mealUpdateDislikesModal"
    :visible="visible"
    v-if="visible"
    modal-class="gc-popup gc-popup-smart-dislikes"
    :no-close-on-backdrop="noCloseOnBackdrop"
  >
    <popup-title>
      <popup-button-close :style="{'visibility' : noCloseOnBackdrop? 'hidden' :'visible' }"
                          @hide="closePopup"
                          @click="dontUpdateDislikes"/>
    </popup-title>
    <popup-body>
      <popup-head class="text-center">
        <popup-text-heading v-if="status==='init'">{{ title }}</popup-text-heading>
        <popup-text-heading v-else-if="status==='success'">{{ successMsg }}</popup-text-heading>
        <popup-text-heading v-else-if="status==='error'">{{ errorMessage }}</popup-text-heading>
        <loading-buffer v-else-if="status==='loading'" :height="'75'"></loading-buffer>
      </popup-head>
      <popup-content>
        <popup-text-body
          v-if="status==='init'"
          class="text-center info-modal__subtitle mb-2"
        >{{ updateDislikesMessage }}
        </popup-text-body>
        <div class="popup-chk-wrap" v-if="status==='init'">
          <ul class="popup-chk-ul">
            <li :key="ingredient.id" class="popup-chk-li" v-for="ingredient in ingredients">
              <hr class="popup-chk-ingredient-separator"/>
              <input :id="ingredient.name" :value="ingredient.name" @change="statusText($event)" class="popup-chk-input" type="checkbox" v-model="ingredient.active"/>&nbsp;<label :for="ingredient.name" class="popup-chk-ingredient-label">{{shorten(ingredient.name)}}</label>
            </li>
          </ul>
        </div>
        <div v-if="status==='success'" class="text-center">
            <icon-complete-filled :size="'lg3'" v-if="status==='success'"/>
        </div>
      </popup-content>
      <popup-footer v-if="status==='init'">
        <p class="update-dislikes">{{$tc('message["swap.update-dislikes-added-dislikes"]',selectedIngredientsCount(), [ selectedIngredientsCount() ])}}</p>
        <button-primary :disabled="disableConfirmation"
                        class="gc-popup-smart-dislikes__button-confirmation"
                        v-on:click="updateDislikes()" :text="confirmButtonText"></button-primary>
        <button-secondary v-on:click="dontUpdateDislikes()" :text="noButtonText"/>
      </popup-footer>
    </popup-body>
  </popup>
</template>

<script>
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import ProfileService from '../../../../../services/ProfileService'
import BaseComponent from '../../global/base/BaseComponent'
import DefineInclude from '../../../../../DefineInclude'
import {mapGetters, mapMutations} from 'vuex'
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import Popup from '../../../../global/popups/Popup'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupFooter from '../../../../global/popups/popup-sub-components/PopupFooter'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import ButtonSecondary from '../../../../global/buttons/ButtonSecondary'
import PopupTextBody from '../../../../global/popups/popup-sub-components/PopupTextBody'
import IconCompleteFilled from '../../../../root/icons/IconCompleteFilled'
import NutritionService from '../../../../../services/nutrition/NutritionService'

export default {
  name: 'PopupSmartDislikes',
  props: ['data', 'visible'],
  mixins: [popupAnimationMixin],
  components: {
    IconCompleteFilled,
    PopupTextBody,
    ButtonSecondary,
    PopupContent,
    ButtonPrimary,
    PopupFooter,
    PopupTextHeading,
    PopupHead,
    PopupBody,
    PopupButtonClose,
    PopupTitle,
    Popup,
    LoadingBuffer
  },
  extends: BaseComponent,
  data: function () {
    return {
      successImage: DefineInclude.imagesUrl + 'swap-success.svg',
      title: '',
      status: 'loading',
      loading: true,
      updateDislikesMessage: '',
      ingredients: [],
      msi: {},
      multiIngredients: false,
      confirmButtonText: '',
      noButtonText: '',
      successMsg: 'success',
      errorMessage: 'Error',
      profileService: new ProfileService()
    }
  },
  created () {
    this.status = 'init'
    this.title = this.$i18n.t('message["swap.update-dislikes-title"]')
    this.confirmButtonText = this.$i18n.t('message["swap.update-dislikes-confirm-button"]')
    this.noButtonText = this.$i18n.t('message["swap.update-dislikes-decline-button"]')
    this.ingredients = this.data.ingredients
    this.updateDislikesMessage = this.$i18n.tc('message["swap.update-dislikes-message"]', this.ingredientsToDislikeCount)
    this.msi = this.getMealSwapIngredients()
    this.logEvent('SmartDislikes.Prompted', {
      no_of_suggestions: this.ingredients.length
    })
    this.getDislikedIngredients()
  },
  computed: {

    noCloseOnBackdrop: function () {
      return this.status === 'success' || this.status === 'loading'
    },

    ingredientsToDislikeCount () {
      return this.data.ingredients.length
    },
    disableConfirmation () {
      let ct = 0
      for (let i = 0; i < this.ingredients.length; i++) {
        if (this.ingredients[i].active) {
          ct++
        }
      }
      return ct < 1
    }
  },
  methods: {
    ...mapGetters({
      getMealSwapIngredients: 'mealSwapStore/getMealSwapIngredients',
      getMealSwapStatus: 'mealSwapStore/getMealSwapStatus'
    }),
    ...mapMutations({
      updateMealSwapIngredients: 'mealSwapStore/updateMealSwapIngredients'
    }),
    async getDislikedIngredients () {
      await Promise.all([
        this.profileService.setProfileData()
      ]).catch(() => {
        this.status = 'init'
      })
      let excludedIngredients = this.profileService.getExcludedIngrediants()
      let ingredientsArray = this.ingredients.map(x => x.id)
      let commonIngredients = ingredientsArray.filter(e => excludedIngredients.indexOf(e) !== -1)
      if (commonIngredients.length > 0) {
        for (let i = 0; i < commonIngredients.length;) {
          const idxObj = this.ingredients.findIndex(object => {
            return object.id === commonIngredients[i]
          })
          this.ingredients.splice(idxObj, 1)
          i++
        }
      }
      if (this.ingredients.length < 1) {
        this.closePopup()
      }
    },
    updateDislikes () {
      let service = new ProfileService()
      let mealSwapStatus = this.getMealSwapStatus()

      this.status = 'loading'
      let excludedIngredients = this.ingredientsToExclude()
      let ingIds = this.idArray(excludedIngredients)
      this.successMsg = this.$i18n.t('message["swap.update-dislikes-success-message"]', this.successArray(excludedIngredients.ingredients))

      this.logEvent('SmartDislikes.CustomerAction', {
        status: 'accepted',
        no_of_suggestions: this.ingredients.length,
        no_acceptances: ingIds.length
      })

      service
        .setProfileData()
        .then(res => {
          service
            .addToExcludedIngredients({excluded_ingredients: ingIds})
            .then(() => {
              setTimeout(() => {
                this.status = 'success'
                this.title = this.$i18n.t('message["swap.update-dislikes-success-title"]')
                this.removeFromMSI(this.ingredients)
              }, 1500)
              try {
                let nutritionService = new NutritionService()
                let mealPlan = nutritionService.getMealPlan(parseInt(this.$route.params.mid))

                let meals = mealPlan
                  .getMealsDislikedIngredients(excludedIngredients.ingredients, this.data.mealID, mealSwapStatus)

                setTimeout(() => {
                  this.closePopup(null, () => {
                    if (meals.ingredients.length > 0) {
                      this.showSwapDislikesPopup(meals.ingredients, meals.mealIds, meals.refMealIds)
                    } else {
                      if (this.params.meal) {
                        this.$router.push({
                          path:
                            [DefineInclude.appUrlList.mealPlanBase, this.$route.params.mid].join('/'),
                          query: {
                            day: this.params.day
                          }
                        })
                      }
                    }
                  })
                }, 3000)
              } catch (e) {
                this.title = this.$i18n.t('message["swap.update-dislikes-error.similar-meals"]')
                this.status = 'error'
                this.errorMessage = this.$i18n.t('message["swap.update-dislikes-error.similar-meals"]')
              }
            }).catch((e) => {
              this.title = this.$i18n.t('message["swap.update-dislikes-error.adding-dislikes"]')
              this.status = 'error'
              this.errorMessage = this.$i18n.t('message["swap.update-dislikes-error.adding-dislikes"]')
            })
        }).catch((e) => {
          this.title = this.$i18n.t('message["swap.update-dislikes-error.retrieving-dislikes"]')
          this.status = 'error'
          this.errorMessage = this.$i18n.t('message["swap.update-dislikes-error.retrieving-dislikes"]')
        })
    },
    statusText () {
      const ct = this.selectedIngredientsCount()
      return [ ct, ct > 1 ? 's' : '' ]
    },
    selectedIngredientsCount () {
      let ct = 0
      for (let i = 0; i < this.ingredients.length; i++) {
        if (this.ingredients[i].active) {
          ct++
        }
      }

      return ct
    },
    shorten (name) {
      return name.indexOf('(') > -1 ? name.substring(0, name.indexOf('(') - 1).trim() : name.trim()
    },
    dontUpdateDislikes () {
      let msi = this.msi
      let ingredients = this.data.ingredients
      for (let rm = 0; rm < ingredients.length; rm++) {
        delete msi[ingredients[rm].name]
        this.updateMealSwapIngredients(msi)
      }
      this.logEvent('SmartDislikes.CustomerAction', {
        status: 'rejected',
        no_of_suggestions: this.ingredients.length
      })
      this.closePopup()
    },
    ingredientsToExclude () {
      let ingredients = this.ingredients
      let excludedIngredients = []
      let excludedNames = []
      for (let i = 0; i < ingredients.length; i++) {
        if (ingredients[i].active) {
          excludedIngredients.push(ingredients[i])
          excludedNames.push(ingredients[i].name)
        }
      }
      return {ingredients: excludedIngredients, names: excludedNames}
    },
    idArray (array) {
      let ids = []
      for (let i = 0; i < array.ingredients.length; i++) {
        ids.push(array.ingredients[i].id)
      }
      return ids
    },
    closePopup (e, callback) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('hide-update-dislikes')
        if (callback) {
          callback()
        }
      })
    },
    showSwapDislikesPopup (ingredients, otherMeals, refMealIds) {
      this.$emit('show-swap-dislikes', {ingredients, otherMeals, refMealIds})
    },
    removeFromMSI (ingredients) {
      let msi = this.msi
      for (let rm = 0; rm < ingredients.length; rm++) {
        delete msi[ingredients[rm].name]
      }
      this.updateMealSwapIngredients(msi)
    },
    messageArray () {
      return (this.data.ingredients.length === 1 ? ['', 'it'] : ['s', 'them'])
    },
    successArray (excludedIngredients) {
      let tmp = ''
      if (excludedIngredients.length === 1) {
        tmp = this.shorten(excludedIngredients[0].name)
      } else {
        for (let i = 0; i < excludedIngredients.length; i++) {
          tmp = tmp + (i === excludedIngredients.length - 1 ? ', ' : '') + this.shorten(excludedIngredients[i].name) + (i < excludedIngredients.length - 1 ? ', ' : '')
        }
      }
      return [tmp]
    }
  }
}
</script>
