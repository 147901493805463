import TrackerService from './TrackerService'
import APIErrorHandler from './api-handler/APIErrorHandler'
import moment from 'moment'

export default class MoodTrackerService {
    _trackerService = new TrackerService()

    constructor () {
      this._trackerService.initiate('mood-tracker')
    }

    /**
   *
   * @param dateRange - {moment firstDay, moment today}
   * @returns {Promise<unknown>}
   */
getDataByDateRange (dateRange) {
      return new Promise((resolve, reject) => {
        this._trackerService.query(dateRange.firstDay).then(data => {
          resolve(data)
        }).catch(err => {
          if (err.response.status === 500) {
            this._handleServerError(err)
            reject(err)
          } else {
            this._logSentry(err)
            reject(err)
          }
        })
      })
    }

    add (value, comment) {
      if (!comment) {
        comment = null
      }
      return new Promise((resolve, reject) => {
        this._trackerService.track(value, comment).then(data => {
          resolve(data)
        }).catch(err => {
          if (err.data && err.data.errors.length > 0) {
            this._handleServerError(err)
            reject(err)
          }
          if (err.response.status === 500) {
            this._handleServerError(err)
            reject(err)
          } else {
            this._logSentry(err)
            reject(err)
          }
        })
      })
    }

    update (id, value, comment) {
      if (!comment) {
        comment = null
      }
      return new Promise((resolve, reject) => {
        this._trackerService.update(id, value, comment).then(data => {
          resolve(data)
        }).catch(err => {
          console.log(err)
          if (err.response.status === 500) {
            this._handleServerError(err)
            reject(err)
          } else {
            this._logSentry(err)
            reject(err)
          }
        })
      })
    }

    delete (id) {
      return new Promise((resolve, reject) => {
        this._trackerService.delete(id).then(data => {
          resolve(data)
        }).catch(err => {
          if (err.response.status === 500) {
            this._handleServerError(err)
            reject(err)
          } else {
            this._logSentry(err)
            reject(err)
          }
        })
      })
    }

    getWeekDateRange () {
      let curr = new Date()
      let first = curr.getDate() - curr.getDay()
      let firstDay = new Date(curr.setDate(first))

      let dateRange = {
        'firstDay': moment(firstDay).set({hour: 0, minute: 0, second: 0, millisecond: 0})
      }
      return dateRange
    }

    getMonthDateRange () {
      let date = new Date()
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1)

      let dateRange = {
        'firstDay': moment(firstDay).set({hour: 0, minute: 0, second: 0, millisecond: 0})
      }
      return dateRange
    }

    getTodayDateRange () {
      let firstDay = new Date()

      let dateRange = {
        'firstDay': moment(firstDay).set({hour: 0, minute: 0, second: 0, millisecond: 0})
      }
      return dateRange
    }

    /**
   * Log and show error popup
   * @param response
   * @private
   */
    _handleServerError (response) {
      let sentryEventID = APIErrorHandler.logSentry(response)
      APIErrorHandler.showErrorPopup(sentryEventID)
    }

    _logSentry (response) {
      APIErrorHandler.logSentry(response)
    }
}
