<template>
  <popup
    :visible="true"
    @hide="closePopup"
    modal-class="gc-popup-contact-support"
    v-if="true"
  >
    <popup-title>
      <popup-button-close @click="closePopup"></popup-button-close>
    </popup-title>
    <validation-observer ref="form" v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="handleSubmit(submitForm)">
        <popup-body class="text-center">
          <popup-head class="gc-popup-contact-support__head">
            <popup-text-heading>{{
              $t("message['settings.contact-support']")
            }}</popup-text-heading>
          </popup-head>
          <popup-content class="text-left">
            <popup-text-body>
              <validation-provider
                class="gc-popup-contact-support__input-wrapper"
                tag="div"
                :vid="'full_name'"
                :name="$t('message[\'form.full-name\']')"
                rules="required"
                v-slot="{ errors }"
              >
                <text-body-small class="gc-popup-contact-support__label">
                  {{ $t("message['form.full-name']") }}
                </text-body-small>
                <text-input
                  v-model="formData.fullName"
                  :error-message="errors[0]"
                  :placeholder="$t('message[\'form.full-name\']')"
                />
              </validation-provider>
              <validation-provider
                class="gc-popup-contact-support__input-wrapper"
                tag="div"
                :vid="'email'"
                :name="$t('message[\'form.email\']')"
                rules="required|email"
                v-slot="{ errors }"
              >
                <text-body-small class="gc-popup-contact-support__label">
                  {{ $t("message['auth.email']") }}
                </text-body-small>
                <text-input
                  v-model="formData.email"
                  :error-message="errors[0]"
                  :placeholder="$t('message[\'auth.email\']')"
                />
              </validation-provider>
              <validation-provider
                class="gc-popup-contact-support__input-wrapper"
                tag="div"
                :vid="'subject'"
                :name="$t('message[\'form.subject\']')"
                rules="required"
                v-slot="{ errors }"
              >
                <text-body-small class="gc-popup-contact-support__label">
                  {{ $t("message['form.subject']") }}
                </text-body-small>
                <text-input
                  v-model="formData.subject"
                  :error-message="errors[0]"
                  :placeholder="$t('message[\'form.subject\']')"
                />
              </validation-provider>
              <validation-provider
                class="gc-popup-contact-support__input-wrapper"
                tag="div"
                :vid="'message'"
                :name="$t('message[\'form.message\']')"
                rules="required"
                v-slot="{ errors }"
              >
                <text-body-small class="gc-popup-contact-support__label">
                  {{ $t("message['form.message']") }}
                </text-body-small>
                <text-area
                  v-model="formData.message"
                  :rows="3"
                  :error-message="errors[0]"
                  :placeholder="$t('message[\'form.message\']')"
                />
              </validation-provider>
              <text-error>{{ error }}</text-error>
            </popup-text-body>
          </popup-content>
          <popup-footer>
            <button-primary
              class="pc-contact-us__button-submit"
              :disabled="invalid || state === 'submitted'"
              type="submit"
              :text="$t('message[\'form.send-message\']')"
            />
          </popup-footer>
        </popup-body>
      </form>
    </validation-observer>
  </popup>
</template>

<script>
import TextBodySmall from '../typography/TextBodySmall'
import ContactService from '../../../services/ContactService'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import PopupTextHeading from './popup-sub-components/PopupTextHeading'
import PopupTextBody from './popup-sub-components/PopupTextBody'
import Popup from './Popup'
import PopupTitle from './popup-sub-components/PopupTitle'
import PopupBody from './popup-sub-components/PopupBody'
import PopupHead from './popup-sub-components/PopupHead'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import TextInput from '../inputs/TextInput'
import TextArea from '../inputs/TextArea'
import PopupFooter from './popup-sub-components/PopupFooter'
import ButtonPrimary from '../buttons/ButtonPrimary'
import PopupContent from './popup-sub-components/PopupContent'
import TextError from '../../layout/template-1/sign-up/page-components/TextError'

export default {
  name: 'PopupContactSupport',
  components: {
    TextError,
    ButtonPrimary,
    PopupFooter,
    ValidationProvider,
    ValidationObserver,
    TextInput,
    TextArea,
    Popup,
    PopupTitle,
    PopupContent,
    PopupBody,
    PopupHead,
    PopupButtonClose,
    PopupTextBody,
    PopupTextHeading,
    TextBodySmall
  },

  data: function () {
    return {
      state: 'init',
      error: '',
      formData: {
        fullName: '',
        email: '',
        subject: '',
        message: ''
      }
    }
  },
  mixins: [popupAnimationMixin],
  methods: {
    submitForm () {
      this.showLoading()
      if (this.state === 'submitted') {
        return
      }
      this.state = 'submitted'
      this.error = ''
      const contactService = new ContactService()
      contactService
        .submitContact(this.formData)
        .then(() => {
          this.state = 'success'
          setTimeout(() => {
            this.closePopup()
          }, 1000)
        })
        .catch((error) => {
          this.state = 'init'
          if (error.response.status === 422) {
            if (
              typeof error.response.data.type !== 'undefined' &&
              error.response.data.type === 'logic'
            ) {
              this.error =
                error.response.data.errors[0] ||
                this.$i18n.t('message["general.try-again"]')
            } else {
              this.$refs.form.setErrors(error.response.data.errors)
            }
          } else {
            this.error = this.$i18n.t('message["general.try-again"]')
          }
        })
        .finally(() => {
          this.hideLoading()
        })
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.visible = false
        this.$emit('close')
      })
    }
  }
}
</script>
