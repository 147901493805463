<template>
    <transition name="fade" mode="out-in">
      <div :class="componentClasses" v-show="showComponent">
        <button-secondary-accordion
          :class="accordionClasses"
          :text="group.name"
          :subText="setCounts"
          ref="accordion"
          @click="parentSelect()">
          <div>
            <input-check-box-squared
              class="pc-input-shopping-list-group-ingredients-group__input-checkbox"
              @click="confirmToggleDislikedGroup()"
              :id="0"
              v-model="selectAll"
            />
          </div>
        </button-secondary-accordion>

        <div class="pc-input-shopping-list-group-ingredients-group__sub-category-wrapper" v-show="!changingAll&&showSubCatogories">
          <ingredients-sub-accordion
            ref="subCategory"
            v-for="(subCategory) in group.subCategory"
            :key="subCategory.id"
            :count="count"
            v-model="selectedIngredients"
            :subCategory="subCategory"
            :type="type"

          />
        </div>
        <popup-input-group-ingredients-confirmation :visible="confirmPopup.visible" :category-name="group.name"
                                                    @close="closeConfirmPopup"
                                                    @confirm="toggleDislikedGroup"/>
      </div>
    </transition>
</template>
<script>
import ButtonSecondaryAccordion from '../../../../../../global/buttons/ButtonSecondaryAccordion'
import IngredientsSubAccordion from './InputGroupIngredientsSubGroup'
import IconDislike from '../../../../../../root/icons/IconDislike'
import InputCheckBoxSquared from '../../../../../../global/inputs/InputCheckBoxSquared'
import PopupInputGroupIngredientsConfirmation from './PopupInputGroupIngredientsConfirmation'
import GridCol from '../../../../../../global/grid/GridCol'

export default {
  name: 'InputGroupIngredientsGroup',
  props: {
    type: {
      default: ''
    },
    count: {
      type: Number,
      default: 1
    },
    group: {
      default: function () {
        return {}
      }
    },
    value: {
      default: function () {
        return []
      },
      type: Array
    }
  },
  data () {
    return {
      confirmPopup: {
        visible: false
      },
      selectedIngredients: [],
      showSubCatogories: false,
      allSelectedSubCatogories: [],
      isAllSelected: false,
      changingAll: false,
      selectAll: [],
      selectedCounts: [],
      totalSelectedCount: 0
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: function () {
        this.selectedIngredients = this.value
      }
    },
    selectedIngredients: function () {
      this.isAllSelected = this.$refs.subCategory.every(cat => {
        return cat.isSubGroupDislike
      })
      if (this.isAllSelected) {
        this.selectAll = [0]
      } else {
        this.selectAll = []
      }
      this.setSelectedIngredientsCount()
      this.$emit('input', this.selectedIngredients)
    }
  },
  components: {
    GridCol,
    PopupInputGroupIngredientsConfirmation,
    InputCheckBoxSquared,
    ButtonSecondaryAccordion,
    IngredientsSubAccordion,
    IconDislike

  },
  computed: {
    showComponent: function () {
      return !this.changingAll && !((this.type === 'cart' && this.isAllSelected) || (this.totalSelectedCount === 0 && this.type === 'purchased'))
    },

    accordionClasses: function () {
      return {
        'pc-input-shopping-list-group-ingredients-group__accordion': true,
        'pc-input-shopping-list-group-ingredients-group__accordion--all-selected': this.isAllSelected
      }
    },
    componentClasses: function () {
      return {
        'pc-input-shopping-list-group-ingredients-group': true,
        'pc-input-shopping-list-group-ingredients-group--hidden': this.showComponent
      }
    },

    oneSelect () {
      return this.totalSelectedCount > 0
    },
    setCounts: function () {
      if (this.type === 'cart') {
        return ''
      } else if (this.isAllSelected) {
        return this.$i18n.t('message["shopping-list-group.all-selected"]')
      }

      return this.$i18n.tc('message["shopping-list-group.selected"]', this.totalSelectedCount, { count: this.totalSelectedCount })
    }
  },
  mounted () {
    this.isAllSelected = this.$refs.subCategory.every(cat => {
      return cat.isSubGroupDislike
    })
    if (this.isAllSelected) {
      this.selectAll = [0]
    } else {
      this.selectAll = []
    }
    this.setSelectedIngredientsCount()
  },
  methods: {
    scrollToTitle () {
      this.$refs.accordion.$el.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'nearest'})
    },
    getID () {
      return this.group.id
    },
    setSelectedIngredientsCount () {
      let total = 0
      this.$refs.subCategory.forEach(cat => {
        total += cat.subGroupDislikesCount
      })

      this.totalSelectedCount = total
    },
    selectedAll () {
      if (this.changingAll) {
        return
      }
      if (this.type !== 'purchased') {
        this.changingAll = true
      }
      this.$refs.subCategory.forEach((ingredient, index) => {
        setTimeout(() => {
          if (index === this.group.subCategory.length - 1) {
            setTimeout(() => {
              this.changingAll = false
            }, 300)
          }
          ingredient.selectedAll()
        }, index * 20)
      })
    },
    deselectAll () {
      if (this.changingAll) {
        return
      }
      if (this.type !== 'cart') {
        this.changingAll = true
      }

      this.$refs.subCategory.forEach((ingredient, index) => {
        setTimeout(() => {
          if (index === this.group.subCategory.length - 1) {
            setTimeout(() => {
              this.changingAll = false
            }, 300)
          }
          ingredient.deselectAll()
        }, index * 20)
      })
    },
    toggleDislikedGroup () {
      sessionStorage.setItem('confirmShoppingListParentSelect', '1')
      this.closeConfirmPopup()
      if (this.isAllSelected) {
        this.deselectAll()
      } else {
        this.selectedAll()
      }
    },
    confirmToggleDislikedGroup () {
      let confirmed = sessionStorage.getItem('confirmShoppingListParentSelect') || '0'
      if (confirmed === '0' && this.type === 'cart' && !this.isAllSelected) {
        this.selectAll = []
        this.showConfirmPopup()
      } else {
        this.toggleDislikedGroup()
      }
    },
    showConfirmPopup () {
      this.confirmPopup.visible = true
    },
    closeConfirmPopup () {
      this.confirmPopup.visible = false
    },
    hideAccordion () {
      this.showSubCatogories = false
      this.$refs.accordion.stateSelected = false
    },
    parentSelect () {
      if (this.showSubCatogories) {
        this.showSubCatogories = false
        this.$emit('changed')
      } else {
        this.showSubCatogories = true
        this.$emit('expanded')
        this.$emit('changed')
      }
    }
  }
}
</script>
