<template>
  <div :class="componentClasses" v-on="$listeners">
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_5368_32176)">
      <path d="M6.99146 5.1762C6.69362 5.1762 6.4521 4.93467 6.4521 4.63684C6.4521 4.339 6.69363 4.09749 6.99146 4.09749H23.4606C23.7584 4.09749 24 4.33902 24 4.63684C24 4.93468 23.7584 5.1762 23.4606 5.1762H6.99146Z" fill="white"/>
      <path d="M1.00286 4.41132L1.68452 5.59202L3.56213 2.33987C3.7099 2.0827 4.0382 1.99403 4.29536 2.14181C4.55253 2.28958 4.6412 2.61787 4.49343 2.87504L2.1537 6.9276C2.10789 7.01001 2.03981 7.0814 1.9521 7.13179C1.69494 7.27956 1.36664 7.1909 1.21887 6.93373L0.0726275 4.94834C-0.000834137 4.8211 -0.0186345 4.68539 0.0195348 4.54353C0.057704 4.40165 0.141196 4.29319 0.268585 4.21999L0.275957 4.21576C0.530068 4.06972 0.856317 4.15749 1.00286 4.41132Z" fill="white"/>
      <path d="M6.99146 13.0398C6.69362 13.0398 6.4521 12.7982 6.4521 12.5004C6.4521 12.2026 6.69363 11.9611 6.99146 11.9611H23.4606C23.7584 11.9611 24 12.2026 24 12.5004C24 12.7983 23.7584 13.0398 23.4606 13.0398H6.99146Z" fill="white"/>
      <path d="M1.00286 12.2749L1.68452 13.4556L3.56213 10.2034C3.7099 9.94627 4.0382 9.8576 4.29536 10.0054C4.55253 10.1531 4.6412 10.4814 4.49343 10.7386L2.1537 14.7912C2.10789 14.8736 2.03981 14.945 1.9521 14.9954C1.69494 15.1431 1.36664 15.0545 1.21887 14.7973L0.0726275 12.8119C-0.000821292 12.6847 -0.0186345 12.5489 0.0195348 12.4071C0.057704 12.2652 0.141209 12.1568 0.268585 12.0836L0.275957 12.0793C0.530068 11.9333 0.856317 12.0211 1.00286 12.2749Z" fill="white"/>
      <path d="M6.99146 20.9034C6.69362 20.9034 6.4521 20.6618 6.4521 20.364C6.4521 20.0662 6.69363 19.8247 6.99146 19.8247H23.4606C23.7584 19.8247 24 20.0662 24 20.364C24 20.6618 23.7584 20.9034 23.4606 20.9034H6.99146Z" fill="white"/>
      <path d="M1.00284 20.1384L1.68452 21.3192L3.56213 18.067C3.7099 17.8098 4.0382 17.7212 4.29536 17.8689C4.55253 18.0167 4.6412 18.345 4.49343 18.6022L2.1537 22.6547C2.10789 22.7372 2.03981 22.8085 1.9521 22.8589C1.69494 23.0067 1.36664 22.918 1.21887 22.6609L0.0726275 20.6755C-0.000834137 20.5482 -0.0186345 20.4125 0.0195476 20.2706C0.0577169 20.1288 0.141209 20.0203 0.268585 19.9471L0.275957 19.9429C0.530068 19.7969 0.856304 19.8846 1.00284 20.1384Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_5368_32176">
      <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
      </defs>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconMarkRead',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-mark-read': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
