import Vue from 'vue'
import Vuex from 'vuex'
import WorkoutProgramsService from '../../services/WorkoutProgramsService'
import MealPlanService from '../../services/MealPlanService'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    formServiceObj: {
      subscriptionForm: null,
      progressForm: null
    },
    formStatus: 'init'

  },
  mutations: {
    reset (state) {
      state.formServiceObj = {
        subscriptionForm: null,
        progressForm: null
      }
      state.formStatus = 'init'
      let workoutService = new WorkoutProgramsService()
      workoutService.reset()
      let mealPlanService = new MealPlanService()
      mealPlanService.reset()
    },
    setSubscriptionFormService (state, service) {
      state.formServiceObj.subscriptionForm = service
    },
    setProgressFormService (state, service) {
      state.formServiceObj.progressForm = service
    },
    /**
     *
     * @param state
     * @param value String init|ready|submitted
     */
    setFormStatus (state, value) {
      state.formStatus = value
    }

  },
  actions: {},
  getters: {
    getFormService (state) {
      return state.formServiceObj
    },
    getSubscriptionFormService (state) {
      return state.formServiceObj.subscriptionForm
    },
    getProgressFormService (state) {
      return state.formServiceObj.progressForm
    }
  }
}
