import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default {
  namespaced: true,
  state: {
    days: [1, 2, 3, 4, 5, 6, 7],
    people: 1,
    selectedItems: []
  },
  mutations: {
    incrementPeople (state) {
      // mutate state
      state.people++
    },
    decrementPeople (state) {
      // mutate state
      state.people--
    },
    people (state, payload) {
      // mutate state
      state.people = payload.count
    },
    days (state, payload) {
      state.selectedItems = []
      state.days = payload.days
    },
    selectedItems (state, payload) {
      state.selectedItems = payload.items
    }

  },
  actions: {

  },
  getters: {
    shoppedItems (state) {
      return state.selectedItems
    },
    shoppedItemsCount (state) {
      return state.selectedItems.length
    },
    peopleCount (state) {
      return parseInt(state.people)
    },
    days (state) {
      return state.days
    }
  }
}
