<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M25 12.5C25 19.4036 19.4036 25 12.5 25C5.59644 25 0 19.4036 0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5ZM12.1317 7.86849C11.9283 7.66509 11.9283 7.33532 12.1317 7.13192C12.3351 6.92852 12.6649 6.92852 12.8683 7.13192L17.8594 12.123C17.9589 12.2179 18.0208 12.3517 18.0208 12.5C18.0208 12.5927 17.9966 12.6798 17.9541 12.7552C17.931 12.7963 17.9023 12.8345 17.8683 12.8685L12.8683 17.8685C12.6649 18.0719 12.3351 18.0719 12.1317 17.8685C11.9283 17.6651 11.9283 17.3353 12.1317 17.1319L16.2428 13.0208H7.50001C7.21236 13.0208 6.97918 12.7876 6.97918 12.5C6.97918 12.2124 7.21236 11.9792 7.50001 11.9792H16.2424L12.1317 7.86849Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconArrowCircularFilled',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-dot': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
