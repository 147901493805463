import axios from 'axios'
import DefineInclude from '../../../DefineInclude'
import AuthService from '../../AuthService'
export default class {
  _token = null
  _headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }

  constructor () {
    this._token = ''
    let auth = new AuthService()
    this._setToken(auth.getToken())
  }

  /**
   * use to set the Auth token
   * @param token -auth token
   * @private
   */
  _setToken (token) {
    this._token = token
  }

  _getPath (pathArr) {
    return pathArr.join('/')
  }

  getHeaders () {
    let headers = this._headers
    headers['Authorization'] = 'Bearer ' + this._token
    headers['X-Api-Host'] = DefineInclude.apiUrl.replace(/\/$/, '')
    return headers
  }

  sendRecord (type, payload) {
    let url = DefineInclude.trainerFeedAPI
    let pathArr = ['me', 'events']
    const config = {
      params: { type: type },
      headers: this.getHeaders()
    }
    return axios.post(url + this._getPath(pathArr), payload, config)
  }
}
