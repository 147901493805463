<template>
  <popup
    class="pc-popup-general-exercise"
    :visible="visible"
    v-if="visible"
    size="md"
    @hide="closePopup()"
  >
    <popup-title>
      <popup-button-close @hide="closePopup($event)"></popup-button-close>
    </popup-title>
    <popup-body>
      <popup-content>
        <popup-head>
          <popup-text-heading class="text-center">
            {{ name }}
          </popup-text-heading>
        </popup-head>
        <div>
          <base-image
            class="pc-popup-general-exercise__image"
            :border-radius="'sm1'"
            v-if="videoOptions.cardComponent === ''"
            :src="videoOptions.poster"
          />
          <video-player
            class="pc-popup-general-exercise__video-player"
            v-if="showVideo"
            :disableFullScreen="true"
            :show-seek-bar="videoOptions.showSeekBar"
            :videoComponent="videoOptions.cardComponent"
            v-bind="videoOptions"
          />
          <div class="pc-popup-general-exercise__description">
            <text-body-small
              class="pc-popup-general-exercise__text"
              :line-height="'multi'"
              v-if="muscles && muscles.length > 0"
              :key="'popup-muscles'"
              v-html="showMuscles()"
            />
            <text-body-small
              class="pc-popup-general-exercise__text"
              :line-height="'multi'"
              v-for="(instruction, index) in instructions"
              :key="'popup-instruction-content' + index"
              v-html="instruction"
            />
          </div>
        </div>
      </popup-content>
    </popup-body>
  </popup>
</template>

<script>
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import NativeAppMixin from '../../../../../mixins/NativeAppMixin'
import UrlPattern from 'url-pattern'
import { getIdFromUrl } from 'vue-youtube'
import jsonp from 'jsonp'
import DashBoardService from '../../../../../services/DashboardService'
import Popup from '../../../../global/popups/Popup'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import PopupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import BaseImage from '../../../../root/Image'
import TextBodySmall from '../../../../global/typography/TextBodySmall'
import VideoPlayer from '../../../../global/players/VideoPlayer'

export default {
  name: 'PopupGeneralExercise',
  mixins: [popupAnimationMixin, NativeAppMixin],
  components: {
    Popup,
    PopupTitle,
    PopupButtonClose,
    PopupBody,
    PopupTextHeading,
    PopupHead,
    PopupContent,
    BaseImage,
    TextBodySmall,
    VideoPlayer
  },
  props: {
    name: {
      default: ''
    },
    visible: {
      default: false
    },
    muscles: {
      type: Array,
      default: function () {
        return []
      }
    },
    instructions: {
      type: String,
      default: ''
    },
    fullScreen: false,
    video: {
      default: ''
    },
    image: {
      default: ''
    }
  },
  data: function () {
    return {
      imagesUrl: this.$appConfig.imagesUrl,
      showVideo: false,
      videoOptions: {
        cardComponent: '',
        // youtube/vimeo
        showSeekBar: false,
        type: '',
        poster: '',
        url: '',
        id: '',
        controls: true,
        title: '',
        mute: false
      }
    }
  },

  mounted () {
    let dashBoardService = new DashBoardService()
    this.videoOptions.mute = dashBoardService.isExerciseVideoMuted()
    this.setVideoData()
  },
  methods: {
    setVideoData () {
      this.videoOptions.url = this.video.url
      this.videoOptions.poster = this.image.url
      if (this.getVimeoIdFromUrl(this.video.url)) {
        this.setVimeoVideoData()
      } else if (getIdFromUrl(this.video.url)) {
        this.setYoutubeVideoData()
      }
    },
    setYoutubeVideoData () {
      this.videoOptions.id = getIdFromUrl(this.video.url)
      this.videoOptions.cardComponent = 'YoutubeVideo'
      this.videoOptions.type = 'youtube'
      this.showVideo = true
    },
    setVimeoVideoData () {
      this.videoOptions.id = this.getVimeoIdFromUrl(this.video.url)
      this.videoOptions.type = 'vimeo'
      this.videoOptions.cardComponent = 'VimeoVideo'

      if (!this.videoOptions.poster) {
        this.getVimeoImage(this.videoOptions.id)
          .then((url) => {
            this.videoOptions.poster = url
          }).catch(() => {
            // do nothing
          }).finally(() => {
            this.showVideo = true
          })
      } else {
        this.showVideo = true
      }
    },
    getVimeoIdFromUrl (url) {
      const pattern = new UrlPattern(
        '(http(s)\\://)player.vimeo.com(\\::port)/video/:videoId(/)(?*)'
      )
      let matchUrl = pattern.match(url)
      if (!matchUrl) {
        const patternNotEmbed = new UrlPattern(
          '(http(s)\\://)vimeo.com(\\::port)/:videoId(/)(?*)'
        )
        matchUrl = patternNotEmbed.match(url)
        return matchUrl ? matchUrl.videoId : null
      } else {
        return matchUrl ? matchUrl.videoId : null
      }
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    },
    emitClose () {
      this.closePopup()
      this.$emit('close')
    },
    getVimeoImage (videoId) {
      return new Promise((resolve, reject) => {
        try {
          jsonp(
            'https://vimeo.com/api/oembed.json?url=https://vimeo.com/' +
              videoId +
              '?format=jsonp',
            null,
            (error, data) => {
              if (!error) {
                let height = parseInt(data.height)
                let width = parseInt(data.width)

                // checking for valid dimensions
                if (height === 0 || width === 0) {
                  reject(new Error('invalid image dimensions'))
                  return
                }

                // setting image width to defined value and height according to ratio
                let expectedWidth = 1200
                let ratio = expectedWidth / width
                let expectedHeight = Math.floor(height * ratio)

                let parts = data.thumbnail_url.split('_')
                let fileNameParts = parts[1].split('.')
                let extension = fileNameParts[fileNameParts.length - 1]
                parts[1] =
                  expectedWidth + 'x' + expectedHeight + '.' + extension
                let url = parts.join('_')

                // return vimeo image (if not empty)
                if (url && url.trim() !== '') {
                  resolve(url)
                } else {
                  reject(new Error('empty image'))
                }
              } else {
                reject(new Error('fetch failed'))
              }
            }
          )
        } catch (e) {
          reject(new Error('fetch failed'))
        }
      })
    },
    showMuscles () {
      return (
        '<strong>' +
        this.$i18n.t("message['workouts.muscles']") +
        ': </strong>' +
        this.muscles.join(', ')
      )
    }
  },
  beforeDestroy () {
    document
      .querySelector('body')
      .removeEventListener('click', this.linkNavigateClickHandler, true)
  }
}
</script>
