import BaseAPI from '../BaseAPI.js'
import axios from 'axios'
import DefineInclude from '../../../DefineInclude'

export default class PeriodTrackerApi extends BaseAPI {
  _resource ='me'
  _periodTrackerBaseUrl = ''

  constructor () {
    super()
    this._periodTrackerBaseUrl = DefineInclude.periodTrackerAPIUrl
  }

  getFirstTimePeriod () {
    const url = this._periodTrackerBaseUrl
    const pathArr = [this._resource, 'period_tracker', 'periods']
    return axios.get(url + this._getPath(pathArr), { headers: this._getCustomHeaders() })
  }

  setFirstTimePeriod (lastPeriodDate, lastPeriodLength, periodLength, cycleLength) {
    const url = this._periodTrackerBaseUrl
    const pathArr = [this._resource, 'period_tracker', 'periods']
    return axios.post(url + this._getPath(pathArr), {
      'last_period_date': lastPeriodDate,
      'actual_period_length': lastPeriodLength,
      'period_length': periodLength,
      'cycle_length': cycleLength
    }, { headers: this._getCustomHeaders() })
  }

  updateFirstTimePeriod (periodId, lastPeriodDate, periodLength, cycleLength) {
    const url = this._periodTrackerBaseUrl
    const pathArr = [this._resource, 'period_tracker', 'periods', periodId]
    return axios.put(url + this._getPath(pathArr), {
      'last_period_date': lastPeriodDate,
      'period_length': periodLength,
      'cycle_length': cycleLength
    }, { headers: this._getCustomHeaders() })
  }

  getPrediction () {
    const url = this._periodTrackerBaseUrl
    const pathArr = [this._resource, 'period_tracker', 'predict']
    return axios.get(url + this._getPath(pathArr), { headers: this._getCustomHeaders() })
  }

  updateCycle (cycleId, actualDate, periodLength) {
    const url = this._periodTrackerBaseUrl
    const pathArr = [this._resource, 'period_tracker', 'cycles', cycleId]
    return axios.put(url + this._getPath(pathArr), {
      'actual_date': actualDate,
      'period_length': periodLength
    }, { headers: this._getCustomHeaders() })
  }

  getPeriods () {
    const url = this._periodTrackerBaseUrl
    const pathArr = [this._resource, 'period_tracker', 'history']
    return axios.get(url + this._getPath(pathArr), { headers: this._getCustomHeaders() })
  }

  updatePeriod (periodHistoryId, actualDate, periodLength) {
    const url = this._periodTrackerBaseUrl
    const pathArr = [this._resource, 'period_tracker', 'periods', periodHistoryId]
    return axios.put(url + this._getPath(pathArr), {
      'actual_date': actualDate,
      'period_length': periodLength
    }, { headers: this._getCustomHeaders() })
  }

  updateAllCycles (cycles) {
    const url = this._periodTrackerBaseUrl
    const pathArr = [this._resource, 'period_tracker', 'record', 'cycles']
    return axios.post(url + this._getPath(pathArr), {
      cycles: cycles
    }, { headers: this._getCustomHeaders() })
  }

  _getCustomHeaders () {
    let headers = this._headers
    headers['Authorization'] = 'Bearer ' + this._token
    headers['X-Api-Host'] = DefineInclude.apiUrl.replace(/\/$/, '')

    return headers
  }
}
