import Vue from 'vue'
import Vuex from 'vuex'
import FirstLoadService from '../../services/FirstLoadService'
import ActivityTracker from '../../services/ActivityTracker'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    /** popup visibility */
    visible: false,
    /** popup title */
    title: '',
    /** popup instruction-popups array */
    instructions: [],
    /** popup note */
    notes: '',
    /** if popup setup to show it on first time it's unique key */
    firstTimeKey: '',
    /** popup type mealPlanView/WorkoutView  */
    instructionType: ''
  },
  mutations: {
    /** to show the popup  */
    show (state) {
      state.visible = true

      const ActivityTrackerService = new ActivityTracker()
      if (state.instructionType === 'mealPlanHome') {
        ActivityTrackerService.addEvent('MealPlan.ViewedInstructions', {auto_shown: false}, false)
      } else if (state.instructionType === 'workoutDashboard') {
        ActivityTrackerService.addEvent('WorkoutProgram.ViewedInstructions', {auto_shown: false, context: 'dashboard'}, false)
      } else if (state.instructionType === 'workoutHome') {
        ActivityTrackerService.addEvent('WorkoutProgram.ViewedInstructions', {auto_shown: false, context: 'workout-program-week'}, false)
      } else if (state.instructionType === 'workoutWeeks') {
        ActivityTrackerService.addEvent('WorkoutProgram.ViewedInstructions', {auto_shown: false, context: 'workout-program-week'}, false)
      } else if (state.instructionType === 'workoutDay') {
        ActivityTrackerService.addEvent('WorkoutProgram.ViewedInstructions', {auto_shown: false, context: 'workout-program-day'}, false)
      }
    },
    /** to hide the popup */
    hide (state) {
      state.visible = false
    },
    /** set the pop up content */
    setContent (state, data) {
      state.title = data.title || ''
      state.instructions = data.instructions || ''
      state.notes = data.notes || ''
      state.firstTimeKey = data.firstTimeKey || ''
      state.instructionType = data.instructionType || ''

      const firstLoadService = new FirstLoadService()
      if (state.firstTimeKey !== '' && firstLoadService.getFirstTimeStatus(state.firstTimeKey)) {
        let text = data.instructions || ''
        if (Array.isArray(text)) {
          const index = text.findIndex(ele => {
            ele = ele || ''
            return Boolean(ele.replace(/<\/?[^>]+(>|$)/g, '').trim())
          })
          state.visible = index !== -1
        } else if (typeof text === 'string' || text instanceof String) {
          state.visible = Boolean(text.replace(/<\/?[^>]+(>|$)/g, '').trim())
        }
        if (!state.visible) {
          text = data.notes || ''
          if (Array.isArray(text)) {
            const index = text.findIndex(ele => {
              ele = ele || ''
              return Boolean(ele.replace(/<\/?[^>]+(>|$)/g, '').trim())
            })
            state.visible = index !== -1
          } else if (typeof text === 'string' || text instanceof String) {
            state.visible = Boolean(text.replace(/<\/?[^>]+(>|$)/g, '').trim())
          }
        }
        const ActivityTrackerService = new ActivityTracker()
        if (state.instructionType === 'mealPlanHome') {
          ActivityTrackerService.addEvent('MealPlan.ViewedInstructions', {auto_shown: true}, false)
        } else if (state.instructionType === 'workoutDashboard') {
          ActivityTrackerService.addEvent('WorkoutProgram.ViewedInstructions', {auto_shown: true, context: 'dashboard'}, false)
        } else if (state.instructionType === 'workoutWeeks') {
          ActivityTrackerService.addEvent('WorkoutProgram.ViewedInstructions', {auto_shown: true, context: 'workout-program-week'}, false)
        }
      }
    },
    /** set First time viewed option */
    setFirstTimeViewed (state) {
      if (state.firstTimeKey !== '') {
        const firstLoadService = new FirstLoadService()
        firstLoadService.setFirstTimeStatus(state.firstTimeKey)
      }
    },
    /** reset the popup data */
    reset (state) {
      state.visible = false
      state.title = ''
      state.instructions = []
      state.firstTimeKey = ''
    }

  },
  actions: {

  },
  getters: {
    /** get the popup visibility */
    visible (state) {
      return state.visible
    },
    /** get the popup title */
    title (state) {
      return state.title
    },
    /** get the popup instructions */
    instructions (state) {
      return state.instructions
    }, /** get the popup notes */
    notes (state) {
      return state.notes
    },
    firstTimeViewKey (state) {
      return state.firstTimeKey
    }
  }
}
