<template>
  <preloader-with-text-slides
    :textSlides="textSlides"
    @loaded="nextComponent">
  </preloader-with-text-slides>
</template>

<script>
import BaseComponent from '../global/widgets/forms/FormBaseComponent'
import PreloaderWithTextSlides from '../../../global/preloaders/PreloaderWithTextSlides'

export default {
  name: 'Preloader',
  extends: BaseComponent,
  components: {
    PreloaderWithTextSlides
  },
  data () {
    return {
      textSlides: [
        this.$t('message[\'general.preloader.submitting-info\']'),
        this.$t('message[\'general.preloader.updating-preferences\']')
      ]
    }
  },
  mounted () {
    this.hideLoading()
  }
}
</script>

<style scoped>

</style>
