<template>
    <page :header="header" class="gc-page-body-fat">
      <validation-observer ref="form" v-slot="{handleSubmit, invalid}">
          <page-container>
              <div class="gc-page-body-fat__header">
                  <text-heading4
                      class="gc-page-body-fat__title text-center"
                      weight="extra-bold"
                      lineHeight="multi">{{title.main}}</text-heading4>
              </div>
              <div class="gc-page-body-fat__content" v-if="showBodyFatSlider">
                  <form id="form-input-body-fat"
                      class="gc-page-body-fat__form"
                      @submit.prevent="handleSubmit(nextComponent)">
                          <validation-provider :rules="validation" v-slot="{ errors }" vid="bodyFat" name="body fat">
                            <input-body-fat
                              class="gc-page-body-fat__slider"
                              :error-message="errors[0]"
                              :gender="extraData.gender"
                              :options="options"
                              :useTextLables="extraData.isTextLableEnabled"
                              :showInputFieldManually="extraData.showBodyFatInputField"
                              v-model="newInputVal"
                              @init="setpageFooter"
                             />
                          </validation-provider>
                  </form>
                <text-content v-if="enableBodyFatEstimator() && (extraData.showBodyFatInputField)"
                    class="gc-page-body-fat__popup-description text-center"
                    @click="showBodyFatEstimator()"
                    weight="extra-bold"
                    size="sm2">{{$t('message["general.suggest-body-fat"]')}}</text-content>
              </div>
            <popup-body-fat-estimator
                :gender="extraData.gender"
                :height="extraData.height"
                :system="extraData.system"
                :visible="bodyFatEstimator.show"
                v-if="bodyFatEstimator.show"
                v-on:close="closeBodyFatEstimator()"
                v-on:body-fat="setBodyFat($event)"
              />
          </page-container>
          <page-footer ref="footer" :form="'form-input-body-fat'" :validated="invalid"></page-footer>
        </validation-observer>
    </page>
</template>

<script>
import FormBaseComponent from '../../layout/template-1/global/widgets/forms/FormBaseComponent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ButtonPrimary from '../buttons/ButtonPrimary'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import TextHeading4 from '../typography/TextHeading4'
import PageContainer from './page-sub-components/PageContainer'
import Page from './PageForm'
import InputBodyFat from '../../global/inputs/InputBodyFat'
import PopupBodyFatEstimator from '../../global/popups/PopupBodyFatEstimator'
import TextContent from '../../root/TextContent'
import PageFooter from './page-sub-components/PageFormFooter'
import { flagValue } from '../../../includes/TemplateSettings'

export default {
  name: 'PageBodyFat',
  extends: FormBaseComponent,
  components: {
    Page,
    PageContainer,
    TextHeading4,
    ButtonPrimary,
    TextBodyExtraSmall,
    ValidationObserver,
    ValidationProvider,
    InputBodyFat,
    PopupBodyFatEstimator,
    TextContent,
    PageFooter
  },
  data: function () {
    return {
      header: {
        isTransparent: false,
        isFixed: false,
        show: false,
        left: '',
        right: '',
        rightBlink: false,
        modifiers: [],
        mainTitle: '',
        subTitle: ''
      },
      validation: {
        required: true,
        min_value: 0,
        max_value: 50
      },
      bodyFatEstimator: {
        show: false,
        suggestedBodyFat: ''
      },
      newInputVal: 0,
      showBodyFatSlider: false
    }
  },
  watch: {
    extraData: {
      immediate: true,
      deep: true,
      handler: function () {
        if (this.extraData.gender === 'f') {
          this.validation.min_value = 10
        } else {
          this.validation.min_value = 3
        }
      }

    }
  },
  mounted () {
    // set the footer once the slick is initialized
    // this.$refs.footer
    if (parseInt(this.inputValue) > 50) {
      this.newInputVal = Math.round(this.inputValue) / 100
    } else {
      this.newInputVal = this.inputValue || 25
    }
    if (typeof this.errors[this.inputKey] !== 'undefined') {
      this.$refs.form.setErrors({ 'bodyFat': this.errors[this.inputKey] || [] })
    }
    this.showBodyFatSlider = true
    this.hideLoading()
  },
  methods: {
    enableBodyFatEstimator () {
      return flagValue('show_body_fat_estimator', true)
    },
    setpageFooter () {
      this.$refs.footer.setFooterClass()
    },
    setBodyFat (value) {
      this.newInputVal = Math.round(value)
    },
    closeBodyFatEstimator () {
      this.bodyFatEstimator.show = false
    },
    showBodyFatEstimator () {
      this.bodyFatEstimator.show = true
    },
    nextComponent () {
      this.showLoading()
      const self = this
      // holding router for 0.5 seconds
      setTimeout(() => {
        const inputObj = { key: this.inputKey, value: this.newInputVal }
        self.$emit('submit', inputObj)
      }, 500)
    }
  }
}
</script>
