import { render, staticRenderFns } from "./IconFlower.vue?vue&type=template&id=4c53025a&scoped=true"
import script from "./IconFlower.vue?vue&type=script&lang=js"
export * from "./IconFlower.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c53025a",
  null
  
)

export default component.exports