import ProfileApi from './api/profile/ProfileApi'
import CardApi from './api/profile/CardApi'
import DashBoardService from './DashboardService'
import { i18n } from '../helpers/localization/i18n'
import PreferredMealsApi from './api/profile/PreferredMealsApi'

export default class ProfileService {
  firstName = ''
  lastName = ''
  email = ''
  phone = ''
  address = {
    line1: '',
    suburb: '',
    city: '',
    postcode: '',
    region: {
      name: '',
      id: ''
    },
    country: ''
  }
  extraField= null
  dob = ''
  timezone = ''
  creditCardLast4digits = ''
  stripeKey = ''
  excludedIngredients = []
  cuisines = []
  injuries = []
  supportEmail = ''
  trainer = {}
  formErrors = {}
  extraCustomFields = []
  selectedRecipes = []
  recipes = []
  initialized = false

  constructor () {
    if (!ProfileService.instance) {
      ProfileService.instance = this
    }
    return ProfileService.instance
  }

  getRecipesList () {
    const api = new PreferredMealsApi()
    return new Promise((resolve, reject) => {
      api.getList().then(res => {
        this.recipes = res.data.recipes.map(option => {
          return { id: option.id, text: option.name }
        })
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  setProfileData () {
    const api = new ProfileApi()
    return new Promise((resolve, reject) => {
      api.getList().then(res => {
        this.firstName = res.data.customer.first_name
        this.lastName = res.data.customer.last_name
        this.email = res.data.customer.email
        this.trainer = res.data.settings_manager.trainer
        this.creditCardLast4digits = res.data.customer.credit_card_last_4digits
        this.phone = res.data.customer.phone_number ? res.data.customer.phone_number.number : ''
        this.address.line1 = res.data.address.line_1
        this.address.suburb = res.data.address.suburb
        this.address.city = res.data.address.city
        this.address.postcode = res.data.address.postcode
        this.address.region = res.data.address.region
        this.address.country = res.data.address.country.id
        this.dob = res.data.dob
        this.timezone = res.data.timezone
        this.excludedIngredients = res.data.excluded_ingredients
        this.cuisines = res.data.cuisines
        this.injuries = res.data.injuries
        this.selectedRecipes = res.data.recipes
        this.setExtraFileds(res.data.extra_fields)

        this.stripeKey = res.data.settings_manager.trainer.stripe_key
        this.supportEmail = res.data.settings_manager.trainer.support_email || ''
        this.initialized = true
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  setExtraFileds (data) {
    if (data !== null) {
      this.extraCustomFields = data
    }
  }

  getSelectedRecipes () {
    return this.selectedRecipes
  }

  getAge () {
    const d1 = new Date(this.dob)
    const d2 = new Date()
    const diff = d2.getTime() - d1.getTime()
    return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25))
  }

  getExtraFields () {
    return this.extraCustomFields
  }

  getEmptyExtraFields () {
    return i18n.t('message["profile.account-settings.custom-field-empty"]')
  }

  setFormErrors (errors) {
    this.formErrors = errors
  }

  checkInitialized () {
    return this.initialized
  }

  getFormErrors () {
    return this.formErrors
  }

  getFirstName () {
    return this.firstName
  }

  getLastName () {
    return this.lastName
  }

  getFullName () {
    return this.firstName + ' ' + this.lastName
  }

  getEmail () {
    return this.email
  }

  getPhone () {
    return this.phone
  }

  getAddress () {
    return this.address
  }

  getDob () {
    return this.dob
  }

  getSupportEmail () {
    return this.supportEmail
  }

  getTimezone () {
    return this.timezone
  }

  getCreditCardLast4Digits () {
    return this.creditCardLast4digits
  }

  getStripeKey () {
    return this.stripeKey
  }

  getExcludedIngrediants () {
    return this.excludedIngredients
  }

  getCuisines () {
    return this.cuisines
  }

  getInjuries () {
    return this.injuries
  }

  getExcludedIngredients () {
    if (this.excludedIngredients) {
      let ingredients = this.excludedIngredients.map(ingredient => {
        return parseInt(ingredient) || 0
      })

      return ingredients.filter(ingredient => {
        return !!ingredient
      })
    }
    return []
  }

  getTrainerBrand () {
    return this.trainer.brand_name
  }

  updateDetails () {
    const payload = {
      email: this.email,
      first_name: this.firstName,
      last_name: this.lastName,
      phone_number: this.phone,
      city: this.address.city,
      address: this.address.line1,
      suburb: this.address.suburb,
      country: this.address.country,
      postcode: this.address.postcode,
      date_of_birth: this.dob,
      timezone: this.timezone,
      cuisines: this.cuisines ? this.cuisines : [],
      excluded_ingredients: this.getExcludedIngredients(),
      injuries: this.injuries ? this.injuries : [],
      recipes: this.selectedRecipes ? this.selectedRecipes : []
    }
    if (Array.isArray(this.extraCustomFields) && this.extraCustomFields.length > 0) {
      if (this.extraCustomFields[0].value === null) {
        payload.extra_field = ''
      } else {
        payload.extra_field = this.extraCustomFields[0].value
      }
    }
    if (this.address.region.id) {
      payload.region_id = this.address.region.id
    } else {
      payload.region_name = this.address.region.name
    }

    const api = new ProfileApi()
    return new Promise((resolve, reject) => {
      api.post(payload).then(res => {
        const dashBoardService = new DashBoardService()
        dashBoardService.getUserData(true)
        dashBoardService.getPageData(true)
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  updateFullName (data) {
    this.firstName = data.first_name
    this.lastName = data.last_name
    return this.updateDetails()
  }

  updateExtraField (data) {
    this.extraCustomFields[0].value = data.extra_field
    return this.updateDetails()
  }

  updateEmail (data) {
    this.email = data.email
    return this.updateDetails()
  }

  updatePhone (data) {
    this.phone = data.phone_number
    return this.updateDetails()
  }

  updateBirthday (data) {
    this.dob = data.date_of_birth
    return this.updateDetails()
  }

  updateAddress (data) {
    let regionId = ''
    let regionName = ''
    if (data.region.type === 'id') {
      regionId = data.region.value
    } else {
      regionName = data.region.value
    }

    this.address.line1 = data.address
    this.address.suburb = data.suburb
    this.address.city = data.city
    this.address.country = parseInt(data.country)
    this.address.region.name = regionName
    this.address.region.id = parseInt(regionId)
    this.address.postcode = data.postcode
    return this.updateDetails()
  }

  updateTimezone (data) {
    this.timezone = data.timezone
    return this.updateDetails()
  }

  updateExcludedIngredients (data) {
    this.excludedIngredients = data.excluded_ingredients
    return this.updateDetails()
  }

  addToExcludedIngredients (data) {
    this.excludedIngredients = [...this.excludedIngredients, ...data.excluded_ingredients]
    return this.updateDetails()
  }

  updateInjuries (data) {
    this.injuries = data.injuries
    return this.updateDetails()
  }

  updateRecipes (data) {
    this.selectedRecipes = data.selected_recipes
    return this.updateDetails()
  }

  /**
   *  @deprecated since version 2.0
   * @param payload
   * @returns {Promise<unknown>}
   */
  updateCardDetails (payload) {
    const api = new CardApi()
    return new Promise((resolve, reject) => {
      api.post(payload).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  createStripeCreditCardUpdateSession () {
    const api = new CardApi()
    return new Promise((resolve, reject) => {
      let payload = {
        successUrl: location.origin + '/account-details?page=payment-settings&update=success',
        cancelUrl: location.origin + '/account-details?page=payment-settings&update=cancel'
      }
      api.createCreditCardUpdateSession(payload).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err.response)
      })
    })
  }

  redirectToStripeCheckout (session) {
    return new Promise((resolve, reject) => {
      let stripe = Stripe(session.public_key, {
        stripeAccount: session.stripe_account
      })
      stripe.redirectToCheckout({
        sessionId: session.id
      }).then(data => {
        if (data.error) {
          reject(data.error.message)
        } else {
          resolve(data)
        }
      }).catch(err => {
        reject(err)
      })
    })
  }
  updateImage (formData) {
    const api = new ProfileApi()
    return api.uploadImage(formData)
  }

  changePassword (formData) {
    const api = new ProfileApi()
    return api.changePassword(formData)
  }
}
