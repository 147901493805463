<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.50063 0.900022C5.42652 0.89952 2.6549 2.75123 1.47851 5.59145L1.4785 5.59145C0.302319 8.43181 0.952909 11.701 3.12737 13.8742C6.09513 16.842 10.9066 16.8419 13.8743 13.8741C16.8418 10.9066 16.8419 6.09521 13.8745 3.12739C12.4524 1.69721 10.5174 0.895184 8.50063 0.900022ZM8.50063 0.900022C8.50059 0.900022 8.50055 0.900022 8.5005 0.900022L8.50075 1.00002L8.50076 0.900022C8.50072 0.900022 8.50068 0.900022 8.50063 0.900022ZM3.76068 13.2409C1.26312 10.7426 1.13644 6.74072 3.45357 4.09025L12.9113 13.5481C10.2832 15.8479 6.26878 15.7456 3.76068 13.2409ZM13.548 12.9112L4.09049 3.45341C6.74257 1.14082 10.7407 1.2676 13.241 3.76063C15.7384 6.25887 15.8652 10.2607 13.548 12.9112Z" fill="black" stroke-width="0.2"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconDislike',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-dislike': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
