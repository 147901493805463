<template>
    <div :class="componentClasses">
        <text-content v-if="exerciseGroup.getExercisesCount()>1&&!circuit.isCircuit" class="gc-card-exercise-list-group__title" :weight="'extra-bold'" :lineheight="'lh-lg'" :size="'sm4'">
            {{exerciseGroup.name}}
        </text-content>
        <text-content  v-else-if="exerciseGroup.getExercisesCount()>1&&circuit.isCircuit" :weight="'bold'" :lineheight="'single'" :size="'sm4'">
            {{$t('message["workout.circuit-workout"]')+" - "+circuit.rounds+" "+$tc('message["workout.rounds"]',circuit.rounds)}}
        </text-content>
        <div :class="cardWrapperClasses">
          <div :class="cardClasses"
               v-for="(exercise,groupExerciseID)  in exerciseGroup.getExercises()"
               :key="'exercise-'+exercise.getID()">
            <card-exercise-overview
                class="'gc-card-exercise-list-group__card-list'"
                :index="groupExerciseID+1"
                :exercise = "exercise"
                :isCircuit= "circuit.isCircuit"
                :groupExerciseID= "groupExerciseID"
                :isGrouped= "exerciseGroup.getExercisesCount()>1"
                @click="exerciseClicked($event)"
                >
            </card-exercise-overview>
          </div>
        </div>
    </div>
</template>
<script>
import TextContent from '../../root/TextContent'
import CardExerciseOverview from '../../global/cards/CardExerciseOverview'

export default {
  name: 'CardExerciseListGroup',
  components: {
    TextContent,
    CardExerciseOverview
  },
  props: {
    exerciseGroup: {
      default: ''
    },
    circuit: {
      isCircuit: false,
      rounds: 1
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-card-exercise-list-group': true,
        'col-md-6': this.exerciseGroup.getExercisesCount() === 1,
        'col-12': this.exerciseGroup.getExercisesCount() > 1,
        'gc-card-exercise-list-group--has-multiple': this.exerciseGroup.getExercisesCount() > 1,
        'gc-card-exercise-list-group--has-one': this.exerciseGroup.getExercisesCount() === 1
      }
    },
    cardWrapperClasses: function () {
      return {
        'gc-card-exercise-list-group__cards': true
      }
    },
    cardClasses: function () {
      return {
        'gc-card-exercise-list-group__card-wrapper': true,
        'col-md-6': this.exerciseGroup.getExercisesCount() > 1
      }
    }
  },
  methods: {
    exerciseClicked (id) {
      const data = {
        exerciseId: id,
        isGrouped: this.exerciseGroup.exercises.length > 1
      }
      this.$emit('click-exercise', data)
    }
  }
}
</script>
