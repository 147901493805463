export default {
  /**
   * defined the `pageConfig` prop and set the default value
   */
  props: {
    pageConfig: {
      type: Object,
      required: false,
      default: function () {
        return {
          type: '',
          name: '',
          variation: 'design1',
          properties: [],
          widgets: []
        }
      }
    }
  },
  computed: {
    /**
     * set the component variation by the config
     * @returns {*}
     */
    currentComponent: function () {
      const widget = this.pageConfig
      if (widget && widget.variation) {
        return this.components[widget.variation] || this.components.design1
      } else {
        return this.components.design1
      }
    },
    /**
     * to parse the all the props parsed
     * @returns {*}
     */
    currentComponentData: function () {
      return this.$props
    }
  }
}
