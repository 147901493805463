import DefineInclude from '../DefineInclude'

export default class ImageService {
  images = {}

  constructor () {
    if (!ImageService.instance) {
      ImageService.instance = this
    }
    return ImageService.instance
  }
  setImages () {
    return new Promise((resolve, reject) => {
      // templateConfig receives from external API
      const imagePreset = templateConfig.images
      this.checkTablet().then(res => {
        const isTab = res
        const imageList = []
        Object.keys(imagePreset).forEach(function (value, index, array) {
          let image = ''
          if (isTab && ('2x' in imagePreset[value])) {
            image = imagePreset[value]['2x'] || imagePreset[value]['1x']
          } else {
            image = imagePreset[value]['1x']
          }
          imageList[value] = image
        })
        this.images = Object.assign({}, imageList)
        resolve(this.images)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getDashboardTabImage () {
    const imagePreset = templateConfig.images
    let image = null
    Object.keys(imagePreset).forEach(function (value, index, array) {
      if (value === 'dashboard.background' && ('2x' in imagePreset[value])) {
        image = imagePreset[value]['2x'] || imagePreset[value]['1x']
      }
    })

    return image
  }

  checkTablet () {
    return new Promise((resolve, reject) => {
      const urlParams = window.location.search

      if (urlParams.indexOf('device=tablet') !== -1) {
        resolve(true)
      } else if (urlParams.indexOf('device=phone') !== -1) {
        resolve(false)
      } else {
        resolve(false)
      }
    })
  }

  getWindowHeight () {
    if (self.innerHeight) {
      return self.innerHeight
    }

    if (document.documentElement && document.documentElement.clientHeight) {
      return document.documentElement.clientHeight
    }

    if (document.body) {
      return document.body.clientHeight
    }
  }

  checkRetina () {
    return new Promise((resolve, reject) => {
      const isRetina = (window.devicePixelRatio >= 2)
      resolve(isRetina)
    })
  }

  setBeforeRouteImageListCache (route) {
    return new Promise((resolve, reject) => {
      const routeImages = this.getBeforeRouteCacheImages(route)

      this.cacheImages(routeImages).then(res => {
        resolve()
      })
    })
  }

  setAfterRouteImageListCache (route) {
    return new Promise((resolve, reject) => {
      const routeImages = this.getAfterRouteCacheImages(route)

      this.cacheImages(routeImages).then(res => {
        resolve()
      })
    })
  }

  cacheImages (imagesList) {
    return new Promise((resolve, reject) => {
      const imageObj = []
      const imageCount = imagesList.length
      let loadedImages = 0

      if (imageCount > 0) {
        imagesList.forEach(function (value, index, array) {
          if (!value) {
            resolve()
            return
          }
          imageObj[index] = new Image()
          imageObj[index].src = value

          // image onload
          imageObj[index].onload = function () {
            loadedImages++

            // check if loaded images is equal to image count
            if (loadedImages >= imageCount) {
              resolve()
            }
          }

          // if error occurred
          imageObj[index].onerror = function () {
            resolve()
          }
        })
      } else {
        // resolve if no images exists
        resolve()
      }
    })
  }

  getBeforeRouteCacheImages (route) {
    let imageList = []
    switch (route.path) {
      case DefineInclude.appUrlList.signIn:
        imageList = [
          this.images['login.logo'],
          this.images['login.background']
        ]
        break
      case DefineInclude.appUrlList.dashboard:
        imageList = [
          this.images['dashboard.background']
        ]
        break
    }

    return imageList
  }

  getAfterRouteCacheImages (route) {
    let imageList = []
    switch (route.path) {
      case DefineInclude.appUrlList.dashboard:
        imageList = [
          this.images['workout.week1.tile'],
          this.images['workout.week2.tile'],
          this.images['workout.week3.tile'],
          this.images['workout.week4.tile'],
          this.images['mealPlan.background']
        ]
        break
    }

    return imageList
  }

  getImages () {
    return this.images
  }
}
