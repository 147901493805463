<template>
  <popup-body class="text-center">
    <popup-head>
      <popup-text-heading>{{ title }}</popup-text-heading>
    </popup-head>
    <popup-content class="pc-popup-exercise-swap__injuries-area" v-if="state=== 'init'">
      <text-body-small
        :size="'sm2'"
        :weight="'medium'"
        :line-height="'multi'"
        class="pc-popup-exercise-swap__injuries-area__info">{{ description }}</text-body-small>
      <div class="form-group ma-form ">
        <text-area
          v-model="message"
          :placeholder="customInputPlaceholder"
          class="pc-popup-exercise-swap__injuries-area__text-area"/>
      </div>
    </popup-content>
    <popup-content v-else-if="state=== 'success'" class="pc-popup-exercise-swap__injuries-area">
      <text-body-small
        :size="'sm2'"
        :weight="'medium'"
        :line-height="'multi'"
        class="pc-popup-exercise-swap__injuries-area__info">{{ $t('message["swap.exercise-pending-message"]') }}</text-body-small>
    </popup-content>
    <popup-content v-else>
      <loading-buffer height="50"/>
    </popup-content>
    <popup-footer>
      <button-primary
        v-if="state=== 'init'"
        :text="footerData.btnLabel"
        class="pc-popup-exercise-swap__injuries-area__submit-button"
        @click="footerSubmit"/>
    </popup-footer>
  </popup-body>
</template>

<script>
import PopupContent from '../../../../../../global/popups/popup-sub-components/PopupContent'
import ButtonPrimary from '../../../../../../global/buttons/ButtonPrimary'
import TextArea from '../../../../../../global/inputs/TextArea'
import TextContent from '../../../../../../root/TextContent'
import PopupBody from '../../../../../../global/popups/popup-sub-components/PopupBody'
import PopupHead from '../../../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../../../global/popups/popup-sub-components/PopupTextHeading'
import WorkoutProgramExerciseService from '../../../../../../../services/WorkoutProgramExerciseService'
import PopupFooter from '../../../../../../global/popups/popup-sub-components/PopupFooter'
import LoadingBuffer from '../../../../global/widgets/LoadingBuffer'
import IconCompletedFilled from '../../../../../../root/icons/IconCompletedFilled'
import TextBodySmall from '../../../../../../global/typography/TextBodySmall'

export default {
  name: 'PopupSuggestedExerciseSwapContainerInjuries',
  components: {
    TextBodySmall,
    LoadingBuffer,
    PopupFooter,
    PopupTextHeading,
    PopupHead,
    PopupBody,
    PopupContent,
    ButtonPrimary,
    TextArea,
    TextContent,
    IconCompletedFilled
  },
  props: {
    exercise: {
      required: true,
      validator: function (value) {
        return value instanceof WorkoutProgramExerciseService
      }
    }
  },
  data: function () {
    return {
      state: 'init',
      title: '',
      message: '',
      topDescription: '',
      customInputPlaceholder: '',
      description: '',
      footerData: {
        btnLabel: this.$i18n.t('message[""swap.request-button""]') || 'Request Swap',
        active: true
      }
    }
  },
  watch: {
    state: function () {
      this.$emit('state', this.state)
    }
  },
  created () {
    this.loadPageLanguageData()
    this.loadPageData()
  },
  mounted () {
    this.$emit('mounted')
  },
  methods: {
    loadPageLanguageData () {
      // title
      this.title = this.$i18n.t('message["swap.suggest-injury"]')
      // description
      this.topDescription = this.$i18n.t("message['swap.request-description']")
      // custom inputs placeholder
      this.customInputPlaceholder = this.$i18n.t("message['swap.request-input-placeholder']")
      // custom inputs label
      this.description = this.$i18n.t("message['swap.request-input-label']")
      // footer button text
      this.footerData.btnLabel = this.$i18n.t("message['swap.request-button-text']")
    },
    loadPageData () {
    },
    footerSubmit () {
      this.state = 'submitting'
      this.title = this.$i18n.t('message["exercise-swap.requesting"]')
      this.logEvent('SwappedExercise', {
        reason: 'injury',
        exerciseID: this.exercise.getID(),
        exerciseName: this.exercise.getName()
      }, false)

      this.exercise.submitSwapInjuryRequestExercise([], this.message).then(() => {
        this.title = this.$i18n.t('message["swap.request-success-title"]')
        this.state = 'success'
        this.swapSuccess = true
        this.$emit('swap-success')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.title = this.$i18n.t('message["swap.request-success-title"]')
          this.description = this.$i18n.t('message["swap.exercise-pending-message"]')
          this.state = 'success'
          this.$emit('swap-success')
        } else {
          this.state = 'error'
          this.$emit('swap-error')
        }
      })
    }
  }
}
</script>
