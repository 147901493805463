import ProfileService from '../../../../services/ProfileService'
import CountryService from '../../../../services/CountryService'
import RegionService from '../../../../services/RegionService'
import TimezoneService from '../../../../services/TimezoneService'
import IngredientService from '../../../../services/IngredientService'
import InjuryService from '../../../../services/InjuryService'
import DefineInclude from '../../../../DefineInclude'
import { i18n, setMessages } from '../../../../helpers/localization/i18n'
import { enableGroupedIngredients } from '../../../../includes/TemplateSettings'
// forms config related logic
export class ConfigService {
  getCountryList () {
    return new Promise((resolve, reject) => {
      const countryService = new CountryService()

      // set country options
      countryService.setCountries().then(res => {
        const countryService = new CountryService()
        const countries = []
        const allCountries = countryService.getCountries()

        Object.keys(allCountries).forEach(function (value, index, array) {
          countries.push({ id: value, text: allCountries[value] })
        })
        resolve(countries)
      })
    })
  }

  getRegionList (countryId) {
    return new Promise((resolve, reject) => {
      const countryService = new CountryService()
      const countryName = countryService.getCountryById(countryId)

      const regionService = new RegionService()
      regionService.setRegions().then(res => {
        const regions = []
        const allRegions = regionService.getRegionsByCountryId(countryId)

        Object.keys(allRegions).forEach(function (value, index, array) {
          if (countryName !== allRegions[value]) {
            regions.push({ id: value, text: allRegions[value] })
          }
        })
        resolve(regions)
      })
    })
  }

  getTimeZoneList () {
    return new Promise((resolve, reject) => {
      const timezoneService = new TimezoneService()
      const timeZoneOptions = []
      timezoneService.setTimezones().then(res => {
        const timeZones = timezoneService.getTimezones()

        Object.keys(timeZones).forEach(function (value, index, array) {
          timeZoneOptions.push({
            id: value,
            text: timeZones[value]
          })
        })
        resolve(timeZoneOptions)
      })
    })
  }

  getIngredientList () {
    return new Promise((resolve, reject) => {
      const ingredientService = new IngredientService()
      let mAllergies = []
      ingredientService.setIngredients().then(res => {
        if (enableGroupedIngredients()) {
          this.type = 'accordion'

          mAllergies = ingredientService.getGroupedIngredients()
        } else {
          const ingredients = ingredientService.getIngredients()
          Object.keys(ingredients).forEach(function (value, index, array) {
            const groups = ingredients[value].groups || []
            mAllergies.push({ id: ingredients[value].id, text: ingredients[value].value, groups: groups })
          })
        }
        resolve(mAllergies)
      })
    })
  }

  getInjuryList () {
    return new Promise((resolve, reject) => {
      const injuries = []

      const injuryService = new InjuryService()
      injuryService.setInjuries().then(res => {
        const allInjuries = injuryService.getInjuries()

        Object.keys(allInjuries).forEach(function (value, index, array) {
          injuries.push({ id: value, text: allInjuries[value] })
        })
        resolve(injuries)
      })
    })
  }

  getProfileSRouteConfig () {
    return {
      path: DefineInclude.appUrlList.profile
    }
  }

  getAccountSettingsRouteConfig () {
    return {
      path: DefineInclude.appUrlList.profile,
      query: {
        page: 'account-settings'
      }
    }
  }

  getDietaryPreferenceRouteConfig () {
    return {
      path: DefineInclude.appUrlList.profile,
      query: {
        page: 'dietary-preferences'
      }
    }
  }

  getFitnessPreferenceRouteConfig () {
    return {
      path: DefineInclude.appUrlList.profile,
      query: {
        page: 'fitness-preferences'
      }
    }
  }
}

export default {
  'full-name': {
    title: 'profile.account-settings.full-name',
    pageContainerClass: 'profile-form-page',
    inputs: [
      {
        type: 'text',
        name: 'profile.account-settings.full-name.first-name',
        model: 'first_name',
        placeholder: 'profile.account-settings.full-name.first-name',
        validationRules: 'required'
      },
      {
        type: 'text',
        name: 'profile.account-settings.full-name.last-name',
        model: 'last_name',
        placeholder: 'profile.account-settings.full-name.last-name',
        validationRules: 'required'
      }
    ],
    submitType: 'btnClick',
    submitText: 'Submit',
    getDefaultData: function () {
      return new Promise((resolve, reject) => {
        const profileService = new ProfileService()
        let defaultData = {}
        defaultData = {
          first_name: profileService.getFirstName(),
          last_name: profileService.getLastName()
        }
        resolve({
          defaultData: defaultData
        })
      }
      )
    },
    redirectBack: function () {
      const configService = new ConfigService()
      return configService.getAccountSettingsRouteConfig()
    },
    submitForm: function (payload) {
      const profileService = new ProfileService()
      return profileService.updateFullName(payload)
    }
  },
  email:
    {
      title:
        'profile.account-settings.email',
      pageContainerClass: 'profile-form-page',
      inputs:
        [
          {
            type: 'email',
            name: 'profile.account-settings.email',
            model: 'email',
            placeholder: 'profile.account-settings.email',
            validationRules: 'required|email'
          }
        ],
      submitType: 'btnClick',
      submitText: 'Submit',
      getDefaultData: function () {
        return new Promise((resolve, reject) => {
          const profileService = new ProfileService()
          let defaultData = {}
          defaultData = {
            email: profileService.getEmail()
          }
          resolve({
            defaultData: defaultData
          })
        })
      },
      redirectBack: function () {
        const configService = new ConfigService()
        return configService.getAccountSettingsRouteConfig()
      },
      submitForm: function (payload) {
        const profileService = new ProfileService()
        return profileService.updateEmail(payload)
      }
    },
  birthday:
    {
      title:
        'profile.account-settings.birthday',
      pageContainerClass: 'profile-form-page',
      inputs:
        [
          {
            type: 'date',
            name: 'profile.account-settings.birthday',
            model: 'date_of_birth',
            placeholder: 'profile.account-settings.birthday',
            validationRules: 'date|birthday|required'
          }
        ],
      submitType: 'btnClick',
      submitText: 'Submit',
      getDefaultData: function () {
        return new Promise((resolve, reject) => {
          const profileService = new ProfileService()
          let defaultData = {}
          defaultData = {
            date_of_birth: profileService.getDob()
          }
          resolve({
            defaultData: defaultData
          })
        })
      },
      redirectBack: function () {
        const configService = new ConfigService()
        return configService.getAccountSettingsRouteConfig()
      },
      submitForm: function (payload) {
        const profileService = new ProfileService()
        return profileService.updateBirthday(payload)
      }
    },
  phone:
    {
      title:
        'profile.account-settings.phone',
      pageContainerClass: 'profile-form-page',
      inputs:
        [
          {
            type: 'text',
            name: 'profile.account-settings.phone',
            model: 'phone_number',
            placeholder: 'profile.account-settings.phone',
            validationRules: 'required|phone_number'
          }
        ],
      submitType: 'btnClick',
      submitText: 'Submit',
      getDefaultData: function () {
        return new Promise((resolve, reject) => {
          const profileService = new ProfileService()
          let defaultData = {}
          defaultData = {
            phone_number: profileService.getPhone()
          }
          resolve({
            defaultData: defaultData
          })
        })
      },
      redirectBack: function () {
        const configService = new ConfigService()
        return configService.getAccountSettingsRouteConfig()
      },
      submitForm: function (payload) {
        const profileService = new ProfileService()
        return profileService.updatePhone(payload)
      }
    },
  address:
    {
      title:
        'profile.account-settings.address',
      pageContainerClass: 'profile-form-page',
      inputs:
        [
          {
            type: 'text',
            name: 'profile.account-settings.address.line1',
            model: 'address',
            placeholder: 'profile.account-settings.address.line1',
            validationRules: 'required'
          },
          {
            type: 'text',
            name: 'profile.account-settings.address.suburb',
            model: 'suburb',
            placeholder: 'profile.account-settings.address.suburb',
            validationRules: ''
          },
          {
            type: 'text',
            name: 'profile.account-settings.address.city',
            model: 'city',
            placeholder: 'profile.account-settings.address.city',
            validationRules: 'required'
          },
          {
            type: 'singleSelect',
            name: 'profile.account-settings.address.country',
            model: 'country',
            optionsKey: 'countryList',
            placeholder: 'profile.account-settings.address.country',
            validationRules: 'required'
          },
          {
            type: 'selectOrInputElement',
            name: 'profile.account-settings.address.region',
            model: 'region',
            optionsKey: 'regionList',
            placeholder: 'profile.account-settings.address.region',
            validationRules: 'required'
          },
          {
            type: 'text',
            name: 'profile.account-settings.address.post-code',
            model: 'postcode',
            placeholder: 'profile.account-settings.address.post-code',
            validationRules: 'required'
          }
        ],
      submitType: 'btnClick',
      submitText: 'Submit',
      getDefaultData: function () {
        return new Promise((resolve, reject) => {
          const profileService = new ProfileService()

          const addressData = profileService.getAddress()

          let region = ''
          let regionType = ''
          if (addressData.region && addressData.region.id) {
            region = addressData.region.id
            regionType = 'id'
          } else if (addressData.region) {
            region = addressData.region.name
            regionType = 'name'
          }

          let defaultData = {}
          defaultData = {
            address: addressData.line1,
            suburb: addressData.suburb,
            city: addressData.city,
            region: {
              type: regionType,
              value: region
            },
            country: addressData.country ? addressData.country : null,
            postcode: addressData.postcode
          }

          const configService = new ConfigService()
          let countries = []
          let regions = []
          configService.getCountryList().then(res => {
            countries = res

            // set region options if country selected
            if (addressData.country) {
              configService.getRegionList(addressData.country).then(res => {
                regions = res
                resolve({
                  defaultData: defaultData,
                  formOptions: {
                    countryList: countries,
                    regionList: regions
                  }
                })
              })
            } else {
              resolve({
                defaultData: defaultData,
                formOptions: {
                  countryList: countries
                }
              })
            }
          })
        })
      },
      redirectBack: function () {
        const configService = new ConfigService()
        return configService.getAccountSettingsRouteConfig()
      },
      submitForm: function (payload) {
        const profileService = new ProfileService()
        return profileService.updateAddress(payload)
      }
    },
  'time-zone':
    {
      title:
        'profile.account-settings.time-zone',
      pageContainerClass: 'profile-form-page',
      inputs:
        [
          {
            type: 'singleSelect',
            name: 'profile.account-settings.time-zone',
            model: 'timezone',
            optionsKey: 'timeZoneList',
            placeholder: 'profile.account-settings.time-zone',
            validationRules: 'required'
          }
        ],
      submitType: 'btnClick',
      submitText: 'Submit',
      getDefaultData: function () {
        return new Promise((resolve, reject) => {
          const profileService = new ProfileService()

          let defaultData = {}
          const configService = new ConfigService()
          let timeZones = []
          configService.getTimeZoneList().then(res => {
            timeZones = res
            defaultData = {
              timezone: profileService.getTimezone()
            }
            resolve({
              defaultData: defaultData,
              formOptions: {
                timeZoneList: timeZones
              }
            })
          })
        })
      },
      redirectBack: function () {
        const configService = new ConfigService()
        return configService.getAccountSettingsRouteConfig()
      },
      submitForm: function (payload) {
        const profileService = new ProfileService()
        return profileService.updateTimezone(payload)
      }
    },
  'custom-page': {
    title: 'profile.account-settings.custom-field-title',
    pageContainerClass: 'profile-form-page',
    inputs: [
      {
        type: 'text',
        name: 'profile.account-settings.custom-field',
        model: 'extra_field',
        placeholder: 'profile.account-settings.custom-field',
        validationRules: 'required|max:200'
      }
    ],
    submitType: 'btnClick',
    submitText: 'Submit',
    getDefaultData: function () {
      return new Promise((resolve, reject) => {
        const profileService = new ProfileService()
        let defaultData = {}
        this.title = 'profile.account-settings.custom-field'
        this.placeholder = 'profile.account-settings.custom-field'
        let fieldText = profileService.getExtraFields()[0].key || ''
        let titleText = (fieldText.length > 87 ? fieldText.substring(0, 87) + '...' : fieldText) || ''
        let titleValue = profileService.getExtraFields()[0].value || profileService.getEmptyExtraFields()
        setMessages({'profile.account-settings.custom-field-title': titleText})
        setMessages({'profile.account-settings.custom-field': fieldText})
        defaultData = {
          extra_field: titleValue
        }
        resolve({
          defaultData: defaultData
        })
      }
      )
    },
    redirectBack: function () {
      const configService = new ConfigService()
      return configService.getAccountSettingsRouteConfig()
    },
    submitForm: function (payload) {
      const profileService = new ProfileService()
      return profileService.updateExtraField(payload)
    }
  },
  'foods-do-not-like':
    {
      'title':
        'general.foods.i.dislike',
      'description': '',
      'pageContainerClass': 'profile-form-page-foods-do-not-like',
      'topHTML': function (data) {
        let email = data.email || ''
        return `<div>
        <div class="text-left with-under-line">
          <p class="btn-no-border__text-top dislike-foods__info">` + i18n.t('message["profile.account-settings.dietary-help"]') + '<strong><a href="mailto:' + email + '">' + email + `</a></strong></p>
        </div>
      </div>`
      },
      inputs:
        [
          {
            type: 'groupDislikes',
            name: 'profile.dietary-preferences.food-dont-like',
            model: 'excluded_ingredients',
            optionsKey: 'ingredientList',
            placeholder: 'profile.dietary-preferences.food-dont-like',
            validationRules: ''
          }
        ],
      submitType: 'btnClick',
      getDefaultData: function () {
        return new Promise((resolve, reject) => {
          const profileService = new ProfileService()

          let defaultData = {}
          const configService = new ConfigService()
          let ingredients = []
          configService.getIngredientList().then(res => {
            ingredients = res
            defaultData = {
              excluded_ingredients: profileService.getExcludedIngrediants()
            }
            resolve({
              defaultData: defaultData,
              formOptions: {
                ingredientList: ingredients
              }
            })
          })
        })
      },
      redirectBack: function () {
        const configService = new ConfigService()
        return configService.getProfileSRouteConfig()
      },
      submitForm: function (payload) {
        const profileService = new ProfileService()
        return profileService.updateExcludedIngredients(payload)
      }
    },
  injuries:
    {
      title:
        'profile.fitness-preferences.injuries',
      description: 'profile.fitness-preferences.injuries.description',
      pageContainerClass: 'profile-form-page',
      inputs:
        [
          {
            type: 'multiSelect',
            name: 'profile.fitness-preferences.injuries',
            model: 'injuries',
            optionsKey: 'injuryList',
            placeholder: 'profile.fitness-preferences.injuries',
            validationRules: ''
          }
        ],
      submitType: 'onChange',
      submitText: 'Submit',
      getDefaultData: function () {
        return new Promise((resolve, reject) => {
          const profileService = new ProfileService()

          let defaultData = {}
          const configService = new ConfigService()
          let injuries = []
          configService.getInjuryList().then(res => {
            injuries = res
            defaultData = {
              injuries: profileService.getInjuries()
            }
            resolve({
              defaultData: defaultData,
              formOptions: {
                injuryList: injuries
              }
            })
          })
        })
      },
      redirectBack: function () {
        const configService = new ConfigService()
        return configService.getFitnessPreferenceRouteConfig()
      },
      submitForm: function (payload) {
        const profileService = new ProfileService()
        return profileService.updateInjuries(payload)
      }
    }
}
