<template>
  <div :class="componentClasses">
    <textarea class="gc-text-area__text-area"
              v-bind="$attrs"
              :placeholder="placeholder"
              :disabled="disabled"
              :readonly="readonly"
              :cols="cols"
              :rows="rows"
              :maxlength="maxlength"
              v-model="newValue"
              ref="input"
              @change="emitValue"
    ></textarea>
    <text-content v-if="errorMessage.length > 0" class="gc-text-area__error text-center">
      {{errorMessage}}
    </text-content>
  </div>
</template>

<script>
import TextContent from '../../root/TextContent'

export default {
  name: 'TextArea',
  data () {
    return {
      newValue: ''
    }
  },
  components: {
    TextContent
  },
  props: {
    /**
       * to set an error message
       * expected value - string
       */
    errorMessage: {
      default: ''
    },
    /**
       * to change the value
       * expected value - string
       */
    value: {
      default: ''
    },
    /**
       * to change the placeholder
       * expected value - string
       */
    placeholder: {
      default: ''
    },
    /**
       * to change the state
       * expected value - true, false
       */
    disabled: {
      default: false
    },
    /**
       * to change the value of cols
       * expected value - integer
       */
    cols: {
      default: ''
    },
    /**
       * change to read only
       * expected value - true, false
       */
    readonly: {
      default: false
    },
    /**
       * to change the value of rows
       * expected value - integer
       */
    rows: {
      default: 5
    },
    /**
       * to set a max length
       * expected value - integer
       */
    maxlength: {
      default: 10000
    },
    inputFontSize: {
      type: Number,
      default: 18
    },
    inputLineHeight: {
      type: Number,
      default: 1.5
    },
    inputFontWeight: {
      type: Number,
      default: 700
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-text-area': true,
        'gc-text-area--error': this.errorMessage.length > 0
      }
    }
  },
  watch: {
    /**
       * to set and return value of input
       */
    value () {
      this.newValue = this.value
    },
    newValue () {
      this.$emit('input', this.newValue)
    }
  },
  mounted () {
    this.newValue = this.value
    this.setInputCssValues()
  },
  methods: {
    /**
       * to pass the value of input
       */
    emitValue () {
      this.$emit('change', this.newValue)
    },
    setInputCssValues () {
      const input = this.$refs.input
      input.style.fontSize = this.inputFontSize + 'px'
      input.style.fontWeight = this.inputFontWeight
      input.style.lineHeight = this.inputLineHeight
    }
  }
}
</script>
