<template>

  <page-secondary name="fade" mode="out-in" :header="headerOptions">
    <!--   page background -->
    <!--   page wrapper -->
    <page-container>
      <div style="padding-bottom: 50px; padding-left:0;padding-right:0;">
        <text-body-large >{{$t("message['general.your-meal-plans']")}}</text-body-large>
      </div>
      <grid-row>
        <grid-col md="6" cols="12 mb-4" :key="'meal-plan-list'+id" v-for="(mealPlan,id) in mealPlans">
          <card-navigation :image="mealPlan.image"
                           :header-text="mealPlan.name"
                           @click="navigateNext(mealPlan.id)"
          />
        </grid-col>

      </grid-row>
    </page-container>

  </page-secondary>

</template>

<script>

import BaseComponent from '../global/base/BaseComponent'
import MealPlanService from '../../../../services/MealPlanService'
import PageSecondary from '../../../global/pages/PageSecondary'
import CardNavigation from '../../template-2/global/cards/CardNavigation'
import GridRow from '../../../global/grid/GridRow'
import GridCol from '../../../global/grid/GridCol'
import PageContainer from '../../template-2/global/pages/page-sub-components/PageContainer'
import TextBodyRegular from '../../template-2/global/typography/TextBodyRegular'
import TextBodyLarge from '../../template-2/root/typography/TextBodyLarge'

export default {
  name: 'List',
  extends: BaseComponent,
  components: {
    TextBodyLarge,
    TextBodyRegular,
    PageContainer,
    GridCol,
    GridRow,
    CardNavigation,
    PageSecondary
  },
  data: function () {
    return {
      pageClass: '',
      mealPlans: [],
      headerOptions: {
        show: true,
        left: 'menu',
        modifiers: ['with-img '],
        header: {
          mainTitle: '',
          subTitle: ''
        }
      }
    }
  },
  beforeMount () {
    this.loadPageData()
  },
  mounted () {
    this.setPageClass()
    this.setHeader(this.headerOptions)
  },
  methods: {
    loadPageData () {
      const service = new MealPlanService()
      const self = this
      service
        .getActiveMealPlans()
        .then((data) => {
          this.mealPlans = data.meal_plans
          if (Array.isArray(this.mealPlans) && this.mealPlans.length === 1) {
            this.navigateNext(this.mealPlans[0].id)
          }
          self.setPageTitle(this.$i18n.t("message['general.your-meal-plans']"))
        }).finally(() => {
          this.hideLoading()
        })
    },
    navigateNext (id) {
      this.$router.push({
        path: [this.$appConfig.appUrlList.mealPlanBase, id].join('/')
      })
    }
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
  }
}
</script>
