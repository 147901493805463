<template>
    <page :header="header" class="gc-page-unit-pref">
      <validation-observer ref="form" :rules="'required'" :name="title.main" :vid="'unitPref'" v-slot="{ handleSubmit, invalid}">
          <page-container>
              <div class="gc-page-unit-pref__header text-center">
                  <text-heading-4
                      class="gc-page-unit-pref__title"
                      weight="extra-bold"
                      lineHeight="multi">{{title.main}}</text-heading-4>
                  <text-body-extra-small
                      class="gc-page-unit-pref__sub-title"
                      weight="medium"
                      lineHeight="multi">{{title.sub}}</text-body-extra-small>
              </div>
              <div class="gc-page-unit-pref__content">
                  <form id="form-input-unit-pref"
                      class="gc-page-unit-pref__form"
                      @submit.prevent="handleSubmit(nextComponent)">
                          <validation-provider v-slot="{ errors }" rules="required" vid="unitPrefInput" name="unit preference">
                              <div class="gc-page-unit-pref__measurement-system">
                                  <button-choice
                                    v-for="option in options"
                                    :key="'measurement-value'+option.key"
                                    type="button"
                                    class="gc-page-unit-pref__input gc-page-unit-pref__input--imperial"
                                    :text="getMetricValue(option.key)"
                                    v-model="measurementSystem"
                                    @click="setMeasurement(option.key)"
                                    :selected="measurementSystem === option.key"></button-choice>
                              </div>
                               <text-body-extra-small class="gc-page-progress-form__input-error unit-pref-error-msg text-center" lineHeight="multi">{{errors[0]}}</text-body-extra-small>
                              <div class="gc-page-unit-pref__measurement-info helper-text--unit-preference">
                                  <div v-if="measurementSystem==='imperial'" v-html="imperialHelper"></div>
                                  <div v-else-if="measurementSystem==='metric'" v-html="metricHelper"></div>
                              </div>
                          </validation-provider>
                  </form>
              </div>
          </page-container>
          <page-footer ref="footer" :form="'form-input-unit-pref'" :validated="invalid"></page-footer>
        </validation-observer>
    </page>
</template>

<script>
import FormBaseComponent from '../../layout/template-1/global/widgets/forms/FormBaseComponent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ButtonPrimary from '../buttons/ButtonPrimary'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import TextHeading4 from '../typography/TextHeading4'
import PageContainer from './page-sub-components/PageContainer'
import Page from './PageForm'
import ButtonChoice from '../buttons/ButtonChoice'
import PageFooter from './page-sub-components/PageFormFooter'

export default {
  name: 'PageUnitPref',
  extends: FormBaseComponent,
  components: {
    Page,
    PageContainer,
    TextHeading4,
    ButtonPrimary,
    TextBodyExtraSmall,
    ValidationObserver,
    ValidationProvider,
    ButtonChoice,
    PageFooter
  },
  data: function () {
    return {
      header: {
        isTransparent: false,
        isFixed: false,
        show: false,
        left: '',
        right: '',
        rightBlink: false,
        modifiers: [],
        mainTitle: '',
        subTitle: ''
      },
      metricHelper: this.$i18n.t("message['form-helper-text.unit-metric']"),
      imperialHelper: this.$i18n.t("message['form-helper-text.unit-imperial']"),
      selected: {
        imperial: false,
        metric: false
      },
      measurementSystem: ''
    }
  },
  mounted () {
    this.measurementSystem = this.inputValue
    setTimeout(() => {
      this.$refs.footer.setFooterClass()
      this.hideLoading()
    }, 100)
  },
  methods: {
    setMeasurement (preference) {
      if (preference === 'imperial') {
        this.measurementSystem = 'imperial'
      } else if (preference === 'metric') {
        this.measurementSystem = 'metric'
      }
    },
    getMetricValue (key) {
      const optionObj = this.options.find(option => {
        return option.key === key
      })
      if (optionObj) {
        return optionObj.value
      }
      return key
    },
    nextComponent () {
      const inputObj = { key: this.inputKey, value: this.measurementSystem }
      this.$emit('submit', inputObj)
    }
  }
}
</script>
