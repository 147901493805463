<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_25052_12702)">
        <path d="M16.5656 3.43343C15.029 1.89324 13.0002 0.941844 10.8334 0.745431C8.66667 0.549018 6.49979 1.12007 4.71127 2.35884C2.92274 3.59761 1.62627 5.42535 1.04831 7.52281C0.470355 9.62027 0.647646 11.8541 1.54922 13.8341C1.81322 14.4425 1.83721 15.1282 1.61636 15.7534L0.752075 18.3456C0.71633 18.4529 0.706585 18.5672 0.723643 18.679C0.740701 18.7908 0.784075 18.897 0.850193 18.9888C0.916312 19.0806 1.00328 19.1553 1.10395 19.2069C1.20462 19.2585 1.3161 19.2855 1.42922 19.2856C1.50598 19.2856 1.58222 19.273 1.65493 19.2484L4.24636 18.3849C4.8715 18.1638 5.55709 18.1873 6.16565 18.4506C8.14568 19.3511 10.3791 19.5276 12.476 18.9493C14.5729 18.3709 16.4001 17.0745 17.6385 15.2862C18.877 13.498 19.448 11.3316 19.2519 9.16524C19.0558 6.99889 18.1051 4.97023 16.5656 3.43343ZM10.0006 12.1427H6.42922C6.23978 12.1427 6.0581 12.0675 5.92414 11.9335C5.79019 11.7996 5.71493 11.6179 5.71493 11.4284C5.71493 11.239 5.79019 11.0573 5.92414 10.9234C6.0581 10.7894 6.23978 10.7141 6.42922 10.7141H10.0006C10.1901 10.7141 10.3718 10.7894 10.5057 10.9234C10.6397 11.0573 10.7149 11.239 10.7149 11.4284C10.7149 11.6179 10.6397 11.7996 10.5057 11.9335C10.3718 12.0675 10.1901 12.1427 10.0006 12.1427ZM13.5721 8.57129H6.42922C6.23978 8.57129 6.0581 8.49603 5.92414 8.36208C5.79019 8.22812 5.71493 8.04644 5.71493 7.857C5.71493 7.66756 5.79019 7.48588 5.92414 7.35192C6.0581 7.21797 6.23978 7.14271 6.42922 7.14271H13.5721C13.7615 7.14271 13.9432 7.21797 14.0772 7.35192C14.2111 7.48588 14.2864 7.66756 14.2864 7.857C14.2864 8.04644 14.2111 8.22812 14.0772 8.36208C13.9432 8.49603 13.7615 8.57129 13.5721 8.57129Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_25052_12702">
          <rect width="20" height="20" fill="white" transform="translate(0.25)"/>
        </clipPath>
      </defs>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconChat',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-chat': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
