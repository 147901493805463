<template>
  <b-modal
    ref="GeneralPopup"
    class="gc-popup"
    v-bind="componentAttrs"
    v-on="componentListeners"
    v-on:shown="setupPopupmutationObservers"
    @hide="closePopup"
    id="gc-popup"
  >
    <slot></slot>
  </b-modal>
</template>

<script>
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import PopupFooter from '../popups/popup-sub-components/PopupFooter'
import PopupBody from '../popups/popup-sub-components/PopupBody'
import PopupTitle from '../popups/popup-sub-components/PopupTitle'
import PopupHead from '../popups/popup-sub-components/PopupHead'
import PopupContent from '../popups/popup-sub-components/PopupContent'
import {mapGetters} from 'vuex'

export default {
  name: 'Popup',
  components: {
    PopupBody,
    PopupFooter,
    PopupTitle,
    PopupHead,
    PopupContent
  },
  props: {
    fixedHeader: {
      default: false
    },
    minContentHeight: {
      default: 100
    },
    enableVisualViewport: {
      default: false
    }
  },
  mixins: [popupAnimationMixin],
  /**
   * for props - refer https://bootstrap-vue.org/docs/components/modal#comp-ref-b-modal-props
   * for events -refer https://bootstrap-vue.org/docs/components/modal#comp-ref-b-modal-events
   * @returns {{}}
   */
  data: function () {
    return {
      mutationObserverFooter: null,
      mutationObserverHeader: null,
      popupTitleHeight: 0,
      popupHeaderHeight: 0,
      popupFooterHeight: 0
    }
  },
  inheritAttrs: false,
  computed: {
    componentAttrs: function () {
      let defaults = {
        'size': 'md',
        'no-fade': true,
        'hide-body': true,
        'hide-header': true,
        'hide-footer': true,
        'hide-header-close': true
      }

      if (this.isDesktop) {
        defaults['dialog-class'] = 'gc-popup__dialog gc-popup__dialog--desktop'
        defaults['content-class'] = 'gc-popup__content gc-popup__content--hidden gc-popup__content--desktop gc-popup--with-extra-padding'
        defaults['centered'] = true
      } else {
        defaults['dialog-class'] = 'gc-popup__dialog'
        defaults['content-class'] = 'gc-popup__content gc-popup__content--hidden gc-popup--with-extra-padding'
        defaults['body-class'] = 'gc-popup'
        defaults['modal-class'] = 'gc-popup'
      }

      let props = this.$attrs
      if (props['dialog-class']) {
        props['dialog-class'] = props['dialog-class'] + ' ' + defaults['dialog-class']
      }
      if (props['content-class']) {
        props['content-class'] = props['content-class'] + ' ' + defaults['content-class']
      }
      if (props['body-class']) {
        props['body-class'] = props['body-class'] + ' ' + defaults['body-class']
      }
      if (props['modal-class']) {
        props['modal-class'] = props['modal-class'] + ' ' + defaults['modal-class']
      }
      return Object.assign(defaults, props)
    },
    componentListeners: function () {
      return this.$listeners
    },
    ...mapGetters({
      orientation: 'getOrientation'
    })
  },
  watch: {
    orientation () {
      setTimeout(() => {
        this.setContentHeight()
      }, 1000)
    }
  },
  beforeDestroy () {
    if (this.mutationObserverFooter) {
      this.mutationObserverFooter.disconnect()
    }
    if (this.mutationObserverHeader) {
      this.mutationObserverHeader.disconnect()
    }
    this.removeEventListener()
  },
  mounted () {
    this.addEventListener()
  },
  methods: {
    setupPopupmutationObservers () {
      const popupHeader = document.querySelector('.gc-popup :not(.gc-popup__body-content) > .gc-popup__head')

      const popupFooter = document.querySelector('.gc-popup__footer')
      if (popupFooter) {
        const config = { attributes: true, childList: true, subtree: true }
        const callback = () => {
          this.setContentHeight()
        }
        this.mutationObserverFooter = new MutationObserver(callback)
        this.mutationObserverFooter.observe(popupFooter, config)
      }
      if (popupHeader) {
        const config = { attributes: true, childList: true, subtree: true }
        const callback = () => {
          this.setContentHeight()
        }
        this.mutationObserverHeader = new MutationObserver(callback)
        this.mutationObserverHeader.observe(popupHeader, config)
      }
      this.setContentHeight()
      setTimeout(() => {
        this.setContentHeight()
      }, 1000)
    },
    addEventListener () {
      if (this.enableVisualViewport && window.visualViewport) {
        visualViewport.addEventListener('resize', this.setContentHeight)
      } else {
        window.addEventListener('resize', this.setContentHeight)
      }
    },
    removeEventListener () {
      if (this.enableVisualViewport && window.visualViewport) {
        visualViewport.removeEventListener('resize', this.setContentHeight)
      } else {
        window.removeEventListener('resize', this.setContentHeight)
      }
    },
    setContentHeight () {
      // ignore content height set on desktop mode
      if (this.isDesktop) {
        return
      }

      let windowHeight = window.innerHeight
      let popupDialog = document.querySelector('.gc-popup .gc-popup__dialog')
      let modalPaddingTop = 0
      if (popupDialog) {
        const modalPaddingTopString = window.getComputedStyle(popupDialog).paddingTop
        modalPaddingTop = parseFloat(modalPaddingTopString.replace('px', ''))
      }

      let popupModalContent = document.querySelector('.modal-content')
      let modalContentPaddingTop = 0
      if (popupModalContent) {
        const modalContentPaddingTopString = window.getComputedStyle(popupModalContent).paddingTop
        modalContentPaddingTop = parseFloat(modalContentPaddingTopString.replace('px', ''))
      }

      const popupContent = document.querySelector('.gc-popup .gc-popup__body-content')
      const popupBody = document.querySelector('.gc-popup .gc-popup__body')
      const popupTitle = document.querySelector('.gc-popup .gc-popup__title')

      let popupTitleHeight = 0
      if (popupTitle) {
        popupTitleHeight = popupTitle.offsetHeight || 0
      }
      const popupFooter = document.querySelector('.gc-popup :not(.gc-popup__body-content) > .gc-popup__footer')
      let popupFooterHeight = 0
      if (popupFooter) {
        popupFooterHeight = popupFooter.offsetHeight || 0
      } else if (popupContent) {
        popupContent.style.paddingBottom = '40px'
      }
      const popupFloatingFooter = document.querySelector('.gc-popup :not(.gc-popup__body-content) > .gc-popup__floating-footer')
      let popupFloatingFooterHeight = 0
      if (popupFloatingFooter) {
        popupFloatingFooterHeight = popupFloatingFooter.offsetHeight || 0
        let floatingFooterTop = (windowHeight - modalPaddingTop - popupFloatingFooterHeight - 60)
        popupFloatingFooter.style.top = (floatingFooterTop) + 'px'
        const staticBottom = document.querySelector('.gc-popup .gc-popup_body-static-bottom')
        staticBottom.style.width = '100%'
        staticBottom.style.height = (popupFloatingFooterHeight + 60) + 'px'
      }
      const popupHeader = document.querySelector('.gc-popup :not(.gc-popup__body-content) > .gc-popup__head')
      let popupHeaderHeight = 0
      if (popupHeader) {
        popupHeaderHeight = popupHeader.offsetHeight || 0
      }
      if (popupHeader && popupHeader.classList.contains('gc-popup__head--fixed')) {
        let height = windowHeight - modalPaddingTop - popupTitleHeight - popupFooterHeight - popupHeaderHeight - modalContentPaddingTop

        if (height >= this.minContentHeight) {
          if (popupContent) {
            popupContent.style.height = (height) + 'px'
            this.setDisableBodyScroll(popupContent)
          }
          popupBody.style.height = 'auto'
        } else {
          popupBody.style.height = (windowHeight - modalPaddingTop - popupTitleHeight - modalContentPaddingTop) + 'px'
          this.setDisableBodyScroll(popupBody)
          if (popupContent) {
            popupContent.style.height = 'auto'
          }
        }
      } else if (popupContent) {
        let contentHeight = (windowHeight - modalPaddingTop - popupTitleHeight - popupHeaderHeight - modalContentPaddingTop - popupFooterHeight)

        if (contentHeight >= this.minContentHeight) {
          popupContent.style.height = contentHeight + 'px'
          popupBody.style.height = 'auto'
          this.setDisableBodyScroll(popupContent)
        } else {
          let bodyHeight = (windowHeight - modalPaddingTop - popupTitleHeight)
          popupBody.style.height = bodyHeight + 'px'

          popupContent.style.height = 'auto'
          popupBody.style.overflow = 'auto'
          this.setDisableBodyScroll(popupBody)
        }
      }
      if (windowHeight > 1020) {
        popupDialog.style.width = '375px'
        popupDialog.style.paddingTop = '24vh'
      }
    },
    setDisableBodyScroll (element) {
      // disableBodyScroll(element)
      // disableBodyScroll(document.querySelector('.macroapp'))
    },
    closePopup (e = null) {
      if (e) {
        e.preventDefault()
      }
      // clearAllBodyScrollLocks()
      this.closeAnimatedPopup(() => {
        this.$emit('hide', e)
      })
    }
  }
}
</script>
