import { render, staticRenderFns } from "./IconMealPlanFilled.vue?vue&type=template&id=328d7905&scoped=true"
import script from "./IconMealPlanFilled.vue?vue&type=script&lang=js"
export * from "./IconMealPlanFilled.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "328d7905",
  null
  
)

export default component.exports