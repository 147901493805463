<template>
  <div>
    <popup-content>
      <text-content class="gc-popup-exercise-swap__success-description" :size="'sm2'" :weight="'medium'" :line-height="'multi'" >{{description}}</text-content>
      <text-content class="gc-popup-exercise-swap__support-email" :size="'sm1'" :weight="'extra-bold'" :line-height="'multi'" ><a :href="'mailto:'+trainerEmail">{{trainerEmail}}</a></text-content>
    </popup-content>
    <popup-floating-footer>
      <button-primary :text="footerData.btnLabel" :disabled="!footerData.active" @click="footerSubmit"></button-primary>
    </popup-floating-footer>
  </div>
</template>

<script>
import workoutSwapMixin from '../../../../../mixins/workoutSwapMixin'
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupTextBody from '../../../../global/popups/popup-sub-components/PopupTextBody'
import PopupFloatingFooter from '../../../../global/popups/popup-sub-components/PopupFloatingFooter'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import TextContent from '../../../../root/TextContent'

export default {
  name: 'SwapSuccess',
  mixins: [workoutSwapMixin],
  props: {
    message: {
      default: ''
    },
    trainerEmail: {
      default: ''
    }
  },
  components: {
    ButtonPrimary,
    PopupFloatingFooter,
    PopupTextBody,
    PopupContent,
    LoadingBuffer,
    TextContent
  },
  data: function () {
    return {
      description: '',
      footerData: {
        btnLabel: this.$i18n.t('message["general.view-new-exercise"]'),
        active: true
      }
    }
  },
  mounted () {
    this.setPageLanguageData()

    this.$emit('content-change')
  },
  methods: {
    setPageLanguageData () {
      // title
      this.setSwapTitle(this.$i18n.t('message["swap.success-title"]'))
      // success description
      if (this.message) {
        this.description = this.message
      } else {
        this.description = this.$i18n.t("message['swap.exercise-has-been-swapped']")
      }
      if (this.description !== this.$i18n.t("message['swap.exercise-has-been-swapped']")) {
        this.setSwapTitle(this.$i18n.t('message["swap.request-success-title"]'))
      }
    },
    footerSubmit () {
      this.$emit('successReload', 'Exercise Swapped')
    }
  }
}
</script>
