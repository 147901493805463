import moment from 'moment'
import {mapGetters, mapMutations} from 'vuex'
import FoodDiaryService from '../../../../services/FoodDiaryService'
import logMealMixin from './logMealMixin'

export default {
  mixins: [logMealMixin],
  props: {
    subHeading: {
      default: ''
    },
    mealLog: {
      default: function () {
        return {}
      },
      type: Object
    }
  },
  data: function () {
    return {
      mealPlanID: 0,
      state: 'init',
      days: [],
      currentDayID: 0,
      currentDay: null,
      currentLog: null,
      service: new FoodDiaryService(),
      loggedMeals: [],
      previousLoggedMeals: [],
      dayText: '',
      selectedOptionText: ''
    }
  },
  watch: {
    mealLog: {
      immediate: true,
      handler: function (value) {

        this.dayText = moment(value.logged_date).format('ll')
        if (this.dayText === moment().format('ll')) {
          this.dayText = this.$i18n.t('message["general.today"]').toLowerCase()
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      previousRoute: 'getPreviousRoute',
      lastSelectedMeals: 'foodDiaryStore/getLastSelectedMeals'
    }),
    newMealsCount: function () {
      let count = 0
      this.loggedMeals.forEach(meal => {
        if (meal.mealType === 'platform') {
          let isLoggedPreviously = this.previousLoggedMeals.some(previousLoggedMeal => {
            return previousLoggedMeal.meal_type === 'platform' && previousLoggedMeal.id === meal.id
          })
          if (!isLoggedPreviously) {
            count++
          }
        }
      })
      return count
    },
    isLoggedMealsChanged: function () {
      if (this.newMealsCount > 0) {
        return true
      }
      let previousLoggedPlatformMeals = this.previousLoggedMeals.filter(previousLoggedMeal => {
        return previousLoggedMeal.meal_type === 'platform'
      })
      let loggedPlatformMeals = this.loggedMeals.filter(loggedMeal => {
        return loggedMeal.meal_type === 'platform' || loggedMeal.mealType === 'platform'
      })
      if (previousLoggedPlatformMeals.length !== loggedPlatformMeals.length) {
        return true
      } else if (previousLoggedPlatformMeals.length === 0 && loggedPlatformMeals.length === 0) {
        return false
      }
      return !previousLoggedPlatformMeals.every(previousLoggedMeal => {
        return loggedPlatformMeals.some(loggedMeal => {
          return previousLoggedMeal.id === loggedMeal.id
        })
      })
    }
  },
  methods: {
    ...mapMutations({
      setLastSelectedMeals: 'foodDiaryStore/setLastSelectedMeals'
    }),

    setSelectedOptionDayText () {
      let currentRequired = this.currentLog.required
      let selectedDay = this.days.find(day => {
        return day.getCalories() === currentRequired.calories ||
          day.getMacroNutrients().carbohydrates === currentRequired.macronutrients.carbohydrates ||
          day.getMacroNutrients().fats === currentRequired.macronutrients.fats ||
          day.getMacroNutrients().proteins === currentRequired.macronutrients.proteins
      })
      if (selectedDay) {
        this.selectedOptionText = this.numberWordToNumeric(selectedDay.getName())
      } else {
        this.selectedOptionText = this.$i18n.t('message["food-diary.another-option"]')
      }
    },
    contentChanged () {
      this.$emit('changed')
    },
    isSelected (id) {
      return this.loggedMeals.some(meal => {
        return meal.id === id
      })
    },
    isSelectedAnotherDay () {
      if (!this.currentDay) {
        return false
      } else if (this.currentDay.getCalories()) {
        return this.mealLog.required.calories !== this.currentDay.getCalories() ||
          this.mealLog.required.macronutrients.carbohydrates !== this.currentDay.getMacroNutrients().carbohydrates ||
          this.mealLog.required.macronutrients.fats !== this.currentDay.getMacroNutrients().fats ||
          this.mealLog.required.macronutrients.proteins !== this.currentDay.getMacroNutrients().proteins
      }

      return true
    },
    submitData () {
      if (this.state !== 'submitting' && (this.isLoggedMealsChanged)) {
        this.state = 'submitting'
        this.$emit('submitting')
        this.currentLog.logged_meals = this.loggedMeals
        this.service.saveMealLog(this.currentLog).then((data) => {
          this.$emit('log', data.log)
          this.setUserEventMealsLogged(data.log)
          this.$emit('success', {type: 'meal-plan', saved: false})
        }).finally(() => {
          this.setLastSelectedMeals(null)
          this.state = 'init'
        })
      }
    },
    setSelected (type, currentMeal) {
      const platformMeal = currentMeal.getAPIResponse()
      platformMeal.mealType = 'platform'
      platformMeal.time = moment().locale('en').format('HH:mm')
      platformMeal.date = moment().locale('en').format('YYYY-MM-DD')
      if (type.selected) {
        let hasMeal = this.loggedMeals.some(meal => {
          return meal.id === platformMeal.id
        })
        if (!hasMeal) {
          let recipes = platformMeal.recipes.map(recipe => {
            return recipe.name
          })
          platformMeal.name = recipes.join(' | ')

          this.loggedMeals.push(platformMeal)
        }
      } else {
        this.loggedMeals = this.loggedMeals.filter(meal => {
          return meal.id !== platformMeal.id
        })
      }

      this.setLastSelectedMeals(this.loggedMeals)
    },
    customMealButtonClick () {
      this.$emit('request-custom-meal', {
        required: {
          macronutrients: this.currentDay.getMacroNutrients(),
          calories: this.currentDay.getCalories()
        }
      })
      this.$emit('changed')
    },
    setDay (day) {
      let dayID = day.getId()
      this.currentDay = null

      this.$nextTick(() => {
        this.currentDayID = dayID
        this.currentDay = this.days.find((day) => {
          return day.getId() === this.currentDayID
        })

        if (this.mealLog && this.mealLog.logged_meals.length === 0 && this.loggedMeals.length === 0) {
          this.currentLog.required = {
            macronutrients: this.currentDay.getMacroNutrients(),
            calories: this.currentDay.getCalories()
          }
        }
        this.$emit('meal-log-required-info-changed', this.currentLog)
      })
      this.setSelectedOptionDayText()
    },
    setUserEventMealsLogged (log) {
      this.loggedMeals.forEach(meal => {
        if (meal.mealType === 'platform') {
          let isLoggedPreviously = this.previousLoggedMeals.some(previousLoggedMeal => {
            return previousLoggedMeal.meal_type === 'platform' && previousLoggedMeal.id === meal.id
          })
          if (!isLoggedPreviously) {
            const context = this.previousRoute.name === 'DashboardMain'
              ? 'dashboard'
              : this.previousRoute.name === 'MealPlanMainList'
                ? 'food-diary'
                : 'external-page'
            this.logEvent('FoodDiary.MealLogged', {
              'context': context,
              'selected_option': this.selectedOptionText,
              'is_saved': false,
              'is_custom': false,
              'meal_type': (meal.type.name).toLowerCase(),
              'is_today': (log.logged_date === moment().locale('en').format('YYYY-MM-DD'))
            })
          }
        }
      })
    }
  }
}
