<template>
  <text-content
  :size= "'md4'"
  :weight= "weight"
  :lineHeight= this.lineHeight
  :decoration= this.decoration>
    <slot></slot>
  </text-content>
</template>

<script>
import TextContent from '../TextContent'

export default {
  name: 'text-body-large',
  components: {
    TextContent
  },
  props: {
    decoration: {
      default: ' '
    },
    lineHeight: {
      default: 'single'
    },
    weight: {
      default: 'medium'
    }
  }
}
</script>

<style scoped></style>
