<template>
    <svg
            fill="none"
            height="25"
            viewBox="0 0 25 25"
            width="25"
            xmlns="http://www.w3.org/2000/svg"
    >
        <path
                d="M18.5098 12.0192H12.9809V6.73077C12.9809 6.4649 12.7655 6.25 12.5001 6.25C12.2348 6.25 12.0194 6.4649 12.0194 6.73077V12.0192H6.49053C6.22515 12.0192 6.00977 12.2341 6.00977 12.5C6.00977 12.7659 6.22515 12.9808 6.49053 12.9808H12.0194V18.75C12.0194 19.0159 12.2348 19.2308 12.5001 19.2308C12.7655 19.2308 12.9809 19.0159 12.9809 18.75V12.9808H18.5098C18.7751 12.9808 18.9905 12.7659 18.9905 12.5C18.9905 12.2341 18.7751 12.0192 18.5098 12.0192Z"
                fill="#868686"
                id="btn-add-entry"
        />
    </svg>
</template>
<script>
export default {
  name: 'plus-svg'
}
</script>
