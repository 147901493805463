<template>
  <div
    class="gc-navigator-bottom-bar__floating-area-button"
    @click="goToFitnessDiary()">
    <div class="gc-navigator-bottom-bar__icon">
      <icon-metrics
        size="sm4"
        class="gc-navigator-bottom-bar__floating-area-button-icon"/>
    </div>
    <div class="gc-navigator-bottom-bar__text">
      <text-body-extra-small
        line-height="multi"
        class=" gc-navigator-bottom-bar__floating-area-button-text">
        {{ $t('message["navigator.bottom-bar.metrics"]') }}
      </text-body-extra-small>
    </div>
  </div>
</template>
<script>
import IconMetrics from '../../../root/icons/IconMetrics'
import TextBodyExtraSmall from '../../typography/TextBodyExtraSmall'

export default {
  name: 'NavigatorBottomBarButtonMetrics',
  components: {
    IconMetrics,
    TextBodyExtraSmall
  },
  methods: {
    goToFitnessDiary () {
      this.$emit('click')
    }
  }
}
</script>
