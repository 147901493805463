import axios from 'axios'
import BaseAPI from '../api/BaseAPI'
import DefineInclude from '../../DefineInclude'

/**
 * Base API Class that can be use to connect the apis
 */
export default class WorkoutLogFeedBack extends BaseAPI {
  url = DefineInclude.feedBackAPIURL

  /***
   * custom API for the send Feedback
   *
   */
  sendFeedback (payload) {
    const url = this.url
    const headers = this._headers
    headers['X-Api-Host'] = DefineInclude.apiUrl.replace(/\/$/, '')
    return axios.post(url, payload, { headers: headers })
  }
}
