export default {
  inject: ['activityTracker'],
  methods: {
    /**
     * to log the activity tracker by type and payload object
     * @param eventType -type
     * @param payload - object of parameters
     * @param log - boolean
     */
    logEvent (eventType, payload, log = true) {
      this.activityTracker.addEvent(eventType, payload, log)
    },
    setUserProperties (key, value, isOnce) {
      this.activityTracker.setUserProperties(key, value, isOnce)
    }
  }
}
