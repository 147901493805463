<template>
    <popup
    v-if="visible"
    ref="evergreenProgressImage"
    :visible="visible"
    @hide="closePopup"
    class="gc-popup-evergreen-progress-image"
    :no-close-on-backdrop="true"
  >
    <popup-title>
      <popup-button-close @hide="closePopup()"/>
    </popup-title>
    <popup-body class="gc-popup-evergreen-progress-image__body text-center">
        <popup-content>
          <div class="gc-popup-evergreen-progress-image__photo-wrapper">
            <img :src="data.photo" alt="Image Description">
          </div>
          <text-content :size="'sm3'">
            <div v-html="data.download_prompt"></div>
          </text-content>
        </popup-content>
        <popup-footer v-if="status==='init'">
            <button-primary :text="$t('message[\'popup.evergreen-progress-image.redeem-button\']')" @click="downloadImage"></button-primary>
        </popup-footer>
    </popup-body>
  </popup>
</template>
<script>
import Popup from './Popup'
import PopupBody from './popup-sub-components/PopupBody'
import PopupTitle from './popup-sub-components/PopupTitle'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupContent from './popup-sub-components/PopupContent'
import PopupFooter from './popup-sub-components/PopupFooter'
import ButtonPrimary from '../buttons/ButtonPrimary'
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import TextContent from '../../root/TextContent'
import PopupTextBody from './popup-sub-components/PopupTextBody'
import NativeAppMixin from '../../../mixins/NativeAppMixin'
import { isMobileNativeApp } from '../../../includes/NativeAppCommon'
export default {
  name: 'PopupEvergreenProgressImage',
  components: {
    Popup,
    PopupBody,
    PopupTitle,
    PopupButtonClose,
    PopupContent,
    PopupFooter,
    ButtonPrimary,
    TextContent,
    PopupTextBody
  },
  mixins: [popupAnimationMixin, NativeAppMixin],
  props: {
    data: {
      type: Object,
      required: true
    },
    visible: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      status: 'init',
      loading: true
    }
  },
  mounted() {
    this.logEvent('EvergreenProgressPics.PostPhotoViewed', {})
  },
  methods: {
    closePopup (e, callback) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('hideEvergreenProgressImagePopup')
        if (callback) {
          callback()
        }
      })
    },

    downloadImage () {
      if (isMobileNativeApp()) {
        this.handleUrlNavigate(this.data.photo) 
      } else {
        const imageUrl = this.data.photo

        const link = document.createElement('a')
        link.href = imageUrl
        link.target = '_blank'
        link.download = 'image.png'
        link.click()
        this.logEvent('EvergreenProgressPics.PostPhotoDownloaded', {})
      }
      
      //  try {
      //     const imageUrl = this.data.photo

      //     const response = await fetch(imageUrl)
      //     const imageData = await response.blob()

      //     const blobUrl = window.URL.createObjectURL(imageData)

      //     const link = document.createElement('a');=
      //     link.href = blobUrl;
      //     link.download = 'evergreen_image.jpg'
      //     link.click()
      //   } catch (error) {
      //     console.error('Error downloading image:', error);
      //   }
    }
  }
}
</script>
