<template>
  <popup
    :visible="visible"
    @hide="closePopup"
    modal-class="gc-popup gc-popup-server-error gc-popup-error"
    v-if="visible"
  >
  <popup-title>
    <popup-button-close @click="closePopup"></popup-button-close>
  </popup-title>
  <popup-body class="text-center">
    <popup-content>
      <popup-head>
        <icon-warning class="gc-popup-error__header-icon" :size="'lg3'"></icon-warning>
        <popup-text-heading  class="gc-popup-error__title">{{$i18n.t("message['popup.input-validation-error.title']")}}</popup-text-heading>
      </popup-head>
      <popup-text-body class="gc-popup-error__description">
        <text-content :size="'sm4'" :weight="'extra-bold'" :lineHeight="'multi'">{{$i18n.t("message['popup.input-validation-error.description']")}}</text-content>
      </popup-text-body>
      <popup-text-body class="gc-popup-error__list">
        <ul v-if="errors">
          <li v-for="(error, index) in errors" :key="index">
            <text-content :size="'sm2'" :weight="'medium'" :lineHeight="'multi'">{{errorToText(error)}}</text-content>
          </li>
        </ul>
      </popup-text-body>
      <div>
      </div>
    </popup-content>
    <popup-footer>
      <button-primary class="gc-popup-error__btn-wrapper" :text="$i18n.t('message[\'general.try-again-text\']' )"  @click="closePopup()" />
    </popup-footer>
  </popup-body>
  </popup>
</template>

<style>

</style>

<script>
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import ButtonPrimary from '../../global/buttons/ButtonPrimary'
import PopupFooter from '../../global/popups/popup-sub-components/PopupFooter'
import PopupTextBody from '../../global/popups/popup-sub-components/PopupTextBody'
import PopupTextHeading from '../../global/popups/popup-sub-components/PopupTextHeading'
import PopupHead from '../../global/popups/popup-sub-components/PopupHead'
import PopupContent from '../../global/popups/popup-sub-components/PopupContent'
import PopupBody from '../../global/popups/popup-sub-components/PopupBody'
import PopupButtonClose from '../../global/popups/popup-sub-components/PopupButtonClose'
import PopupTitle from '../../global/popups/popup-sub-components/PopupTitle'
import Popup from '../../global/popups/Popup'
import TextContent from '../../root/TextContent'
import IconWarning from '../../root/icons/IconWarning'

export default {
  name: 'PopupDataInvalid',
  props: {
    visible: {
      default: true
    },
    errors: {
      default: function () {
        return []
      }
    }

  },
  mixins: [popupAnimationMixin],
  components: {
    ButtonPrimary,
    PopupFooter,
    PopupTextBody,
    PopupTextHeading,
    PopupHead,
    PopupContent,
    PopupBody,
    PopupButtonClose,
    PopupTitle,
    Popup,
    TextContent,
    IconWarning
  },
  data: function () {
    return {
      imagesUrl: this.$appConfig.imagesUrl,
      logoIcon: this.$appConfig.imagesUrl + 'theme-switch.png'
    }
  },
  methods: {
    errorToText (error) {
      if (typeof error === 'string') {
        return error
      } else if (Array.isArray(error) && error[0]) {
        return error[0]
      }
      return error.toString()
    },
    showError (error) {
      if (Array.isArray(error)) {
        return error.join(',')
      }
      return error
    },
    closePopup () {
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    },
    submit () {
      this.$emit('submit')
    }
  }
}
</script>
