<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5 34C26.6127 34 34 26.6127 34 17.5C34 8.3873 26.6127 1 17.5 1C8.3873 1 1 8.3873 1 17.5C1 26.6127 8.3873 34 17.5 34Z" fill="#AFAFAF" fill-opacity="0.2"/>
    <path d="M34 17.5C34 26.6127 26.6127 34 17.5 34C8.3873 34 1 26.6127 1 17.5C1 8.3873 8.3873 1 17.5 1C26.6127 1 34 8.3873 34 17.5ZM2.485 17.5C2.485 25.7926 9.20744 32.515 17.5 32.515C25.7926 32.515 32.515 25.7926 32.515 17.5C32.515 9.20744 25.7926 2.485 17.5 2.485C9.20744 2.485 2.485 9.20744 2.485 17.5Z" fill="#AFAFAF"/>
    <path d="M14.2851 13.1426C14.2851 11.9613 13.3238 11 12.1426 11C10.9613 11 10 11.9613 10 13.1426C10 14.3238 10.9613 15.2851 12.1426 15.2851C13.3238 15.2851 14.2851 14.3238 14.2851 13.1426ZM11.4284 13.1426C11.4284 12.7491 11.7491 12.4284 12.1426 12.4284C12.5361 12.4284 12.8568 12.7491 12.8568 13.1426C12.8568 13.5361 12.5361 13.8568 12.1426 13.8568C11.7491 13.8568 11.4284 13.5361 11.4284 13.1426Z" fill="#AFAFAF"/>
    <path d="M23.5693 11C22.3881 11 21.4268 11.9613 21.4268 13.1426C21.4268 14.3238 22.3881 15.2851 23.5693 15.2851C24.7506 15.2851 25.7119 14.3238 25.7119 13.1426C25.7119 11.9613 24.7506 11 23.5693 11ZM23.5693 13.8568C23.1758 13.8568 22.8551 13.5361 22.8551 13.1426C22.8551 12.7491 23.1758 12.4284 23.5693 12.4284C23.9628 12.4284 24.2835 12.7491 24.2835 13.1426C24.2835 13.5361 23.9628 13.8568 23.5693 13.8568Z" fill="#AFAFAF"/>
    <path d="M23.7507 19.8081C22.2623 21.4729 20.114 22.4271 17.8557 22.4271C15.6039 22.4271 13.4542 21.4722 11.9594 19.8074C11.6958 19.5132 11.2445 19.4889 10.9509 19.7532C10.6574 20.0167 10.6331 20.4681 10.8967 20.7616C12.6621 22.7278 15.1989 23.8555 17.8557 23.8555C20.5197 23.8555 23.0565 22.727 24.8162 20.7609C25.0791 20.4666 25.0541 20.016 24.7598 19.7524C24.4649 19.4896 24.0135 19.5139 23.7507 19.8081Z" fill="#AFAFAF"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconGood',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-good': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
