<template>
  <div
    class="gc-calender-slider__wrapper"
    v-show="show">
    <slick
      ref="slick"
      :options="slickOptions"
      @afterChange="handleAfterChange"
      @beforeChange="handleBeforeChange"
      @breakpoint="handleBreakpoint"
      @destroy="handleDestroy"
      @edge="handleEdge"
      @init="handleInit"
      @reInit="handleReInit"
      @setPosition="handleSetPosition"
      @swipe="handleSwipe"
      @lazyLoaded="handleLazyLoaded"
      @lazyLoadError="handleLazeLoadError">

      <div
        class="gc-card-meal__values"
        >
        <loading-buffer
          style="width: 73px"
          height="73"
          v-if="hasMore"/>
      </div>

      <div
        class="gc-card-meal__values"
        v-for="(item, index) in dates"
        :key="index">
        <calender-slider-cal-day
          :month="item.month"
          :day="item.day"
          :is-selected="selected.day===item.day&&selected.month===item.month"
          :is-logged= "item.logged"
          :class="{ 'gc-calender-slider-cal-day--centered': dates.length<7 }"
          @click="setSelected(item,index)"
        />
      </div>
    </slick>
  </div>
</template>

<script>
import Slick from 'vue-slick'
import CalenderSliderCalDay from '../sliders/calender-slider-sub-components/CalenderSliderCalDay'
import { slickMixin } from '@/mixins/slickMixin'
import LoadingBuffer from '../../layout/template-1/global/widgets/LoadingBuffer'
import moment from 'moment'
export default {
  name: 'CalendarSlider',
  mixins: [slickMixin],
  components: {
    LoadingBuffer,
    Slick,
    CalenderSliderCalDay
  },
  data () {
    return {
      show: true,
      slickOptions: {
        slidesToShow: 13,
        slidesToScroll: 13,
        infinite: false,
        arrows: false,
        lazyLoad: 'ondemand',
        rtl: false,
        speed: 1000,
        initialSlide: 0,
        touchThreshold: 400,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 10,
              slidesToScroll: 10
            }
          },
          {
            breakpoint: 760,
            settings: {
              slidesToShow: 10,
              slidesToScroll: 10
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 6
            }
          },
          {
            breakpoint: 100,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5
            }
          }
        ]
      },
      selected: {
        id: null,
        date: null,
        day: null,
        month: null,
        index: 0
      },
      breakpointChanged: false,
      currentSlide: 0,
      previousCount: 0
    }
  },
  props: {
    /**
     {
     * month: month name,
     * day: day ,
     * date: date,
     * id: unique id for day
     * logged: is day logged
     * }
     */
    maxSlides: {
      default: 0
    },
    lastSelectedDay: {
      type: Object
    },
    /**
      * [{
      * month: month name,
      * day: day ,
      * date: date,
      * id: unique id for day
      * logged: is day logged
      * }]
     */
    dates: {
      default: []
    },
    hasMore: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    dates: {
      deep: true,
      immediate: true,
      handler: function (value, oldValue) {
        let currentLength = (value && Array.isArray(value)) ? value.length : 0
        let previousLength = (oldValue && Array.isArray(oldValue)) ? oldValue.length : 0
        if (!this.$refs.slick || currentLength === previousLength) {
          return
        }
        this.$refs.slick.destroy()
        let currentIndex = this.currentSlide + (value.length - this.previousCount)
        this.slickOptions.initialSlide = currentIndex
        this.$nextTick(() => {
          this.$refs.slick.create()
          if (this.previousCount === value.length) {
            this.slickOptions.initialSlide = value.length
            this.slickOptions.currentSlide = value.length
          } else {
            this.slickOptions.initialSlide = currentIndex
            this.slickOptions.currentSlide = currentIndex
          }
          if (this.$refs.slick) {
            this.$refs.slick.goTo(currentIndex)
          }

          this.previousCount = value.length
        })
      }
    }
  },
  beforeMount () {
    if (this.maxSlides && this.slickOptions.slidesToShow > this.maxSlides) {
      this.slickOptions.slidesToShow = this.maxSlides
      this.slickOptions.slidesToScroll = this.maxSlides
    }
    this.slickOptions.responsive.forEach((option, key) => {
      if (this.maxSlides && option.settings.slidesToShow > this.maxSlides) {
        this.slickOptions.responsive[key].slidesToShow = this.maxSlides
        this.slickOptions.responsive[key].slidesToScroll = this.maxSlides
      }
    })
    this.slickOptions.rtl = this.isRTL()
  },
  mounted () {
    this.previousCount = this.dates.length
  },
  methods: {
    handleBreakpoint (event, slick, breakpoint) {
      this.breakpointChanged = true
    },
    afterBreakpointChanged () {
      if (!this.lastSelectedDay) {
        let today = moment().locale('en').format('YYYY-MM-DD')

        this.dates.some((day, index) => {
          const isToday = today === day.date
          if (isToday) {
            this.selected = day
            this.selected.index = index
          }
          return isToday
        })
      } else {
        this.selected = this.lastSelectedDay
      }

      this.$nextTick(() => {
        let index = this.selected.index
        if (this.hasMore) {
          index++
        }
        this.$refs.slick.goTo(index)
        this.breakpointChanged = false
      })
    },
    setSelected (item, index) {
      this.selected = {
        day: item.day,
        id: item.id,
        month: item.month,
        date: item.date,
        index: index
      }
      this.$emit('selected', this.selected)
    },
    handleInit (event, slick) {
      if (!this.lastSelectedDay) {
        let today = moment().locale('en').format('YYYY-MM-DD')

        this.dates.some((day, index) => {
          const isToday = today === day.date
          if (isToday) {
            this.selected = day
            this.selected.index = index
          }
          return isToday
        })
      } else {
        this.selected = this.lastSelectedDay
      }
      this.$nextTick(() => {
        let index = this.selected.index
        if (this.hasMore) {
          index++
        }
        if (!index) {
          this.dates.some((day, i) => {
            const sameDate = this.selected.date === day.date
            if (sameDate) {
              this.selected.index = i
              index = i
            }
            return sameDate
          })
        }
        this.slickOptions.initialSlide = index
        this.$refs.slick.goTo(index)
        this.$emit('selected', this.selected)
      })
    },
    handleAfterChange (event, slick, currentSlide) {
      this.currentSlide = currentSlide
      if (this.breakpointChanged) {
        this.afterBreakpointChanged()
      }
    },
    handleSwipe (event, slick, direction) {
      this.currentSlide = slick.currentSlide
      if (this.currentSlide === 0) {
        this.emitLoadMore()
      }
    },
    handleEdge (event, slick, direction) {
      if (direction === 'right') {
        this.currentSlide = slick.currentSlide
        this.emitLoadMore()
      }
    },
    emitLoadMore () {
      if (this.hasMore) {
        this.$emit('load-more')
      }
    }
  },
  beforeDestroy () {
    this.$refs.slick.$el.style.display = 'none'
    this.show = false
  }
}
</script>

<style scoped>

</style>
