<template>
  <popup
    v-if="visible"
    ref="exerciseSwapModal"
    :visible="visible"
    modal-class="pc-popup-suggested-exercise-swap "
    @hide="closePopup"
  >
    <popup-title class="gc-popup__title--multiple" :style="popupTitleStyles">
      <popup-button-close @hide="closePopup"/>
      <popup-button-back v-if="type==='alternatives' && this.subComponentState !== 'success'" @click="type='options'"/>
    </popup-title>

    <popup-suggested-exercise-swap-container-contact-support
      v-if="type==='contact-support'"
      :exercise="exercise"
      @mounted="contentChanged"
      @state="subComponentStateChanged($event)"
      @swap-success="swapSuccess"
    />
    <popup-suggested-exercise-swap-container-options
      v-else-if="type==='options'"
      :exercise="exercise"
      :suggested-exercises="suggestedExercises"
      @type-selected="setSuggestionType($event)"
      @injuries-selected="setInjuriesSelected($event)"
      @mounted="contentChanged"
      @state="subComponentStateChanged($event)"
      @swap-success="swapSuccess"
    />
    <popup-suggested-exercise-swap-container-injuries
      v-else-if="type==='injuries'"
      :exercise="exercise"
      @mounted="contentChanged"
      @state="subComponentStateChanged($event)"
      @swap-success="swapSuccess"/>
    <popup-suggested-exercise-swap-container-alternatives
      v-else-if="type === 'alternatives'"
      :exercise="exercise"
      :type="suggestionType"
      :alternatives="suggestedExercises[suggestionType]"
      @mounted="contentChanged"
      @state="subComponentStateChanged($event)"
      @swap-success="swapSuccess"
    />
    <loading-buffer v-else-if="type==='loading'"/>

  </popup>
</template>

<script>

import LoadingBuffer from '../../../global/widgets/LoadingBuffer'
import Popup from '../../../../../global/popups/Popup'
import PopupContent from '../../../../../global/popups/popup-sub-components/PopupContent'
import PopupBody from '../../../../../global/popups/popup-sub-components/PopupBody'
import PopupButtonClose from '../../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupTextBody from '../../../../../global/popups/popup-sub-components/PopupTextBody'
import PopupHead from '../../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupTitle from '../../../../../global/popups/popup-sub-components/PopupTitle'
import popupAnimationMixin from '../../../../../../mixins/popupAnimationMixin'
import ProfileService from '../../../../../../services/ProfileService'
import { mapActions, mapMutations } from 'vuex'
import WorkoutProgramExerciseService from '../../../../../../services/WorkoutProgramExerciseService'
import TextBodyExtraSmall from '../../../../../global/typography/TextBodyExtraSmall'
import PopupSuggestedExerciseSwapContainerContactSupport
  from './popup-suggested-exercise-swap-sub-components/PopupSuggestedExerciseSwapContainerContactSupport'
import PopupSuggestedExerciseSwapContainerOptions
  from './popup-suggested-exercise-swap-sub-components/PopupSuggestedExerciseSwapContainerOptions'
import PopupSuggestedExerciseSwapContainerInjuries
  from './popup-suggested-exercise-swap-sub-components/PopupSuggestedSupportExerciseSwapContainerInjuries'
import PopupSuggestedExerciseSwapContainerAlternatives
  from './popup-suggested-exercise-swap-sub-components/PopupSuggestedExerciseSwapContainerAlternatives'
import IconBackward from '../../../../../root/icons/IconBackward'
import PopupButtonBack from '../../../../../global/popups/popup-sub-components/PopupButtonBack'

export default {
  name: 'PopupSuggestedExerciseSwap',
  components: {
    PopupButtonBack,
    IconBackward,
    PopupSuggestedExerciseSwapContainerAlternatives,
    PopupSuggestedExerciseSwapContainerInjuries,
    PopupSuggestedExerciseSwapContainerOptions,
    PopupSuggestedExerciseSwapContainerContactSupport,
    TextBodyExtraSmall,
    LoadingBuffer,
    Popup,
    PopupBody,
    PopupButtonClose,
    PopupTextBody,
    PopupHead,
    PopupTextHeading,
    PopupTitle,
    PopupContent
  },
  mixins: [popupAnimationMixin],
  props: {
    visible: {
      default: false
    },
    exercise: {
      required: true,
      validator: function (value) {
        return value instanceof WorkoutProgramExerciseService
      }
    }
  },
  data: function () {
    return {
      type: 'loading',
      suggestionType: '',
      params: this.$route.query,
      trainerEmail: null,
      canSwap: false,
      suggestedExercises: null,
      pageData: {
        title: ''
      },
      activeComponent: '',
      successMessage: '',
      state: 'loading',
      loading: false,
      hasError: false,
      subComponentState: 'init'
    }
  },
  computed: {
    popupTitleStyles: function () {
      return {
        'visibility': (this.subComponentState !== 'submitting') ? 'visible' : 'hidden'
      }
    }

  },
  mounted () {
    const profileService = new ProfileService()
    profileService.setProfileData()
      .then(data => {
        data = data.data
        if (typeof data.settings_manager.trainer.support_email !== 'undefined') {
          this.trainerEmail = data.settings_manager.trainer.support_email
        }
      })

    this.exercise.getSuggestedExerciseSwapDetails().then(data => {
      this.suggestedExercises = data.suggested_exercises || {}
      this.canSwap = data.can_swap || false
      if (this.canSwap) {
        this.type = 'options'
      } else {
        this.type = 'contact-support'
      }
    })
  },
  methods: {
    ...mapMutations({
      unsetLogDataInStore: 'workoutLogStore/unsetLogFormData',
      setCurrentExercise: 'workoutLogStore/setCurrentExercise',
      removeWorkoutLog: 'singleExercisePageStore/removeWorkoutLog'
    }),
    ...mapActions({
      removeWorkoutLog: 'singleExercisePageStore/removeWorkoutLog'
    }),
    subComponentStateChanged (state) {
      this.subComponentState = state
    },
    swapSuccess () {
      setTimeout(() => {
        this.afterSuccessRedirect()
        this.closePopup()
      }, 2000)
    },
    contentChanged () {
      this.$refs.exerciseSwapModal.setContentHeight()
    },
    setSuggestionType (type) {
      this.type = 'alternatives'
      this.suggestionType = type
    },
    setInjuriesSelected (type) {
      this.type = 'injuries'
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        // TODO: refactor this to an emit
        this.$parent.hideExerciseSwapPopup()
      })
    },
    unsetWorkoutLogCache () {
      const exerciseID = this.exercise.getID()
      this.setCurrentExercise(exerciseID)
      this.unsetLogDataInStore(exerciseID)
      this.removeWorkoutLog()
    },
    afterSuccessRedirect () {
      this.closeAnimatedPopup(() => {
        this.unsetWorkoutLogCache()
        this.$emit('successHandle')
        this.swapSuccess = true
      })
    }
  }
}
</script>
