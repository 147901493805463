<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M13.9989 1.77714L20.6806 7.73943L20.6818 7.74054C20.9975 8.02818 21.2496 8.38064 21.4217 8.77469C21.5939 9.16925 21.682 9.59652 21.68 10.0283V19.9482C21.68 20.7576 21.3642 21.5338 20.8019 22.1061C20.2396 22.6785 19.477 23 18.6818 23H5.3184C4.52322 23 3.76061 22.6785 3.19833 22.1061C2.63606 21.5338 2.32017 20.7576 2.32017 19.9482L2.32018 10.0412L2.32016 10.0385C2.31678 9.60499 2.40416 9.17576 2.57648 8.77934C2.74859 8.38343 3.00148 8.02933 3.31835 7.74056L9.99873 1.77943L10.0013 1.77712C10.551 1.27667 11.2626 1 12.0001 1C12.7376 1 13.4492 1.27669 13.9989 1.77714ZM14.57 21.4305V15.4141C14.57 15.021 14.4165 14.644 14.1434 14.366C13.8703 14.088 13.4999 13.9319 13.1137 13.9319H10.8865C10.5003 13.9319 10.1299 14.088 9.85677 14.366C9.58367 14.644 9.43024 15.021 9.43024 15.4141V21.4305H14.57ZM16.1119 21.4305H18.6818C19.068 21.4305 19.4384 21.2743 19.7115 20.9963C19.9846 20.7183 20.1381 20.3413 20.1381 19.9482L20.1381 10.0299C20.1378 9.81944 20.0935 9.61101 20.0081 9.41933C19.9228 9.22765 19.7983 9.05667 19.6429 8.91776L12.9612 2.96679C12.6955 2.72922 12.3538 2.5981 12.0001 2.5981C11.6465 2.5981 11.305 2.72904 11.0393 2.96648L4.35779 8.91731C4.20246 9.05621 4.07745 9.22765 3.99208 9.41933C3.90671 9.61101 3.86242 9.819 3.86216 10.0295V19.9482C3.86216 20.3413 4.01558 20.7183 4.28868 20.9963C4.56178 21.2743 4.93218 21.4305 5.3184 21.4305H7.88826V15.4141C7.88826 14.6047 8.20414 13.8285 8.76642 13.2562C9.3287 12.6838 10.0913 12.3623 10.8865 12.3623H13.1137C13.9089 12.3623 14.6715 12.6838 15.2338 13.2562C15.7961 13.8285 16.1119 14.6047 16.1119 15.4141V21.4305Z"
            fill="#9E9E9E"/>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconHomeLinear',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-home-linear': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
