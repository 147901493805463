<template>
  <div class="product-offer-wrap">
    <button type="button" class="btn-main btn-shopping" v-on:click="purchase">
      <div class="col-12">
        <div class="row product-offer-row">
          <div class="col-9 p-0 product-offer-col">
            <div class="btn-shopping__title p-0 text-center">
              <p v-html="product"></p>
              <div v-if="productImage">
                <img class="product-offer-image" style='' :src="productImage"/>
              </div>
              <span class="text-left" v-html="productDescription"></span>
              <span class="product-offer-price">Buy Now Just {{displayAmount}}</span>
            </div>
          </div>
        </div>
      </div>
    </button>
  </div>
</template>
<script>
import upsellButtonMixin from '../../../../../mixins/upsellButtonMixin'

export default {
  name: 'upsell-button',
  mixins: [upsellButtonMixin]
}
</script>
