import HabitsApi from '../api/habits/HabitsApi'
import APIErrorHandler from '../api-handler/APIErrorHandler'
import moment from 'moment'
import lodash from 'lodash'
import Habit from './Habit'
import HabitDayEntries from './HabitDayEntries'

export default class HabitsTrackerService {
    _api = null
    habitsEntries = []

    /**
   *
   * @type {[Habit]}
   */
    habits =[]
  _isHabitsLoaded = []
  constructor () {
    this._api = new HabitsApi()
  }

  setHabits () {
    return new Promise((resolve, reject) => {
      this._api.getAllHabitsEntries()
        .then(data => data.data.data)
        .then(data => {
          this.habits = []
          data.forEach(habit => {
            this.habits.push(new Habit(habit))
          })
          this._isHabitsLoaded = true
          resolve(this.habits)
        }).catch(error => {
          if (error.response && error.response.status === 500) {
            this._handleServerError(error)
            reject(error)
          } else if (error.response && error.response.status !== 404 && error.response.status !== 403) {
            APIErrorHandler.logSentry(error)
            reject(error)
          }
        })
    })
  }

  getHabits () {
    if (this._isHabitsLoaded) {
      return this.habits
    }
    throw new Error('not habits loaded')
  }

  query (from, to) {
    from = from.format('YYYY-MM-DD')
    to = to.format('YYYY-MM-DD')

    return new Promise((resolve, reject) => {
      this._api.queryHabits(from, to)
        .then(data => data.data.data).then(data => {
          let habitDayEntries = []
          if (Array.isArray(data) && data.length > 0) {
            if (this._isAllHabitsNotHavingEntries(data)) {
              habitDayEntries = [ this._makeHabitDayEntriesByDate(this.getCurrentDate()) ]
            } else {
              data.forEach(habit => {
                if (habit.status_history.length !== 0) {
                  habit.status_history.forEach(item => {
                    let currentHabitDayEntryIndex = habitDayEntries.findIndex(habitDayEntry => {
                      return habitDayEntry.getDate() === moment(item.set_on).locale('en').format('YYYY-MM-DD')
                    })
                    if (currentHabitDayEntryIndex >= 0) {
                      habitDayEntries[currentHabitDayEntryIndex].setEntry(habit.id, item)
                    } else {
                      let currentHabitDayEntries = this._makeHabitDayEntriesByDate(moment(item.set_on).locale('en').format('YYYY-MM-DD'))
                      if (currentHabitDayEntries) {
                        currentHabitDayEntries.setEntry(habit.id, item)
                        habitDayEntries.push(currentHabitDayEntries)
                      }
                    }
                  })
                }
              })
            }
          }
          this.addHabitsEntries(habitDayEntries)
          resolve(habitDayEntries)
        }).catch(error => {
          if (error.response && error.response.status === 500) {
            this._handleServerError(error)
            reject(error)
          } else if (error.response && error.response.status !== 404 && error.response.status !== 403) {
            APIErrorHandler.logSentry(error)
            reject(error)
          }

          reject(error)
        })
    })
  }

  /**
   * API response of habits
   * @param habits
   * @returns {*}
   */
  _isAllHabitsNotHavingEntries (habits) {
    return habits.every(habit => {
      return habit.status_history.length === 0
    })
  }

  /**
   *
   * @param date
   * @returns {HabitDayEntries}
   * @private
   */
  _makeHabitDayEntriesByDate (date) {
    return new HabitDayEntries(date, this.habits)
  }
  addDayEntry (date) {
    let dayEntry = this._makeHabitDayEntriesByDate(date)
    this.addHabitsEntries([dayEntry])
  }
  addHabitsEntries (habitDayEntries) {
    let currentEntries = this.habitsEntries
    this.habitsEntries = [...habitDayEntries, ...currentEntries]
    this.habitsEntries = lodash.sortedUniqBy(this.habitsEntries, function (e) {
      return e.date
    })
  }
  getTodayDayEntries () {
    let todayDateRange = this.getTodayDateRange()
    return this.query(todayDateRange.from, todayDateRange.to).then(entries => {
      return entries[0]
    })
  }

  setHabit (habitId, status) {
    return new Promise((resolve, reject) => {
      this._api.setHabit(habitId, status).then(data => {
        this.habitsEntries = data
        resolve(data)
      }).catch(error => {
        if (error.response.status === 500) {
          this._handleServerError(error)
          reject(error)
        } else {
          APIErrorHandler.logSentry(error)
          reject(error)
        }
      })
    })
  }

  getTodayDateRange () {
    let firstDay = new Date()

    let dateRange = {
      'from': moment(firstDay).locale('en').set({hour: 0, minute: 0, second: 0, millisecond: 0}),
      'to': moment().locale('en')
    }
    return dateRange
  }

  getCurrentDate () {
    return moment().locale('en').format('YYYY-MM-DD')
  }

  /**
   * Log and show error popup
   * @param response
   * @private
   */
  _handleServerError (response) {
    let sentryEventID = APIErrorHandler.logSentry(response)
    APIErrorHandler.showErrorPopup(sentryEventID)
  }
}
