import axios from 'axios'
import BaseAPI from '../BaseAPI'

export default class ProfileApi extends BaseAPI {
  constructor () {
    super()
    this._resource = 'account-details'
    this.url = this._baseURL + this.endpoint
  }

  uploadImage (formData) {
    const url = this._getParentURL()
    const params = [url + this._resource, 'profile-image']
    const headers = this._headers
    headers['Content-Type'] = 'multipart/form-data'
    return axios.post(params.join('/'), formData,{headers})
  }

  changePassword (formData) {
    const url = this._getParentURL()
    const params = [url + this._resource, 'password']
    return axios.post(params.join('/'), formData)
  }

  addPushNotificationDevices(payload){
    const url = this._getParentURL()
    const params = [url + this._resource, 'devices']
    return axios.post(params.join('/'), payload)
  }
}
