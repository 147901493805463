import MealPlanMealRecipeService from './MealPlanMealRecipeService'
import MealPlanAPI from '../api/meal-plan/MealPlanAPI'

export default class MealPlanMealService {
  _id = null
  _name = ''
  _recipes = []
  _referenceMealId = ''
  _disliked = false
  _favourite = false
  _apiResponse = null

  constructor (data, planId) {
    this._apiResponse = data
    this._planId = planId
    this._id = data.id
    this._referenceMealId = data.reference_meal_id
    this._name = data.name || ''

    this._macroNutrients = data.macronutrients || {}
    this._calories = data.calories || 0

    this.swapStatus = data.swap_status || 'ok'
    this.canSwap = data.can_swap || false
    this.dislikeEnalbed = data.isPublished

    this.type = data.type || false
    this.isPaired = data.is_paired || false

    data.recipes.forEach(recipe => {
      this._recipes.push(new MealPlanMealRecipeService(recipe, this._planId))
    })
  }

  getId () {
    return this._id
  }
  getName () {
    return this._name
  }
  setDisliked (dislike) {
    this._disliked = dislike
  }

  setFavourite (favourite) {
    this._favourite = favourite
  }

  isDisliked () {
    return this._disliked
  }

  isFavourite () {
    return this._favourite
  }

  canDislike () {
    return this.dislikeEnalbed
  }

  getType () {
    return this.type
  }

  getCalories () {
    return this._calories
  }
  getMacroNutrients () {
    return this._macroNutrients
  }
  getReferenceMealId () {
    return this._referenceMealId
  }

  isPairedMeal () {
    return this.isPaired || false
  }
  swapEnabled () {
    return this.canSwap && this.swapStatus === 'ok'
  }

  getSwapStatus () {
    return this.swapStatus || 'ok'
  }

  getRecipes () {
    return this._recipes
  }

  getRecipesCount () {
    return this._recipes.length
  }

  swap () {
    return new Promise((resolve, reject) => {
      const api = new MealPlanAPI()
      api.substitute(this._planId, this._id).then(response => {
        this.swapStatus = 'swap-requested'
        resolve(response)
      }).catch(err => {
        reject(err.response)
      })
    })
  }

  getTotalPreparationTime () {
    return this._recipes.reduce((accumulator, recipes) => {
      return accumulator + recipes.getPreparationTime()
    }, 0)
  }
  getAPIResponse () {
    return this._apiResponse
  }
}
