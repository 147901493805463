<template>
  <grid-col
    lg="6"
    class="pc-card-meal-log"
  >
    <card-meal
      :type="$t('message[\'food-diary.dine-out.meal-type\']')"
      :title="name"
      :macro-nutrients="macroNutrientsArray"
      :calories="caloriesArray"
      :images-src="[]"
    >
      <card-meal-action-log-meal
        :state="(selected)?'active':''"
        @click="toggleLog()"
      />
      <card-meal-action-save
        :style="{ visibility :(showSavedState)? 'visible': 'hidden' }"
        :state="savedState"
        @click="save()"
      />
    </card-meal>
  </grid-col>
</template>
<script>
import CardMeal from '../../../../global/cards/CardMeal'
import CardMealActionViewMeal from '../../../../global/cards/card-meal-sub-components/CardMealActionViewMeal'
import CardMealActionLogMeal from '../../../../global/cards/card-meal-sub-components/CardMealActionLogMeal'
import GridCol from '../../../../global/grid/GridCol'

import CardMealActionSave from '../../../../global/cards/card-meal-sub-components/CardMealActionSave'

export default {
  name: 'CardDineOutMeal',
  components: { CardMealActionSave, GridCol, CardMealActionLogMeal, CardMealActionViewMeal, CardMeal },
  props: {
    showSavedState:{
      required: false,
      type: Boolean,
      default: false
    },
    saved:{
      required: false,
      type: Boolean,
      default: false
    },
    selected:{
      required: false,
      type: Boolean
    },
    name: {
      required: true,
      type: String
    },
    macroNutrients: {
      required: true,
      type: Object
    },
    calories: {
      required: true,
      type: Number
    },



  },
  data: function () {
    return {
      disableSave: false,
      title: '',
      images: []
    }
  },
  computed: {
    savedState :function(){
      if(this.disableSave){
        return 'disabled'
      }
      return (this.saved ? 'active':'')
    },
    macroNutrientsArray: function () {
      if (!this.macroNutrients) {
        return []
      }
      let macros = [
        {
          qut: this.roundupMacroNutrients(this.macroNutrients.proteins),
          unit: this.$i18n.t('message["food-diary.unit-grams"]'),
          name: this.firstLetterCaps(this.$i18n.t("message['general.proteins']"))
        },
        {
          qut: this.roundupMacroNutrients(this.macroNutrients.fats),
          unit: this.$i18n.t('message["food-diary.unit-grams"]'),
          name: this.firstLetterCaps(this.$i18n.t("message['general.fats']"))
        },
        {
          qut: this.roundupMacroNutrients(this.macroNutrients.carbohydrates),
          unit: this.$i18n.t('message["food-diary.unit-grams"]'),
          name: this.firstLetterCaps(this.$i18n.t("message['general.carbs']"))
        }
      ]
      if (this.macroNutrients.show_net_carbs) {
        macros.push({
          qut: this.roundupMacroNutrients(this.macroNutrients.net_carbohydrates),
          unit: this.$i18n.t('message["food-diary.unit-grams"]'),
          name: this.firstLetterCaps(this.$i18n.t("message['general.carbs-net']"))
        })
      }

      return macros
    },

    caloriesArray: function () {
      return [
        {
          qut: this.calories,
          name: this.$i18n.tc("message['general.calories']", this.roundupMacroNutrients(this.calories))
        }
      ]
    }

  },
  watch:{
    saved: function () {
      this.disableSave = false
    }
  },
  mounted () {
  },
  methods: {
    toggleLog(){
      if(this.selected){
        this.$emit('remove')
      } else {
        this.$emit('add')
      }


    },
    save(){
      if(this.disableSave){
        return
      }

      this.disableSave =true
      if(this.saved){
        this.$emit('erase')
      } else {
        this.$emit('save')
      }

    },
    roundupMacroNutrients (val) {
      const intVal = parseInt(val)
      if (isNaN(intVal)) {
        return val
      } else {
        return intVal
      }
    }
  }
}
</script>
