<template>
  <popup
    :visible="visible"
    @hide="closePopup"
    modal-class="gc-popup gc-popup-server-error gc-popup-error"
    v-if="visible"
  >
  <popup-title>
    <popup-button-close @click="closePopup"></popup-button-close>
  </popup-title>
  <popup-body class="text-center">
    <popup-content>
      <popup-head>
        <icon-no-network class="gc-popup-error__header-icon" :size="'lg3'"></icon-no-network>
        <popup-text-heading  class="gc-popup-error__title">{{$i18n.t("message['general.no-connection-title']")}}</popup-text-heading>
      </popup-head>
      <popup-text-body class="gc-popup-error__description">
        <text-content :size="'sm4'" :weight="'extra-bold'" :lineHeight="'multi'">{{$i18n.t("message['general.no-connection-description']")}}</text-content>
      </popup-text-body>
      <div>
      </div>
    </popup-content>
    <popup-footer>
      <button-primary class="gc-popup-error__btn-wrapper" :text="$i18n.t('message[\'general.try-again-text\']' )"  @click="closePopup()" />
    </popup-footer>
  </popup-body>
  </popup>
</template>

<script>
import ButtonPrimary from '../buttons/ButtonPrimary'
import PopupFooter from './popup-sub-components/PopupFooter'
import PopupTextBody from './popup-sub-components/PopupTextBody'
import PopupTextHeading from './popup-sub-components/PopupTextHeading'
import PopupHead from './popup-sub-components/PopupHead'
import PopupContent from './popup-sub-components/PopupContent'
import PopupBody from './popup-sub-components/PopupBody'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupTitle from './popup-sub-components/PopupTitle'
import Popup from './Popup'
import TextContent from '../../root/TextContent'
import IconNoNetwork from '../../root/icons/IconNoNetwork'

export default {
  name: 'PopupNetworkError',
  components: {
    ButtonPrimary,
    PopupFooter,
    PopupTextBody,
    PopupTextHeading,
    PopupHead,
    PopupContent,
    PopupBody,
    PopupButtonClose,
    PopupTitle,
    Popup,
    TextContent,
    IconNoNetwork
  },
  data: function () {
    return {
      imagesUrl: this.$appConfig.imagesUrl
    }
  },
  computed: {
    visible () {
      return this.$store.getters.getNoConnectionErrorPopup
    }
  },
  mounted () {
  },
  methods: {
    closePopup () {
      if (navigator.onLine) {
        this.showLoading()
        this.$store.commit('hideNoConnectionErrorPopup')

        this.$router.go(this.$router.currentRoute)
      }
    }
  }
}
</script>

<style scoped>
</style>
