import ContactAPI from './api/contact/ContactAPI'

export default class ContactService {
  constructor () {
    if (!ContactService.instance) {
      ContactService.instance = this
    }
    return ContactService.instance
  }

  submitContact (data) {
    const api = new ContactAPI()

    const payload = {
      full_name: data.fullName,
      email: data.email,
      subject: data.subject,
      message: data.message
    }

    return new Promise((resolve, reject) => {
      api.post(payload).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}
