<template>
  <div>

    <svg height="50px"
         preserveAspectRatio="xMidYMid"
         style="margin: auto; background: rgb(241, 242, 243, 0.0); display: block; shape-rendering: auto;"
         viewBox="0 0 100 100" width="50px" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50" cy="50" fill="none" r="35" stroke="#fff"
              opacity="0.8"
              stroke-dasharray="164.93361431346415 56.97787143782138"
              stroke-width="8">
        <animateTransform attributeName="transform" dur="1s" keyTimes="0;1" repeatCount="indefinite"
                          type="rotate" values="0 50 50;360 50 50"></animateTransform>
      </circle>
    </svg>
  </div>
</template>
<script>
export default {
  name: 'image-uploading-svg'
}
</script>
