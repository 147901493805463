import AuthService from '../services/AuthService'
import DashBoardService from '../services/DashboardService'
import ProfileService from '../services/ProfileService'
import NativeAppService from '../services/NativeAppService'
import { isMobileNativeApp } from './NativeAppCommon'
import LanguageService from '../services/LanguageService'
import { i18n } from '../helpers/localization/i18n'
import { checkIsMember } from '../helpers/user'
import ActivityTracker from '../services/ActivityTracker'

class NLAFFoodDiary {
  addCustomMeal (meal) {
    const detail = meal
    const event = new CustomEvent('food-diary-add-custom-meal', { detail })

    document.dispatchEvent(event)
  }

  async canLogMeals () {
    let dashboard = new DashBoardService()
    await dashboard.getPageData()
    return dashboard.isEntriesEditable()
  }
}

class NLAFGlobal {
  foodDiary = null;

  constructor () {
    this.foodDiary = new NLAFFoodDiary()
  }

  showDynamicContentPopup (slug, title = null, image = null, content = null) {
    const detail = {
      slug,
      title,
      image,
      content
    }
    const event = new CustomEvent('dynamic-content-popup', { detail })

    document.dispatchEvent(event)
  }

  logEvent (eventType, payload, log = true) {
    if (!log) return
    const activityTracker = new ActivityTracker()
    activityTracker.addEvent(eventType, payload, log)
  }

  isMember () {
    return checkIsMember()
  }

  isCreator () {
    return !checkIsMember()
  }
  setUserProperties (key, value, isOnce) {
    const activityTracker = new ActivityTracker()
    activityTracker.setUserProperties(key, value, isOnce)
  }

  async getAccessToken () {
    const auth = new AuthService()
    if (auth.isValidToken()) {
      return Promise.resolve(auth.getToken())
    }
    const token = await auth.getTokenFormRefreshToken()

    return Promise.resolve(token)
  }

  async getNewAccessToken () {
    const auth = new AuthService()

    const token = await auth.getTokenFormRefreshToken()

    return Promise.resolve(token)
  }

  async getMe () {
    const dashboard = new DashBoardService()

    return Promise.resolve(await dashboard.getUserData())
  }

  async getMeTrainer () {
    const dashboard = new DashBoardService()

    return Promise.resolve(await dashboard.getUserConfig())
  }

  async isChatActive () {
    return Promise.resolve(true)
  }

  async getTrainerName () {
    let account = new ProfileService()
    if (!account.getTrainerBrand()) {
      await account.setProfileData()
    }

    return Promise.resolve(account.getTrainerBrand())
  }

  async getSupportEmail () {
    if (!checkIsMember()) {
      return Promise.resolve('')
    }
    let account = new ProfileService()
    if (!account.getSupportEmail()) {
      await account.setProfileData()
    }

    return Promise.resolve(account.getSupportEmail())
  }

  hasMicrophonePermissions () {
    if (!isMobileNativeApp()) {
      return true
    }

    let nativeApp = new NativeAppService()
    return nativeApp.microphonePermissions
  }

  hasCameraPermissions () {
    if (!isMobileNativeApp()) {
      return true
    }

    let nativeApp = new NativeAppService()
    return nativeApp.cameraPermissions
  }

  getLangCode () {
    let lang = new LanguageService()
    return lang.getAppLang()
  }

  /**
   * returns the latest auth tokens
   * @returns {*}
   */
  getAuthTokens () {
    let auth = new AuthService()
    return new Promise((resolve, reject) => {
      if (auth.getToken()) {
        resolve({
          token: auth.getToken()
        })
      } else {
        reject(new Error('no tokens'))
      }
    })
  }

  getLanguageLine (key) {
    return i18n.t('message["' + key + '"]')
  }

  getTrainerID () {
    if (document && document.querySelector('meta[name=ma-application]')) {
      let maAppMeta = document
        .querySelector('meta[name=ma-application]')
        .getAttribute('content') || ''
      maAppMeta = maAppMeta.split('.')
      const trainerID = maAppMeta[1] || 0
      const resellerID = maAppMeta[0] || 0
      return resellerID + '-' + trainerID
    }

    return null
  }
}

if (window) {
  window.NLAF = new NLAFGlobal()
}
