/**
 * Sets the value of a data attribute on the meta tag with name "ma-application".
 *
 * @param {string} name - The name of the data attribute to set. This will be prefixed with "data-".
 * @param {string} value - The value to set for the data attribute.
 *
 * If the meta tag does not exist, an error message will be logged to the console.
 */
export function setMAApplicationData (name, value) {

  // Find the meta tag with name "ma-application"
  const metaTag = document.querySelector('meta[name="ma-application"]')

  // Check if the meta tag exists
  if (metaTag) {
    // Set the value of the data-id attribute to 55
    metaTag.setAttribute('data-' + name, value)
  } else {
    console.error('Meta tag with name "ma-application" not found.')
  }
}


/**
 * Dynamically adds a script tag to the document's head.
 *
 * @param {string} src - The source URL of the script to be added.
 *
 * This function creates a new script element, sets its source to the provided URL,
 * and appends it to the head of the document. This can be used to dynamically load
 * and execute JavaScript files.
 */
export function addScriptToDocument (src) {
  const script = document.createElement('script')
  script.setAttribute('src', src)

  document.head.appendChild(script)
}


/**
 * Dynamically adds a stylesheet link tag to the document's head.
 *
 * This function creates a new link element, sets its 'rel' attribute to 'stylesheet' to indicate it's a stylesheet link,
 * sets its 'href' attribute to the URL of the CSS file, and appends it to the head of the document.
 * This can be used to dynamically load and apply CSS stylesheets.
 *
 * @param {string} href - The URL of the CSS file to be added.
 */
export function addStyleLinkToDocument(href){
  // Create a new link element
  const style = document.createElement('link');

  // Set the rel attribute to 'stylesheet' to indicate it's a stylesheet link
  style.rel = 'stylesheet';

  // Set the href attribute to the URL of your CSS file
  style.href = href;

  // Append the link element to the header of the document
  document.head.appendChild(style);

}
