<template>
  <div
    :class="{'gc-navigator-bottom-bar__link--active': active }"
    class="gc-navigator-bottom-bar__link gc-navigator-bottom-bar__link--home"
    @click="clicked()">
    <div class="gc-navigator-bottom-bar__icon">
      <icon-home-filled
        v-if="active"
        :size="'md1'"
        class="gc-navigator-bottom-bar__link-icon gc-navigator-bottom-bar__link-icon--active"/>
      <icon-home-filled
        v-else
        :size="'md1'"
        class="gc-navigator-bottom-bar__link-icon gc-navigator-bottom-bar__link-icon--inactive"/>
      <icon-underline/>
    </div>
  </div>
</template>
<script>
import IconHomeLinear from '../../../root/icons/IconHomeLinear'
import IconHomeFilled from '../../../root/icons/IconHomeFilled'
import IconEllipse from '../../../root/icons/IconEllipse'
import IconUnderline from '../../../root/icons/IconUnderline'

export default {
  name: 'NavigatorBottomBarLinkHome',
  components: {
    IconUnderline,
    IconEllipse,
    IconHomeFilled,
    IconHomeLinear
  },
  props: {
    active: {
      default: false
    }
  },
  methods: {
    clicked () {
      this.$emit('click')
    }
  }
}
</script>
