<template>
  <div
    class="card-timer card-timer--with-workout"
    v-bind:class="{'card-timer--white' : color==='white'}"
  >
    <div>
      <div class="app-timer d-flex">
        <transition>
          <countdown
            ref="countdownWorkout"
            @progress="countdownProgress"
            :time="time*1000"
            :auto-start="autoStart"
            v-if="show"
            class="time-set app-timer__left"
            v-on:end="workoutEnded()"
          >
            <template slot-scope="props">
              <div class="workout-left-timer" v-bind:class="{'time-active' : !Boolean(name) || play}">
                <p class="app-timer__header" v-if="Boolean(name)&&!play">{{name}}</p>
                <span  class="app-timer__time"
                  v-if="Boolean(name)&&!play"
                >{{headerTime (props.totalMinutes, props.seconds)}}</span>
                <span class="with-sub-title"   v-if="!play">{{headerTimeType (props.totalMinutes, props.seconds) }}
                  </span>

                <span  v-if="!Boolean(name)||play"
                  class="app-timer__time"
                >{{setTimerNumbers(props.totalMinutes) }}:{{setTimerNumbers(props.seconds) }}</span>
                <span class="with-sub-title"  v-if="play">{{$t('message["general.time-minutes-short"]')}}</span>
              </div>
            </template>
          </countdown>
        </transition>
        <a class="app-timer__right text-right" @click="playWorkoutCountdown">
          <span v-if="play">
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25 0C11.2152 0 0 11.2152 0 25C0 38.7848 11.2152 50 25 50C38.7848 50 50 38.7848 50 25C50 11.2152 38.7848 0 25 0ZM21.875 34.375H15.625V15.625H21.875V34.375ZM34.375 34.375H28.125V15.625H34.375V34.375Z"
                fill="#3D3D3D"
              />
            </svg>
          </span>
          <span v-if="!play">
           <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.2564 0C11.33 0 0 11.215 0 25C0 38.785 11.33 50 25.2564 50C39.1828 50 50.5128 38.785 50.5128 25C50.5128 11.215 39.1828 0 25.2564 0ZM38.3586 25.6883L19.8372 38.1883C19.695 38.2842 19.5291 38.3333 19.3632 38.3333C19.2285 38.3333 19.093 38.3017 18.9701 38.2367C18.694 38.0925 18.5214 37.8092 18.5214 37.5V12.5C18.5214 12.1908 18.694 11.9075 18.9701 11.7633C19.2462 11.6183 19.5796 11.6383 19.8381 11.8117L38.3594 24.3117C38.5884 24.4667 38.7265 24.7242 38.7265 25C38.7265 25.2758 38.5884 25.5333 38.3586 25.6883Z" fill="#2FC417"/>
</svg>

          </span>
        </a>
      </div>
      <transition>
        <div>
          <div class="row">
            <div class="col text-center" v-if="play">
              <a
                class="timer__play-btn timer--bottom-text"
                v-on:click="restartCountdown()"
              >{{$t('message["general.restart"]')}}</a>
            </div>
            <div class="col text-center" v-if="showRest">
              <a
                class="timer__play-btn timer--bottom-text"
                v-on:click="showRestCountdown()"
              >{{$t('message["general.rest"]')}}</a>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import VueCountdown from '@chenfengyuan/vue-countdown'
import timerWorkoutMixin from '../../../../../mixins/component-mixins/global/timers/timerWorkoutMixin'

Vue.component(VueCountdown.name, VueCountdown)
export default {
  name: 'WorkoutTimer',
  mixins: [timerWorkoutMixin]
}
</script>
