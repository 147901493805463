import moment from 'moment'

export default {
  props: {
    name: {
      default: ''
    },
    type: {
      default: ''
    },
    measurementUnit: {
      default: ''
    },
    isEditable: {
      default: true
    },
    firstTimeData: {
      default: function () {
        return {
          value: '',
          date: ''
        }
      }
    },
    unit: {
      default: ''
    },
    latestData: {
      default: function () {
        return {
          value: '',
          date: ''
        }
      }
    }
  },
  data: function () {
    return {
      progress: {
        percentage: 0,
        diff: 0,
        diffText: '',
        diffPercentageText: '',
        type: 'none' // none,increase,decrease,loss,gain
      },
      message: {
        'empty-measurement': '',
        'request-log-measurement': '',
        loss: '',
        gain: '',
        increase: '',
        decrease: ''
      }

    }
  },

  mounted () {
    this.setText()
    this.setProgressData()
    if ($('.arabic-support').length > 0) {
      moment.locale('ar')
    }
  },
  watch: {
    'latestData.value': function (newVal, oldVal) {
      this.setText()
      this.setProgressData()
    }
  },
  methods: {
    viewMore (context) {
      this.showLoading()

      this.logEvent('Journey.ViewedMeasurement', { 'measurement ': this.type, context: context }, false)
      this.delayedRouterPush({
        path: this.$appConfig.appUrlList.progress,
        query: {
          page: 'chart',
          type: this.type
        }
      })
    },
    setText () {
      this.message = {
        'empty-measurement': this.$i18n.t('message["fitness-diary.empty-measurement"]', [this.name.toLowerCase()]),
        'request-log-measurement': this.$i18n.t('message["fitness-diary.request-log-measurement"]'),
        loss: this.$i18n.t('message["fitness-diary.loss"]'),
        gain: this.$i18n.t('message["fitness-diary.gain"]'),
        increase: this.$i18n.t('message["fitness-diary.increase"]'),
        decrease: this.$i18n.t('message["fitness-diary.decrease"]')
      }
      if (this.type === 'weight') {
        this.message['request-log-measurement'] = this.$i18n.t('message["fitness-diary.request-log-weight"]')
      }
    },
    setProgressData () {
      if (this.firstTimeData.value && this.latestData.value) {
        const firstTimeValue = parseFloat(this.firstTimeData.value)
        const latestValue = parseFloat(this.latestData.value)
        if (firstTimeValue < latestValue) {
          this.progress.type = 'increase'
          this.progress.diffText = this.message.gain
          this.progress.diffPercentageText = this.message.increase
        } else if (firstTimeValue > latestValue) {
          this.progress.type = 'decrease'
          this.progress.diffText = this.message.loss
          this.progress.diffPercentageText = this.message.decrease
        } else {
          this.progress.type = 'none'
        }
        const diff = Math.abs((latestValue - firstTimeValue))
        if (this.measurementUnit === 'lb' || parseInt(diff).toFixed() === parseFloat(diff).toString()) {
          this.progress.diff = Number.parseFloat(diff).toFixed(0)
        } else {
          this.progress.diff = Number.parseFloat(diff).toFixed(1)
        }

        this.progress.percentage = (this.progress.diff / firstTimeValue) * 100
        this.progress.percentage = this.progress.percentage.toFixed()
      }
    },
    addData () {
      this.$emit('update-data')
    },
    getLatestDate () {
      return this.formatDate(this.latestData.date)
    },
    getLatestValue () {
      if (this.type === 'weight' && this.measurementUnit === 'kg') {
        return Number.parseFloat(this.latestData.value).toFixed(1) || '0'
      } else if (this.type === 'weight' && this.measurementUnit === 'lb') {
        return Number.parseFloat(this.latestData.value).toFixed(0) || '0'
      }
      if (this.type !== 'body_fat') {
        return this.latestData.value
      }

      const value = Number.parseFloat(this.latestData.value).toFixed(0)
      if (this.latestData.value >= 40) {
        return value + '+'
      }
      return value.toString()
    },
    getFirstTimeDate () {
      return this.formatDate(this.firstTimeData.date)
    },
    getFirstTimeValue () {
      if (this.type === 'weight' && this.measurementUnit === 'kg') {
        return Number.parseFloat(this.firstTimeData.value).toFixed(1) || 0
      } else if (this.type === 'weight' && this.measurementUnit === 'lb') {
        return Number.parseFloat(this.firstTimeData.value).toFixed(0) || 0
      }
      if (this.type !== 'body_fat') {
        return this.firstTimeData.value
      }
      const value = Number.parseFloat(this.firstTimeData.value).toFixed(0)
      if (this.firstTimeData.value >= 40) {
        return value + '+'
      }
      return value.toString()
    },
    formatDate (dateTime) {
      if (!dateTime) {
        return ''
      }
      const latestDate = new Date(this.latestData.date)
      const firstTimeDate = new Date(this.firstTimeData.date)
      if (!this.latestData.date || !this.firstTimeData.date ||
        this.progress.type === 'none' ||
        moment(latestDate).diff(moment(firstTimeDate), 'months') === 0) {
        return moment(dateTime).format('ll')
      } else {
        return moment(dateTime).format('MMM YYYY')
      }
    }

  }

}
