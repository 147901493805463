<template>
  <div
    :class="componentClasses"
  >
    <div>
      <div class="pc-timer__wrap d-flex"
           v-bind:class="{'app-timer--with-header':Boolean(name),'pc-timer-rest--playing' : play}">
        <transition>
          <countdown
            ref="countdownRest"
            :time="restCountdownTime*1000"
            :auto-start="false"
            v-if="timerShow"
            v-on:end="startWorkout()"
            class="pc-timer-group pc-timer__left"
            @progress="countdownProgress"
          >
            <template slot-scope="props">
                <div v-if="!play" class="pc-timer-rest__text-area--with-header">
                  <text-content class="pc-timer-set-timer__header"
                                size="lg1"
                                line-height="multi"
                                weight="extra-bold"
                  >{{$t('message["general.rest"]')}}
                  </text-content>
                  <text-content
                    size="sm4"
                    line-height="multi"
                    weight="extra-bold"
                    class="time-block pc-timer-rest--block">
                    <span class="pc-timer-set-timer__time--with-sub-title pc-timer-rest--with-sub-title">{{$t('message["general.for"]')}}</span>
                    &nbsp;<span
                      class="pc-timer-set-timer__time pc-timer-rest__time"
                    >{{headerTime (props.totalMinutes, props.seconds) }}</span>
                    <span class="pc-timer-set-timer__time--with-sub-title pc-timer-rest__time-type">{{headerTimeType (props.totalMinutes, props.seconds) }}</span>
                  </text-content>
                </div>
                <div v-if="play" class="pc-timer-rest__text-area--without-header">
                  <text-content
                    size="xl1"
                    line-height="multi"
                    weight="medium"
                    class="time-block pc-timer-rest--block">
                    <span
                      class="pc-timer-set-timer__time"
                    >{{setTimerNumbers(props.totalMinutes) }}:{{setTimerNumbers(props.seconds) }}</span>
                  </text-content>
                </div>
            </template>
          </countdown>
        </transition>
        <div class="pc-timer__right text-right" v-if="!play" @click="playCountdown">
          <icon-play size="lg4" class="pc-timer-rest__icon-play"/>

        </div>
      </div>
      <transition>
        <div class="pc-timer__footer">
          <div class="row">
            <div class="col text-center" v-if="!noWorkoutMessage">
              <text-content
                class="timer__play-btn pc-timer__action-button"
                size="sm1"
                weight="extra-bold"
                line-height="multi"
                v-on:click="startWorkout()"
              >{{$t('message["general.start-workout"]')}}
              </text-content>
            </div>

            <div class="col text-center" v-else-if="restMessage">
              <text-content
                class="pc-timer__action-button pc-timer__action-button-grey"
                size="sm1"
                weight="extra-bold"
                line-height="multi"
              >{{restMessage}}
              </text-content>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import VueCountdown from '@chenfengyuan/vue-countdown'
import timerRestMixin from '../../../../../mixins/component-mixins/global/timers/timerRestMixin'
import IconPlay from '../../../../root/icons/IconPlay'
import TextContent from '../../../../root/TextContent'

Vue.component(VueCountdown.name, VueCountdown)
export default {
  name: 'RestTimerTest',
  components: {
    TextContent,
    IconPlay
  },
  mixins: [timerRestMixin],
  computed: {
    componentClasses: function () {
      return {
        'pc-timer': true,
        'pc-timer-rest': true,
        'pc-timer-rest--playing': this.state === 'started',
        'pc-timer-rest--pending': this.state !== 'started'
      }
    }
  }
}
</script>
