<template>
  <page-secondary class="pc-creator-analytics" :header="header">
    <page-container-fluid class="pc-notifications__container" v-if="iFrameUrl">
      <iframe plausible-embed :src="iFrameUrl" scrolling="yes" frameborder="0" loading="lazy" style="width: 1px; min-width: 100%; min-height: calc(100vh - 85px - 20px)"></iframe>
      <script type="module" src="https://plausible.io/js/embed.host.js"></script>
    </page-container-fluid>
  </page-secondary>
</template>

<script>
import pageMixin from '../../../../mixins/pageMixin'
import LoadingBuffer from '../global/widgets/LoadingBuffer'
import Page from '../../../global/pages/Page'
import PageContainerFluid from '../../../global/pages/page-sub-components/PageContainerFluid'
import PageSecondary from '../../../global/pages/PageSecondary'
import TextBodyExtraSmall from '../../../global/typography/TextBodyExtraSmall'
import { getCreatorAnalyticsConfig } from '../../../../includes/TemplateSettings'
import headerEventBus from '../../../../event-buses/headerEventBus'

export default {
  name: 'PageCreatorAnalytics',
  mixins: [pageMixin],
  components: {
    LoadingBuffer,
    Page,
    PageContainerFluid,
    PageSecondary,
    TextBodyExtraSmall
  },
  data: function () {
    return {
      header: {
        isTransparent: false,
        isFixed: false,
        show: true,
        left: 'previous-emit',
        right: '',
        rightBlink: false,
        modifiers: ['small-title'],
        mainTitle: 'Analytics',
        subTitle: ''
      }
    }
  },
  computed: {
    iFrameUrl: function () {
      if (!getCreatorAnalyticsConfig()) {
        return false
      }
      const config = getCreatorAnalyticsConfig()
      const user = config.user// `dickersonross.com`
      const key = config.key // `tBa5c3WLJcXb-k4fMIDel`
      return `https://plausible.io/share/${user}?auth=${key}&embed=true&theme=light`
    }
  },
  created () {
    this.hideLoading()
  },
  beforeMount () {
    headerEventBus.$on('back-button-click', this.navigatePrevious)
  },
  methods: {
    navigatePrevious () {
      this.delayedRouterPush({
        path: '/dashboard'
      })
    }
  },
  beforeDestroy () {
    headerEventBus.$off('back-button-click', this.navigatePrevious)
  }
}
</script>
