import { extend } from 'vee-validate'
import {i18n} from '../../localization/i18n'
const validator = {
  validate (weight) {
    let weightInKG = weight.kg
    let weightInLBs = weight.lbs
    if (weight.preference === 'metric' && weightInKG) {
      if (weightInKG < 30) {
        return i18n.t("message['validation']['messages']['min_value']").replace('{min}', 30)
      } else if (weightInKG > 250) {
        return i18n.t("message['validation']['messages']['max_value']").replace('{max}', 250)
      } else {
        return true
      }
    } else if (weight.preference === 'imperial' && weightInLBs) {
      if (weightInLBs < 60) {
        return i18n.t("message['validation']['messages']['min_value']").replace('{min}', 60)
      } else if (weightInLBs > 550) {
        return i18n.t("message['validation']['messages']['max_value']").replace('{max}', 550)
      } else {
        return true
      }
    }
    return i18n.t("message['validation']['messages']['required']")
  }
}
extend('weight', validator)
