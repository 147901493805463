<template>
  <div :class="componentClasses" @click="clicked()">
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5104 4.20302C13.2397 3.93232 12.8006 3.93232 12.5299 4.20306L6.40686 10.3261L3.18368 7.10289C2.91293 6.83215 2.47388 6.83215 2.20309 7.10289C1.9323 7.37364 1.9323 7.81269 2.20309 8.08348L5.91654 11.7969C6.05193 11.9323 6.22939 12 6.40681 12C6.58422 12 6.76173 11.9323 6.89708 11.7969L13.5104 5.1836C13.7812 4.91286 13.7812 4.47381 13.5104 4.20302Z"
        fill="black"
      />
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconTick',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-tick': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
