import FoodDiaryAPI from './api/meal-plan/FoodDiaryAPI'
import FoodDiaryCustomMealsService from '@/services/FoodDiaryCustomMealsService'

export default class FoodDiarySavedDineOutMealsService extends FoodDiaryCustomMealsService {
  _api = {}
  _savedMeals = []
  _lastedSearchStr = null
  _currentPage = 0
  _lastPage = 0
  _hasMore = false

  constructor () {
    super()
    this._api = new FoodDiaryAPI()
  }

  async search (text, restaurant, page = 1) {
    if (text !== this._lastedSearchStr) {
      this._lastedSearchStr = text
      this._savedMeals = []
      this._currentPage = 0
      this._lastPage = 0
    }
    if (page <= this._currentPage) {
      return this._savedMeals
    }
    let data = await this._api.searchSavedDineOutMeals({
      search: text,
      restaurant:restaurant,
      page: page,
      pre_page: 100
    })
    data = data.data

    if (this._currentPage < data.current_page) {
      this._currentPage = data.current_page
      this._lastPage = data.last_page
      this._hasMore = data.has_more_pages
      this._savedMeals = this._savedMeals.concat(data.meals)
    }

    return this._savedMeals
  }

}
