<template>
  <div>
    <card-meal
      :type="mealOptions.getType().name"
      :title="name"
      :images-src="images"
      :is-paired="isPaired"
      :macro-nutrients="[]"
      :calories="0"
      v-on="$listeners"
      class="pc-meal-plan-day__card-meal"
      @click="navigateToMeal()"
    >
      <card-meal-action-swap
        v-if="canSwap || swapState === 'active'"
        :state="swapState"
        @clicked="showSwapPopup()"
      />
      <card-meal-action-dislike
        v-if="showDislike && swapState !== 'active' && enabledByPlan"
        :state="dislikeState"
        @clicked="updateDislike()"
      />
      <card-meal-action-favorite
        v-if="showFavorite && swapState !== 'active' && enabledByPlan"
        :state="favoriteState"
        :disabled="isFavoriteDisabled"
        @clicked="updateFavorite()"
      />
    </card-meal>
    <swap-meal
      v-if="swapPopup.visible"
      :meal="mealOptions"
      :visible="swapPopup.visible"
      :data="swapPopupData"
      @show-update-dislikes="showUpdateDislikesPopup"
      @hide-swap-popup="hideSwapPopup"
      @meal-swap-success="mealSwapSuccess"
    />
    <popup-add-meal-dislikes
      v-if="mealDislikesPopup.visible"
      :meal="mealOptions"
      :generated-meal-i-d="meal_id"
      :meal-name="name"
      :meal-i-d="mealOptions.getReferenceMealId()"
      :meal-plan-i-d="$route.params.mid"
      :visible="mealDislikesPopup.visible"
      :swap-data="swapPopupData"
      :is-swapped="swapState === 'active'"
      :can-swap="canSwap"
      @close="hideMealDislikePopup()"
      @swapped="mealSwapSuccess"
      @success="dislikeSuccess($event)"
      @show-update-dislikes="showUpdateDislikesPopup"
    />
    <popup-smart-dislikes
      v-if="updateDislikes.visible"
      :meal-plan="mealPlan"
      :visible="updateDislikes.visible"
      :data="updateDislikesData"
      @show-swap-dislikes="showSwapDislikesPopup"
      @hide-update-dislikes="hideUpdateDislikesPopup"
    />
    <popup-swap-dislikes
      v-if="swapDislikes.visible"
      :visible="swapDislikes.visible"
      :data="swapDislikesData"
      :mealPlan="mealPlan"
      @hide-swap-dislikes-popup="hideSwapDislikesPopup"
      @meal-swap-success="mealSwapSuccess"
    />
    <popup-favorite-disliked-meal
      :visible="showFavoriteDislikedMealPopup"
      @hide-popup="showFavoriteDislikedMealPopup = false"
      @submit="favoriteDislikedMeal()"
    />
    <popup-dislike-favorite-meal
      :visible="showDislikeFavoriteMealPopup"
      @hide-popup="showDislikeFavoriteMealPopup = false"
      @submit="dislikeFavoriteMeal()"
    />
  </div>
</template>

<script>
import CardMeal from '../../../../global/cards/CardMeal'
import CardMealActionDislike from '../../../../global/cards/card-meal-sub-components/CardMealActionDislike'
import CardMealActionSwap from '../../../../global/cards/card-meal-sub-components/CardMealActionSwap'
import {mapMutations} from 'vuex'
import SwapMeal from '../../../../global/popups/PopupSwapMeal'
import PopupAddMealDislikes from '../../../../global/popups/PopupAddMealDislikes'
import MealDislikeService from '../../../../../services/MealDislikeService'
import {enableMealDislikesFlag, enableMealFavourites} from '../../../../../includes/TemplateSettings'
import PopupSmartDislikes from '../widgets/PopupSmartDislikes'
import PopupSwapDislikes from '../widgets/PopupSwapDislikes'
import NutritionService from '../../../../../services/nutrition/NutritionService'
import DashBoardService from '../../../../../services/DashboardService'
import CardMealActionFavorite from '@/components/global/cards/card-meal-sub-components/CardMealActionFavorite'
import ActionMealFavorite from '@/components/global/actions/ActionMealFavorite.vue'
import MealFavouritesService from '@/services/nutrition/MealFavouritesService'
import PopupFavoriteDislikedMeal from '@/components/global/popups/PopupFavoriteDislikedMeal.vue'
import PopupDislikeFavoriteMeal from '@/components/global/popups/PopupDislikeFavoriteMeal.vue'
import lodash from 'lodash'

export default {
  name: 'MealCard',
  components: {
    PopupDislikeFavoriteMeal, PopupFavoriteDislikedMeal,
    ActionMealFavorite,
    CardMeal,
    CardMealActionSwap,
    CardMealActionDislike,
    SwapMeal,
    PopupAddMealDislikes,
    PopupSmartDislikes,
    PopupSwapDislikes,
    CardMealActionFavorite,
  },
  props: {
    mealOptions: {
      default: function () {
        return {}
      },
      type: Object,
    },
    dayTitle: {
      default: '',
      type: String,
    },
    dayID: {
      default: false,
    },
    showSwap: {
      default: true,
    },
  },
  data: function () {
    return {
      name: '',
      type: '',
      recipes: [],
      meal_id: '',
      enabledByPlan: false,
      showDislike: false,
      showFavorite: false,
      favoriteState: '',
      isFavorite: false,
      isAddingToFavorites: false,
      isFavoriteDisabled: false,
      showFavoriteDislikedMealPopup: false,
      showDislikeFavoriteMealPopup: false,
      swapPopupData: {},
      updateDislikesData: {},
      swapDislikesData: {},
      swapState: '',
      swapPopup: {
        visible: false,
      },
      isDisliked: false,
      isDisliking: false,
      mealDislikesPopup: {
        visible: false,
      },
      updateDislikes: {
        visible: false,
      },
      swapDislikes: {
        visible: false,
      },
      mealPlan: null,
    }
  },
  computed: {
    canSwap: function () {
      return this.showSwap && this.mealOptions.swapEnabled()
    },
    isPaired: function () {
      return this.mealOptions.isPairedMeal()
    },
    images: function () {
      let imgs = []
      this.recipes.map((recipe) => {
        imgs.push(recipe.getImageMainImage())
      })
      imgs = imgs.slice(0, 3)
      let sortedImgs = lodash.sortBy(imgs)
      return sortedImgs
    },

    dislikeState: function () {
      if (this.isDisliking) {
        return 'disabled'
      }
      return this.isDisliked ? 'active' : ''
    },
  },
  watch: {
    mealOptions: {
      immediate: true,
      deep: true,
      handler: function () {
        this.swapState =
          this.mealOptions.getSwapStatus() === 'swap-requested' ? 'active' : ''
        this.isDisliked = this.mealOptions.isDisliked() ? 'active' : ''
      },
    },
  },
  async created () {
    let service = new MealFavouritesService()
    this.isFavorite = service.isFavouriteMeal(this.mealOptions.getReferenceMealId()) ? 'active' : ''
    this.showFavorite = enableMealFavourites()
    this.favoriteState = this.isFavorite ? 'active' : ''
  },
  mounted () {
    this.enableMealDislikeSwapByActivePlan()
    this.initMealSwapIngredients()
    this.showDislike =
      enableMealDislikesFlag() && this.mealOptions.canDislike()
    this.isDisliked = this.mealOptions.isDisliked() ? 'active' : ''
  },
  beforeMount () {
    let mpService = new NutritionService()
    this.mealPlan = mpService.getMealPlan(parseInt(this.$route.params.mid))
    const recipes = this.mealOptions.getRecipes().map((recipe) => {
      if (recipe) {
        return recipe.getName()
      }
      return ''
    })
    this.name =
      recipes[0] +
      (recipes.length > 1
        ? ' + ' +
        (recipes.length - 1) +
        ' ' +
        this.$i18n.t('message[\'general.more-text\']')
        : '')
    this.type = this.mealOptions.getType().name
    this.recipes = this.mealOptions.getRecipes()
    this.meal_id = this.mealOptions.getId()
  },
  methods: {
    ...mapMutations({
      initMealSwapIngredients: 'mealSwapStore/init',
    }),
    enableMealDislikeSwapByActivePlan () {
      const dashBoardService = new DashBoardService()
      this.enabledByPlan = dashBoardService.hasActivePlan()
    },
    navigateToMeal () {
      // TODO - refactor
      this.$router.push({
        path: [
          this.$appConfig.appUrlList.mealPlanBase,
          this.$route.params.mid,
        ].join('/'),
        query: {
          loading: '0',
          day: this.dayID,
          meal: this.meal_id,
        },
      })
    },
    getMealPlanID () {
      return this.$route.params.mid
    },
    mealSwapSuccess () {
      this.swapState = 'active'
      this.$emit('update-swap-buttons-visibility', this.swapState)
    },
    showSwapPopup () {
      if (this.swapState !== '') {
        return
      }
      this.setSwapPopupData()
      this.swapPopup.visible = true
    },
    setSwapPopupData () {
      this.swapPopupData = {
        mealID: this.mealOptions.getId(),
        mealTitle: this.mealOptions.getName(),
        dayTitle: this.dayTitle,
        recipes: this.mealOptions.getRecipes(),
      }
    },
    hideSwapPopup () {
      this.swapPopup.visible = false
    },
    showUpdateDislikesPopup (data) {
      this.disableAllCardClick()
      this.updateDislikesData = {
        ingredients: data.ingredients,
        mealID: this.mealOptions.getId(),
      }
      setTimeout(() => {
        this.updateDislikes.visible = true
      }, 1000)
    },
    hideUpdateDislikesPopup () {
      this.updateDislikes.visible = false
    },
    showSwapDislikesPopup (data) {
      this.disableAllCardClick()
      this.hideUpdateDislikesPopup()
      this.swapDislikesData = {
        ingredients: data.ingredients,
        otherMeals: data.otherMeals,
        refMealIds: data.refMealIds,
      }
      setTimeout(() => {
        this.swapDislikes.visible = true
      }, 1000)
    },
    hideSwapDislikesPopup () {
      this.swapDislikes.visible = false
    },

    hidePopup () {
      this.popup.visible = false
    },
    dislikeSuccess (event) {
      if (event.isSwapped) {
        this.mealSwapSuccess(event.isSwapped)
      }
      this.isDisliked = true
    },
    hideMealDislikePopup () {
      this.mealDislikesPopup.visible = false
      if (this.isDisliked === true) {
        this.dislikeState = 'active'
      }
    },
    async toggleFavorite () {
      if (this.isAddingToFavorites) {
        return
      }
      if (this.isFavorite) {
        await this.removeMealFavorite()
      } else {
        this.isFavorite = true
        this.isAddingToFavorites = true
        this.favoriteState = this.isFavorite ? 'active' : ''
        this.isFavoriteDisabled = true
        let service = new MealFavouritesService()
        await service
          .setFavourite({
            meal_id: this.mealOptions.getReferenceMealId()
          })
          .then(() => {
            this.logEvent('MealPlan.FavouritedAMeal', {
              type: this.mealOptions.getType(),
              name: this.mealOptions.getName()
            })
          })
          .catch(() => {
            this.$store.commit('showServerErrorPopup')
            this.isFavorite = false
            this.favoriteState = this.isFavorite ? 'active' : ''
            this.isFavoriteDisabled = false
          })
          .finally(() => {
            this.isAddingToFavorites = false
            this.isFavoriteDisabled = false
          })
      }
    },
    toggleDislike () {
      if (this.isDisliked) {
        this.removeMealDislike()
      } else {
        this.setSwapPopupData()
        this.mealDislikesPopup.visible = true
      }
    },
    updateDislike () {
      if (this.isFavorite) {
        this.showDislikeFavoriteMealPopup = true
      } else {
        this.toggleDislike()
      }
    },
    async updateFavorite () {
      if (this.isDisliked) {
        this.showFavoriteDislikedMealPopup = true
      } else {
        await this.toggleFavorite()
      }
    },
    async removeMealFavorite () {
      if (this.isAddingToFavorites) {
        return
      }
      this.isFavoriteDisabled = true
      this.isAddingToFavorites = true
      this.isFavorite = false
      this.favoriteState = this.isFavorite ? 'active' : ''
      let service = new MealFavouritesService()
      await service
        .removeFavourite(this.mealOptions.getReferenceMealId())
        .catch((e) => {
          this.$store.commit('showServerErrorPopup')
          this.isFavorite = true
          this.favoriteState = this.isFavorite ? 'active' : ''
          this.isFavoriteDisabled = false
        })
        .finally(() => {
          this.isAddingToFavorites = false
          this.isFavoriteDisabled = false
        })
    },
    async favoriteDislikedMeal () {
      this.removeMealDislike()
      await this.toggleFavorite()
    },
    async dislikeFavoriteMeal () {
      await this.removeMealFavorite()
      setTimeout(() => {
        this.toggleDislike()
      }, 750)
    },
    removeMealDislike () {
      if (this.isDisliking) {
        return
      }
      this.isDisliking = true
      let dislikeService = new MealDislikeService()
      dislikeService
        .removeDislike(this.mealOptions.getReferenceMealId())
        .then(() => {
          this.isDisliked = false
          this.mealOptions.setDisliked(false)
        })
        .finally(() => {
          this.isDisliking = false
        })
    },
    disableAllCardClick () {
      document
        .querySelectorAll('.pc-meal-plan-day__card-meal')
        .forEach((ele) => {
          ele.style.pointerEvents = 'none'
          setTimeout(() => {
            ele.style.pointerEvents = 'auto'
          }, 2000)
        })
    },
  },
}
</script>
