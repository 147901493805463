<template>
    <div :class="componentClasses">
            <input-group-ingredients-group
              ref="ingredientsGroup"
              v-for="group in groupedIngredients"
              :key="group.id"
              :group="group"
              :type="type"
              :count="count"
              v-model="selectedIngredients"
              @expanded="groupExpanded(group.id)"
              @changed="changed()"
            />
    </div>
</template>
<script>
import InputGroupIngredientsGroup from './input-group-ingredients-sub-components/InputGroupIngredientsGroup'
import GridRow from '../../../../../global/grid/GridRow'

export default {
  name: 'InputGroupIngredients',
  components: {
    GridRow,
    InputGroupIngredientsGroup
  },
  props: {
    type: {
      default: 'cart' // purchased, cart

    },
    showHeader: {
      default: false
    },
    count: {
      type: Number,
      default: 1
    },
    groupedIngredients: {
      default: function () {
        return []
      }
    },
    value: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function () {
        this.selectedIngredients = this.value
      }
    },
    selectedIngredients: function () {
      this.$emit('input', this.selectedIngredients)
    }

  },
  data: function () {
    return {
      selectedIngredients: []
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'pc-input-group-ingredients': true
      }
    }
  },
  methods: {
    groupExpanded (id) {
      let expandedElement = null
      this.$refs.ingredientsGroup.forEach(ele => {
        if (ele.getID() !== id) {
          ele.hideAccordion()
        } else {
          expandedElement = ele
        }
      })
      if (expandedElement) {
        this.$nextTick(() => {
          expandedElement.scrollToTitle()
        })
      }
    },
    changed () {
      this.$emit('changed')
    }
  }
}
</script>
