<template>
  <!--   page background -->
  <page-secondary
    :header="headerOptions"
    class="pc-profile--favorite-meals"
  >
    <page-container class="pc-profile--favorite-meals__container">
      <text-content
        :size="'sm2'"
        :weight="'medium'"
        :line-height="'multi'"
        class="pc-text-helper"
      >
        <p v-html="$t('message[\'favorite-meals.message\']')"/>
      </text-content>
      <text-body-small
        v-if="favorites.length === 0"
        class="pc-text-no-results"
      >
        <p v-html="$t('message[\'favorite-meals.no-meals\']')"/>
      </text-body-small>

      <grid-row>
        <grid-col
          v-for="(favorite, dID) in favorites"
          :key="dID"
          md="6"
        >
          <card-meal-favorite
            class="pc-card-meal-favorite"
            :favorite="favorite"
            @delete="showDeletePopup(favorite.id, favorite.name)"
          />
        </grid-col>
      </grid-row>

      <popup-remove-favorites
        v-if="deletePopup.visible"
        :meal="deletePopup.mealName"
        :meal-i-d="deletePopup.mealID"
        :visible="deletePopup.visible"
        @close="deletePopup.visible = false"
        @success="successRemove($event)"
      />
    </page-container>
  </page-secondary>
</template>

<script>
import BaseComponent from '../../global/base/BaseComponent'
import PopupRemoveFavorites from '../../../../global/popups/PopupRemoveFavorites'
import CardMealFavorite from './page-components/CardMealFavorite'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import TextContent from '../../../../root/TextContent'
import PageSecondary from '../../../../global/pages/PageSecondary'
import PageContainer from '../../../../global/pages/page-sub-components/PageContainer'
import GridCol from '../../../../global/grid/GridCol'
import GridRow from '../../../../global/grid/GridRow'
import TextBodySmall from '../../../template-2/global/typography/TextBodySmall'
import NutritionService from '@/services/nutrition/NutritionService'
import headerEventBus from '@/event-buses/headerEventBus'

export default {
  name: 'PageFavouriteMeals',
  components: {
    TextBodySmall,
    GridRow,
    GridCol,
    TextBodyExtraSmall,
    CardMealFavorite,
    PopupRemoveFavorites,
    TextContent,
    PageSecondary,
    PageContainer
  },
  extends: BaseComponent,
  data: function () {
    return {
      imagesUrl: this.$appConfig.imagesUrl,
      headerOptions: {
        isTransparent: false,
        isFixed: false,
        show: true,
        left: 'previous-emit',
        right: '',
        rightBlink: false,
        modifiers: ['small-title', 'with-bg-color'],
        mainTitle: this.$t('message["general.favorite-meals"]'),
        subTitle: ''
      },
      favorites: [],
      deletePopup: {
        mealName: '',
        mealID: 0,
        visible: false
      }
    }
  },
  created () {
    this.setPageTitle(this.$t('message["general.favorite-meals"]'))
  },
  mounted () {
    this.setHeader(this.headerOptions)
    this.setPageClass()
    this.setPageData()
    headerEventBus.$on('back-button-click', this.navigatePrevious)
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
  },
  beforeDestroy () {
    headerEventBus.$off('back-button-click', this.navigatePrevious)
  },
  methods: {
    showDeletePopup (mealID, mealName) {
      this.deletePopup = {
        mealName: mealName,
        mealID: mealID,
        visible: true
      }
    },
    async setPageData () {
      let nutrition = new NutritionService()
      await nutrition.setDetailedMealPreferences().then(async () => {

        this.favorites = await nutrition.getFavourites().getFavourites()
      }).finally(() => {
        this.hideLoading()
      })
    },
    navigatePrevious () {
      this.$router.push({
        path: this.$appConfig.appUrlList.profile,
        query: {
          page: 'dietary-preferences',
          loading: '0'
        }
      })
    },
    getPreparationTime (meal) {
      let totalTime = meal.recipes.reduce((accumulator, recipe) => {
        return accumulator + recipe.preparation_time
      }, 0)

      return (
        totalTime + ' ' + this.$i18n.tc('message[\'general.minutes\']', totalTime)
      )
    },
    getIngredientsCount (meal) {
      let total = meal.recipes.reduce((accumulator, recipe) => {
        return accumulator + recipe.ingredients_count
      }, 0)
      return (
        total + ' ' + this.$i18n.tc('message[\'general.ingredients\']', total)
      )
    },
    successRemove (data) {
      this.setPageData()
      this.logEvent('MealPlan.DeletedFavoriteMeal', {
        meal: this.deletePopup.mealName
      }, false)
    }
  }
}
</script>
