<template>
  <div>
    <button
      type="button"
      class="btn accordion-card__btn accordion-card__btn--outline"
      @click="showContent"
    >
      {{subItem.name}}
      <svg v-if="!showDropDown" class="svg-right-arrow accordion-card__left-arrow" width="13" height="25" viewBox="0 0 13 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.6057 12.1398L1.14725 0.160504C0.947881 -0.0459921 0.618288 -0.0551719 0.410766 0.144244C0.203245 0.343123 0.196116 0.672716 0.394507 0.880726L11.508 12.4999L0.394457 24.1191C0.196067 24.3271 0.203196 24.6567 0.410718 24.8556C0.511451 24.9522 0.641628 25 0.770829 25C0.908184 25 1.04446 24.9461 1.1472 24.8393L12.6057 12.86C12.798 12.6586 12.798 12.3412 12.6057 12.1398Z" fill="white"/>
</svg>
      <!-- <img
        v-if="!showDropDown"
        class="accordion-card__left-arrow"
        :src="imagesUrl +'back-next.svg'"
        alt="down"
      /> -->

<svg v-if="showDropDown" class="svg-down-arrow accordion-card__left-arrow" width="25" height="13" viewBox="0 0 25 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.8602 12.6056L24.8395 1.14719C25.046 0.94782 25.0552 0.618227 24.8558 0.410705C24.6569 0.203184 24.3273 0.196055 24.1193 0.394446L12.5001 11.508L0.880917 0.394396C0.672907 0.196006 0.343315 0.203135 0.144436 0.410657C0.0478058 0.51139 1.90735e-06 0.641567 1.90735e-06 0.770768C1.90735e-06 0.908123 0.0539074 1.0444 0.160746 1.14714L12.14 12.6056C12.3414 12.7979 12.6588 12.7979 12.8602 12.6056Z" fill="white"/>
</svg>

     <!-- <img
        v-if="showDropDown"
        class="accordion-card__left-arrow"
        :src="imagesUrl +'down-arrow.svg'"
        alt="down"
      /> -->
    </button>
    <div class="app-content-wrap">
      <div class="card-info" v-html="subItem.content" v-if="showDropDown"></div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import NativeAppMixin from '../../../../../mixins/NativeAppMixin'
export default {
  name: 'subitem-area',
  mixins: [NativeAppMixin],
  props: {
    rootKey: {},
    subItem: {},
    subKey: {}
  },
  data: function () {
    return {
      showDropDown: false,
      imagesUrl: this.$appConfig.imagesUrl
    }
  },
  mounted () {
    document.querySelector('body')
      .addEventListener('click', this.linkNavigateClickHandler, true)
  },
  methods: {
    ...mapMutations({
      setLastOffset: 'listLastExpandedStore/setLastOffset',
      setLastExpanded: 'listLastExpandedStore/setLastExpanded'
    }),
    rememberLastOffset () {
      const offsetTop = (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0)
      this.setLastOffset(offsetTop)
    },
    linkNavigateClickHandler (event) {
      let targetElement = event.target
      const selector = '.card-info a'
      while (targetElement != null) {
        if (targetElement.matches(selector)) {
          event.preventDefault()
          this.handleUrlNavigate(targetElement.getAttribute('href'))
          return
        }
        targetElement = targetElement.parentElement
      }
    },
    showContent () {
      if (this.subItem.content.replace(/<[^>]*>?/gm, '').length <= 300) {
        this.showDropDown = !this.showDropDown
      } else {
        this.setLastExpanded(this.rootKey)
        this.rememberLastOffset()
        this.$router.push({
          path: this.$route.path,
          query: {
            rootItem: this.rootKey,
            subItem: this.subKey
          }
        })
      }
    }
  },
  beforeMount () {
    document.querySelector('body')
      .removeEventListener('click', this.linkNavigateClickHandler, true)
  }
}
</script>
