<template>
  <b-modal
    ref="upsellConfirmationModal"
    dialog-class="info-modal__dialog"
    content-class="info-modal__content info-modal__content--hidden"
    body-class="info-modal pt-0"
    modal-class="info-modal"
    size="lg"
    :visible="visible"
    v-if="visible"
    hide-body
    hide-header
    hide-footer
    hide-header-close
  >
    <button class="btn-close" @click="confirm(false)">
      <svg class="svg-popup-close-button" width="25" height="25" viewBox="0 0 25 25" fill="" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.8811 12.5L24.714 1.66715C25.0954 1.28575 25.0954 0.667394 24.714 0.286047C24.3326 -0.0953001 23.7142 -0.0953489 23.3329 0.286047L12.5 11.1189L1.66715 0.286047C1.28575 -0.0953489 0.667394 -0.0953489 0.286047 0.286047C-0.0953001 0.667443 -0.0953489 1.2858 0.286047 1.66715L11.1189 12.5L0.286047 23.3329C-0.0953489 23.7143 -0.0953489 24.3326 0.286047 24.714C0.47672 24.9047 0.726671 25 0.976622 25C1.22657 25 1.47647 24.9047 1.6672 24.714L12.5 13.8811L23.3328 24.714C23.5235 24.9047 23.7735 25 24.0234 25C24.2734 25 24.5233 24.9047 24.714 24.714C25.0954 24.3326 25.0954 23.7142 24.714 23.3329L13.8811 12.5Z" fill="white"/>
      </svg>
    </button>
    <div>
      <div>
        <div class="info-modal__head">
          <h1 class="info-modal__title product-modal__title">{{title}}</h1>
        </div>
        <loading-buffer v-if="status==='loading'"></loading-buffer>

        <div class="text-center" v-if="status==='success'">
          <icon-complete-filled :size="'lg3'" />
        </div>
        <div class="popup-btn-wrap">
          <div v-if="confirmText">
            <button
              type="button"
              class="btn btn-main mt-5"
              v-on:click="confirm(true)"
            >{{confirmText}}
            </button>
          </div>
          <div v-if="declineText">
            <button
              type="button"
              class="btn btn-main btn-main--with-only-outline"
              v-on:click="confirm(false)"
            >{{declineText}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import BaseComponent from '../../global/base/BaseComponent'
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import IconCompleteFilled from '../../../../root/icons/IconCompleteFilled'

export default {
  name: 'upsell-popup',
  props: ['data', 'visible'],
  mixins: [popupAnimationMixin],
  components: {
    LoadingBuffer,
    IconCompleteFilled
  },
  extends: BaseComponent,
  data: function () {
    return {
      status: 'loading',
      loading: true,
      title: '',
      successMsg: 'success',
      confirmText: '',
      declineText: '',
      errorMessage: 'Error'
    }
  },
  created () {
    this.title = this.$i18n.t('message["' + this.data.message.lang + '"]', this.data.message.vars)
    this.status = this.data.status
    this.confirmText = null
    this.declineText = null
  },
  watch: {
    data: function (newData, oldData) {
      this.title = this.$i18n.t('message["' + this.data.message.lang + '"]', this.data.message.vars)
      this.status = newData.status
      this.confirmText = this.$i18n.t('message["' + newData.confirmText + '"]')
      this.declineText = newData.declineText ? this.$i18n.t('message["' + newData.declineText + '"]') : null
    }
  },
  methods: {
    closePopup () {
      this.closeAnimatedPopup(() => {
        this.$emit('hide')
      })
    },
    confirm (confirmation) {
      this.$emit('confirmation', confirmation)
      if (!confirmation || (confirmation && !this.declineText)) {
        this.closePopup()
      }
    }
  }
}
</script>
