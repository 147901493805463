import { extend } from 'vee-validate'
import { i18n } from '../../localization/i18n'

const validator = {
  validate (value) {
    if ((value.toString().includes('-') || value.toString().toLowerCase().includes('f'))) {
      return i18n.t('message["validation.reps-only"]')
    }
    return true

  }
}
extend('workout_reps', validator)
