import TrainerFeedAPI from './api/custom/TrainerFeedAPI'

export default class TrainerFeedService {
  _api = null

  constructor () {
    this._api = new TrainerFeedAPI()
  }

  logFitnessDairyPhotoUpload (image) {
    // todo : remove function

  }

  logFitnessDiaryMetricUpdate (metric) {
    // todo : remove function

  }

  logProgressUpdate (updatedData) {
    // todo : remove function

  }

  getPublicImage (images, type) {
    // todo : remove function

  }

  logFirstDayComplete () {
    // todo : remove function

  }

  _modifyProgressValue (value) {
    return parseFloat(value) || 0
  }
}
