<template>
  <div>
    <workout-timer :color="color"
                   v-if="workoutTimer.show" :time="time" v-on:workout-end="workoutTimerEnded()"
                   :show-rest="Boolean(restTime)"
                   :auto-start="play"
                   v-on:remaining="workoutRemaining($event)"
                   v-on:start="workoutTimerStarted($event)" v-on:pause="workoutTimerPaused($event)"
                   :name="workoutHeader"/>

    <rest-timer :autoStart="restTimer.play" :color="'green'" :insideVideo="color==='white'" :rest-time="restTimer.time"
                :rest-interval="restInterval" :name="restHeader" v-if="restTimer.show" v-on:ended="restTimerEnded()"
                v-on:start="restTimerStarted($event)" v-on:pause="restTimerPaused($event)"
                :restMessage="restMessage"
                :no-workout-message="restTimer.showNoWorkoutTimerMessage "/>
  </div>

</template>
<script>
import WorkoutTimer from './WorkoutTimer'
import RestTimer from './RestTimer'

export default {
  name: 'timer',
  components: { WorkoutTimer, RestTimer },
  props: {
    setID: {
      default: -1
    },
    time: {
      default: 0
    },
    play: {
      default: false
    },
    restHeader: {
      default: ''
    },
    workoutHeader: {
      default: ''
    },
    color: {
      default: 'black'
    },
    restTime: {
      default: 0
    },
    restInterval: {
      default: 'second'
    },
    restMessage: {
      default: ''
    }
  },
  data: function () {
    return {
      startSetID: -1,
      workoutTimer: {
        play: false,
        show: false
      },
      restTimer: {
        showNoWorkoutTimerMessage: false,
        play: false,
        show: false,
        time: 0
      }

    }
  },
  created () {
    this.startSetID = this.setID
    if (parseInt(this.time)) {
      this.workoutTimer.show = true
      this.restTimer.time = this.restTime
    } else {
      if (this.restTime) {
        this.restTimer.time = this.restTime
        this.restTimer.showNoWorkoutTimerMessage = true
        this.restTimer.show = true
      } else if (!this.time) {
        this.restTimer.time = 0
      }
    }
  },
  watch: {
    play: function (newVal, oldVal) {
      if (newVal) {
        this.workoutTimer.play = true
        this.workoutTimer.show = true
        this.restTimer.play = false
        this.restTimer.show = false
      }
    }
  },
  methods: {
    workoutTimerEnded () {
      if (parseInt(this.restTime)) {
        this.workoutTimer.show = false
        this.restTimer.show = true
      }
      this.$emit('workout-timer-end', this.modifyEvent({}))
      this.timerEvent('workout-timer-end', this.modifyEvent({}))
    },
    restTimerEnded () {
      if (parseInt(this.time)) {
        this.restTimer.show = false
        this.workoutTimer.show = true
      }
      this.$emit('rest-timer-end', this.modifyEvent({}))
      this.timerEvent('rest-timer-end', this.modifyEvent({}))
    },
    restTimerStarted (event) {
      this.$emit('rest-timer-start', this.modifyEvent(event))
      this.timerEvent('rest-timer-start', this.modifyEvent(event))
    },
    restTimerPaused (event) {
      this.$emit('rest-timer-paused', this.modifyEvent(event))
      this.timerEvent('rest-timer-paused', this.modifyEvent(event))
    },
    workoutTimerStarted (event) {
      this.$emit('workout-timer-start', this.modifyEvent(event))
      this.timerEvent('workout-timer-start', this.modifyEvent(event))
    },
    workoutTimerPaused (event) {
      this.$emit('workout-timer-paused', this.modifyEvent(event))
      this.timerEvent('workout-timer-paused', this.modifyEvent(event))
    },
    workoutRemaining (event) {
      this.$emit('workout-timer-remaining', this.modifyEvent(event))
      this.timerEvent('workout-timer-remaining', this.modifyEvent(event))
    },
    timerEvent (name, eventData) {
      eventData = { type: name, ...eventData }
      const event = new CustomEvent('exercise-timer', { detail: eventData })
      document.dispatchEvent(event)
    },
    modifyEvent (event) {
      return { 'set-id': this.startSetID, ...event }
    }
  }
}
</script>
