<template>
  <b-col v-bind="attr" v-on="$listeners">
    <slot></slot>
  </b-col>
</template>

<script>
/**
 *
 * https://bootstrap-vue.org/docs/components/layout#comp-ref-b-col
   * PROPS
   *
   align-self     String                        null    Vertical alignment of the grid cell with respect to the row: 'start', 'center', 'end', 'baseline', or 'stretch'
   col              Boolean                       false   When true makes an equal width column grid cell spans for xs and up breakpoints
   cols             Number or String                      Number of columns the grid cell spans for xs and up breakpoints
   lg               Boolean or Number or String           Number of columns the grid cell spans for lg and up breakpoints
   md               Boolean or Number or String           Number of columns the grid cell spans for md and up breakpoints
   offset           Number or String                      Number of columns the grid cell is offset for xs and up breakpoints
   offset-lg        Number or String                      Number of columns the grid cell is offset for lg and up breakpoints
   offset-md        Number or String                      Number of columns the grid cell is offset for md and up breakpoints
   offset-sm        Number or String                      Number of columns the grid cell is offset for sm and up breakpoints
   offset-xl        Number or String                      Number of columns the grid cell is offset for xl and up breakpoints
   order            Number or String                      Flex order of the grid cell for xs and up breakpoints
   order-lg         Number or String                      Flex order of the grid cell for lg and up breakpoints
   order-md         Number or String                      Flex order of the grid cell for md and up breakpoints
   order-sm         Number or String                      Flex order of the grid cell for sm and up breakpoints
   order-xl         Number or String                      Flex order of the grid cell for xl and up breakpoints
   sm               Boolean or Number or String           Number of columns the grid cell spans for sm and up breakpoints
   tag              String                        'div'   Specify the HTML tag to render instead of the default tag
   xl               Boolean or Number or String           Number of columns the grid cell spans for xl and up breakpoints
   */
export default {
  name: 'GridCol',
  computed: {
    attr: function () {
      return this.$attrs
    }
  }
}
</script>

<style scoped>

</style>
