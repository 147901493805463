<template>
  <div class="gc-list-habits-tracker">
    <div class="gc-list-habits-tracker__wrapper-content">
      <div
        v-for="(entry,entryIndex) in setHabitsOrder.getEntries()"
        :key="entryIndex"
        class="gc-list-habits-tracker__wrapper-list-item"
      >
        <div class="gc-list-habits__wrapper-title">
          <text-content
            class="gc-list-habits-tracker__wrapper-list-item-description"
            :size="'sm2'"
            :weight="'medium'"
            :line-height="'multi'"
          >
            {{ entry.habit.getName() }}
          </text-content>
        </div>
        <div>
          <div class="gc-list-habits__wrapper-icons">
            <icon-completed-linear
              v-if="entry.getLabel()!=='yes'"
              :state="setIconStateByIndex (entryIndex)"
              :size="'sm4'"
              @click="clicked(entry, 'yes', entryIndex)"
            />
            <icon-completed-filled
              v-else-if="entry.getLabel()==='yes'"
              ::state="setIconStateByIndex (entryIndex)"
              :size="'sm4'"
              @click="clicked(entry, 'yes', entryIndex)"
            />
            <icon-incompleted-linear
              v-if="entry.getLabel()!=='no'"
              :state="setIconStateByIndex (entryIndex)"
              :size="'sm4'"
              @click="clicked(entry, 'no', entryIndex)"
            />
            <icon-incompleted-filled
              v-else-if="entry.getLabel()==='no'"
              :state="setIconStateByIndex (entryIndex)"
              :size="'sm4'"
              @click="clicked(entry, 'no', entryIndex)"
            />
            <icon-arrow-circular-linear
              v-if="entry.getLabel()!=='skip'"
              class="gc-list-habits-tracker__arrow-icon"
              :state="setIconStateByIndex (entryIndex)"
              :size="'sm4'"
              @click="clicked(entry, 'skip', entryIndex)"
            />
            <icon-arrow-circular-filled
              v-else-if="entry.getLabel()==='skip'"
              class="gc-list-habits-tracker__arrow-icon--filled"
              :state="setIconStateByIndex (entryIndex)"
              :size="'sm4'"
              @click="clicked(entry, 'skip', entryIndex)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TextContent from '../../root/TextContent'
import IconCompletedLinear from '../../root/icons/IconCompletedLinear'
import IconIncompletedLinear from '../../root/icons/IconIncompletedLinear'
import IconArrowCircularLinear from '../../root/icons/IconArrowCircularLinear'
import IconCompletedFilled from '../../root/icons/IconCompletedFilled'
import IconIncompletedFilled from '../../root/icons/IconIncompletedFilled'
import IconArrowCircularFilled from '../../root/icons/IconArrowCircularFilled'
import DashBoardService from '../../../services/DashboardService'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'ListHabitsTracker',
  components: {
    TextContent,
    IconCompletedLinear,
    IconIncompletedLinear,
    IconCompletedFilled,
    IconArrowCircularLinear,
    IconIncompletedFilled,
    IconArrowCircularFilled
  },
  props: {
    /**
     * habit tracker habits list
     */
    habitsEntries: {
      default: function(){
        return []
      },
      required: true,
      type:Array
    }
  },
  data () {
    return {
      submittingEntryIndexes: []
    }
  },
  computed: {
    ...mapGetters({
      getPlanExpiredPopupShow: 'getPlanExpiredPopupShow'
    }),
    setHabitsOrder () {
      return this.habitsEntries
    }
  },
  methods: {
    ...mapMutations({
      showSubscriptionExpiredPopup: 'showSubscriptionExpiredPopup'
    }),
    setIconStateByIndex (entryIndex) {
      return this.submittingEntryIndexes.includes(entryIndex) ? 'disabled' : ''
    },
    clicked (entry, status, entryIndex) {
      let dashboard = new DashBoardService()
      if (!(dashboard.isEntriesEditable())) {
        if (!(this.getPlanExpiredPopupShow)) {
          this.showSubscriptionExpiredPopup('habitTracker_select_status')
        }
      } else {
        if (this.submittingEntryIndexes.includes(entryIndex)) {
          return
        }
        this.submittingEntryIndexes.push(entryIndex)
        if (entry.getLabel() === status) {
          this.unmarkEntry(entry, entryIndex)
        } else {
          this.markEntry(entry, status, entryIndex)
        }
      }
    },
    unmarkEntry (entry, entryIndex) {
      let existedStatus = entry.getLabel()

      this.habitsEntries.deleteHabitEntry(entry.entry.id).then(() => {
        this.$emit('update-habit', entry.getDate())

        this.logEvent('HabitTracker.HabitStatusCleared', {
          habit: entry.habit.getName(),
          cleared_status: this.getLogStatusName(existedStatus),
          tracking_date: entry.getDate()
        }, false)
      }).finally(() => {
        this.submittingEntryIndexes = this.submittingEntryIndexes.filter(value => {
          return value !== entryIndex
        })
      })
    },
    markEntry (entry, status, entryIndex) {
      let habits = this.setHabitsOrder.getEntries()
      habits[entryIndex].entry.label = status
      entry.saveEntry(status).then(() => {
        this.$emit('update-habit', entry.getDate())

        this.logEvent('HabitTracker.HabitStatusChecked', {
          habit: entry.habit.getName(),
          status: this.getLogStatusName(entry.getLabel()),
          tracking_date: entry.getDate()
        }, false)
      }).catch(() => {
        habits[entryIndex].entry.label = null
      }).finally(() => {
        this.submittingEntryIndexes = this.submittingEntryIndexes.filter(value => {
          return value !== entryIndex
        })
      })
    },
    getLogStatusName (raw) {
      switch (raw) {
        case 'yes':
          return 'completed'

        case 'no':
          return 'incomplete'

        default:
          return 'skipped'
      }
    }
  }
}
</script>
