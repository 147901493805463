<template>
    <side-bar-button-primary
            @click="requestUpdate('weight')"
    >
        <template #default>
            {{$t('message["navigator.bottom-bar.weight"]').toLowerCase()}}
        </template>
        <template #icon>
            <icon-scale size="sm2"/>
            <pop-up-update-fitness-data
              :fitnessData="updatePopup.data"
              :type="updatePopup.type"
              :visible="updatePopup.show"
              v-if="updatePopup.show"
              v-on:close="closeUpdatePopup()"
            />
        </template>
    </side-bar-button-primary>
</template>
<script>
import IconScale from '../../root/icons/IconScale'
import SideBarButtonPrimary from './side-bar-sub-components/SideBarButtonPrimary'
import FitnessDiaryStore from '../../../../../store/modules/FitnessDiaryStore'
import { flagValue } from '../../../../../includes/TemplateSettings'
import { mapGetters, mapMutations } from 'vuex'
import DashBoardService from '../../../../../services/DashboardService'
import PopUpUpdateFitnessData from '../popups/PopUpUpdateFitnessData'

export default {
  name: 'ButtonSideBarWeightUpdate',
  components: {
    IconScale,
    SideBarButtonPrimary,
    PopUpUpdateFitnessData
  },
  data: function () {
    return {
      fitnessData: [],
      show: false,
      updatePopup: {
        show: false,
        type: '',
        data: 0
      }
    }
  },
  beforeCreate () {
    const store = this.$store
    if (!(store && store.state && store.state['fitnessDiaryStore'])) {
      store.registerModule('fitnessDiaryStore', FitnessDiaryStore)
    }
  },
  mounted () {
    if (
      flagValue('show_fitness_diary') === null ||
      flagValue('show_fitness_diary')
    ) {
      this.show = true
    }

    this.getData()
  },
  methods: {
    ...mapGetters({
      service: 'fitnessDiaryStore/getService'
    }),
    ...mapMutations({
      showSubscriptionExpiredPopup: 'showSubscriptionExpiredPopup'
    }),
    closeUpdatePopup () {
      this.updatePopup.show = false
    },
    getData () {
      let service = this.service()
      service
        .getDataFromAPI()
        .then(() => {
          this.fitnessData = Object.values(service.getData())
          this.hasTodayUploadAllImages = Object.values(service.images).every(
            image => {
              return image.getTodayImage()
            }
          )
          this.updatedFitnessData()
          this.isLoaded = true
        })
        .catch(() => {
          this.isLoaded = false
        })
    },
    requestUpdate (type) {
      let service = new DashBoardService()
      if (service.isEntriesEditable()) {
        if (type) {
          let currentData = this.fitnessData.find(data => {
            return data.getType() === type
          })
          if (currentData) {
            this.updatePopup.show = true
            this.updatePopup.type = currentData.getType()
            this.updatePopup.data = currentData
          }
        }
      } else {
        this.showSubscriptionExpiredPopup('')
      }
    }
  }
}
</script>
