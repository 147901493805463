import ProgressFeedbackAPI from './api/progress-check-in/ProgressFeedbackAPI'

export default class ProfileService {
  _progressFeedbackApi = new ProgressFeedbackAPI()

  getLatestFeedbackAt () {
    return this._progressFeedbackApi.getLatestFeedbackAt()
  }

  sendFeedbackEmail (feedbackMood, feedbackNote = null) {
    return this._progressFeedbackApi.sendFeedbackEmail({
      feedback_mood: feedbackMood,
      feedback_note: feedbackNote
    })
  }
}
