<template>
  <page-secondary
    :header="headerOptions"
    class="pc-meal-plan-meal macroapp--page-mealplan-meal"
  >
    <grid-row class="pc-meal-plan-meal__row" no-gutters>
      <grid-col class="pc-meal-plan-meal__col" col='12' lg='6'>
        <card-meal-featured
          class="pc-meal-plan__card-featured"
          :imagesSrc="getFirstImages"
          :macroNutrients="macroNutrients"
          :calories="calories"
        >
        </card-meal-featured>
      </grid-col>
      <grid-col class="pc-meal-plan-meal__col" col="12" lg="6">
        <div
          class="pc-meal-plan__action-bar-wrapper"
          v-if="showDislike || isSwapEnabled"
        >
          <bar-meal-action v-if="enabledByPlan" class="container">
            <action-meal-swap
              v-if="isSwapEnabled"
              :state="swapState"
              v-on:click="showSwapPopup"
            />
            <action-meal-dislike
              v-if="showDislike && swapState !== 'active'"
              :state="dislikeState"
              @clicked="updateDislike()"
            />
            <action-meal-favorite
              :disabled="isFavoriteDisabled"
              v-if="showFavorite && swapState !== 'active'"
              :state="favoriteState"
              @clicked="updateFavorite()"
            />
          </bar-meal-action>
        </div>
        <page-container class="pc-meal-plan-meal__container">
          <div
            v-for="(recipe, recipeID) in recipes"
            v-bind:key="'accordion-recipe-tile' + recipeID"
          >
            <accordion-recipe
              :recipe="recipe"
              :show-arrow="recipes.length > 1"
            />
          </div>
          <swap-meal
            v-if="swapPopup.visible"
            :visible="swapPopup.visible"
            :data="swapData"
            :meal="meal"
            @show-update-dislikes="showUpdateDislikesPopup"
            @hide-swap-popup="hideSwapPopup"
            @meal-swap-success="mealSwapSuccess"
          ></swap-meal>

          <popup-add-meal-dislikes
            :meal="meal"
            v-if="mealDislikesPopup.visible"
            :meal-name="getMealName()"
            :generated-meal-i-d="getMealID()"
            :meal-i-d="refMealID"
            :meal-plan-i-d="getMealPlanID()"
            :visible="mealDislikesPopup.visible"
            :swap-data="swapData"
            :can-swap="isSwapEnabled"
            :is-swapped="swapState === 'active'"
            @close="hideMealDislikePopup()"
            @swapped="mealSwapSuccess"
            @success="dislikeSuccess($event)"
            @show-update-dislikes="showUpdateDislikesPopup"
          />
          <popup-smart-dislikes
            v-if="updateDislikes.visible"
            :meal-plan="mealPlan"
            :visible="updateDislikes.visible"
            :data="updateDislikesData"
            @show-swap-dislikes="showSwapDislikesPopup"
            @hide-update-dislikes="hideUpdateDislikesPopup"
          />
          <popup-swap-dislikes
            v-if="swapDislikes.visible"
            :visible="swapDislikes.visible"
            :data="swapDislikesData"
            :meal-plan="mealPlan"
            @hide-swap-dislikes-popup="hideSwapDislikesPopup"
            @meal-swap-success="mealSwapSuccess"
          />
          <popup-favorite-disliked-meal
            :visible="showFavoriteDislikedMealPopup"
            @hide-popup="showFavoriteDislikedMealPopup = false"
            @submit="favoriteDislikedMeal()"
          />
          <popup-dislike-favorite-meal
            :visible="showDislikeFavoriteMealPopup"
            @hide-popup="showDislikeFavoriteMealPopup = false"
            @submit="dislikeFavoriteMeal()"
          />
        </page-container>
      </grid-col>
    </grid-row>
  </page-secondary>
</template>

<script>
import SwapMeal from '../../../global/popups/PopupSwapMeal'
import BaseComponent from '../global/base/BaseComponent'
import MealDislikeService from '../../../../services/MealDislikeService'
import MealFavouritesService from '../../../../services/nutrition/MealFavouritesService'
import {mapGetters, mapMutations} from 'vuex'

import BarMealAction from '../../../global/bars/BarMealAction.vue'
import CardMealFeatured from '../../../global/cards/CardMealFeatured.vue'
import ActionMealSwap from '../../../global/actions/ActionMealSwap.vue'
import ActionMealDislike from '../../../global/actions/ActionMealDislike.vue'
import PopupAddMealDislikes from '../../../global/popups/PopupAddMealDislikes'
import PopupFavoriteDislikedMeal from '../../../global/popups/PopupFavoriteDislikedMeal'
import PopupDislikeFavoriteMeal from '../../../global/popups/PopupDislikeFavoriteMeal'
import AccordionRecipe from './AccordionRecipe'
import {enableMealDislikesFlag, enableMealFavourites} from '../../../../includes/TemplateSettings'
import PageSecondary from '../../../global/pages/PageSecondary.vue'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer.vue'
import GridRow from '../../../global/grid/GridRow'
import GridCol from '../../../global/grid/GridCol'
import headerEventBus from '../../../../event-buses/headerEventBus'
import NutritionService from '../../../../services/nutrition/NutritionService'
import PopupSmartDislikes from './widgets/PopupSmartDislikes'
import PopupSwapDislikes from './widgets/PopupSwapDislikes'
import DashBoardService from '../../../../services/DashboardService'
import ActionMealFavorite from '@/components/global/actions/ActionMealFavorite'
import lodash from 'lodash'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'
export default {
  name: 'PageMeal',
  extends: BaseComponent,
  components: {
    PopupSmartDislikes,
    GridCol,
    GridRow,
    AccordionRecipe,
    SwapMeal,
    CardMealFeatured,
    BarMealAction,
    ActionMealSwap,
    ActionMealDislike,
    PopupAddMealDislikes,
    PageSecondary,
    PageContainer,
    PopupSwapDislikes,
    ActionMealFavorite,
    PopupFavoriteDislikedMeal,
    PopupDislikeFavoriteMeal
  },
  data: function () {
    return {
      enabledByPlan: false,
      swapData: null,
      updateDislikesData: {},
      swapDislikesData: {},
      nutritionOptions: {},
      showNutritionCard: false,
      refMealID: '',
      pageClass: ['macroapp--page-common', 'macroapp--page-mealplan-meal'],
      headerOptions: {
        show: true,
        isTransparent: false,
        left: 'previous-emit',
        right: 'help',
        rightDisabled: true,
        helpContent: '',
        modifiers: ['with-img', 'with-bg-color', 'small-title'],
        header: {
          mainTitle: '',
          subTitle: '',
        },
        formData: {
          image: '',
        },
        mainTitle: '',
      },
      isDisliked: false,
      isFavorite: false,
      dayTitle: '',
      totalTime: 0,
      recipes: [],
      instructionsPopup: {
        instructions: '',
        title: '',
      },
      swapPopup: {
        visible: false,
      },
      updateDislikes: {
        visible: false,
      },
      swapDislikes: {
        visible: false,
      },
      mealDislikesPopup: {
        visible: false,
      },
      showFavoriteDislikedMealPopup: false,
      showDislikeFavoriteMealPopup: false,
      macroNutrients: [],
      swapState: '',
      dislikeState: '',
      favoriteState: '',
      isFavoriteDisabled: false,
      isDisliking: false,
      isAddingToFavorites: false,
      calories: [],
      imagesSrc: [''],
      instructions: [],
      showDislike: false,
      showFavorite: false,
      isSwapEnabled: true,
      meal: null,
      mealPlan: null,
    }
  },
  mounted () {
    this.setPageClass()
    this.enableMealDislikeSwapByActivePlan()
    this.initMealSwapIngredients()
    this.setHeader(this.headerOptions)
    headerEventBus.$on('back-button-click', this.navigatePrevious)
    pageReadyEvent()
  },
  created () {
    let mpService = new NutritionService()
    this.mealPlan = mpService.getMealPlan(parseInt(this.$route.params.mid))
    this.loadMealPlanData()
  },
  computed: {
    ...mapGetters({
      previousRoute: 'getPreviousRoute',
    }),
    getFirstImages () {
      let imgs = []
      this.recipes.map((recipe) => {
        imgs.push(recipe.getImageMainImage())
      })
      imgs = imgs.slice(0, 3)
      let sortedImgs = lodash.sortBy(imgs)
      return sortedImgs
    },
  },
  methods: {
    ...mapMutations({
      initMealSwapIngredients: 'mealSwapStore/init',
    }),
    ...mapGetters({
      getMealSwapStatus: 'mealSwapStore/getMealSwapStatus',
    }),
    enableMealDislikeSwapByActivePlan () {
      const dashBoardService = new DashBoardService()
      this.enabledByPlan = dashBoardService.hasActivePlan()
    },
    loadMealPlanData () {
      const service = new NutritionService()
      let plan = service.getMealPlan(parseInt(this.$route.params.mid))
      if (plan.isLoaded()) {
        this.setPlanData(plan)
        this.hideLoading()
        return
      }
      plan
        .getDataFromAPI()
        .then(() => {
          this.setPlanData(plan)
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.status &&
            err.response.status === 404
          ) {
            this.headerOptions.mainTitle = 404
          }
        })
        .finally(() => {
          this.hideLoading()
        })
    },
    async setPlanData (plan) {
      this.instructionsPopup.instructions = [plan.getDescription()]
      this.instructionsPopup.notes = plan.getNotes() || ''
      // calling to setContent function in the pageStore
      this.setInstructionsPopupContent(this.instructionsPopup)

      let day = plan.getDay(parseInt(this.$route.query.day))
      this.meal = day.getMeal(parseInt(this.$route.query.meal))

      this.headerOptions.mainTitle = this.meal.getType().name
      this.recipes = this.meal.getRecipes()
      this.refMealID = this.meal.getReferenceMealId()

      let service = new MealFavouritesService()
      this.isFavorite = service.isFavouriteMeal(this.refMealID) ? 'active' : ''

      this.isDisliked = this.meal.isDisliked()
      this.dislikeState = this.isDisliked ? 'active' : ''
      this.favoriteState = this.isFavorite ? 'active' : ''
      this.swapState =
        this.meal.getSwapStatus() === 'swap-requested' ? 'active' : ''
      this.isSwapEnabled =
        (plan.isSwapEnabled() && this.meal.swapEnabled()) ||
        this.swapState === 'active'
      this.nutritionOptions.calories = this.meal.getCalories()
      this.nutritionOptions.macronutrients = this.meal.getMacroNutrients()
      this.showDislike = enableMealDislikesFlag() && this.meal.canDislike()
      this.showFavorite = enableMealFavourites()
      this.addMacroNutrients()
      this.addCalories()

      this.showNutritionCard = true
      this.totalTime = this.meal.getTotalPreparationTime()
      this.setSwapData()

      this.dayTitle = day.getName()
    },
    navigatePrevious () {
      if (!this.previousRoute.name) {
        this.$router.replace({
          path: this.$route.path,
          query: {
            day: this.$route.query.day || null,
          },
        })
      } else {
        this.$router.replace({
          path: this.previousRoute.path,
          query: this.previousRoute.query,
        })
      }
    },
    toggleDislike () {
      if (this.isDisliked) {
        this.removeMealDislike()
      } else {
        this.setSwapData()
        this.mealDislikesPopup.visible = true
      }
    },
    async toggleFavorite () {
      if (this.isAddingToFavorites) {
        return
      }
      if (this.isFavorite) {
        await this.removeMealFavorite()
      } else {
        this.isFavorite = true
        this.isAddingToFavorites = true
        this.favoriteState = this.isFavorite ? 'active' : ''
        this.isFavoriteDisabled = true
        let service = new MealFavouritesService()
        await service
          .setFavourite({
            meal_id: this.refMealID
          })
          .then(() => {
            this.logEvent('MealPlan.FavouritedAMeal', {type: this.meal.getType(), name: this.meal.getName()})
          })
          .catch((e) => {
            this.$store.commit('showServerErrorPopup')
            this.isFavorite = false
            this.favoriteState = this.isFavorite ? 'active' : ''
            this.isFavoriteDisabled = false
          })
          .finally(() => {
            this.isAddingToFavorites = false
            this.isFavoriteDisabled = false
          })
      }
    },
    updateDislike () {
      if (this.isFavorite) {
        this.showDislikeFavoriteMealPopup = true
      } else {
        this.toggleDislike()
      }
    },
    async updateFavorite () {
      if (this.isDisliked) {
        this.showFavoriteDislikedMealPopup = true
      } else {
        await this.toggleFavorite()
      }
    },
    async favoriteDislikedMeal () {
      this.removeMealDislike()
      await this.toggleFavorite()
    },
    async dislikeFavoriteMeal () {
      await this.removeMealFavorite()
      setTimeout(() => {
        this.toggleDislike()
      }, 750)
    },
    removeMealDislike () {
      if (this.isDisliking) {
        return
      }
      this.isDisliking = true
      let dislikeService = new MealDislikeService()
      dislikeService
        .removeDislike(this.refMealID)
        .then(() => {
          this.isDisliked = false
          this.meal.setDisliked(false)
        })
        .finally(() => {
          this.isDisliking = false
          this.dislikeState = this.isDisliked ? 'active' : ''
        })
    },
    async removeMealFavorite () {
      if (this.isAddingToFavorites) {
        return
      }
      this.isFavoriteDisabled = true
      this.isAddingToFavorites = true
      this.isFavorite = false
      this.favoriteState = this.isFavorite ? 'active' : ''
      let service = new MealFavouritesService()
      await service
        .removeFavourite(this.refMealID)
        .catch((e) => {
          this.$store.commit('showServerErrorPopup')
          this.isFavorite = true
          this.favoriteState = this.isFavorite ? 'active' : ''
          this.isFavoriteDisabled = false
        })
        .finally(() => {
          this.isAddingToFavorites = false
          this.isFavoriteDisabled = false
        })
    },
    hideMealDislikePopup () {
      this.mealDislikesPopup.visible = false
      if (this.isDisliked === true) {
        this.dislikeState = 'active'
      }
    },
    dislikeSuccess (event) {
      if (event.isSwapped) {
        this.mealSwapSuccess(event.isSwapped)
      }
      this.isDisliked = true
    },
    swapped () {
      setTimeout(() => {
        this.$router.go(-1)
      }, 2000)
    },
    setDislikeStatus (data) {
    },
    getMealName () {
      let fullMealName = []
      this.recipes.forEach((recipe) => {
        fullMealName.push(recipe.getName())
      })
      return fullMealName.join(' ')
    },
    getMealPlanID () {
      return parseInt(this.$route.params.mid) || 0
    },
    getMealID () {
      return parseInt(this.$route.query['meal']) || 0
    },
    showSwapPopup () {
      if (this.swapState !== 'active') {
        this.setSwapData()
        this.swapPopup.visible = true
      }
    },
    setSwapData () {
      this.swapData = {
        mealID: this.$route.query.meal,
        refMealID: this.refMealID,
        mealTitle: this.headerOptions.mainTitle,
        dayTitle: this.dayTitle,
        recipes: this.recipes,
      }
    },
    hideSwapPopup () {
      this.swapPopup.visible = false
    },
    showUpdateDislikesPopup (data) {
      this.updateDislikesData = {
        ingredients: data.ingredients,
        mealID: data.mealID,
      }
      setTimeout(() => {
        this.updateDislikes.visible = true
      }, 3000)
    },
    hideUpdateDislikesPopup () {
      this.updateDislikes.visible = false
    },
    showSwapDislikesPopup (data) {
      this.swapDislikesData = {
        ingredients: data.ingredients,
        otherMeals: data.otherMeals,
        refMealIds: data.refMealIds,
      }
      this.swapDislikes.visible = true
    },
    hideSwapDislikesPopup () {
      this.swapDislikes.visible = false
    },
    mealSwapSuccess () {
      this.swapState = 'active'
      this.hideSwapDislikesPopup()
    },
    addMacroNutrients () {
      // to insert data to nutrition array
      const Protein = this.nutritionOptions.macronutrients.proteins
      this.macroNutrients.push({
        unit: this.$i18n.t('message[\'general.g\']'),
        name: this.$i18n.t('message[\'general.proteins\']'),
        qut: Protein,
      })
      const fat = this.nutritionOptions.macronutrients.fats
      this.macroNutrients.push({
        unit: this.$i18n.t('message[\'general.g\']'),
        name: this.$i18n.t('message[\'general.fats\']'),
        qut: fat,
      })
      const Carb = this.nutritionOptions.macronutrients.carbohydrates
      this.macroNutrients.push({
        unit: this.$i18n.t('message[\'general.g\']'),
        name: this.$i18n.t('message[\'general.carbs\']'),
        qut: Carb,
      })
      if (this.nutritionOptions.macronutrients.show_net_carbs === true) {
        const NetCarb = this.nutritionOptions.macronutrients.net_carbohydrates
        this.macroNutrients.push({
          unit: this.$i18n.t('message[\'general.g\']'),
          name: this.$i18n.t('message[\'general.carbs-net\']'),
          qut: NetCarb,
        })
      }
    },
    addCalories () {
      // to insert data to nutrition array
      const calories = this.nutritionOptions.calories
      this.calories.push({
        unit: '',
        name: this.$i18n.tc('message[\'general.calories\']', calories),
        qut: calories,
      })
    },
  },
  beforeDestroy () {
    headerEventBus.$off('back-button-click', this.navigatePrevious)
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
  },
}
</script>
