<template>
  <div
    :class="{'gc-navigator-bottom-bar__link--active': active }"
    class="gc-navigator-bottom-bar__link gc-navigator-bottom-bar__link--community-chat">
    <div class="gc-navigator-bottom-bar__icon" @click="clicked()">
      <icon-community-chat
        v-if="active"
        :size="'md1'"
        class="gc-navigator-bottom-bar__link-icon gc-navigator-bottom-bar__link-icon--active"/>
      <icon-community-chat
        v-else
        :size="'md1'"
        class="gc-navigator-bottom-bar__link-icon gc-navigator-bottom-bar__link-icon--inactive"/>
      <icon-ellipse v-if="showNotificationEllipse"/>
      <icon-underline/>
    </div>
  </div>
</template>
<script>
import IconCommunityChat from '../../../root/icons/IconCommunityChat'
import IconCommunity from '../../../root/icons/IconCommunity'
import IconChat from '../../../root/icons/IconChat'
import IconDot from '../../../root/icons/IconDot'
import IconEllipse from '../../../root/icons/IconEllipse'
import TextBodyExtraSmall from '../../typography/TextBodyExtraSmall'
import {mapGetters} from 'vuex'
import IconUnderline from '@/components/root/icons/IconUnderline.vue'

export default {
  name: 'NavigatorBottomBarLinkCommunityChat',
  components: {
    IconUnderline,
    IconDot,
    IconEllipse,
    IconCommunity,
    IconChat,
    IconCommunityChat,
    TextBodyExtraSmall
  },
  props: {
    active: {
      default: false
    },
    chatHasNotification: {
      default: false
    }
  },
  methods: {
    clicked () {
      this.$emit('click')
    },
    handleNewMessages () {
      this.chatHasNotification = true
    },
    handleChatCollapsed () {
      this.chatHasNotification = false
    }
  },
  computed: {
    ...mapGetters({
      unreadCommunityNotificationsExist: 'communityStore/isUnreadNotificationsExist'
    }),
    showNotificationEllipse () {
      return (this.chatHasNotification || this.unreadCommunityNotificationsExist)
    }
  },
  mounted () {
    window.addEventListener('ma-chat-new-messages', this.handleNewMessages)
    window.addEventListener('ma-chat-collapsed', this.handleChatCollapsed)
  },
  beforeDestroy () {
    window.removeEventListener('ma-chat-new-messages', this.handleNewMessages)
    window.removeEventListener('ma-chat-collapsed', this.handleChatCollapsed)
  }
}
</script>
