<template>
    <div :class="componentClasses">
        <div class="gc-card-memeber-progress__main-wrapper">
          <!-- {{progressDetails}} -->
          <div class="gc-card-memeber-progress__photo-wrapper">
            <img :src="progressDetails.processed_images.ig_post" />
          </div>
            <text-content class="gc-card-memeber-progress__text--gap-2">{{ progressDetails.transformation_time_human }}</text-content>
            <text-content class="gc-card-memeber-progress__text--gap-1" :size="'sm3'">{{ progressDetails.photos.oldest.date}} → {{ progressDetails.photos.latest.date}}</text-content>
            <text-content class="gc-card-memeber-progress__text--gap-2">{{ progressDetails.customer.first_name }} {{ progressDetails.customer.last_name }}</text-content>
            <text-content class="gc-card-memeber-progress__text--gap-1" :weight="'extra-bold'" :size="'sm3'" @click="copyEmail">{{ progressDetails.customer.email }}</text-content>
            <button-custom :text="'IG Post'" @click="openImageInNewTab" />
        </div>
    </div>
</template>
<script>
import TextContent from '../../root/TextContent'
import Image from '../../root/Image'
import ButtonCustom from '../buttons/ButtonCustom'
import NativeAppMixin from '../../../mixins/NativeAppMixin'
import { isMobileNativeApp } from '../../../includes/NativeAppCommon'
export default {
  components: {
    Image,
    TextContent,
    ButtonCustom
  },
  props: {
    progressDetails: {
      type: Object,
      required: true
    }
  },
  mixins: [NativeAppMixin],
  computed: {
    componentClasses: function () {
      return {
        'gc-card-member-progress': true
      }
    }
  },
  methods: {
    openImageInNewTab () {
      if (isMobileNativeApp()) {
        this.handleUrlNavigate(this.progressDetails.processed_images.ig_post) 
      } else {
        const imageUrl = this.progressDetails.processed_images.ig_post

        const link = document.createElement('a')
        link.href = imageUrl
        link.target = '_blank'
        link.download = 'image.png'
        link.click()
      }
    },
    copyEmail () {
      const email = this.progressDetails.customer.email
      const el = document.createElement('textarea')
      el.value = email
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    }
  }
}
</script>
