<template>
  <div :class="componentClasses"  >
    <div class="row pc-workout__nav-exercise-large-set__row">
      <div
        class="col p-0"
        v-for="(exercise, id) in exercises"
        :key="'exercise-tab' + id"
      >
        <div
          class="pc-workout__nav-exercise-large-set__tab"
          v-bind:class="{
            'pc-workout__nav-exercise-large-set--active':
              exercise.getID() === currentExercise.getID(),
            'pc-workout__nav-exercise-large-set--complete': isExerciseCompleted(
              exercise
            ),
          }"
          v-on:click="navigateNav(exercise.id)"
        >
          <div class="pc-workout__nav-exercise-large-set__single-tab">
            <text-body-extra-small>
             <icon-complete-filled v-if="isExerciseCompleted(exercise)" :size="'sm1'" />
              {{ id + 1 }}
          </text-body-extra-small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import IconCompleteFilled from '../../../../root/icons/IconCompleteFilled'

export default {
  name: 'NavExerciseLargeSet',
  components: {
    TextBodyExtraSmall,
    IconCompleteFilled
  },
  props: {
    exercises: {
      type: Array,
      default: function () {
        return []
      }
    },
    currentExercise: {
      type: Object,
      required: true
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'pc-workout__nav-exercise-large-set': true,
        'pc-workout__nav-exercise-large-set--with-max-tabs': true
      }
    }
  },
  methods: {
    isExerciseCompleted (exercise) {
      return (
        (exercise.getIsCompleted() && this.currentExercise.getID() !== exercise.getID()) ||
        (this.currentExercise.getIsCompleted() && this.currentExercise.getID() === exercise.getID())
      )
    },
    navigateNav (id) {
      if (id === this.currentExercise.getID()) {
        return
      }
      this.$emit('navigate', id)
    }
  }
}
</script>
