import CountryAPI from './api/common/CountryAPI'

export default class CountryService {
  countries = {}
  count = 0
  constructor () {
    if (!CountryService.instance) {
      CountryService.instance = this
    }
    return CountryService.instance
  }

  setCountries () {
    const api = new CountryAPI()
    return new Promise((resolve, reject) => {
      api.getList().then(res => {
        const countryArr = []
        this.count =  res.data.countries.length
        res.data.countries.forEach(function (value, index, array) {
          countryArr[value.id] = value.name
        })
        this.countries = Object.assign({}, countryArr)
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getCountryById (country) {
    return this.countries[country]
  }

  load(){
    if(this.getCount()>0){
      return Promise.resolve()
    }
    return  this.setCountries()
  }

  getCount(){
    return this.count
  }

  getCountries () {
    return this.countries
  }
}
