import BaseComponent from '../../../../components/layout/template-1/global/base/BaseComponent'
import { mapGetters, mapMutations } from 'vuex'
import DashBoardService from '../../../../services/DashboardService'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  extends: BaseComponent,
  data () {
    return {
      pageClass: [''],
      hasImages: true,
      uploadPopup: {
        show: false
      },
      showProgress: true,
      showHistory: true,
      headerOptions: {
        show: true,
        left: 'previous-emit',
        right: 'add',
        rightDisabled: false,
        helpContent: '',
        modifiers: [ 'with-small-title', 'with-bg-color' ],
        formData: {
          image: ''
        },
        mainTitle: this.$i18n.t('message["fitness-diary.photo-journey"]'),
        subTitle: '',
        header: {
          mainTitle: this.$i18n.t('message["fitness-diary.photo-journey"]'),
          subTitle: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getAddButtonClick: 'pageStore/getAddButtonClick',
      headerBackButtonClick: 'pageStore/getBackButtonClick',
      service: 'fitnessDiaryStore/getService',
      getPlanExpiredPopupShow: 'getPlanExpiredPopupShow'
    })
  },
  watch: {
    headerBackButtonClick: function (newVal, oldVal) {
      this.navigatePrevious()
    },
    getAddButtonClick: function (newVal, oldVal) {
      this.uploadPopup.show = true
    }
  },
  beforeMount () {
    let dashBoardService = new DashBoardService()
    if (!dashBoardService.isFitnessDiaryEditable()) {
      this.headerOptions.right = ''
    }
  },
  methods: {
    ...mapMutations({
      showSubscriptionExpiredPopup: 'showSubscriptionExpiredPopup'
    }),
    navigatePrevious () {
      this.$router.push({
        path: this.$appConfig.appUrlList.progress
      })
    },
    refreshAll () {
      this.showProgress = false
      this.showHistory = false
      setTimeout(() => {
        this.showProgress = true
        this.showHistory = true
        pageReadyEvent()
      }, 50)
    },
    hasNoImages () {
      this.hasImages = false
    },
    uploadedImage (event) {
      this.sendPhotoUploadedEmail(event)
      this.hasImages = true
      this.refreshAll()
    },
    sendPhotoUploadedEmail (event) {
      this.service.sendPhotoUploadedEmail(event)
    }
  }
}
