export default class FirstLoadService {
  data = {
    exerciseView: true
  }

  localstorageKeys = {
    exerciseView: 'exerciseViewDisplayed',
    mealPlanView: 'mealPlanViewDisplayed',
    pwaPopUpView: 'pwaDisplayed',
    appSwitchView: 'appSwitchDisplayed'
  }

  constructor () {
    if (!FirstLoadService.instance) {
      FirstLoadService.instance = this
    }
    return FirstLoadService.instance
  }

  getExerciseViewFirstTimeStatus () {
    return this.getFirstTimeStatus('exerciseView')
  }

  getMealPlanViewFirstTimeStatus () {
    return this.getFirstTimeStatus('mealPlanView')
  }

  setExerciseViewDisplayed () {
    this.setFirstTimeStatus('exerciseView')
  }

  getFirstTimeStatus (key) {
    const exerciseViewDisplayed = localStorage.getItem(this.localstorageKeys[key])
    return (Boolean(!exerciseViewDisplayed))
  }

  setFirstTimeStatus (key) {
    localStorage.setItem(this.localstorageKeys[key], true)
  }
}
