import { render, staticRenderFns } from "./ProcessData.vue?vue&type=template&id=9622be6c&scoped=true"
import script from "./ProcessData.vue?vue&type=script&lang=js"
export * from "./ProcessData.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9622be6c",
  null
  
)

export default component.exports