import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    /** popup visibility */
    visible: false,
    /** popup title */
    title: '',
    /** popup description */
    description: '',
    /** confirmation button text */
    confirmationText: '',
    /** confirmation loading text */
    confirmationLoadingText: '',
    /** state of the action */
    state: 'init'
  },
  mutations: {
    /** to show the popup  */
    show (state) {
      state.visible = true
    },
    /** to hide the popup */
    hide (state) {
      state.visible = false
    },
    /** set the pop up description */
    setDescription (state, data) {
      state.description = data
    },
    /** set the pop up title */
    setTitle (state, data) {
      state.title = data
    },
    /** set confirmation */
    setConfirmationText (state, data) {
      state.confirmationText = data
    },
    /** set confirmation loading text */
    setConfirmationLoadingText (state, data) {
      state.confirmationLoadingText = data
    }
  },
  getters: {
    /** get the popup visibility */
    visible (state) {
      return state.visible
    },
    /** get the popup title */
    title (state) {
      return state.title
    },
    /** get the popup description */
    description (state) {
      return state.description
    },
    /** get the popup confirmation text */
    confirmationText (state) {
      return state.confirmationText
    },
    /** get the popup confirmation loading text */
    confirmationLoadingText (state) {
      return state.confirmationLoadingText
    }
  }
}
