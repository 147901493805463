<template>
    <div class="gc-card-challenge__wrapper">
        <div :class="componentClasses"
             :style="componentStyles"
             v-on="$listeners">

            <div class="gc-card-challenge__head">
                <text-body-regular
                        class="gc-card-challenge__title"
                        :weight="'extra-bold'"
                        :lineHeight="'multi'">
                    {{cardTitle}}
                </text-body-regular>
            </div>

            <div class="gc-card-challenge__bottom">
                <text-body-extra-small :lineHeight="'multi'" v-if="activationTime">
                    {{$t('message[\'general.starting-on\']',{date: this.activateOn})}}
                </text-body-extra-small>
                <div class="gc-card-challenge__timer-wrapper">
                  <text-body-regular :weight="'extra-bold'" :lineHeight="'multi'" v-if="showMinutesDifference">
                    {{$t('message[\'challenge-card.less-than-10-min\']')}}
                  </text-body-regular>
                  <text-body-regular :weight="'extra-bold'" :lineHeight="'multi'" v-else>
                    <span>{{$t('message[\'challenge-card.days\']',{days: countdown.days})}}</span> <icon-dot class="gc-card-challenge__timer-separator"/>
                    <span>{{$t('message[\'challenge-card.hours\']',{hours: countdown.hours})}}</span> <icon-dot class="gc-card-challenge__timer-separator"/>
                    <span>{{$t('message[\'challenge-card.minutes\']',{minutes: countdown.minutes})}}</span>
                  </text-body-regular>
                  <icon-meal v-if="this.type === 'meal-plan'" :size="'sm4'"></icon-meal>
                  <icon-workouts v-else-if="this.type === 'workout'" :size="'sm4'"></icon-workouts>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import TextBodyRegular from '../typography/TextBodyRegular'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import IconMeal from '../../root/icons/IconMeal'
import IconWorkouts from '../../root/icons/IconWorkouts'
import moment from 'moment'
import IconDot from '../../root/icons/IconDot'

export default {
  name: 'CardChallenge',
  components: {
    IconDot,
    IconWorkouts,
    IconMeal,
    TextBodyExtraSmall,
    TextBodyRegular
  },
  props: {
    type: {
      default: 'meal-plan' // 'meal-plan' or 'workout'
    },
    title: {
      default: ''
    },
    image: {
      default: []
    },
    activationTime: {
      default: ''
    }
  },
  data () {
    return {
      countdown: {
        days: '',
        hours: '',
        minutes: ''
      },
      countdownTimer: null,
      showMinutesDifference: false
    }
  },
  computed: {
    componentStyles: function () {
      return {
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url("${this.image}"),` + templateConfig.style_variables['var(--button-primary)']
      }
    },
    componentClasses: function () {
      return {
        'gc-card-challenge': true
      }
    },
    componentOverlayClasses: function () {
      return {
        'gc-card-challenge__overlay': true
      }
    },
    imageComponentStyles: function () {
      return {
        backgroundImage: `url("${this.image}")`
      }
    },
    cardTitle () {
      return this.title.length >= 49 ? this.title.substring(0, 49) + '...' : this.title
    },
    activateOn () {
      return moment.unix(this.activationTime).format('MMM DD, YYYY')
    }
  },
  mounted () {
    this.setCountdown()
    this.countdownTimer = setInterval(this.setCountdown, 10000)
  },
  beforeDestroy () {
    clearInterval(this.countdownTimer)
  },
  methods: {
    setCountdown () {
      let duration = moment.duration(moment.unix(this.activationTime).diff(moment()))
      this.showMinutesDifference = duration.asMinutes() <= 10

      if (!this.showMinutesDifference) {
        this.countdown.days = this.getFormattedDuration(Math.floor(duration.asDays()))
        this.countdown.hours = this.getFormattedDuration(duration.hours())
        this.countdown.minutes = this.getFormattedDuration(duration.minutes())
      }
    },
    getFormattedDuration (duration) {
      let durationStr = duration
      if (durationStr.toString().length <= 1) {
        return `0${durationStr}`
      } else {
        return duration
      }
    }
  }
}
</script>
<style>
</style>
