<template>
  <div :class="componentClasses" @click="clicked()">
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.11185 0C5.88466 0 4.70959 0.446518 3.84175 1.23539C2.95471 2.04208 2.46045 3.13832 2.46045 4.27917V6.13962C2.46045 6.44772 2.71049 6.69776 3.0186 6.69776H11.2049C11.513 6.69776 11.763 6.44772 11.763 6.13962V4.27917C11.763 3.13832 11.2689 2.04208 10.3817 1.23539C9.51396 0.446518 8.33885 0 7.1116 0H7.11185ZM7.11185 1.11629C8.05621 1.11629 8.9627 1.45418 9.63098 2.06065C10.2785 2.65005 10.6469 3.44629 10.6469 4.27917V5.58147H3.5769V4.27917C3.5769 3.44645 3.94531 2.65008 4.59279 2.06065C5.26107 1.45415 6.16757 1.11629 7.11192 1.11629H7.11185Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.6236 7.62861C13.6236 7.08603 13.4078 6.56514 13.0245 6.18116C12.6405 5.79792 12.1195 5.58203 11.577 5.58203H2.64668C2.1041 5.58203 1.5832 5.79781 1.19923 6.18116C0.815989 6.56514 0.600098 7.08616 0.600098 7.62861V13.9542C0.600098 14.4968 0.815881 15.0176 1.19923 15.4016C1.5832 15.7849 2.10422 16.0008 2.64668 16.0008H11.577C12.1196 16.0008 12.6405 15.785 13.0245 15.4016C13.4077 15.0176 13.6236 14.4966 13.6236 13.9542V7.62861ZM7.66994 11.4403C8.31965 11.2103 8.78623 10.5896 8.78623 9.86114C8.78623 8.93684 8.03608 8.1867 7.11179 8.1867C6.1875 8.1867 5.43735 8.93684 5.43735 9.86114C5.43735 10.5897 5.90393 11.2104 6.55364 11.4403V12.8379C6.55364 13.146 6.80369 13.396 7.11179 13.396C7.41989 13.396 7.66994 13.146 7.66994 12.8379V11.4403ZM7.11179 9.30299C7.41989 9.30299 7.66994 9.55303 7.66994 9.86114C7.66994 10.1692 7.41989 10.4193 7.11179 10.4193C6.80369 10.4193 6.55364 10.1692 6.55364 9.86114C6.55364 9.55303 6.80369 9.30299 7.11179 9.30299Z"
        fill="black"
      />
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconSecureLock',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-secure-lock': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>
