<template>
  <div :class="cardClass" v-on:click="viewMore('home_card')">
    <p class="card-measure__title">{{ name }}</p>
    <div class="card-measure__add-button">
      <a :href="void 0" v-on:click.stop="addData()">
        <plus-svg/>
      </a>
    </div>
    <div class="container card-measure-second-section">
      <div class="row align-items-center">
        <div class="col-4 p-0 text-left">
          <p class="card-measure__measure">
            {{ getFirstTimeValue() }} <span> {{ measurementUnit }}</span>
          </p>
          <p class="card-measure__day">{{ getFirstTimeDate() }}</p>
        </div>
        <div class="col-4 text-center">
          <div class="card-measure__arrow-top-measure">
            <p class="card-measure__difference" v-if="type==='height'&&progress.type !== 'none'">
              {{ progress.percentage }}% <br/><span>{{ progress.diffPercentageText }}</span>
            </p>
            <p class="card-measure__difference" v-else-if="(type!=='height'|| type!=='body_fat')&&progress.type !== 'none'">
              <br/><span>{{ progress.diffPercentageText }}</span>
            </p>
          </div>

          <log-right-arrow-svg/>
        </div>
        <div class="col-4 p-0 text-right">
          <p class="card-measure__measure">
            {{ getLatestValue() }} <span> {{ measurementUnit }}</span>
          </p>
          <p class="card-measure__day">{{ getLatestDate() }}</p>
        </div>
      </div>
    </div>
    <div class="card-measure__more-button" v-if="isEditable" v-on:click.stop.prevent="viewMore('home_more')">
       <p class="card-measure__more-link">{{ $t('message["general.more"]') }} ></p>
    </div>
  </div>
</template>
<script>
import LogRightArrowSvg from './svgs/LogRightArrowSvg'
import fitnessDiaryFitnessMeasurementComponentMixin from '../../../../../mixins/component-mixins/page/fitness-diary/fitnessDiaryFitnessMeasurementComponentMixin'
import PlusSvg from './svgs/PlusSvg'

export default {
  name: 'CardMediumFitnessDiaryMeasurement',
  mixins: [fitnessDiaryFitnessMeasurementComponentMixin],
  computed: {
    cardClass: function () {
      return [
        'card-measure', 'card-measure--small', 'card-measure__' + this.type
      ]
    }
  },
  components: { LogRightArrowSvg, PlusSvg }
}
</script>
