export function appendToBody (htmlContent) {

// Append the HTML content directly to the body of the document
  document.body.insertAdjacentHTML('beforeend', htmlContent);
}


export function injectHTMLToBody (htmlContent) {
  // Create a new div element
  const newDiv = document.createElement('div');

  // Set the innerHTML of the new div element to the HTML content
  newDiv.innerHTML = htmlContent;
  const nodes = newDiv.querySelectorAll(':scope > *')
  nodes.forEach(node => {
    if(node.tagName.toLowerCase() === 'script' && node.textContent){

      const script = document.createElement('script');
      // Define the script content
      script.textContent = node.textContent
      // Append the script to the body
      document.body.appendChild(script);

    } else {
      document.body.appendChild(node)
    }

  })
  // Append the new div element to the body of the document
  document.body.appendChild(newDiv);
}

export function clearAllCookies() {
  // Get all cookies
  const cookies = document.cookie.split(";");

  // Iterate through each cookie and delete it
  for (let cookie of cookies) {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

    // Set the cookie's expiration date to a past date
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  }
}
