<template>
  <button-base :submit-type="submitType" :class="componentClasses" @click="clicked()">
    <div class="gc-button-custom__left-section">
      <slot  name="left" class="gc-button-custom__left-section-icon"></slot>
      <div class="gc-button-custom__text-section">
        <text-content :class="textClass" :line-height="'lh-xl'" :size="'sm1'" :weight="'extra-bold'"> {{ text }}</text-content>
        <text-content  :size="'sm1'" :line-height="'multi'" v-if="hasSubText"> {{ subText }}</text-content>
      </div>
    </div>
    <div class="gc-button-custom__right-section">
      <slot  name="right" class="gc-button-custom__right-section-icon"></slot>
    </div>
  </button-base>
</template>

<script>

import TextContent from '../../root/TextContent'
import ButtonBase from '../../root/ButtonBase'

export default {
  name: 'ButtonCustom',
  components: {
    ButtonBase,
    TextContent
  },
  props: {
    submitType: {
      default: ''
    },
    /**
     * The main text of the button
     */
    text: {
      default: ''
    },
    /**
     * The sub text of the button
     */
    subText: {
      default: ''
    },
    // The border-radius of the button // values : 'none' = 0px | 'sm1' = 10px | 'md1' = 20px | 'lg1' = 30px
    borderRadius: {
      default: 'lg1'
    },
    // The type of the button // values: "solid" | "outline"
    type: {
      default: 'solid'
    },
    /**
     * The disabled state of the button
     */
    disabled: {
      default: false
    }
  },
  computed: {
    hasSubText: function () {
      return this.subText.replace(/\s/g, '') !== ''
    },
    hasIcon () {
      return !!this.$slots.default
    },
    textClass: function () {
      return {
        'gc-button-custom__text': true,
        'gc-button-custom__text--with-sub-text': this.hasSubText
      }
    },
    subTextClass: function () {
      return {
        'gc-button-custom__sub-text': this.hasSubText
      }
    },
    componentClasses: function () {
      return {
        'gc-button-custom': true,
        'gc-button-custom--with-sub-text': this.hasSubText,
        'gc-button-custom--disabled': this.disabled,
        'gc-button-custom--border-radius-none': this.borderRadius === 'none',
        'gc-button-custom--border-radius-sm1': this.borderRadius === 'sm1',
        'gc-button-custom--border-radius-md1': this.borderRadius === 'md1',
        'gc-button-custom--border-radius-lg1': this.borderRadius === 'lg1',
        'gc-button-custom--type-solid': this.type === 'solid',
        'gc-button-custom--type-outline': this.type === 'outline'
      }
    }
  },
  methods: {
    /**
     * function to emit the click event
     */
    clicked () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped></style>
