<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5 34C26.6127 34 34 26.6127 34 17.5C34 8.3873 26.6127 1 17.5 1C8.3873 1 1 8.3873 1 17.5C1 26.6127 8.3873 34 17.5 34Z" fill="#AFAFAF" fill-opacity="0.2"/>
    <path d="M34 17.5C34 26.6127 26.6127 34 17.5 34C8.3873 34 1 26.6127 1 17.5C1 8.3873 8.3873 1 17.5 1C26.6127 1 34 8.3873 34 17.5ZM2.485 17.5C2.485 25.7926 9.20744 32.515 17.5 32.515C25.7926 32.515 32.515 25.7926 32.515 17.5C32.515 9.20744 25.7926 2.485 17.5 2.485C9.20744 2.485 2.485 9.20744 2.485 17.5Z" fill="#AFAFAF"/>
    <path d="M17.2844 19.5703C14.6269 19.5703 12.0916 20.698 10.3254 22.6642C10.0618 22.9577 10.0861 23.4091 10.3797 23.6726C10.6725 23.9362 11.1246 23.9126 11.3881 23.6184C12.8829 21.9536 15.0326 20.9987 17.2844 20.9987C19.5427 20.9987 21.6917 21.9529 23.1801 23.6176C23.3208 23.7755 23.5165 23.8555 23.7122 23.8555C23.8814 23.8555 24.0521 23.7955 24.1885 23.6741C24.4821 23.4105 24.5071 22.9599 24.2442 22.6656C22.4852 20.6987 19.9484 19.5703 17.2844 19.5703Z" fill="#AFAFAF"/>
    <path d="M25.8554 11C25.4605 11 25.1413 11.32 25.1413 11.7142C25.1413 12.8955 24.18 13.8568 22.9987 13.8568C21.8174 13.8568 20.8561 12.8955 20.8561 11.7142C20.8561 11.32 20.5369 11 20.1419 11C19.747 11 19.4277 11.32 19.4277 11.7142C19.4277 13.6832 21.0297 15.2851 22.9987 15.2851C24.9677 15.2851 26.5696 13.6832 26.5696 11.7142C26.5696 11.32 26.2504 11 25.8554 11Z" fill="#AFAFAF"/>
    <path d="M15.1419 11.7142C15.1419 11.32 14.8227 11 14.4277 11C14.0328 11 13.7135 11.32 13.7135 11.7142C13.7135 12.8955 12.7522 13.8568 11.571 13.8568C10.3897 13.8568 9.42838 12.8955 9.42838 11.7142C9.42838 11.32 9.10914 11 8.71419 11C8.31924 11 8 11.32 8 11.7142C8 13.6832 9.60193 15.2851 11.571 15.2851C13.54 15.2851 15.1419 13.6832 15.1419 11.7142Z" fill="#AFAFAF"/>
    </svg>
    </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconCrap',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-crap': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
