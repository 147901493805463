import SliderService from '../services/SliderService'

/**
 * base methods for the simple slick
 * @type {{data: (function(): {slick: {sliderType: string, sliderID: string, slickOptions: {nextArrow: boolean, dots: boolean, centerMode: boolean, prevArrow: boolean, variableWidth: boolean, slidesToShow: number, autoplay: boolean}}}), methods: {next(): void, slickGoToByTypeAndID(): void, handleBeforeChange(*, *, *, *), handleBreakpoint(*, *, *), handleLazyLoaded(*, *, *, *), prev(): void, handleInit(*, *), handleEdge(*, *, *), handleSwipe(*, *, *), handleLazeLoadError(*, *, *, *), handleDestroy(*, *), reInit(): void, handleAfterChange(*, *, *=): void, slickGoTo(*=): void, handleReInit(*, *), setSliderTypeAndID(*, *): void, handleSetPosition(*, *)}}}
 */
export const slickMixin = {
  data: function () {
    return {
      slick: {
        sliderType: '',
        sliderID: '',
        slickOptions: {
          centerMode: true,
          touchThreshold: 40,
          slidesToScroll: 1,
          slidesToShow: 1,
          autoplay: false,
          dots: false,
          prevArrow: false,
          nextArrow: false,
          variableWidth: true
        }
      }
    }
  },
  methods: {
    setSliderTypeAndID (sliderType, sliderID) {
      this.slick.sliderID = sliderID
      this.slick.sliderType = sliderType
    },
    // slick
    next () {
      this.$refs.slick.next()
    },
    slickGoTo (slideIndex) {
      this.$refs.slick.goTo(slideIndex)
    },
    slickGoToByTypeAndID () {
      const service = new SliderService()
      this.slickGoTo(service.getCurrentSlideIndex(this.slick.sliderType, this.sliderID))
    },
    prev () {
      this.$refs.slick.prev()
    },
    reInit () {
      if (typeof this.$refs.slick === 'undefined') {
        return
      }
      const service = new SliderService()
      const currIndex = service.getCurrentSlideIndex(this.slick.sliderType, this.slick.sliderID)

      if(this.$refs.slick){
        this.$refs.slick.destroy()
      }
      this.$nextTick(() => {
        if(this.$refs.slick){
          this.$refs.slick.create()
          this.$refs.slick.goTo(currIndex, true)
        }

      })
    },
    resetSliderType () {
      let service = new SliderService()
      service.resetSliderType(this.slick.sliderType)
    },
    slickDestroy () {

    },
    // Events listeners
    handleAfterChange (event, slick, currentSlide) {
      if (this.slick.sliderType) {
        const service = new SliderService()
        service.setCurrentSlide(this.slick.sliderType, this.slick.sliderID, currentSlide)
      }
    },
    handleBeforeChange (event, slick, currentSlide, nextSlide) {
    },
    handleBreakpoint (event, slick, breakpoint) {
    },
    handleDestroy (event, slick) {
    },
    handleEdge (event, slick, direction) {
    },
    handleInit (event, slick) {
    },
    handleReInit (event, slick) {
    },
    handleSetPosition (event, slick) {
    },
    handleSwipe (event, slick, direction) {
    },
    handleLazyLoaded (event, slick, image, imageSource) {
    },
    handleLazeLoadError (event, slick, image, imageSource) {
    }
  }
}
