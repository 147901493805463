import { render, staticRenderFns } from "./ContainerTextShare.vue?vue&type=template&id=848d753e&scoped=true"
import script from "./ContainerTextShare.vue?vue&type=script&lang=js"
export * from "./ContainerTextShare.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "848d753e",
  null
  
)

export default component.exports