<template>
  <div :class="componentClasses">
  </div>
</template>

<script>
export default {
  name: 'Separator',
  props:
    {
      height: {
        default: 'sm'
      }
    },
  computed: {
    componentClasses: function () {
      return {
        'rc-separator': true,
        'rc-separator--height-sm': this.height === 'sm',
        'rc-separator--height-md': this.height === 'md',
        'rc-separator--height-lg': this.height === 'lg'
      }
    }
  }
}
</script>

<style scoped></style>
