<template>
  <div class="gc-card-feedback-mood">
    <card-mood-tracker-mood
      :state="selectedMood === moods[0] ? 'active' : 'inactive'"
      :show-add-icon="false"
      :show-mood-count="false"
      :moodClickable="true"
      :moodType="moods[0]"
      :text="moods[0]" v-on:select-mood="selectMood">
      <icon-awful class="gc-card-tracker__icon-mood"/>
    </card-mood-tracker-mood>

    <card-mood-tracker-mood
      :state="selectedMood === moods[1] ? 'active' : 'inactive'"
      :show-add-icon="false"
      :show-mood-count="false"
      :moodClickable="true"
      :moodType="moods[1]"
      :text="moods[1]" v-on:select-mood="selectMood">
      <icon-meh class="gc-card-tracker__icon-mood"/>
    </card-mood-tracker-mood>

    <card-mood-tracker-mood
      :state="selectedMood === moods[2] ? 'active' : 'inactive'"
      :show-add-icon="false"
      :show-mood-count="false"
      :moodClickable="true"
      :moodType="moods[2]"
      :text="moods[2]" v-on:select-mood="selectMood">
      <icon-great class="gc-card-tracker__icon-mood"/>
    </card-mood-tracker-mood>
  </div>
</template>

<script>
import CardMoodTrackerMood from './card-mood-tracker-sub-components/CardMoodTrackerMood'
import IconAwful from '../../root/icons/IconAwful'
import IconMeh from '../../root/icons/IconMeh'
import IconGreat from '../../root/icons/IconGreat'

export default {
  name: 'CardFeedbackMood',
  components: {
    CardMoodTrackerMood,
    IconAwful,
    IconMeh,
    IconGreat
  },
  data () {
    return {
      selectedMood: '',
      moods: [
        this.$t('message[\'fitness-diary.so-far-comparison.feedback.not-satisfied\']'),
        this.$t('message[\'fitness-diary.so-far-comparison.feedback.neutral\']'),
        this.$t('message[\'fitness-diary.so-far-comparison.feedback.so-satisfied\']')
      ]
    }
  },
  methods: {
    selectMood (mood) {
      this.selectedMood = mood.type
      this.$emit('moodChange', this.selectedMood)
    }
  }
}
</script>

<style scoped>

</style>
