<template>
    <div :class="componentClasses">
        <icon-camera :size="'sm4'" />
    </div>
</template>
<script>
import IconCamera from '../../../root/icons/IconCamera'
export default {
  name: 'CardPhotoCompareEmpty',
  components: { IconCamera },
  computed: {
    componentClasses: function () {
      return {
        'gc-card-photo-compare-empty': true
      }
    }
  }
}
</script>
