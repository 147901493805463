import { extend } from 'vee-validate'
import {i18n} from '../../localization/i18n'
import moment from 'moment'

const validator = {
  validate (value) {
    const inputDate = new Date(value)
    const minDate = new Date()
    const maxDate = new Date()
    const today = new Date()

    minDate.setFullYear(maxDate.getFullYear() - 100)
    maxDate.setFullYear(maxDate.getFullYear() - 15)

    if (moment(today).diff(moment(inputDate), 'days') > 36525) {
      return i18n.t('message["validation.please_recheck"]')
    } else if ((today.getFullYear() - inputDate.getFullYear()) < 0) {
      return i18n.t('message["validation.future_date_error"]')
    } else if (moment(today).diff(moment(inputDate), 'days') < 5479) {
      return i18n.t('message["validation.less_than_error"]')
    }
    return minDate <= inputDate && maxDate >= inputDate
  }
}
extend('birthday', validator)
