// Function to remove all IndexedDB databases
export async function removeAllIndexedDBDatabases() {
  try {
    // Get a list of all databases
    const databases = await indexedDB.databases();

    // Iterate through each database and delete it
    for (let i = 0; i < databases.length; i++) {
      const databaseName = databases[i].name;
      await indexedDB.deleteDatabase(databaseName);
      console.log(`Deleted IndexedDB database: ${databaseName}`);
    }

    console.log("All IndexedDB databases deleted successfully.");
  } catch (error) {
    console.error("Error deleting IndexedDB databases:", error);
  }
}
