<template>
  <div :class="componentClasses" @mousedown="mouseDown($event)" @click="clicked($event)">
    <icon-tick v-if="completed" :size="'sm2'" class="gc-slider-workout-program-day-button__icon"/>
    <text-body-extra-small :weight="'extra-bold'" :line-height="'multi'" class="gc-slider-workout-program-day-button__text">
      {{ text }}
    </text-body-extra-small>
  </div>
</template>

<script>
import IconTick from '../../../root/icons/IconTick.vue'
import TextBodyExtraSmall from '../../typography/TextBodyExtraSmall.vue'
export default {
  name: 'SliderWorkoutProgramDayButton',
  components: {
    IconTick,
    TextBodyExtraSmall
  },
  data: function () {
    return {
      mouseDownScreenX: 0,
      clickScreenX: 0
    }
  },
  props: {
    text: {
      default: ''
    },
    active: {
      default: false
    },
    completed: {
      default: true
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-slider-workout-program-day-button': true,
        'gc-slider-workout-program-day-button--active': this.active
      }
    }
  },
  methods: {
    clicked (event) {
      this.clickScreenX = event.screenX
      const diff = Math.abs(this.clickScreenX - this.mouseDownScreenX)
      if (diff < 20) {
        this.$emit('click')
      }
    },
    mouseDown (event) {
      this.mouseDownScreenX = event.screenX
    }
  }

}
</script>
