import PairAPI from '../api/meal-plan/PairAPI'
import lodash from 'lodash'
import ActivityTracker from '../ActivityTracker'

export default class PairService {
  api = new PairAPI()
  loaded = false
  details = {}

  /**
   * Constructor
   * @return {PairService|PairService}
   */
  constructor () {
    if (!PairService.instance) {
      PairService.instance = this
    }
    return PairService.instance
  }

  /**
   * Initiate member meal pair details
   * @return {Promise}
   */
  init (config = {forceLoad: false}) {
    return new Promise(async (resolve, reject) => {
      if (
        lodash.has(this.details, 'success') &&
        !config.forceLoad
      ) {
        // if data already loaded and force load not enabled
        resolve()
      } else {
        await this.api.getList()
          .then((res) => {
            this.details = res.data
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      }
    })
  }

  /**
   * Get owner's meal types
   * @return {*}
   */
  getParentMealTypes () {
    return this._getAttribute('parent_plan.meal_types', [])
  }

  /**
   * Get owner's meal plan category
   * @return {*}
   */
  getPlanCategory () {
    return this._getAttribute('parent_plan.category', {})
  }

  /**
   * Get paired or not paired status
   * @return {*}
   */
  isPaired () {
    return this._getAttribute('paired', false)
  }

  /**
   * Get can or cannot be paired status (if it already paired this will return false)
   * @return {*}
   */
  canPair () {
    return this._getAttribute('can_be_paired', false)
  }

  /**
   * Get whether owner's plan is valid or not (eligible to be paired)
   * @return {boolean|*}
   */
  ownerPlanIsValid () {
    if (this.isPaired()) {
      return true
    } else {
      return this.canPair()
    }
  }

  /**
   * Get already paired meal types
   * @return {*}
   */
  getPairedMealTypes () {
    return this._getAttribute('paired_meal_types', [])
  }

  /**
   * Get owner's disliked ingredients
   * @return {*}
   */
  getDislikedIngredients () {
    return this._getAttribute('parent_plan.dislikes', [])
  }

  /**
   * Pair provided meal types
   * if already paired this will be failed. need to unpair and try again
   * @param mealTypes
   * @return {Promise}
   */
  pair (mealTypes) {
    return new Promise(async (resolve, reject) => {
      this.api.post({
        pairing_meal_types: mealTypes
      })
        .then((res) => {
          this._logMeaMealPlanMealPairedEvent(mealTypes)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  _logMeaMealPlanMealPairedEvent (mealTypes) {
    try {
      let activity = new ActivityTracker()

      let selectedMealsStrings = []
      this.getParentMealTypes().forEach(mealType => {
        if (mealTypes.includes(mealType.id)) {
          selectedMealsStrings.push(mealType.name.toLowerCase())
        }
      })
      activity.addEvent('MealPlanPairing.MealPlanPaired', {meal_types: selectedMealsStrings})
    } catch (e) {

      // ignore issue
    }
  }

  /**
   * Unpair member from owner's meal types (previously paired)
   * @return {Promise}
   */
  unpair () {
    return new Promise(async (resolve, reject) => {
      this.api.unpair()
        .then((res) => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  /**
   * Get value for the provided key
   * @param key
   * @param defaultValue
   * @return {*}
   * @private
   */
  _getAttribute (key, defaultValue) {
    return lodash.get(this.details, key, defaultValue)
  }
}
