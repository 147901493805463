<template>
    <div>
        <div class="pc-popup-period-tracker">
            <popup-title class="pc-popup-period-tracker__header-without-icon"></popup-title>
            <popup-body class="text-center">
                <popup-content>
                    <popup-head class="text-center">
                        <popup-text-heading class="pc-popup-period-tracker__head">
                            {{$t('message[\'period-tracker.popup-saving\']')}}
                        </popup-text-heading>
                    </popup-head>
                </popup-content>
            </popup-body>
        </div>
    </div>
</template>

<script>
import PopupHead from '../../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../../global/popups/popup-sub-components/PopupTextHeading'
import {mapGetters} from 'vuex'
import PopupTitle from '../../../../../global/popups/popup-sub-components/PopupTitle'
import PopupBody from '../../../../../global/popups/popup-sub-components/PopupBody'
import PopupContent from '../../../../../global/popups/popup-sub-components/PopupContent'

export default {
  name: 'ProcessData',
  components: {
    PopupHead,
    PopupTextHeading,
    PopupTitle,
    PopupBody,
    PopupContent
  },
  mounted () {
    this.submitFirstRecord()
  },
  methods: {
    ...mapGetters({
      service: 'periodTrackerStore/getService'
    }),
    submitFirstRecord () {
      let firstRecord = this.service().getFirstRecord()
      if (firstRecord.getId()) {
        this.logEvent('PeriodTracker.LoggedPeriod', { }, false)
        // updating record
        firstRecord.update()
          .then(() => {
            this.$emit('next')
          })
          .catch(() => {
            this.$emit('close')
          })
      } else {
        // creating record
        this.logEvent('PeriodTracker.ConfiguredTracker', { }, false)
        firstRecord.save()
          .then(() => {
            this.$emit('next')
          })
          .catch(() => {
            this.$emit('close')
          })
      }
    }
  }
}
</script>

<style scoped>

</style>
