<template>
  <navigation-bar :class="isDesktop ? 'container' : '' ">
    <navigation-bar-tab
      :active="currentTab==='meal-plan'"
      @click="changeTab('meal-plan')"
    >
      {{ $t('message["food-diary.navigation-log-meal.meal-plan-meals"]') }}
    </navigation-bar-tab>
    <navigation-bar-tab
      v-if="getDineOutEnabled"
      :active="currentTab==='dine-out'"
      @click="changeTab('dine-out')"
    >
      {{ $t('message["food-diary.navigation-log-meal.dine-out"]') }}
    </navigation-bar-tab>
    <navigation-bar-tab
      :active="currentTab==='saved-meals'"
      @click="changeTab('saved-meals')"
    >
      {{ $t('message["food-diary.navigation-log-meal.saved-meals"]') }}
    </navigation-bar-tab>
    <navigation-bar-tab
      :active="currentTab==='new-meal'"
      @click="changeTab('new-meal')"
    >
      {{ $t('message["food-diary.navigation-log-meal.add-new-meal"]') }}
    </navigation-bar-tab>
  </navigation-bar>
</template>

<script>
import NavigationBar from '../../../../global/navigators/NavigationBar'
import NavigationBarTab from '../../../../global/navigators/navigation-bar-sub-components/NavigationBarTab'
import { mapGetters } from 'vuex'

export default {
  name: 'NavigationMealsAdd',
  components: {
    NavigationBarTab,
    NavigationBar
  },
  props: {
    activeTab: {
      required: true
    }
  },
  data: function () {
    return {
      selectedTab: null
    }
  },
  computed: {
    ...mapGetters({
      getDineOutEnabled :'foodDiaryStore/getDineOutEnabled'
    }),
    currentTab: function () {
      return this.selectedTab || this.activeTab
    }
  },
  methods: {
    changeTab (name) {
      this.$emit('change-tab', name)
    }
  }
}
</script>

<style scoped>

</style>
