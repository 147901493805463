<template>
  <page-secondary class="pc-workout-log-home" :header="headerOptions">
    <page-container class="pc-workout-log-home__container">
      <input-search-bar
        v-model="exerciseStr"
        :placeholder="searchPlaceholder"
        :name="'exercise'"
        @click="search()"
        @input="search()"
        class="pc-workout-log-home__search-bar"
      />
      <text-body-extra-small class="pc-workout-log-home__no-result-text" v-if="searchResults.length === 0" :line-height="'multi'">
        {{ $t("message['workout-log.no-results']") }}
      </text-body-extra-small>
      <div
        class="pc-workout-log-home__exercise-card-list"
        v-if="searchResults.length > 0"
      >
        <button-link-forward
          @click="navigate(exercise.id, exercise.name)"
          v-for="(exercise, index) in searchResults"
          :key="'exercise' + exercise.name + index"
          class="pc-workout-log-home__exercise-card"
          :text="exercise.name"
          :subText="showMusclesText(exercise.muscles)"
        />
      </div>
    </page-container>
  </page-secondary>
</template>

<script>
import BaseComponent from '../global/base/BaseComponent'
import WorkoutLogService from '../../../../services/WorkoutLogService'
import lodash from 'lodash'
import PageSecondary from '../../../global/pages/PageSecondary'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import InputSearchBar from './page-components/workout-log-sub-components/InputSearchBar'
import ButtonLinkForward from '../../../global/buttons/ButtonLinkForward'
import TextBodyExtraSmall from '../../../global/typography/TextBodyExtraSmall'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'PageWorkoutLogHome',
  extends: BaseComponent,
  components: {
    PageSecondary,
    PageContainer,
    InputSearchBar,
    ButtonLinkForward,
    TextBodyExtraSmall
  },
  data: function () {
    return {
      exerciseStr: '',
      searchResults: [],
      page: 'workout-log',
      searchPlaceholder: this.$i18n.t('message["workout-log.search"]'),
      pageClass: [
        'macroapp--page-workout-log',
        'macroapp--page-workout-log--home'
      ],
      error: '',
      headerOptions: {
        show: true,
        left: 'menu',
        right: '',
        rightDisabled: false,
        helpContent: '',
        modifiers: ['with-bg-color', 'small-title'],
        header: {
          mainTitle: 'Workout Log',
          subTitle: ''
        }
      }
    }
  },
  created () {
    this.setPageTitle(this.$t('message["workout-log.title"]'))
  },
  mounted () {
    this.loadPageData()
  },
  methods: {
    loadPageData () {
      this.headerOptions.header.mainTitle = this.$i18n.t(
        'message["workout-log.title"]'
      )
      pageReadyEvent()
      this.search()
    },
    search () {
      const service = new WorkoutLogService()
      service
        .searchLogByName(this.exerciseStr.trim())
        .then((data) => {
          const exercises = lodash.orderBy(data.data.exercises, 'name', 'asc')
          this.searchResults = exercises
        })
        .finally(() => {
          this.hideLoading()
        })
    },
    navigate (exercise, name) {
      this.$router.push({
        path: this.$appConfig.appUrlList.workoutBase,
        query: {
          'workout-log-history': exercise,
          name: name
        }
      })
    },
    showMusclesText (muscles) {
      let str = Object.values(muscles).join(', ')
      return str.length < 90 ? str : str.substring(0, 86) + '...'
    }
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
  }
}
</script>

<style scoped></style>
