<template>
  <page-image-cover :class="componentClasses" :header="headerOptions" :background-image="backgroundImage">
    <div class="pc-sign-in__start-header">
      <div class="pc-sign-in__logo">
        <img :src="logo"
             :style="logoShow?{}:logoStyle" @onerror="logoShow= false" alt="">
      </div>
    </div>
    <!--   page wrapper -->
    <page-container>
      <validation-observer ref="form" v-slot="{ handleSubmit }">
        <div class="pc-sign-in__card-sign-in">
          <form @submit.prevent="handleSubmit(signIn)">
            <div class="pc-sign-in__header">
                <text-content :weight="'extra-bold'"
                              :size="'lg1'"
                              :lineHeight="'multi'"
                              class="text-center" >
                {{ $t("message['form.website-login']") }}
                </text-content>
            </div>

            <div class="pc-sign-in__inputs">
              <validation-provider :name="$t('message[\'auth.email\']').toLowerCase()"
                                  :vid="'email'"
                                  rules="required|email"
                                  v-slot="{ errors }">
                <div class="form-group ma-form">
                  <text-input :type="'email'"
                              :placeholder="$t('message[\'auth.email\']')"
                              :errorMessage="errors[0]"
                              v-model="formData.email" />
                </div>
              </validation-provider>
              <validation-provider :name="$t('message[\'auth.password\']').toLowerCase()"
                                  :vid="'password'"
                                  rules="required"
                                  v-slot="{ errors }">
                <div class="form-group ma-form">
                  <text-input :type="'password'"
                              :placeholder="$t('message[\'auth.password\']')"
                              :errorMessage="errors[0]"
                              v-model="formData.password" />
                </div>
              </validation-provider>
            </div>

            <p class="text-center app-error-msg">{{serverError}}</p>

            <div class="pc-sign-in__buttons">
              <button-primary type="submit"
                              :text="$t('message[\'auth.login-submit\']')" />
            </div>

            <div class="pc-sign-in__frogot-passowrd">
              <button-link  type="button"
                            class=" text-center"
                            @click="navigate('/forgot-password')"
                            :text="$t('message[\'auth.forgotPassword\']')" />
            </div>

            <div  class="pc-sign-in__register" v-if="iapEnabled" >
              <button-link type="button"
                           v-on:click="register()"
                           class="pc-sign-in__register-button"
                           :text="$t('message[\'auth.register\']')" />
              <icon-forward :size="'sm1'"
                            class="pc-sign-in__register-icon"/>
            </div>

            <div class="pc-sign-in__popup-links">
              <!--<button-link  type="button"
                            class=" text-center"
                            @click="showAccountDeletion = true"
                            :text="$t('message[\'settings.delete-account\']')" />-->
              <button-link  type="button"
                            class=" text-center"
                            @click="showPrivacyPolicy()"
                            :text="$t('message[\'settings.privacy-policy\']')" />
              <button-link  type="button"
                            class=" text-center"
                            @click="showLegalDisclaimer()"
                            :text="$t('message[\'settings.legal-disclaimer\']')" />
            </div>
          </form>
        </div>
      </validation-observer>
    </page-container>
    <div class="app-bg-overlay"></div>

    <content-page-popup :page="'privacy-policy'" :visible="showPrivacyPolicyPopup" v-bind:key="'privacy-policy-popup'"
                        v-if="showPrivacyPolicyPopup"
                        v-on:close="closePrivacyPolicyPopup()"></content-page-popup>
    <content-page-popup :page="'legal-disclaimer'" :visible="showTermsCondPopup" v-bind:key="'legal-disclaimer-popup'"
                        v-if="showTermsCondPopup"
                        v-on:close="closeTermsAndConditionsPopup()"></content-page-popup>
    <popup-account-deletion v-if="showAccountDeletion" @hide-popup="showAccountDeletion = false"  :visible="showAccountDeletion"/>

  </page-image-cover>
</template>

<script>
import ContentPagePopup from '../../../global/popups/PopupContentPage'
import TextInput from '../../../global/inputs/TextInput'
import ButtonPrimary from '../../../global/buttons/ButtonPrimary'
import PopupAccountDeletion from '../../../global/popups/PopupAccountDeletion.vue'
import ButtonLink from '../../../global/buttons/ButtonLink'
import PageImageCover from '../../../global/pages/PageImageCover'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import TextContent from '../../../root/TextContent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import signInMixin from '../../../../mixins/component-mixins/page/auth/signInMixin'
import IconForward from '../../../root/icons/IconForward'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'sign-in',
  mixins: [signInMixin],
  components: {
    IconForward,
    ContentPagePopup,
    ValidationProvider,
    ValidationObserver,
    TextInput,
    ButtonPrimary,
    TextContent,
    ButtonLink,
    PageImageCover,
    PageContainer,
    PopupAccountDeletion
  },
  data: function () {
    return {
      headerOptions: {
        show: false

      },
      showAccountDeletion: false
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'pc-sign-in': true,
        'pc-sign-in__desktop-view': this.isDesktop,
        'pc-sign-in__tablet-view': this.isTablet
      }
    }
  },
  created () {
    this.setTrainer('')
    this.setPageTitle(this.$t("message['form.website-login']"))
  },
  mounted () {
    if (templateConfig && templateConfig.style_variables && templateConfig.style_variables['var(--bg-homePages)']) {
      document.body.style.background = templateConfig.style_variables['var(--bg-homePages)']
    }

    this.serverError = $('.alert.alert-danger').first().text()
    this.setImages()
    this.setIAPConfig()
    this.hideLoading()
    pageReadyEvent()
  },
  methods: {
    navigate (path) {
      if (path !== this.$router.currentRoute.fullPath) {
        if (path.includes('/progress')) {
          this.logEvent('Journey.ViewedHome', { context: 'side_menu' })
        }
        // holding router so slick dom breaking is not visible before fade effect
        this.delayedRouterPush({
          path: path
        })
      }
    },
  }
}
</script>
