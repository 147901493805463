<template>
  <div class="gc-slider-workout-program-week-card">
    <image-base
      :class="
        isOverlayActive
          ? 'gc-slider-workout-program-week-card__image--in-active'
          : ''
      "
      class="gc-slider-workout-program-week-card__image"
      v-if="image"
      :src="image"
      :borderRadius="'sm1'"
    />
    <div
      class="gc-slider-workout-program-week-card__overlay"
      v-if="isOverlayActive"
    ></div>
    <div class="gc-slider-workout-program-week-card__content">
      <icon-tick
        class="gc-slider-workout-program-week-card__icon"
        v-if="showTickIcon"
        :size="'sm4'"
      />
      <text-body-regular
        class="gc-slider-workout-program-week-card__text"
        :weight="'extra-bold'"
        :line-height="'multi'"
      >
        {{ text }}
      </text-body-regular>
    </div>
  </div>
</template>

<script>
import IconTick from '../../../root/icons/IconTick'
import ImageBase from '../../../root/Image'
import TextBodyRegular from '../../typography/TextBodyRegular'
export default {
  components: {
    ImageBase,
    IconTick,
    TextBodyRegular
  },
  props: {
    image: {
      default: ''
    },
    text: {
      default: ''
    },
    showTickIcon: {
      default: false
    },
    isOverlayActive: {
      default: true
    }
  }
}
</script>

<style></style>
