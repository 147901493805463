<template>
  <div>
      <text-content
        class="text-center"
        :size="'sm2'"
        :weight="'medium'"
        :line-height="'multi'">
        {{$t('message[\'swap.static-exercise-swap-message\']')}}
      </text-content>
    <popup-floating-footer>
      <button-primary :text="footerData.btnLabel" :disabled="!footerData.active" @click="footerSubmit"></button-primary>
    </popup-floating-footer>

  </div>
</template>

<script>
import workoutSwapMixin from '../../../../../mixins/workoutSwapMixin'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupFloatingFooter from '../../../../global/popups/popup-sub-components/PopupFloatingFooter'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import TextContent from '../../../../root/TextContent'

export default {
  name: 'SwapContactSupport',
  mixins: [workoutSwapMixin],
  components: {
    ButtonPrimary,
    PopupFloatingFooter,
    PopupContent,
    TextContent
  },
  data: function () {
    return {
      message: '',
      topDescription: '',
      customInputPlaceholder: '',
      customInputLabel: '',
      footerData: {
        btnLabel: this.$i18n.t('message[""swap.request-button""]') || 'Request Swap',
        active: true
      }
    }
  },
  created () {
    this.loadPageLanguageData()
  },
  methods: {
    loadPageLanguageData () {
      // title
      this.setSwapTitle(this.$i18n.t('message["swap.swap-exercises"]'))
      // description
      this.topDescription = this.$i18n.t('message[\'swap.static-exercise-swap-message\']')
      // footer button text
      this.footerData.btnLabel = this.$i18n.t('message[\'swap.static-exercise-swap-submit\']')
    },
    footerSubmit () {
      this.$emit('contactSupportSwapSubmit')
    }
  }
}
</script>
