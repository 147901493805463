<template>
  <page-secondary :header="headerOptions" class="pc-content">
    <page-content-image v-if="mainImage" :contentImage="mainImage" />
    <page-container>
        <page-content-text :description="description"/>
    </page-container>
  </page-secondary>
</template>

<script>
import BaseComponent from '../global/base/BaseComponent'
import ContentPageFactoryService from '../../../../services/ContentPageFactoryService'
import ContentContainer from './widgets/ContentContainer'
import {mapMutations} from 'vuex'
import PageContentImage from '../../../global/pages/page-sub-components/PageContentImage'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import PageContentText from './page-components/PageContentText'
import PageSecondary from '../../../global/pages/PageSecondary'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'
export default {
  name: 'PageContent',
  extends: BaseComponent,
  components: {
    PageSecondary,
    ContentContainer,
    PageContentImage,
    PageContainer,
    PageContentText
  },
  data: function () {
    return {
      pageType: this.$route.params.contentPage,
      itemList: {},
      mainImage: '',
      description: '',
      headerOptions: {
        isTopFixed: false,
        isTransparent: false,
        isFixed: false,
        show: true,
        left: 'menu',
        right: '',
        rightBlink: false,
        modifiers: ['small-title'],
        mainTitle: '',
        subTitle: ''
      }
    }
  },
  mounted () {
    this.hideLoading()
    const service = new ContentPageFactoryService()
    service.getPageList().then(data => {
      const link = service.findPageInList(this.pageType, data)
      this.setPageName(link.code)
      this.loadPageData(link.code)
    })
  },
  methods: {
    ...mapMutations({
      setPageName: 'pageStore/setPageName',
      unsetPageName: 'pageStore/unsetPageName'
    }),
    loadPageData (pageType) {
      const contentPageFactory = new ContentPageFactoryService()
      const page = contentPageFactory.getPage(pageType)
      page.setPageData().then(() => {
        this.headerOptions.mainTitle = page.pageData.page.title
        this.setPageTitle(page.pageData.page.title)
        this.description = page.pageData.page.description
        this.mainImage = page.pageData.page.header_image
        pageReadyEvent()
      })
    }
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
  }
}
</script>
