<template>
  <div class="pc-notification-list-item " :class="{'pc-notification-list-item--active': !notification.isRead}">
    <div class="pc-notification-list-item__left-section">
      <div class="pc-notification-list-item__icon pc-notification-list-item__icon--crown">
        <icon-crown v-if="notification.user.role === 'creator'" />
        <text-body-small :weight="extra - bold">{{ userAvatarCharacters }}</text-body-small>
      </div>
      <div class="pc-notification-list-item__content">
        <div class="pc-notification-list-item__content-user-icon">
          <text-body-small v-if="notificationType === 'comment-added'" :line-height="'multi'" v-html="$t('message[\'community.notification.commented\']', {
            userNameTitle: `<span>${$tc(
              'message[\'community.notification.user-title\']',
              notification.actorCount-1,
              { userName: userActiveName, otherCount: notification.actorCount - 1 }
            )}</span>`
          })"></text-body-small>

          <text-body-small v-else-if="notificationType === 'comment-mention'" :line-height="'multi'" v-html="$t('message[\'community.notification.comment-mentioned\']', { 
            userNameTitle: `<span>${userActiveName}</span>`
          })"></text-body-small>

          <text-body-small v-else-if="notificationType === 'post-mention'" :line-height="'multi'" v-html="$t('message[\'community.notification.post-mentioned\']', { 
            userNameTitle: `<span>${userActiveName}</span>`
          })"></text-body-small>
        </div>
        <div v-if="notification.actorCount === 1 && notificationType === 'comment-added'" class="pc-notification-list-item__content-user-content">
          <text-body-extra-small v-if="notification.comment && notification.comment.trim().length > 0">
            "{{ notification.comment }}"
          </text-body-extra-small>
        </div>
        <div class="pc-notification-list-item__content-user-timestamp">
          <text-body-extra-small>{{ notificationAt }}</text-body-extra-small>
        </div>
      </div>
    </div>
    <div class="pc-notification-list-item__right-section">
      <div class="pc-notification-list-item__arrow"><icon-forward size="sm1"/></div>
    </div>
  </div>
</template>

<script>
import IconCrown from '../../../../root/icons/IconCrown'
import IconForward from '../../../../root/icons/IconForward'
import TextBodySmall from '../../../../global/typography/TextBodySmall'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import moment from 'moment'

export default {
  name: 'CardCommunityNotification',
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  components: {
    IconCrown,
    IconForward,
    TextBodySmall,
    TextBodyExtraSmall
  },
  computed: {
    notificationType () {
      switch (this.notification.kind) {
        case 'comment':
          return 'comment-added'
          break;

          case 'comment-mentions':
          return 'comment-mention'
          break;

          case 'post-mentions':
          return 'post-mention'
          break;
      
        default:
          return this.notification.kind
          break;
      }
    },
    userActiveName () {
      return this.notification.user.nickname || this.notification.user.name.split(' ')[0]
    },
    userAvatarCharacters () {
      const words = this.userActiveName.trim().split(/\s+/)
      if (words.length >= 2) {
        const firstLetters = words.slice(0, 2).map(word => word[0])
        return firstLetters.join('').toUpperCase()
      }
      return this.userActiveName.slice(0, 2).toUpperCase()
    },
    notificationAt () {
      const dt = moment.utc(this.notification.updatedAt).local()
      const now = moment()
      const startOfNotificationDate = dt.clone().startOf('day')
      const startOfToday = now.clone().startOf('day')
      let dtStr = dt.format('MMM DD, YYYY - h:mm a')
      if (now.diff(dt, 'seconds') > 0 && now.diff(dt, 'hours') <= 12) {
        dtStr = dt.fromNow()
      } else if (startOfToday.diff(startOfNotificationDate, 'days') === 0) {
        dtStr = `Today - ${dt.format('h:mm a')}`
      } else if (startOfToday.diff(startOfNotificationDate, 'days') === 1) {
        dtStr = `Yesterday - ${dt.format('h:mm a')}`
      }
      return dtStr.charAt(0).toUpperCase() + dtStr.slice(1)
    }
  }
}
</script>
