<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C12.6711 0 15.1823 1.0402 17.0711 2.92891C18.9598 4.8177 20 7.32891 20 10C20 12.6711 18.9598 15.1823 17.0711 17.0711C15.1823 18.9598 12.6711 20 10 20C7.32887 20 4.81766 18.9598 2.92891 17.0711C1.04016 15.1823 0 12.6711 0 10C0 7.32891 1.04016 4.81766 2.92891 2.92891C4.81762 1.0402 7.32887 0 10 0ZM1.17188 10C1.17188 14.8679 5.13215 18.8281 10 18.8281C14.8679 18.8281 18.8281 14.8679 18.8281 10C18.8281 5.13215 14.8679 1.17188 10 1.17188C5.13215 1.17188 1.17188 5.13215 1.17188 10ZM13.9496 6.7915C14.1784 6.56271 14.5494 6.56271 14.7782 6.79146C15.007 7.02029 15.007 7.3913 14.7782 7.62009L9.1897 13.2086C9.07532 13.323 8.92532 13.3802 8.7754 13.3802C8.62548 13.3802 8.47552 13.323 8.3611 13.2086L5.22309 10.0706C4.99427 9.84177 4.99427 9.47075 5.22309 9.24197C5.45192 9.01318 5.82294 9.01318 6.05173 9.24197L8.77544 11.9657L13.9496 6.7915Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconTickLinear',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-tick-linear': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
