import FitnessDiaryAPI from './api/progress-check-in/FitnessDiaryAPI'
import FitnessMetric from './FitnessMetric'
import FitnessImageMetric from './FitnessImageMetric'
import FormOptionHelper from './helpers/FormOptionHelper'
import SubscriptionService from './ui/forms/SubscriptionService'
import { i18n } from '../helpers/localization/i18n'
import DashBoardService from './DashboardService'
import { flagValue } from '../includes/TemplateSettings'

export default class FitnessDiaryService {
  diaryAPI = new FitnessDiaryAPI()
  measurementSystem = ''
  user = { gender: '' }
  userData = null
  summaryData = null
  data = {
    weight: new FitnessMetric('weight', this),
    body_fat: new FitnessMetric('body_fat', this),
    // activity: new FitnessMetric('activity'),
    arms: new FitnessMetric('arms', this),
    shoulders: new FitnessMetric('shoulders', this),
    chest: new FitnessMetric('chest', this),
    waist: new FitnessMetric('waist', this),
    hips: new FitnessMetric('hips', this),
    legs: new FitnessMetric('legs', this),
    calves: new FitnessMetric('calves', this),
    height: new FitnessMetric('height', this)
  }

  images = {
    'front-image': new FitnessImageMetric('front-image', this),
    'side-image': new FitnessImageMetric('side-image', this),
    'back-image': new FitnessImageMetric('back-image', this)
  }

  getDataFromAPI (force = false) {
    const userDataCall = this.getMyInfoFromAPI(force)
    const progressCall = this.setSummaryDataFromAPI(force)
    return new Promise((resolve, reject) => {
      Promise.all([userDataCall, progressCall])
        .then(() => {
          resolve()
        })
        .catch(() => {
          reject(new Error('data not loaded'))
        })
    })
  }

  getData () {
    return this.data
  }

  getMeasurementSystem () {
    return this.measurementSystem
  }

  setSummaryDataFromAPI (force) {
    return new Promise((resolve, reject) => {
      if (!force && this.summaryData) {
        resolve()
        return
      }
      this.diaryAPI.getSummary().then(data => {
        this.summaryData = data.data

        let measurements = ['arms', 'shoulders', 'chest', 'waist', 'hips', 'legs', 'calves', 'height']
        data.data.entries.forEach(entry => {
          if (entry.field === 'weight') {
            this.setWeightEntry(entry)
          } else if (entry.field === 'body_fat') {
            this.setBodyFatEntry(entry)
          } else if (measurements.includes(entry.field)) {
            this.setMeasurementEntry(entry)
          }
        })
        data.data.photos.forEach(photoObj => {
          const imageType = photoObj.type + '-image'
          if (this.images[photoObj.type + '-image']) {
            this.images[imageType].setCurrentImage(photoObj.latest.value, photoObj.latest.id)
            this.images[imageType].setLatestDate(photoObj.latest.date)
            this.images[imageType].setFirstTimeImage(photoObj.oldest.value, photoObj.oldest.date, photoObj.oldest.id)
          }
        })
        resolve()
      }).catch(() => {
        reject(new Error('error in summary data'))
      })
    })
  }

  setMeasurementEntry (entry) {
    this.data[entry.field].setCurrentValue(entry.latest.value)
    this.data[entry.field].setName(i18n.t("message['progress." + entry.field + "']"))
    this.data[entry.field].setLatestDate(entry.latest.date)
    this.data[entry.field].setFirstTimeValue(entry.oldest.value, entry.oldest.date)
    this.data[entry.field].setMeasurementSystem(this.measurementSystem)
    this.data[entry.field].setUnit(entry.latest.unit)
  }

  setWeightEntry (entry) {
    this.setMeasurementEntry(entry)
  }

  setBodyFatEntry (entry) {
    this.setMeasurementEntry(entry)
    this.data.body_fat.setName(i18n.t("message['progress.body-fat']"))
  }

  getStatisticsOptions (statistics, key) {
    const field = statistics.fields.find(field => {
      return field.key === key
    })

    return field.options || []
  }

  value (num) {
    return Math.round(num * 2) / 2
  }

  getStatisticsValue (statistics, key) {
    const value = this.getStatisticsOptions(statistics, key).find(option => {
      return option.selected || false
    })
    if (typeof value === 'undefined') {
      return 0
    }
    return value.value || 0
  }

  getFirstTimeData () {
    return new Promise((resolve, reject) => {
      this.diaryAPI.getFirstTimeData().then(data => {
        const firstTimeData = data.data.data
        firstTimeData.forEach(value => {
          const currentKey = value.key
          if (this.data[currentKey]) {
            this.data[currentKey].setFirstTimeValue(value.value, value.date_added)
          } else if (this.images[currentKey]) {
            this.images[currentKey].setFirstTimeImage(value.value, value.date_added)
          }
        })
        resolve(data.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getMyInfoFromAPI (force) {
    return new Promise((resolve, reject) => {
      if (!force && this.userData) {
        resolve()
        return
      }
      const service = new DashBoardService()
      service.getUserData().then(data => {
        this.userData = data
        this.user.gender = data.gender
        this.measurementSystem = data.measurement_system
        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  /**
   * get the body fat ranges with images
   * @param gender
   * @returns {*}
   */
  getBodyFatOptions (gender = null) {
    if (gender === null) {
      gender = this.user.gender
    }
    const service = new FormOptionHelper()
    const bodyFatRanges = service.getBodyFats()
    if (gender === 'female' || gender === 'f') {
      return bodyFatRanges.f
    } else {
      return bodyFatRanges.m
    }
  }

  getBodyFatTextEnabled () {
    const service = new FormOptionHelper()
    const bodyFatRanges = service.getBodyFats()

    if (typeof bodyFatRanges.isTextLabel !== 'undefined') {
      return bodyFatRanges.isTextLabel;
    } else {
      return false;
    }
  }

  getBodyFatInputFieldEnabled () {
    const service = new FormOptionHelper()
    const bodyFatRanges = service.getBodyFats()

    if (typeof bodyFatRanges.showInput !== 'undefined') {
      return bodyFatRanges.showInput;
    } else {
      return true;
    }
  }

  updateData () {
    let bodyFat = this.data.fats.getUpdatedValue()
    bodyFat = bodyFat * 100
    const service = new SubscriptionService()
    bodyFat = service.mapData(parseInt(bodyFat), 'bodyFat')
    const data = {
      height: this.data.height.getUpdatedValue(),
      weight: this.data.weight.getUpdatedValue(),
      body_fat: bodyFat,
      lbm: bodyFat,
      activity_level: this.data.activity.getUpdatedValue(),
      activity_multiplier: this.data.activity.getUpdatedValue(),
      shoulders: this.data.shoulders.getUpdatedValue() || '',
      arms: this.data.arms.getUpdatedValue() || '',
      chest: this.data.chest.getUpdatedValue() || '',
      waist: this.data.waist.getUpdatedValue() || '',
      hips: this.data.hips.getUpdatedValue() || '',
      legs: this.data.legs.getUpdatedValue() || '',
      calves: this.data.calves.getUpdatedValue() || ''
    }
    return new Promise((resolve, reject) => {
      this.diaryAPI.updateData(data).then(output => {
        resolve(output)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getPhotosHistory (currentPage, perPage) {
    const params = {
      page: currentPage,
      per_page: perPage
    }
    return this.diaryAPI.getPhotosHistory(params)
  }

  getEntriesHistory (field, currentPage, perPage) {
    const params = {
      page: currentPage,
      per_page: perPage,
      field: field,
      type: 'day'
    }
    return this.diaryAPI.getEntriesHistory(params)
  }

  removeImageHistory (id) {
    return this.diaryAPI.removePhoto(id)
  }

  sendPhotoUploadedEmail (eventData) {
    const service = new DashBoardService()
    if (!flagValue('send_fitness_dairy_photo_upload_email', true)) {
      return Promise.resolve(false)
    }
    if (eventData.private === 1) {
      return Promise.resolve(false)
    }
    return new Promise((resolve, reject) => {
      service.getUserData().then(data => {
        const name = data.first_name + ' ' + data.last_name
        const email = data.email
        const langData = {
          customerName: name,
          customerEmail: email
        }
        const subject = i18n.t("message['fitness-diary.photo-upload-email-subject']", langData)
        const message = i18n.t("message['fitness-diary.photo-upload-email-message']", langData)
        this.diaryAPI.sendPhotoUploadedEmail({
          subject: subject,
          message: message
        })
          .then((ee) => {
            resolve(true)
          })
          .catch(() => {
            reject(new Error('unable to fitness diary photo upload email body : ' + message))
          })
      }).catch(() => {
        reject(new Error('unable to fitness diary photo upload email'))
      })
    })
  }
}
