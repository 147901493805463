<template>
  <div :class="componentClasses" @click="clicked()">
     <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.13363 0.440984C2.29259 -0.593306 0.799988 0.253997 0.799988 2.33197V17.6665C0.799988 19.7466 2.29259 20.5928 4.13363 19.5595L17.8187 11.8728C19.6604 10.8382 19.6604 9.1619 17.8187 8.12748L4.13363 0.440984Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconPlayTriangle',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-play-triangle': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
