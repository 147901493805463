<template>
  <div :class="componentClasses" v-on="$listeners">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.5714 8.57145H11.4277V1.42855C11.4277 0.639577 10.788 0 9.99893 0C9.20984 0 8.57017 0.639577 8.57017 1.42855V8.57145H1.42876C0.639672 8.57145 0 9.21103 0 10C0 10.789 0.639672 11.4286 1.42876 11.4286H8.5701V18.5714C8.5701 19.3604 9.20977 20 9.99886 20C10.788 20 11.4276 19.3604 11.4276 18.5714V11.4286H18.5712C19.3603 11.4286 20 10.789 20 10C20 9.21103 19.3605 8.57145 18.5714 8.57145Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconAdd',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-add': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
