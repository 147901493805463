import TimeZoneAPI from './api/common/TimeZoneAPI'

export default class TimezoneService {
  timezones = {}

  constructor () {
    if (!TimezoneService.instance) {
      TimezoneService.instance = this
    }
    return TimezoneService.instance
  }

  setTimezones () {
    const api = new TimeZoneAPI()
    return new Promise((resolve, reject) => {
      api.getList().then(res => {
        const timezoneArr = []
        res.data.timezones.forEach(function (value, index, array) {
          timezoneArr[value.id] = value.value
        })

        this.timezones = Object.assign({}, timezoneArr)
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getTimezones () {
    return this.timezones
  }

  getTimezone (id) {
    return this.timezones[id]
  }
}
