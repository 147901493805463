<template>
  <div>
    <page-secondary :header="headerOptions">
      <page-container class="pc-preferred-meals__container">
        <div class="gc-form-dynemic">
          <!--   page wrapper -->
          <div>
            <alert-dotted
              class="gc-form-dynemic__alert"
              v-if="editDisable && member.getFullName()"
            >
              <div class="gc-form-dynemic__alert-header">
                <icon-meal :size="'sm2'"/>
                <text-body-small
                  class="gc-form-dynemic__alert-title"
                  :line-height="'multi'"
                  :weight="'extra-bold'"
                >{{
                    $t(
                      'message["meal-plan-pair.paired-meal-plan-with-exclamation"]'
                    )
                  }}
                </text-body-small
                >
              </div>
              <text-body-extra-small
                class="gc-form-dynemic__alert-description"
                :line-height="'lh-xl'"
                v-html="
                  $t(
                    'message[\'meal-plan-pair.feature-preferred-meals-disabled-due-to\']'
                  )
                "
              ></text-body-extra-small>
            </alert-dotted>
            <div v-if="!editDisable" class="pc-preferred-meals__sub-content">
              <text-body-small :lineHeight="'multi'">{{
                  $t(
                    'message["profile.account-settings.preferred-meals-sub-content"]'
                  )
                }}
              </text-body-small>
            </div>
            <div
              class="gc-form-dynemic__profile-form profile-form profile-form-page-foods-do-not-like"
            >
              <validation-observer
                ref="form"
                :name="headerOptions.mainTitle"
                tag="div"
                v-slot="{ submitForm, invalid }"
              >
                <validation-provider
                  :name="headerOptions.mainTitle"
                  :rules="{ limit_selected_options: { limit: 2 } }"
                  tag="div"
                  v-slot="{ errors }"
                >
                  <input-multi-select
                    v-model="inputValue"
                    v-if="recipes.length > 0 && !editDisable"
                    :name="headerOptions.mainTitle"
                    :disabled="editDisable"
                    :options="recipes"
                    :max-selected="2"
                    :has-error="!!errors[0]"
                    :max-selected-title="headerOptions.mainTitle"
                    @mounted="setPageFooterClass()"
                    :placeHolder="
                      $t('message[\'general.select-your-preferred-meals\']')
                    "
                  />
                  <text-body-extra-small
                    class="pc-preferred-meals__error-message"
                  >{{ errors[0] }}
                  </text-body-extra-small
                  >

                  <page-footer v-if="!editDisable" ref="pageFooter">
                    <button-primary
                      @click="submitFormData"
                      :disabled="invalid"
                      :text="$t('message[\'profile.save\']')"/>
                  </page-footer>
                </validation-provider>
              </validation-observer>
            </div>
          </div>
        </div>
      </page-container>
    </page-secondary>
  </div>
</template>

<script>
import ProfileService from '../../../../../services/ProfileService'
import PageFooter from '../../../../global/pages/PageFixedFooter'
import PageContainer from '../../../../global/pages/page-sub-components/PageContainer'
import PageSecondary from '../../../../global/pages/PageSecondary'
import BaseComponent from '../../global/base/BaseComponent'
import TextBodySmall from '../../../../global/typography/TextBodySmall'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import PairService from '../../../../../services/meal-plan-pair/PairService'
import ConnectionService from '../../../../../services/meal-plan-pair/ConnectionService'
import inputMultiSelect from '../../../../global/inputs/InputMultiSelect'
import AlertDotted from '../../../../global/alerts/AlertDotted'
import Member from '../../../../../services/meal-plan-pair/Member'
import IconMeal from '../../../../root/icons/IconMeal'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'
import headerEventBus from '@/event-buses/headerEventBus'

export default {
  name: 'DislikedIngredients',
  extends: BaseComponent,
  components: {
    TextBodyExtraSmall,
    TextBodySmall,
    PageFooter,
    PageContainer,
    PageSecondary,
    inputMultiSelect,
    AlertDotted,
    IconMeal,
    ButtonPrimary,
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      headerOptions: {
        isTransparent: false,
        isFixed: false,
        show: true,
        left: 'previous-emit',
        right: '',
        rightBlink: false,
        modifiers: ['small-title', 'with-bg-color'],
        mainTitle: this.$t('message["meal-plan.preferred-meals"]'),
        subTitle: ''
      },
      editDisable: false,
      member: new Member(),
      connectionService: new ConnectionService(),
      pairService: new PairService(),
      profileService: new ProfileService(),
      inputValue: [],
      recipes: []
    }
  },
  mounted () {
    this.setHeader(this.headerOptions)
    this.setPageClass()
    this.setPageData()
    headerEventBus.$on('back-button-click', this.navigatePrevious)
  },
  beforeDestroy () {
    headerEventBus.$off('back-button-click', this.navigatePrevious)
  },
  methods: {
    navigatePrevious () {
      this.$router.push({
        path: this.$appConfig.appUrlList.profile,
        query: {
          page: 'dietary-preferences',
          loading: '0'
        }
      })
    },
    async setPageData () {
      // initiating connection, pair, profile details
      await Promise.all([
        this.connectionService.init(),
        this.pairService.init(),
        this.profileService.setProfileData(),
        this.profileService.getRecipesList()
      ])
        .catch(() => {
          this.hideLoading()
        })
        .finally(() => {
          pageReadyEvent()
          // setting partner details
          this.member = this.connectionService.getPartner()
          if (
            this.pairService.isPaired() &&
            !this.connectionService.isOwner()
          ) {
            // if meal plan is paired and current user is not the owner

            // disable inputs
            this.editDisable = true
            this.recipes = this.profileService.recipes
            this.hideLoading()
          } else {
            this.recipes = this.profileService.recipes
            this.inputValue = this.profileService.selectedRecipes
            this.$nextTick(() => {
              this.hideLoading()
              if (this.$refs.form) {
                this.$refs.form.validate()
              }
            })
          }
        })
    },
    async submitFormData () {
      this.showLoading()
      const profileService = new ProfileService()
      await profileService.setProfileData()
      await profileService.updateRecipes({selected_recipes: this.inputValue})
        .then(() => {
          this.goBack()
        })
        .catch(() => {
          this.hideLoading()
        })
    },
    goBack () {
      this.showLoading()
      let self = this
      setTimeout(function () {
        self.$router.push({
          path: self.$appConfig.appUrlList.profile,
          query: {
            page: 'dietary-preferences'
          }
        })
      }, 500)
    },
    setPageFooterClass () {
      this.$refs.pageFooter.setFooterClass()
    }
  }
}
</script>

<style scoped></style>
