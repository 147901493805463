import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    lastExpanded: {}
  },
  mutations: {
    setLastExpanded (state, index) {
      state.lastExpanded.expanded = index
    },
    setLastOffset (state, offset) {
      state.lastExpanded.rememberTop = offset
    },
    resetLastOffset (state) {
      state.lastExpanded.rememberTop = 0
    },
    reset (state) {
      state.lastExpanded = {}
    }
  },
  getters: {
    getLastExpanded (state) {
      return state.lastExpanded
    }
  }
}
