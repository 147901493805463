<template>
  <div :class="componentClasses" @click="clicked()">
  <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.63984 5.21677C7.7423 5.18397 7.85967 5.16699 7.99281 5.16699C8.1347 5.16699 8.25671 5.18411 8.36 5.21685V7.646H10.7703C10.8123 7.76483 10.8329 7.88274 10.8329 8.00002C10.8329 8.10968 10.812 8.23225 10.7683 8.36829H8.36V10.7796C8.225 10.8156 8.10274 10.833 7.99281 10.833C7.89169 10.833 7.77424 10.8157 7.63984 10.7796V8.36829H5.23151C5.18788 8.23225 5.16689 8.10968 5.16689 8.00002C5.16689 7.88274 5.18758 7.76483 5.22956 7.646H7.63984V5.21677ZM8 15.3394C12.0535 15.3394 15.3394 12.0535 15.3394 8C15.3394 3.94653 12.0535 0.66055 8 0.66055C3.94653 0.66055 0.66055 3.94653 0.66055 8C0.66055 12.0535 3.94653 15.3394 8 15.3394ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z" fill="white"/>
  </svg>
  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconAddMenuBar',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-add-menu-bar': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
