<template>

  <popup
    v-if="visible"
    ref="uploadPhotosModal"
    :visible="visible"
    @hide="closePopup"
    modal-class="gc-popup-upload-photos">
    <popup-title>
      <popup-button-close @hide="closePopup"/>
    </popup-title>
    <popup-body class="gc-popup-upload-photos__body">
      <popup-head class="text-center">
        <div v-if="!(readyToUpload&&!(hasImageToUpload()&&!readyToUpload))">
          <popup-text-heading
            v-if="!isImagesUploaded">
            {{ $t('message["fitness-diary.add-photos"]') }}
          </popup-text-heading>
          <text-body-small v-if="hasAlreadyUploadedImages" line-height="multi"  class="gc-popup-upload-photos__sub-heading">
            {{$t('message["fitness-diary.replace-today-photos"]')}}
          </text-body-small>
        </div>
        <popup-text-heading
          v-if="readyToUpload&&!(hasImageToUpload()&&!readyToUpload)&&!success.status">
          {{ $t('message["mood-tracker.pop-up-loading"]')}}...
        </popup-text-heading>
        <popup-text-heading
          v-if="success.status">
          {{ $t('message["mood-tracker.pop-up-header-success"]')}}
        </popup-text-heading>
      </popup-head>
      <popup-content class="gc-popup-upload-photos__content">

        <div class="gc-popup-upload-photos__icon-complete" v-if="success.status">
          <icon-complete-filled :size="'lg3'" />
        </div>

        <loading-buffer v-if="readyToUpload&&!(hasImageToUpload()&&!readyToUpload)&&!success.status" class="gc-popup-upload-photos__buffer"/>

        <div v-bind:style="readyToUpload&&!(hasImageToUpload()&&!readyToUpload) ? {'opacity' : '0' , 'pointer-events' : 'none'} : {'opacity': '100%'}"
              v-if="!success.status">
          <text-body-extra-small :style="{'visibility': (hasInvalidImage|| hasErrorUpload)?'visible':'hidden' }"
               class="gc-popup-upload-photos__error-text">
            <p v-if="hasErrorUpload">{{uploadErrorMessage}}</p>
            <p v-else-if="hasInvalidImage ">{{invalidImageError}}</p>
          </text-body-extra-small>
          <div class="gc-popup-upload-photos__cards-wrapper" v-if="!success.status">
              <div class="gc-popup-upload-photos__card">
                <text-body-extra-small class="gc-popup-upload-photos__card-title" :lineHeight="'multi'">
                  {{ $t('message[\'fitness-diary.front-image\']') }}
                </text-body-extra-small>
                <div class="gc-popup-upload-photos__card-image-upload-tile">
                  <fitness-image-image-upload
                    class="gc-popup-upload-photos_card-image"
                    :image-name="'front_image'"
                    :image-object="service().images['front-image']"
                    :is-private="isPrivate"
                    :ready-to-upload="readyToUpload&&startUpload['front-image']"
                    v-on:error-upload="errorUpload('front-image',$event)"
                    v-on:invalid-upload="invalidImage('front-image',$event)"
                    v-on:remove-upload="removeUpload('front-image')"
                    v-on:to-upload="toUploadedImage('front-image')"
                    v-on:uploaded="uploadedImage('front-image')"
                    @enable-submit="enableImageSubmit"
                    @disable-submit="disableImageSubmit"
                  />
                </div>
              </div>

              <div class="gc-popup-upload-photos__card">
                <text-body-extra-small class="gc-popup-upload-photos__card-title" :lineHeight="'multi'">
                  {{ $t('message[\'fitness-diary.side-image\']') }}
                </text-body-extra-small>
                <div class="gc-popup-upload-photos__card-image-upload-tile">
                  <fitness-image-image-upload
                    class="gc-popup-upload-photos_card-image"
                    :image-name="'side_image'"
                    :image-object="service().images['side-image']"
                    :is-private="isPrivate"
                    :ready-to-upload="readyToUpload&&startUpload['side-image']"
                    v-on:error-upload="errorUpload('side-image',$event)"
                    v-on:invalid-upload="invalidImage('side-image',$event)"
                    v-on:remove-upload="removeUpload('side-image')"
                    v-on:to-upload="toUploadedImage('side-image')"
                    v-on:uploaded="uploadedImage('side-image')"
                    @enable-submit="enableImageSubmit"
                    @disable-submit="disableImageSubmit"
                  />
                </div>
              </div>

              <div class="gc-popup-upload-photos__card">
                <text-body-extra-small class="gc-popup-upload-photos__card-title" :lineHeight="'multi'">
                  {{ $t('message[\'fitness-diary.back-image\']') }}
                </text-body-extra-small>
                <div class="gc-popup-upload-photos__card-image-upload-tile">
                  <fitness-image-image-upload
                    class="gc-popup-upload-photos_card-image"
                    :image-name="'back_image'"
                    :image-object="service().images['back-image']"
                    :is-private="isPrivate"
                    :ready-to-upload="readyToUpload&&startUpload['back-image']"
                    v-on:error-upload="errorUpload('back-image',$event)"
                    v-on:invalid-upload="invalidImage('back-image',$event)"
                    v-on:remove-upload="removeUpload('back-image')"
                    v-on:to-upload="toUploadedImage('back-image')"
                    v-on:uploaded="uploadedImage('back-image')"
                    @enable-submit="enableImageSubmit"
                    @disable-submit="disableImageSubmit"
                  />
                </div>
              </div>
            </div>
            <div class="gc-popup-upload-photos__private-check" v-if="canUploadPublic&&!readyToUpload &&showTeamSeeImagesText()">
              <div class="gc-popup-upload-photos__private-check-input">
                <div class="gc-popup-upload-photos__private-check-checkbox">
                  <input-radio-button-squared :selected="isSetPrivate" @click="changeIsSetPrivate"></input-radio-button-squared>
                </div>
              </div>
              <div>
                <text-body-extra-small
                  class="gc-popup-upload-photos__private-check-text"
                  v-if="showTeamSeeImagesText()">{{$t('message["fitness-diary.let-team-see-check"]', [trainerEmail])}}
                </text-body-extra-small>
                <div
                  class="gc-popup-upload-photos__helper-team"
                  v-if="hasImageToUpload()&&!readyToUpload && isSetPrivate && showTeamSeeImagesText()">
                  <text-body-extra-small>
                    {{ $t('message["fitness-diary.team-see-images"]', [trainerEmail]) }}
                  </text-body-extra-small>
                </div>
                <div
                  class="gc-popup-upload-photos__helper-only-you"
                  v-if="hasImageToUpload()&&!readyToUpload && !isSetPrivate && showTeamSeeImagesText()">
                  <text-body-extra-small>
                    {{ $t('message["fitness-diary.you-see-images"]', [trainerEmail]) }}
                  </text-body-extra-small>
                </div>
              </div>
            </div>

            <div>
              <div
                class="gc-popup-upload-photos__general-error"
                v-for="(error,eid) in generalErrors"
                :key="'general-error-'+eid"
                v-html="error"
              ></div>
            </div>
          <div v-if="!success.status">
            <div class="gc-popup-upload-photos__uploading-text" v-if="readyToUpload&&!(hasImageToUpload()&&!readyToUpload)">
            </div>
          </div>
        </div>
      </popup-content>
      <popup-footer>
        <button-primary
          v-if="!(readyToUpload&&!(hasImageToUpload()&&!readyToUpload))"
          :text="$i18n.t('message[\'general.upload\']')"
          :disabled="!(hasImageToUpload()&&!readyToUpload) || !imageSubmit"
          v-on:click="upload()" />
      </popup-footer>
    </popup-body>

  </popup>
</template>

<script>
import Popup from './Popup'
import PopupTitle from './popup-sub-components/PopupTitle'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupHead from './popup-sub-components/PopupHead'
import PopupTextHeading from './popup-sub-components/PopupTextHeading'
import PopupTextSubHeading from './popup-sub-components/PopupTextSubHeading'
import PopupContent from './popup-sub-components/PopupContent'
import PopupFooter from './popup-sub-components/PopupFooter'
import PopupBody from './popup-sub-components/PopupBody'
import IconCompleteFilled from '../../root/icons/IconCompleteFilled'
import ButtonPrimary from '../buttons/ButtonPrimary'
import TextBodySmall from '../../global/typography/TextBodySmall'
import LoadingBuffer from '../../layout/template-1/global/widgets/LoadingBuffer'
import InputRadioButtonSquared from '../inputs/InputRadioButtonSquared'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import { mapGetters } from 'vuex'
import FitnessImageImageUpload from '../inputs/InputImageUpload'
import ProfileService from '../../../services/ProfileService'
import { flagValue } from '../../../includes/TemplateSettings'
import UserEventsService from '../../../services/UserEventsService'
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'

export default {
  name: 'PopUpUploadPhotos',
  mixins: [popupAnimationMixin],
  components: {
    TextBodyExtraSmall,
    PopupBody,
    InputRadioButtonSquared,
    TextBodySmall,
    IconCompleteFilled,
    ButtonPrimary,
    PopupFooter,
    PopupTextSubHeading,
    PopupContent,
    LoadingBuffer,
    PopupTextHeading,
    PopupHead,
    PopupButtonClose,
    PopupTitle,
    Popup,
    FitnessImageImageUpload
  },
  props: {
    visible: { default: false }
  },
  data: function () {
    return {
      imageSubmit: true,
      uploadErrorMessage: this.$i18n.t('message["fitness-diary.error-upload"]'),
      contentClass: 'info-modal__content info-modal__content--hidden custom-modal-padding fd-upload-image-popup ',
      trainerEmail: '',
      hasAlreadyUploadedImages: false,
      hasErrorUpload: false,
      isImagesUploaded: false,
      readyToUpload: false,
      isSetPrivate: false,
      imagesUrl: this.$appConfig.imagesUrl,
      success: {
        image: this.$appConfig.imagesUrl + 'swap-success.svg',
        status: false
      },
      canUploadPublic: false,
      hasError: false,
      generalErrors: [],
      submited: false,
      isUploaded: {
        'front-image': false,
        'side-image': false,
        'back-image': false
      },
      invalidImages: {
        'front-image': '',
        'side-image': '',
        'back-image': ''
      },
      toUploaded: {
        'front-image': false,
        'side-image': false,
        'back-image': false
      },
      startUpload: {
        'front-image': false,
        'side-image': false,
        'back-image': false
      },
      payload: {
        name: 'add-photos'
      }
    }
  },
  computed: {
    isPrivate () {
      return !this.isSetPrivate ? 1 : 0
    },
    noCloseOnBackdrop () {
      return !(this.readyToUpload || this.isImagesUploaded)
    },
    hasInvalidImage () {
      return Object.values(this.invalidImages).some(msg => {
        return Boolean(msg)
      })
    },
    invalidImageError () {
      return Object.values(this.invalidImages).find(msg => {
        return Boolean(msg)
      })
    }
  },
  mounted () {
    let pService = new ProfileService()
    pService.setProfileData().then(() => {
      if (pService.getAge() >= 18) {
        this.canUploadPublic = true
      }
      this.trainerEmail = pService.trainer.company_name || ''
    })

    if (this.service().images['front-image'].getTodayImage() || this.service().images['back-image'].getTodayImage() || this.service().images['side-image'].getTodayImage()) {
      this.contentClass = this.contentClass + ' fd-upload-image-popup--with-new-replace'
      this.hasAlreadyUploadedImages = true
    }
  },
  methods: {
    ...mapGetters({
      service: 'fitnessDiaryStore/getService'
    }),
    setHasError (error) {
      this.hasError = error
    },
    doAction () {
    },
    changeIsSetPrivate () {
      this.isSetPrivate = !this.isSetPrivate
    },
    closePopup (e) {
      if (this.hasErrorUpload) {
        return
      }
      if (!this.readyToUpload || this.hasUploadedAll()) {
        if (e) {
          e.preventDefault()
        }
        this.closeAnimatedPopup(() => {
          this.$emit('close')
          // backdrop close event fire
          if (e && e.trigger === 'backdrop') {
            this.submitCloseEvent('JourneyPhoto', 'background')
          }
        })
      }
    },
    showTeamSeeImagesText () {
      return !flagValue('enable_fitness_diary_only_private_photo_upload', false)
    },
    hasUploadedAll () {
      return Object.keys(this.toUploaded).every((type) => {
        return this.toUploaded[type] === this.isUploaded[type]
      })
    },
    uploadedImageCount () {
      const images = Object.keys(this.toUploaded).filter((type) => {
        return this.toUploaded[type]
      })
      return images.length
    },
    isImageWaitingUpload (type) {
      return this.toUploaded[type] && !this.isUploaded[type]
    },
    uploadedImage (imageType) {
      this.isUploaded[imageType] = true
      if (this.hasUploadedAll() && !this.hasErrorUpload) {
        this.success.status = true
        this.isImagesUploaded = true
        let context = 'my_journey'
        if (this.$route.name === 'DashboardMain') {
          context = 'dashboard'
        }
        this.logEvent('Journey.AddedPhoto', {
          'no_of_photos ': this.uploadedImageCount(),
          'private': this.isPrivate,
          'context': context
        })
        setTimeout(() => {
          this.$emit('uploaded', {
            'no_of_photos ': this.uploadedImageCount(),
            'private': this.isPrivate,
            'context': context,
            isUploaded: this.isUploaded
          })
          this.closePopup()
          if (flagValue('fd_feedback_popup_show')) {
            UserEventsService.sendFitnessDiaryFeedBackPopupView()
          }
        }, 1500)
      } else if (!this.hasUploadedAll() && !this.hasErrorUpload) {
        this.setNextImageToUpload()
      }
    },
    setNextImageToUpload () {
      let imageType = Object.keys(this.toUploaded).find((type) => {
        return this.isImageWaitingUpload(type)
      })
      this.startUpload[imageType] = true
    },
    hasImageToUpload () {
      return Object.values(this.toUploaded).some((ele) => {
        return ele
      })
    },
    upload () {
      this.hasErrorUpload = false
      this.readyToUpload = this.hasImageToUpload()
      this.setNextImageToUpload()
    },
    toUploadedImage (imageType) {
      this.toUploaded[imageType] = true
      this.invalidImages[imageType] = ''
    },
    errorUpload (imageType, error) {
      this.uploadErrorMessage = error
      this.hasErrorUpload = true
      this.readyToUpload = false
      this.toUploaded[imageType] = true
    },
    invalidImage (type, message) {
      this.invalidImages[type] = message
    },
    removeUpload (imageType) {
      this.toUploaded[imageType] = false
    },
    enableImageSubmit () {
      this.imageSubmit = true
    },
    disableImageSubmit () {
      this.imageSubmit = false
    }
  }
}
</script>
