<template>
  <grid-col col="6" :class="componentClasses" v-on="$listeners">
    <icon-play-triangle/>
    <text-body-small :size="'sm2'" :lineHeight="'multi'">{{
      text
    }}</text-body-small>
  </grid-col>
</template>

<script>
import TextBodySmall from '../../../../global/typography/TextBodySmall'
import TextContent from '../../../../root/TextContent'
import GridCol from '../../../../global/grid/GridCol'
import IconPlayTriangle from '../../../../root/icons/IconPlayTriangle'

export default {
  name: 'ButtonMealRecipeVideo',
  components: {
    IconPlayTriangle,
    GridCol,
    TextContent,
    TextBodySmall
  },
  props: {
    text: {
      default: ''
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'pc-button-meal-recipe-video': true
      }
    }
  }
}
</script>
