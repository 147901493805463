<template>
  <div class="gc-input-coupon-code">
    <div class="gc-input-coupon-code__input-wrapper">
      <text-input
        class="gc-input-coupon-code__input"
        :placeholder="'XXX'"
        :disabled="isCouponCodeValid"
        :min="3"
        :max="20"
        v-model.trim="couponCodeInput"
        @change="requestValidation"
        @input="calculateInputWidth"
      />
      <span class="gc-input-coupon-code__input-span" ref="span"></span>
      <icon-complete-filled
        v-if="isCouponCodeValid"
        :style="{left: this.inputWidth}"
        class="gc-input-coupon-code__icon-complete"
        :size="'sm4'"
      />
      <icon-close
        v-if="isCouponCodeValid"
        class="gc-input-coupon-code__icon-close"
        :size="'sm1'"
        @click="closeButtonClicked()"
      />
      <button-custom
        v-if="couponCodeInput.length > 0 && !isCouponCodeValid"
        class="gc-input-coupon-code__button-custom"
        @click="couponButtonClicked()"
        :submit-type="'button'"
        :text="$t('message[\'sign-up.form.coupon-apply\']')"
        :type="'outline'"
      />
    </div>
    <text-body-extra-small />
  </div>
</template>

<script>
import IconClose from '../../../root/icons/IconClose'
import IconCompleteFilled from '../../../root/icons/IconCompleteFilled'
import ButtonCustom from '../../buttons/ButtonCustom'
import TextBodyExtraSmall from '../../typography/TextBodyExtraSmall'
import TextInput from '../TextInput'

export default {
  name: 'InputCouponCode',
  components: {
    TextInput,
    IconCompleteFilled,
    IconClose,
    ButtonCustom,
    TextBodyExtraSmall
  },
  data: function () {
    return {
      couponCodeInput: '',
      inputWidth: 0
    }
  },
  props: {
    value: {
      default: ''
    },
    isCouponCodeValid: {
      default: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function () {
        this.couponCodeInput = this.value
      }
    }
  },
  methods: {
    requestValidation () {

    },
    couponButtonClicked () {
      this.$emit('request-validation', this.couponCodeInput)
    },
    closeButtonClicked () {
      this.couponCodeInput = ''
      this.$emit('close')
    },
    calculateInputWidth () {
      this.$emit('input', this.couponCodeInput)
      let spanEl = this.$refs.span
      spanEl.innerText = this.couponCodeInput
      this.inputWidth = (spanEl.offsetWidth + -10) + 'px'
    }
  }
}
</script>
