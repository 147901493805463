<template>
  <div
    :class="componentClasses"
    v-on="$listeners"
    @click="clicked()">
    <icon-save-filled
      v-if="state==='active'"
      :size="'sm4'"
      class="gc-meal__action-icon"
    /><icon-save-linear
      v-else
      :size="'sm4'"
      class="gc-meal__action-icon"
    />
    <text-body-small v-if="showText" class="gc-meal__action-text" :lineHeight="'multi'">
      {{ text }}
    </text-body-small>
  </div>
</template>

<script>
import IconDislike from '../../root/icons/IconDislike'
import TextBodySmall from '../typography/TextBodySmall'
import IconSaveFilled from '../../root/icons/IconSaveFilled'
import IconSaveLinear from '../../root/icons/IconSaveLinear'

export default {
  name: 'ActionMealSave',
  components: {
    IconSaveLinear,
    IconSaveFilled,
    IconDislike,
    TextBodySmall
  },
  props:
    {
      state: {
        type: String,
        default: ''
      },
      showText: {
        type: Boolean,
        default: true
      },
      limitText: {
        type: Boolean,
        default: false

      }
    },
  computed: {
    componentClasses: function () {
      return {
        'gc-meal__action': true,
        'gc-meal__action-save': true,
        'gc-meal__action-disabled': this.state === 'disabled',
        'gc-meal__action-onTap': this.state === 'onTap',
        'gc-meal__action-active': this.state === 'active'
      }
    },
    text: function () {
      let text = this.$i18n.t('message["meal-plan.save"]')
      if (this.state === 'active') {
        return this.$i18n.t('message["meal-plan.saved"]')
      }if (this.state === 'activating') {
        return this.$i18n.t('message["meal-plan.saving"]')
      }
      if (this.limitText) {
        text = text.substring(0, 2) + '...'
      }
      return text
    }
  },
  methods: {
    clicked () {
      this.$emit('clicked')
    }
  }
}
</script>
<style scoped>
</style>
