<template>
    <div class="gc-page-member-connect-input">
        <text-body-small line-height="multi" class="gc-page-member-connect-input__description">
            {{$t('message[\'member-connect.connect-description\']')}}
        </text-body-small>
        <text-input class="gc-page-member-connect-input__input" :placeholder="$t('message[\'member-connect.enter-connect-code\']')"
                    v-model="connectCode"
                    :error-message="errorMessage"></text-input>
        <alert-dotted class="gc-page-member-connect-input__alert">
            <text-body-small
            :line-height="'multi'"
                    v-html="$t('message[\'member-connect.to-be-connect-info-note-generic\']')"
            ></text-body-small>
        </alert-dotted>
    </div>
</template>

<script>
import TextBodySmall from '../../typography/TextBodySmall'
import TextInput from '../../inputs/TextInput'
import AlertDotted from '../../alerts/AlertDotted'

export default {
  name: 'PageMemberConnectInput',
  props: {
    errorMessage: {
      default: ''
    }
  },
  components: {
    TextBodySmall,
    TextInput,
    AlertDotted
  },
  watch: {
    connectCode () {
      this.emitValue()
    }
  },
  data () {
    return {
      connectCode: ''
    }
  },
  methods: {
    emitValue () {
      this.$emit('change', this.connectCode)
    }
  }
}
</script>

<style scoped>

</style>
