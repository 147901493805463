import HabitEntry from './HabitEntry'

export default class HabitDayEntries {
  date = null
  entries = []
  constructor (date, habits) {
    this.date = date

    this.entries = []
    habits.forEach(habit => {
      this.entries.push(new HabitEntry(habit, date))
    })
  }
  getDate () {
    return this.date
  }

  getEntries () {
    return this.entries
  }

  isAllLogged () {
    return this.entries.every(entry => {
      return entry.isLogged()
    })
  }

  isSomeLogged () {
    return this.entries.some(entry => {
      return entry.isLogged()
    })
  }

  /**
   * delete the habit entry
   * @param id - habit entry ID
   * @returns {Promise<T>}
   */
  deleteHabitEntry (id) {
    const entry = this.findHabitEntry(id)
    if (entry) {
      return entry.deleteEntry()
    } else {
      throw new Error('Habit Entry not found')
    }
  }

  /**
   * find habbit entry by ID
   * @param entryID
   * @returns {*}
   */
  findHabitEntry (entryID) {
    const entries = this.getEntries()
    return entries.find((entry) => this.__isEntryIDEqualID(entry, entryID))
  }

  __isEntryIDEqualID (entry, entryID) {
    return entry.entry.id === entryID
  }

  setEntry (habitID, entry) {
    let currentEntry = this.findEntryByHabit(habitID)

    if (currentEntry) {
      currentEntry.setEntry(entry)
    } else {
      throw new Error('No Habit Entry Found')
    }
  }

  findEntryByHabit (habitID) {
    let currentEntry
    currentEntry = this.entries.find(entryItem => {
      return entryItem.getHabitID() === habitID
    })
    return currentEntry
  }

  hasLoggedEntry () {
    return this.entries.some(habitEntry => {
      return habitEntry.isEntryEmpty()
    })
  }
}
