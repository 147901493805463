<template>
  <div :class="componentClasses" @click="clicked()">
    <svg
      width="106"
      height="24"
      viewBox="0 0 106 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_11008_189099)">
        <path
          d="M103.173 0H2.63587C1.93679 0 1.26635 0.277398 0.772027 0.771171C0.277707 1.26494 0 1.93464 0 2.63294L0 21.3671C0 22.0654 0.277707 22.7351 0.772027 23.2288C1.26635 23.7226 1.93679 24 2.63587 24H103.173C103.923 24 104.642 23.7025 105.172 23.173C105.702 22.6435 106 21.9253 106 21.1765V2.82353C106 2.07468 105.702 1.35651 105.172 0.826993C104.642 0.297478 103.923 0 103.173 0V0ZM105.293 21.1765C105.293 21.7381 105.07 22.2767 104.672 22.6739C104.275 23.071 103.736 23.2941 103.173 23.2941H2.63587C2.12478 23.2923 1.63517 23.0886 1.27377 22.7276C0.912381 22.3666 0.708529 21.8776 0.706667 21.3671V2.63294C0.708529 2.12243 0.912381 1.63335 1.27377 1.27236C1.63517 0.911368 2.12478 0.707742 2.63587 0.705882H103.173C103.736 0.705882 104.275 0.928991 104.672 1.32613C105.07 1.72326 105.293 2.26189 105.293 2.82353V21.1765Z"
          fill="black"
        />
        <path
          d="M12.0626 7.93359H9.02393V15.5289H10.3807V12.5924H12.0626C13.7586 12.5924 14.8186 11.7736 14.8186 10.263C14.8186 8.75242 13.7586 7.93359 12.0626 7.93359ZM11.9919 11.463H10.3807V9.13359H12.0131C12.9883 9.13359 13.5042 9.55006 13.5042 10.2983C13.5042 11.0465 12.9671 11.4277 12.0131 11.4277L11.9919 11.463Z"
          fill="black"
        />
        <path
          d="M17.7374 9.883C17.3659 9.8787 16.9976 9.95208 16.6562 10.0984C16.3148 10.2447 16.0077 10.4608 15.7549 10.7326C15.502 11.0045 15.3088 11.3262 15.1878 11.6771C15.0668 12.028 15.0205 12.4003 15.0521 12.7701C15.019 13.1419 15.0637 13.5165 15.1834 13.8701C15.3031 14.2237 15.4952 14.5485 15.7475 14.8239C15.9998 15.0993 16.3066 15.3192 16.6486 15.4697C16.9906 15.6202 17.3602 15.6979 17.7339 15.6979C18.1076 15.6979 18.4772 15.6202 18.8192 15.4697C19.1612 15.3192 19.4681 15.0993 19.7203 14.8239C19.9726 14.5485 20.1647 14.2237 20.2844 13.8701C20.4041 13.5165 20.4488 13.1419 20.4157 12.7701C20.4483 12.4006 20.4031 12.0285 20.2829 11.6776C20.1627 11.3268 19.9702 11.005 19.7179 10.7329C19.4655 10.4609 19.1589 10.2448 18.8178 10.0984C18.4767 9.95197 18.1087 9.87861 17.7374 9.883ZM17.7374 14.5912C16.8753 14.5912 16.3241 13.8854 16.3241 12.7701C16.3241 11.6548 16.8612 10.9489 17.7374 10.9489C18.6137 10.9489 19.1508 11.6548 19.1508 12.7701C19.1508 13.8854 18.5925 14.5842 17.7374 14.5842V14.5912Z"
          fill="black"
        />
        <path
          d="M25.9913 13.6593L24.9949 9.97461H23.9491L22.9597 13.6593L21.9563 9.97461H20.6489L22.3237 15.537H23.4261L24.4437 11.8946L25.4613 15.537H26.5637L28.2385 9.97461H26.9807L25.9913 13.6593Z"
          fill="black"
        />
        <path
          d="M31.0935 9.88281C30.7268 9.88961 30.3654 9.97077 30.0311 10.1214C29.6968 10.2719 29.3966 10.4888 29.1488 10.7588C28.901 11.0288 28.7107 11.3463 28.5894 11.692C28.4682 12.0377 28.4185 12.4044 28.4435 12.7699C28.4139 13.141 28.4623 13.5142 28.5854 13.8655C28.7085 14.2169 28.9036 14.5388 29.1584 14.8105C29.4131 15.0823 29.7218 15.2979 30.0648 15.4436C30.4077 15.5894 30.7774 15.662 31.15 15.6569C31.6624 15.6845 32.1704 15.5509 32.6027 15.2748C33.035 14.9987 33.3698 14.5942 33.5598 14.1181L32.5068 13.6805C32.4242 13.9532 32.2512 14.1898 32.0162 14.3515C31.7812 14.5131 31.4982 14.5901 31.2136 14.5699C31.0109 14.5747 30.8093 14.5384 30.6211 14.4631C30.4329 14.3879 30.2619 14.2752 30.1185 14.132C29.9751 13.9888 29.8624 13.818 29.787 13.63C29.7117 13.4419 29.6753 13.2406 29.6801 13.0381H33.5809V12.6146C33.5951 11.0899 32.7329 9.88281 31.0935 9.88281ZM29.7296 12.0922C29.7792 11.7681 29.9446 11.4729 30.1954 11.2613C30.4462 11.0497 30.7653 10.9362 31.0935 10.9416C31.2494 10.9285 31.4064 10.9486 31.5539 11.0007C31.7015 11.0529 31.8362 11.1358 31.9492 11.244C32.0622 11.3521 32.1508 11.4831 32.2092 11.6281C32.2675 11.7732 32.2943 11.929 32.2877 12.0852L29.7296 12.0922Z"
          fill="black"
        />
        <path
          d="M35.8208 10.8001V9.97421H34.5488V15.5295H35.8208V12.6142C35.8095 12.4259 35.8383 12.2373 35.9053 12.0608C35.9723 11.8844 36.076 11.7242 36.2096 11.5908C36.3432 11.4574 36.5036 11.3538 36.6802 11.2868C36.8568 11.2199 37.0456 11.1911 37.2342 11.2024C37.3894 11.1915 37.5453 11.1915 37.7006 11.2024V9.93185C37.6016 9.93185 37.4886 9.93185 37.3402 9.93185C37.0302 9.91581 36.7222 9.98913 36.4528 10.1431C36.1835 10.297 35.9641 10.5251 35.8208 10.8001Z"
          fill="black"
        />
        <path
          d="M40.6194 9.88281C40.2527 9.88961 39.8913 9.97077 39.557 10.1214C39.2227 10.2719 38.9225 10.4888 38.6747 10.7588C38.4269 11.0288 38.2365 11.3463 38.1153 11.692C37.9941 12.0377 37.9444 12.4044 37.9694 12.7699C37.9398 13.141 37.9882 13.5142 38.1113 13.8655C38.2344 14.2169 38.4295 14.5388 38.6843 14.8105C38.939 15.0823 39.2477 15.2979 39.5907 15.4436C39.9336 15.5894 40.3032 15.662 40.6759 15.6569C41.1835 15.6785 41.6853 15.542 42.112 15.2663C42.5386 14.9907 42.8689 14.5894 43.0574 14.1181L41.9691 13.7016C41.8865 13.9744 41.7135 14.211 41.4785 14.3726C41.2435 14.5342 40.9605 14.6113 40.6759 14.591C40.4773 14.5892 40.2811 14.548 40.0987 14.4698C39.9162 14.3915 39.7511 14.2778 39.613 14.1353C39.475 13.9928 39.3666 13.8243 39.2943 13.6395C39.222 13.4548 39.1872 13.2576 39.1919 13.0593H43.1068V12.6358C43.1068 11.0899 42.2306 9.88281 40.6194 9.88281ZM39.2555 12.0922C39.3064 11.7706 39.4715 11.478 39.7207 11.268C39.9698 11.0581 40.2863 10.9447 40.6123 10.9487C40.7682 10.9355 40.9252 10.9557 41.0727 11.0078C41.2203 11.0599 41.355 11.1428 41.468 11.251C41.581 11.3592 41.6696 11.4902 41.728 11.6352C41.7863 11.7802 41.8131 11.936 41.8066 12.0922H39.2555Z"
          fill="black"
        />
        <path
          d="M47.7426 10.5877C47.5465 10.3579 47.3011 10.1751 47.0246 10.0529C46.7481 9.93066 46.4477 9.87221 46.1455 9.88183C44.5838 9.88183 43.6934 11.1877 43.6934 12.7689C43.6934 14.3501 44.5838 15.6559 46.1455 15.6559C46.4479 15.6671 46.7488 15.6094 47.0256 15.4871C47.3023 15.3648 47.5475 15.1811 47.7426 14.9501V15.5289H49.0146V7.93359H47.7426V10.5877ZM47.7426 12.9524C47.7711 13.1537 47.7557 13.3587 47.6976 13.5535C47.6394 13.7483 47.5398 13.9283 47.4056 14.0811C47.2714 14.2339 47.1058 14.356 46.92 14.4389C46.7342 14.5219 46.5327 14.5638 46.3292 14.5618C45.4035 14.5618 44.9159 13.8559 44.9159 12.783C44.9159 11.7101 45.4106 11.0042 46.3292 11.0042C47.1136 11.0042 47.7426 11.5759 47.7426 12.6207V12.9524Z"
          fill="black"
        />
        <path
          d="M56.0455 9.88183C55.7377 9.86838 55.4308 9.92492 55.148 10.0472C54.8652 10.1695 54.614 10.3543 54.4131 10.5877V7.93359H53.1411V15.5289H54.4131V14.943C54.6103 15.1734 54.8569 15.3565 55.1346 15.4787C55.4123 15.6009 55.714 15.6591 56.0172 15.6489C57.5719 15.6489 58.4623 14.3359 58.4623 12.7618C58.4623 11.1877 57.6002 9.88183 56.0455 9.88183ZM55.8264 14.5407C55.623 14.5427 55.4214 14.5007 55.2357 14.4178C55.0499 14.3348 54.8842 14.2127 54.75 14.0599C54.6158 13.9071 54.5163 13.7272 54.4581 13.5324C54.4 13.3376 54.3846 13.1325 54.4131 12.9312V12.5995C54.4131 11.5548 55.0067 10.983 55.8264 10.983C56.7451 10.983 57.2398 11.6889 57.2398 12.7618C57.2398 13.8348 56.7098 14.5407 55.8264 14.5407Z"
          fill="black"
        />
        <path
          d="M61.4307 13.8781L60.0668 9.97461H58.7241L60.7735 15.3323L60.5615 15.8546C60.5254 16.0336 60.4214 16.1916 60.2712 16.2956C60.121 16.3996 59.9363 16.4414 59.7559 16.4123C59.6147 16.4226 59.473 16.4226 59.3319 16.4123V17.4781C59.5026 17.5035 59.6751 17.5153 59.8477 17.5134C60.2889 17.5455 60.7274 17.4217 61.0864 17.1636C61.4455 16.9056 61.7024 16.5297 61.8123 16.1017L64.1019 10.017H62.7663L61.4307 13.8781Z"
          fill="black"
        />
        <path
          d="M88.3331 8.77347C88.0541 8.77186 87.7775 8.82528 87.5192 8.93066C87.2609 9.03605 87.026 9.19132 86.8279 9.38759L86.729 8.90053H85.04V18.0205L86.9622 17.6041V15.3946C87.3445 15.7144 87.8274 15.8894 88.326 15.8888C89.697 15.8888 90.9548 14.7664 90.9548 12.2817C90.9548 10.0088 89.6828 8.77347 88.3331 8.77347ZM87.8738 14.1594C87.7052 14.1691 87.5365 14.1412 87.3801 14.0777C87.2236 14.0142 87.0833 13.9167 86.9692 13.7923V10.8911C87.0838 10.7622 87.2257 10.6604 87.3846 10.5932C87.5436 10.5261 87.7155 10.4952 87.8879 10.5029C88.5946 10.5029 89.0751 11.3005 89.0751 12.3241C89.0751 13.3476 88.5875 14.1594 87.8738 14.1594Z"
          fill="black"
        />
        <path
          d="M94.5024 8.77344C92.6509 8.77344 91.5273 10.3687 91.5273 12.3805C91.5273 14.7593 92.8559 15.9664 94.7497 15.9664C95.4889 15.9784 96.2188 15.801 96.8697 15.4511V13.8628C96.2786 14.1608 95.6238 14.311 94.9617 14.3005C94.1985 14.3005 93.5484 14.0252 93.4495 13.1005H97.2513C97.2513 12.9946 97.2513 12.5781 97.2513 12.3946C97.3149 10.3687 96.3539 8.77344 94.5024 8.77344ZM93.4636 11.6464C93.4636 10.757 94.0077 10.3828 94.4883 10.3828C94.9688 10.3828 95.4776 10.757 95.4776 11.6464H93.4636Z"
          fill="black"
        />
        <path
          d="M79.8533 9.43003L79.7332 8.85121H78.0938V15.7053H79.9876V11.0606C80.1587 10.8532 80.389 10.7029 80.6479 10.6297C80.9067 10.5565 81.1818 10.564 81.4363 10.6512V8.85121C81.1472 8.73808 80.8266 8.73449 80.535 8.84111C80.2435 8.94773 80.001 9.15719 79.8533 9.43003Z"
          fill="black"
        />
        <path
          d="M70.2851 10.9126C70.2851 10.602 70.5395 10.482 70.9423 10.482C71.6099 10.4963 72.2642 10.6706 72.8503 10.9902V9.13373C72.2444 8.88899 71.5959 8.76664 70.9423 8.77373C69.3806 8.77373 68.3418 9.60667 68.3418 11.0043C68.3418 13.1926 71.2674 12.8396 71.2674 13.7784C71.2674 14.1455 70.9565 14.2655 70.5607 14.2655C69.8141 14.2219 69.0881 14.0043 68.4407 13.6302V15.529C69.1079 15.8263 69.8302 15.9802 70.5607 15.9808C72.1578 15.9808 73.2602 15.169 73.2602 13.7502C73.2249 11.3784 70.2851 11.802 70.2851 10.9126Z"
          fill="black"
        />
        <path
          d="M75.811 7.22852L73.9383 7.63793V13.9415C73.9286 14.2071 73.9733 14.4719 74.0696 14.7197C74.1659 14.9676 74.3117 15.1932 74.4982 15.3828C74.6847 15.5724 74.908 15.722 75.1544 15.8225C75.4007 15.923 75.665 15.9723 75.9311 15.9673C76.3954 15.9881 76.8579 15.8986 77.2808 15.7062V14.1179C77.0335 14.2238 75.8251 14.5838 75.8251 13.412V10.5885H77.2808V8.93675H75.8251L75.811 7.22852Z"
          fill="black"
        />
        <path
          d="M82.1499 8.25909L84.0791 7.85674V6.33203L82.1499 6.73438V8.25909Z"
          fill="black"
        />
        <path
          d="M84.0791 8.90039H82.1499V15.7051H84.0791V8.90039Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_11008_189099">
          <rect width="106" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconPoweredByStripe',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-powered-by-stripe': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>
