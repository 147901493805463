import { render, staticRenderFns } from "./BadgeImportant.vue?vue&type=template&id=73bb50d9&scoped=true"
import script from "./BadgeImportant.vue?vue&type=script&lang=js"
export * from "./BadgeImportant.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73bb50d9",
  null
  
)

export default component.exports