import BaseAPI from '../BaseAPI.js'
import axios from 'axios'

export default class extends BaseAPI {
  _resource ='pair'
  _parentResources = ['meal-plans']

  unpair () {
    return axios.post(this._baseURL + this._parentResources + '/unpair')
  }
}
