<template>
    <div :class="componentClasses" @click="clicked()">
        <div class="gc-input-radio-button-squared__circle"></div>
        <icon-tick :size="'sm1'"/>
        <icon-dislike :size="'sm1'"/>
    </div>
</template>

<script>
import IconTick from '../../root/icons/IconTick'
import IconDislike from '../../root/icons/IconDislike'

export default {
  name: 'InputRadioButtonSquared',
  components: {
    IconTick,
    IconDislike
  },
  props: {
    disabled: {
      default: false
    },
    selected: {
      default: false
    },
    semiSelected: {
      default: false
    },
    dislike: {
      default: false
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-input-radio-button-squared': true,
        'gc-input-radio-button-squared--disabled': this.disabled,
        'gc-input-radio-button-squared--dislike': this.dislike,
        'gc-input-radio-button-squared--selected': this.selected,
        'gc-input-radio-button-squared--semi-selected': this.semiSelected
      }
    }
  },
  methods: {
    clicked () {
      if (!this.disabled && !this.dislike) {
        this.$emit('click')
        if (!this.selected) {
          this.selected = true
        } else {
          this.selected = false
        }
      }
    }
  }
}
</script>
