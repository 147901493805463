/**
 * Dispatches a "DOMContentLoaded" event on the window's document.
 *
 * This function can be used to simulate the firing of the "DOMContentLoaded" event,
 * which is normally fired by the browser when the initial HTML document has been completely loaded and parsed.
 *
 * The dispatched event is configured to bubble up through the DOM and to be cancelable.
 */
export function dispatchDOMContentLoaded(){

  window.document.dispatchEvent(new Event("DOMContentLoaded", {
    bubbles: true
  }));
}
