<template>
    <page :header="header" class="gc-page-activity-level">
        <validation-observer ref="form" v-slot="{handleSubmit, invalid}">
          <page-container>
            <div class="gc-page-activity-level__header">
                <text-heading-4
                    class="gc-page-activity-level__title text-center"
                    weight="extra-bold"
                    lineHeight="multi">{{title.main}}</text-heading-4>
                <div class="gc-page-activity-level__sub-title text-center">
                  <text-body-extra-small class="gc-page-activity-level__info-link" weight="medium" lineHeight="multi" @click="showActivityPopup">
                      {{title.sub}}
                      <icon-info size="sm1" class="gc-page-activity-level__info-button"/></text-body-extra-small>
                </div>
            </div>
              <div class="gc-page-activity-level__content">
                  <form id="form-input-activity-level"
                      class="gc-page-activity-level__form"
                      @submit.prevent="handleSubmit(nextComponent)">
                          <validation-provider v-slot="{ errors }" rules="required|excluded:0" vid="activityLevel" name="activity level">
                              <button-choice
                                  type="button"
                                  class="gc-page-activity-level__input"
                                  v-for="option in options"
                                  :key="option.key"
                                  :text="option.value"
                                  v-model="activityLevel"
                                  @click="setActivityLevel(option.key)"
                                  :selected="(activityLevel===option.key)"></button-choice>
                                  <text-body-extra-small class="gc-page-progress-form__input-error activity-level-error-msg text-center"  lineHeight="multi">{{errors[0]}}</text-body-extra-small>
                          </validation-provider>
                  </form>
              </div>
          </page-container>
          <page-footer :form="'form-input-activity-level'" :validated="invalid"></page-footer>
        </validation-observer>
    </page>
</template>

<script>
import FormBaseComponent from '../../layout/template-1/global/widgets/forms/FormBaseComponent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ButtonPrimary from '../buttons/ButtonPrimary'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import TextHeading4 from '../typography/TextHeading4'
import PageContainer from './page-sub-components/PageContainer'
import Page from './PageForm'
import ButtonChoice from '../buttons/ButtonChoice'
import PageFooter from './page-sub-components/PageFormFooter'
import IconInfo from '../../root/icons/IconInfo'

export default {
  name: 'PageActivityLevel',
  extends: FormBaseComponent,
  components: {
    Page,
    PageContainer,
    TextHeading4,
    ButtonPrimary,
    TextBodyExtraSmall,
    ValidationObserver,
    ValidationProvider,
    ButtonChoice,
    PageFooter,
    IconInfo
  },
  props: ['infoText'],
  data: function () {
    return {
      header: {
        isTransparent: false,
        isFixed: false,
        show: false,
        left: '',
        right: '',
        rightBlink: false,
        modifiers: [],
        mainTitle: '',
        subTitle: ''
      },
      activityLevel: '',
      activityPopupVisibility: false,
      instructionsPopup: {
        instructions: [],
        notes: '',
        title: ''
      }
    }
  },
  mounted () {
    if (typeof this.inputValue === 'number') {
      // in progress update page, option.key(number) is saved as the inputValue while in onboarding page option.value(string) is saved as the inputValue
      this.activityLevel = this.inputValue
    } else {
      let selectedOption = this.options.find(option => option.value === this.inputValue)
      if (selectedOption) {
        this.activityLevel = selectedOption.key
      }
    }
    this.hideLoading()

    this.instructionsPopup.instructions = [this.infoText]
    this.instructionsPopup.title = this.title.main
    // calling to setContent function in the pageStore
    this.setInstructionsPopupContent(this.instructionsPopup)
  },
  methods: {
    showActivityPopup () {
      this.viewInstructionsPopup()
    },
    hideActivityPopup () {
      this.activityPopupVisibility = false
    },
    setActivityLevel (selectedActivityLevel) {
      this.activityLevel = selectedActivityLevel
    },
    nextComponent () {
      const inputObj = { key: this.inputKey, value: this.activityLevel }
      this.$emit('submit', inputObj)
    }
  }
}
</script>
