import CommunityAPI from '../custom-api/CommunityAPI'
import DashBoardService from '@/services/DashboardService'
import {checkIsMember} from '@/helpers/user'

export default class CommunityService {
  list = null

  constructor () {
    if (!CommunityService.instance) {
      CommunityService.instance = this
    }
    return CommunityService.instance
  }

  initCommunities (params) {
    let api = new CommunityAPI()
    return api.initUser(params).then(data => data.data)
  }

  getMeUser () {
    let api = new CommunityAPI()
    return api.getMeUser().then(data => data.data)
  }

  loadCommunities () {
    try {
      let api = new CommunityAPI()
      return api.getFeeds().then(data => data.data).then(data => {
        this.list = data.feeds
        return this.list
      })
    } catch (error) {
      return []
    }
  }

  getCommunities () {
    if (this.list === null) {
      return this.loadCommunities()
    } else {
      return Promise.resolve(this.list)
    }
  }

  async getActiveCommunities () {
    const dashBoardService = new DashBoardService()
    if (!dashBoardService.isCommunityEnabled()) {
      return []
    }
    const communityType = this.list.flatMap(item => item.type)
    if (communityType.includes('all_members') && this.list[0].enabled) {
      return this.list
    } else if (communityType.includes('plan_based')) {
      if (!checkIsMember()) {
        return this.list
      }
      try {
        const communityPlans = this.list.flatMap(item => item.pricingPlans)
        const dashBoardService = new DashBoardService()
        const pricingPlans = []
        const userData = await dashBoardService.getUserData()
        userData.subscriptions.some(subscription => {
          if (!['expired', 'cancelled'].includes(subscription.status)) {

            pricingPlans.push((subscription.pricing_plan_uuid || subscription.plan_uuid))
          }
        })
        const commonPlans = communityPlans.filter(plan => pricingPlans.includes(plan))
        const activeCommunityList = this.list.filter(community => {
          return community.pricingPlans.some(plan => commonPlans.includes(plan)) && community.enabled
        })
        return activeCommunityList
      } catch (error) {
        return []
      }
    }
    return []
  }

  getNotifications (limit = 20, offset = 0) {
    return (new CommunityAPI()).getNotifications(limit, offset).then(data => data.data)
  }

  notificationRead (id) {
    return (new CommunityAPI()).setNotificationRead(id)
  }

  setNotificationsAsSeen () {
    return (new CommunityAPI()).setAllNotificationsSeen()
  }

  setNotificationsAsRead () {
    return (new CommunityAPI()).setAllNotificationsRead()
  }

  async isActiveCommunity (id) {
    if (!this.list) {
      return false
    } else {
      const activeComs = await this.getActiveCommunities()
      if (activeComs) {
        return activeComs.some(link => link.id === id)
      }

    }
  }
}
