<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
    <path d="M13.2803 11.0303C13.5732 10.7374 13.5732 10.2626 13.2803 9.96967L9.53032 6.21966C9.23744 5.92677 8.76255 5.92677 8.46966 6.21966L4.71965 9.96967C4.42676 10.2626 4.42676 10.7374 4.71965 11.0303C5.01254 11.3232 5.48743 11.3232 5.78031 11.0303L8.99999 7.81065L12.2197 11.0303C12.5126 11.3232 12.9874 11.3232 13.2803 11.0303Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C13.9705 18 18 13.9705 18 9C18 4.02954 13.9705 1.66596e-07 9 1.07324e-07C4.02954 4.80518e-08 1.66596e-07 4.02954 1.07324e-07 9C4.80518e-08 13.9705 4.02954 18 9 18ZM9 16.5C4.85796 16.5 1.50001 13.142 1.50001 9C1.50001 4.85796 4.85796 1.50001 9 1.50001C13.142 1.50001 16.5 4.85796 16.5 9C16.5 13.142 13.142 16.5 9 16.5Z" fill="black"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="18" height="18" fill="white"/>
    </clipPath>
    </defs>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconUpArrowFilled',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-up-arrow-filled': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
