<template>
    <div :class="componentClasses" >

        <div class="pc-input-shopping-list-group-ingredients-item__left-area">
          <image-base
            :default-src="defaultImage"
            :src="image"
            class="pc-input-shopping-list-group-ingredients-item__image"
            />
          <div class="pc-input-shopping-list-group-ingredients-item__info">
            <text-body-small
              class="pc-input-shopping-list-item__title"
              :line-height="'multi'"
            >
              {{ name }}
            </text-body-small>
            <text-body-extra-small
              class="pc-input-shopping-list-item__sub-title"
              :line-height="'multi'"
            >
              {{ itemTotalQuantityWithUnit() }}
            </text-body-extra-small>
          </div>

        </div>

        <div class="pc-input-shopping-list-group-ingredients-item__right-area">
          <input-check-box-squared
            @click="dislike"
            :id="id"
            v-model="inputValue"
            class="pc-input-shopping-list-group-ingredients-item__radio-button"
          />
        </div>
    </div>
</template>
<script>
import TextBodySmall from '../../../../../../global/typography/TextBodySmall'
import TextBodyExtraSmall from '../../../../../../global/typography/TextBodyExtraSmall'
import InputCheckBoxSquared from '../../../../../../global/inputs/InputCheckBoxSquared'
import ImageBase from '../../../../../../root/Image'
export default {
  name: 'InputGroupIngredientsItem',
  props: {
    value: {
      default: []
    },
    id: {},
    name: {},
    unit: {},
    quantity: {},
    image: {},
    count: {
      default: 1
    }
  },
  components: {
    ImageBase,
    TextBodySmall,
    TextBodyExtraSmall,
    InputCheckBoxSquared
  },
  data () {
    return {
      inputValue: [],
      defaultImage: this.$appConfig.imagesUrl + 'mealplan/default-food-image.jpg'
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function () {
        this.inputValue = this.value
      }
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'pc-input-shopping-list-group-ingredients-item': true
      }
    }
  },
  methods: {
    itemTotalQuantityWithUnit () {
      const totalQty = this.quantity * this.count
      if (parseInt(totalQty) === parseFloat(totalQty)) {
        return totalQty.toFixed(0) + ' ' + this.unit
      } else {
        return totalQty.toFixed(2) + ' ' + this.unit
      }
    },
    getID () {
      return this.id
    },
    dislike () {
      this.$emit('input', this.inputValue)
    }

  }
}
</script>
