<template>
  <div class="card-timer card-timer--with-stop">
    <div>
      <div class="app-timer d-flex">
        <transition>
          <a class="set-time app-timer__left">
            <span class="title app-timer__header" v-html="header" v-if="state==='pending'"></span>
            <div   v-bind:class="{'time-active' : state==='started'||state==='paused'}">
              <span class="sub-title" id="time" v-html="time"></span>
              <span class="with-sub-title">{{$t('message["general.time-minutes-short"]')}}</span>
            </div>
          </a>
        </transition>
        <a class="app-timer__right text-right">
          <span
            alt="play"
            class="timer__btn-lg"
            v-if="state==='paused'||state==='pending'"
            :src="imagePlay"
            @click="resume"
          >

            <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.2564 0C11.33 0 0 11.215 0 25C0 38.785 11.33 50 25.2564 50C39.1828 50 50.5128 38.785 50.5128 25C50.5128 11.215 39.1828 0 25.2564 0ZM38.3586 25.6883L19.8372 38.1883C19.695 38.2842 19.5291 38.3333 19.3632 38.3333C19.2285 38.3333 19.093 38.3017 18.9701 38.2367C18.694 38.0925 18.5214 37.8092 18.5214 37.5V12.5C18.5214 12.1908 18.694 11.9075 18.9701 11.7633C19.2462 11.6183 19.5796 11.6383 19.8381 11.8117L38.3594 24.3117C38.5884 24.4667 38.7265 24.7242 38.7265 25C38.7265 25.2758 38.5884 25.5333 38.3586 25.6883Z" fill="#2FC417"/>
</svg>

          </span>
          <span :src="imagePause" @click="pause()" class="timer__btn-lg"  v-if="state==='started'" >
                        <svg
                          fill="none"
                          height="50"
                          viewBox="0 0 50 50"
                          width="51"
                          xmlns="http://www.w3.org/2000/svg"
                        >
              <path
                d="M25 0C11.2152 0 0 11.2152 0 25C0 38.7848 11.2152 50 25 50C38.7848 50 50 38.7848 50 25C50 11.2152 38.7848 0 25 0ZM21.875 34.375H15.625V15.625H21.875V34.375ZM34.375 34.375H28.125V15.625H34.375V34.375Z"
                fill="#3D3D3D"
              />
            </svg>
          </span>
        </a>
      </div>
    </div>
    <transition>
      <div>
        <div class="row">
          <div class="col text-center" v-if="state==='started'||state==='paused'">
            <a
              class="timer__play-btn timer--bottom-text"
              v-on:click="stop()"
            >{{$t('message["general.stop"]')}}</a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style>
</style>

<script>
import { Howl, Howler } from 'howler'

export default {
  name: 'stop-watch',
  props: {
    setID: {
      default: -1
    },
    play: {
      default: false
    },
    startValue: {
      default: 0
    },
    header: {
      default: ''
    }
  },
  data: function () {
    return {
      sounds: {
        started: null,
        ended: null
      },
      startSetID: 0,
      state: 'pending',
      startTime: Date.now(),
      currentTime: Date.now(),
      interval: null,
      imagePath: this.$appConfig.imagesUrl + 'timer/',
      imagePlay: '',
      imagePause: '',
      imageRestart: '',
      pauseAt: null,
      pauseRelasedAt: null
    }
  },
  mounted: function () {
    this.startSetID = this.setID
    if (this.startValue !== null && parseInt(this.startValue) > 0) {
      this.state = 'paused'
      this.startTime = Date.now() - (parseInt(this.startValue) * 1000)
    }
    this.pauseAt = Date.now()
    this.interval = setInterval(this.updateCurrentTime, 1000)
    this.imagePlay = this.imagePath + 'btn-play-dark.svg'
    this.imagePause = this.imagePath + 'btn-pause-dark.svg'
    this.imageRestart = this.imagePath + 'restart-dark.svg'
  },
  destroyed: function () {
    clearInterval(this.interval)
  },
  watch: {
    play (newVal, oldVal) {
      if (newVal === true && oldVal === false) {
        if (this.state === 'paused' || this.state === 'pending') {
          this.resume()
        }
      } else if (newVal === false && oldVal === true) {
        if (this.state === 'started') {
          this.stop()
        }
      }
    },
    state (newVal, oldVal) {
      if (newVal === 'paused' && oldVal === 'started') {
        this.pauseAt = Date.now()
        this.timerEvent('paused', { time: this.getTime(), 'set-id': this.startSetID })
      } else if (newVal === 'started' && oldVal === 'paused') {
        this.pauseRelasedAt = Date.now()
        this.startTime = this.startTime + (this.pauseRelasedAt - this.pauseAt)
        this.updateCurrentTime()
        this.timerEvent('resumed', { time: this.getTime(), 'set-id': this.startSetID })
      } else if (newVal === 'started' && oldVal === 'pending') {
        this.playStartTimer()
        this.timerEvent('started')
      } else if (newVal === 'stopped' && oldVal === 'started') {
        this.playEndTimer()
        this.timerEvent('stopped', { time: this.getTime(), 'set-id': this.startSetID })
      }
    }
  },
  computed: {
    time: function () {
      return this.minutes + ':' + this.seconds
    },
    milliseconds: function () {
      return this.currentTime - this.startTime
    },
    hours: function () {
      const lapsed = this.milliseconds
      const hrs = Math.floor(lapsed / 1000 / 60 / 60)
      return hrs >= 10 ? hrs : '0' + hrs
    },
    minutes: function () {
      const lapsed = this.milliseconds
      const min = Math.floor((lapsed / 1000 / 60) % 60)
      return min >= 10 ? min : '0' + min
    },
    seconds: function () {
      const lapsed = this.milliseconds
      const sec = Math.ceil((lapsed / 1000) % 60)
      return sec >= 10 ? sec : '0' + sec
    }
  },
  methods: {
    setVolume () {
      if (templateConfig.instance_config &&
        'workout_timer_vol' in templateConfig.instance_config) {
        let vol = parseInt(templateConfig.instance_config.workout_timer_vol) || 0
        vol = vol / 10
        Howler.volume(vol)
      }
    },
    playStartTimer () {
      this.sounds.started = new Howl({
        src: ['https://app-redesign-plugin-files.s3.us-west-1.amazonaws.com/sounds/timer/timer-start.mp3'],
        volume: 0.5
      })
      this.setVolume()
      this.sounds.started.play()
    },
    playEndTimer () {
      this.sounds.ended = new Howl({
        src: ['https://app-redesign-plugin-files.s3.us-west-1.amazonaws.com/sounds/timer/timer-end.mp3'],
        volume: 0.5
      })
      this.setVolume()
      this.sounds.ended.play()
    },
    reset: function () {
      this.state = 'started'
      this.startTime = Date.now()
      this.currentTime = Date.now()
    },
    pause: function () {
      this.$data.state = 'paused'
    },
    resume: function () {
      if (this.state === 'pending') {
        this.initState()
      }
      this.state = 'started'
    },
    stop () {
      this.state = 'stopped'
      setTimeout(() => {
        this.initState()
        this.state = 'pending'
      }, 1000)
    },
    initState () {
      this.startTime = Date.now()
      this.currentTime = Date.now()
    },
    updateCurrentTime: function () {
      if (this.state === 'started') {
        this.currentTime = Date.now()
        this.$emit('current-time', { time: this.getTime(), 'set-id': this.startSetID })
      }
    },
    getTime () {
      return {
        days: 0,
        hours: this.hours,
        milliseconds: '',
        minutes: this.minutes,
        seconds: this.seconds,
        totalDays: 0,
        totalHours: this.hours,
        totalMilliseconds: '',
        totalMinutes: this.hours * 60 + this.minutes,
        totalSeconds: (this.hours * 60 + this.minutes) * 60 + this.seconds
      }
    },
    timerEvent (name, eventData) {
      this.$emit(name, eventData)
      eventData = { type: 'stop-watch-' + name, ...eventData }
      const event = new CustomEvent('exercise-timer', { detail: eventData })
      document.dispatchEvent(event)
    }
  }
}
</script>
