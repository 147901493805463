<template>
  <page-image-cover :class="componentClasses" :header="headerOptions" :background-image="backgroundImage">
    <div class="pc-add-new-password__start-header">
      <div class="pc-add-new-password__logo">
        <img :style="logoShow?{}:logoStyle" :src="logo" alt="" @onerror="logoShow= false">
      </div>
    </div>
    <!--   page wrapper -->
    <page-container>
      <validation-observer ref="form" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submitForm)">
          <div class="pc-add-new-password__card-sign-in">
            <div class="pc-add-new-password__header">
                <text-content :weight="'extra-bold'"
                              :size="'lg1'"
                              :lineHeight="'multi'"
                              class="text-center" >
                {{ $t("message[\'form.change-password\']") }}
                </text-content>
            </div>

            <div class="pc-add-new-password__inputs">
              <div class="form-group ma-form">
                <validation-provider :vid="'email'"
                                    :name="$t('message[\'auth.email\']')"
                                    rules="required|email"
                                    v-slot="{ errors }">
                  <text-input :type="'email'"
                                  :placeholder="$t('message[\'auth.email\']')"
                                  :errorMessage="errors[0]"
                                  v-model="formData.email" />
                </validation-provider>
              </div>
              <div class="form-group ma-form">
                <validation-provider :vid="'password'"
                                    :name="$t('message[\'placeholder.new-password\']')"
                                    rules="required|min:6"
                                    v-slot="{ errors }">
                  <text-input :type="'password'"
                                  :placeholder="$t('message[\'placeholder.new-password\']')"
                                  :errorMessage="errors[0]"
                                  v-model="formData.password" />
                </validation-provider>
              </div>
              <div class="form-group ma-form">
                <validation-provider :vid="'confirm password'"
                                    :name="$t('message[\'placeholder.new-password-confirmation\']')"
                                    rules="required|confirmed:password"
                                    v-slot="{ errors }">
                  <text-input :type="'password'"
                                  :placeholder="$t('message[\'placeholder.new-password-confirmation\']')"
                                  :errorMessage="errors[0]"
                                  v-model="formData.confirm_password" />
                </validation-provider>
              </div>
            </div>
            <p class="app-error-msg ">{{ systemError[0] }}</p>
            <div class="pc-add-new-password__buttons">
              <button-primary type="submit"
                              :text="$t('message[\'auth.submit\']')" />
              <button-primary type="button"
                              @click="goBack()"
                              class="pc-add-new-password__buttons-back"
                              :text="$t('message[\'forgot-password.back-to-login\']')" />
            </div>
          </div>
        </form>
      </validation-observer>
    </page-container>
    <div class="app-bg-overlay"></div>
  </page-image-cover>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '../../../../helpers/validation'
import addNewPasswordMixin from '../../../../mixins/component-mixins/page/auth/addNewPasswordMixin'
import TextInput from '../../../global/inputs/TextInput'
import ButtonPrimary from '../../../global/buttons/ButtonPrimary'
import TextContent from '../../../root/TextContent'
import PageImageCover from '../../../global/pages/PageImageCover'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import { checkNativeAppVersion } from '../../../../includes/NativeAppCommon'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'RequestNewPassword',
  mixins: [addNewPasswordMixin],
  components: {
    ValidationProvider,
    ValidationObserver,
    TextInput,
    ButtonPrimary,
    TextContent,
    PageImageCover,
    PageContainer
  },
  data: function () {
    return {
      headerOptions: {
        show: false

      }
    }
  },
  created () {
    this.setTrainer('')
    this.setPageTitle(this.$t("message['form.change-password']"))
  },
  mounted () {
    this.showLoading()
    this.setImages()
    setTimeout(() => {
      this.hideLoading()
      pageReadyEvent()
    }, 1500)
    if (templateConfig && templateConfig.style_variables && templateConfig.style_variables['var(--bg-homePages)']) {
      document.body.style.background = templateConfig.style_variables['var(--bg-homePages)']
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'pc-add-new-password': true,
        'pc-add-new-password__desktop-view': this.isDesktop,
        'pc-add-new-password__tablet-view': this.isTablet
      }
    }
  },
  methods: {
    goBack () {
      try {
        if (checkNativeAppVersion()) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              command: {
                name: 'goback'
              }
            })
          )
        }
      } catch (e) {
      }
      window.history.back()
    }
  }

}
</script>

<style scoped>

</style>
