<template>
  <page-secondary :header="headerOptions" class="pc-meal-plan-shopping-list">
    <page-container class="pc-meal-plan-shopping-list__container">
      <text-body-extra-small
        class="pc-meal-plan-shopping-list__heading"
        :line-height="'multi'"
        v-if="shoppingItems.length !== this.selectedItems.length"
      >
        {{ $t('message["shopping-list.shop-helper"]') }}
      </text-body-extra-small>
       <text-body-extra-small
        class="pc-meal-plan-shopping-list__completed-message"
        :line-height="'multi'"
        v-if="shoppingItems.length === this.selectedItems.length"
      >
      {{ $t('message["shopping-list.completed-message"]') }}
      </text-body-extra-small>
      <text-body-extra-small
        class="pc-meal-plan-shopping-list__empty-message"
        :line-height="'multi'"
        v-if="shoppingItems.length === 0"
      >
        {{ $t('message["shopping-list.empty-message"]') }}
      </text-body-extra-small>
      <input-group-ingredients
        v-if="showGroupedShoppingList"
        :grouped-ingredients="groupedShoppingItems"
        v-model="selectedItems"
        :count="peopleCount"
        type="cart"
      />
      <div class="pc-meal-plan-shopping-list__shopping-list-items" v-if="!showGroupedShoppingList">
        <transition-group name="fade" mode="out-in" appear>
          <input-shopping-list-item

            v-for="(item, key) in shoppingItems"
            v-show="!selectedItems.includes(item.id)"
            :key="'shopping-list-item-' + key + item.id"
            class="pc-meal-plan-shopping-list__shopping-list-item"
            :image="getThumbImageFromOrig(item.image)"
            :title="item.name"
            :quantity="item.quantity"
            :units="item.units"
            :item-i-d="item.id"
            :count="peopleCount"
            v-model="selectedItems"
          />
        </transition-group>
      </div>
      <page-footer class="pc-meal-plan-shopping-list__footer">
        <transition name="slide-up-fade">
          <button-primary-forward-icon
            class="pc-meal-plan-shopping-list__button"
            v-if="showCartButton && shoppedItemsCount > 0"
            @click="navigate"
            :text="shoppingListButtonTitle"
            :subText="shoppingListButtonSubTitle"
          >
            <icon-cart />
          </button-primary-forward-icon>
        </transition>
      </page-footer>
    </page-container>
    <popup-shopping-list-email
      class="pc-meal-plan-shopping-list__popup"
      v-if="popupShoppingListEmail.visible"
      :meal-plan-i-d="mealPlanID"
      :visible="popupShoppingListEmail.visible"
      @close="hidePopupShoppingListEmail"
    />
  </page-secondary>
</template>

<script>
import { flagValue } from '../../../../../includes/TemplateSettings'
import UserEventsService from '../../../../../services/UserEventsService'
import pageItemsMixin from '../../../../../mixins/component-mixins/page/shopping-list/pageItemsMixin'
import PageSecondary from '../../../../global/pages/PageSecondary'
import PageContainer from '../../../../global/pages/page-sub-components/PageContainer'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import ButtonPrimaryForwardIcon from '../../../../global/buttons/ButtonPrimaryForwardIcon'
import cartButtonMixin from '../../../../../mixins/cartButtonMixin'
import IconCart from '../../../../root/icons/IconCart'
import InputShoppingListItem from './page-components/InputShoppingListItem'
import headerEventBus from '../../../../../event-buses/headerEventBus'
import PopupShoppingListEmail from '../page-components/PopupShopingListEmail'
import InputGroupIngredients from './page-components/InputGroupIngredients'
import PageFooter from '../../../../global/pages/PageFixedFooter'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'PageShop',
  components: {
    PageFooter,
    InputGroupIngredients,
    PopupShoppingListEmail,
    PageSecondary,
    PageContainer,
    TextBodyExtraSmall,
    ButtonPrimaryForwardIcon,
    IconCart,
    InputShoppingListItem
  },
  mixins: [pageItemsMixin, cartButtonMixin],
  data: function () {
    return {
      popupShoppingListEmail: {
        visible: false
      },
      headerOptions: {
        show: true,
        isTransparent: false,
        left: 'previous',
        right: 'message',
        rightDisabled: true,
        helpContent: '',
        modifiers: ['with-bg-color', 'small-title'],
        formData: {
          image: ''
        },
        mainTitle: this.$i18n.t("message['general.shopping-list']")
      }
    }
  },
  computed: {
    shoppingListButtonTitle: function () {
      if (this.showGroupedShoppingList) {
        let count = parseInt(this.count)
        return this.$i18n.tc("message['shopping-list-group.items-added']", count, { count: count })
      }
      return this.$i18n.t("message['general.purchased-list']")
    },
    shoppingListButtonSubTitle: function () {
      if (this.showGroupedShoppingList) {
        return this.$i18n.t("message['general.purchased-list']")
      }

      if (this.count > 1) {
        return this.count.toString() + ' ' + this.$i18n.tc('message["general.items"]', parseInt(this.count))
      }
      return this.count.toString() + ' ' + this.$i18n.tc('message["general.items"]', parseInt(this.count))
    }
  },
  created () {
    this.setPageTitle(this.$t('message["general.shopping-list"]'))
  },
  beforeMount () {
    this.selectedItems = this.shoppedItems
    this.mealPlanID = this.$route.params.mid
  },
  mounted () {
    this.setPageClass()

    this.getShoppingListData()
    this.getMealPlanData()
    if (flagValue('sl_feedback_popup_show')) {
      UserEventsService.sendShoppingListFeedBackPopupView()
    }
    // adding header Event Bus listener
    headerEventBus.$on('send-button-click', () => {
      this.popupShoppingListEmail.visible = true
    })
    pageReadyEvent()
  },
  beforeDestroy () {
    // removing header Event Bus  listener
    headerEventBus.$off('send-button-click')
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
  },
  methods: {
    hidePopupShoppingListEmail () {
      this.popupShoppingListEmail.visible = false
    }
  }
}
</script>
