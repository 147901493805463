import RegionAPI from './api/common/RegionAPI'

export default class RegionService {
  regions = {}

  constructor () {
    if (!RegionService.instance) {
      RegionService.instance = this
    }
    return RegionService.instance
  }

  setRegions () {
    const api = new RegionAPI()
    return new Promise((resolve, reject) => {
      api.getList().then(res => {
        const countryArr = []
        res.data.regions.forEach(function (countryVal, index, array) {
          countryArr[countryVal.country_id] = {
            regions: []
          }

          const regionArr = []
          countryVal.region.forEach(function (regionVal, index, array) {
            regionArr[regionVal.region_id] = regionVal.name
          })
          countryArr[countryVal.country_id].regions = Object.assign({}, regionArr)
        })
        this.regions = Object.assign({}, countryArr)
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getRegions () {
    return this.regions
  }

  getRegionsByCountryId (country) {
    return this.regions[country].regions
  }
}
