import BaseAPI from '../BaseAPI.js'
import axios from 'axios'
import DefineInclude from '../../../DefineInclude'
export default class extends BaseAPI {
    _emailApiBaseUrl = DefineInclude.progressFeedbackAPIUrl

    /**
   * Get latest feedback created timestamp
   * @returns {Promise<AxiosResponse<T>>}
   */
    getLatestFeedbackAt () {
      const url = this._emailApiBaseUrl
      const headers = this._headers
      headers.Authorization = 'Bearer ' + this._token
      headers['X-Api-Host'] = DefineInclude.apiUrl.replace(/\/$/, '')
      return axios.get(url + 'latest-feedback-at', { headers: headers })
    }

    /**
     * Send feedback email
     * @param payload
     * @param grouped
     * @returns {Promise<AxiosResponse<T>>}
     */
    sendFeedbackEmail (payload, grouped = false) {
      const url = this._emailApiBaseUrl
      const headers = this._headers
      headers.Authorization = 'Bearer ' + this._token
      headers['X-Api-Host'] = DefineInclude.apiUrl.replace(/\/$/, '')
      return axios.post(url + 'send-feedback', payload, { headers: headers })
    }
}
