<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.8 1.4C9.8 0.627201 9.1728 0 8.4 0C7.6272 0 7 0.627201 7 1.4V7H1.4C0.627201 7 0 7.6272 0 8.4C0 9.1728 0.627201 9.8 1.4 9.8H8.4C9.1728 9.8 9.8 9.1728 9.8 8.4V1.4Z" fill="black"/>
        <path d="M21.0002 1.4C21.0002 0.627201 20.373 0 19.6002 0C18.8274 0 18.2002 0.627201 18.2002 1.4V8.4C18.2002 9.1728 18.8274 9.8 19.6002 9.8H26.6002C27.373 9.8 28.0002 9.1728 28.0002 8.4C28.0002 7.6272 27.373 7 26.6002 7H21.0002V1.4Z" fill="black"/>
          <path d="M19.6002 18.2H26.6002C27.373 18.2 28.0002 18.8272 28.0002 19.6C28.0002 20.3728 27.373 21 26.6002 21H21.0002V26.6C21.0002 27.3728 20.373 28 19.6002 28C18.8274 28 18.2002 27.3728 18.2002 26.6V19.6C18.2002 18.8272 18.8274 18.2 19.6002 18.2Z" fill="black"/>
            <path d="M8.4 18.2H1.4C0.627201 18.2 0 18.8272 0 19.6C0 20.3728 0.627201 21 1.4 21H7V26.6C7 27.3728 7.6272 28 8.4 28C9.1728 28 9.8 27.3728 9.8 26.6V19.6C9.8 18.8272 9.1728 18.2 8.4 18.2Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconNormalScreen',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-normal-screen': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
