<template>

  <popup
    v-if="visible"
    ref="updateFitnessDataModal"
    :visible="visible"
    @hide="closePopup"
    :modal-class="popupClasses"
  >
    <popup-title>
      <popup-button-close @hide="closePopup"/>
    </popup-title>
    <popup-body class="gc-popup-update-fitness-data__body">
      <popup-head class="text-center">
        <popup-text-heading v-if="status ==='init'">{{ fitnessData.getName() }}</popup-text-heading>
        <div v-if="status==='loading'" class="text-center">
          <loading-buffer :height="'75'"></loading-buffer>
        </div>
      </popup-head>
      <popup-content>
        <div v-if="status ==='init'">
          <validation-observer ref="metricForm" tag="div" v-slot="{handleSubmit}">
            <form class="gc-popup-update-fitness-data__form-row" id="form1" @submit.prevent="handleSubmit(doAction)">
              <div class="gc-popup-update-fitness-data__form" v-if="fitnessData.getType() !== 'body_fat'">
                <validation-provider
                  :name="fitnessData.getName().toLowerCase()"
                  :vid="fitnessData.getType()"
                  :rules="validation"
                  v-slot="{ errors }"
                  tag="div"
                  class="gc-popup-update-fitness-data__input-measurement-wrapper">
                  <input-measurement-spinner
                    :error-message="errors[0]"
                    :maxValue="9999"
                    :step="step"
                    :value="30.0"
                    v-model="inputValue"
                    :unit="unit"
                    @change="changed"/>
                </validation-provider>
              </div>
              <div class="gc-popup-update-fitness-data__form gc-popup-update-fitness-data__form-body-fat" v-else>
                <validation-provider
                  :name="fitnessData.getName().toLowerCase()"
                  :rules="validation"
                  v-slot="{ errors }"
                  tag="div"
                >
                  <input-body-fat
                    :value="inputValue"
                    :input-value="getBodyFatValue()"
                    :options="service().getBodyFatOptions()"
                    :useTextLables="service().getBodyFatTextEnabled()"
                    :showInputFieldManually="service().getBodyFatInputFieldEnabled()"
                    :gender="gender"
                    :height="height"
                    v-model="inputValue"
                    :measurement-system="measurementSystem"
                    :error-message="errors[0]"
                    v-on:has-error="setHasError($event)"
                    v-on:input="setBodyFatValue($event)"
                  ></input-body-fat>
                </validation-provider>
              </div>

            </form>
          </validation-observer>
          <div class="info-modal__general-error">
            <div
              class="ma-form__message ma-form__message--error"
              v-for="(error,eid) in generalErrors"
              :key="'fd-general-errors'+eid"
              v-html="error.toString()"
            ></div>
          </div>
        </div>
        <div class="gc-popup-update-fitness-data__success">
          <icon-complete-filled :size="'lg3'" v-if="status==='success'"/>
        </div>

      </popup-content>
      <popup-footer v-if="status === 'init'">
        <button-primary
          form="form1"
          :disabled="submited || hasError"
          type="submit"
          :text="$i18n.t('message[\'ui.modal-save\']')"/>
      </popup-footer>
    </popup-body>

  </popup>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import fitnessDiaryUpdateMetricMixin
  from '../../../mixins/component-mixins/page/fitness-diary/fitnessDiaryUpdateMeasurementComponentMixin'
import ButtonPrimary from '../buttons/ButtonPrimary'
import InputMeasurementSpinner from '../inputs/InputMeasurementSpinner'
import InputBodyFat from '../inputs/InputBodyFat'
import Popup from './Popup'
import PopupTitle from './popup-sub-components/PopupTitle'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupBody from './popup-sub-components/PopupBody'
import PopupHead from './popup-sub-components/PopupHead'
import PopupTextHeading from './popup-sub-components/PopupTextHeading'
import PopupContent from './popup-sub-components/PopupContent'
import PopupFooter from './popup-sub-components/PopupFooter'
import DashboardService from '../../../services/DashboardService'
import IconCompleteFilled from '../../root/icons/IconCompleteFilled'
import LoadingBuffer from '@/components/layout/template-1/global/widgets/LoadingBuffer.vue'

export default {
  name: 'PopUpUpdateFitnessData',
  mixins: [popupAnimationMixin, fitnessDiaryUpdateMetricMixin],
  components: {
    LoadingBuffer,
    PopupFooter,
    PopupContent,
    PopupTextHeading,
    PopupHead,
    PopupBody,
    PopupButtonClose,
    PopupTitle,
    Popup,
    InputBodyFat,
    InputMeasurementSpinner,
    ButtonPrimary,
    ValidationObserver,
    ValidationProvider,
    IconCompleteFilled
  },
  props: {
    visible: {default: false}
  },
  computed: {
    popupClasses: function () {
      return `gc-popup-update-fitness-data gc-popup-update-fitness-data--${this.fitnessData.type}`
    }
  },
  mounted () {
    const dashboardService = new DashboardService()
    dashboardService.getUserData().then(data => {
      this.height = data.height
      this.gender = data.gender
    })
    this.inputValue = this.fitnessData.getCurrentValue() || 0

    if (this.inputValue < 1 && this.fitnessData.getType() === 'body_fat') {
      this.inputValue = this.inputValue * 100
    }

    this.unit = this.fitnessData.getMeasurementUnit()
    this.inputChanged()
    this.setMaxAndMinValues()
    this.measurementSystem = this.service().getMeasurementSystem()
  },
  methods: {
    getBodyFatValue () {
      return this.inputValue
    },
    logEvents () {
      let context = 'my_journey'
      if (this.$route.name === 'DashboardMain') {
        context = 'dashboard'
      }
      this.logEvent('Journey.AddedMeasurement', {
        'measurement ': this.fitnessData.type,
        context: context
      }, false)

      if (this.fitnessData.type === 'weight') {
        this.logEvent('Journey.AddedMeasurement.Weight', {
          value: this.fitnessData.getCurrentValueInMetric(),
          context: context
        })
      } else if (this.fitnessData.type === 'body_fat') {
        this.logEvent('Journey.AddedMeasurement.BodyFat', {
          value: this.fitnessData.getCurrentValueInMetric(),
          context: context
        }, false)
      }
    },
    afterUpdateData () {
      this.logEvents()
      setTimeout(() => {
        this.closePopup()
      }, 1500)
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
        // backdrop close event fire
        if (e && e.trigger === 'backdrop') {
          this.submitCloseEvent('JourneyFitness', 'background')
        }
      })
    }

  }
}
</script>
