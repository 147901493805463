<template>
  <div
    class="gc-page"
    v-bind="$attrs"
    v-on="$listeners">
    <header-page
      v-if="header.show"
      :options="header"
      :is-transparent="header.isTransparent"
      :is-fixed="header.isFixed"
      :modifiers="header.modifiers"
      :main-title="header.mainTitle"
      :sub-title="header.subTitle"
      @height-updated="headerUpdated"
    />
    <div
      class="gc-page__content"
      :style="contentAreaStyles">
      <slot/>
    </div>

  </div>
</template>

<script>
import HeaderPage from '../headers/HeaderPage'
import pageMixin from '../../../mixins/component-mixins/global/page/pageMixin'

export default {
  name: 'Page',
  mixins: [pageMixin],
  props: {
    /**
     * header component props
     * refer header component prop
     * sample structure
     *  @example
     *  {
     *    isTransparent: false,
     *    isFixed: false,
     *    show: true,
     *    left: 'previous',
     *    right: 'message',
     *    rightBlink: false,
     *    modifiers: [],
     *    mainTitle: 'Day 1',
     *    subTitle: ''
     *   }
     */
    header: {
      type: Object,
      required: true
    }
  },
  components: { HeaderPage }

}
</script>

<style scoped>

</style>
