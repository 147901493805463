<template>
  <component
    v-bind:is="dynamicComponent"
    :pageConfig="dynamicComponentConfig"
  ></component>
</template>

<script>
import PageContactUs from './PageContactUs'
import MainPageMixin from '../../../../mixins/mainPageMixin'
export default {
  name: 'Main',
  mixins: [MainPageMixin],
  components: { PageContactUs },
  data: function () {
    return {
      components: {
        'page-contact-us': { design1: PageContactUs }
      }
    }
  },
  computed: {
    page () {
      return 'page-contact-us'
    }
  }
}
</script>

<style scoped></style>
