<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 20C4.48583 20 0 15.5142 0 10C0 4.48583 4.48583 0 10 0C15.5142 0 20 4.48583 20 10C20 15.5142 15.5142 20 10 20ZM10 0.833333C4.94583 0.833333 0.833333 4.94583 0.833333 10C0.833333 15.0542 4.94583 19.1667 10 19.1667C15.0542 19.1667 19.1667 15.0542 19.1667 10C19.1667 4.94583 15.0542 0.833333 10 0.833333Z" fill="black"/>
        <path d="M10 6.66663C9.31083 6.66663 8.75 6.10579 8.75 5.41663C8.75 4.72746 9.31083 4.16663 10 4.16663C10.6892 4.16663 11.25 4.72746 11.25 5.41663C11.25 6.10579 10.6892 6.66663 10 6.66663ZM10 4.99996C9.77083 4.99996 9.58333 5.18663 9.58333 5.41663C9.58333 5.64663 9.77083 5.83329 10 5.83329C10.2292 5.83329 10.4167 5.64663 10.4167 5.41663C10.4167 5.18663 10.2292 4.99996 10 4.99996Z" fill="black"/>
        <path d="M9.99992 15C9.76992 15 9.58325 14.8134 9.58325 14.5834V9.16671H8.74992C8.51992 9.16671 8.33325 8.98004 8.33325 8.75004C8.33325 8.52004 8.51992 8.33337 8.74992 8.33337H9.99992C10.2299 8.33337 10.4166 8.52004 10.4166 8.75004V14.5834C10.4166 14.8134 10.2299 15 9.99992 15Z" fill="black"/>
        <path d="M11.6667 15H8.33341C8.10341 15 7.91675 14.8133 7.91675 14.5833C7.91675 14.3533 8.10341 14.1666 8.33341 14.1666H11.6667C11.8967 14.1666 12.0834 14.3533 12.0834 14.5833C12.0834 14.8133 11.8967 15 11.6667 15Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconInfo',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-info': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
