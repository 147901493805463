<template>
    <div class="gc-navigation-bar" v-on="$listeners" v-bind="$attrs">
      <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'NavigationBar'
}
</script>

<style scoped>

</style>
