<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5 34C26.6127 34 34 26.6127 34 17.5C34 8.3873 26.6127 1 17.5 1C8.3873 1 1 8.3873 1 17.5C1 26.6127 8.3873 34 17.5 34Z" fill="#AFAFAF" fill-opacity="0.2"/>
    <path d="M34 17.5C34 26.6127 26.6127 34 17.5 34C8.3873 34 1 26.6127 1 17.5C1 8.3873 8.3873 1 17.5 1C26.6127 1 34 8.3873 34 17.5ZM2.485 17.5C2.485 25.7926 9.20744 32.515 17.5 32.515C25.7926 32.515 32.515 25.7926 32.515 17.5C32.515 9.20744 25.7926 2.485 17.5 2.485C9.20744 2.485 2.485 9.20744 2.485 17.5Z" fill="#AFAFAF"/>
    <path d="M23.1794 19.8082C21.691 21.473 19.5427 22.4271 17.2844 22.4271C15.0326 22.4271 12.8829 21.4722 11.3881 19.8075C11.1246 19.5139 10.6732 19.4889 10.3797 19.7532C10.0861 20.0167 10.0618 20.4681 10.3254 20.7616C12.0909 22.7278 14.6277 23.8555 17.2844 23.8555C19.9484 23.8555 22.4852 22.7271 24.2449 20.7609C24.5078 20.4667 24.4828 20.016 24.1885 19.7525C23.8936 19.4889 23.4422 19.5139 23.1794 19.8082Z" fill="#AFAFAF"/>
    <path d="M22.9987 11C21.0297 11 19.4277 12.6019 19.4277 14.571C19.4277 14.9652 19.7477 15.2851 20.1419 15.2851C20.5362 15.2851 20.8561 14.9652 20.8561 14.571C20.8561 13.3897 21.8174 12.4284 22.9987 12.4284C24.18 12.4284 25.1413 13.3897 25.1413 14.571C25.1413 14.9652 25.4612 15.2851 25.8554 15.2851C26.2497 15.2851 26.5696 14.9652 26.5696 14.571C26.5696 12.6019 24.9677 11 22.9987 11Z" fill="#AFAFAF"/>
    <path d="M13.7135 14.571C13.7135 14.9652 14.0335 15.2851 14.4277 15.2851C14.8219 15.2851 15.1419 14.9652 15.1419 14.571C15.1419 12.6019 13.54 11 11.571 11C9.60193 11 8 12.6019 8 14.571C8 14.9652 8.31996 15.2851 8.71419 15.2851C9.10842 15.2851 9.42838 14.9652 9.42838 14.571C9.42838 13.3897 10.3897 12.4284 11.571 12.4284C12.7522 12.4284 13.7135 13.3897 13.7135 14.571Z" fill="#AFAFAF"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconGreat',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-great': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
