<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path d="M24.6016 19.3058L15.046 2.75498C14.5146 1.83467 13.5629 1.28516 12.5001 1.28516C11.4374 1.28516 10.4856 1.83467 9.95427 2.75498L0.398669 19.3058C-0.132727 20.2262 -0.132727 21.3251 0.398669 22.2455C0.930064 23.1658 1.88172 23.7153 2.94451 23.7153H22.0557C23.1185 23.7153 24.0702 23.1658 24.6016 22.2455C25.133 21.3251 25.133 20.2262 24.6016 19.3058ZM23.3331 21.5132C23.0665 21.975 22.589 22.2507 22.0557 22.2507H2.94451C2.41121 22.2507 1.93368 21.975 1.66707 21.5132C1.40047 21.0514 1.40047 20.5 1.66707 20.0382L11.2228 3.48735C11.4894 3.02553 11.9669 2.74985 12.5002 2.74985C13.0334 2.74985 13.5109 3.02553 13.7776 3.48735L23.3332 20.0382C23.5998 20.5 23.5998 21.0514 23.3331 21.5132Z" fill="#FD003A"/>
        <path d="M13.2323 8.59387H11.7677V15.9172H13.2323V8.59387Z" fill="#FD003A"/>
        <path d="M12.4999 17.3821C11.9615 17.3821 11.5234 17.8201 11.5234 18.3585C11.5234 18.897 11.9615 19.335 12.4999 19.335C13.0383 19.335 13.4764 18.897 13.4764 18.3585C13.4764 17.8201 13.0383 17.3821 12.4999 17.3821Z" fill="#FD003A"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="25" height="25" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconWarning',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-warning': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
