<template>
  <text-content
  :size="'sm2'"
  :weight="weight"
  :lineHeight="lineHeight"
  :decoration="decoration"
  v-on="$listeners">
    <slot></slot>
  </text-content>
</template>

<script>
import TextContent from '../../root/TextContent'

export default {
  name: 'TextBodySmall',
  components: {
    TextContent
  },
  props: {
    /**
    * to decorate textbody
    * expected values - underline, italic
    */
    decoration: {
      default: ' '
    },
    /**
    * to set lineheight of textbody
    * expected values(old version): 'single' = 1 | 'multi' = 1.5
    * expected values(new version): 'lh-sm' = 1 | 'lh-md' = 1.3 | 'lh-lg' = 1.5 | 'lh-xl' = 1.6
    */
    lineHeight: {
      default: 'lh-sm'
    },
    /**
     * To change the font weight
     * Expected values: 'light' = 400 | 'medium' = 600 | 'bold' = 600 | 'extra-bold' = 700
     */
    weight: {
      default: 'medium'
    }
  }
}
</script>

<style scoped></style>
