import LanguageService from '@/services/LanguageService'
import SignUpService from '@/services/sign-up/SignUpService'
import globalSignUpMixin from '@/mixins/component-mixins/page/sign-up/globalSignUpMixin'
import pageSignUpCompleteMixin from '@/mixins/component-mixins/page/sign-up/pageSignUpCompleteMixin'
import DashBoardService from '@/services/DashboardService'

export default {
  mixins:[globalSignUpMixin, pageSignUpCompleteMixin],
  data: function () {
    return {
      stripeKey: null,
      accountId: null,
      paymentState: '',
      contactSupport: {
        popupShow: false
      }
    }
  },
  methods: {
    restartPayment () {
      this.$router.replace({ path: this.$appConfig.appUrlList.signUpPay.replace(':plan', this.$route.params.plan) })
    },
    showPopupContactSupport () {
      this.contactSupport.popupShow = true
    },
    retrievePaymentIntent (clientSecret) {
      let lang = new LanguageService()
      const stripe = Stripe(this.stripeKey, {
        locale: lang.getLangForStripe(),
        stripeAccount: this.accountId,
        betas: ['netbanking_beta_1', 'netbanking_bank_beta_1'],
      })

      stripe.retrievePaymentIntent(clientSecret).then(paymentIndent => {
        console.log(paymentIndent)
      })
    },
    checkSubscriptionStatus (subscriptionId = null) {
      if (!subscriptionId && !this.$route.query.subscription) {
        return
      }
      subscriptionId = subscriptionId || this.$route.query.subscription
      this.hideLoading()
      const planID = this.$route.params.plan
      const service = new SignUpService(planID)

      service.checkSubscriptionStatus(subscriptionId).then(subscription => {
        console.info('check payment state')
        console.table(subscription)
        if (subscription.state !== 'pending-payment') {
          this.goToThankYouPage()

          return
        }
        this.popupPaymentProcessing.show = true
        setTimeout(() => {
          this.checkSubscriptionStatus()
        }, 3000)
      })
    },
    initService (planID = null) {
      if (!planID) {
        planID = this.$route.params.plan
      }
      if (this.getService && this.getService.getPlanUUID() === planID) {
        // found service for relevant plan UUID
        this.service = this.getService
      } else {
        // create new sign up object
        this.service = new SignUpService(planID)
      }
    },
    getPaymentGatewayDetails () {
      return this.service.getPaymentGatewayDetails()
    },
    delay (time) {
      return new Promise(resolve => setTimeout(resolve, time))
    },
    confirmCheckoutPaymentBySessionID () {
      if (this.$route.query.sessionId) {
        this.popupPaymentProcessing.show = true
        this.service.confirmCheckoutPaymentBySessionID(this.$route.query.sessionId).then(async data => {
          if (data.subscription_id && data.paid) {
            this.subscriptionID = data.subscription_id
            this.checkSubscriptionStatus(data.subscription_id)
          } else {
            await this.delay(3000)
            this.confirmCheckoutPaymentBySessionID()
          }
        }).catch(async()=>{
          await this.delay(3000)
          this.confirmCheckoutPaymentBySessionID()
        })
      }
    },
    async goToThankYouPage () {
      this.showLoading()
      await this.delay(1000)
      let dashBoardService = new DashBoardService()
      await dashBoardService.getUserData(true)
      await this.goToNextPage()
    },
    async goToNextPage () {
      if (this.redirected) {
        return
      }
      this.$router.replace({ path: this.$appConfig.appUrlList.signUpThankYou.replace(':subscription', this.subscriptionID) })
    }
  }
}
