<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4768 3.72551L14.4767 3.72554L14.4785 3.72904C14.538 3.84223 14.6541 3.9125 14.7822 3.9125H17.6562C18.8934 3.9125 19.9 4.91913 19.9 6.15625V16.7031C19.9 17.9402 18.8934 18.9469 17.6562 18.9469H2.34375C1.10663 18.9469 0.1 17.9402 0.1 16.7031V6.15625C0.1 4.91913 1.10663 3.9125 2.34375 3.9125H5.27344C5.40159 3.9125 5.5176 3.84224 5.57706 3.72921L5.57713 3.72925L5.57885 3.72563L6.37391 2.05493L6.37401 2.05473L6.37953 2.04346L6.37984 2.04284C6.67067 1.46106 7.25487 1.1 7.90531 1.1H12.1504C12.8008 1.1 13.385 1.46106 13.6758 2.0428L13.6761 2.04343L13.6817 2.05469L13.6818 2.05489L14.4768 3.72551ZM6.79922 4.33162L6.79892 4.33223C6.50804 4.91398 5.92384 5.275 5.27344 5.275H2.34375C1.85774 5.275 1.4625 5.67024 1.4625 6.15625V16.7031C1.4625 17.1891 1.85774 17.5843 2.34375 17.5843H17.6562C18.1423 17.5843 18.5375 17.1891 18.5375 16.7031V6.15621C18.5375 5.6702 18.1423 5.27496 17.6562 5.27496H14.7822C14.1318 5.27496 13.5476 4.91394 13.2568 4.33219L13.2564 4.33157L13.2509 4.32027L13.2505 4.31944L12.4557 2.64937L12.4558 2.64934L12.4539 2.64582C12.3945 2.53276 12.2785 2.4625 12.1504 2.4625H7.90531C7.77716 2.4625 7.66113 2.53276 7.60171 2.64588L7.60164 2.64584L7.59994 2.64941L6.80518 4.31949L6.80478 4.32031L6.79922 4.33162ZM5.21715 11.0391C5.21715 8.40194 7.36281 6.25629 9.99996 6.25629C12.6371 6.25629 14.7828 8.40194 14.7828 11.0391C14.7828 13.6763 12.6371 15.8219 9.99996 15.8219C7.36281 15.8219 5.21715 13.6763 5.21715 11.0391ZM6.57965 11.0391C6.57965 12.9251 8.11392 14.4594 9.99996 14.4594C11.886 14.4594 13.4203 12.9251 13.4203 11.0391C13.4203 9.15305 11.886 7.61879 9.99996 7.61879C8.11392 7.61879 6.57965 9.15305 6.57965 11.0391ZM15.4121 8.39968V7.03718H16.7746V8.39968H15.4121Z"
        fill="#252524" stroke="#252524" stroke-width="0.2"/>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconCamera',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-camera': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
