<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.42635 3.00015C3.76067 3.00015 3.21265 3.52992 3.21265 4.17351V6.10804H1.21097C0.54545 6.10804 0 6.63599 0 7.27939V16.8259C0 17.4693 0.54545 18 1.21097 18L15.5735 17.9998C16.239 17.9998 16.7851 17.4693 16.7851 16.8257V14.8912H18.789C19.4547 14.8912 20 14.3607 20 13.7171L19.9998 4.17336C19.9998 3.5298 19.4546 3 18.7889 3L4.42635 3.00015ZM4.42635 3.6899H18.7889C19.0714 3.6899 19.2864 3.90037 19.2864 4.17351V13.7173C19.2864 13.9904 19.0714 14.2016 18.7889 14.2016H16.7852V7.27931C16.7852 7.19894 16.7763 7.12027 16.7602 7.04421C16.7115 6.81619 16.5939 6.61144 16.4291 6.45209C16.3743 6.39913 16.3138 6.35156 16.2495 6.30937C16.056 6.18266 15.8231 6.10799 15.5736 6.10799H3.92625V4.17346C3.92625 3.90033 4.14395 3.68985 4.42649 3.68985L4.42635 3.6899ZM1.21101 6.79838H15.5736C15.8562 6.79838 16.0711 7.00607 16.0711 7.27935V8.99296H0.713338V7.27935C0.713338 7.00607 0.928171 6.79838 1.21084 6.79838H1.21101ZM0.713501 9.68271H16.0712V11.0015H0.713501V9.68271ZM0.713501 11.6887H16.0712V16.8259C16.0712 17.0992 15.8564 17.3095 15.5737 17.3095H1.21117C0.928493 17.3095 0.713664 17.0991 0.713664 16.8259L0.713501 11.6887ZM2.43583 13.471C2.24202 13.4848 2.09519 13.6457 2.10538 13.8332C2.11541 14.0209 2.27849 14.1662 2.47278 14.1607H6.21225H6.21209C6.30812 14.1627 6.40096 14.1273 6.4696 14.0625C6.53824 13.9975 6.57694 13.9087 6.57694 13.8158C6.57694 13.723 6.53824 13.6342 6.4696 13.5692C6.40096 13.5044 6.30812 13.469 6.21209 13.471H2.47262H2.47278C2.46036 13.4703 2.44809 13.4703 2.43583 13.471H2.43583ZM2.47278 15.0235C2.37803 15.0235 2.28709 15.0597 2.21989 15.1245C2.15284 15.1893 2.11526 15.2771 2.11526 15.3687C2.11526 15.4605 2.15284 15.5482 2.21989 15.613C2.28709 15.6779 2.37803 15.714 2.47278 15.714H4.07105C4.16581 15.714 4.25675 15.6779 4.32379 15.613C4.39083 15.5482 4.42858 15.4605 4.42858 15.3687C4.42858 15.2771 4.39084 15.1893 4.32379 15.1245C4.25675 15.0597 4.16581 15.0235 4.07105 15.0235H2.47278Z" fill="black"/>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconPaymentCard',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-payment': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
