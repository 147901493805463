import DashbardAPI from '../api/dashboard/DashboardAPI'
import PlanOneOffService from './PlanOneOffService'
import PlanSubscriptionService from './PlanSubscriptionService'
import lodash from 'lodash'
import moment from 'moment'

export default class BillingService {
  _plans = []
  _stripeKey = null
  _creditCard = null
  _billingResponse = null

  getPlans () {
    return this._plans
  }

  /**
   * get the billing iformations for the API
   * @returns {Promise}
   */
  getInfoFormAPI () {
    let service = new DashbardAPI()
    return new Promise((resolve, reject) => {
      if (this._billingResponse !== null) {
        resolve(this._billingResponse)
      } else {
        service.getBillingInformation().then(response => {
          this._billingResponse = response.data
          this._mapAPISuccessAPIResponse(this._billingResponse)
          resolve(this._billingResponse)
        }).catch(() => {
          reject(new Error('error on fetching billing info'))
        })
      }
    })
  }

  /**
   * map the API success response with the class structure
   * @param billingResponse
   * @private
   */
  _mapAPISuccessAPIResponse (billingResponse) {
    this._plans = []
    let items = lodash.sortBy(billingResponse.subscriptions.items, 'created_at').reverse()
    items.forEach(plan => {
      if(!plan.payment_details && plan.payment_gateway==='stripe'){
        plan.payment_details = {
          type: 'card'
        }

      }
      if (plan.plan_type === 'subscription' || plan.is_recurring) {
        this._plans.push(new PlanSubscriptionService(plan))
      } else {
        this._plans.push(new PlanOneOffService(plan))
      }
    })
    this._stripeKey = billingResponse.stripe_key || null
    this._creditCard = billingResponse.credit_card || null
  }

  /**
   * sort the plans according to the custom order
   * order - Sort the not expired and canceled plans with the acs date then sort the canceled plans and merge it
   * @returns {*[]}
   */
  getCustomSortedPlans () {
    let plans = this.getPlans().filter(plan => {
      return !plan.isExpired() && plan.getStatus() !== 'pending-payment'
    })
    let cancelledPlans = plans.filter((plan) => {
      return plan.isCancelled() && moment().diff(plan.getCancelledDate(), 'days') < 0
    })
    let notCancelledPlans = plans.filter((plan) => {
      return !plan.isCancelled()
    })

    notCancelledPlans = lodash.sortBy(notCancelledPlans, (plan) => {
      return plan.getExpireDate()
    })
    cancelledPlans = lodash.sortBy(cancelledPlans, (plan) => {
      return plan.getCancelledDate()
    })

    return [...notCancelledPlans, ...cancelledPlans]
  }

  /**
   * get the stripe public key
   * @returns {null}
   */
  getStripeKey () {
    return this._stripeKey
  }

  /**
   * get the last 4 digits of the credit/debit card
   * @returns {string|*|string}
   */
  getCreditCardLastDigits () {
    const planWithCard  = this._plans.find(plan => {
      return plan.getPaymentDetails().type === 'card' || plan.getPaymentDetails().type === 'sepa_debit'
    })
    if(planWithCard){
      return planWithCard.getCardLastDigits()
    }
    if (!this._creditCard) {
      return ''
    }
    return this._creditCard.card_last_4 || ''
  }

  /**
   * check the user paid via apple pay
   * @returns {boolean}
   */
  hasApplePaymentGatewayPlan () {
    return this._plans.some(plan => {
      return plan.getPaymentGateway() === 'apple' && !plan.isCancelled() && !plan.isExpired()
    })
  }

  hasAnyStateApplePaymentGatewayPlan () {
    return this._plans.some(plan => {
      return plan.getPaymentGateway() === 'apple'
    })
  }

  /**
   * check the user paid via stripe pay
   * @returns {boolean}
   */
  hasStripePaymentGatewayPlan () {
    return this._plans.some(plan => {
      return plan.getPaymentGateway() === 'stripe'
    })
  }

  hasSubscriptionPlans () {
    return this._plans.some(plan => {
      return plan.getPlanType() === 'subscription'
    })
  }

  hasSubscriptionPlansWithStripePaymentGateway () {

    return this._plans.some(plan => {

      return plan.getPlanType() === 'subscription' && plan.getPaymentGateway() === 'stripe'
    })
  }



  hasCardPayment () {
    if (this._creditCard) {
      return !!this._creditCard.card_last_4
    }

    return this._plans.some(plan => {
      return plan.getPaymentDetails().type === 'card'
    })
  }
  hasSEPAPayment () {
    return this._plans.some(plan => {
      return plan.getPaymentDetails().type === 'sepa_debit'
    })
  }
  hasGiroPayPayment () {
    return this._plans.some(plan => {
      return plan.getPaymentDetails().type === 'giropay'
    })
  }
  hasIdealPayment () {
    return this._plans.some(plan => {
      return plan.getPaymentDetails().type === 'ideal'
    })
  }
}
