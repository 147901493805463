<template>
  <div class="pc-accordion">

    <div class="pc-accordion__card" no-body v-for="(rootItem) in itemList" :key="'accordion-card-'+rootItem.id">
      <div header-tag="header" role="tab">
        <button-primary-accordion :text="rootItem.name"
                                  class="pc-accordion__card-btn"
                                  :selected=" rootItem.id === currentCollapseIndex"
                                  v-b-toggle="'accordion-' + rootItem.id"
                                  v-on:click="setLastExpanded(rootItem.id)"
        />
      </div>
      <b-collapse
        :id="'accordion-'+rootItem.id"
        accordion="my-accordion"
        class="pc-accordion__card-collapse"
        role="tabpanel"
        :visible="isVisible(rootItem.id)"
        v-on:hide="collapseHidden()"
        v-on:shown="collapseShown(rootItem)"
      >

        <page-content-text v-if="rootItem.content && currentCollapseIndex ===rootItem.id" :description="rootItem.content" />
        <div v-for="(subItem) in rootItem.subItems" :key="'sub-item'+subItem.id">
          <page-list-sub-item-area :root-key="rootItem.id" :sub-item="subItem" :sub-key="subItem.id"
                                   class="accordion-card__text"/>
        </div>
      </b-collapse>
    </div>
  </div>
</template>
<script>
import PageListSubItemArea from './PageListSubItemArea'
import { BButton, BCollapse } from 'bootstrap-vue'
import { mapMutations } from 'vuex'
import NativeAppMixin from '../../../../../mixins/NativeAppMixin'
import ButtonPrimaryAccordion from '../../../../global/buttons/ButtonPrimaryAccordion'
import PageContentText from './PageContentText'

export default {
  name: 'ListItems',
  components: {
    PageListSubItemArea,
    BButton,
    BCollapse,
    ButtonPrimaryAccordion,
    PageContentText
  },
  mixins: [NativeAppMixin],
  props: {
    itemList: {},
    lastExpanded: {}
  },
  data: function () {
    return {
      currentCollapseIndex: -1,
      imagesUrl: this.$appConfig.imagesUrl
    }
  },
  watch: { lastExpanded: {
    deep: true,
    immediate: true,
    handler: function () {
      if (this.lastExpanded.expanded) {
        this.currentCollapseIndex = this.lastExpanded.expanded
      }
    }
  }},
  methods: {
    collapseShown (rootItem) {
      this.currentCollapseIndex = rootItem.id
      this.setLastExpanded(rootItem.id)
    },
    collapseHidden () {
      this.resetLastExpanded()
    },
    ...mapMutations({
      setLastExpanded: 'listLastExpandedStore/setLastExpanded',
      resetLastExpanded: 'listLastExpandedStore/reset'
    }),
    isVisible (index) {
      if (!this.lastExpanded || !this.lastExpanded.expanded) {
        return false
      }
      return this.lastExpanded.expanded === index
    },
    linkNavigateClickHandler (event) {
      let targetElement = event.target
      const selector = '.accordion-card__text a, .text-content-area a'
      while (targetElement != null) {
        if (targetElement.matches(selector)) {
          event.preventDefault()
          this.handleUrlNavigate(targetElement.getAttribute('href'))
          return
        }
        targetElement = targetElement.parentElement
      }
    }
  },
  mounted () {
    setTimeout(() => {
      document.querySelector('body')
        .addEventListener('click', this.linkNavigateClickHandler, true)
    }, 100)
  },
  beforeDestroy () {
    document.querySelector('body')
      .removeEventListener('click', this.linkNavigateClickHandler, true)
  }
}
</script>
