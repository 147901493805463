import DefineInclude from '../../DefineInclude'
import { flagValue } from '../../includes/TemplateSettings'

export default class {
  getBodyFats () {
    let customImages = flagValue('custom_body_fat_image_config', null)
    customImages = typeof customImages === 'string' ? JSON.parse(customImages) : customImages
    if (customImages && typeof customImages === 'object') {
      return customImages
    }
    const baseImageURL = DefineInclude.resourceBaseURL + '/static/assets/images/bodyfat/'
    const maleBaseImageUrl = baseImageURL + 'men/'
    const femaleBaseImageUrl = baseImageURL + 'women/'
    return {
      isTextLabel: false,
      showInput: true,
      m:
        [
          { image: maleBaseImageUrl + 'm1.jpg', min: '3', max: '5' },
          { image: maleBaseImageUrl + 'm2.jpg', min: '6', max: '8' },
          { image: maleBaseImageUrl + 'm3.jpg', min: '9', max: '13' },
          { image: maleBaseImageUrl + 'm4.jpg', min: '14', max: '17' },
          { image: maleBaseImageUrl + 'm5.jpg', min: '18', max: '22' },
          { image: maleBaseImageUrl + 'm6.jpg', min: '23', max: '27' },
          { image: maleBaseImageUrl + 'm7.jpg', min: '28', max: '32' },
          { image: maleBaseImageUrl + 'm8.jpg', min: '33', max: '37' },
          { image: maleBaseImageUrl + 'm9.jpg', min: '38', max: '100' }
        ],
      f: [
        { image: femaleBaseImageUrl + 'f1.jpg', min: '10', max: '13' },
        { image: femaleBaseImageUrl + 'f2.jpg', min: '14', max: '18' },
        { image: femaleBaseImageUrl + 'f3.jpg', min: '19', max: '23' },
        { image: femaleBaseImageUrl + 'f4.jpg', min: '24', max: '27' },
        { image: femaleBaseImageUrl + 'f5.jpg', min: '28', max: '32' },
        { image: femaleBaseImageUrl + 'f6.jpg', min: '33', max: '37' },
        { image: femaleBaseImageUrl + 'f7.jpg', min: '38', max: '42' },
        { image: femaleBaseImageUrl + 'f8.jpg', min: '43', max: '47' },
        {
          image: femaleBaseImageUrl + 'f9.jpg', min: '48', max: '100'
        }
      ]
    }
  }
}
