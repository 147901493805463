<template>
  <div>
    <button type="button" class="btn-main btn-shopping btn-shopping--with-outline" v-on:click="navigate">
      <div class="col-12">
        <div class="row">
          <div class="col-8 p-0">
            <p class="btn-shopping__title p-0 text-left">
              {{$tc('message["general.ingredients"]',count)}}</p>
          </div>
          <div class="col-4 pl-0 pr-0 text-right right-side  small-phone">
            <span>{{count}}  {{$tc('message["general.items"]',count)}}</span>
            <img :src=" imagesUrl+'v2/right-arrow-gray.svg'" alt="" class="btn-shopping__image">
          </div>
        </div>
      </div>
    </button>
  </div>
</template>
<script>
import cartButtonMixin from '../../../../../../mixins/cartButtonMixin'

export default {

  name: 'cart-button-v-2',
  mixins: [cartButtonMixin]
}
</script>
