<template>
    <div>
        <div class="pc-popup-period-tracker">
            <popup-title>
                <popup-button-close @click="closePopup"/>
            </popup-title>
            <popup-body class="text-center">
                <popup-content>
                    <popup-head class="text-center">
                        <popup-text-heading class="pc-popup-period-tracker__head">
                            {{$t('message[\'period-tracker.popup-saved\']')}}
                        </popup-text-heading>
                    </popup-head>
                    <div class="pc-popup-period-tracker__content pc-popup-period-tracker__content--success">
                        <icon-completed-filled :size="'lg3'"/>
                    </div>
                </popup-content>
            </popup-body>
        </div>
    </div>
</template>

<script>
import PopupHead from '../../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../../global/popups/popup-sub-components/PopupTextHeading'
import IconCompletedFilled from '../../../../../root/icons/IconCompletedFilled'
import PopupTitle from '../../../../../global/popups/popup-sub-components/PopupTitle'
import PopupBody from '../../../../../global/popups/popup-sub-components/PopupBody'
import PopupContent from '../../../../../global/popups/popup-sub-components/PopupContent'
import PopupButtonClose from '../../../../../global/popups/popup-sub-components/PopupButtonClose'

export default {
  name: 'ProcessCompleted',
  components: {
    PopupHead,
    PopupTextHeading,
    IconCompletedFilled,
    PopupButtonClose,
    PopupTitle,
    PopupBody,
    PopupContent
  },
  mounted () {
    setTimeout(() => {
      this.$emit('close')
      this.$emit('processed')
    }, 2000)
  },
  methods: {
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
