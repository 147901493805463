<template>
  <popup-body class="pc-popup-suggested-exercise-swap__body-alternatives">
    <popup-head>
      <popup-text-heading class="text-center">{{ title }}</popup-text-heading>
      <text-body-regular
        class="pc-popup-suggested-exercise-swap__body-alternatives-sub-title"
        line-height="multi"
        v-if="state==='init'"
        >{{ $t("message['exercise-swap.select-alternatives']") }}</text-body-regular>
    </popup-head>
    <popup-content >
      <div v-if="state==='init'">
        <div ref="exerciseList" class="pc-popup-suggested-exercise-swap__alternative-exercise-list">
          <input-radio-exercise
            class="pc-popup-suggested-exercise-swap__alternative-exercise"
            v-for="alternative in currentAlternatives"
            :key="'alternative-'+alternative.exercise_id"
            :id="alternative.exercise_id"
            v-model="selectedExercise"
            :name="alternative.name"
            :image="alternative.images[0] || ''"
            :muscle="exercise.getPreferredMuscle()"
            :set-text="exercise.getSetsRepsString()"
            :set-type="exercise.getSetType().name"
          />
        </div>
        <div class="pc-popup-suggested-exercise-swap__area-more" v-if="alternatives.length>maxExercisesToShow">
          <text-body-small line-height="multi" class="pc-popup-suggested-exercise-swap__body-alternatives-no-matching-text">{{ $t("message['exercise-swap.no-matching-alternatives']") }}</text-body-small>
          <text-body-small
            class="pc-popup-suggested-exercise-swap__body-alternatives-link-more"
            line-height="multi"
            weight="extra-bold"
            decoration="underline"
            @click="showMore">{{ $t("message['exercise-swap.more-alternatives']") }}</text-body-small>
        </div>
      </div>
      <div
        v-else-if="this.state === 'submitting'"
        class="text-center">
        <loading-buffer height="50"/>
      </div>
      <div
        v-else-if="this.state === 'success'"
        class="text-center">
        <icon-completed-filled size="lg3"
                               class="pc-popup-suggested-exercise-swap__success-icon"/>
      </div>
    </popup-content>
    <popup-footer>
      <button-primary
        v-if="state=== 'init'"
        :disabled="!selectedExercise"
        :text="buttonText"
        @click="submit"/>
    </popup-footer>
  </popup-body>
</template>

<script>
import PopupBody from '../../../../../../global/popups/popup-sub-components/PopupBody'
import PopupTextHeading from '../../../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupContent from '../../../../../../global/popups/popup-sub-components/PopupContent'
import PopupFooter from '../../../../../../global/popups/popup-sub-components/PopupFooter'
import ButtonPrimary from '../../../../../../global/buttons/ButtonPrimary'
import InputRadioExercise from '../../InputRadioExercise'
import WorkoutProgramExerciseService from '../../../../../../../services/WorkoutProgramExerciseService'
import PopupHead from '../../../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextSubHeading from '../../../../../../global/popups/popup-sub-components/PopupTextSubHeading'
import TextBodySmall from '../../../../../../global/typography/TextBodySmall'
import IconCompletedFilled from '../../../../../../root/icons/IconCompletedFilled'
import LoadingBuffer from '../../../../global/widgets/LoadingBuffer'
import TextBodyRegular from '../../../../../../global/typography/TextBodyRegular'
export default {
  name: 'PopupSuggestedExerciseSwapContainerAlternatives',
  components: { TextBodyRegular, LoadingBuffer, IconCompletedFilled, TextBodySmall, PopupTextSubHeading, PopupHead, InputRadioExercise, ButtonPrimary, PopupFooter, PopupContent, PopupTextHeading, PopupBody },
  props: {
    alternatives: {
      required: true,
      type: Array
    },
    type: {
      default: 'gym'
    },
    exercise: {
      required: true,
      validator: function (value) {
        return value instanceof WorkoutProgramExerciseService
      }
    }
  },
  data: function () {
    return {
      maxExercisesToShow: 3,
      state: 'init',
      currentExerciseGroupIndex: 0,
      selectedExercise: '',
      buttonText: this.$i18n.t('message["exercise-swap.alternatives-swap-button-text"]')
    }
  },
  computed: {
    currentAlternatives: function () {
      return this.alternatives.slice(this.currentExerciseGroupIndex * this.maxExercisesToShow, (this.currentExerciseGroupIndex * this.maxExercisesToShow + this.maxExercisesToShow))
    },
    title: function () {
      if (this.state === 'submitting') {
        return this.$i18n.t('message["exercise-swap.swapping"]')
      } else if (this.state === 'success') {
        return this.$i18n.t('message["exercise-swap.swapped"]')
      } else if (this.type === 'gym') {
        return this.$i18n.t('message["exercise-swap.gym-alternatives"]')
      } else {
        return this.$i18n.t('message["exercise-swap.home-alternatives"]')
      }
    }
  },
  watch: {
    state: function () {
      this.$emit('state', this.state)
      this.$nextTick(() => {
        this.$emit('updated')
      })
    }
  },
  mounted () {
    this.$emit('mounted')
  },
  methods: {
    showMore () {
      if (this.alternatives.length > (this.currentExerciseGroupIndex + 1) * 3) {
        this.currentExerciseGroupIndex++
      } else {
        this.currentExerciseGroupIndex = 0
      }
      this.selectedExercise = null
      this.$refs.exerciseList.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    },
    submit () {
      this.logEvent('SwappedExercise', {
        reason: this.type,
        exerciseID: this.exercise.getID(),
        exerciseName: this.exercise.getName()
      }, false)
      this.state = 'submitting'
      this.$emit('submitting')
      this.exercise.swapForAlternativeExercise(this.selectedExercise).then(data => {
        this.state = 'success'
        this.$emit('swap-success')
      })
    }
  }
}
</script>

<style scoped>

</style>
