<template>
  <div class="macroapp--page-common macroapp">
    <transition name="fade" mode="out-in">
      <component v-bind:is="dynamicComponent" v-if="loaded"></component>
    </transition>
    <popup-not-found-error v-if="notFoundPage"  v-on:close="goBack()"></popup-not-found-error>
  </div>
</template>

<script>
import BaseComponent from '../global/base/BaseComponent'
import PageList from './PageList'
import PageDetails from './PageDetails'
import PageVideo from './PageVideo'
import PageContent from './PageContent'
import MainPageMixin from '../../../../mixins/mainPageMixin'
import ContentPageFactoryService from '../../../../services/ContentPageFactoryService'
import PopupNotFoundError from '../../../global/popups/PopupNotFoundError'
export default {
  name: 'Main',
  extends: BaseComponent,
  mixins: [MainPageMixin],
  components: {
    PopupNotFoundError
  },
  data: function () {
    return {
      pageName: this.$route.params.contentPage.toString(),
      loaded: false,
      components: {
        'content-custom-list': { design1: PageList },
        'content-custom-details': { design1: PageDetails },
        'content-custom-video': { design1: PageVideo },
        'content-custom-content': { design1: PageContent }

      },
      pageType: null,
      pageCode: null,
      notFoundPage: false
    }
  },
  updated () {
    this.setPageName(this.pageCode)
  },
  mounted () {
    const service = new ContentPageFactoryService()
    service.getPageList().then(data => {
      const link = service.findPageInList(this.pageName, data)
      if (link) {
        this.pageCode = link.code
        this.loadPageData(link.code)
        this.setPageName(link.code)
      } else {
        this.notFoundPage = true
      }
    }).catch(() => {
      if (navigator.onLine) {
        this.notFoundPage = true
      }
    })
  },
  computed: {
    page () {
      switch (this.pageType) {
        case 'custom-list':
          return 'content-custom-list'
        case 'custom-video':
          return 'content-custom-video'
        // following custom page type added for the internal page handle
        case 'custom-details':
          return 'content-custom-details'
        default:
          return 'content-custom-content'
      }
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    loadPageData (pageName) {
      const contentPageFactory = new ContentPageFactoryService()
      const page = contentPageFactory.getPage(pageName)
      page.setPageData().then(() => {
        const queryParams = this.$route.query
        if ('rootItem' in queryParams && 'subItem' in queryParams) {
          this.pageType = 'custom-details'
        } else {
          this.pageType = page.pageData.type
        }
        this.loaded = true
        this.logEvent('PageView', { slug: '/page/' + this.pageName, title: page.pageData.page.title })
      }).catch(() => {
        this.notFoundPage = true
      })
    }
  }
}
</script>
