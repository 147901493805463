<template>
  <popup
      :visible="visible"
      @hide="closePopup"
      modal-class="gc-popup gc-popup-server-error gc-popup-error"
      v-if="visible"
    >
    <popup-title>
      <popup-button-close @click="closePopup"></popup-button-close>
    </popup-title>
    <popup-body class="text-center">
      <popup-content>
        <popup-head>
          <icon-Not-found class="gc-popup-error__header-icon" :size="'lg3'"></icon-Not-found>
          <popup-text-heading  class="gc-popup-error__title">{{$i18n.t("message['general.not-found-error-title']")}}</popup-text-heading>
        </popup-head>
        <popup-text-body class="gc-popup-error__description">
          <text-content :size="'sm4'" :weight="'extra-bold'" :lineHeight="'multi'">{{$i18n.t("message['general.not-found-error-description']")}}</text-content>
        </popup-text-body>
        <div>
        </div>
      </popup-content>
      <popup-footer>
        <button-primary class="gc-popup-error__btn-wrapper" :text="$i18n.t('message[\'general.go-back\']' )"  @click="closePopup()" />
      </popup-footer>
    </popup-body>
    </popup>
</template>

<script>

import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import { mapMutations } from 'vuex'
import ButtonPrimary from '../buttons/ButtonPrimary'
import PopupFooter from './popup-sub-components/PopupFooter'
import PopupTextBody from './popup-sub-components/PopupTextBody'
import PopupTextHeading from './popup-sub-components/PopupTextHeading'
import PopupHead from './popup-sub-components/PopupHead'
import PopupContent from './popup-sub-components/PopupContent'
import PopupBody from './popup-sub-components/PopupBody'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupTitle from './popup-sub-components/PopupTitle'
import Popup from './Popup'
import TextContent from '../../root/TextContent'
import IconNotFound from '../../root/icons/IconNotFound'

export default {
  name: 'PopupNotFoundError',
  mixins: [popupAnimationMixin],
  components: {
    ButtonPrimary,
    PopupFooter,
    PopupTextBody,
    PopupTextHeading,
    PopupHead,
    PopupContent,
    PopupBody,
    PopupButtonClose,
    PopupTitle,
    Popup,
    TextContent,
    IconNotFound
  },
  data: function () {
    return {
      imagesUrl: this.$appConfig.imagesUrl,
      visible: true
    }
  },
  mounted () {
    this.hideLoading()
  },
  methods: {
    ...mapMutations({
      hideNotFoundPopupShow: 'hideNotFoundPopupShow'
    }),
    closePopup () {
      this.closeAnimatedPopup(() => {
        this.$emit('close')
        this.hideNotFoundPopupShow()
      })
    }
  }
}
</script>

<style scoped>
</style>
