export default {
  data () {
    return {
      thumbLoading: false,
      thumbImage: null,
      videoThumbLoaded: false
    }
  },
  props: {
    isGrouped: {
      default: false
    },
    isCircuit: {
      default: false
    },
    exercise: {
      required: true,
      type: Object
    },
    groupExerciseID: {
      default: ''
    }
  },
  computed: {
    image: function () {
      return this.thumbImage || this.exercise.getImage()
    },
    exerciseName: function () {
      return (this.exercise.getName().length > 30) ? this.exercise.getName().substring(0, 30) + '...' : this.exercise.getName()
    },
    setsText: function () {
      let sets = this.exercise.getSetsReps()
      let isAllSetsHaveOneRep = sets.every((set) => {
        return set.trim() === '1'
      })
      if (isAllSetsHaveOneRep) {
        return sets.length + ' ' + this.$i18n.tc('message[\'general.set\']', sets.length)
      } else {
        return this.$i18n.tc('message[\'general.reps\']', sets.length) + ' ' + this.exercise.getSetsRepsString()
      }
    }
  },
  mounted () {
    this.setThumbLoading()
    if (!this.exercise.hasImage()) {
      this.loadVideoImageFromVideoUrl()
    }
  },
  methods: {
    setVideoThumb () {
      if (this.videoThumbLoaded) {
        return
      }
      this.videoThumbLoaded = true
      this.thumbLoading = true
      const vimeoId = this.getVimeoIdFromUrl(this.exercise.getVideoUrl())
      if (vimeoId) {
        // no need to handle error since default error action is ignore
        this.getVimeoImage(vimeoId)
          .then(url => {
            this.thumbLoading = false
            this.thumbImage = url
          }).catch(() => {
          // do nothing
          })
      }
    },
    setThumbLoading () {
      this.thumbLoading = true
      setTimeout(() => {
        this.thumbLoading = false
      }, 1000)
    },
    navigateNext () {
      this.$emit('navigate-next')
    },
    getExerciseName () {
      let name = this.exercise.getName()

      return (name.length > 60) ? name.substring(0, 57) + '...' : name
    },
    getExerciseMuscles () {
      let preferredMuscle = this.exercise.getPreferredMuscle()
      if (preferredMuscle) {
        return preferredMuscle
      }

      let muscles = this.exercise.getMuscles()
      let musclesText = ''
      if (Array.isArray(muscles)) {
        musclesText = muscles.join(', ')
      } else {
        musclesText = muscles
      }

      return (musclesText.length > 30) ? musclesText.substring(0, 30) + '...' : musclesText
    },
    getExerciseMusclesCount () {
      return this.exercise.getMusclesCount()
    },
    loadVideoImageFromVideoUrl () {
      this.exercise.loadVideoImageFromVideoUrl()
    }
  }
}
