<template>
  <div
    :class="{'gc-navigator-bottom-bar__link--active': active }"
    class="gc-navigator-bottom-bar__link gc-navigator-bottom-bar__link--workout"
    @click="clicked()">
    <div class="gc-navigator-bottom-bar__icon">
      <icon-coaching
        v-if="active"
        :size="'md1'"
        class="gc-navigator-bottom-bar__link-icon gc-navigator-bottom-bar__link-icon--active"/>
      <icon-coaching
        v-else
        :size="'md1'"
        class="gc-navigator-bottom-bar__link-icon"/>
      <icon-underline/>
    </div>
  </div>
</template>
<script>
import IconCoaching from '../../../root/icons/IconCoaching'
import IconDot from '../../../root/icons/IconDot'
import IconUnderline from '@/components/root/icons/IconUnderline.vue'

export default {
  name: 'NavigatorBottomBarLinkCoaching',
  components: {
    IconUnderline,
    IconDot,
    IconCoaching
  },
  props: {
    active: {
      default: false
    }
  },
  methods: {
    clicked () {
      this.$emit('click')
    }
  }
}
</script>
