<template>
    <transition name="fade" mode="out-in">
      <component v-bind:is="dynamicComponent" v-bind="{...componentData,'pageConfig': getPageConfigByName(this.page) }"
                 v-on:submit="formSubmit($event)"></component>
    </transition>
</template>

<script>
import BaseComponent from '../global/base/BaseComponent'
import NutritionalPreference from '../../../global/pages/PageMealCategory'
import Allergies from '../../../global/pages/PageDisliking'
import DGoal from '../../../global/pages/PageDietaryGoal'
import MealsPerDay from '../../../global/pages/PageMealsPerDay'
import VegetarianDays from '../../../global/pages/PageVegetarianDays'
import ProgressCheckInService from '../../../../services/ui/forms/ProgressCheckInService'
import Measurements from './PageBodyMeasurements'
import { mapGetters, mapMutations, mapState } from 'vuex'
import PageMemberConnected from '../../../global/pages/PageMemberConnected'
import ConnectionService from '../../../../services/meal-plan-pair/ConnectionService'
import PairService from '../../../../services/meal-plan-pair/PairService'
import PageMealTypesPairing from '../../../global/pages/PageMealTypesPairing'
import DashBoardService from '../../../../services/DashboardService'
import PagePreferredRecipes from '../../../global/pages/PagePreferredRecipes'
import PageMealPlanDays from '../../../global/pages/PageMealPlanDays'
import { enableSmartCheckingForActivityLvl, showMealPlanDays } from '@/includes/TemplateSettings'
import ActivityLevel from '@/components/global/pages/PageActivityLevel'

export default {
  name: 'MealPlanMain',
  extends: BaseComponent,
  data: function () {
    return {
      components: {
        'progress-member-connected': {
          component: { design1: PageMemberConnected },
          querySection: 'member-connected',
          options: {},
          init: async function (resolve, reject, service, data) {
            if (!data.mealPlanConnect) {
              reject(new Error('Meal plan pair not enabled'))
            }

            resolve()
          },
          onSubmit: function () {
          },
          getNextComponent: function (resolve, reject, service) {
            resolve({ component: NutritionalPreference, querySection: { page: 'diet', section: 'diet-type' } })
          }
        },
        'progress-diet-type': {
          component: { design1: NutritionalPreference },
          querySection: 'diet-type',
          options: {},
          init: async function (resolve, reject, service, data) {
            if (data.mealPlanConnect) {
              reject(new Error('Meal plan pair is enabled'))
            }

            this.options = service.getDietTypeFormData()
            resolve()
          },
          onSubmit: function () {
          },
          getNextComponent: function (resolve, reject, service) {
            service.setNutritionalPrefData().then(() => {
              resolve({ component: DGoal, querySection: { page: 'diet', section: 'goal' } })
            })
          }
        },
        'progress-food-dont-like': {
          component: { design1: Allergies },
          querySection: 'food-do-not-like',
          options: {
            title: {
              main: '',
              sub: ''
            },
            inputKey: 'excluded_ingredients',
            inputValue: '',
            validationRules: 'required',
            options: []
          },
          init: async function (resolve, reject, service, data) {
            if (data.mealPlanConnect) {
              reject(new Error('Meal plan pair is enabled'))
            }

            if (!service.showIngredientDislikes()) {
              reject(new Error('skip progress-food-dont-like'))
            } else {
              this.options = service.getAllergiesFormData()
              resolve()
            }
          },
          onSubmit: function () {
          },
          getNextComponent: function (resolve, reject, service) {
            resolve({ component: PagePreferredRecipes, querySection: { page: 'diet', section: 'preferred-recipes' } })
          }
        },
        'progress-vegetarian-days': {
          component: { design1: VegetarianDays },
          querySection: 'vegetarian-days',
          options: {},
          init: function (resolve, reject, service, data) {
            if (service.DietCategoryOptions.vegetarian[service.dietary.category].length === 0) {
              reject(new Error('no vege days available'))
            } else {
              this.options = service.getVegetarianDaysFormData()
              resolve()
            }
          },
          onSubmit: function () {
          },
          getNextComponent: function (resolve, reject, service) {
            resolve({ component: Measurements, querySection: { page: 'measurements' } })
          }
        },
        'progress-dietary-goal': {
          component: { design1: DGoal },
          querySection: 'goal',
          options: {},
          init: function (resolve, reject, service, data) {
            this.options = service.getDietaryGoalFormData()

            resolve()
          },
          onSubmit: function () {
          },
          getNextComponent: function (resolve, reject, service) {
            resolve({ component: ActivityLevel, querySection: { page: 'diet', section: 'activity-level' } })
          }
        },
        'progress-activity-level': {
          component: { design1: ActivityLevel },
          querySection: 'activity-level',
          options: {},
          init: function (resolve, reject, service, data) {

              if (!service.isSelectedGoalMultiplierOne() && enableSmartCheckingForActivityLvl()) {
                reject(new Error('Smart Checking Enabled'))
              }else {
                this.options = service.getActivityLevelFormData()
                resolve()
              }
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({ component: Allergies, querySection: { page: 'diet', section: 'food-do-not-like' } })
          }
        },
        'preferred-recipes': {
          component: { design1: PagePreferredRecipes },
          querySection: 'preferred-recipes',
          init: function (resolve, reject, service, data) {
            const connectionService = new ConnectionService()
            const pairService = new PairService()
            if (service.showPreferredRecipes()) {
              if (pairService.isPaired()) {
                if (connectionService.isOwner()) {
                  this.options = service.getPagePreferredRecipesData()
                  resolve()
                } else {
                  reject(new Error('Not Manager'))
                }
              } else {
                this.options = service.getPagePreferredRecipesData()
                resolve()
              }
            } else {
              reject(new Error('Disabled Preferred Recipes'))
            }
          },
          onSubmit: function () {
          },
          getNextComponent: function (resolve, reject, service) {
            resolve({ component: MealsPerDay, querySection: { page: 'diet', section: 'meals-pre-day' } })
          }
        },
        'progress-meals-per-day': {
          component: { design1: MealsPerDay },
          querySection: 'meals-pre-day',
          options: {},
          init: function (resolve, reject, service, data) {
            this.options = service.getMealsPreDayFormData()
            resolve()
          },
          onSubmit: function () {
          },
          getNextComponent: function (resolve, reject, service) {
            resolve({ component: PageMealPlanDays, querySection: { page: 'diet', section: 'meal-plan-days' } })
          }
        },
        'progress-meal-plan-days': {
          component: { design1: PageMealPlanDays },
          querySection: 'meal-plan-days',
          options: {},
          init: function (resolve, reject, service, data) {
            if (!showMealPlanDays()) {
              reject(new Error('Mea lPlan Days is hidden'))
            }
            const connectionService = new ConnectionService()
            if (connectionService.isPartnerConnected()) {
              if (!connectionService.isOwner()) {
                reject(new Error('Not Manager'))
              }
            }
            this.options = service.getMealPlanDaysFormData()
            resolve()
          },
          onSubmit: function () {
          },
          getNextComponent: function (resolve, reject, service) {
            resolve({ component: PageMealTypesPairing, querySection: { page: 'diet', section: 'meal-types-pairing' } })
          }
        },
        'progress-meal-types-pairing': {
          component: { design1: PageMealTypesPairing },
          querySection: 'meal-types-pairing',
          options: {
            mealsPerDay: 3
          },
          init: function (resolve, reject, service, data) {
            if (!data.mealPlanConnect) {
              reject(new Error('Meal plan pair not enabled'))
            }
            this.options.mealsPerDay = service.dietary.meals_per_day
            resolve()
          },
          onSubmit: function () {
          },
          getNextComponent: function (resolve, reject, service) {
            resolve({ component: VegetarianDays, querySection: { page: 'diet', section: 'vegetarian-days' } })
          }
        }
      },
      currentComponent: { design1: NutritionalPreference },
      dynamicComponent: '',
      componentData: {},
      mealPlanConnect: false
    }
  },
  async mounted () {
    await this.setMealPlanConnectStatus()
    if (this.getService() === null) {
      const progressService = new ProgressCheckInService()
      const payload = { type: (this.$route.name === 'MealPlanUpdateMain') ? 'diet' : 'fitness' }
      progressService.getDataFromAPI(payload).then(data => {
        this.setService(progressService)
        this.loadComponent(this.getService())
      }).catch(() => {
        this.showServerErrorPopup()
        this.hideLoading()
      })
    } else {
      this.loadComponent(this.getService())
    }
  },
  computed: {
    ...mapState({
      formStatus: 'formWizardStore/formStatus'

    }),
    page () {
      for (const [key, value] of Object.entries(this.components)) {
        if (value.querySection === this.$route.query.section) {
          return key
        }
      }
      return 'progress-member-connected'
    }
  },
  methods: {
    ...mapGetters({
      getService: 'formWizardStore/getProgressFormService'
    }),
    ...mapMutations({
      setService: 'formWizardStore/setProgressFormService',
      showServerErrorPopup: 'showServerErrorPopup',
      setFormStatus: 'formWizardStore/setFormStatus'
    }),
    async setMealPlanConnectStatus () {
      const dashBoardService = new DashBoardService()
      if (!dashBoardService.isMealPlanPairEnabled()) {
        return
      }

      const connectionService = new ConnectionService()
      const pairService = new PairService()

      await Promise.all([connectionService.init(), pairService.init()])
        .catch(() => {
          this.hideLoading()
        })

      if (!connectionService.isPartnerConnected()) {
        return
      }

      if (connectionService.isOwner()) {
        return
      }

      if (!pairService.ownerPlanIsValid()) {
        return
      }

      this.mealPlanConnect = true
    },
    loadComponent (service) {
      const curComponent = this.getComponentBySection()
      const componentData = new Promise((resolve, reject) => {
        curComponent.init(resolve, reject, service, {
          mealPlanConnect: this.mealPlanConnect
        })
      })
      this.currentComponent = curComponent
      componentData.then(next => {
        this.componentData = curComponent.options
        this.dynamicComponent = this.getComponentByConfig()
        this.hideLoading()
      }).catch(() => {
        this.nextComponent(true)
        this.hideLoading()
      })
    },
    formSubmit (data) {
      this.setKeyData(data.key, data.value)
      this.nextComponent()
    },
    getComponentBySection () {
      return this.components[this.page]
    },
    getComponentByComponentObject (component) {
      for (const [key, value] of Object.entries(this.components)) {
        // TODO -fix
        // eslint-disable-next-line no-unused-vars
        for (const [ckey, cvalue] of Object.entries(value.component)) {
          if (cvalue === component) {
            return this.components[key]
          }
        }
      }
      return this.components['progress-diet-type']
    },
    setKeyData (key, value) {
      const service = this.getService()
      if (key === 'excluded_ingredients') {
        service.setIngredientsSelected(value)
      }

      var isValueChanged = this.checkValueChange(service.dietary[key], value, key)
      this.logEvent('ProgressUpdate.StepComplete', {type: this.$route.path.split('/')[1], step: key, value_changed: isValueChanged}, false)

      service.dietary[key] = value
    },

    checkValueChange (oldValue, newValue, key) {
      if (key === 'excluded_ingredients') {
        let check = this.arrayEquals(oldValue, newValue)
        return !check
      } else {
        if (newValue === oldValue) {
          return false
        } else {
          return true
        }
      }
    },

    arrayEquals (a, b) {
      return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    },

    getCurrentComponent () {
      return this.currentComponent
    },
    nextComponent (replace = false) {
      const next = new Promise((resolve, reject) => {
        this.getCurrentComponent().getNextComponent(resolve, reject, this.getService())
      })
      next.then(nextComponent => {
        const routeObj = {
          path: this.$route.path,
          query: nextComponent.querySection
        }

        if (replace) {
          this.$router.replace(routeObj)
        } else {
          this.$router.push(routeObj)
        }
      })
    },
    getComponentByConfig () {
      const pageConfig = this.getPageConfigByName(this.page)
      const pageComponents = this.getComponentBySection().component

      if (pageConfig && pageConfig.variation) {
        return pageComponents[pageConfig.variation] || pageComponents.design1
      } else {
        return pageComponents.design1
      }
    }
  }
}
</script>
