<template>
  <page class="pc-subscription-start" :desktop-side-bar="false" :header="headerOptions" :background-image="backgroundImage">

    <page-container>
      <div class="pc-subscription-start__card-feature-box">
        <text-heading4
          :lineHeight="'multi'"
          :weight="'extra-bold'"
          class="pc-subscription-start__card-feature-box__title"
          v-if="hasGreetingText()"
          v-html="$t('message[\'subscriptions.start-title\']',{name:userName})"/>
        <text-body-regular
          :lineHeight="'multi'"
          class="pc-subscription-start__card-feature-box__sub-text"
          v-html="$t('message[\'subscriptions.start-description\']')"/>
        <button-primary
          @click="nextComponent"
          :text="$t('message[\'subscriptions.start-button\']')"/>
      </div>

    </page-container>
    <div class="app-bg-overlay"></div>
  </page>
</template>

<script>
import BaseComponent from '../global/widgets/forms/FormBaseComponent'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import TextHeading4 from '../../../global/typography/TextHeading4'
import TextBodyRegular from '../../../global/typography/TextBodyRegular'
import ButtonPrimary from '../../../global/buttons/ButtonPrimary'
import Page from '../../../global/pages/PageImageCover'
import { pageReadyEvent, subscriptionSettingsPageLoadedEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'PageSubscriptionStart',
  extends: BaseComponent,
  props: {
    userName: {
      default: ''
    }
  },
  components: {Page, ButtonPrimary, TextBodyRegular, TextHeading4, PageContainer},
  validated: {
    status: true
  },
  data: function () {
    return {
      logoShow: true,
      logoStyle: {
        visibility: 'hidden'
      },
      logo: '',
      backgroundImage: '',
      pageClass: ['macroapp--page-start'],
      headerOptions: {
        show: true,
        left: '',
        right: '',
        modifiers: ['with-img'],
        mainTitle: '',
        subTitle: '',
        isTransparent: true
      }
    }
  },
  watch: {
    isDesktop: function () {
      this.headerOptions.show = !this.isDesktop
    }
  },
  beforeMount () {
    this.headerOptions.show = !this.isDesktop
  },
  created () {
    this.setImages()
  },
  mounted () {
    subscriptionSettingsPageLoadedEvent()
  },
  methods: {
    hasGreetingText () {
      let html = this.$i18n.t('message[\'subscriptions.start-greeting\']', {name: this.userName})
      let div = document.createElement('div')
      div.innerHTML = html
      let text = div.textContent || div.innerText || ''
      return Boolean(text)
    },
    nextComponent () {
      this.$emit('submit', {})
    },
    loaded () {
      this.hideLoading()
      pageReadyEvent()
    },
    setImages () {
      this.backgroundImage = this.getStoreImage('onboard.startBackground')
    }
  }
}
</script>
