<template>
    <div
      :class="componentClasses"
      :style="componentStyles"
      @click="clicked()"
    >
      <text-content
        class="gc-input-meal-category-card__text"
        :weight="'extra-bold'"
        :line-height="'multi'"
        :size="'sm4'"
      >
        {{ text }}
      </text-content>
    </div>
</template>

<script>
import TextContent from '../../../root/TextContent'

export default {
  name: 'InputMealCategoryCard',
  components: {
    TextContent
  },
  props: {
    id: {
      default: 0
    },
    text: {
      default: ''
    },
    image: {
      default: ''
    },
    value: {
      default: 0
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-input-meal-category-card': true,
        'gc-input-meal-category-card--selected': this.value === this.id
      }
    },
    componentStyles: function () {
      return {
        'background-image': `linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(${this.image})`
      }
    }
  },
  methods: {
    clicked () {
      this.$emit('click')
      /**
       * emits the "id" to parent element
       * */
      this.$emit('input', this.id)
    }
  }
}
</script>

<style scoped></style>
