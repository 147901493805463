<template>
  <div class="pc-workout-log-history-statistics">
        <icon-trophy class="pc-workout-log-history-statistics__icon" :size="'lg4'" />
        <text-body-regular class="pc-workout-log-history-statistics__title" :line-height="'multi'" :weight="'extra-bold'">
          {{ title }}
        </text-body-regular>
      <text-heading-3 class="pc-workout-log-history-statistics__best-score" :line-height="'multi'" :weight="'extra-bold'">
        {{ bestScore }}
      </text-heading-3>
    <text-body-regular class="pc-workout-log-history-statistics__mean-score" :line-height="'multi'" :weight="'extra-bold'">
      {{ meanScore }}
    </text-body-regular>
  </div>
</template>

<script>
import TextBodyRegular from '../../../../../global/typography/TextBodyRegular'
import TextHeading3 from '../../../../../global/typography/TextHeading3'
import IconTrophy from '../../../../../root/icons/IconTrophy'

export default {
  components: {
    IconTrophy,
    TextBodyRegular,
    TextHeading3
  },
  props: {
    title: '',
    bestScore: '',
    meanScore: ''
  }

}
</script>
