<template>
  <div :class="componentClasses">
    <div v-if="selectedMeasurementSystem === 'metric'"
         @click="focusInputOnClick('input-cm')"
         class="gc-input-height__input-wrapper__cm row">
      <input
        class="gc-input-height__input col-6"
        maxlength="4"
        :placeholder="placeholder"
        :disabled="disabled"
        v-model="cmValue"
        type="number"
        step="0.01"
        ref="inputCm"
      />
      <text-content
        class="gc-input-height__unit col-6"
        :weight="'extra-bold'"
        :size="'sm4'"
        :lineHeight="'multi'">
        {{$t('message["general.cm"]')}}
      </text-content>
    </div>
    <div v-if="selectedMeasurementSystem === 'metric'">
      <text-content
        v-if="errorMessages.length > 0"
        class="gc-input-height__error-message"
        :weight="'medium'"
        :size="'sm1'"
        :lineHeight="'multi'">
        {{ errorMessages }}
      </text-content>
    </div>
    <div class="gc-input-height__input-wrapper__ft-in__inputs-wrapper"
         v-if="selectedMeasurementSystem === 'imperial'">
      <div @click="focusInputOnClick('input-ft')"
           class="gc-input-height__input-wrapper__ft row">
        <input
          class="gc-input-height__input col-10"
          maxlength="2"

          :placeholder="placeholder"
          :disabled="disabled"
          v-model="ftValue"
          type="number"
          ref="inputFt"
        />
        <text-content
          class="gc-input-height__unit col-2"
          :weight="'extra-bold'"
          :size="'sm4'"
          :lineHeight="'multi'">
          {{$t('message["general.ft"]')}}
        </text-content>
      </div>
      <div @click="focusInputOnClick('input-in')"
           class="gc-input-height__input-wrapper__in row">
        <input
          class="gc-input-height__input col-10"
          maxlength="2"
          :placeholder="placeholder"
          :disabled="disabled"
          v-model="inValue"
          type="number"
          ref="inputIn"
        />
        <text-content
          class="gc-input-height__unit col-2"
          :weight="'extra-bold'"
          :size="'sm4'"
          :lineHeight="'multi'">
            {{$t('message["general.inch"]')}}
        </text-content>
      </div>
    </div>
    <div class="gc-input-height__error-message-wrapper__ft-in" v-if="selectedMeasurementSystem === 'imperial'">
      <text-content
        v-if="errorMessages.length > 0"
        class="gc-input-height__error-message"
        :weight="'medium'"
        :size="'sm1'"
        :lineHeight="'multi'">
        {{ errorMessages }}
      </text-content>
    </div>
    <div class="gc-input-height__unit-selector-wrapper" v-if="measurementSystemChangeable">
      <div
        class="gc-input-height__unit-selector__unit gc-input-height__unit-selector__unit-ft-in"
        v-bind:class="{ 'gc-input-height__unit-selector__unit--selected': this.selectedMeasurementSystem === 'imperial' }"
        @click="changeUnit('imperial')">
        <icon-down-arrow-filled
          v-if="selectedMeasurementSystem === 'imperial'"
          :size="'sm1'"
          class="gc-input-height__unit-selector__unit-ft-in__arrow" />
        <text-content
          class="gc-input-height__unit-selector__unit-ft-in__text"
          :weight="'extra-bold'"
          :size="'sm4'"
          :lineHeight="'multi'">
          {{$t('message["general.ft"]')}} {{$t('message["general.inch"]')}}
        </text-content>
      </div>
      <div
        class="gc-input-height__unit-selector__unit gc-input-height__unit-selector__unit-cm"
        v-bind:class="{ 'gc-input-height__unit-selector__unit--selected': this.selectedMeasurementSystem === 'metric' }"
        @click="changeUnit('metric')">
        <icon-down-arrow-filled
          v-if="selectedMeasurementSystem === 'metric'"
          :size="'sm1'"
          class="gc-input-height__unit-selector__unit-cm__arrow" />
        <text-content
          class="gc-input-height__unit-selector__unit-cm__text"
          :weight="'extra-bold'"
          :size="'sm4'"
          :lineHeight="'multi'">
          {{$t('message["general.cm"]')}}
        </text-content>
      </div>
    </div>
  </div>
</template>

<script>
import TextContent from '../../root/TextContent'
import IconDownArrowFilled from '../../root/icons/IconDownArrowFilled'

export default {
  name: 'InputHeight',
  components: {
    TextContent,
    IconDownArrowFilled
  },
  data: function () {
    return {
      cmValue: null,
      ftValue: null,
      inValue: null,
      lastCmValue: null,
      lastFtValue: null,
      lastInValue: null,
      isValueChanged: false,
      selectedMeasurementSystem: 'metric',
      haveError: false,
      curruntValue: 0
    }
  },
  props: {
    measurementSystemChangeable: {
      default: true
    },
    value: {
      default: null
    },
    minValue: {
      type: Number,
      default: 0
    },
    maxValue: {
      type: Number,
      default: 999
    },
    errorMessages: {
      default: ''
    },
    placeholder: {
      default: '0'
    },
    disabled: {
      default: false
    },
    measurementSystem: { // expected values - cm , ft-in
      default: 'metric'
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-input-height': true,
        'gc-input-height__error': this.errorMessages.length > 0,
        'gc-input-height__disabled': this.disabled
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function () {
        if (this.value.preference === 'imperial') {
          this.selectedMeasurementSystem = 'imperial'
          this.inValue = this.value.inch
          this.ftValue = this.value.feet
        } else {
          this.selectedMeasurementSystem = 'metric'
          this.cmValue = this.value.cm
        }
      }
    },
    measurementSystem: {
      immediate: true,
      handler: function () {
        if (this.measurementSystem === 'imperial') {
          this.selectedMeasurementSystem = 'imperial'
        } else {
          this.selectedMeasurementSystem = 'metric'
        }
      }
    },
    cmValue: function () {
      this.isValueChanged = true
      if (this.cmValue < this.minValue) {
        this.cmValue = this.minValue
      } else if (this.cmValue > this.maxValue) {
        this.cmValue = this.lastCmValue
      } else {
        this.lastCmValue = this.cmValue
      }
      this.emitMetricValues()
    },
    ftValue: function () {
      this.isValueChanged = true
      if (this.ftValue < 4) {
        this.ftValue = this.minValue
      } else if (this.ftValue > 8) {
        this.ftValue = this.lastFtValue
      } else {
        this.lastFtValue = this.ftValue
      }
      this.emitImperialValue()
    },
    inValue: function () {
      this.isValueChanged = true
      if (this.inValue < 0) {
        this.inValue = this.minValue
      } else if (this.inValue > 12) {
        this.inValue = this.lastInValue
      } else {
        this.lastInValue = this.inValue
      }
      this.emitImperialValue()
    }
  },
  methods: {
    changeUnit (unit) {
      if (!this.disabled) {
        this.selectedMeasurementSystem = unit
        this.$emit('unit', this.selectedMeasurementSystem)
        if (this.selectedMeasurementSystem === 'imperial') {
          this.emitImperialValue()
        } else {
          this.emitMetricValues()
        }
      }
    },
    focusInputOnClick (inputName) {
      if (inputName === 'input-in') {
        this.$refs.inputIn.focus()
      } else if (inputName === 'input-ft') {
        this.$refs.inputFt.focus()
      } else {
        this.$refs.inputCm.focus()
      }
    },
    emitImperialValue () {
      let totalInches = ((parseFloat(this.ftValue) * 12) || null) + (parseFloat(this.inValue) || null)
      this.$emit('input', {
        preference: this.selectedMeasurementSystem,
        feet: parseFloat(this.ftValue) || 0,
        inch: parseFloat(this.inValue) || 0,
        totalInch: totalInches || 0,
        cm: 0
      })
    },
    emitMetricValues () {
      this.$emit('input', {
        preference: this.selectedMeasurementSystem,
        feet: 0,
        inch: 0,
        totalInch: 0,
        cm: parseFloat(this.cmValue) || 0
      })
    }
  }
}
</script>
