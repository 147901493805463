import PlanService from './PlanService'

export default class PlanOneOffService extends PlanService {
  /**
   * check the plan is canceled one off can't cancel. So return value is fasle
   * @returns {boolean} false
   */
  isCancelled () {
    return false
  }

  getPlanType() {
    return 'one-off'
  }
}
