<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.07692 14C7.84695 14 7.62523 13.9188 7.45245 13.7714C6.79987 13.2156 6.17071 12.6932 5.61562 12.2324L5.61278 12.23C3.98534 10.879 2.57999 9.71231 1.60217 8.56301C0.509121 7.27817 0 6.05996 0 4.72914C0 3.43613 0.45514 2.24325 1.28149 1.37008C2.1177 0.486588 3.26511 0 4.5127 0C5.44517 0 6.29913 0.287175 7.05079 0.85348C7.43014 1.13933 7.77399 1.48918 8.07692 1.89725C8.37998 1.48918 8.72371 1.13933 9.10318 0.85348C9.85484 0.287175 10.7088 0 11.6413 0C12.8887 0 14.0363 0.486588 14.8725 1.37008C15.6988 2.24325 16.1538 3.43613 16.1538 4.72914C16.1538 6.05996 15.6448 7.27817 14.5518 8.56289C13.574 9.71231 12.1687 10.8789 10.5416 12.2298C9.98548 12.6913 9.35533 13.2145 8.70128 13.7717C8.52861 13.9188 8.30677 14 8.07692 14ZM4.5127 0.921792C3.53254 0.921792 2.63212 1.30285 1.97708 1.99485C1.3123 2.6973 0.946145 3.66832 0.946145 4.72914C0.946145 5.84842 1.37319 6.84945 2.33067 7.97486C3.25611 9.06269 4.63262 10.2054 6.22641 11.5285L6.22937 11.5309C6.78656 11.9935 7.41818 12.5179 8.07557 13.0778C8.7369 12.5168 9.36951 11.9916 9.9278 11.5283C11.5215 10.2051 12.8979 9.06269 13.8233 7.97486C14.7807 6.84945 15.2077 5.84842 15.2077 4.72914C15.2077 3.66832 14.8415 2.6973 14.1768 1.99485C13.5218 1.30285 12.6213 0.921792 11.6413 0.921792C10.9232 0.921792 10.264 1.14414 9.68193 1.58258C9.1632 1.97348 8.80184 2.46764 8.58999 2.8134C8.48104 2.9912 8.28927 3.09733 8.07692 3.09733C7.86457 3.09733 7.67281 2.9912 7.56386 2.8134C7.35213 2.46764 6.99077 1.97348 6.47192 1.58258C5.88983 1.14414 5.2306 0.921792 4.5127 0.921792Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconFavoriteLinear',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-favorite-linear': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
