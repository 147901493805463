<template>
  <div
    :class="componentClasses"
    v-on="$listeners"
    v-bind="$attrs">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'PageContainerFluid',
  props: {
    /**
       * container will be Fluid until comes to the specific value
       *  xs - Extra small <576px
       *  sm - Small ≥576px
       *  md - Medium  ≥768px
       *  lg - Large ≥992px
       *  xl - Extra large ≥1200px
       */
    breakpoint: {
      default: 'sm',
      type: String
    },
    /**
     * reverse logic of fluid
     */
    reverse: {
      default: false
    }
  },
  data: function () {
    return {
      windowWidth: window.innerWidth
    }
  },
  computed: {
    componentClasses: function () {
      let classes = ['gc-page__container-fluid']
      let isFluid = false
      if ((this.breakpoint === 'sm') ||
          (this.windowWidth >= 768 && this.breakpoint === 'md') ||
          (this.windowWidth >= 992 && this.breakpoint === 'lg') ||
          (this.windowWidth >= 1200 && this.breakpoint === 'xl')) {
        isFluid = true
      }

      if ((isFluid && !this.reverse) || (!isFluid && this.reverse)) {
        classes = classes.concat(['container-fluid', 'p-0', 'm-0'])
      } else {
        classes.push('container')
      }
      return classes
    }

  },
  beforeMount () {
    window.addEventListener('resize', this.setWindowWidth)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.setWindowWidth)
  },
  methods: {
    setWindowWidth () {
      this.windowWidth = window.innerWidth
    }
  }

}
</script>

<style scoped>

</style>
