<template>
  <div>
    <card-meal
      :title="name"
      :images-src="images">
      <card-meal-action-remove @click="showDeletePopup"/>
    </card-meal>

  </div>
</template>
<script>
import CardMeal from '../../../../../global/cards/CardMeal'
import CardMealActionRemove from '../../../../../global/cards/card-meal-sub-components/CardMealActionRemove'
import lodash from 'lodash'

export default {
  name: 'card-meal-favorite',
  components: {
    CardMeal,
    CardMealActionRemove
  },
  props: {
    favorite: {}

  },
  computed: {
    images: function () {
      let imgs = []
      this.favorite.recipes.forEach(recipe => {
        if (recipe.images && recipe.images[0]) {
          imgs.push(recipe.images[0].url)
        }
      })
      imgs = imgs.slice(0, 3)
      let sortedImgs = lodash.sortBy(imgs)
      return sortedImgs
    },
    name: function () {
      return this.favorite.recipes[0].name +
        (this.favorite.recipes.length > 1
          ? ' + ' +
          (this.favorite.recipes.length - 1) +
          ' ' +
          this.$i18n.t('message[\'general.more-text\']')
          : '')
    }
  },
  methods: {
    showDeletePopup () {
      this.$emit('delete', true)
    }
  }
}
</script>
