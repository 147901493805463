<template>
    <div class="pc-navigation-tabs-secondary" v-on="$listeners">
      <slot>  </slot>
    </div>
</template>

<script>
export default {
  name: 'NavigationTabsSecondary'
}
</script>

<style scoped>

</style>
