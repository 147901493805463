<template>
  <page-secondary :header="headerOptions" class="pc-content">
      <page-content-image v-if="mainImage" :contentImage="mainImage" />
    <page-container>
        <page-list-container :item-list="itemList" :main-image="mainImage" :description="description" :lastExpanded="lastExpanded"/>
    </page-container>
  </page-secondary>
</template>

<script>
import BaseComponent from '../global/base/BaseComponent'
import ContentPageFactoryService from '../../../../services/ContentPageFactoryService'
import PageListContainer from './page-components/PageListContainer'
import { mapGetters, mapMutations } from 'vuex'
import lodash from 'lodash'
import PageContentImage from '../../../global/pages/page-sub-components/PageContentImage'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import PageSecondary from '../../../global/pages/PageSecondary'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'
export default {
  name: 'PageList',
  extends: BaseComponent,
  components: {
    PageSecondary,
    PageListContainer,

    PageContentImage,
    PageContainer
  },
  data: function () {
    return {
      pageType: this.$route.params.contentPage,
      itemList: {},
      description: '',
      mainImage: '',
      pageClass: ['macroapp--page-content', 'macroapp--page-content-list'],
      headerOptions: {
        isTopFixed: false,
        isTransparent: false,
        isFixed: false,
        show: true,
        left: 'menu',
        right: '',
        rightBlink: false,
        modifiers: ['small-title'],
        mainTitle: '',
        subTitle: ''
      },
      lastExpanded: {}
    }
  },
  mounted () {
    this.hideLoading()
    const service = new ContentPageFactoryService()
    service.getPageList().then(data => {
      const link = service.findPageInList(this.pageType, data)
      this.setPageName(link.code)
      this.loadPageData(link.code)
      this.setOffsetTop()
    })
  },
  methods: {
    ...mapGetters({
      getLastExpanded: 'listLastExpandedStore/getLastExpanded'
    }),
    ...mapMutations({
      setPageName: 'pageStore/setPageName',
      unsetPageName: 'pageStore/unsetPageName',
      resetLastExpanded: 'listLastExpandedStore/reset',
      resetLastOffset: 'listLastExpandedStore/resetLastOffset'
    }),
    loadPageData (pageType) {
      const contentPageFactory = new ContentPageFactoryService()
      const page = contentPageFactory.getPage(pageType)
      page.setPageData().then(() => {
        const listData = page.getListData()
        this.headerOptions.mainTitle = page.pageData.page.title
        this.setPageTitle(page.pageData.page.title)
        this.description = page.pageData.page.description
        this.logPageEvent(this.description)
        this.itemList = lodash.sortBy(Object.values(listData.items), (item) => {
          return item.sequence
        })
        this.itemList = this.itemList.map(item => {
          item.subItems = lodash.sortBy(Object.values(item.subItems), (subItem) => {
            return subItem.sequence
          })
          return item
        })
        this.mainImage = listData.headerImage
        this.lastExpanded = this.getLastExpanded()
        pageReadyEvent()
      })
    },
    logPageEvent (description) {
      description = description || ''
      if (description.includes('MA:LiveStream')) {
        let path = this.$route.path.replace('/page/', '')
        this.logEvent('Temp.VisitedLiveStreamPage', {
          'slug': path
        })
      }
    },
    setOffsetTop () {
      setTimeout(function () {
        if (this.lastExpanded && this.lastExpanded.rememberTop) {
          window.scroll(0, this.lastExpanded.rememberTop)
          this.resetLastOffset()
        }
      }.bind(this), 0)
    }
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
  }
}
</script>
