/**
 *   "env" -> environment type

    "is_toning_enabled"               -> is toning API is enabled
    "toning_url"                      -> toning API url
    "api_url"                         ->  app API url
    "resources_base_url"              -> where resources are loaded icons and default css/js
    "asserts":{                       -> assert related configs
      "toning_css_injection"            -> use toning css or not
      "demo_assets"                     -> use demo assert or not
    }
 *
 * @type {{development: {asserts: {toning_css_injection: boolean, demo_assets: boolean}, is_toning_enabled: boolean, api_url: string, toning_url: string, resources_base_url: *}, production: {asserts: {toning_css_injection: boolean, demo_assets: boolean}, is_toning_enabled: boolean, api_url: string, toning_url: string, resources_base_url: string}, env: string, "toning-preview": {asserts: {toning_css_injection: boolean, demo_assets: boolean}, is_toning_enabled: boolean, api_url: string, toning_url: string, resources_base_url: *}, demo: {asserts: {toning_css_injection: boolean, demo_assets: boolean}, is_toning_enabled: boolean, api_url: string, toning_url: string, resources_base_url: *}, staging: {asserts: {toning_css_injection: boolean, demo_assets: boolean}, is_toning_enabled: boolean, api_url: string, toning_url: string, resources_base_url: string}}}
 */


const customConfig ={
  "env": "toning-preview",
  "production": {
    "is_toning_enabled":true,
    "toning_url": "https://toning.macroactivemvp.com/api/",
    "api_url":  window.location.origin + '/api/',
    "resources_base_url": "https://d63708ukmb4z7.cloudfront.net",
    "asserts":{
      "toning_css_injection" :true,
      "demo_assets" :false,
    }
  },
  "demo": {
    "is_toning_enabled":false,
    "toning_url": "https://toning.macroactivemvp.com/api/",
    "api_url": "https://platform-mock-api.macroactivemvp.com/api/",
    "resources_base_url": window.location.origin,
    "asserts":{
      "toning_css_injection" :false,
      "demo_assets" :true,
    }
  },
  "toning-preview": {
    "is_toning_enabled":false,
    "community_api_url" : "https://preview-api-staging.macroactivemvp.com/api/community/2023-08-02/",
	  "community_asset_url" : "https://app-redesign-plugin-files.s3.us-west-1.amazonaws.com/previews/staging/community/assets/",
    "toning_url": "https://test-toning.macroactivemvp.com/api/",
    "api_url": "https://preview-api-staging.macroactivemvp.com/api/",
    "resources_base_url": window.location.origin,
    "asserts":{
      "toning_css_injection" :false,
      "demo_assets" :true,
    }
  },
  "staging": {
    "is_toning_enabled":true,
    "toning_url": "https://toning.macroactivemvp.com/api/",
    "api_url": window.location.origin + '/api/',
    "resources_base_url": "https://d63708ukmb4z7.cloudfront.net",
    "asserts":{
      "toning_css_injection" :true,
      "demo_assets" :false,
    }
  },
  "development": {
    "is_toning_enabled":true,
    "toning_url": "https://toning.macroactivemvp.com/api/",
    "api_url": "https://platform-mock-api.macroactivemvp.com/api/",
    "resources_base_url": window.location.origin,
    "asserts":{
      "toning_css_injection" :false,
      "demo_assets" :false,
    }
  }
}

export  default  customConfig[customConfig['env']]
export const env = customConfig['env']
