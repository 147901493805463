<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.1464 8.05375C11.9512 7.85849 11.9512 7.5419 12.1464 7.34664C12.3417 7.15138 12.6583 7.15138 12.8536 7.34664L17.645 12.1381C17.7405 12.2292 17.8 12.3576 17.8 12.5C17.8 12.589 17.7768 12.6726 17.736 12.745C17.7138 12.7845 17.6862 12.8211 17.6536 12.8537L12.8536 17.6537C12.6583 17.849 12.3417 17.849 12.1464 17.6537C11.9512 17.4585 11.9512 17.1419 12.1464 16.9466L16.0931 13H7.70001C7.42387 13 7.20001 12.7761 7.20001 12.5C7.20001 12.2239 7.42387 12 7.70001 12H16.0927L12.1464 8.05375Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0ZM1 12.5C1 6.14873 6.14873 1 12.5 1C18.8513 1 24 6.14873 24 12.5C24 18.8513 18.8513 24 12.5 24C6.14873 24 1 18.8513 1 12.5Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconArrowCircularLinear',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-dot': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
