<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.1418 16.0127L3.53657 1.4165H2.92156L2.79598 1.7354C2.46454 2.57712 2.32292 3.43801 2.3865 4.22491C2.45897 5.12189 2.79994 5.89846 3.37261 6.47077L9.51598 12.6103L10.3263 11.8005L16.0058 18.1474C16.5708 18.712 17.5433 18.7455 18.1418 18.1474C18.7307 17.5588 18.7307 16.6012 18.1418 16.0127Z" fill="black"/>
      <path d="M6.66962 11.1884L1.85816 15.9969C1.26929 16.5854 1.26929 17.543 1.85816 18.1315C2.41757 18.6906 3.38533 18.7399 3.99413 18.1315L8.80562 13.323L6.66962 11.1884Z" fill="black"/>
      <path d="M17.86 4.27485L15.1307 7.00245L14.4186 6.29089L17.148 3.56326L16.436 2.8517L13.7067 5.5793L12.9947 4.86774L15.724 2.14014L15.012 1.42862L11.452 4.9864C11.0165 5.42165 10.7565 5.99956 10.7197 6.61375C10.7104 6.76936 10.6751 6.92242 10.6165 7.068L12.9291 9.37914C13.0748 9.32056 13.228 9.28532 13.3837 9.27604C13.9982 9.23939 14.5765 8.97943 15.012 8.54419L18.572 4.98644L17.86 4.27485Z" fill="black"/>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconMeal',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-meal': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
