<template>
    <svg fill="none" height="9" viewBox="0 0 15 9" width="15" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.7216 0.278285C14.5361 0.0927104 14.3163 0 14.0624 0H0.937571C0.683502 0 0.46386 0.0927104 0.278285 0.278285C0.0927104 0.464065 0 0.683707 0 0.937622C0 1.19149 0.0927104 1.41113 0.278285 1.59675L6.84071 8.15918C7.02649 8.34476 7.24614 8.43767 7.5 8.43767C7.75386 8.43767 7.97371 8.34476 8.15913 8.15918L14.7216 1.5967C14.9069 1.41113 15 1.19149 15 0.93757C15 0.683707 14.9069 0.464065 14.7216 0.278285Z"
              fill="#868686"/>
    </svg>
</template>
<script>
export default {
  name: 'downTriangleSvg'
}
</script>
