<template>
    <popup class="pc-popup-member-disconnect-confirm" v-if="visible" :visible="visible" size="md" @hide="closePopup()">
        <popup-title>
            <popup-button-close  @click="closePopup"></popup-button-close>
        </popup-title>
        <popup-body>
            <popup-content class="pc-popup-member-disconnect-confirm__content">
                <popup-head class="pc-popup-member-disconnect-confirm__head">
                    <popup-text-heading class="pc-popup-member-disconnect-confirm__title">
                        {{ $t('message[\'member-connect.disconnected\']') }}
                    </popup-text-heading>
                    <popup-text-body>
                        <div v-html="$t('message[\'member-connect.disconnected-description\']', [name])"></div>
                    </popup-text-body>
                </popup-head>
                <card-member-small :name="name" :email="email" :avatar="avatar"></card-member-small>
            </popup-content>
            <popup-footer>
                <button-primary
                        :text="$t('message[\'general.understood\']')"
                        v-on:click="closePopup"/>
            </popup-footer>
        </popup-body>
    </popup>
</template>

<script>
import Popup from '../../../../global/popups/Popup'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupTextBody from '../../../../global/popups/popup-sub-components/PopupTextBody'
import PopupFooter from '../../../../global/popups/popup-sub-components/PopupFooter'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import CardMemberSmall from '../../../../global/cards/CardMemberSmall'
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import ConnectionService from '../../../../../services/meal-plan-pair/ConnectionService'

export default {
  name: 'MemberDisconnected',
  props: {
    avatar: {
      default: ''
    },
    name: {
      default: ''
    },
    email: {
      default: ''
    }
  },
  mixins: [popupAnimationMixin],
  components: {
    CardMemberSmall,
    Popup,
    PopupTitle,
    PopupButtonClose,
    PopupBody,
    PopupContent,
    PopupHead,
    PopupTextHeading,
    PopupTextBody,
    PopupFooter,
    ButtonPrimary
  },
  data () {
    return {
      connectionService: new ConnectionService(),
      visible: false
    }
  },
  async mounted () {
    await this.connectionService.init({forceLoad: true})

    if (
      !(this.connectionService.isPartnerConnected() &&
            this.connectionService.getPartner().getEmail() === this.email)
    ) {
      // show popup if current user is not the disconnected user
      this.visible = true
    }
  },
  methods: {
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.visible = false
        this.$emit('close')
      })
    }
  }
}
</script>

<style scoped>

</style>
