import SubscriptionAPI from '../../api/subscription/SubscriptionAPI'
import activityLevel from '../../../config/activity-levels'
import { i18n } from '../../../helpers/localization/i18n'
import NutritionCategoryAPI from '../../api/subscription/NutritionCategoryAPI'
import { AppConfig } from '../../AppConfig'
import UnitHelper from '../../helpers/UnitHelper'
import FormOptionHelper from '../../helpers/FormOptionHelper'
import DashBoardService from '../../DashboardService'
import ActivityTracker from '../../ActivityTracker'
import { enableGroupedIngredients, flagValue } from '../../../includes/TemplateSettings'
import store from '../../../store'
import IngredientService from '../../IngredientService'
import * as Sentry from '@sentry/browser'

export default class SubscriptionService {
  subscriptionID = 0
  data = {};
  errors = {};
  values = {
    gender: ' ',
    system: 'metric',
    workouts: [],
    extraFields: [],
    days_per_mealplan: 7
  };

  _myData = null;
  options = {
    genders: [],
    location: [],
    experienceLevel: [],
    mpd: {},
    vegetarian: {},
    dGoals: {},
    ingredients: [],
    injuries: [],
    workouts: [],
    extraFields: []
  };

  _configs = {
    defaultGender: 'f',
    defaultMeasurementSystem: 'metric',
    showIngredientDislikes: true,
    showInjuries: true,
    showMeasurementSystem: true,
    showFatQuestion: false,
    showPreferredRecipes: false
  }

  _data = null;

  /**
   * load the subscription and other data from the APIs
   * @param payload object { id : int }
   * @returns {Promise<unknown>}
   */
  getDataFromAPI (payload) {
    return new Promise((resolve, reject) => {
      Promise.all([this.getMyData(), this.getSubscriptionData(payload), this.getNutritionalPref(), this.getAllergies(), this.getConfig()]).then(() => {
        this.hasMealPlans()
        resolve()
      }).catch((err) => {
        reject(err)
      })
    })
  }

  /**
   *  get the subscription data and map them to options
   * @param payload
   * @returns {Promise<unknown>}
   */
  getSubscriptionData (payload) {
    const id = payload.id
    this.subscriptionID = parseInt(payload.id) || 0
    return new Promise((resolve, reject) => {
      if (this._data === null) {
        const service = new SubscriptionAPI()
        service.get(id).then(data => {
          this._data = data.data
          this.setSubscriptionOptionsFields()
          resolve(data.data)
        }).catch(err => {
          if (err.response.status === 404) {
            store.commit('showNotFoundPopupShow')
          }
          reject(err)
        })
      } else {
        this.setSubscriptionOptionsFields()
        resolve(this._data)
      }
    })
  }

  getMyData () {
    return new Promise((resolve, reject) => {
      if (this._myData === null) {
        const dashBoardService = new DashBoardService()
        dashBoardService.getUserData(true).then(data => {
          this._myData = data
          resolve(this._myData)
        }).catch(() => {
          reject(new Error('no data'))
        })
      } else {
        resolve(this._myData)
      }
    })
  }

  /**
   * load the configurations of the subscription flow
   * @returns {Promise}
   */
  getConfig () {
    return new Promise((resolve, reject) => {
      const dashBoardService = new DashBoardService()
      dashBoardService.getUserConfig().then(() => {
        this._configs.defaultGender = (dashBoardService.getDefaultGender() === 'f') ? 'female' : 'male'
        this._configs.defaultMeasurementSystem = dashBoardService.getDefaultMeasurementSystem()

        this._configs.showIngredientDislikes = dashBoardService.isIngredientDislikesEnabled()
        this._configs.showMeasurementSystem = dashBoardService.canMeasurementSystemChangeable()
        this._configs.showInjuries = dashBoardService.isInjuriesSelectionEnabled()
        this._configs.showFatQuestion = dashBoardService.isShowFatQuestionEnabled()
        this._configs.showPreferredRecipes = dashBoardService.isPreferredRecipesEnabled()

        this.values.gender = this._configs.defaultGender
        this.values.system = this._configs.defaultMeasurementSystem
        resolve(this._configs)
      }).catch(() => {
        reject(new Error('no data'))
      })
    })
  }

  /**
   * get Nutritional categories list and map into the options
   * @returns {Promise<unknown>}
   */
  getNutritionalPref () {
    return new Promise((resolve, reject) => {
      const api = new NutritionCategoryAPI()
      api.getList().then(data => {
        this.options.nutritionalPref = data.data.categories
        resolve(this.options.nutritionalPref)
      })
    })
  }

  /**
   * get Allergies and map them in options
   * @returns {Promise<unknown>}
   */
  getAllergies () {
    const service = new IngredientService()
    return new Promise((resolve) => {
      service.setIngredients().then(() => {
        if (!enableGroupedIngredients()) {
          this.options.ingredients = Object.values(service.getIngredients()).map(option => {
            const groups = option.groups || []
            return { id: option.id, text: option.value, groups: groups }
          })
        } else {
          this.options.ingredients = service.getGroupedIngredients()
        }
        resolve(this.options.ingredients)
      })
    })
  }

  /**
   * get Nutritional category data by the `nutritional_category` ID
   * @returns {Promise<unknown>}
   */
  setNutritionalPrefData () {
    const nutritionalPrefID = this.getValue('nutritional_category')
    return new Promise((resolve, reject) => {
      const service = new NutritionCategoryAPI()
      service.get(nutritionalPrefID).then(data => {
        this.options.mpd[nutritionalPrefID] = data.data.mpd
        this.options.vegetarian[nutritionalPrefID] = data.data.vegetarian
        this.options.dGoals[nutritionalPrefID] = data.data.goals
        resolve(data.data)
      }).catch(err => {
        reject(err.response)
      })
    })
  }

  getWorkoutLocationValueByKey (key) {
    let locationValue = key
    Object.keys(this._data.locations).forEach((item) => {
      if (item === key) {
        locationValue = this._data.locations[item]
      }
    })

    return locationValue
  }
  getWorkoutExpLevelValueByKey (key) {
    let expValue = key
    Object.keys(this._data.levels).forEach((item) => {
      if (item === key) {
        expValue = this._data.levels[item]
      }
    })

    return expValue
  }

  /**
   * map the API options data to the options values
   */
  setSubscriptionOptionsFields () {
    this.options.genders = this._data.genders || []
    this.options.activityLevel = this._data.activity_level.map(option => {
      return { key: option.id, value: option.name }
    })
    Object.keys(this._data.levels).forEach((item) => {
      this.options.experienceLevel.push({ key: item, value: this._data.levels[item] })
    })

    Object.keys(this._data.locations).forEach((item) => {
      this.options.location.push({ key: item, value: this._data.locations[item] })
    })

    this.options.injuries = this._data.injuries.map(option => {
      return { id: option.id, text: option.value }
    })

    this.options.activityLevel = this._data.activity_level.map(option => {
      return { key: option.id, value: option.name }
    })
    if (this._data.extra_fields !== null) {
      this._data.extra_fields.forEach((option) => {
        this.options.extraFields.push(option)
      })
    }
    this.options.workouts = this._data.workouts

    if (this._data.extra_fields !== null) {
      this._data.extra_fields.forEach((option) => {
        this.options.extraFields.push(option)
      })
    }
  }

  /**
   * set the forms data
   * @param data
   */
  setValues (data) {
    this.values[data.key] = data.value
  }

  setAmplitudeEventsAndUserProperties () {
    try {
      const dob = new Date(this.values.dob)
      let tracker = new ActivityTracker()

      tracker.setUserProperties('Gender', this.values.gender, false)
      tracker.setUserProperties('Age', (new Date().getFullYear() - dob.getFullYear()), false)
      if( this.values.body_fats){
        tracker.setUserProperties('Lbm', this.values.body_fats, false)
      }

      tracker.setUserProperties('ActivityMultiplier', this.values.activity_level, false)
      tracker.setUserProperties('ActivityLevel', this.values.activity_level, false)

      let height = this.getHeightValueByUnit(this.values.system, this.values.height)
      let weight = this.getWeightValueByUnit(this.values.system, this.values.weight)

      if (this.values.system === 'imperial') {
        height = Math.ceil(height * 2.54)
        weight = Math.ceil(weight / 2.2)
      }
      const subscriptionSetupCompleted = {}
      tracker.setUserProperties('Height', height, false)
      tracker.setUserProperties('Weight', weight, false)

      tracker.addEvent('Journey.AddedMeasurement.Weight', { value: weight, context: 'subscription-setup' })
      if(this.values.body_fats){
        tracker.addEvent('Journey.AddedMeasurement.BodyFat', { value: this.values.body_fats, context: 'subscription-setup' }, false)

      }

      if (this.values.nutritional_category) {
        tracker.setUserProperties('MealCategory ', this.getNutritionalCategoryNameByID(this.values.nutritional_category), false)
        tracker.setUserProperties('NutritionGoal', this.getNutritionGoalNameByIDAndCategoryID(this.values.nutrition_goals, this.values.nutritional_category), false)
        tracker.setUserProperties('MealsPerDay', this.values.meals_per_day, false)
        tracker.setUserProperties('Allergies', this.getAllergiesNamesByIds(this.values.allergies), false)
        subscriptionSetupCompleted['meal_plan'] =
        {
          'body_fat': ((this.values.body_fats)?(this.values.body_fats / 100):null),
          'lbm': ((this.values.body_fats)? this.values.body_fats:null),
          'ntr_goal': {id: this.values.nutrition_goals, label: this.getNutritionGoalNameByIDAndCategoryID(this.values.nutrition_goals, this.values.nutritional_category)},
          'meals_per_day': parseInt(this.values.meals_per_day),
          'category': {id: this.values.nutritional_category, label: this.getNutritionalCategoryNameByID(this.values.nutritional_category)}
        }
      }
      if (Array.isArray(this.values.workouts) && this.values.workouts.length > 0) {
        subscriptionSetupCompleted['workout_programs'] = []
        tracker.setUserProperties('WorkoutGoal', this.getWorkoutGoalById(this.values.workouts[0].workout_goal, 0, this.getValue('gender')), false)
        tracker.setUserProperties('WorkoutDays', this.getWorkoutDaysById(this.values.workouts[0].workout_days, 0, this.getValue('gender')), false)
        tracker.setUserProperties('WorkoutSessionDuration', this.getWorkoutDurationById(this.values.workouts[0].duration, 0, this.getValue('gender')), false)
        tracker.setUserProperties('FitnessExperienceLevel', (this.values.workouts[0].level, false))
        tracker.setUserProperties('Injuries', this.getInjuriesNamesByIds(this.values.injuries), false)
        tracker.setUserProperties('WorkoutLocation', this.getWorkoutLocationValueByKey(this.values.workouts[0].location).toLowerCase(), false)
        this.values.workouts.forEach((workout, workoutId) => {
          subscriptionSetupCompleted['workout_programs'].push({
            'id': workout.id,
            'goal': {id: workout.workout_goal, label: this.getWorkoutGoalById(workout.workout_goal, workoutId, this.getValue('gender'))},
            'days_per_week': this.getWorkoutDaysById(workout.workout_days, workoutId, this.getValue('gender')),
            'session_duration': {id: workout.duration, label: this.getWorkoutDurationById(workout.duration, workoutId, this.getValue('gender'))},
            'location': this.getWorkoutLocationValueByKey(workout.location).toLowerCase(),
            'experience_level': this.getWorkoutExpLevelValueByKey(workout.level).toLowerCase()
          })
        })
      }
      tracker.addEvent('SubscriptionSetup.Completed', subscriptionSetupCompleted)
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  isExtraFieldsEnabled () {
    if (this.options.extraFields.length > 0) {
      return true
    }
    return false
  }

  getWorkoutGoalById (workoutGoalId, workoutIndex, gender) {
    let selectedGoal = this.options.workouts[0].workout_goals[gender].find(goals => {
      return parseInt(workoutGoalId) === goals.id
    })
    if (selectedGoal) {
      return selectedGoal.value
    }
    return workoutGoalId
  }
  getWorkoutDurationById (workoutDurationId, workoutIndex, gender) {
    let selectedDuration = this.options.workouts[0].durations[gender].find(duration => {
      return parseInt(workoutDurationId) === duration.id
    })
    let workoutDurationStr = ''

    if (selectedDuration) {
      workoutDurationStr = selectedDuration.value
    } else {
      workoutDurationStr = workoutDurationId.toString()
    }

    return workoutDurationStr.match(/\d/g).join('')
  }
  getWorkoutDaysById (workoutDayId, workoutIndex, gender) {
    let selectedDays = this.options.workouts[0].days[gender].find(days => {
      return parseInt(workoutDayId) === days.id
    })
    if (selectedDays) {
      return selectedDays.value
    }
    return workoutDayId
  }

  getAllergiesNamesByIds (ids) {
    if (!Array.isArray(ids)) {
      return []
    }
    ids = ids.map(id => {
      return parseInt(id) || 0
    })
    let selectedIds = []
    this.options.ingredients.forEach(ingredient => {
      if (ids.includes(ingredient.id)) {
        selectedIds.push(ingredient.text)
      }
    })

    return selectedIds
  }

  getInjuriesNamesByIds (ids) {
    if (!Array.isArray(ids)) {
      return []
    }
    ids = ids.map(id => {
      return parseInt(id) || 0
    })
    let selectedIds = []
    this.options.injuries.forEach(injury => {
      if (ids.includes(injury.id)) {
        selectedIds.push(injury.value || injury.text)
      }
    })

    return selectedIds
  }

  getNutritionalCategoryNameByID (id) {
    id = parseInt(id)
    let selectedCategory = this.options.nutritionalPref.find(category => {
      return category.id === id
    })
    if (selectedCategory) {
      return selectedCategory.name
    }
    return id
  }

  getNutritionGoalNameByIDAndCategoryID (id, categoryId) {
    let goals = this.options.dGoals[parseInt(categoryId)]
    id = parseInt(id)
    if (goals) {
      let selectedGoal = goals.find((goal) => {
        return id === goal.id
      })

      if (selectedGoal) {
        return selectedGoal.name
      }
    }

    return id
  }

  /**
   *  set the Start Form Data
   * @returns {{inputValue: string, options: [], inputKey: string, title: {sub: string, main: string}}}
   */
  getStartFormData () {
    return {
      title: {
        main: '',
        sub: ''
      },
      inputKey: '',
      inputValue: '',
      options: [],
      userName: this._myData.first_name || ''
    }
  }
  getGenderOptionName (val) {
    let genderOption = this.options.genders.find(gender => {
      return gender.id === val
    })
    return genderOption.name
  }
  /**
   * Generate Gender Form Data
   * @returns {{inputValue: *, options: [], inputKey: string, title: {sub: string, main: *}, errors: {}}}
   */
  getGenderFormData () {
    let workoutCount = this.getWorkoutCount()

    let options = [
      { value: 'male', support: true, name: this.getGenderOptionName('m') },
      { value: 'female', support: true, name: this.getGenderOptionName('f') }
    ]

    if (workoutCount > 0) {
      const isSupportMale = this.options.workouts.every((workout, index) => {
        return this.isGenderSupportWorkout('male', index)
      })
      const isSupportFemale = this.options.workouts.every((workout, index) => {
        return this.isGenderSupportWorkout('female', index)
      })
      options = [
        { value: 'male', support: isSupportMale, name: this.getGenderOptionName('m') },
        { value: 'female', support: isSupportFemale, name: this.getGenderOptionName('f') }
      ]
    }

    let defaultOptions = options.filter(option => {
      return option.value === this._configs.defaultGender
    })
    let notDefaultOptions = options.filter(option => {
      return option.value !== this._configs.defaultGender
    })
    options = [...defaultOptions, ...notDefaultOptions]
    return {
      title: {
        main: this.getContent('gender'),
        sub: ''
      },
      errors: this.errors,
      inputKey: 'gender',
      inputValue: this.getValue('gender') || this._configs.defaultGender,
      options: options
    }
  }

  /**
   * Generate Birthday Form Data
   * @returns {{inputValue: *, options: [], inputKey: string, title: {sub: string, main: *}, errors: {}}}
   */
  getBirthdayFormData () {
    return {
      title: {
        main: this.getContent('date-of-birth'),
        sub: ''
      },
      errors: this.errors,
      inputKey: 'dob',
      inputValue: this.getValue('dob'),
      options: []
    }
  }
  getPagePreferredRecipesData () {
    return {
      title: {
        main: i18n.t("message['form-title.preferred-recipe']"),
        sub: i18n.t("message['profile.account-settings.preferred-meals-sub-content']")
      },
      errors: this.errors,
      inputKey: 'recipes',
      inputValue: this.getValue('recipes'),
      options: this._data['recipes'].map(option => {
        return { id: option.id, text: option.name }
      })
    }
  }
  getCustomPageData () {
    return {
      title: {
        main: this.options.extraFields[0].key,
        sub: ''
      },
      extraData: {
        value: this.options.extraFields[0].value
      },
      errors: this.errors,
      inputKey: 'extra_field',
      inputValue: this.getValue('extra_field')
    }
  }
  /**
   * Generate Height Form Data
   * @returns {{inputValue: (*|{feet: string, preference: string, cm: string, inch: string}), options: [], inputKey: string, title: {sub: string, main: *}, errors: {}}}
   */
  getHeightFormData () {
    const pref = this.values.system || 'imperial'
    const defaultValue = { preference: pref, feet: '', inch: '', cm: '' }
    return {
      title: {
        main: this.getContent('height'),
        sub: ''
      },
      errors: this.errors,
      inputKey: 'height',
      switchPreference: this._configs.showMeasurementSystem,
      inputValue: this.getValue('height') || defaultValue,
      options: []
    }
  }

  /**
   * Generate Weight Form Data
   * @returns {{inputValue: (*|{preference: string, lbs: string, kg: string}), options: [], inputKey: string, title: {sub: string, main: *}, errors: {}}}
   */
  getWeightFormData () {
    let preference = ''
    if (typeof this.getValue('height') !== 'undefined') {
      preference = this.getValue('height').preference || 'imperial'
    } else {
      preference = 'imperial'
    }
    const defaultValue = { preference: preference, lbs: '', kg: '' }
    return {
      title: {
        main: this.getContent('weight'),
        sub: ''
      },
      errors: this.errors,
      inputKey: 'weight',
      switchPreference: this._configs.showMeasurementSystem,
      inputValue: this.getValue('weight') || defaultValue,
      options: []
    }
  }

  /**
   * Generate BodyFat Form Data
   * @returns {{inputValue: *, options: *, inputKey: string, title: {sub: string, main: *}, errors: {bodyFat: (*|*[])}}}
   */
  getBodyFatFormData () {
    this.convertValuesToUnitSystem()
    return {
      title: {
        main: this.getContent('body-fat'),
        sub: ''
      },
      errors: { bodyFat: this.errors.body_fats || [] },
      inputKey: 'body_fats',
      inputValue: this.getValue('body_fats'),
      options: this.getBodyFat(),
      extraData: {
        system: this.values.system,
        weight: this.getWeightValueByUnit(this.values.system, this.values.weight),
        height: this.getHeightValueByUnit(this.values.system, this.values.height),
        gender: (this.values.gender === 'male' ? 'm' : 'f'),
        isTextLableEnabled: this.getBodyFatTextEnabled(),
        showBodyFatInputField: this.getBodyFatInputFieldEnabled()
      }
    }
  }
  /**
   * Generate Activity Level Form Data
   * @returns {{infoText: (*|string), inputValue: *, options: *, inputKey: string, title: {sub: VueI18n.TranslateResult, main: VueI18n.TranslateResult}, errors: {}}}
   */
  getActivityLevelFormData () {
    return {
      title: {
        main: i18n.t("message['form-title.activity-level']"),
        sub: i18n.t("message['form-sub-title.activity-level']")
      },
      infoText: this.getContent('activity-level-info-txt') || '',
      errors: this.errors,
      inputKey: 'activity_level',
      inputValue: this.getValue('activity_level'),
      options: this.getActivityLevel()
    }
  }

  /**
   *  Generate Diet Type Form Data
   * @returns {{inputValue: *, options: (string|[]|[{value: string, key: string}, {value: string, key: string}, {value: string, key: string}]|{value: string, key: string}), inputKey: string, title: {sub: string, main: (*|string)}, errors: {nutritional_category: (value.data.nutritionalPref|*[])}}}
   */
  getDietTypeFormData () {
    return {
      title: {
        main: this.getContent('nutritional-prefs') || '',
        sub: ''
      },
      errors: { nutritional_category: (this.errors.nutritional_category || []) },
      inputKey: 'nutritional_category',
      inputValue: this.getValue('nutritional_category'),
      options: this.options.nutritionalPref
    }
  }

  /**
   * Generate Dietary Goal Form Data
   * @returns {{inputValue: *, options: *, inputKey: string, title: {sub: string, main: (*|string)}, errors: {nutrition_goals: (value.data.dGoal|*[])}}}
   */
  getDietaryGoalFormData () {
    return {
      title: {
        main: this.getContent('nutrition-goal') || '',
        sub: ''
      },
      errors: { nutrition_goals: (this.errors.nutrition_goals || []) },
      inputKey: 'nutrition_goals',
      inputValue: this.getValue('nutrition_goals'),
      options: this.options.dGoals[this.getValue('nutritional_category')]
    }
  }

  /**
   * Generate Vegetarian Days Form Data
   * @returns {{inputValue: *, options: *, inputKey: string, title: {sub: string, main: string | VueI18n.LocaleMessages | string}, errors: {}}}
   */
  getVegetarianDaysFormData () {
    return {
      title: {
        main: i18n.t("message['form-title.vegetarian-days']") || '',
        sub: ''
      },
      errors: this.errors,
      inputKey: 'vegetarian_days',
      inputValue: this.getValue('vegetarian_days'),
      options: this.options.vegetarian[this.getValue('nutritional_category')].map(option => this._mapVegetarianDaysOptions(option))
    }
  }

  getMealPlanDaysFormData () {
    return {
      title: {
        main: i18n.t("message['form-title.meal-plan-days']") || '',
        sub: ''
      },
      errors: this.errors,
      inputKey: 'days_per_mealplan',
      inputValue: this.getValue('days_per_mealplan'),
      options: [
        {key: 2, value: 2},
        {key: 3, value: 3},
        {key: 4, value: 4},
        {key: 5, value: 5},
        {key: 6, value: 6},
        {key: 7, value: 7}
      ]
    }
  }

  /**
   * map the vegetarian day options
   * @param option
   * @returns {function(*=): {value: string, key: *}}
   * @private
   */
  _mapVegetarianDaysOptions (option) {
    let optionInInt = parseInt(option)
    return {
      value: i18n.tc('message["progress.vegetarian-day-count"]', optionInInt, { days: optionInInt }),
      key: option
    }
  }

  /**
   * Generate Dietary Meals Pre Day Form Data
   * @returns {{inputValue: *, options: {value: string, key: string}[], inputKey: string, title: {sub: string, main: *}, errors: {meals_per_day: (value.data.mealsPerDay|*[])}}}
   */
  getMealsPreDayFormData () {
    return {
      title: {
        main: this.getContent('nutrition-meals-number'),
        sub: ''
      },
      errors: { meals_per_day: (this.errors.meals_per_day || []) },
      inputKey: 'meals_per_day',
      inputValue: this.getValue('meals_per_day'),
      options: Object.keys(this.options.mpd[this.getValue('nutritional_category')]).map(option => {
        return { key: option, value: option }
      })
    }
  }

  /**
   * Generate Allergies Form Data
   * @returns {{inputValue: *, options: ([]|*[]), inputKey: string, title: {sub: string, main: *}, errors: {}}}
   */
  getAllergiesFormData () {
    return {
      title: {
        main: this.getContent('blacklisted-ingredients'),
        sub: ''
      },
      errors: this.errors,
      inputKey: 'allergies',
      inputValue: this.getValue('allergies'),
      options: this.options.ingredients
    }
  }

  /**
   * Generate Experience Level Form Data
   * @returns {{inputValue: *, options: ([]|*[]), inputKey: string, title: {sub: string, main: *}, errors: {level: (*|*[])}}}
   */
  getExperienceLevelFormData () {
    return {
      title: {
        main: this.getContent('experience-level'),
        sub: ''
      },
      errors: { level: (this.errors.level || []) },
      inputKey: 'level',
      inputValue: this.getValue('level'),
      options: this.options.experienceLevel
    }
  }

  /**
   * Generate Exercise Location Form Data
   * @returns {{inputValue: *, options: ([]|*[]), inputKey: string, title: {sub: string, main: *}, errors: {location: (*|*[])}}}
   */
  getExerciseLocationFormData () {
    return {
      title: {
        main: this.getContent('exercise-location-preference'),
        sub: ''
      },
      errors: { location: (this.errors.level || []) },
      inputKey: 'location',
      inputValue: this.getValue('location'),
      options: this.options.location
    }
  }

  /**
   * Generate  Unit Preference Form Data
   * @returns {{inputValue: *, options: [{value: string, key: string}, {value: string, key: string}], inputKey: string, title: {sub: string, main: *}, errors: {}}}
   */
  getUnitPreferenceFormData () {
    let value = ''
    if (this.getValue('system') !== '') {
      value = this.getValue('system')
    } else {
      value = this._configs.defaultMeasurementSystem
    }
    let options = [{ key: 'metric', value: i18n.t("message['form.metric']") }, {
      key: 'imperial',
      value: i18n.t("message['form.imperial']")
    }]
    if (this._configs.defaultMeasurementSystem === 'imperial') {
      options = options.reverse()
    }
    return {
      title: {
        main: this.getContent('system'),
        sub: i18n.t("message['form-sub-title.unit-preferences']")
      },
      errors: this.errors,
      inputKey: 'system',
      inputValue: value,
      options: options
    }
  }

  /**
   * Generate Workout Form Data
   * @param workoutIndex
   * @returns {{inputValue: *, options: {duration: *, daysPreWeek: *, workoutGoal: *}, inputKey: string, title: {duration: *, image: (*|string), sub: string, dayPreWeek: *, main: *, workoutGoal: *}, errors: {duration: (*|*[]), workoutGoal: (*|*[]), daysPerWeek: (*|*[])}}}
   */
  getWorkoutFormData (workoutIndex) {
    let inputValue = ''
    if (typeof this.values.workouts[workoutIndex] === 'undefined') {
      inputValue = this.setEmptyWorkout(this.getWorkout(workoutIndex).id)
    } else {
      inputValue = this.values.workouts[workoutIndex]
    }
    return {
      title: {
        main: this.getWorkout(workoutIndex).name,
        image: this.getWorkout(workoutIndex).image || null,
        dayPreWeek: this.getContent('workout-days-per-week'),
        duration: this.getContent('workout-session-duration'),
        workoutGoal: this.getContent('workout-goal'),
        experienceLevel: this.getContent('experience-level'),
        exerciseLocation: this.getContent('exercise-location-preference'),
        sub: ''
      },
      errors: {
        daysPerWeek: (this.errors['workouts.' + workoutIndex + '.days_per_week'] || []),
        duration: (this.errors['workouts.' + workoutIndex + '.duration'] || []),
        workoutGoal: (this.errors['workouts.' + workoutIndex + '.workout_goal'] || [])
      },
      inputKey: 'workouts',
      inputValue: inputValue,
      options: {
        daysPreWeek: this.getDaysPerWeek(workoutIndex),
        duration: this.getSessionDuration(workoutIndex),
        workoutGoal: this.getWorkoutGoals(workoutIndex),
        exerciseLocation: this.getExerciseLocationOptions(),
        experienceLevel: this.getExperienceLevelOptions(),
        supports: this.getWorkoutSupports(workoutIndex)
      }
    }
  }

  getExerciseLocationOptions () {
    const options = []
    this.options.location.forEach(loc => {
      options.push({
        id: loc.key,
        text: loc.value
      })
    })

    return options
  }
  getExperienceLevelOptions () {
    const options = []
    this.options.experienceLevel.forEach((lvl) => {
      options.push({
        id: lvl.key,
        text: lvl.value
      })
    })

    return options
  }

  getSubscriptionTypeData () {
    return this._myData.subscriptions.find(sub => {
      return sub.id === this.subscriptionID
    })
  }

  /**
   * check the subscription has meal plans
   * @returns {*|boolean}
   */
  hasMealPlans () {
    const subscription = this.getSubscriptionTypeData()
    if (subscription) {
      return subscription.has_meal_plans || false
    }
    return true
  }

  /**
   * check the subscription has workout programs
   * @returns {*|boolean}
   */
  hasWorkoutProgrammes () {
    const subscription = this.getSubscriptionTypeData()
    if (subscription) {
      return subscription.has_workout_programs || false
    }
    return true
  }

  getWorkoutsFormData () {
    const workoutsFormData = {
      options: []
    }
    let workoutIndex = 0
    for (workoutIndex = 0; workoutIndex < this.getWorkoutCount(); workoutIndex++) {
      workoutsFormData.options.push(this.getWorkoutFormData(workoutIndex))
    }

    return workoutsFormData
  }

  _getCustomConfigFields () {
    return flagValue('subscription_custom_fields', {})
  }

  getCustomFormData () {
    const options = this._getCustomConfigFields()
    const defaultValues = {}
    options.fields.forEach(option => {
      defaultValues[option.key] = ''
    })
    return {
      title: {
        main: options.heading || '',
        sub: options.description || ''
      },
      errors: [],
      inputKey: 'custom',
      inputValue: this.getValue('custom') || defaultValues,
      submitText: options.submit_button || i18n.t('message["general.next"]'),
      options: options.fields
    }
  }

  hasCustomDetails () {
    const config = flagValue('subscription_custom_fields', {})
    if (config && typeof config === 'object' && Object.keys(config).length > 0 && config.fields.length > 0) {
      return true
    }
    return false
  }

  /**
   * Generate  Injuries Form Data
   * @returns {{inputValue: *, options: ([]|*[]), inputKey: string, title: {sub: *, main: VueI18n.TranslateResult}, errors: {}}}
   */
  getInjuriesFormData () {
    return {
      title: {
        main: i18n.t("message['form-title.injuries']"),
        sub: this.getContent('injuries-list')
      },
      errors: this.errors,
      inputKey: 'injuries',
      inputValue: this.getValue('injuries'),
      options: this.options.injuries
    }
  }

  /**
   * get the forms options by the Key
   * @param key
   * @returns {*}
   */
  getOptions (key) {
    return this.options[key]
  }

  /**
   * get the forms values by the Key
   * @param key
   * @returns {*}
   */
  getValue (key) {
    return this.values[key] || ''
  }

  /**
   * get the forms errors by the Key
   * @param key
   * @returns {*}
   */
  getError (key) {
    return this.errors[key]
  }

  /**
   * get content of the API
   * @param key
   * @returns {*}
   */
  getContent (key) {
    return this._data.contents[key]
  }

  /**
   * get the body fat ranges with images
   * @param gender
   * @returns {*}
   */
  getBodyFat (gender = null) {
    if (gender === null) {
      gender = this.getValue('gender')
    }
    const service = new FormOptionHelper()
    const bodyFatRanges = service.getBodyFats()
    if (gender === 'female' || gender === 'f') {
      return bodyFatRanges.f
    } else {
      return bodyFatRanges.m
    }
  }

  getBodyFatTextEnabled () {
    const service = new FormOptionHelper()
    const bodyFatRanges = service.getBodyFats()

    if (typeof bodyFatRanges.isTextLabel !== 'undefined') {
      return bodyFatRanges.isTextLabel;
    } else {
      return false;
    }
  }

  getBodyFatInputFieldEnabled () {
    const service = new FormOptionHelper()
    const bodyFatRanges = service.getBodyFats()

    if (typeof bodyFatRanges.showInput !== 'undefined') {
      return bodyFatRanges.showInput;
    } else {
      return true;
    }
  }

  /**
   * get the activity level with mapped data
   * @returns {{description: *, value: *, key: *}[]}
   */
  getActivityLevel () {
    return this.options.activityLevel.map(level => {
      const index = activityLevel.levels.findIndex(lvl => {
        return lvl.id === level.key
      })
      return {
        key: level.key,
        value: level.value,
        description: (index === -1) ? '' : activityLevel.levels[index].description
      }
    })
  }

  /**
   * returns a empty valued workout object
   * @param id
   * @param weeks @deprecated
   * @returns {{workout_goal: string, duration: string, days_per_week: string, level: string, workout_days: string, location: string, id: *}}
   */
  setEmptyWorkout (id, weeks = null) {
    return {
      id: id,
      workout_goal: '',
      workout_days: '',
      days_per_week: '',
      duration: '',
      level: '',
      location: ''
    }
  }

  /**
   * get the workout option by index
   * @param index
   * @returns {*}
   */
  getWorkout (index) {
    return this.options.workouts[index]
  }

  /**
   * returns the workouts count
   * @returns {number}
   */
  getWorkoutCount () {
    return this.options.workouts.length
  }

  isGenderSupportWorkout (gender, workoutIndex = 0) {
    const workoutGoals = Boolean(this.options.workouts[workoutIndex].workout_goals[gender].length)
    const days = Boolean(this.options.workouts[workoutIndex].days[gender].length)
    const duration = Boolean(this.options.workouts[workoutIndex].durations[gender].length)

    return (workoutGoals && days && duration)
  }

  /**
   * @deprecated don't use
   * returns the workout week values by the index
   * @param workoutIndex
   * @returns {*}
   */
  getWeeks (workoutIndex = 0) {
    const gender = this.getValue('gender')
    return this.options.workouts[workoutIndex].weeks[gender]
  }

  /**
   *  returns the workout goal values by the index
   * @param workoutIndex
   * @returns {*}
   */
  getWorkoutGoals (workoutIndex = 0) {
    const gender = this.getValue('gender')
    return this.options.workouts[workoutIndex].workout_goals[gender].map(option => {
      return { id: option.id, text: option.value }
    })
  }

  /**
   *  returns the workout session duration  values by the index
   * @param workoutIndex
   * @returns {*}
   */
  getSessionDuration (workoutIndex = 0) {
    const gender = this.getValue('gender')
    return this.options.workouts[workoutIndex].durations[gender].map(option => {
      let supportedGoals = option.supported_workout_goals || []
      return { id: option.id, text: option.value, supportedGoals: supportedGoals }
    })
  }

  /**
   *  returns the workout days values by the index
   * @param workoutIndex
   * @returns {*}
   */
  getDaysPerWeek (workoutIndex = 0) {
    const gender = this.getValue('gender')
    return this.options.workouts[workoutIndex].days[gender].map(option => {
      let supports = option.supports || []
      supports = supports.map(supportOption => {
        if (!supportOption.goals && supportOption.goal) {
          supportOption.goals = [supportOption.goal]
        }
        return supportOption
      })
      return {
        text: i18n.tc('message["subscriptions.days-a-week"]', parseInt(option.value), [parseInt(option.value)]),
        id: option.value,
        supports: supports
      }
    })
  }

  /**
   *  returns the workout days values by the index
   * @param workoutIndex
   * @returns {*}
   */
  getWorkoutSupports (workoutIndex = 0) {
    const gender = this.getValue('gender')
    if (gender === 'male') {
      return this.options.workouts[workoutIndex].supports['m']
    } else if (gender === 'female') {
      return this.options.workouts[workoutIndex].supports['f']
    }

    return this.options.workouts[workoutIndex].supports[gender]
  }

  /**
   *  returns the workout id values by the index
   * @param workoutIndex
   * @returns {*}
   */
  getWorkoutID (workoutIndex = 0) {
    return this.options.workouts[workoutIndex].id
  }

  getWeeklyExercise () {
    return [
      { key: '1.00', value: '1-3 times or less', description: '30 - 60 mins or less' },
      { key: '1.20', value: '3-4 times', description: '60 mins sessions' },
      { key: '1.375', value: '4-5 times', description: '60 - 90 mins sessions' },
      { key: '1.55', value: '5-6 times', description: '90 - 120 mins or more' }
    ]
  }

  setConfig (config) {
    this.config = config
  }

  convertValuesToUnitSystem () {
    const unitMath = new UnitHelper()
    if (this.values.system === 'metric') {
      if (this.values.height.preference === 'imperial') {
        this.values.height.cm = unitMath.inToCm(((parseFloat(this.values.height.feet) * 12) + parseFloat((this.values.height.inch || '0'))))
        this.values.height.preference = 'metric'
      }
      if (this.values.weight.preference === 'imperial') {
        this.values.weight.kg = unitMath.lbToKg(parseFloat(this.values.weight.lbs))
        this.values.weight.preference = 'metric'
      }
    } else if (this.values.system === 'imperial') {
      if (this.values.height.preference === 'metric') {
        const totalInc = unitMath.cmToIn((parseFloat(this.values.height.cm)))
        this.values.height.feet = Math.floor(totalInc / 12)
        this.values.height.inch = totalInc - (this.values.height.feet * 12)
        this.values.height.preference = 'imperial'
      }

      if (this.values.weight.preference === 'metric') {
        this.values.weight.lbs = unitMath.kgToLb(this.values.weight.kg)
        this.values.weight.preference = 'imperial'
      }
    }
  }

  /**
   * map the forms data according to the config file or default config
   * @param val
   * @param type
   * @returns {*}
   */
  mapData (val, type) {
    let res = val
    let mapType
    let config = AppConfig
    if (typeof macroAppConfig !== 'undefined') {
      config = macroAppConfig || AppConfig
    }
    // eslint-disable-next-line
    if (typeof config.onBoarding.mapping[type] === 'undefined' || typeof config.onBoarding.mapping[type].length === 0) {
      return res
    } else {
      res = ''
    }
    for (mapType of config.onBoarding.mapping[type]) {
      if (mapType.type === 'range' && val >= mapType.range.min &&
        val <= mapType.range.max) {
        res = mapType.systemValue
        break
      } else if (mapType.type === 'single' && val === (mapType.value)) {
        res = mapType.systemValue
        break
      }
    }
    if (res === '') {
      throw new Error('data mapping out of bound exception : type - ' + type + ', value ' + val)
    }

    return res
  }

  /**
   * set the User 422 errors
   * @param errors
   */
  setErrors (errors) {
    this.errors = errors
  }

  getHeightValueByUnit (unit, height) {
    if (unit === 'imperial') {
      return Number.parseFloat(parseFloat(height.feet * 12) + (parseFloat((height.inch || '0')))).toFixed(2)
    } else {
      return Number.parseFloat(height.cm).toFixed(2)
    }
  }

  getWeightValueByUnit (unit, weight) {
    if (unit === 'imperial') {
      return Number.parseFloat(weight.lbs).toFixed(2)
    } else {
      return Number.parseFloat(weight.kg).toFixed(2)
    }
  }

  /**
   * send the user forms data to the API
   * map data to API request
   * @param subscription
   * @returns {Promise<unknown>}
   */
  sendData (subscription) {
    return new Promise((resolve, reject) => {
      this.convertValuesToUnitSystem()
      const dob = new Date(this.values.dob)
      const height = this.getHeightValueByUnit(this.values.system, this.values.height)
      const weight = this.getWeightValueByUnit(this.values.system, this.values.weight)

      const payload = {
        gender: this.values.gender,
        dob_day: dob.getDate(),
        dob_month: (dob.getMonth() + 1),
        dob_year: dob.getFullYear(),
        system: this.values.system,
        weight: parseFloat(weight),
        height: parseFloat(height),
        activity_level: this.values.activity_level,
        nutritional_category: this.values.nutritional_category,
        nutrition_goals: this.values.nutrition_goals,
        meals_per_day: this.values.meals_per_day,
        cuisines: [],
        allergies: this.arrayToIntArray(this.values.allergies),
        level: this.values.level,
        location: this.values.location,
        injuries: this.arrayToIntArray(this.values.injuries),
        workouts: this.values.workouts,
        extra_field: this.values.extra_field,
        days_per_mealplan: this.values.days_per_mealplan
      }

      if (this._configs.showFatQuestion) {
        let bodyFat = this.mapData(this.values.body_fats, 'bodyFat')
        payload.body_fats = bodyFat
        payload.body_fat = bodyFat
      }

      if (this._configs.showPreferredRecipes && this.values && Array.isArray(this.values.recipes)) {
        payload.recipes = this.values.recipes.map(value => {
          return parseInt(value) || 0
        })
      }
      payload.vegetarian_days = parseInt(this.values.vegetarian_days) || parseInt(this.values.vegetarianDay) || 0
      const api = new SubscriptionAPI()
      api.saveData(subscription, payload).then(data => {
        this.setAmplitudeEventsAndUserProperties()
        resolve(data.data)
      }).catch(err => {
        reject(err.response)
      }).finally(() => {

      })
    })
  }

  saveCustomData () {
    return new Promise((resolve, reject) => {
      if (this.hasCustomDetails()) {
        const config = this._getCustomConfigFields()
        const payload = {
          heading: config.heading || '',
          description: config.description || '',
          submit_button: config.submit_button || '',
          values: this.values.custom
        }

        if (!config.url) {
          const activityService = new ActivityTracker()
          activityService.addEvent(config.heading, payload)
        } else {
          this.sendCustomData(config.url, payload)
        }
      }
      resolve()
    })
  }

  sendCustomData (url, payload) {
    let subscriptionAPI = new SubscriptionAPI()
    return subscriptionAPI.sendCustomData(url, payload)
  }

  arrayToIntArray (array) {
    if (array) {
      let mappedArray = array.map(item => {
        return parseInt(item) || 0
      })

      return mappedArray.filter(item => {
        return !!item
      })
    }
    return []
  }

  showIngredientDislikes () {
    return this._configs.showIngredientDislikes
  }
  showInjuries () {
    return this._configs.showInjuries
  }
  showMeasurementSystem () {
    return this._configs.showMeasurementSystem
  }
  showFatQuestion () {
    return this._configs.showFatQuestion
  }

  showPreferredRecipes () {
    return this._configs.showPreferredRecipes
  }
}
