<template>
  <div>
    <page-secondary :header="headerOptions">
      <page-container>
        <grid-row align-h="center">
          <grid-col :md="6">
            <image-base
              :src="imagesUrl + 'visa-card-new.png'"
              class="pc-profile-payment-settings__image"
            />
          </grid-col>
        </grid-row>

        <page-footer>
          <button-primary :text="$t('message[\'profile.payment-settings.update\']')" @click="update()">

          </button-primary>
        </page-footer>
      </page-container>
    </page-secondary>

    <popup-payment-error
      v-if="errorPopup.show"
      :error="errorPopup.errorDescription"
      :title="errorPopup.title"
      :visible="errorPopup.show"
      @close="errorPopup.show = false"
    />
  </div>
</template>

<script>
import BaseComponent from '../../global/base/BaseComponent'
import ProfileService from '../../../../../services/ProfileService'
import PopupPaymentError from './widgets/PopupPaymentError'
import ImageBase from '../../../../root/Image'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import PageSecondary from '../../../../global/pages/PageSecondary'
import PageContainer from '../../../../global/pages/page-sub-components/PageContainer'
import PageFooter from '../../../../global/pages/PageFixedFooter'
import GridCol from '../../../../global/grid/GridCol'
import GridRow from '../../../../global/grid/GridRow'

export default {
  name: 'PageCreditCard',
  components: {
    GridCol,
    GridRow,
    PageFooter,
    PopupPaymentError,
    ImageBase,
    ButtonPrimary,
    PageSecondary,
    PageContainer
  },
  extends: BaseComponent,
  data: function () {
    return {
      errorPopup: {
        visibility: false,
        title: '',
        errorDescription: '',
        show: false
      },
      headerOptions: {
        isTransparent: false,
        isFixed: false,
        show: true,
        left: 'previous',
        right: '',
        rightBlink: false,
        modifiers: ['with-bg-color', 'small-title'],
        mainTitle: this.$t('message["profile.payment-settings"]'),
        subTitle: ''
      }
    }
  },
  mounted () {
    this.setPageData()
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
  },
  methods: {
    navigate () {
      const query = {
        page: 'payment-settings',
        section: 'update',
        stk: btoa(this.stripeKey)
      }
      this.$router.push({
        path: this.$route.path,
        query: query
      })
    },
    update () {
      const profileService = new ProfileService()
      profileService
        .createStripeCreditCardUpdateSession()
        .then((data) => {
          profileService
            .redirectToStripeCheckout(data.session)
            .then((data) => {
              /**
               * doing nothing stripe redirection
               */
            })
            .catch((redirectionError) => {
              this.errorPopup.title = this.$i18n.t(
                'message["profile.payment-settings.update"]'
              )
              this.errorPopup.errorDescription = redirectionError
              this.errorPopup.visibility = true
            })
        })
        .catch((err) => {
          this.errorPopup.title = this.$i18n.t(
            'message["profile.payment-settings.update"]'
          )
          if (err && err.type === 'form' && err.errors.message) {
            this.errorPopup.errorDescription = err.errors.message
          } else {
            this.errorPopup.errorDescription = this.$i18n.t(
              'message["profile.payment-settings.update-error"]'
            )
          }

          setTimeout(() => {
            this.errorPopup.show = true
          }, 2000)
        })
    },

    setPageData () {
      const profileService = new ProfileService()
      profileService.setProfileData().then((res) => {
        this.stripeKey = profileService.getStripeKey()
        this.hideLoading()
      })
    },
    navigatePrevious () {
      this.$router.push({
        path: this.$route.path,
        query: {loading: '0'}
      })
    }
  }
}
</script>
