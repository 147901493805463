<template>
    <page :header="header" class="gc-page-meal-plan-connected" @ready="setFooterClasses">
            <page-container>
                <div class="gc-page-meal-plan-connected__header">
                    <text-heading-4
                            class="gc-page-meal-plan-connected__title text-center"
                            weight="extra-bold"
                            lineHeight="multi">
                        {{$t('message[\'meal-plan-pair.meal-plan-pairing\']')}}
                    </text-heading-4>
                </div>
                <div class="gc-page-meal-plan-connected__content">
                    <text-body-small class="gc-page-meal-plan-connected__connected-text">
                        {{$t('message[\'member-connect.connected-with\']')}}
                    </text-body-small>
                    <card-member-small
                        :avatar="member.getAvatar()"
                        :name="member.getFullName()"
                        :email="member.getEmail()">
                    </card-member-small>
                    <alert-dotted class="gc-page-meal-plan-connected__alert" v-if="parentPlanCategory">
                        <text-body-small
                        :line-height="'multi'"
                                v-html="$t('message[\'member-connect.connected-info-note\']', [parentPlanCategory.name, member.getFullName()])"
                        ></text-body-small>
                    </alert-dotted>
                </div>
            </page-container>
        <popup-member-disconnect :name="member.getFullName()" @disconnected="nextComponent" ref="disconnectPopup"></popup-member-disconnect>
        <page-footer-multiple-buttons
                    ref="pageMultipleFooter"
                    @click="nextComponent"
                    @clickSecondary="showDisconnectConfirm"
                    :text="$t('message[\'member-connect.stay-connected\']')"
                    :textSecondary="$t('message[\'member-connect.disconnect-now\']')"
                    :validated="false"></page-footer-multiple-buttons>
    </page>
</template>

<script>
import FormBaseComponent from '../../layout/template-1/global/widgets/forms/FormBaseComponent'
import TextBodySmall from '../typography/TextBodySmall'
import TextHeading4 from '../typography/TextHeading4'
import PageContainer from './page-sub-components/PageContainer'
import Page from './PageForm'
import PageFooterMultipleButtons from './page-sub-components/PageFooterMultipleButtons'
import Member from '../../../services/meal-plan-pair/Member'
import ConnectionService from '../../../services/meal-plan-pair/ConnectionService'
import PairService from '../../../services/meal-plan-pair/PairService'
import AlertDotted from '../alerts/AlertDotted'
import CardMemberSmall from '../cards/CardMemberSmall'
import PopupMemberDisconnect from '../popups/PopupMemberDisconnect'

export default {
  name: 'PageMemberConnected',
  extends: FormBaseComponent,
  components: {
    PopupMemberDisconnect,
    CardMemberSmall,
    AlertDotted,
    Page,
    PageContainer,
    TextHeading4,
    PageFooterMultipleButtons,
    TextBodySmall
  },
  data: function () {
    return {
      header: {
        isTransparent: false,
        isFixed: false,
        show: false,
        left: '',
        right: '',
        rightBlink: false,
        modifiers: [],
        mainTitle: '',
        subTitle: ''
      },
      member: new Member(),
      connectionService: new ConnectionService(),
      pairService: new PairService(),
      parentPlanCategory: {}
    }
  },
  mounted () {
    this.setPageDetails()
  },
  methods: {
    async setPageDetails () {
      this.showLoading()

      // initiating connection service and pair service (which will load current state)
      await Promise.all([this.connectionService.init(), this.pairService.init()])
        .catch(() => {
          this.hideLoading()
        })

      // getting other partner details (should be the owner)
      this.member = this.connectionService.getPartner()

      // getting owner's meal plan categories
      this.parentPlanCategory = this.pairService.getPlanCategory()
      this.setFooterClasses()
      this.hideLoading()
    },
    showDisconnectConfirm () {
      this.$refs.disconnectPopup.show()
    },
    nextComponent () {
      let payload = {}
      if (this.connectionService.isPartnerConnected() && this.parentPlanCategory) {
        payload = { key: 'nutritional_category', value: this.parentPlanCategory.id }
      }
      this.$emit('submit', payload)
    },
    setFooterClasses () {
      if (this.$refs.pageMultipleFooter) {
        this.$refs.pageMultipleFooter.setFooterClass()
      }
    }
  }
}
</script>
