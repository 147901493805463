import Vue from 'vue'
/**
 * header event bus
 * use for handle the events of the header
 */
/**
 * @type {Vue | CombinedVueInstance<Vue, object, object, object, Record<never, any>>}
 */
const headerEventBus = new Vue()

export default headerEventBus
