<template>
    <img
      :alt="name"
      :class="componentClasses"
      :src="imageSrc"
      v-bind="$attrs"
      v-on="$listeners"
      @error="onError($event)"
      @load="onLoad($event)"
      @click="onClick($event)"
    />
</template>

<script>
export default {
  name: 'ImageBase',
  props: {
    src: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    defaultSrc: {
      type: String,
      default: ''
    },
    borderRadius: {
      default: 'none'
    }
  },
  data: function () {
    return {
      imageSrc: ''
    }
  },
  watch: {
    src: function () {
      this.imageSrc = this.src || this.defaultSrc
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'rc-image': true,
        'rc-image--border-radius-none': this.borderRadius === 'none',
        'rc-image--border-radius-sm1': this.borderRadius === 'sm1',
        'rc-image--border-radius-md1': this.borderRadius === 'md1',
        'rc-image--border-radius-circle': this.borderRadius === 'circle'
      }
    }
  },
  mounted () {
    this.imageSrc = this.src
  },
  methods: {
    onError (err) {
      this.imageSrc = this.defaultSrc
      this.$emit('error', err)
    },
    onClick (event) {
      this.$emit('click', event)
    },
    onLoad (event) {
      if (this.imageSrc === this.src) {
        this.$emit('loaded', event)
      } else {
        this.$emit('loaded-default', event)
      }
    }
  }
}
</script>

<style scoped>

</style>
