<template>
    <div  :class="componentClasses">
        <div class="gc-chart__wrapper" id='chart-wrapper' ref="graph-scroll-sec">
            <line-chart
            v-if="graphData.datasets[0].data.length"
            :chart-data="graphData"
            :uom="unit"
            :tooltip="tooltips"
            :isRtl="isRTL()"
            :style="'width:' + graphWidth + 'px'"
            @axisDataRendered="setAxisData"
            >
            </line-chart>
            <div class="fixed-y-axis" id="y-axis" v-if="staticAxis&&graphData">
              <div v-for="(tick,index, key) in staticAxis.ticks" :key="key" >
              <div v-if="staticAxis.ticks.length - 1 === index"
                :style="
                'margin-top:'+  70 + 'px; ' +
                'margin-bottom: 5px;' +
                'font-size:' + staticAxis.options.ticks.fontSize + 'px;' +
                'color: ' + staticAxis.options.ticks.fontColor + ';' +
                'font-weight:700;'"
              >
                {{tick}}
              </div>
              <div
                  v-else
                  :style="
                'margin-top:'+  70 + 'px; ' +
                'margin-bottom:'+ yTickGap() + 'px;' +
                'font-size:' + staticAxis.options.ticks.fontSize + 'px;' +
                'color: ' + staticAxis.options.ticks.fontColor + ';' +
                'font-weight:700;'"
              >{{tick}}
              </div>
              </div>
          </div>
        </div>
    </div>
</template>
<script>
import LineChart from './LineChart'
export default {
  props: {
    axisLables: {
      default: []
    },
    graphData: {
      default: []
    },
    graphWidth: {
      type: Number,
      required: true,
      default: 0
    },
    tooltips: {
      default: {}
    },
    unit: {
      default: ''
    }
  },
  components: {
    LineChart
  },
  created () {
    this.setDataCollection()
  },
  mounted () {
    // window.addEventListener(
    //   'orientationchange',
    //   // this.handleOrientationChange
    // )
  },
  computed: {
    componentClasses () {
      return {
        'gc-chart': true
      }
    }
  },

  data () {
    return {
      firsttimeLoad: true,
      // datacollection: {},
      chartTooltip: {
        labels: []
      },
      staticAxis: {
        ticks: [],
        margins: {}
      },
      hideYAxis: false
    }
  },
  methods: {
    setDataCollection () {
      this.dataCollection = {
        labels: this.axisLables,
        datasets: [
          {
            label: '',
            fill: false,
            backgroundColor: '#2A25FF',
            borderColor: templateConfig.style_variables['var(--selected-state)'],
            borderDash: [6, 6],
            borderWidth: 1,
            pointBorderWidth: 5,
            pointHitRadius: 5,
            options: {
              // spanGaps: true,
              responsive: false, // Instruct chart js to respond nicely.
              maintainAspectRatio: false // Add to prevent default behaviour of full-width/height
            },
            data: this.graphData
          }
        ]
      }
    },
    yTickGap () {
      return this.staticAxis.ticks.length === 4 ? 91 : 148
    },
    setAxisData (data) {
      this.staticAxis = data.y
      if (!this.isRTL()) {
        this.setHorizontalScroll()
      }
    },

    setHorizontalScroll () {
      const element = this.$refs['graph-scroll-sec']
      element.scrollLeft = element.offsetWidth
      this.hideLoading()
    },
    handleOrientationChange () {
      const orientation = window.screen.orientation.type
      if (orientation === 'portrait-primary') {
        document.getElementById('gc-chart__wrapper').style.width = 91 + '%'
      } else if (orientation === 'landscape-primary') {
        document.getElementById('gc-chart__wrapper').style.width = 94 + '%'
        document.getElementById('y-axis').style.paddingRight = '26px'
      }
    }
  }
}
</script>
