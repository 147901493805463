<template>
    <div>
        <progress-card-lg v-bind="cardData()" v-if="showLargeCard" v-on:update-data="requestUpdate()"/>
        <progress-card-md v-bind="cardData()" v-else-if="showMediumCard"   v-on:update-data="requestUpdate()"/>
        <progress-card-first  v-bind="cardData()" v-else  v-on:update-data="requestUpdate()"/>
    </div>
</template>
<script>
import ProgressCardFirst from './ProgressCardFirst'
import ProgressCardLg from './ProgressCardLg'
import ProgressCardMd from './ProgressCardMd'
import fitnessDiaryFitnessMeasurementWrapperComponentMixin
  from '../../../../../mixins/component-mixins/page/fitness-diary/fitnessDiaryFitnessMeasurementWrapperComponentMixin'

export default {
  name: 'CardWrapperFitnessDiaryMeasurement',
  mixins: [fitnessDiaryFitnessMeasurementWrapperComponentMixin],
  components: { ProgressCardFirst, ProgressCardLg, ProgressCardMd }
}
</script>
