export default {
  props: {
    data: {
      required: true
    },
    isEditable: {
      default: true
    }

  },
  computed: {
    showLargeCard: function () {
      return this.data.getType() === 'weight' && (this.getLatestValue() !== this.getFirstTimeValue()) && (this.data.getLatestData().date.toString() !== this.data.getFirstTimeData().date.toString())
    },
    showMediumCard: function () {
      return (this.data.getFirstTimeData().value) && (this.data.getLatestData().value) && (this.getLatestValue() !== this.getFirstTimeValue()) && (this.data.getLatestData().date.toString() !== this.data.getFirstTimeData().date.toString())
    },
    showSmallCard: function () {
      return true
    }
  },
  methods: {
    cardData () {
      return {
        isEditable: this.isEditable,
        name: this.data.getName(),
        type: this.data.getType(),
        firstTimeData: this.data.getFirstTimeData(),
        latestData: this.data.getLatestData(),
        measurementUnit: this.data.getMeasurementUnit()
      }
    },
    requestUpdate () {
      this.$emit('update-data', this.data.getType())
    },
    getLatestValue () {
      if (this.cardData().type === 'weight' && this.cardData().measurementUnit === 'kg') {
        return Number.parseFloat(this.cardData().latestData.value).toFixed(1) || '0'
      } else if (this.cardData().type === 'weight' && this.cardData().measurementUnit === 'lb') {
        return Number.parseFloat(this.cardData().latestData.value).toFixed(0) || '0'
      }
      if (this.cardData().type !== 'body_fat') {
        return this.cardData().latestData.value
      }

      const value = Number.parseFloat(this.cardData().latestData.value).toFixed(0)
      if (this.cardData().latestData.value >= 40) {
        return value + '+'
      }
      return value.toString()
    },
    getFirstTimeValue () {
      if (this.cardData().type === 'weight' && this.cardData().measurementUnit === 'kg') {
        return Number.parseFloat(this.cardData().firstTimeData.value).toFixed(1) || '0'
      } else if (this.cardData().type === 'weight' && this.cardData().measurementUnit === 'lb') {
        return Number.parseFloat(this.cardData().firstTimeData.value).toFixed(0) || '0'
      }
      if (this.cardData().type !== 'body_fat') {
        return this.cardData().firstTimeData.value
      }
      const value = Number.parseFloat(this.cardData().firstTimeData.value).toFixed(0)
      if (this.cardData().firstTimeData.value >= 40) {
        return value + '+'
      }
      return value.toString()
    }
  }

}
