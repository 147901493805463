import DashBoardService from '../../../../services/DashboardService'

export default {
  data: function () {
    return {
      headerOptions: {
        show: false
      },
      popupPaymentProcessing: {
        show: false
      },
      redirected: false,
      subscriptionID: null,
      userData: null,
      userName: '',
      introDescriptionText: '',
      needSubscriptionSetup: false
    }
  },
  methods: {
    async loadUserData () {
      let dashBoardService = new DashBoardService()
      this.userData = await dashBoardService.getUserData(true)
      this.userName = this.userData.first_name
      const selectedPlan = this.userData.subscriptions.find(plan => {
        return plan.id === this.subscriptionID
      })
      console.log('selectedPlan', selectedPlan)
      this.needSubscriptionSetup= (selectedPlan && (selectedPlan.has_workout_programs || selectedPlan.has_meal_plans))
      console.log('needSubscriptionSetup', this.needSubscriptionSetup)
    },
    async goToNextPage () {
      await this.loadUserData()
      const selectedPlan = this.userData.subscriptions.find(plan => {
        return plan.id === this.subscriptionID
      })
      if (selectedPlan && (selectedPlan.has_workout_programs || selectedPlan.has_meal_plans)) {
        this.$router.replace({ path: this.$appConfig.appUrlList.onboard.replace(':subscription', this.subscriptionID), query: { from: 'thank-you' } })
      } else {
        this.$router.replace({ path: this.$appConfig.appUrlList.dashboard })
      }
    }
  }

}
