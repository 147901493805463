<template>
  <div :class="componentClasses" @click="clicked()">
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 0C4.48591 0 0 4.48591 0 9.99984C0 15.5139 4.48591 20 10 20C15.5141 20 19.9998 15.5139 19.9998 9.99984C19.9998 4.48591 15.5141 0 10 0ZM13.7509 13.0438C13.7509 13.4289 13.4297 13.7507 13.044 13.7507H7.02134C6.63613 13.7509 6.24931 13.3548 6.24931 12.9694V6.95649C6.24931 6.57161 6.57129 6.24931 6.95649 6.24931H13.044C13.4297 6.24931 13.7509 6.57161 13.7509 6.95649V13.0438Z" fill="#3D3D3D"/>
  </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconStop',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-stop': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
