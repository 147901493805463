<template>
        <popup class="gc-popup-body-fat-estimator" v-if="visible" :visible="visible" @hide="closePopup">
            <popup-title class="gc-popup-body-fat-estimator__title">
                <popup-button-close @click="closePopup" ></popup-button-close>
            </popup-title>
            <popup-body>
                <popup-head class="gc-popup-body-fat-estimator__heading">
                    <popup-text-heading class="text-center">{{popupHeading}}</popup-text-heading>
                </popup-head>
                <validation-observer ref="form" v-slot="{ handleSubmit, invalid }">
                  <popup-content v-if="state==='init'">
                          <form class="gc-popup-body-fat-estimator__form" id="body-fat-estimator-form" @submit.prevent="handleSubmit(submitForm)">

                              <popup-text-body class="gc-popup-body-fat-estimator__form-label">{{$t('message[\'body-fat-estimator.height-label\']',{unit:unit})}}</popup-text-body>
                              <validation-provider
                                  :name="$t('message[\'progress.height\']')"
                                  rules="required"
                                  v-slot="{ errors }">
                                  <text-input
                                      class="gc-popup-body-fat-estimator__form-input"
                                      type="number"
                                      step="0.01"
                                      placeholder="00"
                                      v-model="formData.height"
                                      :error-message="errors[0]"></text-input>
                              </validation-provider>

                              <popup-text-body class="gc-popup-body-fat-estimator__form-label">{{$t('message[\'body-fat-estimator.neck-circumference-label\']',{unit:unit})}}</popup-text-body>
                              <validation-provider
                                  :name="'neck circumference'"
                                  rules="required"
                                  v-slot="{ errors }">
                                  <text-input
                                      class="gc-popup-body-fat-estimator__form-input"
                                      type="number"
                                      step="0.01"
                                      placeholder="00" v-model="formData.neckCircumference"
                                      :error-message="errors[0]"></text-input>
                              </validation-provider>

                              <popup-text-body class="gc-popup-body-fat-estimator__form-label">{{$t('message[\'body-fat-estimator.abdomen-circumference-label\']',{unit:unit})}}</popup-text-body>
                              <validation-provider
                                  :name="'abdomen circumference'"
                                  rules="required"
                                  v-slot="{ errors }">
                                  <text-input
                                      class="gc-popup-body-fat-estimator__form-input"
                                      type="number"
                                      step="0.01"
                                      placeholder="00"
                                      v-model="formData.abdomenCircumference"
                                      :error-message="errors[0]"></text-input>
                              </validation-provider>

                              <popup-text-body class="gc-popup-body-fat-estimator__form-error-msg text-center">{{customError}}</popup-text-body>
                          </form>
                  </popup-content>

                  <popup-footer v-if="state==='init'">
                    <button-primary
                                  form="body-fat-estimator-form"
                                  class="gc-popup-body-fat-estimator__form-button-submit"
                                  :text="$t('message[\'general.calculate\']')"
                                  type="submit"
                                  :disabled="invalid"></button-primary>
                  </popup-footer>
                 </validation-observer>

                <loading-buffer v-if="state==='calculating'" height="40"></loading-buffer>
            </popup-body>
        </popup>
</template>

<script>
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import NativeAppMixin from '../../../mixins/NativeAppMixin'
import {ValidationObserver, ValidationProvider} from 'vee-validate'

import PopupBody from '../popups/popup-sub-components/PopupBody'
import PopupContent from '../popups/popup-sub-components/PopupContent'
import PopupFooter from '../popups/popup-sub-components/PopupFooter'
import PopupHead from '../popups/popup-sub-components/PopupHead'
import PopupTitle from '../popups/popup-sub-components/PopupTitle'
import Popup from '../popups/Popup'
import PopupButtonClose from '../popups/popup-sub-components/PopupButtonClose'
import PopupTextHeading from '../popups/popup-sub-components/PopupTextHeading'
import TextBodySmall from '../../global/typography/TextBodySmall.vue'
import TextInput from '../../global/inputs/TextInput'
import ButtonPrimary from '../../global/buttons/ButtonPrimary'
import LoadingBuffer from '../../layout/template-1/global/widgets/LoadingBuffer.vue'
import PopupTextBody from './popup-sub-components/PopupTextBody.vue'

export default {
  name: 'PopupBodyFatEstimatorNew',
  mixins: [popupAnimationMixin],
  components: {
    NativeAppMixin,
    ValidationObserver,
    ValidationProvider,
    Popup,
    PopupTitle,
    PopupBody,
    PopupFooter,
    PopupContent,
    PopupHead,
    PopupButtonClose,
    PopupTextHeading,
    TextBodySmall,
    TextInput,
    ButtonPrimary,
    LoadingBuffer,
    PopupTextBody
  },
  props: {
    weight: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    system: {
      type: String,
      default: 'metric'
    },
    gender: {
      type: String,
      default: 'm'
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      state: 'init', // init,calculating
      imagesUrl: this.$appConfig.imagesUrl,
      formData: {
        height: 0,
        abdomenCircumference: 0,
        neckCircumference: 0
      },
      bodyFat: '-',
      unit: '',
      customError: ''
    }
  },
  mounted () {
    this.formData.height = Math.round(this.height * 100) / 100
    if (this.system === 'metric') {
      this.unit = this.$i18n.t('message["general.cm"]')
    } else {
      this.unit = this.$i18n.tc('message["general.inches"]', 2)
    }
    this.hideLoading()
  },
  computed: {
    popupHeading () {
      if (this.state === 'init') {
        return this.$i18n.t("message['general.suggest-body-fat']")
      } else if (this.state === 'calculating') {
        return this.$i18n.t("message['general.calculating']")
      } else {
        return ''
      }
    }
  },
  methods: {
    closePopup (e) {
      if (e) {
        e.preventDefault()
        this.$emit('close')
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    },
    emitClose () {
      this.closePopup()
      this.$emit('close')
    },
    showErrorMessage () {
      this.customError = this.$t('message["general.invalid-data"]')
      this.$nextTick(() => {
        document.querySelector('.gc-popup-body-fat-estimator__form-error-msg').scrollIntoView()
      })
    },
    submitForm () {
      this.customError = ''
      this.state = 'calculating'
      setTimeout(() => {
        if (parseFloat(this.formData.height) && parseFloat(this.formData.neckCircumference) && parseFloat(this.formData.abdomenCircumference)) {
          this.bodyFat = this.estimateBodyFat(this.formData.height, this.formData.neckCircumference, this.formData.abdomenCircumference,
            this.gender, this.system)
          if (this.bodyFat < 3 || this.bodyFat > 100) {
            this.state = 'init'
            this.showErrorMessage()
          } else {
            this.$emit('body-fat', this.bodyFat)
            this.$emit('close')
          }
        } else {
          this.state = 'init'
          this.showErrorMessage()
        }
      }, 1000)
    },
    estimateBodyFat (height, neckCircumference, abdomenCircumference, gender, system) {
      const heightCentimeters = system === 'metric' ? height : height * 2.54
      const neckCircumferenceCentimeters = system === 'metric' ? neckCircumference : neckCircumference * 2.54
      const abdomenCircumferenceCentimeters = system === 'metric' ? abdomenCircumference : abdomenCircumference * 2.54
      if (gender === 'm') {
        // formula for men
        return 10.1 - 0.239 * heightCentimeters + 0.8 * abdomenCircumferenceCentimeters - 0.5 * neckCircumferenceCentimeters
      } else {
        // formula for women
        return 19.2 - 0.239 * heightCentimeters + 0.8 * abdomenCircumferenceCentimeters - 0.5 * neckCircumferenceCentimeters
      }
    }

  }
}
</script>
