var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc-notification-list-item",class:{'pc-notification-list-item--active': !_vm.notification.isRead}},[_c('div',{staticClass:"pc-notification-list-item__left-section"},[_c('div',{staticClass:"pc-notification-list-item__icon pc-notification-list-item__icon--crown"},[(_vm.notification.user.role === 'creator')?_c('icon-crown'):_vm._e(),_c('text-body-small',{attrs:{"weight":_vm.extra - _vm.bold}},[_vm._v(_vm._s(_vm.userAvatarCharacters))])],1),_c('div',{staticClass:"pc-notification-list-item__content"},[_c('div',{staticClass:"pc-notification-list-item__content-user-icon"},[(_vm.notificationType === 'comment-added')?_c('text-body-small',{attrs:{"line-height":'multi'},domProps:{"innerHTML":_vm._s(_vm.$t('message[\'community.notification.commented\']', {
          userNameTitle: `<span>${_vm.$tc(
            'message[\'community.notification.user-title\']',
            _vm.notification.actorCount-1,
            { userName: _vm.userActiveName, otherCount: _vm.notification.actorCount - 1 }
          )}</span>`
        }))}}):(_vm.notificationType === 'comment-mention')?_c('text-body-small',{attrs:{"line-height":'multi'},domProps:{"innerHTML":_vm._s(_vm.$t('message[\'community.notification.comment-mentioned\']', { 
          userNameTitle: `<span>${_vm.userActiveName}</span>`
        }))}}):(_vm.notificationType === 'post-mention')?_c('text-body-small',{attrs:{"line-height":'multi'},domProps:{"innerHTML":_vm._s(_vm.$t('message[\'community.notification.post-mentioned\']', { 
          userNameTitle: `<span>${_vm.userActiveName}</span>`
        }))}}):_vm._e()],1),(_vm.notification.actorCount === 1 && _vm.notificationType === 'comment-added')?_c('div',{staticClass:"pc-notification-list-item__content-user-content"},[(_vm.notification.comment && _vm.notification.comment.trim().length > 0)?_c('text-body-extra-small',[_vm._v(" \""+_vm._s(_vm.notification.comment)+"\" ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"pc-notification-list-item__content-user-timestamp"},[_c('text-body-extra-small',[_vm._v(_vm._s(_vm.notificationAt))])],1)])]),_c('div',{staticClass:"pc-notification-list-item__right-section"},[_c('div',{staticClass:"pc-notification-list-item__arrow"},[_c('icon-forward',{attrs:{"size":"sm1"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }