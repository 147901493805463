import VueI18n from 'vue-i18n'
import LanguageService from '../../services/LanguageService'
import moment from 'moment'
import lodash from 'lodash'
import langJSON from '../../../lang'
import defaultLang from '../../../lang/lang'
import Vue from 'vue'
Vue.use(VueI18n)
/** handle language **/
let messages = {def: {message: {}}}
export const locale = 'def'
export const i18n = new VueI18n({
  locale: locale, // set locale
  messages, // set locale messages
  fallbackLocale: locale
})
// eslint-disable-next-line
const loadedLanguages = [locale] // our default language that is preloaded
function setI18nLanguage (lang) {
  const langService = new LanguageService()
  moment.locale(langService.setAppLangCode())
  i18n.locale = lang
  return lang
}

export function loadLanguageAsync (lang, withToningLang = true, theme = 1) {
  // eslint-disable-next-line
  return new Promise((resolve, reject) => {
    const langService = new LanguageService()
    // // If the language hasn't been loaded yet
    let toningLang = templateConfig.localization || {}
    langService.getAll().then(langMessages => {
      if (typeof toningLang.validation === 'string') {
        try {
          toningLang.validation = JSON.parse(toningLang.validation)
        } catch (e) {
          // do nothing
        }
      }
      let langValues
      const appLang = (theme === 2) ? langJSON : defaultLang
      if (process.env.NODE_ENV === 'production' && withToningLang) {
        langValues = lodash.merge({}, langMessages, appLang, toningLang)
      } else {
        langValues = lodash.merge({}, langMessages, appLang)
      }

      if (typeof toningLang.validation === 'object') {
        langValues.validation = toningLang.validation
      }
      i18n.setLocaleMessage(lang, { message: langValues })
      resolve(setI18nLanguage(lang))
    })

    // loadedLanguages.push(appLang)
  })
}

export function setMessages (messages) {
  i18n.mergeLocaleMessage(locale, {message: messages})
}
/** end handle language **/
