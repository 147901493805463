import { mapGetters, mapMutations } from 'vuex'
import IngredientService from '../../../services/IngredientService'
import {showMealSwapDislikePopupFlag} from '../../../includes/TemplateSettings'

export default {
  data: function () {
    return {
      msi: null
    }
  },
  computed: {

  },
  methods: {
    ...mapGetters({
      getMealSwapIngredients: 'mealSwapStore/getMealSwapIngredients'
    }),
    ...mapMutations({
      updateMealSwapIngredients: 'mealSwapStore/updateMealSwapIngredients',
      updateMealSwapStatus: 'mealSwapStore/updateMealSwapStatus'
    }),
    updateMSI (toAdd) {
      const repeatIngredientSwap = []
      const msi = this.msi
      for (let ingredients = 0; ingredients < toAdd.length; ingredients++) {
        if (msi[toAdd[ingredients].value]) {
          msi[toAdd[ingredients].value][1] = msi[toAdd[ingredients].value][1] + 1
          if (msi[toAdd[ingredients].value][1] >= 3) {
            repeatIngredientSwap.push({ name: toAdd[ingredients].value, id: toAdd[ingredients].id, active: false })
          }
        } else {
          msi[toAdd[ingredients].value] = [toAdd[ingredients].id, 1]
        }
      }
      this.updateMealSwapIngredients(msi)
      return repeatIngredientSwap
    },
    filterDisabled (availableIngredients, recipeIngredients) {
      let recipeIngredientIds = []
      for (let ingredient in recipeIngredients) {
        recipeIngredientIds.push(recipeIngredients[ingredient].reference_ingredient_id)
      }
      return availableIngredients.filter((item) => {
        return recipeIngredientIds.includes(item.id)
      })
    },
    getRepeatIngredientSwap (recipe) {
      const ingService = new IngredientService()
      return new Promise(async (resolve) => {
        let repeatIngredientSwap = []
        let recipeIngredients = recipe.getIngredients()
        if (!recipe.isLoaded()) {
          try {
            await recipe.getDetailedInfo()
            recipeIngredients = recipe.getIngredients()
          } catch (e) {
            recipeIngredients = recipe.getIngredients()
          }
        }
        recipe.getIngredients()
        if (showMealSwapDislikePopupFlag()) {
          try {
            let ingResponse = await ingService.setIngredients()
            let availableIngredients = ingResponse.data.ingredients
            this.msi = this.getMealSwapIngredients()
            availableIngredients = this.filterDisabled(availableIngredients, recipeIngredients)
            repeatIngredientSwap = this.updateMSI(availableIngredients)
          } catch (e) {
            repeatIngredientSwap = []
          }

          resolve(repeatIngredientSwap)
        } else {
          resolve(repeatIngredientSwap)
        }
      })
    },
    showUpdateDislikesPopup (ingredients, mealID) {
      this.$emit('show-update-dislikes', { ingredients, mealID })
    }

  }
}
