<template>
  <action-meal-save
  :state="state"
  :showText="showText"
  :limitText="limitText"
  v-on="$listeners"
  @clicked="clicked()"
  ></action-meal-save>
</template>

<script>
import ActionMealDislike from '../../../global/actions/ActionMealDislike.vue'
import ActionMealSave from '../../actions/ActionMealSave'

export default {
  name: 'CardMealActionSave',
  components: {
    ActionMealSave,
    ActionMealDislike
  },
  props:
    {
      state: {
        type: String,
        default: ''
      },
      showText: {
        type: Boolean,
        default: true
      },
      limitText: {
        type: Boolean,
        default: false

      }
    },
  computed: {
    componentClasses: function () {
      return {
        'gc-card-meal__action': true,
        'gc-card-meal__action-dislike': true,
        'gc-card-meal__action-disabled': this.state === 'disabled',
        'gc-card-meal__action-onTap': this.state === 'onTap',
        'gc-card-meal__action-active': this.state === 'active'
      }
    },
    text: function () {
      let text = this.$i18n.t('message["meal-plan.dislike"]')
      if (this.state === 'active') {
        return this.$i18n.t('message["meal-plan.disliked"]')
      }
      if (this.limitText) {
        text = text.substring(0, 2) + '...'
      }
      return text
    }
  },
  methods: {
    clicked () {
      this.$emit('clicked')
    }
  }
}
</script>
<style scoped>
</style>
