import { extend } from 'vee-validate'
import {i18n} from '../../localization/i18n'

const validator = {
  validate (height) {
    let heightInInches = height.totalInch
    let heightInCM = height.cm
    if (height.preference === 'metric' && heightInCM) {
      if (heightInCM < 120) {
        return i18n.t("message['validation']['messages']['min_value']").replace('{min}', 120)
      } else if (heightInCM > 244) {
        return i18n.t("message['validation']['messages']['max_value']").replace('{max}', '244 cm')
      } else {
        return true
      }
    } else if (height.preference === 'imperial' && heightInInches) {
      if (heightInInches < 48) {
        return i18n.t("message['validation']['messages']['min_value']").replace('{min}', 48)
      } else if (heightInInches > 96) {
        return i18n.t("message['validation']['messages']['max_value']").replace('{max}', '8 ft (96 inches)')
      } else {
        return true
      }
    }
    return i18n.t("message['validation']['messages']['required']")
  }
}
extend('height', validator)
