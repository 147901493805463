import DashboardAPI from '../api/dashboard/DashboardAPI'

export default class InvitationsService {
  _pageData = null
  _invitations = null

  loadPageData () {
    let dashboardAPI = new DashboardAPI()

    return new Promise((resolve, reject) => {
      if (this._pageData) {
        resolve(this._pageData)
        return
      }

      dashboardAPI.getInvitationPageData().then(apiData => apiData.data).then(data => {
        this._pageData = data
        resolve(this._pageData)
      }).catch(err => {
        reject(err)
      })
    })
  }

  loadInvitations () {
    let dashboardAPI = new DashboardAPI()

    return new Promise((resolve, reject) => {
      if (this._invitations) {
        resolve(this._invitations)
        return
      }

      dashboardAPI.getInvitations().then(apiData => apiData.data).then(data => {
        this._invitations = data.users.reverse()
        resolve(this._invitations)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getInvitationLink () {
    return this._pageData.links[0] || null
  }

  getPageHeader () {
    return this._pageData.header_text || ''
  }

  getPageTitle () {
    return this._pageData.title_text || ''
  }

  getPageImage () {
    return this._pageData.header_image || ''
  }

  getPageDescription () {
    return this._pageData.description || ''
  }

  getInvitations () {
    if (!Array.isArray(this._invitations)) {
      return []
    }
    return this._invitations.filter(invitation => {
      return invitation.state !== 'registered'
    })
  }

  getInvitationsCount () {
    if (!this._invitations) {
      return 0
    }
    return this.getInvitations().length
  }
}
