<template>
  <div>
    <div class="macroapp--page-purchase macroapp--external-page" :style="'background-image: url('+backgroundImage+')'">
      <div class="card-purches">
        <h1 class="head__title">{{ $t("message['iap.program.head_title']") }}</h1>
        <p class="sub__title">{{ $t("message['iap.program.sub__title']") }}</p>
        <div class="card-purches__program">
          <div class="card-program card-program--mealplan" v-html="$t('message[\'iap.program.meal_plan\']')"></div>
          <div class="card-program-symbol">
            <p>+</p>
          </div>
          <div class="card-program card-program--workout" v-html="$t('message[\'iap.program.workout\']')"></div>
        </div>
        <div class="card-purches__description" v-html="$t('message[\'iap.program.description\']')"></div>
        <div class="card-purches__description text-center" v-html="$t('message[\'iap.program.small_description\']')"></div>
        <div class="footer card-purches__buy">
          <p class="sub__title" v-if="productDetails.name">{{ $t("message['iap.program.purchase_label']") }} {{productDetails.name}}</p>
          <button type="button" class="btn btn-primary btn-main footer__btn-main"
                  @click="purchaseClick" v-if="productDetails.localizedPrice">
            {{ $t("message['iap.program.buy_for']") }} {{productDetails.localizedPrice}}
          </button>
          <button type="button" class="btn btn-main btn-main--with-only-outline" @click="goBack">
            {{ $t("message['iap.program.back_btn']") }}
          </button>
        </div>
        <div class="card-purches__description text-center" v-html="$t('message[\'iap.program.bottom_description\']')"></div>
      </div>
    </div>
  </div>
</template>

<script>
import IAPService from '../../../../services/IAPService'
export default {
  name: 'Purchase',
  data () {
    return {
      productDetails: {
        name: '',
        localizedPrice: ''
      },
      backgroundImage: ''
    }
  },
  beforeDestroy () {
    this.destroyListeners()
  },
  mounted () {
    this.setPageData()
    this.listeners()
  },
  methods: {
    logError (type, message, payload = {}, show = true) {
      this.logEvent(type, {
        error: message,
        payload: payload
      })
      IAPService.logError(message, payload, show)
      console.log(type, message, payload)
    },
    goBack () {
      this.$router.push('/login')
    },
    setPageData () {
      this.backgroundImage = this.getStoreImage('login.background')
      const iapService = new IAPService()
      let purchaseConfig = iapService.getPurchaseConfig()
      if (purchaseConfig && 'enabled' in purchaseConfig && purchaseConfig.enabled) {
        iapService.inAppProductDetailsRequestSend()
      } else {
        iapService.setPurchaseConfig()
          .then(() => {
            purchaseConfig = iapService.getPurchaseConfig()
            if (purchaseConfig && 'enabled' in purchaseConfig && purchaseConfig.enabled) {
              iapService.inAppProductDetailsRequestSend()
            } else {
              this.logError('SignUp.IAPError', 'Server Error: IAP not enabled', false)
              this.goBack()
            }
          }).catch(() => {
            this.logError('SignUp.IAPError', 'IAP not enabled', false)
            this.goBack()
          })
      }
    },
    purchaseClick () {
      this.$store.commit('showLoading')
      const iapService = new IAPService()
      iapService.purchaseInAppProductRequestSend()
    },
    listeners () {
      document.addEventListener('iap-message', this.iapStatusCheck)
    },
    iapStatusCheck (ev) {
      this.logEvent('SignUp.Purchase.IAPResponse', ev.detail, false)
      switch (ev.detail.status) {
        case 'product_details_response':
          this.productDetails = ev.detail.data
          this.logEvent('SignUp.IAPInitiated',
            {
              price: this.productDetails.localizedPrice,
              plan_name: this.productDetails.name
            }, false)
          this.$store.commit('hideLoading')
          break
        case 'purchase_response':
          this.$store.commit('showLoading')
          this.$emit('purchaseClick')
          break
        case 'timeout':
          this.logError('SignUp.IAPError', 'Purchase Timeout', ev.detail.data)
          this.$store.commit('hideLoading')
          break
        case 'error':
          this.logError('SignUp.IAPError', 'Purchase Error', ev.detail.data)
          this.$router.push('/login')
          break
      }
    },
    destroyListeners () {
      document.removeEventListener('iap-message', this.iapStatusCheck)
    }
  }
}
</script>

<style scoped>
</style>
