<template>
  <div>
    <popup-content>
      <text-content class="pc-popup-swap-injuries-request-success-text text-center" :size="'sm2'" :weight="'medium'" :line-height="'multi'">{{description}}</text-content>
    </popup-content>
    <popup-footer>
    </popup-footer>
  </div>

</template>

<script>
import workoutSwapMixin from '../../../../../mixins/workoutSwapMixin'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupTextBody from '../../../../global/popups/popup-sub-components/PopupTextBody'
import PopupFooter from '../../../../global/popups/popup-sub-components/PopupFooter'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import TextContent from '../../../../root/TextContent'

export default {
  name: 'SwapRequestSuccess',
  mixins: [workoutSwapMixin],
  components: {
    ButtonPrimary,
    PopupFooter,
    PopupTextBody,
    PopupContent,
    TextContent
  },
  data: function () {
    return {
      footerData: {
        btnLabel: this.$i18n.t('message["general.continue"]'),
        active: true
      },
      description: ''
    }
  },
  mounted () {
    this.setPageLanguageData()

    this.$emit('content-change')
  },
  methods: {
    setPageLanguageData () {
      // title
      this.setSwapTitle(this.$i18n.t('message["swap.request-success-title"]'))
      // success description text
      this.description = this.$i18n.t("message['swap.success-text']")
      // footer button text
      this.footerData.btnLabel = this.$i18n.t("message['swap.done-button']")
      setTimeout(() => {
        this.footerSubmit()
      }, 4000)
    },
    footerSubmit () {
      this.$emit('successRedirect', 'Exercise Swapped')
    }
  }
}
</script>
