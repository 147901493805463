<template>
   <card-content class="pc-profile__card-recurring-subscription" :class="componentClasses">
    <div class="pc-profile__card-recurring-subscription-head">
        <text-body-medium
          :weight="'extra-bold'"
          :lineHeight="'lh-lg'"  v-html="plan.getName()"/>
        <text-body-small v-html="textDescription"/>
    </div>
    <div class="pc-profile__card-recurring-subscription-footer">
      <div class="pc-profile__card-recurring-subscription-footer-left">
        <div v-if="plan.getType() === 'subscription' && plan.isCancelled()"
             class="pc-profile__card-recurring-subscription__label-cancelled">
          <text-body-extra-small weight="extra-bold" line-height="lh-xl">{{$t('message["billing-info.cancelled"]')}}</text-body-extra-small>
        </div>
        <button-custom v-else :type="'outline'" :text="textMoreDetails" @click="showPopup"></button-custom>
      </div>
      <div class="pc-profile__card-recurring-subscription-footer-right"
           v-if="!plan.isCancelled()">
        <icon-payment-card size="sm4" v-if="plan.isACardPayment()"></icon-payment-card>
        <icon-payment-s-e-p-a size="sm4" v-else-if="plan.isASEPAPayment()"></icon-payment-s-e-p-a>
        <icon-payment-i-deal size="sm4" v-else-if="plan.isAIDealPayment()"></icon-payment-i-deal>
        <icon-payment-giro size="sm4" v-else-if="plan.isAGiroPayment()"></icon-payment-giro>
        <icon-payment-apple-in-app size="sm4" v-else-if="plan.isApplePaymentGateway()"></icon-payment-apple-in-app>
      </div>

    </div>

     <popup-billing-info :visible="popupVisibility" :plan="plan" @close="hidePopup"/>
   </card-content>
</template>

<script>
import CardContent from '../../../../../global/cards/CardContent.vue'
import TextBodyMedium from '../../../../../global/typography/TextBodyMedium.vue'
import TextBodySmall from '../../../../../global/typography/TextBodySmall.vue'
import ButtonCustom from '../../../../../global/buttons/ButtonCustom.vue'
import PlanService from '../../../../../../services/plans/PlanService'
import PopupBillingInfo from './PopupBillingInfo'
import TextBodyExtraSmall from '../../../../../global/typography/TextBodyExtraSmall'
import IconPaymentCard from '../../../../../root/icons/IconPaymentDebitCard'
import IconPaymentAppleInApp from '../../../../../root/icons/IconPaymentAppleInApp'
import IconPaymentSEPA from '@/components/root/icons/IconPaymentSEPA.vue'
import IconPaymentIDeal from '@/components/root/icons/IconPaymentIDeal.vue'
import IconPaymentGiro from '@/components/root/icons/IconPaymentGiro.vue'

export default {
  name: 'CardRecurringSubscription',
  props: {
    plan: {
      validator: function (value) {
        return value instanceof PlanService
      }
    }
  },
  components: {
    IconPaymentGiro,
    IconPaymentIDeal,
    IconPaymentSEPA,
    IconPaymentAppleInApp,
    IconPaymentCard,
    TextBodyExtraSmall,
    ButtonCustom,
    PopupBillingInfo,
    CardContent,
    TextBodyMedium,
    TextBodySmall
  },
  data: function () {
    return {
      popupVisibility: false
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'pc-profile__card-recurring-subscription--subscription': this.plan.getType() === 'subscription',
        'pc-profile__card-recurring-subscription--one-off': this.plan.getType() === 'one-off',
        'pc-profile__card-recurring-subscription--challenge': this.plan.getType() === 'challenge'
      }
    },
    textMoreDetails: function () {
      return this.$i18n.t("message['billing-info.more-details']")
    },
    textDescription: function () {
      if (this.plan.getType() === 'subscription') {
        if (this.plan.isCancelled()) {
          const date = this.plan.getCancelledDateString()
          return this.$i18n.t("message['billing-info.cancel-on']",
            {date: `<span>${date}</span>`})
        } else {
          const date = this.plan.nextBillingDateString()
          const price = this.plan.getPrice()
          const currency = this.plan.getCurrency()
          return this.$i18n.t("message['billing-info.renew-on']",
            {
              date: `<span>${date}</span>`,
              price: `<span>(${price} ${currency})</span>`
            })
        }
      } else if (this.plan.getType() === 'one-off') {
        const date = this.plan.getExpireDateString()
        return this.$i18n.t("message['billing-info.expires-on']",
          {date: `<span>${date}</span>`})
      } else if (this.plan.getType() === 'challenge') {
        if (!(this.plan.isStarted())) {
          const date = this.plan.getActivationDateString()
          return this.$i18n.t("message['billing-info.challenge-start']",
            {date: `<span>${date}</span>`})
        } else {
          const date = this.plan.getExpireDateString()
          return this.$i18n.t("message['billing-info.challenge-end']",
            {date: `<span>${date}</span>`})
        }
      }
    }
  },

  methods: {
    showPopup () {
      this.popupVisibility = true
    },
    hidePopup () {
      this.popupVisibility = false
    }
  }

}

</script>
