<template>
  <div :class="componentClasses" @click="clicked()">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
        <path d="M9.71181 14.8843L0.128397 5.7176C-0.0367998 5.55811 -0.0441436 5.29443 0.115389 5.12842C0.274492 4.9624 0.538167 4.9567 0.704575 5.11541L9.9999 14.0062L19.2953 5.11537C19.4617 4.95666 19.7253 4.96236 19.8845 5.12838C19.9618 5.20897 20 5.31311 20 5.41647C20 5.52635 19.9569 5.63538 19.8714 5.71756L10.288 14.8843C10.1269 15.0382 9.87295 15.0382 9.71181 14.8843Z" fill="#3D3D3D"/>
        </g>
        <defs>
        <clipPath id="clip0">
        <rect width="20" height="20" fill="white" transform="matrix(1.19249e-08 1 1 -1.19249e-08 0 0)"/>
        </clipPath>
        </defs>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconDownArrow',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-down-arrow': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
