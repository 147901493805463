<template>
  <div :class="componentClasses">
    <div class="pc-timer__wrap d-flex">
      <transition>
        <div class="pc-timer-group pc-timer__left">
          <text-content class="title pc-timer-set-timer__header"
                        v-if="state==='pending'"
                        size="lg1"
                        weight="extra-bold"
                        line-height="multi"
          >
            <div v-html="header"></div>
          </text-content>
          <text-content class="pc-timer-set-timer__time-second-title"
                        size="xl1"
                        weight="medium"
                        line-height="single"
          >
            <span id="time" v-html="time"></span>
          </text-content>
          <text-content class="pc-timer-set-timer__time--with-sub-title"
                        size="sm4"
                        weight="extra-bold"
                        line-height="multi"
          >
              <span
              >{{$t('message["general.time-minutes-short"]')}}</span>
          </text-content>
        </div>
      </transition>
      <div class="pc-timer__right text-right">
        <div
          class="pc-timer__btn-lg"
          v-if="state==='paused'||state==='pending'||state==='stopped'"
          @click="resume"
        >
          <icon-play size="lg4"/>
        </div>
        <div @click="pause()" class="pc-timer__btn-lg" v-if="state==='started'">
          <icon-pause size="lg4"></icon-pause>
        </div>
      </div>
    </div>

    <transition>
      <div class="pc-timer__footer">
        <div class="row">
          <div class="col text-center" v-if="state==='started'||state==='paused'">
            <text-content
              class="timer__play-btn pc-timer__action-button"
              size="sm1"
              weight="extra-bold"
              line-height="multi"
              v-on:click="stop()"
            >{{$t('message["general.stop"]')}}
            </text-content>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style>
</style>

<script>
import { Howl, Howler } from 'howler'
import IconPause from '../../../../root/icons/IconPause'
import IconPlay from '../../../../root/icons/IconPlay'
import TextContent from '../../../../root/TextContent'

export default {
  name: 'stop-watch',
  components: {
    TextContent,
    IconPlay,
    IconPause
  },
  props: {
    setID: {
      default: -1
    },
    play: {
      default: false
    },
    startValue: {
      default: 0
    },
    header: {
      default: ''
    }
  },
  data: function () {
    return {
      sounds: {
        started: null,
        ended: null
      },
      startSetID: 0,
      state: 'pending',
      startTime: Date.now(),
      currentTime: Date.now(),
      interval: null,
      imagePath: this.$appConfig.imagesUrl + 'timer/',

      pauseAt: null,
      pauseRelasedAt: null
    }
  },
  mounted: function () {
    this.startSetID = this.setID
    if (this.startValue !== null && parseInt(this.startValue) > 0) {
      this.state = 'paused'
      this.startTime = Date.now() - (parseInt(this.startValue) * 1000)
    }
    this.pauseAt = Date.now()
    this.interval = setInterval(this.updateCurrentTime, 1000)
  },
  destroyed: function () {
    clearInterval(this.interval)
  },
  watch: {
    play (newVal, oldVal) {
      if (newVal === true && oldVal === false) {
        if (this.state === 'paused' || this.state === 'pending') {
          this.resume()
        }
      } else if (newVal === false && oldVal === true) {
        if (this.state === 'started') {
          this.stop()
        }
      }
    },
    state (newVal, oldVal) {
      if (newVal === 'paused' && oldVal === 'started') {
        this.pauseAt = Date.now()
        this.timerEvent('paused', {
          time: this.getTime(),
          'set-id': this.startSetID
        })
      } else if (newVal === 'started' && oldVal === 'paused') {
        this.pauseRelasedAt = Date.now()
        this.startTime = this.startTime + (this.pauseRelasedAt - this.pauseAt)
        this.updateCurrentTime()
        this.timerEvent('resumed', {
          time: this.getTime(),
          'set-id': this.startSetID
        })
      } else if (newVal === 'started' && oldVal === 'pending') {
        this.playStartTimer()
        this.timerEvent('started')
      } else if (newVal === 'stopped' && oldVal === 'started') {
        this.playEndTimer()
        this.timerEvent('stopped', {
          time: this.getTime(),
          'set-id': this.startSetID
        })
      }
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'pc-timer': true,
        'pc-timer-stop-watch': true,
        'pc-timer-stop-watch--playing': this.state === 'started',
        'pc-timer-stop-watch--stopped': this.state === 'stopped',
        'pc-timer-stop-watch--paused': this.state === 'paused',
        'pc-timer-stop-watch--pending': this.state === 'pending'
      }
    },
    time: function () {
      return this.minutes + ':' + this.seconds
    },
    milliseconds: function () {
      return this.currentTime - this.startTime
    },
    hours: function () {
      const lapsed = this.milliseconds
      const hrs = Math.floor(lapsed / 1000 / 60 / 60)
      return hrs >= 10 ? hrs : '0' + hrs
    },
    minutes: function () {
      const lapsed = this.milliseconds
      const min = Math.floor((lapsed / 1000 / 60) % 60)
      return min >= 10 ? min : '0' + min
    },
    seconds: function () {
      const lapsed = this.milliseconds
      const sec = Math.ceil((lapsed / 1000) % 60)
      return sec >= 10 ? sec : '0' + sec
    }
  },
  methods: {
    setVolume () {
      if (templateConfig.instance_config &&
          'workout_timer_vol' in templateConfig.instance_config) {
        let vol = parseInt(templateConfig.instance_config.workout_timer_vol) || 0
        vol = vol / 10
        Howler.volume(vol)
      }
    },
    playStartTimer () {
      this.sounds.started = new Howl({
        src: ['https://app-redesign-plugin-files.s3.us-west-1.amazonaws.com/sounds/timer/timer-start.mp3'],
        volume: 0.5
      })
      this.setVolume()
      this.sounds.started.play()
    },
    playEndTimer () {
      this.sounds.ended = new Howl({
        src: ['https://app-redesign-plugin-files.s3.us-west-1.amazonaws.com/sounds/timer/timer-end.mp3'],
        volume: 0.5
      })
      this.setVolume()
      this.sounds.ended.play()
    },
    reset: function () {
      this.state = 'started'
      this.startTime = Date.now()
      this.currentTime = Date.now()
    },
    pause: function () {
      this.$data.state = 'paused'
    },
    resume: function () {
      if (this.state === 'pending') {
        this.initState()
      }
      this.state = 'started'
    },
    stop () {
      this.state = 'stopped'
      setTimeout(() => {
        this.initState()
        this.state = 'pending'
      }, 1000)
    },
    initState () {
      this.startTime = Date.now()
      this.currentTime = Date.now()
    },
    updateCurrentTime: function () {
      if (this.state === 'started') {
        this.currentTime = Date.now()
        this.$emit('current-time', {
          time: this.getTime(),
          'set-id': this.startSetID
        })
      }
    },
    getTime () {
      let totalSeconds = Math.ceil((this.milliseconds / 1000))
      return {
        days: 0,
        hours: this.hours,
        milliseconds: '',
        minutes: this.minutes,
        seconds: this.seconds,
        totalDays: 0,
        totalHours: this.hours,
        totalMilliseconds: '',
        totalMinutes: Math.ceil((this.milliseconds / 60000)),
        totalSeconds: totalSeconds
      }
    },
    timerEvent (name, eventData) {
      this.$emit(name, eventData)
      eventData = { type: 'stop-watch-' + name, ...eventData }
      const event = new CustomEvent('exercise-timer', { detail: eventData })
      document.dispatchEvent(event)
    }
  }
}
</script>
