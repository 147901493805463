<template>
    <div class="pc-card-share-pair-code">
        <text-body-small class="pc-card-share-pair-code__description" :line-height="'multi'"
                         v-html="$i18n.t('message[\'member-connect.code-share-description\']')"
        ></text-body-small>
        <div class="pc-card-share-pair-code__code-box">
            <text-body-regular class="pc-card-share-pair-code__code-box-title" :line-height="'multi'"
                               :weight="'extra-bold'">{{ pairCode }}
            </text-body-regular>
            <container-text-share v-if="pairCode"
                                  :popup-button-text="$t('message[\'popup.copy-and-share.copy-code\']')"
                                  :popup-description="$t('message[\'popup.copy-and-share.description-code\']')"
                                  :text="pairCode">
                <button-custom
                        class="pc-card-share-pair-code__code-box-button"
                        :text="$i18n.t('message[\'member-connect.connect-code-share-button\']')"
                ></button-custom>
            </container-text-share>
        </div>
    </div>
</template>

<script>
import TextBodySmall from '../../../../../global/typography/TextBodySmall'
import TextBodyRegular from '../../../../../global/typography/TextBodyRegular'
import ButtonCustom from '../../../../../global/buttons/ButtonCustom'
import ContainerTextShare from '../../../../../global/containers/ContainerTextShare'

export default {
  name: 'CardSharePairCode',
  props: {
    pairCode: {
      default: ''
    }
  },
  components: {
    ContainerTextShare,
    TextBodySmall,
    TextBodyRegular,
    ButtonCustom
  }
}
</script>

<style scoped></style>
