<template>
  <div :class="componentClasses" v-on="$listeners">
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6819 6.61829C15.6819 5.52589 15.248 4.47842 14.4756 3.70602C13.7033 2.93369 12.6558 2.5 11.5636 2.5C10.4714 2.5 9.42398 2.93368 8.65158 3.70602C7.87925 4.47835 7.44531 5.52583 7.44531 6.61829C7.44531 7.71044 7.87923 8.75792 8.65158 9.53031C9.42391 10.3026 10.4714 10.7366 11.5636 10.7366C12.6556 10.7351 13.7023 10.301 14.4744 9.52882C15.2463 8.75668 15.6807 7.70995 15.6819 6.61829ZM8.48833 6.61829C8.48833 5.80264 8.8125 5.02041 9.38907 4.44382C9.96586 3.86703 10.7479 3.54308 11.5635 3.54308C12.3792 3.54308 13.1612 3.86701 13.738 4.44382C14.3148 5.02037 14.6388 5.80264 14.6388 6.61829C14.6388 7.43369 14.3148 8.21593 13.738 8.79276C13.1612 9.36931 12.3792 9.69326 11.5635 9.69326C10.7484 9.69253 9.96659 9.36811 9.39006 8.79158C8.81375 8.21503 8.48926 7.43345 8.48833 6.61829Z" fill="white"/>
    <path d="M19.0001 17.8053C18.9981 16.2427 18.3769 14.7446 17.2718 13.6398C16.1671 12.5349 14.669 11.9134 13.1066 11.9116H10.0213C8.45898 11.9133 6.96092 12.5348 5.85614 13.6398C4.75118 14.7446 4.12985 16.2427 4.12793 17.8053C4.12793 18.0932 4.36145 18.3267 4.6496 18.3267C4.93776 18.3267 5.17128 18.0932 5.17128 17.8053C5.17273 16.5192 5.68401 15.2864 6.59341 14.3771C7.50257 13.4677 8.73545 12.9562 10.0213 12.9548H13.1066C14.3924 12.9562 15.6253 13.4677 16.5345 14.3771C17.4439 15.2863 17.9552 16.5192 17.9566 17.8053C17.9566 18.0932 18.1901 18.3267 18.4783 18.3267C18.7665 18.3267 19.0001 18.0932 19.0001 17.8053Z" fill="white"/>
    <path d="M1.15278 10.2361C1.05487 10.1382 1 10.0057 1 9.86723C1 9.72896 1.05488 9.59624 1.15278 9.49858C1.25069 9.40067 1.3834 9.3458 1.52167 9.3458H3.20248V7.66499C3.20248 7.37683 3.43601 7.14331 3.72416 7.14331C4.01208 7.14331 4.24559 7.37683 4.24559 7.66499V9.3458H5.9264C6.21456 9.3458 6.44808 9.57932 6.44808 9.86723C6.44808 10.1554 6.21455 10.3889 5.9264 10.3889H4.24559V12.0697C4.24559 12.3579 4.01207 12.5914 3.72416 12.5914C3.436 12.5914 3.20248 12.3579 3.20248 12.0697V10.3889H1.52167C1.3834 10.3889 1.25069 10.334 1.15278 10.2361Z" fill="white"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconInviteFriend',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-invite-friend': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
