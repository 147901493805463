import WorkoutProgramExerciseService from './WorkoutProgramExerciseService'
import WorkoutProgramsService from './WorkoutProgramsService'
import Lodash from 'lodash'
import { i18n } from '../helpers/localization/i18n'
import WorkoutProgramExerciseSetTypeService from './WorkoutProgramExerciseSetTypeService'

export default class WorkoutProgramDayService {
  completed = false
  name = ''
  id = 0
  programID = 0
  weekID = 0
  description = ''
  type = 'standard'
  injuries = []
  exercises = []
  circuitRounds = 1

  /**
   * @param day - API day Object
   */
  constructor (day) {
    this.completed = day.completed
    this.name = day.day
    this.id = day.id
    this.programID = day.programID
    this.weekID = day.weekID

    if (day.workouts.length > 1) {
      this.type = 'standard'
    } else if (this.isCircuitModeEnabled() && day.workouts[0]) {
      this.type = day.workouts[0].type || 'standard'
    }

    day.workouts.forEach(workout => {
      this.description = this.description + ((workout.description) ? workout.description : '')
      this.injuries = [...this.injuries, ...workout.injuries]
      Lodash.uniqBy(this.injuries, 'name')
      this.circuitRounds = workout.circuit_rounds || 1

      workout.exercises.forEach(exercise => {
        this.exercises.push(new WorkoutProgramExerciseService({
          ...exercise,
          circuitRounds: workout.circuit_rounds || 1,
          programID: this.programID,
          weekID: this.weekID,
          dayID: this.id
        }))
      })
    })

    this.getGroupedExercisesBySetType()
  }

  /**
   * returns the exercise complete state
   * @returns {boolean}
   */
  getIsCompleted () {
    return this.completed
  }

  getID () {
    return this.id
  }

  getName () {
    return this.name
  }

  getDescription () {
    return this.description
  }

  getInjuries () {
    return this.injuries
  }

  /**
   * returns the exercise array
   * @returns {[WorkoutProgramExerciseService]}
   */
  getExercises () {
    return this.exercises
  }

  getType () {
    return this.type
  }

  /**
   * returns the Circuit Mode enabled
   * @returns {boolean}
   */
  isCircuitModeEnabled () {
    let workoutService = new WorkoutProgramsService()
    return workoutService.isCircuitModeEnabled()
  }

  /**
   *
   * @param exerciseID
   * @returns {WorkoutProgramExerciseService}
   */
  getExercise (exerciseID) {
    return this.exercises.find(exercise => {
      return exerciseID === exercise.getID()
    })
  }

  /**
   * returns the exercise list grouped by the set type
   * @returns {[]}
   */
  getGroupedExercisesBySetType () {
    let groupedExercises = this.groupExercises()
    let groupedExercisesBySetTypeArray = []
    groupedExercises.forEach(group => {
      let exerciseGroup = new WorkoutProgramExerciseSetTypeService(group[0].setType, group)
      groupedExercisesBySetTypeArray.push(exerciseGroup)
    })

    return groupedExercisesBySetTypeArray
  }

  /**
   * group the exercises by super set, giant set, circuit and ect
   * @returns {[]}
   */
  groupExercises () {
    let exercises = this.exercises
    let mode = this.type
    if (mode === 'circuit') {
      return this.groupCircuitExercises(exercises)
    }
    const groupedExercises = []
    let preID = 0
    let currentGroup = []
    exercises.forEach((exercise) => {
      if (preID === 0) {
        preID = exercise.setType.id
        currentGroup.push(exercise)
      } else if (
        exercise.setType.id !== preID ||
        !this.isSetAMultipleSet(exercise.setType.name)
      ) {
        groupedExercises.push(currentGroup)
        currentGroup = []
        currentGroup.push(exercise)
        preID = exercise.setType.id
      } else if (exercise.setType.id === preID) {
        currentGroup.push(exercise)
      } else {
        currentGroup.push(exercise)
        preID = exercise.setType.id
      }
    })

    groupedExercises.push(currentGroup)
    return groupedExercises
  }

  /**
   * group the all day exercises to one circuit set group
   * @param exercises
   * @returns {[]}
   */
  groupCircuitExercises (exercises) {
    const groupedExercises = []
    groupedExercises.push(exercises)
    return groupedExercises
  }

  /**
   * returns the Day is a circuit
   * @returns {boolean}
   */
  getIsCircuit () {
    return this.type === 'circuit'
  }

  getCircuitDetails () {
    return {
      isCircuit: this.getIsCircuit(),
      rounds: this.circuitRounds
    }
  }
  /**
   * returns circuit rounds
   * @returns {number}
   */
  getCircuitRounds () {
    if (this.type === 'circuit') {
      return this.circuitRounds
    }
    throw new Error('current day not circuit workout')
  }

  /**
   * check is Set a grouped exercises set -> giant set , super set
   * @param type
   * @returns {boolean}
   */
  isSetAMultipleSet (type) {
    const sets = [
      (i18n.t('message["workouts.set-type-super"]')).toLowerCase(),
      (i18n.t('message["workouts.set-type-giant"]')).toLowerCase(),
      (i18n.t('message["workout.mode-circuit"]')).toLowerCase(),
      (i18n.t('message["workout.set-type-triset"]')).toLowerCase()
    ]
    type = type.toLowerCase()
    return sets.some(eType => {
      return type.includes(eType)
    })
  }

  /**
   * set day complete when all exercises are completed within the day
   * note - not save the compete state in API
   */
  setDayCompleteStateByExercises () {
    this.completed = this.exercises.every((exercise) => {
      return exercise.getIsCompleted()
    })
  }

  /**
   * set day complete and all exercises completed within the day
   * note - not save the compete state in API
   */
  setDayCompleted () {
    this.completed = true
    this.exercises.forEach((exercise) => {
      exercise.setExerciseCompleted()
    })
  }

  /**
   * set day incomplete and all exercises incomplete within the day
   * note - not save the compete state in API
   */
  setDayIncomplete () {
    this.completed = false
    this.exercises.forEach((exercise) => {
      exercise.setExerciseInComplete()
    })
  }

  getExerciseCount () {
    return this.exercises.length
  }
}
