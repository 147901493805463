<template>
  <div class="list-container">
    <div class="content-image" v-if="checkHasImage()">
      <!-- <img :src="mainImage"/> -->
    </div>
    <p class="content-text app-content-wrap" v-bind:class="{'content-text--without-content-image': !checkHasImage() }"
       v-html="description">

    </p>
    <items :item-list="itemList" :last-expanded="lastExpanded"/>
  </div>
</template>
<script>
import Items from './Items'
import NativeAppMixin from '../../../../../mixins/NativeAppMixin'

export default {
  name: 'list-container',
  components: { Items },
  mixins: [NativeAppMixin],
  props: {
    itemList: {},
    mainImage: {},
    hideImage: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: ''
    },
    lastExpanded: {}
  },
  methods: {
    checkHasImage () {
      return !this.hideImage && Boolean(this.mainImage)
    },
    linkNavigateClickHandler (event) {
      let targetElement = event.target
      const selector = '.list-container .content-text a'
      while (targetElement != null) {
        if (targetElement.matches(selector)) {
          event.preventDefault()
          this.handleUrlNavigate(targetElement.getAttribute('href'))
          return
        }
        targetElement = targetElement.parentElement
      }
    }
  },
  mounted () {
    setTimeout(() => {
      document.querySelector('body')
        .addEventListener('click', this.linkNavigateClickHandler, true)
    }, 100)
  },
  beforeDestroy () {
    document.querySelector('body')
      .removeEventListener('click', this.linkNavigateClickHandler, true)
  }
}
</script>
