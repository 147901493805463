<template>
  <div :class="componentClasses" @click="clicked()">
    <div class="gc-side-bar__button-collapse__wrapper">
      <text-content :weight="'medium'" :lineHeight="'multi'" :size="'sm2'">
        <slot></slot>
      </text-content>
      <icon-down-arrow></icon-down-arrow>
    </div>
  </div>
</template>
<script>
import TextContent from '../../../root/TextContent'
import IconDownArrow from '../../../root/icons/IconDownArrow'
import IconUpArrow from '../../../root/icons/IconUpArrow'
import Separator from '../../../root/Separator'

export default {
  name: 'side-bar-button-collapse',
  components: {
    TextContent,
    IconDownArrow,
    IconUpArrow,
    Separator
  },
  props: {
    selected: {
      default: false
    }
  },
  data: function () {
    return {
      stateSelected: false
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-side-bar__button-collapse': true,
        'gc-side-bar__button-collapse__selected': this.stateSelected
      }
    }
  },
  watch: {
    selected: {
      immediate: true,
      handler: function () {
        this.stateSelected = this.selected
      }
    }
  },
  methods: {
    clicked () {
      if (!this.disabled) {
        this.$emit('click')
        this.stateSelected = !this.stateSelected
      }
    }
  }
}

</script>
