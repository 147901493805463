<template>
  <div ref="input_wrapper" :class="componentClasses">
    <div
      class="gc-input-measurement-spinner__input">
      <div class="gc-input-measurement-spinner__left">
        <input
          maxlength="2"
          :minlength="minValue"
          step="any"
          type="number"
          v-model="value"
          value="value"
          @focus="handleInputState()"
          @blur="handleInputState()"
          id="input"
          ref="input"
          :disabled="disabled"
        />
      </div>
      <div class="gc-input-measurement-spinner__right">
        <text-body-regular
          @click="focusInputOnClick"
          class="gc-input-measurement-spinner__unit"
          :weight="'extra-bold'"
          :lineHeight="'multi'">
          {{unit}}
        </text-body-regular>
        <div class="gc-input-measurement-spinner__input-spinner">
          <icon-up-arrow-filled @click="addValueClicked" />
          <icon-down-arrow-filled @click="reduceValueClicked" />
        </div>
      </div>
    </div>
    <text-body-extra-small v-if="errorMessage.length > 0"
                           class="gc-input-measurement-spinner__error-message"
                           :lineHeight="'multi'">
      {{ errorMessage }}
    </text-body-extra-small>
  </div>
</template>

<script>
import IconUpArrowFilled from '../../root/icons/IconUpArrowFilled'
import IconDownArrowFilled from '../../root/icons/IconDownArrowFilled'
import TextBodyRegular from '../typography/TextBodyRegular'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'

export default {
  name: 'InputMeasurementSpinner',
  components: {
    TextBodyExtraSmall,
    TextBodyRegular,
    IconDownArrowFilled,
    IconUpArrowFilled
  },
  data: function () {
    return {
      inputValue: 0,
      isValueChanged: false,
      haveError: false,
      isTyped: false,
      isActive: false,
      lastValue: 0,
      step: 1
    }
  },
  props: {
    minValue: {
      type: Number,
      default: 0
    },
    maxValue: { // set max value
      type: Number,
      default: 9999
    },
    maxCharLength: { // set max char length (including decimal)
      type: Number,
      default: 4
    },
    errorMessage: {
      default: ''
    },
    value: {type: Number},
    disabled: {default: false},
    unit: {
      default: 'kg' // ( cm, kg, in, lb)  (//'kg' and 'cm' will have decimal points, 'lb' and 'in' will not)
    },
    inputFontWeight: {
      type: Number,
      default: 700
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-input-measurement-spinner': true,
        'gc-input-measurement-spinner__error': this.errorMessage.length > 0,
        'gc-input-measurement-spinner__disabled': this.disabled
      }
    }
  },
  watch: {
    inputValue: {
      handler: function () {
        if (this.inputValue !== 0) {
          this.isValueChanged = true
          let valueParts = this.inputValue.toString().split('.')
          let decimalCount = 0
          if (1 in valueParts) {
            decimalCount = valueParts[1].length
          }

          let inputCharCount = this.inputValue.toString().length
          if (inputCharCount > 5) {
            this.inputValue = this.lastValue
            this.setInputWidth()
            return
          }
          if (this.inputValue >= this.minValue && this.inputValue <= this.maxValue && decimalCount <= 2) {
            this.$emit('change', this.inputValue)
            this.$emit('input', this.inputValue)
            this.lastValue = this.inputValue
          } else {
            this.inputValue = this.lastValue
          }
          this.setInputWidth()
        } else {
          this.inputValue = 0
        }
      }
    },
    value: {
      immediate: true,
      handler: function () {
        this.inputValue = this.value
        if (typeof (this.inputValue) === 'string') {
          this.inputValue.replace(/^0+/, '')
        }
      }
    },
    unit: {
      immediate: true,
      handler: function () {
        this.setInputDecimalCount()
      }
    }
  },
  mounted () {
    this.setInputDecimalCount()
    this.setInputCssValues()
    this.setInputWidth()
  },
  methods: {
    setInputCssValues () {
      const input = this.$refs.input
      input.style.fontWeight = this.inputFontWeight
    },
    setInputWidth () {
      const wrapper = this.$refs.input_wrapper
      if (this.isDesktop) {
        wrapper.style.width = '100%'
      } else {
        const val = this.lastValue.toString()
        const newLength = 155 + (30 * val.length)
        wrapper.style.width = newLength + 'px'
      }
    },
    setInputDecimalCount () { // check if input needs decimal point or not by UNIT prop
      this.step = 0.1
    },
    focusInputOnClick () {
      this.$refs.input.focus()
    },
    addValueClicked () {
      if (this.value < 0) {
        this.value = 0
      }
      this.addValue()
      this.setInputWidth()
    },
    addValue () { // step = 1 if this.inludeDecimal = false
      this.isTyped = true
      if (this.disabled) {
        return
      }
      if (this.step >= 1) {
        this.inputValue = parseInt(this.inputValue) || 0
        if (this.inputValue <= this.maxValue) {
          this.inputValue += this.step
        }
        if (this.inputValue > this.maxValue) {
          this.inputValue = this.maxValue
        }
        this.lastValue = this.inputValue
      } else { // step = 0.1 if this.inludeDecimal = true
        if (this.inputValue <= this.maxValue) {
          let value = parseFloat(this.inputValue) + 0.1
          let valueRounded = Math.round(value * 10) / 10
          this.inputValue = valueRounded
        }
        this.lastValue = this.inputValue
      }
    },
    reduceValueClicked () {
      if (this.value > 0) {
        this.reduceValue()
      }
      this.setInputWidth()
    },
    reduceValue () {
      this.isTyped = true
      if (this.disabled) {
        return
      }
      if (this.step >= 1) { // step = -1 if this.inludeDecimal = false
        this.inputValue = parseInt(this.inputValue) || 0
        if (this.value >= this.minValue) {
          this.value -= this.step
          this.setInputWidth()
        }
        if (this.inputValue < this.minValue) {
          this.inputValue = this.minValue
        }
      } else { // step = -0.1 if this.inludeDecimal = true
        if (this.inputValue > this.minValue) {
          let value = this.inputValue - 0.1
          let valueRounded = Math.round(value * 10) / 10
          this.inputValue = valueRounded
          this.setInputWidth()
        }
      }
    },
    handleInputState () {
      this.isActive = !this.isActive
    }
  }
}
</script>
