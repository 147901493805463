<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 4.25C1 3.55964 1.55964 3 2.25 3H22.75C23.4404 3 24 3.55964 24 4.25C24 4.94036 23.4404 5.5 22.75 5.5H2.25C1.55964 5.5 1 4.94036 1 4.25Z" fill="black"/>
    <path d="M1 12.25C1 11.5596 1.55964 11 2.25 11H22.75C23.4404 11 24 11.5596 24 12.25C24 12.9404 23.4404 13.5 22.75 13.5H2.25C1.55964 13.5 1 12.9404 1 12.25Z" fill="black"/>
    <path d="M2.25 19C1.55964 19 1 19.5596 1 20.25C1 20.9404 1.55964 21.5 2.25 21.5H22.75C23.4404 21.5 24 20.9404 24 20.25C24 19.5596 23.4404 19 22.75 19H2.25Z" fill="black"/>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconMenu',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-menu': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
