<template>
  <div :class="componentClasses" @click="clicked()">
   <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.08439 25.3065C4.35534 24.0942 3.00446 22.548 2.05349 20.813L6.00509 20.1163C6.6041 20.9267 7.32821 21.6673 8.19877 22.2753C12.5398 25.3133 18.5403 24.2552 21.5787 19.916C24.6115 15.5778 23.5541 9.58099 19.2195 6.53611C14.8772 3.50212 8.88038 4.55952 5.83953 8.89535C5.83006 8.90656 5.81677 8.93944 5.8024 8.95533L5.80305 8.95904L8.37065 10.7601L1.43872 11.9691L0.208235 5.04475L2.76865 6.83757C2.78858 6.8207 2.8072 6.79642 2.81722 6.77748C7.02759 0.762133 15.3213 -0.700277 21.3351 3.51227C27.3558 7.73124 28.8173 16.0194 24.6023 22.0413C20.3913 28.0529 12.0985 29.5209 6.08439 25.3065Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconReplay',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-replay': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
