<template>
  <popup
    :visible="visible"
    modal-class="pc-popup-meal-plan-summary-email"
    v-if="visible"
    size="md"
    @hide="closePopup()"
  >
    <popup-title>
      <popup-button-close  :style="closeButtonStyles" @hide="closePopup($event)"></popup-button-close>
    </popup-title>
    <popup-body class="text-center">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(formSubmit)"
          class="pc-popup-meal-plan-summary__form"
        >
          <popup-content>
            <popup-head>
              <popup-text-heading>
                {{ title ? title : '' }}
              </popup-text-heading>
            </popup-head>
            <icon-complete-filled
              v-if="status === 'success'"
              :size="'lg1'"
            ></icon-complete-filled>
            <loading-buffer v-if="status === 'loading'" height="50"/>
            <div v-if="status === 'init'">
              <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(formSubmit)">
                  <ValidationProvider name="email" rules="required|email" v-slot="{ errors  }">
                    <div class="form-group ma-form">
                      <text-body-small
                        class="pc-popup-meal-plan-summary__form-label"
                        :line-height="'multi'"
                      >{{ $i18n.t('message[\'general.email\']') }}
                      </text-body-small>
                      <text-input
                        class="pc-popup-meal-plan-summary__form-input"
                        :placeholder="$t('message[\'meal-plan.enter-your-email\']')"
                        :error-message="errors[0]"
                        v-model="formData.email"
                      />
                    </div>
                  </ValidationProvider>
                </form>
              </ValidationObserver>
            </div>
          </popup-content>
          <popup-footer>
            <button-primary
              v-if="status === 'init'"
              class="pc-popup-meal-plan-summary__form-button"
              :text="footerData.btnLabel"
            ></button-primary>
          </popup-footer>
        </form>
      </ValidationObserver>
    </popup-body>
  </popup>
</template>

<script>
import DashBoardService from '../../../../../services/DashboardService'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '../../../../../helpers/validation'
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import MealPlanService from '../../../../../services/MealPlanService'
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import Popup from '../../../../global/popups/Popup'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import TextInput from '../../../../global/inputs/TextInput'
import TextBodySmall from '../../../../global/typography/TextBodySmall'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import PopupFooter from '../../../../global/popups/popup-sub-components/PopupFooter'
import IconCompleteFilled from '../../../../root/icons/IconCompleteFilled'
import UserEventsService from '../../../../../services/UserEventsService'

export default {
  name: 'PopupMealPlanSummaryEmail',
  props: {
    visible: {
      default: false
    },
    mealPlanID: {
      required: true
    }
  },
  mixins: [popupAnimationMixin],
  components: {
    LoadingBuffer,
    ValidationProvider,
    ValidationObserver,
    Popup,
    PopupTitle,
    PopupContent,
    PopupHead,
    PopupTextHeading,
    PopupButtonClose,
    PopupBody,
    TextInput,
    TextBodySmall,
    ButtonPrimary,
    PopupFooter,
    IconCompleteFilled
  },
  mounted () {
    this.title = this.$i18n.t('message["meal-plan.email-summary-popup-title"]')
    const dashBoardService = new DashBoardService()
    dashBoardService.getUserData().then(data => {
      this.formData.email = data.email
    })
  },
  computed: {
    closeButtonStyles: function () {
      return {
        'visibility': (this.status !== 'loading') ? 'visible' : 'hidden'
      }
    }
  },
  data: function () {
    return {
      footerData: {
        btnLabel: this.$i18n.t('message["meal-plan.email-summary-popup-button-text"]'),
        active: true

      },
      submited: false,
      status: 'init',
      successImage: this.$appConfig.imagesUrl + 'swap-success.svg',
      systemErrors: [],
      formData: {
        email: '',
        subject: this.$i18n.t('message[\'meal-plan.email-summary-popup-email-subject\']'),
        message: this.$i18n.t('message[\'meal-plan.email-summary-popup-email-message\']'),
        meal_plan_id: ''
      },
      title: '',
      contentText: '',
      imagesUrl: this.$appConfig.imagesUrl,
      meals: []
    }
  },
  methods: {
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }

      this.closeAnimatedPopup(() => {
        this.status = 'init'
        this.$emit('close')
      })
    },
    formSubmit () {
      if (this.submited) {
        return
      }
      this.submited = true
      this.footerData.active = false
      this.status = 'loading'
      this.title = this.$i18n.t('message["meal-plan.email-summary-popup-title-sending"]')
      this.sendEmail()
    },
    sendEmail () {
      const service = new MealPlanService()
      this.formData.meal_plan_id = this.mealPlanID
      this.logEvent('MealPlan.EmailWeeklySummary')
      service.sendWeeklySummaryEmail(this.formData).then(() => {
        this.title = this.$i18n.t('message["meal-plan.email-summary-popup-title-done"]')
        this.hideLoading()
        this.status = 'success'
        setTimeout(() => {
          this.closePopup()
          UserEventsService.sendMealPlanSummaryFeedBackPopupView()
        }, 3000)
      }).catch(err => {
        this.hideLoading()
        if (err.response.data.type && err.response.data.type === 'logic') {
          this.systemErrors = err.response.data.errors
        } else if (err.response.data.errors) {
          this.systemErrors = err.response.data.errors
        } else {
          this.systemErrors = [this.$i18n.t('message["general.server-error-description"]')]
        }
        this.status = 'init'
      }).finally(() => {
        this.submited = false
        this.footerData.active = true
      })
    }

  }
}
</script>
