<template>
  <div class="workout-log-history-empty">
    <icon-history class="workout-log-history-empty__icon" :size="'md2'" />
    <text-body-extra-small
      class="workout-log-history-empty__text"
      :line-height="'multi'"
      v-html="$t('message[\'workout-log.history-empty\']')"
    />
  </div>
</template>
<script>
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall.vue'
import IconClock from '../../../../root/icons/IconClock.vue'
import IconHistory from '../../../../root/icons/IconHistory.vue'
export default {
  components: {
    IconHistory,
    TextBodyExtraSmall,
    IconClock
  },
  name: 'workout-log-history-empty'
}
</script>
