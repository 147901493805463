import Vue from 'vue'
import Vuex from 'vuex'
import BannerService from '../../services/BannerService'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    service: new BannerService(),
    banners: []
  },
  mutations: {
    setBanners (state, banners) {
      state.banners = banners
    }
  },
  actions: {
    loadBanners ({ commit, state }) {
      setTimeout(function () {
        state.service.getBanners().then(banners => {
          state.banners = banners
          commit('setBanners', banners)
        })
      }, 3000)
    }
  },
  getters: {
    getBanners (state) {
      return state.banners
    },
    getBannersToShow (state, commit, rootState) {
      const currentPage = rootState.pageStore.currentPage
      return state.banners.filter((banner, index) => {
        return state.service.checkBannerToShow(index, currentPage)
      })
    }

  }
}
