<template>
  <div ref="textDiv" :class="componentClasses" v-on="$listeners">
      <slot></slot>
  </div>
</template>

<script>
import NativeAppMixin from '../../mixins/NativeAppMixin'

export default {
  name: 'TextContent',
  mixins: [NativeAppMixin],
  props: {
    /**
     * To change the font weight
     * Expected values: 'light' = 300 | 'medium' = 400 | 'bold' = 600 | 'extra-bold' = 700
     */
    weight: {
      default: 'medium'
    },
    /**
     * To change the font size
     * Expected values: 'sm1 = 12px' | 'sm2' = 14px | 'sm3' = 16px | 'sm4' = 18px | 'md1' = 20px | 'md2' = 22px | 'md3' = 24px | 'md4' = 26px | 'lg1' = 30px | 'lg2' = 34px | 'lg3' = 34px | 'lg4' = 36px | 'xl1' = 60px
     */
    size: {
      default: 'sm4'
    },
    /**
     * To change the font style
     * Expected values: 'underline' | 'italic' | default - no styles
     */
    decoration: {
      default: ' '
    },
    /**
     * To change the font line height
     * Expected values: 'single' = 1 | 'multi' = 1.5
     * Expected values: 'lh-sm' = 1 | 'lh-md' = 1.3 | 'lh-lg' = 1.5 | 'lh-xl' = 1.6
     */
    lineHeight: {
      default: 'lh-sm'
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'rc-text-content': true,
        'rc-text-content--weight-light': this.weight === 'light',
        'rc-text-content--weight-medium': this.weight === 'medium',
        'rc-text-content--weight-bold': this.weight === 'bold',
        'rc-text-content--weight-extra-bold': this.weight === 'extra-bold',
        'rc-text-content--size-sm1': this.size === 'sm1',
        'rc-text-content--size-sm2': this.size === 'sm2',
        'rc-text-content--size-sm3': this.size === 'sm3',
        'rc-text-content--size-sm4': this.size === 'sm4',
        'rc-text-content--size-md1': this.size === 'md1',
        'rc-text-content--size-md2': this.size === 'md2',
        'rc-text-content--size-md3': this.size === 'md3',
        'rc-text-content--size-md4': this.size === 'md4',
        'rc-text-content--size-lg1': this.size === 'lg1',
        'rc-text-content--size-lg2': this.size === 'lg2',
        'rc-text-content--size-lg3': this.size === 'lg3',
        'rc-text-content--size-lg4': this.size === 'lg4',
        'rc-text-content--size-xl1': this.size === 'xl1',
        'rc-text-content--style-underline': this.decoration === 'underline',
        'rc-text-content--style-italic': this.decoration === 'italic',
        'rc-text-content--line-height-single-line': this.lineHeight === 'single', // this has been deprecated & no longer used
        'rc-text-content--line-height-multi-line': this.lineHeight === 'multi', // this has been deprecated & no longer used
        'rc-text-content--line-height-sm': this.lineHeight === 'lh-sm',
        'rc-text-content--line-height-md': this.lineHeight === 'lh-md',
        'rc-text-content--line-height-lg': this.lineHeight === 'lh-lg',
        'rc-text-content--line-height-xl': this.lineHeight === 'lh-xl'
      }
    }
  },
  mounted () {
    if (this.$refs['textDiv']) {
      this.$refs['textDiv'].addEventListener(
        'click',
        this.linkNavigateClickHandler,
        true
      )
    }
  },
  methods: {
    linkNavigateClickHandler (event) {
      let targetElement = event.target
      let selector = 'a'
      while (targetElement != null) {
        if (targetElement.matches(selector)) {
          event.preventDefault()
          this.handleUrlNavigate(targetElement.getAttribute('href'))
          return
        }
        targetElement = targetElement.parentElement
      }
    }
  },
  beforeDestroy () {
    if (this.$refs['textDiv']) {
      this.$refs['textDiv'].removeEventListener(
        'click',
        this.linkNavigateClickHandler,
        true
      )
    }
  }
}
</script>

<style scoped></style>
