<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.9374 15.8327L18.4438 8.65774C18.6773 8.43958 19.0428 8.45014 19.2631 8.68142C19.4831 8.91288 19.4756 9.27855 19.2462 9.50091L11.3328 17.0654C11.2261 17.171 11.0817 17.2296 10.9316 17.2282C10.7768 17.2302 10.6279 17.1693 10.5188 17.0597L6.02414 12.5651C5.79778 12.3385 5.79778 11.9716 6.02414 11.745C6.25069 11.5187 6.61764 11.5187 6.844 11.745L10.9374 15.8327Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.951506 7.71619C2.88642 3.04472 7.44516 -0.000874249 12.5014 3.62951e-05C15.8184 -0.00797652 19.0007 1.31104 21.3395 3.66316C26.2206 8.54442 26.2206 16.4582 21.3395 21.3391C16.4585 26.2203 8.54473 26.2203 3.66348 21.3391C0.0870332 17.7648 -0.983041 12.3878 0.951506 7.71619ZM1.1633 12.5011C1.1633 18.7516 6.25089 23.8394 12.5014 23.8394C18.752 23.8394 23.8397 18.7516 23.8397 12.5011C23.8397 6.25057 18.752 1.16299 12.5014 1.16299C6.25089 1.16299 1.1633 6.25057 1.1633 12.5011Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconCompletedLinear',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-dot': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
