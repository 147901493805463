<template>
  <div class="gc-sidebar gc-navigator-side-menu">
    <nav id="menu" v-on:scroll.passive="setScroll">
      <div class="gc-sidebar__top">
        <div class="gc-sidebar__user-details-wrap" v-if="isMember" @click="navigate('/account-details?page=account-settings')">
          <div class="gc-sidebar__image-wrap">
            <img :src="profileImage" @onerror="imageLoadOnError" alt="profile-image"/>
          </div>
          <text-content
            class="gc-sidebar__profile-name"
            :weight="'extra-bold'"
            :size="'md1'"
            :lineHeight="'multi'">
            {{$t('message["general.sidebar-title"]', { name: charLimitedUserName }) }}
          </text-content>

        </div>
        <side-bar-button-primary
          @click="navigate('/dashboard')"
          :active="(!logButtonActive &&    curruntRoute === '/dashboard')"
          >
          <template #default>
            {{$t('message["navigator.bottom-bar.home"]')}}
          </template>
          <template #icon>
            <icon-home-filled size="sm2" v-if="(!logButtonActive && curruntRoute === '/dashboard')"/>
            <icon-home-linear size="sm2" v-else/>
          </template>
        </side-bar-button-primary>
        <side-bar-button-primary
          v-if="isMember"
          @click="navigate('/workout-programs')"
          :active="!logButtonActive && curruntRoute.startsWith('/workout-programs')">
          <template #default>
            {{$t('message["navigator.bottom-bar.workout"]')}}
          </template>
          <template #icon>
            <icon-workout-filled size="sm2" v-if="!logButtonActive && curruntRoute.startsWith('/workout-programs')"/>
            <icon-workout-linear size="sm2" v-else/>
          </template>
        </side-bar-button-primary>
        <side-bar-button-primary
          v-if="isMember"
          @click="navigate('/meal-plans')"
          :active="!logButtonActive &&curruntRoute.startsWith('/meal-plans')">>
          <template #default>
            {{$t('message["navigator.bottom-bar.meal"]')}}
          </template>
          <template #icon>
            <icon-meal-plan-linear size="sm2" v-if="!logButtonActive && curruntRoute.startsWith('/meal-plans')"/>
            <icon-meal-plan-linear size="sm2" v-else/>
          </template>
        </side-bar-button-primary>
        <side-bar-button-primary
          @click="navigate('/menu')"
          v-bind:class="{'gc-side-bar__button-primary__selected':(!logButtonActive && !(curruntRoute.startsWith('/meal-plans')|| curruntRoute.startsWith('/workout-programs') || curruntRoute === '/dashboard'))}">
          <template #default>
            {{$t('message["navigator.bottom-bar.more"]')}}
          </template>
          <template #icon>
            <icon-menu size="sm2" />
          </template>
        </side-bar-button-primary>
        <side-bar-button-primary
          v-if="fitnessDiaryEnabled&&isMember"
          @click="toggleLogButton"
          class="gc-side-bar__button-log"
          v-bind:class="{'gc-side-bar__button-primary__selected':logButtonActive}">
          <template #default>
            {{$t('message["general.log"]')}}
          </template>
          <template #icon>
            <icon-add-menu-bar size="sm2" />
          </template>
        </side-bar-button-primary>
        <transition name="fade">
          <div class="gc-side-bar__button-group-log" v-if="logButtonActive">
            <button-side-bar-photo-upload
              v-if="fitnessDiaryEnabled && navigatorButtonsShow.metrics && isWeightMetricEnabled"/>
            <button-side-bar-weight-update
              v-if="fitnessDiaryEnabled && navigatorButtonsShow.scale && isPhotoJourneyEnabled" />
            <button-side-bar-metrics
              v-if="fitnessDiaryEnabled && navigatorButtonsShow.metrics && isWeightMetricEnabled"/>
          </div>
        </transition>

      </div>
      <div  class="gc-sidebar__bottom">
        <ul class="side-menu-bottom-wrap">
          <li>
            <button-link @click="openTermsAndConditionsPopup()" :text="$t('message[\'settings.terms-cond\']')"></button-link>
          </li>
          <li>
            <button-link @click="openPrivacyPolicyPopup()" :text="$t('message[\'settings.privacy-policy\']')"></button-link>
          </li>
          <li>
            <button-link :text="getTrainerIDInfo() + ' | ' + appVersion"></button-link>
          </li>
        </ul>
      </div>
    </nav>

    <content-page-popup :page="'privacy-policy'" :visible="showPrivacyPolicyPopup" v-bind:key="'privacy-policy-popup'" v-if="showPrivacyPolicyPopup"
                        v-on:close="closePrivacyPolicyPopup()"></content-page-popup>
    <content-page-popup :page="'legal-disclaimer'" :visible="showTermsCondPopup" v-bind:key="'legal-disclaimer-popup'" v-if="showTermsCondPopup"
                        v-on:close="closeTermsAndConditionsPopup()"></content-page-popup>
    <popup-logout-confirmation :visible="showLogoutConfirmationPopup"
                               v-on:close="closeLogoutConfirmationPopup()"
                               v-on:confirm="logout" />

  </div>
</template>
<script>

import sideBarMixin from '../../../../../mixins/component-mixins/global/sideBarMixin'
import DashboardService from '../../../../../services/DashboardService'
import PopupLogoutConfirmation from '../popups/PopupLogoutConfirmation'
import IconMenu from '../../root/icons/IconMenu'
import SideBarButtonPrimary from './side-bar-sub-components/SideBarButtonPrimary'
import ButtonLink from '../buttons/ButtonLink'
import TextContent from '../../root/TextContent'
import IconHomeLinear from '../../root/icons/IconHomeLinear'
import IconHomeFilled from '../../root/icons/IconHomeFilled'
import IconWorkoutLinear from '../../root/icons/IconWorkoutLinear'
import IconWorkoutFilled from '../../root/icons/IconWorkoutFilled'
import IconMealPlanLinear from '../../root/icons/IconMealplanLinear'
import IconMealPlanFilled from '../../root/icons/IconMealplanFilled'
import IconAddMenuBar from '../../root/icons/IconAddMenuBar'
import {
  isMeasurementsEnabled,
  isMeasurementWeightEnabled,
  isPhotoUploadEnabled
} from '../../../../../includes/TemplateSettings'
import ButtonSideBarMetrics from './ButtonSideBarMetrics'
import ButtonSideBarWeightUpdate from './ButtonSideBarWeightUpdate'
import ButtonSideBarPhotoUpload from './ButtonSideBarPhotoUpload'
import { checkIsMember } from '../../../../../helpers/user'

export default {
  name: 'NavigatorSideMenu',
  components: {
    ButtonSideBarMetrics,
    ButtonSideBarWeightUpdate,
    ButtonSideBarPhotoUpload,
    IconAddMenuBar,
    IconMealPlanFilled,
    IconMealPlanLinear,
    IconWorkoutFilled,
    IconWorkoutLinear,
    IconHomeFilled,
    IconHomeLinear,
    PopupLogoutConfirmation,
    IconMenu,
    SideBarButtonPrimary,
    ButtonLink,
    TextContent
  },
  data: function () {
    return {
      isContentPage: false,
      charLimitedUserName: this.userName,
      showLogoutConfirmationPopup: false,

      navigatorButtonsShow: {
        scale: true,
        photo: true,
        metrics: true,
        mood: false,
        food: false,
        habit: false
      }
    }
  },
  mixins: [sideBarMixin],
  computed: {
    isMetricsEnabled: function () {
      return isMeasurementsEnabled()
    },
    isPhotoJourneyEnabled: function () {
      return isPhotoUploadEnabled()
    },
    isWeightMetricEnabled: function () {
      return isMeasurementWeightEnabled()
    }
  },
  beforeMount () {
    if (!checkIsMember()) {
      return
    }
    this.loadUserConfig()
    this.settingCurruntRoute()
    this.isContentPage = this.curruntRoute.startsWith('/page')
    let date = new Date()
    this.year = date.getFullYear()

    let service = new DashboardService()
    service.getNavigation().then(data => {
      this.navLinksList = data.links
    })
    this.showWorkoutLog()
    this.checkNewFeatures()
  },
  mounted () {
    if (!checkIsMember()) {
      return
    }
    this.setUserNameCharLimit()
    this.loadUserConfig()
  },
  methods: {
    loadUserConfig () {
      let service = new DashboardService()
      service.getUserData().then(() => {
        this.fitnessDiaryEnabled = service.isFitnessDiaryEnabled()
        this.navigatorButtonsShow.scale = this.fitnessDiaryEnabled
        this.navigatorButtonsShow.photo = this.fitnessDiaryEnabled
        this.navigatorButtonsShow.metrics = this.fitnessDiaryEnabled
      }).catch(e => {
        this.navigatorButtonsShow.scale = false
        this.navigatorButtonsShow.photo = false
        this.navigatorButtonsShow.metrics = false
      })
    },
    toggleLogButton () {
      this.logButtonActive = !this.logButtonActive
    },
    isCurrentRoute (link) {
      if (link.url.endsWith('/dashboard') && this.$route.path === link.url && Object.values(this.$route.query).length > 0) {
        return false
      }

      if (Object.keys(this.$route.query).length > 0) {
        if (Object.keys(this.$route.query)[0] === 'workout-log-history') {
          return false
        }
      }
      return this.curruntRoute === link.url
    },
    setUserNameCharLimit () {
      let username = this.userName
      if (username.length > 8) {
        username = username.substring(0, 8)
        this.charLimitedUserName = username + '...'
      } else {
        this.charLimitedUserName = username
      }
    },
    showLogoutPopup () {
      this.showLogoutConfirmationPopup = true
    },
    closeLogoutConfirmationPopup () {
      this.showLogoutConfirmationPopup = false
    }
  }
}

</script>
