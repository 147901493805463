<template>
  <div :class="componentClasses">
    <select
      ref="input"
      v-bind="$attrs"
      :class="selectComponentClasses"
      :disabled="disabled"
      v-model="newValue"
      @change="onChange"
    >
      <option v-if="empty" class="gc-input-select__input-placeholder" value="" disabled selected>{{placeholderText}}</option>
      <option  v-for="(item, index) in items"
               :value="item.id"
               :key="index"
               :selected="newValue"
      >{{item.text}}</option>
    </select>
    <text-body-extra-small v-if="showErrorMessage && errorMessage.length > 0" class="gc-input-select__error">
      {{ errorMessage }}
    </text-body-extra-small>
  </div>
</template>

<script>
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'

export default {
  name: 'InputSelect',
  components: {
    TextBodyExtraSmall
  },
  data () {
    return {
      isOpen: false,
      newValue: ''
    }
  },
  props: {
    empty: {
      type: Boolean,
      default: true
    },
    value: {
      default: ''
    },
    items: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    placeholder: {
      default: ''
    },
    showErrorMessage: {
      default: true
    },
    errorMessage: {
      default: ''
    },
    disabled: {
      default: false
    },
    inputFontSize: {
      type: String,
      default: '18'
    },
    inputLineHeight: {
      type: String,
      default: ' 1.5'
    },
    inputFontWeight: {
      type: String,
      default: '700'
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function () {
        this.newValue = this.value
      }
    },
    newValue: function (val) {
      this.$emit('input', val)
      this.$emit('change', val)
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-input-select': true,
        'gc-input-select--error': this.errorMessage.length > 0,
        'gc-input-select--disabled': this.disabled
      }
    },
    selectComponentClasses: function () {
      return {
        'gc-input-select__input': true,
        'gc-input-select__input--empty': !this.newValue
      }
    },
    placeholderText: function () {
      return this.placeholder || this.$i18n.t('message["general.select"]')
    }
  },
  beforeMount () {
    this.newValue = this.value
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
    this.setInputCssValues()
  },
  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    onChange () {
      this.$emit('input', this.newValue)
      this.$emit('change', this.newValue)
    },
    clicked () {
      this.isOpen = true
      this.results = this.items
    },
    setItem (result) {
      this.value = result
      this.isOpen = false
    },
    onInputChange () {
      this.$emit('input', this.newValue)
      this.$emit('change', this.newValue)
    },
    setInputCssValues () {
      const input = this.$refs.input
      input.style.fontSize = this.inputFontSize + 'px'
      input.style.fontWeight = this.inputFontWeight
      input.style.lineHeight = this.inputLineHeight
    },
    handleClickOutside (event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false
      }
    }
  }
}
</script>
