<template>
  <div :class="componentClasses" >
    <loading-buffer
      v-if="status === 'loading'"
      :height="40"
      class="gc-card-subscription-plan__loading-buffer" />

    <div
      v-else
      class="gc-card-subscription-plan__container">
      <icon-complete-filled
        :size="'sm4'"
        class="gc-card-subscription-plan__icon" />
      <div class="gc-card-subscription-plan__content">
        <text-body-regular
          :weight="'extra-bold'"
          :line-height="'multi'"
          class="gc-card-subscription-plan__content-title">{{ title }}</text-body-regular>
        <text-body-extra-small
          :line-height="'multi'"
          class="gc-card-subscription-plan__content-period"><div v-html="billingText"/></text-body-extra-small>
        <!-- When discount is not applied -->
        <text-body-small
          v-if="discountedPrice===price"
          :weight="'extra-bold'"
          :line-height="'multi'"
          class="gc-card-subscription-plan__content-price">
          {{ priceText }}
        </text-body-small>
        <!-- When discount is applied -->
        <text-body-small
          v-else
          :weight="'extra-bold'"
          :line-height="'multi'"
          class="gc-card-subscription-plan__content-price">
          <span class="gc-card-subscription-plan__content-price-discounted">{{ currency + ' ' + price.toFixed(2) }}</span>
          {{ priceText }}
        </text-body-small>
        <text-body-small
          v-if="trialPeriod"
          :weight="'extra-bold'"
          :line-height="'multi'"
          class="gc-card-subscription-plan__content-trial-period">
          <span class="gc-card-subscription-plan__content-trial-period-text">{{ $i18n.t('message["sign-up.plan-card.trial-period"]') }}</span>
          {{ trialPeriod }}
        </text-body-small>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingBuffer from '../../../layout/template-1/global/widgets/LoadingBuffer'
import IconCompleteFilled from '../../../root/icons/IconCompleteFilled'
import TextBodyExtraSmall from '../../typography/TextBodyExtraSmall'
import TextBodyRegular from '../../typography/TextBodyRegular'
import TextBodySmall from '../../typography/TextBodySmall'
export default {
  name: 'CardSubscriptionPlan',
  components: { TextBodyRegular, TextBodyExtraSmall, IconCompleteFilled, TextBodySmall, LoadingBuffer },
  props: {
    title:
     {
       type: String,
       default: '',
       required: true
     },
    type: {
      type: String,
      default: 'subscription',
      required: true
    },
    period: {
      type: String,
      default: '-',
      required: false
    },
    price: {
      type: Number,
      default: 20,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    status: {
      type: String,
      default: 'default'
    },
    discountedPrice: {
      default: 0,
      type: Number
    },
    trialPeriod: {
      default: 0,
      type: Number
    }
  },
  computed: {
    billingText: function () {
      if (this.type === 'subscription') {
        return this.$i18n.t('message["sign-up.plan-card.subscription.billing-period"]') + ': ' + this.period
      } else if (this.type === 'one-off') {
        return this.$i18n.t('message["sign-up.plan-card.one-off.type"]')
      } else if (this.type === 'challenge') {
        return this.$i18n.t('message["sign-up.plan-card.challenge.type"]')
      }
      return ''
    },
    priceText: function () {
      let values = {
        currency: this.currency,
        price: this.price.toFixed(2)
      }
      if (this.discountedPrice !== this.price) {
        values.price = this.discountedPrice.toFixed(2)
      }
      if (this.type === 'subscription') {
        return this.$i18n.t('message["sign-up.plan-card.subscription.price"]', values)
      } else if (this.type === 'one-off' || this.type === 'challenge') {
        return this.$i18n.t('message["sign-up.plan-card.one-off.price"]', values)
      }
      return ''
    },
    componentClasses: function () {
      return {
        'gc-card-subscription-plan': true,
        'gc-card-subscription-plan__loading': this.status === 'loading'
      }
    }
  }

}
</script>

<style>

</style>
