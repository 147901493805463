<template>
  <page-secondary :header="headerOptions" class="pc-meal-plan-shopping-days">
    <page-container class="pc-meal-plan-shopping-days__container">
      <text-body-regular
        class="pc-meal-plan-shopping-days__heading"
        :weight="'extra-bold'"
        :line-height="'multi'"
        >{{
          $t('message["shopping-list.add-or-remove-days"]')
        }}</text-body-regular
      >

      <text-body-small
        class="pc-meal-plan-shopping-days__sub-heading"
        :weight="'extra-bold'"
        :line-height="'multi'"
        v-if="selectedDays.length !== days.length"
        >{{
          $tc('message["shopping-list.days-selected"]', selectedDays.length)
        }}</text-body-small
      >

      <text-body-small
        class="pc-meal-plan-shopping-days__sub-heading"
        :weight="'extra-bold'"
        :line-height="'multi'"
        v-else
        >{{ $t('message["shopping-list.all-days-selected"]') }}</text-body-small
      >

      <div class="pc-meal-plan-shopping-days__button-check-wrapper">
        <grid-row class="pc-meal-plan-shopping-days__button-check-row" no-gutters>
          <grid-col class="pc-meal-plan-shopping-days__button-check-col" cols="6" md="3" v-for="day in days" :key="'checkbox-button' + day.id">
            <button-check
              class="pc-meal-plan-shopping-days__button-check"
              :id="day.id"
              :text="day.text"
              :selected="selectedDays.includes(day.id)"
              @click="toggleSelectedDays"
            />
          </grid-col>
        </grid-row>

      </div>

      <text-body-regular
        class="pc-meal-plan-shopping-days__count-text"
        :weight="'extra-bold'"
        :line-height="'multi'"
        >{{ $t("message['general.for-how-many-people']") }}</text-body-regular
      >
      <input-number-spinner
        :step="1"
        :min-value="0"
        :max-value="100"
        :is-valid="true"
        v-model.number="peopleCount"
        class="pc-meal-plan-shopping-days__input-spinner"
      />

    </page-container>
    <page-footer>
      <button-primary
        class="pc-meal-plan-shopping-days__submit-button"
        :disabled="parseInt(peopleCount) < 1 || selectedDays.length < 1"
        @click="createList()"
        :text="$t('message[\'shopping-list.create-list\']')"
      />
    </page-footer>
  </page-secondary>
</template>

<script>
import pageInputDaysAndCountMixin from '../../../../../mixins/component-mixins/page/shopping-list/pageInputDaysAndCountMixin'
import PageSecondary from '../../../../global/pages/PageSecondary'
import PageContainer from '../../../../global/pages/page-sub-components/PageContainer'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import TextBodyRegular from '../../../../global/typography/TextBodyRegular'
import TextBodySmall from '../../../../global/typography/TextBodySmall'
import InputNumberSpinner from '../../../../global/inputs/InputNumberSpinner'
import ButtonCheck from '../../../../global/buttons/ButtonCheck'
import PageFooter from '../../../../global/pages/PageFixedFooter'
import GridRow from '../../../../global/grid/GridRow'
import GridCol from '../../../../global/grid/GridCol'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'PageDays',
  components: {
    GridCol,
    GridRow,
    PageSecondary,
    PageContainer,
    ButtonPrimary,
    TextBodyRegular,
    TextBodySmall,
    InputNumberSpinner,
    ButtonCheck,
    PageFooter
  },
  mixins: [pageInputDaysAndCountMixin],
  data: function () {
    return {
      pageClass: [
        'macroapp--page-notification',
        'page--shopping-cart',
        'page--shopping-cart-days',
        'macroapp--page--shopping-cart-days'
      ],
      headerOptions: {
        show: true,
        isTransparent: false,
        left: 'previous',
        right: '',
        rightDisabled: true,
        helpContent: '',
        modifiers: ['with-bg-color', 'small-title'],
        formData: {
          image: ''
        },
        mainTitle: this.$i18n.t("message['general.shopping-list']")
      }
    }
  },
  created () {
    this.setPageTitle(this.$t('message["general.shopping-list"]'))
  },
  mounted () {
    this.setPageClass()
    this.getMealPlanData()
    pageReadyEvent()
  },
  computed: {
    componentName: function () {
      if (this.isDesktop) {
        return 'grid-row'
      } else {
        return 'slick'
      }
    }
  },

  beforeDestroy () {
    this.resetHeader()
    this.resetBackground()
  }
}
</script>
