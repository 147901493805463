import moment from 'moment'
import { mapGetters, mapMutations } from 'vuex'
import FoodDiaryService from '../../../../services/FoodDiaryService'
import logMealMixin from './logMealMixin'
import FoodDiarySavedDineOutMealsService from '@/services/FoodDiarySavedDineOutMealsService'
import FoodDiarySavedMealsService from '@/services/FoodDiarySavedMealsService'
import FoodDiaryDineOutService from '@/services/FoodDiaryDineOutService'
import { macroSplit } from '@/helpers/nutrition/dineout'

export default {
  mixins: [logMealMixin],
  props: {
    mealLog: {
      default: function () {
        return {}
      },
      type: Object
    }
  },
  data: function () {
    return {
      mealsPerGroup: 10,
      selectedCategory: {
        name:'',
        itemsCount:'',
        page:1,
        total: 0,
        from: 0,
        to:0,
      },
      state: 'init',
      showMealSavedState: false,
      savedMeals: [],
      alreadyLoggedMeals: [],
      mealsToAdd: [],
      mealsToRemove: [],
      restaurantSearchString: '',
      mealSearchString: '',
      selectedRestaurant: null,
      restaurants: [],
      service: new FoodDiaryDineOutService(),
      foodDiaryService: new FoodDiaryService(),
      macroSplitInput: ['remainingCalories'],
      showMacroSplitInput: false,
      caloriesInput: true,
    }
  },
  computed: {
    ...mapGetters({
      previousRoute: 'getPreviousRoute',
    }),
    showOnlyMealsForRemainingCalories: function () {
      return this.macroSplitInput.includes('remainingCalories')
    },
    checkMacroSplitForMeals: function () {
      return this.macroSplitInput.includes('macroSplit')
    },
    remainingLogCalories: function () {
      const food = new FoodDiaryService()
      const total = food.getCurrentNutrientTotalsOfMeals(this.mealLog.logged_meals)
      const remaining = food.getRemainingNutrientTotalsOfMeals(this.mealLog.required, total)
      if (this.mealsToAdd.length > 0) {
        this.mealsToAdd.forEach(meal => {
          remaining.calories = remaining.calories - meal.recipes[0].calories
        })
      }
      if (this.mealsToRemove.length > 0) {
        this.mealsToRemove.forEach(meal => {
          remaining.calories = remaining.calories + meal.recipes[0].calories
        })
      }
      return (remaining.calories < 0) ? 0 : remaining.calories
    },
    remainingLogMacros: function () {
      const food = new FoodDiaryService()
      const total = food.getCurrentNutrientTotalsOfMeals(this.mealLog.logged_meals)
      const remaining = food.getRemainingNutrientTotalsOfMeals(this.mealLog.required, total)
      const macros = remaining.macronutrients
      if (this.mealsToAdd.length > 0) {
        this.mealsToAdd.forEach(meal => {
          macros.carbohydrates = macros.carbohydrates - meal.recipes[0].macronutrients.carbohydrates
          macros.fats = macros.fats - meal.recipes[0].macronutrients.carbohydrates
          macros.proteins = macros.proteins - meal.recipes[0].macronutrients.carbohydrates
        })
      }
      if (this.mealsToRemove.length > 0) {
        this.mealsToRemove.forEach(meal => {
          macros.carbohydrates = macros.carbohydrates + meal.recipes[0].macronutrients.carbohydrates
          macros.fats = macros.fats + meal.recipes[0].macronutrients.carbohydrates
          macros.proteins = macros.proteins + meal.recipes[0].macronutrients.carbohydrates
        })
      }
      const updated =  {
        carbohydrates:(macros.carbohydrates<0)? 0:macros.carbohydrates,
        fats:(macros.fats<0)? 0:macros.fats,
        proteins:(macros.proteins<0)? 0:macros.proteins,
      }

      return {...macros,...updated }
    },
    ...mapGetters({
      getLastSelectedRestaurantName: 'foodDiaryStore/getLastSelectedRestaurantName'

    }),
    hasAnyMealResult () {
      if (this.mealSearchString && this.selectedRestaurant) {
        return this.selectedRestaurant.groups.some(group => {
          return this.hasSearchItemInGroup(group)
        })
      }
      return true
    },
    count: function () {
      return this.mealsToAdd.length
    },
  },
  watch: {
    macroSplitInput: function () {
      if(!this.selectedRestaurant){
        this.init()
      }

    },
    mealSearchString: function (){
      this.filterSelectedRestaurantMeals()
    },
    mealLog: {
      immediate: true,
      handler: function (val) {
        const logs = val.logged_meals
        this.alreadyLoggedMeals = []
        logs.forEach(log => {
          if (log.mealType === 'dine-out') {
            this.alreadyLoggedMeals.push(log)
          }
        })

      }
    }
  },
  methods: {
    ...mapMutations({
      setDineOutEnabled: 'foodDiaryStore/setDineOutEnabled',
      setLastSelectedRestaurantName: 'foodDiaryStore/setLastSelectedRestaurantName',
    }),
    isMealBelowRemainingCalories (meal) {
      if (this.checkMealAddToLog(meal)) {
        return true
      }
      if (this.checkMacroSplitForMeals) {
        return this.checkMacroSpilt(meal)
      } else if (this.showOnlyMealsForRemainingCalories) {
        return this.checkMacroSpilt(meal,false)
      }
      return true
    },
    checkMacroSpilt (meal, strict = true) {
      const nutrition = {
        calories: this.remainingLogCalories,
        macronutrients: this.remainingLogMacros

      }
      return macroSplit(nutrition, meal.recipes[0], strict, false, false, this.selectedRestaurant.name)
    },
    setCategoryText (group) {
      return group.name + ' • ' + group.meals.filter(obj => obj.show).length
    },
    hasSearchItemInGroup (group) {
      return group.meals.some(obj => obj.show)
    },
    setSelectedCategoryName (group) {
      const name = group.name
      this.selectedCategory.name = (this.selectedCategory.name === name) ? '' : name
      this.selectedCategory.page = 1

      this.setPaginationData(group)

    },
    filterSelectedRestaurantMeals(){
      const searchStr = this.mealSearchString.toLowerCase()
      this.selectedRestaurant.groups =  this.selectedRestaurant.groups.map(group=>{
        group.meals=  group.meals.map(obj => {
          obj.show  = obj.name.toLowerCase().includes(searchStr) && this.isMealBelowRemainingCalories(obj)
          return obj
        })
        return group
      })
    },
    goToSelectedCategoryPage(a){
      this.selectedCategory.page = a
      this.$nextTick(()=>{
        document.querySelector('.pc-container-dine-out__meal-group__row').scrollIntoView({ behavior: 'smooth', block: 'start' })
      })
    },
    getGroupMeals(group){
      const filteredMeals = group.meals.filter(meal=>meal.show)
      let startIndex = (this.selectedCategory.page - 1) * this.mealsPerGroup;
      return filteredMeals.slice(startIndex, startIndex + this.mealsPerGroup);
    },
    getCatPageCount(group){
      return Math.ceil((this.selectedCategory.total) / this.mealsPerGroup)
    },
    setPaginationData(group){
      const filteredMeals = group.meals.filter(meal=>meal.show)

      this.selectedCategory.total =filteredMeals.length
      this.selectedCategory.to =  (this.selectedCategory.page) * this.mealsPerGroup
      this.selectedCategory.from =  (this.selectedCategory.page - 1) * this.mealsPerGroup + 1
    },
    isSelected (meal) {
      const hasLoggedNow = this.mealsToAdd.some(selected => {
        return selected.id === meal.id
      })
      if (hasLoggedNow) {
        return hasLoggedNow
      }
      const hasLoggedBefore = this.alreadyLoggedMeals.some(selected => {
        return selected.id === meal.id
      })

      if (hasLoggedBefore) {
        return !this.mealsToRemove.some(selected => {
          return selected.id === meal.id
        })
      }
      return false
    },
    submitData () {
      this.state = 'submitting'
      this.$emit('submitting')
      const log = this.mealLog
      log.logged_meals = [...log.logged_meals, ...this.mealsToAdd]
      log.logged_meals = log.logged_meals.filter(meal => {
        return !this.mealsToRemove.some(mealToRemove => {
          return meal.id === mealToRemove.id
        })

      })
      this.foodDiaryService.saveMealLog(log).then((data) => {
        this.mealsToAdd.forEach((meal)=>{
          this.setUserEventCustomMealAdded(meal.name,false)
        })
        this.mealsToAdd= []
        this.mealsToRemove= []
        this.$emit('log', data.log)
        this.$emit('success', {
          type: 'dine-out',
          saved: false
        })

      }).finally(() => {
        this.state = 'init'
      })
    },
    removeMeal (meal) {
      const hasLoadedBefore = this.alreadyLoggedMeals.some(obj => obj.id === meal.id)
      if (hasLoadedBefore) {
        this.mealsToRemove.push(meal)
      } else {
        this.mealsToAdd = this.mealsToAdd.filter(obj => obj.id !== meal.id)
      }
    },
    addMeal (meal) {
      this.mealAdded(meal)
    },
    checkMealAddToLog (meal) {
      return this.mealsToAdd.some(obj => obj.id === meal.id) || this.alreadyLoggedMeals.some(obj => obj.id === meal.id)
    },
    mealAdded (meal) {
      let hasRemovedBefore = this.mealsToRemove.some(addedMeal => {
        return meal.id === addedMeal.id
      })
      if (hasRemovedBefore) {
        this.mealsToRemove = this.mealsToRemove.filter(addedMeal => {
          return meal.id !== addedMeal.id
        })
      } else {
        const logData = {
          type: {
            id: 0,
            name: this.$i18n.t('message["food-diary.dine-out.meal-type"]')
          },
          mealType: 'dine-out',
          meal_type: 'dine-out',
          time: moment().locale('en').format('HH:mm'),
          date: moment().locale('en').format('YYYY-MM-DD'),
          restaurant: {
            name: this.selectedRestaurant.name
          }
        }
        this.mealsToAdd.push(Object.assign(logData, meal))
      }
    },
    backToList () {
      this.setLastSelectedRestaurantName('')
      this.selectedRestaurant = null
    },
    init () {
      const fd = this.service
      const startTime = performance.now()
      fd.init().then(() => {
        this.showMacroSplitInput= fd.showMacroSplitInput()
        this.setDineOutEnabled(fd.hasDineOutURL())
        const rem = {
          calories: this.remainingLogCalories,
          macronutrients: this.remainingLogMacros
        }
        const remaining = (this.showOnlyMealsForRemainingCalories || this.checkMacroSplitForMeals) ? rem : null

        fd.getRestaurants(remaining, this.checkMacroSplitForMeals).then(async (list) => {
          this.restaurants = list

          if (this.getLastSelectedRestaurantName) {
            const rest = this.getLastSelectedRestaurantName
            await this.showRestaurant({ name: rest })

          }
        })

        const endTime = performance.now()
      })
    },
    async showRestaurant (rest) {
      this.showMealSavedState = false
      const restaurant = await this.service.findRestaurant(rest.name)
      restaurant.filtergroups = [...restaurant.groups]
      this.selectedRestaurant = { ...rest, ...restaurant }
      this.filterSelectedRestaurantMeals()
      let foodDiarySavedMealsService = new FoodDiarySavedDineOutMealsService()
      this.savedMeals = await foodDiarySavedMealsService.search('', this.selectedRestaurant.name)
      this.showMealSavedState = true
      this.setLastSelectedRestaurantName(this.selectedRestaurant.name)
    },
    isSavedMeal (meal) {
      return this.savedMeals.some(savedMeal => {
        return meal.name === savedMeal.name
      })
    },
    changeTab (name) {
      this.$emit('change-tab', name)
    },
    async eraseMeal (meal) {
      const selectedMeal = this.savedMeals.find(saved => {
        return saved.name === meal.name
      })

      let service = new FoodDiarySavedMealsService()
      this.$emit('deleting')
      await service.removeSavedMeal(selectedMeal._id)
      this.savedMeals = this.savedMeals.filter(obj => obj._id !== selectedMeal._id)
      this.$emit('meal-erased')
    },
    setUserEventCustomMealAdded (name, hasImage) {
      let context = (this.previousRoute.path.includes('/dashboard')) ? 'dashboard' : 'food-diary'
      this.logEvent('FoodDiary.DineOutMealAdded', {
        'context': context,
        'name': name,
        'has_image': hasImage,
        'is_saved': false
      })
    },
    async saveMeal (meal) {

      let mealData = {
        saved: true,
        type: {
          id: 0,
          name: this.$i18n.t('message["food-diary.dine-out.meal-type"]')
        },
        meta: {
          type: 'dine-out',
          restaurantName: this.selectedRestaurant.name
        },

      }
      const saveData = { ...mealData, ...meal }
      const savedMeal = await this.foodDiaryService.saveMeal(saveData)
      this.savedMeals.push(savedMeal.meal)
      this.$emit('meal-saved', savedMeal)
    }
  }
}
