<template>
  <popup
    @hide="closePopup"
    modal-class="pc-popup-workout-log-modify"
    :visible="visible"
    v-if="visible"
  >
    <popup-title>
      <popup-button-close @click="closePopup"></popup-button-close>
    </popup-title>
    <popup-body class="text-center">
      <popup-content>
        <popup-head>
          <popup-text-heading >{{$t('message["popup.workout-log-modify.title"]')}}</popup-text-heading>
        </popup-head>
        <popup-text-body style="visibility: hidden">{{$t('message["popup.workout-log-modify.description"]')}}</popup-text-body>

      </popup-content>
      <popup-footer>
        <button-secondary
          class="pc-popup-workout-log-modify__button"
          :text="$t('message[\'general.edit\']')"
          @click="clickEdit()"/>
        <button-secondary
          class="pc-popup-workout-log-modify__button"
          :text="$t('message[\'workout-log.mark-incomplete\']')"
          @click="clickDelete()"/>
      </popup-footer>
    </popup-body>
  </popup>
</template>

<script>
import popupAnimationMixin from '../../../../../../mixins/popupAnimationMixin'
import PopupBody from '../../../../../global/popups/popup-sub-components/PopupBody'
import ButtonSecondary from '../../../../../global/buttons/ButtonSecondary'
import PopupContent from '../../../../../global/popups/popup-sub-components/PopupContent'
import PopupButtonClose from '../../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupTitle from '../../../../../global/popups/popup-sub-components/PopupTitle'
import Popup from '../../../../../global/popups/Popup'
import PopupHead from '../../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupFooter from '../../../../../global/popups/popup-sub-components/PopupFooter'
import PopupTextBody from '../../../../../global/popups/popup-sub-components/PopupTextBody'

export default {
  name: 'popupWorkoutLogModify',
  props: ['visible'],
  mixins: [popupAnimationMixin],
  components: {
    PopupTextBody,
    PopupFooter,
    PopupTextHeading,
    PopupHead,
    PopupBody,
    ButtonSecondary,
    PopupContent,
    PopupButtonClose,
    PopupTitle,
    Popup
  },
  data: function () {
    return {
      title: '',
      imagesUrl: this.$appConfig.imagesUrl
    }
  },

  methods: {
    clickDelete () {
      this.$emit('delete')
    },
    clickEdit () {
      this.$emit('edit')
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    }
  }
}
</script>
