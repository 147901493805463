<template>
  <div class="pc-card-notification" @click="doAction">
    <div class="pc-card-notification__section-left">
      <div class="pc-card-notification__icon-warning-wrap">
        <icon-warning class="pc-card-notification__icon-warning" size="sm1"></icon-warning>
      </div>
      <div class="pc-card-notification__message">
        <text-body-extra-small :lineHeight="'multi'" :weight="'medium'">{{text}}</text-body-extra-small>
      </div>
    </div>
    <div class="pc-card-notification__section-right">
      <div class="pc-card-notification__button-badge-wrap">
        <icon-forward size="sm1"  />
        <!-- <badge-important class="pc-card-notification__button-badge" @click="doAction">
          <text-body-extra-small weight="extra-bold" lineHeight="multi">{{ $t('message["general.click-here"]') }}
          </text-body-extra-small>
        </badge-important> -->
      </div>
    </div>
  </div>
</template>
<script>
import NotificationService from '../../../../../services/NotificationService'
import BadgeChoice from '../../../../global/badges/BadgeChoice'
import BadgeImportant from '../../../../global/badges/BadgeImportant'
import TextArea from '../../../../global/inputs/TextArea'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import IconWarning from '../../../../root/icons/IconWarning'
import IconForward from '../../../../root/icons/IconForward'
import ButtonProgressAction from './ButtonProgressAction'

export default {

  name: 'CardNotification',
  components: {
    IconWarning,
    TextArea,
    ButtonProgressAction,
    BadgeChoice,
    BadgeImportant,
    TextBodyExtraSmall,
    IconForward

  },
  props: {
    type: {
      default: ''
    },
    text: {
      default: ''
    },
    action: {
      default: {}
    }
  },
  data: function () {
    return {}
  },
  methods: {
    doAction () {
      if (this.action.url.includes('meal-plans/update')) {
        this.$router.push({
          path: this.$appConfig.appUrlList.mealPlanUpdate
        })
      } else if (this.action.url.includes('workout-programs/update')) {
        this.$router.push({
          path: this.$appConfig.appUrlList.workoutUpdate
        })
      } else if (this.action.url.includes('api/notifications/subscription/') && this.action.url.includes('/cancel')) {
        this.doCancelNotificationsSubscription(this.action.url)
      } else {
        window.location.href = this.action.url
      }
    },
    doCancelNotificationsSubscription (url) {
      const service = new NotificationService()
      service.cancelSubscription(url).then(() => {
        this.$emit('hide')
      }).catch(() => {
        this.$store.commit('showServerErrorPopup')
      })
    }
  }
}
</script>
