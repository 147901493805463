import IngredientAPI from './api/common/IngredientAPI'
import {i18n} from '../helpers/localization/i18n'
import lodash from 'lodash'
export default class IngredientService {
  ingredients = {}
  ingredientsByGroups = []

  constructor () {
    if (!IngredientService.instance) {
      IngredientService.instance = this
    }
    return IngredientService.instance
  }

  setIngredients () {
    const api = new IngredientAPI()
    return new Promise((resolve, reject) => {
      api.getList().then(res => {
        const ingredientArr = lodash.sortBy(res.data.ingredients, 'value')
        this.setData(ingredientArr)
        this.ingredients = Object.assign({}, ingredientArr)
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  setData (ingredients) {
    let uncategorized = []
    this.ingredientsByGroups = []
    ingredients.forEach((ingredient, index) => {
      if (ingredient.groups.length > 0) {
        ingredient.groups.forEach((group) => {
          this.addToGroupArray(ingredient, group, group.parent_group, index)
        })
      } else {
        uncategorized.push({'id': ingredient.id, 'name': ingredient.value})
      }
    })

    uncategorized = lodash.uniqBy(uncategorized, 'id')

    let ingredientsByGroupsOtherIndex = this._getOtherGroupIndex()
    if (uncategorized.length > 0) {
      let uncategorizedSubGroup = {
        'id': 1000000000,
        'name': i18n.t('message["group-dislikes.uncategorized"]'),
        'ingrediants': uncategorized
      }

      if (ingredientsByGroupsOtherIndex !== -1) {
        this.ingredientsByGroups[ingredientsByGroupsOtherIndex].subCategory.push(uncategorizedSubGroup)
      } else {
        this._createOtherGroup([uncategorizedSubGroup])
      }
    }

    this._sortGroupedIngredients()
  }

  _sortGroupedIngredients () {
    this.ingredientsByGroups.map(group => {
      group.subCategory = group.subCategory.map(subCategory => {
        subCategory.ingrediants = lodash.sortBy(subCategory.ingrediants, 'name')
        return subCategory
      })
      group.subCategory = lodash.sortBy(group.subCategory, 'name')

      return group
    })

    this.ingredientsByGroups = lodash.sortBy(this.ingredientsByGroups, 'name')
  }

  _createOtherGroup (subCategories) {
    this.ingredientsByGroups.push({
      'id': 20000000,
      'name': i18n.t('message["group-dislikes.other"]'),
      'subCategory': subCategories
    })
  }

  _getOtherGroupIndex () {
    return this.ingredientsByGroups.findIndex(group => {
      return group.name.toLowerCase() === i18n.t('message["group-dislikes.other"]').toLowerCase()
    })
  }

  addToGroupArray (ingredient, group, parentGroup) {
    let ingredientObj = { 'id': ingredient.id, 'name': ingredient.value }

    if (parentGroup) {
      let foundParentGroupIndex = this.ingredientsByGroups.findIndex(obj => {
        return obj.id === parentGroup.id
      })
      let subGroup = {
        'id': group.id,
        'name': group.name,
        'ingrediants': [ingredientObj]
      }
      if (foundParentGroupIndex !== -1) {
        let foundSubCategroryIndex = this.ingredientsByGroups[foundParentGroupIndex].subCategory.findIndex(obj => {
          return obj.id === group.id
        })
        if (foundSubCategroryIndex !== -1) {
          this.ingredientsByGroups[foundParentGroupIndex].subCategory[foundSubCategroryIndex].ingrediants.push(ingredientObj)
        } else {
          this.ingredientsByGroups[foundParentGroupIndex].subCategory.push(subGroup)
        }
      } else {
        this.ingredientsByGroups.push({
          'id': parentGroup.id,
          'name': parentGroup.name,
          'subCategory': [subGroup]
        })
      }
    } else {
      let subGroup = {
        'id': group.id,
        'name': group.name,
        'ingrediants': [ingredientObj]
      }
      let otherCategoryIndex = this._getOtherGroupIndex()
      if (otherCategoryIndex === -1) {
        this._createOtherGroup([subGroup])
      } else {
        let foundSubCategroryIndex = this.ingredientsByGroups[otherCategoryIndex].subCategory.findIndex(obj => {
          return obj.id === group.id
        })

        if (foundSubCategroryIndex !== -1) {
          this.ingredientsByGroups[otherCategoryIndex].subCategory[foundSubCategroryIndex].ingrediants.push(ingredientObj)
        } else {
          this.ingredientsByGroups[otherCategoryIndex].subCategory.push(subGroup)
        }
      }
    }
  }

  getIngredient (id) {
    return this.ingredients[id]
  }

  getGenericIds (names) {
    const ing = this.ingredients
    const genericIds = []
    for (const key in ing) {
      if (names.indexOf(ing[key]) > -1) {
        genericIds.push(parseInt(key, 10))
      }
    }
    return genericIds
  }

  getIngredients () {
    return this.ingredients
  }

  getGroupedIngredients () {
    return this.ingredientsByGroups
  }
}
