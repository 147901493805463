<template>
  <div :class="componentClasses" @click="clicked()">
  <svg width="13" height="25" viewBox="0 0 13 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.6054 12.8602L1.147 24.8395C0.947637 25.046 0.618044 25.0552 0.410522 24.8558C0.203 24.6569 0.195872 24.3273 0.394262 24.1193L11.5078 12.5001L0.394211 0.880917C0.19582 0.672907 0.20295 0.343315 0.410471 0.144436C0.511205 0.0478058 0.641382 1.87313e-06 0.770582 1.86184e-06C0.907937 1.84983e-06 1.04422 0.0539073 1.14695 0.160746L12.6054 12.14C12.7977 12.3414 12.7977 12.6588 12.6054 12.8602Z" fill="black"/>
  </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconForward',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-forward': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
