import moment from 'moment'
import MealPlanService from './MealPlanService'
import FoodDiaryAPI from '../api/meal-plan/FoodDiaryAPI'

export default class FoodDiaryService {
  logs = []
  _mealPlan= null
  _nextPage = 1
  _hasMoreLogs = true

  constructor (mealPlan) {
    this.setMealPlan(mealPlan)
  }

  /***
   *
   * @param mealPlanService
   */
  setMealPlan (mealPlanService) {
    if (!(mealPlanService instanceof MealPlanService)) {
      throw new Error('not an instance of MealPlanService')
    }
    this._mealPlan = mealPlanService
  }
  getNextPageLogs () {
    let foodDiaryAPI = new FoodDiaryAPI()
    if (!this._hasMoreLogs) {
      return Promise.resolve(this.logs)
    }
    return new Promise((resolve, reject) => {
      foodDiaryAPI.getLogPaginatedData({page: this._nextPage}).then(data => data.data).then(data => {
        this.logs = this.logs.concat(data.logs)
        this._hasMoreLogs = this.data.has_more_pages || false
        if (this._hasMoreLogs) {
          this._nextPage = data.current_page + 1
        }

        resolve(this.logs)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getTodayLog () {
    return this.logs.find(log => {
      return log.logged_date === moment().format('YYYY-MM-DD')
    })
  }

  /**
   *
   * @param date fromat YYYY-MM-DD
   * @returns {*}
   */
  getLogByDate (date) {
    return this.logs.find(log => {
      return log.logged_date === date
    })
  }
}
