import BaseComponent from '../components/layout/template-1/global/base/BaseComponent'
import ShoppingListService from '../services/ShoppingListService'
import DefineInclude from '../DefineInclude'
import { mapGetters } from 'vuex'

/**
 * there are variations of the cart button with same logic
 * to different variation can use this mixin
 */
export default {
  extends: BaseComponent,
  props: ['type', 'dayid', 'smallVersion'],
  data: function () {
    return {
      items: 0,
      title: 'Shopping List'
    }
  },
  created () {
    if (this.type === 'list') {
      this.title = this.$i18n.t("message['general.shopping-list']")
    } else {
      this.title = this.$i18n.t("message['general.purchased-list']")
    }
  },
  mounted () {
    if (this.type === 'list') {
      const service = new ShoppingListService()

      service.get({ days: [this.dayid] }, this.$route.params.mid).then(res => {
        this.items = res.shopping_items.length
        this.hideLoading()
      }).catch(() => {
        this.hideLoading()
      })
    }
  },
  computed: {
    ...mapGetters({
      shoppedItemsCount: 'shoppingStore/shoppedItemsCount'
    }),
    count () {
      if (this.type === 'list') {
        return this.items
      } else {
        return this.shoppedItemsCount
      }
    }
  },
  methods: {
    navigate () {
      if (this.type === 'list') {
        this.$router.push({
          path: DefineInclude.appUrlList.shoppingList.replace(
            ':mid',
            this.$route.params.mid
          ),
          query: { page: 'shop' }
        })
        this.logEvent('MealPlan.SingleDay.ShoppingListViewed', {day: 'Day ' + this.dayid}, false)
      } else {
        this.$router.push({
          path: DefineInclude.appUrlList.shoppingList.replace(
            ':mid',
            this.$route.params.mid
          ),
          query: { page: 'cart' }
        })
      }
    }
  }
}
