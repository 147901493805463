<template>
    <div class="pc-timer pc-timer-completed">
        <div class="pc-timer__wrap d-flex text-center">
            <icon-completed-filled size="sm4" class="pc-timer-completed__icon"></icon-completed-filled>
            <text-body-small line-height="lh-lg" weight="extra-bold">{{$t('message["workout.timer.completed"]')}}</text-body-small>
        </div>
        <transition>
            <div class="pc-timer__footer">
                <div class="row">
                    <div class="col text-center">
                        <text-content
                                class="timer__play-btn pc-timer__action-button"
                                size="sm1"
                                weight="extra-bold"
                                line-height="multi"
                                v-on:click="restart"
                        >{{$t('message["general.restart"]')}}
                        </text-content>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import TextBodySmall from '../../../../global/typography/TextBodySmall'
import IconCompletedFilled from '../../../../root/icons/IconCompletedFilled'
import TextContent from '../../../../root/TextContent'

export default {
  name: 'TimerCompleted',
  components: {
    IconCompletedFilled,
    TextBodySmall,
    TextContent
  },
  methods: {
    restart () {
      this.$emit('restart')
    }
  }
}
</script>

<style scoped>

</style>
