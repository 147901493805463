var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.member.getId())?_c('div',[_c('div',{staticClass:"pc-profile--my-connections__header"},[_c('text-body-small',{attrs:{"lineHeight":'lh-lg'}},[_vm._v(_vm._s(_vm.$i18n.t( 'message[\'member-connect.connected-with\']' ))+" ")])],1),_c('card-member-large',{attrs:{"avatar":_vm.member.getAvatar(),"name":_vm.member.getFullName(),"email":_vm.member.getEmail(),"labelText":_vm.memberTypeLabel}},[_c('div',{staticClass:"pc-profile--my-connections__button-wrapper"},[(_vm.mealPairingEnabled)?_c('button-custom',{staticClass:"pc-profile--my-connections__button-pair-meal-plan",attrs:{"type":'outline',"disabled":!_vm.ownerPlanIsValid,"text":_vm.$i18n.t(
                    'message[\'meal-plan-pair.pair-meal-plan\']'
                    )},on:{"click":_vm.showMealPlanPairPopup}}):_vm._e(),_c('button-custom',{staticClass:"pc-profile--my-connections__button-disconnect",attrs:{"type":'outline',"text":_vm.$i18n.t(
                    'message[\'member-connect.disconnect-button\']'
                    )},on:{"click":_vm.showDisconnectConfirm}})],1)]),(_vm.ownerPlanNotValidAlertEnabled)?_c('alert-dotted',{staticClass:"pc-profile--my-connections__alert-dotted"},[_c('div',{staticClass:"pc-profile--my-connections__alert-dotted-header"},[_c('icon-meal',{attrs:{"size":'sm2'}}),_c('text-body-small',{attrs:{"line-height":'multi',"weight":'extra-bold'}},[_vm._v(" "+_vm._s(_vm.$i18n.t( 'message[\'meal-plan-pair.pairing-unavailable\']' ))+" ")])],1),_c('text-body-extra-small',{staticClass:"pc-profile--my-connections__alert-description",attrs:{"line-height":'multi'},domProps:{"innerHTML":_vm._s(_vm.$i18n.t(
            'message[\'meal-plan-pair.pairing-unavailable-description\']'
            ))}})],1):_vm._e(),(_vm.mealPairingPendingAlertEnabled)?_c('alert-dotted',{staticClass:"pc-profile--my-connections__alert-dotted"},[_c('div',{staticClass:"pc-profile--my-connections__alert-dotted-header"},[_c('icon-meal',{attrs:{"size":'sm2'}}),_c('text-body-small',{attrs:{"line-height":'multi',"weight":'extra-bold'}},[_vm._v(" "+_vm._s(_vm.$t('message[\'meal-plan-pair.pending\']'))+" ")])],1),_c('text-body-extra-small',{staticClass:"pc-profile--my-connections__alert-description",attrs:{"line-height":'multi'},domProps:{"innerHTML":_vm._s(_vm.$t('message[\'meal-plan-pair.pending-description\']'))}})],1):_vm._e(),_c('popup-meal-plans-pair',{ref:"mealTypesPairPopup",on:{"refresh":_vm.setPageData}}),_c('popup-member-disconnect',{ref:"disconnectPopup",attrs:{"name":_vm.member.getFullName()},on:{"disconnected":_vm.emitDisconnect}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }