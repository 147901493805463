<template>
  <div class="pc-container-workout-log-exercise">
    <loading-buffer height="300" v-if="!isLoaded"/>
    <div
      class="pc-container-workout-log-exercise__history workout-history"
      v-if="showLog">
    <workout-log-stat
      :entries="currentHistory.entries"
      :logged-at="currentHistory.logged_at"
      :note="currentHistory.note"
      :exercise-i-d="exerciseID"
      :exercise-name="exerciseName"
      :day-name="dayName"
      :week-name="weekName"
      :workout-i-d="workoutID"
      :unit-system-value="currentUnitSystem"
      :exercise-rest-interval="exerciseRestInterval"
      :exercise-rest-time="exerciseRestTime"
      :exercise-time="workoutTime"
      :exercise-time-value="workoutTime"
      v-on:request-delete="requestDelete=true;$emit('data-deleted')"
      v-on:submitted="submited=true"
      v-on:request-edit="showStat=false"
    />
  </div>
    <workout-log-note-form
      class="pc-container-workout-log-exercise__form-note"
      :exercise-i-d="exerciseID" :exercise-name="exerciseName" :exercise-rest-interval="exerciseRestInterval"
      :exercise-rest-time="exerciseRestTime"
      :exercise-time="workoutTime"
      :exercise-time-value="workoutTime"
      :note="note"
      :day-name="dayName"
      :week-name="weekName"
      :save-type="action"
      :workout-i-d="workoutID"
      :sets-count="setsCount"
      v-if="showNoteForm"
      :group-info="exerciseGroup"
      v-on:canceled="reset"
      v-on:submitted="submited=true"
      v-on:completed="navigateNextExercise()"/>
    <workout-log-entry-form
      class="pc-container-workout-log-exercise__form-entry"
      :current-unit-system="currentUnitSystem"
                            :is-completed="isCompleted"
                            :entries="entries"
                            :group-info="exerciseGroup"
                            :exercise-i-d="exerciseID"
                            :exercise-name="exerciseName"
                            :day-name="dayName"
                            :week-name="weekName"
                            :exercise-rest-interval="exerciseRestInterval"
                            :exercise-rest-time="exerciseRestTime"
                            :exercise-time="workoutTime"
                            :exercise-time-value="workoutTime"
                            :force-edit="requestEdit"
                            :metrics="metrics"
                            :pre-note="note"
                            :sets="sets"
                            :sets-count="setsCount"
                            :workout-i-d="workoutID"
                            v-if="showMainForm"
                            :history-data="history"
                            v-on:canceled="reset"
                            v-on:submitted="submited=true"
                            v-on:completed="navigateNextExercise()"/>

    <popup-workout-log-remove-logged-data :exercise-i-d="exerciseID" :visible="requestDelete" v-if="requestDelete"
                                          v-on:close="requestDelete=false" v-on:success="makeExerciseIncomplete()"/>
  </div>
</template>

<script>
import DashBoardService from '../../../../../services/DashboardService'
import BaseComponent from '../../global/base/BaseComponent'
import WorkoutLogNoteForm from './FormNoteWorkoutLog'
import WorkoutLogEntryForm from './FormWorkoutLog'
import WorkoutLogStat from '../widgets/WorkoutLogStat'
import PopupWorkoutLogRemoveLoggedData from './popups/PopupWorkoutLogMarkIncomplete'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import feedbackMixin from '../../../../../mixins/feedbackMixin'
import { flagValue } from '../../../../../includes/TemplateSettings'
import UserEventsService from '../../../../../services/UserEventsService'

export default {
  name: 'WorkoutLogExercise',
  mixins: [feedbackMixin],
  components: {
    LoadingBuffer,
    WorkoutLogEntryForm,
    WorkoutLogNoteForm,
    WorkoutLogStat,
    PopupWorkoutLogRemoveLoggedData
  },
  props: {
    forceEdit: { default: true },
    isCompleted: { default: false },
    exerciseID: {
      default: 0,
      type: Number
    },
    exerciseName: { default: '' },
    dayName: { default: '' },
    weekName: { default: '' },
    workoutID: { default: 0 },
    metrics: {
      default: function () {
        return []
      },
      type: Array
    },
    exerciseGroup: {
      default: function () {
        return {
          setName: '',
          currentID: 1,
          totalExercises: 1
        }
      },
      type: Object
    },
    exerciseTimeValue: { default: 0, type: Number },
    exerciseRestTime: { default: 0, type: Number },
    exerciseTimeInterval: { default: 'minute', type: String },
    exerciseRestInterval: { default: 'minute', type: String },
    sets: { default: '', type: String },
    setsCount: {
      default: 0,
      type: Number
    }
  },
  extends: BaseComponent,
  data: function () {
    return {
      submited: false,
      workoutTime: 0,
      showStat: false,
      entries: [],
      genericExerciseID: 0,
      note: '',
      timezone: '',
      exerciseTime: 0,
      currentHistory: [],
      currentUnitSystem: 'metric',
      historyData: {},
      logData: {},
      requestEdit: false,
      requestDelete: false,
      isLoaded: false,
      isDeleted: false
    }
  },
  computed: {
    ...mapGetters({
      service: 'singleExercisePageStore/getCurrentWorkoutLogService'
    }),
    action () {
      if (this.currentHistory.logged_at) {
        return 'update'
      }
      return 'save'
    },
    showNoteForm: function () {
      return !(this.showStat && this.currentHistory.logged_at) && this.isLoaded && (this.metrics.length === 0 || !this.sets)
    },
    showMainForm: function () {
      return !(this.showStat && this.currentHistory.logged_at) && this.isLoaded && this.metrics.length > 0 && Boolean(this.sets)
    },
    showLog: function () {
      return this.showStat && this.currentHistory.logged_at
    }
  },
  mounted () {
    this.setLogData()
    this.setFeedbackPopup()
  },

  methods: {
    ...mapMutations({
      unsetLogDataInStore: 'workoutLogStore/unsetLogFormData',
      setCurrentExercise: 'workoutLogStore/setCurrentExercise'
    }),
    ...mapActions({
      removeWorkoutLog: 'singleExercisePageStore/removeWorkoutLog'
    }),
    reset () {
      this.isLoaded = false
      this.unsetLogDataInStore()
      this.setCurrentExercise()
      this.removeWorkoutLog()
      this.$nextTick(() => {
        this.isLoaded = true
      })
    },
    setLogData () {
      this.calculateWorkoutTime()
      const dashBoardService = new DashBoardService()
      const userPromise = dashBoardService.getUserData()
      const workoutLogPromise = this.service.getLogByExercise()
      Promise.all([userPromise, workoutLogPromise]).then((values) => {
        const logData = values[1]
        const userData = values[0]
        this.currentUnitSystem = userData.measurement_system
        this.timezone = userData.timezone
        this.currentHistory = logData.data
        if (logData.data.logged_at) {
          this.requestEdit = true
        }
        this.entries = logData.data.entries
        this.note = logData.data.note || ''
        this.genericExerciseID = logData.data.exercise.id || 0
        this.loadHistory(true)
      })
    },
    setFeedbackPopup () {
      this.setFeedbackEvent('workout-log', 10)
      this.setFeedbackFeatureFlag('workout_log_feedback_popup_show')
      const featureName = this.$i18n.t('message[\'workout-log.title\']')
      this.setFeedbackPopupData(this.$i18n.t('message[\'popup.feed-back.title\']', { feature: featureName }), this.$i18n.t('message[\'popup.feed-back.placeholder\']'))
    },
    /**
       * calculate the workout time to seconds
       */
    calculateWorkoutTime () {
      this.workoutTime = 0
      if (
        typeof this.exerciseTimeValue !== 'undefined' &&
          this.exerciseTimeValue !== null
      ) {
        this.workoutTime = parseInt(this.exerciseTimeValue)
        if (this.exerciseTimeInterval === 'minute') {
          this.workoutTime = this.workoutTime * 60
        } else if (this.exerciseTimeInterval === 'hour') {
          this.workoutTime = this.workoutTime * 60 * 60
        }
      }
    },
    loadHistory (force = false) {
      this.service
        .getGenericExerciseHistory(this.genericExerciseID, force)
        .then((history) => {
          this.history = history.data.history
          this.isLoaded = true
        }).finally(() => {
          this.isLoaded = true
        })
    },
    emitNextExercise () {
      this.loadHistory(true)
      this.$emit('nextExercise', true)
    },
    navigatePerviousExercise () {
      this.$emit('perviousExercise', true)
    },
    navigateNextExercise () {
      this.loadHistory(true)
      if (flagValue('workout_log_feedback_popup_show')) {
        UserEventsService.sendWorkoutLogFeedBackPopupView()
      }
      this.emitNextExercise()
    },
    makeExerciseIncomplete () {
      this.isDeleted = true
      this.showStat = false
      this.currentHistory.logged_at = null
    }

  }
}
</script>
