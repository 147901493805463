<template>
  <badge :class="componentClasses">
    <slot></slot>
  </badge>
</template>

<script>
import Badge from '../../root/Badge'

export default {
  name: 'BadgeChoice',
  components: {
    Badge
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-badge-choice': true
      }
    }
  }
}
</script>

<style scoped></style>
