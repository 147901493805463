<template>
  <div
    v-if="pageCount>1"
    class="gc-pagination"
  >
    <div
      v-if="showBackwardLink"
      class="gc-pagination__link"
      :class="{'gc-pagination__link--disabled': currentPage-1 <1}"
      @click="goToPage(currentPage-1)"
    >
      <icon-backward size="sm1" />
    </div>
    <div
      v-if="showFirstLink"
      class="gc-pagination__link gc-pagination__link--first"
      :class="{'gc-pagination__link gc-pagination__link--active':currentPage===1}"
      @click="goToPage(1)"
    >
      <text-body-small>1</text-body-small>
    </div>
    <text-body-small v-if="showFirstLink">
      ...
    </text-body-small>
    <div
      v-for="link in pageLinks"
      :key="'link'+link"
      class="gc-pagination__link gc-pagination__link"
      :class="{'gc-pagination__link gc-pagination__link--active':currentPage===link}"
      @click="goToPage(link)"
    >
      <text-body-small>{{ link }}</text-body-small>
    </div>
    <text-body-small v-if="showLastLink">
      ...
    </text-body-small>
    <div
      v-if="showLastLink"
      class="gc-pagination__link gc-pagination__link--last"
      :class="{'gc-pagination__link gc-pagination__link--active':currentPage===pageCount}"
      @click="goToPage(pageCount)"
    >
      <text-body-small>{{ pageCount }}</text-body-small>
    </div>

    <div
      v-if="showForwardLink"
      class="gc-pagination__link"
      :class="{'gc-pagination__link--disabled': currentPage+1 > this.pageCount}"
      @click="goToPage(currentPage+1)"
    >
      <icon-forward size="sm1" />
    </div>
  </div>
</template>

<script>

import TextBodyRegular from '@/components/global/typography/TextBodyRegular.vue'
import IconForward from '@/components/root/icons/IconForward.vue'
import IconBackward from '@/components/root/icons/IconBackward.vue'
import TextBodySmall2 from '@/components/global/typography/TextBodySmall2.vue'
import TextBodySmall from '@/components/global/typography/TextBodySmall.vue'

export default {
  name: 'Pagination',
  components: {
    TextBodySmall,
    TextBodySmall2,
    IconBackward,
    IconForward,
    TextBodyRegular,
  },
  props: {
    pageCount: {
      type: Number,
      default: 4
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  computed: {
    showFirstLink: function () {
      return this.pageCount > 5 &&this.currentPage!==1&&this.currentPage!==2
    },
    showLastLink: function () {
      return this.pageCount > 5&&this.currentPage!==this.pageCount
    },
    showBackwardLink: function () {
      return (this.pageCount > 5)

    },
    showForwardLink: function () {
      return (this.pageCount > 5)
    },
    pageLinks: function () {
      if (this.pageCount > 4) {
        const links =[]
        if(this.currentPage - 1>=1){
          links.push(this.currentPage - 1);
        }
        links.push(this.currentPage);
        if(this.currentPage + 1 < this.pageCount){
          links.push(this.currentPage + 1);
        }
        return links

      }else{
        const links =[]
        for (let i = 1; i <= this.pageCount; i++) {
          if(i===1 &&this.showFirstLink || i===this.pageCount && this.showLastLink){
            continue
          }
          links.push(i);
        }
        return links
      }

    }
  },
  methods:{
    goToPage(page){
      if(page<1||page>this.pageCount){
        return
      }
      this.$emit('go-to',page)
    }
  }
}
</script>

