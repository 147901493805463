<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8921 15.1249H22.3851C23.1445 15.1249 23.76 15.7405 23.76 16.4999C23.76 17.2592 23.1444 17.8748 22.385 17.8748H10.8921C10.1327 17.8748 9.51715 17.2592 9.51715 16.4999C9.51715 15.7405 10.1327 15.1249 10.8921 15.1249Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16.5C0 7.38749 7.38749 0 16.5 0C25.6125 0 33 7.38749 33 16.5C33 25.6125 25.6125 33 16.5 33C7.38749 33 0 25.6125 0 16.5ZM2.75002 16.5C2.75002 24.0937 8.90626 30.25 16.5 30.25C24.0937 30.25 30.25 24.0937 30.25 16.5C30.25 8.90626 24.0937 2.75002 16.5 2.75002C8.90626 2.75002 2.75002 8.90626 2.75002 16.5Z" fill="black"/>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconDecrease',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-decrease': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
