import PlanService from './PlanService'
import moment from 'moment'

export default class PlanSubscriptionService extends PlanService {
  _renewedTimeStamp = null
  _cancelledTimeStamp = null

  constructor (plan) {
    super(plan)
    this._renewedTimeStamp = plan.renewed_at || null
    this._cancelledTimeStamp = plan.cancelled_at || null
  }

  /**
   *
   * @returns {null}
   */
  getRenewPrice () {
    return this.price
  }

  /**
   * get plan renew date
   * @returns {moment.Moment}
   */
  getRenewedDate () {
    return moment(this._renewedTimeStamp * 1000)
  }

  /**
   * get plan renew date String
   * @returns {string}
   */
  getRenewedDateString () {
    return this.getRenewedDate().format('ll')
  }

  /**
   * returns the plans next billing date
   * @returns {moment.Moment}
   */

  nextBillingDate () {
    return this.getExpireDate()
  }

  /**
   * returns the plans next billing date string
   * @returns {string}
   */
  nextBillingDateString () {
    return this.getExpireDateString()
  }

  /**
   * check plan is canceled
   * @returns {boolean}
   */
  isCancelled () {
    return !!this._cancelledTimeStamp
  }

  /**
   * returns the plan canceled date
   * @returns {moment.Moment}
   */
  getCancelledDate () {
    return moment(this._cancelledTimeStamp * 1000)
  }

  /**
   * returns the plans created/registered date string
   * @returns {string}
   */
  getCancelledDateString () {
    return this.getCancelledDate().format('ll')
  }

  getPlanType(){
    return 'subscription'
  }
}
