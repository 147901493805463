import AccountDeleteApi from './api/account/AccountDeleteApi'

export default class {

  getAccountDeletePageData () {
    this._api = new AccountDeleteApi()
    return this._api.fetchAccountDeleteConfirmationContent()
  }

  sendAccountDeleteLinkEmail (email) {
    this._api = new AccountDeleteApi()
    return this._api.sendAccountDeleteLinkEmail(
      {email: email}
    )
  }

  verifyAccountDeleteEmail (token) {
    this._api = new AccountDeleteApi()
    return this._api.verifyEmail(token)
  }

  getAccountDeleteStatus (token) {
    this._api = new AccountDeleteApi()
    return this._api.fetchAccountDeletionStatus(token)
  }
}
