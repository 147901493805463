import LanguageAPI from './api/lang/LanguageAPI'
import langJson from '../../lang/lang'

export default class LanguageService {
  data = null
  lang = 'en'
  langNumbers = {}
  constructor () {
    if (!LanguageService.instance) {
      this.getDataFormStorage()
      this.getFromAPI()
      this.setAppLang()
      LanguageService.instance = this
    }
    return LanguageService.instance
  }

  setAppLang () {
    const lang = document.documentElement.lang || 'en-US'
    if (['en-NZ', 'en-GB', 'en-US'].includes(lang)) {
      // english
      this.lang = 'en'
    } else if (lang === 'de-DE') {
      // German (Germany)
      this.lang = 'de'
    } else if (lang === 'es-ES') {
      // Spanish (Spain)
      this.lang = 'es'
    } else if (lang === 'fr-FR') {
      // French (France)
      this.lang = 'fr'
    } else if (lang === 'ar-SA') {
      // Arabic (Saudi Arabia)
      this.lang = 'ar-sa'
      document.documentElement.dir = 'rtl'
    } else if (lang === 'pt-BR') {
      // Portuguese (Brazil)
      this.lang = 'pt-br'
    } else if (lang === 'nl-NL') {
      // Dutch (Netherlands)
      this.lang = 'nl'
    } else {
      this.lang = 'en'
    }
  }

  getLangForStripe () {
    if (this.lang === 'ar-sa') {
      return 'ar'
    } else if (this.lang === 'pt-br') {
      return 'pt'
    }

    return this.lang
  }

  setAppLangCode () {
    return this.lang
  }

  getAppLang () {
    return this.setAppLangCode()
  }

  setDataToStorage () {
    localStorage.setItem('LanguageData', JSON.stringify(this.data))
  }

  get (key) {
    return new Promise((resolve, reject) => {
      if (this.data !== null) {
        if (typeof this.data[key] === 'undefined') {
          resolve(langJson[key])
        } else {
          resolve(this.data[key])
        }
      } else {
        this.getFromAPI().then(data => {
          if (typeof this.data[key] === 'undefined') {
            resolve(langJson[key])
          } else {
            resolve(this.data[key])
          }
        })
      }
    })
  }

  getAll () {
    return new Promise((resolve, reject) => {
      if (this.data !== null) {
        resolve(this.data)
      } else {
        this.getFromAPI().then(data => {
          resolve(data)
        })
      }
    })
  }

  getDataFormStorage () {
    if (localStorage.getItem('LanguageData') !== null) {
      this.data = JSON.parse(localStorage.getItem('LanguageData'))
    }
  }

  destroyStorageData () {
    localStorage.removeItem('LanguageData')
  }

  getFromAPI () {
    const service = new LanguageAPI()
    return new Promise((resolve, reject) => {
      service.getList().then(response => {
        this.data = response.data
        this.setDataToStorage()
        resolve(this.data)
      })
    })
  }

  numberWordToNumeric (numberStr) {
    if (typeof numberStr !== 'string') {
      return numberStr
    }
    this.filterNumberKeysAndValues(this.data)
    numberStr.replace(/\b(work)\b/)
    for (const key in this.langNumbers) {
      const regx = new RegExp('\\b(' + key + ')\\b')
      if (numberStr.search(regx) >= 0) {
        return numberStr.replace(regx, this.langNumbers[key])
      }
    }
    return numberStr
  }

  filterNumberKeysAndValues (obj) {
    const filter = /^general.\d+$/
    let key
    for (key in obj) {
      if (obj.hasOwnProperty(key) && filter.test(key)) {
        this.langNumbers[obj[key]] = key.replace('general.', '')
      }
    }
  }
}
