<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 20C4.47305 20 0 15.5275 0 10C0 4.47305 4.47254 0 10 0C15.527 0 20 4.47254 20 10C20 15.527 15.5275 20 10 20ZM14.5057 9.33332H10.6667V5.49434C10.6667 5.12617 10.3682 4.82766 10 4.82766C9.6318 4.82766 9.33332 5.12613 9.33332 5.49434V9.33332H5.49434C5.12617 9.33332 4.82766 9.6318 4.82766 10C4.82766 10.3682 5.12613 10.6667 5.49434 10.6667H9.33332V14.5057C9.33332 14.8738 9.6318 15.1723 10 15.1723C10.3682 15.1723 10.6667 14.8739 10.6667 14.5057V10.6667H14.5057C14.8738 10.6667 15.1723 10.3682 15.1723 10C15.1723 9.6318 14.8738 9.33332 14.5057 9.33332Z" fill="#AFAFAF"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconAddMore',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-add-more': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
