export default class PlanNotFoundError extends Error {
  constructor (planName = 'plan') {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super()

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PlanNotFoundError)
    }

    this.name = 'PlanNotFoundError'
    // Custom debugging information
    this.planName = planName
  }
}
