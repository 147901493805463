import { extend } from 'vee-validate'
import { i18n } from '../../localization/i18n'

const validator = {
  validate (value) {
    // eslint-disable-next-line no-useless-escape
    const tester = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g
    let isMatch = !tester.test(String(value))

    if (isMatch) {
      return i18n.t('message[\'validation\'][\'messages\'][\'regex\']')
    }
    return true

  }
}
extend('phone_number', validator)
