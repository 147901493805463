import PeriodTrackerApi from '../api/period-tracker/PeriodTrackerApi'
import PeriodTrackerService from './PeriodTrackerService'

export default class PeriodFirstRecord {
  _id = null
  _customerId = null
  _lastPeriodDate = null
  _lastPeriodLength = null
  _periodLength = null
  _cycleLength = null
  _createdAt = null
  _updatedAt = null

  constructor (initialData = null) {
    if (initialData) {
      this.initiate(initialData)
    }
  }

  initiate (initialData) {
    this._id = initialData.id
    this._customerId = initialData.customer_id
    this._lastPeriodDate = initialData.last_period_date
    this._lastPeriodLength = initialData.actual_period_length
    this._periodLength = initialData.period_length
    this._cycleLength = initialData.cycle_length
    this._createdAt = initialData.created_at
    this._updatedAt = initialData.updated_at
  }

  getId () {
    return this._id
  }

  getCustomerId () {
    return this._customerId
  }

  getLastPeriodDate () {
    return this._lastPeriodDate
  }

  getLastPeriodLength () {
    return this._lastPeriodLength
  }

  getPeriodLength () {
    return this._periodLength
  }

  getCycleLength () {
    return this._cycleLength
  }

  getCreatedAt () {
    return this._createdAt
  }

  getUpdatedAt () {
    return this._updatedAt
  }

  setLastPeriodDate (lastPeriodDate) {
    this._lastPeriodDate = lastPeriodDate
  }

  setLastPeriodLength (lastPeriodLength) {
    this._lastPeriodLength = lastPeriodLength
  }

  setPeriodLength (periodLength) {
    this._periodLength = periodLength
  }

  setCycleLength (cycleLength) {
    this._cycleLength = cycleLength
  }

  save () {
    return new Promise((resolve, reject) => {
      let api = new PeriodTrackerApi()
      api.setFirstTimePeriod(
        this.getLastPeriodDate(),
        this.getLastPeriodLength(),
        this.getPeriodLength(),
        this.getCycleLength()
      )
        .then(data => {
          this.initiate(data.data)
          resolve()
        })
        .catch((error) => {
          PeriodTrackerService.handleServerError(error.response)
          reject(error)
        })
    })
  }

  update () {
    return new Promise((resolve, reject) => {
      let api = new PeriodTrackerApi()
      api.updateFirstTimePeriod(
        this.getId(),
        this.getLastPeriodDate(),
        this.getPeriodLength(),
        this.getCycleLength()
      )
        .then(data => {
          this.initiate(data.data)
          resolve()
        })
        .catch(error => {
          PeriodTrackerService.handleServerError(error.response)
          reject(error)
        })
    })
  }
}
