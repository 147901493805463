<template>
    <page :header="header" class="gc-page-weight">
      <validation-observer ref="form" v-slot="{handleSubmit, invalid}">
          <page-container>
              <div class="gc-page-weight__header">
                  <text-heading-4
                      class="gc-page-weight__title text-center"
                      weight="extra-bold"
                      lineHeight="multi">{{title.main}}</text-heading-4>
              </div>
              <div class="gc-page-weight__content">
                  <form id="form-input-weight"
                      class="gc-page-weight__form"
                      @submit.prevent="handleSubmit(nextComponent)">
                          <validation-provider v-slot="{ errors }" :rules="validations" vid="weightInput" name="weight">
                              <input-weight
                                  class="gc-page-weight__input-weight"
                                  :placeholder="placeholder"
                                  :measurementSystem="weight.preference"
                                  @unit="weight.preference=$event"
                                  v-model="weight"
                                  :measurement-system-changeable="switchPreference"
                                  :error-message="errors[0]">
                              </input-weight>
                          </validation-provider>
                  </form>
              </div>
          </page-container>
          <page-footer :form="'form-input-weight'" :validated="invalid"></page-footer>
         </validation-observer>
    </page>
</template>

<script>
import FormBaseComponent from '../../layout/template-1/global/widgets/forms/FormBaseComponent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ButtonPrimary from '../buttons/ButtonPrimary'
import InputWeight from '../inputs/InputWeight'
import TextBodyRegular from '../typography/TextBodyRegular'
import TextHeading4 from '../typography/TextHeading4'
import PageContainer from './page-sub-components/PageContainer'
import Page from './PageForm'
import { setValuesMixin } from '../../layout/template-1/global/widgets/forms/helpers/SetValuesMixin'
import PageFooter from './page-sub-components/PageFormFooter'

export default {
  name: 'Weight',
  extends: FormBaseComponent,
  mixins: [ setValuesMixin ],
  props: {
    switchPreference: {
      default: true
    }
  },
  components: {
    Page,
    PageContainer,
    TextHeading4,
    TextBodyRegular,
    ButtonPrimary,
    InputWeight,
    ValidationObserver,
    ValidationProvider,
    PageFooter
  },
  data: function () {
    return {
      header: {
        isTransparent: false,
        isFixed: false,
        show: false,
        left: '',
        right: '',
        rightBlink: false,
        modifiers: [],
        mainTitle: '',
        subTitle: ''
      },
      weight: {
        preference: '',
        lbs: null,
        kg: null
      },
      disabled: true,
      validations: '',
      placeholder: ''
    }
  },
  beforeMount () {
    // get already saved weight value
    this.weight = this.inputValue

    this.hideLoading()
    if (this.weight.preference === 'imperial' && typeof this.errors.weight !== 'undefined') {
      this.$refs.form.setErrors({ weightInput: 'this.errors.weight' })
    } else if (typeof this.errors.weight !== 'undefined') {
      this.$refs.form.setErrors({ weightInput: this.errors.weight })
    }
    this.$nextTick(() => {
      this.validations = 'weight'
    })
  },
  watch: {
    weight: {
      immediate: true,
      deep: true,
      handler: function () {
        if (this.weight.preference === 'imperial') {
          this.placeholder = '110.0'
        } else if (this.weight.preference === 'metric') {
          this.placeholder = '50.0'
        }
      }
    }
  },
  methods: {
    nextComponent () {
      this.$emit('submit', { key: this.inputKey, value: this.weight })
    }
  }
}
</script>
