import BaseAPI from '../BaseAPI.js'
import axios from 'axios'
import DefineInclude from '../../../DefineInclude'

export default class extends BaseAPI {
  _resource = ''
  _foodDiaryBaseUrl = ''

  constructor () {
    super()
    this._foodDiaryBaseUrl = DefineInclude.foodDiaryAPIURL
  }

  /***
   * custom API for the email send only
   *
   */
  getLogPaginatedData (payload) {
    let headers = this._headers
    headers['Authorization'] = 'Bearer ' + this._token
    headers['X-Api-Host'] = DefineInclude.apiUrl.replace(/\/$/, '')
    let pathArr = ['meals', 'log']
    return axios.get(this._foodDiaryBaseUrl + this._getPath(pathArr), {headers: this._getCustomHeaders(), params: payload})
  } /***
   * custom API for the email send only
   *
   */
  getLogByID (id) {
    let headers = this._headers
    headers['Authorization'] = 'Bearer ' + this._token
    headers['X-Api-Host'] = DefineInclude.apiUrl.replace(/\/$/, '')
    let pathArr = ['meals', 'log', id]
    return axios.get(this._foodDiaryBaseUrl + this._getPath(pathArr), {headers: this._getCustomHeaders()})
  }

  searchLog (search) {
    let headers = this._headers
    headers['Authorization'] = 'Bearer ' + this._token
    headers['X-Api-Host'] = DefineInclude.apiUrl.replace(/\/$/, '')
    let pathArr = ['meals', 'log', 'search']
    return axios.get(this._foodDiaryBaseUrl + this._getPath(pathArr), {params: search, headers: this._getCustomHeaders()})
  }

  saveLog (payload) {
    let headers = this._headers
    headers['Authorization'] = 'Bearer ' + this._token
    headers['X-Api-Host'] = DefineInclude.apiUrl.replace(/\/$/, '')
    let pathArr = ['meals', 'log']
    return axios.post(this._foodDiaryBaseUrl + this._getPath(pathArr), payload, {headers: this._getCustomHeaders()})
  }

  saveRecipe (payload) {
    let pathArr = ['recipes', 'custom']
    return axios.post(this._foodDiaryBaseUrl + this._getPath(pathArr), payload, {headers: this._getCustomHeaders()})
  }
  saveMeal (payload) {
    let pathArr = ['meals', 'custom']
    return axios.post(this._foodDiaryBaseUrl + this._getPath(pathArr), payload, {headers: this._getCustomHeaders()})
  }
  removeMealFromLog (logID, mealID) {
    let pathArr = ['meals', 'log', logID, 'remove-meal', mealID]
    return axios.delete(this._foodDiaryBaseUrl + this._getPath(pathArr), {headers: this._getCustomHeaders()})
  }

  removeSavedMeal (mealID) {
    let pathArr = ['meals', 'custom', mealID, 'save']
    return axios.post(this._foodDiaryBaseUrl + this._getPath(pathArr), {saved: false}, {headers: this._getCustomHeaders()})
  }

  searchSavedMeals (params) {
    let pathArr = ['meals', 'custom', 'saved']
    return axios.get(this._foodDiaryBaseUrl + this._getPath(pathArr), {headers: this._getCustomHeaders(), params: params})
  }

  searchSavedDineOutMeals (params) {
    let pathArr = ['meals', 'custom', 'saved','dine-out']
    return axios.get(this._foodDiaryBaseUrl + this._getPath(pathArr), {headers: this._getCustomHeaders(), params: params})
  }

  _getCustomHeaders () {
    let headers = this._headers
    headers['Authorization'] = 'Bearer ' + this._token
    headers['X-Api-Host'] = DefineInclude.apiUrl.replace(/\/$/, '')

    return headers
  }
}
