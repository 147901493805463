export default {
  /**
   * defined the `pageConfig` prop and set the default value
   */
  props: {
    pageConfig: {
      type: Object,
      required: false,
      default: function () {
        return {
          type: '',
          name: '',
          variation: 'design1',
          properties: [],
          widgets: []
        }
      }
    }
  },
  methods: {
    /**
     * get the widget config by the parsed prop
     * @param name
     * @returns {{name: *, type: string, page-components: [], variation: string, properties: []}|T}
     */
    getWidgetConfig (name) {
      const widget = this.pageConfig.widgets.find(widget => {
        return widget.name === name
      })
      if (widget) {
        return widget
      } else {
        return {
          type: 'widget',
          name: name,
          variation: 'design1',
          properties: [],
          widgets: []
        }
      }
    }
  }
}
