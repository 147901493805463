<template>
  <div
    class="gc-navigator-bottom-bar__floating-area-button"
    @click="requestUpload">
    <div class="gc-navigator-bottom-bar__icon">
      <transition>
        <icon-photos
          size="sm4"
          class="gc-navigator-bottom-bar__floating-area-button-icon"/>
      </transition>
    </div>
    <div class="gc-navigator-bottom-bar__text">
      <text-body-extra-small
        line-height="multi"
        class=" gc-navigator-bottom-bar__floating-area-button-text">
        {{ $t('message["navigator.bottom-bar.photo"]') }}
      </text-body-extra-small>
    </div>
    <pop-up-upload-photos
      v-if="uploadPopup.show"
      :visible="uploadPopup.show"
      @close="dataUpdated()"
      @updated="dataUpdated()"
    />
  </div>
</template>
<script>
import TextBodyExtraSmall from '../../typography/TextBodyExtraSmall'
import { mapGetters } from 'vuex'
import FitnessDiaryStore from '../../../../store/modules/FitnessDiaryStore'
import { flagValue } from '../../../../includes/TemplateSettings'

import IconPhotos from '../../../root/icons/IconPhotos'
import PopUpUploadPhotos from '../../popups/PopUpUploadPhotos'

export default {
  name: 'NavigatorBottomBarButtonPhoto',
  components: {
    IconPhotos,
    PopUpUploadPhotos,
    TextBodyExtraSmall
  },
  data: function () {
    return {
      show: false,
      uploadPopup: {
        show: false
      }
    }
  },
  beforeCreate () {
    const store = this.$store
    if (!(store && store.state && store.state['fitnessDiaryStore'])) {
      store.registerModule('fitnessDiaryStore', FitnessDiaryStore)
    }
  },
  mounted () {
    if (
      flagValue('show_fitness_diary') === null ||
      flagValue('show_fitness_diary')
    ) {
      this.show = true
    }

    this.getData()
  },
  methods: {
    requestUpload () {
      this.uploadPopup.show = true
    },
    ...mapGetters({
      service: 'fitnessDiaryStore/getService'
    }),
    dataUpdated () {
      this.uploadPopup.show = false
    },
    getData () {
      let service = this.service()
      service
        .getDataFromAPI()
        .then(() => {
          this.fitnessData = Object.values(service.getData())
          this.hasTodayUploadAllImages = Object.values(service.images).every(
            image => {
              return image.getTodayImage()
            }
          )
          this.updatedFitnessData()
          this.isLoaded = true
        })
        .catch(() => {
          this.isLoaded = false
        })
    }
  }
}
</script>
