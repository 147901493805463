<template>
  <div :class="componentClasses" @click="clicked()">
    <div>
      <text-content
        class="pc-dashboard__btn-progress-action__title"
        :weight="'extra-bold'"
        :line-height="'multi'"
        :size="'sm4'"
        v-html="header"
      />
      <text-content :weight="'medium'" :line-height="'multi'" :size="'sm1'" v-html="description"/>
    </div>
    <icon-forward :size="'md1'" />
  </div>
</template>

<script>
import TextContent from '../../../../root/TextContent'
import IconForward from '../../../../root/icons/IconForward'

export default {
  name: 'ButtonProgressAction',
  components: {
    TextContent,
    IconForward
  },
  props: {
    header: {
      default: ''
    },
    description: {
      default: ''
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'pc-dashboard__btn-progress-action': true
      }
    }
  },
  methods: {
    clicked () {
      this.$emit('click')
    }
  }
}
</script>

<style scoped></style>
