<template>
  <div
    :class="componentClasses"
    @click="clicked"/>
</template>

<script>
export default {
  name: 'InputRadioButtonCircular',
  props: {
    id: {
      required: true
    },
    disabled: {
      default: false
    },
    value: {
      default: false
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-input-radio-button-circular': true,
        'gc-input-radio-button-circular--disabled': this.disabled,
        'gc-input-radio-button-circular--selected': this.value === this.id
      }
    }
  },
  methods: {
    clicked () {
      if (!this.disabled) {
        this.$emit('click', this.id)
        this.$emit('input', this.id)
      }
    }
  }
}
</script>

<style scoped>

</style>
