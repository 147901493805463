<template>

  <popup
    v-if="visible"
    ref="updateFitnessDataModal"
    :visible="visible"
    @hide="closePopup"
    modal-class="gc-popup-update-fitness-data"
  >
    <popup-title>
      <popup-button-close @hide="closePopup"/>
    </popup-title>
    <popup-body class="gc-popup-update-fitness-data__body">
      <popup-head class="text-center">
        <popup-text-heading v-if="status ==='init'">{{ fitnessData.getName() }}</popup-text-heading>
        <div v-if="status==='loading'" class="text-center">
          <loading-buffer :height="'75'"></loading-buffer>
        </div>
      </popup-head>
      <popup-content>
        <div v-if="status ==='init'">
          <div class="gc-popup-update-fitness-data__form" v-if="fitnessData.getType() !== 'body_fat'">
            <ValidationObserver name="bodyFat" ref="metricForm" tag="div">
              <div class="gc-popup-update-fitness-data__form-row">
                <div>
                  <ValidationProvider
                    :name="fitnessData.getName()"
                    :rules="validation"
                    v-slot="{ errors }"
                    tag="div"
                    class="gc-popup-update-fitness-data__input-measurement-wrapper">
                    <input-measurement-spinner
                      :errorMessage="errors[0]"
                      :maxValue="9999"
                      :step="step"
                      :value="30.0"
                      v-model="inputValue"
                      :unit="unit"
                      @change="changed"/>
                  </ValidationProvider>
                </div>
              </div>
            </ValidationObserver>
          </div>
          <div class="gc-popup-update-fitness-data__form gc-popup-update-fitness-data__form-body-fat" v-else>
            <input-body-fat
              :input-value="getBodyFatValue()"
              :options="service().getBodyFatOptions()"
              :gender="gender"
              :height="height"
              :measurement-system="measurementSystem"
              v-on:has-error="setHasError($event)"
              v-on:input="setBodyFatValue($event)"
            ></input-body-fat>
          </div>
          <div class="info-modal__general-error">
            <div
              class="ma-form__message ma-form__message--error"
              v-for="(error,eid) in generalErrors"
              :key="'fd-general-errors'+eid"
              v-html="error"
            ></div>
          </div>
        </div>
        <div class="gc-popup-update-fitness-data__success">
          <icon-complete-filled :size="'lg3'" v-if="status==='success'"/>
        </div>

      </popup-content>
      <popup-footer v-if="status === 'init'">
        <button-primary
          :disabled="submited || hasError"
          v-on:click="doAction()"
          :text="$i18n.t('message[\'ui.modal-save\']')"/>
      </popup-footer>
    </popup-body>

  </popup>

</template>

<script>
import UpTriangleSvg from './svgs/UpTriangleSvg'
import InputBodyFat from './../../../../global/inputs/InputBodyFat'
import DownTriangleSvg from './svgs/DownTriangleSvg'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import fitnessDiaryUpdateMetricMixin
  from '../../../../../mixins/component-mixins/page/fitness-diary/fitnessDiaryUpdateMeasurementComponentMixin'
import Popup from '../../../../global/popups/Popup'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import PopupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupFooter from '../../../../global/popups/popup-sub-components/PopupFooter'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import InputMeasurement from '../../../../global/inputs/InputMeasurement'
import InputMeasurementSpinner from '../../../../global/inputs/InputMeasurementSpinner'
import ButtonSecondary from '../../../../global/buttons/ButtonSecondary'
import moment from 'moment'
import LoadingBuffer from '@/components/layout/template-1/global/widgets/LoadingBuffer.vue'
import IconCompleteFilled from '@/components/root/icons/IconCompleteFilled.vue'

export default {
  name: 'PopUpUpdateFitnessData',
  mixins: [popupAnimationMixin, fitnessDiaryUpdateMetricMixin],
  components: {
    IconCompleteFilled,
    LoadingBuffer,
    ButtonSecondary,
    InputMeasurementSpinner,
    InputMeasurement,
    ButtonPrimary,
    PopupFooter,
    PopupContent,
    PopupTextHeading,
    PopupBody,
    PopupHead,
    PopupButtonClose,
    PopupTitle,
    Popup,
    DownTriangleSvg,
    UpTriangleSvg,
    InputBodyFat,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    visible: {default: false}
  },
  methods: {
    saveData () {
      // don't submit when not data
      if (!parseFloat(this.inputValue)) {
        return
      }
      if (this.fitnessData.getType() === 'body_fat') {
        this.inputValue = parseInt(this.inputValue) / 100
      }
      this.status = 'loading'
      this.submited = true
      this.fitnessData.setInputValue(this.inputValue)
      this.fitnessData.saveData()
        .then(() => {
          this.status = 'success'
          this.success.status = true
          this.fitnessData.setLatestDate(
            moment().format('YYYY-MM-DD')
          )
          this.afterUpdateData()
          this.$emit('updated')
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 422) {
            if (err.response.data.type === 'form') {
              this.generalErrors.errors = Object.values(
                err.response.data.errors
              )
            } else if (err.response.data.type === 'logic') {
              this.generalErrors.errors = err.response.data.errors
            } else {
              let errors = []
              const serverErrors = Object.values(err.response.data.errors)
              errors = errors.concat(serverErrors)
              this.generalErrors.errors = errors
            }
          } else {
            this.generalErrors = [
              this.$i18n.t('message["general.server-error-title"]')
            ]
          }
        })
        .finally(() => {
          this.submited = false
        })
    },
    logEvents () {
      let context = 'my_journey'
      if (this.$route.name === 'DashboardMain') {
        context = 'dashboard'
      }
      this.logEvent('Journey.AddedMeasurement', {'measurement ': this.fitnessData.type, context: context}, false)

      if (this.fitnessData.type === 'weight') {
        this.logEvent('Journey.AddedMeasurement.Weight', {
          value: this.fitnessData.getCurrentValueInMetric(),
          context: context
        })
      } else if (this.fitnessData.type === 'body_fat') {
        this.logEvent('Journey.AddedMeasurement.BodyFat', {
          value: this.fitnessData.getCurrentValueInMetric(),
          context: context
        }, false)
      }
    },
    afterUpdateData () {
      this.logEvents()
      setTimeout(() => {
        this.closePopup()
      }, 1500)
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
        // backdrop close event fire
        if (e && e.trigger === 'backdrop') {
          this.submitCloseEvent('JourneyFitness', 'background')
        }
      })
    }

  }
}
</script>
