import Vue from 'vue'
import Vuex from 'vuex'
import WorkoutLogService from '../../services/WorkoutLogService'
import WorkoutProgramsService from '../../services/WorkoutProgramsService'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    workoutProgram: null,
    exerciseDataStatus: 'init',
    workoutDataStatus: 'init',
    generalExerciseLogDataStatus: 'init',
    generalExerciseDataStatus: 'init',
    workoutID: 0,
    exerciseID: 0,
    workoutLogService: new Map(),
    exerciseData: new Map(),
    currentWorkoutLogService: null,
    currentExerciseData: null

  },
  mutations: {
    reset (state, payload) {
      state.workoutProgram = null
      state.exerciseDataStatus = 'init'
      state.workoutDataStatus = 'init'
      state.generalExerciseLogDataStatus = 'init'
      state.generalExerciseDataStatus = 'init'
      state.workoutID = 0
      state.exerciseID = 0
      state.workoutLogService = new Map()
      state.exerciseData = new Map()
      state.currentWorkoutLogService = null
      state.currentExerciseData = null
    },
    init (state, payload) {
      state.workoutID = payload.workoutId
      state.exerciseID = payload.exerciseID
      if (!state.workoutLogService.has(state.exerciseID)) {
        const workoutLogService = new WorkoutLogService(state.exerciseID, state.workoutID)
        state.workoutLogService.set(state.exerciseID, workoutLogService)
      }
      state.currentWorkoutLogService = state.workoutLogService.get(state.exerciseID)
    },
    resetExerciseDataForce (state) {
      state.exerciseDataStatus = 'init'
      state.generalExerciseDataStatus = 'init'
      state.exerciseData.delete(state.exerciseID)
    },
    resetWorkoutDataForce (state, data) {
      state.workoutDataStatus = 'init'
      const workoutService = new WorkoutProgramsService()
      workoutService.reset()
    }
  },
  actions: {
    resetStatus ({ commit, state }) {
      state.exerciseDataStatus = 'init'
      state.workoutDataStatus = 'init'
      state.generalExerciseLogDataStatus = 'init'
      state.generalExerciseDataStatus = 'init'
    },
    resetWorkoutData ({ commit, state }, data) {
      state.workoutDataStatus = 'init'
      const workoutService = new WorkoutProgramsService()
      workoutService.reset()
    },
    loadExerciseData ({ commit, state }, data) {
      if (state.exerciseDataStatus === 'loading') {
        return
      }
      if (state.exerciseData.has(state.exerciseID)) {
        state.currentExerciseData = state.exerciseData.get(state.exerciseID)
        state.exerciseDataStatus = 'loaded'
      } else {
        let exercise = null
        state.exerciseDataStatus = 'loading'
        if (state.workoutProgram) {
          let exerciseID = parseInt(state.exerciseID)
          exercise = state.workoutProgram.getExerciseByID(exerciseID)
        } else {
          throw new Error('workout program not loaded ID :' + state.workoutID)
        }
        exercise.setExerciseData()
          .then(() => {
            state.exerciseData.set(state.exerciseID, exercise)
            state.currentExerciseData = state.exerciseData.get(state.exerciseID)
          }).finally(() => {
            state.exerciseDataStatus = 'loaded'
          })
      }
    },
    loadWorkoutData ({ commit, state }, data) {
      if (state.workoutDataStatus === 'loading') {
        return
      }
      state.workoutDataStatus = 'loading'
      const workoutService = new WorkoutProgramsService()
      workoutService.setWorkoutProgramList().then(() => {
        let workoutID = parseInt(state.workoutID)
        let program = workoutService.getWorkoutProgram(workoutID)
        if (program.checkDetailsLoaded()) {
          state.workoutProgram = program
          state.workoutDataStatus = 'loaded'
        } else {
          program.setProgramDetails().finally(() => {
            state.workoutProgram = program
            state.workoutDataStatus = 'loaded'
          })
        }
      })
    },
    resetExerciseDataForce ({ commit, state }) {
      state.exerciseDataStatus = 'init'
      state.generalExerciseDataStatus = 'init'
      state.exerciseData.delete(state.exerciseID)
    },
    removeWorkoutLog ({ commit, state }) {
      state.workoutLogService = new Map()
      let workoutLogService = new WorkoutLogService(state.exerciseID, state.workoutID)
      state.workoutLogService.set(state.exerciseID, workoutLogService)
      state.currentWorkoutLogService = workoutLogService
    }

  },
  getters: {
    getExerciseDataStatus (state) {
      return state.exerciseDataStatus
    },
    getWorkoutDataStatus (state) {
      return state.workoutDataStatus
    },
    getExerciseData (state) {
      return state.currentExerciseData
    },
    getCurrentWorkoutLogService (state) {
      return state.currentWorkoutLogService
    },
    getCurrentWorkoutProgram (state) {
      return state.workoutProgram
    }
  }
}
