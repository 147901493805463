<template>
  <div class="card-custom-video">
    <p class="head__title">{{video.title}}</p>
    <div :style="wrapStyle" class="full-width-wrap">
      <transition>
        <div class="image-wrap" v-show="!showVideo">
          <div class="image-wrap__icon-wrapper">
            <img
              ref="playIcon"
              :src="$appConfig.imagesUrl+'video-player/btn-play.svg'" class="image-wrap__icon"
              v-on:click="showVideoArea()"
              />
          </div>
          <img :src="video.thumbnail_url"
               style="cursor:pointer" v-if="showImage"
               :style="thumbnailStyle"
               class=""
               v-on:error="hideImage()"/>
          <div :style="thumbnailStyle" class="card-custom-video_tumb-no-image" ref="noImageArea" v-if="!showImage" >

          </div>

        </div>
      </transition>
    </div>
    <a class="content-link" v-if="videoType==='other'" :href="video.url">{{ video.title}}</a>
    <a :href="void(0)" class="content-link" v-bind:key="tag" v-for="tag in filterDelayTags">#{{tag}}</a>
    <p class="content-text" v-html=" video.description "></p>

  </div>
</template>
<script>
import NativeAppMixin from '../../../../../mixins/NativeAppMixin'
export default {
  name: 'videoArea',
  mixins: [NativeAppMixin],
  props: {
    video: {}
  },
  data: function () {
    return {
      height: 235,
      autoplay: true,
      isFrameReady: false,
      showImage: true,
      showVideo: false,
      videoWidth: screen.width,
      videoType: '',
      videoID: '',
      vimeoPlayer: null,
      youtubePlayerVars: {
        autoplay: 1,
        muted: 1
      },
      wrapStyle: {
        height: '240px'
      },
      videoStyle: {
        height: '240px'
      },
      thumbnailStyle: {
        height: '240px'
      }
    }
  },
  mounted () {
    const videoUrl = this.video.url
    if (
      videoUrl.match(
        /(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/g
      )
    ) {
      const videoname = videoUrl.split('?')[0]
      const urlParams = videoname.split('/')
      this.videoID = urlParams[urlParams.length - 1]
      this.videoType = 'vimeo'
    } else if (videoUrl.includes('youtube') || this.isYouTubeUrl(videoUrl)) {
      this.videoID = this.getYouTubeID(videoUrl)
      this.videoType = 'youtube'
    } else {
      this.videoType = 'other'
    }
    this.resizeVideo()
  },
  computed: {
    filterDelayTags: function (tags) {
      return this.video.tags.filter((tag) => {
        return !tag.match(/delay\d+\w/)
      })
    }
  },
  methods: {
    hideImage () {
      this.showImage = false
      setTimeout(() => {
        const background = window.getComputedStyle(this.$refs.noImageArea)
        const elements = $(this.$refs.playIcon)
        if (this.lightOrDark(background.getPropertyValue('background-color'))) {
          elements.css('filter', 'invert(1)')
        } else {
          elements.css('filter', 'invert(0)')
        }
      }, 500)
    },
    showVideoFrame (e) {
      this.autoplay = false
      this.showVideo = true
    },
    showVideoArea () {
      this.handleUrlNavigate(this.video.url)
    },
    resizeVideo () {
      const height = (7 * parseFloat($('.full-width-wrap').css('width'))) / 12
      this.height = height
      this.wrapStyle.height = height + 'px !important'
      this.videoStyle.height = height + 'px !important'
      this.thumbnailStyle.height = height + 'px !important'
    },
    isYouTubeUrl (url) {
      url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
      return (url[2] !== undefined)
    },
    getYouTubeID (url) {
      url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
      // TODO -fix
      // eslint-disable-next-line no-useless-escape
      return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0]
    },
    linkNavigateClickHandler (event) {
      let targetElement = event.target
      const selector = '.content-text a'
      while (targetElement != null) {
        if (targetElement.matches(selector)) {
          event.preventDefault()
          this.handleUrlNavigate(targetElement.getAttribute('href'))
          return
        }
        targetElement = targetElement.parentElement
      }
    }
  }

}
</script>
<style>

</style>
