<template>
  <div :class="componentClasses">
    <dashboard-notification-bar v-show="showNotificationBar"/>
    <side-bar-v2  v-if="!isDesktop"/>

    <div class="pc-dashboard__nav-bar-header-right-section">

      <div class="app-nav__btn-pwa-wrap">
        <icon-create-shortcut class="app-nav__btn-pwa" v-if="showPwaPopup()" @click="pwaPopup.visible = true" size="md3" />
        <popup-p-w-a-instructions
          :visible="pwaPopup.visible"
          v-bind:key="'pwa-popup'"
          v-if="pwaPopup.visible"
          v-on:close="pwaPopup.visible = false"
        ></popup-p-w-a-instructions>
      </div>
      <div class="pc-dashboard__nav-bar-header-bell-icon"
        :class="{ 'pc-dashboard__nav-bar-header-bell-icon--new-notification': unreadCommunityNotificationsExist }"
        @click="goToNotificationPage"
        v-if="isCommunityEnabled">
        <icon-bell-filled v-if="unreadCommunityNotificationsExist" />
        <icon-bell v-else />
      </div>
    </div>

  </div>
</template>
<script>
import SideBarV2 from '../../../../global/navigators/SideBar'
import appHeaderMixin from '../../../../../mixins/appHeaderMixin'
import DashboardNotificationBar from './DashboardNotificationBar'
import { mapGetters } from 'vuex'
import PopupPWAInstructions from '../../../../global/popups/PopupPWAInstructions'
import SideMenu from '../../global/widgets/SideMenuV2'
import { flagValue } from '../../../../../includes/TemplateSettings'
import IconCreateShortcut from '../../../../root/icons/IconCreateShortcut'
import IconBell from '../../../../root/icons/IconBell'
import IconBellFilled from '../../../../root/icons/IconBellFilled'
import { checkIsMember } from '../../../../../helpers/user'
export default {
  name: 'NavBarHeader',
  components: {
    PopupPWAInstructions,
    DashboardNotificationBar,
    SideBarV2,
    SideMenu,
    IconCreateShortcut,
    IconBell,
    IconBellFilled
  },
  props: {},
  mixins: [appHeaderMixin],
  computed: {
    ...mapGetters({
      notificationBarSettings: 'pageStore/notificationBarSettings',
      notificationCount: 'getNotificationCount'
    }),
    showNotificationBar () {
      return checkIsMember()
    },
    showNewSideMenu () {
      return !!flagValue('show_sidebar_v2', false)
    },
    componentClasses: function () {
      return {
        'pc-dashboard__nav-bar-header': true,
        'pc-dashboard__nav-bar-header--with-notification-bar': this.notificationBarShow
      }
    },
    notificationBarShow () {
      return (
        this.notificationBarSettings.show &&
        this.notificationCount > 0 &&
        !(this.params.page && this.params.page === 'notifications')
      )
    }
  }
}
</script>

<style scoped></style>
