<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 5.33334H3.66667H17" stroke="#434343" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15.3327 5.33333V17C15.3327 17.442 15.1571 17.8659 14.8445 18.1785C14.532 18.4911 14.108 18.6667 13.666 18.6667H5.33268C4.89065 18.6667 4.46673 18.4911 4.15417 18.1785C3.84161 17.8659 3.66602 17.442 3.66602 17V5.33333M6.16602 5.33333V3.66667C6.16602 3.22464 6.34161 2.80072 6.65417 2.48816C6.96673 2.17559 7.39065 2 7.83268 2H11.166C11.608 2 12.032 2.17559 12.3445 2.48816C12.6571 2.80072 12.8327 3.22464 12.8327 3.66667V5.33333" stroke="#434343" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconDeleteLinear',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-delete': true,
        'rc-icon-delete-linear': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
