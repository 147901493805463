<template>
      <div v-on="$listeners" class="gc-nutrition-details-bar__values-wrapper">
        <div class="gc-nutrition-details-bar__values" v-for="(item, index) in macroNutrients" :key="'macroNutrients-'+index">
          <div class="gc-nutrition-details-bar__values-row gc-nutrition-details-bar__values-row-top">
            <text-body-regular class="gc-nutrition-details-bar__value-qut" :weight="'extra-bold'" :lineHeight="'multi'">{{roundValues(item.qut)}}</text-body-regular>
            <text-body-extra-small class="gc-nutrition-details-bar__value-unit" :weight="'extra-bold'" :lineHeight="'multi'">{{item.unit}}</text-body-extra-small>
          </div>
          <div class="gc-nutrition-details-bar__values-row">
            <text-body-extra-small class="gc-nutrition-details-bar__value-name" :lineHeight="'multi'">{{firstLetterCaps(item.name)}}</text-body-extra-small>
          </div>
        </div>
        <div v-show="!hideCalories" class="gc-nutrition-details-bar__values gc-nutrition-details-bar__values-calories" v-for="(item, index) in calories" :key="'calories-'+index" >
          <div class="gc-nutrition-details-bar__values-row gc-nutrition-details-bar__values-row-top">
            <text-body-regular class="gc-nutrition-details-bar__value-qut" :weight="'extra-bold'" :lineHeight="'multi'">{{roundValues(item.qut)}}</text-body-regular>
            <text-body-extra-small class="gc-nutrition-details-bar__value-unit" :weight="'extra-bold'" :lineHeight="'multi'">{{item.unit}}</text-body-extra-small>
          </div>
          <div class="gc-nutrition-details-bar__values-row">
            <text-body-extra-small class="gc-nutrition-details-bar__value-name" :lineHeight="'multi'">{{firstLetterCaps(item.name)}}</text-body-extra-small>
          </div>
        </div>
      </div>

</template>
<script>
import TextBodyRegular from '../typography/TextBodyRegular'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import { getVisibilityOfCaloriesInNutrition } from '@/includes/TemplateSettings'
export default {
  name: 'NutritionDetailsBar',
  components: {
    TextBodyExtraSmall,
    TextBodyRegular
  },
  props:
      {
        calories: {
          default: []
        },
        macroNutrients: {
          default: []
        }
      },
  computed: {
    componentClasses: function () {
      return {
        'gc-nutrition-details-bar': true
      }
    }
  },
  data: function(){
    return {
      hideCalories:  getVisibilityOfCaloriesInNutrition()
    }
  },
  methods: {
    roundValues (val) {
      const number = parseFloat(val)
      const numberInt = parseInt(val)
      if (number === numberInt) {
        return numberInt
      }
      if (number) {
        return number.toFixed(1)
      }
      return val
    }
  }
}
</script>
<style>
</style>
