<template>
  <popup
    :visible="visible"
    @hide="closePopup"
    modal-class="gc-instructions-popup"
    ref="modal"
    v-if="visible"
  >
    <popup-title>
      <popup-button-close @hide="closePopup($event), submitCloseEvent('Instructions','x')"/>
    </popup-title>
    <popup-body>
      <popup-head class="text-center" v-if="title">
        <popup-text-heading>{{ title }}</popup-text-heading>
      </popup-head>
      <popup-content class="gc-instructions-popup__content">
        <popup-text-body v-if="notes">
          <span class="info-modal__text-title">{{$t('message["general.notes"]')}} : </span>
          <span class="app-content-wrap" v-html="notes"></span>
        </popup-text-body>
        <div  v-if="checkIsNotEmptyTextArray (instructions)">
          <popup-text-body
            :key="'instructions'+iID"
            class="app-content-wrap"
            v-for="(instruction,iID) in instructions"
          >
            <span v-html="instruction"></span>
          </popup-text-body>
        </div>

      </popup-content>
    </popup-body>
  </popup>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import NativeAppMixin from '../../../mixins/NativeAppMixin'
import Popup from './Popup'
import PopupBody from './popup-sub-components/PopupBody'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupContent from './popup-sub-components/PopupContent'
import PopupTextBody from './popup-sub-components/PopupTextBody'
import PopupHead from './popup-sub-components/PopupHead'
import PopupTextHeading from './popup-sub-components/PopupTextHeading'
import PopupTextSubHeading from './popup-sub-components/PopupTextSubHeading'
import PopupTitle from './popup-sub-components/PopupTitle'
import headerEventBus from '../../../event-buses/headerEventBus'

export default {
  name: 'Instructions',
  components: {
    Popup,
    PopupBody,
    PopupButtonClose,
    PopupContent,
    PopupTextBody,
    PopupHead,
    PopupTextHeading,
    PopupTextSubHeading,
    PopupTitle
  },
  mixins: [popupAnimationMixin, NativeAppMixin],
  data: function () {
    return {
      imagesUrl: this.$appConfig.imagesUrl
    }
  },
  computed: {
    ...mapGetters({
      instructions: 'instructionPopupStore/instructions',
      visible: 'instructionPopupStore/visible',
      title: 'instructionPopupStore/title',
      notes: 'instructionPopupStore/notes'
    })
  },
  mounted () {
    setTimeout(() => {
      document.querySelector('body')
        .addEventListener('click', this.linkNavigateClickHandler, true)
    }, 500)
  },
  methods: {
    ...mapMutations({
      hidePopup: 'instructionPopupStore/hide'
    }),
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.hidePopup()
        headerEventBus.$emit('info-popup-closed')
        this.$emit('close')
        if (e && e.trigger === 'backdrop') {
          this.submitCloseEvent('Instructions', 'background')
        }
      })
    },
    emitClose () {
      this.closePopup()
      this.$emit('close')
    },
    linkNavigateClickHandler (event) {
      let targetElement = event.target
      const selector = '.info-modal__text a'
      while (targetElement != null) {
        if (targetElement.matches(selector)) {
          event.preventDefault()
          this.handleUrlNavigate(targetElement.getAttribute('href'))
          return
        }
        targetElement = targetElement.parentElement
      }
    }
  },
  beforeDestroy () {
    document.querySelector('body')
      .removeEventListener('click', this.linkNavigateClickHandler, true)
  }
}
</script>
