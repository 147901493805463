<template>
  <div class="pc-container-saved-meals">
    <page-container class="pc-container-saved-meals__top-container pc-container__remaining-ntr">
      <text-body-extra-small class="pc-food-dairy-log-meals__remaining-ntr">{{$t('message[\'food-diary.remaining-for-the-day\']')}}</text-body-extra-small>

      <nutrition-details-bar
        :calories="remainingMacros.calories"
        :macro-nutrients="remainingMacros.macronutrients"
      />
    </page-container>
    <transition name="fade">
      <navigation-meals-add
        class="pc-container-saved-meals__bar-navigation"
        active-tab="saved-meals"
        @change-tab="changeTab($event)"
      />
    </transition>
    <page-container class="pc-container-saved-meals__container-search">
      <input-search-bar
        v-if="!(meals.length===0 && !searchString && state !== 'searching')"
        v-model="searchString"
        :placeholder="$t('message[\'food-diary.search-meals\']')"
        class="pc-container-saved-meals__input-search"
      />
      <text-body-extra-small
        v-if="state!=='searching'&&meals.length===0&&!searchString"
        class="pc-container-saved-meals__empty-message"
        line-height="lh-lg"
      >
        {{ $t('message["food-diary.empty-saved-meals"]') }}
      </text-body-extra-small>
      <text-body-extra-small
        v-else-if="state!=='searching'&&meals.length===0"
        class="pc-container-saved-meals__empty-message pc-container-saved-meals__no-results"
        line-height="lh-lg"
      >
        {{ $t('message["food-diary.no-results-for-saved-meals"]') }}
      </text-body-extra-small>

      <grid-row
        v-if="meals.length>0"
        class="pc-container-saved-meals__list-meals"
      >
        <card-saved-meal-with-macros
          v-for="meal in meals"
          :id="meal._id"
          :key="meal._id"
          :show-net-carbs="showNetCarbs"
          :is-logged="isMealLogged(meal)"
          :log-i-d="'2'"
          :recipes="meal.recipes"
          :restaurant-name="getRestaurantName(meal)"
          :name="meal.name"
          :macro-nutrients="getCustomMealMealMacros(meal)"
          :calories="getCustomMealMealCalories(meal)"
          class="pc-container-saved-meals__meal"
          :type="meal.type"
          :meal-type="'custom'"
          @meal-log-removed="mealRemoved(meal)"
          @meal-unsaved="mealUnsaved(meal)"
          @meal-log-added="mealAdded(meal)"
        />
        <loading-buffer
          v-if="state==='searching'"
          height="60"
        />
      </grid-row>
      <page-fixed-footer v-if="isActiveTab">
        <button-primary
          v-if="meals.length !== 0"
          :text="state === 'submitting' ? '' : $tc('message[\'food-diary.log-meal-submit\']', newMealCount, { count: newMealCount })"
          :disabled="isButtonDisabled"
          @click="submit()"
        >
          <loading-buffer-custom v-if="state === 'submitting'" />
        </button-primary>
      </page-fixed-footer>
    </page-container>
  </div>
</template>

<script>
import InputSearchBar from '../../workout/page-components/workout-log-sub-components/InputSearchBar'
import CardSavedMealWithMacros from './CardSavedMealWithMacros'
import PageContainer from '../../../../global/pages/page-sub-components/PageContainer'
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import FoodDiaryDaySlider from './FoodDiaryDaySlider'
import NavigationMealsAdd from './NavigationMealsAdd'
import NutritionDetailsBar from '../../../../global/bars/BarNutritionDetails'
import TextBodySmall from '../../../../global/typography/TextBodySmall'
import PageFixedFooter from '../../../../global/pages/PageFixedFooter'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import logSavedMealMixin from '../../../../../mixins/component-mixins/page/food-diary/logSavedMealMixin'
import GridRow from '../../../../global/grid/GridRow'
import LoadingBufferCustom from '../../global/widgets/LoadingBufferCustom'

export default {
  name: 'ContainerSavedMeals',
  components: {
    LoadingBufferCustom,
    GridRow,
    TextBodyExtraSmall,
    ButtonPrimary,
    PageFixedFooter,
    NavigationMealsAdd,
    NutritionDetailsBar,
    FoodDiaryDaySlider,
    LoadingBuffer,
    PageContainer,
    CardSavedMealWithMacros,
    TextBodySmall,
    InputSearchBar },
  mixins: [logSavedMealMixin],
  computed: {
    selectDayText () {
      const selectedOptionText = this.selectedOptionText
      return this.$i18n.t("message['food-diary.selected-option-message']",
        {day: this.dayText, option: `<span style="font-weight: 700">${selectedOptionText}</span>`})
    }
  }
}
</script>

<style scoped>

</style>
