<template>
  <div @click="navigateNext()" class="card-image card-image--full-with-n card-image--full-with--reps"
       v-bind:class="{'card-image--disabled': (!exercise.getImage() || thumbLoading)}">
    <img
      alt=""
      v-if="image"
      :src="image"
      class="card-image__image"
      @error="setVideoThumb()"
    />
    <svg v-if="exercise.getIsCompleted()" class="svg-green-check-btn check-green" width="22" height="22" viewBox="0 0 22 20"
         fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="10.6013" cy="10" rx="10.6013" ry="10" fill="#2FC417"/>
      <path
        d="M18.0975 2.92891C16.0952 1.0402 13.433 0 10.6013 0C7.76953 0 5.10728 1.0402 3.10501 2.92891C1.1027 4.81766 0 7.32891 0 10C0 12.6711 1.1027 15.1823 3.10501 17.0711C5.10733 18.9598 7.76953 20 10.6013 20C13.433 20 16.0952 18.9598 18.0975 17.0711C20.0998 15.1823 21.2025 12.6711 21.2025 10C21.2025 7.32891 20.0998 4.8177 18.0975 2.92891ZM10.6013 18.8281C5.44073 18.8281 1.24234 14.8679 1.24234 10C1.24234 5.13215 5.44073 1.17188 10.6013 1.17188C15.7618 1.17188 19.9602 5.13215 19.9602 10C19.9602 14.8679 15.7618 18.8281 10.6013 18.8281Z"
        fill="#2FC417"/>
      <path
        d="M15.6658 6.79265C15.4233 6.5639 15.0299 6.5639 14.7874 6.79269L9.30212 11.9669L6.41464 9.24316C6.1721 9.01437 5.77877 9.01437 5.53619 9.24316C5.2936 9.47195 5.2936 9.84296 5.53619 10.0718L8.86287 13.2098C8.98416 13.3242 9.14314 13.3814 9.30208 13.3814C9.46101 13.3814 9.62003 13.3242 9.74129 13.2098L15.6658 7.62128C15.9084 7.3925 15.9084 7.02148 15.6658 6.79265Z"
        fill="white"/>
    </svg>
    <p class="exercise-number-v2" v-if="isGrouped">{{$t('message["workouts.exercise"]')}} {{(groupExerciseID+1)}}</p>
    <div class="card-container">
      <p class="card-image-set-title">{{getExerciseName()}}</p>
      <p class="card-image__set-number">{{getExerciseMuscles()}}</p>
      <p class="card-image__set-name"
         v-if="!isCircuit&&((getExerciseMusclesCount()===1&&getExerciseMuscles(exercise.muscles)!==exercise.setType.name)|| getExerciseMusclesCount()>1)">
        {{exercise.setType.name}}</p>
      <p class="card-image__set-name" v-if="isCircuit">{{$t('message["workout.mode-circuit"]')}}</p>
      <p class="card-image__set-number card-image__set-number--with-reps" v-if="exercise.getSetsRepsString()">
        {{ setsText }}
      </p>
    </div>
    <div class="card-image__overlay"></div>
  </div>
</template>
<script>

import exerciseCardMixin from '../../../../../mixins/component-mixins/page/workout/exerciseCardMixin'

export default {
  name: 'ExerciseListCard',
  mixins: [exerciseCardMixin]
}
</script>
