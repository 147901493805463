import { configure, extend, localize } from 'vee-validate'
import lang from '../../../lang/lang'
import * as rules from 'vee-validate/dist/rules'
import './validation-rules/date_between.js'
import './validation-rules/birthday'
import './validation-rules/time'
import './validation-rules/date'
import './validation-rules/height'
import './validation-rules/weight'
import './validation-rules/not_same_password'
import './validation-rules/phone_number'
import './validation-rules/workout_reps'
import './validation-rules/limit_selected_options'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})
let localization = templateConfig.localization
let langObject = lang.validation

if (localization.validation && typeof localization.validation === 'object') {
  langObject = localization.validation
} else if (localization.validation && typeof localization.validation === 'string') {
  try {
    langObject = JSON.parse(localization.validation)
  } catch (e) {
    // Do nothing
  }
}

localize('en', langObject)
configure({
  classes: {
    invalid: 'ma-form__input--error'
  }
})
