<template>
  <div
    class="gc-page-form-footer gc-page-footer-multiple-buttons container footer--with-back"
    :class="footerWrapperClass"
    ref="footerContainer"
  >
    <button-primary
      class="gc-page-form-footer__submit-button"
      type="submit"
      :form="form"
      :disabled="validated"
      :text="buttonText"
      @click="clicked()"
    ></button-primary>
    <button-link
      class="gc-page-form-footer__link gc-page-footer-multiple-buttons__link text-center"
      line-height="multi"
      @click="secondaryButtonClick"
      :text="textSecondary"
    />
    <text-body-extra-small
      v-if="!hideReturnButton"
      class="gc-page-form-footer__link text-center"
      lineHeight="multi"
      @click="delayedGoBack"
      >{{ $t("message['general.go-back']") }}</text-body-extra-small
    >
  </div>
</template>
<script>
import ButtonPrimary from '../../../global/buttons/ButtonPrimary'
import TextBodyExtraSmall from '../../../global/typography/TextBodyExtraSmall'
import ButtonLink from '../../buttons/ButtonLink'
export default {
  name: 'PageFooterMulipleButtons',
  components: {
    ButtonPrimary,
    TextBodyExtraSmall,
    ButtonLink
  },
  props: {
    validated: {
      default: false
    },
    fixed: {
      default: true
    },
    text: {
      default: ''
    },
    textSecondary: {
      default: ''
    },
    form: {
      default: ''
    },
    hideReturnButton: {
      default: false
    }
  },
  data: function () {
    return {
      footerWrapperClass: 'fixed-bottom'
    }
  },
  computed: {
    buttonText () {
      return this.text || this.$i18n.t('message["general.next"]')
    }
  },
  mounted () {
    window.addEventListener('resize', this.setFooterClass)
    this.setFooterClass()
  },
  methods: {
    setFooterClass () {
      this.$nextTick(() => {
        let height = 154 - 25
        if (this.$refs.footerContainer) {
          height = this.$refs.footerContainer.offsetHeight
        }
        if (window.innerHeight - height - 25 > $('.gc-page__container').height()) {
          if (this.fixed) {
            this.footerWrapperClass = 'fixed-bottom'
          } else {
            this.footerWrapperClass = 'custom-footer-padding'
          }
        } else {
          this.footerWrapperClass = ' custom-footer-padding'
        }
      })
    },
    secondaryButtonClick () {
      this.$emit('clickSecondary')
    },
    clicked () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
</script>
