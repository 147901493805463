<template>
  <page-secondary :header="headerOptions" class="pc-content">
    <page-container>
        <page-content-text :description="content"/>
    </page-container>
  </page-secondary>
</template>

<script>
import BaseComponent from '../global/base/BaseComponent'
import ContentPageFactoryService from '../../../../services/ContentPageFactoryService'
import {mapMutations} from 'vuex'
import PageContentImage from '../../../global/pages/page-sub-components/PageContentImage'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import PageContentText from './page-components/PageContentText'
import PageSecondary from '../../../global/pages/PageSecondary'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'Details',
  extends: BaseComponent,
  components: {
    PageSecondary,
    PageContentImage,
    PageContainer,
    PageContentText
  },
  data: function () {
    return {
      pageType: this.$route.params.contentPage,
      content: '',
      pageClass: ['macroapp--page-content', 'macroapp--page-content-detail'],
      headerOptions: {
        isTopFixed: false,
        isTransparent: false,
        isFixed: false,
        show: true,
        left: 'previous',
        right: '',
        rightBlink: false,
        modifiers: ['small-title'],
        mainTitle: '',
        subTitle: ''
      }
    }
  },
  mounted () {
    const service = new ContentPageFactoryService()
    service.getPageList().then(data => {
      const link = service.findPageInList(this.pageType, data)
      this.setPageName(link.code)
      this.loadPageData(link.code)
    })
  },
  methods: {
    ...mapMutations({
      setPageName: 'pageStore/setPageName',
      unsetPageName: 'pageStore/unsetPageName'
    }),
    loadPageData (pageType) {
      // this.headerOptions.header.subTitle =
      const contentPageFactory = new ContentPageFactoryService()
      const page = contentPageFactory.getPage(pageType)
      const self = this

      page.setPageData().then(() => {
        this.headerOptions.mainTitle = page.pageData.page.title
        this.setPageTitle(page.pageData.page.title)
        const data = page.getDetails(self.params.rootItem, self.params.subItem)
        self.content = data.content
        self.headerOptions.subTitle = data.title
        self.hideLoading()
        pageReadyEvent()
      })
    }
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
  }
}
</script>
