<template>
  <div
    :class="componentClasses"
    :style="componentStyles"
    @click="emitClick()">
    <header-simple
      :header-text="headerText"
      :icon="'forward'" />
  </div>
</template>

<script>
import TextContent from '../../root/TextContent'
import HeaderSimple from '../headers/HeaderSimple'

export default {
  name: 'CardNavigation',
  components: {
    HeaderSimple,
    TextContent
  },
  props: {
    headerText: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-card-navigation': true
      }
    },
    componentStyles: function () {
      return {
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url("${this.image}"), #000000`
      }
    }
  },
  methods: {
    emitClick () {
      this.$emit('click')
    }
  }
}
</script>

<style scoped></style>
