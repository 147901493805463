<template>
  <div :class="componentClasses" @click="clicked()">
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16.5C0 7.40205 7.40205 0 16.5 0C25.5979 0 33 7.40205 33 16.5C33 25.5979 25.5979 33 16.5 33C7.40205 33 0 25.5979 0 16.5ZM1.37498 16.5C1.37498 24.84 8.16002 31.625 16.5 31.625C24.84 31.625 31.625 24.84 31.625 16.5C31.625 8.16002 24.84 1.37498 16.5 1.37498C8.16002 1.37498 1.37498 8.16002 1.37498 16.5ZM17.1876 15.8125H25.4376C25.8176 15.8125 26.1251 16.12 26.1251 16.5C26.1251 16.88 25.8176 17.1875 25.4376 17.1875H17.1876V25.4375C17.1876 25.8175 16.8801 26.125 16.5 26.125C16.12 26.125 15.8125 25.8175 15.8125 25.4375V17.1875H7.56252C7.18251 17.1875 6.875 16.88 6.875 16.5C6.875 16.12 7.18251 15.8125 7.56252 15.8125H15.8125V7.56246C15.8125 7.18245 16.12 6.87494 16.5 6.87494C16.8801 6.87494 17.1876 7.18245 17.1876 7.56246V15.8125Z" fill="black"/>
  </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconIncrease',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-increase': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
