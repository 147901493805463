<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_11547_4388)">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M3.86988 7.21393V7.32149C3.63138 7.23819 3.37589 7.19297 3.11041 7.19297C1.81036 7.19297 0.75 8.27738 0.75 9.60692V14.393C0.75 15.7225 1.81036 16.8069 3.11041 16.8069C3.37589 16.8069 3.63138 16.7617 3.86988 16.6784V16.786C3.86988 18.1156 4.93024 19.2 6.23029 19.2C7.53035 19.2 8.5907 18.1156 8.5907 16.786V12.5767H16.9093V16.786C16.9093 18.1156 17.9697 19.2 19.2697 19.2C20.5698 19.2 21.6301 18.1156 21.6301 16.786V16.6784C21.8686 16.7617 22.1241 16.8069 22.3896 16.8069C23.6896 16.8069 24.75 15.7225 24.75 14.393V9.60692C24.75 8.27738 23.6896 7.19297 22.3896 7.19297C22.1241 7.19297 21.8686 7.23819 21.6301 7.32149V7.21393C21.6301 5.88439 20.5698 4.79999 19.2697 4.79999C17.9697 4.79999 16.9093 5.88439 16.9093 7.21393V10.9395H8.5907V7.21393C8.5907 5.88439 7.53035 4.79999 6.23029 4.79999C4.93024 4.79999 3.86988 5.88439 3.86988 7.21393ZM6.23029 6.43718C5.81438 6.43718 5.47077 6.78859 5.47077 7.21393V9.59043L5.47082 9.60692V14.393L5.47077 14.4095V16.786C5.47077 17.2114 5.81438 17.5628 6.23029 17.5628C6.64621 17.5628 6.98982 17.2114 6.98982 16.786V7.21393C6.98982 6.78859 6.64621 6.43718 6.23029 6.43718ZM3.11041 8.83016C3.5233 8.83016 3.86494 9.17648 3.86988 9.59765V14.4022C3.86494 14.8234 3.5233 15.1697 3.11041 15.1697C2.6945 15.1697 2.35088 14.8183 2.35088 14.393V9.60692C2.35088 9.18157 2.6945 8.83016 3.11041 8.83016ZM18.5102 7.21393C18.5102 6.78859 18.8538 6.43718 19.2697 6.43718C19.6856 6.43718 20.0292 6.78859 20.0292 7.21393V9.59058L20.0292 9.60692V14.393L20.0292 14.4093V16.786C20.0292 17.2114 19.6856 17.5628 19.2697 17.5628C18.8538 17.5628 18.5102 17.2114 18.5102 16.786V7.21393ZM21.6301 9.59757V14.4023C21.6351 14.8235 21.9767 15.1697 22.3896 15.1697C22.8055 15.1697 23.1491 14.8183 23.1491 14.393V9.60692C23.1491 9.18157 22.8055 8.83016 22.3896 8.83016C21.9767 8.83016 21.6351 9.17644 21.6301 9.59757Z"
              fill="#9E9E9E"/>
      </g>
      <defs>
        <clipPath id="clip0_11547_4388">
          <rect width="24" height="24" fill="white" transform="translate(0.75)"/>
        </clipPath>
      </defs>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconWorkoutLinear',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-workout-linear': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
