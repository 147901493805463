export default class StripePaymentFailed extends Error {
  constructor (errorCode, message) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super()

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, StripePaymentFailed)
    }

    this.name = 'StripePaymentFailed'
    // Custom debugging information
    this.message = message
    this.errorCode = errorCode
  }
}
