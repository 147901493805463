<template>
  <div :class="componentClasses">
    <div class="gc-input-action-search__input-wrapper">
    <input
      class="gc-input-action-search__input"
      :maxlength="maxValue"
      :placeholder="placeholder"
      :disabled="disabled"
      v-model="comValue"
      ref="input"
    />
    <icon-search :size="'md1'"/>
    </div>
    <text-content
      v-if="errorMessage.length > 0"
      class="gc-input-action-search__error"
      :weight="'medium'"
      :size="'sm1'"
      :lineHeight="'multi'">
      {{ errorMessage }}
    </text-content>
  </div>
</template>

<script>
import TextContent from '../../root/TextContent'
import IconSearch from '../../root/icons/IconSearch'

export default {
  name: 'InputActionSearch',
  components: {
    TextContent,
    IconSearch
  },
  data () {
    return {
      comValue: 0,
      isValueChanged: false
    }
  },
  props: {
    placeholder: {
      default: '0'
    },
    errorMessage: {
      default: ''
    },
    disabled: {
      default: false
    },
    maxValue: {
      default: 1000
    },
    value: {
      default: ''
    },
    inputFontSize: {
      type: Number,
      default: 18
    },
    inputLineHeight: {
      type: Number,
      default: 1.5
    },
    inputFontWeight: {
      type: Number,
      default: 700
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-input-action-search': true,
        'gc-input-action-search--error': this.errorMessage.length > 0,
        'gc-input-action-search--disabled': this.disabled
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function () {
        this.comValue = this.value
      }
    },
    comValue: function (val) {
      this.isValueChanged = true
      this.comValue = val
      this.$emit('change', val)
      this.$emit('input', val)
    }
  },
  mounted () {
    this.setInputCssValues()
  },
  methods: {
    setInputCssValues () {
      const input = this.$refs.input
      input.style.fontSize = this.inputFontSize + 'px'
      input.style.fontWeight = this.inputFontWeight
      input.style.lineHeight = this.inputLineHeight
    }
  }
}
</script>
