<template>
  <div :style="componentStyles" class="pc-card-image" @click="clicked()">
    <icon-complete-filled v-if="completed" :size="'sm4'"></icon-complete-filled>
  </div>
</template>
<script>

import IconCompleteFilled from '../../../../root/icons/IconCompleteFilled'
export default {
  name: 'card-image',
  components: {
    IconCompleteFilled
  },
  props: {
    image: {
      default: ''
    },
    completed: {
      default: false
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    componentStyles: function () {
      return {
        background: `url("${this.image}")`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }
    }
  },
  methods: {
    clicked () {
      this.$emit('click')
    }
  }
}
</script>
