<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ['options', 'uom', 'tooltip'],
  mounted () {
    this.addPlugin({
      id: 'my-plugin',
      beforeInit: function (chart) {
        chart.data.labels.forEach(function (e, i, a) {
          if (/\n/.test(e)) {
            a[i] = e.split(/\n/)
          }
        })
      }
    })

    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, Object.assign(this.options, {
      tooltips: {
        enabled: false,
        mode: 'index',
        position: 'nearest',
        custom: this.customTooltips
      },
      animation: {
        onComplete: (e) => {
          this.$emit('axisDataRendered', {
            y: e.chart.scales['y-axis-0']
          })
        }
      }
    }))
  },
  beforeDestroy () {
    const tooltipElement = document.getElementById('chartjs-tooltip')

    if (tooltipElement) {
      tooltipElement.remove()
    }

    this.$data._chart.destroy()
  },
  methods: {
    customTooltips (tooltipModel) {
      // Tooltip Element
      let tooltipEl = document.getElementById('chartjs-tooltip')

      // Create element on first render
      if (!tooltipEl) {
        tooltipEl = document.createElement('div')
        tooltipEl.id = 'chartjs-tooltip'
        tooltipEl.innerHTML = '<table></table>'
        document.body.appendChild(tooltipEl)
      }

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0
        return
      }

      // Set caret Position
      tooltipEl.classList.remove('above', 'below', 'no-transform')
      if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign)
      } else {
        tooltipEl.classList.add('no-transform')
      }

      // Set Text
      if (tooltipModel.body) {
        const dataPoints = tooltipModel.dataPoints || []
        let innerHtml = '<thead>'

        if (this.isRTL()) {
          dataPoints.forEach((dataPoint) => {
            innerHtml += '<tr>' +
            '<th>' +
            '<div class="card-graph__head-section">' +
            '<p class="card-graph__title"><span>' + this.uom + '</span>' + dataPoint.value + '</p>' +
            '<p class="card-graph__sub-title">' + this.tooltip.labels[dataPoint.index] + '</p></div>' +
            '</th>' +
            '</tr>'
          })
        } else {
          dataPoints.forEach((dataPoint) => {
            innerHtml += '<tr>' +
            '<th>' +
            '<div class="card-graph__head-section">' +
            '<p class="card-graph__title">' + dataPoint.value + '<span>' + this.uom + '</span></p>' +
            '<p class="card-graph__sub-title">' + this.tooltip.labels[dataPoint.index] + '</p></div>' +
            '</th>' +
            '</tr>'
          })
        }

        const tableRoot = tooltipEl.querySelector('table')
        tableRoot.innerHTML = innerHtml
      }

      // `this` will be the overall tooltip
      const position = this.$data._chart.canvas.getBoundingClientRect()

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1
      tooltipEl.style.left = position.left + tooltipModel.caretX + 'px'
      tooltipEl.style.top = position.top + tooltipModel.caretY + 'px'
      tooltipEl.style.fontFamily = tooltipModel._fontFamily
      tooltipEl.style.fontSize = tooltipModel.fontSize
      tooltipEl.style.fontStyle = tooltipModel._fontStyle
      tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px'
    }
  }
}
</script>
