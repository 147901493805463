<template>
  <div :class="componentClasses">
    <div @click="focusInputOnClick" :class="inputClasses">
      <text-body-regular
        class="gc-input-measurement__text"
        :weight="'extra-bold'"
        :lineHeight="'multi'">
        {{ text }}
      </text-body-regular>
      <input
        class="gc-input-measurement__input"
        :maxlength="maxValue"
        :placeholder="placeholder"
        :disabled="disabled"
        v-model="comValue"
        type="text"
        inputmode="decimal"
        ref="input"
        :step="step"
      />
      <text-body-regular
        class="gc-input-measurement__unit"
        :weight="'extra-bold'"
        :lineHeight="'multi'">
        {{ unit }}
      </text-body-regular>
    </div>
    <text-body-extra-small
      v-if="errorMessage.length > 0"
      :lineheight="'multi'"
      class="gc-input-measurement__error-message"
    >
      {{ errorMessage }}
    </text-body-extra-small>
  </div>
</template>

<script>
import TextBodyRegular from '../typography/TextBodyRegular'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'

export default {
  name: 'InputMeasurement',
  components: {
    TextBodyExtraSmall,
    TextBodyRegular
  },
  data: function () {
    return {
      comValue: null,
      isValueChanged: false,
      lastValue: 0
    }
  },
  props: {
    errorMessage: {
      default: ''
    },
    minValue: {
      type: Number,
      default: 0
    },
    maxValue: {
      type: Number,
      default: 100
    },
    unit: {
      default: ''
    },
    text: {
      default: ''
    },
    placeholder: {
      default: '0'
    },
    disabled: {
      default: false
    },
    inputFontSize: {
      type: Number,
      default: 18
    },
    inputLineHeight: {
      type: Number,
      default: 1.5
    },
    inputFontWeight: {
      type: Number,
      default: 700
    },
    step: {
      default: 0.01
    },
    maxCharLength: { // set max char length (including decimal)
      type: Number,
      default: 2
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-input-measurement': true,
        'gc-input-measurement__error': this.errorMessage.length > 0,
        'gc-input-measurement__disabled': this.disabled
      }
    },
    inputClasses: function () {
      return {
        'gc-input-measurement__input-wrapper': true,
        'gc-input-measurement__input-wrapper-double-lined': this.text.length > 20,
        'gc-input-measurement__input-wrapper-triple-lined': this.text.length > 30
      }
    }
  },
  watch: {
    comValue: {
      immediate: true,
      handler: function () {
        if (this.comValue !== null) {
          if (this.comValue.toString().endsWith(',')) {
            this.comValue = this.comValue.toString().replace(',', '.')
          }
          this.isValueChanged = true
          let valueParts = this.comValue.toString().split('.')
          let decimalCount = 0
          if (1 in valueParts) {
            decimalCount = valueParts[1].length
          }
          if (this.comValue >= this.minValue && this.comValue <= this.maxValue && decimalCount <= 2) {
            this.$emit('change', this.comValue)
            this.$emit('input', this.comValue)
            this.lastValue = this.comValue
          } else {
            this.comValue = this.lastValue
          }
        }
      }
    }
  },
  mounted () {
    this.setInputCssValues()
  },
  methods: {
    setInputCssValues () {
      const input = this.$refs.input
      input.style.fontSize = this.inputFontSize + 'px'
      input.style.fontWeight = this.inputFontWeight
      input.style.lineHeight = this.inputLineHeight
    },
    focusInputOnClick () {
      this.$refs.input.focus()
    }
  }
}
</script>
