<template>
  <div :class="componentClasses" @click="clicked()">
   <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
    <path d="M13.2803 6.96966C13.5732 7.26255 13.5732 7.73744 13.2803 8.03033L9.53032 11.7803C9.23744 12.0732 8.76255 12.0732 8.46966 11.7803L4.71965 8.03033C4.42676 7.73744 4.42676 7.26255 4.71965 6.96966C5.01254 6.67678 5.48743 6.67678 5.78031 6.96966L8.99999 10.1893L12.2197 6.96966C12.5126 6.67678 12.9874 6.67678 13.2803 6.96966Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 -1.07324e-07C13.9705 -1.66596e-07 18 4.02954 18 9C18 13.9705 13.9705 18 9 18C4.02954 18 1.66596e-07 13.9705 1.07324e-07 9C4.80518e-08 4.02954 4.02954 -4.80518e-08 9 -1.07324e-07ZM9 1.50001C4.85796 1.50001 1.50001 4.85796 1.50001 9C1.50001 13.142 4.85796 16.5 9 16.5C13.142 16.5 16.5 13.142 16.5 9C16.5 4.85796 13.142 1.50001 9 1.50001Z" fill="black"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="18" height="18" fill="white" transform="translate(18 18) rotate(-180)"/>
    </clipPath>
    </defs>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconDownArrowFilled',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon--down-arrow-filled': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
