<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.4591 10.2429C11.862 10.2429 11.3779 10.7075 11.3779 11.2805V17.9624C11.3779 18.5355 11.862 19 12.4591 19C13.0562 19 13.5402 18.5354 13.5402 17.9623V11.2805C13.5402 10.7075 13.0562 10.2429 12.4591 10.2429Z" fill="black"/>
    <path d="M13.9191 7.45953C13.9191 8.26561 13.2656 8.91906 12.4595 8.91906C11.6535 8.91906 11 8.26561 11 7.45953C11 6.65345 11.6535 6 12.4595 6C13.2656 6 13.9191 6.65345 13.9191 7.45953Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 0C5.59067 0 0 5.59116 0 12.5C0 19.4093 5.59116 25 12.5 25C19.4093 25 25 19.4088 25 12.5C25 5.59067 19.4088 0 12.5 0ZM12.5 23.0469C6.67026 23.0469 1.95312 18.3293 1.95312 12.5C1.95312 6.67026 6.67065 1.95312 12.5 1.95312C18.3297 1.95312 23.0469 6.67065 23.0469 12.5C23.0469 18.3297 18.3293 23.0469 12.5 23.0469Z" fill="black"/>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconInfo',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-info': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
