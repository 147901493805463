<template>
  <div class="gc-input-custom-date">
    <div class="gc-input-custom-date__input gc-input-custom-date__input--day">
      <text-input
        class="gc-input-custom-date__input-day"
        placeholder="DD"
        type="tel"
        v-model.number="date.day"
        :maxlength="2"
        pattern="[0-9]*"
        @change="emitInput"></text-input>
    </div>
    <div class="gc-input-custom-date__input gc-input-custom-date__input--month">
      <input-single-select
        class="gc-input-custom-date__input-month"
        :options="months"
        :place-holder="'MM'"
        :sort="false"
        v-model.number="date.month"
        @change="emitInput"></input-single-select>
    </div>
    <div class="gc-input-custom-date__input gc-input-custom-date__input--year">
      <text-input
        class="gc-input-cutom-date__input-year"
        placeholder="YYYY"
        type="tel"
        v-model.number="date.year"
        :maxlength="4"
        pattern="[0-9]*"
        @change="emitInput"></text-input>
    </div>
  </div>
</template>

<script>
import InputSelect from './InputSelect'
import InputSingleSelect from './InputSingleSelect'
import TextInput from './TextInput'

export default {
  components: {
    TextInput,
    InputSelect,
    InputSingleSelect
  },
  name: 'InputCustomDate',
  props: {
    value: {
      default: ''
    },
    error: {
      default: ''
    }
  },
  data: function () {
    return {
      date: {
        day: '',
        month: '',
        year: ''
      },
      edited: {
        day: false,
        month: false,
        year: false
      },
      months: [],
      monthNames: ''
    }
  },
  watch: {
    date: {
      deep: true,
      handler: function () {
        if (this.date.day) {
          this.edited.day = true
        }
        if (this.date.month) {
          this.edited.month = true
        }
        if (this.date.year) {
          this.edited.year = true
        }
      }
    },
    value: function (val) {
      this.setDoB(val)
    }
  },
  beforeMount () {
    this.months = []
    let month = 1
    for (month = 1; month <= 12; month++) {
      this.months.push({
        id: month,
        text: this.$i18n.t('message["calendar.month-' + month + '-short"]')
      })
    }
    if (this.value) {
      this.setDoB(this.value)
    }
  },
  methods: {
    setDoB (value) {
      const date = value.split('-')
      this.date.day = date[2] || this.date.day

      this.date.month = (parseInt(date[1]) || parseInt(this.date.month))
      this.date.year = date[0] || this.date.year
    },
    emitInput () {
      if (this.edited.month && this.edited.day && this.edited.year) {
        let month = parseInt(this.date.month)
        if (month < 10) {
          month = '0' + month
        }
        let day = parseInt(this.date.day) || 1
        if (day < 10) {
          day = '0' + day
        }
        this.$emit('input', this.date.year + '-' + month + '-' + day)
      }
    }
  }
}
</script>
