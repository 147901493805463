<template>
  <transition name="fade">
    <div  class="gc-popup__footer" id="gc-popup-footer">
      <slot></slot>
    </div>
  </transition>

</template>

<script>
export default {
  name: 'PopupFooter'
}
</script>

<style scoped>

</style>
