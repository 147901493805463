import NutritionAPI from '../api/meal-plan/NutritionAPI'
import GenericRecipeService from './GenericRecipeService'

export default class GenericMealService {
  id = null
  name = ''
  mealTypes = []

  recipes = []
  _isLoaded = false
  constructor (id) {
    this.id = id
  }

  isLoaded () {
    return this._isLoaded
  }
  load () {
    const nutritionAPI = new NutritionAPI()
    return nutritionAPI.getMeal(this.id).then(res => res.data).then(data => {
      const meal = data.data

      this.name = meal.name
      this.nutrients = meal.nutrients
      this.recipes = []
      meal.recipes.forEach(recipeObj => {
        const recipe = new GenericRecipeService(recipeObj.id)

        recipe.setName(recipeObj.name)
        recipe.setImages(recipeObj.images)
        recipe.setVideos(recipeObj.videos)
        recipe.setPreparationTime(recipeObj.preparation_time)
        recipe.setIngredients(recipeObj.ingredients)
        recipe.setInstructions(recipeObj.instructions)
        recipe.setDescription(recipeObj.description)
        recipe.setMacroNutrients(recipeObj.nutrients)
        recipe.setCalories(recipeObj.nutrients.calories)

        this.recipes.push(recipe)
      })
      this._isLoaded = true
      return this
    })
  }
  getRecipe (id) {
    return this.recipes.find(recipe => recipe.getId() === id)
  }

  getRecipes () {
    return this.recipes
  }

  getId () {
    return this.id
  }

  getName () {
    return this.name
  }
}
