<template>
  <page-secondary :header="headerOptions" :desktop-side-bar="false">

    <page-container>
      <div class="text-center">
        <validation-observer ref="form" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submit)">

            <text-content v-if="status==='success'" :size="'sm4'" weight="extra-bold" class="pc-account-deletion__title">{{$t("message['account-deletion.popup-title']")}}</text-content>

            <div class="pc-account-deletion__description">
              <text-content  v-if="status==='init'" :size="'sm2'" :lineHeight="'multi'" v-html="pageData['page-content']"></text-content>
              <text-content  v-if="status==='success'" :size="'sm2'" :lineHeight="'multi'"  v-html="pageData['request-submitted']" ></text-content>
            </div>
            <div>
              <loading-buffer v-if="status==='loading'" :height="'75'"></loading-buffer>
              <validation-provider :name="$t('message[\'auth.email\']').toLowerCase()"
                                   :vid="'email'"
                                   rules="required|email"
                                   v-slot="{ errors }">
                <text-input
                  v-if="status==='init'"
                  class="pc-account-deletion__email-input"
                  v-model="formData.email"
                  :type="'email'"
                  :errorMessage="errors[0]"
                  :placeholder="$t('message[\'general.enter-email\']')"
                />
              </validation-provider>
            </div>
          <page-fixed-footer>
            <button-primary
              v-if="status==='init'"
              class="pc-account-deletion__submit-button"
              type="submit"
              :text="pageData['button-start']"
            />
            <button-primary
              v-if="status==='success'"
              class="pc-account-deletion__return-button"
              @click="closePopup()"
              :text="$t('message[\'general.understood\']')"
            />
          </page-fixed-footer>


          </form>
        </validation-observer>
      </div>


    </page-container>

  </page-secondary>

</template>

<script>
import AccountDeleteService from '@/services/AccountDeleteService'
import ButtonPrimary from './../../../global/buttons/ButtonPrimary.vue'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import TextContent from '../../../root/TextContent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import TextInput from '../../../global/inputs/TextInput'

import LoadingBuffer from '../global/widgets/LoadingBuffer'
import PageSecondary from '../../../global/pages/PageSecondary'
import PageFixedFooter from '../../../global/pages/PageFixedFooter'
import TextBodyRegular from '../../../global/typography/TextBodyRegular'
import TextBodyMedium from '../../../global/typography/TextBodyMedium'
import ProfileService from '@/services/ProfileService'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'PageAccountDeletion',
  components: {
    TextBodyMedium,
    TextBodyRegular,
    PageFixedFooter,
    PageSecondary,
    LoadingBuffer,
    PageContainer,
    ButtonPrimary,
    TextContent,
    ValidationProvider,
    ValidationObserver,
    TextInput
  },
  props: [],
  data: function () {
    return {
      headerOptions: {
        show: true,
        left: 'previous',
        right: '',
        rightBlink: false,
        modifiers: ['with-bg-color', 'small-title'],
        mainTitle: '',
        header: {
          mainTitle: '',
          subTitle: ''
        }
      },
      pageData: {},
      formData: {
        email: '',
        password: '',
        remember: true
      },
      errors: {
        email: []
      },
      status: 'loading'
    }
  },
  mounted () {
    this.setPageData()
    const profile = new ProfileService()
    this.formData.email = profile.getEmail()
  },
  methods: {
    async setPageData () {
      if (this.email) {
        this.formData.email = this.email
      }
      let service = new AccountDeleteService()
      await service.getAccountDeletePageData().then(data => {
        this.pageData = data.data.content
        this.headerOptions.mainTitle =this.pageData['page-title']
      }).catch(() => {
      }).finally(() => {
        this.hideLoading()
        this.status = 'init'
        pageReadyEvent()
      })
    },
    async submit () {
      this.status = 'loading'
      this.validateFormData()
      if (this.errors.email.length === 0) {
        let service = new AccountDeleteService()
        await service.sendAccountDeleteLinkEmail(this.formData.email).then(() => {
          this.logEvent('DataDelete.RequestConfirmed', {'email': this.formData.email})
          this.status = 'success'
        }).catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            if (
              typeof error.response.data.type !== 'undefined' &&
              error.response.data.type === 'logic'
            ) {
              this.error =
                error.response.data.errors[0] ||
                this.$i18n.t('message["general.try-again"]')
            } else {
              this.$refs.form.setErrors(error.response.data.errors)
            }
          } else {
            this.$store.commit('showServerErrorPopup')
          }
        }).finally(() => {

        })
      }
    },
    validateFormData () {
      const requiredText = this.$i18n.t('message["validation.required"]')
      if (!this.formData.email) {
        this.errors.email = [requiredText.replace(':attribute', this.$i18n.t('message[\'auth.email\']'))]
      }
    },
    closePopup () {
      this.showLoading()
      this.$router.replace({ path: '/dashboard' })
    }
  }
}
</script>
