<template>
  <!-- for mobile tab view -->
  <div class="pc-dashboard__main-featured-area pc-dashboard__main-featured-area--mobile" :class="featuredAreaClasses"
       v-if="!isDesktop">

    <div class="pc-dashboard__featured-image" :style="heroCardStyles">
      <nav-bar-header :options="headerOptions"/>
      <text-heading3
        class="pc-dashboard__main-featured-area--gretting-text"
        :weight="'extra-bold'"
        :line-height="'lh-md'"
      >
        {{ pageHeader }}
      </text-heading3>
    </div>

  </div>
  <!-- for desktop view -->
  <div v-else class="pc-dashboard__main-featured-area pc-dashboard__main-featured-area--desktop">

    <nav-bar-header :options="headerOptions"/>
    <div class="container pc-dashboard-notification-desktop-icon-wrap" v-if="isCommunityEnabled">
      <div class="pc-dashboard__nav-bar-header-bell-icon"
           :class="{ 'pc-dashboard__nav-bar-header-bell-icon--new-notification': unreadCommunityNotificationsExist }"
           @click="goToNotificationPage">
        <icon-bell-filled v-if="unreadCommunityNotificationsExist"/>
        <icon-bell v-else/>
      </div>
    </div>
    <div class="pc-dashboard__main-featured-area__content">
      <div class="pc-dashboard__featured-image" :style="heroCardStylesDesktop">
        <image-base v-if="!showTabImage" class="pc-dashboard__featured-image-bg--blur" :src="backgroundTabImage"/>
        <image-base v-if="!showTabImage" class="pc-dashboard__featured-image-on-top" :src="backgroundTabImage"/>
        <text-heading3
          class="pc-dashboard__main-featured-area--gretting-text"
          :weight="'extra-bold'"
          :line-height="'lh-md'"
        >
          {{ pageHeader }}
        </text-heading3>

      </div>

    </div>
  </div>
</template>
<script>
import NavBarHeader from './NavBarHeader'
import TextHeading3 from '../../../template-2/global/typography/TextHeading3'
import ImageBase from '../../../template-2/root/Image'
import ImageService from '../../../../../services/ImageService'
import {mapGetters} from 'vuex'
import DefineInclude from '../../../../../DefineInclude'
import IconBell from '../../../../root/icons/IconBell'
import IconBellFilled from '../../../../root/icons/IconBellFilled'
import DashboardService from '../../../../../services/DashboardService'

export default {
  name: 'ImageFeatured',
  components: {
    ImageBase,
    TextHeading3,
    NavBarHeader,
    IconBell,
    IconBellFilled
  },
  props: {
    hasNotifications: {
      default: false
    },
    pageHeader: {
      default: false
    }
  },
  data: function () {
    return {
      hasTabImage: true,
      backgroundImage: null,
      backgroundTabImage: null,
      headerOptions: {
        show: false,
        left: 'menu',
        right: 'pwa',
        helpContent: '',
        rightDisabled: false,
        modifiers: [],
        header: {
          mainTitle: '',
          subTitle: ''
        }
      },
      isCommunityEnabled: false
    }
  },
  computed: {
    ...mapGetters({
      unreadCommunityNotificationsExist: 'communityStore/isUnreadNotificationsExist'
    }),
    showTabImage: function () {
      return (!this.isDesktop || this.hasTabImage)
    },
    featuredAreaClasses: function () {
      return {
        'pc-dashboard__main-featured-area--with-notification-bar': this
          .hasNotifications
      }
    },
    heroCardStyles: function () {
      if (this.isTablet) {
        return {'background-image': `url("${this.backgroundTabImage}")`}
      }

      return {'background-image': `url("${this.backgroundImage}")`}
    },
    heroCardStylesDesktop: function () {
      if (this.showTabImage) {
        return {'background-image': `url("${this.backgroundTabImage}")`}
      }

      return {}
    }
  },
  beforeMount () {
    this.backgroundImage = this.getStoreImage('dashboard.background')
    let imageService = new ImageService()
    const tabImage = imageService.getDashboardTabImage()
    let defaultTabImages = [
      'https://d3nepnh0ml2m2t.cloudfront.net/images/presets/generic/2x.dashborad.jpg',
      'https://test-macroapp-toning-images.s3-ap-southeast-2.amazonaws.com/images/presets/generic/2x.dashborad.jpg',
      'https://frontend-macroactive-testing.s3-us-west-1.amazonaws.com/images/presets/generic/2x.dashborad.jpg'
    ]
    if (this.backgroundImage !== tabImage && !defaultTabImages.includes(tabImage)) {
      this.backgroundTabImage = tabImage
      this.hasTabImage = true
    } else {
      this.backgroundTabImage = this.backgroundImage
      this.hasTabImage = false
    }

    let dashboardService = new DashboardService()
    dashboardService.getUserConfig().then(() => {
      this.isCommunityEnabled = dashboardService.isCommunityEnabled()
    })
  },
  methods: {
    goToNotificationPage () {
      this.delayedRouterPush({
        path: DefineInclude.appUrlList.dashboard,
        query: {page: 'notifications', tab: 'other'}
      })
    }
  }
}
</script>
