<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_24350_14592)">
        <path d="M8.34715 13.4514L6.76887 11.3534L13.1502 6.55277L14.7285 8.65074L8.34715 13.4514Z" fill="white"/>
        <path d="M4.36738 8.80935C4.27675 8.68888 4.14555 8.61648 4.00689 8.59688C3.86823 8.57728 3.72211 8.61048 3.60164 8.70111L2.37735 9.62209C2.26059 9.70993 2.18505 9.83792 2.16461 9.98254C2.14416 10.1272 2.18125 10.2711 2.2691 10.3879L8.02578 18.0401C8.20712 18.281 8.55067 18.3295 8.79157 18.1483L10.0159 17.2273C10.2568 17.046 10.3054 16.7025 10.1241 16.4615L4.36738 8.80935Z" fill="white"/>
        <path d="M2.83704 11.7927L1.9755 12.4408C1.51506 12.7872 1.42228 13.4436 1.76865 13.904L4.50868 17.5463C4.67645 17.7693 4.92108 17.9137 5.19743 17.9527C5.47378 17.9918 5.74884 17.9209 5.97186 17.7531L6.83341 17.105L2.83704 11.7927Z" fill="white"/>
        <path d="M13.4723 1.95982C13.3816 1.83934 13.2504 1.76695 13.1118 1.74734C12.9731 1.72774 12.827 1.7609 12.7065 1.85157L11.4822 2.77259C11.2413 2.95385 11.1927 3.29739 11.374 3.53838L17.1307 11.1906C17.3119 11.4315 17.6555 11.4801 17.8964 11.2989L19.1207 10.3778C19.3617 10.1966 19.4102 9.85303 19.229 9.61205L13.4723 1.95982Z" fill="white"/>
        <path d="M1.31208 15.4564C1.15991 15.5708 1.06144 15.7377 1.03478 15.9263C1.00814 16.1148 1.05649 16.3024 1.17096 16.4546L1.89239 17.4136C2.12873 17.7276 2.57654 17.7909 2.89063 17.5547L3.61577 17.0091L2.03721 14.9108L1.31208 15.4564Z" fill="white"/>
        <path d="M16.9893 2.45368C16.8216 2.23062 16.5769 2.08628 16.3006 2.04722C16.0242 2.00815 15.7492 2.07902 15.5261 2.24684L14.6646 2.89497L18.661 8.20723L19.5225 7.5591C19.7456 7.39132 19.8899 7.14669 19.929 6.87034C19.968 6.59395 19.8972 6.31894 19.7293 6.09591L16.9893 2.45368Z" fill="white"/>
        <path d="M19.6056 2.58638C19.3693 2.2722 18.9214 2.20889 18.6073 2.44527L17.8822 2.99078L19.4608 5.08911L20.1859 4.54359C20.5 4.30727 20.5633 3.85949 20.327 3.54535L19.6056 2.58638Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_24350_14592">
          <rect width="20" height="20" fill="white" transform="translate(0.75)"/>
        </clipPath>
      </defs>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconWorkoutLinear',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-workout-linear': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
