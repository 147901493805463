import FoodDiaryService from '@/services/FoodDiaryService'
import DashBoardService from '@/services/DashboardService'

export default {

  mealLog: {
    default: function () {
      return {}
    },
    type: Object
  },
  computed: {
    remainingLogCalories: function () {
      const food = new FoodDiaryService()
      const total = food.getCurrentNutrientTotalsOfMeals(this.mealLog.logged_meals)
      const remaining = food.getRemainingNutrientTotalsOfMeals(this.mealLog.required, total)
      return (remaining.calories < 0) ? 0 : remaining.calories
    },
    remainingLogMacros: function () {
      const food = new FoodDiaryService()
      const total = food.getCurrentNutrientTotalsOfMeals(this.mealLog.logged_meals)
      const remaining = food.getRemainingNutrientTotalsOfMeals(this.mealLog.required, total)
      return          {
          fats: (remaining.macronutrients.fats < 0) ? 0 : remaining.macronutrients.fats,
          carbohydrates: (remaining.macronutrients.carbohydrates < 0) ? 0 : remaining.macronutrients.carbohydrates,
          proteins: (remaining.macronutrients.proteins < 0) ? 0 : remaining.macronutrients.proteins,
          net_carbohydrates: (remaining.macronutrients.net_carbohydrates < 0) ? 0 : remaining.macronutrients.net_carbohydrates,

      }
    },
    remainingMacros: function () {
      const food = new FoodDiaryService()
      const total = food.getCurrentNutrientTotalsOfMeals(this.mealLog.logged_meals)
      const remaining = food.getRemainingNutrientTotalsOfMeals(this.mealLog.required, total)
      if (this.mealsToAdd && this.mealsToAdd.length > 0) {
        this.mealsToAdd.forEach(meal => {
          meal.recipes.forEach(recipe => {
            remaining.calories = remaining.calories - recipe.calories
            remaining.macronutrients.fats = remaining.macronutrients.fats - recipe.macronutrients.fats
            remaining.macronutrients.carbohydrates = remaining.macronutrients.carbohydrates - recipe.macronutrients.carbohydrates
            remaining.macronutrients.proteins = remaining.macronutrients.proteins - recipe.macronutrients.proteins
            remaining.macronutrients.net_carbohydrates = remaining.macronutrients.net_carbohydrates - recipe.macronutrients.net_carbohydrates

          })
        })
      }
      if (this.mealsToRemove && this.mealsToRemove.length > 0) {
        this.mealsToRemove.forEach(meal => {
          meal.recipes.forEach(recipe => {
            remaining.calories = remaining.calories + recipe.calories
            remaining.macronutrients.fats = recipe.macronutrients.fats + remaining.macronutrients.fats
            remaining.macronutrients.carbohydrates = recipe.macronutrients.carbohydrates + remaining.macronutrients.carbohydrates
            remaining.macronutrients.proteins = recipe.macronutrients.proteins + remaining.macronutrients.proteins
            remaining.macronutrients.net_carbohydrates = recipe.macronutrients.net_carbohydrates + remaining.macronutrients.net_carbohydrates
          })
        })
      }
      const dashBoard = new DashBoardService()
      remaining.macronutrients.show_net_carbs =  dashBoard.isNetCarbsAsSeparateNutrient()
      return {
        macronutrients: this.mapMapMacronutrients(remaining.macronutrients),
        calories: this.mapMapCalories(remaining.calories)
      }
    },
    macroNutrients: function () {
      return this.mapMapMacronutrients(this.mealLog.required.macronutrients)
    },
    calories () {
      return this.mapMapCalories(this.mealLog.required.calories)
    },
    selectDayText () {
      const selectedOptionText = this.selectedOptionText
      return this.$i18n.t('message[\'food-diary.selected-option-message\']',
        {
          day: this.dayText,
          option: `<span style="font-weight: 700">${selectedOptionText}</span>`
        })
    }
  }
  ,
  methods: {
    mapMapCalories (calories, config = { showMinus: false }) {
      return [{
        unit: '',
        name: this.$i18n.tc('message[\'general.calories\']', calories),
        qut: (config.showMinus || calories > 0) ? calories : 0
      }]
    },
    mapMapMacronutrients (macros, config = { showMinus: false }) {
      let macroNutrients = []
      // to insert data to nutrition array
      const protein = macros.proteins
      macroNutrients.push({
        unit: this.$i18n.t('message[\'general.g\']'),
        name: this.$i18n.t('message[\'general.proteins\']'),
        qut: (config.showMinus || protein > 0) ? protein : 0
      })
      const fat = macros.fats
      macroNutrients.push({
        unit: this.$i18n.t('message[\'general.g\']'),
        name: this.$i18n.t('message[\'general.fats\']'),
        qut: (config.showMinus || fat > 0) ? fat : 0
      })
      const carbs = macros.carbohydrates
      macroNutrients.push({
        unit: this.$i18n.t('message[\'general.g\']'),
        name: this.$i18n.t('message[\'general.carbs\']'),
        qut: (config.showMinus || carbs > 0) ? carbs : 0
      })
      if (macros.show_net_carbs === true) {
        const netCarbs = macros.net_carbohydrates
        macroNutrients.push({
          unit: this.$i18n.t('message[\'general.g\']'),
          name: this.$i18n.t('message[\'general.carbs-net\']'),
          qut: (config.showMinus || netCarbs > 0) ? netCarbs : 0
        })
      }
      return macroNutrients
    },
  }
}
