<template>
  <div class="gc-form-dynemic">
    <!--   page wrapper -->
    <div>
      <div v-html="config.topHTML"></div>
      <div :class="config.pageContainerClass" class="gc-form-dynemic__profile-form profile-form">
        <validation-observer ref="form" v-slot="{ handleSubmit, invalid }">
          <form id="form-dynemic" @submit.prevent="handleSubmit(submitForm)" ref="formElement">
            <p class="gc-form-dynemic__description ma-form__description" v-if="config.description">
              {{ $t("message[" + JSON.stringify(config.description) + "]") }}
            </p>

            <div
              class="form-group ma-form"
              v-for="(input, inputID) in config.inputs"
              :key="'input-' + inputID"
            >
              <validation-provider
                :rules="input.validationRules"
                v-slot="{ errors, invalid, validated }"
                :vid="input.model"
                :name="$t('message[' + JSON.stringify(input.placeholder) + ']')"
              >
                <!-- inputs type: text  -->
                <div v-if="input.type === 'text'">
                  <text-input
                    :name="$t('message[' + JSON.stringify(input.name) + ']')"
                    :placeholder="$t('message[' + JSON.stringify(input.placeholder) + ']')"
                    v-model="formData[input.model]"
                    @change="emitChange"
                    :errorMessage='errors[0] ? errors[0] : formErrors[input.model] ? formErrors[input.model][0] : "" '
                  />
                </div>

                <!-- inputs type: email  -->
                <div v-if="input.type === 'email'">
                  <text-input
                    type="email"
                    :name="$t('message[' + JSON.stringify(input.name) + ']')"
                    :placeholder="$t('message[' + JSON.stringify(input.placeholder) + ']')"
                    v-model="formData[input.model]"
                    @change="emitChange"
                    :errorMessage='errors[0] ? errors[0] : formErrors[input.model] ? formErrors[input.model][0] : "" '
                  />
                </div>

                <!-- inputs type: date  -->
                <div v-if="input.type === 'date'">

                  <input-custom-date  v-model="formData[input.model]" :error="(invalid && validated) || formErrors[input.model]"/>
                  <!-- <custom-date
                    dir="ltr"
                    v-if="!isIOS"
                    v-model="formData[input.model]"
                    :error="(invalid && validated) || formErrors[input.model]"
                  /> -->
                  <text-content v-if="!isIOS" class="app-error-msg"
                    :size="'sm1'"
                    :lineHeight="'multi'"
                  >
                    {{
                      errors[0]
                        ? errors[0]
                        : formErrors[input.model]
                        ? formErrors[input.model][0]
                        : ""
                    }}
                  </text-content>
                </div>

                <div v-if="input.type === 'singleSelect'">
                  <input-single-select v-if="Array.isArray(dropdownOptions[input.optionsKey])  && dropdownOptions[input.optionsKey].length"
                                class="gc-form-dynemic__input-select"
                                :options="dropdownOptions[input.optionsKey]" :value="formData[input.model]"
                                :classes="'forms-control ma-form__input ma-form__input_country'"
                                :placeHolder="$t('message[' + JSON.stringify(input.placeholder) + ']')"
                                @input="formData[input.model] = $event, emitChange($event)"/>

                  <text-content class="app-error-msg"
                    :size="'sm1'"
                    :lineHeight="'multi'"
                  >
                    {{
                      errors[0]
                        ? errors[0]
                        : formErrors[input.model]
                        ? formErrors[input.model][0]
                        : ""
                    }}
                  </text-content>
                </div>

                <div v-if="input.type === 'selectOrInputElement'">
                  <SelectOrInputElement
                    :options="dropdownOptions[input.optionsKey]"
                    :name="$t('message[' + JSON.stringify(input.name) + ']')"
                    :value="formData[input.model]"
                    class="ma-form__input_region gc-form-dynemic__input-select"
                    :placeHolder="
                      $t('message[' + JSON.stringify(input.placeholder) + ']')
                    "
                    @input="
                      (formData[input.model] = $event), emitChange($event)
                    "
                  />

                  <text-content class="app-error-msg"
                    :size="'sm1'"
                    :lineHeight="'multi'"
                  >
                    {{
                      errors[0]
                        ? errors[0]
                        : formErrors[input.model]
                        ? formErrors[input.model][0]
                        : ""
                    }}
                  </text-content>
                </div>

                <div v-if="input.type === 'multiSelect'">
                  <select2
                    :multiple="true"
                    :options="dropdownOptions[input.optionsKey]"
                    :place-holder="
                      $t('message[' + JSON.stringify(input.placeholder) + ']')
                    "
                    @change="
                      (formData[input.model] = $event), emitChange($event)
                    "
                    v-model="formData[input.model]"
                    class="hello"
                  ></select2>

                  <text-content class="app-error-msg"
                    :size="'sm1'"
                    :lineHeight="'multi'"
                  >
                    {{
                      errors[0]
                        ? errors[0]
                        : formErrors[input.model]
                        ? formErrors[input.model][0]
                        : ""
                    }}
                  </text-content>
                </div>
                <div
                  v-if="
                    input.type === 'groupDislikes' &&
                    dropdownOptions[input.optionsKey]
                  "
                >
                  <dislikes-input
                    :multiple="true"
                    :options="dropdownOptions[input.optionsKey]"
                    :place-holder="
                      $t('message[' + JSON.stringify(input.placeholder) + ']')
                    "
                    @mounted="setPageFooterClass()"
                    :show-header="true"
                    @change="
                      (formData[input.model] = $event), emitChange($event)
                    "
                    v-model="formData[input.model]"
                  ></dislikes-input>
                  <text-content class="app-error-msg"
                    :size="'sm1'"
                    :lineHeight="'multi'"
                  >
                    {{
                      errors[0]
                        ? errors[0]
                        : formErrors[input.model]
                        ? formErrors[input.model][0]
                        : ""
                    }}
                  </text-content>
                </div>
              </validation-provider>
            </div>
            <page-footer
              ref="pageFooter"
              v-if="config.submitType === 'btnClick'"
              :validated="invalid">
              <button-primary  :form="'form-dynemic'"
                               :text="$t('message[\'profile.save\']')"
                               :disabled="invalid"/>
            </page-footer>
          </form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { isIOS } from 'mobile-device-detect'
import BaseComponent from '../../layout/template-1/global/base/BaseComponent'
import ButtonPrimary from '../buttons/ButtonPrimary'
import TextInput from '../inputs/TextInput'
import Select2 from '../inputs/Select2'
import CustomDate from '../../layout/template-1/global/widgets/FormCustomDate'
import InputCustomDate from '../inputs/InputDatePicker'
import SelectOrInputElement from '../inputs/InputSelectOrInputText'
import DislikesInput from '../../layout/template-1/global/widgets/forms/dietary/DislikesInput'
import PageFooter from '../pages/PageFixedFooter'
import TextContent from '../../root/TextContent'
import InputSelect from '../inputs/InputSelect'
import InputSingleSelect from '../inputs/InputSingleSelect'
export default {
  name: 'FormDynamic',
  props: ['config', 'options', 'errors'],
  extends: BaseComponent,
  components: {
    PageFooter,
    ValidationProvider,
    ValidationObserver,
    SelectOrInputElement,
    Select2,
    CustomDate,
    DislikesInput,
    TextInput,
    ButtonPrimary,
    TextContent,
    InputSelect,
    InputSingleSelect,
    InputCustomDate
  },
  data: function () {
    return {
      isIOS: true,
      pageClass: ['macroapp--page-profile'],
      headerOptions: {
        show: true,
        left: 'previous',
        right: '',
        rightDisabled: true,
        helpContent: '',
        modifiers: ['with-bg-color'],
        header: {
          mainTitle: '',
          subTitle: ''
        }
      },
      formData: {},
      submitData: {
        formName: '',
        formData: {}
      },
      dropdownOptions: {},
      formErrors: {}
    }
  },
  watch: {
    config: {
      handler () {
        this.init()
      },
      deep: true
    },
    errors: {
      handler () {
        this.setServerValidationErrors()
      },
      deep: true
    },
    options: {
      handler (val) {
        this.dropdownOptions = val
      },
      deep: true
    }
  },
  beforeMount () {
    this.init()
  },
  mounted () {
    this.setHeader(this.headerOptions)
    this.setPageClass()
    this.isIOS = isIOS
  },
  methods: {
    init () {
      this.headerOptions.header.subTitle = this.$t(
        'message["' + this.config.title + '"]'
      )
      this.submitData.formName = this.config.formName
      this.formData = this.config.defaultData
    },
    setServerValidationErrors () {
      this.formErrors = this.errors
    },
    setInputValue (value, model) {
      this.formData[model] = value
    },
    setPageFooterClass () {
      // to nothing
    },
    emitChange () {
      if (this.config.submitType === 'onChange') {
        this.submitForm()
      } else {
        this.submitData.formData = this.formData
        this.$emit('change', this.submitData)
      }
      this.setPageFooterClass()
    },
    submitForm () {
      this.submitData.formData = this.formData
      this.$emit('submit', this.submitData)
    },
    navigatePrevious () {
      this.$emit('navigatePrevious', this.config.formName)
    }
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
  }
}
</script>

<style scoped></style>
