<template>
  <page-secondary :header="headerOptions">
    <div class="pc-invite-a-friend">
      <button-invitation-notification @click="navigateToPageList" :text="$i18n.t('message[\'invitation.page-invite-a-friend.invite-button-text\']')" />
      <page-content-image :content-image="pageData.header_image" />
      <page-container class="pc-invite-a-friend__container">
        <div class="pc-invite-a-friend__title">
          <text-body-regular v-html="pageData.title_text" :lineHeight="'lh-lg'" :size="'sm4'" :weight="'extra-bold'" />
        </div>
        <div class="pc-invite-a-friend__description">
          <text-body-extra-small v-html="pageData.description" />
        </div>
      </page-container>
      <container-text-share :modalClasses="['pc-invite-a-friend__popup-share']" :url="shareURL">
        <page-footer :fixed-on-desktop="true">
          <button-primary class="pc-invite-a-friend__button-invite" :text="$i18n.t('message[\'invitation.page-invite-a-friend.share-link-button-text\']')" >
            <icon-invite-friend />
          </button-primary>
        </page-footer>
      </container-text-share>
    </div>
  </page-secondary>
</template>

<script>

import PageSecondary from '../../../global/pages/PageSecondary'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import TextBodyRegular from '../../../global/typography/TextBodyRegular'
import TextBodyExtraSmall from '../../../global/typography/TextBodyExtraSmall'
import PageFooter from '../../../global/pages/PageFixedFooter'
import ButtonPrimary from '../../../global/buttons/ButtonPrimary'
import UserEventsService from '../../../../services/UserEventsService'
import globalMixin from '../../../../../src/mixins/globalMixin'
import InvitationsService from '../../../../services/invitations/InvitationsService'
import ButtonInvitationNotification from './page-components/ButtonInvitationNotification'
import PageContentImage from '../../../global/pages/page-sub-components/PageContentImage'
import ContainerTextShare from '../../../global/containers/ContainerTextShare'
import IconInviteFriend from '../../../root/icons/IconInviteFriend'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'page-invite-a-friend',
  components: {
    ContainerTextShare,
    ButtonPrimary,
    PageFooter,
    TextBodyExtraSmall,
    TextBodyRegular,
    PageContainer,
    PageSecondary,
    globalMixin,
    InvitationsService,
    ButtonInvitationNotification,
    PageContentImage,

    IconInviteFriend
  },
  data: function () {
    return {
      headerOptions: {
        show: true,
        left: 'menu',
        right: '',
        rightBlink: false,
        modifiers: ['with-bg-color', 'small-title'],
        mainTitle: ''
      },
      pageData: [],
      shareURL: ''
    }
  },
  beforeMount () {
    this.showLoading()
    this.service = new InvitationsService()
    this.getPageData()
  },
  mounted () {
    UserEventsService.trackInviteAFriendPageVisit()
  },
  methods: {
    getPageData () {
      this.service.loadPageData().then(data => {
        this.pageData = data
        this.headerOptions.mainTitle = this.service.getPageHeader()
        this.setPageTitle(this.headerOptions.mainTitle)
        this.shareURL = this.service.getInvitationLink().url
        this.hideLoading()

      }).catch((error) => {
        if (error.response.status === 404) {
          this.$store.commit('showNotFoundPopupShow')
        }
      }).finally(() => {
        this.hideLoading()
        pageReadyEvent()
      })
    },
    navigateToPageList () {
      this.delayedRouterPush({
        path: this.$route.path,
        query: {
          page: 'list',
          type: this.type
        }
      })
    }
  }

}
</script>

<style scoped>

</style>
