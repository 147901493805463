<template>
  <action-meal-view-meal
    :state="state"
  v-on="$listeners"
  ></action-meal-view-meal>
</template>

<script>
import ActionMealViewMeal from '../../../global/actions/ActionMealViewMeal.vue'

export default {
  name: 'CardMealActionViewMeal',
  components: {
    ActionMealViewMeal
  },
  props:
    {
      state: {
        type: String,
        default: ''
      },
      showText: {
        type: Boolean,
        default: true
      },
      limitText: {
        type: Boolean,
        default: false

      }
    },
  computed: {
    componentClasses: function () {
      return {
        'gc-card-meal__action': true,
        'gc-card-meal__action-view-meal': true,
        'gc-card-meal__action-disabled': this.state === 'disabled',
        'gc-card-meal__action-onTap': this.state === 'onTap'
      }
    },
    text: function () {
      let text = this.$i18n.t('message["meal-plan.view-meal"]')
      if (this.limitText) {
        text = text.substring(0, 2) + '...'
      }
      return text
    }
  }
}
</script>
<style scoped>
</style>
