import IndexDBHelper from '@/services/index-db/IndexDBHelper'
import { macroSplit } from '@/helpers/nutrition/dineout'
export default class DineOutDB {

  constructor () {
    this.dbHelper = new IndexDBHelper('DineOutDB', 1, 'RestaurantsDB')
  }

  open () {
    return this.dbHelper.openDatabase()
  }

  hasData () {
    return this.open().then(() => {
      return this.dbHelper.getDataCount().then(data => {
        this.dbHelper.closeDatabase()
        return data !== 0
      })
    })
  }

  addData (value) {
    return this.open().then(() => {
      return this.dbHelper.addData(value).then(data => {
        this.dbHelper.closeDatabase()
        return data
      })
    })
  }

  addBulkData (valueArr) {
    return this.open().then(() => {
      return this.dbHelper.addBulkData(valueArr).then(data => {

        this.dbHelper.closeDatabase()
        return data
      })
    })
  }

  search (search) {
    const dbHelper = this.dbHelper

    return dbHelper.openDatabase()
      .then(() => {
        return dbHelper.searchByName(search)
      })
      .then(results => {
        dbHelper.closeDatabase()
        return results
      })
      .catch(error => {
        console.error('Error:', error)
      })
  }

  getRestaurants (macroSplitVal = null,strict= false) {
    const dbHelper = this.dbHelper
    return dbHelper.openDatabase()
      .then(() => {
        return dbHelper.openCursorOnStore((results, cursor) => {
          const res = cursor.value

          try {
            const mealsCount = res.meals.m.filter(meal => {
              return macroSplit(macroSplitVal, meal, strict, false, false, res.name)
            }).length
            const drinkCount = res.meals.d.filter(meal => {
              return macroSplit(macroSplitVal, meal, strict, false, false, res.name)
            }).length
            results.push({
              name: res.name,
              mealCount: mealsCount + drinkCount
            })
          } catch (e) {
            results.push({
              name: res.name,
              mealCount: res.meals.m.length + res.meals.d.length
            })
          }

          cursor.continue()
        })

      })
      .then(results => {
        dbHelper.closeDatabase()
        return results
      })
      .catch(error => {
        console.error('Error:', error)
      })
  }

  getAll () {

    const dbHelper = this.dbHelper

    return dbHelper.openDatabase()
      .then(() => {
        return dbHelper.getAllData()
      })
      .then(results => {
        dbHelper.closeDatabase()
        return results
      })
      .catch(error => {
        console.error('Error:', error)
      })
  }
}
