<template>
    <page :header="header" class="gc-page-member-connect" >
        <page-container>
            <div class="gc-page-member-connect__header">
                <text-heading-4
                        class="gc-page-member-connect__title text-center"
                        weight="extra-bold"
                        lineHeight="multi">
                    {{$t('message[\'meal-plan-pair.meal-plan-pairing\']')}}
                </text-heading-4>
            </div>
            <page-member-connected-section v-if="memberConnected"
                                           :member="member"
                                           @refresh="setPageDetails"></page-member-connected-section>
            <page-member-connect-input v-else
                                       :errorMessage="errorMessage"
                                       @change="setConnectCode"></page-member-connect-input>
            <popup-process-tracking ref="processSuccessModal"
                                   :processing-title="$t('message[\'member-connect.connecting\']')"
                                   :processed-title="$t('message[\'member-connect.connected\']')"></popup-process-tracking>
        </page-container>
        <page-footer ref="pageFooter" v-if="memberConnected" @click="nextComponent"></page-footer>
        <page-footer-multiple-buttons
                ref="pageMultipleFooter"
                v-else
                @click="connect"
                @clickSecondary="nextComponent"
                :text="$t('message[\'member-connect.connect\']')"
                :textSecondary="$t('message[\'member-connect.skip-for-now\']')"
                :validated="connectCode.length === 0"></page-footer-multiple-buttons>

    </page>
</template>

<script>
import FormBaseComponent from '../../layout/template-1/global/widgets/forms/FormBaseComponent'
import TextHeading4 from '../typography/TextHeading4'
import PageContainer from './page-sub-components/PageContainer'
import Page from './PageForm'
import PageFooter from './page-sub-components/PageFormFooter'
import PageFooterMultipleButtons from './page-sub-components/PageFooterMultipleButtons'
import Member from '../../../services/meal-plan-pair/Member'
import ConnectionService from '../../../services/meal-plan-pair/ConnectionService'
import PairService from '../../../services/meal-plan-pair/PairService'
import PageMemberConnectInput from './page-member-connect-sub-components/PageMemberConnectInput'
import PageMemberConnectedSection from './page-member-connect-sub-components/PageMemberConnectedSection'
import PopupProcessTracking from '../popups/PopupProcessTracking'
import MealPlanService from '../../../services/MealPlanService'

export default {
  name: 'PageMemberConnect',
  extends: FormBaseComponent,
  components: {
    PageMemberConnectInput,
    Page,
    PageContainer,
    TextHeading4,
    PageFooterMultipleButtons,
    PageMemberConnectedSection,
    PageFooter,
    PopupProcessTracking
  },
  data: function () {
    return {
      header: {
        isTransparent: false,
        isFixed: false,
        show: false,
        left: '',
        right: '',
        rightBlink: false,
        modifiers: [],
        mainTitle: '',
        subTitle: ''
      },
      member: new Member(),
      memberConnected: false,
      connectionService: new ConnectionService(),
      pairService: new PairService(),
      mealPlanService: new MealPlanService(),
      parentPlan: null,
      connectCode: '',
      errorMessage: ''
    }
  },
  mounted () {
    this.setPageDetails()
    this.setFooterClasses()
  },
  methods: {
    async setPageDetails () {
      this.showLoading()

      // initiating connection service and pair service (which will load current state)
      await Promise.all([this.connectionService.init(), this.pairService.init()])
        .catch(() => {
          this.hideLoading()
        })

      // getting whether partner is connected
      this.memberConnected = this.connectionService.isPartnerConnected()

      // getting other partner details (should be the owner)
      this.member = this.connectionService.getPartner()

      // getting other partner details (should be the owner)
      this.parentPlan = this.pairService.getPlanCategory()
      this.setFooterClasses()
      this.hideLoading()
    },
    setConnectCode (connectCode) {
      this.connectCode = connectCode
    },
    async connect () {
      this.$refs.processSuccessModal.showProcessing()
      this.errorMessage = ''

      // try disconnecting before connecting
      try {
        await this.connectionService.disconnect()
      } catch (e) {
        // ignore
      }

      this.connectionService.connect(this.connectCode)
        .then(async (res) => {
          if (!res.success) {
            // if connect code failed show error
            this.errorMessage = this.$t('message[\'member-connect.invalid-connect-code\']')
            this.$refs.processSuccessModal.hide()
          } else {
            // re initiate pair details
            await this.pairService.init({forceLoad: true})

            // re initiating connection details
            await this.connectionService.init({forceLoad: true})
              .finally(() => {
                this.setPageDetails()

                // reset meal plan details
                this.mealPlanService.reset()

                this.$refs.processSuccessModal.showProcessed()
              })
          }
        })
        .catch(() => {
          this.errorMessage = this.$t('message[\'member-connect.invalid-connect-code\']')
          this.$refs.processSuccessModal.hide()
        })
    },
    nextComponent () {
      let payload = {}
      if (this.memberConnected && this.parentPlan) {
        payload = { key: 'nutritional_category', value: this.parentPlan.id }
      }
      this.$emit('submit', payload)
    },
    setFooterClasses () {
      if (this.$refs.pagefooter) {
        this.$refs.pagefooter.setFooterClass()
      }

      if (this.$refs.pageMultipleFooter) {
        this.$refs.pageMultipleFooter.setFooterClass()
      }
    }
  }
}
</script>
