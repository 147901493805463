import { mapGetters, mapMutations, mapActions } from 'vuex'
import moment from 'moment'
import UserEventService from '../../../../services/UserEventsService'
import DashBoardService from '../../../../services/DashboardService'

export default {
  data () {
    return {
      timeout: false,
      firstRecord: {},
      history: [],
      prediction: {},
      lastHistory: {},
      nextPeriodPredictionDate: '',
      nextPeriodPrediction: '',
      isLoading: true,
      isLoadingBuffer: true,
      hasLatestLog: true,
      isInsidePredictionScope: false,
      cycles: 0,
      periodStartDate: '',
      hasFullLogged: true
    }
  },

  computed: {
    formattedDate () {
      let additionalDate = moment(this.prediction.getNextPeriod()).add(this.firstRecord.getCycleLength() * (this.cycles), 'days').add(this.firstRecord.getPeriodLength(), 'days')
      let predictionDate = moment(this.prediction.getNextPeriod()).add(this.firstRecord.getCycleLength() * (this.cycles), 'days')
      if (additionalDate.month() === predictionDate.month()) {
        return predictionDate.format('MMM Do') + '-' + additionalDate.format('Do, YYYY')
      } else {
        return predictionDate.format('MMM Do') + '-' + additionalDate.format('MMM Do, YYYY')
      }
    }
  },
  methods: {
    ...mapGetters({
      service: 'periodTrackerStore/getService',
      isEntriesEditable: 'fitnessDiaryStore/getService',
      getFoodDiaryPopupStatus: 'featurePopupStore/visible',
      getIsPopupShown: 'featurePopupStore/isPopupShown',
      getFirstRecord: 'periodTrackerStore/getFirstRecord',
      getPeriodLogs: 'periodTrackerStore/getPeriodLogs',
      getPrediction: 'periodTrackerStore/getPrediction',
      getPageInitStatus: 'periodTrackerStore/getPageInitStatus'
    }),
    ...mapMutations({
      showSubscriptionExpiredPopup: 'showSubscriptionExpiredPopup',
      changePageInitStatus: 'periodTrackerStore/changePageInitStatus'
    }),

    ...mapActions({
      initPage: 'periodTrackerStore/pageInit'
    }),

    setPeriodTrackerFeaturePopupVisibility () {
      if (!this.getIsPopupShown()) {
        UserEventService.periodTrackerFeaturePopupView()
      }
    },
    navigateHome () {
      this.showLoading()
      this.delayedRouterPush({
        path: '/dashboard',
        query: { page: 'period-tracker' }
      })
    },
    navigateEdit () {
      this.showLoading()
      this.delayedRouterPush({
        path: '/dashboard',
        query: { page: 'period-tracker', edit: 1 }
      })
    },
    clickAddNew () {
      let service = new DashBoardService()
      if (!service.isEntriesEditable()) {
        this.showSubscriptionExpiredPopup('')
      } else if (this.firstRecord.getId()) {
        this.navigateEdit()
      } else {
        this.navigateHome()
      }
    },
    async pageInit () {
      // loading details
      if (!this.getPageInitStatus()) {
        this.initPage().then(() => {
          this.firstRecord = this.getFirstRecord()
          this.history = this.getPeriodLogs().slice()
          this.lastHistory = this.history.pop()
          this.prediction = this.getPrediction()
          this.setPredictionContent()
        })
          .finally(() => {
            this.isLoading = false
            this.isLoadingBuffer = false
          }).catch(() => {
            this.changePageInitStatus(false)
            this.isLoadingBuffer = false
          })
      } else {
        this.firstRecord = this.getFirstRecord()
        this.history = this.getPeriodLogs().slice()
        this.lastHistory = this.history.pop()
        this.prediction = this.getPrediction()
        this.setPredictionContent()
        this.isLoading = false
        this.isLoadingBuffer = false
      }
      setTimeout(() => {
        if (!this.getPageInitStatus) {
          this.timeout = true
        }
      }, 5000)
    },

    setPredictionContent () {
      try {
        let nextPeriodDate = this._getNextPeriodDate()
        let today = this._getTodayDate()
        let nextPeriodDiff = this._getDateDiffernce(today, nextPeriodDate)
        if (nextPeriodDate < today) {
          this.hasLatestLog = false
          this.cycles = this._getCycleCount()
          let additionalDate = this._getAdditionalDate()
          if (today >= additionalDate) {
            let nextPredictionPeriodDate = this._getNextPredictionPeriodDate()
            this.nextPeriodPrediction = this._getDateDiffernce(today, nextPredictionPeriodDate)
          } else {
            this.periodStartDate = this._getPeriodStartDate()
            this.isInsidePredictionScope = true
            this.nextPeriodPrediction = this._getDateDiffernce(today, additionalDate)
          }
        } else {
          let lastPredictionEndDate = this._getLastPredictionEndDate()
          if (today < lastPredictionEndDate) {
            this.periodStartDate = this._getLastPredictionStartDate()
            this.isInsidePredictionScope = true
            this.hasFullLogged = false
            this.nextPeriodPrediction = this._getDateDiffernce(today, lastPredictionEndDate)
          } else {
            this.nextPeriodPrediction = nextPeriodDiff
          }
        }
      } catch (e) {
        console.log(e)
      }
    },

    _getNextPeriodDate () {
      return moment(this.lastHistory.getActualDate()).add(this.firstRecord.getCycleLength(), 'days').startOf('day')
    },

    _getTodayDate () {
      return moment(new Date())
    },

    _getDateDiffernce (from, to) {
      return to.diff(from, 'days')
    },

    _getCycleCount () {
      return Math.floor(this._getDateDiffernce(moment(this.lastHistory.getActualDate()).startOf('day'), this._getTodayDate()) / this.firstRecord.getCycleLength())
    },

    _getAdditionalDate () {
      return moment(this.prediction.getNextPeriod()).add(this.firstRecord.getCycleLength() * (this.cycles - 1), 'days').add(this.firstRecord.getPeriodLength(), 'days').startOf('day')
    },

    _getNextPredictionPeriodDate () {
      return moment(this.prediction.getNextPeriod()).add(this.firstRecord.getCycleLength() * (this.cycles - 1), 'days').add(this.firstRecord.getCycleLength(), 'days')
    },

    _getPeriodStartDate () {
      return moment(this.prediction.getNextPeriod()).add(this.firstRecord.getCycleLength() * (this.cycles - 1), 'days').format('MMM Do, YYYY')
    },

    _getLastPredictionEndDate () {
      return moment(this.prediction.getNextPeriod()).subtract(this.firstRecord.getCycleLength(), 'days').add(this.firstRecord.getPeriodLength(), 'days').startOf('day')
    },

    _getLastPredictionStartDate () {
      return moment(this.prediction.getNextPeriod()).subtract(this.firstRecord.getCycleLength(), 'days').format('MMM Do, YYYY')
    }

  }
}
