import DefineInclude from '../../DefineInclude'
import AuthService from '../AuthService'
import store from '../../store'
import * as Sentry from '@sentry/browser'

/**
 * Base Service API Class that can be use to connect the apis
 */
export default class APIErrorHandler {
  static handleError (response) {
    let res
    let sentryEventID
    if (!response ||
      typeof response.status === 'undefined' ||
      (!response.config.url.includes(DefineInclude.apiUrl) && !response.config.url.includes(DefineInclude.apiV2Url))) {
      return response
    }
    switch (response.status) {
      case 401:
        res = APIErrorHandler.handleUnauthorizedError(response)
        break
      case 422:
        if (response.data.type === 'logic') {
          sentryEventID = APIErrorHandler.logSentry(response)
          res = APIErrorHandler.handleInternalServerError(response, sentryEventID)
        } else {
          res = response
        }
        break
      case 404:
        res = response
        break
      case 403:
        res = response
        break
      default:
        sentryEventID = APIErrorHandler.logSentry(response)
        res = APIErrorHandler.handleInternalServerError(response, sentryEventID)
    }
    return res
  }

  static handleUnauthorizedError (response) {
    const auth = new AuthService()
    auth.removeAccessToken()
    const apiUrl = response.config.url
    if(apiUrl.includes(DefineInclude.memberProgressApiUrl)){
      return response.data
    }
    // check is api url is integrations/customer_token
    if (!apiUrl.includes('integrations/customer_token')) {
      location.reload()
    } else {
      auth.logOut()
    }
    return response.data
  }

  static handleNoConnection () {
    store.commit('showNoConnectionErrorPopup')
  }

  static handleInternalServerError (response, eventID) {
    const apiUrl = response.config.url
    let isValidState = response.status >= 500 && response.status < 600
    if (response && !apiUrl.includes('auth/forgot-password') && isValidState) {
      APIErrorHandler.showErrorPopup(eventID)
    }

    return response
  }

  static showErrorPopup (eventID) {
    store.commit('showServerErrorPopup')
    store.commit('setServerErrorID', eventID)
  }

  static logSentry (response, name = 'API errors') {
    const userID = sessionStorage.getItem('app-user-id') || 0
    return Sentry.captureException({
      response: {
        status: JSON.stringify(response.status),
        data: JSON.stringify(response.data),
        url: JSON.stringify(response.config.url)
      },
      userID: userID,
      name: name
    })
  }
}
