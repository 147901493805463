const MoodTracker = {
  AWFUL: {
    ID: 'AWFUL',
    TYPE: 1
  },
  CRAP: {
    ID: 'CRAP',
    TYPE: 2
  },
  MEH: {
    ID: 'MEH',
    TYPE: 3
  },
  GOOD: {
    ID: 'GOOD',
    TYPE: 4
  },
  GREAT: {
    ID: 'GREAT',
    TYPE: 5
  }

}

export default MoodTracker
