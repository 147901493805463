<template>
    <div :class="componentClass">
      <image-base v-if="src&&showImage"
                  :src="src"
                  border-radius="sm1"
                  @error="error"
                  v-on="$listeners"
                  v-bind="$attrs"/>
      <icon-camera :size="'sm4'" v-else/>
    </div>
</template>

<script>
import IconCamera from '../../../../root/icons/IconCamera'
import ImageBase from '../../../../root/Image'
export default {
  name: 'ImageTile',
  components: { ImageBase, IconCamera },
  props: {
    src: {
      default: ''
    }
  },
  data: function () {
    return {
      showImage: true
    }
  },
  watch: {
    src: function () {
      this.showImage = true
    }
  },
  computed: {
    componentClass: function () {
      return {
        'pc-image-tile': true,
        'pc-image-tile__empty': !(this.src && this.showImage)
      }
    }
  },
  methods: {
    error (event) {
      this.showImage = false
      this.$emit('error', event)
    }
  }
}
</script>

<style scoped>

</style>
