import InjuryAPI from './api/common/InjuryAPI'

export default class InjuryService {
  injuries = {}

  constructor () {
    if (!InjuryService.instance) {
      InjuryService.instance = this
    }
    return InjuryService.instance
  }

  setInjuries () {
    const api = new InjuryAPI()
    return new Promise((resolve, reject) => {
      api.getList().then(res => {
        const injuryArr = []
        res.data.injuries.forEach(function (value, index, array) {
          injuryArr[value.id] = value.value
        })
        this.injuries = Object.assign({}, injuryArr)
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getInjury (id) {
    return this.injuries[id]
  }

  getInjuries () {
    return this.injuries
  }
}
