export function replaceWordsWithNumbers (numberStr, langNumbers) {
  let words = numberStr.split(' ')
  for (const key in langNumbers) {
    if (words.includes(key)) {
      words = words.map(word => {
        return (word === key) ? langNumbers[key] : word
      })
      numberStr = words.join(' ')
      return numberStr
    }
  }
  return numberStr
}
