<template>
  <div>
    <page-container
      class="pc-container-meal-plan-meal__custom-meal-top-container custom-meal pc-container__remaining-ntr">
      <text-body-extra-small class="pc-food-dairy-log-meals__remaining-ntr">
        {{ $t('message[\'food-diary.remaining-for-the-day\']') }}
      </text-body-extra-small>

      <nutrition-details-bar
        :calories="remainingMacros.calories"
        :macro-nutrients="remainingMacros.macronutrients"
      />
    </page-container>
    <transition>
      <navigation-meals-add
        active-tab="new-meal"
        @change-tab="changeTab($event)"
      />
    </transition>
    <page-container>
      <!-- Custom Meal Form  -->
      <validation-observer
        v-slot="{ handleSubmit, invalid }"
        ref="form"
        tag="div"
      >
        <form
          id="custom-log-form"
          class="pc-container-meal-plan-meal-log-form"
          @submit.prevent="handleSubmit(submitForm)"
        >
          <div class="pc-container-meal-plan-meal-log-form__image-input-wrapper">
            <food-diary-image-input
              @removed="removedImage($event)"
              @uploaded="uploadedImage($event)"
              @error="setImageUploadError($event)"
            />
            <text-body-extra-small
              v-if="imageError[0]"
              :line-height="'multi'"
            >
              <p class="pc-container-meal-plan-meal-log-form__image-error-message">
                {{ imageError[0] }}
              </p>
            </text-body-extra-small>
          </div>

          <validation-provider
            v-slot="{ errors }"
            class="pc-container-meal-plan-meal-log-form__input-wrapper"
            :name="$t('message[\'food-diary.enter-food-name\']')"
            tag="div"
            vid="name"
            rules="required|max:200"
          >
            <text-input
              v-model="formData.name"
              :placeholder="[[$t('message[\'food-diary.enter-food-name\']')]]"
              :error-message="errors[0]"
              class="pc-container-meal-plan-meal-log-form__input"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            class="pc-container-meal-plan-meal-log-form__input-wrapper"
            :name="firstLetterCaps($t('message[\'general.proteins\']'))"
            tag="div"
            vid="macronutrients.proteins"
            rules="required|min_value:0|between:0,1000"
          >
            <input-measurement
              v-model="formData.macronutrients.proteins"
              :max-value="9999"
              :step="0.01"
              :text="$t('message[\'general.proteins\']')"
              :unit="$t('message[\'general.g\']')"
              :error-message="errors[0]"
              class="pc-container-meal-plan-meal-log-form__input"
              @change="setProteinsValue"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            class="pc-container-meal-plan-meal-log-form__input-wrapper"
            :name="firstLetterCaps($t('message[\'general.fats\']'))"
            tag="div"
            vid="macronutrients.fats"
            rules="required|min_value:0|between:0,1000"
          >
            <input-measurement
              v-model="formData.macronutrients.fats"
              :max-value="9999"
              :step="0.01"
              :text="$t('message[\'general.fats\']')"
              :unit="$t('message[\'general.g\']')"
              :error-message="errors[0]"
              class="pc-container-meal-plan-meal-log-form__input"
              @change="setFatsValue"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            class="pc-container-meal-plan-meal-log-form__input-wrapper"
            :name="firstLetterCaps($t('message[\'general.carbs\']'))"
            tag="div"
            vid="macronutrients.carbohydrates"
            rules="required|min_value:0|between:0,1000"
          >
            <input-measurement
              v-model="formData.macronutrients.carbohydrates"
              :max-value="9999"
              :step="0.01"
              :text="firstLetterCaps($t('message[\'general.carbs\']'))"
              :unit="$t('message[\'general.g\']')"
              :error-message="errors[0]"
              class="pc-container-meal-plan-meal-log-form__input"
              @change="setCarbohydratesValue"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            class="pc-container-meal-plan-meal-log-form__input-wrapper"
            :name="firstLetterCaps($t('message[\'general.carbs-net\']'))"
            tag="div"
            vid="macronutrients.net_carbohydrates"
            rules="min_value:0|between:0,1000"
          >
            <input-measurement
              v-model="formData.macronutrients.net_carbohydrates"
              :max-value="9999"
              :step="0.01"
              :text="firstLetterCaps($t('message[\'general.carbs-net\']')) + ' ' + '(' + $t('message[\'general.form.optional\']') + ')'"
              :unit="$t('message[\'general.g\']')"
              :error-message="errors[0]"
              class="pc-container-meal-plan-meal-log-form__input"
              @change="setNetCarbohydratesValue"
            />
          </validation-provider>

          <validation-provider
            v-show="!hideCalories"
            v-slot="{ errors }"
            :name="firstLetterCaps($tc('message[\'general.calories\']',2))"
            tag="div"
            vid="calories"
            rules="required|min_value:0|between:0,10000"
          >
            <input-measurement
              v-model="formData.calories"
              :max-value="99999"
              :step="0.01"
              :text="firstLetterCaps($tc('message[\'general.calories\']', 2))"
              :unit="$t('message[\'food-diary.unit-cal\']')"
              :error-message="errors[0]"
              class="pc-container-meal-plan-meal-log-form__input"
              name="calories"
              @change="setCaloriesValue"
            />
          </validation-provider>

          <div class="pc-container-meal-plan-meal-log-form__saved-area">
            <input-check-box-squared
              :id="1"
              v-model="saved"
            />
            <text-body-small line-height="lh-lg">
              {{ $t('message["food-diary.save-this-meal"]') }}
            </text-body-small>
          </div>

          <p
            v-if="error"
            class="app-error-msg"
          >
            {{ error }}
          </p>
          <page-fixed-footer v-if="isActiveTab">
            <button-primary
              :text="state === 'submitting' ? '' : $tc('message[\'food-diary.log-meal-submit\']', 0, { count: 0 })"
              :disabled="state === 'submitting'||invalid"
              form="custom-log-form"
              type="submit"
            >
              <loading-buffer-custom v-if="state === 'submitting'"/>
            </button-primary>
          </page-fixed-footer>
        </form>
      </validation-observer>
    </page-container>
  </div>
</template>
<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import FoodDiaryService from '../../../../../services/FoodDiaryService'
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import ButtonSecondaryBackward from '../../../../global/buttons/ButtonSecondaryBackward'
import FoodDiaryDaySlider from './FoodDiaryDaySlider'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import TextBodySmall from '../../../../global/typography/TextBodySmall'
import TextInput from '../../../../global/inputs/TextInput'
import InputMeasurement from '../../../../global/inputs/InputMeasurement'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import logCustomMealMixin from '../../../../../mixins/component-mixins/page/food-diary/logCustomMealMixin'
import FoodDiaryImageInput from './FoodDiaryImageInput'
import PageContainer from '../../../../global/pages/page-sub-components/PageContainer'
import NutritionDetailsBar from '../../../../global/bars/BarNutritionDetails'
import NavigationMealsAdd from './NavigationMealsAdd'
import PageFixedFooter from '../../../../global/pages/PageFixedFooter'
import InputCheckBoxSquared from '../../../../global/inputs/InputCheckBoxSquared'
import GridRow from '../../../../global/grid/GridRow'
import GridCol from '../../../../global/grid/GridCol'
import LoadingBufferCustom from '../../global/widgets/LoadingBufferCustom'
import {getVisibilityOfCaloriesInNutrition} from '@/includes/TemplateSettings'

export default {
  name: 'ContainerCustomMealLog',
  components: {
    LoadingBufferCustom,
    GridCol,
    GridRow,
    InputCheckBoxSquared,
    PageFixedFooter,
    NavigationMealsAdd,
    PageContainer,
    NutritionDetailsBar,
    ButtonPrimary,
    TextBodySmall,
    TextBodyExtraSmall,
    ButtonSecondaryBackward,
    FoodDiaryDaySlider,
    LoadingBuffer,
    FoodDiaryImageInput,
    ValidationProvider,
    ValidationObserver,
    TextInput,
    InputMeasurement
  },
  mixins: [logCustomMealMixin],
  props: {
    isActiveTab: {
      type: Boolean,
      required: true
    }
  },
  data: function () {
    return {
      hideCalories: getVisibilityOfCaloriesInNutrition(),
      saved: [1]
    }
  },
  computed: {

    macroNutrients: function () {
      let macroNutrients = []
      // to insert data to nutrition array
      const protein = this.mealLog.required.macronutrients.proteins
      macroNutrients.push({
        unit: this.$i18n.t('message[\'general.g\']'),
        name: this.$i18n.t('message[\'general.proteins\']'),
        qut: protein
      })
      const fat = this.mealLog.required.macronutrients.fats
      macroNutrients.push({
        unit: this.$i18n.t('message[\'general.g\']'),
        name: this.$i18n.t('message[\'general.fats\']'),
        qut: fat
      })
      const carbs = this.mealLog.required.macronutrients.carbohydrates
      macroNutrients.push({
        unit: this.$i18n.t('message[\'general.g\']'),
        name: this.$i18n.t('message[\'general.carbs\']'),
        qut: carbs
      })
      if (this.mealLog.required.macronutrients.show_net_carbs === true) {
        const netCarbs = this.mealLog.required.macronutrients.net_carbohydrates
        macroNutrients.push({
          unit: this.$i18n.t('message[\'general.g\']'),
          name: this.$i18n.t('message[\'general.carbs-net\']'),
          qut: netCarbs
        })
      }

      return macroNutrients
    },
    calories () {
      return [{
        unit: '',
        name: this.$i18n.tc('message[\'general.calories\']', this.mealLog.required.calories),
        qut: this.mealLog.required.calories
      }]
    }
  },
  watch: {
    isActiveTab: function () {
      this.days = []
      this.setMealPlanIDAndDays()
    },
    saved: function () {
      this.formData.saved = this.saved.includes(1)
    }
  },
  mounted () {
    if (getVisibilityOfCaloriesInNutrition()) {
      this.formData.calories = 0
    }
    this.setMealPlanIDAndDays()
    this.newMealText = this.$i18n.t('message[\'food-diary.new-meal\']')
    this.formData.macronutrients.show_net_carbs = this.supportNetCarbs
    if (!this.supportNetCarbs) {
      this.formData.macronutrients.net_carbohydrates = 0
    }
    this.service = new FoodDiaryService()
  },
  methods: {
    changeTab (name) {
      this.$emit('change-tab', name)
    }
  }

}
</script>
