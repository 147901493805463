import DefineInclude from '../DefineInclude'

export default class {
  customerID = 0
  logging = true

  /**
   * send events to events API or log events in dev env
   * @param eventType
   * @param payload
   * @param log
   */
  addEvent (eventType, payload, log = true) {
    try {
      if (typeof eventType === 'string' && eventType.includes('SignUp')) {
        console.log(eventType, JSON.stringify(payload))
      }

      if (typeof payload === 'undefined') {
        payload = {}
      }
      // [Amplitude]  Cannot have object nested in an array property values
      if (window.location.origin + '/api/' === DefineInclude.apiUrl && window.location.origin + '/api/' !== DefineInclude.testAPI) {
        MAnalytics.sendEvents(eventType, payload)
      } else {
        MAnalytics.log(eventType, payload)
      }
    } catch (e) {

    }
  }

  getOrientation () {
    if (window.innerHeight > window.innerWidth) {
      return 'portrait'
    } else {
      return 'landscape'
    }
  }

  _log () {
    // if (this.logging === true) { console.log.apply(null, arguments) }
  }

  setUserProperties (key, value, isOnce) {
    try {
      if (window.location.origin + '/api/' === DefineInclude.apiUrl && window.location.origin + '/api/' !== DefineInclude.testAPI) {
        MAnalytics.setUserProperties(key, value, isOnce)
      }
    } catch (e) {

    }

    this._log('setUserProperties', key, value)
    if (typeof amplitude === 'undefined') {
      return
    }
    let params = {}

    if (typeof key === 'object') {
      params = key
    } else {
      params[key] = value
    }

    const identify = new amplitude.Identify()

    $.each(params, function (objKey, objValue) {
      if (isOnce === true) {
        identify.setOnce(objKey, objValue)
      } else {
        identify.set(objKey, objValue)
      }
    })

    amplitude.getInstance().identify(identify)
  }

  sendToAPI () {

  }

  setUserIdentity(atCustomer){
    try {
      MAnalytics && MAnalytics.setUserIdentity && MAnalytics.setUserIdentity(atCustomer);
    } catch (e){
      console.log(e)
    }

  }
}
