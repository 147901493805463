import { extend } from 'vee-validate'
import {i18n} from '../../localization/i18n'

const validator = {
  validate (time) {
    const parts = time.toString().split(':')
    let sec = 0
    if (parts.length === 2) {
      sec = (parseInt(parts[0]) * 60) + parseInt(parts[1])
    } else if (parts.length === 3) {
      sec = (parseInt(parts[0]) * 3600)(parseInt(parts[1]) * 60) + parseInt(parts[2])
    } else {
      sec = parts[0] || 0
    }
    if (sec === 0) {
      return i18n.t("message['validation']['messages']['required']")
    }
    return sec >= 0
  }
}
extend('time', validator)
