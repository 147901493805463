<template>
  <popup
    v-if="visible"
    ref="mealSwapModal"
    :visible="visible"
    @hide="closePopup"
    class="gc-popup-switch-dashboard-layout"
    :no-close-on-backdrop="noCloseOnBackdrop"
  >
    <popup-title>
      <popup-button-close :style="{'visibility' : noCloseOnBackdrop? 'hidden' :'visible' }" @hide="closePopup()"/>
    </popup-title>
    <popup-body class="gc-popup-switch-dashboard-layout__body text-center">
      <popup-head class="text-center">
        <popup-text-heading>{{ $t('message["shopping-list-group.popup-confirmation-header"]') }}</popup-text-heading>
        <div v-if="status==='loading'" class="text-center">
          <loading-buffer :height="'75'"></loading-buffer>
        </div>
      </popup-head>
      <popup-content>
        <popup-text-body
          v-if="status==='init' && isNewDashboardEnabled"
          class="text-center info-modal__subtitle mb-2"
        >{{ $t('message["dashboard-layout-switch.popup-confirmation-description-old"]') }}
        </popup-text-body>
        <popup-text-body
          v-if="status==='init' && !isNewDashboardEnabled"
          class="text-center info-modal__subtitle mb-2"
        >{{ $t('message["dashboard-layout-switch.popup-confirmation-description-new"]') }}
        </popup-text-body>
        <icon-complete-filled :size="'lg3'" v-if="status==='success'"/>

        <div v-if="status==='error'">
          <div class="text-center"/>

          <p class="text-center info-modal__subtitle mb-2">{{ errorMessage }}</p>
        </div>
      </popup-content>
      <popup-footer v-if="status==='init'">
        <button-primary @click="changeLayout()" :text="swapConfirmButtonText"></button-primary>
      </popup-footer>
    </popup-body>

  </popup>
</template>

<script>
import LoadingBuffer from '../../layout/template-1/global/widgets/LoadingBuffer'
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import Popup from './Popup.vue'
import PopupBody from './popup-sub-components/PopupBody'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupContent from './popup-sub-components/PopupContent'
import PopupFloatingFooter from './popup-sub-components/PopupFloatingFooter'
import PopupFooter from './popup-sub-components/PopupFooter'
import PopupTextBody from './popup-sub-components/PopupTextBody'
import PopupHead from './popup-sub-components/PopupHead'
import PopupTextHeading from './popup-sub-components/PopupTextHeading'
import PopupTextSubHeading from './popup-sub-components/PopupTextSubHeading'
import PopupTitle from './popup-sub-components/PopupTitle'
import ButtonPrimary from '../buttons/ButtonPrimary'
import ButtonSecondary from '../buttons/ButtonSecondary'
import BaseComponent from '../../layout/template-1/global/base/BaseComponent'
import IconCompleteFilled from '../../root/icons/IconCompleteFilled'
import {newDashboardLayout, showNewDashboardLayout} from '@/includes/TemplateSettings'

export default {
  name: 'SwapMeal',
  components: {
    LoadingBuffer,
    Popup,
    PopupBody,
    PopupButtonClose,
    PopupContent,
    PopupFloatingFooter,
    PopupFooter,
    PopupTextBody,
    PopupHead,
    PopupTextHeading,
    PopupTextSubHeading,
    PopupTitle,
    ButtonSecondary,
    ButtonPrimary,
    IconCompleteFilled
  },
  mixins: [popupAnimationMixin],
  extends: BaseComponent,
  props: {
    visible: {
      default: false,
      type: Boolean
    }
  },
  data: function () {
    return {
      status: 'loading',
      swapConfirmButtonText: this.$i18n.t('message["general.change-theme.button-text"]'),
    }
  },
  created () {
    this.status = 'init'
  },
  computed: {
    noCloseOnBackdrop: function () {
      return this.status === 'success' || this.status === 'loading'
    },
    isNewDashboardEnabled: function () {
      return showNewDashboardLayout()
    }
  },
  methods: {
    changeLayout () {
      if (this.isNewDashboardEnabled) {
        localStorage.setItem('newDashboardTheme', 'false')
        this.logEvent('SwitchedDashboardTheme', {to: 'old'}, false)
      } else {
        localStorage.setItem('newDashboardTheme', 'true')
        this.logEvent('SwitchedDashboardTheme', {to: 'new'}, false)
      }
      this.$router.push({path: '/dashboard'})
    },
    closePopup (e, callback) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
        if (callback) {
          callback()
        }
      })
    }
  }
}
</script>
