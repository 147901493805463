export default class {
  inToCm (inches) {
    return Number.parseFloat(Math.round(inches * 2.54 * 100) / 100).toFixed(2)
  }

  cmToIn (cm) {
    return Number.parseFloat(Math.round(cm * 0.39370 * 100) / 100).toFixed(2)
  }

  lbToKg (lb) {
    return Number.parseFloat(Math.round(lb * 0.453592 * 100) / 100).toFixed(2)
  }

  kgToLb (kg) {
    return Number.parseFloat(Math.round(kg * 2.20462 * 100) / 100).toFixed(2)
  }
}
