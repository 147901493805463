<template>
  <div :class="componentClasses">
    <icon-warning
      size="sm1"
      class="gc-alert-warning__icon"/>
    <div>
      <text-body-extra-small :weight="'extra-bold'" class="gc-alert-warning__name">{{ name }} </text-body-extra-small>
      <text-body-extra-small>
        <p
          v-if="description"
          class="gc-alert-warning__description"
          v-html="description"/>
      </text-body-extra-small>
    </div>
  </div>
</template>
<script>
import TextContent from '../../root/TextContent'
import IconWarning from '../../root/icons/IconWarning'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'

export default {
  name: 'AlertWarning',
  components: {
    TextBodyExtraSmall,
    IconWarning,
    TextContent
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-alert-warning': true,
        'text-justify': true
      }
    }
  },
  methods: {}
}
</script>
