import FoodDiaryAPI from './api/meal-plan/FoodDiaryAPI'
import FoodDiarySavedMealsService from '@/services/FoodDiarySavedMealsService'

export default class FoodDiaryCustomMealsService {
  _api = {}
  _savedMeals = []
  _lastedSearchStr= null
  _currentPage= 0
  _lastPage= 0
  _hasMore = false


  reset () {
    this._savedMeals = []
    this._lastedSearchStr = null
    this._currentPage = 0
    this._lastPage = 0
    this._hasMore = false
  }

  async search (text, page = 1) {
    if (text !== this._lastedSearchStr) {
      this._lastedSearchStr = text
      this._savedMeals = []
      this._currentPage = 0
      this._lastPage = 0
    }
    if (page <= this._currentPage) {
      return this._savedMeals
    }
    let data = await this._api.searchSavedMeals({ search: text, page: page })
    data = data.data

    if (this._currentPage < data.current_page) {
      this._currentPage = data.current_page
      this._lastPage = data.last_page
      this._hasMore = data.has_more_pages
      this._savedMeals = this._savedMeals.concat(data.meals)
    }

    return this._savedMeals
  }

  hasMoreMeals () {
    return this._hasMore
  }
  getCurrentPage () {
    return this._currentPage
  }
  getMeals () {
    return this._savedMeals
  }

  async removeSavedMeal (id) {
    let data = await this._api.removeSavedMeal(id)
    data = data.data
    this._savedMeals = this._savedMeals.filter(meal => {
      return meal._id !== id
    })
    return data
  }
}
