<template>
  <transition name="fade">
    <div :class="componentClasses" id="gc-popup-head">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'PopupHead',
  props: {
    fixed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-popup__head': true,
        'gc-popup__head--fixed': this.fixed
      }
    }
  }
}
</script>

<style scoped>

</style>
