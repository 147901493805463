<template>
  <div class="gc-input-multi-select-dropdown">
    <div ref="refSelect"  class="dropdown-wrapper">
      <input-text id="input" v-model="searchStr"></input-text>
      <div ref="refDropDown" :class="dropdownClasses" v-if="showDropDrown"  @focusout="elementFocus">
        <text-content :size="'sm4'"
                      :line-height="'multi'"
                      v-for="option in filterOptions"
                      :key="option.text"
                      class="gc-input-multi-select-dropdown__option"
                      :class="{ 'gc-input-multi-select-dropdown__option-selected': selectedOptions.includes(option) }"
                      @click="toggleOption(option)">
                          {{option.text}}
        </text-content>
      </div>
    </div>

    <div  v-for="option in selectedOptions" :key="option.text">
      <div v-if="!isSearchOnly" @click="toggleOption(option)">{{option.text}} <icon-close/> </div>

    </div>
  </div>
</template>
<script>
import TextContent from '../../root/TextContent'
import InputText from './TextInput'
import IconClose from '../../root/icons/IconClose'
export default {
  components: { IconClose, InputText, TextContent },
  name: 'Select2',
  props: {
    hasError: {
      default: false
    },
    isSearchOnly: {
      default: true
    },
    options: {
      default: []
    },
    value: {
      type: Array,
      default () {
        return []
      }
    },
    multiple: {
      default: true
    },
    placeHolder: {
      default: ''
    },
    disabled: {
      default: false
    },
    errorMessage: {
      default: ''
    },
    maxSelected: {
      type: Number,
      default: 0
    },
    maxSelectedTitle: {
      default: ''
    }
  },
  data: function () {
    return {
      showDropDrown: false,
      dropdownOptions: [],
      selectedOptions: [],
      searchStr: '',
      elementID: '',
      errorClass: '',
      haveError: false,
      dropdownClasses: ['dropdown'],
      noResultMessage: this.$i18n.t('message["general.no-results"]'),
      maximumSelectedMessage: this.$i18n.t('message["validation.limit_selected_options"]').replace('{_field_}', this.maxSelectedTitle).replace('{limit}', this.maxSelected)
    }
  },
  computed: {
    filterOptions: function () {
      if (this.searchStr) {
        return this.dropdownOptions.filter(obj => {
          return (obj.text.toLowerCase().includes(this.searchStr.toLowerCase()))
        })
      }
      return this.dropdownOptions
    }
  },
  mounted () {
    document.addEventListener('click', this.docClickListener)
    const input = document.querySelector('input')
    input.addEventListener('input', this.toggleDropDrown)
  },
  methods: {
    elementFocus (e) {
      console.log(e)
    },
    toggleDropDrown () {
      this.showDropDrown = true
    },
    docClickListener (event) {
      // console.log(this.$refs)
      // Check if the clicked element is not the element you're watching
      if (this.$refs && this.$refs.refSelect && !this.$refs.refSelect.contains(event.target)) {
        // The click occurred outside of the element
        // Your code to run when a click occurs outside the element
        this.$nextTick(() => {
          this.showDropDrown = false
          this.$refs.refSelect.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        })
      }
    },
    toggleOption (option) {
      if (this.isSearchOnly) {
        this.$emit('clickedValue', option.id)
      } else {
        if (this.maximumSelectionLength && this.selectedOptions.length >= this.maximumSelectionLength) {
          return
        }
        // console.log(this.selectedOptions, option)
        const hasOption = this.selectedOptions.some(sOpt => {
          return sOpt.id === option.id
        })
        if (hasOption) {
          this.selectedOptions = this.selectedOptions.filter(sOpt => {
            return sOpt.id !== option.id
          })
        } else {
          this.selectedOptions.push(option)
        }
      }
      this.showDropDrown = false
    }
  },
  watch: {
    showDropDrown: function () {
      this.dropdownClasses = ['dropdown']
      if (this.showDropDrown) {
        if (window.innerHeight - this.$refs.refSelect.getBoundingClientRect().top - 60 < 300) {
          this.dropdownClasses.push('dropdown--top')
          return
        }
      }

      this.dropdownClasses.push('dropdown--bottom ')
    },
    selectedOptions: function () {
      const selectedValues = []
      this.selectedOptions.forEach(option => {
        selectedValues.push(option.id)
      })
      const hasAll = selectedValues.every(val => {
        return this.value.includes(val)
      })
      if (!hasAll) {
        this.$emit('input', selectedValues)
      }
    },
    value: function () {
      this.selectedOptions = this.dropdownOptions.filter(opt => {
        return this.value.includes(opt.id)
      })
    },
    options: {
      immediate: true,
      deep: true,
      handler: function () {
        this.dropdownOptions = this.options
      }
    }
  },
  beforeDestroy () {
    document.removeEventListener('click', this.docClickListener)
  }
}
</script>
