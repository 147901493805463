<template>
  <popup
    :visible="visible"
    v-if="visible"
    size="md"
    @hide="closePopup()"
  >
    <popup-title>
      <popup-button-close  @hide="closePopup($event)"></popup-button-close>
    </popup-title>

    <PoupBody class="text-center">
      <popup-content>
        <popup-head>
          <div class="info-modal__head mood-tracker-modal__head" v-if="status==='init'">
            <popup-text-heading  v-if="moodselectedState"><p>{{ $t('message["mood-tracker.pop-up-heading.with-select-mood"]', {type:moodTypeText}) }}</p></popup-text-heading>
            <popup-text-heading   v-else><p>{{ $t('message["mood-tracker.pop-up-heading.without-mood"]') }} ?</p></popup-text-heading>
          </div>
          <div class="info-modal__sub-heading mood-tracker-modal__sub-head" v-if="status==='init'">
            <popup-text-body>
              <p>{{ $t('message["mood-tracker.pop-up-sub-heading"]') }}</p>
            </popup-text-body>
          </div>
        </popup-head>
        <div class="info-modal__mood-area" v-if="status==='init'">
          <card-mood-tracker :showAddIcon="false" :showMoodCount="false" :enableMoodClickable="enableMoodClickableFeature" :selectMoodType="type" v-on:mood-select="moodSelect($event)"></card-mood-tracker>
        </div>
        <div class="info-modal__note-area" v-if="status==='init'">
            <text-area
                :placeholder="$t('message[\'mood-tracker.pop-up-placeholder\']')"
                :errorMessage="commentErrorMessage||errorMessage"
                v-model="comment"
            />
        </div>
        <div v-if="status==='loading'">
        <div class="text-center info-modal__subtitle">
          <popup-text-heading>
            <p>{{ $t('message["mood-tracker.pop-up-loading"]') }}</p>
          </popup-text-heading>
        </div>
        <loading-buffer :height="'100'"></loading-buffer>
        </div>
        <div v-if="status==='success'">
          <div class="text-center info-modal__subtitle mood-tracker-modal__success">
            <popup-text-heading>
              {{ $t('message["mood-tracker.pop-up-header-success"]') }}
            </popup-text-heading>
          </div>
          <icon-complete-filled :size="'lg2'"/>
        </div>
      </popup-content>
      <popup-footer>
        <div v-if="status==='init'">
          <button-primary :text="$t('message[\'mood-tracker.pop-up-save-button-text\']')" :disabled="submitInProgress|| commentErrorMessage"  v-on:click="doAction()"/>
        </div>
      </popup-footer>
    </PoupBody>
  </popup>
</template>
<script>
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import TextContent from '../../../../root/TextContent'
import TextArea from '../../../../global/inputs/TextArea'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import MoodTrackerService from '../../../../../services/MoodTrackerService'
import IconCompleteFilled from '../../../../root/icons/IconCompleteFilled'
import MOODS from '../../../../../services/enum/MoodTracker'
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import Popup from '../../../../global/popups/Popup'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupTextSubHeading from '../../../../global/popups/popup-sub-components/PopupTextSubHeading'
import PopupTextBody from '../../../../global/popups/popup-sub-components/PopupTextBody'
import PoupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupFooter from '../../../../global/popups/popup-sub-components/PopupFooter'

export default {
  name: 'pop-up-add-mood',
  mixins: [popupAnimationMixin],
  components: {
    PopupFooter,
    PopupContent,
    PopupTitle,
    PopupHead,
    PopupTextHeading,
    PopupButtonClose,
    Popup,
    TextContent,
    TextArea,
    ButtonPrimary,
    CardMoodTracker: () => import('../../../../global/cards/CardMoodTracker'),
    IconCompleteFilled,
    LoadingBuffer,
    PopupTextSubHeading,
    PopupTextBody,
    PoupBody
  },
  props: {
    visible: { default: false },
    type: {default: ''},
    moodId: {default: null},
    moodComment: {default: ''},
    enableMoodClickableFeature: {default: false},
    isEditablePopup: {default: false}
  },
  data: function () {
    return {
      contentClass: 'info-modal__content info-modal__content--hidden custom-modal-padding fd-upload-image-popup ',
      submitInProgress: false,
      moodTypeText: '',
      comment: null,
      status: 'loading',
      moodselectedState: true,
      errorMessage: '',
      commentErrorMessage: '',
      moodType: 0
    }
  },
  watch: {
    comment: function () {
      if (this.comment.length > 255) {
        this.commentErrorMessage = this.$i18n.t('message.validation.messages.max').replace('{_field_}', 'comment').replace('{length}', 255)
      } else if (this.comment && this.comment.length < 3) {
        this.commentErrorMessage = this.$i18n.t('message.validation.messages.min').replace('{_field_}', 'comment').replace('{length}', 3)
      } else {
        this.commentErrorMessage = ''
      }
    }
  },
  computed: {
  },
  beforeMount () {
    if (this.type === '' || this.type === 0) {
      this.moodselectedState = false
    } else {
      this.setTypeText(this.type)
      this.moodType = this.type
      this.comment = this.moodComment
    }
  },
  mounted () {
    this.status = 'init'
  },
  methods: {
    moodSelect (event) {
      this.setTypeText(event.type)
      this.moodType = event.type
      this.moodselectedState = true
      this.errorMessage = false
    },
    closePopup (e) {
      this.closeAnimatedPopup(() => {
        this.$emit('close')
        // backdrop close event fire
        if (e && e.trigger === 'backdrop') {
          this.submitCloseEvent('MoodTracker', 'background')
        }
      })
    },
    doAction () {
      let validate = this.validateBeforeSubmit()
      if (validate) {
        this.status = 'loading'
        this.submitInProgress = true
        let moodTrackerService = new MoodTrackerService()

        if (this.isEditablePopup) {
          moodTrackerService.update(this.moodId, this.moodType, this.comment)
            .then(() => {
              this.$emit('success', {isActiveLoadingBuffer: true})
              this.status = 'success'
              setTimeout(() => {
                this.closePopup()
              }, 3000)
            }).catch((error) => {
              if (error.message) {
                this.errorMessage = error.extensions.validation['input.comment'][0].message
              }
              this.moodType = 0
              this.status = 'init'
              this.submitInProgress = false
            })
        } else {
          moodTrackerService.add(this.moodType, this.comment)
            .then(() => {
              this.$emit('success', {isActiveLoadingBuffer: true})
              this.status = 'success'
              setTimeout(() => {
                this.closePopup()
              }, 3000)
            }).catch((error) => {
              if (error.message) {
                this.errorMessage = error.extensions.validation['input.comment'][0].message
              }
              this.moodType = 0
              this.status = 'init'
              this.submitInProgress = false
            })
        }
      }
    },
    validateBeforeSubmit () {
      if (this.moodType === 0) {
        this.errorMessage = this.$i18n.t('message["mood-tracker.mood-not-select-error"]')
        return false
      }

      if (this.comment) {
        this.comment = this.comment.replace(/\r?\n|\r/g, ' ')
      }
      this.errorMessage = ''
      return true
    },
    setTypeText (type) {
      if (type === MOODS.AWFUL.TYPE) {
        this.moodTypeText = this.$i18n.t('message["mood-tracker.mood-awful"]')
      } else if (type === MOODS.CRAP.TYPE) {
        this.moodTypeText = this.$i18n.t('message["mood-tracker.mood-crap"]')
      } else if (type === MOODS.MEH.TYPE) {
        this.moodTypeText = this.$i18n.t('message["mood-tracker.mood-meh"]')
      } else if (type === MOODS.GOOD.TYPE) {
        this.moodTypeText = this.$i18n.t('message["mood-tracker.mood-good"]')
      } else if (type === MOODS.GREAT.TYPE) {
        this.moodTypeText = this.$i18n.t('message["mood-tracker.mood-great"]')
      }
    }
  }
}
</script>
