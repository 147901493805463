<template>
  <div class="pc-input-shopping-list-item">
    <img
      :src="itemImage"
      @error="setDefaultImage()"
      alt=""
      class="pc-input-shopping-list-item__image"
    />
    <div class="pc-input-shopping-list-item__text-container">
      <text-body-small
        class="pc-input-shopping-list-item__title"
        :weight="'extra-bold'"
        :line-height="'multi'"
      >
        {{ title }}
      </text-body-small>
      <text-body-extra-small
        class="pc-input-shopping-list-item__sub-title"
        :line-height="'multi'"
      >
        {{ itemTotalQuantityWithUnit() }}
      </text-body-extra-small>
    </div>
    <input-check-box-circular
      v-model="inputValue"
      :id="itemID"
      class="pc-input-shopping-list-item__radio-button"
    />
  </div>
</template>

<script>
import InputCheckBoxCircular from '../../../../../global/inputs/InputCheckBoxCircular'
import TextBodyExtraSmall from '../../../../../global/typography/TextBodyExtraSmall'
import TextBodySmall from '../../../../../global/typography/TextBodySmall'

export default {
  name: 'InputShoppingListItem',
  components: {
    TextBodySmall,
    TextBodyExtraSmall,
    InputCheckBoxCircular
  },
  props: {
    itemID: {
      default: ''
    },
    title: {
      default: ''
    },
    image: {
      default: ''
    },
    quantity: {
      default: ''
    },
    units: {
      default: ''
    },
    value: {
      type: Array,
      required: true,
      default: function () {
        return []
      }
    },
    count: {
      default: ''
    }
  },
  data: function () {
    return {
      checkBoxID: '',
      visible: true,
      itemImage: '',
      inputValue: []
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function () {
        this.inputValue = this.value
      }
    },
    inputValue: {
      handler: function () {
        this.$emit('input', this.inputValue)
      }
    },
    image: function () {
      this.itemImage = this.image
    }
  },
  beforeMount () {
    this.checkBoxID = this.makeid()
    this.itemImage = this.image
  },
  methods: {
    itemTotalQuantityWithUnit () {
      const totalQty = this.quantity * this.count
      if (parseInt(totalQty) === parseFloat(totalQty)) {
        return totalQty.toFixed(0) + ' ' + this.units
      } else {
        return totalQty.toFixed(2) + ' ' + this.units
      }
    },
    setDefaultImage () {
      this.itemImage =
        this.$appConfig.imagesUrl + 'mealplan/default-food-image.jpg'
    },
    makeid () {
      return 'cart_item_' + this.itemID
    }
  }
}
</script>

<style></style>
