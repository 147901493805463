<template>
    <popup class="gc-popup-logout-confirmation" v-if="visible" :visible="visible" @hide="closePopup">
        <popup-title class="gc-popup-logout-confirmation__title">
            <popup-button-close @click="closePopup"></popup-button-close>
        </popup-title>

        <popup-body>
            <popup-head class="gc-popup-logout-confirmation__heading">
                <popup-text-heading :size="'md2'"  weight="extra-bold" class="text-center">{{$t('message["auth.logout-popup.header"]')}}</popup-text-heading>
            </popup-head>
            <popup-content class="gc-popup-logout-confirmation__content">
                <popup-text-body class="gc-popup-logout-confirmation__description text-center">{{$t('message["auth.logout-popup.description"]')}}</popup-text-body>
            </popup-content>

            <popup-footer class="gc-popup-logout-confirmation__footer">
                <button-primary :text="$t('message[\'auth.log-out\']')" @click="confirmed"></button-primary>
                <button-secondary :text="$t('message[\'general.cancel\']')" @click="closePopup"></button-secondary>
            </popup-footer>
        </popup-body>
    </popup>
</template>

<script>
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import PopupTitle from './popup-sub-components/PopupTitle'
import PopupBody from './popup-sub-components/PopupBody'
import PopupHead from './popup-sub-components/PopupHead'
import PopupContent from './popup-sub-components/PopupContent'
import PopupFooter from './popup-sub-components/PopupFooter'
import ButtonPrimary from '../buttons/ButtonPrimary'
import ButtonSecondary from '../buttons/ButtonSecondary'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupTextBody from './popup-sub-components/PopupTextBody'
import PopupTextHeading from './popup-sub-components/PopupTextHeading'
import Popup from './Popup'
import TextContent from '../../root/TextContent'

export default {
  name: 'PopupLogoutConfirmation',
  mixins: [popupAnimationMixin],
  components: {
    Popup,
    PopupTitle,
    PopupHead,
    PopupBody,
    PopupContent,
    PopupFooter,
    PopupButtonClose,
    ButtonPrimary,
    TextContent,
    PopupTextBody,
    PopupTextHeading,
    ButtonSecondary
  },
  props: ['visible'],
  methods: {
    confirmed () {
      this.$emit('confirm')
    },
    closePopup (e) {
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    }
  }
}
</script>
