import GroupAPI from '../api/connect/GroupAPI'
import Member from './Member'
import lodash from 'lodash'
import ActivityTracker from '../ActivityTracker'
import PairService from './PairService'

export default class ConnectionService {
  api = new GroupAPI()
  details = {}

  /**
   * Constructor
   * @return {ConnectionService|ConnectionService}
   */
  constructor () {
    if (!ConnectionService.instance) {
      ConnectionService.instance = this
    }
    return ConnectionService.instance
  }

  /**
   * Initiate member connection details
   * @param config
   * @return {Promise}
   */
  init (config = {
    forceLoad: false,
    forceCreate: false
  }) {
    return new Promise(async (resolve, reject) => {
      if (
        lodash.has(this.details, 'is_member') &&
        !config.forceLoad
      ) {
        // if data is already loaded and force load not enabled
        resolve()
      } else {
        // if data is not loaded or force load enabled

        // loading member connect data
        await this.api.getList()
          .then(async (res) => {
            this.details = res.data
          })
          .catch(err => {
            reject(err)
          })

        if (!this.details.is_member && config.forceCreate) {
          // if member connect instance not created and force create enabled

          // creating a new connection
          this.createConnection()
            .then(() => {
              // re loading member connect data
              this.init({forceLoad: true})
                .then(() => {
                  resolve()
                })
                .catch((err) => {
                  reject(err)
                })
            })
            .catch((err) => {
              reject(err)
            })
        } else {
          resolve()
        }
      }
    })
  }

  /**
   * Get connection code generated for the logged user
   * @return {*}
   */
  getConnectionCode () {
    return this._getAttribute('passcode', null)
  }

  /**
   * Get own details
   * @return {Member}
   */
  getSelf () {
    return new Member(lodash.head(lodash.filter(this.details.members, (member) => {
      return this.details.is_owner === member.is_owner
    })))
  }

  /**
   * Get paired partner details
   * @return {Member}
   */
  getPartner () {
    return new Member(lodash.head(lodash.filter(this.details.members, (member) => {
      return this.details.is_owner !== member.is_owner
    })))
  }

  /**
   * Get whether partner is connected or not
   * @return {boolean}
   */
  isPartnerConnected () {
    return this.getPartner().getId() !== null
  }

  /**
   * Get whether current user is an owner or not
   * @return {*}
   */
  isOwner () {
    return this._getAttribute('is_owner', false)
  }

  /**
   * Get whether current user is generated a connection
   * @return {*}
   */
  isConnectionCreated () {
    return this._getAttribute('is_member', false)
  }

  /**
   * Disconnect from current connection
   * @return {Promise}
   */
  disconnect () {
    let isOwnerBeforeUngrouped = this.isOwner()
    return new Promise(async (resolve, reject) => {
      this.api.leaveGroup()
        .then(() => {
          this._logConnectionDisconnectedEvent(isOwnerBeforeUngrouped)
          resolve()
        }).catch(err => {
          reject(err)
        })
    })
  }

  _logConnectionDisconnectedEvent (isOwner) {
    try {
      const userType = (isOwner) ? 'manager' : 'follower'
      let activity = new ActivityTracker()
      activity.addEvent('MealPlanPairing.ConnectionDisconnected', {initiated_by: userType})
    } catch (e) {

      // ignore issue
    }
  }

  _logConnectionEstablishedEvent (data = null) {
    try {
      let pairService = new PairService()
      pairService.init({forceLoad: true}).then(() => {
        let activity = new ActivityTracker()
        activity.addEvent('MealPlanPairing.ConnectionEstablished', {is_mealplan_available: pairService.canPair()}, false)
      })
    } catch (e) {

      // ignore issue
    }
  }

  /**
   * Create a connection (which will generate a connection code)
   * @return {Promise}
   */
  createConnection () {
    return new Promise(async (resolve, reject) => {
      this.api.createGroup()
        .then((res) => {
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
    })
  }

  /**
   * Connect to a connection using a connection code provided by another member
   * @param passCode
   * @return {Promise}
   */
  connect (passCode) {
    return new Promise(async (resolve, reject) => {
      this.api.joinGroup(passCode)
        .then((res) => {
          this._logConnectionEstablishedEvent(res.data)
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
    })
  }

  /**
   * Get value for the provided key
   * @param key
   * @param defaultValue
   * @return {*}
   * @private
   */
  _getAttribute (key, defaultValue) {
    return lodash.get(this.details, key, defaultValue)
  }
}
