<template>
  <button-base :borderRadius="'sm1'" :class="componentClasses" :type="'outline'" @click="clicked()">
    <div class="gc-button-secondary-forward__left-section">
      <slot class="gc-button-secondary-forward__left-section-icon"></slot>
      <div class="gc-button-secondary-forward__text-section">
        <text-body-regular :class="textClass" :weight="'extra-bold'" :lineHeight="'multi'">{{ text }}</text-body-regular>
        <text-body-extra-small :class="subTextClass" :line-height="'multi'" v-if="hasSubText">{{ subText }}</text-body-extra-small>
      </div>
    </div>
    <div class="gc-button-secondary-forward__right-section">
      <icon-forward :size="'md1'"></icon-forward>
    </div>
  </button-base>
</template>

<script>

import ButtonBase from '../../root/ButtonBase'
import IconForward from '../../root/icons/IconForward'
import TextBodyRegular from '../typography/TextBodyRegular'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'

export default {
  name: 'ButtonSecondaryForward',
  components: {
    TextBodyExtraSmall,
    TextBodyRegular,
    ButtonBase,
    IconForward
  },
  props: {
    /**
     * The main text of the button
     */
    text: {
      default: ''
    },
    /**
     * The sub text of the button
     */
    subText: {
      default: ''
    },
    /**
     * The disabled state of the button
     */
    disabled: {
      default: false
    }
  },
  computed: {
    hasSubText: function () {
      return this.subText.replace(/\s/g, '') !== ''
    },
    hasIcon () {
      return !!this.$slots.default
    },
    textClass: function () {
      return {
        'gc-button-secondary-forward__text': true,
        'gc-button-secondary-forward__text--with-sub-text': this.hasSubText
      }
    },
    subTextClass: function () {
      return {
        'gc-button-secondary-forward__sub-text': this.hasSubText
      }
    },
    componentClasses: function () {
      return {
        'gc-button-secondary-forward': true,
        'gc-button-secondary-forward--with-sub-text': this.hasSubText,
        'gc-button-secondary-forward--disabled': this.disabled
      }
    }
  },
  methods: {
    /**
     * function to emit the click event
     */
    clicked () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped></style>
