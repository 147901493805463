import SignUpAPI from './api/SignUp/SignUpAPI'
import AuthService from './AuthService'
import IntegrationApi from './api/integration/IntegrationApi'
import * as Sentry from '@sentry/browser'
import store from '../store'
import ActivityTracker from './ActivityTracker'

export default class IAPService {
  // event types thrown from module
  eventStatus = {
    productDetailsReceived: 'product_details_response',
    purchaseInAppProductResponseReceived: 'purchase_response',
    timeout: 'timeout',
    accountCreateValidation: 'account_create_validation',
    accountConflict: 'account_conflict',
    subscriptionCreated: 'subscription_created',
    busy: 'busy',
    error: 'error'
  }

  // call stack steps
  callStackDefine = {
    ready: 0,
    inAppProductDetailsRequest: 1,
    inAppProductDetailsResponse: 2,
    purchaseInAppProductRequest: 3,
    purchaseInAppProductResponse: 4,
    postUserDetails: 5,
    postPaymentDetails: 6,
    purchaseInAppProductFinishRequest: 7,
    purchaseInAppProductFinishResponse: 8
  }

  // init
  currentCallStackStep = 0
  responseWaiting = false
  sessionData = {
    productDetails: {},
    purchaseConfig: {},
    signUpPayload: {},
    appStoreReceipt: '',
    paymentTransactionId: '',
    purchase: {},
    subscriptionId: ''
  }

  timeout = null
  timeOutMessage = 'Time out occurred. Try again'
  interval = null

  constructor () {
    if (!IAPService.instance) {
      IAPService.instance = this
      // start listeners
      this._listen()
    }
    return IAPService.instance
  }

  /**
   * Log error to sentry
   * @param message
   * @param payload
   * @param show
   */
  static logError (message, payload = {}, show = true) {
    let eventID = Sentry.captureException({message: message, payload: payload})
    if (show) {
      store.commit('showServerErrorPopup')
      store.commit('setServerErrorID', eventID)
    }
  }

  setPurchaseInitStack () {
    this._setStackLevel('ready')
  }

  setAccountInitStack () {
    this._setStackLevel('inAppProductDetailsResponse')
  }

  /**
   * Set sign up related payload data
   * @param signUpPayload
   */
  submitMemberDetails (signUpPayload) {
    const signUpApi = new SignUpAPI()
    const authService = new AuthService()
    const activityTracker = new ActivityTracker()
    let region = signUpPayload.region
    if(parseInt(signUpPayload.region)){
      region = parseInt(signUpPayload.region)
    }
    activityTracker.addEvent('SignUp.MemberRegister.Init', {
      email: signUpPayload.email,
      country: signUpPayload.country,
      region: region,
      regionName: signUpPayload.region_name
    }, true)
    if (!signUpPayload.verification_code) {
      delete signUpPayload.verification_code
    }
    signUpApi.postUserDetails(signUpPayload)
      .then(response => {
        activityTracker.addEvent('SignUp.MemberRegister.Success', response.data, true)
        authService.setTokenInStorage(response.data.token)
        activityTracker.addEvent('SignUp.PostPayment.Init', {
          payment_transaction_id: this.sessionData.paymentTransactionId
        }, false)
        signUpApi.postPaymentDetails({
          type: 'iap-ios',
          receipt: this.sessionData.appStoreReceipt,
          payment_transaction_id: this.sessionData.paymentTransactionId
        }).then(response => {
          activityTracker.addEvent('SignUp.PostPayment.Success', response.data, true)
          this._destroyTimeout()
          this.sessionData.subscriptionId = response.data.subscription_id
          this._purchaseInAppProductFinishRequestSend(this.sessionData.purchase)
        }).catch((error) => {
          activityTracker.addEvent('SignUp.PostPayment.Failed', error.response, true)
          this._setStackLevel('inAppProductDetailsResponse')
          this._handleProcessError('Server error')
        })
      }).catch(error => {
        activityTracker.addEvent('SignUp.MemberRegister.Failed', error.response, true)
        this._setStackLevel('inAppProductDetailsResponse')
        if (error.response.status === 422) {
          this._destroyTimeout()
          this._dispatchEvent(this.eventStatus.accountCreateValidation, error.response.data)
        } else if (error.response.status === 409) {
          this._destroyTimeout()
          this._dispatchEvent(this.eventStatus.accountConflict, error.response.data)
        } else {
          this._handleProcessError('Server error')
        }
      })
  }

  /**
   * Set purchase config related data
   * @returns {Promise<unknown>}
   */
  setPurchaseConfig () {
    return new Promise((resolve, reject) => {
      const integrationApi = new IntegrationApi()
      integrationApi.getPurchaseConfig()
        .then(response => {
          this.sessionData.purchaseConfig = response.data
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
   * Get purchase config data
   * @returns {{}}
   */
  getPurchaseConfig () {
    return this.sessionData.purchaseConfig
  }

  /**
   * Get product details object
   * @returns {{}}
   */
  getProductData () {
    return this.sessionData.productDetails
  }

  /**
   * Product details request send to native app
   */
  inAppProductDetailsRequestSend () {
    // TODO: check for product id
    if (this._validateStackLevel('inAppProductDetailsRequest')) {
      // init send
      this._appPostMessage('inAppProductDetailsRequest', {
        productId: this.sessionData.purchaseConfig.iosProductId
      })
      let intervalLoop = 3
      this.interval = setInterval(() => {
        if (intervalLoop > 0) {
          this._appPostMessage('inAppProductDetailsRequest', {
            productId: this.sessionData.purchaseConfig.iosProductId
          })
          intervalLoop = intervalLoop - 1
        } else {
          this.currentCallStack = null
          this._dispatchEvent(this.eventStatus.timeout, {
            message: 'Unable to retrieve product details. Please try again.'
          })
          this._destroyInterval()
        }
      }, 10000)
    } else {
      this._dispatchEvent(this.eventStatus.busy)
    }
  }

  /**
   * Send purchase app product request to react native app
   */
  purchaseInAppProductRequestSend () {
    if (this._validateStackLevel('purchaseInAppProductRequest')) {
      this._appPostMessage('purchaseInAppProductRequest', {
        productId: this.sessionData.purchaseConfig.iosProductId
      })
      this.timeOutMessage = 'Unable to complete the purchase. Please try again.'
      this._initTimeout(120000)
    } else {
      this._dispatchEvent(this.eventStatus.busy)
    }
  }

  /**
   * Initialize timeout
   * @private
   */
  _initTimeout (timeout) {
    this.timeout = setTimeout(() => {
      this.currentCallStack = null
      this._dispatchEvent(this.eventStatus.timeout, {message: this.timeOutMessage})
    }, timeout)
  }

  /**
   * Destroy timeout (do if process finished)
   * @private
   */
  _destroyTimeout () {
    clearTimeout(this.timeout)
  }

  /**
   * Destroy interval (do if process finished)
   * @private
   */
  _destroyInterval () {
    clearInterval(this.interval)
  }

  /**
   * Check whether provided stack key is not yet processed
   * @param stackKey
   * @returns {boolean}
   * @private
   */
  _validateStackLevel (stackKey) {
    const stackLevelValidate = this.currentCallStackStep < this.callStackDefine[stackKey]
    if (stackLevelValidate) {
      // set current step to stack
      this._setStackLevel(stackKey)
    }
    return stackLevelValidate
  }

  /**
   * Sets current stack level
   * @param stackKey
   * @private
   */
  _setStackLevel (stackKey) {
    this.currentCallStackStep = this.callStackDefine[stackKey]
  }

  /**
   * Send purchase app product finish request to react native app
   * @private
   */
  _purchaseInAppProductFinishRequestSend (purchase) {
    if (this._validateStackLevel('purchaseInAppProductFinishRequest')) {
      this._appPostMessage('purchaseInAppProductFinishRequest', {
        purchase: purchase
      })
      this.timeOutMessage = 'Unable to finish session. Please try again.'
      this._initTimeout(30000)
    } else {
      this._dispatchEvent(this.eventStatus.busy)
    }
  }

  /**
   * Process complete action handle
   * @private
   */
  _handleProcessSuccess () {
    this._destroyTimeout()
    this._setStackLevel('ready')
    this._dispatchEvent(this.eventStatus.subscriptionCreated, {subscriptionId: this.sessionData.subscriptionId})
  }

  /**
   * Process error action handle
   * @private
   */
  _handleProcessError (error) {
    this._destroyTimeout()
    this._setStackLevel('ready')
    this.responseWaiting = false
    this._dispatchEvent(this.eventStatus.error)
    alert(error)
  }

  /**
   * Submit post message to react native app
   * @private
   */
  _appPostMessage (name, args) {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          command: {
            name: name,
            args: args
          }
        })
      )
      const activityTracker = new ActivityTracker()
      activityTracker.addEvent('Dev.SignUp.AppPostMessage', {
        command: {
          name: name,
          args: args
        }
      }, true)
      this.responseWaiting = true
    }
  }

  /**
   * Dispatch global event for UX reference
   * @private
   */
  _dispatchEvent (status, data = {}) {
    const event = new CustomEvent('iap-message', {detail: {status: status, data: data}})
    document.dispatchEvent(event)
  }

  /**
   * Listen for react native app events
   * @private
   */
  _listen () {
    window.addEventListener('message', (ev) => {
      try {
        const eventJson = JSON.parse(ev.data)
        const activityTracker = new ActivityTracker()
        activityTracker.addEvent('SignUp.IAPRawResponse', eventJson, true)
        if (!this.responseWaiting) {
          // action if not waiting for response
        } else if (eventJson.args.error) {
          this._handleProcessError(eventJson.args.error)
        } else {
          switch (eventJson.name) {
            case 'inAppProductDetailsResponse':
              if (this._validateStackLevel('inAppProductDetailsResponse')) {
                this.responseWaiting = false
                this._destroyInterval()
                this.sessionData.productDetails = eventJson.args
                this._dispatchEvent(this.eventStatus.productDetailsReceived, eventJson.args)
              } else {
                this._dispatchEvent(this.eventStatus.busy)
              }
              break

            case 'purchaseInAppProductResponse':
              if (this._validateStackLevel('purchaseInAppProductResponse')) {
                this.responseWaiting = false
                this._destroyTimeout()
                this.sessionData.appStoreReceipt = eventJson.args.app_store_receipt
                this.sessionData.purchase = eventJson.args.purchase

                this.sessionData.paymentTransactionId = eventJson.args.payment_transaction_id ||
                  eventJson.args.purchase.transactionId

                this._dispatchEvent(this.eventStatus.purchaseInAppProductResponseReceived, eventJson.args)
              } else {
                this._dispatchEvent(this.eventStatus.busy)
              }
              break

            case 'purchaseInAppProductFinishResponse':
              if (this._validateStackLevel('purchaseInAppProductFinishResponse')) {
                this.responseWaiting = false
                this._destroyTimeout()
                this._handleProcessSuccess()
              } else {
                this._dispatchEvent(this.eventStatus.busy)
              }
              break
          }
        }
      } catch (e) {

      }
    })
  }
}
