<template>
  <div class="accordion">
    <div class="accordion-card" no-body v-for="(rootItem) in itemList" :key="'accordion-card-'+rootItem.id">
      <div header-tag="header" role="tab">
        <b-button
          class="btn accordion-card__btn collapsed"
          block
          href="#"
          v-b-toggle="'accordion-' + rootItem.id"
          v-on:click="setLastExpanded(rootItem.id)"
        >
          {{rootItem.name}}
          <svg class="svg-down-arrow accordion-card__left-arrow when-opened" width="25" height="25" viewBox="0 0 25 25"
               fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path
                d="M12.8602 18.6056L24.8395 7.14719C25.046 6.94782 25.0552 6.61823 24.8558 6.41071C24.6569 6.20318 24.3273 6.19605 24.1193 6.39445L12.5001 17.508L0.880917 6.3944C0.672907 6.19601 0.343315 6.20314 0.144436 6.41066C0.0478058 6.51139 1.90735e-06 6.64157 1.90735e-06 6.77077C1.90735e-06 6.90812 0.0539074 7.0444 0.160746 7.14714L12.14 18.6056C12.3414 18.7979 12.6588 18.7979 12.8602 18.6056Z"
                fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="25" height="25" fill="white" transform="translate(25) rotate(90)"/>
              </clipPath>
            </defs>
          </svg>

          <svg class="svg-right-arrow accordion-card__left-arrow when-closed" width="13" height="25" viewBox="0 0 13 25"
               fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.6057 12.1398L1.14725 0.160504C0.947881 -0.0459921 0.618288 -0.0551719 0.410766 0.144244C0.203245 0.343123 0.196116 0.672716 0.394507 0.880726L11.508 12.4999L0.394457 24.1191C0.196067 24.3271 0.203196 24.6567 0.410718 24.8556C0.511451 24.9522 0.641628 25 0.770829 25C0.908184 25 1.04446 24.9461 1.1472 24.8393L12.6057 12.86C12.798 12.6586 12.798 12.3412 12.6057 12.1398Z"
              fill="white"/>
          </svg>

        </b-button>
      </div>
      <b-collapse
        :id="'accordion-'+rootItem.id"
        accordion="my-accordion"
        class="accordion-card__collapse"
        role="tabpanel"
        :visible="isVisible(rootItem.id)"
        v-on:hide="resetLastExpanded()"
        v-on:shown="setLastExpanded(rootItem.id)"
      >
        <div class="text-content-area app-content-wrap" v-html="rootItem.content"></div>
        <div v-for="(subItem) in rootItem.subItems" :key="'sub-item'+subItem.id">
          <sub-item-area :root-key="rootItem.id" :sub-item="subItem" :sub-key="subItem.id"
                         class="accordion-card__text"/>
        </div>
      </b-collapse>
    </div>
  </div>
</template>
<script>
import SubItemArea from './SubitemArea'
import { BButton, BCollapse } from 'bootstrap-vue'
import { mapMutations } from 'vuex'
import NativeAppMixin from '../../../../../mixins/NativeAppMixin'

export default {
  name: 'items',
  components: {
    SubItemArea,
    BButton,
    BCollapse
  },
  mixins: [NativeAppMixin],
  props: {
    itemList: {},
    lastExpanded: {}
  },
  data: function () {
    return {
      imagesUrl: this.$appConfig.imagesUrl
    }
  },
  methods: {
    ...mapMutations({
      setLastExpanded: 'listLastExpandedStore/setLastExpanded',
      resetLastExpanded: 'listLastExpandedStore/reset'
    }),
    isVisible (index) {
      if (!this.lastExpanded || !this.lastExpanded.expanded) {
        return false
      }
      return this.lastExpanded.expanded === index
    },
    linkNavigateClickHandler (event) {
      let targetElement = event.target
      const selector = '.accordion-card__text a, .text-content-area a'
      while (targetElement != null) {
        if (targetElement.matches(selector)) {
          event.preventDefault()
          this.handleUrlNavigate(targetElement.getAttribute('href'))
          return
        }
        targetElement = targetElement.parentElement
      }
    }
  },
  mounted () {
    setTimeout(() => {
      document.querySelector('body')
        .addEventListener('click', this.linkNavigateClickHandler, true)
    }, 100)
  },
  beforeDestroy () {
    document.querySelector('body')
      .removeEventListener('click', this.linkNavigateClickHandler, true)
  }
}
</script>
