<template>
  <page-secondary :header="headerOptions" :class="componentClasses" style="transform: translate3d(0)">

    <page-container class="pc-photo-journey__slider-wrapper">
    <slider-journey-photo-highlight :isRTL="isRTL()" v-if="showProgress && hasImages" v-on:has-no-image="hasNoImages()"></slider-journey-photo-highlight>
    </page-container>
      <page-container class="container__photo-journey">
      <container-photo-journey-history class="pc-photo-journey__history" @reInit="refreshAll" v-if="showProgress && showHistory" v-on:has-no-images="showHistory=false" ></container-photo-journey-history>
      <div v-else-if="showProgress">
        <text-body-extra-small  class="pc-photo-journey__text-empty" lineHeight="multi">
            {{$t('message["fitness-diary.photo-journey-description"]')}}
        </text-body-extra-small>
      </div>
    </page-container>
    <pop-up-upload-photos :visible="uploadPopup.show" v-if="uploadPopup.show" v-on:close="uploadPopup.show = false" v-on:uploaded="uploadedImage($event)">
    </pop-up-upload-photos>
  </page-secondary>
</template>
<script>

import SliderJourneyPhotoHighlight from './page-components/SliderJourneyPhotoHighlight'
// import PhotoJourneyHistory from './widgets/PhotoJourneyHistory'
import ContainerPhotoJourneyHistory from './page-components/ContainerPhotoJourneyHistory'
import PopUpUploadPhotos from '../../../global/popups/PopUpUploadPhotos'
import fitnessDiaryPhotoJourneyMixin
  from '../../../../mixins/component-mixins/page/fitness-diary/fitnessDiaryPhotoJourneyMixin'
import PageSecondary from '../../../global/pages/PageSecondary'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import headerEventBus from '../../../../event-buses/headerEventBus'
import TextBodyExtraSmall from '../../../global/typography/TextBodyExtraSmall'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'PhotoJourney',
  mixins: [fitnessDiaryPhotoJourneyMixin],
  components: {
    TextBodyExtraSmall,
    PageContainer,
    PageSecondary,
    SliderJourneyPhotoHighlight,
    ContainerPhotoJourneyHistory,
    PopUpUploadPhotos
  },

  data: function () {
    return {
      pageClass: ['macroapp--page-common'],
      headerOptions: {
        show: true,
        left: 'previous-emit',
        right: 'add',
        rightDisabled: false,
        isFixed: true,
        helpContent: '',
        modifiers: ['small-title'],
        formData: {
          image: ''
        },
        header: {
          mainTitle: '',
          subTitle: ''
        }
      }
    }
  },
  beforeMount () {
    this.checkDirection()
  },
  mounted () {
    this.setPageClass()
    this.headerOptions.header.mainTitle = this.$i18n.t(
      'message["fitness-diary.photo-journey"]'
    )
    this.headerOptions.mainTitle = this.$i18n.t(
      'message["fitness-diary.photo-journey"]'
    )
    this.hideLoading()
    pageReadyEvent()
    this.logEvent('Journey.ViewedPhotos', { context: 'my_journey' }, false)

    headerEventBus.$on('add-button-click', this.showPopupUploadPhoto)
    headerEventBus.$on('back-button-click', this.navigatePrevious)
  },
  methods: {
    showPopupUploadPhoto () {
      if (!this.uploadPopup.show) {
        this.uploadPopup.show = true
      }
    },
    checkDirection () {

    }
  },
  computed: {
    componentClasses: function () {
      return {
        'pc-photo-journey': true,
        'pc-photo-journey__tablet-view': this.isTablet,
        'pc-photo-journey__desktop-view': this.isDesktop
      }
    }
  },
  beforeDestroy () {
    headerEventBus.$off('add-button-click', this.showPopupUploadPhoto)
    headerEventBus.$off('back-button-click', this.navigatePrevious)
  }
}

</script>
<style scoped>
</style>
