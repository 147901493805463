<template>
  <div>
    <div class="head"
         v-bind:class="setDescriptionClass" v-bind:style="headStyle" >
      <h1 class="head__title " v-bind:class="{'head__title--white' : this.image!=='','head_title--workout-form' : this.image!==''}" >{{title}}</h1>
      <p class="head__subtitle head__subtitle--grey" v-if="this.sub_title!==''">{{sub_title}}</p>
      <slot></slot>
      <div class="head-image__overlay"></div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'FormHeader',
  props: {
    title: {
      type: String,
      required: true
    },
    sub_title: String,
    image: {
      type: String,
      default: ''
    }
  },
  computed: {
    setDescriptionClass: function () {
      return {
        'head--with-description': this.sub_title !== '',
        'head--with-image': this.image !== ''
      }
    },
    headStyle: function () {
      if (this.image === '') {
        return {}
      } else {
        return {
          'background-image': "url('" + this.image + "')"
        }
      }
    }
  }
}
</script>
