<template>
  <div class="pc-sub-item-area">
    <button-secondary-accordion class="pc-sub-item-area__card-btn" :text="subItem.name" @click="showContent" />
    <text-body-extra-small line-height="multi" v-if="subItem.content &&showDropDown">
      <div class="pc-sub-item-area__app-content-wrap app-content-wrap">
        <card-text class="pc-sub-item-area__card-content" v-if="subItem.content &&showDropDown">
          <div  v-html="subItem.content"></div>
        </card-text>
      </div>
    </text-body-extra-small>

  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import NativeAppMixin from '../../../../../mixins/NativeAppMixin'
import ButtonSecondaryAccordion from '../../../../global/buttons/ButtonSecondaryAccordion'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import CardText from '../../../../global/cards/CardText'
export default {
  name: 'PageListSubItemArea',
  mixins: [NativeAppMixin],
  components: {
    CardText,
    TextBodyExtraSmall,
    ButtonSecondaryAccordion
  },
  props: {
    rootKey: {},
    subItem: {},
    subKey: {}
  },
  data: function () {
    return {
      showDropDown: false,
      imagesUrl: this.$appConfig.imagesUrl
    }
  },
  mounted () {
    document.querySelector('body')
      .addEventListener('click', this.linkNavigateClickHandler, true)
  },
  methods: {
    ...mapMutations({
      setLastOffset: 'listLastExpandedStore/setLastOffset',
      setLastExpanded: 'listLastExpandedStore/setLastExpanded'
    }),
    rememberLastOffset () {
      const offsetTop = (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0)
      this.setLastOffset(offsetTop)
    },
    linkNavigateClickHandler (event) {
      let targetElement = event.target
      const selector = '.card-info a'
      while (targetElement != null) {
        if (targetElement.matches(selector)) {
          event.preventDefault()
          this.handleUrlNavigate(targetElement.getAttribute('href'))
          return
        }
        targetElement = targetElement.parentElement
      }
    },
    showContent () {
      const ele = document.createElement('div')
      ele.innerHTML = this.subItem.content || ''
      const text = ele.textContent.trim()
      if (text.length <= 1000) {
        this.showDropDown = !this.showDropDown
      } else {
        this.setLastExpanded(this.rootKey)
        this.rememberLastOffset()
        this.$router.push({
          path: this.$route.path,
          query: {
            rootItem: this.rootKey,
            subItem: this.subKey
          }
        })
      }
    }
  },
  beforeMount () {
    document.querySelector('body')
      .removeEventListener('click', this.linkNavigateClickHandler, true)
  }
}
</script>
