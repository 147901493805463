<template>
  <popup
    :visible="visible"
    @hide="closePopup"
    v-if="visible"
    class="gc-popup-meal-favorite-delete"
  >
    <popup-title>
      <popup-button-close :style="{'visibility':(showClose)?'visible':'hidden'}"
                          @hide="closePopup()"></popup-button-close>
    </popup-title>
    <popup-body v-if="!success&&!submitted">
      <popup-head class="text-center">
        <popup-text-heading><p v-html="$t('message[\'popup.remove-meal-favorite-header\']')"></p></popup-text-heading>
      </popup-head>
      <popup-content>
        <popup-text-body class="text-center">
          <p v-html="$t('message[\'popup.remove-meal-favorite-description\']',{meal:meal})"></p>
        </popup-text-body>
      </popup-content>
      <popup-footer>
        <button-primary :text="$t('message[\'popup.remove-meal-dislikes-button\']')" @click="confirmed">
        </button-primary>
      </popup-footer>
    </popup-body>

    <popup-body v-if="submitted">
      <popup-head class="text-center">
        <popup-text-heading><p v-html="$t('message[\'popup.remove-meal-dislikes-header-submitting\']')"></p>
        </popup-text-heading>
      </popup-head>
      <popup-content>
        <loading-buffer :height="'75'"></loading-buffer>
      </popup-content>

    </popup-body>
    <popup-body v-if="success">
      <popup-head class="text-center">
        <popup-text-heading><p v-html="$t('message[\'popup.remove-meal-dislikes-header-submitted\']')"></p>
        </popup-text-heading>
      </popup-head>
      <popup-content class="gc-popup-meal-favorite-delete__content-success text-center">
        <icon-complete-filled class="gc-popup-meal-favorite-delete__icon-success" :size="'lg2'"/>
      </popup-content>

    </popup-body>

  </popup>
</template>
<script>

import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import NativeAppMixin from '../../../mixins/NativeAppMixin'
import MealFavouritesService from '../../../services/nutrition/MealFavouritesService'
import LoadingBuffer from '../../layout/template-1/global/widgets/LoadingBuffer'
import Popup from './Popup'
import PopupTitle from './popup-sub-components/PopupTitle'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupBody from './popup-sub-components/PopupBody'
import PopupContent from './popup-sub-components/PopupContent'
import PopupTextBody from './popup-sub-components/PopupTextBody'
import PopupFooter from './popup-sub-components/PopupFooter'
import ButtonPrimary from '../buttons/ButtonPrimary'
import PopupHead from './popup-sub-components/PopupHead'
import PopupTextHeading from './popup-sub-components/PopupTextHeading'
import IconTick from '../../root/icons/IconTick'
import IconCompleteFilled from '../../root/icons/IconCompleteFilled'

export default {
  props: {
    visible: {
      default: false
    },
    meal: {
      default: 'This meal'
    },
    mealID: {
      default: 0,
      type: Number
    }
  },
  name: 'PopupRemoveFavorites',
  mixins: [popupAnimationMixin, NativeAppMixin],
  components: {
    IconCompleteFilled,
    IconTick,
    PopupTextHeading,
    ButtonPrimary,
    PopupFooter,
    PopupTextBody,
    PopupContent,
    PopupBody,
    PopupButtonClose,
    PopupTitle,
    Popup,
    PopupHead,
    LoadingBuffer,
  },
  data: function () {
    return {
      showClose: true,
      submitted: false,
      success: false,
      imagesUrl: this.$appConfig.imagesUrl
    }
  },
  methods: {
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    },
    confirmed () {
      let service = new MealFavouritesService()
      if (this.submitted) {
        return
      }
      this.showClose = false
      this.submitted = true
      service.removeFavourite(this.mealID).then(data => {
        this.success = true
        this.$emit('success', {id: this.mealID})
        setTimeout(() => {
          this.closePopup()
        }, 1000)
      }).finally(() => {
        this.submitted = false
      })
    }
  }
}
</script>

<style scoped>

</style>
