<template>
  <div>
    <nav-circuit-exercises
      :exercises="exercises"
      :current-exercise="currentExercise"
      :show-circuit-back-button="showCircuitBackButton"
      :show-circuit-finish-button="showCircuitFinishButton"
      :show-circuit-next-button="showCircuitNextButton"
      @goto-previous-exercise="navigatePerviousExerciseCircuit"
      @goto-next-exercise="navigateNextExerciseCircuit"
      @finish-circuit="completeCircuit"
      />
    <workout-log-success-area v-if="showSuccess" />
  </div>
</template>
<script>

import WorkoutLogSuccessArea from './ContainerFullscreenSuccess'
import { mapGetters, mapMutations } from 'vuex'
import NavCircuitExercises from './NavCircuitExercises'

export default {
  name: 'NavCircuitExerciseBar',
  components: {
    NavCircuitExercises,
    WorkoutLogSuccessArea
  },
  props: {
    day: {},

    circuit: {},
    currentExercise: {},
    exercises: {}
  },
  data: function () {
    return {
      showSuccess: false
    }
  },
  computed: {
    ...mapGetters({
      circuitModeCurrentRound: 'workoutCircuitStore/getCurrentRound'
    }),
    isCurrentCompleted () {
      if (this.exercise.getIsCompleted) {
        return true
      }
      return this.getCurrentCompletedExercises().some((ex) => {
        return (
          ex.id === parseInt(this.currentExercise.getID()) &&
          this.circuit.totalRounds === ex.round
        )
      })
    },
    showCircuitNextButton () {
      return (
        !(
          this.exercises.length === this.getCurrentExerciseIndex() + 1 &&
          this.circuitModeCurrentRound >= this.circuit.totalRounds
        )
      )
    },
    showCircuitBackButton () {
      return (
        !(
          this.getCurrentExerciseIndex() === 0 &&
          this.circuitModeCurrentRound === 1
        )
      )
    },
    showCircuitFinishButton () {
      return (
        this.exercises.length === this.getCurrentExerciseIndex() + 1 &&
        this.circuitModeCurrentRound >= this.circuit.totalRounds
      )
    }
  },
  mounted () {
    this.initDayCircuit(parseInt(this.day))
  },
  methods: {
    ...mapGetters({
      workoutLogService: 'singleExercisePageStore/getCurrentWorkoutLogService',
      getCurrentCompletedExercises:
        'workoutCircuitStore/getCurrentCompletedExercises',
      getCurrentRound: 'workoutCircuitStore/getCurrentRound'
    }),
    ...mapMutations({
      initDayCircuit: 'workoutCircuitStore/initDay',
      destroyDayCircuit: 'workoutCircuitStore/destroyDay',
      setExerciseComplete: 'workoutCircuitStore/setExerciseComplete',
      incrementRound: 'workoutCircuitStore/incrementRound',
      decrementRound: 'workoutCircuitStore/decrementRound'
    }),

    navigateNextExerciseCircuit () {
      this.showLoading()
      setTimeout(() => {
        this.setExerciseComplete(parseInt(this.currentExercise.getID()))
        let exerciseToNavIndex = this.getCurrentExerciseIndex() + 1
        if (exerciseToNavIndex >= this.exercises.length) {
          exerciseToNavIndex = 0
          this.incrementRound()
        }
        const exerciseToNav = this.exercises[exerciseToNavIndex].id

        this.$emit('goto-exercise', exerciseToNav)
      }, 500)
    },
    navigatePerviousExerciseCircuit () {
      let exerciseToNavIndex = this.getCurrentExerciseIndex() - 1
      if (exerciseToNavIndex < 0) {
        exerciseToNavIndex = this.exercises.length - 1
        this.decrementRound()
      }
      const exerciseToNav = this.exercises[exerciseToNavIndex].id
      this.$emit('goto-exercise', exerciseToNav)
    },
    completeCircuit () {
      this.workoutLogService()
        .setCompleteDay(parseInt(this.day))
        .then(() => {
          this.showSuccess = true
          setTimeout(() => {
            this.$emit('goto-exercise-listing', this.day)
          }, 500)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getCurrentExerciseIndex () {
      return this.exercises.findIndex((ex) => {
        return ex.id === this.currentExercise.getID()
      })
    },
    getCircuitModeNavText () {
      let currentIndex = this.getCurrentExerciseIndex()
      currentIndex++
      return this.$i18n.t(
        'message["workout-log.group-exercise-current-text-circuit"]',
        [currentIndex, this.exercises.length]
      )
    }
  }
}
</script>
