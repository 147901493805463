<template>
  <div class="gc-card-meal-featured__wrapper">
    <div :class="componentClasses" v-on="$listeners">
      <div
        v-if="imagesSrc.length > 0"
        class="gc-card-meal-featured__image-wrapper row"
      >
        <img
          v-for="(item, index) in imagesSrc"
          :src="item"
          class="gc-card-meal-featured__image col"
          :key="'gc-card-meal-featured__image' + index"
          alt=""
        />
      </div>
      <div :class="componentOverlayClasses"></div>
      <div></div>
      <nutrition-details-bar
        class="container"
        :calories="calories"
        :macroNutrients="macroNutrients"
        v-if="showNutritionCard"
      />
    </div>

    <slot></slot>
  </div>
</template>
<script>
import NutritionDetailsBar from '../bars/BarNutritionDetails.vue'
import {showNutritionInformationFlag} from '../../../includes/TemplateSettings'

export default {
  name: 'CardMealFeatured',
  components: {
    NutritionDetailsBar
  },
  data: function () {
    return {
      showNutritionCard: true
    }
  },
  props: {
    imagesSrc: {
      default: []
    },
    calories: {
      default: []
    },
    macroNutrients: {
      default: []
    }
  },
  beforeMount () {
    this.showNutritionCard = showNutritionInformationFlag()
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-card-meal-featured': true
      }
    },
    componentOverlayClasses: function () {
      return {
        'gc-card-meal-featured__overlay': this.showNutritionCard,
        'gc-card-meal-featured__overlay-no-image': this.imagesSrc.length === 0
      }
    }
  }
}
</script>
<style></style>
