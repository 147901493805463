import BaseAPI from '../BaseAPI.js'
import axios from 'axios'

export default class extends BaseAPI {
  _resource ='subscriptions'

  saveData (subscription, payload) {
    const url = this._getParentURL()
    const params = [url + this._resource, subscription]
    return axios.post(params.join('/'), payload)
  }

  sendCustomData (url, payload) {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest()
      xhr.open('POST', url)
      xhr.setRequestHeader('Authorization', 'Bearer ' + this._token)
      xhr.send(JSON.stringify(payload))
      resolve()
    })
  }
}
