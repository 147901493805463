import AuthService from '../../services/AuthService'

export function checkIsMember () {
  const auth = new AuthService()
  return (!auth.hasRoles() || auth.isMember())
}
export function checkIsCoach () {
  const auth = new AuthService()
  return auth.isCoach()
}
export function checkIsCreator () {
  const auth = new AuthService()
  return auth.isCreator()
}
export function checkNotAMember () {
  return !checkIsMember()
}
