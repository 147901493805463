<template>
  <!--   page background -->
  <div>
    <popup-payment-success
      v-if="successPopup.visibility"
      :title="successPopup.title"
      :visible="successPopup.visibility"
      @close="navigate()"/>
  </div>
</template>

<script>
import PopupPaymentSuccess from '../widgets/PopupPaymentSuccess'
import BaseComponent from '../../../global/base/BaseComponent'

export default {
  name: 'Settings',
  components: {
    PopupPaymentSuccess
  },
  extends: BaseComponent,
  data: function () {
    return {
      pageClass: ['macroapp--page-profile', 'macroapp--page-profile__update_card--success'],
      successPopup: {
        visibility: false,
        title: ''
      },
      headerOptions: {
        show: true,
        left: 'previous',
        right: '',
        rightDisabled: true,
        helpContent: '',
        stripeKey: '',
        modifiers: ['with-bg-color'],
        header: {
          mainTitle: '',
          subTitle: this.$t('message["profile.payment-settings"]')
        }

      }
    }
  },
  mounted () {
    this.setHeader(this.headerOptions)
    this.setPageClass()
    this.setPageData()
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
  },
  methods: {

    setPageData () {
      this.successPopup.title = this.$t('message["popup.payment-success.title"]')
      this.successPopup.visibility = true
      this.hideLoading()
    },
    navigate () {
      this.$router.push({
        path: '/account-details?page=payment-settings'
      })
    }
  }
}
</script>
