<template>
  <page-form>
    <popup-data-invalid :errors="errors" :visible="hasError" v-on:close="navigate()"/>
  </page-form>
</template>

<script>
import NotificationService from '../../../../services/NotificationService'
import BaseComponent from '../global/widgets/forms/FormBaseComponent'
import {mapGetters} from 'vuex'
import PopupDataInvalid from '../../../global/popups/PopupDataInvalid'
import PageForm from '../../../global/pages/PageForm'
import * as Sentry from '@sentry/browser'
import DashboardService from '../../../../services/DashboardService'

export default {
  name: 'Process',
  extends: BaseComponent,
  components: {
    PageForm,
    PopupDataInvalid
  },
  data () {
    return {
      hasError: false,
      errors: []
    }
  },
  beforeMount () {
    this.showLoading()
  },
  mounted () {
    const subscription = this.$route.params.subscription
    const formData = this.getService()

    formData.sendData(subscription).then(data => {
      formData.saveCustomData()
    }).then(() => {
      (new DashboardService()).resetData()
      this.nextComponent()
    }).catch(err => {
      if (err.status === 422) {
        formData.setErrors(err.data.errors)
        this.errors = Object.values(err.data.errors) || []
        this.hasError = true
        this.hideLoading()
      }
      Sentry.captureException(err)
    }).finally(() => {
      let notifications = new NotificationService()
      notifications.resetNotifications()
    })
  },
  methods: {
    ...mapGetters({
      getService: 'formWizardStore/getSubscriptionFormService'
    }),
    navigate () {
      const subscription = this.$route.params.subscription
      this.$router.push({
        path:
          this.$appConfig.appUrlList.onboard.replace(
            ':subscription',
            subscription
          ) + '/1'
      })
    }
  }
}
</script>

<style scoped>
</style>
