<template>
  <div
    :class="componentClasses"
    v-on="$listeners"
    @click="clicked()">
    <icon-add-more
      v-if="state === 'active'"
      :size="'sm2'"
      class="gc-meal__action-icon-active"
    />
    <icon-add-more-rounded
      v-else
      :size="'sm2'"
      class="gc-meal__action-icon"
    />
    <text-body-small
      v-if="showText"
      class="gc-meal__action-text"
      :lineHeight="'multi'" >
      {{ text }}
    </text-body-small>
  </div>
</template>

<script>
import IconAddMore from '../../root/icons/IconAddMore'
import IconAddMoreRounded from '../../root/icons/IconAddMoreRounded'
import TextBodySmall from '../typography/TextBodySmall'

export default {
  name: 'ActionMealLogMeal',
  components: {
    TextBodySmall,
    IconAddMore,
    IconAddMoreRounded
  },
  props:
    {
      state: {
        type: String,
        default: ''
      },
      showText: {
        type: Boolean,
        default: true
      },
      limitText: {
        type: Boolean,
        default: false

      }
    },
  computed: {
    componentClasses: function () {
      return {
        'gc-meal__action': true,
        'gc-meal__action-log-meal': true,
        'gc-meal__action-disabled': this.state === 'disabled',
        'gc-meal__action-onTap': this.state === 'onTap',
        'gc-meal__action-active': this.state === 'active'
      }
    },
    text: function () {
      let text = this.$i18n.t('message["meal-plan.log-meal"]')
      if (this.state === 'active') {
        return this.$i18n.t('message["meal-plan.logged-meal"]')
      }
      if (this.limitText) {
        text = text.substring(0, 2) + '...'
      }
      return text
    }
  },
  methods: {
    clicked () {
      this.$emit('clicked')
    }
  }
}
</script>
<style scoped>
</style>
