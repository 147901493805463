<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.5 10C12.3284 10 13 9.32843 13 8.5C13 7.67157 12.3284 7 11.5 7C10.6716 7 10 7.67157 10 8.5C10 9.32843 10.6716 10 11.5 10Z" fill="white"/>
      <path d="M17.9481 3.07855L7.50555 1.02318C7.16944 0.957032 6.82207 1.03378 6.53985 1.23654C6.25763 1.43929 6.06368 1.75146 6.00067 2.10435L5.45916 5.13732H3.28938C3.12004 5.13732 2.95237 5.17234 2.79592 5.24039C2.63948 5.30843 2.49733 5.40816 2.3776 5.53389C2.25787 5.65961 2.1629 5.80887 2.09811 5.97313C2.03332 6.1374 1.99998 6.31345 2 6.49124V17.6465C2.00006 18.0055 2.13593 18.3497 2.37773 18.6036C2.61953 18.8574 2.94745 19 3.28938 19H13.9139C14.2559 19 14.5839 18.8574 14.8257 18.6035C15.0675 18.3496 15.2033 18.0053 15.2033 17.6462V16.6425L15.5154 16.7039C15.6818 16.7367 15.8528 16.7347 16.0185 16.6981C16.1842 16.6615 16.3414 16.591 16.4811 16.4906C16.6209 16.3902 16.7404 16.2619 16.8329 16.113C16.9254 15.9641 16.9891 15.7975 17.0203 15.6227L18.9779 4.65859C19.0091 4.48385 19.0072 4.30437 18.9724 4.13039C18.9375 3.95642 18.8703 3.79136 18.7747 3.64464C18.6791 3.49791 18.5569 3.37241 18.415 3.27528C18.2732 3.17815 18.1146 3.1113 17.9481 3.07855ZM2.83332 12.7627V7.02211C2.83332 6.75425 2.93467 6.49735 3.11507 6.30794C3.29547 6.11854 3.54014 6.01213 3.79526 6.01213H13.4674C13.7067 6.01213 13.9363 6.11197 14.1056 6.28969C14.2748 6.46741 14.3699 6.70844 14.3699 6.95978V15.5499L10.3333 11.5772L8.86123 12.5201L6.60834 10.1546L2.83332 12.7627ZM18.0765 4.95816L16.5691 13.4011L15.2032 11.4441V6.49124C15.2032 6.1322 15.0674 5.78786 14.8256 5.53398C14.5838 5.28009 14.2558 5.13746 13.9139 5.13746H6.30698L6.72641 2.78759C6.74968 2.65722 6.79719 2.53294 6.86622 2.42185C6.93524 2.31076 7.02443 2.21503 7.12869 2.14013C7.23295 2.06523 7.35025 2.01262 7.47387 1.98532C7.5975 1.95801 7.72503 1.95654 7.8492 1.98099L17.3558 3.85213C17.4722 3.87506 17.5833 3.92186 17.6826 3.98985C17.7818 4.05785 17.8674 4.1457 17.9343 4.24841C18.0013 4.35111 18.0483 4.46666 18.0727 4.58844C18.0971 4.71022 18.0984 4.83585 18.0765 4.95816Z" fill="white"/>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconPhotos',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-photos': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
