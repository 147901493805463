<template>
<div :class="componentClasses" @click="clicked()">
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_21751_29250)">
      <path d="M6.85313 20C7.58199 20 8.29375 19.8203 8.96834 19.466C9.61443 19.127 10.3858 19.1268 11.0319 19.4662C11.7063 19.8203 12.418 20 13.1471 20C16.4866 20 19 13.3178 19 8.98477C19 5.12364 16.9214 2.99708 13.1471 2.99708C12.2059 2.99708 11.27 3.30752 10.4404 3.89466C10.5553 2.77303 10.9572 0.986285 12.0114 0.793365C12.23 0.753326 12.3748 0.544733 12.3344 0.327545C12.2941 0.110162 12.0828 -0.033197 11.8655 0.00664673C10.152 0.320221 9.73248 2.84119 9.63056 3.93033C9.60893 3.91802 9.58489 3.91207 9.56429 3.89747C8.73339 3.3084 7.79591 2.99708 6.85313 2.99708C3.07865 2.99708 1 5.12364 1 8.98477C1 13.3178 3.51337 20 6.85313 20ZM6.85313 3.79708C7.63822 3.79708 8.39304 4.05 9.09654 4.54864C9.36296 4.73758 9.67462 4.83235 9.98693 4.83497C9.98815 4.83501 9.98914 4.83572 9.99037 4.83575C9.99349 4.83592 9.99649 4.83592 9.99956 4.83592C10.3164 4.83599 10.6334 4.74024 10.9039 4.54864C11.6072 4.05 12.362 3.79708 13.1471 3.79708C16.4965 3.79708 18.1947 5.54239 18.1947 8.98477C18.1947 12.9254 15.8846 19.2 13.1471 19.2C12.5505 19.2 11.9656 19.0516 11.4082 18.7588C10.9672 18.5273 10.4837 18.4115 10 18.4115C9.51652 18.4115 9.03264 18.5273 8.59202 18.7588C8.03461 19.0516 7.44947 19.2 6.85313 19.2C4.11541 19.2 1.80535 12.9254 1.80535 8.98477C1.80535 5.54239 3.50374 3.79708 6.85313 3.79708Z" fill="black"/>
      <path d="M6.85313 20C7.58199 20 8.29375 19.8203 8.96834 19.466C9.61443 19.127 10.3858 19.1268 11.0319 19.4662C11.7063 19.8203 12.418 20 13.1471 20C16.4866 20 19 13.3178 19 8.98477C19 5.12364 16.9214 2.99708 13.1471 2.99708C12.2059 2.99708 11.27 3.30752 10.4404 3.89466C10.5553 2.77303 10.9572 0.986285 12.0114 0.793365C12.23 0.753326 12.3748 0.544733 12.3344 0.327545C12.2941 0.110162 12.0828 -0.033197 11.8655 0.00664672C10.152 0.320221 9.73248 2.84119 9.63056 3.93033C9.60893 3.91802 9.58489 3.91207 9.56429 3.89747C8.73339 3.3084 7.79591 2.99708 6.85313 2.99708C3.07865 2.99708 1 5.12364 1 8.98477C1 13.3178 3.51337 20 6.85313 20Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_21751_29250">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconMealPlanFilled',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-meal-plan-filled': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
