<template>
    <div :class="componentClasses">
      <div :class="{'gc-input-group-ingredients-item__wrapper--dislike': isDislike}" class="gc-input-group-ingredients-item__wrapper" @click="dislike()">
        <div class="gc-input-group-ingredients-item__square-radio" v-if="!isDislike">
        </div>
        <div class="gc-input-group-ingredients-item__dislike-radio" v-else>
          <icon-dislike :size="'sm4'" />
        </div>
        <div class="gc-input-group-ingredients-item__title">
          <text-content :weight="'bold'" :lineHeight="'multi'">{{name}}</text-content>
        </div>
      </div>
    </div>
</template>
<script>
import TextContent from '../../../root/TextContent'
import IconDislike from '../../../root/icons/IconDislike'
export default {
  name: 'InputGroupIngredientsItem',
  props: {
    id: {},
    name: {},
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TextContent,
    IconDislike
  },
  data () {
    return {
      isDislike: false
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-input-group-ingredients-item': true
      }
    }
  },
  methods: {
    getID () {
      return this.id
    },
    dislike () {
      if (this.disabled) {
        return
      }

      if (this.isDislike) {
        this.unsetDisliked()
      } else {
        this.setDisliked()
      }

      this.emitDislike()
    },
    emitDislike () {
      this.$emit('dislike', {id: this.id, status: this.isDislike})
    },
    setDisliked () {
      this.isDislike = true
    },

    unsetDisliked () {
      this.isDislike = false
    },
    setDislikedAndEmit () {
      this.isDislike = true
      this.emitDislike()
    },

    unsetDislikedAndEmit () {
      this.isDislike = false
      this.emitDislike()
    }
  }
}
</script>
