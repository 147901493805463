import EventHandlerAPI from './custom-api/EventHandlerAPI'

export default class NotificationPopupService {
  constructor () {
    if (!NotificationPopupService.instance) {
      NotificationPopupService.instance = this
    }
    return NotificationPopupService.instance
  }

  setMemberNotification (type, customerId, payload) {
    const api = new EventHandlerAPI()
    return new Promise((resolve, reject) => {
      api.submitMessage(JSON.stringify({
        customer: customerId,
        type: type,
        message: payload
      }))
        .then((res) => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
    })
  }

  getMemberNotifications () {
    const api = new EventHandlerAPI()
    return new Promise((resolve, reject) => {
      api.getMessages()
        .then((res) => {
          resolve(res.data.messages)
        }).catch(err => {
          reject(err)
        })
    })
  }
}
