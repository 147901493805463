<template>
  <div
    :class="{'gc-navigator-bottom-bar__link--active': active }"
    class="gc-navigator-bottom-bar__link gc-navigator-bottom-bar__link--add"
  >
    <div class="gc-navigator-bottom-bar__icon--add">
      <icon-add-menu-filled
        v-if="!active"
        :class="iconClasses"
        @click="iconClicked()"
      />
      <icon-add-menu-filled
        v-else
        :class="iconClasses"
        @click="iconClicked()"
      />
      <icon-underline/>
    </div>
  </div>
</template>
<script>
import IconAddMenuLinear from '../../../root/icons/IconAddMenuLinear'
import IconAddMenuFilled from '../../../root/icons/IconAddMenuFilled'
import IconUnderline from '@/components/root/icons/IconUnderline.vue'

export default {
  name: 'NavigatorBottomBarLinkAdd',
  components: {IconUnderline, IconAddMenuLinear, IconAddMenuFilled},
  data: function () {
    return {
      active: false,
      iconClasses: ['gc-navigator-bottom-bar__link-icon']
    }
  },
  watch: {
    active: function (value) {
      if (value) {
        this.iconClasses = [
          'gc-navigator-bottom-bar__link-icon',
          'gc-navigator-bottom-bar__link-icon--active'
        ]
      } else {
        this.iconClasses = [
          'gc-navigator-bottom-bar__link-icon',
          'gc-navigator-bottom-bar__link-icon'
        ]
      }
    }
  },
  methods: {
    iconClicked () {
      this.active = !this.active
      this.$emit('active', this.active)
    },
    setInactive () {
      this.active = false
    }
  }
}
</script>
