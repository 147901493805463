<template>
  <popup
    ref="upsellConfirmationModal"
    :visible="visible"
    v-if="visible"
    @hide="confirm(false)"
  >
    <popup-title>
      <popup-button-close @click="confirm(false)" />

    </popup-title>

    <popup-body>
      <popup-content class="text-center">
          <popup-text-heading>{{title}}</popup-text-heading>
          <loading-buffer v-if="status==='loading'"></loading-buffer>
          <div class="text-center" v-if="status==='success'">
            <icon-complete-filled :size="'lg3'" />
          </div>

      </popup-content>
      <popup-footer>
          <button-primary  class="mb-2" v-if="confirmText"  :text="confirmText" v-on:click="confirm(true)"/>
          <button-secondary v-if="declineText" :text="declineText" @click="confirm(false)" />
      </popup-footer>
    </popup-body>
  </popup>
</template>

<script>
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import BaseComponent from '../../global/base/BaseComponent'
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import IconCompleteFilled from '../../../../root/icons/IconCompleteFilled'
import Popup from '../../../../global/popups/Popup'
import ButtonSecondary from '../../../../global/buttons/ButtonSecondary'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupFooter from '../../../../global/popups/popup-sub-components/PopupFooter'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'

export default {
  name: 'upsell-popup',
  props: ['data', 'visible'],
  mixins: [popupAnimationMixin],
  components: {
    ButtonSecondary,
    ButtonPrimary,
    PopupTextHeading,
    PopupFooter,
    PopupContent,
    PopupBody,
    PopupButtonClose,
    PopupTitle,
    Popup,
    LoadingBuffer,
    IconCompleteFilled
  },
  extends: BaseComponent,
  data: function () {
    return {
      status: 'loading',
      loading: true,
      title: '',
      successMsg: 'success',
      confirmText: '',
      declineText: '',
      errorMessage: 'Error'
    }
  },
  created () {
    this.title = this.$i18n.t('message["' + this.data.message.lang + '"]', this.data.message.vars)
    this.status = this.data.status
    this.confirmText = ''
    this.declineText = ''
  },
  watch: {
    data: function (newData, oldData) {
      this.title = this.$i18n.t('message["' + this.data.message.lang + '"]', this.data.message.vars)
      this.status = newData.status
      this.confirmText = this.$i18n.t('message["' + newData.confirmText + '"]')
      this.declineText = newData.declineText ? this.$i18n.t('message["' + newData.declineText + '"]') : null
      if (!newData.confirmText) {
        this.confirmText = ''
      }
      if (!newData.declineText) {
        this.declineText = ''
      }
    }
  },
  methods: {
    closePopup () {
      this.closeAnimatedPopup(() => {
        this.$emit('hide')
      })
    },
    confirm (confirmation) {
      this.$emit('confirmation', confirmation)
      if (!confirmation || (confirmation && !this.declineText)) {
        this.closePopup()
      }
    }
  }
}
</script>
