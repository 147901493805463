<template>
  <button-base :borderRadius="'sm1'" :class="componentClasses" :type="'solid'" @click="clicked()">
    <div class="gc-button-primary-accordion__left-section">
      <slot class="gc-button-primary-accordion__left-section-icon"></slot>
      <div class="gc-button-primary-accordion__text-section">
        <text-content :class="textClass" :line-height="'multi'" :size="'sm4'" :weight="'extra-bold'"> {{ text }}</text-content>
        <text-content  :size="'sm1'" :line-height="'multi'" v-if="hasSubText"> {{ subText }}</text-content>
      </div>
    </div>
    <div class="gc-button-primary-accordion__right-section">
      <icon-forward></icon-forward>
    </div>
  </button-base>
</template>

<script>

import TextContent from '../../root/TextContent'
import ButtonBase from '../../root/ButtonBase'
import IconForward from '../../root/icons/IconForward'

export default {
  name: 'ButtonPrimaryAccordion',
  components: {
    ButtonBase,
    TextContent,
    IconForward
  },
  props: {
    /**
     * The main text of the button
     */
    text: {
      default: ''
    },
    /**
     * The sub text of the button
     */
    subText: {
      default: ''
    },
    /**
     * The disabled state of the button
     */
    disabled: {
      default: false
    },
    selected: {
      default: false
    }
  },
  data () {
    return {
      stateSelected: this.selected
    }
  },
  watch: {
    selected: function () {
      this.stateSelected = this.selected
    }
  },
  computed: {
    hasSubText: function () {
      return this.subText.replace(/\s/g, '') !== ''
    },
    hasIcon () {
      return !!this.$slots.default
    },
    textClass: function () {
      return {
        'gc-button-primary-accordion__text': true,
        'gc-button-primary-accordion__text--with-sub-text': this.hasSubText
      }
    },
    subTextClass: function () {
      return {
        'gc-button-primary-accordion__sub-text': this.hasSubText
      }
    },
    componentClasses: function () {
      return {
        'gc-button-primary-accordion': true,
        'gc-button-primary-accordion--with-sub-text': this.hasSubText,
        'gc-button-primary-accordion--disabled': this.disabled,
        'gc-button-primary-accordion--selected': this.stateSelected
      }
    }
  },
  methods: {
    /**
     * function to emit the click event
     */
    clicked () {
      if (!this.disabled) {
        this.$emit('click')
        if (!this.stateSelected) {
          this.stateSelected = true
        } else {
          this.stateSelected = false
        }
      }
    }
  }
}
</script>

<style scoped></style>
