import NativeAppService from '../services/NativeAppService'
import { isMobileNativeApp } from '../includes/NativeAppCommon'

export default {
  methods: {
    handleUrlNavigate (url) {
      if (url && url.toLowerCase().startsWith('mailto:')) {
        window.open(url)
      } else if (url && url.startsWith('/') && !url.startsWith('//')) {
        this.$router.push(url)
      } else if (isMobileNativeApp() && window.ReactNativeWebView) {
        const nativeAppService = new NativeAppService()
        if (nativeAppService.inAppBrowsing) {
          const vimeoRegex = /vimeo.*(?:\/|clip_id=)([0-9a-z]*)/i
          if (url.match(vimeoRegex)) {
            url = window.location.origin + '/video/view?q=' + url
          }
          nativeAppService.showAppBrowser(url)
        } else {
          window.open(url)
        }
      } else {
        const vimeoRegex = /vimeo.*(?:\/|clip_id=)([0-9a-z]*)/i
        if (url.match(vimeoRegex)) {
          url = window.location.origin + '/video/view?q=' + url
        }
        window.open(url)
      }
    }
  }
}
