import { extend } from 'vee-validate'
const validator = {

  validate (value, args) {
    const inputDate = new Date(value)
    const minDate = new Date(args[0])
    const maxDate = new Date(args[1])
    return minDate <= inputDate && maxDate >= inputDate
  }
}
extend('date_between', validator)
