/**
 * Base API Class that can be use to connect the apis
 */
import DefineInclude from '../../DefineInclude'
import AuthService from '../AuthService'
import axios from 'axios'

export default class EventHandlerAPI {
  _baseURL = ''
  auth = null
  constructor () {
    this._baseURL = DefineInclude.eventAPIUrl
    this._token = ''
    this.auth = new AuthService()
  }

  trackEvent (payload) {
    let url = this._baseURL + ['automations', 'track'].join('/')
    return this._post(payload, url)
  }

  trackEventWithEligibilityCheck (payload) {
    let url = this._baseURL + ['automations', 'track', 'is_eligible'].join('/')
    return this._post(payload, url)
  }

  trackAutomationEvent (payload) {
    let url = this._baseURL + ['automations', 'is_eligible'].join('/')
    return this._post(payload, url)
  }

  getMessages () {
    let url = this._baseURL + ['messages'].join('/')
    return axios.get(url, { headers: this._getCustomHeaders() })
  }

  submitMessage (payload) {
    let url = this._baseURL + ['messages'].join('/')
    return this._post(payload, url)
  }

  _post (payload, url) {
    return axios.post(url, payload, { headers: this._getCustomHeaders() })
  }
  _getCustomHeaders () {
    let headers = {}
    headers['Authorization'] = 'Bearer ' + this._token
    headers['X-Api-Host'] = DefineInclude.apiUrl.replace(/\/$/, '')

    return headers
  }
}
