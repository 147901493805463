<template>
  <page :header="headerOptions" class="gc-page-injuries">
    <page-container>
    <ValidationObserver name="injuries" ref="form" tag="div" v-slot="{ handleSubmit }">
      <form id="form-input-injuries-prevent-exercising" @submit.prevent="handleSubmit(nextComponent)">
        <div class="gc-page-injuries__header-wrapper">
          <text-heading4 class="gc-page-injuries__title" :weight="'extra-bold'" :lineHeight="'multi'">{{title.main}}</text-heading4>
          <text-body-extra-small class="gc-page-injuries__sub-title" :weight="'extra-bold'" :lineHeight="'multi'">{{title.sub}}</text-body-extra-small>
        </div>
        <ValidationProvider :vid="'Injuries'"
                            :name="title.main"
                            tag="div"
                            v-slot="{ errors }">
          <input-multi-select
            v-on:change="setFormData"
            v-model="inputValue"
            :options="options"
            @change="inputChange()"
            :placeHolder="$t('message[\'general.enter-your-injuries\']')"/>
          <text-body-extra-small class="gc-page-injuries__error-message">{{errors[0]}}</text-body-extra-small>
        </ValidationProvider>
      </form>
    </ValidationObserver>
    </page-container>
    <page-footer ref="footer" :form="'form-input-injuries-prevent-exercising'" :validated="invalid"></page-footer>
  </page>
</template>

<script>
import BaseComponent from '../../layout/template-1/global/widgets/forms/FormBaseComponent'
import inputMultiSelect from './../inputs/InputMultiSelect'
import TextHeading4 from '../typography/TextHeading4'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import Page from './PageForm'
import PageContainer from './page-sub-components/PageContainer'
import PageFooter from './page-sub-components/PageFormFooter'

export default {
  name: 'InjuriesPreventExercising',
  extends: BaseComponent,
  components: {
    PageFooter,
    PageContainer,
    Page,
    TextBodyExtraSmall,
    TextHeading4,
    inputMultiSelect
  },
  data: function () {
    return {
      headerOptions: {
        show: false,
        left: 'previous',
        right: '',
        modifiers: ['with-img'],
        mainTitle: '',
        subTitle: '',
        isTransparent: true
      }
    }
  },
  created () {
    this.pageClass = ['page-injuries']
  },
  mounted () {
    this.hideLoading()
  },
  watch: {
    childValue: function (val) {
      this.setFormData()
    }
  },
  methods: {
    inputChange () {
      this.$refs.footer.setFooterClass()
    }
  }
}
</script>

<style scoped>

</style>
