var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gc-header-steps"},_vm._l((_vm.steps),function(step,index){return _c('div',{key:index,class:[
    _vm.stepClasses,
      {
        'gc-header-step--completed': step.status === 'completed',
        'gc-header-step--active': step.active,
      }
    ]},[_c('div',{staticClass:"gc-header-step__circle"},[(step.status === 'completed')?_c('icon-tick',{staticClass:"gc-header-step__icon",attrs:{"size":'sm1'}}):_vm._e(),(step.status !== 'completed')?_c('div',{staticClass:"gc-header-step__count"},[_vm._v(" "+_vm._s(step.number)+" ")]):_vm._e()],1),_c('text-body-extra-small',{staticClass:"gc-header-step__description",attrs:{"line-height":'multi'}},[_vm._v(" "+_vm._s(step.text)+" ")])],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }