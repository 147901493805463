<template>
  <popup
    :visible="true"
    @hide="closePopup"
    modal-class="gc-popup gc-popup-server-error gc-popup-chat-not-supported"

  >
    <popup-title>
      <popup-button-close @click="closePopup"></popup-button-close>
    </popup-title>
    <popup-body class="text-center">
      <popup-content>
        <popup-head>
          <icon-server-error class="gc-popup-chat-not-supported__header-icon" :size="'lg3'"></icon-server-error>
          <popup-text-heading  class="gc-popup-chat-not-supported__title">{{$i18n.t("message['chat.popup-not-supported.title']")}}</popup-text-heading>
        </popup-head>
        <popup-text-body class="gc-popup-chat-not-supported__description">
          <text-content :size="'sm4'" :weight="'extra-bold'" :lineHeight="'multi'" v-html="$i18n.t('message[\'chat.popup-not-supported.description-1\']',)"></text-content>
        </popup-text-body>
        <popup-text-body class="gc-popup-chat-not-supported__description">
          <text-content :size="'sm4'" :weight="'extra-bold'" :lineHeight="'multi'" v-html="$i18n.t('message[\'chat.popup-not-supported.description-2\']',{url: url})">{{$i18n.t("message['chat.popup-not-supported.description-2']")}}</text-content>
        </popup-text-body>

        <popup-text-body class="gc-popup-chat-not-supported__description">
          <text-content :size="'sm4'" :weight="'extra-bold'" :lineHeight="'multi'" v-html="$i18n.t('message[\'chat.popup-not-supported.description-3\']',{email: supportEmail})" >{{$i18n.t("message['chat.popup-not-supported.description-3']")}}</text-content>
        </popup-text-body>

      </popup-content>
      <popup-footer>
        <button-secondary :text="$i18n.t('message[\'settings.contact-support\']')" @click="navigateToContact()"></button-secondary>
      </popup-footer>
    </popup-body>

  </popup>
</template>

<script>

import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import ButtonPrimary from '../../global/buttons/ButtonPrimary'
import PopupFooter from '../../global/popups/popup-sub-components/PopupFooter'
import PopupTextBody from '../../global/popups/popup-sub-components/PopupTextBody'
import PopupTextHeading from '../../global/popups/popup-sub-components/PopupTextHeading'
import PopupHead from '../../global/popups/popup-sub-components/PopupHead'
import PopupContent from '../../global/popups/popup-sub-components/PopupContent'
import PopupBody from '../../global/popups/popup-sub-components/PopupBody'
import PopupButtonClose from '../../global/popups/popup-sub-components/PopupButtonClose'
import PopupTitle from '../../global/popups/popup-sub-components/PopupTitle'
import Popup from '../../global/popups/Popup'
import TextContent from '../../root/TextContent'
import ButtonSecondary from '../../global/buttons/ButtonSecondary'
import IconServerError from '../../root/icons/IconServerError'
import ProfileService from '../../../services/ProfileService'

export default {
  name: 'PopupChatNotSupported',
  mixins: [popupAnimationMixin],
  components: {
    ButtonPrimary,
    PopupFooter,
    PopupTextBody,
    PopupTextHeading,
    PopupHead,
    PopupContent,
    PopupBody,
    PopupButtonClose,
    PopupTitle,
    Popup,
    TextContent,
    ButtonSecondary,
    IconServerError
  },
  data: function () {
    return {
      imagesUrl: this.$appConfig.imagesUrl,
      supportEmailAddress: 'email'
    }
  },
  watch: {
    visible: function () {
      if (this.visible) {
        this.hideLoading()
      }
    }
  },
  computed: {
    url () {
      return `<a href="${location.origin}">${location.origin}</a>`
    },
    supportEmail () {
      const email = this.supportEmailAddress
      return `<a href="mailto:${email}">${email}</a>`
    }
  },
  mounted () {
    let profile = new ProfileService()
    profile.setProfileData().then(() => {
      this.supportEmailAddress = profile.getSupportEmail()
    })
  },
  methods: {
    closePopup () {
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    },

    navigateToContact () {
      this.delayedRouterPush({
        path: this.$appConfig.appUrlList.contact
      })
      this.closePopup()
    }
  }
}
</script>

<style scoped>
</style>
