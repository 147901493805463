<template>
  <button-base :class="componentClasses" @click="clicked()">
    <text-content :class="textClass" :line-height="'multi'" :size="'sm4'" :weight="'bold'"> {{ text }}</text-content>
    <text-content :class="subTextClass" :line-height="'multi'" :size="'sm1'"> {{ subText }}</text-content>
    <div :class="{'gc-button-standard__icon':hasIcon}">
      <slot></slot>
    </div>
  </button-base>
</template>

<script>

import TextContent from '../../root/TextContent'
import ButtonBase from '../../root/ButtonBase'

export default {
  name: 'ButtonStandard',
  components: {
    ButtonBase,
    TextContent
  },
  props: {
    /**
     * The main text of the button
     */
    text: {
      default: ''
    },
    /**
     * The sub text of the button
     */
    subText: {
      default: ''
    },
    /**
     * The type of the button
     * Expected values: "solid" | "outline"
     */
    type: {
      default: 'solid'
    },
    /**
     * The disabled state of the button
     */
    disabled: {
      default: false
    }
  },
  computed: {
    hasSubText: function () {
      return this.subText.replace(/\s/g, '') !== ''
    },
    hasIcon () {
      return !!this.$slots.default
    },
    textClass: function () {
      return {
        'gc-button-standard__text': true,
        'gc-button-standard__text--with-sub-text': this.hasSubText
      }
    },
    subTextClass: function () {
      return {
        'gc-button-standard__sub-text': this.hasSubText
      }
    },
    componentClasses: function () {
      return {
        'gc-button-standard': true,
        'gc-button-standard--with-sub-text': this.hasSubText,
        'gc-button-standard--disabled': this.disabled,
        'gc-button-standard--type-solid': this.type === 'solid',
        'gc-button-standard--type-outline': this.type === 'outline'
      }
    }
  },
  methods: {
    /**
     * function to emit the click event
     */
    clicked () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped></style>
