<template>
  <text-body-extra-small
    class="pc-sign-up__text-error"
    line-height="lh-lg"
    v-on="$listeners"
  >
    <slot />
  </text-body-extra-small>
</template>

<script>
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
export default {
  name: 'TextError',
  components: { TextBodyExtraSmall }
}
</script>

<style scoped>

</style>
