<template>
    <div  class="gc-video-player-seek-bar">
        <p v-text="currentTime" class="gc-video-player-seek-bar__timer timer"/>
        <input type="range"
               ref="slider"
               min="1" max="10000"
               v-model="sliderValueSmooth"
               :style="sliderStyle"
               class="gc-video-player-seek-bar__slider"
               @input="onInput"
               @change="onSeek"/>
    </div>
</template>
<script>
import { isChrome, isFirefox, isSafari } from 'mobile-device-detect'
import timeFormatHelper from '../../../../services/helpers/TimeFormatHelper'
import { isMobileNativeApp } from '../../../../includes/NativeAppCommon'
import { BFormInput } from 'bootstrap-vue'

export default {
  name: 'VideoPlayerSeekBar',
  props: {
    showControllers: {
      default: true
    },
    current: {
      default: 0
    },
    videoDuration: {
      default: 0
    }
  },
  components: {
    BFormInput
  },
  watch: {
    sliderValue: function () {
      if (!this.seeking) {
        this.sliderValueSmooth = this.sliderValue * 100
      }
    },
    current: function () {
      return this.current
    }
  },
  computed: {
    videoSeekBarClasses () {
      return {
        'gc-video-player-seek-bar': true,
        'gc-video-player-seek-bar--moz': isFirefox,
        'gc-video-player-seek-bar--webkit': isChrome || isSafari || isMobileNativeApp()
      }
    },
    sliderStyle () {
      let sliderValue = this.sliderValueSmooth / 100
      let colorFinished = templateConfig.style_variables['$button-primary-fill'] || '#ffffff'
      let colorPending = colorFinished + '80'
      return {
        background: `linear-gradient(90deg, ${colorFinished} ${sliderValue}%, ${colorPending} ${sliderValue}%)`

      }
    }
  },
  data: function () {
    return {
      seeking: false,
      sliderValue: 0,
      sliderValueSmooth: 0,
      default: 0,
      currentTime: '00:00',
      currentSeekBarPosition: 0
    }
  },
  methods: {
    onInput () {
      this.seeking = true
    },
    resetSeekBarData () {
      this.sliderValue = 0
      this.currentSeekBarPosition = 0
      this.secondsRef = 0
      this.currentTime = '00:00'
    },
    onWindowResize () {
      if (this.currentSeekBarPosition !== this.sliderValue) {
        this.sliderValue = this.currentSeekBarPosition
      }
    },
    timeUpdateEvent ({ percent, seconds }) {
      if (this.seeking) {
        return
      }
      const { time } = timeFormatHelper.secToMinSec(seconds)
      if (percent === 1) {
        this.currentSeekBarPosition = 100
        this.sliderValue = 100
        this.currentTime = time
      } else if (percent * 100 !== this.sliderValueSmooth) {
        // timeupdate event gets triggered every 250ms in general (https://developer.vimeo.com/player/sdk/reference#timeupdate)
        // This condition is to make sure the UI gets update only once a second
        this.currentSeekBarPosition = percent * 100
        if (this.currentSeekBarPosition < 100) {
          this.sliderValue = this.currentSeekBarPosition
          this.sliderValueSmooth = this.sliderValue * 100
        }
        this.currentTime = time
      }
    },
    onSeek () {
      this.seeking = false
      this.sliderValue = this.sliderValueSmooth / 100
      if (this.sliderValue > 99) {
        this.sliderValue = 99
      } else if (this.sliderValue < 0) {
        this.sliderValue = 0
      }
      this.$emit('onSeek', this.sliderValue)
    }

  }
}
</script>
