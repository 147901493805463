import { mapGetters } from 'vuex'
import moment from 'moment'
import lodash from 'lodash'

export default {
  props: {
    isEditable: {
      default: true
    }
  },
  data () {
    return {
      state: 'init',
      uploadPopup: {
        show: false
      },
      first: {
        date: '',
        images: {
          front: { image: '', show: false, state: 'loading' },
          side: { image: '', show: false, state: 'loading' },
          back: { image: '', show: false, state: 'loading' }
        }
      },
      latest: {
        date: '',
        images: {
          front: { image: '', show: false, state: 'loading' },
          side: { image: '', show: false, state: 'loading' },
          back: { image: '', show: false, state: 'loading' }
        }
      },
      dateFormat: ''
    }
  },
  mounted () {
    this.setPageData()
  },
  computed: {
    showFirstImages () {
      return this.first.date && Object.values(this.first.images).some(image => {
        return image.show && image.image
      })
    },
    showLatestImages () {
      return this.latest.date && (this.latest.date !== this.first.date) && Object.values(this.latest.images).some(image => {
        return image.show && image.image
      })
    },
    ...mapGetters({
      service: 'fitnessDiaryStore/getService'
    })
  },
  methods: {
    hideFirstImage (index) {
      this.first.images[index].show = false
    },
    hideLatestImage (index) {
      this.latest.images[index].show = false
    },
    hideLoadingFirstImage (index) {
      this.first.images[index].state = 'loaded'
    },
    hideLoadingLatestImage (index) {
      this.latest.images[index].state = 'loaded'
    },
    showPhotoUpload () {
      this.uploadPopup.show = true
    },
    hidePhotoUpload () {
      this.uploadPopup.show = false
    },
    viewMore () {
      this.$router.push({
        path: this.$route.path,
        query: { page: 'photo-journey' }
      })
    },
    setDateFormat (firstDate) {
      const firstDateObj = moment(firstDate)
      const nowObj = moment()

      // if greater than month
      if (nowObj.diff(firstDateObj, 'days') > 30) {
        this.dateFormat = 'MMM YYYY'
      } else {
        this.dateFormat = 'll'
      }
    },
    photosUploaded (event) {
      this.setImageData(event)
    },
    setPageData (event = null) {
      if (event) {
        this.sendPhotoUploadedEmail(event)
      }
      this.state = 'init'
      if (this.first.date === this.latest.date) {
        this.first = {
          date: '',
          images: {
            front: { image: '', show: false, state: 'loading' },
            side: { image: '', show: false, state: 'loading' },
            back: { image: '', show: false, state: 'loading' }
          }
        }
      }
      this.latest = {
        date: '',
        images: {
          front: { image: '', show: false, state: 'loading' },
          side: { image: '', show: false, state: 'loading' },
          back: { image: '', show: false, state: 'loading' }
        }
      }
      Promise.all([this.service.getDataFromAPI()])
        .then(() => {
          this.setImageData()
        })
        .finally(() => {
          this.state = 'loaded'
          this.hideLoading()
        })
    },
    sendPhotoUploadedEmail (event) {
      this.service.sendPhotoUploadedEmail(event)
    },
    setImageData () {
      const images = this.service.images
      const dates = Object.values(images).filter((image) => {
        return !!image.firstTime.date
      }).map(image => {
        return new Date(image.firstTime.date)
      })
      const newDates = Object.values(images).filter((image) => {
        return !!image.latestDate
      }).map(image => {
        return new Date(image.latestDate)
      })

      let firstDate = lodash.min(dates) || new Date()
      let latestDate = lodash.max(newDates) || new Date()
      firstDate = moment(firstDate).format('YYYY-MM-DD')
      latestDate = moment(latestDate).format('YYYY-MM-DD')
      if (firstDate) {
        this.setDateFormat(firstDate)
        const firstDateObj = moment(firstDate)
        this.first.date = firstDateObj.format(this.dateFormat)
      }
      if (moment(firstDate).format('YYYY-MM-DD') === moment(images['front-image'].firstTime.date).format('YYYY-MM-DD')) {
        this.first.images.front.image = images['front-image'].firstTime.image
        this.first.images.front.show = true
      }
      if (moment(firstDate).format('YYYY-MM-DD') === moment(images['side-image'].firstTime.date).format('YYYY-MM-DD')) {
        this.first.images.side.image = images['side-image'].firstTime.image
        this.first.images.side.show = true
      }
      if (moment(firstDate).format('YYYY-MM-DD') === moment(images['back-image'].firstTime.date).format('YYYY-MM-DD')) {
        this.first.images.back.image = images['back-image'].firstTime.image
        this.first.images.back.show = true
      }

      if (latestDate) {
        const latestDateObj = moment(latestDate)
        this.latest.date = latestDateObj.format(this.dateFormat)
      }

      this.latest.images.front.image = ''
      this.latest.images.front.show = false
      this.latest.images.side.image = ''
      this.latest.images.side.show = false
      this.latest.images.back.image = ''
      this.latest.images.back.show = false
      if (moment(firstDate).format('YYYY-MM-DD') !== moment(latestDate).format('YYYY-MM-DD') && moment(latestDate).format('YYYY-MM-DD') === moment(images['front-image'].latestDate).format('YYYY-MM-DD')) {
        this.latest.images.front.image = images['front-image'].currentImage
        this.latest.images.front.show = true
      }
      if (moment(firstDate).format('YYYY-MM-DD') !== moment(latestDate).format('YYYY-MM-DD') && moment(latestDate).format('YYYY-MM-DD') === moment(images['side-image'].latestDate).format('YYYY-MM-DD')) {
        this.latest.images.side.image = images['side-image'].currentImage
        this.latest.images.side.show = true
      }
      if (moment(firstDate).format('YYYY-MM-DD') !== moment(latestDate).format('YYYY-MM-DD') && moment(latestDate).format('YYYY-MM-DD') === moment(images['back-image'].latestDate).format('YYYY-MM-DD')) {
        this.latest.images.back.image = images['back-image'].currentImage
        this.latest.images.back.show = true
      }
    }
  }
}
