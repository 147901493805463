<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18.2657 0.950012C17.5265 0.94788 16.8174 1.24181 16.2965 1.76622L6.88822 11.174L6.88809 11.1741C6.80462 11.2582 6.74166 11.3604 6.70403 11.4727L6.704 11.4728L5.27548 15.7585L5.27547 15.7585C5.14207 16.159 5.35859 16.5918 5.75906 16.7252C5.83677 16.7511 5.91813 16.7643 5.99998 16.7643H6.00011C6.08208 16.7642 6.16354 16.7511 6.24138 16.7254L6.24155 16.7254L10.5272 15.2968L10.5272 15.2968L10.7906 15.0765L10.826 15.1119L10.826 15.1118L20.2344 5.70326C21.3218 4.61599 21.3219 2.85304 20.2347 1.76564C19.7125 1.24335 19.0042 0.949966 18.2657 0.950012ZM18.2657 0.950012L18.2655 0.999693L18.2655 0.950011C18.2656 0.950011 18.2656 0.950012 18.2657 0.950012ZM19.1537 4.62323L19.1538 4.62317C19.3897 4.38799 19.522 4.06831 19.5212 3.73514C19.5202 3.4037 19.3882 3.08608 19.1541 2.85145C18.6639 2.36032 17.8684 2.35951 17.3773 2.84965L19.1537 4.62323ZM19.1537 4.62323L9.87649 13.9007L7.20835 14.7917L8.09652 12.127L17.3772 2.84968L19.1537 4.62323ZM17.4282 10.95C17.0061 10.95 16.664 11.2922 16.664 11.7143V18.8571C16.664 19.224 16.3665 19.5214 15.9997 19.5214H3.14282C2.77594 19.5214 2.47853 19.224 2.47853 18.8571V4.57136C2.47853 4.20447 2.77594 3.90706 3.14282 3.90706H11.7141C12.1362 3.90706 12.4784 3.56487 12.4784 3.14275C12.4784 2.72063 12.1362 2.37849 11.7141 2.37849H3.14282C1.93176 2.37849 0.95 3.36027 0.95 4.57136V18.8571C0.95 20.0682 1.93176 21.05 3.14282 21.05H15.9997C17.2108 21.05 18.1925 20.0682 18.1925 18.8571V11.7142C18.1925 11.2921 17.8503 10.95 17.4282 10.95Z" fill="#9E9E9E" stroke="#9E9E9E" stroke-width="0.1"/>
  </svg>

  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconEdit',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-edit': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
