<template>
  <div
    class="gc-popup-custom-media"
    id="myModal"
    @click.self="close"
  >
    <icon-close class="gc-popup-custom-media__close-icon" @click="close()"/>
    <div class="gc-popup-custom-media__content" >
      <iframe
        v-if="type === 'video'"
        class="gc-popup-custom-media__content-iframe"
        allow="autoplay; fullscreen"
        allowfullscreen
        :src="url"
        style=""
        title="recipe"
      ></iframe>
      <image-base v-if="type === 'image'" class="gc-popup-custom-media__content-image" :src="url"/>
    </div>
  </div>
</template>

<script>
import IconClose from '../../root/icons/IconClose'
import ImageBase from '../../root/Image.vue'
export default {
  name: 'PopupCustomMedia',
  components: {ImageBase, IconClose},
  props: {
    url: {
      required: true
    },
    /* video , image */
    type: {
      type: String,
      required: true
    }
  },
  beforeMount () {
    document.body.classList.add('with-media-popup')
  },
  data: function () {
    return {
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  },
  beforeDestroy () {
    document.body.classList.remove('with-media-popup')
  }
}
</script>
