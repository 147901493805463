<template>
  <div class="gc-list-mood-tracker">

  <div class="gc-list-mood-tracker__icon-mood">
    <IconAwful v-if="moodType === 1"></IconAwful>
    <IconCrap v-else-if="moodType === 2"></IconCrap>
    <IconMeh v-else-if="moodType === 3"></IconMeh>
    <IconGood v-else-if="moodType === 4"></IconGood>
    <IconGreat v-else-if="moodType === 5"></IconGreat>
    <IconGreat v-else></IconGreat>
  </div>

  <div class="gc-list-mood-tracker__description">

      <div class="gc-list-mood-tracker__description--head">
         <TextContent
        :size= "'sm2'"
        :weight= "'extra-bold'">
          <p>{{mainTitle}}</p>
        </TextContent>
         <span>
         <TextContent
        :size= "'sm1'"
        :weight= "'extra-bold'">
          <p>{{formattedDate}}</p>
        </TextContent>
         </span>
      </div>

      <div class="gc-list-mood-tracker__description--sub-text">
       <TextContent
        :size= "'sm1'"
        :weight= "'medium'">
          <p>{{formattedDescription}}</p>
        </TextContent>
      </div>

  </div>
  <div class="gc-list-mood-tracker__dots">
       <icon-more @click="showMoodTrackerGeneralPopup()"></icon-more>
    </div>
  </div>
</template>

<script>

import TextContent from '../../root/TextContent'
import IconAdd from '../../root/icons/IconAdd'
import IconAwful from '../../root/icons/IconAwful'
import IconCrap from '../../root/icons/IconCrap'
import IconMeh from '../../root/icons/IconMeh'
import IconGood from '../../root/icons/IconGood'
import IconGreat from '../../root/icons/IconGreat'
import IconMore from '../../root/icons/IconMore'
import MOODS from '../../../services/enum/MoodTracker'
import moment from 'moment'
export default {
  name: 'ListMoodTracker',
  components: {
    TextContent,
    IconAdd,
    IconAwful,
    IconCrap,
    IconMeh,
    IconGood,
    IconGreat,
    IconMore

  },
  props: {
    /**
     * The main text of the mood
     */
    text: {
      default: ''
    },
    /**
     * The disabled state of the mood
     */
    disabled: {
      default: false
    },
    /**
     * The type of the mood
     */
    moodType: {
      default: 1
    },
    /**
     * The description of the mood
     */
    description: {
      default: ''
    },
    /**
     * The time stamp of the mood
     */
    timeStamp: {
      default: ''
    }
  },
  data () {
    return {
      mainTitle: ''
    }
  },

  mounted () {
    this.setType()
  },
  computed: {
    formattedDate () {
      let time = moment(this.timeStamp)
      return time.format('LT')
    },
    formattedDescription () {
      if (this.description === 'null') {
        return null
      } else {
        return this.description
      }
    }
  },
  methods: {
    setType () {
      if (this.moodType === MOODS.AWFUL.TYPE) {
        this.mainTitle = this.$i18n.t('message["mood-tracker.mood-awful"]')
      } else if (this.moodType === MOODS.CRAP.TYPE) {
        this.mainTitle = this.$i18n.t('message["mood-tracker.mood-crap"]')
      } else if (this.moodType === MOODS.MEH.TYPE) {
        this.mainTitle = this.$i18n.t('message["mood-tracker.mood-meh"]')
      } else if (this.moodType === MOODS.GOOD.TYPE) {
        this.mainTitle = this.$i18n.t('message["mood-tracker.mood-good"]')
      } else if (this.moodType === MOODS.GREAT.TYPE) {
        this.mainTitle = this.$i18n.t('message["mood-tracker.mood-great"]')
      } else {
        // tempary fix
        this.mainTitle = this.$i18n.t('message["mood-tracker.mood-great"]')
      }
    },

    showMoodTrackerGeneralPopup () {
      this.$emit('showGeneralPopup')
    }
  }
}
</script>

<style scoped></style>
