<template>
    <div class="container-fullscreen-success">
      <icon-completed-filled size="md1" class="container-fullscreen-success__icon"/>

    </div>
</template>
<script>
import IconCompletedFilled from '../../../../root/icons/IconCompletedFilled'
export default {
  name: 'ContainerFullscreenSuccess',
  components: { IconCompletedFilled }
}
</script>
