<template>
  <grid-row>
    <grid-col
      cols="12"
      v-if="
        noneChallengeMealPlans.length === 0 && challengeMealPlans.length === 0
      "
    >
      <card-empty :text="emptyText">
        <icon-no-meal :size="'sm4'" />
      </card-empty>
    </grid-col>
    <grid-col
      cols="12"
      :lg="cardCount > 1 ? 6 : 12"
      v-for="mealPlan in noneChallengeMealPlans"
      :key="'meal-plan-card-navigation-' + mealPlan.getId()"
    >
      <card-navigation
        :class="mealPlanCardsClasses"
        :header-text="mealPlan.getName()"
        :image="mealPlan.getCoverImage()"
        @click="navigate(mealPlan.getId())"
      />
    </grid-col>
    <grid-col cols="12" lg="6" v-if="showFoodDairy">
      <card-food-diary />
    </grid-col>
  </grid-row>
</template>
<script>
import IconNoMeal from "../../../../root/icons/IconMeal";
import CardEmpty from "./CardEmpty";
import DashBoardService from "../../../../../services/DashboardService";
import CardNavigation from "../../../../global/cards/CardNavigation";
import CardFoodDiary from "./CardFoodDiary";
import GridRow from "../../../../global/grid/GridRow";
import GridCol from "../../../../global/grid/GridCol";
import { showFoodDairy } from "../../../../../includes/TemplateSettings";

export default {
  name: "ListCardNutrition",
  components: {
    GridCol,
    GridRow,
    CardNavigation,
    IconNoMeal,
    CardEmpty,
    CardFoodDiary,
  },
  props: {
    noneChallengeMealPlans: {
      default: [],
    },
    challengeMealPlans: {
      default: [],
    },
    foodDairy: {
      default: true,
    },
  },
  computed: {
    showFoodDairy: function () {
      return (
        this.isFoodDiaryEnabled &&
        this.noneChallengeMealPlans.length > 0 &&
        this.foodDairy
      );
    },
    mealPlanCardsClasses: function () {
      return {
        "pc-dashboard__card-navigation-meal-plan": true,
        "pc-dashboard__card-navigation-meal-plan--with-food-diary":
          this.isFoodDiaryEnabled,
      };
    },
    cardCount: function () {
      let count = this.noneChallengeMealPlans.length;
      if (this.noneChallengeMealPlans.length === 0) {
        return 1;
      } else if (this.isFoodDiaryEnabled) {
        return count + 1;
      }
      return count;
    },
  },
  data: function () {
    return {
      emptyText: "",
      isFoodDiaryEnabled: false,
    };
  },
  created() {
    this.isFoodDiaryEnabled = showFoodDairy();
    let service = new DashBoardService();
    service.getPageData().then((data) => {
      this.emptyText = data.contents["no-meal-plans-msg"];
    });
  },
  methods: {
    navigate(id) {
      const navigationPath = [this.$appConfig.appUrlList.mealPlanBase, id].join(
        "/"
      );
      if (
        navigationPath === this.$route.path &&
        Object.entries(this.$route.query).length === 0
      ) {
        return;
      }
      this.showLoading();
      // delaying route push to prevent UI breakage visibility
      this.delayedRouterPush({
        path: navigationPath,
      });
    },
  },
};
</script>

<style scoped></style>
