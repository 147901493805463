import MealPlanMealService from './MealPlanMealService'

export default class MealPlanDayService {
  _id = null
  _favourites = null
  _name = ''
  _macroNutrients = {}
  _meals = []

  constructor (data, planId) {
    this._planId = planId
    this._id = data.id
    this._name = data.name || ''
    this._macroNutrients = data.macronutrients || {}
    this._calories = data.calories || 0

    data.meals.forEach(meal => {
      this._meals.push(new MealPlanMealService(meal, this._planId))
    })
  }

  getId () {
    return this._id
  }

  setDislikeService (service) {
    this._dislikes = service
    this._meals.forEach(meal => {
      try {
        meal.setDisliked(this._dislikes.isDislikeMeal(meal.getReferenceMealId()))
      } catch (e) {
        console.warn(e)
      }
    })
  }

  setFavouriteService (service) {
    this._favourites = service
    this._meals.forEach(meal => {
      try {
        meal.setFavourite(this._favourites.isFavouriteMeal(meal.getReferenceMealId()))
      } catch (e) {
        console.warn(e)
      }
    })
  }
  getName () {
    return this._name
  }
  getCalories () {
    return this._calories
  }
  getMacroNutrients () {
    return this._macroNutrients
  }
  getMeals () {
    return this._meals
  }

  getMeal (mealId) {
    return this._meals.find(meal => {
      return meal.getId() === mealId
    })
  }

  getMealCount () {
    return this._meals.length
  }
}
