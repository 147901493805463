<template>
  <div class="gc-slider-meal-plan">
    <component
      :is="componentName"
      :class="componentClasses"
      ref="slick"
      class="mealplan-slick-slider"
      :options="slick.slickOptions"
      @afterChange="handleAfterChange"
      @beforeChange="handleBeforeChange"
      @breakpoint="handleBreakpoint"
      @destroy="handleDestroy"
      @edge="handleEdge"
      @init="handleInit"
      @reInit="handleReInit"
      @setPosition="handleSetPosition"
      @swipe="handleSwipe"
      @lazyLoaded="handleLazyLoaded"
      @lazyLoadError="handleLazeLoadError"
    >
      <component
        :is="subComponentName"
        :class="subComponentClasses"

        v-for="(item, key) in days"
        :key="'meal-plan-slider-card-' + key + '-' + item.getId()"
      >
        <div class="gc-slider-meal-plan" @click="navigateNext(item.getId(), key)">
          <card-meal-slider
            :index="item.getId()"
            :header="numberWordToNumeric(item.getName())"
            :description="getAllMealOfDay(item)"
          />
        </div>
      </component>
    </component>
  </div>
</template>
<script>
import Slick from 'vue-slick'
import { slickMixin } from '../../../mixins/slickMixin'
import CardMealSlider from '../cards/card-meal-sub-components/CardMealSlider'
import GridRow from '../grid/GridRow'
import GridCol from '../grid/GridCol'

export default {
  name: 'SliderMealPlan',
  components: {
    Slick,
    GridCol,
    GridRow,
    CardMealSlider
  },
  props: {
    mealPlanId: {
      default: 0
    },
    days: {
      default: []
    }
  },
  mixins: [slickMixin],
  data: function () {
    return {
      slick: {
        slickOptions: {
          centerMode: false,
          slidesToShow: 1,
          variableWidth: true,
          autoplay: false,
          dots: false,
          prevArrow: false,
          nextArrow: false,
          infinite: false,
          rtl: false,
          initialSlide: 0,
          touchThreshold: 80
        }
      }
    }
  },
  computed: {
    componentName: function () {
      if (this.isTablet || this.isDesktop) {
        return 'div'
      } else {
        return 'slick'
      }
    },
    subComponentName: function () {
      return 'div'
    },
    componentClasses: function () {
      return {
        'gc-slider-meal-plan__grid-slider': this.isDesktop || this.isTablet
      }
    },
    subComponentClasses: function () {
      return {
        'gc-slider-meal-plan__grid-div': this.isDesktop || this.isTablet
      }
    }
  },
  beforeMount () {
    let sliderData =
      localStorage.getItem('lastMealPlanDay') || '{"mealPlanID": 0,"index": 0}'
    sliderData = JSON.parse(sliderData)
    if (sliderData.mealPlanId && this.mealPlanId === sliderData.mealPlanId) {
      this.slick.slickOptions.initialSlide = sliderData.index || 0
    }
    this.slick.slickOptions.rtl = this.isRTL()
  },
  methods: {
    afterGetMealPlanOptionData () {
      this.reInit()
    },

    navigateNext (id, index) {
      let slideData = {
        mealPlanId: this.mealPlanId,
        index: index
      }
      localStorage.setItem('lastMealPlanDay', JSON.stringify(slideData))
      this.$emit('navigateNext', id)
    },

    getAllMealOfDay (item) {
      const meals = item.getMeals().map((meal) => {
        const recipes = meal.getRecipes().map((recipe) => {
          return recipe.getName()
        })
        return recipes.join()
      })
      const mealStr = meals.join(', ')
      return mealStr.length > 40
        ? mealStr
          .substring(0, 40 - 3)
          .trim()
          .replace(/-$/, '') + '... '
        : mealStr
    }
  },
  beforeDestroy () {
    if (!this.isDesktop && !this.isTablet && this.$refs.slick) {
      this.$refs.slick.destroy()
    }
  }
}
</script>
