<template>
    <div class="workout-log__success-area">
        <img :src="$appConfig.imagesUrl + 'icons/tick-green.svg'" class="workout-log__success-area-img">
    </div>
</template>
<script>
export default {
  name: 'workout-log-success-area'
}
</script>
