<template>
  <!--   page background -->
  <page class="pc-progress-start" :desktop-side-bar="false" :header="headerOptions" :background-image="backgroundImage">
      <!--   page wrapper -->
      <page-container>
        <div class="pc-progress-start__card-feature-box">
          <text-heading4
            :lineHeight="'multi'"
            :weight="'extra-bold'"
            class="pc-progress-start__card-feature-box__title"
            v-if="hasGreetingText()"
            v-html="$t('message[\'progress.start-greeting\']',{name:userName})"/>
          <text-body-regular
            :lineHeight="'multi'"
            class="pc-progress-start__card-feature-box__sub-text"
            v-html="$t('message[\'progress.start-description\']')"/>
          <button-primary
            @click="nextComponent"
            :text="$t('message[\'progress.start-button\']')"/>
        </div>

      </page-container>
      <div class="app-bg-overlay"></div>
  </page>
</template>

<script>
import BaseComponent from '../global/base/BaseComponent'
import ProgressCheckInService from '../../../../services/ui/forms/ProgressCheckInService'
import DashboardService from '../../../../services/DashboardService'
import Page from '../../../global/pages/PageImageCover'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import ButtonPrimary from '../../../global/buttons/ButtonPrimary'
import TextHeading2 from '../../../global/typography/TextHeading2'
import TextBodyRegular from '../../../global/typography/TextBodyRegular'
import TextHeading4 from '../../../global/typography/TextHeading4'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'Start',
  extends: BaseComponent,
  components: {TextHeading4, TextBodyRegular, TextHeading2, ButtonPrimary, PageContainer, Page},
  data: function () {
    return {
      backgroundImage: '',
      userName: '',
      headerOptions: {
        show: true,
        left: 'previous',
        right: '',
        modifiers: ['with-img', 'transparent-desktop'],
        mainTitle: '',
        subTitle: '',
        header: {
          mainTitle: '',
          subTitle: ''
        },
        isTransparent: true
      }
    }
  },
  mounted () {
    const dService = new DashboardService()
    dService.getUserData().then(data => {
      this.userName = data.first_name
    })
    const service = new ProgressCheckInService()
    service.getProgressUpdate()
    service.getFitnessDiarySummary()
    if (this.$route.name === 'MealPlanUpdateMain') {
      service.getMealPlanUpdate().then(() => {
        this.hideLoading()
        pageReadyEvent()
      }).catch(() => {
        this.$router.push({ path: '/dashboard' })
      })
    } else {
      service.getWorkoutUpdate().then(() => {
        this.hideLoading()
        pageReadyEvent()
      }).catch(() => {
        this.$router.push({ path: '/dashboard' })
      })
    }

    this.setImages()
  },
  methods: {
    hasGreetingText () {
      let html = this.$i18n.t('message[\'progress.start-greeting\']', {name: this.userName})
      let div = document.createElement('div')
      div.innerHTML = html
      let text = div.textContent || div.innerText || ''
      return Boolean(text)
    },
    setImages () {
      this.backgroundImage = this.getStoreImage('progressCheckIn.start')
    },
    nextComponent () {
      this.$emit('submit', {})
    }
  }
}
</script>
