<template>
  <page-secondary :header="headerOptions">
    <page-container-fluid
      breakpoint="lg"
      :reverse="true"
    >
      <calendar-slider
        v-if="dates.length>0"
        :dates="dates"
        :last-selected-day="lastSelectedDate"
        :has-more="hasMoreDates"
        class="gc-habits-tracker__calander"
        @selected="selectedDate($event)"
        @load-more="loadMore($event)"
      />
    </page-container-fluid>
    <page-container class="container">
      <div class="gc-habits-tracker__habits-list">
        <list-habits-tracker
          v-if="currentEntry"
          :habits-entries="currentEntry"
          @update-habit="updateDay"
        />
      </div>
    </page-container>
  </page-secondary>
</template>
<script>
import BaseComponent from '../global/base/BaseComponent'
import ListHabitsTracker from '../../../global/lists/ListHabitsTracker'
import pageMixin from '../../../../mixins/pageMixin'
import CalendarSlider from '../../../global/sliders/CalendarSlider'
import HabitsService from '../../../../services/habits-tracker/HabitsTrackerService'
import { mapGetters } from 'vuex'
import moment from 'moment'
import PageSecondary from '../../../global/pages/PageSecondary'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import PageContainerFluid from '../../../global/pages/page-sub-components/PageContainerFluid'
import DashboardService from '@/services/DashboardService'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'PageHabitsTracker',
  components: {
    PageContainerFluid,
    PageContainer,
    PageSecondary,
    ListHabitsTracker,
    CalendarSlider
  },
  extends: BaseComponent,
  mixins: [pageMixin],
  data () {
    return {
      headerOptions: {
        show: true,
        left: 'previous-emit',
        right: '',
        rightDisabled: false,
        modifiers: ['with-bg-color', 'small-title'],
        header: {
          mainTitle: this.$i18n.t('message["habit-tracker.header"]'),
          subTitle: ''
        },
        mainTitle: this.$i18n.t('message["habit-tracker.header"]')
      },
      pageClass: ['macroapp--page-common'],
      loading: true,
      dateRange: 28,
      habitsEntriesDetails: [],
      dates: [],
      dayEntries: null,
      currentEntry: null,
      hasMoreDates: true,
      habitTracker: new HabitsService(),
      lastSelectedDate: null,
      habitDates: {
        start: null,
        end: null
      }
    }
  },
  computed: {
    ...mapGetters({
      headerBackButtonClick: 'pageStore/getBackButtonClick'
    })
  },
  watch: {
    headerBackButtonClick: function () {
      this.navigatePrevious()
    }
  },
  created () {
    this.setPageTitle(this.$t('message["habit-tracker.header"]'))
  },
  beforeMount () {
    if (!this.isEnabled()) {
      this.$router.replace({ path: '/dashboard' })
    }
    this.showLoading()
  },
  mounted () {
    this.headerOptions.header.mainTitle = this.$i18n.t('message["habit-tracker.header"]')
    this.setPageClass()
    this.setHabits()
    this.setHeader(this.headerOptions)
    pageReadyEvent()
  },
  methods: {
    loadMore () {
      this.getDates()
    },
    isEnabled () {
      const dashboard = new DashboardService()
      return dashboard.hasHabitTrackerEnabled()
    },
    navigatePrevious () {
      this.showLoading()
      this.delayedRouterPush({
        path: this.$appConfig.appUrlList.dashboard
      })
    },
    getDates () {
      let dashboardService = new DashboardService()
      const regDate = dashboardService.getRegisteredDate()
      let startDate, endDate
      if (!this.habitDates.start) {
        startDate = moment().locale('en').subtract(this.dateRange, 'days')
        endDate = moment().set({
          hour: 23,
          minute: 59,
          second: 0,
          millisecond: 0
        })
      } else {
        endDate = this.habitDates.end.subtract(this.dateRange, 'days')
        startDate = this.habitDates.start.subtract(this.dateRange, 'days')

      }


      this.habitDates.end = endDate
      this.habitDates.start = startDate
      const newDates = []
      this.setHabitEntries(startDate, endDate).then(dayEntries => {
        let hasMoreDates = true
        this.dayEntries = dayEntries
        let day = startDate
        while (day <= endDate) {
          if(day.diff(regDate, 'minutes')>0){
            let dayEntry = this.findDayEntryByDate(day.locale('en').format('YYYY-MM-DD'))
            let date = {
              'id': day.locale('en').format('YYYY-MM-DD'),
              'month': day.locale('en').format('MMM'),
              'day': day.locale('en').get('date'),
              'date': day.locale('en').format('YYYY-MM-DD'),
              'logged': !!dayEntry && dayEntry.hasLoggedEntry()
            }
            newDates.push(date)
          } else {

            hasMoreDates = false
          }


          day = day.clone().add(1, 'd')
        }
        this.hasMoreDates = hasMoreDates
        this.dates = [...newDates, ...this.dates]

      }).finally(() => {
        this.hideLoading()
      })
    },

    updateDay (day) {
      this.dates.some((dateObject, index) => {
        let date = moment(day).locale('en').format('YYYY-MM-DD')
        if (dateObject.date === date) {
          if (this.currentEntry.getDate() === date) {
            this.dates[index].logged = this.currentEntry.isSomeLogged()
          } else {
            this.dates[index].logged = true
          }
          return true
        }

        return false
      })
    },
    selectedDate (event) {
      let dayEntry = this.findDayEntryByDate(event.id)
      this.lastSelectedDate = event
      if (dayEntry) {
        this.currentEntry = dayEntry
      } else {
        this.habitTracker.addDayEntry(event.id)
        this.dayEntries = this.habitTracker.habitsEntries
        dayEntry = this.findDayEntryByDate(event.id)
        this.currentEntry = dayEntry
      }
    },
    findDayEntryByDate (date) {
      return this.dayEntries.find(dayEntry => {
        return dayEntry.getDate() === date
      })
    },
    setHabits () {
      this.habitTracker.setHabits().then(() => {
        this.getDates()
      })
    },
    setHabitEntries (from, to) {
      return this.habitTracker.query(from, to)
    }
  }
}
</script>
