<template>
  <page-secondary :header="headerOptions">
    <page-container  class="pc-list-invitation__container">
      <grid-row>
        <grid-col lg="6" v-if="!listEmpty"
                  v-for="invitee in inviteesList"
                  :key="invitee.id">
          <div class="pc-list-invitation">
            <text-body-small :weight="'extra-bold'" :lineHeight="'multi'">
              {{invitee.name}}
            </text-body-small>
            <text-body-extra-small :lineHeight="'lh-xl'">
              {{$t("message[\'general.invited-successfully\']")}}
            </text-body-extra-small>
          </div>

        </grid-col>
        <grid-col  v-if="listEmpty">
          <div class="pc-list-invitation__empty-list">
            <text-body-extra-small>
              {{$t("message[\'general.empty-invitees-list\']")}}
            </text-body-extra-small>
          </div>
        </grid-col>
      </grid-row>

    </page-container>
  </page-secondary>
</template>

<script>

import PageSecondary from '../../../global/pages/PageSecondary'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import InvitationsService from '../../../../services/invitations/InvitationsService'
import TextBodySmall from '../../../global/typography/TextBodySmall'
import TextBodyExtraSmall from '../../../global/typography/TextBodyExtraSmall'
import GridCol from '../../../global/grid/GridCol'
import GridRow from '../../../global/grid/GridRow'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'
export default {
  name: 'pageInvitationsList',
  components: {
    GridRow,
    GridCol,
    TextBodyExtraSmall,
    TextBodySmall,
    PageContainer,
    PageSecondary
  },
  data: function () {
    return {
      headerOptions: {
        show: true,
        left: 'previous',
        right: '',
        rightBlink: false,
        modifiers: ['with-bg-color', 'small-title'],
        mainTitle: this.$i18n.t('message["general.my-invitees-list"]')
      },
      inviteesList: [],
      listEmpty: false
    }
  },
  beforeMount () {
    this.showLoading()
    this.service = new InvitationsService()
    this.loadInviteesList()
  },
  methods: {
    loadInviteesList () {
      this.service.loadInvitations().then(() => {
        this.inviteesList = this.service.getInvitations()
        this.listEmpty = this.inviteesList.length === 0
        this.hideLoading()
      }).catch((error) => {
        this.listEmpty = true
        if (error && error.response && error.response.status === 404) {
          this.$store.commit('showNotFoundPopupShow')
        }
      }).finally(() => {
        pageReadyEvent()
        this.hideLoading()
      })
    }
  }

}
</script>

<style scoped>

</style>
