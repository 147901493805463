<template>
  <div @click="onClick">
    <slot></slot>
    <popup-text-share
      :popupClasses="modalClasses"
      v-if="showSharePopup"
      :visible="showSharePopup"
      :header="popupHeader"
      :description="popupDescription"
      :button="popupButtonText"
      :url="url"
      :text="text"
      @close="showSharePopup = false"
    />
  </div>
</template>

<script>
import PopupTextShare from './container-text-share-sub-components/PopupTextShare'
import {checkNativeAppVersion} from '../../../includes/NativeAppCommon'

export default {
  name: 'ContainerTextShare',
  components: { PopupTextShare },
  props: {
    text: {
      default: ''
    },
    title: {
      default: ''
    },
    popupHeader: {
      default: ''
    },
    popupDescription: {
      default: ''
    },
    popupButtonText: {
      default: ''
    },
    url: {
      default: ''
    },
    modalClasses: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data: function () {
    return {
      showSharePopup: false
    }
  },
  computed: {
    shareData: function () {
      let data = {}
      if (this.text) {
        data.text = this.text
      }
      if (this.title) {
        data.title = this.title
      }
      if (this.url) {
        data.url = this.url
      }
      return data
    }
  },
  methods: {
    async onClick () {
      this.logEvent('InviteFriend.CopiedInvitationLink', {})
      if (checkNativeAppVersion()) {
        this.showSharePopup = true
      } else {
        if (navigator.share) {
          try {
            await navigator.share(this.shareData)
          } catch (err) {
            if (err.name !== 'AbortError') {
              this.showSharePopup = true
            }
          }
        } else {
          this.showSharePopup = true
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
