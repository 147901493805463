<template>
  <div class="gc-slider-workout-program" v-if="show" ref="slider">
    <header-simple
      class="gc-slider-workout-program__header"
      :header-text="workout.getName()"
      :icon="'bulb'"
      :show-send-icon="true"
      @icon-click="showInstructionsPopup()"
      @print-icon-click="printPrograme()"
      :show-icon="showInstructionsPopupBulb()"
    />
    <div class="gc-slider-workout-program__slider">
      <slick class="gc-slider-workout-program__week-slider" ref="slickWeek" :options="weeksSlickOptions">
        <div
          class="gc-slider-workout-program__slider-card-wrapper"
          v-for="(week, weekIndex) in weeks"
          :key="weekIndex"
          @click="setSelectedWeekData(week, true)"
        >
          <slider-workout-program-week-card
            :image="week.getTileImage()"
            :text="numberWordToNumeric(week.getName())"
            :is-overlay-active="week.getID() !== activeWeek"
            :show-tick-icon="week.getIsCompleted()"
          />
        </div>
      </slick>

      <slick
        class="gc-slider-workout-program__day-slider"
        v-if="days.length > 0"
        ref="slickDay"
        :options="daysSlickOptions"
      >
          <slider-workout-program-day-button
            id="slickDayButton"
            v-for="(day, dayIndex) in days" :key="dayIndex"
            @click="navigateNext(day.getID(), day)"
            :text="(numberWordToNumeric(day.getName())).length < 37 ? numberWordToNumeric(day.getName()) : (numberWordToNumeric(day.getName())).substring(0,37)+'...'"
            :active="isDayActive(day.getID())"
            :completed="day.getIsCompleted()"
          />
      </slick>
      <popup-workout-pdf-send :visible="showWorkoutPopup" :workout-id="workoutID" :weeks="workoutWeeks" @close="closePdfPopup" />
    </div>
  </div>
</template>

<script>
import Slick from 'vue-slick'
import BaseComponent from '../../layout/template-1/global/base/BaseComponent'
import {mapGetters, mapMutations} from 'vuex'
import HeaderSimple from '../headers/HeaderSimple'
import WorkoutProgramService from '../../../services/WorkoutProgramService'
import SliderWorkoutProgramDayButton from './slider-workout-program-sub-components/SliderWorkoutProgramDayButton'
import SliderWorkoutProgramWeekCard from './slider-workout-program-sub-components/SliderWorkoutProgramWeekCard'
import PopupWorkoutPdfSend from '../../global/popups/PopupWorkoutPdfSend'
export default {
  name: 'SliderWorkoutProgram',
  extends: BaseComponent,
  components: {
    PopupWorkoutPdfSend,
    HeaderSimple,
    Slick,
    SliderWorkoutProgramDayButton,
    SliderWorkoutProgramWeekCard
  },
  props: {
    workout: {
      type: Object,
      validator: function (value) {
        return value instanceof WorkoutProgramService
      }
    },
    page: {
      default: 'dashboard'
    },
    activeWorkout: {}
  },
  data: function () {
    return {
      workoutWeeks: [],
      workoutID: '',
      show: true,
      title: '',
      isActive: null,
      active: false,
      activeWeek: null,
      activeDay: null,
      days: [],
      weeks: {},
      instructionsPopup: {
        instructions: [],
        notes: '',
        title: '',
        firstTimeKey: ''
      },
      weeksSlickOptions: {
        slidesToShow: 1,
        variableWidth: true,
        autoplay: false,
        dots: false,
        prevArrow: false,
        nextArrow: false,
        infinite: false,
        rtl: false,
        touchThreshold: 40
      },
      daysSlickOptions: {
        slidesToScroll: 1,
        slidesToShow: 1,
        variableWidth: true,
        autoplay: false,
        dots: false,
        prevArrow: false,
        nextArrow: false,
        infinite: false,
        rtl: false,
        touchThreshold: 40
      },
      showWorkoutPopup: false
    }
  },
  computed: {
    ...mapGetters({
      orientation: 'getOrientation'
    })
  },
  watch: {
    activeWorkout (workout) {
      if (parseInt(workout) !== parseInt(this.workout.getID())) {
        this.resetPage()
      }
    },
    activeWeek () {
      this.setActiveWeekOnSlider()
    },
    orientation: function () {
      this.setSelectedWeekData()
      if (this.days.length) {
        this.reInitDaysSlider()
      }
    }
  },
  beforeMount () {
    this.weeksSlickOptions.rtl = this.isRTL()
    this.daysSlickOptions.rtl = this.isRTL()
    this.loadPageData()
  },
  methods: {
    ...mapMutations({
      viewInstructionsPopup: 'instructionPopupStore/show',
      setWorkoutFrom: 'setWorkoutFrom',
      setInstructionsPopupContent: 'instructionPopupStore/setContent'
    }),
    closePdfPopup () {
      this.showWorkoutPopup = false
    },
    resetPage () {
      this.activeWeek = null
      this.days = []
      this.reInitDaysSlider()
      this.active = false
    },
    printPrograme () {
      this.workoutID = this.workout.getID()

      this.workoutWeeks = this.workout.getWeeks().map((item) => ({
        id: item.id,
        text: this.numberWordToNumeric(item.name)
      }))

      this.workoutWeeks.unshift({ id: '*', text: this.$i18n.t('message["popup.workout-send-pdf.dropdown-default"]') })
      this.showWorkoutPopup = true
      // const printWindow = window.open(window.location.origin + '/workout-programs/' + id + '?print-page=1', 'Print', 'left=200, top=200, width=950, height=500, toolbar=0, resizable=0')
      // printWindow.addEventListener('load', () => {

      // }, true)

      // printWindow.addEventListener('afterprint', (event) => {
      //   printWindow.close()
      // })
    },
    showInstructionsPopup () {
      this.instructionsPopup.instructionType = 'workoutDashboard'
      this.setInstructionsPopupContent(this.instructionsPopup)
      this.viewInstructionsPopup()
    },
    showInstructionsPopupBulb () {
      return (
        this.checkIsNotEmptyTextArray(this.instructionsPopup.instructions) ||
        this.checkIsNotEmptyTextArray(this.instructionsPopup.notes)
      )
    },
    loadPageData () {
      this.workout
        .setProgramDetails()
        .then(() => {
          this.weeks = this.workout.getWeeks()
          this.show = this.weeks.length !== 0
          this.instructionsPopup.instructions = [this.workout.getDescription()]
          this.instructionsPopup.notes = this.workout.getNotes()
          let lastVisitedState = this.getLastVisitedState()
          this.activeWeek = lastVisitedState.weekID
          this.activeDay = lastVisitedState.dayID
          this.reInitWeekSlider()

          let currentWeek = this.weeks.find((week) => {
            return this.activeWeek === week.getID()
          })
          currentWeek = currentWeek || null
          if (currentWeek) {
            this.setSelectedWeekData(currentWeek)
          }
        }).catch(() => {
          this.show = false
        }).finally(() => {
          this.hideLoading()
          this.$emit('ready', { id: this.workout.getID() })
        })
    },
    reInitWeekSlider () {
      if (typeof this.$refs.slickWeek !== 'undefined') {
        this.$refs.slickWeek.destroy()
      }
      this.$nextTick(() => {
        if (this.$refs.slickWeek) {
          this.$refs.slickWeek.create(this.weeksSlickOptions)
        }
        if (this.activeWeek) {
          this.setActiveWeekOnSlider()
          this.reInitDaysSlider()
        }
      })
    },
    reInitDaysSlider () {
      if (typeof this.$refs.slickDay !== 'undefined') {
        this.$refs.slickDay.destroy()
      }

      this.$nextTick(() => {
        if (this.active && this.$refs.slickDay) {
          this.$refs.slickDay.create(this.daysSlickOptions)
        }
        if (parseInt(this.activeWorkout) === parseInt(this.workout.getID())) {
          this.active = true
        }
        if (Array.isArray(this.days)) {
          let dayIndex = this.days.findIndex((day) => {
            return parseInt(this.activeDay) === day.id
          })
          if (dayIndex >= 0) {
            this.slickGoToDay(dayIndex)
          }
        }
        this.setSlideToScrollCount()
        this.$nextTick(() => {
          this.setSlideToScrollCount()
        })
      })
    },
    setSlideToScrollCount () {
      let ele = null
      if (!this.$refs.slider) {
        return
      }
      this.$refs.slider.querySelectorAll('.gc-slider-workout-program-day-button').forEach((i) => {
        if (ele === null || (ele && ele.offsetWidth < i.offsetWidth)) {
          ele = i
        }
      })
      let sliderWidth = this.$refs.slider.offsetWidth

      if (ele && ele.offsetWidth) {
        if (3 * (ele.offsetWidth + 12) < sliderWidth) {
          this.daysSlickOptions.slidesToScroll = 3
          this.daysSlickOptions.slidesToShow = 3
        } else if (2 * (ele.offsetWidth + 12) < sliderWidth) {
          this.daysSlickOptions.slidesToScroll = 2
          this.daysSlickOptions.slidesToShow = 2
        } else {
          this.daysSlickOptions.slidesToScroll = 1
          this.daysSlickOptions.slidesToShow = 1
        }
      }
    },
    setLastVisitedState (weekID, dayID) {
      let activeStateData = {
        weekID: weekID,
        dayID: dayID,
        programID: this.workout.getID()
      }
      localStorage.setItem(
        'workoutWeekSlider',
        JSON.stringify(activeStateData)
      )
    },
    getLastVisitedState () {
      let activeStateData = localStorage.getItem('workoutWeekSlider')
      return (
        JSON.parse(activeStateData) || { weekID: 0, dayID: 0, programID: 0 }
      )
    },
    isDayActive (dayID) {
      return parseInt(dayID) === parseInt(this.activeDay)
    },
    setActiveWeekOnSlider () {
      if (!this.activeWeek) {
        this.slickGoTo(0)
      } else {
        let currentWeekIndex = Object.values(this.weeks).findIndex((week) => {
          return week.getID() === this.activeWeek
        })
        if (currentWeekIndex >= 0) {
          this.slickGoTo(currentWeekIndex)
        }
      }
    },
    setSelectedWeekData (week, clicked = false) {
      if (!week || !week.getID()) {
        return
      }
      this.activeWeek = week.getID()
      this.days = week.getDays()
      if (clicked) {
        this.setLastVisitedState(this.activeWeek, 0)
      }

      this.calculateTextLength(this.days)

      this.$emit('activeWorkout', this.workout.getID())
      this.logSliderWeekSelectedEvent()
    },
    logSliderWeekSelectedEvent () {
      let eventData = {
        program_name: this.workout.getName(),
        context: this.page
      }
      this.logEvent('WorkoutProgram.SliderWeekSelected', eventData, false)
    },
    logSliderDaySelectedEvent (day, dayType) {
      this.logEvent('WorkoutProgram.ViewedSingleDay', {
        day: day,
        context: 'slider',
        type: dayType
      })
    },
    calculateTextLength () {
      this.reInitDaysSlider()
    },
    navigateNext (dayID, day) {
      this.showLoading()
      this.logSliderDaySelectedEvent(
        this.numberWordToNumeric(day.name),
        day.type
      )

      this.setWorkoutFrom(this.page)
      this.setLastVisitedState(this.activeWeek, dayID)
      this.delayedRouterPush({
        path: this.$appConfig.appUrlList.workout,
        query: {
          workout: this.workout.getID(),
          week: this.activeWeek,
          day: dayID
        }
      })
    },
    slickGoTo (slideIndex) {
      if (this.$refs.slickWeek) {
        this.$refs.slickWeek.goTo(slideIndex)
      }
    },
    slickGoToDay (slideIndex) {
      if (this.$refs.slickDay) {
        this.$refs.slickDay.goTo(slideIndex)
      }
    }
  }
}
</script>
