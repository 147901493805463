import ShoppingListAPI from './api/meal-plan/ShoppingListAPI'
import { enableGroupedIngredients } from '../includes/TemplateSettings'
import Lodash from 'lodash'
import LanguageService from './LanguageService'
import { i18n } from '../helpers/localization/i18n'

export default class ShoppingListService {
  shoppingLists = new Map()
  ingredientsByGroups = []
  constructor () {
    if (!ShoppingListService.instance) {
      ShoppingListService.instance = this
    }
    return ShoppingListService.instance
  }

  sendEmail (formData) {
    const api = new ShoppingListAPI()
    let lang = new LanguageService()
    const langCode = lang.lang
    formData = {...formData, lang: langCode}
    return api.sendEmail(formData, this._checkIsEmailGrouped())
  }

  _checkIsEmailGrouped () {
    return Boolean(enableGroupedIngredients())
  }

  get (ids, mid) {
    const uniqueID = this._createUniqueID(ids, mid)
    if (this.shoppingLists.has(uniqueID)) {
      return new Promise((resolve, reject) => {
        resolve(this.shoppingLists.get(uniqueID))
      })
    }
    return this.getFromAPI(ids, mid)
  }

  getFromAPI (ids, mid) {
    const api = new ShoppingListAPI()
    return new Promise((resolve, reject) => {
      let url
      if (typeof ids === 'undefined') {
        url = api.getList()
      } else {
        url = api.post(ids, [mid])
      }
      url.then((data) => {
        this.shoppingLists.set(this._createUniqueID(ids, mid), data.data)
        resolve(data.data)
      })
    })
  }

  _createUniqueID (ids, mid) {
    const sortedIDS = Lodash.sortBy(ids)
    return mid + '.' + sortedIDS.toString()
  }

  group (ingredients) {
    let uncategorized = []
    this.ingredientsByGroups = []
    ingredients.forEach((ingredient, index) => {
      if (ingredient.groups.length > 0) {
        ingredient.groups.forEach((group) => {
          this._addToGroupArray(ingredient, group, group.parent_group, index)
        })
      } else {
        uncategorized.push({
          'id': ingredient.id,
          'image': ingredient.image,
          'name': ingredient.name,
          'quantity': ingredient.quantity,
          'units': ingredient.units
        })
      }
    })

    uncategorized = Lodash.uniqBy(uncategorized, 'id')

    let ingredientsByGroupsOtherIndex = this._getOtherGroupIndex()
    if (uncategorized.length > 0) {
      let uncategorizedSubGroup = {
        'id': 1000000000,
        'name': i18n.t('message["group-dislikes.uncategorized"]'),
        'ingrediants': uncategorized
      }

      if (ingredientsByGroupsOtherIndex !== -1) {
        this.ingredientsByGroups[ingredientsByGroupsOtherIndex].subCategory.push(uncategorizedSubGroup)
      } else {
        this._createOtherGroup([uncategorizedSubGroup])
      }
    }

    this._sortGroupedIngredients()
  }

  _sortGroupedIngredients () {
    this.ingredientsByGroups.map(group => {
      group.subCategory = group.subCategory.map(subCategory => {
        subCategory.ingrediants = Lodash.sortBy(subCategory.ingrediants, 'name')
        return subCategory
      })
      group.subCategory = Lodash.sortBy(group.subCategory, 'name')

      return group
    })

    this.ingredientsByGroups = Lodash.sortBy(this.ingredientsByGroups, 'name')
  }

  _createOtherGroup (subCategories) {
    this.ingredientsByGroups.push({
      'id': 20000000,
      'name': i18n.t('message["group-dislikes.other"]'),
      'subCategory': subCategories
    })
  }

  _getOtherGroupIndex () {
    return this.ingredientsByGroups.findIndex(group => {
      return group.name.toLowerCase() === i18n.t('message["group-dislikes.other"]').toLowerCase()
    })
  }

  _addToGroupArray (ingredient, group, parentGroup) {
    let ingredientObj = {
      'id': ingredient.id,
      'image': ingredient.image,
      'name': ingredient.name,
      'quantity': ingredient.quantity,
      'units': ingredient.units
    }

    if (parentGroup) {
      let foundParentGroupIndex = this.ingredientsByGroups.findIndex(obj => {
        return obj.id === parentGroup.id
      })
      let subGroup = {
        'id': group.id,
        'name': group.name,
        'ingrediants': [ingredientObj]
      }
      if (foundParentGroupIndex !== -1) {
        let foundSubCategroryIndex = this.ingredientsByGroups[foundParentGroupIndex].subCategory.findIndex(obj => {
          return obj.id === group.id
        })
        if (foundSubCategroryIndex !== -1) {
          this.ingredientsByGroups[foundParentGroupIndex].subCategory[foundSubCategroryIndex].ingrediants.push(ingredientObj)
        } else {
          this.ingredientsByGroups[foundParentGroupIndex].subCategory.push(subGroup)
        }
      } else {
        this.ingredientsByGroups.push({
          'id': parentGroup.id,
          'name': parentGroup.name,
          'subCategory': [subGroup]
        })
      }
    } else {
      let subGroup = {
        'id': group.id,
        'name': group.name,
        'ingrediants': [ingredientObj]
      }
      let otherCategoryIndex = this._getOtherGroupIndex()
      if (otherCategoryIndex === -1) {
        this._createOtherGroup([subGroup])
      } else {
        let foundSubCategroryIndex = this.ingredientsByGroups[otherCategoryIndex].subCategory.findIndex(obj => {
          return obj.id === group.id
        })

        if (foundSubCategroryIndex !== -1) {
          this.ingredientsByGroups[otherCategoryIndex].subCategory[foundSubCategroryIndex].ingrediants.push(ingredientObj)
        } else {
          this.ingredientsByGroups[otherCategoryIndex].subCategory.push(subGroup)
        }
      }
    }
  }
}
