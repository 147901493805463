<template>
  <div class="pc-shopping-list-pages">
    <transition name="fade" mode="out-in">
      <component v-bind:is="dynamicComponent" v-once></component>
    </transition>
  </div>
</template>

<script>
import PageDays from './PageDays'
import PageShop from './PageShop'
import PageCart from './PageCart'
import { mapGetters } from 'vuex'
import MainPageMixin from '../../../../../mixins/mainPageMixin'
import BaseComponent from '../../global/base/BaseComponent'
import store from '../../../../../store'

export default {
  name: 'Main',
  components: { },
  extends: BaseComponent,
  mixins: [MainPageMixin],
  data: function () {
    return {
      components: {
        'page-shopping-days': PageDays,
        'page-shopping-shop': PageShop,
        'page-shopping-cart': PageCart
      }

    }
  },
  computed: {
    page () {
      const queryParams = this.$route.query
      if ('page' in queryParams && queryParams.page === 'shop') {
        return 'page-shopping-shop'
      } else if ('page' in queryParams && queryParams.page === 'cart') {
        return 'page-shopping-cart'
      } else {
        return 'page-shopping-days'
      }
    },
    ...mapGetters({
      pageClasses: 'pageStore/pageClasses',
      navClasses: 'pageStore/navClasses',
      headerOptions: 'pageStore/header',
      background: 'pageStore/background'
    }),
    dynamicComponent: function () {
      return this.components[this.activeComponent]
    },
    activeComponent: function () {
      const queryParams = this.$route.query

      if ('page' in queryParams && queryParams.page === 'shop') {
        return 'page-shopping-shop'
      } else if ('page' in queryParams && queryParams.page === 'cart') {
        return 'page-shopping-cart'
      } else {
        return 'page-shopping-days'
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    if (!navigator.onLine) {
      store.commit('showNoConnectionErrorPopup')
    }
    next()
  }
}
</script>
