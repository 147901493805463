import BaseAPI from '../BaseAPI.js'
import axios from 'axios'

export default class extends BaseAPI {
  _resource ='integrations'

  getPurchaseConfig () {
    return axios.get(this._baseURL + 'integrations/in-app-purchase/config')
  }
}
