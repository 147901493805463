<template>
  <page-secondary :header="headerOptions" class="pc-profile--change-password">
    <!--   page wrapper -->
    <page-container class="pc-profile--change-password__container">
      <div>
        <validation-observer ref="form" v-slot="{ handleSubmit, invalid }">
          <form id="form-password" @submit.prevent="handleSubmit(submitForm)">
            <validation-provider
              :name="$t('message[\'form.current-password\']')"
              ref="currentPasswordValidationProvider"
              rules="required"
              v-slot="{ errors }"
              vid="oldPassword"
            >
              <div class="pc-profile--change-password__input-wrapper">
                <text-input
                  tabindex="1"
                  :placeholder="[[$t('message[\'form.current-password\']')]]"
                  ref="currentPasswordInput"
                  type="password"
                  v-model="formData.old_password"
                  :errorMessage="errors[0]"
                  class="pc-profile--change-password__input pc-profile--change-password__input_current-password"
                />
              </div>
            </validation-provider>
            <validation-provider
              :name="$t('message[\'form.new-password\']')"
              :rules="'required|not_same_password:@oldPassword'"
              ref="newPasswordValidationProvider"
              v-slot="{ errors }"
              vid="confirmation"
            >
              <div class="pc-profile--change-password__input-wrapper">
                <text-input
                  tabindex="2"
                  :placeholder="[[$t('message[\'form.new-password\']')]]"
                  type="password"
                  v-model="formData.new_password"
                  :errorMessage="errors[0]"
                  class="pc-profile--change-password__input pc-profile--change-password__input_new-password"
                />

              </div>
            </validation-provider>
            <validation-provider
              :name="$t('message[\'form.confirm-password\']')"
              ref="confirmPasswordValidationProvider"
              rules="required|confirmed:confirmation"
              v-slot="{ errors }"
            >
              <div class="pc-profile--change-password__input-wrapper">
                <text-input
                  tabindex="3"
                  :placeholder="[[$t('message[\'form.confirm-password\']')]]"
                  type="password"
                  v-model="formData.new_password_confirmation"
                  :errorMessage="errors[0]"
                  class="pc-profile--change-password__input pc-profile--change-password__input_confirm-password"
                />
              </div>
            </validation-provider>
            <page-footer>
              <button-primary
                :text="$t('message[\'profile.update\']')"
                :disabled="submited || invalid"
                :form="'form-password'"
              />
            </page-footer>
          </form>
        </validation-observer>
      </div>
    </page-container>
  </page-secondary>
</template>

<script>
import BaseComponent from '../../global/base/BaseComponent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ProfileService from '../../../../../services/ProfileService'
import PageSecondary from '../../../../global/pages/PageSecondary'
import PageContainer from '../../../../global/pages/page-sub-components/PageContainer'
import TextInput from '../../../../global/inputs/TextInput'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import PageFooter from '../../../../global/pages/PageFixedFooter'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'ChangePassword',
  extends: BaseComponent,
  components: {
    PageFooter,
    ValidationObserver,
    ValidationProvider,
    PageSecondary,
    PageContainer,
    TextInput,
    ButtonPrimary
  },
  created () {
    this.setPageTitle(this.$t('message["form.change-password"]'))
  },

  mounted () {
    this.headerOptions.mainTitle = this.$i18n.t(
      "message['form.change-password']"
    )
    this.setHeader(this.headerOptions)
    this.setPageClass()
    this.hideLoading()
    const inputs = document.querySelectorAll('.ma-form__input')
    inputs.forEach((input) => {
      input.blur()
    })
    pageReadyEvent()
  },
  data: function () {
    return {
      submited: false,
      stripe: '',
      imagesUrl: this.$appConfig.imagesUrl,
      validation: {},
      headerOptions: {
        isTransparent: false,
        isFixed: false,
        show: true,
        left: 'previous',
        right: '',
        rightBlink: false,
        modifiers: ['small-title', 'with-bg-color'],
        mainTitle: 'Change Password',
        subTitle: ''
      },
      formData: {
        new_password_confirmation: '',
        new_password: '',
        old_password: ''
      },
      errors: {},
      imageURL: this.$appConfig.imagesUrl
    }
  },
  methods: {
    submitForm () {
      this.submited = true
      this.showLoading()
      const service = new ProfileService()
      service
        .changePassword(this.formData)
        .then((data) => {
          this.afterSuccess()
        })
        .catch((err) => {
          const errors = {}
          errors[this.$i18n.t("message['form.new-password']")] =
            err.response.data.errors.new_password || []
          errors[this.$i18n.t("message['form.current-password']")] =
            err.response.data.errors.old_password || []
          errors[this.$i18n.t("message['form.confirm-password']")] =
            err.response.data.errors.new_password || []
          this.$refs.currentPasswordValidationProvider.setErrors(
            err.response.data.errors.old_password || []
          )
          this.$refs.newPasswordValidationProvider.setErrors(
            err.response.data.errors.new_password || []
          )
          this.$refs.form.setErrors(errors)
          this.hideLoading()
        })
        .finally(() => {
          this.submited = false
        })
    },
    afterSuccess () {
      this.$router.push({
        path: this.$route.path
      })
    },
    changeEvent (event) {}
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
  }
}
</script>
