<template>
  <div
    class="gc-page__content-image"
    v-on="$listeners"
    v-if="contentImage">
    <img :src="contentImage"/>
  </div>
</template>

<script>
export default {
  name: 'PageContentImage',
  props: {
    contentImage: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>

</style>
