<template>
  <page-secondary
    :desktop-side-bar="false"
    :header="headerOptions"
    class="pc-sign-up-payment"
  >
    <header-subscription
      v-if="paymentState==='failed'"
      :steps="stepsData"
      :route="'/sign-up'"
    />
    <grid-row
      v-if="paymentState==='failed'"
      :no-gutters="true"
    >
      <grid-col
        md="12"
        lg="4"
      >
        <card-subscription-cover-image
          :logo="logoImage"
          :image="titleImage"
        />
      </grid-col>
      <grid-col
        md="12"
        lg="8"
        class="pc-sign-up-payment__content-col"
      >
        <page-container class="pc-sign-up-register__container">
          <div class="pc-sign-up-payment__wrapper">
            <alert-dotted
              v-if="paymentState==='failed' "
              class="pc-sign-up-payment__warning"
            >
              <icon-warning
                size="sm2"
                class="pc-sign-up-payment__warning-icon"
              />
              <div class="pc-sign-up-payment__content">
                <text-body-small
                  :weight="'extra-bold'"
                  :line-height="'multi'"
                  class="pc-sign-up-payment__warning-name"
                >
                  {{ $t('message["sign-up.payment-failed"]') }}
                </text-body-small>

                <text-body-extra-small
                  :line-height="'multi'"
                  class="pc-sign-up-payment__warning-description"
                >
                  {{ $t('message["sign-up.payment-failed-or-canceled"]') }}
                </text-body-extra-small>
              </div>
            </alert-dotted>
            <div v-if="paymentState==='failed' ">
              <button-primary
                class="pc-sign-up-payment-confirm__submit"
                :text="$t('message[\'sign-up.payment.try-again\']')"
                @click="restartPayment"
              />
              <button-secondary
                class="pc-sign-up-payment-confirm__contact"
                :text="$t('message[\'settings.contact-support\']')"
                @click="contactSupport.popupShow= true"
              />
            </div>
          </div>
        </page-container>
      </grid-col>
    </grid-row>
    <popup-contact-support
      v-if="contactSupport.popupShow"
      :popup-visibility="contactSupport.popupShow"
      @close="contactSupport.popupShow=false"
    />
    <popup-payment-processing
      v-if="!isStripeCheckout&&paymentState!=='failed' && popupPaymentProcessing.show"
      @redirected="redirected=true"
    />
    <popup-please-await
      v-else-if="paymentState!=='failed' && popupPaymentProcessing.show"
      @redirected="redirected=true"
    />
  </page-secondary>
</template>
<script>

import GridRow from '../../../global/grid/GridRow'
import GridCol from '../../../global/grid/GridCol'
import IconLoadingBuffer from '../../../root/icons/IconLoadingBuffer'
import TextError from './page-components/TextError'
import PageSecondary from '../../../global/pages/PageSecondary'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import PopupContactSupport from '../../../global/popups/PopupContactSupport'
import HeaderSubscription from '../../../global/headers/HeaderSubscription'
import PopupPaymentProcessing from './page-components/PopupPaymentProcessing'
import CardSubscriptionCoverImage
  from '../../../global/cards/card-subscription-sub-components/CardSubscriptionCoverImage'
import AlertDotted from '../../template-2/global/alerts/AlertDotted'
import TextBodyRegular from '../../../global/typography/TextBodyRegular'
import TextBodyExtraSmall from '../../../global/typography/TextBodyExtraSmall'
import TextBodySmall from '../../../global/typography/TextBodySmall'
import IconWarning from '../../../root/icons/IconWarning'
import headerEventBus from '../../../../event-buses/headerEventBus'
import PageFooter from '../../../global/pages/PageFixedFooter'
import ButtonPrimary from '../../../global/buttons/ButtonPrimary'
import ButtonSecondary from '../../../global/buttons/ButtonSecondary'
import pageSignUpPaymentConfirmMixin from '@/mixins/component-mixins/page/sign-up/pageSignUpPaymentConfirmMixin'
import { mapMutations } from 'vuex'
import DashBoardService from '@/services/DashboardService'
import PopupPleaseAwait from '@/components/layout/template-1/sign-up/page-components/PopupPleaseAwait.vue'
import { setMAApplicationData } from '@/helpers/dom/header'

export default {
  name: 'PageSignUpPaymentConfirm',
  components: {
    PopupPleaseAwait,
    ButtonSecondary,
    PageFooter,
    ButtonPrimary,
    IconWarning,
    TextBodyRegular,
    TextBodyExtraSmall,
    TextBodySmall,
    AlertDotted,
    CardSubscriptionCoverImage,
    GridRow,
    GridCol,
    IconLoadingBuffer,
    TextError,
    PageSecondary,
    PageContainer,
    PopupContactSupport,
    HeaderSubscription,
    PopupPaymentProcessing
  },
  mixins: [ pageSignUpPaymentConfirmMixin],
  data:function (){
    return {
      isStripeCheckout: false
    }
  },
  created () {
    this.setImages()

    if(sessionStorage.getItem('plan-id')){
      setMAApplicationData('plan-id',sessionStorage.getItem('plan-id'))
    }
    if(sessionStorage.getItem('plan-currency')){
      setMAApplicationData('plan-currency',sessionStorage.getItem('plan-currency'))
    }
    if(sessionStorage.getItem('plan-price')){
      setMAApplicationData('plan-price',sessionStorage.getItem('plan-price'))
      setMAApplicationData('subscription-price',sessionStorage.getItem('plan-price'))
    }

  },
  beforeMount () {
    this.paymentState = this.$route.query.redirect_status
    headerEventBus.$on('support-icon-clicked', this.showPopupContactSupport)
    this.setRouteTransition({ name: 'none', mode: 'out-in' })
  },
  mounted () {
    this.initService()
    if (this.$route.query.subscription) {
      this.subscriptionID = this.$route.query.subscription
    }

    this.service.getPlanDetails().then((paymentData) => {
      this.isStripeCheckout =  paymentData.checkout_type ==='stripe' || false
      const paymentGateways = paymentData.payment_gateways[0]
      this.stripeKey = paymentGateways['publishable_key']
      this.accountId = paymentGateways['account_id']

      if (this.$route.query.payment_intent_client_secret) {
        this.retrievePaymentIntent(this.$route.query.payment_intent_client_secret)
      }
      if (this.paymentState !== 'failed') {
        this.confirmCheckoutPaymentBySessionID()
        this.checkSubscriptionStatus()
      }
      this.hideLoading()
    })
    let dashBoardService = new DashBoardService()
    dashBoardService.getUserData(true).then(()=>{

      setMAApplicationData('customer-email',dashBoardService.getUserEmail())
    })
  },
  methods:{
    ...mapMutations({
      setRouteTransition: 'setRouteTransition'
    })
  },
  beforeDestroy () {
    headerEventBus.$on('support-icon-clicked', this.showPopupContactSupport)
  }
}
</script>

<style scoped>

</style>
