import BaseAPI from '../BaseAPI.js'
import axios from 'axios'
import DefineInclude from '../../../DefineInclude'
import moment from 'moment'

export default class HabitsApi extends BaseAPI {
    _resource ='me'
    _habitsTrackerBaseUrl = ''

    constructor () {
      super()
      this._habitsTrackerBaseUrl = DefineInclude.habitsTrackerAPIUrl
    }

    getUserData () {
      const url = this._habitsTrackerBaseUrl
      const pathArr = [this._resource]
      return axios.get(url + this._getPath(pathArr), {headers: this._getCustomHeaders()})
    }

    getAllHabitsEntries () {
      // If have other url need to add on DefineIncludes.
      const url = this._habitsTrackerBaseUrl
      const pathArr = [this._resource, 'habits']
      return axios.get(url + this._getPath(pathArr), {headers: this._getCustomHeaders()})
    }

    queryHabits (from, to) {
      // If have other url need to add on DefineIncludes.
      const url = this._habitsTrackerBaseUrl
      const pathArr = [this._resource, 'habits_status_history']
      const configs = {
        headers: this._getCustomHeaders(),
        params: { from: from, to: to }
      }
      return axios.get(url + this._getPath(pathArr), configs)
    }

    /**
   * upsert (create or update) habit entry
   * @param habitId
   * @param status
   * @param date
   * @returns {Promise<AxiosResponse<T>>}
   */
    setHabitEntry (habitId, status, date = moment()) {
      // If have other url need to add on DefineIncludes.
      const url = this._habitsTrackerBaseUrl
      let bodyParams = {'label': status, 'set_on': date.format('YYYY-MM-DD')}
      const pathArr = [this._resource, 'habits', habitId, 'status']
      return axios.put(url + this._getPath(pathArr), bodyParams, {headers: this._getCustomHeaders()})
    }

    /**
   * delete the habit entry by it's ID and habit ID
   * @param habitId - habit ID
   * @param habitEntryID - habit entry ID
   * @returns {Promise<AxiosResponse<T>>}
   */
    deleteHabitEntry (habitId, habitEntryID) {
      const url = this._habitsTrackerBaseUrl

      const pathArr = [this._resource, 'habits', habitId, 'statuses', habitEntryID]
      return axios.delete(url + this._getPath(pathArr), {headers: this._getCustomHeaders()})
    }

    _getCustomHeaders () {
      let headers = this._headers
      headers['Authorization'] = 'Bearer ' + this._token
      headers['X-Api-Host'] = DefineInclude.apiUrl.replace(/\/$/, '')
      return headers
    }
}
