<template>
    <popup
            :visible="visible"
            v-if="visible"
            size="md"
            ref="popup"
            modal-class="pc-popup-period-tracker-settings"
            @shown="initiate"
            @hide="closePopup"
    >
        <component v-bind:is="activeComponent.component"
                   v-if="activeComponentKey"
                   @processed="dataProcessed"
                   @next="nextComponent"
                   @exit-confirm="showExitConfirm"
                   @mounted="componentMounted"
                   @goBack="goBack"
                   @close="closePopup"/>
        <loading-buffer v-else  />
    </popup>
</template>

<script>
import popupAnimationMixin from '../../../../../../mixins/popupAnimationMixin'
import Popup from '../../../../../global/popups/Popup'
import PopupTitle from '../../../../../global/popups/popup-sub-components/PopupTitle'
import PopupButtonClose from '../../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupButtonBack from '../../../../../global/popups/popup-sub-components/PopupButtonBack'
import PopupBody from '../../../../../global/popups/popup-sub-components/PopupBody'
import PopupContent from '../../../../../global/popups/popup-sub-components/PopupContent'
import PopupFloatingFooter from '../../../../../global/popups/popup-sub-components/PopupFloatingFooter'
import CycleDuration from './CycleDuration'
import PeriodDuration from './PeriodDuration'
import RecentPeriod from './RecentPeriod'
import ProcessData from './ProcessData'
import ProcessCompleted from './ProcessCompleted'
import ExitConfirm from './ExitConfirm'
import {mapGetters} from 'vuex'
import DisclaimerConfirm from './DisclaimerConfirm'
import LoadingBuffer from '../../../global/widgets/LoadingBuffer'

export default {
  name: 'PopupDefaultSettings',
  props: {
    visible: {
      default: false
    }
  },
  mixins: [popupAnimationMixin],
  components: {
    LoadingBuffer,
    PopupFloatingFooter,
    Popup,
    PopupTitle,
    PopupButtonClose,
    PopupButtonBack,
    PopupBody,
    PopupContent,
    ExitConfirm
  },
  data () {
    return {
      loading: true,
      firstRecord: {},
      components: {
        disclaimer: {
          component: DisclaimerConfirm,
          next: {
            action: () => {
              this.activeComponentKey = 'cycleDuration'
            }
          }
        },
        cycleDuration: {
          component: CycleDuration,
          next: {
            action: () => {
              this.activeComponentKey = 'periodDuration'
            }
          }
        },
        periodDuration: {
          component: PeriodDuration,
          goBack: {
            action: () => {
              this.activeComponentKey = 'cycleDuration'
            }
          },
          next: {
            action: () => {
              if (this.firstRecord.getId()) {
                this.activeComponentKey = 'processData'
              } else {
                this.activeComponentKey = 'recentDuration'
              }
            }
          }
        },
        recentDuration: {
          component: RecentPeriod,
          goBack: {
            action: () => {
              this.activeComponentKey = 'periodDuration'
            }
          },
          next: {
            action: () => {
              this.activeComponentKey = 'processData'
            }
          }
        },
        processData: {
          component: ProcessData,
          next: {
            action: () => {
              this.activeComponentKey = 'processComplete'
            }
          }
        },
        processComplete: {
          component: ProcessCompleted
        },
        exitConfirm: {
          component: ExitConfirm
        }
      },
      activeComponentKey: null,
      exitConfirm: false
    }
  },
  computed: {
    activeComponent: function () {
      if (this.exitConfirm) {
        return this.components.exitConfirm
      } else {
        return this.components[this.activeComponentKey]
      }
    }
  },
  methods: {
    ...mapGetters({
      service: 'periodTrackerStore/getService'
    }),
    initiate () {
      this.service()
        .setFirstRecord()
        .then(() => {
          this.firstRecord = this.service().getFirstRecord()

          if (!this.firstRecord.getId()) {
            this.activeComponentKey = 'disclaimer'
          } else {
            this.activeComponentKey = 'cycleDuration'
          }
        }).catch(() => {
          this.closePopup()
        })
    },
    goBack () {
      this.activeComponent.goBack.action()
    },
    nextComponent () {
      this.activeComponent.next.action()
    },
    showExitConfirm (show = true) {
      if (!this.firstRecord.getId()) {
        this.exitConfirm = show
      } else {
        this.closePopup()
      }
    },
    componentMounted () {
      this.$refs.popup.setContentHeight()
    },
    dataProcessed () {
      this.$emit('completed')
    },
    closePopup () {
      this.closeAnimatedPopup(() => {
        this.$emit('close')
        this.exitConfirm = false
      })
    }
  }
}
</script>

<style scoped>

</style>
