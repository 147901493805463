import RecipeAPI from '../api/meal-plan/RecipeAPI'

export default class MealPlanMealRecipeService {
  _id = null
  _name = ''
  _images = []
  _ingredients = []
  _referenceRecipeId = ''
  _planID = null
  _videos = []
  _instructions = {
    title: '',
    steps: []
  }
  _description = ''
  _isLoaded = false
  _apiResponse = null

  constructor (data, planID) {
    this._id = data.id
    this._planID = planID
    this._name = data.name || ''
    this._images = data.images || []
    this._ingredients = data.ingredients
    this._preparationTime = data.preparation_time || 0
    this._referenceRecipeId = data.reference_recipe_id || null
  }

  getId () {
    return this._id
  }

  getName () {
    return this._name
  }

  /**
   * returns preparation time in minutes
   * @returns {*|number}
   */
  getPreparationTime () {
    return this._preparationTime
  }

  getReferenceId () {
    return this._referenceRecipeId
  }

  getImages () {
    return this._images || []
  }

  getImageMainImage () {
    if (this._images && this._images[0]) {
      return this._images[0]
    }
    return null
  }

  getInstructionSteps () {
    return this._instructions.steps
  }

  getIngredients () {
    return this._ingredients
  }

  getVideos () {
    return this._videos
  }

  getDescription () {
    return this._description
  }

  isLoaded () {
    return this._isLoaded
  }

  getDetailedInfo () {
    return new Promise((resolve, reject) => {
      if (this._isLoaded) {
        resolve(this)
      } else {
        this.getDataFromAPI().then(() => {
          resolve(this)
        }).catch(() => {
          reject(new Error('recipe not loaded'))
        })
      }
    })
  }

  setVideos (videos) {
    this._videos = videos || []
  }

  setImages (images) {
    this._images = images || []
  }

  setDescription (description) {
    this._description = description
  }

  setInstructions (instructions) {
    this._instructions = instructions
  }

  setIngredients (ingredients) {
    this._ingredients = ingredients
  }

  getDataFromAPI () {
    let recipeAPI = new RecipeAPI()
    return recipeAPI.get(this._id, [this._planID]).then(data => data.data).then(data => {
      this._videos = data.videos || []
      this._images = data.images || []
      this._description = data.description
      this._ingredients = data.ingredients
      this._instructions = data.instructions

      this._apiResponse = data
      return data
    }).finally(() => {
      this._isLoaded = true
    })
  }

  getAPIResponse () {
    return this._apiResponse
  }
}
